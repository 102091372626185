import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Modal, Switch, Table, Tag, message } from "antd";
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { deleteInventoryApi } from "../../../../../../apis/eventsApi";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import {
  AddonCategoryDetailsInventoryDetailRoute,
  AddonCategoryDetailsInventoryRoute,
  EventCategoryDetailsInventoryDetailRoute,
  EventCategoryDetailsInventoryRoute,
} from "../../../../../../url-routes/urlRoutes";
import {
  HOLD_STATUS,
  INVENTORY_FACILITIES,
  INVENTORY_ID_REQUIRED,
  SOMETHING_WENT_WRONG,
} from "../../../../../../utils/constants";
import { decodeContent } from "../../../../../../utils/contentParser";
import InventoryHoldStatusTable from "./InventoryPopup/InventoryHoldStatusTable";
import InventoryStatusHoldForm from "./InventoryPopup/InventoryStatusHoldForm";

const InventoryListing = ({
  inventoryData,
  loadingAllInventoryList,
  setLoadInventoryAgain,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editInventory, setEditInventory] = useState(null);
  const [releasingHold, setReleasingHold] = useState(false);

  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const addonId = searchParams.get("addonId");

  const showModal = (inventory, releasableHold) => {
    setIsModalOpen(true);
    setEditInventory(inventory);
    if (releasableHold) {
      setReleasingHold(releasableHold);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setEditInventory(null);
    setReleasingHold(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditInventory(null);
    setReleasingHold(null);
  };

  const deleteInventory = async (deleteId) => {
    const response = await deleteInventoryApi(deleteId);
    if (response.status) {
      message.success("Inventory Deleted Successfully");
      setLoadInventoryAgain(response);
    } else {
      message.error(
        response?.data?.response?.data?.errors[0]?.detail ||
          response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this inventory?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteInventory(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  function redirectToInventoryDetailPage(inventoryId) {
    if (!inventoryId) {
      message.error(INVENTORY_ID_REQUIRED);
      return;
    }
    let url = addonId
      ? `${AddonCategoryDetailsInventoryDetailRoute}?inventoryId=${inventoryId}&id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsInventoryDetailRoute}?inventoryId=${inventoryId}&id=${eventId}&categoryId=${categoryId}`;
    return url;
  }

  function redirectToEditPage(editId) {
    if (!editId) {
      message.error(INVENTORY_ID_REQUIRED);
      return;
    }
    return addonId
      ? `${AddonCategoryDetailsInventoryRoute}?editId=${editId}&id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsInventoryRoute}?editId=${editId}&id=${eventId}&categoryId=${categoryId}`;
  }

  function getAvailedFacilities(inventory) {
    const facilityList = INVENTORY_FACILITIES?.map((item) => {
      if (inventory?.[item.key]) {
        return (
          <span>
            {item?.name}
            {" : "}
            {inventory?.[item.key] === true ? "Yes" : inventory?.[item.key]}
          </span>
        );
      }
    });
    return facilityList;
  }

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return (
          <>
            <div>
              {/* <Link to="/Inventoryview_page"> */}
              <Link to={redirectToInventoryDetailPage(record?.uuid)}>
                <a style={{ fontSize: "14px", lineHeight: "20px" }}>
                  {decodeContent(record?.name)}
                </a>
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {getAvailedFacilities(record)}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Comments",
      dataIndex: "item_description",
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy",
    },
    {
      title: "	Share?",
      dataIndex: "share",

      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Switch checked={record} disabled />
            </div>
          </>
        );
      },
    },

    {
      title: "Quantity",
      dataIndex: "field_quantity",
    },

    {
      title: "Status",
      // dataIndex: "field_inventory_status",
      dataIndex: "inventory_status",
      render: (record) => {
        return (
          <>
            <div className="primary_cta">{record}</div>
          </>
        );
      },
    },

    {
      key: "4",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex">
              <Link to={redirectToEditPage(record?.uuid)}>
                <EditOutlined className="action_edit" />
              </Link>

              <DeleteOutlined
                className="action_delete"
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteHandler(record.uuid);
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  function deriveAvailabilityStatus(inventory) {
    const result = HOLD_STATUS?.reduce((acc, item) => {
      if (inventory?.[item?.key] && !!Number(inventory?.[item.key])) {
        acc.push(
          <div
            style={{ cursor: "pointer" }}
            onClick={() => showModal(inventory, item?.key)}
          >
            {inventory?.[item?.key]} : {item.name}
          </div>
        );
        return acc;
      }
      return acc;
    }, []);
    return result;
  }

  const dataSource = inventoryData?.length
    ? inventoryData?.map((inventory) => {
        return {
          ...inventory,
          item_code: decodeContent(inventory?.item_code),
          inventory_status: (
            <>
              {inventory?.availability && !!Number(inventory?.availability) && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => showModal(inventory, "availability")}
                >
                  {inventory?.availability}
                  {" : Available"}
                </div>
              )}
              {deriveAvailabilityStatus({
                ...inventory,
              })}
            </>
          ),
        };
      })
    : [];

  return (
    <div>
      <Table
        className="category_itinerary_table"
        columns={columns}
        dataSource={dataSource}
        // size="small"
        pagination={false}
        loading={{
          spinning: loadingAllInventoryList,
          indicator: <DataLoader />,
        }}
      />
      {editInventory && (
        <Modal
          title={
            <>
              <span className="span_bold">Edit Status</span>
            </>
          }
          footer={""}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal_header">
            <a href="#">{editInventory?.item_code || ""}</a>

            <div className="buttons_flex">
              <p className="">{decodeContent(editInventory?.name)}</p>
              {editInventory?.share && (
                <p>
                  <Tag className="primary_color" color="blue">
                    Share
                  </Tag>
                </p>
              )}
            </div>
            <div
              className="span_text"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: "column",
                paddingBottom: "1rem",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              {getAvailedFacilities(editInventory)}
            </div>
          </div>
          <InventoryHoldStatusTable
            editInventory={editInventory}
            releasingHold={releasingHold}
          />
          <InventoryStatusHoldForm
            editInventory={editInventory}
            setLoadInventoryAgain={setLoadInventoryAgain}
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            releasingHold={releasingHold}
          />
        </Modal>
      )}
    </div>
  );
};
export default InventoryListing;
