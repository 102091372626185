import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Grid } from "antd";
import RolesSearchbar from "../../../components/Admin/ManageRoles/RolesSearchbar";
import AssociateHeader from "../../../components/Breadcrumb/Admin_header/AssociateHeader";
import AssociateMembers from "../../../components/Admin/ManageRoles/AssociateMembers";
import AssociatePermissions from "../../../components/Admin/ManageRoles/AssociatePermissions";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { useSearchParams } from "react-router-dom";
import { getAllRolesApi, getSingleRoleApi } from "../../../apis/manageRoles";
import DataLoader from "../../../others/Loaders/DataLoader";
import { userAssociatedWithRolesApi } from "../../../apis/usersApi";
import { useSelector, useDispatch } from "react-redux";
import validPermissions from "./validPermissions.json";
import PermissionsDropDown from "./PermissionsDropDown";

const { Header, Content, Sider } = Layout;

const ManageRoleDetailsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const roleMachineName = searchParams.get("role");
  const roleMachineName = searchParams.get("name");
  const id = searchParams.get("role");
  const [getRoleDependency, setGetRoleDependency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleNames, setRoleNames] = useState({});
  const [permissionsList, setPermissionsList] = useState([]);
  const [users, setUsers] = useState([]);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Roles", path: "/manage-roles" },
    { title: "Details", path: "" },
  ];

  const userCreated = useSelector((state) => state.users.userCreated);
  const userDelete = useSelector((state) => state.users.userDeleted);
  const userUpdate = useSelector((state) => state.users.userUpdated);

  const getRoleDetails = async () => {
    setIsLoading(true);
    const response = await getSingleRoleApi(id);

    if (response.status) {
      setRoleNames({
        name: response?.data?.data?.label,
        machineName: response?.data?.data?.id,
      });
      let permission = response?.data?.data?.permissions || [];
      let allPermissions = [];

      permission.forEach((e, index) => {
        // if (validPermissions?.indexOf(e) !== -1) {
        allPermissions.push(e);
        // }
      });
      setPermissionsList(permission);
      // setUsers(response?.data?.users || {});
      // setIsLoading(false);
    } else {
      setRoleNames({});
      setPermissionsList([]);
      // setIsLoading(false);
    }
  };

  const getUsersAssociatedWithRole = async () => {
    setIsLoading(true);
    const response = await userAssociatedWithRolesApi(roleMachineName);
    if (response.status) {
      setUsers(response?.data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRoleDetails(roleMachineName);
    getUsersAssociatedWithRole();
  }, [roleMachineName, getRoleDependency, userCreated, userDelete, userUpdate]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <RolesSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            {console.log("ROLE NAME", roleNames)}
            <AssociateHeader roleNames={roleNames} />
          </div>
          {/* {isLoading ? (
            <DataLoader />
          ) : ( */}
          <div>
            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <AssociateMembers
                users={users}
                setGetRoleDependency={setGetRoleDependency}
                getRoleDependency={getRoleDependency}
                isLoading={isLoading}
              />
            </Content>

            <div
              className="site-layout-background"
              style={{ marginBottom: "10px" }}
            >
              <PermissionsDropDown
                viewMode={true}
                permissions={permissionsList}
              />
            </div>
          </div>
          {/* )} */}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ManageRoleDetailsPage;
