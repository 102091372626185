import { Grid, Layout } from "antd";
import React from "react";
import ManageRolesContainer from "../../../components/Admin/ManageRoles/ManageRolesContainer";
import ManageRolesHeader from "../../../components/Admin/ManageRoles/ManageRolesHeader";
import RolesSearchbar from "../../../components/Admin/ManageRoles/RolesSearchbar";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
const { Header, Content, Sider } = Layout;

const ManageRolesPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Roles", path: "/manage-roles" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <RolesSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ManageRolesHeader />
          </div>
          <Content className="site-layout-background">
            <ManageRolesContainer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ManageRolesPage;
