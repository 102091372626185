import { Card, Col, Row, Tabs } from "antd";
import React from "react";
import Reservation from "./DashboardBarGraph";
import DashboardBarGraph from "./DashboardBarGraph";
import { useSearchParams } from "react-router-dom";

const DashboardBarGraphContainer = ({
  setActiveBarTab,
  setBarDuration,
  dashboardBarData,
  activeBarTab,
  barDuration,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Row
      style={{ marginBottom: "20px" }}
      className="dash-section dash-section-two"
    >
      <Col span={24}>
        <Card>
          <h3>Reservations</h3>
          <Row gutter={16}></Row>
          <Tabs
            defaultActiveKey={activeBarTab}
            onChange={(e) => {
              setActiveBarTab(e);
              searchParams.set("bar_tab", e);
              setSearchParams(searchParams);
            }}
          >
            <Tabs.TabPane tab="New" key="new">
              <DashboardBarGraph
                barDuration={barDuration}
                setActiveBarTab={setActiveBarTab}
                setBarDuration={setBarDuration}
                dashboardBarData={dashboardBarData}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Canceled" key="cancelled">
              <DashboardBarGraph
                barDuration={barDuration}
                setActiveBarTab={setActiveBarTab}
                setBarDuration={setBarDuration}
                dashboardBarData={dashboardBarData}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Net New" key="net_new">
              <DashboardBarGraph
                barDuration={barDuration}
                setActiveBarTab={setActiveBarTab}
                setBarDuration={setBarDuration}
                dashboardBarData={dashboardBarData}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardBarGraphContainer;
