import { Table, Divider, Checkbox, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { getAppliedPlannedPaymentsListApi } from "../../../../../../apis/reservationApi";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
} from "../../../../../../utils/constants";
import { addCommasInPricing } from "../../../../../../utils/contentParser";
import { ReservationPlannedPaymentDetailRoute } from "../../../../../../url-routes/urlRoutes";

const columns = [
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
  },
  {
    title: "Payment Amount",
    dataIndex: "planned_amount",
  },
  {
    title: "Total Applied",
    dataIndex: "total_applied",
  },
  {
    title: "Applied from this payment",
    dataIndex: "applied_from_this_payment",
  },
];

const PlannedPaymentsApplied = ({
  paymentData,
  loadingPaymentData,
  reservationId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const paymentId = searchParams.get("paymentId");
  useEffect(() => {
    getAppliedPaymentsList(paymentId);
  }, []);

  const getAppliedPaymentsList = async (paymentId) => {
    setLoading(true);
    const res = await getAppliedPlannedPaymentsListApi(paymentId);
    if (res.status) {
      setData(
        res?.data?.data?.map((payment) => ({
          key: payment?.id,
          uuid: payment?.uuid,
          description: (
            <Link
              to={`${ReservationPlannedPaymentDetailRoute}?id=${reservationId}&paymentId=${payment?.uuid}`}
            >
              {payment?.field_paymenttype || ""}
            </Link>
          ),
          date: payment?.field_paydate
            ? moment(payment?.field_paydate).format(DATE_FORMAT)
            : "",
          payment_method: payment?.field_paymethod || "",

          planned_amount: payment?.field_planamount
            ? `$${addCommasInPricing(payment?.field_planamount)}`
            : "",
          total_applied: payment?.field_total_applied
            ? `$${addCommasInPricing(payment?.field_total_applied)}`
            : "",
          applied_from_this_payment:
            payment?.field_plannedpayment_field_applied_amount
              ? `$${addCommasInPricing(
                  payment?.field_plannedpayment_field_applied_amount
                )}`
              : "",
        }))
      );
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Planned Payments - Applied</h4>
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loading, size: "large" }}
      />
    </div>
  );
};
export default PlannedPaymentsApplied;
