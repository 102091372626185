import { DatePicker, Form } from "antd";
import React from "react";

const DynamicStartEndDate = ({
  form,
  name,
  restField,
  formListName,
  startDateLabel,
  endDateLabel,
  startName,
  endName,
}) => {
  const validateEndDate = (e, value) => {
    if (e.field.includes(startName)) {
      const startDate = value;
      const endDate = form.getFieldValue([
        formListName,
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: [
                formListName,
                e?.dependencies?.[0]?.[0],
                e?.dependencies?.[0]?.[1],
              ],
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: [
                  formListName,
                  e?.dependencies?.[0]?.[0],
                  e?.dependencies?.[0]?.[1],
                ],
                errors: [
                  `${endDateLabel} cannot be less than the ${startDateLabel}`,
                ],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue([
        formListName,
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject(
            `${endDateLabel} cannot be less than the ${startDateLabel}`
          );
        }
      }
    }
    return Promise.resolve();
  };
  return (
    <>
      <Form.Item
        label={startDateLabel}
        {...restField}
        name={[name, startName]}
        rules={[
          {
            validator: validateEndDate,
            dependencies: [[name, endName]], // Trigger validation when endDate changes
          },
        ]}
      >
        <DatePicker
          style={{
            padding: "4px",
          }}
          format={"M/D/YYYY"}
        />
      </Form.Item>
      <Form.Item
        label={endDateLabel}
        {...restField}
        name={[name, endName]}
        rules={[
          {
            validator: validateEndDate,
            dependencies: [[name, startName]], // Trigger validation when startDate changes
          },
        ]}
      >
        <DatePicker
          style={{
            padding: "4px",
          }}
          format={"M/D/YYYY"}
        />
      </Form.Item>
    </>
  );
};

export default DynamicStartEndDate;
