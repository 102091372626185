import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  Checkbox,
  Switch,
  Modal,
  Tag,
  Button,
  Input,
  Menu,
  Form,
  Select,
  message,
} from "antd";
import {
  MailOutlined,
  PlusOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getReservationActivityListingApi } from "../../../../apis/reservationApi";
import moment from "moment";
import { DATE_FORMAT, SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { createActivitiesApi } from "../../../../apis/contactApi";
import { decodeContent } from "../../../../utils/contentParser";
import { useDispatch } from "react-redux";
import { setActivityTrigger } from "../../../../redux/features/reservationFeatures/reservationSlice";
import { useSelector } from "react-redux";
import {
  ReservationItemDetailRoute,
  ReservationPlannedPaymentDetailRoute,
} from "../../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Date/Time",
    dataIndex: "date_time",
  },
  {
    title: "User",
    dataIndex: "user",
  },
];
const { TextArea } = Input;

const ReservationActivityListing = ({
  reservationId,
  loadingReservationData,
  reservationData,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isActivityTrigger } = useSelector((state) => state.reservation);
  const [creationLoading, setCreationLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    getActivityListing();
  }, [isActivityTrigger]);

  const redirectActivity = (activityType, activityObj) => {
    let redirectionUrl = null;
    if (
      activityType.includes("add_planned_payment") &&
      permissionChecker("modify payments")
    ) {
      redirectionUrl = `${ReservationPlannedPaymentDetailRoute}?id=${reservationId}&paymentId=${activityObj?.planned_payment_uuid}`;
    }
    if (
      activityType.includes("reservation_item") &&
      permissionChecker("add/edit reservation")
    ) {
      redirectionUrl = `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${activityObj?.reservation_item_uuid}`;
    }
    return redirectionUrl;
  };
  const getActivityListing = async () => {
    setIsLoading(true);
    const response = await getReservationActivityListingApi(reservationId);
    if (response.status) {
      setCount(response?.data?.pager?.total_items || 0);
      setData(
        response?.data?.rows?.length
          ? response?.data?.rows?.map((listItem) => ({
              key: listItem?.id,
              uuid: listItem?.uuid,
              description: (
                <>
                  {listItem?.field_task_type === "Email" ? (
                    <Link
                      to={`/contact/details/activities?activityId=${listItem?.uuid}&contactId=${listItem?.contact_uuid}`}
                    >
                      {decodeContent(listItem?.field_description)}{" "}
                    </Link>
                  ) : redirectActivity(listItem?.entity_type, listItem) ? (
                    <Link
                      to={redirectActivity(listItem?.entity_type, listItem)}
                    >
                      {decodeContent(listItem?.field_description)}{" "}
                    </Link>
                  ) : (
                    decodeContent(listItem?.field_description)
                  )}
                </>
              ),
              status: (
                <>
                  <span
                  // style={{ color: "Green" }}
                  >
                    {listItem?.field_task_status}
                  </span>
                </>
              ),
              date_time: (
                <>
                  {listItem?.created ? (
                    <span>
                      {moment.unix(listItem?.created).format(DATE_FORMAT)}{" "}
                      {moment.unix(listItem?.created).format("hh:mm A")}{" "}
                      <span className="span_text">
                        {moment.unix(listItem?.created).fromNow()}
                      </span>
                    </span>
                  ) : null}
                </>
              ),
              user:
                listItem?.created_on === "Staff Web" || !listItem?.created_on?.trim()?.length 
                  ? listItem?.user
                  : listItem?.user === "Anonymous"
                  ? "Primary Traveler"
                  : listItem?.contact_created_by,
            }))
          : []
      );
    } else {
      setCount(0);
      setData([]);
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsLoading(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinishHandler = (value) => {
    console.log("note");
    const payload = {
      data: {
        type: "activity--activity",
        attributes: {
          // field_note_text: value?.note?.trim() || "",
          field_description: value?.note?.trim() || null,
          field_task_type: "note",
          field_task_status: null,
          field_priority: null,
        },
        relationships: {
          field_reservation: reservationId
            ? {
                data: {
                  type: "reservation--reservation",
                  id: reservationId,
                },
              }
            : null,
        },
      },
    };
    if (value?.note) {
      createActivityNote(payload);
    }
  };

  const createActivityNote = async (payload) => {
    setCreationLoading(true);
    const response = await createActivitiesApi({ payload });
    if (response.status) {
      dispatch(setActivityTrigger(!isActivityTrigger));
      message.success("Note Added Successfully");
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setCreationLoading(false);
    handleCancel();
  };
  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Activity ({count})</h4>
        {permissionChecker("add/edit reservation") ? (
          <Button type="primary" onClick={showModal}>
            <PlusOutlined /> Add Note
          </Button>
        ) : null}

        <Modal
          footer={
            <>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Save
              </Button>
            </>
          }
          className="pop_modal"
          title={
            <>
              <span className="span_bold">Adding Note</span>
            </>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal_heading">
            <div className="modal_title">
              <p>
                Add Note to Reservation:{" "}
                <span className="span_text">{reservationData?.name}</span>
              </p>
            </div>
          </div>

          <div className="modal_textarea">
            <Form layout="vertical" form={form} onFinish={onFinishHandler}>
              <Form.Item
                className="textarea_row"
                name="note"
                label="Note"
                rules={[{ required: true }]}
              >
                <TextArea showCount maxLength={500}></TextArea>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        loading={{
          spinning: isLoading || loadingReservationData,
          size: "large",
        }}
      />
    </div>
  );
};
export default ReservationActivityListing;
