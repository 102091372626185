import { EditOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEmailTemplatesListApi } from "../../../apis/emailTemplates";
import DataLoader from "../../../others/Loaders/DataLoader";
import { AdminEmailTemplateEditRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { permissionChecker } from "../../../utils/permisssionChecker";

const columns = [
  {
    title: "Template",
    dataIndex: "email_templates",
    key: "email_templates",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const EmailTemplatesContainer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const getEmailTemplates = async () => {
    setLoading(true);
    const res = await getEmailTemplatesListApi();
    if (res.status) {
      setData(
        res?.data?.data?.map((listItem) => ({
          key: listItem.id,
          email_templates: listItem.title,
          action: (
           <>
           {
            permissionChecker("edit email templates") ?  <EditOutlined
            className="action_edit"
            onClick={() => {
              navigate(`${AdminEmailTemplateEditRoute}?id=${listItem?.id}`);
            }}
          /> : null
           }
           </>
          ),
        }))
      );
      setLoading(false);
    } else {
      setData([]);
      message.error(SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };
  return (
    <>
      <Table
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

export default EmailTemplatesContainer;
