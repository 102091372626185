import { Breadcrumb, Layout, Menu, Col, Row, Grid, message, Modal } from "antd";
import Searchreservations_menu from "../../../../components/Reservation/ReservationSearchBar";
import GroupListingsHeader from "./GroupListingsHeader";
import GroupListingsTable from "./GroupListingsTable";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { drupalAxiosRequest } from "../../../../utils/drupalAxiosRequest";
import { customRequestHeader, Token } from "../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { Header, Content, Sider } = Layout;
function GroupListingsPage() {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get("id");
  const groupName = searchParams.get("name");
  const eventId = searchParams.get("event_id")

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    {
      title: "Details",
      path: `/reservation/details?id=${paramsId}`,
    },
    { title: "Group With", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <GroupListingsHeader />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <GroupListingsTable groupName={groupName} eventId={eventId}/>
          </Content>

        </Layout>
      </Layout>
    </Layout>
  );
}
export default GroupListingsPage;
