import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEventApi,
  getAllEventsApi,
  getEventCoaBrandApi,
  getEventCommissionCalculationApi,
  getEventDefaultPayTermApi,
  getEventDestinationsApi,
  getEventMultiCurrencyApi,
  getEventPartnersApi,
  getEventPaymentProcessorApi,
  getEventShipApi,
  getEventStatusApi,
  getEventSuppliersApi,
  getEventTypeApi,
  getSingleEventApi,
  getAllAccommodationListApi,
  getAllCategoryListApi,
  getSingleAccommodationApi,
  getSingleCategoryApi,
  createAccommodationApi,
  createCategoryApi,
  updateAccommodationApi,
  updateCategoryApi,
  getAllInventoryListApi,
  eventDiscountListingApi,
  getDependentCategoriesApi,
  createOrUpdateCategoryApi,
  getAllEventsWrapperApi,
} from "../../../apis/eventsApi";
import { toSnakeCase } from "../../../utils/contentParser";

export const createOrEditEvent = createAsyncThunk(
  "events/createOrEditEvent",
  async (data) => {
    const response = await createEventApi(data);
    return response;
  }
);

export const getSingleEvent = createAsyncThunk(
  "events/getSingleEvent",
  async (uuid, { rejectWithValue }) => {
    const response = await getSingleEventApi(uuid);
    return response;
  }
);

// export const getAllEvents = createAsyncThunk(
//   "events/getAllEvents",
//   async (data) => {
//     const response = await getAllEventsApi(data);
//     return response;
//   }
// );

export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async (data) => {
    let urlQuery = new URLSearchParams(data).toString();
    let eventType =
      toSnakeCase(data?.tab) !== "all_events" ? toSnakeCase(data?.tab) : "";
    data?.sort_order === "recently_read" &&
      (urlQuery = `items_per_page=10&sort_order=recently_read&page=0${
        eventType ? `&event_type=${eventType}` : ""
      }`);
    const response = await getAllEventsWrapperApi(urlQuery);
    return response;
  }
);
export const getEventTypeList = createAsyncThunk(
  "events/getEventTypeList",
  async () => {
    const response = await getEventTypeApi();
    return response;
  }
);
export const getEventPartnersList = createAsyncThunk(
  "events/getEventPartnersList",
  async () => {
    const response = await getEventPartnersApi();
    return response;
  }
);
export const getEventSuppliersList = createAsyncThunk(
  "events/getEventSuppliersList",
  async () => {
    const response = await getEventSuppliersApi();
    return response;
  }
);
export const getEventDestinationsList = createAsyncThunk(
  "events/getEventDestinationsList",
  async () => {
    const response = await getEventDestinationsApi();
    return response;
  }
);
export const getEventShipList = createAsyncThunk(
  "events/getEventShipList",
  async () => {
    const response = await getEventShipApi();
    return response;
  }
);

export const getEventStatusList = createAsyncThunk(
  "events/getEventStatusList",
  async () => {
    const response = await getEventStatusApi();
    return response;
  }
);

export const getEventPaymentProcessorList = createAsyncThunk(
  "events/getEventPaymentProcessorList",
  async () => {
    const response = await getEventPaymentProcessorApi();
    return response;
  }
);

export const getEventMultiCurrencyList = createAsyncThunk(
  "events/getEventMultiCurrencyList",
  async () => {
    const response = await getEventMultiCurrencyApi();
    return response;
  }
);

export const getEventDefaultPayTerm = createAsyncThunk(
  "events/getEventDefaultPayTerm",
  async () => {
    const response = await getEventDefaultPayTermApi();
    return response;
  }
);

export const getEventCoaBrand = createAsyncThunk(
  "events/getEventCoaBrand",
  async () => {
    const response = await getEventCoaBrandApi();
    return response;
  }
);

export const getEventCommissionCalculation = createAsyncThunk(
  "events/getEventCommissionCalculation",
  async () => {
    const response = await getEventCommissionCalculationApi();
    return response;
  }
);
export const getAllAccommodationList = createAsyncThunk(
  "events/getAllAccommodationList",
  async () => {
    const response = await getAllAccommodationListApi();

    return response;
  }
);
export const getAllCategoryList = createAsyncThunk(
  "events/getAllCategoryList",
  async () => {
    const response = await getAllCategoryListApi();
    return response;
  }
);

export const createAccommodation = createAsyncThunk(
  "events/createAccommodation",
  async (data) => {
    const response = await createAccommodationApi(data);
    return response;
  }
);
export const createCategory = createAsyncThunk(
  "events/createCategory",
  async (data) => {
    const response = await createOrUpdateCategoryApi(data);
    return response;
  }
);

export const getSingleAccomodation = createAsyncThunk(
  "events/getSingleAccomodation",
  async (data, { rejectWithValue }) => {
    const response = await getSingleAccommodationApi(data);
    return response;
  }
);
export const updateAccommodation = createAsyncThunk(
  "events/updateAccommodation",
  async (data) => {
    const response = await updateAccommodationApi(data);
    return response;
  }
);
export const getAllInventroy = createAsyncThunk(
  "events/getAllInventroy",
  async (id) => {
    const response = await getAllInventoryListApi(id);
    return response;
  }
);
export const getAllEventDiscount = createAsyncThunk(
  "events/getAllEventDiscount",
  async (id) => {
    const response = await eventDiscountListingApi(id);
    return response;
  }
);

export const getSingleCategory = createAsyncThunk(
  "events/getSingleCategory",
  async (data, { rejectWithValue }) => {
    const response = await getSingleCategoryApi(data);
    return response;
  }
);

export const updateSingleCategory = createAsyncThunk(
  "events/updateSingleCategory",
  async (data) => {
    const response = await createOrUpdateCategoryApi(data);
    return response;
  }
);

export const getDependentCategories = createAsyncThunk(
  "events/getDependentCategories",
  async (data, { rejectWithValue }) => {
    const response = await getDependentCategoriesApi(data);
    return response;
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    eventCreation: {
      eventCreationResponse: {},
      createEventInsertId: "",
      loadingEventCreation: false,
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      pressedNextButton: false,
      eventStep: 0,
    },
    singleEvent: {
      singleEventResponse: {},
      loadingSingleEvent: false,
    },
    allEvents: {
      allEventsResponse: {},
      loadingAllEvents: false,
    },
    eventType: {
      eventTypeResponse: {},
      loadingEventType: false,
    },
    eventPartners: {
      eventPartnersResponse: {},
      loadingEventPartners: false,
    },
    eventSuppliers: {
      eventSuppliersResponse: {},
      loadingEventSuppliers: false,
    },
    eventDestinations: {
      eventDestinationsResponse: {},
      loadingEventDestinations: false,
    },
    eventShip: {
      eventShipResponse: {},
      loadingEventShip: false,
    },
    eventStatus: {
      eventStatusResponse: {},
      loadingEventStatus: false,
    },
    isEventStatusChanged: false,

    eventPaymentProcessor: {
      eventPaymentProcessorResponse: {},
      loadingEventPaymentProcessor: false,
    },
    eventMultiCurrency: {
      eventMultiCurrencyResponse: {},
      loadingEventMultiCurrency: false,
    },
    eventDefaultPayTerm: {
      eventDefaultPayTermResponse: {},
      loadingEventDefaultPayTerm: false,
    },
    eventCoaBrand: {
      eventCoaBrandResponse: {},
      loadingEventCoaBrand: false,
    },
    eventCommissionCalculation: {
      eventCommissionCalculationResponse: {},
      loadingEventCommissionCalculation: false,
    },
    allAccommodation: {
      allAccommodationResponse: {},
      loadingAllAccommodation: false,
    },
    allCategory: {
      allCategoryResponse: {},
      loadingAllCategory: false,
    },
    accomodationCreation: {
      accomodationCreationResponse: {},
      loadingAccomodationCreation: false,
    },
    singleAccommodation: {
      singleAccomodationResponse: {},
      loadingSingleAccomodation: false,
    },
    categoryCreation: {
      categoryCreationResponse: {},
      loadingCategoryCreation: false,
    },
    singleCategory: {
      singleCategoryResponse: {},
      loadingSingleCategory: true,
    },
    updateCategory: {
      updateCategoryResponse: {},
      loadingUpdateCategory: false,
    },
    dependentCategory: {
      dependentCategoryResponse: {},
      loadingDependentCategory: false,
    },
    accommodationUpdate: {
      accommodationUpdateResponse: {},
      loadingAccommodationUpdate: false,
    },
    categoryUpdate: {
      categoryUpdateResponse: {},
      loadingCategoryUpdate: false,
    },
    allInventoryList: {
      allInventoryListResponse: {},
      loadingAllInventoryList: false,
    },
    allEventDiscount: {
      allEventDiscountResponse: {},
      loadingAllEventDiscount: false,
    },
    isAssetUpdated: false,
    isAssetDeleted: false,
    isAccommodationOrCategoryCreated: false,
    isCategoryCloned: false,
    isInventoryUpdated: false,
    isFilterApplied: false,
    searchFilters: {},
    isImportEventTrigger: false,
  },
  reducers: {
    updateEventStep: (state, action) => {
      switch (action.payload) {
        case "1":
          state.eventCreation.step1 = "done";
          break;
        case "2":
          state.eventCreation.step2 = "done";
          break;
        case "3":
          state.eventCreation.step3 = "done";
          break;
        case "4":
          state.eventCreation.step4 = "done";
          break;
        default:
          state.eventCreation.step1 = "";
          state.eventCreation.step2 = "";
          state.eventCreation.step3 = "";
          state.eventCreation.step4 = "";
      }
    },
    pressEventNext: (state, action) => {
      action.payload === "next-button"
        ? (state.eventCreation.pressedNextButton = true)
        : (state.eventCreation.pressedNextButton = false);
    },
    eventStepSetter: (state, action) => {
      state.eventCreation.eventStep = action.payload;
    },
    changedEventStatus: (state, action) => {
      state.isEventStatusChanged = !state.isEventStatusChanged;
    },
    triggerIsAssetUpdated: (state, action) => {
      state.isAssetUpdated = !state.isAssetUpdated;
    },
    triggerIsAssetDeleted: (state, action) => {
      state.isAssetDeleted = !state.isAssetDeleted;
    },
    AccommodationOrCategoryCreated: (state, action) => {
      state.isAccommodationOrCategoryCreated =
        !state.isAccommodationOrCategoryCreated;
    },
    categoryCloned: (state, action) => {
      state.isCategoryCloned = !state.isCategoryCloned;
    },
    inventoryUpdated: (state, action) => {
      state.isInventoryUpdated = !state.isInventoryUpdated;
    },
    eventSearchFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    eventSearchFilterSetter: (state, action) => {
      state.searchFilters = action.payload;
    },
    importEventTrigger: (state, action) => {
      state.isImportEventTrigger = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createOrEditEvent.pending, (state) => {
      state.eventCreation.loadingEventCreation = true;
    });
    builder.addCase(createOrEditEvent.fulfilled, (state, action) => {
      state.eventCreation.eventCreationResponse = action.payload;
      state.eventCreation.loadingEventCreation = false;
    });
    builder.addCase(createOrEditEvent.rejected, (state, action) => {
      state.eventCreation.loadingEventCreation = false;
    });

    builder.addCase(getSingleEvent.pending, (state) => {
      state.singleEvent.loadingSingleEvent = true;
    });
    builder.addCase(getSingleEvent.fulfilled, (state, action) => {
      state.singleEvent.singleEventResponse = action.payload;
      state.singleEvent.loadingSingleEvent = false;
    });
    builder.addCase(getSingleEvent.rejected, (state, action) => {
      state.singleEvent.singleEventResponse = action.payload;
      state.singleEvent.loadingSingleEvent = false;
    });

    builder.addCase(getAllEvents.pending, (state) => {
      state.allEvents.loadingAllEvents = true;
    });
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.allEvents.allEventsResponse = action.payload;
      state.allEvents.loadingAllEvents = false;
    });
    //event type list
    builder.addCase(getEventTypeList.pending, (state) => {
      state.eventType.loadingEventType = true;
    });
    builder.addCase(getEventTypeList.fulfilled, (state, action) => {
      state.eventType.eventTypeResponse = action.payload;
      state.eventType.loadingEventType = false;
    });
    //event partners list
    builder.addCase(getEventPartnersList.pending, (state) => {
      state.eventPartners.loadingEventPartners = true;
    });
    builder.addCase(getEventPartnersList.fulfilled, (state, action) => {
      state.eventPartners.eventPartnersResponse = action.payload;
      state.eventPartners.loadingEventPartners = false;
    });
    //event partners list
    builder.addCase(getEventSuppliersList.pending, (state) => {
      state.eventSuppliers.loadingEventSuppliers = true;
    });
    builder.addCase(getEventSuppliersList.fulfilled, (state, action) => {
      state.eventSuppliers.loadingEventSuppliers = false;
      state.eventSuppliers.eventSuppliersResponse = action.payload;
    });
    //event destinations list
    builder.addCase(getEventDestinationsList.pending, (state) => {
      state.eventDestinations.loadingEventDestinations = true;
    });
    builder.addCase(getEventDestinationsList.fulfilled, (state, action) => {
      state.eventDestinations.eventDestinationsResponse = action.payload;
      state.eventDestinations.loadingEventDestinations = false;
    });
    //event ship list
    builder.addCase(getEventShipList.pending, (state) => {
      state.eventShip.loadingEventShip = true;
    });
    builder.addCase(getEventShipList.fulfilled, (state, action) => {
      state.eventShip.eventShipResponse = action.payload;
      state.eventShip.loadingEventShip = false;
    });
    //event status list
    builder.addCase(getEventStatusList.pending, (state) => {
      state.eventStatus.loadingEventStatus = true;
    });
    builder.addCase(getEventStatusList.fulfilled, (state, action) => {
      state.eventStatus.eventStatusResponse = action.payload;
      state.eventStatus.loadingEventStatus = false;
    });
    //event payment processor
    builder.addCase(getEventPaymentProcessorList.pending, (state) => {
      state.eventPaymentProcessor.loadingEventPaymentProcessor = true;
    });
    builder.addCase(getEventPaymentProcessorList.fulfilled, (state, action) => {
      state.eventPaymentProcessor.eventPaymentProcessorResponse =
        action.payload;
      state.eventPaymentProcessor.loadingEventPaymentProcessor = false;
    });
    //event multi currency
    builder.addCase(getEventMultiCurrencyList.pending, (state) => {
      state.eventMultiCurrency.loadingEventMultiCurrency = true;
    });
    builder.addCase(getEventMultiCurrencyList.fulfilled, (state, action) => {
      state.eventMultiCurrency.eventMultiCurrencyResponse = action.payload;
      state.eventMultiCurrency.loadingEventMultiCurrency = false;
    });
    //default pay term
    builder.addCase(getEventDefaultPayTerm.pending, (state) => {
      state.eventDefaultPayTerm.loadingEventDefaultPayTerm = true;
    });
    builder.addCase(getEventDefaultPayTerm.fulfilled, (state, action) => {
      state.eventDefaultPayTerm.eventDefaultPayTermResponse = action.payload;
      state.eventDefaultPayTerm.loadingEventDefaultPayTerm = false;
    });
    //event COA brand
    builder.addCase(getEventCoaBrand.pending, (state) => {
      state.eventCoaBrand.loadingEventCoaBrand = true;
    });
    builder.addCase(getEventCoaBrand.fulfilled, (state, action) => {
      state.eventCoaBrand.eventCoaBrandResponse = action.payload;
      state.eventCoaBrand.loadingEventCoaBrand = false;
    });
    //event commission calculation
    builder.addCase(getEventCommissionCalculation.pending, (state) => {
      state.eventCommissionCalculation.loadingEventCommissionCalculation = true;
    });
    builder.addCase(
      getEventCommissionCalculation.fulfilled,
      (state, action) => {
        state.eventCommissionCalculation.eventCommissionCalculationResponse =
          action.payload;
        state.eventCommissionCalculation.loadingEventCommissionCalculation = false;
      }
    );
    //Accommodation
    builder.addCase(getAllAccommodationList.pending, (state) => {
      state.allAccommodation.loadingAllAccommodation = true;
    });
    builder.addCase(getAllAccommodationList.fulfilled, (state, action) => {
      state.allAccommodation.allAccommodationResponse = action.payload;
      state.allAccommodation.loadingAllAccommodation = false;
    });
    builder.addCase(getAllCategoryList.pending, (state) => {
      state.allCategory.loadingAllCategory = true;
    });
    builder.addCase(getAllCategoryList.fulfilled, (state, action) => {
      state.allCategory.allCategoryResponse = action.payload;
      state.allCategory.loadingAllCategory = false;
    });
    builder.addCase(createAccommodation.pending, (state) => {
      state.accomodationCreation.loadingAccomodationCreation = true;
    });
    builder.addCase(createAccommodation.fulfilled, (state, action) => {
      state.accomodationCreation.accomodationCreationResponse = action.payload;
      state.accomodationCreation.loadingAccomodationCreation = false;
    });
    ////
    builder.addCase(getSingleAccomodation.pending, (state) => {
      state.singleAccommodation.loadingSingleAccomodation = true;
    });
    builder.addCase(getSingleAccomodation.fulfilled, (state, action) => {
      state.singleAccommodation.singleAccomodationResponse = action.payload;
      state.singleAccommodation.loadingSingleAccomodation = false;
    });
    ////
    builder.addCase(createCategory.pending, (state) => {
      state.categoryCreation.loadingCategoryCreation = true;
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.categoryCreation.categoryCreationResponse = action.payload;
      state.categoryCreation.loadingCategoryCreation = false;
    });
    builder.addCase(updateAccommodation.pending, (state) => {
      state.accommodationUpdate.loadingAccommodationUpdate = true;
    });
    builder.addCase(updateAccommodation.fulfilled, (state, action) => {
      state.accommodationUpdate.accommodationUpdateResponse = action.payload;
      state.accommodationUpdate.loadingAccommodationUpdate = false;
    });

    //Category detail inventory
    builder.addCase(getAllInventroy.pending, (state) => {
      state.allInventoryList.loadingAllInventoryList = true;
    });
    builder.addCase(getAllInventroy.fulfilled, (state, action) => {
      state.allInventoryList.allInventoryListResponse = action.payload;
      state.allInventoryList.loadingAllInventoryList = false;
    });
    //Event Discount
    builder.addCase(getAllEventDiscount.pending, (state) => {
      state.allEventDiscount.loadingAllEventDiscount = true;
    });
    builder.addCase(getAllEventDiscount.fulfilled, (state, action) => {
      state.allEventDiscount.allEventDiscountResponse = action.payload;
      state.allEventDiscount.loadingAllEventDiscount = false;
    });
    builder.addCase(getSingleCategory.pending, (state) => {
      state.singleCategory.loadingSingleCategory = true;
    });
    builder.addCase(getSingleCategory.fulfilled, (state, action) => {
      state.singleCategory.singleCategoryResponse = action.payload;
      state.singleCategory.loadingSingleCategory = false;
    });

    builder.addCase(updateSingleCategory.pending, (state) => {
      state.updateCategory.loadingUpdateCategory = true;
    });
    builder.addCase(updateSingleCategory.fulfilled, (state, action) => {
      state.updateCategory.updateCategoryResponse = action.payload;
      state.updateCategory.loadingUpdateCategory = false;
    });
    // getDependentCategories
    // dependentCategory: {
    //   dependentCategoryResponse: {},
    //   loadingDependentCategory: false,
    // },
    builder.addCase(getDependentCategories.pending, (state) => {
      state.dependentCategory.loadingDependentCategory = true;
    });
    builder.addCase(getDependentCategories.fulfilled, (state, action) => {
      state.dependentCategory.dependentCategoryResponse = action.payload;
      state.dependentCategory.loadingDependentCategory = false;
    });
  },
});
export const {
  updateEventStep,
  pressEventNext,
  eventStepSetter,
  changedEventStatus,
  triggerIsAssetUpdated,
  triggerIsAssetDeleted,
  AccommodationOrCategoryCreated,
  categoryCloned,
  inventoryUpdated,
  eventSearchFilterApplied,
  eventSearchFilterSetter,
  importEventTrigger,
} = eventsSlice.actions;
export default eventsSlice.reducer;
