import { Table, Button, Divider, Anchor } from 'antd';
import { PhoneOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from "react-router-dom";

function Res_Itineraries() {

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Count',
      dataIndex: 'count',
    },
    {
      title: 'First Created On',
      dataIndex: 'first_created',
    },
    {
      title: 'Last Created On',
      dataIndex: 'last_created',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },

  ];
  const data = [
    {
      key: '1',
      status: <>
        No Itineraries
      </>,
      count: '',
      first_created: '',
      last_created: '',
      action: '',
    },

  ];

  let locale = {
    emptyText: (
      <span>
        <div class="ant-empty-image">
          <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
        </div>

        <p className='span_text image_attach'>
          No Itineraries
        </p>
      </span>
    )
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Itineraries (0)</h4>
        <span>
          <Link to="/Reservation_editform">
            {" "}
            <Button type="primary"><PlusOutlined /> ADD</Button>
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />


      <Table className='request_table' locale={locale} columns={columns}
        // dataSource={data}

        pagination={false} showHeader={false} size="small" />

    </div>
  );
}
export default Res_Itineraries;