import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../url-routes/urlRoutes";

function CreateOrEditAddonFeeHeader({
  form,
  loadingFeeCreation,
  discountType,
  eventId,
  addonId,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const feeId = searchParams.get("editId");
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(
                discountType === "event"
                  ? `${EventDetailsRoute}?id=${eventId}#Fees`
                  : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
              )
            }
          />
          <div className="header_res">
            <p>{`${feeId ? "Edit" : "Add"} Fee`}</p>
            <p className="header_smalltext"></p>
          </div>
        </h3>

        <div className="breadcrum_buttons">
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={loadingFeeCreation}
            type="primary"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateOrEditAddonFeeHeader;
