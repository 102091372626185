import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import {
  contactDetailsEditLoader,
  updateMembershipInfo,
} from "../../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import "./MembershipForm.less";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const AddmembershipDrawer = ({
  programData,
  membershipProgram,
  contactId,
  open,
  setOpen,
  form,
  isLoading,
  editIndex,
  setEditIndex,
}) => {
  const dispatch = useDispatch();
  const { updateMembershipResponse, loadingUpdateMembership } = useSelector(
    (state) => state.contacts.updateMembership
  );
  const updateContactRef = useRef(false);
  const [programTypeList, setProgramTypeList] = useState([]);
  const [loadingProgramType, setLoadingProgramType] = useState(false);
  const [programNameList, setProgramNameList] = useState([]);
  const [loadingProgramName, setLoadingProgramName] = useState(false);
  const [statusLevelList, setStatusLevelList] = useState([]);
  const [loadingStatusLevel, setLoadingStatusLevel] = useState(false);
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  useEffect(() => {
    if (updateContactRef.current) {
      if (updateMembershipResponse.status) {
        const msg = "Membership Programs Updated";
        onClose();
        message.success(msg);
      } else {
        message.error(
          updateMembershipResponse?.data?.response?.data?.message ||
            updateMembershipResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        onClose();
      }
    }
    updateContactRef.current = true;
  }, [updateMembershipResponse]);

  useEffect(() => {
    if (open) {
      getTaxonomyList(
        "program_type",
        setProgramTypeList,
        setLoadingProgramType
      );
      getTaxonomyList(
        "program_name",
        setProgramNameList,
        setLoadingProgramName
      );
      getTaxonomyList(
        "status_level",
        setStatusLevelList,
        setLoadingStatusLevel
      );
    }
  }, [open]);

  const showDrawer = () => {
    setOpen(true);
    form.setFieldsValue({
      field_program_type: null,
      field_program_name: null,
      field_membership_number: null,
      field_status_level: null,
    });
  };

  const onClose = () => {
    setOpen(false);
    setEditIndex(null);
  };

  const onFinishHandler = (values) => {
    const isEveryValueMissing = Object.values(values).every((value) => {
      if (value === null || value === undefined || value === "") {
        return true;
      }
      return false;
    });
    const existingMembershipProgram = membershipProgram.map((membership) => ({
      field_program_type: membership?.field_program_type?.id || null,
      field_program_name: membership?.field_program_name?.id || null,
      field_membership_number: membership?.field_membership_number || null,
      field_status_level: membership?.field_status_level?.id || null,
    }));

    const editMembershipProgram = [...existingMembershipProgram];
    editMembershipProgram[editIndex] = {
      field_program_type: values.field_program_type,
      field_program_name: values.field_program_name,
      field_membership_number: values.field_membership_number,
      field_status_level: values.field_status_level,
    };
    const reqHeaders = customRequestHeader({ token: Token });
    const payload = {
      data: {
        type: "contact--contact",
        id: contactId,
        attributes: {
          field_membership_program:
            editIndex !== null
              ? editMembershipProgram
              : [
                  ...existingMembershipProgram,
                  {
                    field_program_type: values.field_program_type,
                    field_program_name: values.field_program_name,
                    field_membership_number: values.field_membership_number,
                    field_status_level: values.field_status_level,
                  },
                ],
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (!isEveryValueMissing) {
      dispatch(updateMembershipInfo({ payload, reqHeaders, contactId }));
    }
  };
  return (
    <>
      {permissionChecker("add/edit contact") ? (
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined />
          Add Membership Programs
        </Button>
      ) : null}

      <Drawer
        title={
          !!Object.keys(programData)?.length
            ? "Edit Membership Programs"
            : "Add Membership Programs"
        }
        maxWidth={425}
        onClose={onClose}
        maskClosable={false}
        open={open}
        bodyStyle={{ paddingBottom: 32 }}
        extra={
          <Space>
            <Button
              onClick={() => {
                dispatch(contactDetailsEditLoader(false));
                form.submit();
              }}
              loading={loadingUpdateMembership}
              type="primary"
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Program Type"
                name="field_program_type"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingProgramType}
                  options={programTypeList?.map((programType) => ({
                    key: programType.drupal_internal__tid,
                    value: programType.drupal_internal__tid,
                    label: programType.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Program Name"
                name="field_program_name"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingProgramName}
                  options={programNameList?.map((programName) => ({
                    key: programName.drupal_internal__tid,
                    value: programName.drupal_internal__tid,
                    label: programName.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Membership Number"
                name="field_membership_number"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Status Level"
                name="field_status_level"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingStatusLevel}
                  options={statusLevelList.map((statusLevel) => ({
                    key: statusLevel.drupal_internal__tid,
                    value: statusLevel.drupal_internal__tid,
                    label: statusLevel.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AddmembershipDrawer;
