import { Country } from "country-state-city";

export function customCountriesList() {
  //this function put US on top  
  let countries = Country.getAllCountries();
  const indexOfUsa = countries.findIndex((country) => country.isoCode === "US");
  const removedItem = countries.splice(indexOfUsa, 1);
  countries.unshift(removedItem[0]);
  countries = countries.map(val=>{
    if(val.name.toLowerCase() === "united states"){
      val.name = "USA"
    }
    return val
  })
  return countries;
}
