import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Col, Row, Collapse, TimePicker, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Radio,
  Divider,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from "antd";

import moment from "moment";
import { getReservationItemDetailApi } from "../../../../apis/reservationApi";
import { useSearchParams } from "react-router-dom";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

function Itemdetailsedit_form(props) {
  const { data, form } = props;
  const [value, setValue] = useState("");

  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get("id");
  const itemId = searchParams.get("item-id");

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        item_code: data?.reservation_item_inventory?.field_item_number,
        item_name: data?.reservation_item_inventory?.field_item_name,
        item_status: data?.reservation_item?.field_itemstatus_label,
        field_cruise_bookno: data?.reservation_item?.field_cruise_bookno,
        field_comments: data?.reservation_item?.field_comments?.[0]?.value,
        field_comment_id: data?.reservation_item?.field_comments[0]?.value
      });
    }
  }, [data]);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const { Panel } = Collapse;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <>
      <div className="partnership_inform">
        <h4>Item Details</h4>
      </div>
      <Divider className="global_divider" />
      <div className="addnote_form">
        <div>
          <Form
            {...layout}
            form={form}
            labelCol={{
              sm: 24,
              md: 6,
              lg: 6,
              xl: 4,
              xxl: 4,
            }}
            wrapperCol={{
              sm: 24,
              md: 14,
              lg: 14,
              xl: 14,
              xxl: 12,
            }}
            layout="horizontal"
          >
            <Form.Item label="Item Code" name="item_code">
              <Input />
            </Form.Item>

            <Form.Item label="Item Name" name="item_name">
              <Input />
            </Form.Item>

            <Form.Item label="Commission Staff" name="commission_staff">
              <Select 
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                <Select.Option value="1443" label="Aaron Phanco">Aaron Phanco</Select.Option>
                <Select.Option value="1432" label="Alexis Kalugin">Alexis Kalugin</Select.Option>
                <Select.Option value="1351" label="Alicia Green">Alicia Green</Select.Option>
                <Select.Option value="1358" label="Andrea Torres">Andrea Torres</Select.Option>
                <Select.Option value="1398" label="Arpee Caron">Arpee Caron</Select.Option>
                <Select.Option value="1131" label="Charlie Spencer">Charlie Spencer</Select.Option>
                <Select.Option value="1405" label="Charlotte Russ">Charlotte Russ</Select.Option>
                <Select.Option value="1422" label="Ciara Haywood">Ciara Haywood</Select.Option>
                <Select.Option value="1356" label="Darlene Jones">Darlene Jones</Select.Option>
                <Select.Option value="1385" label="Deanna Noell">Deanna Noell</Select.Option>
                <Select.Option value="1129" label="DeeDee Bauman">DeeDee Bauman</Select.Option>
                <Select.Option value="1321" label="Diana Rangel">Diana Rangel</Select.Option>
                <Select.Option value="1415" label="Dylan Vasquez">Dylan Vasquez</Select.Option>
                <Select.Option value="1388" label="Elsa Orozco">Elsa Orozco</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Item Status" name="item_status">
              <Select
                disabled
                showSearch
                showArrow
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={[
                  { value: "reserved", label: "Reserved" },
                  { value: "cancelled", label: "Canceled" },
                  { value: "cancelled_pending", label: "Canceled Pending" },
                  { value: "pending", label: "Pending" },
                ]}
              />
            </Form.Item>

            <Form.Item label="Cruise Booking Number" name="field_cruise_bookno">
              <Input />
            </Form.Item>

            {!['purchased',"quote"].includes(data?.reservation_item?.field_itemstatus)?  
                <Form.Item label="Comments" name="field_comments">
                  <TextArea rows={3} />
                </Form.Item>
                 : null
            }

            {data?.reservation_item?.field_itemstatus==='purchased' && (
              <Form.Item
                label="Confirmation Number"
                name="field_comment_id"
              >
                <Input  disabled />
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </>
  );
}

export default Itemdetailsedit_form;
