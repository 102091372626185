import { Col, Divider, Form, Row, Spin } from "antd";
import React from "react";
import { addCommasInPricing } from "../../../../../../utils/contentParser";
import moment from "moment";
import { paymentMethodFilter } from "../../../../../../utils/paymentMethodFilter";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function PaymentBasicDetail({ paymentData, loadingPaymentData }) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Payment Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingPaymentData} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Payment Amount" className="asterick-align" name="payment_amount">
                <label>
                  {paymentData?.field_paymentamount
                    ? `$${addCommasInPricing(
                      paymentData?.field_paymentamount.toString()
                    )}`
                    : ""}
                </label>
              </Form.Item>
              <Form.Item label="Payment Method" className="asterick-align" name="payment_method">
                <label>{paymentMethodFilter(paymentData?.field_paymentmethod)  || null}</label>
              </Form.Item>
              <Form.Item label="Payment Source" className="asterick-align" name="payment_source">
                <label>{paymentData?.field_paymentsource?.label || null}</label>
              </Form.Item>
              <Form.Item label="Comment" className="asterick-align" name="comment">
                <label>{paymentData?.field_comment || null}</label>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Payment Date" className="asterick-align" name="payment_date">
                <label>
                  {paymentData?.field_paymentdate
                    ? moment(
                      paymentData?.field_paymentdate,
                      "YYYY-MM-DD"
                    ).format("MM/DD/YYYY")
                    : ""}{" "}
                  {paymentData?.created
                    ? moment(
                      paymentData?.created,
                      "YYYY-MM-DDTHH:mm:ssZ"
                    ).fromNow()
                    : ""}
                </label>
              </Form.Item>
              <Form.Item label="Payment Status" className="asterick-align" name="payment_status">
                <label>{paymentData?.field_payment_status?.label || ""}</label>
              </Form.Item>
              <Form.Item label="Auth Info" className="asterick-align" name="auth_info">
                <label>{paymentData?.field_authinfo || ""}</label>
              </Form.Item>
              <Form.Item className="asterick-align full_response asterick-align" label="Full Response Info" name="full_response_info">
                <label>{paymentData?.field_fullresponse?.value || ""}</label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default PaymentBasicDetail;
