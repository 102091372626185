import { Table, Button, Divider, Switch, Tooltip } from "antd";
import React from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const ExtensionMisc = ({ extensionData, extensionLoading }) => {
  const {
    field_miscellaneous_notes,
    field_leader_attending_ext,
    field_touring_the_day_they_arrive,
    field_meeting_space,
  } = extensionData || {};

  const data = [
    {
      key: "1",
      label: "Meeting Space",
      input: <>{field_meeting_space || ""}</>,
    },

    {
      key: "2",
      label: "Touring The Day They Arrive",
      // input: <>{field_touring_the_day_they_arrive ? "Yes" : "NO"}</>,
      input: (
        <>
          <Tooltip title={field_touring_the_day_they_arrive ? "Yes" : "No"}>
            <Switch checked={field_touring_the_day_they_arrive} disabled />
          </Tooltip>
        </>
      ),
    },

    {
      key: "3",
      label: "Notes",
      input: <>{field_miscellaneous_notes || ""}</>,
    },
    {
      key: "4",
      label: "Leader attending Ext",
      input: <>{field_leader_attending_ext || ""}</>,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Misc</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: extensionLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default ExtensionMisc;
