import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message, Modal } from "antd";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import ViewplannedHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentDetail/PannedPaymentDetailHeader";
import ViewplannedPayment from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentDetail/PlannedPaymentBasicDetail";
import PaymentsApplied from "../../../components/PaymentStatus/ViewPlanned/PaymentsApplied";
import PaymentsystemInformation from "../../../components/PaymentStatus/ViewPlanned/PaymentsystemInformation";
import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getPlannedPaymentDetailApi,
  getPlannedPaymentTransactionListApi,
} from "../../../apis/reservationApi";
import PannedPaymentDetailHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentDetail/PannedPaymentDetailHeader";
import PlannedPaymentBasicDetail from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentDetail/PlannedPaymentBasicDetail";
import SystemInformation from "../../../others/CommonSystemInformation/SystemInformation";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import TransationsDetail from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/TransationsDetail/TransationsDetail";
const { Header, Content, Sider } = Layout;

function PlannedPaymentDetailPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("id");
  const paymentId = searchParams.get("paymentId");
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [paymentData, setPaymentData] = useState({});
  const [loadingPaymentData, setLoadingPaymentData] = useState(false);
  const [unpaidPayment, setUnpaidPayment] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [paymentDataTrigger, setPaymentDataTrigger] = useState(false);

  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Details", path: `${ReservationDetailRoute}?id=${reservationId}` },
    // {
    //   title: "Planned Payment",
    //   path: `${ReservationDetailRoute}?id=${reservationId}`,
    // },
    { title: "Planned Payment Detail", path: "" },
  ];

  useEffect(() => {
    getPaymentDetails(paymentId);
  }, [paymentDataTrigger]);

  useEffect(() => {
    if (paymentData?.field_paymenttype?.name === "refund") {
      getTransactionsList({
        reservationUuid: paymentData?.field_reservation?.uuid,
        travelerUuid: paymentData?.field_traveler?.uuid,
      });
    }
  }, [paymentData]);
  const handleOk = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await drupalAxiosRequest(
        "delete",
        `/api/v1/add_planned_payment/add_planned_payment/${paymentId}`,
        customRequestHeader({ defaultFormat: true })
      );
      if (response.status) {
        message.success("Planned payment deleted successfully");
        navigate(`/reservation/details?id=${reservationId}`);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancel = () => {
    setDeleteModalOpen(false);
  };

  const getTransactionsList = async ({ reservationUuid, travelerUuid }) => {
    setLoadingTransaction(true);
    const res = await getPlannedPaymentTransactionListApi({
      reservationUuid,
      travelerUuid,
    });
    if (res.status) {
      setTransactionList(res?.data?.data);
      setLoadingTransaction(false);
    } else {
      setTransactionList([]);
      setLoadingTransaction(false);
    }
  };
  const getPaymentDetails = async (paymentId) => {
    setLoadingPaymentData(true);
    const res = await getPlannedPaymentDetailApi(paymentId);
    if (res.status) {
      setPaymentData(res?.data?.data);
      setLoadingPaymentData(false);
    } else {
      setPaymentData({});
      setLoadingPaymentData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <PannedPaymentDetailHeader
              paymentData={paymentData}
              transactionList={transactionList}
              unpaidPayment={unpaidPayment}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
              setDeleteModalOpen={setDeleteModalOpen}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <PlannedPaymentBasicDetail
              paymentData={paymentData}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
            />
          </Content>
          {paymentData?.field_paymenttype?.name === "refund" ? (
            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <TransationsDetail
                paymentData={paymentData}
                transactionList={transactionList}
                loadingTransaction={loadingTransaction}
                loadingPaymentData={loadingPaymentData}
                reservationId={reservationId}
                setLoadingTransaction={setLoadingTransaction}
                setPaymentDataTrigger={setPaymentDataTrigger}
                paymentDataTrigger={paymentDataTrigger}
              />
            </Content>
          ) : null}

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <PaymentsApplied
              paymentData={paymentData}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
              setUnpaidPayment={setUnpaidPayment}
              paymentDataTrigger={paymentDataTrigger}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <SystemInformation
              user_id={paymentData?.user_id || {}}
              modified_by={paymentData?.field_last_modified_by || {}}
              created={paymentData?.created}
              changed={paymentData?.changed}
              loading={loadingPaymentData}
            />
          </Content>
          <Modal
            title="Confirmation"
            open={deleteModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to delete this planned payment?</p>
          </Modal>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PlannedPaymentDetailPage;
