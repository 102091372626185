import { Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

function TypographyGroup() {
    return (
        <>
        <Title>h1. This is Heading 1</Title>
        <Title level={2}>h2. Heading 1</Title>
        <Title level={3}>h3. Ant Design</Title>
        <Title level={4}>h4. Ant Design</Title>
        <Title level={5}>h5. Ant Design</Title>
      </>
    );
}
 


export default TypographyGroup;