export const Navigation = [
  "view navigation menu dashboard view all button",
  "view navigation menu organization",
  "view navigation menu contacts",
  "view navigation menu group details",
  "view navigation menu events",
  "view navigation menu reservations",
  "view navigation menu all reports",
  "view navigation menu elite travelers",
  "view navigation menu email templates",
  "view navigation menu email monitor",
  "view navigation menu custom dropdown",
  "view navigation menu manage roles",
  "view navigation menu manage users",
  "view navigation menu marketing sources",
];

export const Contacts = [
  "add/edit contact",
  "import contact",
  "view contact",
  "delete contact",
  "find duplicates",
  "copy contact",
  "view reservations",
  "add/edit reservation",
  "view events",
  "add task activity",
  "view published activity entities",
  "edit task details and message",
  "add activity entities",
  "add/edit travel history",
  "merge contact",
  "view task document",
  "add task document",
  "delete task document",
  "add/edit request",
  "delete request",
];

export const Organization = [
  "add/edit organization",
  "delete organization",
  "view organizations",
  "view contact",
];

export const Event = [
  "add/edit event",
  "view events",
  "delete event",
  "sync event",
  "export event",
  "import event",
  "copy event",
  // "run event report",
  "add category",
  "view published category entities",
  "copy category",
  "delete category",
  "add/edit event fee",
  "view published fees entities",
  "delete fees entities",
  "copy event fees",
  "add/edit event discount",
  "view published discounts entities",
  "delete discounts entities",
  "copy event discounts",
  // "add/edit event document",
  "add accommodation type entities",
  "view published accommodation type entities",
  "delete accommodation type entities",
  "add/edit addon",
  "view addon",
  "copy addon",
  "delete addon",
  "add/edit event descriptions",
  "delete event descriptions",
  "add/edit event assets",
  "view event assets",
  "delete event assets",
  "delete event itineraries",
  "add/edit event itinerary",
];

export const Admin = [
  "edit custom dropdown",
  "add/edit role",
  "delete role",
  "view role",
  "add/edit user",
  "edit email templates",
  "edit marketing sources",
  "delete marketing sources",
];

export const Reservations = [
  "add/edit reservation",
  "view reservations",
  "view contact",
  "view events",
  "modify payments",
];

export const Group = [
  "add/edit group details",
  "view group details",
  "delete group details",
  "convert to events",
  "view events",
];
