import React from "react";
import "./reports.css";
import { Breadcrumb, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { QUERY_BUILDER_URL } from "../../../utils/constants";
import queryConfig from "../../../config/serverless/query.config";

const CreateReports = () => {
  const navigate = useNavigate();
  const searchedUrl = new URLSearchParams(window.location.search).get("query");
  const handleMessage = (event) => {
    // if (event.origin !== "http://localhost:4000") return;
    if (event.origin !== queryConfig.BASE_URL) return;
    if (event.data) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("query", event.data.elements); // Set the value for the "query" parameter
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  };
  window.addEventListener("message", handleMessage);
  return (
    <>
      <div className="report_page" style={{ width: "100%", height: "100vh" }}>
        <div className="breadcrumb_content report_breadcrumb">
          <Breadcrumb className="report_heading">
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create Report</Breadcrumb.Item>
          </Breadcrumb>
          <div className="create-report">
            <div
              className="breadcrumb_heading create-report__padding"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3 className="flex_row_column">
                <CloseOutlined
                  className="icon_font"
                  onClick={() => {
                    navigate("/reports");
                  }}
                />{" "}
                <div className="header_res">
                  <p style={{ paddingLeft: "8px" }}>Create Report</p>
                </div>
              </h3>

              {/* <div
                className="breadcrum_buttons"
                style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "end",
                }}
              > */}
              {/* <Button type="primary">Save</Button>
                <Button>Cancel</Button> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <iframe
          id="iframe"
          title="reports"
          allow="true"
          src={`${QUERY_BUILDER_URL}/index${
            searchedUrl ? `?query=${searchedUrl}` : ""
          }`}
          height={"100%"}
          width={"100%"}
          // sandbox="allow-same-origin allow-scripts allow-popups"
        />
      </div>
    </>
  );
};

export default CreateReports;
