import { Grid, Layout, message } from "antd";
// import "./CategoryDetailsPage.less";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CategoryAvailablity from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Availalbility/CategoryAvailablity";
import CategoryInformation from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/CategoryInformation";
import HeaderCategoryDetail from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/HeaderCategoryDetail";
import Pricing from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Pricing/Pricing";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import Discounts from "../../../../../components/Events_components/Category/Discounts/Discounts";
import Fees from "../../../../../components/Events_components/Category/Fees/Fees";
import { getSingleCategory } from "../../../../../redux/features/eventFeatures/eventsSlice";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import Inventory from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/Inventory";
import DependentCategory from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/DependentCategory/DependentCategory";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const { Header, Content, Sider } = Layout;

const AddonCategoryDetailsPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const categoryId = searchParams.get("categoryId");
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `/event/details?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    {
      title: "Category Detail",
      path: "",
    },
  ];

  const getSingleCategoryRef = useRef(false);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const { isAccommodationOrCategoryCreated, singleCategory, isCategoryCloned } =
    useSelector((state) => state.events);
  const { singleCategoryResponse, loadingSingleCategory } = singleCategory;

  useEffect(() => {
    if (getSingleCategoryRef?.current) {
      if (singleCategoryResponse?.status) {
        setCategoryDetails(singleCategoryResponse?.data?.data || {});
      } else {
        message.error(
          singleCategoryResponse?.error?.response?.data?.errors?.[0]?.title ||
            singleCategoryResponse?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    getSingleCategoryRef.current = true;
  }, [singleCategoryResponse]);

  useEffect(() => {
    dispatch(getSingleCategory(categoryId));
  }, [isAccommodationOrCategoryCreated, isCategoryCloned, categoryId]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <HeaderCategoryDetail
              eventId={eventId}
              addonId={addonId}
              categoryDetails={categoryDetails}
              loadingSingleCategory={loadingSingleCategory}
              categoryId={categoryId}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CategoryInformation
              eventId={eventId}
              addonId={addonId}
              categoryDetails={categoryDetails}
              loadingSingleCategory={loadingSingleCategory}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CategoryAvailablity
              eventId={eventId}
              addonId={addonId}
              loadingSingleCategory={loadingSingleCategory}
            />
          </Content>

          <Content
            className="site-layout-background category_pricing"
            style={{ marginBottom: "20px" }}
          >
            <Pricing
              eventId={eventId}
              categoryId={categoryId}
              addonId={addonId}
              categoryDetails={categoryDetails}
              loadingSingleCategory={loadingSingleCategory}
            />
          </Content>

          <Content
            className="site-layout-background category_pricing"
            style={{ marginBottom: "20px" }}
          >
            <Inventory
              eventId={eventId}
              addonId={addonId}
              loadingSingleCategory={loadingSingleCategory}
            />
          </Content>

          <Content
            className="site-layout-background category_pricing"
            style={{ marginBottom: "20px" }}
          >
            <Discounts eventId={eventId} addonId={addonId} />
          </Content>

          <Content
            className="site-layout-background category_pricing"
            style={{ marginBottom: "20px" }}
          >
            <Fees eventId={eventId} addonId={addonId} />
          </Content>
          <Content
            className="site-layout-background category_pricing"
            style={{ marginBottom: "20px" }}
          >
            <DependentCategory eventId={eventId} addonId={addonId} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonCategoryDetailsPage;
