import { Form, Grid, Layout } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import CreateContactHeader from "../../components/Contacts/CreateContacts/CreateContactHeader";
import CreateContactSteps from "../../components/Contacts/CreateContacts/CreateContactSteps";
import "./CreateContactPage.less";
const { Header, Content, Sider } = Layout;

const CreateContactPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const editId = searchParams.get("editId");
  const createId = searchParams.get("creationId");
  const copyId = searchParams.get("copyId");
  const primaryContactOrgId = searchParams.get("primaryContactOrgId");
  const orgId = searchParams.get("orgId");

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    { title: editId ? "Edit Contact" : "Add Contact", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateContactHeader
              editId={editId}
              createId={createId}
              primaryContactOrgId={primaryContactOrgId}
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              orgId={orgId}
            />
          </div>
          <Content className="site-layout-background">
            <CreateContactSteps
              editId={editId}
              createId={createId}
              primaryContactOrgId={primaryContactOrgId}
              copyId={copyId}
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              orgId={orgId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CreateContactPage;
