import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { changeTabState } from "../../../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import { GroupDetails_DetailsRoute } from "../../../../url-routes/urlRoutes";

const CreateOrEditTourHeader = ({
  form1,
  form2,
  form3,
  editId,
  createId,
  preExtId,
  postExtId,
  type,
}) => {
  const [currentTab, setCurrentTab] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [headerName, setHeaderName] = useState("");
  const { groupDetailsCreation, groupDetailsExtensionCreation } = useSelector(
    (state) => state.groupDetails
  );
  const { loadingGroupDetailsCreation } = groupDetailsCreation;
  const { loadingGroupDetailsExtensionCreation } =
    groupDetailsExtensionCreation;

  useEffect(() => {
    let tab = location.hash ? decodeURI(location.hash).slice(1) : "Tour";
    setCurrentTab(tab);
    tabHeader(editId, preExtId, postExtId, tab);
  }, [location.hash]);



  const tabHeader = (eId, preId, postId, tab) => {
    if (location.hash) {
      if (tab === type) {
        eId ? setHeaderName(`Edit ${type}`) : setHeaderName(`Add ${type}`);
      }
      if (tab === "Pre-Extension") {
        eId && preId
          ? setHeaderName("Edit Pre-Extension")
          : setHeaderName("Add Pre-Extension");
      }
      if (tab === "Post-Extension") {
        eId && postId
          ? setHeaderName("Edit Post-Extension")
          : setHeaderName("Add Post-Extension");
      }
    } else {
      setHeaderName(`Add ${type}`);
    }
  };
  const onSaveHandler = () => {
    if (currentTab === type) {
      dispatch(changeTabState("main-tab"));
      form1.submit();
    }
    if (currentTab === "Pre-Extension") {
      dispatch(changeTabState("pre-tab"));
      form2.submit();
    }
    if (currentTab === "Post-Extension") {
      dispatch(changeTabState("post-tab"));
      form3.submit();
    }
  };

  const onCancelHandler = () => {
    let route =
      createId || editId || preExtId || postExtId
        ? routeToDetailsPage(createId || editId, preExtId, postExtId)
        : -1;
    navigate(route);
  };

  const routeToDetailsPage = (groupDetailId, preId, postId) => {
    return `${GroupDetails_DetailsRoute}?type=${type}${
      groupDetailId ? `&id=${groupDetailId}` : ""
    }${preId ? `&preExtId=${preExtId}` : ""}${
      postId ? `&postExtId=${postExtId}` : ""
    }#${type}`;
  };
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="addnote_content">
          <div className="breadcrumb_title">
            <h3 className="flex_row_column">
              <ArrowLeftOutlined
                className="back-icon-profile"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              {headerName}
            </h3>
          </div>
        </div>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSaveHandler();
            }}
            loading={
              loadingGroupDetailsCreation ||
              loadingGroupDetailsExtensionCreation
            }
          >
            Save & Exit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrEditTourHeader;
