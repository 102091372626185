import { Table, Button, Divider } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getFinancialInfoApi } from "../../../../apis/reservationApi";
import { addCommasInPricing } from "../../../../utils/contentParser";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../utils/constants";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function ReservationsFinancialInfo({ reservationId }) {
  const [financialData, setFinancialData] = useState({});
  const [loadingFinancialData, setLoadingFinancialData] = useState(false);
  const { isPayeeTrigger } = useSelector((state) => state.reservation);
  const data = [
    {
      key: "financial_status",
      label: "Financial Status",
      input: financialData?.financial_status || null,
    },
    {
      key: "total_amount",
      label: "Total Amount",
      input: `$${addCommasInPricing(
        financialData?.total_amount?.toString() || 0
      )}`,
    },
    {
      key: "total_paid_amount",
      label: "Total Paid Amount",
      input: `$${addCommasInPricing(
        financialData?.total_paid_amount?.toString() || 0
      )}`,
    },
    {
      key: "balance_amount",
      label: "Balance Amount",
      input: `$${addCommasInPricing(
        financialData?.balance_amount?.toString() || 0
      )}`,
    },

    {
      key: "item_amount",
      label: "Item Amount",
      input: `$${addCommasInPricing(
        financialData?.item_amount?.toString() || 0
      )}`,
    },
    {
      key: "discount_amount",
      label: "Discount Amount",
      input: `$${addCommasInPricing(
        financialData?.total_discount_amount?.toString() || 0
      )}`,
    },
    {
      key: "fee_amount",
      label: "Fee Amount",
      input: `$${addCommasInPricing(
        financialData?.total_fees_amount?.toString() || 0
      )}`,
    },
  ];

  useEffect(() => {
    getFinancialData(reservationId);
  }, [isPayeeTrigger]);

  const getFinancialData = async (resId) => {
    setLoadingFinancialData(true);
    const res = await getFinancialInfoApi(resId);
    if (res.status) {
      setFinancialData(res?.data?.data);
      setLoadingFinancialData(false);
    } else {
      setFinancialData({});
      setLoadingFinancialData(false);
    }
  };

  if (financialData?.confirm_time) {
    data.push({
      key: "confirm_time",
      label: "Confirm Time",
      input: financialData?.confirm_time ? (
        <>
          <span>
            {moment.unix(financialData?.confirm_time).format(DATE_FORMAT)}{" "}
            {moment.unix(financialData?.confirm_time).format(TIME_FORMAT)}
          </span>{" "}
          <span className="span_text">
            {moment.unix(financialData?.confirm_time).fromNow()}
          </span>
        </>
      ) : null,
    });
  }
  if (financialData?.reinstate_time) {
    data.push({
      key: "reinstate_time",
      label: "Reinstate Time",
      input: financialData?.reinstate_time ? (
        <>
          <span>
            {moment.unix(financialData?.reinstate_time).format(DATE_FORMAT)}{" "}
            {moment.unix(financialData?.reinstate_time).format(TIME_FORMAT)}
          </span>{" "}
          <span className="span_text">
            {moment.unix(financialData?.reinstate_time).fromNow()}
          </span>
        </>
      ) : null,
    });
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Financial Information</h4>
        {/* <span>
          <Link to="/">
            {" "}
            <Button type="primary"><PlusOutlined /> ADD</Button>
          </Link>
        </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        loading={{ spinning: loadingFinancialData, size: "large" }}
        size="small"
      />
    </div>
  );
}

export default ReservationsFinancialInfo;
