import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Divider,
} from "antd";

function onChange(time, timeString) {
    console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function CruisePostform_seven() {
    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);

    useEffect(() => {
        form.validateFields(["nickname"]);
    }, [checkNick, form]);

    const onCheckboxChange = (e) => {
        setCheckNick(e.target.checked);
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log("Success:", values);
        } catch (errorInfo) {
            console.log("Failed:", errorInfo);
        }
    };

    const { Panel } = Collapse;

    const onChange = (key) => {
        console.log(key);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <div className="">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <h4 classname="mb-0">Miscellaneous</h4>

            </div>
            <Divider className="global_divider mb-0" />



            <div style={{ marginTop: "24px" }} />
            <Form
                {...layout}
                labelCol={{
                    sm: 24,
                    md: 6,
                    lg: 6,
                    xl: 5,
                    xxl: 4,
                }}
                wrapperCol={{
                    sm: 24,
                    md: 14,
                    lg: 14,
                    xl: 14,
                    xxl: 12,
                }}
                layout="horizontal"
            >

                <Form.Item label="Meeting Space" name="Meeting Space">
                    <Input />
                </Form.Item>

                <Form.Item label="Touring The Day They Arrive">
                    <Switch onChange={onChange} />
                </Form.Item>


                <div className="textarea_row">
                    <Form.Item label="Notes" name="Meeting Space">
                        <TextArea rows={4} />
                    </Form.Item>
                </div>

                <Form.Item label="Leader attending Ext" name="leaderattending">
                    <Input />
                </Form.Item>
                {/* 
                <Form.Item label="Optional Post Departure Ins Included">
                    <Switch onChange={onChange} />
                </Form.Item>
                 */}
            </Form>
        </div>




    );
}

export default CruisePostform_seven;
