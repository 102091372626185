import React, { useEffect, useState } from "react";
import { Layout, Menu, Button, Tooltip } from "antd";
import {
  ApartmentOutlined,
  FundProjectionScreenOutlined,
  FileDoneOutlined,
  SettingOutlined,
  IdcardOutlined,
  FormOutlined,
  PlusOutlined,
  AppstoreOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import "./Header_sidebar.less";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assests/images/logo.svg";
import {
  AdminEmailMonitorRoute,
  EventRoute,
  EventsListingRoute,
  ReportListingRoute,
} from "../../url-routes/urlRoutes";
import ReservationCreationModal from "../Reservation/CreateOrEditReservation/ReservationCreationModal";
import { permissionChecker } from "../../utils/permisssionChecker";
const { SubMenu } = Menu;

function Sidebar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("home");
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddContact = authData?.permissions?.includes("add/edit contact");
  const allowAddOrganization = authData?.permissions?.includes(
    "add/edit organization"
  );
  const allowAddEvent = authData?.permissions?.includes("add/edit event");
  const allowAddReservation = authData?.permissions?.includes(
    "add/edit reservation"
  );
  const location = useLocation();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const menuClickHandler = (e) => {
    setSelectedMenu(e.key);
  };
  useEffect(() => {
    let selectedMenuTab = location.pathname.slice(1).split("/")[0];
    if (selectedMenuTab) {
      setSelectedMenu(selectedMenuTab);
    } else {
      setSelectedMenu("home");
    }

    if (["reports", "elite-travellers"].includes(selectedMenuTab)) {
      setOpenMenu(
        openMenu.includes("admin") ? ["admin", "reports"] : ["reports"]
      );
    }
    if (
      [
        "email_templates",
        "email_monitor",
        "custom-dropdown",
        "manage-roles",
        "manage-users",
        "marketing-sources",
      ].includes(selectedMenuTab)
    ) {
      setOpenMenu(
        openMenu.includes("reports") ? ["admin", "reports"] : ["admin"]
      );
    }
  }, [location.pathname]);

  const openMenuToggler = ({ key }) => {
    let newMenu = [...openMenu];
    if (newMenu.includes(key)) {
      newMenu.splice(newMenu.indexOf(key), 1);
      setOpenMenu(newMenu);
    } else {
      newMenu.push(key);
      setOpenMenu(newMenu);
    }
  };
  return (
    <>
      <div className="sidebar-logo">
        <Link
          style={{
            marginBottom: "26px",
            textAlign: "center",
            display: "block",
            width: "210px",
          }}
          to="/"
          onClick={() => {
            setSelectedMenu("home");
          }}
        >
          <img src={Logo} alt="website logo" className="main_logo" />
        </Link>
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selectedMenu]}
        onClick={menuClickHandler}
        openKeys={openMenu}
      >
        <Menu.Item
          key="home"
          icon={<AppstoreOutlined />}
          className="menus_background"
        >
          {" "}
          <Link className="color_link" to="/">
            Dashboard
          </Link>
        </Menu.Item>

        {permissionChecker("view navigation menu organization") ? (
          <Menu.Item
            key="organization"
            icon={<ApartmentOutlined />}
            className="menus_background"
          >
            <div className="menu_flex">
              <Link className="color_link" to="/organization/listing">
                Organizations
              </Link>

              {allowAddOrganization ? (
                <Tooltip
                  title={
                    <>
                      <span className="tooltip_bold">Add Organization</span>
                    </>
                  }
                >
                  <Button
                    type="y"
                    icon={
                      <Link to="/organization">
                        <PlusOutlined />
                      </Link>
                    }
                    className="headersidebar_button"
                  />
                </Tooltip>
              ) : null}
            </div>
          </Menu.Item>
        ) : null}

        {permissionChecker("view navigation menu contacts") ? (
          <Menu.Item
            key="contact"
            icon={<IdcardOutlined />}
            className="menus_background"
          >
            <div className="menu_flex">
              <Link className="color_link" to="/contact/listing">
                Contacts
              </Link>

              {allowAddContact ? (
                <Tooltip
                  title={
                    <>
                      <span className="tooltip_bold">Add Contact</span>
                    </>
                  }
                >
                  <Button
                    type="y"
                    icon={
                      <Link to="/contact">
                        <PlusOutlined />
                      </Link>
                    }
                    className="headersidebar_button"
                  />
                </Tooltip>
              ) : null}
            </div>
          </Menu.Item>
        ) : null}

        {permissionChecker("view navigation menu group details") ? (
          <Menu.Item
            key="group-details"
            icon={<FormOutlined />}
            className="menus_background"
          >
            <Link className="color_link" to="/group-details/listing">
              Group Details
            </Link>
          </Menu.Item>
        ) : null}
        {permissionChecker("view navigation menu events") ? (
          <Menu.Item
            key="event"
            icon={<CarryOutOutlined />}
            className="menus_background"
          >
            <div className="menu_flex">
              <Link className="color_link" to={EventsListingRoute}>
                Events
              </Link>
              {allowAddEvent ? (
                <Tooltip
                  title={
                    <>
                      <span className="tooltip_bold">Add Event</span>
                    </>
                  }
                >
                  <Button
                    type="y"
                    icon={
                      <Link to={EventRoute}>
                        <PlusOutlined />
                      </Link>
                    }
                    className="headersidebar_button"
                  />
                </Tooltip>
              ) : null}
            </div>
          </Menu.Item>
        ) : null}
        {permissionChecker("view navigation menu reservations") ? (
          <Menu.Item
            key="reservation"
            icon={<FileDoneOutlined />}
            className="menus_background"
          >
            <div className="menu_flex">
              <Link className="color_link" to="/reservations">
                Reservations
              </Link>

              {allowAddReservation ? (
                <Tooltip
                  title={
                    <>
                      <span className="tooltip_bold">Create</span>
                    </>
                  }
                >
                  <Button
                    onClick={showModal}
                    icon={<PlusOutlined />}
                    className="headersidebar_button"
                  />
                </Tooltip>
              ) : null}
              {isModalOpen ? (
                <ReservationCreationModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
              ) : null}
            </div>
          </Menu.Item>
        ) : null}

        {permissionChecker("view navigation menu all reports") ||
        permissionChecker("view navigation menu elite travelers") ? (
          <SubMenu
            key="reports"
            icon={<FundProjectionScreenOutlined />}
            title="Reports"
            className="menus_background"
            onTitleClick={(e) => {
              openMenuToggler(e);
            }}
          >
            {permissionChecker("view navigation menu all reports") ? (
              <Menu.Item key="reports">
                <Link to={ReportListingRoute}>All Reports</Link>
              </Menu.Item>
            ) : null}
            {permissionChecker("view navigation menu elite travelers") ? (
              <Menu.Item key="elite-travelers">
                <Link to="/elite-travelers">Elite Travelers</Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}

        {permissionChecker("view navigation menu email templates") ||
        permissionChecker("view navigation menu email monitor") ||
        permissionChecker("view navigation menu custom dropdown") ||
        permissionChecker("view navigation menu manage roles") ||
        permissionChecker("view navigation menu manage users") ||
        permissionChecker("view navigation menu marketing sources") ? (
          <SubMenu
            key="admin"
            icon={<SettingOutlined />}
            title="Admin"
            className="menus_background"
            onTitleClick={(e) => {
              openMenuToggler(e);
            }}
          >
            {permissionChecker("view navigation menu email templates") ? (
              <Menu.Item key="email_templates">
                <Link to="/email_templates">Email Templates</Link>
              </Menu.Item>
            ) : null}
            {permissionChecker("view navigation menu email monitor") ? (
              <Menu.Item key="email_monitor">
                <Link to={AdminEmailMonitorRoute}>Email Monitor</Link>
              </Menu.Item>
            ) : null}
            {permissionChecker("view navigation menu custom dropdown") ? (
              <Menu.Item key="custom-dropdown">
                <Link to="/custom-dropdown">Custom Dropdown</Link>
              </Menu.Item>
            ) : null}

            {permissionChecker("view navigation menu manage roles") ? (
              <Menu.Item key="manage-roles">
                <Link to="/manage-roles">Manage Roles</Link>
              </Menu.Item>
            ) : null}
            {/* <Menu.Item key="manage-permissions">
            <Link to="/manage-permissions">Manage Permissions</Link>
          </Menu.Item> */}
            {permissionChecker("view navigation menu manage users") ? (
              <Menu.Item key="manage-users">
                <Link to="/manage-users">Manage Users</Link>
              </Menu.Item>
            ) : null}

            {permissionChecker("view navigation menu marketing sources") ? (
              <Menu.Item key="marketing-sources">
                <Link to="/marketing-sources?global=true">Marketing Sources</Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}
      </Menu>
    </>
  );
}

export default Sidebar;
