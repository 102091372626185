import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";

import { Form, Input, DatePicker, Switch, Divider } from "antd";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import paymentMethod from "../../../../../../utils/paymentMethod.json"
const { TextArea } = Input;

function PayViaStripePaymentForm({
  paymentDetail,
  setPaymentAmount,
  totalPendingAmount,
  form,
}) {
  const [paymentMethodList, setPaymentMethodList] = useState(paymentMethod.list);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  // useEffect(() => {
  //   getTaxonomyList(
  //     "payment_method",
  //     setPaymentMethodList,
  //     setPaymentMethodLoading
  //   );
  // }, []);

  if (paymentDetail) {
    return (
      <div className="">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 className="mb-0">Payment Details</h4>
        </div>
        <Divider className="global_divider" />

        <div style={{ marginTop: "24px" }} />
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item
              label="Payment Amount"
              className="asterick-align"
              name="payment_amount"
              rules={[
                { required: true, message: "Please Input Payment Amount" },
              ]}
            >
              <Input
                type="number"
                min={0}
                pattern="/^[0-9\b]+$/"
                onKeyDown={(evt) => {
                  if (
                    evt.key === "e" ||
                    evt.key === "-" ||
                    evt.key === "+" ||
                    // evt.key === "." ||
                    evt.key === "ArrowUp" ||
                    evt.key === "ArrowDown" ||
                    (evt.target.value.split(".")?.[0]?.length >= 7 &&
                      evt.key != "Backspace")
                  ) {
                    evt.preventDefault();
                  }
                }}
                onInput={(e) => {
                  setPaymentAmount(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Payment Method"
              name="payment_method"
              className="asterick-align"
              rules={[
                { required: true, message: "Please Select Payment Method" },
              ]}
            >
              <Select
                placeholder="Select Payment Method"
                options={paymentMethodList.map((paymentMethod,index) => ({
                  key: index,
                  value: paymentMethod.value,
                  label: paymentMethod.name,
                }))}
                loading={paymentMethodLoading}
              />
            </Form.Item>

            <div className="textarea_row">
              <Form.Item
                label="Comments"
                name="comments"
                rules={[{ required: true, message: "Please Input Comments" }]}
              >
                <TextArea rows={4} onChange={(e) => {}} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item
              name="payment_date"
              className="asterick-align"
              label="Payment Date"
              rules={[
                { required: true, message: "Please Input Payement Date" },
              ]}
            >
              <DatePicker format={"MM/DD/YYYY"} />
            </Form.Item>

            <Form.Item label="Transfer Balance" className="asterick-align" name="transfer_balance">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  return;
}

export default PayViaStripePaymentForm;
