import { Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReservationPaymentsListingApi } from "../../../apis/reservationApi";
import SystemInformation from "../../../others/CommonSystemInformation/SystemInformation";
import Reservationsitems from "../ReservationItem/Reservationsitems";
import ReservationActivityListing from "./ReservationDetailSections/ReservationActivityListing";
import ReservationAdditionalInfo from "./ReservationDetailSections/ReservationAdditionalInfo";
import ReservationGeneralInfo from "./ReservationDetailSections/ReservationGeneralInfo";
import ReservationPayments from "./ReservationDetailSections/ReservationPayments";
import ReservationPromoCode from "./ReservationDetailSections/ReservationPromoCode";
import ReservationsFinancialInfo from "./ReservationDetailSections/ReservationsFinancialInfo";
import TravelersListing from "./ReservationDetailSections/Travelers/TravelersListing";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import ReservationCancellationInfo from "./ReservationDetailSections/ReservationCancellationInfo";
const { Header, Content, Sider } = Layout;

const ReservationDetailContainer = ({
  reservationId,
  reservationData,
  loadingReservationData,
}) => {
  const [paymentsListing, setPaymentListing] = useState([]);
  const [cancelledTravellerStatus, setCancelledTravellerStatus] = useState([]);
  const [loadingPaymentListing, setLoadingPaymentListing] = useState(false);
  const { isPayeeTrigger, isActivityTrigger, highlightSection } = useSelector(
    (state) => state.reservation
  );

  useEffect(() => {
    if (cancelledTravellerStatus.length) {
      getReservationPaymentsList({
        travellersId: cancelledTravellerStatus.join(),
        reservationId,
      });
    } else {
      getReservationPaymentsList({ reservationId });
    }
  }, [cancelledTravellerStatus, isPayeeTrigger]);

  const getReservationPaymentsList = async (data) => {
    setLoadingPaymentListing(true);
    const res = await getReservationPaymentsListingApi(data);
    if (res.status) {
      setPaymentListing(res?.data?.travelers);
      setLoadingPaymentListing(false);
    } else {
      setPaymentListing([]);
      setLoadingPaymentListing(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <>
      <Content
        className="site-layout-background"
        style={{
          marginBottom: "20px",
          border:
            highlightSection?.travelers ||
            highlightSection?.select_contact ||
            highlightSection?.select_relation ||
            highlightSection?.select_payee
              ? "2px solid red"
              : "none",
        }}
      >
        <TravelersListing />
      </Content>

      <Content
        className="site-layout-background"
        style={{
          marginBottom: "20px",
          border: highlightSection?.general_information
            ? "2px solid red"
            : "none",
        }}
      >
        <ReservationGeneralInfo
          reservationData={reservationData}
          reservationId={reservationId}
          loadingReservationData={loadingReservationData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <ReservationsFinancialInfo
          reservationData={reservationData}
          reservationId={reservationId}
          loadingReservationData={loadingReservationData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{
          marginBottom: "20px",
          border: highlightSection?.items ? "2px solid red" : "none",
        }}
      >
        <Reservationsitems reservationData={reservationData} />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <ReservationPromoCode
          reservationData={reservationData}
          reservationId={reservationId}
        />
      </Content>

      {paymentsListing?.length
        ? paymentsListing?.map((paymentList) => (
            <>
              <Content
                className="site-layout-background"
                style={{
                  marginBottom: "20px",
                  border:
                    highlightSection?.payments?.includes(
                      paymentList?.traveler
                    ) || paymentList?.is_discrepency
                      ? "2px solid red"
                      : "",
                }}
              >
                <div className="reservationpayment_table">
                  <ReservationPayments
                    reservationData={reservationData}
                    reservationId={reservationId}
                    paymentList={paymentList}
                    setCancelledTravellerStatus={setCancelledTravellerStatus}
                    cancelledTravellerStatus={cancelledTravellerStatus}
                    loadingPaymentListing={loadingPaymentListing}
                  />
                </div>
              </Content>
            </>
          ))
        : null}
      {/* <Divider style={{ marginBottom: "0" }} />
        <div className="reservationpayment_table">
          <ReservationPaymentTable_one />
        </div>
        <Divider style={{ marginBottom: "0" }} />

        <div className="reservationpayment_table">
          <ReservationPaymentTable_two />
        </div>
        <Divider style={{ marginBottom: "0" }} />

        <div className="reservationpayment_table">
          <ReservationPaymentTable_three />
        </div> */}

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <ReservationAdditionalInfo
          reservationData={reservationData}
          reservationId={reservationId}
          loadingReservationData={loadingReservationData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <ReservationActivityListing
          reservationId={reservationId}
          loadingReservationData={loadingReservationData}
          reservationData={reservationData}
        />
      </Content>

      {reservationData?.reservation_status?.key === "cancelled" && (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <ReservationCancellationInfo
            reservationData={reservationData}
            reservationId={reservationId}
            loadingReservationData={loadingReservationData}
          />
        </Content>
      )}

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <SystemInformation
          user_id={reservationData?.user_id || {}}
          modified_by={reservationData?.field_last_modified_by || {}}
          created={reservationData?.created}
          changed={reservationData?.changed}
          loading={loadingReservationData}
          reservationData={reservationData}
        />
      </Content>
    </>
  );
};

export default ReservationDetailContainer;
