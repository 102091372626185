import { Grid, Layout } from "antd";
import React from "react";
import CustomDropdownSearchbar from "../../../components/Admin/CustomDropdown/CustomDropdownSearchbar";
import EditCustomDropdownContainer from "../../../components/Admin/CustomDropdown/EditCustomDropdownContainer";
import EditCustomDropdownHeader from "../../../components/Admin/CustomDropdown/EditCustomDropdownHeader";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { useSearchParams } from "react-router-dom";
const { Header, Content } = Layout;

const CustomDropdownEditPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams()
  // const paramId = searchParams?.get("id")
  const paramName = searchParams?.get("name")
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Custom Dropdowns", path: "/custom-dropdown" },
    { title: `Edit ${paramName}`, path: "" },
  ];


  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CustomDropdownSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EditCustomDropdownHeader title={breadCrumbs[breadCrumbs.length-1].title} />
          </div>
          <Content>
            <EditCustomDropdownContainer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CustomDropdownEditPage;
