import React from "react";
import AutoPayAuthForm from "./AutoPayAuthForm";
import PlannedPaymentForm from "./PlannedPaymentForm";

const PlannedPaymentFormContainer = ({
  form,
  onFinishHandler,
  loadingPaymentData,
}) => {
  return (
    <div className="details-tabs" style={{ margin: 0 }}>
      <div className="basic_details pd-16 bg-white">
        <PlannedPaymentForm
          form={form}
          onFinishHandler={onFinishHandler}
          loadingPaymentData={loadingPaymentData}
        />
      </div>

      <div className="basic_details pd-16 bg-white">
        <AutoPayAuthForm
          form={form}
          onFinishHandler={onFinishHandler}
          loadingPaymentData={loadingPaymentData}
        />
      </div>
    </div>
  );
};

export default PlannedPaymentFormContainer;
