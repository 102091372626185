import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Select, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import "./CruiseDetailsForm.less";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const MeetingSpaceNeedsForm = ({ form, onFinishHandler, isLoading }) => {
  const [largeTheatreList, setLargeTheatreList] = useState([]);
  const [loadingLargeTheatre, setLoadingLargeTheatre] = useState(false);
  const [breakOutRoomsList, setBreakOutRoomsList] = useState([]);
  const [loadingBreakOutRooms, setLoadingBreakOutRooms] = useState(false);
  const [preliminaryEventList, setPreliminaryEventList] = useState([]);
  const [loadingPreliminaryEvent, setLoadingPreliminaryEvent] = useState(false);
  const [diningTimeList, setDiningTimeList] = useState([]);
  const [loadingDiningTime, setLoadingDiningTime] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "large_theatre",
      setLargeTheatreList,
      setLoadingLargeTheatre
    );
    getTaxonomyList(
      "break_out_rooms",
      setBreakOutRoomsList,
      setLoadingBreakOutRooms
    );
    getTaxonomyList(
      "preliminary_event_schedule",
      setPreliminaryEventList,
      setLoadingPreliminaryEvent
    );
    getTaxonomyList("dining_time", setDiningTimeList, setLoadingDiningTime);
  }, []);

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0">Meeting Space Needs</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.List
            name="field_meeting_needs"
            initialValue={[
              {
                field_large_theater: null,
                field_break_out_rooms: [],
                field_preliminary_event: null,
                field_dining_time: null,
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                  >
                    {key > 0 ? (
                      <Form.Item>
                        <div className="close_relative">
                          <Button
                            className="close_absolute"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </Form.Item>
                    ) : null}
                    <Form.Item
                      label="Large Theater "
                      {...restField}
                      name={[name, "field_large_theater"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={largeTheatreList.map((largeTheatre) => ({
                          key: largeTheatre.id,
                          value: largeTheatre.drupal_internal__tid,
                          label: largeTheatre.name,
                        }))}
                        loading={loadingLargeTheatre}
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item
                      label="Break Out Rooms"
                      {...restField}
                      name={[name, "field_break_out_rooms"]}
                    >
                      <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={breakOutRoomsList.map((breakOutRooms) => ({
                          key: breakOutRooms.id,
                          value: breakOutRooms.drupal_internal__tid,
                          label: breakOutRooms.name,
                        }))}
                        loading={loadingBreakOutRooms}
                      />
                    </Form.Item>

                    <Form.Item
                      label={
                        <div div className="text_break">
                          <div>Preliminary Event </div>
                          <div>Schedule Needed?</div>
                        </div>
                      }
                      {...restField}
                      name={[name, "field_preliminary_event"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={preliminaryEventList.map(
                          (preliminaryEvent) => ({
                            key: preliminaryEvent.id,
                            value: preliminaryEvent.drupal_internal__tid,
                            label: preliminaryEvent.name,
                          })
                        )}
                        loading={loadingPreliminaryEvent}
                        allowClear
                      />
                    </Form.Item>
                    <div className="form_align_select_two">
                      <Form.Item
                        label="Dining Time"
                        {...restField}
                        name={[name, "field_dining_type"]}
                      >
                        <Select
                          showSearch
                          showArrow
                          filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          options={diningTimeList.map((diningTime) => ({
                            key: diningTime.id,
                            value: diningTime.drupal_internal__tid,
                            label: diningTime.name,
                          }))}
                          loading={loadingDiningTime}
                          allowClear
                        />
                      </Form.Item>
                      <div className="form_label">
                        <Form.Item
                          {...restField}
                          name={[name, "field_dining_time"]}
                        >
                          <TimePicker use12Hours format="hh:mm A" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Meeting Space Needs
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </div>
  );
};

export default MeetingSpaceNeedsForm;
