import React, { useState, useEffect, useRef } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleVocabulary,
  createTerm,
  deleteTerm,
  updateTerm,
  setSingleVocabularyLocations,
} from "../../../redux/features/customDropdownFeatures/customDropDownSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DataLoader from "../../../others/Loaders/DataLoader";
import { useSearchParams } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  setNewTerm,
  save,
  cellRef,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber /> : <Input ref={cellRef} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              save(record);
            }
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditCustomDropdownContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [add, setAdd] = useState(false);
  const [count, setCount] = useState(0);
  const [newTerm, setNewTerm] = useState("");
  const cellRef = useRef(null);
  let singleVocabulary = useSelector(
    (state) => state.customDropDown.singleVocabulary
  );
  let {
    loading,
    responseMessage,
    createdTermResponse,
    updatedTermResponse,
    deletedTermResponse,
  } = useSelector((state) => state.customDropDown);
  const { singleVocabularyData } = singleVocabulary;

  const [trigger, setTrigger] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const messageRef = useRef();
  const NEWLY_ROW_ID = -1;

  // get url paramss
  let paramId = searchParams.get("id");

  useEffect(() => {
    if (paramId) {
      dispatch(getSingleVocabulary(paramId));
    }
  }, [createdTermResponse, updatedTermResponse, deletedTermResponse]);

  useEffect(() => {
    if (messageRef.current) {
      if (responseMessage?.status) {
        message.success(
          responseMessage?.data?.data?.message ||
            responseMessage?.genericMessage ||
            "Success"
        );
      } else {
        message.error(
          responseMessage?.data?.response?.data?.message ||
            responseMessage?.data?.message ||
            responseMessage?.data?.genericMessage ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    messageRef.current = true;
  }, [responseMessage]);

  useEffect(() => {
    const renderedItems = singleVocabularyData?.map((item) => {
      return {
        title: <HolderOutlined className="draggable" type="swap" />,
        key: item?.id,
        name: item?.name,
      };
    });
    setDataSource(renderedItems);
    setCount(singleVocabularyData.length);
  }, [singleVocabularyData, responseMessage]);

  useEffect(() => {
    if (cellRef.current) {
      cellRef.current.focus();
    }
  }, [editingKey]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record, rowIndex) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = (record, rowIndex) => {
    if (rowIndex === dataSource.length - 1) {
      setAdd(false);
      setDataSource([...dataSource.slice(0, -1)]);
    }
    setEditingKey("");
  };

  const save = async (key, rowIndex) => {
    const formData = await form.getFieldsValue();
    form.submit();
    if (!formData.name.length) {
      return false;
    }
    if (key.id === NEWLY_ROW_ID) {
      handleSaveTerm(formData.name, key.id);
    } else {
      handleSaveTerm(formData.name, key.key);
    }
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > NEWLY_ROW_ID) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setEditingKey("");
      } else {
        newData.push(row);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = async (record) => {
    // get url paramss
    const type = paramId;
    let data = {
      type: type,
      id: record.key,
      data: {
        type: type,
        id: record.key,
      },
    };
    dispatch(deleteTerm(data));

    setTrigger(!trigger);
  };

  const columns = [
    { title: "", dataIndex: "title", key: "title", width: "150px" },
    { title: "", dataIndex: "name", key: "name", editable: true },
    {
      title: "operation",
      width: "100px",
      dataIndex: "operation",
      render: (_, record, rowIndex) => {
        let editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record, rowIndex);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record, rowIndex)}
            >
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <div className="action_flex action_end">
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record, rowIndex)}
              >
                <EditOutlined className="action_edit" />
              </Typography.Link>

              <Popconfirm
                title="Are you sure you want to delete this?"
                placement="bottomRight"
                onConfirm={() => handleDelete(record)}
              >
                <div className="action_flex action_end">
                  <DeleteOutlined className="action_delete" />
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setAdd(true);
    form.resetFields();
    let tempDd = {
      title: <HolderOutlined className="draggable" type="swap" />,
      id: NEWLY_ROW_ID,
      name: ``,
    };
    setDataSource([...dataSource, tempDd]);
    edit(tempDd, NEWLY_ROW_ID);
  };

  const handleSaveTerm = (name, id) => {
    // get vocabulary machine name
    const myParam = paramId;
    const type = `taxonomy_term--${myParam}`;
    const data = {
      myParam: myParam,
      data: {
        type: type,
        attributes: {
          name: name,
          description: "This is a new term",
        },
      },
    };

    if (id === NEWLY_ROW_ID) {
      data.data.attributes.weight = count + 1;
      dispatch(createTerm(data));
      setCount(+1);
    } else {
      data.id = id;
      data.data.id = id;
      dispatch(updateTerm(data));
    }

    setAdd(false);
    setTrigger(!trigger);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        // handleSave,
        setNewTerm,
        save,
        cellRef,
      }),
    };
  });

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(dataSource);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDataSource(items);
    dispatch(setSingleVocabularyLocations(items));
  };

  const generateIndex = (key) => {
    const elem = dataSource?.findIndex((val) => val.key === key);
    return elem;
  };

  return (
    <Form form={form} component={false}>
      <div className="partnership_inform">
        <p className="dropdown_heading">Dropdown Options</p>
      </div>
      <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
        <Droppable droppableId="table">
          {(provided) => {
            return (
              <>
                <Table
                  showHeader={false}
                  ref={provided.innerRef}
                  loading={{ spinning: loading, indicator: <DataLoader /> }}
                  components={{
                    body: {
                      row: (props) => {
                        return (
                          <Draggable
                            draggableId={props?.["data-row-key"]}
                            index={generateIndex(props["data-row-key"])}
                            isDragDisabled={add}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {props.children}
                              </tr>
                            )}
                          </Draggable>
                        );
                      },
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={dataSource}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={false}
                />
                {provided.placeholder}
              </>
            );
          }}
        </Droppable>
      </DragDropContext>
      {loading ? null : (
        <Button
          onClick={handleAdd}
          className="button_style"
          type="dashed"
          disabled={add || editingKey}
        >
          <PlusCircleOutlined /> Add Option
        </Button>
      )}
    </Form>
  );
};
export default EditCustomDropdownContainer;
