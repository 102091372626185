import { Col, Divider, Form, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { addCommasInPricing } from "../../../../../utils/contentParser";
const columns = [
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
];
function CalculationDetails({ cancelDetails, loadingCancelDetails }) {
  const { calculation_details } = cancelDetails || {};
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    setLocalLoading(false);
  }, [calculation_details]);

  useEffect(() => {
    setData(
      calculation_details?.length
        ? calculation_details?.map((calculationDetail) => ({
          description: <b>{calculationDetail?.fee_name}</b>,
          amount: calculationDetail?.fee_amt
            ?`$${addCommasInPricing(calculationDetail?.fee_amt.toString())}`
            : "$0",
          children: [
            {
              description: calculationDetail?.item_name,
              amount: calculationDetail?.item_amt
                ? `$${addCommasInPricing(calculationDetail?.item_amt.toString())}`
                : "$0",
              children: calculationDetail?.traveler?.length
                ? calculationDetail?.traveler?.map((listItem) => ({
                  description: listItem?.name,
                  amount: listItem?.amount ? `$${addCommasInPricing(listItem?.amount.toString())}` : "$0",
                }))
                : [],
            },
          ],
        }))
        : []
    );
  }, [calculation_details]);

  useEffect(() => {
    if (data.length) {
      expandAllRows(data);
    }
  }, [data]);

  const expandAllRows = (datasource) => {
    const allKeys = datasource.reduce((keys, item) => {
      if (item.children) {
        return [...keys, item.key, ...item.children.map((child) => child.key)];
      }
      return keys;
    }, []);
    setExpandedRowKeys(allKeys);
  };
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Calculation Details</h4>
      </div>
      <Divider className="global_divider" />

      <div />
      <Table
        className="payment_table pay_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        expandable={{
          expandedRowKeys,
          expandIcon: () => null,
        }}
        pagination={false}
        loading={{
          spinning: loadingCancelDetails && localLoading,
          size: "large",
        }}
      />
    </div>
  );
}
export default CalculationDetails;
