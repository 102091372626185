import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  CancelReservationItemRoute,
  InsurancePayment,
  ReplaceItemRoute,
  ReservationDetailRoute,
} from "../../../url-routes/urlRoutes";
import { changeStatusApi } from "../../../apis/reservationApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader, Token } from "../../../utils/cutomRequestHeader";
import axios from "axios";

function Reservationtableview_header({
  data,
  triggerData,
  setTriggerData,
  setDeleteModalOpen,
  fetchReservationItem
}) {
  const status = data?.reservation_item?.reservation_status;
  const itemStatus = data?.reservation_item?.field_itemstatus;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingRevise, setLoadingRevise] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  let reservationId = searchParams.get("id");
  let itemId = searchParams.get("item-id");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    resStatusUpdate(reservationId, itemId);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const resStatusUpdate = async (resId, itemId) => {
    setLoadingRevise(true);
    let payload = {
      res_uuid: resId,
      res_status: "revise",
      resitem_uuid: itemId,
    };
    const res = await changeStatusApi(payload);
    if (res.status) {
      message.success("Success");
      setTriggerData(!triggerData);
      handleCancel();
    } else {
      message.error(
        res?.data?.response?.data?.data?.message || SOMETHING_WENT_WRONG
      );
    }
    setLoadingRevise(false);
    setIsModalOpen(false);
  };

  const handleDeleteItem = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await drupalAxiosRequest(
        "delete",
        `/api/v1/reservation_item/reservation_item/${itemId}`,
        customRequestHeader({ defaultFormat: true })
        );
        if (response.status) {
          message.success("Reservation item deleted successfully");
          navigate(`/reservation/details?id=${reservationId}`);
        } else {
        message.error(response?.data?.response?.data?.errors?.[0]?.detail || "Something went wrong");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong");
    }
  };
  const modalHandler = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this reservation item?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return handleDeleteItem();
      },
    });
    // setDeleteModalOpen(true);
  };

  const payHandler = () => {
    navigate(`${InsurancePayment}?id=${reservationId}&item-id=${itemId}`);
  }
  const updateQuote = async () => {
    try {
      setButtonLoader(true);
      const res = await drupalAxiosRequest(
        "get",
        `/api/rest/update-quote/${reservationId}/${itemId}`,
        customRequestHeader({ token: Token })
      );
      if (res.status) {
        message.success(
          {
            content: (
              <span dangerouslySetInnerHTML={{ __html:res?.data?.message || "Quote Price Updated Successfully" }} />
            ),
          }
          );
          fetchReservationItem()
      } else {
        message.error(
          {
            content: (
              <span dangerouslySetInnerHTML={{ __html:res.data.response.data.message || res?.message || SOMETHING_WENT_WRONG }} />
            ),
          }
        );
      }
      setButtonLoader(false);
    } catch (err) {
      console.log("err", err);
      setButtonLoader(false);
    }
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        {/* <h3><ArrowLeftOutlined className="back-icon-profile" />   <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >View Reservation Item (10036 - Balcony Cabin)  </span>   <span className='span_serial' style={{ fontSize: '14px', lineHeight: '22px', fontWeight: '400', fontWeight: '500', fontFamily: "'Poppins', sans-serif", color: '#00000073', opacity: '0.6' }} > IMC Gospel Music Celebration 2023 Hawaii Cruise</span>
        </h3> */}

        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(`${ReservationDetailRoute}?id=${reservationId}`)
            }
          />
          <div className="header_res">
            <p>View Reservation Item</p>
            <p className="header_smalltext">
              {data?.reservation?.id && `(${data?.reservation?.id})`}
            </p>
            <p className="header_smalltext">
              {data?.reservation_item_category?.code
                ? `${data?.reservation_item_category?.code}-`
                : ""}
              {data?.reservation_item_category?.name
                ? `${data?.reservation_item_category?.name}-`
                : ""}
              {data?.event_datail?.name}
            </p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          {itemStatus === "quote" &&
          [
            "active",
            "revise",
            "cancel_pending",
            "cancelled",
            "completed",
          ].includes(status) &&
          parseFloat(data?.traveler_details_total_sum?.total_items_price) >
            0 ? (
            <Button type="primary" onClick={() => payHandler()}>
              Pay
            </Button>
          ) : null}

          {itemStatus === "quote" &&
          ["active", "revise", "cancel_pending", "temporary_hold"].includes(
            status
          ) ? (
            <Button
              type="primary"
              loading={buttonLoader}
              onClick={() => updateQuote()}
            >
              Update Quote
            </Button>
          ) : null}
          {!["quote","cancelled","purchased"].includes(itemStatus) && status === "active" ? (
            <Button type="primary" onClick={() => showModal()}>
              Revise
            </Button>
          ) : null}

          {!["quote","cancelled","purchased"].includes(itemStatus) &&
          (status === "active" ||
            status === "revise" ||
            status === "cancel_pending") ? (
            <Button
              type="primary"
              onClick={() => {
                if (reservationId && itemId) {
                  navigate(
                    `${ReplaceItemRoute}?id=${reservationId}&item-id=${itemId}`
                  );
                }
              }}
            >
              Replace
            </Button>
          ) : null}

          <Modal
            footer={[
              <Button type="primary" onClick={() => handleOk()}>
                Confirm
              </Button>,
              <Button onClick={() => handleCancel()}>Cancel</Button>,
            ]}
            title="Revise Confirmation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="revise-btn_modal">
              <p>
                Please confirm that you want to start revising reservation item{" "}
                <span>{data?.reservation_item?.name}</span>.
              </p>
              <p>
                Reservation status will be changed to Revise and the item will
                be available for editing.
              </p>
            </div>
          </Modal>

          {!["quote","cancelled","purchased"].includes(itemStatus) &&
          (status === "active" ||
            status === "revise" ||
            status === "cancel_pending") ? (
            <Link
              to={`${CancelReservationItemRoute}?id=${reservationId}&item-id=${itemId}`}
            >
              <Button type="primary">Cancel</Button>
            </Link>
          ) : null}
          

          {itemStatus === "quote" || ["quote", "waitlist", "pending", "temporary_hold"].includes(
            status
          ) ? (
            <Button type="danger" onClick={modalHandler}>
              Delete
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Reservationtableview_header;
