import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AddonFeeDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import FeeDiscountCategoriesHeader from "../../../../../components/Events/EventDetailsTabs/FeeDiscountCategory/FeeDiscountCategoriesHeader";
import FeeDiscountCategoriesEdit from "../../../../../components/Events/EventDetailsTabs/FeeDiscountCategory/FeeDiscountCategoriesEdit";

const { Header, Content, Sider } = Layout;

const AddonFeeCategoriesEditPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoriesListData, setCategoriesListData] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState([]);
  const feeId = searchParams.get("feeId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Details",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    {
      title: "Fee Details",
      path: `${AddonFeeDetailsRoute}?id=${eventId}&addonId=${addonId}&feeId=${feeId}`,
    },
    { title: "Categories", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <FeeDiscountCategoriesHeader
              feeType={"addon"}
              eventId={eventId}
              addonId={addonId}
              feeId={feeId}
              updatedPayload={updatedPayload}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <FeeDiscountCategoriesEdit
              feeType={"addon"}
              eventId={eventId}
              addonId={addonId}
              feeId={feeId}
              loadingCategories={loadingCategories}
              setLoadingCategories={setLoadingCategories}
              categoriesListData={categoriesListData}
              setCategoriesListData={setCategoriesListData}
              setUpdatedPayload={setUpdatedPayload}
              updatedPayload={updatedPayload}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonFeeCategoriesEditPage;
