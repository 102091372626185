import { AutoComplete, Divider, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../others/commonApiCalls/commonApiCalls";
import { getGroupWithListApi } from "../../../apis/reservationApi";
import { useDebounce } from "../../../others/Debounce/debounce";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { ArrowDownOutlined } from "@ant-design/icons";

function ReservationPreferenceForm({
  form,
  onFinishHandler,
  editId,
  reservationFormData,
}) {
  const [bedRequest, setBedRequest] = useState([]);
  const [loadingBegReq, setLoadingBedReq] = useState(false);
  const [diningTimeRequest, setDiningTimeRequest] = useState([]);
  const [loadingDiningTimeReq, setLoadingDiningTimeReq] = useState(false);
  const [diningTimeAssigned, setDiningTimeAssigned] = useState([]);
  const [loadingDiningTimeAssigned, setLoadingDiningTimeAssigned] =
    useState(false);
  const [tableSizeReq, setTableSizeReq] = useState([]);
  const [loadingTableSizeReq, setLoadingTableSizeReq] = useState(false);
  const [tableStatus, setTableStatus] = useState([]);
  const [loadingTableStatus, setLoadingTableStatus] = useState(false);
  const [charterGroup, setCharterGroup] = useState([]);
  const [loadingCharterGroup, setLoadingCharterGroup] = useState(false);
  const [charterPriority, setCharterPriority] = useState([]);
  const [loadingCharterPriority, setLoadingCharterPriority] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchVal, setSearchVal] = useState(null);
  const debouncedSearch = useDebounce(searchVal, 500);

  useEffect(() => {
    if (reservationFormData?.select_event?.id) {
      getTaxonomyList(
        `dining_time_request?filter[field_dining_time_request_event.id]=${reservationFormData?.select_event?.id}`,
        setDiningTimeRequest,
        setLoadingDiningTimeReq
      );
      getTaxonomyList(
        `dining_time_assigned?filter[field_dining_time_assigned_event.id]=${reservationFormData?.select_event?.id}`,
        setDiningTimeAssigned,
        setLoadingDiningTimeAssigned
      );
    }
  }, [reservationFormData]);
  useEffect(() => {
    getTaxonomyList("bed_request", setBedRequest, setLoadingBedReq);
    getTaxonomyList(
      "table_size_request",
      setTableSizeReq,
      setLoadingTableSizeReq
    );
    getTaxonomyList("table_status", setTableStatus, setLoadingTableStatus);
    getTaxonomyList("charter_group", setCharterGroup, setLoadingCharterGroup);
    getTaxonomyList(
      "charter_priority",
      setCharterPriority,
      setLoadingCharterPriority
    );
    getGroupWithList();
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      getGroupWithList(debouncedSearch);
    }
  }, [debouncedSearch]);
  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      form.setFieldsValue({
        traveling_with: reservationFormData?.traveling_with,
        group_with: reservationFormData?.group_with,
        field_bed_request: reservationFormData?.field_bed_request?.id,
        field_dining_time_request:
          reservationFormData?.field_dining_time_request?.id,
        field_dining_time_assigned:
          reservationFormData?.field_dining_time_assigned?.id,
        field_table_size_request:
          reservationFormData?.field_table_size_request?.id,
        table_no: reservationFormData?.table_no,
        field_table_status: reservationFormData?.field_table_status?.id,
        field_charter_group: reservationFormData?.field_charter_group?.id,
        field_charter_priority: reservationFormData?.field_charter_priority?.id,
      });
    }
  }, [reservationFormData]);

  const getGroupWithList = async (searchTerm) => {
    const res = await getGroupWithListApi(searchTerm, editId);
    if (res.status) {
      setGroupList(res?.data?.list);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const onSelect = (value) => {
    setSelectedOption(value);
  };

  const handleSearch = (value) => {
    setSearchVal(value);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // paddingBottom: "10px",
        }}
      >
        <h4>Reservation Preferences</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 12,
        }}
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item label="Traveling With" name="traveling_with">
          <Input />
        </Form.Item>

        <Form.Item label="Group With" name="group_with">
          {/* <Input /> */}
          <AutoComplete
            allowClear
            value={selectedOption}
            options={groupList.map((item) => ({
              key: item,
              value: item,
              label: item,
            }))}
            onSelect={onSelect}
            onSearch={handleSearch}
          />
        </Form.Item>

        <Form.Item name="field_bed_request" label="Bed Request">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={bedRequest.map((badReqItem) => ({
              key: badReqItem.drupal_internal__tid,
              value: badReqItem.id,
              label: badReqItem.name,
            }))}
            loading={loadingBegReq}
          />
        </Form.Item>

        <Form.Item name="field_dining_time_request" label="Dining Time Request">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={diningTimeRequest.map((diningTimeRequestItem) => ({
              key: diningTimeRequestItem.drupal_internal__tid,
              value: diningTimeRequestItem.id,
              label: diningTimeRequestItem.name,
            }))}
            loading={loadingDiningTimeReq}
          />
        </Form.Item>

        <Form.Item
          name="field_dining_time_assigned"
          label="Dining Time Assigned"
        >
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={diningTimeAssigned.map((diningTimeAssignedItem) => ({
              key: diningTimeAssignedItem.drupal_internal__tid,
              value: diningTimeAssignedItem.id,
              label: diningTimeAssignedItem.name,
            }))}
            loading={loadingDiningTimeAssigned}
          />
        </Form.Item>

        <Form.Item name="field_table_size_request" label="Table Size Request">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={tableSizeReq.map((tableSizeReqItem) => ({
              key: tableSizeReqItem.drupal_internal__tid,
              value: tableSizeReqItem.id,
              label: tableSizeReqItem.name,
            }))}
            loading={loadingTableSizeReq}
          />
        </Form.Item>

        <Form.Item label="Table No" name="table_no">
          <Input />
        </Form.Item>

        <Form.Item name="field_table_status" label="Table Status">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={tableStatus.map((tableStatusItem) => ({
              key: tableStatusItem.drupal_internal__tid,
              value: tableStatusItem.id,
              label: tableStatusItem.name,
            }))}
            loading={loadingTableStatus}
          />
        </Form.Item>

        <Form.Item name="field_charter_group" label="Charter Group">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={charterGroup.map((charterGroupItem) => ({
              key: charterGroupItem.drupal_internal__tid,
              value: charterGroupItem.id,
              label: charterGroupItem.name,
            }))}
            loading={loadingCharterGroup}
          />
        </Form.Item>

        <Form.Item name="field_charter_priority" label="Charter Priority">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={charterPriority.map((charterPriorityItem) => ({
              key: charterPriorityItem.drupal_internal__tid,
              value: charterPriorityItem.id,
              label: charterPriorityItem.name,
            }))}
            loading={loadingCharterPriority}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default ReservationPreferenceForm;
