import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getDiscountDetailsApi,
  getSingleFeeDetailApi,
  updateDiscountCategoryListingApi,
  updateFeeCategoryListingApi,
} from "../../../../apis/eventsApi";
import {
  AddonDiscountDetailsRoute,
  AddonFeeDetailsRoute,
  EventDetailsRoute,
  EventDiscountDetailsRoute,
  EventFeeDetailsRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

function FeeDiscountCategoriesHeader({
  feeType,
  discountType,
  eventId,
  addonId,
  discountId,
  feeId,
  updatedPayload,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [feeDiscountData, setFeeDiscountData] = useState({});
  const [feeDisLoading, setFeeDisLoading] = useState(false);
  useEffect(() => {
    getFeeDiscountData(feeId, discountId);
  }, [feeId, discountId]);

  const getFeeDiscountData = async (feeId, discountId) => {
    if (feeId || discountId) {
      setFeeDisLoading(true);
      const res = feeId
        ? await getSingleFeeDetailApi(feeId)
        : await getDiscountDetailsApi(discountId);
      if (res.status) {
        setFeeDiscountData(res?.data?.data);
        setFeeDisLoading(false);
      } else {
        setFeeDiscountData({});
        setFeeDisLoading(false);
        message.error(SOMETHING_WENT_WRONG);
      }
    }
  };
  const routeToCategoryList = (
    feeType,
    discountType,
    eventId,
    addonId,
    discountId,
    feeId
  ) => {
    if (feeId) {
      if (feeType === "event") {
        return `${EventFeeDetailsRoute}?id=${eventId}&feeId=${feeId}`;
      } else if (feeType === "addon") {
        return `${AddonFeeDetailsRoute}?id=${eventId}&addonId=${addonId}&feeId=${feeId}`;
      }
    }
    if (discountId) {
      if (discountType === "event") {
        return `${EventDiscountDetailsRoute}?id=${eventId}&discountId=${discountId}`;
      } else if (discountType === "addon") {
        return `${AddonDiscountDetailsRoute}?id=${eventId}&addonId=${addonId}&discountId=${discountId}`;
      }
    }
  };
  const updateCategoriesList = async (
    feeType,
    discountType,
    eventId,
    feeId,
    discountId,
    addonId,
    updatedPayload
  ) => {
    setIsLoading(true);
    let updateId = !!feeId ? feeId : discountId;
    let payload = {
      data: {
        type: !!feeId ? "fees--fees" : "discounts--discounts",
        id: !!feeId ? feeId : discountId,
        attributes: {
          field_category: updatedPayload,
        },
      },
    };

    const response = feeId
      ? await updateFeeCategoryListingApi({ updateId, payload })
      : await updateDiscountCategoryListingApi({ updateId, payload });

    if (response?.status) {
      navigate(
        routeToCategoryList(
          feeType,
          discountType,
          eventId,
          addonId,
          discountId,
          feeId
        )
      );
      message.success(
        `${
          feeId ? "Fee" : discountId ? "Discount" : ""
        } Category Updated Successfully`
      );
      setIsLoading(false);
    } else {
      message.error(
        response?.data?.response?.data?.errors?.[0]?.title ||
          response?.data?.response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setIsLoading(false);
    }
  };

  const onSaveHandler = () => {
    updateCategoriesList(
      feeType,
      discountType,
      eventId,
      feeId,
      discountId,
      addonId,
      updatedPayload
    );
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => navigate(-1)}
          />
          {feeDisLoading ? (
            <>
              <Skeleton.Input active={true} style={{ width: "400px" }} />
            </>
          ) : (
            <div className="header_res">
              <p>
                {feeId ? `Fee` : discountId ? `Discount` : ""} Categories{" "}
                {feeDiscountData?.name?.name
                  ? `(${feeDiscountData?.name?.name})`
                  : ""}
              </p>
              <Link
                to={`${EventDetailsRoute}?id=${eventId}`}
                className="header_smalltext"
              >
                {feeDiscountData?.field_event?.name}{" "}
                {feeDiscountData?.field_event?.drupal_internal__id
                  ? `(${feeDiscountData?.field_event?.drupal_internal__id})`
                  : ""}
              </Link>
            </div>
          )}
        </h3>

        <div className="breadcrum_buttons">
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button loading={isLoading} type="primary" onClick={onSaveHandler}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FeeDiscountCategoriesHeader;
