import React, { useState } from "react";
import { Button, Col, Drawer, Form, Input, Row, Space, Tooltip } from "antd";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { documentFileUpdater } from "../../../../redux/features/contactFeatures/contactSlice";
import {
  createActivityDocumentLinkApi,
  editTaskActivityApi,
} from "../../../../apis/contactApi";
import { InfoCircleOutlined } from "@ant-design/icons";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

function TaskDocumentLink({ field_task_documents }) {
  const dispatch = useDispatch();
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid, user_id } = authorization;
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addDocumentLoading, setAddDocumentLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const activityId = searchParams.get("activityId");
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const addTaskDocuments = async (payload) => {
    const res = await editTaskActivityApi({ payload, activityId });
    if (res.status) {
      setAddDocumentLoading(false);
      form.resetFields();
      setFileList([]);
      onClose();
      dispatch(documentFileUpdater(res.data));
      message.success("Document Link Added");
    } else {
      setAddDocumentLoading(false);
      message.error(
        res?.data?.errors?.[0]?.detail ||
          res?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const createDocumentLink = async (payload) => {
    const link = await createActivityDocumentLinkApi({ payload });
    if (link.status) {
      return link?.data?.data;
    } else {
      message.error(
        link?.data?.response?.data?.errors?.[0]?.detail?.split(": ")?.[1] ||
          link?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setAddDocumentLoading(false);
      return;
    }
  };

  const onFinish = async (values) => {
    setAddDocumentLoading(true);
    const existingDocuments = field_task_documents?.length
      ? field_task_documents?.map(({ type, id }) => ({ type, id }))
      : [];

    const payloadForLink = {
      data: {
        type: "media--link",
        attributes: {
          name: values?.link_name || "",
          field_media_entity_link: [
            {
              uri: values?.link_path,
            },
          ],
        },
      },
    };

    const newDocuments = [
      ...existingDocuments,
      // ...Upload_File?.map(({ uid }) => ({ type: "file--file", id: uid })),
    ];

    // call api to create link document ()
    const documentLink = await createDocumentLink(payloadForLink);
    if (documentLink?.id) {
      const payload = {
        data: {
          type: "activity--activity",
          id: activityId,
          relationships: {
            field_task_documents: {
              data: [
                ...newDocuments,
                {
                  type: "media--link",
                  id: documentLink?.id,
                },
              ],
            },
            field_last_modified_by: {
              data: {
                type: "user--user",
                id: uuid,
              },
            },
          },
        },
      };
      addTaskDocuments(payload);
    }
  };

  return (
    <>
      <div onClick={showDrawer}> Link</div>

      <Drawer
        title="Attach Link"
        maxWidth={425}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            <Button
              loading={addDocumentLoading}
              onClick={(e) => {
                form.submit();
              }}
              type="primary"
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={
                  <div>
                    Link Path{" "}
                    <Tooltip title="URL should contain HTTP // or HTTPS // to be a valid URL link.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                name="link_path"
                rules={[
                  {
                    required: true,
                    message: "Please input link",
                  },
                  {
                    type: "url",
                    message: "Invalid link",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Link Name"
                name="link_name"
                rules={[
                  {
                    required: true,
                    message: "Please input link name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
export default TaskDocumentLink;
