import React, { useEffect, useState } from "react";
import { Table, Button, Modal, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  ContactDetailRoute,
  ReservationDetailRoute,
} from "../../../../url-routes/urlRoutes";
import moment from "moment";
import { Country } from "country-state-city";
import { UnorderedListOutlined } from "@ant-design/icons";
import { mergeDuplicateContactApi } from "../../../../apis/contactApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

const authData = JSON.parse(localStorage.getItem("authorization"));
const allowMergeContact = authData?.permissions?.includes("merge contact");
const countriesList = Country.getAllCountries();
const getPrimaryAddress = (address) => {
  return (
    <>
      {address?.field_address_field_field_address_line_1
        ? `${address?.field_address_field_field_address_line_1}, `
        : ""}
      {address?.field_address_field_field_address_line_2
        ? `${address?.field_address_field_field_address_line_2}, `
        : ""}
      {address?.field_address_field_field_city
        ? `${address?.field_address_field_field_city}, `
        : ""}
      {address?.field_address_field_field_state
        ? `${address?.field_address_field_field_state}, `
        : ""}
      {address?.field_address_field_field_country
        ? `${countriesList.find(
          (country) =>
            country.isoCode === address.field_address_field_field_country
        )?.name
        }, `
        : ""}
      {address?.field_address_field_field_postal_code
        ? `${address.field_address_field_field_postal_code}`
        : ""}
    </>
  );
};
function DuplicateContactList({
  duplicateList,
  contactData,
  setDuplicateListTrigger,
  duplicateListTrigger,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resList, setResList] = useState([]);
  const [loadingMerge, setLoadingMerge] = useState(false);
  const [currentLoaderVal, setCurrentLoaderVal] = useState(null);

  const columns = [
    {
      title: "Contact ID",
      dataIndex: "contact_id",
      render: (value, recordObj) => {
        return (
          <Link to={`${ContactDetailRoute}?id=${recordObj?.key}`}>{value}</Link>
        );
      },
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      render: (value, recordObj) => {
        return (
          <>
            <Link to={`${ContactDetailRoute}?id=${recordObj?.key}`}>
              {value}
            </Link>
            <div>
              {getPrimaryAddress(recordObj?.listItem?.address?.[0] || {})}
            </div>
          </>
        );
      },
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      render: (value) => {
        return value ? (
          <>
            <span>{moment(value, "YYYY-MM-DD").format("MM/DD/YYYY")}</span>
            <span className="span_text" style={{ marginLeft: "10px" }}>
              {`Age ${moment(value, "YYYY-MM-DD").fromNow(true)}`}
            </span>
          </>
        ) : null;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Reservation",
      dataIndex: "reservation",
      render: (value, recordObj) => {
        return (
          <>
            <span>{value}</span>
            {recordObj?.listItem?.reservations?.length ? (
              <UnorderedListOutlined
                style={{
                  marginLeft: "10px",
                  fontSize: "20px",
                  color: "#1A7F93",
                }}
                onClick={() => {
                  showReservationModal(recordObj?.listItem?.reservations);
                }}
              />
            ) : null}
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (value, recorObj) => {
        return allowMergeContact ? (
          <Button
            type="primary"
            loading={loadingMerge && currentLoaderVal === recorObj?.contact_id}
            onClick={() => {
              setCurrentLoaderVal(recorObj?.contact_id);
              mergeDuplicateContact(
                recorObj?.contact_id,
                contactData?.drupal_internal__id
              );
            }}
          >
            Merge
          </Button>
        ) : null;
      },
    },
  ];

  const resColumns = [
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
    },
    {
      title: "Reservation Name",
      dataIndex: "reservation_name",
    },
  ];

  useEffect(() => {
    if (duplicateList.length) {
      setData(
        duplicateList?.map((listItem) => {
          return {
            listItem: listItem,
            key: listItem?.uuid,
            contact_id: listItem?.id,
            full_name: listItem?.name,
            birth_date: listItem?.field_date_of_birth,
            gender: listItem?.field_gender,
            email: listItem?.field_email_address,
            reservation: listItem?.reservations?.length || 0,
            action: "",
          };
        })
      );
    }
  }, [duplicateList]);

  const showReservationModal = (reservations) => {
    if (reservations.length) {
      setResList(
        reservations?.map((reservation) => ({
          reservation_id: (
            <Link to={`${ReservationDetailRoute}?id=${reservation?.uuid}`}>
              {reservation.id}
            </Link>
          ),
          reservation_name: (
            <Link to={`${ReservationDetailRoute}?id=${reservation?.uuid}`}>
              {reservation.name}
            </Link>
          ),
        }))
      );
    } else {
      setResList([]);
    }
    setIsModalOpen(true);
  };
  const hideReservationModal = () => {
    setResList([]);
    setIsModalOpen(false);
  };

  const mergeDuplicateContact = async (currentId, sourceId) => {
    const payload = {
      data: {
        source_contact_id: Number(sourceId),
        duplicate_contact_id: Number(currentId),
      },
    };
    if (currentId && sourceId) {
      setLoadingMerge(true);

      const response = await mergeDuplicateContactApi(payload);
      if (response?.status) {
        if (response?.data?.status) {
          message.success("Contact Merged Successfully");
          navigate(`${ContactDetailRoute}?id=${contactData.id}`);
          // setDuplicateListTrigger(!duplicateListTrigger);
        } else {
          message.error(response?.data?.data || SOMETHING_WENT_WRONG);
        }
        setLoadingMerge(false);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setLoadingMerge(false);
      }
    } else {
      message.error("ID Is Missing");
    }
  };
  return (
    <>
      <div className="duplicate-table__heading">
        <h3>
          Source Contact:{" "}
          {`${contactData?.name || ""} (${contactData?.drupal_internal__id})`}
        </h3>
      </div>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Modal
        title="Reservations"
        open={isModalOpen}
        onOk={hideReservationModal}
        onCancel={hideReservationModal}
        // maskClosable={false}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <div className="modal_tablebox">
          <Table
            className="modal_table"
            columns={resColumns}
            dataSource={resList}
            pagination={false}
            scroll={{ y: 350 }}
          />
        </div>
      </Modal>
    </>
  );
}

export default DuplicateContactList;
