import { Grid, Layout, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import AddonDetailsContainer from "../../../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AddonDetailsContainer";
import AddonDetailsHeader from "../../../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AddonDetailsHeader";
import Searchevents_menu from "../../../../components/Events/EventsSearchbar";
import { getSingleEventApi } from "../../../../apis/eventsApi";
import { useSelector } from "react-redux";
import { getSingleEvent } from "../../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

function AddonDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    singleEvent,
    isEventStatusChanged,
    eventCreation,
    isAssetUpdated,
    isAssetDeleted,
  } = useSelector((state) => state.events);
  const { singleEventResponse, loadingSingleEvent } = singleEvent;
  const { eventCreationResponse, loadingEventCreation } = eventCreation;
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const [addonType, setAddonType] = useState("");
  const [singleAddonData, setSingleAddonData] = useState({});
  const navigate = useNavigate()
  const addonDetailsRef = useRef(false);
  const breadCrumbs = [
    { title: "Events", path: "/events/listing" },
    { title: "Detail", path: `/event/details?id=${eventId}` },
    { title: "Add-Ons", path: `/event/details?id=${eventId}#Add-Ons` },
    { title: "Add-Ons Detail", path: `` },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view published fees entities");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    if (addonDetailsRef.current) {
      if (singleEventResponse.status) {
        setSingleAddonData(singleEventResponse?.data?.data);
        setAddonType(singleEventResponse?.data.data.field_add_ons_type?.key);
        const { type, langcode, drupal_internal__id, id } =
          singleEventResponse?.data?.data;
        // updateRecentlyViewed(type, id, langcode, drupal_internal__id);
      } else {
        message.error(
          singleEventResponse?.data?.message || SOMETHING_WENT_WRONG
        );
        setSingleAddonData({});
      }
    }
    addonDetailsRef.current = true;
  }, [singleEventResponse]);

  useEffect(() => {
    dispatch(getSingleEvent(addonId));
  }, [
    isEventStatusChanged,
    isAssetUpdated,
    isAssetDeleted,
    eventCreationResponse,
    addonId,
  ]);

  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchevents_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <AddonDetailsHeader
              addonId={addonId}
              eventId={eventId}
              singleAddonData={singleAddonData}
              loadingData={loadingSingleEvent}
            />
          </div>
        </Layout>
        <AddonDetailsContainer
          addonId={addonId}
          eventId={eventId}
          singleAddonData={singleAddonData}
          loadingData={loadingSingleEvent}
          addonType={addonType}
        />
      </Layout>
    </Layout>
  );
}

export default AddonDetailsPage;
