import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Skeleton, Tooltip, message } from "antd";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  cloneInventoryApi,
  deleteInventoryApi,
} from "../../../../../../../apis/eventsApi";
import {
  AddonCategoryDetailsInventoryRoute,
  AddonCategoryDetailsRoute,
  EventCategoryDetailsInventoryRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../../../url-routes/urlRoutes";
import {
  INVENTORY_ID_REQUIRED,
  SOMETHING_WENT_WRONG,
} from "../../../../../../../utils/constants";

const InventoryDetailHeader = ({
  inventoryData,
  inventoryLoading,
  inventoryId,
  eventId,
  addonId,
  categoryId,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { field_category } = inventoryData || {};
  const { meta } = field_category || {};

  function redirectToEventOrAddon() {
    return addonId
      ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
      : `${EventDetailsRoute}?id=${eventId}#Accommodations`;
  }

  function redirectToCategoryDetail() {
    return addonId
      ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`;
  }

  function redirectToEditPage(editId) {
    if (!editId) {
      message.error(INVENTORY_ID_REQUIRED);
      return;
    }
    return addonId
      ? `${AddonCategoryDetailsInventoryRoute}?editId=${editId}&id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsInventoryRoute}?editId=${editId}&id=${eventId}&categoryId=${categoryId}`;
  }

  const deleteInventory = async (deleteId) => {
    setIsLoading(true);
    const response = await deleteInventoryApi(deleteId);
    setIsLoading(false);
    if (response.status) {
      message.success("Inventory Deleted Successfully");
      navigate(redirectToCategoryDetail());
    } else {
      message.error(
        response?.data?.response?.data?.message || response?.data?.response?.data?.errors?.[0]?.detail ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this inventory?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteInventory(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const cloneThisInventory = async (payload) => {
    setIsLoading(true);
    const cloneRes = await cloneInventoryApi(payload);
    if (cloneRes.status) {
      message.success("Inventory Cloned Successfully");
      form.resetFields();
      navigate(redirectToCategoryDetail());
    } else {
      message.error(
        cloneRes?.data?.response?.data?.message ||
          cloneRes?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
    setIsLoading(false);
  };

  const onFinish = ({ duplicate_count }) => {
    const payload = {
      inventory_uuid: inventoryId,
      clone: duplicate_count,
    };

    cloneThisInventory(payload);
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column flex_gap" style={{ gap: "1rem" }}>
          <ArrowLeftOutlined className="" onClick={() => navigate(-1)} />
          <div className="">
            {inventoryLoading ? (
              <>
                <Skeleton.Input
                  active={true}
                  style={{ width: "250px", height: "55px" }}
                />
              </>
            ) : (
              <>
                {" "}
                <p style={{ margin: "0px" }}>
                  {inventoryData?.field_item_name || ""} (
                  {inventoryData?.drupal_internal__id || ""})
                </p>
                <div className="header_res">
                  <Link to={redirectToEventOrAddon()}>
                    <p
                      className="header_smalltext cta_hover_color"
                      style={{ margin: "0px" }}
                    >
                      {`${meta?.event_data?.event_name || ""} (${
                        meta?.event_data?.event_id || ""
                      })`}
                    </p>
                  </Link>
                  <p className="header_smalltext cta_hover_color"> {`>`} </p>
                  <Link to={redirectToCategoryDetail()}>
                    <p
                      className="header_smalltext cta_hover_color"
                      style={{ margin: "0px" }}
                    >
                      {`${meta?.category_name || ""} (${
                        meta?.drupal_internal__target_id || ""
                      }) `}
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </h3>

        <div className="breadcrumb_buttons">
          <Tooltip placement="top" title="Edit">
            <Link to={redirectToEditPage(inventoryId)}>
              <Button
                type="y"
                disabled={inventoryLoading || isLoading}
                icon={<EditOutlined />}
              />
            </Link>
          </Tooltip>

          <Tooltip placement="top" title="Clone">
            <Button
              onClick={showModal}
              disabled={inventoryLoading || isLoading}
              type="y"
              icon={<CopyOutlined />}
            />
          </Tooltip>

          {!inventoryLoading && (
            <Modal
              footer={[
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Copy
                </Button>,
              ]}
              title="Copy Inventory"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <div className="copy_inventory_modal">
                <p>
                  Please specify the number of duplicates you would like to
                  generate for this inventory.
                </p>

                <Form
                  name="basic"
                  form={form}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item label="Duplicate Count" name="duplicate_count">
                    <Input
                      type="number"
                      min="1"
                      style={{
                        width: 100,
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          )}

          <Tooltip placement="top" title="Delete">
            <Button
              className="delete_btn"
              type="y"
              disabled={inventoryLoading || isLoading}
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.preventDefault();
                onDeleteHandler(inventoryId);
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailHeader;
