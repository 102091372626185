import { Table, Divider, Checkbox, Switch, Modal, Tag, Button } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import React, { useState } from "react";
import moment from "moment";
import { ReservationEditRoute } from "../../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const ReservationAdditionalInfo = ({
  reservationData,
  reservationId,
  loadingReservationData,
}) => {
  const data = [
    {
      key: "1",
      label: "Celebration",
      input: reservationData?.field_celebration?.name || "",
    },
    {
      key: "2",
      label: "Celebration Date",
      input: reservationData?.celebration_date
        ? moment(reservationData?.celebration_date).format("MM/DD/YYYY")
        : "",
    },
    {
      key: "3",
      label: "Surprise",
      input: <Switch checked={reservationData?.field_surprise} disabled />,
    },
    {
      key: "4",
      label: "Traveler Comments",
      input: reservationData?.traveler_comments || "",
    },
    {
      key: "5",
      label: "Event Coordinator Notes and Potential Challenges",
      input:
        reservationData?.event_coordinator_notes_potential_challenges || "",
    },
    {
      key: "6",
      label: "Event Partner Notes",
      input: reservationData?.event_partner_notes || "",
    },
    {
      key: "7",
      label: "Supplier Notes",
      input: reservationData?.supplier_notes || "",
    },
    {
      key: "8",
      label: "Signature Services Notes",
      input: reservationData?.signature_services_notes || "",
    },
    {
      key: "9",
      label: "Cancellation Comments",
      input: reservationData?.cancellation_comments || "",
    },
  ];

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Additional Information</h4>

        {permissionChecker("add/edit reservation") ? <span>
          <Link to={`${ReservationEditRoute}?editId=${reservationId}`}>
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span> : null}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        columns={columns}
        showHeader={false}
        dataSource={data}
        size="small"
        pagination={false}
        loading={{ spinning: loadingReservationData, size: "large" }}
      />
    </div>
  );
};
export default ReservationAdditionalInfo;
