import { Button, Skeleton } from "antd";
import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../url-routes/urlRoutes";
import { CloseOutlined } from "@ant-design/icons";

function ReservationPayment_header({
  form,
  creationLoading,
  reservationData,
  loadingReservationData,
}) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />
          {loadingReservationData ? (
            <Skeleton.Input active={true} style={{ width: "250px" }} />
          ) : (
            <div className="header_res">
              <p>Schedule Payment</p>
              <Link
                to={`${ReservationDetailRoute}?id=${reservationData?.id}`}
                className="header_smalltext"
              >
                {`${reservationData?.name} - ${reservationData?.drupal_internal__id}`}
              </Link>
            </div>
          )}
        </h3>
        <div
          className="breadcrum_buttons"
          style={{ display: "flex", gap: "10px" }}
        >
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={creationLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReservationPayment_header;
