import React, { useEffect, useState } from "react";
import Res_table from "../../../../../Forms/Res_editguest_form/Res_table";
import { Select, Form, Input, Button, DatePicker, Switch } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { TimePicker } from "antd";
import * as moment from "moment";
import { TRAVELER_REPORTING_ROLES } from "../../../../../../utils/constants";

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;
const TravelerForm = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [tableData, setTableData] = useState({});

  const {
    reservationStatus,
    data,
    index,
    setCopiedIndex,
    relations,
    relationLoading,
    arrivalAirlineLoading,
    arrivalAirline,
    arrivalTransfer,
    arrivalTransferLoading,
    departureAirline,
    departureAirlineLoading,
    departureTransferLoading,
    departureTransfer,
    preArrivalAirlineLoading,
    preArrivalAirline,
    extArrivalTransferLoading,
    extArrivalTransfer,
    extDepartureAirlineLoading,
    extDepartureAirline,
    extDepartureTransferLoading,
    extDepartureTransfer,
    postExtArrivalAirlineLoading,
    postExtArrivalAirline,
    postExtArrivalTransferLoading,
    postExtArrivalTransfer,
    postExtDepartureAirlineLoading,
    postExtDepartureAirline,
    postExtDepartureTransferLoading,
    postExtDepartureTransfer,
  } = props;

  useEffect(() => {
    let age =
      data.contact.field_date_of_birth &&
      moment().diff(data?.contact?.field_date_of_birth, "years");
    let dropdownData = {
      name:
        data.contact.data !== null
          ? `${data.contact.field_first_name
            ? data.contact.field_first_name + " "
            : ""
            }${data.contact.field_middle_name
              ? data.contact.field_middle_name + " "
              : ""
            }${data.contact.field_last_name ? data.contact.field_last_name : ""
            }`.trim()
          : data?.name,
      dob:
        data.contact.field_date_of_birth &&
        moment(data?.contact?.field_date_of_birth).format("L"),
      age: age,
      email: data?.contact?.field_email_address,
      relationship: data?.field_relation_traveler?.name,
      relationship_id:
        data?.field_relation_traveler?.drupal_internal__revision_id,
      reservationStatus,
      travellerUUid: data?.id,
    };
    setTableData(dropdownData);
  }, [data]);

  return (
    <>
      <div className="ant-collapse-header edit-traveller-wrapper">
        <div className={`ict-custom-accordion custom-collapse_form ${showForm ? "accordion-form-wrapper" : ""}`}>
          <div className="ict-custom-accordion--header">
            <Button type="link">
              {showForm ? (
                <DownOutlined
                  className="down_color"
                  onClick={() => setShowForm(!showForm)}
                />
              ) : (
                <RightOutlined
                  className="down_color"
                  onClick={() => setShowForm(!showForm)}
                />
              )}
            </Button>
            {Object.keys(tableData).length ? (
              <Res_table
                tableData={tableData}
                index={index}
                setCopiedIndex={setCopiedIndex}
                relations={relations}
                relationLoading={relationLoading}
              />
            ) : null}
          </div>

          <div className="ict-custom-accordion--content">
            <div className="res_form_main">
              <div className="res_form_text">
                <h4>Traveler Details</h4>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Gender"
                name={`field_gender-${index}`}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name={`field_role-${index}`}
                label="Role"
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  <option value="Primary">Primary</option>
                  <option value="Sponsor">Sponsor</option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Staff Price"
                name={`field_staff_price-${index}`}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Reporting Role"
                name={`field_reporting_roles-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={TRAVELER_REPORTING_ROLES?.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                ></Select>
              </Form.Item>

              <div className="res_form_text">
                <h4>Personal Flight Details (Arrival/Departure)</h4>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Flight Arrival"
                name={`flight_arrival-${index}`}
              >
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Arrival Airline"
                name={`arrival_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={arrivalAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Arrival Date/Time"
                name={`arrival_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col_single">
                <Form.Item
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 9 }}
                  name={`arrival_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Arrival Last Depart City"
                name={`arrival_last_depart_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Arrival Last Depart Date/Time"
                name={`arrival_last_depart_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  name={`arrival_last_depart_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Arrival Transfer Date/Time"
                name={`arrival_transfer_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  name={`arrival_transfer_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Arrival Record Locator"
                name={`arrival_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Arrival Transfer"
                name={`arrival_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={arrivalTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={arrivalTransferLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Departure Transfer"
                name={`departure_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={arrivalTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={arrivalTransferLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Departure Transfer Date/Time"
                name={`departure_transfer_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col label-col--smx">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  name={`departure_transfer_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                className="asterick-align"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Air Notes"
                name={`air_notes-${index}`}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Flight Departure"
                name={`flight_departure-${index}`}
              >
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Departure Airline"
                name={`departure_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={departureAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={departureAirlineLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Departure Date/Time"
                name={`departure_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col_single">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  name={`departure_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Arrival City"
                name={`arrival_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Departure Record Locator"
                name={`departure_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <div className="res_form_text">
                <h4>
                  Personal Flight Details (Pre Ext Arrival / Departure)
                </h4>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival Flight"
                name={`pre_extarrival_flight-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival Airline"
                name={`pre_extarrival_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={preArrivalAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={preArrivalAirlineLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Pre Ext Arrival Date/Time"
                name={`pre_extarrival_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" ormat="MM/DD/YYYY" />
              </Form.Item>

              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  label=""
                  name={`pre_extarrival_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival Last Depart City"
                name={`pre_extarrival_last_depart_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Pre Ext Arrival Last Depart Date/Time"
                name={`pre_extarrival_last_depart_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>

              <div className="label-col label-col--sm">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  name={`pre_extarrival_last_depart_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival Record Locator"
                name={`pre_extarrival_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival Transfer"
                name={`extarrival_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={extArrivalTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={extArrivalTransferLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Departure Flight"
                name={`pre_extdeparture_flight-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Departure Airline"
                name={`pre_extdeparture_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={extDepartureAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={extDepartureAirlineLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Pre Ext Departure Date/Time"
                name={`pre_extdeparture_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" ormat="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  label=""
                  name={`pre_extdeparture_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Arrival City"
                name={`pre_extarrival_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Departure Record Locator"
                name={`pre_extdeparture_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Pre Ext Departure Transfer"
                name={`extdeparture_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={extDepartureTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                ></Select>
              </Form.Item>

              <div className="res_form_text">
                <h4>
                  Personal Flight Details (Post Ext Arrival / Departure)
                </h4>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival Flight"
                name={`post_extarrival_flight-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival Airline"
                name={`post_extarrival_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={postExtArrivalAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={postExtArrivalAirlineLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Post Ext Arrival Date/Time"
                name={`post_extarrival_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" ormat="MM/DD/YYYY" />
              </Form.Item>
              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  label=""
                  name={`post_extarrival_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival Last Depart City"
                name={`post_extarrival_last_depart_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Post Ext Arrival Last Depart Date/Time"
                name={`post_extarrival_last_depart_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" ormat="MM/DD/YYYY" />
              </Form.Item>

              <div className="label-col label-col--sm">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  label=""
                  name={`post_extarrival_last_depart_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival Record Locator"
                name={`post_extarrival_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival Transfer"
                name={`post_extarrival_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={postExtArrivalTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={postExtArrivalTransferLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Departure Flight"
                name={`post_extdeparture_flight-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Departure Airline"
                name={`post_extdeparture_airline-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={postExtDepartureAirline?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={postExtDepartureAirlineLoading}
                ></Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 9 }}
                label="Post Ext Departure Date/Time"
                name={`post_extdeparture_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" ormat="MM/DD/YYYY" />
              </Form.Item>

              <div className="label-col">
                <Form.Item
                  wrapperCol={{ span: 9 }}
                  label=""
                  name={`post_extdeparture_time-${index}`}
                >
                  <TimePicker format={'HH:mm'} use12Hours={true} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Arrival City"
                name={`post_extarrival_city-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Departure Record Locator"
                name={`post_extdeparture_record_locator-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Post Ext Departure Transfer"
                name={`post_extdeparture_transfer-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={postExtDepartureTransfer?.map((tag) => ({
                    value: tag?.drupal_internal__revision_id,
                    label: tag?.name,
                  }))}
                  loading={postExtDepartureTransferLoading}
                ></Select>
              </Form.Item>

              <div className="res_form_text">
                <h4>Operations</h4>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Doc Request"
                name={`field_doc_request-${index}`}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Doc Request Type"
                name={`field_docrequest_type-${index}`}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <option value="Paper Docs">Paper Docs</option>
                  <option value="Early Docs">Early Docs</option>
                  <option value="E-Docs">E-Docs</option>
                  <option value="Other">Other</option>
                </Select>
              </Form.Item>

              <div className="textarea_row">
                <Form.Item
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                  label="Doc Notes"
                  name={`field_doc_notes-${index}`}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </div>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Insurance Declined"
                name={`field_insurance_declined-${index}`}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Contract Received Date"
                name={`contract_rcvd_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Web Terms Accept Method"
                name={`field_web_terms-${index}`}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Web Terms Accept Date"
                name={`web_terms_accept_date-${index}`}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelerForm;
