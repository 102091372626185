import { Pagination, Table } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { decodeContent } from "../../../../../utils/contentParser";
import DataLoader from "../../../../../others/Loaders/DataLoader";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (record, values) => {
      return (
        <>
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                position: "relative",
                margin: "0",
                opacity: "0.5",
              }}
            >
              {values.id}
            </p>
            <p>{decodeContent(record)}</p>
          </div>
        </>
      );
    },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: (record) => {
      return (
        <>
          <div className="phone_row" style={{ display: "flex", gap: "10px" }}>
            <>
              <PhoneOutlined
                style={{
                  transform: "rotate(90deg)",
                  width: "25px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              />
              <p>{record}</p>
            </>
          </div>
        </>
      );
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (record) => {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <MailOutlined style={{ paddingTop: "4px" }} />
            <p>{record}</p>
          </div>
        </>
      );
    },
  },

  {
    title: "Organization Type",
    dataIndex: "organization_type",
  },

  {
    title: "Organization",
    dataIndex: "organization",
  },
];

const ContactListModal = (props) => {
  const [contactData, setContactData] = useState();
  const [filteredContact, setFilterContact] = useState();
  const { contacts, selectedRowKeys, setSelectedRowKeys, loading,isLoading } = props;
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    if (contacts) {
      let data = [];
      contacts.forEach((element, index) => {
        data.push({
          key: index,
          id: element.id,
          name: element.name,
          phone: element.field_cell_phone_extension
            ? `${element.field_cell_phone_extension}-${element.field_cell_phone}`
            : null,
          email: element.field_email_address,
          organization_type: element.field_organization_type,
          organization: element.field_organization,
        });
      });
      setContactData(data);
    }
  }, [contacts]);

  //   useEffect(() => {
  //     if (contactData) {
  //       const filteredData = contactData.filter((val) =>
  //         val.name.toLowerCase().includes(searchVal)
  //       );
  //       setFilterContact(filteredData);
  //     } else {
  //       setFilterContact(contactData);
  //     }
  //   }, [searchVal, contactData]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "radio",
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  if(isLoading){
    return <DataLoader/>
  }
  return (
    <>
    {contacts.length ? (
      <Table
      className="modal_table"
      rowSelection={rowSelection}
      columns={columns}
      loading={loading}
      dataSource={contactData}
      pagination={false}
    />
    ):null}
    </>
  );
};
export default ContactListModal;
