import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";

function Breadcrumb_adddropdown() {
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <div className="addnote_content">
          <div className="breadcrumb_title">
            <h3 className="flex_row_column">
              <CloseOutlined className="back-icon-profile" />
              <div className="header_res">
                <p>Add Dropdown</p>
              </div>
            </h3>
          </div>
        </div>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button type="primary">Save</Button>
          <Button>Cancel</Button>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb_adddropdown;
