import { Grid, Layout } from "antd";
import React, { useEffect } from "react";
import CustomDropdownSearchbar from "../../../components/Admin/CustomDropdown/CustomDropdownSearchbar";
import EmailTemplatesHeader from "../../../components/Admin/EmailTemplates/EmailTemplatesHeader";
import ManageRolesContainer from "../../../components/Admin/ManageRoles/ManageRolesContainer";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EmailTemplatesContainer from "../../../components/Admin/EmailTemplates/EmailTemplatesContainer";
import { useNavigate } from "react-router-dom";
import { permissionChecker } from "../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

const EmailTemplatesPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Email Templates", path: "/email_templates" },
    
  ];
  const navigate = useNavigate()
  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu email templates");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CustomDropdownSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EmailTemplatesHeader />
          </div>
          <Content className="site-layout-background">
            <EmailTemplatesContainer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EmailTemplatesPage;
