import { Table, Button, Divider } from "antd";
import React from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const InspirationInitial = ({ allDetails, allDetailsLoading }) => {
  const data = [
    {
      key: "1",
      label: "Name",
      input: <>{allDetails?.field_username || ""}</>,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0">Inspiration Initial Contact Person</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default InspirationInitial;
