import { Table, Button, Divider,Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useState } from 'react';


const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const data = [
  {
    key: "1",
    label: "Dining Time Requested",
    input: "Early Seating",
  },
  {
    key: "2",
    label: "Insurance Declined",
    input:  <>
    <Switch  disabled defaultChecked  />
    </>,
  },
  {
    key: "3",
    label: "Doc Request",
    input:   <>
    <Switch disabled defaultChecked />
  
  </>,
  },
  {
    key: "4",
    label: "Web Terms Accept Date",
    input: "10/19/2022 02:59 PM",
  },
  {
    key: "5",
    label: "Web Terms Accept IP",
    input: "69.129.241.61",
  },


];

function Res_operations() {
 
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Operations</h4>
        <span>
          <Link to="/Reservation_editform">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Res_operations;
