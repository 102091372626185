import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllVocabulariesApi,
  getSingleVocabularyApi,
  createTermApi,
  updateTermApi,
  deleteTermApi,
  searchVocabularyApi,
  updateSingleVocabularyLocationsApi,
} from "../../../apis/customDropDownApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

export const getAllVocabularies = createAsyncThunk(
  "customDropDown/getAllVocabularies",
  async (data) => {
    const allVocabularies = await getAllVocabulariesApi(data);
    if (allVocabularies.status) {
      return allVocabularies?.data?.data;
    } else {
      return SOMETHING_WENT_WRONG;
    }
  }
);

export const getSingleVocabulary = createAsyncThunk(
  "customDropDown/getSingleVocabulary",
  async (vocabularyName) => {
    const getVocabulary = await getSingleVocabularyApi(vocabularyName);
    console.log(getVocabulary.data.data, "getVocabulary");
    if (getVocabulary.status) {
      return { data: getVocabulary.data.data, status: true };
    } else {
      return { data: getVocabulary.data, status: false };
    }
  }
);

export const createTerm = createAsyncThunk(
  "customDropDown/createTerm",
  async (data) => {
    const termCreated = await createTermApi(data.data, data.myParam);
    return termCreated;
  }
);

export const updateTerm = createAsyncThunk(
  "customDropDown/updateTerm",
  async (data) => {
    const termUpdated = await updateTermApi(data.data, data.myParam, data.id);
    return termUpdated;
  }
);

export const deleteTerm = createAsyncThunk(
  "customDropDown/deleteTerm",
  async (data) => {
    const termDeleted = await deleteTermApi(data);
    return { ...termDeleted, id: data.id };
  }
);

export const searchVocabulary = createAsyncThunk(
  "customDropDown/searchVocabulary",
  async (data) => {
    console.log("thunks");
    const searchTerms = await searchVocabularyApi(data);
    if (searchTerms.status) {
      return { data: searchTerms?.data?.data, status: true };
    } else {
      return { data: searchTerms, status: false };
    }
  }
);

// export const getSingleVocabularyLocations = createAsyncThunk(
//   "customDropDown/getSingleVocabularyLocations",
//   async (vocabularyName) => {
//     const getVocabulary = await getSingleVocabularyApi(vocabularyName);
//     console.log(getVocabulary.data.data, "getVocabulary");
//     if (getVocabulary.status) {
//       return { data: getVocabulary.data.data, status: true };
//     } else {
//       return { data: getVocabulary.data, status: false };
//     }
//   }
// );

export const updateSingleVocabularyWeight = createAsyncThunk(
  "customDropDown/updateSingleVocabularyWeight",
  async (data) => {
    let updateVocabularyWeight = await updateSingleVocabularyLocationsApi(data);
    return updateVocabularyWeight;
  }
);

export const customDropDownSlice = createSlice({
  name: "customDropDown",
  initialState: {
    allVocabularies: [],
    singleVocabulary: {
      status: false,
      singleVocabularyData: [],
      singleVocabularyResponse: {},
      singleVocabularyError: null,
    },
    singleVocabularyLocations: {
      orderedTerms: [],
      error: null,
      updateWeightLoading: false,
      updateWeightResponse: {},
    },
    createdTermResponse: {},
    updatedTermResponse: {},
    deletedTermResponse: {},
    loading: true,
    error: null,
    responseMessage: "",
    search: "",
  },
  reducers: {
    clearSingleVocabulary: (state, action) => {
      // state.singleVocabulary = {};
    },
    clearCreateTerm: (state, action) => {
      // state.createTerm = {};
    },
    clearUpdateTerm: (state, action) => {
      // state.updateTerm = {};
    },
    setLoadig: (state, action) => {
      state.loading = action.payload;
    },
    addOption: (state, action) => {
      // if item is already present then don't add it
      if (
        !state.singleVocabulary.find((item) => item.id === action.payload.id)
      ) {
        state.singleVocabulary.push(action.payload);
      }
    },
    deleteOption: (state, action) => {
      state.singleVocabulary = state.singleVocabulary.filter(
        (item) => item.id !== action.payload
      );
    },
    updateOption: (state, action) => {
      //find if id is same then olny update
      state.singleVocabulary = state.singleVocabulary.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSingleVocabularyLocations: (state, action) => {
      const terms = action?.payload?.map((val, i) => {
        return { uuid: val.key, weight: i };
      });
      state.singleVocabularyLocations.orderedTerms = [...terms];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVocabularies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVocabularies.fulfilled, (state, action) => {
      state.loading = false;
      state.allVocabularies = action.payload;
    });
    builder.addCase(getAllVocabularies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleVocabulary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleVocabulary.fulfilled, (state, action) => {
      state.loading = false;
      // state.singleVocabulary.status = true;
      state.singleVocabulary.singleVocabularyData = action.payload.data;
    });
    builder.addCase(getSingleVocabulary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.data;
    });
    builder.addCase(createTerm.pending, (state) => {
      state.loading = true;
      // state.error = null
    });
    builder.addCase(createTerm.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.singleVocabulary.singleVocabularyData.push(
          action.payload.data.data
        );
      }
      state.responseMessage = {
        ...action.payload,
        genericMessage: action.payload.status
          ? "Successfully Added"
          : SOMETHING_WENT_WRONG,
      };
      state.TermResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(createTerm.rejected, (state, action) => {
      state.loading = false;
      state.responseMessage = { success: false, text: SOMETHING_WENT_WRONG };
      state.error = action.payload;
    });
    builder.addCase(updateTerm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTerm.fulfilled, (state, action) => {
      action.payload.status &&
        (state.singleVocabulary.singleVocabularyData =
          state.singleVocabulary.singleVocabularyData.map((item) => {
            if (item.id === action.payload.data.data.id) {
              return {
                ...item,
                ...action.payload.data.data,
              };
            } else {
              return item;
            }
          }));
      // state = {...state, updatedTermResponse: action.payload, createdTermResponse :{}, deletedTermResponse: {}}
      state.responseMessage = {
        ...action.payload,
        genericMessage: action.payload.status
          ? "Successfully Updated"
          : SOMETHING_WENT_WRONG,
      };
      state.updatedTermResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(updateTerm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.responseMessage = {
        success: false,
        message: SOMETHING_WENT_WRONG,
      };
    });
    builder.addCase(deleteTerm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTerm.fulfilled, (state, action) => {
      if (action.payload.status) {
        const newStateData =
          state.singleVocabulary?.singleVocabularyData?.filter(
            (val) => val.id !== action.payload.id
          );
        state.singleVocabulary.singleVocabularyData = [...newStateData];
      }
      state.deletedTermResponse = action.payload;
      state.responseMessage = {
        ...action.payload,
        genericMessage: action.payload.status
          ? "Successfully Deleted"
          : SOMETHING_WENT_WRONG,
      };
      state.loading = false;
    });
    builder.addCase(deleteTerm.rejected, (state, action) => {
      state.error = action.payload;
      state.responseMessage = {
        success: false,
        message: SOMETHING_WENT_WRONG,
      };
      state.loading = false;
    });
    builder.addCase(searchVocabulary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchVocabulary.fulfilled, (state, action) => {
      state.loading = false;
      state.allVocabularies = action.payload.data;
    });
    builder.addCase(searchVocabulary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    ////
    builder.addCase(updateSingleVocabularyWeight.pending, (state) => {
      state.loading = true;
      state.singleVocabularyLocations.updateWeightLoading = true;
    });
    builder.addCase(updateSingleVocabularyWeight.fulfilled, (state, action) => {
      state.loading = false;
      state.singleVocabularyLocations.updateWeightResponse = action.payload;
      state.singleVocabularyLocations.updateWeightLoading = false;
      // state.allVocabularies = action.payload.data;
    });
    builder.addCase(updateSingleVocabularyWeight.rejected, (state, action) => {
      state.loading = false;
      state.singleVocabularyLocations.updateWeightLoading = false;
      state.singleVocabularyLocations.error = false;
      // state.errorsearchVocabulary = action.payload;
    });
  },
});

export const {
  clearSingleVocabulary,
  clearCreateTerm,
  clearUpdateTerm,
  setLoadig,
  addOption,
  deleteOption,
  updateOption,
  setSingleVocabularyLocations,
} = customDropDownSlice.actions;
