import React, { useEffect, useRef, useState } from "react";
import TaskDocuments from "./TaskDocuments";
import { Content } from "antd/lib/layout/layout";
import TaskActivity from "./TaskActivity";
import TaskDetails from "./TaskDetails";
import ActivityConfirmationHeader from "./ActivityConfirmationHeader";
import Resersystem_information from "../../../Reservations_components/Reservationsemail_confirmationfolder/Resersystem_information";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleActivity } from "../../../../redux/features/contactFeatures/contactSlice";
import { Grid, message } from "antd";
import TaskMessage from "./TaskMessage";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const ContactActivityDetailContainer = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [activityDetails, setActivityDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { singleActivity, taskActivityUpdated, documentFileUpdated } =
    useSelector((state) => state.contacts);
  const { loadingSingleActivity, singleActivityResponse } = singleActivity;
  const activityId = searchParams.get("activityId");
  const activityRef = useRef();

  useEffect(()=>{
    const isAllowed = permissionChecker("view published activity entities");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  useEffect(() => {
    if (activityRef.current) {
      if (singleActivityResponse.status) {
        setActivityDetails(singleActivityResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    activityRef.current = true;
  }, [singleActivityResponse]);

  useEffect(() => {
    dispatch(getSingleActivity({ activityId }));
  }, [taskActivityUpdated, documentFileUpdated]);
  return (
    <>
      <div className="events-header">
        <ActivityConfirmationHeader
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </div>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <TaskDetails
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <TaskMessage
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <TaskActivity
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <TaskDocuments
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <Resersystem_information
          activityDetails={activityDetails}
          loadingSingleActivity={loadingSingleActivity}
        />
      </Content>
    </>
  );
};

export default ContactActivityDetailContainer;
