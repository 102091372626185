import React from "react";
import { Col, Row, Form, Input, Divider } from "antd";
import moment from "moment";
import { Country } from "country-state-city";

function FindDuplicateContactData({ contactData, loadingContactData }) {
  const countriesList = Country.getAllCountries();

  const getPrimaryAddress = (address) => {
    return (
      <>
        {address?.field_address_line_1 && `${address?.field_address_line_1}, `}
        {address?.field_address_line_2 && `${address?.field_address_line_2}, `}
        {address?.field_city && `${address?.field_city}, `}
        {address?.field_state && `${address?.field_state}, `}
        {address?.field_country &&
          `${countriesList.find(
            (country) => country.isoCode === address.field_country
          )?.name
          }, `}
        {address?.field_postal_code && `${address.field_postal_code}`}
      </>
    );
  };
  return (
    <div className="duplicate_form">
      <div className="duplicate_heading">
        <h3>
          Source Contact:{" "}
          {`${contactData?.name || ""} (${contactData?.drupal_internal__id})`}
        </h3>
      </div>
      <Divider className="global_divider"></Divider>
      <Row>
        <Col span={12}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item label="Gender" name="username">
              <span className="document_pdf">
                {contactData?.field_gender?.label}
              </span>
            </Form.Item>

            <Form.Item label="Date Of Birth" name="username">
              {contactData?.field_date_of_birth ? (
                <>
                  <span>
                    {moment(
                      contactData?.field_date_of_birth,
                      "YYYY-MM-DD"
                    ).format("MM/DD/YYYY")}
                  </span>
                  <span className="span_text" style={{ marginLeft: "10px" }}>
                    {`Age ${moment(
                      contactData?.field_date_of_birth,
                      "YYYY-MM-DD"
                    ).fromNow(true)}`}
                  </span>
                </>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item label="Email Address" name="username">
              <span className="document_pdf">
                {contactData?.field_email_address}
              </span>
            </Form.Item>

            <Form.Item label="Primary Address" name="username">
              <span className="document_pdf">
                {contactData?.field_address_field?.length
                  ? getPrimaryAddress(contactData?.field_address_field?.[0])
                  : ""}
              </span>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default FindDuplicateContactData;
