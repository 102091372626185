import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Form, Modal, Table, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteRequestApi,
  getSingleRequestApi,
  marketingSourceApi,
} from "../../../../apis/contactApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { getAllRequestsList } from "../../../../redux/features/contactFeatures/contactSlice";
import { EventDetailsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import RequestForm from "./RequestForm";
import "./Requests.less";
import { decodeContent } from "../../../../utils/contentParser";
import { permissionChecker } from "../../../../utils/permisssionChecker";
import { getMarketingSource } from "../../../../apis/eventsApi";

const Requests = ({ contactId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const contactRequestRef = useRef(false);
  const [open, setOpen] = useState("");
  const [requestListData, setRequestListData] = useState([]);
  const [isMarketingCodeSelected, setIsMarketingCodeSelected] = useState(false);
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [marketingDetail, setMarketingDetail] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [getRequestResponse, setGetRequestResponse] = useState([]);
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);
  const [marketingCode, setMarketingCode] = useState([]);
  const [marketingCodeGlobalData, setMarketingCodeGlobalData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [loadingMarketingCode, setLoadingMarketingCode] = useState(false);
  const { allRequestsResponse, loadingAllRequests } = useSelector(
    (state) => state.contacts.allRequests
  );

  useEffect(() => {
    if (contactRequestRef.current) {
      if (allRequestsResponse?.status) {
        setRequestListData(allRequestsResponse?.data || []);
      } else {
        message.error(allRequestsResponse?.message || SOMETHING_WENT_WRONG);
        setRequestListData([]);
      }
    }
    contactRequestRef.current = true;
  }, [allRequestsResponse]);

  useEffect(() => {
    getMarketingDropdownData();
  }, []);
  useEffect(() => {
    dispatch(getAllRequestsList({ contactId }));
  }, [contactId, getRequestResponse]);

  const handleChangeMarketing = () => {
    setIsMarketingCodeSelected(true);
  };
  const getMarketingDropdownData = async () => {
    setLoadingMarketingCode(true);
    const response = await marketingSourceApi();
    if (response.status) {
      setMarketingCodeGlobalData(response?.data?.[0]?.channels);
      setLoadingMarketingCode(false);
    } else {
      setMarketingCodeGlobalData([]);
      setLoadingMarketingCode(false);
    }
  };
  const getMarketingDropdownDataBasedOnEvent = async (id) => {
    setLoadingMarketingCode(true);
    const response = await getMarketingSource(id);
    if (response.status) {
      const allChannels = response?.data.reduce((acc, item) => {
        return acc.concat(item.channels);
      }, []);

      setMarketingCode(allChannels.filter(Boolean));
      setLoadingMarketingCode(false);
      return allChannels;
    } else {
      setMarketingCode([]);
      setLoadingMarketingCode(false);
      return [];
    }
  };

  const valueExists = (id, containArr) => {
    containArr = containArr?.filter(Boolean);
    return containArr?.some((event) => event.uuid === id);
  };

  const showDrawer = async (values, id) => {
    setOpen(values);
    if (id) {
      setIsFormDataLoading(true);
      setRequestId(id);
      const res = await getSingleRequestApi(id);
      if (res.status) {
        const { data } = res?.data;

        let marketingChannels = [];
        let marketingDetail= [];
        if (valueExists(data?.field_event?.id, eventList)) {
          if (data?.field_event?.id) {
            marketingChannels = await getMarketingDropdownDataBasedOnEvent(
              data?.field_event?.id
            );
          }

          if (data?.field_marketing_channel?.id) {
             marketingDetail = marketingChannels.find(
              (details) => data?.field_marketing_channel?.id === details?.uuid
            )?.details;
            setMarketingDetail(marketingDetail);
          }
        }

        form.setFieldsValue({
          request_date: data?.field_request_date
            ? moment(data?.field_request_date)
            : null,
          event: valueExists(data?.field_event?.id, eventList)
            ? data?.field_event?.id
            : null,
          quantity: data?.field_quantity,
          marketing_code: valueExists(
            data?.field_marketing_channel?.id,
            marketingChannels
          )
            ? data?.field_marketing_channel?.id
            : null,
          marketing_detail: valueExists(
            data?.field_marketing_detail?.id,
            marketingDetail
          )
            ? data?.field_marketing_detail?.id
            : null,
          request_status: data?.field_Request_status,
          complete_date: data?.field_complete_date
            ? moment(data?.field_complete_date)
            : null,
          request_type: data?.field_request_type?.id,
        });
        data?.field_event?.id
          ? valueExists(data?.field_event?.id, eventList)
            ? setIsEventSelected(true)
            : setIsEventSelected(false)
          : setIsEventSelected(false);
        data?.field_marketing_channel?.id
          ? valueExists(data?.field_marketing_channel?.id, marketingChannels)
            ? setIsMarketingCodeSelected(true)
            : setIsMarketingCodeSelected(false)
          : setIsMarketingCodeSelected(false);
        setIsFormDataLoading(false);
      }
    } else {
      setRequestId("");
      setIsMarketingCodeSelected(false);
      setIsEventSelected(false);
      setIsFormDataLoading(false);
    }
  };
  const onClose = () => {
    setOpen();
    form.resetFields();
  };

  const deleteRequest = async (deleteId) => {
    const response = await deleteRequestApi(deleteId);
    if (response.status) {
      setGetRequestResponse(response);
      message.success("Request Deleted Successfully");
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this request?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteRequest(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const reqData = requestListData?.map((request) => {
    return {
      key: request?.event_uuid,
      reqId: request?.request_uuid,
      request_type: request?.field_request_type || "",
      request_date: request?.field_request_date
        ? moment(request?.field_request_date, "DD/MM/YYYY").format("MM/DD/YYYY")
        : "",
      event_code: request?.field_event_code || "",
      event: decodeContent(request?.event_name) || "",
      destination: request?.field_destination?.name || "",
      quantity: request?.field_quantity || "",
      marketing:
        request?.field_marketing_code && request?.field_marketing_details?.name
          ? `${request?.field_marketing_code} / ${request?.field_marketing_details?.name}`
          : request?.field_marketing_code ||
            request?.field_marketing_details?.name,
      status: request?.field_Request_status || "",
    };
  });

  const columns = [
    {
      key: "1",
      title: "Request Type",
      dataIndex: "request_type",
    },
    {
      key: "2",
      title: "Request Date",
      dataIndex: "request_date",
    },

    {
      key: "3",
      title: "Event Code",
      dataIndex: "event_code",
      render: (record, recordObj) => {
        return (
          <>
            {permissionChecker("view events") ? (
              <Link to={`${EventDetailsRoute}?id=${recordObj?.key}`}>
                <div>
                  <p>{record}</p>
                </div>
              </Link>
            ) : (
              <div>
                <p>{record}</p>
              </div>
            )}
          </>
        );
      },
    },

    {
      key: "4",
      title: "Event",
      dataIndex: "event",
      render: (record, recordObj) => {
        return (
          <>
            {permissionChecker("view events") ? (
              <Link to={`${EventDetailsRoute}?id=${recordObj?.key}`}>
                <div>
                  <p>{record}</p>
                </div>
              </Link>
            ) : (
              <div>
                <p>{record}</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "5",
      title: "Destination",
      dataIndex: "destination",
    },
    {
      key: "6",
      title: "Quantity",
      dataIndex: "quantity",
    },

    {
      key: "7",
      title: "Marketing",
      dataIndex: "marketing",
    },

    {
      key: "8",
      title: "Status",
      dataIndex: "status",
    },

    {
      key: "9",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex">
              {permissionChecker("add/edit request") ? (
                <EditOutlined
                  className="action_edit"
                  onClick={() => showDrawer("Edit", record.reqId)}
                />
              ) : null}

             {permissionChecker("delete request")?  <DeleteOutlined
                className="action_delete"
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteHandler(record.reqId);
                }}
              /> : null}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="requests action_break">
      <div className="requests_title">
        <h4 className="content_heading mb-0">Requests</h4>
        <RequestForm
          setGetRequestResponse={setGetRequestResponse}
          open={open}
          showDrawer={showDrawer}
          onClose={onClose}
          form={form}
          requestId={requestId}
          setIsMarketingCodeSelected={setIsMarketingCodeSelected}
          setIsEventSelected={setIsEventSelected}
          isEventSelected={isEventSelected}
          isMarketingCodeSelected={isMarketingCodeSelected}
          handleChangeMarketing={handleChangeMarketing}
          isFormDataLoading={isFormDataLoading}
          loadingMarketingCode={loadingMarketingCode}
          marketingCode={marketingCode}
          marketingDetail={marketingDetail}
          eventList={eventList}
          setEventList={setEventList}
          setMarketingDetail={setMarketingDetail}
          setMarketingCode={setMarketingCode}
          getMarketingDropdownDataBasedOnEvent={
            getMarketingDropdownDataBasedOnEvent
          }
        />
      </div>
      <Divider className="global_divider" />
      <Table
        className="request_table"
        columns={columns}
        dataSource={reqData}
        pagination={false}
        loading={{ indicator: <DataLoader />, spinning: loadingAllRequests }}
        size="small"
      />
    </div>
  );
};
export default Requests;
