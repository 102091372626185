import "antd/dist/antd.css";
import axios, { Axios } from "axios";
import { Button, Table, Modal, Input, Checkbox, message, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Select } from "antd";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  deleteOrganisationApi,
  deleteOrganizationApi,
} from "../../../apis/organisationApi";
import { Link } from "react-router-dom";
import { decodeContent } from "../../../utils/contentParser";
import { createFilterTags } from "../../../others/util/filterTagsCreator";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { permissionChecker } from "../../../utils/permisssionChecker";
const Option = Select.Option;

function OrganizationsListContainer({
  organisationsList,
  totalCount,
  isLoading,
  page,
  order,
  count,
  setIsOrgDeleted,
  setUrlParamsObject,
  urlParamsObject,
  isOrgDeleted,
}) {
  const navigate = useNavigate();
  const [deleteList, setDeleteList] = useState([]);
  const { isFilterApplied, searchFilters } = useSelector(
    (state) => state.organizations
  );
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const orgTypeFilter = searchParams.get("org_type");
  const allowDeleteOrganizations = authData?.permissions?.includes(
    "delete organization"
  );
  const allowViewOrganizations =
    authData?.permissions?.includes("view organizations");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const onSelectChange = (selectList) => {
    setDeleteList(selectList);
  };

  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu organization");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  const rowSelection = {
    deleteList,
    onChange: onSelectChange,
  };

  const handleSorting = (value) => {
    if (value === "recently_read") {
      setUrlParamsObject({ count: 10, order: "recently_read", page: 1 });
    } else {
      setUrlParamsObject({ ...urlParamsObject, order: value });
    }
  };

  const deleteOrganisation = async (deleteId) => {
    const payload = {
      data: {
        type: "node--organization",
        id: deleteId,
      },
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
    });

    const response = await deleteOrganizationApi({
      reqHeaders,
      orgId: deleteId,
      payload,
    });
    if (response.status) {
      message.success("Organization Deleted Successfully");
      setIsOrgDeleted(!isOrgDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };
  const onDeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteOrganisation(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const orgranisationsListData = organisationsList?.map(
    (organisation, index) => {
      return {
        key: index + 1 || "",
        uuid: organisation.field_organization_uuid,
        id: organisation.nid || "",
        name: decodeContent(organisation.title || ""),
        email: organisation.field_email_address || "",
        leader_name: organisation.field_leader_name || "",
        primary_contact: organisation.name || "",
        primary_contactId: organisation.field_contact_uuid,
        organization_type: organisation.field_organization_type || "",
      };
    }
  );

  const columns = [
    {
      key: "1",
      title: "Name",
      dataIndex: "name",
      render: (record, values) => {
        return (
          <>
            {allowViewOrganizations ? (
              <Link to={`/organization/details?id=${values.uuid}`}>
                <div>
                  <span>{values.id}</span>
                  <p>{record}</p>
                </div>
              </Link>
            ) : (
              <div>
                <span>{values.id}</span>
                <p>{record}</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "3",
      title: "Organization Type",
      dataIndex: "organization_type",
    },

    {
      key: "4",
      title: "Leader Name",
      dataIndex: "leader_name",
    },

    {
      key: "5",
      title: "Primary Contact",
      dataIndex: "primary_contact",
    },

    {
      key: "6",
      title: "Primary Contact Email Address",
      dataIndex: "email",
      render: (record) => {
        return record ? (
          <>
            <p className="email_wrap">
              <MailOutlined style={{ paddingRight: "6px" }} /> {record}
            </p>
          </>
        ) : (
          ""
        );
      },
    },

    {
      key: "7",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex action_end">
              {allowEditOrganizations ? (
                <Link to={`/organization?editId=${record.uuid}`}>
                  <EditOutlined className="action_edit" />
                </Link>
              ) : null}
              {allowDeleteOrganizations ? (
                <DeleteOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteHandler(record.uuid);
                  }}
                  className="action_delete delete_btn"
                />
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  // if (isLoading) {
  //   return <DataLoader />;
  // }
  return (
    <>
      <div
        style={{
          marginLeft: "10px",
          position: "relative",
          zIndex: "10",
          width: "fit-content",
        }}
      >
        {isFilterApplied && (
          <div className="filter-applied--wrapper">
            <span className="filter-title">Filter(s) Applied:</span>
            {createFilterTags(searchFilters)}
          </div>
        )}
        {
          <>
            <span>Sort by :</span>
            <Select
              disabled={isLoading}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: 165,
                marginLeft: "10px",
                marginRight: "12px",
              }}
              value={urlParamsObject.order}
              onChange={(e) => {
                handleSorting(e);
              }}
            >
              <Option value="recently_read" label="Recently Viewed">Recently Viewed</Option>
              <Option value="ASC" label="Ascending">Ascending</Option>
              <Option value="DESC" label="Descending">Descending</Option>
            </Select>
          </>
        }
      </div>
      <Table
        className="table_listing organization__listing"
        loading={{
          spinning: isLoading,
          indicator: (
            <DataLoader width={80} height={80} stroke={4} outerStroke={4} />
          ),
        }}
        columns={columns}
        dataSource={orgranisationsListData}
        showHeader={true}
        // rowSelection={rowSelection}
        pagination={
          urlParamsObject?.order === "recently_read"
            ? false
            : {
                current: Number(urlParamsObject.page),
                pageSize: urlParamsObject.count,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                total: totalCount,
                position: ["topRight", "bottomRight"],
                onChange: (pageNo, pageSize) => {
                  setUrlParamsObject({
                    ...urlParamsObject,
                    page: pageNo,
                    count: pageSize,
                  });
                  // setPage(pageNo);
                  // setItemsPerPage(pageSize);
                  // setSearchQuery({
                  //   count: pageSize,
                  //   page: pageNo,
                  // });
                },
              }
        }
      ></Table>
    </>
  );
}

export default OrganizationsListContainer;
