import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getDiscountDetailsApi } from "../../../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import DiscountDetailsContainer from "../../../../../components/Events/EventDetailsTabs/DiscountTab/DiscountDetailsContainer";
import DiscountDetailsHeader from "../../../../../components/Events/EventDetailsTabs/DiscountTab/DiscountDetailsHeader";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
const { Header } = Layout;

const AddonDiscountDetailsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const discountId = searchParams.get("discountId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const [discountDetailsData, setDisountDetailsData] = useState({});
  const [loadingDiscountDetailData, setLoadingDiscountDetailData] =
    useState(false);
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Details",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: "Discount Detail", path: "" },
  ];
  useEffect(() => {
    getDiscountDetails(discountId);
  }, []);

  const getDiscountDetails = async (dId) => {
    setLoadingDiscountDetailData(true);
    const response = await getDiscountDetailsApi(dId);
    if (response?.status) {
      setDisountDetailsData(response?.data?.data);
      setLoadingDiscountDetailData(false);
    } else {
      setDisountDetailsData({});
      setLoadingDiscountDetailData(false);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <DiscountDetailsHeader
              discountDetailsData={discountDetailsData}
              loadingDiscountDetailData={loadingDiscountDetailData}
              discountType={"addon"}
            />
          </div>
          <DiscountDetailsContainer
            discountDetailsData={discountDetailsData}
            loadingDiscountDetailData={loadingDiscountDetailData}
            discountType={"addon"}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonDiscountDetailsPage;
