import { Button, Divider, Form, Input, Menu, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateItem } from "../../../../apis/reservationApi";
import { ReservationItemDetailRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import EventAddAccommodation from "../../../Events/EventDetailsTabs/CategoriesTab/CategoriesListing/EventAddAccommodation";
import Event_addcategory from "../../../Events/EventDetailsTabs/CategoriesTab/CategoriesListing/EventAddCategory";

const menu = (
  <Menu
    items={[
      {
        label: <EventAddAccommodation />,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <Event_addcategory />,
        key: "1",
      },
    ]}
  />
);

const dataS = [
  {
    key: "1",
    guest: "David Astemborski",
    percent: "",
    amount: "",
  },

  {
    key: "2",
    guest: "Kelly Astemborski",
    percent: "",
    amount: "",
  },
];
const Paymentresponsibility_edit = (props) => {
  const {
    data,
    form,
    itemId,
    traveler_details_total_sum,
    paramsId,
    reservationItem,
    paymentRespTotalAmount,
    inventroy,
  } = props;
  const totalAmount = paymentRespTotalAmount?.isUpdated
    ? paymentRespTotalAmount.totalAmount
    : Number(traveler_details_total_sum?.total_items_amount || 0);
  const [paymentData, setpaymentData] = useState([]);
  const [splitFlag, setSplitFlag] = useState(false);
  const navigate = useNavigate();
  const getFieldsData = (record) => {
    const paymentPercentKeys = Object.keys(form.getFieldsValue()).filter(
      (key) => key.startsWith("paymentPercent-")
    );
    const paymentAmountKeys = Object.keys(form.getFieldsValue()).filter((key) =>
      key.startsWith("paymentAmount-")
    );
    const otherAmountKey = paymentAmountKeys.find(
      (val) => val !== `paymentAmount-${record.key}`
    );
    const otherPercentKey = paymentPercentKeys.find(
      (val) => val !== `paymentPercent-${record.key}`
    );
    return [otherAmountKey, otherPercentKey];
  };

  function formatNumber(num) {
    // Convert the number to a string
    let formattedNum = num?.toString();

    // Remove trailing zeroes and decimal point if followed by only zeroes
    formattedNum = formattedNum?.replace(/\.?0+$/, "");

    return formattedNum;
  }
  const regex = /_(.*?)-/;
  function divideMoney(totalAmount, percentageUser1, percentageUser2) {

    let amountUser1 = (totalAmount * percentageUser1) / 100;
    let amountUser2 = (totalAmount * percentageUser2) / 100;

    amountUser1 = Math.round(amountUser1 * 100) / 100;
    amountUser2 = Math.round(amountUser2 * 100) / 100;

    const totalCalculated = amountUser1 + amountUser2;
    if (totalCalculated !== totalAmount) {
        const diff = totalAmount - totalCalculated;
        if (diff > 0) {
            amountUser1 += diff;
        } else {
            amountUser2 += diff;
        }
    }
    return [amountUser1, amountUser2];
}
  const columns = [
    {
      title: "Traveler",
      dataIndex: "guest",
    },

    {
      title: "Percent",
      dataIndex: "percent",
      render: (text, record, index) => (
        <Form.Item name={`paymentPercent-${record.key}`}>
          <Input
            type="number"
            onChange={(e) => {
              const percentValue = Number(e.target.value);
              const currentAmount = (totalAmount * percentValue) / 100;
              if (percentValue < 0) {
                form.setFieldsValue({
                  [`paymentPercent-${record.key}`]: 0,
                });
                message.error(
                  "Payment responsibility should be more than to 0%"
                );
              } else if (percentValue > 100) {
                const fieldsPayload = {
                  [`paymentPercent-${record.key}`]: 100,
                  [`paymentAmount-${record.key}`]: totalAmount,
                };
                message.error(
                  "Payment responsibility should not be more than 100%"
                );
                if (data.length === 2) {
                  const [otherAmountKey, otherPercentKey] =
                    getFieldsData(record);
                  fieldsPayload[otherAmountKey] = 0;
                  fieldsPayload[otherPercentKey] = 0;
                }
                form.setFieldsValue(fieldsPayload);
              } else {
                const [amount1,amount2] = divideMoney(totalAmount,percentValue,100 - percentValue)
                if (data.length === 2) {
                  const [otherAmountKey, otherPercentKey] =
                    getFieldsData(record);
                  form.setFieldsValue({
                    [otherAmountKey]: amount2,
                    [otherPercentKey]: (100 - percentValue).toFixed(2),
                  });
                }
                form.setFieldsValue({
                  [`paymentAmount-${record.key}`]: amount1
                });
              }
            }}
          />
        </Form.Item>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record, index) => (
        <Form.Item name={`paymentAmount-${record.key}`}>
          <Input
            type="number"
            onChange={(e) => {
              const paymentValue = Number(e.target.value);
              const currentPercentage = (paymentValue / totalAmount) * 100;
              if (paymentValue < 0) {
                form.setFieldsValue({
                  [`paymentAmount-${record.key}`]: 0,
                });
                message.error(
                  "Payment responsibility should be more than to 0"
                );
              } else if (paymentValue > totalAmount) {
                message.error(
                  `Payment responsibility should not be more than ${totalAmount}`
                );
                const fieldsPayload = {
                  [`paymentAmount-${record.key}`]: totalAmount,
                  [`paymentPercent-${record.key}`]: 100,
                };
                if (data.length === 2) {
                  const [otherAmountKey, otherPercentKey] =
                    getFieldsData(record);
                  fieldsPayload[otherAmountKey] = 0;
                  fieldsPayload[otherPercentKey] = 0;
                }
                form.setFieldsValue(fieldsPayload);
              } else {
                if (data.length === 2) {
                  const [otherAmountKey, otherPercentKey] =
                    getFieldsData(record);
                  form.setFieldsValue({
                    [otherAmountKey]: (totalAmount - paymentValue).toFixed(2),
                    [otherPercentKey]: (100 - currentPercentage).toFixed(2),
                  });
                }
                form.setFieldsValue({
                  [`paymentPercent-${record.key}`]:
                    currentPercentage.toFixed(2),
                });
              }
            }}
          />
        </Form.Item>
      ),
    },
  ];

  const updateItemDetails = async (payload) => {
    const response = await updateItem(payload, itemId);
    if (response && response.status) {
      message.success(
        response?.data?.message || "Reservation Item Edited Successfully"
      );
      navigate(
        `${ReservationItemDetailRoute}?id=${paramsId}&item-id=${itemId}`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
    }
  };

  useEffect(() => {
    if (data) {
      let list = [];
      data.forEach((value, index) => {
        form.setFieldsValue({
          [`paymentPercent-${index}`]: formatNumber(
            value?.field_payment_resp_percent
          ),
          [`paymentAmount-${index}`]: formatNumber(
            value?.field_payment_resp_amount
          ),
        });

        list.push({
          key: index,
          guest: value?.traveler_name,
          percent: value?.field_payment_resp_percent,
          amount: value?.field_payment_resp_amount,
        });
      });
      setpaymentData(list);
    }
  }, [data]);

  useEffect(() => {
    if (paymentRespTotalAmount.isUpdated) {
      const paymentPercentKeys = Object.keys(form.getFieldsValue()).filter(
        (key) => key.startsWith("paymentPercent-")
      );
      const paymentAmountKeys = Object.keys(form.getFieldsValue()).filter(
        (key) => key.startsWith("paymentAmount-")
      );
      form.resetFields([...paymentPercentKeys, ...paymentAmountKeys]);
      form.setFieldsValue({
        [`paymentPercent-0`]: 100,
        [`paymentAmount-0`]: paymentRespTotalAmount.totalAmount,
      });
    }
  }, [paymentRespTotalAmount.totalAmount]);
  const handleFinish = (values) => {
    const tableKeys = Object.keys(values).filter((val) => val.includes("-"));
    const paymentPercent = Object.keys(values).filter((key) =>
      key.includes("paymentAmount")
    );
    var sumPaymentPercent = paymentPercent.reduce(
      (sum, key) => Number(sum) + Number(values[key] || 0),
      0
    );

    if (sumPaymentPercent === totalAmount) {
      const itemIds = Object.keys(values).filter((val) =>
        val.includes("item_id")
      );
      let travelers = [];
      let paymentResponsibility = data.map((item) => ({
        field_traveler: item.traveler_id,
      }));

      tableKeys.forEach((val) => {
        const lengthOfKey = val.split("-").pop().length;
        if (lengthOfKey === 1 && val.includes("amount")) {
          let data = {
            field_price: values[val],
            discounts: [],
            fees: [],
            id: "",
          };
          travelers.push(data);
        }
          if (val.includes("discount")) {
            const index = val.split("-")[1];
            let parentIndex = val[val.length - 2] - 1;
            if(!val.match(regex)) return
            let drupalIndex = val.match(regex)[1];
            const checkedValue = form.getFieldValue(`item_active-${index}`);
            travelers[parentIndex].discounts.push({
              index: drupalIndex,
              field_amount: values[val],
              field_is_active: checkedValue,
            });
          }
          if (val.includes("fee")) {
            const index = val.split("-")[1];
            let parentIndex = val[val.length - 2] - 1;
            if(!val.match(regex)) return
            let drupalIndex = val?.match(regex)[1];
            const checkedValue = form.getFieldValue(`item_active-${index}`);
            travelers[parentIndex].fees.push({
              index: drupalIndex,
              field_amount: values[val],
              field_is_active: checkedValue,
            });
          }
          // else if (val.includes('item_active')) {
          // let parentIndex = val[val.length - 2];
          // let selfIndex = val[val.length - 1];
          // travelers[parentIndex].discount = {
          //     index: selfIndex,
          //     field_amount: values[val],
          //     field_is_active: values[`item_active-${parentIndex}${selfIndex}`]

          // }
          if (val.includes("paymentAmount")) {
            let selfIndex = val[val.length - 1];
            paymentResponsibility[selfIndex].field_payment_resp_amount =
              values[val];
          } else if (val.includes("paymentPercent")) {
            let selfIndex = val[val.length - 1];
            paymentResponsibility[selfIndex].field_payment_responsibility =
              values[val];
          }
      });
      const payload = {
        reservation_item: {
          field_cruise_bookno: values?.field_cruise_bookno,
          field_itemstatus: values?.item_status,
          field_comstaff: values?.commission_staff,
          id: reservationItem?.id,
          reservation_id: paramsId,
          uuid: itemId,
          field_payment_res: paymentResponsibility,
        },
        reservation_item_inventory: {
          field_item_name: values?.item_name,
          field_item_number: values?.item_code,
          id: inventroy?.id,
        },
        travelers_items: travelers,
      };
      if(!['purchased',"quote"].includes(reservationItem?.field_itemstatus)){
        payload.reservation_item.field_comments = [
          {
            value: values?.comments,
            format: "full_html",
          },
        ]
      }
      itemIds.forEach((e, index) => {
        payload.travelers_items[index].id = values[e];
      });
      updateItemDetails(payload);
    } else {
      message.error(
        `Payment Responsibility Should Be Equal to total amount: ${totalAmount}`
      );
    }
  };

  function splitPayment(totalAmount, numPeople) {
    // Calculate initial split amounts
    const initialSplit = totalAmount / numPeople;

    // Calculate decimal values
    const decimalAmount = totalAmount % numPeople;

    // Adjust split amounts
    const adjustedSplit = Array.from(
      { length: numPeople },
      (_, i) => Math.floor(initialSplit) + (i < decimalAmount ? 1 : 0)
    );

    return adjustedSplit;
  }
  useEffect(() => {
    if (splitFlag) {
      let listLength = data.length;
      let totalAmount = paymentRespTotalAmount.isUpdated
        ? paymentRespTotalAmount.totalAmount
        : traveler_details_total_sum?.total_items_amount;

      const splitAmount = splitPayment(totalAmount, listLength);
      data.forEach((value, index) => {
        const percentage = (splitAmount[index] / totalAmount) * 100;
        form.setFieldsValue({
          [`paymentPercent-${index}`]: percentage.toFixed(2),
          [`paymentAmount-${index}`]: splitAmount[index],
        });
      });
      setSplitFlag(false);
    }
  }, [splitFlag]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'" }}>Payment Responsibility</h4>
        <span>
          <Button type="primary" onClick={() => setSplitFlag(true)}>
            {" "}
            Split Equally
          </Button>
        </span>
      </div>
      <Divider className="global_divider" />
      <Form form={form} onFinish={(v) => handleFinish(v)}>
        <Table
          columns={columns}
          dataSource={paymentData}
          size="middle"
          pagination={false}
        />
      </Form>
    </div>
  );
};

export default Paymentresponsibility_edit;
