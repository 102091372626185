import { Table, Divider, Checkbox, Switch, Input } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React from 'react';


const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };


const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },

  {
    title: 'Name',
    dataIndex: 'name',
  },

  {
    title: 'Discount Amount',
    dataIndex: 'master_discount',
  },

  {
    title: 'Discount Percent	',
    dataIndex: 'discount_percent',
  },

  {
    title: 'Active?',
    dataIndex: 'active',
  },

  // {
  //   title: "Add'l Discount Amount",
  //   dataIndex: 'add_discount',
  // },

  // {
  //   title: "Add'l Discount Percent",
  //   dataIndex: 'add_percent',
  // },

  // {
  //   title: "Limit",
  //   dataIndex: 'limit',
  // },

];
const data = [
  {
    key: '1',
    type: 'Adjustment',
    name: '	MM Rate',
    master_discount: '',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },


  {
    key: '2',
    type: 'Friends & Family',
    name: 'Friends & Family Discount C	',
    master_discount: '$412.00',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },

  {
    key: '3',
    type: 'Adjustment',
    name: '	MM Rate',
    master_discount: '',
    discount_percent: '50%',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },


  {
    key: '4',
    type: 'Friends & Family',
    name: 'Friends & Family Discount VB	',
    master_discount: '',
    discount_percent: '50%',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },


  {
    key: '5',
    type: 'Special Offer',
    name: 'Book Online Discount	',
    master_discount: '$50.00	',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },

  {
    key: '5',
    type: 'Special Offer',
    name: 'Book Online Discount	',
    master_discount: '$50.00	',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },

  {
    key: '5',
    type: 'Special Offer',
    name: 'Book Online Discount	',
    master_discount: '$50.00	',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },

  {
    key: '5',
    type: 'Special Offer',
    name: 'Book Online Discount	',
    master_discount: '$50.00	',
    discount_percent: '',
    active: <>
    <Switch onChange={onChange} />
    </>,
    add_discount: <>
    <Input style={{ width: 200 }} />
    </>,
     add_percent: <>
     <Input style={{ width: 200 }} />
     </>,
      limit: <>
      <Input type='number' style={{ width: 200 }} />
      </>,
  },
  

 ];
const Discount_edit = () => (
   <div>
     {/* <div className='requests_title'>
      <h4>Fees (15)</h4>
      </div>
      <Divider style={{ marginTop: '0' }} /> */}
    <Table columns={columns} dataSource={data} size="middle" pagination={false} />
    
  </div>
);

export default Discount_edit;