import React from "react";

import { useEffect, useState } from "react";
import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
} from "antd";
import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
import Sidebar from "./Header_sidebar";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
import { useDebounce } from "../../others/Debounce/debounce";
import { drupalAxiosRequest } from "../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../utils/cutomRequestHeader";
import { decodeContent } from "../../utils/contentParser";
const { useBreakpoint } = Grid;

function SearchElite_menu({
  showSearchBar,
  urlParamsObject,
  setEliteTraveles,
}) {
  const [searchValue, setSearchValue] = useState(urlParamsObject?.name);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const getCompletedEvents = async () => {
    const payload = {
      items_per_page: Number(urlParamsObject?.count),
      page: Number(urlParamsObject?.page) - 1,
      sort_by: "name",
      sort_order: urlParamsObject?.order,
      name: debouncedSearchTerm ?? "",
    };
    const queryParam = `?items_per_page=${payload.items_per_page}&page=${payload.page}&name=${payload.name}`;
    const data = await drupalAxiosRequest(
      "get",
      `/api/v1/list/completed_events/listing${queryParam}`,
      customRequestHeader({ defaultFormat: true })
    );
    const customData = data?.data?.rows?.map((val) => {
      return {
        name: decodeContent(val?.name),
        Code: val.field_event_code,
        Duration: val.duration,
        Destination: val?.field_destination?.title,
        action: (
          <>
            <div className="action_flex action_end">
              <Link to={`/Report_elitetravelerdetails?id=${val.uuid}`}>
                <a href="#" className="member_hover">
                  View Upgraded Travelers
                </a>
              </Link>
            </div>
          </>
        ),
        exp_row: false,
      };
    });
    setEliteTraveles(customData);
  };
  useEffect(() => {
    if (showSearchBar) {
      getCompletedEvents();
    }
  }, [debouncedSearchTerm]);

  const [options, setOptions] = useState([]);
  const searchHandler = (value) => {
    setSearchValue(value);
  };
  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu complete_events_filter">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        {showSearchBar && (
          <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
            <div className="search-group">
              <Button
                type="link"
                onClick={() => {
                  setShowSearch(false);
                }}
                className="back-btn"
              >
                <LeftOutlined />
              </Button>
              <AutoComplete
                dropdownMatchSelectWidth={252}
                options={options}
                onSelect={onSelect}
                onSearch={searchHandler}
                onChange={searchHandler}
                allowClear={{ clearIcon: <CloseOutlined /> }}
              >
                <Input.Search
                  size="large"
                  placeholder="Search Completed Events"
                  enterButton
                />
              </AutoComplete>
            </div>
          </Col>
        )}
        <Col sm={12} className="profile-group empty-col"></Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default SearchElite_menu;
