import {
  ArrowLeftOutlined,
  MailOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Skeleton, Dropdown, Space } from "antd";
import "./EventReservationsTravelersHeader.less";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { EventDetailsRoute } from "../../../../url-routes/urlRoutes";
import downloadConfig from "../../../../config/download.config";
import axios from "axios";

function EventReservationsTravelersHeader({
  eventData,
  loadingEventData,
  showModal,
  type,
}) {
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const items = [
    {
      label: <a>CSV</a>,
      key: "0",
      value: "csv",
    },
    {
      label: <a>XL</a>,
      key: "1",
      value: "xlsx",
    },
  ];

  const handleDownload = async (event) => {
    const downloadType = items.find((val) => event.key === val.key).value;
    try {
      const response = await axios.get(
        `${downloadConfig.BASE_URL}/export/event/res/travaler/data/${eventData?.drupal_internal__id}/${downloadType}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `All Travelers - ${eventData?.name}.${downloadType}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.log("🚀 ~ handleDownload ~ err:", err);
    }
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />
          {loadingEventData ? (
            <Skeleton.Input style={{ width: "400px" }} />
          ) : (
            <div className="header_res">
              <p>All Travelers</p>
              <Link
                className="header_smalltext"
                to={`${EventDetailsRoute}?id=${eventData?.id}`}
              >
                {eventData?.name || ""}{" "}
                {eventData?.field_event_code
                  ? `(${eventData?.field_event_code})`
                  : ""}
              </Link>
            </div>
          )}
        </h3>
        {type === "event" ? (
          <div className="breadcrumb_buttons">
            <Button
              type=""
              onClick={() => {
                showModal(true);
              }}
            >
              <MailOutlined /> Send Emails
            </Button>

            <Dropdown
              className="export-dropdown"
              menu={{
                items,
                onClick: handleDownload,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Export
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default EventReservationsTravelersHeader;
