import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import { customRequestHeader } from "../../../../../../utils/cutomRequestHeader";
import { getOrganizationListApi } from "../../../../../../apis/contactApi";
import { Country } from "country-state-city";
import {
  getTaxonomyList,
  getTourOperatorList,
} from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import MobileInput from "../../../../../Custom/MobileInput";
import { useSelector } from "react-redux";
import { getEventPartnersList } from "../../../../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch } from "react-redux";
import { decodeContent } from "../../../../../../utils/contentParser";
const { RangePicker } = DatePicker;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function TourDetailsForm({ onFinishHandler, form, isLoading, type }) {
  const countriesList = Country.getAllCountries();
  const dispatch = useDispatch();
  const { eventPartners } = useSelector((state) => state.events);
  const { eventPartnersResponse, loadingEventPartners } = eventPartners;
  const [tourOperatorList, setTourOperatorList] = useState([]);
  const [loadingTourOperator, setLoadingTourOperator] = useState(false);
  const [tourItineraryList, setTourItineraryList] = useState([]);
  const [loadingTourItinerary, setLoadingTourItinerary] = useState(false);
  const [eventPartnersList, setEventPartnersList] = useState([]);
  const eventPartnersRef = useRef(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    dispatch(getEventPartnersList());
    // getTaxonomyList(
    //   "tour_operator",
    //   setTourOperatorList,
    //   setLoadingTourOperator
    // );
    getTourOperatorList(setTourOperatorList, setLoadingTourOperator);
    getTaxonomyList(
      "tour_itinerary",
      setTourItineraryList,
      setLoadingTourItinerary
    );
  }, []);

  useEffect(() => {
    if (eventPartnersRef.current) {
      if (eventPartnersResponse.status) {
        setEventPartnersList(eventPartnersResponse.data.data);
      } else {
        setEventPartnersList([]);
      }
    }
    eventPartnersRef.current = true;
  }, [eventPartnersResponse]);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>{type} Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
          initialValues={{ field_tollfree_phone_extension: "+1" }}
        >
          <Form.Item label="Event Partner" name="field_event_partner">
            <Select
              allowClear
              mode="multiple"
              style={{
                width: "100%",
              }}
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={eventPartnersList?.map((eventPartners) => ({
                key: eventPartners?.drupal_internal__nid,
                value: eventPartners?.id,
                label: eventPartners?.title,
              }))}
              loading={loadingEventPartners}
            />
          </Form.Item>
          <Form.Item
            label="Event Name"
            className="asterick-align"
            name="field_event_name"
            rules={[{ required: true, message: "Please fill Event Name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Event Code"
            className="asterick-align"
            name="field_event_code"
            rules={[{ required: true, message: "Please fill Event Code" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={`Length Of ${type}`} name="field_length_of_tour">
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label={`${type} Operator`} name="field_tour_operator">
            <Select
              placeholder={`${type} Operator`}
              options={tourOperatorList?.map((tourOperator) => ({
                key: tourOperator?.nid,
                value: tourOperator?.uuid,
                label: decodeContent(tourOperator?.title),
              }))}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingTourOperator}
              allowClear
            />
          </Form.Item>
          <Form.Item label={`${type} Itinerary`} name="field_tour_itinerary">
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={tourItineraryList?.map((tour_itinerary) => ({
                key: tour_itinerary?.drupal_internal__tid,
                value: tour_itinerary?.id,
                label: tour_itinerary?.name,
              }))}
              loading={loadingTourItinerary}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Requested Dates" name="field_requested_dates">
            <RangePicker
              style={{
                fontFamily: "'Poppins', sans-serif",
                height: "32px",
              }}
              format={"MM/DD/YYYY"}
            />
          </Form.Item>
          <Form.Item label="Alternative Dates" name="field_alternative_dates">
            <RangePicker
              style={{
                fontFamily: "'Poppins', sans-serif",
                height: "32px",
              }}
              format={"MM/DD/YYYY"}
            />
          </Form.Item>
          <Form.Item
            label="Are Dates Flexible?"
            name="field_are_dates_flexible"
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Event Exclusive"
            name="field_event_exclusive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <MobileInput
            label={"Toll Free Phone Number"}
            extension={"field_tollfree_extension"}
            phoneName={"field_tollfree_phone"}
            form={form}
          />

          <Form.Item
            label="URL Address"
            className="asterick-align"
            name="field_url_address"
            rules={[
              {
                type: "url",
                message: "Invalid url",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default TourDetailsForm;
