import { Empty, Form, Grid, Layout, Spin, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getMakePaymentApi,
  getReservationDetailApi,
  paymentWithStripeApi,
} from "../../../../../../apis/reservationApi";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../../../../url-routes/urlRoutes";
import BreadCrumbGenerator from "../../../../../BreadCrumbGenerator/BreadCrumbGenerator";
import Searchreservations_menu from "../../../../ReservationSearchBar";
import PayViaStripeHeader from "./PayViaStripeHeader";
import PayViaStripePaymentForm from "./PayViaStripePaymentForm";
import Paymentviastripe_table from "./PlannedPaymentAppyToFormTable";
import PlannedPaymentAppyToFormTable from "./PlannedPaymentAppyToFormTable";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
} from "../../../../../../utils/constants";
import { paymentMethodFilter } from "../../../../../../utils/paymentMethodFilter";
const { Header, Content, Sider } = Layout;

function PayViaStripePage() {
  const [paymentData, setPaymentData] = useState({
    email: "harshphartyal45313302818@gmail.com",
    name: "OSL test user",
    contact_id: 64,
  });
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const [paymentDetail, setPaymentDetail] = useState();
  const [plannedPayment, setPlannedPayment] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState();
  const [loading, setLoading] = useState(false);
  const reservationUuid = searchParams.get("id");
  const eventName = searchParams.get("event-name");
  const reservationId = searchParams.get("reservation-id");
  const travellerUuid = searchParams.get("traveler-uuid");
  const paymentId = searchParams.get("paymentId");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    {
      title: "Detail",
      path: `${ReservationDetailRoute}?id=${reservationUuid}`,
    },
    // { title: "Payments", path: "" },
    { title: "Make Payment", path: "" },
  ];

  useEffect(() => {
    getReservationDetail(reservationUuid);
    getPaymentPageDetails(travellerUuid);
  }, []);

  useEffect(() => {
    if (paymentAmount > 0) {
      const timeId = setTimeout(() => {
        debouncePayment(paymentAmount, totalPendingAmount);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    } else {
      form.setFieldsValue({ payment_amount: paymentAmount });
      const newTableData = [...plannedPayment].map((item) => ({
        ...item,
        apply: false,
        apply_from_this_payment: null,
      }));
      setPlannedPayment(newTableData);
    }
  }, [paymentAmount]);

  const debouncePayment = (paymentAmount, totalPendingAmount) => {
    if (Number(paymentAmount) > Number(totalPendingAmount)) {
      form.setFieldsValue({ payment_amount: totalPendingAmount });
      getPaymentPageDetails(travellerUuid, totalPendingAmount);
    } else {
      getPaymentPageDetails(travellerUuid, paymentAmount);
    }
  };
  useEffect(() => {
    if (paymentDetail) {
      form.setFieldsValue({
        payment_amount: paymentDetail?.payment_amount || null,
        payment_method: paymentDetail?.payment_method?.id
          ? Number(paymentDetail?.payment_method?.id)
          : null,
        comments: paymentDetail?.comments || null,
        payment_date: paymentDetail?.payment_date
          ? moment(paymentDetail?.payment_date)
          : null,
        transfer_balance: paymentDetail?.transfer_balance || 0,
      });
    }
  }, [paymentDetail]);

  // useEffect(() => {
  //   if (paymentAmount) {
  //     let amount = (paymentAmount / plannedPayment.length).toFixed(2);
  //     let newPlannedPaymentData = plannedPayment;
  //     newPlannedPaymentData.forEach((planPayment) => {
  //       planPayment.apply_from_this_payment = amount;
  //     });
  //     setPlannedPayment(newPlannedPaymentData);
  //   }
  // }, [paymentAmount]);

  const handleTableInputChange = (value, index, key) => {
    const newTableData = [...plannedPayment];
    let payment_amount = null;
    let plannedAmount = newTableData[index]?.planned_amount
      ? Number(newTableData[index]?.planned_amount)
      : null;
    let amountPaid = newTableData[index]?.amount_paid
      ? Number(newTableData[index]?.amount_paid)
      : null;

    newTableData[index][key] = value;
    if (value) {
      payment_amount =
        Number(form.getFieldValue("payment_amount")) +
        (plannedAmount - amountPaid);
      newTableData[index].apply_from_this_payment = plannedAmount - amountPaid;
    } else {
      payment_amount =
        Number(form.getFieldValue("payment_amount")) <=
        plannedAmount - amountPaid
          ? 0
          : Number(form.getFieldValue("payment_amount")) -
            newTableData[index].apply_from_this_payment;
      newTableData[index].apply_from_this_payment = null;
    }
    setPlannedPayment(newTableData);
    form.setFieldsValue({ payment_amount: payment_amount || null });
  };

  const getPaymentPageDetails = async (travellerUuid, paymentAmount) => {
    setLoading(true);
    const response = await getMakePaymentApi({travellerUuid, paymentAmount,paymentId});
    if (response && response.status) {
      const { data } = response?.data;
      setTotalPendingAmount(
        data?.planned_payments?.reduce(
          (total, entry) => total + (entry.planned_amount - entry.amount_paid),
          0
        )
      );
      setPlannedPayment(
        data?.planned_payments?.map((plannedPayment) => ({
          id: plannedPayment?.id || null,
          uuid: plannedPayment?.uuid || null,
          description: plannedPayment?.description || null,
          date: plannedPayment?.date
            ? moment(plannedPayment?.date).format(DATE_FORMAT)
            : null,
          payment_method: paymentMethodFilter(plannedPayment?.payment_method) || null,
          planned_amount: plannedPayment?.planned_amount || 0,
          amount_paid: plannedPayment?.amount_paid || 0,
          apply: plannedPayment?.apply,
          apply_from_this_payment:
            plannedPayment.apply_from_this_payment || null,
        }))
      );
      setPaymentDetail(data?.payment_detail);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };
  const onFinish = (values) => {
    const atLeastOneApply = plannedPayment.filter(val=> val && val.apply)
    if(!atLeastOneApply.length){
      message.error("Please select atleast one planned payment")
    }
    const payload = {
      label: "checkout",
      reservation_id: reservationUuid,
      traveler_id: travellerUuid,
      payment_detail: {
        payment_amount: values?.payment_amount || null,
        paid_amount: values?.paid_amount || null,
        payment_date: values?.payment_date
          ? values?.payment_date?.format("YYYY-MM-DD")
          : null,
        payment_method: values?.payment_method || null,
        transfer_balance: values?.transfer_balance || null,
        comments: values?.comments || null,
      },
      planned_payments: plannedPayment?.length
        ? plannedPayment?.map((planPayment) => ({
            id: planPayment?.id || null,
            apply: planPayment?.apply,
            apply_from_this_payment:
              planPayment?.apply_from_this_payment || null,
          }))
        : [],
    };
    payWithStripe(payload);
  };

  const payWithStripe = async (payload) => {
    setIsPaymentLoading(true);
    const res = await paymentWithStripeApi(payload);
    if (res.status) {
      // if (res?.data?.status) {
      setIsPaymentLoading(false);
      window.location.href = res?.data?.redirect_url || res?.data?.session_url;
    } else {
      setIsPaymentLoading(false);
      message.error(
        res?.data?.response?.data?.data?.message ||
          res?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };
  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <PayViaStripeHeader
              paymentDetail={paymentDetail}
              eventName={eventName}
              reservationId={reservationId}
              form={form}
              isPaymentLoading={isPaymentLoading}
              reservationData={reservationData}
              loadingReservationData={loadingReservationData}
            />
          </div>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            form={form}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Spin spinning={loading} size="large">
              {" "}
              {paymentDetail ? (
                <>
                  {" "}
                  <Content
                    className="site-layout-background"
                    style={{ marginBottom: "20px" }}
                  >
                    <PayViaStripePaymentForm
                      paymentDetail={paymentDetail}
                      setPaymentAmount={setPaymentAmount}
                      form={form}
                      totalPendingAmount={totalPendingAmount}
                    />
                  </Content>
                  <Content
                    className="site-layout-background"
                    style={{ marginBottom: "20px" }}
                  >
                    <PlannedPaymentAppyToFormTable
                      handleTableInputChange={handleTableInputChange}
                      data={plannedPayment}
                    />
                  </Content>
                </>
              ) : (
                <Content
                  className="site-layout-background"
                  style={{ marginBottom: "20px" }}
                >
                  {" "}
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Content>
              )}
            </Spin>
          </Form>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PayViaStripePage;
