import { Divider, Form, Input } from "antd";
import React from "react";
import DataLoader from "../../../../../../others/Loaders/DataLoader";

const { TextArea } = Input;
const TourNotes = ({ form, onFinishHandler, isLoading }) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Notes</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24,
          }}
          layout="horizontal"
          onFinish={onFinishHandler}
          form={form}
        >
          <div className="textarea_row">
            <Form.Item name="field_note">
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default TourNotes;
