import { CloseOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../../../../../url-routes/urlRoutes";

function PayViaStripeHeader({
  reservationId,
  eventName,
  form,
  isPaymentLoading,
  reservationData,
  loadingReservationData,
  paymentDetail,
}) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />
          {loadingReservationData ? (
            <Skeleton.Input active={true} style={{ width: "250px" }} />
          ) : (
            <div className="header_res">
              <p>Make Payment</p>
              <Link
                to={`${ReservationDetailRoute}?id=${reservationData?.id}`}
                className="header_smalltext"
              >
                {`${reservationData?.name} - ${reservationData?.drupal_internal__id}`}
              </Link>
            </div>
          )}
        </h3>
        <div
          className="breadcrum_buttons"
          style={{ display: "flex", gap: "10px" }}
        >
          <Button onClick={()=>{
                window.location.href = `${ReservationDetailRoute}?id=${reservationData?.id}`;
          }}>Cancel</Button>
          {paymentDetail?.is_fully_paid ? null : (
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={isPaymentLoading}
            >
              Pay Now
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PayViaStripeHeader;
