import { Button, Skeleton } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import { PlusOutlined, ImportOutlined } from "@ant-design/icons";
import "./OrganizationsListHeader.less";
import Import_organization from "../../Forms/Import_contact/Import_organization";

function OrganizationsListHeader({ numberOfOrg, isLoading }) {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_column">
          {" "}
          Organizations {"  "}{" "}
          <span className="small_text">
            {isLoading ? (
              <Skeleton.Button
                shape="round"
                size="small"
                style={{ width: "50px" }}
              />
            ) : (
              `(${numberOfOrg || 0})`
            )}
          </span>
        </h3>

        <div className="breadcrum_buttons mobile_hidden">
          {/* <Import_organization /> */}
          {allowAddOrganizations ? (
            <Link to="/organization">
              {"  "}
              <Button type="primary">
                <PlusOutlined /> <span>Add Organization</span>
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="btradcrumbmob_button">
          <Button>
            <ImportOutlined /> <span> Import</span>
          </Button>
          <Link to="/contact">
            {" "}
            <Button type="primary">
              <PlusOutlined /> <span> Add</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default OrganizationsListHeader;
