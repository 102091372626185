import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
import Searchreservations_menu from "../../components/Reservation/ReservationSearchBar";
import Breadcrumb_resactivityemail from "../../components/Breadcrumb/Breadcrumb_resactivityemail/Breadcrumb_resactivityemail";
import Res_activityemailviewpage from "../../components/View_components/Res_activityemailviewpage/Res_activityemailviewpage";
import React from "react";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Reservation_activityemailview() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>

          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reservations</Breadcrumb.Item>
            <Breadcrumb.Item>Activity</Breadcrumb.Item>
            <Breadcrumb.Item>Edit Task</Breadcrumb.Item>
          </Breadcrumb>

          <div className="events-header">
            <Breadcrumb_resactivityemail />
          </div>

          <Res_activityemailviewpage />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Reservation_activityemailview;
