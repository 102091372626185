import { Form, Grid, Layout } from "antd";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import CategoryInventoryInformation from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/AddorEditInventory/CategoryInventoryInformation";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  EventCategoryDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import InventoryEditPageHeader from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/AddorEditInventory/InventoryEditPageHeader";
const { Header } = Layout;

const EventCategoryInventory = () => {
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loadingSingleInventory, setLoadingSingleInventory] = useState(false);
  const eventId = searchParams.get("id");
  const inventoryId = searchParams.get("editId");
  const categoryId = searchParams.get("categoryId");

  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `/event/details?id=${eventId}#Accommodations` },
    {
      title: "Category Detail",
      path: `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${
        categoryId || ""
      }`,
    },
    { title: `${inventoryId ? "Edit" : "Add"} Inventory`, path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <InventoryEditPageHeader form={form} loading={loading} />
          </div>
          <CategoryInventoryInformation
            form={form}
            loading={loading}
            setLoading={setLoading}
            loadingSingleInventory={loadingSingleInventory}
            setLoadingSingleInventory={setLoadingSingleInventory}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EventCategoryInventory;
