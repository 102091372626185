import {
  ArrowLeftOutlined,
  DownOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Alert, Button, Dropdown, Menu, Skeleton, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeStatusApi, getInvoiceApi } from "../../../apis/reservationApi";
import {
  CancelReservationRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
  TIME_FORMAT,
} from "../../../utils/constants";
import {
  markPayeeTrigger,
  setSectionHighlighter,
} from "../../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReviseConfirmationCommon from "../../../others/util/ReviseConfirmationCommon";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../../utils/permisssionChecker";

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <a href="#">Delete</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "More Options",
        key: "3",
      },
    ]}
  />
);

function ReservationDetailHeader({
  reservationId,
  reservationData,
  loadingReservationData,
  setIsDiscarded,
  isDiscarded,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalResponse, setModalResponse] = useState();
  const [statusChangePayload, setStatusChangePayload] = useState();
  const [isDiscardActive, setIsDiscardActive] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isPayeeTrigger } = useSelector((state) => state.reservation);

  let paramId = searchParams.get("id");

  const items = [
    {
      label: <span onClick={() => setInvoiceLoader(true)}>Invoice</span>,
      key: "0",
    },
  ];

  const { key } =
    (reservationData && reservationData?.reservation_status) ?? {};

  useEffect(() => {
    if (reservationData?.reservation_status?.key) {
      if (
        key === "waitlist" ||
        key === "quote" ||
        key === "pending" ||
        key === "temporary_hold"
      ) {
        setIsDiscardActive(true);
      } else {
        setIsDiscardActive(false);
      }
    }
  }, [reservationData]);

  const showMessageArray = (errorArray) => {
    errorArray.forEach((error) => {
      message.error(error);
    });
  };
  const changeStatus = async (payload) => {
    const response = await changeStatusApi(payload);
    const { data } = response?.data?.response?.data || {};
    if (response && response.status) {
      message.success("Reservation Status Updated");
      dispatch(setSectionHighlighter({}));
      window.location.reload();
    } else {
      markPayeeTrigger(!isPayeeTrigger);
      dispatch(setSectionHighlighter(data));
      if (Object.keys(data).length) {
        if (data?.general_information || data?.select_relation) {
          showMessageArray(data?.general_information || data?.select_relation);
        } else {
          message.error(
            data?.message ||
            data?.travelers ||
              data?.select_contact ||
              data?.select_payee ||
              data?.payments ||
              data?.items
          );
        }
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    setIsModalOpen(false);
    setLoading(false);
    setModalResponse();
  };
  const onStatusChange = (status) => {
    setIsModalOpen(true);
    const data = {
      res_uuid: reservationId,
      res_status: status,
    };
    setStatusChangePayload(data);
    if (status === "active") {
      setModalData(
        <p>
          Please confirm that you want to activate Reservation{" "}
          <span>
            {reservationData?.name}-{reservationData?.drupal_internal__id}
          </span>
        </p>
      );
    } else if (status === "pending") {
      setModalData(
        <p>
          Please confirm that you want to save{" "}
          {reservationData?.reservation_type}{" "}
          <span>
            {reservationData?.name}-{reservationData?.drupal_internal__id}
          </span>{" "}
          as Pending.
        </p>
      );
    } else if (status === "temporary_hold") {
      setModalData(
        <p>
          Please confirm that you want to convert{" "}
          {reservationData?.reservation_type}{" "}
          <span>
            {" "}
            {reservationData?.name}-{reservationData?.drupal_internal__id}{" "}
          </span>
          to Reservation
        </p>
      );
    }
  };

  // const onDiscardReservation = async (payload) => {
  //   setLoading(true);
  //   const res = await changeStatusApi(payload);
  //   if (res.status) {
  //     message.success("Reservation Status Updated");
  //     setIsDiscarded(!isDiscarded);
  //   } else {
  //     message.error(SOMETHING_WENT_WRONG);
  //   }
  //   setLoading(false);
  //   setIsModalOpen(false);
  //   setModalResponse();
  // };

  useEffect(() => {
    // if (statusChangePayload?.res_status === "discarded") {
    //   onDiscardReservation(statusChangePayload);
    // } else
    if (modalResponse === "confirm") {
      changeStatus(statusChangePayload);
    }
  }, [modalResponse]);

  const discardReservation = async (status) => {
    setIsModalOpen(true);
    setModalData(
      <p>
        Please confirm that you want to discard{" "}
        {reservationData?.reservation_type}{" "}
        <span>
          {reservationData?.name}-{reservationData?.drupal_internal__id}
        </span>
      </p>
    );
    let payload = { res_uuid: reservationId, res_status: status };
    setStatusChangePayload(payload);
  };

  const alertMessage = (reservationData) => {
    let message = null;
    if (reservationData?.created_on?.key === "staffweb") {
      message = `This reservation is not confirmed. It will expire on ${moment(
        reservationData?.changed
      )
        .add(2880, "minutes")
        .format(DATE_FORMAT)} at ${moment(reservationData?.changed)
        .add(2880, "minutes")
        .format(TIME_FORMAT)}`;
    }
    if (reservationData?.created_on?.key === "contactweb") {
      message = `This reservation is not confirmed. It will expire on ${moment(
        reservationData?.changed
      )
        .add(
          reservationData?.select_event?.field_time_in_cart || 1200,
          "seconds"
        )
        .format(DATE_FORMAT)} at ${moment(reservationData?.changed)
        .add(
          reservationData?.select_event?.field_time_in_cart || 1200,
          "seconds"
        )
        .format(TIME_FORMAT)}`;
    }
    return message;
  };

  const getInvoice = async () => {
    const response = await getInvoiceApi(paramId);
    if (response && response.status) {
      window.open(response?.data?.fileUrl, "_blank");
    } else {
      message.error("Something went wrong");
    }
    setInvoiceLoader(false);
  };

  const handleMenuClick = (e) => {
    getInvoice();
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <div className="breadcrumb_content">
        <div className="breadcrumb_heading view_header">
          <h3 className="flex_row_column">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() => {
                navigate(ReservationListingRoute);
              }}
            />
            {loadingReservationData ? (
              <>
                <Skeleton.Input active={true} style={{ width: "300px" }} />
              </>
            ) : (
              <div className="header_res">
                <p>{reservationData?.name}</p>
                <span className="small_text">
                  {" "}
                  ({reservationData?.drupal_internal__id})
                </span>
              </div>
            )}
          </h3>
          {isModalOpen ? (
            <ReviseConfirmationCommon
              isModalOpen={isModalOpen}
              modalData={modalData}
              setIsModalOpen={setIsModalOpen}
              setModalResponse={setModalResponse}
              loading={loading}
              setLoading={setLoading}
            />
          ) : null}
          {loadingReservationData ? null : (
            <div className="breadcrumb_buttons">
            {permissionChecker("add/edit reservation") ? (<>
              {/* <Link to={`${CancelReservationRoute}?id=${reservationId}`}> */}{" "}
              {key === "waitlist" ||
              key === "quote" ||
              key === "temporary_hold" ||
              key === "pending" ? (
                <Button onClick={() => discardReservation("discarded")}>
                  Discard
                </Button>
              ) : null}
              {key === "active" ||
              key === "revise" ||
              key === "cancel_pending" ? (
                <Button
                  onClick={() => {
                    navigate(`${CancelReservationRoute}?id=${reservationId}`);
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              {/* </Link> */}
              {key === "revise" || key === "temporary_hold" ? (
                <Button type="" onClick={() => onStatusChange("active")}>
                  Set to Active
                </Button>
              ) : null}
              {key === "discarded" ? (
                <Button type="" onClick={() => onStatusChange("pending")}>
                  Set to Pending
                </Button>
              ) : null}
              {key === "pending" || key === "waitlist" || key === "quote" ? (
                <Button
                  type=""
                  onClick={() => onStatusChange("temporary_hold")}
                >
                  Convert to Reservation
                </Button>
              ) : null}
              {key === "cancelled" ? (
                <Button
                  type=""
                  loading={loading}
                  onClick={() => onStatusChange("revise")}
                >
                  Reinstate
                </Button>
              ) : null}
              <Dropdown menu={menuProps} overlayClassName="guest_dropdown">
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Button type="" loading={invoiceLoader}>
                      Report <DownOutlined />
                    </Button>
                  </Space>
                </a>
              </Dropdown>
            </>):null}
            </div>
          )}
          <div className="breadcrumb_toggle">
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayClassName="add_intake"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button type="y" icon={<MoreOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>

      {reservationData?.reservation_status?.key === "temporary_hold" &&
      alertMessage(reservationData) ? (
        <div className="alert_box">
          <Alert message={alertMessage(reservationData)} type="info" showIcon />
        </div>
      ) : null}
    </>
  );
}

export default ReservationDetailHeader;
