import { Table, Button, Divider, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { EventRoute } from "../../../../url-routes/urlRoutes";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function GeneralInformations({ eventData, eventId, loading }) {
  const authData = JSON.parse(localStorage.getItem("authorization"));

  const data = [
    {
      key: "1",
      label: "Type",
      input: eventData?.field_type,
    },
    {
      key: "2",
      label: "Event Name",
      input: eventData?.name,
    },
    {
      key: "3",
      label: "Event Code",
      input: eventData?.field_event_code,
    },
    {
      key: "4",
      label: "Event Partner",

      input: eventData?.field_event_partner?.length
        ? eventData?.field_event_partner?.map((eventPartner, index) => (
          <Link to={`/organization/details?id=${eventPartner?.id}`}>
            {`${eventPartner?.title} (${eventPartner?.drupal_internal__nid})${index < eventData?.field_event_partner?.length - 1 ? ", " : ""
              }`}
          </Link>
        ))
        : "",
    },

    {
      key: "5",
      label: "Suppliers",
      input: eventData?.field_event_supplier?.length
        ? eventData?.field_event_supplier?.map((eventSupplier, index) => (
          <Link to={`/organization/details?id=${eventSupplier?.id}`}>
            {`${eventSupplier?.title} (${eventSupplier?.drupal_internal__nid
              })${index < eventData?.field_event_supplier?.length - 1 ? ", " : ""
              }`}
          </Link>
        ))
        : "",
    },
    {
      key: "6",
      label: "Start Date",
      input: eventData?.field_start_date ? (
        <>
          <span>
            {moment(eventData?.field_start_date).format("MM/DD/YYYY")}
          </span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {moment(eventData?.field_start_date, "YYYY-MM-DD").fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "7",
      label: "End Date",
      input: eventData?.field_end_date ? (
        <>
          <span>{moment(eventData?.field_end_date).format("MM/DD/YYYY")}</span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {moment(eventData?.field_end_date, "YYYY-MM-DD").fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "8",
      label: "Destination",
      input: eventData?.field_destination?.name || "",
    },
    {
      key: "8",
      label: "Destination Country",
      input: eventData?.field_destination_country || "",
    },
    {
      key: "9",
      label: "Ship",
      input: eventData?.field_ship?.name || "",
    },
    {
      key: "10",
      label: "Toll Free Phone Number",
      input: (
        <>
          {eventData?.field_tollfree_extension &&
            eventData?.field_tollfree_phone
            ? `${eventData?.field_tollfree_extension}-${eventData?.field_tollfree_phone}`
            : ""}
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4 className="mb-0">General Information</h4>
        <span>
          {permissionChecker("add/edit event") ? (
            <Tooltip title="Edit" placement="top" >
              <Link to={`${EventRoute}?editId=${eventId}&step=1`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="general_information_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
        loading={{ spinning: loading, indicator: <DataLoader /> }}
      />
    </div>
  );
}

export default GeneralInformations;
