import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  AddonDiscountRoute,
  AddonFeeRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventDiscountRoute,
  EventFeesRoute,
} from "../../../../url-routes/urlRoutes";
import { Button, Modal, Skeleton, Tooltip, message } from "antd";
import { deleteEventDiscountApi } from "../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

function DiscountDetailsHeader({
  discountDetailsData,
  loadingDiscountDetailData,
  discountType,
}) {
  const navigate = useNavigate();
  const [searchParamas, setSearchParams] = useSearchParams();
  const eventId = searchParamas.get("id");
  const addonId = searchParamas.get("addonId");
  const discountId = searchParamas.get("discountId");

  const deleteAddonDiscount = async (deleteId) => {
    const response = await deleteEventDiscountApi(deleteId);
    if (response.status) {
      message.success("Fee Deleted Successfully");
      navigate(
        discountType === "event"
          ? `${EventDetailsRoute}?id=${eventId}#Discounts`
          : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const ondeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this Discount?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteAddonDiscount(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const onCopyHandler = (eId, aId, dId) => {
    navigate(
      discountType === "event"
        ? `${EventDiscountRoute}?id=${eId}&copyId=${dId}`
        : `${AddonDiscountRoute}?id=${eId}&addonId=${aId}&copyId=${dId}`
    );
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(
                discountType === "event"
                  ? `${EventDetailsRoute}?id=${eventId}#Discounts`
                  : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
              )
            }
          />
          {loadingDiscountDetailData ? (
            <Skeleton.Input active={true} style={{ width: "400px" }} />
          ) : (
            <div className="header_res">
              <p>
                {discountDetailsData?.name?.name || ""} (
                {discountDetailsData?.field_discount_type?.name || ""})
              </p>
              {permissionChecker("view events") ? <Link
                to={
                  addonId
                    ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                    : `${EventDetailsRoute}?id=${eventId}`
                }
                className="header_smalltext"
              >
                {discountDetailsData?.field_event?.name || ""}{" "}
                {discountDetailsData?.field_event?.drupal_internal__id
                  ? `(${discountDetailsData?.field_event?.drupal_internal__id})`
                  : ""}
              </Link> : null}
            </div>
          )}
        </h3>
        <div className="buttons_flex">
          {permissionChecker("add/edit event discount") ?<Link
            to={
              discountType === "event"
                ? `${EventDiscountRoute}?id=${eventId}&editId=${discountId}`
                : `${AddonDiscountRoute}?id=${eventId}&addonId=${addonId}&editId=${discountId}`
            }
          >
            <Tooltip title="Edit">
              <Button icon={<EditOutlined />} />
            </Tooltip>
          </Link> :null}
          {permissionChecker("copy event discounts") ? <Tooltip title="Copy">
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                onCopyHandler(eventId, addonId, discountId);
              }}
            />
          </Tooltip> : null}
          { permissionChecker("delete discounts entities") ? <Tooltip title="Delete">
            <Button
              className="delete_btn"
              onClick={(e) => {
                e.preventDefault();
                ondeleteHandler(discountId);
              }}
              icon={<DeleteOutlined />}
            />
          </Tooltip> : null}
        </div>
      </div>
    </div>
  );
}

export default DiscountDetailsHeader;
