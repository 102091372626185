import { Divider, Form, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";

const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const MiscellaneousForm = ({ form, onFinishHandler, isLoading }) => {
  const [roommatePolicyList, setRoommatePolicyList] = useState([]);
  const [loadingRoommatePolicy, setLoadingRoommatePolicy] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "non_married_roommate_policy",
      setRoommatePolicyList,
      setLoadingRoommatePolicy
    );
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 classname="mb-0">Miscellaneous</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Non-Married Roommate Policy"
            name="field_non_married_roommate_policy"
          >
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={roommatePolicyList.map((roommatePolicy) => ({
                key: roommatePolicy.id,
                value: roommatePolicy.id,
                label: roommatePolicy.name,
              }))}
              loading={loadingRoommatePolicy}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Optional Post Departure Ins Included"
            name="field_optional_post_departure_ins_included"
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="textarea_row">
            <Form.Item
              label="Event Partner Flight Allowance"
              name="field_event_partner_flight_allowance"
            >
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default MiscellaneousForm;
