import { Table, Button, Divider, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import {
  ContactDetailRoute,
  ReservationEditRoute,
} from "../../../../url-routes/urlRoutes";
import { useSelector } from "react-redux";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function ReservationGeneralInfo({
  reservationData,
  reservationId,
  loadingReservationData,
}) {
  const { highlightSection } = useSelector((state) => state.reservation);
  const data = [
    {
      key: "1",
      label: "Reservation Status",
      input: reservationData?.reservation_status?.label || "",
    },
    {
      key: "2",
      label: "Source",
      input: reservationData?.field_source?.name || "",
    },
    {
      key: "3",
      label: "Channel",
      input: reservationData?.field_channel?.name || "",
    },
    {
      key: "4",
      label: "Detail",
      input: reservationData?.field_detail?.name || "",
    },
    {
      key: "5",
      label: "Referred by Contact",
      input: reservationData?.referred_by_contact?.name ? (
        <Link
          to={`${ContactDetailRoute}?id=${reservationData?.referred_by_contact?.id}`}
        >
          {reservationData?.referred_by_contact?.name}
        </Link>
      ) : (
        ""
      ),
    },
    {
      key: "6",
      label: "Travel Agent",
      input: reservationData?.field_travel_agent?.name ? (
        <Link
          to={`${ContactDetailRoute}?id=${reservationData?.field_travel_agent?.id}`}
        >
          {reservationData?.field_travel_agent?.name}
        </Link>
      ) : (
        ""
      ),
    },
    {
      key: "7",
      label: "Traveling With",
      input: reservationData?.traveling_with || "",
    },
    {
      key: "8",
      label: "Group With",
      input: reservationData?.group_with ? (
        <Link
          to={`/reservation/details/group-with/details?id=${reservationId}&event_id=${reservationData?.select_event?.drupal_internal__id}&name=${reservationData.group_with}`}
        >
          {reservationData.group_with}
        </Link>
      ) : (
        ""
      ),
    },
    {
      key: "9",
      label: "Bed Request",
      input: reservationData?.field_bed_request?.name || "",
    },
    {
      key: "10",
      label: "Dining Time Request",
      input: reservationData?.field_dining_time_request?.name || "",
    },
    {
      key: "11",
      label: "Dining Time Assigned",
      input: reservationData?.field_dining_time_assigned?.name || "",
    },
    {
      key: "12",
      label: "Table Size Request",
      input: reservationData?.field_table_size_request?.name || "",
    },
    {
      key: "13",
      label: "Table No",
      input: reservationData?.table_no,
    },
    {
      key: "14",
      label: "Table Status",
      input: reservationData?.field_table_status?.name || "",
    },
    {
      key: "15",
      label: "Charter Group",
      input: reservationData?.field_charter_group?.name || "",
    },
    {
      key: "16",
      label: "Charter Priority",
      input: reservationData?.field_charter_priority?.name || "",
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">General Information</h4>
        {permissionChecker("add/edit reservation") ? (
          <span>
            <Tooltip title="Edit" placement="top">
              <Link to={`${ReservationEditRoute}?editId=${reservationId}`}>
                {" "}
                <Button
                  danger={!!highlightSection?.general_information}
                  icon={<EditOutlined />}
                />
              </Link>
            </Tooltip>
          </span>
        ) : null}
      </div>
      <Divider className="global_divider mb-0" />
      {/* <Divider style={{ marginBottom: '0' }} /> */}
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        loading={{ spinning: loadingReservationData, size: "large" }}
        size="small"
      />
    </div>
  );
}

export default ReservationGeneralInfo;
