import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message } from "antd";
import "./OrganizationsListingPage.less";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Cookie, customRequestHeader } from "../../utils/cutomRequestHeader";
import { getAllOrganisations } from "../../redux/features/organizationFeatures/organizationSlice";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import OrganizationsListContainer from "../../components/Organizations/OrganizationsListing/OrganizationsListContainer";
import OrganizationsListHeader from "../../components/Organizations/OrganizationsListing/OrganizationsListHeader";
import OrganizationSearchBar from "../../components/Organizations/OrganizationsListing/OrganizationSearchBar";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
const { Header, Content, Sider } = Layout;

function OrganizationsListingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [isOrgDeleted, setIsOrgDeleted] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { useBreakpoint } = Grid;
  const dispatch = useDispatch();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const { organizationsListingResponse, loadingOrganizationsList } =
    useSelector((state) => state.organizations.organizationsListing);
  const organizationsListRef = useRef(false);
  const [organizationsListData, setOrganizationsListData] = useState([]);
  const [listTotalCount, setListTotalCount] = useState(0);
  const page = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const organizationType = searchParams.get("org_type");
  const organizationName = searchParams.get("name");
  const search = searchParams.get("search");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
    search,
    name: organizationName,
    org_type: organizationType,
  });

  useEffect(() => {
    if (organizationsListRef.current) {
      if (organizationsListingResponse.status) {
        setOrganizationsListData(organizationsListingResponse.data?.rows || []);
        setListTotalCount(
          Number(organizationsListingResponse.data?.pager?.total_items)
        );
      } else {
        message.error(
          organizationsListingResponse?.data?.response?.data?.message ||
            organizationsListingResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        setOrganizationsListData([]);
        setListTotalCount(0);
      }
    }
    organizationsListRef.current = true;
  }, [organizationsListingResponse]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setUrlParamsObject({ count, order, page });
    }
  }, [location.search]);

  useEffect(() => {
    for (let prop in urlParamsObject) {
      if (!urlParamsObject[prop]) {
        delete urlParamsObject[prop];
      }
    }
    setSearchParams(urlParamsObject);
    const reqHeaders = customRequestHeader({
      token: Token,
      cookie: Cookie,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    dispatch(
      getAllOrganisations({
        ...urlParamsObject,
        items_per_page: Number(urlParamsObject?.count),
        page: Number(urlParamsObject?.page) - 1,
        sort_by: "name",
        sort_order: urlParamsObject?.order,
        reqHeaders,
      })
    );
    // const result = "?" + new URLSearchParams(urlParamsObject).toString();
    // // : `?count=10&order=asc&page=1`;
    // dispatch(getAllOrganisations(`api/organization/list${result}`));
  }, [urlParamsObject, isOrgDeleted]);

  const breadCrumbs = [
    { title: "Organizations", path: "/organization/listing" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <OrganizationSearchBar
              organizationName={organizationName}
              organizationType={organizationType}
              // organisationsList={organizationsListData}
              // page={page}
              // order={order}
              // count={count}
              // setSearchParams={setSearchParams}
              setUrlParamsObject={setUrlParamsObject}
              urlParamsObject={urlParamsObject}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <OrganizationsListHeader
              numberOfOrg={listTotalCount}
              isLoading={loadingOrganizationsList}
            />
          </div>
          <Content>
            <OrganizationsListContainer
              organisationsList={organizationsListData}
              totalCount={listTotalCount}
              isLoading={loadingOrganizationsList}
              isOrgDeleted={isOrgDeleted}
              setIsOrgDeleted={setIsOrgDeleted}
              page={page || 1}
              order={order || "ASC"}
              count={count || 10}
              organizationName={organizationName}
              organizationType={organizationType}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              setSearchParams={setSearchParams}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default OrganizationsListingPage;
