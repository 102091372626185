import { ArrowLeftOutlined, CarOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { Button, Radio } from 'antd';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Breadcrumb_elitetravelerdetails({eventName}) {
  const [loadings, setLoadings] = useState([]);
  const navigate = useNavigate();
  const navigateToManageRoles = () => {
    navigate("/elite-travelers");
  };
  return (

    <div className="breadcrumb_content">
      <div className='breadcrumb_heading view_header' >
        <h3 className='flex_column_two' style={{ gap: "12px" }}><ArrowLeftOutlined  onClick={navigateToManageRoles} className="" /> 
         {/* <CarOutlined style={{ fontSize: '32px', marginRight: '12Px' }} />  */}
         <span>{eventName?.name} </span>   <span className='small_text'> {eventName?.id}</span>
        </h3>
      </div>
    </div>

  );
}

export default Breadcrumb_elitetravelerdetails;
