import {
  Button,
  Form,
  Input,
  Modal,
  message,
  Select,
  InputNumber,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getUserRoles,
  searchInput,
  userCreatedOption,
  userUpdatedOption,
} from "../../redux/features/usersFeatures/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { Country } from "country-state-city";
import { createUserApi, updateUserApi } from "../../apis/usersApi";
import "./ManageUsers/customInputNumber.css";
import "./Admin.less";
import FormItem from "antd/es/form/FormItem";
import {
  AUTHENTICATED_USER_ROLE,
  DRUPAL_INTERNAL_ROLES_ID,
  SOMETHING_WENT_WRONG,
} from "../../utils/constants";
import MobileInput from "../Custom/MobileInput";

const CreateUserModal = ({
  openModal,
  setOpenModal,
  modalData,
  editUser = false,
  setEditIndex,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.users.userRoles)?.filter(
    (item) => !DRUPAL_INTERNAL_ROLES_ID.includes(item?.id)
  );
  const countriesList = Country.getAllCountries();

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    // if (!userRoles.length) {
    dispatch(getUserRoles());
    // }
  }, [dispatch]);

  let options = userRoles.map((item) => {
    return {
      label: item.label,
      value: item.id,
    };
  });

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const editUserFunc = async (editPayload) => {
    const data = {
      type: "user--user",
      id: modalData?.id,
      attributes: {
        status: editPayload?.status ? 1 : 0,
        name: editPayload?.name,
        field_first_name: editPayload?.first_name,
        field_last_name: editPayload?.last_name,
        mail: editPayload?.email,
        pass: {
          value: editPayload?.password,
          existing: "xxxxx",
        },
        field_cell_phone_extension: editPayload?.phone_ext,
        field_cell_phone_number: editPayload?.phone,
        field_designation: editPayload?.designation,
      },
    };

    if (
      !editPayload?.phone ||
      editPayload?.phone === "null" ||
      !editPayload?.phone_ext
    ) {
      delete data.attributes["field_cell_phone_number"];
      delete data.attributes["field_cell_phone_extension"];
    }

    let roles = editPayload?.roles?.map((item) => {
      return {
        type: "user_role--user_role",
        id: item.value ? item.value : item,
      };
    });
    data.relationships = {
      roles: { data: [AUTHENTICATED_USER_ROLE, ...roles] },
    };

    setIsLoading(true);
    const response = await updateUserApi(data, modalData.id);
    if (response.status) {
      dispatch(userUpdatedOption(response.data.data));
      message.success("User Updated Successfully");
      setOpenModal(false);
      modalData = {};
      // setGetRoleDependency(!getRoleDependency);
      setIsLoading(false);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setIsLoading(false);
    }
  };

  // get user roles only once

  useEffect(() => {
    let modalRoles;
    if (modalData?.roles?.length) {
      modalRoles = userRoles
        ?.filter((role) =>
          modalData?.roles?.some((item) => item?.id === role?.id)
        )
        .map(({ label, id }) => ({ label, value: id }));
    }

    const {
      name,
      field_first_name,
      field_last_name,
      field_cell_phone_number,
      field_cell_phone_extension,
      mail,
      field_designation,
      status,
    } = modalData || {};

    if (modalData && Object.keys(modalData).length) {
      form.setFieldsValue({
        name,
        first_name: field_first_name,
        last_name: field_last_name,
        email: mail,
        // phone_ext: field_cell_phone?.value.replace(
        //   field_cell_phone.local_number,
        //   ""
        // ),
        phone_ext: field_cell_phone_extension || "+1",
        phone: field_cell_phone_number || "",
        designation: field_designation,
        status: editUser ? status : true,
        // roles: null,
      });
    } else {
      form.setFieldsValue({
        status: editUser ? status : true,
        // roles: null,
      });
    }

    if (modalRoles) {
      form.setFieldsValue({
        roles: modalRoles,
      });
    }
  }, [modalData]);

  const handleOk = () => {};
  const handleCancel = () => {
    setOpenModal(false);
    form.resetFields();
    setEditIndex();
  };
  const onClickHandler = (values) => {
    for (let prop in values) {
      if (!values[prop]) {
        values[prop] = null;
      }
    }
    if (editUser) {
      editUserFunc(values);
      dispatch(searchInput(""));
    } else {
      formSubmit(values);
      // setOpenModal(false);
    }
  };

  // function to create user
  async function createUserRequest(data) {
    setIsLoading(true);
    const userCreated = await createUserApi(data);
    let errors = userCreated?.data?.response?.data?.errors;

    if (!errors) {
      setIsLoading(false);
      dispatch(userCreatedOption(userCreated.data.data));
      form.resetFields();
      setOpenModal(false);
      message.success("User Created Successfully");
    } else {
      setIsLoading(false);
      errors?.forEach((element) => {
        message.error(element?.detail || SOMETHING_WENT_WRONG);
      });
    }
  }

  // To create user
  function formSubmit(values) {
    let relationships = {};
    let data = {
      type: "user--user",
      attributes: {
        status: 1,
        name: values.name,
        field_first_name: values.first_name,
        field_last_name: values.last_name,
        mail: values.email,
        pass: values.password,
        field_cell_phone_extension: values?.phone_ext,
        field_cell_phone_number: values?.phone,
        // field_cell_phone: values.phone_ext + values.phone,
        field_designation: values.designation,
      },
    };

    if (!values.phone || !values?.phone_ext || values?.phone === "null") {
      delete data.attributes["field_cell_phone_number"];
      delete data.attributes["field_cell_phone_extension"];
    }

    if (values?.roles) {
      let data = [];
      values.roles.forEach((element) => {
        data.push({
          type: "user_role--user_role",
          id: element,
        });
        let roles = { data: [AUTHENTICATED_USER_ROLE, ...data] };
        relationships = { roles };
      });
    }
    data["relationships"] = relationships;
    createUserRequest({ data });
  }

  const countryCodeSelector = (name) => {
    return (
      <Form.Item name={name} initialValue={"+1"} noStyle>
        <Select style={{ width: "90px" }} showSearch>
          {countriesList.map((country, index) => (
            <Select.Option
              value={`+${country.phonecode.replace("+", "")}`}
              key={index}
            >
              +{country.phonecode.replace("+", "")}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const validateEmail = (rule, value) => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!value || !emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address");
    }
    return Promise.resolve();
  };

  return (
    <>
      {/* {isLoading? <Loader />: */}
      <Modal
        title={
          <>
            <span className="span_bold">
              {editUser ? "Edit User" : "Add User"}
            </span>
          </>
        }
        footer={
          <>
            {" "}
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();

                form.submit();
              }}
              loading={isLoading}
            >
              {modalData ? "Save" : "Create User"}
            </Button>
          </>
        }
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          autoComplete="new-password"
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 8,
            xxl: 8,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 16,
            xxl: 16,
          }}
          layout="horizontal"
          form={form}
          initialValues={{ status: editUser ? modalData?.status : true }}
          onFinish={onClickHandler}
        >
          <Form.Item
            className="asterick-align"
            label="Username"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Username",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter First Name",
              },
            ]}
          >
            <Input autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter Last Name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
                validateTrigger: "onSubmit",
              },
              {
                required: true,
                message: "Please enter email address",
                validateTrigger: "onSubmit",
              },
              // {
              //   required: editUser ? false : true,
              //   message: "Please input your Email Address",
              // },
            ]}
          >
            <Input autoComplete="new-password" />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Password"
            name="password"
            hasFeedback
            rules={[
              {
                required: editUser ? false : true,
                message: "Please enter your Password",
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: editUser ? false : true,
                message: "",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    editUser &&
                    (getFieldValue("password") === "" ||
                      !getFieldValue("password")) &&
                    (value === "" || !value)
                  ) {
                    return Promise.resolve();
                  }
                  if (!value) {
                    return Promise.reject("Please enter your Confirm Password");
                  }
                  if (value && getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <MobileInput
            label={"Phone Number"}
            extension={"phone_ext"}
            phoneName={"phone"}
            form={form}
          />

          <Form.Item
            className="asterick-align"
            label="Designation"
            name="designation"
          >
            <Input />
          </Form.Item>
          {/* <Form.Item label="Role" name="role">
            <Input disabled={editUser} />
          </Form.Item> */}
          <Form.Item
            className="asterick-align"
            label="Role"
            name="roles"
            rules={[
              {
                required: true,
                message: "Please Select A Role",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              // placeholder="Please select"
              options={options}
            />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Status"
            name="status"
            valuePropName="checked"
          >
            <Switch disabled={!editUser} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateUserModal;
