import { Table, Button, Divider } from "antd";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const IsraelStaffing = ({ allDetails, allDetailsLoading }) => {
  const [currentTab, setCurrentTab] = useState(1);
  // const [notFound, setNotFound] = useState(false);
  const {
    field_guide_request,
    field_travel_coordinators,
    field_free_vip_vehicle_driver,
    field_additional_vip_vehicle_driver,
    field_senior_sound_tech,
    field_sound_techs,
    field_sound_assistant,
    field_sound_tech_van,
    field_luggage_truck,
    field_staffing_vehicle_notes,
  } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Guide Request",
      input: `${
        field_guide_request?.length
          ? field_guide_request?.map(({ name }) => name)?.join(", ")
          : ""
      }`,
    },

    {
      key: "2",
      label: "# of Travel Coordinators",
      input: `${field_travel_coordinators || ""}`,
    },
    {
      key: "3",
      label: "Free VIP Vehicle & Driver",
      input: `${field_free_vip_vehicle_driver || ""}`,
    },
    {
      key: "4",
      label: "Additional VIP Vehicle & Driver",
      input: `${field_additional_vip_vehicle_driver || ""}`,
    },
    // {
    //   key: "4",
    //   label: "Additional VIP Vehicle & Driver",
    //   input: `${field_free_vip_vehicle__driver || ""}`,
    // },
    {
      key: "5",
      label: "Senior Sound Tech",
      input: `${field_senior_sound_tech || ""}`,
    },

    {
      key: "6",
      label: "Sound Techs",
      input: `${field_sound_techs || ""}`,
    },

    {
      key: "7",
      label: "Sound Assistant",
      input: `${field_sound_assistant || ""}`,
    },

    {
      key: "8",
      label: "Sound Tech Van",
      input: `${
        field_sound_tech_van === true
          ? "Yes"
          : field_sound_tech_van === false
          ? "No"
          : ""
      }`,
    },

    {
      key: "9",
      label: "Luggage Truck",
      input: `${
        field_luggage_truck === true
          ? "Yes"
          : field_luggage_truck === false
          ? "No"
          : ""
      }`,
    },

    {
      key: "10",
      label: "Notes",
      input: <>{field_staffing_vehicle_notes || ""}</>,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Staffing & Vehicles</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default IsraelStaffing;
