import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createOrEditFlightApi } from "../../../../../../apis/eventsApi";
import { EventDetailsAddonsDetailsRoute } from "../../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import "./Add_Itinerariesform.less";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const AddonCreateOrEditFlightForm = ({
  form,
  editId,
  eventId,
  addonId,
  flightFormData,
  setOnSaveLoading,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (editId && Object.keys(flightFormData).length) {
      form.setFieldsValue({
        ...flightFormData,
        field_segment: flightFormData?.field_segment?.length
          ? flightFormData?.field_segment?.map((segment) => ({
              ...segment,
              field_departure_date_time: segment?.field_departure_date_time
                ? moment(segment?.field_departure_date_time, "YYYY-MM-DD")
                : null,
              field_departure_time: segment?.field_departure_time
                ? moment(segment?.field_departure_time, "hh:mm A")
                : null,
              field_arrival_date_time: segment?.field_arrival_date_time
                ? moment(segment?.field_arrival_date_time, "YYYY-MM-DD")
                : null,
              field_arrival_time: segment?.field_arrival_time
                ? moment(segment?.field_arrival_time, "hh:mm A")
                : null,
            }))
          : [
              {
                field_departure_date_time: null,
                field_departure_time: null,
                field_arrival_date_time: null,
                field_arrival_time: null,
                field_carrier: null,
                field_flight: null,
                field_departure_city: null,
                field_arrival_city: null,
                field_comments: null,
                field_departure: null,
                field_arrival: null,
                field_print: null,
                field_pre_ext_dep: null,
                field_pre_ext_arr: null,
                field_post_ext_dep: null,
                field_post_ext_arr: null,
                segment_number: null,
              },
            ],
      });
    }
  }, [flightFormData]);

  const onFinishHandler = (values) => {
    const reqMethod = editId ? "patch" : "post";
    const payload = {
      data: {
        type: "flights--flights",
        attributes: {
          name: values.name,
          status: true,
          field_segment: values.field_segment.length
            ? values.field_segment.map((segment) => ({
                ...segment,
                field_departure_date_time: segment?.field_departure_date_time
                  ? segment?.field_departure_date_time.format("YYYY-MM-DD")
                  : null,
                field_departure_time: segment?.field_departure_time
                  ? segment?.field_departure_time.format("hh:mm A")
                  : null,
                field_arrival_date_time: segment?.field_arrival_date_time
                  ? segment?.field_arrival_date_time.format("YYYY-MM-DD")
                  : null,
                field_arrival_time: segment?.field_arrival_time
                  ? segment?.field_arrival_time.format("hh:mm A")
                  : null,
              }))
            : [],
        },
        relationships: {
          field_event: {
            data: [
              {
                type: "event--event",
                id: addonId,
              },
            ],
          },
        },
      },
    };
    if (editId) {
      payload.data.id = editId;
    }
    createOrEditFlight({ reqMethod, payload, flightId: editId });
  };

  const createOrEditFlight = async (data) => {
    setOnSaveLoading(true);
    const response = await createOrEditFlightApi(data);
    if (response.status) {
      message.success(`Flight ${editId ? "Edited" : "Added"} Successfully`);
      navigate(
        `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
      );
      setOnSaveLoading(false);
    } else {
      message.error(
        response?.data?.response?.data?.errors?.[0]?.title ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setOnSaveLoading(false);
    }
  };

  const validateEndDate = (e, value) => {
    if (e.field.includes("field_departure_date_time")) {
      const startDate = value;
      const endDate = form.getFieldValue([
        "field_segment",
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: [
                "field_segment",
                e?.dependencies?.[0]?.[0],
                e?.dependencies?.[0]?.[1],
              ],
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: [
                  "field_segment",
                  e?.dependencies?.[0]?.[0],
                  e?.dependencies?.[0]?.[1],
                ],
                errors: [`Arrival Date cannot be less than the Departure Date`],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue([
        "field_segment",
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject(
            `Arrival Date cannot be less than the Departure Date`
          );
        }
      }
    }
    return Promise.resolve();
  };
  return (
    <Form
      form={form}
      name="dynamic_form_nest_item"
      onFinish={onFinishHandler}
      autoComplete="off"
      {...layout}
      labelCol={{
        sm: 24,
        md: 6,
        lg: 10,
        xl: 8,
        xxl: 8,
      }}
      wrapperCol={{
        sm: 24,
        md: 14,
        lg: 14,
        xl: 18,
        xxl: 14,
      }}
      layout="horizontal"
    >
      <Form.Item
        className="asterick-align"
        name="name"
        label="Flight Name"
        rules={[
          {
            required: true,
            message: "Please enter flight name",
          },
        ]}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 8,
          xxl: 7,
        }}
      >
        <Input />
      </Form.Item>
      <div className="Itinerariesform_main">
        <Form.List
          name="field_segment"
          initialValue={[
            {
              field_departure_date_time: null,
              field_departure_time: null,
              field_arrival_date_time: null,
              field_arrival_time: null,
              field_carrier: null,
              field_flight: null,
              field_departure_city: null,
              field_arrival_city: null,
              field_comments: null,
              field_departure: null,
              field_arrival: null,
              field_print: null,
              field_pre_ext_dep: null,
              field_pre_ext_arr: null,
              field_post_ext_dep: null,
              field_post_ext_arr: null,
              segment_number: 1,
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={
                    key > 0
                      ? { borderTop: "1px solid #e8e8e8", paddingTop: "30px" }
                      : { paddingBottom: "30px" }
                  }
                >
                  <Row>
                    <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        {...restField}
                        name={[name, "segment_number"]}
                        label=" Segment Number"
                        initialValue={name + 1}
                      >
                        <Input
                          type="number"
                          min={0}
                          autoComplete="nope"
                          pattern="/^[0-9\b]+$/"
                          onKeyDown={(evt) => {
                            if (
                              evt.key === "e" ||
                              evt.key === "-" ||
                              evt.key === "+" ||
                              evt.key === "."
                            ) {
                              evt.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>

                      <div>
                        <Form.Item
                          {...restField}
                          className="departure-date-time-wrapper"
                          label="Departure Date & Time"
                          style={{ marginBottom: "0rem", marginTop: "-22px" }}
                        >
                          <div style={{ display: "flex", gap: "1rem" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "field_departure_date_time"]}
                              style={{
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem",
                              }}
                              rules={[
                                {
                                  validator: validateEndDate,
                                  dependencies: [
                                    [name, "field_arrival_date_time"],
                                  ],
                                },
                              ]}
                            >
                              <DatePicker format={"MM/DD/YYYY"} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "field_departure_time"]}
                              style={{
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem",
                                width: "50%",
                              }}
                            >
                              <TimePicker format="hh:mm A" />
                            </Form.Item>
                          </div>
                        </Form.Item>
                      </div>

                      <Form.Item
                        {...restField}
                        label=" Carrier"
                        name={[name, "field_carrier"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label=" Flight #"
                        name={[name, "field_flight"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label=" Departure City"
                        name={[name, "field_departure_city"]}
                      >
                        <Input />
                      </Form.Item>

                      <div>
                        <Form.Item
                          {...restField}
                          className="departure-date-time-wrapper"
                          label=" Arrival Date/Time"
                          style={{ marginBottom: "0rem", marginTop: "-22px" }}
                        >
                          <div style={{ display: "flex", gap: "1rem" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "field_arrival_date_time"]}
                              style={{
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem",
                              }}
                              rules={[
                                {
                                  validator: validateEndDate,
                                  dependencies: [
                                    [name, "field_departure_date_time"],
                                  ],
                                },
                              ]}
                            >
                              <DatePicker format={"MM/DD/YYYY"} />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              name={[name, "field_arrival_time"]}
                              style={{
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem",
                                width: "50%",
                              }}
                            >
                              <TimePicker format="hh:mm A" />
                            </Form.Item>
                          </div>
                        </Form.Item>
                      </div>

                      <Form.Item
                        {...restField}
                        label=" Arrival City"
                        name={[name, "field_arrival_city"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label=" Comments"
                        name={[name, "field_comments"]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
                      {key > 0 ? (
                        <Form.Item>
                          <div className="close-btn">
                            <Button
                              className="close_absolute"
                              type="dashed"
                              onClick={() => remove(name)}
                              icon={<CloseOutlined />}
                            ></Button>
                          </div>
                        </Form.Item>
                      ) : null}

                      <Form.Item
                        {...restField}
                        label="Departure"
                        name={[name, "field_departure"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Arrival"
                        name={[name, "field_arrival"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Print"
                        name={[name, "field_print"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Pre Ext Dep"
                        name={[name, "field_pre_ext_dep"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Pre Ext Arr"
                        name={[name, "field_pre_ext_arr"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Post Ext Dep"
                        name={[name, "field_post_ext_dep"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Post Ext Arr"
                        name={[name, "field_post_ext_arr"]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}

              <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Segment
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>
      </div>
    </Form>
  );
};
export default AddonCreateOrEditFlightForm;
