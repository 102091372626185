import { Switch, Table } from "antd";
import { Divider } from "antd";
import React from "react";
import DataLoader from "../../../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const RoomViewInformation = ({ inventoryData, inventoryLoading }) => {
  const {
    field_location,
    field_view,
    field_balcony,
    field_bed,
    field_extra_bed,
  } = inventoryData || {};

  const data = [
    {
      key: "1",
      questions: "Location",
      answers: (
        <>
          <span>{field_location?.name || ""}</span>
        </>
      ),
    },
    {
      key: "2",
      questions: "Location 2",
      answers: (
        <>
          <span>{inventoryData?.field_location_2 || ""}</span>
        </>
      ),
    },
    {
      key: "1",
      questions: "View",
      answers: (
        <>
          <span>{field_view?.name || ""}</span>
        </>
      ),
    },
    {
      key: "2",
      questions: "Balcony",
      answers: (
        <>
          <span>{field_balcony ? "Yes" : "No"}</span>
          {/* <Switch disabled checked={field_balcony || false} /> */}
        </>
      ),
    },

    {
      key: "3",
      questions: "Bed",
      answers: (
        <>
          <span>{field_bed?.name || ""}</span>
        </>
      ),
    },
    {
      key: "4",
      questions: "Extra Bed",
      answers: (
        <>
          <span>{field_extra_bed?.name || ""}</span>
        </>
      ),
    },
    {
      key: "5",
      questions: "Connects",
      answers: (
        <>
          <span>{inventoryData?.field_connects || ""}</span>
        </>
      ),
    },
    {
      key: "6",
      questions: "Deck",
      answers: (
        <>
          <span>{inventoryData?.field_deck || ""}</span>
        </>
      ),
    },
    {
      key: "7",
      questions: "Accessible",
      answers: (
        <>
          <Switch disabled checked={inventoryData?.field_accessible || ""} />
        </>
      ),
    },
    {
      key: "8",
      questions: "Organization Category Code",
      answers: (
        <>
          <span>{inventoryData?.field_organization_category_code || ""}</span>
        </>
      ),
    },
    {
      key: "9",
      questions: "Smoking",
      answers: (
        <>
          <Switch disabled checked={inventoryData?.field_smoking || ""} />
        </>
      ),
    },
    {
      key: "10",
      questions: "Age",
      answers: (
        <>
          <span>{inventoryData?.field_age || ""}</span>
        </>
      ),
    },
    {
      key: "11",
      questions: "Type",
      answers: (
        <>
          <span>{inventoryData?.field_type || ""}</span>
        </>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>Room Information</h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        loading={{ spinning: inventoryLoading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default RoomViewInformation;
