import { Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import moment from "moment";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Email Type",
    dataIndex: "email_type",
    key: "email_type",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
];
const LastDaysEmailMonitorContainer = ({completed}) => {
  const [data, setData] = useState([
    { date:"10/12/2023",email_type: "Email 1", count: 0 },
    { date:"15/12/2023",email_type: "Email 2 ", count: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updateData = [];
    completed?.data?.data.forEach((val,index)=>{
      if(Number(val.terms_and_conditions_count) > 0){
        updateData.push({count:val?.terms_and_conditions_count,date:moment(val?.datetime_value).format("MM/DD/YYYY"),email_type:"Terms & Conditions"})
      }
      if(Number(val.booking_cofirmation_count) > 0){
        updateData.push({count:val?.booking_cofirmation_count,date:moment(val?.datetime_value).format("MM/DD/YYYY"),email_type:"Booking Confirmation"})
      }
    })
    setData(updateData)
  }, [completed]);
  // useEffect(() => {
  //   getEmailTemplates();
  // }, []);

  // const getEmailTemplates = async () => {
  //   setLoading(true);
  //   const res = await getEmailTemplatesListApi();
  //   if (res.status) {
  //     setData(
  //       res?.data?.data?.map((listItem) => ({
  //         key: listItem.id,
  //         email_templates: listItem.title,
  //         action: (
  //           <EditOutlined
  //             className="action_edit"
  //             onClick={() => {
  //               navigate(`${AdminEmailTemplateEditRoute}?id=${listItem?.id}`);
  //             }}
  //           />
  //         ),
  //       }))
  //     );
  //     setLoading(false);
  //   } else {
  //     setData([]);
  //     message.error(SOMETHING_WENT_WRONG);
  //     setLoading(false);
  //   }
  // };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>Last 30 Days</h3>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default LastDaysEmailMonitorContainer;
