import { EditOutlined, FileOutlined, DeleteOutlined, SearchOutlined, ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { Dropdown, Menu, Space } from 'antd';

import React, { useState } from 'react';

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="#">File</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: 'Delete',
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: 'Find Duplicates',
        key: '3',
      },
    ]}
  />
);

function Fees_breadcrumb() {
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  return (

    <div className="breadcrumb_content">
      <div className='breadcrumb_heading view_header' >
        {/* <h3><ArrowLeftOutlined className="back-icon-profile" />   <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Category Fees (PS PS Pinnacle Suite)  </span>   <span className='span_serial' style={{ fontSize: '14px', lineHeight: '22px', fontWeight: '400', fontWeight: '500', fontFamily: "'Poppins', sans-serif", color: '#00000073', opacity: '0.6' }} >Gaither Homecoming 2023 Caribbean Cruise (31554)</span>
        </h3> */}


        <h3 className='flex_row_column'>
          <ArrowLeftOutlined className="back-icon-profile" />
          <div className='header_res'>
            <p>Category (PS PS Pinnacle Suite)  </p>
            <p className='header_smalltext' >Gaither Homecoming 2023 Caribbean Cruise (31554)</p>
          </div>
        </h3>



        <div className="breadcrum_buttons">
          <Button>Cancel</Button>
          <Button type="primary">Save</Button>
        </div>

      </div>
    </div>

  );
}

export default Fees_breadcrumb;
