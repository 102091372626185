import { Divider, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TourPricingOptionsForm = ({ onFinishHandler, form, isLoading }) => {
  const [oneForProgramList, setOneForProgramList] = useState([]);
  const [loadingOneForProgram, setLoadingOneForProgram] = useState(false);
  useEffect(() => {
    getTaxonomyList(
      "one_for_program",
      setOneForProgramList,
      setLoadingOneForProgram
    );
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Pricing Options</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label={
              <div div className="text_break">
                <div>Est Group Size For</div>
                <div>Space Request</div>
              </div>
            }
            name="field_group_size_req"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Est Group Size For Pricing"
            name="field_group_size_pricing"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div div className="text_break">
                <div>Est Bus Count </div>
                <div>(Based On Group Size)</div>
              </div>
            }
            name="field_est_bus_count"
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item label="Est Staff Count" name="field_est_staff_count">
            <Input />
          </Form.Item>

          <Form.Item label="Pricing Based On" name="field_pricing_based_on">
            <Input />
          </Form.Item>

          <Form.Item label="1 for Program" name="field_one_for_program">
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={oneForProgramList?.map((oneForProgram) => ({
                key: oneForProgram.id,
                value: oneForProgram.drupal_internal__tid,
                label: oneForProgram.name,
              }))}
              loading={loadingOneForProgram}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Event Partner Income" name="field_partner_income">
            <Input />
          </Form.Item>

          <Form.Item label="Purpose for Income" name="field_purpose_for_income">
            <Input />
          </Form.Item>

          <Form.Item label="Tip Budget" name="field_tip_budget">
            <Input />
          </Form.Item>

          <div className="textarea_row">
            <Form.Item label="Misc Items" name="field_misc_items">
              <TextArea rows={4} />
            </Form.Item>
          </div>

          <div className="textarea_row">
            <Form.Item label="Notes" name="field_notes">
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default TourPricingOptionsForm;
