import { Breadcrumb, Form, Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb_allplannedpayment from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentHeader";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import All_plannedform from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentFormContainer";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../../components/CommonTopBar/CommonTopBar";
import PlannedPaymentHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentHeader";
import PlannedPaymentFormContainer from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PlannedPaymentFormContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addPlannedPaymentApi,
  getPlannedPaymentDetailApi,
  getReservationDetailApi,
} from "../../../apis/reservationApi";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import moment from "moment";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content, Sider } = Layout;

function AddPlannedPaymentPage() {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const travellerId = searchParams.get("travellerId");
  const reservationId = searchParams.get("id");
  const paymentId = searchParams.get("paymentId");
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const [loading, setLoading] = useState(false);
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const { uuid } = authorization;
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Details", path: `${ReservationDetailRoute}?id=${reservationId}` },
    { title: `${paymentId ? "Edit" : "Add"} Planned Payment`, path: "" },
  ];
  const [paymentData, setPaymentData] = useState({});
  const [loadingPaymentData, setLoadingPaymentData] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    getReservationDetail(reservationId);
    if (paymentId) {
      getPaymentDetails(paymentId);
    }
  }, []);
  useEffect(() => {
    if (paymentId && Object.keys(paymentData).length) {
      form.setFieldsValue({
        field_payname: paymentData?.field_payname || null,
        field_planamount: paymentData?.field_planamount || null,
        field_paydate: paymentData?.field_paydate
          ? moment(paymentData?.field_paydate, "YYYY-MM-DD")
          : null,
        field_invoiceno: paymentData?.field_invoiceno || null,
        field_docref_id: paymentData?.field_docref_id || null,
        field_authsign: paymentData?.field_authsign || null,
        field_docsignlink: paymentData?.field_docsignlink?.value || null,
        field_planned_payment_status:
          paymentData?.field_planned_payment_status || null,
        field_paymenttype: paymentData?.field_paymenttype?.uuid || null,
        field_paymethod: paymentData?.field_paymethod?.uuid || null,
      });
    }
  }, [paymentData]);

  const getPaymentDetails = async (paymentId) => {
    setLoadingPaymentData(true);
    const res = await getPlannedPaymentDetailApi(paymentId);
    if (res.status) {
      setPaymentData(res?.data?.data);
      setLoadingPaymentData(false);
    } else {
      setPaymentData({});
      setLoadingPaymentData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const onFinishHandler = (values) => {
    console.log("🚀 ~ onFinishHandler ~ values:", values)
    let payload = {
      data: {
        type: "add_planned_payment--add_planned_payment",
        attributes: {
          name: values?.field_payname || null,
          field_payname: values?.field_payname || null,
          field_planamount: values?.field_planamount,
          field_paydate: values?.field_paydate
            ? values?.field_paydate.format("YYYY-MM-DD")
            : null,
          field_docref_id: values?.field_docref_id || null,
          field_authsign: values?.field_authsign,
          field_docsignlink: values?.field_docsignlink || null,
          field_planned_payment_status: paymentId
            ? values?.field_planned_payment_status.key || null
            : values?.field_planned_payment_status || null,
          field_paymethod: values?.field_paymethod || "",
          field_paymenttype: values?.field_paymenttype || ""

        },
        relationships: {
          field_traveler: travellerId
            ? {
                data: {
                  type: "traveler--traveler",
                  id: travellerId,
                },
              }
            : null,
          field_reservation: reservationId
            ? {
                data: {
                  type: "reservation--reservation",
                  id: reservationId,
                },
              }
            : null,
          field_last_modified_by: {
            data: {
              type: "user--user",
              id: uuid,
            },
          },
        },
      },
    };
    if (paymentId) {
      payload.data.id = paymentId;
    }
    addPlannedPayment({
      reqMethod: paymentId ? "patch" : "post",
      payload,
      paymentId,
    });
  };

  const addPlannedPayment = async (data) => {
    setLoading(true);
    const res = await addPlannedPaymentApi(data);
    if (res.status) {
      setLoading(false);
      message.success(
        `Planned Payment ${paymentId ? "Updated" : "Added"} Successfully`
      );
      navigate(`${ReservationDetailRoute}?id=${reservationId}`);
    } else {
      setLoading(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <PlannedPaymentHeader
              form={form}
              loading={loading}
              reservationData={reservationData}
              reservationId={reservationId}
              paymentId={paymentId}
              loadingPaymentData={loadingPaymentData}
            />
          </div>
          <PlannedPaymentFormContainer
            form={form}
            travellerId={travellerId}
            reservationId={reservationId}
            onFinishHandler={onFinishHandler}
            loadingPaymentData={loadingPaymentData}
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AddPlannedPaymentPage;
