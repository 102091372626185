import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";
import "./Res_guestprofile.less";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Reservation_marketing() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Marketing</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Source" name="source" className="asterick-align">
              <Select>
                <option value="Inspiration">Inspiration</option>
                <option value="IMC Concerts">IMC Concerts</option>
                <option value="Legacy Five">Legacy Five</option>
                <option value="Booth Brothers">Booth Brothers</option>
                <option value="Triumphant Quartet">Triumphant Quartet</option>
                <option value="Joseph Habedank">Joseph Habedank</option>
                <option value="TaRanda Greene">TaRanda Greene</option>
                <option value="Greater Vision">Greater Vision</option>
                <option value="The Hoppers">The Hoppers</option>
                <option value="Jim &amp; Melissa Brady">
                  Jim &amp; Melissa Brady
                </option>
                <option value="Geron Davis &amp; Kindred Souls">
                  Geron Davis &amp; Kindred Souls
                </option>
              </Select>
            </Form.Item>

            <Form.Item label="Type" name="type" className="asterick-align">
              <Select>
                <option value="Concert">Concert</option>
                <option value="Event">Event</option>
                <option value="Email">Email</option>
                <option value="Friend">Friend</option>
                <option value="Magazine/Catalog">Magazine/Catalog</option>
                <option value="Mailing">Mailing</option>
                <option value="Onboard Mention">Onboard Mention</option>
                <option value="Social Media">Social Media</option>
                <option value="Website">Website</option>
                <option value="Other">Other</option>
                <option value="Not sure">Not sure</option>
              </Select>
            </Form.Item>
            <Form.Item label="Detail" name="Detail" className="asterick-align">
              <Select>
                <option value="selected">Onboard Mention</option>
              </Select>
            </Form.Item>

            <div className="textarea_row">
              <Form.Item label="Marketing Notes" name="DMarketing Notes">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Referred By Contact" className="asterick-align" name="Referred By Contact">
              <Select
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'covid_nineteen',
                    label: 'Covid 19 Rebooking',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label="Travel Agent" className="asterick-align" name="travel_agent">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Reservation_marketing;
