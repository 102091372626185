import { Button } from "antd";
import React from "react";

function ChangePasswordHeader() {
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>Change Password</p>
          </div>
        </h3>
        {/* <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button>Cancel</Button>
          <Button type="primary">Save</Button>
        </div> */}
      </div>
    </div>
  );
}
export default ChangePasswordHeader;
