import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import DataLoader from "../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function Resersystem_information({ activityDetails, loadingSingleActivity }) {
  const { user_id, field_last_modified_by, created, changed } =
    activityDetails || "";

  const data = [
    {
      key: "1",
      label: "Created By",
      input: (
        <>
          <span className="">
            {user_id?.field_first_name
              ? user_id?.field_first_name + " " + user_id?.field_last_name
              : user_id?.display_name}
          </span>
          <span className="span_text">
            {created ? ` ${moment(created).from(moment())}` : ""}
          </span>
        </>
      ),
    },

    {
      key: "2",
      label: "Last Modified By",
      input: (
        <>
          <span className="">
            {field_last_modified_by?.field_first_name
              ? field_last_modified_by?.field_first_name +
                " " +
                field_last_modified_by?.field_last_name
              : field_last_modified_by?.display_name}
          </span>
          <span className="span_text">
            {changed ? ` ${moment(changed).from(moment())}` : ""}
          </span>
        </>
      ),
    },

    // {
    //   key: "3",
    //   label: "Execution Status",
    //   input: <>
    //   <span className="">Complete</span>

    //   </>,
    // },

    // {
    //   key: "4",
    //   label: "Execution Count",
    //   input: <>
    //   <span className="">1</span>

    //   </>,
    // },

    // {
    //   key: "5",
    //   label: "Execution Time",
    //   input: <>
    //   <span className="">10/24/2022 12:36 PM</span>
    //   <span className="span_text">  17 days ago</span>

    //   </>,
    // },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>System Information</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        loading={{
          spinning: loadingSingleActivity,
          indicator: <DataLoader />,
        }}
        size="small"
      />
    </div>
  );
}

export default Resersystem_information;
