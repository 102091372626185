import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Empty, message, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createOrganization,
  organizationDetailsEditLoader,
} from "../../../../redux/features/organizationFeatures/organizationSlice";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import "./OrganizationAddressInfo.less";
import { COMPLETE_PREVIOUS_STEP } from "../../../../utils/constants";
const { Meta } = Card;

const OrganizationAddressInfo = ({ organizationDetailsData, orgId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { field_address_information, field_step } = organizationDetailsData;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const {
    field_address_line_1,
    field_address_line_2,
    field_city,
    field_state,
    field_country,
    field_postal_code,
  } = field_address_information || {};
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );

  const onEditHandler = (currentStep) => {
    if (Number(currentStep) < 4) {
      message.error(COMPLETE_PREVIOUS_STEP);
    } else {
      navigate(`/organization?editId=${orgId}&step=3`);
    }
  };

  const deleteModal = (organizationId) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(organizationDetailsEditLoader(false));
        const reqMethod = "patch";
        const reqHeaders = customRequestHeader({
          token: Token,
          defaultFormat: false,
          isHeaderAbsent: false,
          isUploadReq: false,
        });
        const payload = {
          data: {
            type: "node--organization",
            id: organizationId,
            attributes: {
              field_address_information: {
                field_address_line_1: "",
                field_address_line_2: "",
                field_city: "",
                field_state: "",
                field_country: "",
                field_postal_code: "",
              },
              field_step,
            },
            relationships: {
              field_modified_by: {
                data: {
                  type: "user--user",
                  id: userUuid,
                },
              },
            },
          },
        };
        return dispatch(
          createOrganization({ reqMethod, orgId, reqHeaders, payload })
        );
      },
    });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Address Information</h4>
        {!field_address_line_1 &&
          !field_address_line_2 &&
          !field_city &&
          !field_country &&
          !field_state &&
          !field_postal_code && (
            <span>
              {allowEditOrganizations ? (
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    onEditHandler(field_step || 0);
                  }}
                  type="primary"
                >
                  Add Address
                </Button>
              ) : null}
            </span>
          )}
      </div>
      <Divider className="global_divider mb-0" />
      <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
        {!!field_address_line_1 ||
          !!field_address_line_2 ||
          !!field_city ||
          !!field_state ||
          !!field_country ||
          !!field_postal_code ? (
          <Card className="address_modal_card" type="inner" style={{ width: "50%" }}>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: "600",
                fontFamily: "'Poppins'",
              }}
            >
              {`${field_address_line_1 ? `${field_address_line_1}, ` : ""}${field_address_line_2 ? `${field_address_line_2}, ` : ""
                }${field_city ? `${field_city}, ` : ""}${field_state ? `${field_state}, ` : ""
                }${field_country ? `${field_country}, ` : ""}${field_postal_code ? `${field_postal_code}` : ""
                }`}
            </p>

            <Divider className="mb-0" />
            <div className="card_anchor">
              <Button
                onClick={() => {
                  navigate(`/organization?editId=${orgId}&step=3`);
                }}
              >
                Edit
              </Button>
              <Button
                onClick={() => {
                  deleteModal(orgId);
                }}
              >
                Delete
              </Button>
            </div>
          </Card>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};
export default OrganizationAddressInfo;
