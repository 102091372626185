import { Collapse, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import React from "react";
import {
  AddonCategoryDetailsPricingRoute,
  EventCategoryDetailsPricingRoute,
} from "../../../../../../url-routes/urlRoutes";
import PricingTable from "./PricingTable";
import { permissionChecker } from "../../../../../../utils/permisssionChecker";
const { Panel } = Collapse;

const Pricing = ({
  addonId,
  eventId,
  categoryId,
  categoryDetails,
  loadingSingleCategory,
}) => {
  function redirectTo() {
    return addonId
      ? `${AddonCategoryDetailsPricingRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsPricingRoute}?id=${eventId}&categoryId=${categoryId}`;
  }

  return (
    <Collapse
      bordered={false}
      //    defaultActiveKey={['1']}
    >
      <Panel
        header={
          <>
            <div className="collapse_flex">
              <p className="pricing_paragraph">Pricing</p>
                {permissionChecker("add category") ? 
                <Link to={redirectTo()}>
                <Button>{<EditOutlined />}</Button>
              </Link> : null }
            </div>
            <Divider className="global_divider mb-0" />
          </>
        }
        key="1"
      >
        <PricingTable
          categoryDetails={categoryDetails}
          loadingSingleCategory={loadingSingleCategory}
        />
      </Panel>
    </Collapse>
  );
};
export default Pricing;
