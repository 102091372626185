import { GlobalOutlined } from "@ant-design/icons";
import { Empty, Select, Skeleton, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AllEvents.less";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { EventDetailsRoute } from "../../../../url-routes/urlRoutes";
import {
  capitalizeFirstLetter,
  decodeContent,
} from "../../../../utils/contentParser";
import { useSelector } from "react-redux";
import { createFilterTags } from "../../../../others/util/filterTagsCreator";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const Option = Select.Option;

const authData = JSON.parse(localStorage.getItem("authorization"));

const columns = [
  {
    title: "#",
    dataIndex: "hash",
    render: (value, recordObj) =>
      permissionChecker("view events") ? (
        <Link to={`${EventDetailsRoute}?id=${recordObj?.uuid}`}>
          <a>{value}</a>
        </Link>
      ) : (
        <span>{value}</span>
      ),
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (value, recordObj) =>
      permissionChecker("view events") ? (
        <Link to={`${EventDetailsRoute}?id=${recordObj?.uuid}`}>
          <span>{decodeContent(value)}</span>
        </Link>
      ) : (
        <span>{value}</span>
      ),
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Status",
    dataIndex: "tags",
  },
  {
    title: "Start Date",
    dataIndex: "startdate",
  },

  {
    title: "End Date",
    dataIndex: "enddate",
  },
  {
    title: "Destination",
    dataIndex: "destination",
  },
  {
    title: "Venue",
    dataIndex: "ship",
  },
  {
    title: "Organization",
    dataIndex: "eventPartners",
  },
];

const AllEvents = ({
  allEventsData,
  isLoading,
  urlParamsObject,
  setUrlParamsObject,
  eventCount,
  totalItems,
}) => {
  const { isFilterApplied, searchFilters } = useSelector(
    (state) => state.events
  );

  const navigate = useNavigate();
  useEffect(() => {
    const isAllowed = permissionChecker("view navigation menu events");
    if (!isAllowed) {
      navigate("/page-not-access");
    }
  }, []);
  const handleSorting = (value) => {
    if (value === "recently_read") {
      setUrlParamsObject({
        // ...urlParamsObject,
        count: 10,
        order: "recently_read",
        page: 1,
        tab: urlParamsObject?.tab,
      });
      // setIsFilterApplied(false);
    } else {
      setUrlParamsObject({ ...urlParamsObject, order: value });
    }
  };

  const generateFieldStatus = (eventData = {}) => {
    if (
      eventData?.field_is_archive !== "none" &&
      eventData?.field_is_archive === "Archive"
    ) {
      return "Archived";
    }
    if (eventData?.field_status) {
      return eventData?.field_status === "lock"
        ? "Locked"
        : eventData?.field_status === "cancel"
        ? "Canceled"
        : eventData?.field_status === "complete"
        ? "Complete"
        : capitalizeFirstLetter(eventData?.field_status) || "";
    }
    return "";
  };

  const data = !!allEventsData?.length
    ? allEventsData?.map((eventData, index) => {
        console.log(eventData);
        return {
          key: index + 1 || "",
          uuid: eventData?.uuid || "",
          hash: index + 1,
          name: eventData?.name || "",
          code: eventData?.field_event_code || "",
          tags: (
            <>
              <Tag color="">
                {generateFieldStatus(eventData)}
                {/* {eventData?.field_is_archive === "Archive"
                  ? "archive"
                  : eventData?.field_status || ""} */}
              </Tag>
            </>
          ),
          startdate: eventData?.field_start_date || "",
          enddate: eventData?.field_end_date || "",
          destination: eventData?.field_destination?.name || "",
          ship: eventData?.field_ship_export?.name || "",
          eventPartners: eventData?.field_event_partner?.length
            ? eventData?.field_event_partner
                ?.map((item, i) => decodeContent(item.title))
                .join(", ")
            : "",
          // eventData?.field_event_partner?.map((item, i) => {
          //   if (i === eventData?.field_event_partner.length - 1) {
          //     return item?.title;
          //   } else if (i < eventData?.field_event_partner.length - 1) {
          //     return `${item?.title}, `;
          //   }
          // }),
        };
      })
    : [];

  // console.log("vvvvvv", totalItems);
  // if (!isLoading && !data?.length) {
  //   return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  // }

  return (
    <>
      {/* {!!data?.length && !isLoading && ( */}
      <div className="table_filter filter_pagination">
        {isFilterApplied && (
          <div className="filter-applied--wrapper">
            <span className="filter-title">Filter(s) Applied:</span>{" "}
            {createFilterTags(searchFilters)}
          </div>
        )}
        {isLoading ? (
          <Skeleton.Input size="medium" style={{ width: "250px" }} />
        ) : (
          <>
            <span>Sort by :</span>
            <Select
              disabled={isLoading}
              defaultValue="ASC"
              style={{
                width: 165,
                marginLeft: "10px",
              }}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={urlParamsObject.order}
              onChange={(e) => {
                handleSorting(e);
              }}
            >
              <Option value="recently_read" label="Recently Viewed">Recently Viewed</Option>
              <Option value="ASC" label="Ascending">Ascending</Option>
              <Option value="DESC" label="Descending">Descending</Option>
            </Select>
          </>
        )}
      </div>
      {/* )} */}
      <Table
        className="event_listing_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        pagination={
          urlParamsObject?.order === "recently_read"
            ? false
            : {
                current: Number(urlParamsObject?.page),
                pageSize: urlParamsObject?.count,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                total: Number(totalItems),
                position: ["topRight", "bottomRight"],
                onChange: (pageNo, pageSize) => {
                  setUrlParamsObject({
                    ...urlParamsObject,
                    // tab: "Tour",
                    page: pageNo,
                    count: pageSize,
                  });
                },
              }
        }
      ></Table>
    </>
  );
};
export default AllEvents;
