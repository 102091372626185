import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const CruiseSystemInformation = ({ allDetails, allDetailsLoading }) => {
  const { user_id, modified_by, created, changed } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Created By",
      input: (
        <>
          <span className="">
            {user_id?.field_first_name
              ? user_id?.field_first_name + " " + user_id?.field_last_name
              : user_id?.display_name}
          </span>
          <span className="span_text">
            {created ? ` ${moment(created).from(moment())}` : ""}
          </span>
        </>
      ),
    },
    {
      key: "2",
      label: "Last Modified By",
      input: (
        <>
          <span className="">
            {modified_by?.field_first_name
              ? modified_by?.field_first_name +
                " " +
                modified_by?.field_last_name
              : modified_by?.display_name}
          </span>
          <span className="span_text">
            {changed ? ` ${moment(changed).from(moment())}` : ""}
          </span>
        </>
      ),
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>System Information</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};
export default CruiseSystemInformation;
