import { Button, Skeleton } from "antd";
import { SearchOutlined, CarOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBus } from "@fortawesome/free-solid-svg-icons";
import { faShip } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import React from "react";
import {
  GroupDetailsCruise,
  GroupDetailsTour,
} from "../../../url-routes/urlRoutes";
import { useSelector } from "react-redux";

function ListingHeader({ urlParamsObject }) {
  const { allGroupDetails } = useSelector((state) => state.groupDetails);
  const { allGroupDetailsResponse, loadingAllGroupDetails } = allGroupDetails;
  const permisssions = JSON.parse(localStorage.getItem("authorization"))?.permissions

  function deriveTotalCount() {
    if (allGroupDetailsResponse?.status) {
      let data = allGroupDetailsResponse?.data?.[0] || {};
      let totalCount =
        urlParamsObject?.order === "recently_read"
          ? data?.recently_viewed_count || data?.recently_read?.rows?.length
          : data?.total_count;
      return totalCount;
    }
    return 0;
  }

  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>Group Details</p>
            <p className="header_smalltext">
              {loadingAllGroupDetails ? (
                <Skeleton.Button
                  shape="round"
                  size="small"
                  style={{ width: "20px" }}
                />
              ) : (
                `(${deriveTotalCount() || "0"})`
              )}
            </p>
          </div>
        </h3>
        <div className="breadcrum_buttons">
      {permisssions?.includes("add/edit group details") ? (<>
        <Link to={GroupDetailsCruise}>
            <Button type="primary">
              <FontAwesomeIcon icon={faShip} />{" "}
              <span style={{ paddingLeft: "8px" }}> Add Cruise</span>
            </Button>
          </Link>

          <Link to={GroupDetailsTour}>
            {" "}
            <Button type="primary">
              <FontAwesomeIcon icon={faBus} />{" "}
              <span style={{ paddingLeft: "8px" }}>Add Tour</span>
            </Button>
          </Link>
      </>) : null
}
          {/* <Button type="primary"><SearchOutlined /> Add Cruise+Tour</Button> */}
        </div>
      </div>
    </div>
  );
}

export default ListingHeader;
