import React, { useEffect, useRef, useState } from "react";
import { Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  getOrganizationAllDetails,
  getOrganizationDetails,
  getPrimaryContactDetails,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import OrganizationContactTable from "../OrganizationDetailsTabs/ContactsTab/OrganizationContactTable";
import OrganizationAddressInfo from "../OrganizationDetailsTabs/GeneralDetailsTab/OrganizationAddressInfo";
import OrganizationBasicDetails from "../OrganizationDetailsTabs/GeneralDetailsTab/OrganizationBasicDetails";
import OrganizationCompanyInfo from "../OrganizationDetailsTabs/GeneralDetailsTab/OrganizationCompanyInfo";
import OrganizationPrimaryContactDetails from "../OrganizationDetailsTabs/GeneralDetailsTab/OrganizationPrimaryContactDetails";
import SystemInfo from "../OrganizationDetailsTabs/SystemInfo/SystemInfo";
import "./OrganizationDetailsTabs.less";
import { updateRecentlyViewedApi } from "../../../apis/usersApi";
import { updateRecentlyViewed } from "../../../utils/recentlyViewedUpdater";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { getOrganizationLinkedContactsApi } from "../../../apis/organisationApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const createUrl = (tab, id) => {
  const TABCONFIG = {
    "Organization Details": {
      pathname: `/api/v1/node/organization/${id}`,
    },
    Contacts: {
      pathname: `api/organization/view/details/${id}?allcontacts=true`,
    },
    Settings: {
      pathname: `api/organization/view/details/${id}?system=true`,
    },
  };
  return TABCONFIG[tab]?.pathname;
};

const OrganizationDetailsTabs = ({ orgId }) => {
  const [currentTab, setCurrentTab] = useState("");
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    organizationAllDetails,
    organizationCreation,
    primaryContactDetails,
    loadingOrganizationDetailsEdit,
  } = useSelector((state) => state.organizations);
  const {
    organizationAllDetailsResponse,
    loadingOrganizationAllDetailsResponse,
  } = organizationAllDetails;
  const { primaryContactDetailsResponse, loadingPrimaryContactDetails } =
    primaryContactDetails;
  const { organizationCreationResponse, loadingOrganizationCreation } =
    organizationCreation;
  const organizationDetailsRef = useRef(false);
  const [organizationDetailsData, setOrganizationDetailsData] = useState({});
  const [organizationContactsData, setOrganizationContactsData] = useState([]);
  const [loadingContactData, setLoadingContactData] = useState(false);
  const [primaryContactInfo, setPrimaryContactInfo] = useState({});
  const primaryContactDetailsRef = useRef(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    let tab = location.hash
      ? decodeURI(location.hash).slice(1)
      : "Organization Details";
    setCurrentTab(tab);
  }, [location.hash]);

  useEffect(() => {
    if (organizationDetailsRef.current) {
      if (organizationAllDetailsResponse.status) {
        if (
          (currentTab === "Organization Details" ||
            currentTab === "Contacts" ||
            currentTab === "Settings") &&
          !!organizationAllDetailsResponse?.data?.data?.field_primary_contact
            ?.id
        ) {
          const reqHeaders = customRequestHeader({
            token: Token,
            defaultFormat: true,
          });
          dispatch(
            getPrimaryContactDetails({
              contactId:
                organizationAllDetailsResponse?.data?.data
                  ?.field_primary_contact?.id,
              reqHeaders,
            })
          );
        }
        setOrganizationDetailsData(
          organizationAllDetailsResponse.data.data || {}
        );

        // get these keys from response of organization detail
        const { type, langcode, drupal_internal__nid, id } =
          organizationAllDetailsResponse?.data?.data;
        updateRecentlyViewed(type, id, langcode, drupal_internal__nid);
      } else {
        message.error(
          organizationAllDetailsResponse?.data?.message || SOMETHING_WENT_WRONG
        );
        setOrganizationDetailsData({});
      }
    }
    organizationDetailsRef.current = true;
  }, [organizationAllDetailsResponse]);

  useEffect(() => {
    if (primaryContactDetailsRef.current) {
      const {
        drupal_internal__id,
        field_address_field,
        field_job_title,
        field_email_address,
        field_email_address_2,
        name,
        id,
      } = primaryContactDetailsResponse?.data?.data || {};

      if (primaryContactDetailsResponse.status) {
        setPrimaryContactInfo({
          uuid: id,
          id: drupal_internal__id,
          name,
          title: field_job_title,
          email: field_email_address,
          email2: field_email_address_2,
          phone: field_address_field?.[0]?.field_cell_phone
            ? `${field_address_field?.[0]?.field_cell_phone_extension}-${field_address_field?.[0]?.field_cell_phone}`
            : null,
          phone2: field_address_field?.[0]?.field_other_phone
            ? `${field_address_field?.[0]?.field_other_phone_extension}-${field_address_field?.[0]?.field_other_phone}`
            : null,
        });
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setPrimaryContactInfo({});
      }
    }
    primaryContactDetailsRef.current = true;
  }, [primaryContactDetailsResponse]);

  useEffect(() => {
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: true,
    });
    dispatch(getOrganizationAllDetails({ orgId, reqHeaders }));
  }, [organizationCreationResponse]);

  useEffect(() => {
    if (currentTab === "Contacts") {
      setLoadingContactData(true);
      getOrgContactsList();
    }
  }, [currentTab]);

  const handleTabChange = (key) => {
    setCurrentTab(key);
    navigate(`${location.pathname}${location.search}#${key}`);
  };

  const getOrgContactsList = async () => {
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: true,
    });
    const response = await getOrganizationLinkedContactsApi({
      orgId,
      reqHeaders,
    });
    if (response.status) {
      setOrganizationContactsData(response?.data);
      setLoadingContactData(false);
    } else {
      setOrganizationContactsData([]);
      message.error(SOMETHING_WENT_WRONG);
      setLoadingContactData(false);
    }
  };
  if (notFound) {
    navigate("/*");
  }

  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key) => {
        handleTabChange(key);
      }}
    >
      <Tabs.TabPane tab="Organization Details" key="Organization Details">
        <>
          <div className="basic_details pd-16">
            <OrganizationBasicDetails
              organizationDetailsData={organizationDetailsData}
              orgId={orgId}
              isLoading={
                loadingOrganizationAllDetailsResponse &&
                loadingOrganizationDetailsEdit
              }
            />
          </div>
          <div className="basic_details partners_informations pd-16">
            <OrganizationPrimaryContactDetails
              primaryContactDetailsData={primaryContactInfo}
              organizationDetailsData={organizationDetailsData}
              orgId={orgId}
              isLoading={
                (loadingPrimaryContactDetails ||
                  loadingOrganizationAllDetailsResponse) &&
                loadingOrganizationDetailsEdit
              }
            />
          </div>
          <div className="basic_details partners_informations pd-16">
            <OrganizationAddressInfo
              organizationDetailsData={organizationDetailsData || {}}
              orgId={orgId}
              isLoading={
                loadingOrganizationAllDetailsResponse &&
                loadingOrganizationDetailsEdit
              }
            />
          </div>

          <div className="basic_details partners_informations pd-16">
            <OrganizationCompanyInfo
              organizationDetailsData={organizationDetailsData || {}}
              orgId={orgId}
              isLoading={
                loadingOrganizationAllDetailsResponse &&
                loadingOrganizationDetailsEdit
              }
            />
          </div>
        </>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Contact" key="Contacts">
        <div className="reservation_details pd-16 bg-white">
          {/* <Organizationcontact_tab /> */}
          <OrganizationContactTable
            organizationContactsData={organizationContactsData}
            isLoading={loadingContactData}
            orgId={orgId}
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane tab="Settings" key="Settings">
        <div className="settings_details settings_details--system pd-16 bg-white">
          <SystemInfo
            organizationDetailsData={organizationDetailsData || {}}
            loading={loadingOrganizationAllDetailsResponse}
            orgId={orgId}
          />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default OrganizationDetailsTabs;
