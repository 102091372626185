import { Table, Button, Divider, Tabs, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function TourDetails({ allDetails, allDetailsLoading }) {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  const tabArray = ["Pre-Extension", "Tour", "Post-Extension"];
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let type = searchParams.get("type");

  type = capitalizeAndRemoveHyphens(type);

  function capitalizeAndRemoveHyphens(input) {
    switch (input) {
      case 'charter-cruise':
        return 'Charter Cruise';
      case 'cruise-tour':
        return 'Cruise/Tour';
      case 'tour':
        return 'Tour';
      default:
        return input
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  }

  const {
    field_event_details,
    name,
    field_event_code,
    field_event_partner,
    field_length_of_tour,
    field_tour_operator,
    field_tour_itinerary,
  } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Event Partners",
      input: (
        <>
          {field_event_partner?.length
            ? field_event_partner
                ?.map(({ field_organization_name }) => field_organization_name)
                ?.join(", ")
            : ""}{" "}
        </>
      ),
    },
    {
      key: "2",
      label: "Event Name",
      input: <>{name || ""}</>,
    },
    {
      key: "3",
      label: "Event Code",
      input: <>{field_event_code || ""}</>,
    },
    {
      key: "4",
      label: "Requested Dates",
      input: (
        <>
          {(field_event_details?.[0]?.field_requested_start_dates
            ? moment(
                field_event_details?.[0]?.field_requested_start_dates
              ).format(DATE_FORMAT)
            : "") +
            `${
              field_event_details?.[0]?.field_requested_start_dates ? "-" : ""
            }` +
            (field_event_details?.[0]?.field_requested_end_dates
              ? moment(
                  field_event_details?.[0]?.field_requested_end_dates
                ).format(DATE_FORMAT)
              : "")}
        </>
      ),
    },
    {
      key: "5",
      label: "Alternative Dates",
      input: (
        <>
          {(field_event_details?.[0]?.field_alternative_start_dates
            ? moment(
                field_event_details?.[0]?.field_alternative_start_dates
              ).format(DATE_FORMAT)
            : "") +
            `${
              field_event_details?.[0]?.field_alternative_start_dates ? "-" : ""
            }` +
            (field_event_details?.[0]?.field_alternative_end_dates
              ? moment(
                  field_event_details?.[0]?.field_alternative_end_dates
                ).format(DATE_FORMAT)
              : "")}
        </>
      ),
    },
    {
      key: "6",
      label: "Are Dates Flexible?",
      input: (
        <>
          {field_event_details?.[0]?.field_are_dates_flexible === true
            ? "Yes"
            : field_event_details?.[0]?.field_are_dates_flexible === false
            ? "No"
            : ""}
        </>
      ),
    },
    {
      key: "7",
      label: `Length of ${type}`,
      input: <>{field_length_of_tour || ""}</>,
    },
    {
      key: "8",
      label: `${type} Operator`,
      input: <>{field_tour_operator?.title || ""}</>,
    },
    {
      key: "9",
      label: `${type} Itinerary`,
      input: <>{field_tour_itinerary?.name || ""}</>,
    },
    {
      key: "10",
      label: "Event Exclusive",
      input: (
        <>
          <Switch
            checked={field_event_details?.[0]?.field_event_exclusive}
            disabled
          />
        </>
      ),
    },
    {
      key: "11",
      label: "Toll Free Phone Number",
      input: (
        <>
          {allDetails?.field_tollfree_extension &&
          allDetails?.field_tollfree_phone
            ? `${allDetails?.field_tollfree_extension}-${allDetails?.field_tollfree_phone}`
            : ""}
        </>
      ),
    },
    {
      key: "12",
      label: "URL Address",
      input: <>{field_event_details?.[0]?.field_url_address || ""}</>,
    },
  ];

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };
  // const tabItems = allDetails?.field_event_details?.map((item, index) => {
  //   return {
  //     key: index + 1,
  //     label: `Detail ${index + 1}`,
  //     children: (
  //       <Table
  //         className="basic_table"
  //         columns={columns}
  //         dataSource={data}
  //         showHeader={false}
  //         pagination={false}
  //         size="small"
  //       />
  //     ),
  //   };
  // });

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  if (!currentTab) {
    return <DataLoader />;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>{type} Details</h4>
        {/* <span>
          <Link to="/contact">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
      {/* <Tabs
        activeKey={currentTab}
        // className="details-tabs"
        items={tabItems}
        onChange={(key) => {
          handleTabChange(key);
        }}
      ></Tabs> */}
    </div>
  );
}

export default TourDetails;
