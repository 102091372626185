import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Modal, Skeleton, Tag, message } from "antd";
import { Dropdown, Menu, Space, Tooltip } from "antd";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./Intakeview_header.less";
import React, { useState } from "react";
import {
  EventRoute,
  GroupDetailsCruise,
  GroupDetailsTour,
  GroupDetailsListingRoute,
} from "../../../../url-routes/urlRoutes";
import { deleteGroupDetailApi } from "../../../../apis/groupDetailsApi";
import { EventDetailsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <a href="#">Delete</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "Convert to Event",
        key: "3",
      },
    ]}
  />
);

const CruiseOrTourDetailHeader = ({
  // deleteGroupDetail,
  allDetails,
  allDetailsLoading,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = JSON.parse(
    localStorage.getItem("authorization")
  )?.permissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadings, setLoadings] = useState([]);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const pre = searchParams.get("preExtId");
  const post = searchParams.get("postExtId");

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const deleteGroupDetail = async (deleteId) => {
    const response = await deleteGroupDetailApi(deleteId);
    if (response.status) {
      message.success("Deleted successfully");
      navigate(
        `${GroupDetailsListingRoute}?count=10&order=ASC&page=1&tab=Cruise`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id, entity) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete ?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteGroupDetail(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const getLink = () => {
    let preExt = `&preExtId=${pre}`;
    let postExt = `&postExtId=${post}`;
    let hash = location.hash;
    let route = `/group-details/${type}`;

    let URL = `${route}?editId=${id}${pre ? preExt : ""}${
      post ? postExt : ""
    }&type=${type}${hash}`;

    navigate(URL);
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_column_two">
          <span className="">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() => navigate(-1)}
            />
          </span>
          {allDetailsLoading ? (
            <>
              <Skeleton.Input active={true} style={{ width: "250px" }} />
            </>
          ) : (
            <div className="flex_column_two--flex">
              <span>
                {allDetails?.name || ""}
                <span className="small_text_two">
                  {" "}
                  ({allDetails?.drupal_internal__id})
                </span>
              </span>
            </div>
          )}
        </h3>
        {allDetailsLoading ? null : (
          <div className="breadcrumb_buttons">
            {permissions.includes("add/edit group details") ? (
              <Tooltip placement="top" title="Edit">
                <Button type="y" onClick={getLink} icon={<EditOutlined />} />
              </Tooltip>
            ) : null}

            {permissions.includes("delete group details") ? (
              <Tooltip placement="top" title="Delete">
                <Button
                  className="delete_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteHandler(id);
                  }}
                  type="y"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            ) : null}

            {/* <Button type=""> */}
              {allDetails?.field_group_detail_status ===
              "converted_to_event" ? (
                <>
                  {permissions.includes("view events") ? (
                    <Button className="view-event--cta">
                    <a
                      href={`${EventDetailsRoute}?id=${allDetails?.id || ""}`}
                      className="member_hover"
                    >
                      <CopyOutlined /> View Event
                    </a>
                    </Button>
                  ) : null}
                </>
              ) : (
                <>
                  {permissions.includes("convert to events") ? (
                    <Button className="view-event--cta">
                    <a
                      href={`${EventRoute}?editId=${
                        allDetails?.id || ""
                      }&convertToEvent=true`}
                      className="member_hover"
                    >
                      <CopyOutlined /> Convert To Event
                    </a>
                  </Button>
                ) : null}
              </>
            )}
            {/* </Button> */}
          </div>
        )}
        <div className="breadcrumb_toggle">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayClassName="add_intake"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button type="y" icon={<MoreOutlined />} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default CruiseOrTourDetailHeader;
