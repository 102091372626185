import React, { useEffect, useState } from "react";
import { Layout, Grid, Input, Form } from "antd";
import RolesSearchbar from "../../../components/Admin/ManageRoles/RolesSearchbar";
import CreateOrEditRoleHeader from "../../../components/Admin/ManageRoles/CreateOrEditRoleHeader";

import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { useLocation, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import { getSingleRoleApi } from "../../../apis/manageRoles";
import PermissionsDropDown from "./PermissionsDropDown";
const { Header, Content, Sider } = Layout;



const CreateOrEditRolePage = ({viewMode = false}) => {
  const [form] = Form.useForm();
  const queryParams = new URLSearchParams(window.location.search);

  const location = useLocation();
  const { useBreakpoint } = Grid;
  const [permissions, setPermissions] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleData, setRoleData] = useState();
  const editRolePage = location.pathname.includes("edit");
  const [isLoading, setIsLoading] = useState(false);
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Roles", path: "/manage-roles" },

    {
      title: editRolePage ? "Edit Role" : "Create Role",
      path: "/manage-roles/edit",
    },
  ];

  const id = queryParams.get("role");

  const getRoleDetails = async () => {
    setIsLoading(true);
    const response = await getSingleRoleApi(id);
    if (response.status) {
      setRoleName(response?.data?.data?.label || "");
      form.setFieldsValue({
        roleName: response?.data?.data?.label,
      });
      setPermissions(response?.data?.data?.permissions || []);
      setRoleData(response?.data?.data);
      setIsLoading(false);
    } else {
      setRoleName("");
      setPermissions([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getRoleDetails();
    }
  }, []);

  const onChangeHandler = (value, field) => {
    setRoleName(value);
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <RolesSearchbar id={id} />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditRoleHeader
              roleName={roleName}
              permissions={permissions}
              editRolePage={editRolePage}
              roleData={roleData}
              form={form}
            />
          </div>
          {isLoading ? (
            <DataLoader />
          ) : (
            <div>
              <Content
                className="site-layout-background"
                style={{ marginBottom: "20px" }}
              >
                <Form form={form}>
                  <Form.Item
                    className="asterick-align"
                    label="Role Name"
                    name="roleName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter role",
                      },
                    ]}
                  >
                    <Input
                      value={roleName}
                      onChange={(e) => {
                        onChangeHandler(e.target.value, "name");
                      }}
                    />
                  </Form.Item>
                </Form>
              </Content>
            <PermissionsDropDown viewMode={viewMode} setPermissions ={setPermissions} permissions={permissions}/>
            </div>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CreateOrEditRolePage;
