import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, TimePicker} from 'antd';
import { Card, Divider, Anchor } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import React, { useState } from 'react';
const { Option } = Select;

const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};


const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const { TextArea } = Input;


function Reservation_taskactivity() {

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const { Search } = Input;

    const onSearch = (value) => console.log(value);
    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    return (

        <>
            <Button type="" onClick={showDrawer}>
                <PlusOutlined /><span> Add</span>
            </Button>
            <Drawer
                title="Add Activity"
                maxWidth={425}
                onClose={onClose}
                open={open}
                bodyStyle={{ paddingBottom: '32px' }}
                extra={
                    <Space>
                        <Button onClick={onClose} style={{ opacity: '0.8' }} >Cancel</Button>
                        <Button onClick={onClose} type="primary">
                            Save
                        </Button>
                    </Space>
                }
            >



                <Form layout="vertical"
    
      autoComplete="off"
                    >
                    <Row gutter={24}>





                        <Col span={24}>
                            <Form.Item
                                name="Activity Type"
                                label="Activity Type"
                                rules={[{ required: true }]}

                            >
                                <Select placeholder="Comment"

                                >
                                    <Option value="Update">Update</Option>
                                    <Option value="Status Change">Status Change</Option>
                                    <Option value="Execute">Execute</Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="Activity Time"
                                label="Activity Time"
                                rules={[{ required: true }]}

                            >
                                <TimePicker.RangePicker style={{padding:"5px 10px"}}/>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name=" Comments"
                                label=" Comments"
                                rules={[{ required: true }]}

                            >
                                  <TextArea rows={4} />
                            </Form.Item>

                        </Col>

                    </Row>


                </Form>

            </Drawer>
        </>

    );
}

export default Reservation_taskactivity;



