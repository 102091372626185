import { CloseOutlined  } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { Dropdown, Menu, Space } from 'antd';
import React, { useState } from 'react';


function Breadcrumb_resedit() {
    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };
    return (

        <div className="breadcrumb_content">
            <div className='breadcrumb_heading view_header' >
                <h3><CloseOutlined className="back-icon-profile" /> <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Edit Details</span>
                
                </h3>
                <div className="breadcrum_buttons" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'end' }}>
                <Button type="primary">Save</Button>
                    <Button>Cancel</Button>
                   
                </div>


            </div>
        </div>

    );
}

export default Breadcrumb_resedit;
