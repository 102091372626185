import { Breadcrumb, Grid, Layout, Spin, message } from "antd";
import Horizontals from "../../../components/Header/Horizontal_menu";
import FindDuplicateHeader from "../../../components/Breadcrumb/FindDuplicateHeader.js/FindDuplicateHeader";
import FindDuplicates_form from "../../../components/Contacts/ContactDetails/DuplicateContact/FindDuplicateContactData";
import FindDuplicateTable from "../../../components/View_components/FindDuplicate_table/FindDuplicate_table";
import Find_Duplicate_table from "../../../components/Contacts/ContactDetails/DuplicateContact/DuplicateContactList";

import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { ContactDetailRoute } from "../../../url-routes/urlRoutes";
import { useSearchParams } from "react-router-dom";
import ContactSearchBar from "../../../components/Contacts/ContactsListing/ContactSearchBar";
import { Token, customRequestHeader } from "../../../utils/cutomRequestHeader";
import {
  getContactAllDetailsApi,
  getDuplicateContactApi,
} from "../../../apis/contactApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import FindDuplicateContactData from "../../../components/Contacts/ContactDetails/DuplicateContact/FindDuplicateContactData";
import DuplicateContactList from "../../../components/Contacts/ContactDetails/DuplicateContact/DuplicateContactList";
const { Header, Content, Sider } = Layout;

function DuplicateContactPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const contactId = searchParams.get("id");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    { title: "Details", path: `${ContactDetailRoute}?id=${contactId}` },
    { title: "Find Duplicates", path: `` },
  ];
  const [contactData, setContactData] = useState({});
  const [loadingContactData, setLoadingContactData] = useState(false);
  const [duplicateList, setDuplicateList] = useState([]);
  const [duplicateListTrigger, setDuplicateListTrigger] = useState(false);
  useEffect(() => {
    getContactDetails(contactId);
  }, []);

  useEffect(() => {
    if (contactData?.name) {
      getDuplicateContacts(contactData?.name, contactData?.drupal_internal__id);
    }
  }, [contactData, duplicateListTrigger]);

  const getDuplicateContacts = async (name, contact_id) => {
    setLoadingContactData(true);
    const payload = {
      data: {
        validation_type: "name",
        value: name,
        contact_id,
      },
    };
    const response = await getDuplicateContactApi(payload);
    if (response.status) {
      if (response?.data?.duplicates) {
        setDuplicateList(response?.data?.data);
      }
      setLoadingContactData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setDuplicateList([]);
      setLoadingContactData(false);
    }
  };

  const getContactDetails = async (contactId) => {
    setLoadingContactData(true);
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getContactAllDetailsApi({ contactId, reqHeaders });
    if (response.status) {
      setContactData(response?.data?.data);
      setLoadingContactData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setContactData({});
      setLoadingContactData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout className="duplicate_page">
          <Header className="contact_edit main-header">
            <ContactSearchBar
              contactId={contactId}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <FindDuplicateHeader />
          </div>

          <Spin spinning={loadingContactData} size="large">
            <Content style={{ marginBottom: "20px" }}>
              <FindDuplicateContactData
                loadingContactData={loadingContactData}
                contactData={contactData}
              />
            </Content>

            <Content className="minheight_hidden">
              <DuplicateContactList
                duplicateList={duplicateList}
                contactData={contactData}
                setDuplicateListTrigger={setDuplicateListTrigger}
                duplicateListTrigger={duplicateListTrigger}
              />
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default DuplicateContactPage;
