import React from "react";

const EmailTemplatesHeader = () => {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddRole = authData?.permissions?.includes("add/edit role");

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          <span style={{ fontFamily: "'Poppins', sans-serif" }}>
            Email Templates{" "}
          </span>
        </h3>
      </div>
    </div>
  );
};
export default EmailTemplatesHeader;
