import { objectToQueryString } from "../others/util/convertObjectToQueryString";
import { customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
import { stripeAxiosRequest } from "../utils/stripeRequest";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

const createListingUrl = ({
  tab,
  name,
  res_id,
  status,
  contact_name,
  contact_email,
  createdMin,
  createdMax,
  changedMin,
  changedMax,
  event,
  cruise_book_no,
  user_id,
  travel_agent,
}) => {
  return `${tab ? `type=${tab}&` : ""}${name ? `name=${name}&` : ""}${
    res_id ? `res_id=${res_id}&` : ""
  }${status ? `status=${status}&` : ""}${
    contact_name ? `contact_name=${contact_name}&` : ""
  }${contact_email ? `contact_email=${contact_email}&` : ""}${
    createdMin ? `created[min]=${createdMin}&` : ""
  }${createdMax ? `created[max]=${createdMax}&` : ""}${
    changedMin ? `changed[min]=${changedMin}&` : ""
  }${changedMax ? `changed[max]=${changedMax}&` : ""}${
    event ? `event=${event}&` : ""
  }${cruise_book_no ? `cruise_book_no=${cruise_book_no}&` : ""}${
    user_id ? `user_id=${user_id}&` : ""
  }${travel_agent ? `travel_agent=${travel_agent}&` : ""}`;
};

export const getReservationListingAPI = async (payload) => {
  const { page, items_per_page, sort_order, urlQuery } = payload;
  return await drupalAxiosRequest(
    "get",
    urlQuery
      ? `/api/v1/reservations/listing?${urlQuery}`
      : sort_order && sort_order === "recently_read"
      ? `/api/v1/reservations/listing/recently_read`
      : `/api/v1/reservations/listing?sort_order=${
          sort_order || "ASC"
        }&items_per_page=${items_per_page || 10}&page=${
          page || 0
        }&${createListingUrl(payload)}`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const createOrEditReservationApi = async (data) => {
  return drupalAxiosRequest(
    data?.reqMethod,
    `/api/v1/reservation/reservation/${data?.reservationId || ""}`,
    customRequestHeader({ token: Token }),
    data?.payload
  );
};

export const reservationEventsListApi = async () => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/event/reservation`,
    customRequestHeader({ token: Token })
  );
};

export const getReservationDetailApi = async (reservationId) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservation/reservation/${reservationId}?include=select_event,referred_by_contact,field_bed_request,field_dining_time_request,field_dining_time_assigned,field_table_size_request,field_table_status,field_charter_group,field_charter_priority,field_celebration,field_source,field_channel,field_detail,field_last_modified_by,field_travel_agent,field_reasons_not_covered,field_check_list,field_cancellation_confirmation_received_by,user_id,contact_created_by,contact_modify_by`,
    customRequestHeader({ token: Token })
  );
};

export const getReservationInsurancePaymentApi = async (reservationItemId) => {
  return drupalAxiosRequest(
    "get",
   `/api/rest/travelex/payment/details/${reservationItemId}`,
    customRequestHeader({ token: Token })
  );
};

export const getContactListApi = async () => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/contact/contact`,
    customRequestHeader({ token: Token })
  );
};

export const getPromoCodeListApi = async (reservationId) => {
  return drupalAxiosRequest(
    "get",
    `/api/list/promo/discount/${reservationId}`,
    customRequestHeader({ token: Token })
  );
};

export const addOrRemovePromoApi = async (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/reservations/add_remove_discount`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getReservationPaymentsListingApi = async (data) => {
  const reqUrl = data?.travellersId
    ? `/api/rest/reservation/planned/payment/${data.reservationId}/list?traveler_id=${data?.travellersId}`
    : `/api/rest/reservation/planned/payment/${data.reservationId}/list`;
  return drupalAxiosRequest(
    "get",
    reqUrl,
    customRequestHeader({ token: Token })
  );
};
export const addPlannedPaymentApi = (data) => {
  return drupalAxiosRequest(
    data.reqMethod,
    `/api/v1/add_planned_payment/add_planned_payment/${data.paymentId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};
export const getReservationItemListing = async (payload, paramId) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservation/reservation_item/${paramId}?status=${payload}`,
    customRequestHeader({ token: Token })
  );
};

export const getTravelersData = async (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/travelers/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getContactLists = async (payload, search, query) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/contact/res?res_id=${payload}&name=${
      search || ""
    }&${query}&sort_by=name&sort_order=ASC`,
    customRequestHeader({ token: Token })
  );
};

export const updateSelectedContact = async (
  contact,
  travelerUuid,
  reservation
) => {
  const payload = {
    data: {
      res_id: reservation,
      contact_id: contact,
    },
  };
  return drupalAxiosRequest(
    "patch",
    `/api/rest/traveler/update/${travelerUuid}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const addTraveler = async (uuid, type) => {
  const payload = {
    data: {
      res_id: uuid,
      traveler_type: type,
    },
  };
  return drupalAxiosRequest(
    "post",
    `/api/rest/traveler/update`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getAllTravelerDetails = async (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/traveler/traveler?filter[field_reservation.id]=${id}&include=contact,field_relation_traveler`,
    customRequestHeader({ token: Token })
  );
};

export const editTravelerData = async (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/reservation/update_travelers`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getItemEvent = async (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservation/reservation/${id}?include=select_event`,
    customRequestHeader({ token: Token })
  );
};

export const getItemCategories = async (id, guest, query) => {
  const url = query
    ? `/api/rest/reservation/event/${id}/categories?guest=${guest}&${
        query.includes("share=1") ? query : `${query}&share=0`
      }`
    : `/api/rest/reservation/event/${id}/categories?guest=${guest}&share=0`;
  return drupalAxiosRequest("get", url, customRequestHeader({ token: Token }));
};

export const getItemInventory = async (id, guest, query) => {
  const url = query
    ? `/api/rest/reservation_items/category/${id}/inventories?guest=${guest}&${
        query.includes("share=1") ? query : `${query}&share=0`
      }`
    : `/api/rest/reservation_items/category/${id}/inventories?guest=${guest}&share=0`;
  return drupalAxiosRequest("get", url, customRequestHeader({ token: Token }));
};

export const getAddOnList = async (id, guest, type, dep_cat,isReplace) => {
  const query = {
    no_of_travelers: guest,
    addon_type: type,
    view_dep_cat: dep_cat,
  };
  if(isReplace){
    query.replace = 1
  }
  var queryString = objectToQueryString(query);
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservation_item/add_on/${id}?${queryString}`,
    customRequestHeader({ token: Token })
  );
};

export const getPlannedPaymentDetailApi = async (paymentId) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservation/planned_payment/detail/${paymentId}`,
    customRequestHeader({ token: Token })
  );
};

export const getPaymentDetailApi = async (paymentId) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/payment/payment/${paymentId}?include=user_id,field_last_modified_by`,
    customRequestHeader({ token: Token })
  );
};
export const getAddItemsFilter = async (id, guest) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservation/event/${id}/categories?guest=${guest}`,
    customRequestHeader({ token: Token })
  );
};

export const getTermFormDataApi = ({ term, travellerId, reservationId }) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/schedule_payment/traveler/${travellerId}/${reservationId}/${term}`,
    customRequestHeader({ token: Token })
  );
};

export const addReservationItem = async (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/traveler/items/multiple`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};
export const getApplyDiscountListApi = (eventId) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservations/applicable_discounts/${eventId}`,
    customRequestHeader({ token: Token })
  );
};

export const createSchedulePaymentApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/create/scheduled/planned/payments`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};
export const getReservationItemDetailApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservation_item/traveler_item/details/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getApplicationFeeApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservations_item/applicable_fees/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getApplicationDiscountApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservations_item/applicable_discounts/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const postApplicationApi = (payload, urlType, id) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/reservations_item/${urlType}/${id}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const contactSuggestion = (id) => {
  return drupalAxiosRequest(
    "get",
    `/rest/api/traveler/contact/suggestion/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const updateItem = (payload, id) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/reservation_item/traveler_item/details/${id}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getReservationEventListingApi = () => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/event/reservation/search`,
    customRequestHeader({ token: Token })
  );
};

export const getReservationUsersListingApi = () => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservations/listing/user`,
    customRequestHeader({ token: Token })
  );
};

export const getFinancialInfoApi = (resId) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservation/financial_information/${resId}`,
    customRequestHeader({ token: Token })
  );
};

export const getAppliedPlannedPaymentsListApi = (paymentId) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/rest/planned/payment/listing/${paymentId}`,
    customRequestHeader({ token: Token })
  );
};

export const getAppliedPaymentListApi = (paymentId) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/rest/payment/listing/${paymentId}`,
    customRequestHeader({ token: Token })
  );
};

export const addRemoveTraveler = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/reservation_item/add_remove/traveler`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getMakePaymentApi = (options) => {
  const { travellerUuid, paymentAmount, paymentId } = options;
  let url =
    paymentAmount && paymentAmount != 0
      ? `/api/rest/makepayment/formdata/${travellerUuid}?updated_amount=${paymentAmount}${
          paymentId ? `&pp_uuid=${paymentId}` : ""
        }`
      : `/api/rest/makepayment/formdata/${travellerUuid}${
          paymentId ? `?pp_uuid=${paymentId}` : ""
        }`;
  return drupalAxiosRequest("get", url, customRequestHeader({ token: Token }));
};

export const paymentWithStripeApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/payment`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const addItemApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/traveler/items/multiple`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const changeStatusApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/reservation/status_update`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getInvoiceApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/reports/invoice/${id}`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const cancelReservationDetailApi = (resId) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/cancel/reservation_data/${resId}`,
    customRequestHeader({ token: Token })
  );
};

export const getReservationActivityListingApi = (resUuid) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/activity/reservation/${resUuid}`,
    customRequestHeader({ token: Token })
  );
};

export const getRevisePageDetailApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/reservation/replace/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const changeReservationsChildStatusApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/rest/reservation/cancel/status`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const changePaymentStatusApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/payment_status`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const cancelReservationItemDetailApi = ({ resId, itemId }) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/cancel/reservation_data/${resId}?reservation_item=${itemId}`,
    customRequestHeader({ token: Token })
  );
};

export const changeResItemStatusApi = ({ payload }) => {
  return drupalAxiosRequest(
    "post",
    `/api/v1/rest/reservation/cancel/status`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getReservationItemHistoryApi = (itemId) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/activity/reservationitem/${itemId}`,
    customRequestHeader({ token: Token })
  );
};

export const payPlannedPaymentWithStripeApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/planned-payment`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const rollbackReservationItemApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/reservation/reservation_item/rollback`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getTravellerEmailInfoApi = (payload) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/email_template/${payload?.emailType}/${payload?.reservationId}/${
      payload?.travellerId
    }?${payload.paymentId ? `payment_id=${payload.paymentId}` : ""}`,
    customRequestHeader({ token: Token })
  );
};

export const sendEmailToTravellerApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/send/email/template`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const deleteTravellerApi = (traveler_uuid) => {
  return drupalAxiosRequest(
    "delete",
    `/api/v1/traveler/traveler/${traveler_uuid}`,
    customRequestHeader({ token: Token })
  );
};

export const validateTransactionApi = (transaction_id) => {
  return stripeAxiosRequest(
    "get",
    `https://api.stripe.com/v1/charges/${transaction_id}`,
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic c2tfdGVzdF81MU5jbThSU0NEN0I0MnhrY1JueWI1RjdPbFBDcGt1dVo5eE14OTdRQURHWFBpc1hXQ2R3MkV5enFRVHBsQktWTVphckNYemg3QUZPQXRjbmgzRTNQVkRRRjAwQjcyNlZsc2o6`,
    }
  );
};

export const getGroupWithListApi = (search, id) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/reservation-groups?id=${id}${search ? `&search=${search}` : ""}`,
    customRequestHeader({ token: Token })
  );
};

export const getPlannedPaymentTransactionListApi = ({
  reservationUuid,
  travelerUuid,
}) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/stripe/transaction/${reservationUuid}/${travelerUuid}`,
    customRequestHeader({ token: Token })
  );
};

export const refundPlannedPaymentApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/payment/refund`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const transferPlannedPaymentApi = (payload) => {
  return drupalAxiosRequest(
    "post",
    `/api/rest/payment/transfer`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const fetchEmailTypeApi = (contactUuid) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/email_template/guest_communication_template/${contactUuid}`,
    customRequestHeader({ token: Token })
  );
};

export const getAddonReservationListingApi = (payload) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/addon/travelers/list/${payload.addonId}?page=${
      payload.page + 1
    }&items_per_page=${
      payload.items_per_page
    }&only_primary_travelers=1&res_status=${payload.status}`,
    customRequestHeader({ token: Token })
  );
};
