import { EditOutlined, FileOutlined, DeleteOutlined, SearchOutlined, ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { Dropdown, Menu, Space } from 'antd';

import React, { useState } from 'react';

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="#">File</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: 'Delete',
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: 'Find Duplicates',
        key: '3',
      },
    ]}
  />
);

function EditPaymentHeader() {
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  return (

    <div className="breadcrumb_content">
      <div className='breadcrumb_heading view_header' >
        <h3><ArrowLeftOutlined className="back-icon-profile" />   <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Edit Payment Details 07/26/2023  </span>   <span className='span_serial' style={{ fontSize: '14px', lineHeight: '22px', fontWeight: '400', fontWeight: '500', fontFamily: "'Poppins', sans-serif", color: '#00000073', opacity: '0.6' }} > IMC Gospel Music Celebration 2023 Hawaii Cruise - R980105</span>
        </h3>
      </div>
    </div>

  );
}

export default EditPaymentHeader;
