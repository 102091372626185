import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../../url-routes/urlRoutes";

function Reservationitem_header({ nameAndId, isReplaceItem, reservationId }) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(`${ReservationDetailRoute}?id=${reservationId}`);
            }}
          />
          <div className="header_res">
            <p>{isReplaceItem ? `Replace Item` : `Add Items`} </p>
            <Link
              className="header_smalltext"
              to={`${ReservationDetailRoute}?id=${nameAndId?.id}`}
            >
              {nameAndId?.name}
              {nameAndId?.drupal_internal__id
                ? `- ${nameAndId?.drupal_internal__id}`
                : null}
            </Link>
          </div>
        </h3>
        {/*
             <div className="breadcrum_buttons" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'end' }}>
                    <Button type="primary">Save</Button>
                    <Button>Cancel</Button>
            </div>
        */}
      </div>
    </div>
  );
}

export default Reservationitem_header;
