import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Col, Row, Collapse, TimePicker, Descriptions } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
} from "antd";
import moment from "moment";

function onChange(time, timeString) {
    console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function Description_form() {
    const [value, setValue] = useState("");

    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);

    useEffect(() => {
        form.validateFields(["nickname"]);
    }, [checkNick, form]);

    const onCheckboxChange = (e) => {
        setCheckNick(e.target.checked);
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log("Success:", values);
        } catch (errorInfo) {
            console.log("Failed:", errorInfo);
        }
    };

    const { Panel } = Collapse;

    const onChange = (key) => {
        console.log(key);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const fileList = [];
    return (
        <div className="addnote_form">
            <div>
                <Form
                    {...layout}
                    labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 3,
                        xxl: 4,
                    }}
                    wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                    }}
                    layout="horizontal"
                >




                    <Form.Item label="Description Type" name="email_type">
                        <Select>
                            <Select.Option value="">Description</Select.Option>
                            <Select.Option value="1">WebDescription</Select.Option>
                            <Select.Option value="2">WebMoreInfo</Select.Option>
                            <Select.Option value="3">WebResInfo</Select.Option>
                            <Select.Option value="4">WebTerms</Select.Option>
                            <Select.Option value="5">WebTerms2</Select.Option>
                            <Select.Option value="6">WebTerms-Share</Select.Option>
                            <Select.Option value="7">WebTripInfo</Select.Option>
                            <Select.Option value="8">WebSite</Select.Option>
                            <Select.Option value="9">Confirmation-Instructions</Select.Option>
                            <Select.Option value="10">Confirmation-Footer</Select.Option>
                            <Select.Option value="11">Cancellation-Instructions</Select.Option>
                            <Select.Option value="12">Cancellation-Footer</Select.Option>
                        </Select>
                    </Form.Item>


                    <div className="textarea_row">
                        <Form.Item
                            label="Info Text"
                            name="email_body"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" value={value} onChange={setValue} />
                        </Form.Item>
                    </div>

                </Form>
            </div>
        </div>
    );
}

export default Description_form;
