import React from 'react';
import "./Res_guestviewsection.less";

import Guest_details from './Guest_details';
import Guest_Profile from './Guest_Profile';
import Res_operations from './Res_operations';
import Res_Itineraries from './Res_Itineraries';
import Res_operationsnotes from './Res_operationsnotes';
import Res_Marketing from './Res_Marketing';



const Res_guestviewsection = () => (
    <div className='details-tabs' style={{ margin: 0 }}>

        <div className='Res_guest_main'>
            <div className='basic_details  pd-16 bg-white'>
                <Guest_details />
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Guest_Profile />
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Res_operations/>
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Res_Itineraries/>
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Res_operationsnotes/>
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Res_Marketing/>
            </div>
        </div>
    </div>
);

export default Res_guestviewsection;