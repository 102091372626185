import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message, Space } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
// import Horizontals from '../../components/Header/Horizontal_menu';
import SearchElite_menu from "../../components/Header/SearchElite_menu";
import Breadcrumb_elitetravelerdetails from "../../components/Breadcrumb/Breadcrumb_elitetravelerdetails/Breadcrumb_elitetravelerdetails";
import Report_elitedetailstable from "../../components/View_components/Report_elitedetailstable/Report_elitedetailstable";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { drupalAxiosRequest } from "../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../utils/cutomRequestHeader";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
const { Header, Content, Sider } = Layout;

function checkEliteStatusIcon(status) {
  switch (status) {
    case "ELITE":
      return "./elite.png";
    case "SILVER":
      return "./silver.png";
    case "SAPPHIRE":
      return "./Sapphire.png";
    case "PLATINUM":
      return "./Platinum.png";
    case "DIAMOND":
      return "./Diamond.png";
    default:
      console.log("no icons");
  }
}

function Report_elitetravelerdetails() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const [listTotalCount, setListTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const organizationType = searchParams.get("org_type");
  const organizationName = searchParams.get("name");
  const search = searchParams.get("search");

  const [searchText, setSearchText] = useState("");
  const [eliteTraveles, setEliteTraveles] = useState([]);
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page: currentPage,
    search,
    name: organizationName,
    org_type: organizationType,
  });
  const ID = searchParams.get("id");
  const [upgradedTravelers, setUpgradedTravelers] = useState(false);
  const [eventName, setEventName] = useState(null);

  async function getCompletedEvents(options) {
    const payload = {
      items_per_page: Number(urlParamsObject?.count),
      page: Number(urlParamsObject?.page) - 1,
      sort_by: "name",
      sort_order: urlParamsObject?.order,
      only_upgraded: options?.upgradedTravelers ? 1 : 0,
      name: options?.search ?? "",
    };
    const queryParam = `?items_per_page=${payload.items_per_page}&page=${payload.page}&only_upgraded=${payload.only_upgraded}&name=${payload.name}`;
    try {
      const data = await drupalAxiosRequest(
        "get",
        `/api/v1/report/elite_travelers/${ID}${queryParam}`,
        customRequestHeader({ defaultFormat: true })
      );
      setListTotalCount(Number(data.data.data.listing.pager.total_count));
      const { id, name } = data.data.data.event_details;
      setEventName({ id, name });
      const customData = data.data.data.listing.rows.map((val, index) => {
        return {
          key: index,
          hash: val.id,
          name: val.name,
          reservationid: val.res_id,
          totaldays: val.days_travelled,
          contactId: val.uuid,
          Currentstatus: (
            <>
              <Space>
                <img
                  src={checkEliteStatusIcon(val.elite_status)}
                  style={{ width: "24px", objectFit: "cover" }}
                ></img>
                <span className="user-name"> {val.elite_status}</span>
              </Space>
            </>
          ),
        };
      });
      setEliteTraveles(customData);
    } catch (err) {
      message.error(err.message);
      setEliteTraveles([]);
    }
  }
  useEffect(() => {
    getCompletedEvents({ search: searchText, upgradedTravelers });
  }, [urlParamsObject]);

  const breadCrumbs = [
    { title: "Elite Travelers", path: "/elite-travelers" },
    { title: "Details", path: `/Report_elitetravelerdetails?id=${ID}` },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <SearchElite_menu showSearchBar={false} />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <Breadcrumb_elitetravelerdetails eventName={eventName} />
          </div>
          <Content>
            <Report_elitedetailstable
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              setSearchText={setSearchText}
              listTotalCount={listTotalCount}
              eliteTraveles={eliteTraveles}
              setUpgradedTravelers={setUpgradedTravelers}
              getCompletedEvents={getCompletedEvents}
              upgradedTravelers={upgradedTravelers}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default Report_elitetravelerdetails;
