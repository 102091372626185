import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Row, Grid, Col } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
// import Horizontals from '../../components/Header/Horizontal_menu';
import Searchevents_menu from "../../components/Events/EventsSearchbar";
import Eventactive_table from "../../components/Events/EventDetails/Reservations/EventReservationListing";
import Addonsdescription_breadcrumb from "../../components/Breadcrumb/Breadcrumb_Organizationlist/Events/Addonsdescription_breadcrumb";
import Description_form from "../../components/Forms/Description_form/Description_form";
import React from "react";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Eventsdescription_page() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? ""  :"mobileview" ;
  return (
    <Layout className={mobileClass}>
      <Layout>

        <Layout>
          <Header className="contact_edit main-header">
            <Searchevents_menu />
          </Header>
          <Breadcrumb className="">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Events</Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
            <Breadcrumb.Item>Descriptions</Breadcrumb.Item>
            <span>
              <Breadcrumb.Item>Add Description</Breadcrumb.Item>
            </span>

            <Addonsdescription_breadcrumb />
          </Breadcrumb>
          <Content>
            <Description_form />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default Eventsdescription_page;
