import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Select, Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getSingleEventApi } from "../../../../../../../apis/eventsApi";
import { statusList } from "../../../../../../../others/util/commonFunctions";
import { EventCategoryDetailsRoute, EventDetailsRoute } from "../../../../../../../url-routes/urlRoutes";
const { Option } = Select;

function InventoryReservationsHeader({
  setUrlParamsObject,
  urlParamsObject,
  eventId,
  categoryId,
}) {
  const [eventData, setEventData] = useState({});
  const navigate = useNavigate();
  const [loadingEventData, setLoadingEventData] = useState(false);
  useEffect(() => {
    getEventData(eventId);
  }, []);

  const getEventData = async (id) => {
    setLoadingEventData(true);
    const res = await getSingleEventApi(id);
    if (res.status) {
      setEventData(res?.data?.data);
    } else {
      setEventData({});
    }
    setLoadingEventData(false);
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(
                `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`
              );
            }}
          />
          {loadingEventData ? (
            <Skeleton.Input active={true} style={{ width: "500px" }} />
          ) : (
            <div className="header_res">
              View Reservation{" "}
              <Link
                className="header_smalltext"
                to={`${EventDetailsRoute}?id=${eventData?.id}`}
              >
                {eventData?.name} ({eventData?.field_event_code})
              </Link>
            </div>
          )}
        </h3>
      </div>
    </div>
  );
}

export default InventoryReservationsHeader;
