import { Grid, Layout } from "antd";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Discount_breadcrumb from "../../../../../components/Breadcrumb/Events/Category_breadcrumb/Discount_breadcrumb";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import Discount_edit from "../../../../../components/Events_components/Category/Discounts/Discount_edit";
import { EventCategoryDetailsRoute, EventDetailsRoute, EventsListingRoute } from "../../../../../url-routes/urlRoutes";
const { Header, Content, Sider } = Layout;

const EventCategoryDiscount = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Details", path: EventDetailsRoute },
    { title: "Category Details", path: EventCategoryDetailsRoute },
    { title: "Category Discounts", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <Discount_breadcrumb />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Discount_edit />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EventCategoryDiscount;
