import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { createOrEditReservationApi } from '../../../../apis/reservationApi';
import { SOMETHING_WENT_WRONG } from '../../../../utils/constants';
import { drupalAxiosRequest } from '../../../../utils/drupalAxiosRequest';
import { customRequestHeader } from '../../../../utils/cutomRequestHeader';


const GroupListingsTable = ({groupName,eventId}) => {
  const [groupListData,setGroupListData] = useState([])
  const  [loading,setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    fetchGroupWithInfo()
  }, []);

  const fetchGroupWithInfo = async ()=>{
    try{
      setLoading(true)
      const response = await drupalAxiosRequest("get",`/api/v1/group-with-listing/?group=${groupName}&event_id=${eventId}`,customRequestHeader({defaultFormat:true}));
      if(response?.status){
       const data =  response?.data?.data?.map(val=>{
          const {c_uuid,r_uuid,field_middle_name,field_last_name,field_first_name,c_id,r_id} = val
          if(!c_id) return
          return {
            contact_uuid:c_uuid,
            reservation_uuid:r_uuid,
            contact_id:c_id,
            reservation_id:r_id,
            name: `${field_first_name ?? ""} ${field_middle_name ?? "" } ${field_last_name ?? ""}`
          }
        }).filter(Boolean)
        setGroupListData(data || [])
      }else{
        message.error(SOMETHING_WENT_WRONG)
      }
    }catch(err){
      console.log("🚀 ~ fetchGroupWithInfo ~ err:", err)
      message.error(SOMETHING_WENT_WRONG)
    }
    setLoading(false)
  }

  const removeHandler =  async (data) => {
    setLoading(true)
     const payload = {
        data: {
          type: "reservation--reservation",
          id: data?.reservation_uuid,
          attributes: {
            group_with: "",
          },
        },
      };
      const res = await createOrEditReservationApi({ reqMethod: "patch", payload, reservationId: data?.reservation_uuid });
      if (res.status) {
        message.success("Traveler Removed Successfully")
        fetchGroupWithInfo()
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
      setLoading(false)
  }

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Contact ID',
    dataIndex: 'contact_id',
    render:(id,record) => {
      return (
        <Link to={`/contact/details?id=${record?.contact_uuid}`}>
        {id}
        </Link>
      )
    }
  },
  {
    title: 'Reservation ID',
    dataIndex: 'reservation_id',
    render:(id,record)=>{
      return (
        <Link to={`/reservation/details?id=${record?.reservation_uuid}`}>
        {id}
        </Link>
      )
    }
  },
  {
    title: "Actions",
    render: (_,record) => {
      return (
        <>
          <div className="action_flex action_end">
            <DeleteOutlined
              className="action_delete"
              onClick={()=>{removeHandler(record)}}
            />
          </div>
        </>
      );
    },
  },
];

  return (
  <>
    <Table columns={columns} dataSource={groupListData} loading={loading} pagination={false} size="small" />
  </>
);
}
export default GroupListingsTable;