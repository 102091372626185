import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "./constants";

export const convertDateTimeToISO = (date, time) => {
  // date & time should be moment object
  const dateString = date?.format(DATE_FORMAT);
  const timeString = time?.format(TIME_FORMAT);
  const dateTimeString = `${dateString} ${timeString}`;
  const parsedDateTime = moment(
    dateTimeString,
    `${DATE_FORMAT} ${TIME_FORMAT}`
  );
  const formattedDateTime = parsedDateTime.format("YYYY-MM-DDTHH:mm:ssZ");
  return formattedDateTime;
};
