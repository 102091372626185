import { Table, Divider } from "antd";
import React from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },

  {
    title: "Input",
    dataIndex: "input",
  },
];

function CruiseMisc({ allDetails, allDetailsLoading }) {
  const {
    field_non_married_roommate_policy,
    field_optional_post_departure_ins_included,
    field_event_partner_flight_allowance,
  } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Non-Married Roommate Policy",
      input: <>{field_non_married_roommate_policy?.name || ""}</>,
    },
    {
      key: "2",
      label: "Optional Post Departure Ins Included",
      input: (
        <>
          {field_optional_post_departure_ins_included === true
            ? "Yes"
            : field_optional_post_departure_ins_included === false
              ? "No"
              : ""}
        </>
      ),
    },
    {
      key: "3",
      label: "Event Partner Flight Allowance",
      input: <>{field_event_partner_flight_allowance || ""}</>,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 classname="mb-0">Miscellaneous</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default CruiseMisc;
