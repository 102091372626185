import { Card, Col, Grid, Layout, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getDashboardBarDataApi,
  getProdEventsListApi,
  getTotalDashboardCountApi,
} from "../../apis/dashboardApi";
import DashboardBarGraphContainer from "../../components/Dashboard/DashboardBarGraphContainer";
import DashboardPieChartContainer from "../../components/Dashboard/DashboardPieChartContainer";
import DashboardTotal from "../../components/Dashboard/DashboardTotal";
import SearchDashboard_menu from "../../components/Header/SearchDashboard_menu";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import "./Home.less";
const { Header, Content, Sider } = Layout;

function Dashboard() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const [searchParams, setSearchParams] = useSearchParams();
  const barDurationValue = searchParams.get("bar_duration");
  const currentBarTab = searchParams.get("bar_tab");
  const demoEvent = searchParams.get("demographic_event");
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [isToday, setIsToday] = useState(
    [null, "false", "", undefined]?.includes?.(searchParams?.get("is_today"))
      ? false
      : true
  );
  const [dashboardTotalCount, setDashboardTotalCount] = useState({});
  const [loadingDashboard, setLoadingDashboard] = useState(false);

  const [dashboardBarData, setDashboardBarData] = useState({});
  const [barDuration, setBarDuration] = useState(barDurationValue || "7_days");
  const [activeBarTab, setActiveBarTab] = useState(currentBarTab || "new");

  useEffect(() => {
    getDashboardTotal(isToday);
  }, [isToday]);

  useEffect(() => {
    getDashboardBarData({ status: activeBarTab, time: barDuration });
  }, [activeBarTab, barDuration]);

  const getDashboardBarData = async (data) => {
    setLoadingDashboard(true);
    const response = await getDashboardBarDataApi(data);
    if (response.status) {
      setDashboardBarData(response?.data?.data);
      setLoadingDashboard(false);
    } else {
      setDashboardBarData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingDashboard(false);
    }
  };
  const getDashboardTotal = async (today) => {
    setLoadingDashboard(true);
    const response = await getTotalDashboardCountApi(today);
    if (response.status) {
      setDashboardTotalCount(response?.data?.data);
      setLoadingDashboard(false);
    } else {
      setDashboardTotalCount({});
      setLoadingDashboard(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <SearchDashboard_menu />
          </Header>
          <Row
            className="dashboard-header"
            gutter={16}
            style={{ marginBottom: "20px" }}
          >
            <Col span={24}>
              <Card>
                <h3>Dashboard</h3>
              </Card>
            </Col>
          </Row>
          <Spin spinning={loadingDashboard} size="large">
            <Content className="site-layout-background transparent-bg dashboard-section">
              <DashboardTotal
                setIsToday={setIsToday}
                isToday={isToday}
                dashboardTotalCount={dashboardTotalCount}
              />

              <DashboardBarGraphContainer
                setActiveBarTab={setActiveBarTab}
                activeBarTab={activeBarTab}
                barDuration={barDuration}
                setBarDuration={setBarDuration}
                dashboardBarData={dashboardBarData}
              />

              <DashboardPieChartContainer
                setLoadingDashboard={setLoadingDashboard}
              />
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
