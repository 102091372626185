import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SystemInformation from "../../../others/CommonSystemInformation/SystemInformation";
import DataLoader from "../../../others/Loaders/DataLoader";
import AddonsListing from "../EventDetailsTabs/AddonsTab/AddonsListing/AddonsListing";
import AssetTabContainer from "../EventDetailsTabs/AssetsTab/AssetTabContainer";
import EventCategoriesListing from "../EventDetailsTabs/CategoriesTab/CategoriesListing/EventCategoriesListing";
import EventCategoriesListingTotal from "../EventDetailsTabs/CategoriesTab/CategoriesListing/EventscategoryListingTotal";
import DiscountsListing from "../EventDetailsTabs/DiscountTab/DiscountsListing";
import AddonFeesListing from "../EventDetailsTabs/FeesTab/AddonFeesListing";
import AccountingInformation from "../EventDetailsTabs/GeneralDetailsTab/AccountingInformation";
import EventsReservations from "../EventDetailsTabs/GeneralDetailsTab/EventsReservations";
import GeneralInformations from "../EventDetailsTabs/GeneralDetailsTab/GeneralInformations";
import MarketingSources from "../EventDetailsTabs/GeneralDetailsTab/MarketingSources";
import PaymentSetup from "../EventDetailsTabs/GeneralDetailsTab/PaymentSetup";
import SystemOptionsSettings from "../EventDetailsTabs/GeneralDetailsTab/SystemOptionsSettings";
import ItineraryListing from "../EventDetailsTabs/ItinerariesTab/ItineraryListing/ItineraryListing";
import DescriptionsListing from "../EventDetailsTabs/DescriptionsTab/DescriptionsListing/DescriptionsListing";
import { permissionChecker } from "../../../utils/permisssionChecker";
import DiningOptions from "../EventDetailsTabs/GeneralDetailsTab/DiningOptions";

const EventDetailsTabsContainer = ({ eventData, loading, eventId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(null);
  const [accommodationTotalBar, setAccommodationTotalBar] = useState({});

  useEffect(() => {
    let tab = location.hash
      ? decodeURI(location.hash.slice(1))
      : "Event Details";

    setCurrentTab(tab);
  }, [location.hash]);

  const handleTabChange = (key) => {
    setCurrentTab(key);
    navigate(`${location.pathname}${location.search}#${key}`);
  };

  if (!currentTab) {
    return <DataLoader />;
  }
  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key) => {
        handleTabChange(key);
      }}
    >
   {permissionChecker("view events") ?    <Tabs.TabPane tab="Event Detail" key="Event Details">
        <Row className="general-reservation_row" gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="basic_details pd-16 bg-white h-100">
              <GeneralInformations
                eventData={eventData}
                eventId={eventId}
                loading={loading}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="basic_details pd-16 bg-white h-100">
              <EventsReservations
                eventData={eventData}
                eventId={eventId}
                loading={loading}
              />
            </div>
          </Col>
        </Row>

        <div
          className="tabs_style partners_informations pd-16 bg-white"
          style={{ marginBottom: "20px" }}
        >
          <SystemOptionsSettings
            eventData={eventData}
            eventId={eventId}
            loading={loading}
          />
        </div>

        <div
          className="tabs_style partners_informations pd-16 bg-white"
          style={{ marginBottom: "20px" }}
        >
          <PaymentSetup
            eventData={eventData}
            eventId={eventId}
            loading={loading}
          />
        </div>

        <div
          className="tabs_style partners_informations pd-16 bg-white"
          style={{ marginBottom: "20px" }}
        >
          <AccountingInformation
            eventData={eventData}
            eventId={eventId}
            loading={loading}
          />
        </div>

        <div
          className="tabs_style partners_informations pd-16 bg-white"
          style={{ marginBottom: "20px" }}
        >
         {permissionChecker("view events") ?  <MarketingSources
            eventData={eventData}
            eventId={eventId}
            loading={loading}
          /> : null}
        </div>
        <div
          className="tabs_style partners_informations pd-16 bg-white"
          style={{ marginBottom: "20px" }}
        >
          <DiningOptions
            eventData={eventData}
            eventId={eventId}
            loading={loading}
          />
        </div>
      </Tabs.TabPane> : null}
   {permissionChecker("view published accommodation type entities")  ?   <Tabs.TabPane tab="Accommodations" key="Accommodations">
        <div className="activity_details pd-16 bg-white accommodation_scroll_table">
          <EventCategoriesListing
            eventData={eventData}
            eventId={eventId}
            setAccommodationTotalBar={setAccommodationTotalBar}
          />
        </div>
      </Tabs.TabPane> : null}
      {permissionChecker("view published fees entities")  ?<Tabs.TabPane tab="Fees" key="Fees">
        <div className="activity_details pd-16 bg-white">
          <AddonFeesListing feeType={"event"} eventId={eventId} />
        </div>
      </Tabs.TabPane> :null}
      {permissionChecker("view published discounts entities")  ?<Tabs.TabPane tab="Discounts" key="Discounts">
        <div className="product_history pd-16 bg-white">
          <DiscountsListing discountType={"event"} eventId={eventId} />
        </div>
      </Tabs.TabPane> : null}
    {permissionChecker("view addon")  ?  <Tabs.TabPane tab="Add-Ons" key="Add-Ons">
        <div className="product_details container_spacing pd-16 bg-white">
          <AddonsListing eventData={eventData} eventId={eventId} />
        </div>
      </Tabs.TabPane> : null}
      <Tabs.TabPane tab="Descriptions" key="Descriptions">
        <div className="product_details container_spacing pd-16 bg-white">
          <DescriptionsListing eventId={eventData?.drupal_internal__id} />
        </div>
      </Tabs.TabPane>
     {permissionChecker("view event assets") ?  <Tabs.TabPane tab="Assets" key="Assets">
        <div className="settings_details pd-16 bg-white">
          <AssetTabContainer
            eventData={eventData}
            eventId={eventId}
            assetType={"event"}
          />
        </div>
      </Tabs.TabPane> : null}
      Itineraries
      <Tabs.TabPane tab="Itineraries" key="Itineraries">
        <div className="settings_details pd-16 bg-white">
          <ItineraryListing eventId={eventId} itineraryType={"event"} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="System Information" key="System Information">
        <div className="settings_details pd-16 bg-white">
          <SystemInformation
            user_id={eventData?.user_id || {}}
            modified_by={eventData?.modified_by || {}}
            created={eventData?.created}
            changed={eventData?.changed}
            loading={loading}
          />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default EventDetailsTabsContainer;
