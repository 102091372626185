import React, { useEffect, useState } from "react";
import "./Res_editguestformsection.less";

import { Empty, Form, Input, message } from "antd";
import * as moment from "moment";
import TravelerForm from "./TravelerForm";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import { editTravelerData } from "../../../../../../apis/reservationApi";
import { useSearchParams } from "react-router-dom";
import { ReservationDetailRoute } from "../../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import { setSectionHighlighter } from "../../../../../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;
const Res_editguestformsection = (props) => {
  const dispatch = useDispatch();
  const { allTravelersData, form, setLoading, reservationStatus } = props;
  const [copiedIndex, setCopiedIndex] = useState();
  const [relations, setRelations] = useState();
  const [relationLoading, setRelationLoading] = useState();
  const [arrivalAirline, setArrivalAirline] = useState();
  const [arrivalAirlineLoading, setArrivalAirlineLoading] = useState();
  const [arrivalTransfer, setArrivalTransfer] = useState();
  const [arrivalTransferLoading, setArrivalTransferLoading] = useState();
  const [departureAirline, setDepartureAirline] = useState();
  const [departureAirlineLoading, setDepartureAirlineLoading] = useState();
  const [departureTransfer, setDepartureTransfer] = useState();
  const [departureTransferLoading, setDepartureTransferLoading] = useState();
  const [preArrivalAirline, setPreArrivalAirline] = useState();
  const [preArrivalAirlineLoading, setPreArrivalAirlineLoading] = useState();
  const [extArrivalTransfer, setExtArrivalTransfer] = useState();
  const [extArrivalTransferLoading, setExtArrivalTransferLoading] = useState();
  const [extDepartureAirline, setExtDepartureAirline] = useState();
  const [extDepartureAirlineLoading, setExtDepartureAirlineLoading] =
    useState();
  const [extDepartureTransfer, setExtDepartureTransfer] = useState();
  const [extDepartureTransferLoading, setExtDepartureTransferLoading] =
    useState();
  const [postExtArrivalAirline, setPostExtArrivalAirline] = useState();
  const [postExtArrivalAirlineLoading, setPostExtArrivalAirlineLoading] =
    useState();
  const [postExtArrivalTransfer, setPostExtArrivalTransfer] = useState();
  const [postExtArrivalTransferLoading, setPostExtArrivalTransferLoading] =
    useState();
  const [postExtDepartureAirline, setPostExtDepartureAirline] = useState();
  const [postExtDepartureAirlineLoading, setPostExtDepartureAirlineLoading] =
    useState();
  const [postExtDepartureTransfer, setPostExtDepartureTransfer] = useState();
  const [postExtDepartureTransferLoading, setPostExtDepartureTransferLoading] =
    useState();

  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get("id");

  useEffect(() => {
    getTaxonomyList(
      "relation_to_other_traveler",
      setRelations,
      setRelationLoading
    );
    getTaxonomyList(
      "arrival_airline",
      setArrivalAirline,
      setArrivalAirlineLoading
    );
    getTaxonomyList(
      "arrival_transfer",
      setArrivalTransfer,
      setArrivalTransferLoading
    );
    getTaxonomyList(
      "departure_airline",
      setDepartureAirline,
      setDepartureAirlineLoading
    );
    getTaxonomyList(
      "departure_transfer",
      setDepartureTransfer,
      setDepartureTransferLoading
    );
    getTaxonomyList(
      "pre_ext_arrival_airline",
      setPreArrivalAirline,
      setPreArrivalAirlineLoading
    );
    getTaxonomyList(
      "ext_arrival_transfer",
      setExtArrivalTransfer,
      setExtArrivalTransferLoading
    );
    getTaxonomyList(
      "pre_ext_departure_airline",
      setExtDepartureAirline,
      setExtDepartureAirlineLoading
    );
    getTaxonomyList(
      "ext_departure_transfer",
      setExtDepartureTransfer,
      setExtDepartureTransferLoading
    );
    getTaxonomyList(
      "post_ext_arrival_airline",
      setPostExtArrivalAirline,
      setPostExtArrivalAirlineLoading
    );
    getTaxonomyList(
      "ext_arrival_transfer",
      setPostExtArrivalTransfer,
      setPostExtArrivalTransferLoading
    );
    getTaxonomyList(
      "post_ext_departure_airline",
      setPostExtDepartureAirline,
      setPostExtDepartureAirlineLoading
    );
    getTaxonomyList(
      "post_ext_departure_transfer",
      setPostExtDepartureTransfer,
      setPostExtDepartureTransferLoading
    );
  }, []);

  useEffect(() => {
    getTaxonomyList(
      "relation_to_other_traveler",
      setRelations,
      setRelationLoading
    );
    getTaxonomyList(
      "arrival_airline",
      setArrivalAirline,
      setArrivalAirlineLoading
    );
    getTaxonomyList(
      "arrival_transfer",
      setArrivalTransfer,
      setArrivalTransferLoading
    );
    getTaxonomyList(
      "departure_airline",
      setDepartureAirline,
      setDepartureAirlineLoading
    );
    getTaxonomyList(
      "departure_transfer",
      setDepartureTransfer,
      setDepartureTransferLoading
    );
    getTaxonomyList(
      "pre_ext_arrival_airline",
      setPreArrivalAirline,
      setPreArrivalAirlineLoading
    );
    getTaxonomyList(
      "ext_arrival_transfer",
      setExtArrivalTransfer,
      setExtArrivalTransferLoading
    );
    getTaxonomyList(
      "pre_ext_departure_airline",
      setExtDepartureAirline,
      setExtDepartureAirlineLoading
    );
    getTaxonomyList(
      "ext_departure_transfer",
      setExtDepartureTransfer,
      setExtDepartureTransferLoading
    );
    getTaxonomyList(
      "post_ext_arrival_airline",
      setPostExtArrivalAirline,
      setPostExtArrivalAirlineLoading
    );
    getTaxonomyList(
      "ext_arrival_transfer",
      setPostExtArrivalTransfer,
      setPostExtArrivalTransferLoading
    );
    getTaxonomyList(
      "post_ext_departure_airline",
      setPostExtDepartureAirline,
      setPostExtDepartureAirlineLoading
    );
    getTaxonomyList(
      "post_ext_departure_transfer",
      setPostExtDepartureTransfer,
      setPostExtDepartureTransferLoading
    );
  }, []);

  useEffect(() => {
    if (allTravelersData) {
      let travelerData = [];
      allTravelersData.forEach((val, index) => {
        travelerData.push({
          [`field_gender-${index}`]: val?.contact?.field_gender?.label,
          [`field_role-${index}`]: val?.field_role,
          [`field_staff_price-${index}`]: val?.field_staff_price,
          [`field_reporting_roles-${index}`]: val?.field_reporting_roles,
          [`flight_arrival-${index}`]: val?.arrdepart?.flight_arrival,
          [`arrival_airline-${index}`]: val?.arrdepart?.arrival_airline,
          [`arrival_date-${index}`]: val.arrdepart.arrival_date
            ? moment(val?.arrdepart?.arrival_date)
            : null,
          [`arrival_time-${index}`]: val.arrdepart.arrival_time
            ? moment(val?.arrdepart?.arrival_time, "HH:mm:ss")
            : null,
          [`arrival_last_depart_city-${index}`]:
            val?.arrdepart?.arrival_last_depart_city,
          [`arrival_last_depart_date-${index}`]: val.arrdepart
            .arrival_last_depart_date
            ? moment(val?.arrdepart?.arrival_last_depart_date)
            : null,
          [`arrival_last_depart_time-${index}`]: val.arrdepart
            .arrival_last_depart_time
            ? moment(val?.arrdepart?.arrival_last_depart_time, "HH:mm:ss")
            : null,
          [`arrival_transfer_date-${index}`]: val.arrdepart
            .arrival_transfer_date
            ? moment(val?.arrdepart?.arrival_transfer_date)
            : null,
          [`arrival_transfer_time-${index}`]: val.arrdepart
            .arrival_transfer_time
            ? moment(val?.arrdepart?.arrival_transfer_time, "HH:mm:ss")
            : null,
          [`arrival_record_locator-${index}`]:
            val?.arrdepart?.arrival_record_locator,
          [`arrival_transfer-${index}`]: val?.arrdepart?.arrival_transfer,
          [`air_notes-${index}`]: val?.arrdepart?.air_notes,
          [`flight_departure-${index}`]: val?.arrdepart?.flight_departure,
          [`departure_airline-${index}`]: val?.arrdepart?.departure_airline,
          [`departure_date-${index}`]: val.arrdepart.departure_date
            ? moment(val?.arrdepart?.departure_date)
            : null,
          [`arrival_city-${index}`]: val?.arrdepart?.arrival_city,
          [`departure_transfer_date-${index}`]: val.arrdepart
            .departure_transfer_date
            ? moment(val?.arrdepart?.departure_transfer_date)
            : null,
          [`departure_record_locator-${index}`]:
            val?.arrdepart?.departure_record_locator,
          [`departure_transfer-${index}`]: val?.arrdepart?.departure_transfer,
          [`departure_transfer_time-${index}`]: val.arrdepart
            .departure_transfer_time
            ? moment(val?.arrdepart?.departure_transfer_time, "HH:mm:ss")
            : null,
          [`departure_time-${index}`]: val.arrdepart.departure_time
            ? moment(val?.arrdepart?.departure_time, "HH:mm:ss")
            : null,
          [`pre_extarrival_flight-${index}`]:
            val?.pre_extarridepart?.pre_extarrival_flight,
          [`pre_extarrival_airline-${index}`]:
            val?.pre_extarridepart?.pre_extarrival_airline,
          [`pre_extarrival_date-${index}`]: val.pre_extarridepart
            .pre_extarrival_date
            ? moment(val?.pre_extarridepart?.pre_extarrival_date)
            : null,
          [`pre_extarrival_time-${index}`]: val.pre_extarridepart
            .pre_extarrival_time
            ? moment(val?.pre_extarridepart?.pre_extarrival_time, "HH:mm:ss")
            : null,
          [`pre_extarrival_last_depart_city-${index}`]:
            val?.pre_extarridepart?.pre_extarrival_last_depart_city,
          [`pre_extarrival_last_depart_date-${index}`]: val.pre_extarridepart
            .pre_extarrival_last_depart_date
            ? moment(val?.pre_extarridepart?.pre_extarrival_last_depart_date)
            : null,
          [`pre_extarrival_last_depart_time-${index}`]: val.pre_extarridepart
            .pre_extarrival_last_depart_time
            ? moment(
                val?.pre_extarridepart?.pre_extarrival_last_depart_time,
                "HH:mm:ss"
              )
            : null,
          [`pre_extarrival_record_locator-${index}`]:
            val?.pre_extarridepart?.pre_extarrival_record_locator,
          [`extarrival_transfer-${index}`]:
            val?.pre_extarridepart?.extarrival_transfer,
          [`pre_extdeparture_flight-${index}`]:
            val?.pre_extarridepart?.pre_extdeparture_flight,
          [`pre_extdeparture_airline-${index}`]:
            val?.pre_extarridepart?.pre_extdeparture_airline,
          [`pre_extdeparture_date-${index}`]: val.pre_extarridepart
            .pre_extdeparture_date
            ? moment(val?.pre_extarridepart?.pre_extdeparture_date)
            : null,
          [`pre_extdeparture_time-${index}`]: val.pre_extarridepart
            .pre_extdeparture_time
            ? moment(val?.pre_extarridepart?.pre_extdeparture_time, "HH:mm:ss")
            : null,
          [`pre_extarrival_city-${index}`]:
            val?.pre_extarridepart?.pre_extarrival_city,
          [`pre_extdeparture_record_locator-${index}`]:
            val?.pre_extarridepart?.pre_extdeparture_record_locator,
          [`extdeparture_transfer-${index}`]:
            val?.pre_extarridepart?.extdeparture_transfer,
          [`post_extarrival_flight-${index}`]:
            val?.post_extarridepart?.post_extarrival_flight,
          [`post_extarrival_airline-${index}`]:
            val?.post_extarridepart?.post_extarrival_airline,
          [`post_extarrival_date-${index}`]: val.post_extarridepart
            .post_extarrival_date
            ? moment(val?.post_extarridepart?.post_extarrival_date)
            : null,
          [`post_extarrival_time-${index}`]: val.post_extarridepart
            .post_extarrival_time
            ? moment(val?.post_extarridepart?.post_extarrival_time, "HH:mm:ss")
            : null,
          [`post_extarrival_last_depart_city-${index}`]:
            val?.post_extarridepart?.post_extarrival_last_depart_city,
          [`post_extarrival_last_depart_date-${index}`]: val.post_extarridepart
            .post_extarrival_last_depart_date
            ? moment(val?.post_extarridepart?.post_extarrival_last_depart_date)
            : null,
          [`post_extarrival_last_depart_time-${index}`]: val.post_extarridepart
            .post_extarrival_last_depart_time
            ? moment(
                val?.post_extarridepart?.post_extarrival_last_depart_time,
                "HH:mm:ss"
              )
            : null,
          [`post_extarrival_record_locator-${index}`]:
            val?.post_extarridepart?.post_extarrival_record_locator,
          [`post_extarrival_transfer-${index}`]:
            val?.post_extarridepart?.post_extarrival_transfer,
          [`post_extdeparture_flight-${index}`]:
            val?.post_extarridepart?.post_extdeparture_flight,
          [`post_extdeparture_airline-${index}`]:
            val?.post_extarridepart?.post_extdeparture_airline,
          [`post_extdeparture_date-${index}`]: val.post_extarridepart
            .post_extdeparture_date
            ? moment(val?.post_extarridepart?.post_extdeparture_date)
            : null,
          [`post_extdeparture_time-${index}`]: val.post_extarridepart
            .post_extdeparture_time
            ? moment(
                val?.post_extarridepart?.post_extdeparture_time,
                "HH:mm:ss"
              )
            : null,
          [`post_extarrival_city-${index}`]:
            val?.post_extarridepart?.post_extarrival_city,
          [`post_extdeparture_record_locator-${index}`]:
            val?.post_extarridepart?.post_extdeparture_record_locator,
          [`post_extdeparture_transfer-${index}`]:
            val?.post_extarridepart?.post_extdeparture_transfer,
          [`field_doc_request-${index}`]: val?.field_doc_request,
          [`field_docrequest_type-${index}`]: val?.field_docrequest_type,
          [`field_doc_notes-${index}`]: val?.field_doc_notes,
          [`field_insurance_declined-${index}`]: val?.field_insurance_declined,
          [`contract_rcvd_date-${index}`]: val.contract_rcvd_date
            ? moment(val?.contract_rcvd_date)
            : null,
          [`field_web_terms-${index}`]: val?.field_web_terms,
          [`web_terms_accept_date-${index}`]: val.web_terms_accept_date
            ? moment(val?.web_terms_accept_date)
            : null,
          [`field_relation_traveler-${index}`]:
            val?.field_relation_traveler?.drupal_internal__tid,
        });
      });
      setData(travelerData);
    }
  }, [allTravelersData]);

  useEffect(() => {
    data.forEach((val) => {
      form.setFieldsValue(val);
    });
  }, [data]);

  function removeSuffixFromKeys(obj, index) {
    const modifiedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const modifiedKey = key.replace(new RegExp(`-${index}$`), "");
        modifiedObj[modifiedKey] = obj[key];
      }
    }
    return modifiedObj;
  }
  function addSuffixToKeys(obj, index) {
    const modifiedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const modifiedKey = key + `-${index}`;
        modifiedObj[modifiedKey] = obj[key];
      }
    }
    return modifiedObj;
  }

  useEffect(() => {
    if (copiedIndex !== undefined) {
      let copiedData = {};

      const allVals = form.getFieldsValue();
      const keys = Object.keys(allVals).filter((e) => e.includes(copiedIndex));
      keys.forEach((val, index) => {
        copiedData[val] = allVals[val];
      });

      const modifiedObject = removeSuffixFromKeys(copiedData, copiedIndex);
      delete modifiedObject["field_gender"];
      delete modifiedObject["field_role"];

      data.forEach((val, index) => {
        const modifiedValues = addSuffixToKeys(modifiedObject, index);
        form.setFieldsValue(modifiedValues);
      });
      setCopiedIndex();
    }
  }, [copiedIndex]);

  const editTraveler = async (payload) => {
    const response = await editTravelerData(payload);

    if (response && response.status) {
      dispatch(setSectionHighlighter({}));
      message.success("Traveler's Details Edited Successfully");
      setTimeout(() => {
        window.location.href = `${ReservationDetailRoute}?id=${paramsId}`;
      }, 300);
    } else {
      message.error(
        response?.data?.response?.data?.data?.message || SOMETHING_WENT_WRONG
      );
    }
    setLoading(false);
  };

  const onFinishHandler = (values) => {
    let relationshipMissing = [];
    let payload = [];
    allTravelersData.forEach((e, index) => {
      let keyList = Object.keys(values);
      let filteredValue = keyList.filter((val) => val.includes(`-${index}`));
      let data = { id: e.drupal_internal__id };
      filteredValue.map((val) => {
        if (val.includes("time")) {
          data[val.slice(0, -2)] = values[val]
            ? moment(values[val]).format("HH:mm:ss")
            : null;
        } else if (val.includes("date")) {
          data[val.slice(0, -2)] = values[val]
            ? moment(values[val]).format("YYYY-MM-DD")
            : null;
        } else if (
          val.includes("arrival_airline") ||
          // val.includes("field_reporting_roles") ||
          val.includes("arrival_transfer") ||
          val.includes("departure_airline") ||
          val.includes("departure_transfer") ||
          val.includes("extarrival_transfer") ||
          val.includes("pre_extdeparture_airline") ||
          val.includes("extdeparture_transfer") ||
          val.includes("extarrival_transfer") ||
          val.includes("post_extarrival_airline") ||
          val.includes("post_extarrival_airline") ||
          val.includes("post_extarrival_transfer") ||
          val.includes("post_extdeparture_airline") ||
          val.includes("post_extdeparture_transfer")
        ) {
          data[val.split("-")[0]] = { id: typeof values[val] !== "number" ? values[val]?.id : values[val]};
        } else if (val.includes("field_relation_traveler")) {
          if (values[val]) {
            data[val.split("-")[0]] = { id: values[val] };
          } else {
            relationshipMissing.push(val);
          }
        } else {
          data[val.split("-")[0]] = values[val] || null;
        }
      });
      payload.push(data);
    });

    editTraveler({ data: payload });
    setLoading(true);
  };

  const onFinishFailed = (error) => {
    message.error("Some Required Fields Are Empty");
  };

  return (
    <div className="details-tabs" style={{ margin: 0 }}>
      <div>
        <div className="Res_details bg-white">
          {/* <Res_editguest_form /> */}
          <Form
            form={form}
            onFinish={(values) => onFinishHandler(values)}
            onFinishFailed={(values) => onFinishFailed(values)}
          >
            {allTravelersData.length ? (
              allTravelersData.map((e, index) => (
                <TravelerForm
                  reservationStatus={reservationStatus}
                  data={e}
                  index={index}
                  setCopiedIndex={setCopiedIndex}
                  relations={relations}
                  relationLoading={relationLoading}
                  arrivalAirline={arrivalAirline}
                  arrivalAirlineLoading={arrivalAirlineLoading}
                  arrivalTransferLoading={arrivalTransferLoading}
                  arrivalTransfer={arrivalTransfer}
                  departureAirlineLoading={departureAirlineLoading}
                  departureAirline={departureAirline}
                  departureTransferLoading={departureTransferLoading}
                  departureTransfer={departureTransfer}
                  preArrivalAirlineLoading={preArrivalAirlineLoading}
                  preArrivalAirline={preArrivalAirline}
                  extArrivalTransferLoading={extArrivalTransferLoading}
                  extArrivalTransfer={extArrivalTransfer}
                  extDepartureAirlineLoading={extDepartureAirlineLoading}
                  extDepartureAirline={extDepartureAirline}
                  extDepartureTransferLoading={extDepartureTransferLoading}
                  extDepartureTransfer={extDepartureTransfer}
                  postExtArrivalAirline={postExtArrivalAirline}
                  postExtArrivalAirlineLoading={postExtArrivalAirlineLoading}
                  postExtArrivalTransferLoading={postExtArrivalTransferLoading}
                  postExtArrivalTransfer={postExtArrivalTransfer}
                  postExtDepartureAirlineLoading={
                    postExtDepartureAirlineLoading
                  }
                  postExtDepartureAirline={postExtDepartureAirline}
                  postExtDepartureTransferLoading={
                    postExtDepartureTransferLoading
                  }
                  postExtDepartureTransfer={postExtDepartureTransfer}
                />
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Res_editguestformsection;
