import { Content } from "antd/lib/layout/layout";
import React from "react";
import AddonFeeCriteria from "./AddonFeeCriteria";
import AddonFeeInfo from "./AddonFeeInfo";
import FeeDiscountCategoriesListing from "../FeeDiscountCategory/FeeDiscountCategoriesListing";

const AddonFeeDetailsContainer = ({
  feeDetailsData,
  loadingFeeDetailData,
  feeType,
}) => {
  return (
    <div>
      {" "}
      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonFeeInfo
          loadingFeeDetailData={loadingFeeDetailData}
          feeDetailsData={feeDetailsData}
          feeType={feeType}
        />
      </Content>
      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonFeeCriteria
          loadingFeeDetailData={loadingFeeDetailData}
          feeDetailsData={feeDetailsData}
          feeType={feeType}
        />
      </Content>
      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <FeeDiscountCategoriesListing feeType={feeType} />
      </Content>
    </div>
  );
};

export default AddonFeeDetailsContainer;
