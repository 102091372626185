import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import {
  contactDetailsEditLoader,
  updateAdditionalInfo,
} from "../../../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { jacketShirtSize } from "../../../../../others/util/commonFunctions";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
const { Option } = Select;

const AdditionalInformationDrawer = ({ additionalInfoData, contactId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { updateAdditionalResponse, loadingUpdateAdditional } = useSelector(
    (state) => state.contacts.updateAdditional
  );
  const updateContactRef = useRef(false);
  const [open, setOpen] = useState(false);
  const [vocalPartList, setVocalPartList] = useState([]);
  const [loadingVocalPart, setLoadingVocalPart] = useState(false);
  const {
    field_shirt_size,
    field_church_member,
    field_church_name,
    field_military,
    field_company,
    field_website,
    field_department,
    field_occupation,
    field_job_title,
    field_vocal_part,
    field_step,
  } = additionalInfoData || {};
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  useEffect(() => {
    if (updateContactRef.current) {
      if (updateAdditionalResponse.status) {
        onClose();
        message.success("Additional Information Updated");
      } else {
        message.error(
          updateAdditionalResponse?.data?.response?.data?.message ||
            updateAdditionalResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        onClose();
      }
    }
    updateContactRef.current = true;
  }, [updateAdditionalResponse]);

  const showDrawer = () => {
    getTaxonomyList("vocal_part", setVocalPartList, setLoadingVocalPart);
    form.setFieldsValue({
      field_shirt_size: field_shirt_size?.key || null,
      field_church_member,
      field_church_name,
      field_military: field_military?.key || null,
      field_company,
      field_website,
      field_department,
      field_occupation,
      field_job_title,
      field_vocal_part: field_vocal_part?.[0]?.id,
    });
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinishHandler = (values) => {
    const payload = {
      data: {
        type: "contact--contact",
        id: contactId,
        attributes: {
          field_shirt_size: values?.field_shirt_size || null,
          field_church_member: values.field_church_member,
          field_church_name: values?.field_church_name || null,
          field_military: values?.field_military || null,
          field_company: values?.field_company || null,
          field_website: values?.field_website || null,
          field_department: values?.field_department || null,
          field_occupation: values?.field_occupation || null,
          field_job_title: values?.field_job_title || null,
          field_step,
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };

    if (values.field_vocal_part) {
      payload.data = {
        ...payload.data,
        relationships: {
          field_vocal_part: {
            data: {
              type: "taxonomy_term--vocal_part",
              id: values.field_vocal_part,
            },
          },
        },
      };
    }
    const reqHeaders = customRequestHeader({ token: Token });

    dispatch(updateAdditionalInfo({ payload, reqHeaders, contactId }));
  };
  return (
    <>
      {permissionChecker("add/edit contact") ? (
        <Button onClick={showDrawer} type="y" icon={<EditOutlined />} />
      ) : null}

      <Drawer
        title="Edit Additional Information"
        maxWidth={425}
        onClose={onClose}
        open={open}
        maskClosable={false}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            <Button
              onClick={() => {
                dispatch(contactDetailsEditLoader(false));
                form.submit();
              }}
              type="primary"
              loading={loadingUpdateAdditional}
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Jacket/Shirt Size"
                name="field_shirt_size"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  allowClear
                  options={jacketShirtSize.map((size) => ({
                    key: size.key,
                    value: size.key,
                    label: size.label,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Church Member"
                name="field_church_member"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Church Name, City & State"
                name="field_church_name"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Vocal Part"
                name="field_vocal_part"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  loading={loadingVocalPart}
                  options={vocalPartList.map((vocalPart) => ({
                    key: vocalPart.id,
                    value: vocalPart.id,
                    label: vocalPart.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Military"
                name="field_military"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select   showSearch
           showArrow
           filterOption={(input, option) =>
             option.label.toLowerCase().indexOf(input.toLowerCase()) >=
               0 ||
             option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
           } allowClear>
                  <Select.Option value="active_duty" label="Active Duty">Active Duty</Select.Option>
                  <Select.Option value="reserve" label="Reserve">Reserve</Select.Option>
                  <Select.Option value="veteran" label="Veteran">Veteran</Select.Option>
                  <Select.Option value="retired" label="Retired">Retired</Select.Option>
                  <Select.Option value="family" label="Family">Family</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Company"
                name="field_company"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Website"
                name="field_website"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Department"
                name="field_department"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Occupation"
                name="field_occupation"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Job Title"
                name="field_job_title"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AdditionalInformationDrawer;
