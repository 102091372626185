import { Divider } from "antd";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ManageMarketingInspiration from "./MarketingSourcesItems/ManageMarketingInspiration";
import ModalSource from "./MarketingSourcesItems/ModalSource";
import MarketingSources from "./MarketingSources";

const ManageMarketingSources = () => {
  const componentsList = [
    { component: <ManageMarketingInspiration />, dragId: "1" },
  ];

  const [itemList, setItemList] = useState(componentsList);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: "12px 0",
    ...draggableStyle,
  });
  return (
    <div className="manage_market_sources">
      <MarketingSources tab="global" />
    </div>
  );
};

export default ManageMarketingSources;
