import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import "./OrganizationPrimaryContactDetails.less";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const OrganizationPrimaryContactDetails = ({
  primaryContactDetailsData,
  organizationDetailsData,
  orgId,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { uuid, id, name, title, email, email2, phone, phone2 } =
    primaryContactDetailsData || {};
  const { field_fax_extension, field_fax_phone_number } =
    organizationDetailsData || {};

  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );
  const data = [
    {
      key: "name",
      label: "Name",
      input: name ? <Link to={`/contact/details?id=${uuid}`}>{name}</Link> : "",
    },
    {
      key: "title",
      label: "Job Title",
      input: title || "",
    },
    {
      key: "email",
      label: "Email Address",
      input: email || "",
    },
    {
      key: "email2",
      label: "Email Address 2",
      input: email2 || "",
    },
    {
      key: "phone",
      label: "Cell Phone",
      input: phone || "",
    },
    {
      key: "phone2",
      label: "Other Phone",
      input: phone2 || "",
    },
    {
      key: "9",
      label: "Fax",
      input: !!field_fax_phone_number
        ? `${field_fax_extension}-${field_fax_phone_number}`
        : "",
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Primary Contact Information</h4>
        <span>
          {allowEditOrganizations ? (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/organization?editId=${orgId}&step=2`);
              }}
            />
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default OrganizationPrimaryContactDetails;
