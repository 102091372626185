import { Table, Button, Divider } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../utils/constants";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function ExtensionDetails({ extensionData, extensionLoading }) {
  const [dataSource, setDataSource] = useState([]);
  const {
    field_requested_start_date,
    field_requested_end_date,
    field_extension_itinerary,
    field_are_dates_flexible,
    field_extension_destination,
    field_length_of_extension,
    field_tour_operator,
  } = extensionData || {};

  const data = [
    {
      key: "1",
      label: "Extension Destination",
      input: <>{field_extension_destination?.name || ""}</>,
    },
    {
      key: "2",
      label: "Itinerary",
      input: <>{field_extension_itinerary?.name || ""}</>,
    },
    {
      key: "3",
      label: "Requested Dates",
      input: (
        <>
          {(field_requested_start_date
            ? moment(field_requested_start_date).format(DATE_FORMAT)
            : "") +
            `${field_requested_start_date ? " - " : ""}` +
            (field_requested_end_date
              ? moment(field_requested_end_date).format(DATE_FORMAT)
              : "")}
        </>
      ),
    },
    {
      key: "4",
      label: "Are Dates Flexible?",
      input: (
        <>
          {field_are_dates_flexible === true
            ? "Yes"
            : field_are_dates_flexible === false
              ? "No"
              : ""}
        </>
      ),
    },
    {
      key: "5",
      label: "Length of Extension",
      input: <>{field_length_of_extension || ""}</>,
    },
    {
      key: "6",
      label: "Tour Operator",
      input: <>{field_tour_operator?.title || ""}</>,
    },
  ];

  useEffect(() => {
    setDataSource(data);
  }, [extensionData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Dates & Itinerary</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: extensionLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={dataSource}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default ExtensionDetails;
