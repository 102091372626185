import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getDiscountCategoriesListApi,
  getDiscountCategoryListingApi,
  getFeeCategoriesListApi,
  getFeeCategoryListingApi,
} from "../../../../apis/eventsApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import {
  AddonDiscountDetailsCategoryRoute,
  AddonFeeDetailsCategoryRoute,
  EventDiscountDetailsCategoriesRoute,
  EventFeeDetailsCategoriesRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Category Code",
    dataIndex: "category_code",
  },

  {
    title: "Category Name",
    dataIndex: "category_name",
  },

  {
    title: "Active?",
    dataIndex: "active",
  },
];

const FeeDiscountCategoriesListing = ({
  feeType,
  discountType,
  setUsedCount,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categoriesListData, setCategoriesListData] = useState([]);
  const feeId = searchParams.get("feeId");
  const eventId = searchParams.get("id");
  const discountId = searchParams.get("discountId");
  const addonId = searchParams.get("addonId");

  useEffect(() => {
    getCategoriesList(eventId, feeId, discountId, addonId);
  }, []);

  const getCategoriesList = async (eventId, feeId, discountId, addonId) => {
    setLoadingCategories(true);
    const feeData = !!feeType
      ? feeType === "event"
        ? { eventId, feeId }
        : { addonId, feeId }
      : null;
    const discountData = !!discountType
      ? discountType === "event"
        ? { eventId, discountId }
        : { addonId, discountId }
      : null;
    const response = feeId
      ? await getFeeCategoryListingApi(feeData)
      : await getDiscountCategoryListingApi(discountData);
    if (response?.status) {
      setCategoriesListData(response?.data?.listing?.data);
      if (discountType) {
        setUsedCount(response?.data?.global?.used_count_on_reservation || 0);
      }
      setLoadingCategories(false);
    } else {
      setCategoriesListData([]);
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setLoadingCategories(false);
    }
  };

  const data = categoriesListData?.length
    ? categoriesListData.map((category) => ({
        key: category?.category_uuid,
        category_code: category?.category_code,
        category_name: category?.category_name,
        active:
          category?.active !== null ? (
            <Switch disabled checked={category?.active} />
          ) : (
            ""
          ),
      }))
    : [];

  const routeToEditCategoryList = (
    feeType,
    discountType,
    eventId,
    addonId,
    discountId,
    feeId
  ) => {
    if (feeId) {
      if (feeType === "event") {
        return `${EventFeeDetailsCategoriesRoute}?id=${eventId}&feeId=${feeId}`;
      } else if (feeType === "addon") {
        return `${AddonFeeDetailsCategoryRoute}?id=${eventId}&addonId=${addonId}&feeId=${feeId}`;
      }
    }
    if (discountId) {
      if (discountType === "event") {
        return `${EventDiscountDetailsCategoriesRoute}?id=${eventId}&discountId=${discountId}`;
      } else if (discountType === "addon") {
        return `${AddonDiscountDetailsCategoryRoute}?id=${eventId}&addonId=${addonId}&discountId=${discountId}`;
      }
    }
  };

  const onEditHandler = () => {
    navigate(
      routeToEditCategoryList(
        feeType,
        discountType,
        eventId,
        addonId,
        discountId,
        feeId
      )
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          {" "}
          {`Categories (${categoriesListData?.length || 0})`}
        </h4>

        {categoriesListData.length  && permissionChecker("add/edit event fee")? (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              onEditHandler();
            }}
          />
        ) : null}
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loadingCategories, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default FeeDiscountCategoriesListing;
