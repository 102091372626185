import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Select,
  Skeleton,
  Table,
  Tag,
  Modal,
  Switch,
  Form,
  Spin,
  Alert,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ViewReportRoute } from "../../url-routes/urlRoutes";
import reportConfig from "../../config/report.config";
import { useSelector } from "react-redux";
import { createFilterTags } from "../../others/util/filterTagsCreator";
import { permissionChecker } from "../../utils/permisssionChecker";
import axios from "axios";
import { getEventListForBulkInvoiceDropDown } from "../../apis/eventsApi";
import drupalConfig from "../../config/serverless/drupal.config";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { decodeContent } from "../../utils/contentParser";
const { Option } = Select;

function ReportsListContainer({
  urlParamsObject,
  setUrlParamsObject,
  reportListData,
  loadingList,
  reportCount,
  setReportListData,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const user_id = JSON.parse(localStorage.getItem("authorization"))?.user_id;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [isDownloading, setIsDownloading] = useState(
    localStorage.getItem("isDownloading") == "true"
  );
  const [download, setDownload] = useState(null);
  const [eventId, setEventId] = useState(
    localStorage.getItem("eventId") || null
  );
  const [reservationFoundFlag, setReservationFoundFlag] = useState(false);

  const { isReportFilterApplied, reportSearchFilters } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    const fetchEventList = async () => {
      if (isModalVisible) {
        try {
          const response = await getEventListForBulkInvoiceDropDown();
          if (response?.status) {
            setDropdownOptions(response?.data);
          }
        } catch (error) {
          console.error("Error fetching event list:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEventList();
  }, [isModalVisible]);

  useEffect(() => {
    if (!isDownloading) return;
    const fetchProgress = async (eventId) => {
      if (!isDownloading) return;
      try {
        const progressResponse = await axios.get(
          `${drupalConfig.BASE_URL}/api/pdf-generation-status?event_id=${eventId}&user_id=${user_id}`,
          {
            headers: { Authorization: `Bearer ${Token}` },
          }
        );

        const progressData = progressResponse?.data;
        setDownload(progressData);

        if (progressData?.status === "completed") {
          setIsDownloading(false);
          localStorage.setItem("isDownloading", false);
          localStorage.removeItem("eventId", progressData?.eventId);
          setTimeout(() => {
            setDownload(null);
          }, 2000);
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    };

    fetchProgress(eventId);

    const intervalId = setInterval(() => fetchProgress(eventId), 2000);

    return () => clearInterval(intervalId);
  }, [isDownloading, eventId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDownloading) {
        setIsDownloading(false);
        localStorage.setItem("isDownloading", false);
        localStorage.removeItem("eventId");
        setDownload(null);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDownloading]);

  const getFieldEventCodeById = (id) => {
    const option = dropdownOptions.find((option) => option.id === id);
    return option ? option : null;
  };

  const handleOk = async () => {
    if (!selectedOption) {
      message.error("Please select an event first.");
      return;
    }
    if (isDownloading) {
      message.error(
        "A report is already being downloaded. Please wait until it completes."
      );
      return;
    }
    setIsModalVisible(false);
    try {
      setIsDownloading(true);
      localStorage.setItem("isDownloading", true);
      setEventId(selectedOption);
      localStorage.setItem("eventId", selectedOption);

      // Scroll to the top of the page
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 1000);

      const response = await axios.get(
        `${drupalConfig.BASE_URL}/api/generate-pdf?event_id=${selectedOption}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
          responseType: "blob",
        }
      );
      if (response?.data) {
        setReservationFoundFlag(false);
        const blob = new Blob([response.data], { type: "application/zip" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;

        const event = getFieldEventCodeById(selectedOption);

        link.download = `${decodeContent(event?.name?.trim())}-${event?.field_event_code}.zip`;

        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setIsDownloading(false);
        setReservationFoundFlag(true);
        setDownload(null);
        localStorage.setItem("isDownloading", false);
        setEventId(null);
        localStorage.removeItem("eventId");
        message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      setIsDownloading(false);
      setReservationFoundFlag(true);
      localStorage.setItem("isDownloading", false);
      setEventId(null);
      localStorage.removeItem("eventId");
      setDownload(null);
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const errorText = reader.result;
          try {
            const errorJson = JSON.parse(errorText);
            message.error(errorJson.message || "Something went wrong");
          } catch (e) {
            message.error("Error processing the response");
          }
        };
        reader.onerror = () => {
          message.error("Error reading the error response");
        };
        reader.readAsText(error.response.data);
      } else {
        message.error("Error downloading the file.");
      }

      console.error("Error downloading the file:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setSelectedOption(null);
    setIsModalVisible(true);
  };
  const columns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      key: "report_name",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
    },
    {
      title: "View(s)",
      dataIndex: "views",
      key: "views",
    },
    {
      title: "Last View",
      dataIndex: "last_view",
      key: "last_view",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (record, recordObj) => {
        return (
          <Button
            onClick={() => {
              if (recordObj?.reportListItem?.birtLink === "/batch/printing") {
                showModal();
              } else {
                navigate(
                  `${ViewReportRoute}?id=${recordObj?.reportListItem?.id}&uuid=${recordObj?.reportListItem?.uuid}&report_link=${reportConfig?.BASE_URL}${recordObj?.reportListItem?.birtLink}`
                );
              }
            }}
          >
            View Report
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    const isAllowed = permissionChecker("view navigation menu all reports");
    if (!isAllowed) {
      navigate("/page-not-access");
    }
  }, []);

  useEffect(() => {
    if (reportListData.length) {
      setData(
        reportListData?.map((reportListItem) => ({
          report_name: reportListItem?.reportName,
          tags: reportListItem?.reportTag?.length ? (
            <>
              {reportListItem?.reportTag?.map((tag) => (
                <Tag>{tag.name}</Tag>
              ))}
            </>
          ) : null,
          views: reportListItem?.viewCount || 0,
          last_view:
            JSON.parse(reportListItem?.lastView || {})?.days_ago || null,
          reportListItem,
        }))
      );
    }
  }, [reportListData]);

  const handleSorting = (value) => {
    if (value === "recent_read") {
      setUrlParamsObject({
        sort_by: "recent_read",
      });
    } else {
      setUrlParamsObject({ ...urlParamsObject, sort_by: value });
    }
  };
  return (
    <>
      <div className="table_filter">
        {isReportFilterApplied && (
          <div className="filter-applied--wrapper">
            <span className="filter-title">Filter(s) Applied:</span>
            {createFilterTags(reportSearchFilters)}
          </div>
        )}
        {loadingList ? (
          <Skeleton.Input size="medium" style={{ width: "250px" }} />
        ) : (
          <>
            {urlParamsObject.sort_by && (
              <>
                {" "}
                <span>Sort by :</span>
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={urlParamsObject.sort_by}
                  style={{
                    width: 165,
                    marginLeft: "10px",
                  }}
                  onChange={(e) => {
                    handleSorting(e);
                  }}
                >
                  <Option value="recent_read" label="Recently Viewed">
                    Recently Viewed
                  </Option>
                  <Option value="report_name" label="Name">
                    Name
                  </Option>
                  <Option value="view_count" label="Most Used">
                    Most Used
                  </Option>
                </Select>
              </>
            )}
          </>
        )}
      </div>
      {download && download?.status && (
        <>
          {download?.status == "completed" && reservationFoundFlag ? null : (
            <>
              <Alert
                message={`${download?.event}: ${download?.message}`}
                type={download?.status === "in_progress" ? "info" : "success"}
                style={{ marginBottom: 20 }}
              />
              <Alert
                message="Disclaimer: Please do not refresh the page or attempt to generate another report until the Batch Printing Invoice process is complete."
                type="warning"
                style={{ marginBottom: 20 }}
              />
            </>
          )}
        </>
      )}
      <Table
        className="table_tour"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loadingList, size: "large" }}
        pagination={false}
      />

      <Modal
        title="Parameters"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        centered
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <div style={{ width: "30%" }}>
              <label style={{ width: "100%" }}>Enter the event</label>
            </div>
            <Select
              showArrow
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedOption}
              onChange={setSelectedOption}
              placeholder="Enter the event"
              style={{ width: "100%" }}
              loading={loading}
              notFoundContent={loading ? <Spin size="small" /> : null}
            >
              {dropdownOptions.map((option) => {
                const optionText = `${decodeContent(option?.name)}(${
                  option?.field_event_code
                })`;
                return (
                  <Option
                    key={option?.id}
                    value={option?.id}
                    label={optionText}
                  >
                    {optionText}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ReportsListContainer;
