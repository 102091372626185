import { Form, Input, Select, message } from "antd";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createEmailActivityApi } from "../../../../../apis/contactApi";
import CustomUpload from "../../../../../others/CustomUpload/CustomUpload";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import "./ActivitiesEmailForm.less";
import { customRequestHeader } from "../../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import {
  fetchEmailTypeApi,
  sendEmailToTravellerApi,
} from "../../../../../apis/reservationApi";

const ActivitiesEmailForm = ({ form, setCreateLoading, contactData }) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadDocumentId, setuploadDocuementId] = useState("");
  const [editorInitData, setEditorInitData] = useState("");
  const [editor, setEditor] = useState(null);
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emailTypeOption, setEmailTypeOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [emailTypeData, setEmailTypeData] = useState({});
  const [loadingEmailTypes, setLoadingEmailTypes] = useState(false);
  const navigate = useNavigate();
  const contactId = searchParams.get("id");
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid } = authorization;
  // const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    fetchEmailType(contactId);
  }, []);

  useEffect(() => {
    if (Object.keys(contactData).length) {
      form.setFieldsValue({
        name: contactData?.name,
        email: contactData?.field_email_address,
      });
    }
  }, [contactData]);

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  useEffect(() => {
    setEmailTypeOption([emailTypeData?.email_type]);
  }, [emailTypeData]);

  useEffect(() => {
    if (editor) {
      // var parsers = new DOMParser();
      // var xmlDoc = parsers.parseFromString(
      //   selectedOption?.field_email_body?.value,
      //   "text/html"
      // );
      // var decodedHtml = xmlDoc.documentElement.textContent;
      if ((selectedOption.key = "guest_communication_template")) {
        editor.setData(emailTypeData?.emailBody);
      }
    }
  }, [selectedOption]);

  const fetchEmailType = async (contactId) => {
    const res = await fetchEmailTypeApi(contactId);
    if (res.status) {
      setEmailTypeData(res.data.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const sendEmailToContact = (emailData, activityId) => {
    const email_body = editorRef.current?.editor.getData();
    const payload = {
      data: {
        emailType: {
          key: selectedOption?.key || "test", //this is advised from BE to send any string in case of no email type is selected
          value: selectedOption?.value || "test",
        },
        entity_type: "contact",
        entity_id: contactData?.drupal_internal__id,
        traveler_id: contactData?.drupal_internal__id,
        partyName: emailData?.name,
        partyEmail: emailData?.email,
        emailSubject: emailData?.email_subject,
        emailBody: email_body,
        attachements: fileList?.length
          ? fileList?.map((listItem) => ({
              filename: listItem?.name,
              fileid: listItem?.fileid,
              fileuuid: listItem?.uid,
              fileUrl: listItem?.fileUrl,
            }))
          : [],
      },
      status: true,
    };
    sendEmailActivity(payload, activityId);
  };

  const sendEmailActivity = async (data, activityId) => {
    setCreateLoading(true);
    const res = await sendEmailToTravellerApi(data);
    if (res.status) {
      message.success("Email Sent Successfully");
      setCreateLoading(false);

      navigate(
        `/contact/details/activities?activityId=${activityId}&contactId=${contactId}`
      );
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setCreateLoading(false);
    }
  };
  const createEmailActivity = async (data, sendEmailData) => {
    setCreateLoading(true);
    const res = await createEmailActivityApi(data);
    if (res.status) {
      message.success("Activity Logged Successfully");
      sendEmailToContact(sendEmailData, res?.data?.data?.id);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setCreateLoading(false);
  };
  const onFinishHandler = (values) => {
    const { name, email, email_subject } = values;
    const reqHeaders = customRequestHeader({ token: Token });
    const email_body = editorRef.current?.editor.getData();
    const payload = {
      data: {
        type: "activity--activity",
        attributes: {
          name,
          field_email: email,
          field_email_subject: email_subject,
          field_email_body: email_body,
          field_message: email_body,
          field_task_type: "email",
          field_task_status: null,
          field_priority: null,
          field_due_time: moment().format(),
          field_start_time: moment().format(),
          created_on: "staffweb",
          modified_on: "staffweb",
        },
        relationships: {
          field_contact: {
            data: {
              type: "contact--contact",
              id: contactId,
            },
          },
          field_activity_task_name: {
            data: {
              type: "taxonomy_term--activity_task_name",
              id: "9ffb007b-124b-494a-96dd-5049e6d0703b",
            },
          },
          field_activity_email_type: {
            data: null,
          },
          field_attachments: {
            data: fileList?.length
              ? fileList.map((file) => ({
                  type: "file--file",
                  id: file?.uid,
                }))
              : [],
          },
          field_task_documents: {
            data: fileList?.length
              ? fileList.map((file) => ({
                  type: "media--task_documents",
                  id: file?.libraryId,
                }))
              : [],
          },
          field_assignee_staff: {
            data: uuid && {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
          field_last_modified_by: {
            data: uuid && {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
        },
      },
    };
    createEmailActivity(
      { reqHeaders, payload },
      { ...values, field_email_body: email_body?.editor?.getData() }
    );
  };

  const handleSelectionChange = (value, item) => {
    setSelectedOption(item);
    if (item.value === "guest_communication_template") {
      setEditorInitData(emailTypeData?.emailBody || "")
      form.setFieldsValue({
        email_subject: emailTypeData?.email_subject,
      });
    }
  };

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };
  return (
    <div className="addnote_form">
      <div>
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 3,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Name"
            name="name"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please fill name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            className="asterick-align"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
                validateTrigger: "onSubmit",
              },
              {
                required: true,
                message: "Please enter email address",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Email Type" name="email_type">
            <Select
              allowClear
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={emailTypeOption?.map((emailType) => ({
                key: emailType?.key,
                value: emailType?.key,
                label: emailType?.value,
              }))}
              loading={loadingEmailTypes}
              onSelect={(value, item) => handleSelectionChange(value, item)}
              onClear={() => {
                editor.setData(null);
                form.setFieldsValue({ email_subject: null });
              }}
            ></Select>
          </Form.Item>

          <Form.Item
            label="Email Subject"
            name="email_subject"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please enter email subject",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="textarea_row">
            <Form.Item
              className="ckeitor-field-wrapper"
              label="Email Body"
              name="email_body"
              rules={[
                {
                  required: true,
                  message: "Please enter email body",
                },
              ]}
            >
              <CKEditor
                editor={ClassicEditor}
                ref={editorRef}
                config={editorConfig}
                data={editorInitData}
                onReady={(editor) => {
                  setEditorInstance(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorInitData(data);
                  form.setFieldsValue({ email_body: data });
                }}
              />
            </Form.Item>
          </div>

          <div className="textarea_row">
            <Form.Item className="asterick-align" label="Attachments">
              <div>
                <CustomUpload
                  fileList={fileList}
                  isMultiple={true}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/media/task_documents/field_media_file"
                  mediaDocument={true}
                  setuploadDocuementId={setuploadDocuementId}
                  linkMediaUrl="/api/v1/media/task_documents"
                  linkMediaRelation="field_media_file"
                  linkMediaType="media--task_documents"
                  emailDocument={true}
                  availableFormat={
                    "mp4, mp3, wav, aac, txt, rtf, doc, docx, ppt, pptx, xls, xlsx, pdf, odf, odg, odp, ods, odt, fodt, fods, fodp, fodg, key, numbers, pages, png, gif, jpg, jpeg"
                  }
                  availableSize={"100 MB"}
                />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ActivitiesEmailForm;
