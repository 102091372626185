import React from 'react';
import { Table, Divider } from 'antd';
const columns = [
  {
    title: 'Date /Time',
    dataIndex: 'date_time',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Payment Method',
    dataIndex: 'payment_method',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Applied',
    dataIndex: 'applied',
  },
  {
    title: 'Applied from this payment',
    dataIndex: 'applied_payment',
  },
];
const data = [
  {
    key: '1',
    date_time: <><a href="#">07/26/2023</a></>,
    description: <><a href="#">Deposit</a></>,
    payment_method: 'Payment Card Visa-1095',
    amount: '$1,000.00',
    applied: "$1,000.00",
    applied_payment: "$1,000.00",
  },

];

const ViewPaymentDetailsTable = () => (
  <>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h4 className='mb-0'>Planned Payments - Applied to</h4>
    </div>
    <Divider className="global_divider" />
    <Table columns={columns} dataSource={data} pagination={false} />
  </>
);
export default ViewPaymentDetailsTable;
