const ENV = process.env.REACT_APP_PUBLIC_MODE || "development";

const CONFIG = {
  development: {
    BASE_URL: "https://dev-birt-viewer.oslabs.app",
  },
  stage: {
    BASE_URL: "https://inspirationtravel-booking-reports.mmgystage.com",
  },
  prod: {
    BASE_URL: "https://booking-reports.inspirationtravel.com"
  },
  oldStage:{
    BASE_URL: "https://stage-birt-viewer.oslabs.app"
  }
};

export default CONFIG[ENV];
