import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Radio, Alert } from "antd";
import { Dropdown, Menu, Space } from "antd";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <a href="#">Delete</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "More Options",
        key: "3",
      },
    ]}
  />
);

function Reservationsemail_confirmationheader() {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  return (
    <>
      <div className="breadcrumb_content">
        <div className="breadcrumb_heading view_header">
          {/* <h3 className='heading_three' ><ArrowLeftOutlined className="back-icon-profile" />   
      
                    <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >View Task: Email Confirmation </span>
                    <span className='span_text'> (603514)</span>
                </h3> */}

          <h3 className="flex_row_column">
            <ArrowLeftOutlined
              className="back-icon-profile"
              //   onClick={navigate("/contact/details")}
            />
            <div className="header_res">
              <p>View Task: Email Confirmation </p>
              <p className="header_smalltext"> (603514)</p>
            </div>
          </h3>

          {/* <div className="breadcrumb_buttons">
                    <Button
                        type="y"
                        icon={<EditOutlined />}

                    />
                    <Button
                        type="y"
                        icon={<DeleteOutlined />}

                    />

                    <Button type="" >
                        <MoreOutlined />  More Options
                    </Button>
                </div> */}

          {/* <div className='breadcrumb_toggle'>
                    <Dropdown overlay={menu} trigger={['click']}
                          overlayClassName="add_intake"
                    >
                        <a onClick={(e) => e.preventDefault()}
                 
                        >
                            <Space>
                                <Button
                                    type="y"
                                    icon={<MoreOutlined />}

                                />
                            </Space>
                        </a>
                    </Dropdown>
                </div> */}
        </div>
      </div>
    </>
  );
}

export default Reservationsemail_confirmationheader;
