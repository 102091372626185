import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
import { useState } from "react";
import { uploadDocumentApi } from "../../apis/contactApi";
import {
  Cookie,
  adminToken,
  customRequestHeader,
} from "../../utils/cutomRequestHeader";
import drupalConfig from "../../config/serverless/drupal.config";
import { linkMediaToLibraryApi } from "../../apis/organisationApi";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const CustomUpload = ({
  fileList,
  setFileList,
  isMultiple,
  isLoading,
  setIsLoading,
  uploadUrl,
  mediaDocument,
  setuploadDocuementId,
  linkMediaUrl,
  linkMediaType,
  linkMediaRelation,
  emailDocument,
  availableFormat,
  availableSize,
}) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const linkMediaToLibrary = async (mediaResponse) => {
    const payload = {
      data: {
        type: linkMediaType,
        attributes: {
          name: mediaResponse?.filename || "pdf-file",
        },
        relationships: {
          [linkMediaRelation]: {
            data: !!mediaResponse?.id
              ? {
                  type: "file--file",
                  id: mediaResponse?.id,
                }
              : {},
          },
        },
      },
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const response = await linkMediaToLibraryApi({
      url: linkMediaUrl,
      reqHeaders,
      payload,
    });
    if (response.status) {
      setuploadDocuementId(response?.data?.data?.id);
      isMultiple
        ? setFileList([
            ...fileList,
            {
              uid: mediaResponse.id,
              name: mediaResponse.filename,
              url: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              thumbUrl: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              fileid: mediaResponse.drupal_internal__fid,
              fileUrl: mediaResponse.uri.value,
              libraryId: response?.data?.data?.id,

            },
          ])
        : setFileList([
            {
              uid: mediaResponse.id,
              name: mediaResponse.filename,
              url: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              thumbUrl: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              libraryId: response?.data?.data?.id,
            },
          ]);
      setIsLoading(false);
      message.success(`${mediaResponse.filename} Uploaded Successfully`);
    } else {
      message.error("Unable To Upload Document");
      setuploadDocuementId("");
      setFileList([]);
      setIsLoading(false);
    }
  };

  const uploadFunction = async (payload, fileType) => {
    setIsLoading(true);
    const url = uploadUrl;
    const reqHeaders = customRequestHeader({
      token: Token,
      isUploadReq: true,
      fileType,
    });
    const response = await uploadDocumentApi({ url, reqHeaders, payload });
    // console.log("res", response);
    if (response.status) {
      if (mediaDocument) {
        linkMediaToLibrary(response?.data?.data);
      } else {
        if (emailDocument) {
          isMultiple
            ? setFileList([
                ...fileList,
                {
                  fileid: response.data.data.drupal_internal__fid,
                  fileUrl: response.data.data.uri.value,
                  uid: response.data.data.id,
                  name: response.data.data.filename,
                  url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                  thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                },
              ])
            : setFileList([
                {
                  fileid: response.data.data.drupal_internal__fid,
                  fileUrl: response.data.data.uri.value,
                  uid: response.data.data.id,
                  name: response.data.data.filename,
                  url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                  thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                },
              ]);
          setIsLoading(false);
          message.success(
            `${response.data.data.filename} Uploaded Successfully`
          );
        } else {
          isMultiple
            ? setFileList([
                ...fileList,
                {
                  uid: response.data.data.id,
                  name: response.data.data.filename,
                  url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                  thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                },
              ])
            : setFileList([
                {
                  uid: response.data.data.id,
                  name: response.data.data.filename,
                  url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                  thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                },
              ]);
          setIsLoading(false);
          message.success(
            `${response.data.data.filename} Uploaded Successfully`
          );
        }
      }
    } else {
      setIsLoading(false);
      message.error(
        response?.data?.response?.data?.errors?.[0]?.detail?.split("\n")[1] ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onChangeHandler = (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = function () {
        const base64String = reader.result.split(",")[1]; // extract base64 string from data URL
        const binaryArray = getBinaryArrayFromBase64String(base64String);
        // console.log({ binaryArray });
        uploadFunction(binaryArray, info.file.name);
      };
      reader.onerror = function (error) {
        message.error("Failed To Upload Image");
      };
    }
  };

  const getBinaryArrayFromBase64String = (base64String) => {
    const binaryString = atob(base64String);
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    return binaryArray;
  };

  const onRemoveHandler = (removeFile) => {
    if (!isMultiple) {
      setFileList([]);
    } else {
      setFileList(fileList.filter((file) => file.uid !== removeFile.uid));
    }
  };
  return (
    <Upload
      multiple={isMultiple}
      listType="picture"
      onChange={onChangeHandler}
      fileList={fileList}
      customRequest={() => {}}
      onRemove={(e) => {
        onRemoveHandler(e);
      }}
    >
      <Button icon={<UploadOutlined />} loading={isLoading}>
        Upload
      </Button>
      <div style={{ display: "flex", flexDirection: "column", marginTop:"5px" }}>
        {availableFormat ? (
          <span className="span_text">
            Supported File Types : {availableFormat}
          </span>
        ) : null}
        {availableSize ? (
          <span className="span_text">Max File Size : {availableSize}</span>
        ) : null}
      </div>
    </Upload>
  );
};
export default CustomUpload;
