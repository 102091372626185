import { FilterOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import ContactListModal from "./ContactListModal";
import {
  getContactLists,
  updateSelectedContact,
} from "../../../../../apis/reservationApi";
import {
  Token,
  customRequestHeader,
} from "../../../../../utils/cutomRequestHeader";
import { getPrimaryContactsList } from "../../../../../redux/features/organizationFeatures/organizationSlice";
import { objectToQueryString } from "../../../../../others/util/convertObjectToQueryString";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import { useDebounce } from "../../../../../others/Debounce/debounce";
import { useSelector } from "react-redux";
import {
  markPayeeTrigger,
  setSectionHighlighter,
} from "../../../../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { organizationTypeList } from "../../../../../others/util/commonFunctions";
const TravelerSelectContactModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    index,
    setShowTable,
    showTable,
    element,
    refreshTravelerData,
    priorityTraveler,
    priorityLoader,
    orgTypeLoading,
  } = props;
  const [searchVal, setSearchVal] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [isLoading,setIsLoading ] = useState(false)

  const [activeClass, setActiveClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const paramsId = searchParams.get("id");
  const [contacts, setContacts] = useState([]);
  const [addLoader, setAddLoader] = useState(false);
  const [filters, setFilters] = useState({});
  const [form] = Form.useForm();
  const debouncedSearchTerm = useDebounce(searchVal, 500);
  const { isPayeeTrigger, highlightSection } = useSelector(
    (state) => state.reservation
  );

  const onFinishHandler = (values) => {
    setFilters(values);
    setOpenDropdown(false);
  };

  const clearFilter = () => {
    setContacts([]);
    setIsLoading(false)
    form.resetFields();
    setOpenDropdown(false);
    setFilters({});
  };

  useEffect(() => {
    const filter = Object.values(filters).filter(Boolean)
    if (filter && filter.length) {
      getAllContacts(paramsId, searchVal, filters);
    }
  }, [filters]);

  const custumSearch = (
    <Form
      className="custom_search modalcustom_search"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        lg: 20,
        xs: 20,
      }}
      layout="horizontal"
      style={{ fontFamily: "'Poppins', sans-serif" }}
      form={form}
      onFinish={onFinishHandler}
    >
      <Form.Item
        label="Email"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="email"
      >
        <Input
          placeholder="Enter Email Address"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        />
      </Form.Item>

      <Form.Item
        label="Phone"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="phone"
      >
        <Input
          placeholder="Enter Phone Number"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        />
      </Form.Item>

      <Form.Item
        label="Organization type"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="org"
      >
        <Select
          placeholder="Select Organization Type"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          loading={orgTypeLoading}
          showSearch
          showArrow
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >=
              0 ||
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={
            organizationTypeList?.map((org) => ({
              key: org?.key,
              value: org?.key,
              label: org?.label,
              nid: org?.key,
            })) || []
          }
        ></Select>
      </Form.Item>

      <Form.Item
        label="Organization"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="organization"
      >
        <Input
          placeholder="Enter Organization"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        />
      </Form.Item>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          type="text"
          style={{ border: "none", opacity: "0.85" }}
          onClick={() => {
            clearFilter();
          }}
        >
          Clear Filter
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Search
        </Button>
      </div>
    </Form>
  );

  const getAllContacts = async (id, search, query) => {
    const getQuery = objectToQueryString(query);
    const response = await getContactLists(id, search, getQuery);
    if (response && response.status) {
      const { data } = response;
      setContacts(data);
      setIsLoading(false)
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isModalOpen) {
  //     getAllContacts(paramsId);
  //   }
  // }, [isModalOpen]);

  useEffect(() => {
    if (searchVal && isModalOpen) {
      if (filters) {
        getAllContacts(paramsId, searchVal, filters);
      } else {
        getAllContacts(paramsId, searchVal);
      }
    }
  }, [debouncedSearchTerm]);

  const updateContact = async () => {
    let uuid = contacts[selectedRowKeys[0]].id;
    setAddLoader(true);
    let traveler_id = element.uuid;
    const response = await updateSelectedContact(uuid, traveler_id, paramsId);
    if (response && response.status) {
      const { data } = response?.data;
      dispatch(setSectionHighlighter({}));
      dispatch(markPayeeTrigger(!isPayeeTrigger));
      message.success("Traveler updated");
      handleOk();
      refreshTravelerData();
      setAddLoader(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setAddLoader(false);
    }
  };

  const updatePriorityContact = async (uuid) => {
    let filterPriorityTraveler = priorityTraveler.filter(
      (val) => val.contact_uuid === uuid
    );
    setAddLoader(true);
    let contactUuid = filterPriorityTraveler[0]?.contact_id;
    let travelerUuid = element.uuid;
    const response = await updateSelectedContact(
      contactUuid,
      travelerUuid,
      paramsId
    );
    if (response && response.status) {
      const { data } = response?.data;
      message.success("Traveler updated");
      handleOk();
      refreshTravelerData();
      dispatch(markPayeeTrigger(!isPayeeTrigger));
      setAddLoader(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setAddLoader(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setContacts([]);
    setSelectedRowKeys([]);
    setIsLoading(false)
    setSearchVal("");
    form.resetFields();
    setIsModalOpen(false);
    setOpenDropdown(false);
    setFilters({});
  };

  const changeHanlder = (event) => {
    const value = event.target.value
    if (!value.trim()) {
      setIsLoading(false)
      setContacts([]);
      setSelectedRowKeys([])
      setSearchVal("");
    } else {
      setIsLoading(true)
      setSearchVal(value);
    }
  }
  return (
    <>
      {!element.contacts.length ? (
        <Col>
          <div className="select_priority_cta_wrapper">
            <Button
              className="button_hover"
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => showModal()}
              danger={!!highlightSection?.select_contact && element?.is_primary}
            >
              Select Contact
            </Button>
            {priorityTraveler?.length ? (
              <Select
                placeholder="Prior Roommate"
                loading={priorityLoader}
                options={priorityTraveler?.map((aName, index) => ({
                  key: `${aName?.contact_id}-${index}`, 
                  value: aName?.contact_uuid,
                  label: aName?.name,
                }))}
                onSelect={(e) => updatePriorityContact(e)}
              />
            ) : null}
          </div>
        </Col>
      ) : (
        <p>{element.contacts[0].id}</p>
      )}

      <Modal
        className="select-conatct_modal"
        title="Select Contact"
        key={index}
        footer={
          <div className="end-between">
            <Link to={`/contact?redirectBackTo=${paramsId}`}>
              <Button type="">
                <PlusOutlined /> Add Contact
              </Button>
            </Link>
            <Button
              type="primary"
              loading={addLoader}
              disabled={
                selectedRowKeys && selectedRowKeys.length ? false : true
              }
              onClick={() => {
                updateContact();
                // setShowTable(!showTable)
              }}
            >
              Add Selected Contact
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1000}
      >
        <div className="modal_searchbox select-contact-modal-searchbox">
          <Input
            size="large"
            placeholder="Search Contacts"
            enterButton
            value={searchVal}
            onChange={changeHanlder}
          />
          <Dropdown
            overlay={custumSearch}
            overlayStyle={{
              maxWidth: "948px",
              width: "100%",
              paddingTop: "5px",
              paddingLeft: "0px",
              marginLeft: "90px",
            }}
            placement="bottomRight"
            trigger={["click"]}
            className="advance-search-dropdown modal_advanced-searchbox"
            visible={openDropdown}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActiveClass(!activeClass);
                setOpenDropdown(!openDropdown);
              }}
              style={{ position: "absolute", right: "30px", top: "73px" }}
            >
              <Space>
                <FilterOutlined
                  style={{
                    padding: "9px",
                    borderRadius: "50%",
                    marginTop: "-12px",
                    marginRight: "-4px",
                    // backgroundColor: filters ? "#1A7F93" : "transparent",
                    color: "rgba(26, 127, 147, 0.2)",
                  }}
                  className="active-filter active-hover"
                  // className={`${activeClass ? "active" : "inactive"}`}
                />
              </Space>
            </a>
          </Dropdown>
        </div>

        <div className="modal_tablebox">
            <ContactListModal
              contacts={contacts}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
              loading={loading}
              isLoading={isLoading}
            />
        </div>
      </Modal>
    </>
  );
};

export default TravelerSelectContactModal;
