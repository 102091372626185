import { Grid } from "antd";
import React from "react";
import "./CommonTopBar.less";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
const { useBreakpoint } = Grid;

const CommonTopBar = () => {
  return (
    <div className="horizontal_menu">
      <div
        className="profile"
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <BasicProfileControl />
      </div>
    </div>
  );
};

export default CommonTopBar;
