import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams , useParams} from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
import TourAirOptions from "./TourTabs/TourMainTab/TourAirOptions";
import TourDetailsForm from "./TourTabs/TourMainTab/TourDetailsForm";
import TourHotelOptionsForm from "./TourTabs/TourMainTab/TourHotelOptionsForm";
import TourInitialContactPersonForm from "./TourTabs/TourMainTab/TourInitialContactPersonForm";
import TourMealsForm from "./TourTabs/TourMainTab/TourMealsForm";
import TourMiscellaneous from "./TourTabs/TourMainTab/TourMiscellaneous";
import TourNotes from "./TourTabs/TourMainTab/TourNotes";
import TourPricingOptionsForm from "./TourTabs/TourMainTab/TourPricingOptionsForm";
import TourStaffAndVehicles from "./TourTabs/TourMainTab/TourStaffAndVehicles";
import TourPrePostAirOptionsForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostAirOptionsForm";
import TourPrePostDatesAndItineryForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostDatesAndItineryForm";
import TourPrePostMealsForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostMealsForm";
import TourPrePostMiscellaneousForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostMiscellaneousForm";
import TourPrePostPricingOptionsForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostPricingOptionsForm";
import TourPrePostStaffAndVehicleForm from "./TourTabs/TourPrePostExtensionTab/TourPrePostStaffAndVehicleForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  changeTabState,
  createGroupDetails,
  createGroupDetailsExtension,
  getGroupDetailsData,
  getGroupDetailsExtensionData,
} from "../../../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import moment from "moment";
import { GroupDetails_DetailsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
// import { userUuid } from "../../../../utils/cutomRequestHeader";

const CreateOrEditTourTabsContainer = ({
  createId,
  editId,
  form1,
  form2,
  form3,
  preExtId,
  postExtId,
  type
}) => {
  const [currentTab, setCurrentTab] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    groupDetailsCreation,
    groupDetailsExtensionCreation,
    groupDetailsData,
    groupDetailsExtensionData,
  } = useSelector((state) => state.groupDetails);
  const {
    groupDetailsCreationResponse,
    loadingGroupDetailsCreation,
    mainTab,
    preTab,
    postTab,
  } = groupDetailsCreation;
  const groupDetailsCreationRef = useRef(false);
  const {
    groupDetailsExtensionCreationResponse,
    loadingGroupDetailsExtensionCreation,
  } = groupDetailsExtensionCreation;
  const extensionCreationRef = useRef(false);
  const { groupDetailsDataResponse, loadingGroupDetailsData } =
    groupDetailsData;
  const groupDetailsDataRef = useRef(false);
  const {
    groupDetailsExtensionDataResponse,
    loadingGroupDetailsExtensionData,
  } = groupDetailsExtensionData;
  const extensionDataRef = useRef(false);
  const [tourFormField, setTourFormField] = useState({});
  const [extFormField, setExtFormField] = useState({});
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  useEffect(() => {
    let tab = location.hash ? decodeURI(location.hash).slice(1) : type;
    setCurrentTab(tab);
  }, [location.hash]);

  useEffect(() => {
    if (currentTab === type && (editId || createId)) {
      dispatch(getGroupDetailsData(createId || editId));
    }
    if (
      (currentTab === "Pre-Extension" && preExtId) ||
      (currentTab === "Post-Extension" && postExtId)
    ) {
      dispatch(getGroupDetailsExtensionData(createId || editId));
    }
  }, [currentTab]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(tourFormField).length) {
      form1.setFieldsValue({
        field_event_partner: !!tourFormField?.field_event_partner?.length
          ? tourFormField?.field_event_partner?.map(
              (eventPartner) => eventPartner.id
            )
          : [],
        field_event_name: tourFormField?.name || null,
        field_event_code: tourFormField?.field_event_code || null,
        field_length_of_tour: tourFormField?.field_length_of_tour || null,
        field_tour_operator: tourFormField?.field_tour_operator?.id || null,
        field_tour_itinerary: tourFormField?.field_tour_itinerary?.id || null,
        field_tollfree_extension:
          tourFormField?.field_tollfree_extension || "+1",
        field_tollfree_phone: tourFormField?.field_tollfree_phone || null,
        field_requested_dates: [
          !!tourFormField?.field_event_details?.[0]?.field_requested_start_dates
            ? moment(
                tourFormField?.field_event_details?.[0]
                  ?.field_requested_start_dates,
                "YYYY-MM-DD"
              )
            : null,
          !!tourFormField?.field_event_details?.[0]?.field_requested_end_dates
            ? moment(
                tourFormField?.field_event_details?.[0]
                  ?.field_requested_end_dates,
                "YYYY-MM-DD"
              )
            : null,
        ],
        field_alternative_dates: [
          !!tourFormField?.field_event_details?.[0]
            ?.field_alternative_start_dates
            ? moment(
                tourFormField?.field_event_details?.[0]
                  ?.field_alternative_start_dates,
                "YYYY-MM-DD"
              )
            : null,
          !!tourFormField?.field_event_details?.[0]?.field_alternative_end_dates
            ? moment(
                tourFormField?.field_event_details?.[0]
                  ?.field_alternative_end_dates,
                "YYYY-MM-DD"
              )
            : null,
        ],
        field_are_dates_flexible:
          tourFormField?.field_event_details?.[0]?.field_are_dates_flexible,
        field_event_exclusive:
          tourFormField?.field_event_details?.[0]?.field_event_exclusive,
        field_url_address:
          tourFormField?.field_event_details?.[0]?.field_url_address || null,

        field_group_size_req:
          tourFormField?.field_pricing_option?.[0]?.field_group_size_req,
        field_group_size_pricing:
          tourFormField?.field_pricing_option?.[0]?.field_group_size_pricing,
        field_est_bus_count:
          tourFormField?.field_pricing_option?.[0]?.field_est_bus_count,
        field_est_staff_count:
          tourFormField?.field_pricing_option?.[0]?.field_est_staff_count,
        field_pricing_based_on:
          tourFormField?.field_pricing_option?.[0]?.field_pricing_based_on,
        field_purpose_for_income:
          tourFormField?.field_pricing_option?.[0]?.field_purpose_for_income,
        field_partner_income:
          tourFormField?.field_pricing_option?.[0]?.field_partner_income,
        field_notes: tourFormField?.field_pricing_option?.[0]?.field_notes,
        field_tip_budget:
          tourFormField?.field_pricing_option?.[0]?.field_tip_budget,
        field_misc_items:
          tourFormField?.field_pricing_option?.[0]?.field_misc_items,
        field_one_for_program:
          tourFormField?.field_pricing_option?.[0]?.field_one_for_program?.id,
        field_hotel_options: !!tourFormField?.field_hotel_options?.length
          ? tourFormField?.field_hotel_options?.map((hotelOptions) => ({
              ...hotelOptions,
              field_check_in: hotelOptions?.field_check_in
                ? moment(hotelOptions?.field_check_in, "YYYY-MM-DD")
                : null,
              field_check_out: hotelOptions?.field_check_out
                ? moment(hotelOptions?.field_check_out, "YYYY-MM-DD")
                : null,
              field_destination: hotelOptions?.field_destination?.id || null,
              field_star_level: hotelOptions?.field_star_level?.id || null,
              field_traveler_type:
                hotelOptions?.field_traveler_type?.id || null,
            }))
          : [],

        field_air_options: !!tourFormField?.field_air_options?.length
          ? tourFormField?.field_air_options?.map((airOptions) => ({
              field_air_option_city:
                airOptions?.field_air_option_city?.id || null,
              field_carrier_options: airOptions?.field_carrier_options || null,
              field_notes: airOptions?.field_notes || null,
              field_seats: airOptions?.field_seats || null,
            }))
          : [],
        field_meal_type: tourFormField?.field_meal_type?.id || null,
        field_additional_meal: tourFormField?.field_additional_meal || null,
        field_guide_request: tourFormField?.field_guide_request?.length
          ? tourFormField?.field_guide_request?.map(
              (guideRequest) => guideRequest?.id
            )
          : [],
        field_travel_coordinators:
          tourFormField?.field_travel_coordinators || null,
        field_free_vip_vehicle_driver:
          tourFormField?.field_free_vip_vehicle_driver || null,
        field_additional_vip_vehicle_driver:
          tourFormField?.field_additional_vip_vehicle_driver || null,
        field_senior_sound_tech: tourFormField?.field_senior_sound_tech || null,
        field_sound_techs: tourFormField?.field_sound_techs || null,
        field_sound_assistant: tourFormField?.field_sound_assistant || null,
        field_sound_tech_van: tourFormField?.field_sound_tech_van,
        field_luggage_truck: tourFormField?.field_luggage_truck,
        field_staffing_vehicle_notes:
          tourFormField?.field_staffing_vehicle_notes || null,
        field_meeting_space: tourFormField?.field_meeting_space || null,
        field_event_partner_flight_allowance:
          tourFormField?.field_event_partner_flight_allowance || null,
        field_touring_the_day_they_arrive:
          tourFormField?.field_touring_the_day_they_arrive,
        field_extended_sea_of_galilee_boat_ride:
          tourFormField?.field_extended_sea_of_galilee_boat_ride,
        field_use_gai_beach_stage: tourFormField?.field_use_gai_beach_stage,
        field_southern_steps_event: tourFormField?.field_southern_steps_event,
        field_southern_steps_stage: tourFormField?.field_southern_steps_stage,
        field_elec_eng_southern_steps:
          tourFormField?.field_elec_eng_southern_steps,
        field_private_garden_tomb_event:
          tourFormField?.field_private_garden_tomb_event,
        field_private_dining: tourFormField?.field_private_dining,
        field_dinner_venue: tourFormField?.field_dinner_venue || null,
        field_chairs_for_events: tourFormField?.field_chairs_for_events || null,
        field_portable_toilets: tourFormField?.field_portable_toilets || null,
        field_christian_travel_guide:
          tourFormField?.field_christian_travel_guide,
        field_price_in_visa: tourFormField?.field_price_in_visa || null,
        field_baptism_location: tourFormField?.field_baptism_location || null,
        field_leader_performing_baptism:
          tourFormField?.field_leader_performing_baptism || null,
        field_non_married_roommate_policy:
          tourFormField?.field_non_married_roommate_policy?.id || null,
        field_optional_post_departure_ins_included:
          tourFormField?.field_optional_post_departure_ins_included,
        field_username: tourFormField?.field_username || null,
        field_note: tourFormField?.field_note || null,
      });
    }
  }, [tourFormField]);

  useEffect(() => {
    if (
      currentTab === "Pre-Extension" &&
      preExtId &&
      !!Object.keys(extFormField).length
    ) {
      // console.log({ extFormField });
      let preExtFormData = !!extFormField?.length
        ? extFormField.find((extform) => extform.field_type === "pre")
        : null;
      extFormValueSetter(form2, preExtFormData);
    }
    if (
      currentTab === "Post-Extension" &&
      postExtId &&
      !!Object.keys(extFormField).length
    ) {
      // console.log({ extFormField });
      let postExtFormData = !!extFormField?.length
        ? extFormField.find((extform) => extform.field_type === "post")
        : null;
      extFormValueSetter(form3, postExtFormData);
    }
  }, [extFormField]);

  useEffect(() => {
    if (groupDetailsDataRef.current) {
      if (groupDetailsDataResponse.status) {
        setTourFormField(groupDetailsDataResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setTourFormField({});
      }
    }
    groupDetailsDataRef.current = true;
  }, [groupDetailsDataResponse]);

  useEffect(() => {
    if (extensionDataRef.current) {
      if (groupDetailsExtensionDataResponse.status) {
        setExtFormField(groupDetailsExtensionDataResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setExtFormField({});
      }
    }
    extensionDataRef.current = true;
  }, [groupDetailsExtensionDataResponse]);

  useEffect(() => {
    if (groupDetailsCreationRef.current) {
      if (groupDetailsCreationResponse.status) {
        let identifyForm = editId || createId ? "editForm" : "creationForm";
        let route = createRouteToNavigate(
          editId,
          groupDetailsCreationResponse?.data?.data?.id || createId,
          preExtId,
          postExtId,
          currentTab
        );
        let detailsRoute = routeToDetailsPage(
          groupDetailsCreationResponse?.data?.data?.id || createId || editId,
          preExtId,
          postExtId
        );
        if (identifyForm === "creationForm" || createId) {
          message.success(`${type} Created Successfully`);
          mainTab ? navigate(detailsRoute) : navigate(`${route}#Pre-Extension`);
        } else {
          if (identifyForm === "editForm") {
            message.success(`${type} Updated Successfully`);
            mainTab
              ? navigate(detailsRoute)
              : navigate(`${route}#Pre-Extension`);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(changeTabState());
      }
      if (!groupDetailsCreationResponse?.status) {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    groupDetailsCreationRef.current = true;
  }, [groupDetailsCreationResponse]);

  useEffect(() => {
    if (extensionCreationRef.current) {
      if (groupDetailsExtensionCreationResponse.status) {
        if (currentTab === "Pre-Extension") {
          const route = createRouteToNavigate(
            editId,
            createId,
            groupDetailsExtensionCreationResponse?.data?.data?.id || preExtId,
            postExtId,
            currentTab
          );
          const detailsRoute = routeToDetailsPage(
            createId || editId,
            groupDetailsExtensionCreationResponse?.data?.data?.id || preExtId,
            postExtId
          );
          if (preExtId) {
            message.success("Pre-Extension Updated Successfully");
            preTab
              ? navigate(detailsRoute)
              : navigate(`${route}#Post-Extension`);
          } else {
            message.success("Pre-Extension Created Successfully");
            preTab
              ? navigate(detailsRoute)
              : navigate(`${route}#Post-Extension`);
          }
        } else {
          if (currentTab === "Post-Extension") {
            const detailsRoute = routeToDetailsPage(
              createId || editId,
              preExtId,
              groupDetailsExtensionCreationResponse?.data?.data?.id || postExtId
            );
            postExtId
              ? message.success("Post-Extension Updated Successfully")
              : message.success("Post-Extension Created Successfully");
            navigate(detailsRoute);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
      }
      if (!groupDetailsExtensionCreationResponse.status) {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    extensionCreationRef.current = true;
  }, [groupDetailsExtensionCreationResponse]);

  const extFormValueSetter = (extform, extFormData) => {
    extform.setFieldsValue({
      field_requested_dates: [
        !!extFormData?.field_requested_start_date
          ? moment(extFormData?.field_requested_start_date, "YYYY-MM-DD")
          : null,
        !!extFormData?.field_requested_end_date
          ? moment(extFormData?.field_requested_end_date, "YYYY-MM-DD")
          : null,
      ],
      field_extension_destination:
        extFormData?.field_extension_destination?.id || null,
      field_tour_operator: extFormData?.field_tour_operator?.id || null,
      field_extension_itinerary:
        extFormData?.field_extension_itinerary?.id || null,
      field_are_dates_flexible: extFormData?.field_are_dates_flexible,
      field_length_of_extension: extFormData?.field_length_of_extension || null,
      field_hotel_options: !!extFormData?.field_hotel_options?.length
        ? extFormData?.field_hotel_options?.map((hotelOptions) => ({
            ...hotelOptions,
            field_check_in: hotelOptions?.field_check_in
              ? moment(hotelOptions?.field_check_in, "YYYY-MM-DD")
              : null,
            field_check_out: hotelOptions?.field_check_out
              ? moment(hotelOptions?.field_check_out, "YYYY-MM-DD")
              : null,
            field_destination: hotelOptions?.field_destination?.id || null,
            field_star_level: hotelOptions?.field_star_level?.id || null,
            field_traveler_type: hotelOptions?.field_traveler_type?.id || null,
          }))
        : [],
      field_meal_type: extFormData?.field_meal_type?.id || null,
      field_additional_meal: extFormData?.field_additional_meal || null,
      field_group_size_req:
        extFormData?.field_pricing_option?.field_group_size_req || null,
      field_group_size_pricing:
        extFormData?.field_pricing_option?.field_group_size_pricing || null,
      field_est_bus_count:
        extFormData?.field_pricing_option?.field_est_bus_count || null,
      field_est_staff_count:
        extFormData?.field_pricing_option?.field_est_staff_count || null,
      field_pricing_based_on:
        extFormData?.field_pricing_option?.field_pricing_based_on || null,
      field_purpose_for_income:
        extFormData?.field_pricing_option?.field_purpose_for_income || null,
      field_partner_income:
        extFormData?.field_pricing_option?.field_partner_income || null,
      field_notes: extFormData?.field_pricing_option?.field_notes || null,
      field_tip_budget:
        extFormData?.field_pricing_option?.field_tip_budget || null,
      field_misc_items:
        extFormData?.field_pricing_option?.field_misc_items || null,
      field_one_for_program:
        extFormData?.field_pricing_option?.field_one_for_program?.id || null,
      field_travel_coordinators: extFormData?.field_travel_coordinators || null,
      field_senior_sound_tech: extFormData?.field_senior_sound_tech || null,
      field_sound_techs: extFormData?.field_sound_techs || null,
      field_sound_assistant: extFormData?.field_sound_assistant || null,
      field_sound_tech_van: extFormData?.field_sound_tech_van,
      field_luggage_truck: extFormData?.field_luggage_truck,
      field_staffing_vehicle_notes:
        extFormData?.field_staffing_vehicle_notes || null,
      field_air_options: !!extFormData?.field_air_options?.length
        ? extFormData?.field_air_options?.map((airOption) => ({
            field_air_option_city: airOption?.field_air_option_city?.id || null,
            field_seats: airOption?.field_seats,
          }))
        : [],
      field_meeting_space: extFormData?.field_meeting_space || null,
      field_touring_the_day_they_arrive:
        extFormData?.field_touring_the_day_they_arrive || null,
      field_miscellaneous_notes: extFormData?.field_miscellaneous_notes || null,
      field_leader_attending_ext:
        extFormData?.field_leader_attending_ext || null,
      field_note: extFormData?.field_note || null,
    });
  };

  const createOrEditExtension = (allvalues, extension, eventId) => {
    let reqMethod = "";
    if (extension === "Pre-Extension") {
      reqMethod = preExtId ? "patch" : "post";
    }
    if (extension === "Post-Extension") {
      reqMethod = postExtId ? "patch" : "post";
    }
    let payload = {
      data: {
        type: "extensions--extensions",
        attributes: {
          name: "ext",
          field_type: extension === "Pre-Extension" ? "pre" : "post",
          field_requested_start_date: !!allvalues?.field_requested_dates?.[0]
            ? allvalues?.field_requested_dates?.[0]?.format("YYYY-MM-DD")
            : null,
          field_requested_end_date: !!allvalues?.field_requested_dates?.[1]
            ? allvalues?.field_requested_dates?.[1]?.format("YYYY-MM-DD")
            : null,
          field_are_dates_flexible: allvalues?.field_are_dates_flexible,
          field_length_of_extension: allvalues?.field_length_of_extension,
          field_hotel_options: allvalues?.field_hotel_options.map(
            (hotelOptions) => ({
              ...hotelOptions,
              field_check_in: !!hotelOptions?.field_check_in
                ? hotelOptions?.field_check_in?.format("YYYY-MM-DD")
                : null,
              field_check_out: !!hotelOptions?.field_check_out
                ? hotelOptions?.field_check_out?.format("YYYY-MM-DD")
                : null,
            })
          ),
          field_pricing_option: {
            field_group_size_req: allvalues?.field_group_size_req || null,
            field_group_size_pricing:
              allvalues?.field_group_size_pricing || null,
            field_est_bus_count: allvalues?.field_est_bus_count || null,
            field_est_staff_count: allvalues?.field_est_staff_count || null,
            field_pricing_based_on: allvalues?.field_pricing_based_on || null,
            field_purpose_for_income:
              allvalues?.field_purpose_for_income || null,
            field_partner_income: allvalues?.field_partner_income || null,
            field_notes: allvalues?.field_notes || null,
            field_tip_budget: allvalues?.field_tip_budget || null,
            field_misc_items: allvalues?.field_misc_items || null,
            field_one_for_program: allvalues?.field_one_for_program || null,
          },
          field_additional_meal: allvalues?.field_additional_meal || null,
          field_travel_coordinators:
            allvalues?.field_travel_coordinators || null,
          field_senior_sound_tech: allvalues?.field_senior_sound_tech || null,
          field_sound_techs: allvalues?.field_sound_techs || null,
          field_sound_assistant: allvalues?.field_sound_assistant || null,
          field_sound_tech_van: allvalues?.field_sound_tech_van,
          field_luggage_truck: allvalues?.field_luggage_truck,
          field_staffing_vehicle_notes:
            allvalues?.field_staffing_vehicle_notes || null,
          field_air_options: allvalues?.field_air_options,
          field_meeting_space: allvalues?.field_meeting_space || null,
          field_touring_the_day_they_arrive:
            allvalues?.field_touring_the_day_they_arrive,
          field_leader_attending_ext:
            allvalues?.field_leader_attending_ext || null,
          field_free_vip_vehicle_driver:
            allvalues?.field_free_vip_vehicle_driver || null,
          field_additional_vip_vehicle_driver:
            allvalues?.field_additional_vip_vehicle_driver || null,
          field_miscellaneous_notes:
            allvalues?.field_miscellaneous_notes || null,
          field_note: allvalues?.field_note || null,
        },
        relationships: {
          field_extension_destination: allvalues?.field_extension_destination
            ? {
                data: {
                  type: "taxonomy_term--destination",
                  id: allvalues?.field_extension_destination,
                },
              }
            : null,
          field_tour_operator: allvalues?.field_tour_operator
            ? {
                data: {
                  type: "node--organization",
                  id: allvalues?.field_tour_operator,
                },
              }
            : null,
          field_extension_itinerary: allvalues?.field_extension_itinerary
            ? {
                data: {
                  type: "taxonomy_term--extension_itinerary",
                  id: allvalues?.field_extension_itinerary,
                },
              }
            : null,
          field_meal_type: !!allvalues?.field_meal_type
            ? {
                data: {
                  type: "taxonomy_term--meal_type",
                  id: allvalues?.field_meal_type,
                },
              }
            : null,
          field_guide_request: !!allvalues?.field_guide_request?.length
            ? {
                data: allvalues?.field_guide_request?.map((guideRequest) => ({
                  type: "taxonomy_term--guide_request",
                  id: guideRequest,
                })),
              }
            : null,
          field_event: {
            data: {
              type: "event--event",
              id: eventId,
            },
          },
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (reqMethod === "patch") {
      payload.data.id = extension === "Pre-Extension" ? preExtId : postExtId;
    }
    const extensionId = extension === "Pre-Extension" ? preExtId : postExtId;

    dispatch(createGroupDetailsExtension({ reqMethod, extensionId, payload }));
  };

  const onFinishHandler = (allvalues) => {
    // console.log({ allvalues });
    if (currentTab === type) {
      const reqMethod = createId || editId ? "patch" : "post";
      let payload = {
        data: {
          type: "event--event",
          attributes: {
            name: allvalues?.field_event_name || null,
            field_type: type,
            field_status: "lock",
            field_direct_event: false,
            field_group_detail_status:
              reqMethod === "post"
                ? "in_progress"
                : tourFormField?.field_group_detail_status?.key,
            field_time_in_cart: 0,
            field_event_code: allvalues?.field_event_code || null,
            field_length_of_tour: allvalues?.field_length_of_tour || null,
            field_tollfree_extension: allvalues?.field_tollfree_phone
              ? allvalues?.field_tollfree_extension || null
              : null,
            field_tollfree_phone: allvalues?.field_tollfree_phone || null,
            field_event_details: [
              {
                field_requested_start_dates: !!allvalues
                  ?.field_requested_dates?.[0]
                  ? allvalues?.field_requested_dates?.[0]?.format("YYYY-MM-DD")
                  : null,
                field_requested_end_dates: !!allvalues
                  ?.field_requested_dates?.[1]
                  ? allvalues?.field_requested_dates?.[1]?.format("YYYY-MM-DD")
                  : null,
                field_alternative_start_dates: !!allvalues
                  ?.field_alternative_dates?.[0]
                  ? allvalues?.field_alternative_dates?.[0]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_alternative_end_dates: !!allvalues
                  ?.field_alternative_dates?.[1]
                  ? allvalues?.field_alternative_dates?.[1]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_are_dates_flexible: allvalues?.field_are_dates_flexible,
                field_event_exclusive: allvalues?.field_event_exclusive,
                field_url_address: allvalues?.field_url_address || null,
              },
            ],

            field_pricing_option: [
              {
                field_group_size_req: allvalues?.field_group_size_req || null,
                field_group_size_pricing:
                  allvalues?.field_group_size_pricing || null,
                field_est_bus_count: allvalues?.field_est_bus_count || null,
                field_est_staff_count: allvalues?.field_est_staff_count || null,
                field_pricing_based_on:
                  allvalues?.field_pricing_based_on || null,
                field_purpose_for_income:
                  allvalues?.field_purpose_for_income || null,
                field_partner_income: allvalues?.field_partner_income || null,
                field_notes: allvalues?.field_notes || null,
                field_tip_budget: allvalues?.field_tip_budget || null,
                field_misc_items: allvalues?.field_misc_items || null,
                field_one_for_program: allvalues?.field_one_for_program || null,
              },
            ],
            field_hotel_options: allvalues?.field_hotel_options.map(
              (hotelOptions) => ({
                ...hotelOptions,
                field_check_in: !!hotelOptions?.field_check_in
                  ? hotelOptions?.field_check_in?.format("YYYY-MM-DD")
                  : null,
                field_check_out: !!hotelOptions?.field_check_out
                  ? hotelOptions?.field_check_out?.format("YYYY-MM-DD")
                  : null,
              })
            ),
            field_air_options: allvalues?.field_air_options,
            field_additional_meal: allvalues?.field_additional_meal || null,
            field_travel_coordinators:
              allvalues?.field_travel_coordinators || null,
            field_free_vip_vehicle_driver:
              allvalues?.field_free_vip_vehicle_driver || null,
            field_additional_vip_vehicle_driver:
              allvalues?.field_additional_vip_vehicle_driver || null,
            field_senior_sound_tech: allvalues?.field_senior_sound_tech || null,
            field_sound_techs: allvalues?.field_sound_techs || null,
            field_sound_assistant: allvalues?.field_sound_assistant || null,
            field_sound_tech_van: allvalues?.field_sound_tech_van,
            field_luggage_truck: allvalues?.field_luggage_truck,
            field_staffing_vehicle_notes:
              allvalues?.field_staffing_vehicle_notes || null,
            field_meeting_space: allvalues?.field_meeting_space || null,
            field_touring_the_day_they_arrive:
              allvalues?.field_touring_the_day_they_arrive,
            field_extended_sea_of_galilee_boat_ride:
              allvalues?.field_extended_sea_of_galilee_boat_ride,
            field_use_gai_beach_stage: allvalues?.field_use_gai_beach_stage,
            field_southern_steps_event: allvalues?.field_southern_steps_event,
            field_southern_steps_stage: allvalues?.field_southern_steps_stage,
            field_elec_eng_southern_steps:
              allvalues?.field_elec_eng_southern_steps,
            field_private_garden_tomb_event:
              allvalues?.field_private_garden_tomb_event,
            field_private_dining: allvalues?.field_private_dining,
            field_dinner_venue: allvalues?.field_dinner_venue || null,
            field_chairs_for_events: allvalues?.field_chairs_for_events || null,
            field_portable_toilets: allvalues?.field_portable_toilets || null,
            field_christian_travel_guide:
              allvalues?.field_christian_travel_guide,
            field_price_in_visa: allvalues?.field_price_in_visa || null,
            field_baptism_location: allvalues?.field_baptism_location || null,
            field_leader_performing_baptism:
              allvalues?.field_leader_performing_baptism || null,
            field_note: allvalues?.field_note || null,
            field_username: allvalues?.field_username || null,
            field_optional_post_departure_ins_included:
              allvalues?.field_optional_post_departure_ins_included,
            field_event_partner_flight_allowance:
              allvalues?.field_event_partner_flight_allowance || null,
            status: 1,
          },
          relationships: {
            field_guide_request: !!allvalues?.field_guide_request?.length
              ? {
                  data: allvalues?.field_guide_request?.map((guideRequest) => ({
                    type: "taxonomy_term--guide_request",
                    id: guideRequest,
                  })),
                }
              : null,
            field_meal_type: !!allvalues?.field_meal_type
              ? {
                  data: {
                    type: "taxonomy_term--meal_type",
                    id: allvalues?.field_meal_type,
                  },
                }
              : null,
            field_tour_itinerary: allvalues?.field_tour_itinerary
              ? {
                  data: {
                    type: "taxonomy_term--tour_itinerary",
                    id: allvalues?.field_tour_itinerary,
                  },
                }
              : null,
            field_tour_operator: allvalues?.field_tour_operator
              ? {
                  data: {
                    type: "node--organization",
                    id: allvalues?.field_tour_operator,
                  },
                }
              : null,
            field_event_partner: !!allvalues?.field_event_partner?.length
              ? {
                  data: allvalues?.field_event_partner?.map(
                    (eventPartnerId) => ({
                      type: "node--organization",
                      id: eventPartnerId,
                    })
                  ),
                }
              : null,
            field_non_married_roommate_policy:
              !!allvalues?.field_non_married_roommate_policy
                ? {
                    data: {
                      type: "taxonomy_term--non_married_roommate_policy",
                      id: allvalues?.field_non_married_roommate_policy,
                    },
                  }
                : null,
            modified_by: {
              data: {
                type: "user--user",
                id: userUuid,
              },
            },
          },
        },
      };
      const eventId = reqMethod === "patch" ? createId || editId : null;
      if (reqMethod === "patch") {
        payload.data.id = createId || editId;
      }
      dispatch(createGroupDetails({ reqMethod, eventId, payload }));
    }
    if (currentTab === "Pre-Extension" || currentTab === "Post-Extension") {
      createOrEditExtension(allvalues, currentTab, createId || editId);
    }
  };
  const handleTabChange = (key) => {
    setCurrentTab(key);
    let route = location.pathname;
    if ((editId || createId) && preExtId && postExtId) {
      route = `${route}?${editId ? "editId" : "creationId"}=${
        editId || createId
      }&preExtId=${preExtId}&postExtId=${postExtId}#${key}`;
    } else {
      if ((editId || createId) && (preExtId || postExtId)) {
        route = `${route}?${editId ? "editId" : "creationId"}=${
          editId || createId
        }&${preExtId ? "preExtId" : "postExtId"}=${
          preExtId || postExtId
        }#${key}`;
      } else {
        if (editId || createId) {
          route = `${route}?${editId ? "editId" : "creationId"}=${
            editId || createId
          }#${key}`;
        } else {
          route = `${route}#${key}`;
        }
      }
    }
    navigate(route);
  };
  const createRouteToNavigate = (eId, cId, preId, postId) => {
    let route = location.pathname;
    if ((eId || cId) && preId && postId) {
      return (route = `${route}?${eId ? "editId" : "creationId"}=${
        eId || cId
      }&preExtId=${preId}&postExtId=${postId}`);
    } else {
      if ((eId || cId) && (preId || postId)) {
        return (route = `${route}?${eId ? "editId" : "creationId"}=${
          eId || cId
        }&${preId ? "preExtId" : "postExtId"}=${preId || postId}`);
      } else {
        if (eId || cId) {
          return (route = `${route}?${eId ? "editId" : "creationId"}=${
            eId || cId
          }`);
        } else {
          return route;
        }
      }
    }
  };

  const routeToDetailsPage = (groupDetailId, preId, postId) => {
    return `${GroupDetails_DetailsRoute}?type=${type}&id=${groupDetailId}${
      preId ? `&preExtId=${preId}` : ""
    }${postId ? `&postExtId=${postId}` : ""}#${type}`;
  };
  return (
    <>
      <Tabs
        activeKey={currentTab}
        className="details-tabs"
        onChange={(e, v) => {
          handleTabChange(e, v);
        }}
      >
        <Tabs.TabPane tab={type} key={type}>
          <div className="basic_details pd-16 bg-white">
            <TourDetailsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
              type={type}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourHotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourAirOptions
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourMealsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourStaffAndVehicles
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourMiscellaneous
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourInitialContactPersonForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourNotes
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <PlusOutlined />
              Add Pre-Extension
            </span>
          }
          key="Pre-Extension"
          disabled={createId || editId ? false : true}
        >
          <div className="basic_details pd-16">
            <TourPrePostDatesAndItineryForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16">
            <TourHotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostMealsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostPricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostStaffAndVehicleForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostAirOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16">
            <TourPrePostMiscellaneousForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourNotes
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <PlusOutlined />
              Add Post-Extension
            </span>
          }
          key="Post-Extension"
          disabled={createId || editId ? false : true}
        >
          <div className="basic_details pd-16">
            <TourPrePostDatesAndItineryForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16">
            <TourHotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostMealsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostPricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostStaffAndVehicleForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourPrePostAirOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16">
            <TourPrePostMiscellaneousForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <TourNotes
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Button
        type="primary"
        style={{ position: "absolute", right: "60px", top: "255px" }}
        loading={
          loadingGroupDetailsCreation || loadingGroupDetailsExtensionCreation
        }
        onClick={() => {
          dispatch(changeTabState());
          if (currentTab === type) {
            form1.submit();
          }
          if (currentTab === "Pre-Extension") {
            form2.submit();
          }
          if (currentTab === "Post-Extension") {
            form3.submit();
          }
        }}
      >
        Save & Add Extension
      </Button>
    </>
  );
};

export default CreateOrEditTourTabsContainer;
