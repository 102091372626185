import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { EventRoute } from "../../../url-routes/urlRoutes";
import { useSelector } from "react-redux";
import { permissionChecker } from "../../../utils/permisssionChecker";

const EventsListingHeader = ({ urlParamsObject }) => {
  const { allEvents } = useSelector((state) => state.events);
  const { allEventsResponse, loadingAllEvents } = allEvents;
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddEvent = authData?.permissions?.includes("add/edit event");

  // function getTotalCount() {
  //   const data = allEventsResponse?.data?.[0] || {};
  //   return urlParamsObject?.order === "recently_read"
  //     ? data?.recently_read?.pager?.total_items ?? 0
  //     : data?.total_count ?? 0;
  //   return data?.total_count ?? 0;
  // }

  function deriveTotalCount() {
    if (allEventsResponse?.status) {
      let data = allEventsResponse?.data?.[0] || {};
      let totalCount =
        urlParamsObject?.order === "recently_read"
          ? data?.recently_viewed_count || data?.recently_read?.rows?.length
          : data?.["all_events"]?.pager?.total_items;
      return totalCount;
    }
    return 0;
  }

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_column">
          Events{" "}
          <span className="small_text">
            {loadingAllEvents ? (
              <Skeleton.Button
                shape="round"
                size="small"
                style={{ width: "60px" }}
              />
            ) : (
              `(${deriveTotalCount() ?? 0})`
            )}
          </span>
        </h3>

        {permissionChecker("add/edit event") ? (
          <div className="breadcrum_buttons">
            <Link to={EventRoute}>
              <Button type="primary" icon={<PlusOutlined />}>
                Add Event
              </Button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventsListingHeader;
