import { Switch, Table } from "antd";
import { Divider } from "antd";
import React from "react";
import DataLoader from "../../../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const InventoryInformation = ({
  inventoryData,
  inventoryLoading,
  inventoryId,
  eventId,
  addonId,
  categoryId,
}) => {
  const {
    field_item_number,
    field_item_name,
    field_item_description,
    field_occupancy,
    field_min_occupancy,
    field_quantity,
    field_deck_number,
    field_category,
    field_share,
    field_max_shares,
  } = inventoryData || {};

  const data = [
    {
      key: "1",
      questions: "Category",
      answers: (
        <>
          <span>{field_category?.name || ""}</span>
        </>
      ),
    },
    {
      key: "2",
      questions: "Deck Number",
      answers: (
        <>
          <span>{field_deck_number?.name || ""}</span>
        </>
      ),
    },
    {
      key: "3",
      questions: "Item Code",
      answers: (
        <>
          <span>{field_item_number || ""}</span>
        </>
      ),
    },
    {
      key: "4",
      questions: "Item Name",
      answers: (
        <>
          <span>{field_item_name || ""}</span>
        </>
      ),
    },
    {
      key: "5",
      questions: "Quantity",
      answers: (
        <>
          {/* <span className="document_pdf">{field_quantity || ""}</span> */}
          <span>{field_quantity || ""}</span>
        </>
      ),
    },
    {
      key: "6",
      questions: "Max Occupancy",
      answers: (
        <>
          <span>{field_occupancy || ""}</span>
        </>
      ),
    },
    {
      key: "7",
      questions: "Min Occupancy",
      answers: (
        <>
          <span>{field_min_occupancy || ""}</span>
        </>
      ),
    },
    {
      key: "8",
      questions: "Share",
      answers: (
        <>
          <Switch disabled checked={field_share || false} />
        </>
      ),
    },
    {
      key: "9",
      questions: "Max Shares",
      answers: (
        <>
          <span>{field_max_shares || ""}</span>
        </>
      ),
    },
    {
      key: "10",
      questions: "Item Description",
      answers: (
        <>
          <span>{field_item_description || ""}</span>
        </>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          Inventory Item Information
        </h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        loading={{ spinning: inventoryLoading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default InventoryInformation;
