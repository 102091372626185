import { Divider, Switch, Table } from "antd";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const AddonFeeInfo = ({ loadingFeeDetailData, feeDetailsData, feeType }) => {
  const data = [
    {
      key: "1",
      questions: "Fee Type",
      answers: feeDetailsData?.field_fee_type?.name || "",
    },
    {
      key: "2",
      questions: "Fee Name",
      answers: feeDetailsData?.name?.name || "",
    },

    {
      key: "3",
      questions: "Fee Percent",
      answers: feeDetailsData?.field_fee_percent
        ? `${
            feeDetailsData?.field_fee_percent.includes(".00")
              ? Math.round(Number(feeDetailsData?.field_fee_percent))
              : feeDetailsData?.field_fee_percent
          }%`
        : "",
    },

    {
      key: "4",
      questions: "Usage Type",
      answers: feeDetailsData?.field_usage_type?.label || "",
    },
    {
      key: "5",
      questions: "Fee Amount",
      answers: feeDetailsData?.field_fee_amount
        ? `$${addCommasInPricing(feeDetailsData?.field_fee_amount)}`
        : "",
    },
    {
      key: "6",
      questions: "Is Optional",
      answers: <Switch disabled checked={feeDetailsData?.field_is_optional} />,
    },
    {
      key: "7",
      questions: "Description",
      answers: feeDetailsData?.field_description,
    },
    {
      key: "8",
      questions: "Commissionable",
      answers: (
        <Switch disabled checked={feeDetailsData?.field_commissionable} />
      ),
    },
    {
      key: "9",
      questions: "Organization Service",
      answers: feeDetailsData?.field_organization_service || "",
    },
    // {
    //   key: "10",
    //   questions: "Payment Method",
    //   answers: feeDetailsData?.field_payment_method?.name || "",
    // },
    {
      key: "11",
      questions: "Minimum Age",
      answers: feeDetailsData?.field_minimum_age
        ? `${feeDetailsData?.field_minimum_age} years`
        : "",
    },
    {
      key: "12",
      questions: "Maximum Age",
      answers: feeDetailsData?.field_maximum_age
        ? `${feeDetailsData?.field_maximum_age} years`
        : "",
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          Fee Information
        </h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
        loading={{ spinning: loadingFeeDetailData, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default AddonFeeInfo;
