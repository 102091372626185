import {
  ArrowLeftOutlined,
  CloudDownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  GlobalOutlined,
  ImportOutlined,
  MoreOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  cloneEventApi,
  deleteEventApi,
  eventSyncApi,
} from "../../../apis/eventsApi";
import downloadConfig from "../../../config/download.config";
import {
  EventDetailsRoute,
  EventRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { permissionChecker } from "../../../utils/permisssionChecker";
import "../../Contacts/ContactDetails/ContactDetailsHeader.less";
import "./EventDetailsHeader.less";
import ImportEvent from "./ImportEvent";

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <a href="#">File</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "Download",
        key: "2",
      },
      {
        type: "divider",
      },
      {
        label: "Import",
        key: "3",
      },
      {
        type: "divider",
      },
      {
        label: "Delete",
        key: "4",
      },
      {
        type: "divider",
      },

      {
        label: "Reports",
        key: "5",
      },
    ]}
  />
);

const EventDetailsHeader = ({ eventData, loadingSingleEvent }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowRunReportEvent =
    authData?.permissions?.includes("run event report");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [form] = Form.useForm();
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const cloneThisEvent = async (payload) => {
    setIsCloneLoading(true);
    const cloneRes = await cloneEventApi(payload);
    if (cloneRes.status) {
      message.success("Event Cloned Successfully");
      form.resetFields();
      handleOk();
      window.location.href = `${EventDetailsRoute}?id=${
        cloneRes?.data?.data?.cloned_event_uuid || eventId
      }&cloneId=${cloneRes?.data?.data?.cloned_event_uuid || eventId}${
        location.hash ? location.hash : ""
      }`;
    } else {
      message.error(
        cloneRes?.data?.response?.data?.message ||
          cloneRes?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
    setIsCloneLoading(false);
  };

  const cloneEventFinishHandler = ({ event_name, event_code }) => {
    const payload = {
      data: {
        event_uuid: eventId,
        event_name,
        event_code,
      },
    };
    cloneThisEvent(payload);
  };

  const deleteEvent = async (deleteId) => {
    const response = await deleteEventApi(deleteId);
    if (response?.status) {
      message.success("Event Deleted Successfully");
      navigate(`/events/listing`);
    } else {
      message.error( response?.data?.response?.data?.errors?.[0]?.detail|| SOMETHING_WENT_WRONG);
    }
  };

  const ondeleteHandler = (deleteId) => {
    if (deleteId) {
      Modal.confirm({
        title: "Are you sure you want to delete this event?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteEvent(deleteId);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const EventSync = async (eventId) => {
    setIsSyncLoading(true);
    const res = await eventSyncApi(eventId);
    if (res.status) {
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsSyncLoading(false);
  };
  const handleImportOk = () => {
    setOpen(false);
    setImportModal(false);
    setErrorList([]);
  };

  const handleImportCancel = () => {
    setImportModal(false);
  };
  return (
    <div className="breadcrumb_content">
      <Modal
        footer={[
          <Button
            type="primary"
            loading={isCloneLoading}
            onClick={(e) => {
              e.preventDefault();
              form.submit();
            }}
          >
            Copy
          </Button>,
        ]}
        title="Copy Event"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="copy_inventory_modal">
          <Form
            name="basic"
            form={form}
            onFinish={cloneEventFinishHandler}
            autoComplete="off"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            <Form.Item
              className="asterick-align"
              label="Event Name"
              name="event_name"
              rules={[
                {
                  required: true,
                  message: "Please enter event name",
                },
              ]}
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="asterick-align"
              label="Event Code"
              name="event_code"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter event code",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Confirmation"
        footer={
          <>
            <Button
              onClick={(e) => {
                setSyncModalOpen(false);
              }}
            >
              No
            </Button>{" "}
            <Button
              type="primary"
              onClick={(e) => {
                EventSync(eventId);
                setSyncModalOpen(false);
              }}
            >
              Yes
            </Button>
          </>
        }
        onCancel={() => {
          setSyncModalOpen(false);
        }}
        open={syncModalOpen}
      >
        <p>Would you like to sync this event?</p>
      </Modal>
      <Modal
        title="Confirmation"
        onOk={handleImportOk}
        onCancel={handleImportCancel}
        open={importModal}
      >
        <p>Are you sure you want to discard?</p>
      </Modal>
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => navigate(EventsListingRoute)}
          />
          {loadingSingleEvent ? (
            <>
              <Skeleton.Input active={true} style={{ width: "250px" }} />
            </>
          ) : (
            <>
              <GlobalOutlined
                style={{ fontSize: "20px", marginRight: "12Px" }}
              />
              <div className="header_res">
                <p>{eventData?.name}</p>
                <p className="header_smalltext">
                  {eventData?.field_event_code}
                </p>
              </div>
            </>
          )}
        </h3>
        {!loadingSingleEvent && (
          <div className="breadcrumb_buttons">
            {
             permissionChecker("sync event")  ? <Tooltip placement="top" title="Sync">
                <Button
                  icon={<SyncOutlined />}
                  loading={isSyncLoading}
                  onClick={() => {
                    setSyncModalOpen(true);
                  }}
                />
              </Tooltip> : null
            }
            {permissionChecker("add/edit event") ? (
              <Tooltip placement="top" title="Edit">
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    navigate(`${EventRoute}?editId=${eventId}&step=1`);
                  }}
                />
              </Tooltip>
            ) : null}

            {permissionChecker("copy event") ? <Tooltip placement="top" title="Copy">
              <Button
                loading={isCloneLoading}
                icon={<CopyOutlined />}
                onClick={() => {
                  showModal();
                }}
              />
            </Tooltip> : null}

            {permissionChecker("export event") ? <Tooltip placement="top" title="Export">
              <Link
                to={`${downloadConfig.BASE_URL}/export/event/data/${eventData?.drupal_internal__id}`}
                target="_blank"
                download
              >
                <Button
                  loading={exportLoader}
                  icon={<CloudDownloadOutlined />}
                />
              </Link>
            </Tooltip> : null}

            {permissionChecker("import event") ? <Tooltip placement="top" title="Import">
              {/* <>hello</> */}
              <div>
                <ImportEvent
                  open={open}
                  setOpen={setOpen}
                  setIsModalOpen={setImportModal}
                  setErrorList={setErrorList}
                  errorList={errorList}
                />
              </div>
            </Tooltip> : null}

            {permissionChecker("delete event") ? (
              <Tooltip placement="top" title="Delete">
                <Button
                  className="delete_btn"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    ondeleteHandler(eventId);
                  }}
                />
              </Tooltip>
            ) : null}

            {/* {allowRunReportEvent ? (
            <Button>
              <PaperClipOutlined /> Reports
            </Button>
          ) : null} */}
          </div>
        )}
        <div className="breadcrumb_toggle">
          <Dropdown overlay={menu} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button type="y" icon={<MoreOutlined />} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsHeader;
