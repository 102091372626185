import React, { useEffect, useState } from "react";
import { Table, Checkbox } from "antd";
import "../../Admin.less";

//This is the Duplicate JSON file of all the list of permissions saved in BE in YML File.
import AllPermissions from "./AllPermission.json"
const PermissionsList = ({ permissions, setPermissions, permissionList, page ,viewMode}) => {
    const [allCheck, setAllCheck] = useState(false);
    const [data, setData] = useState([]);
    const onChangeHandler = (e, field) => {
        let temp = permissions;
        if (e.target.checked) {
            setAllCheck(e.target.checked)


            permissionList.forEach(value => {
                if (!temp.indexOf(value) !== -1) {
                    temp.push(value)
                }
            });
            setPermissions(temp)
        } else {
            permissionList.forEach(value => {
                if (temp.indexOf(value) !== -1) {
                    const updatedPermissions = temp.filter(item => item !== value);
                    temp = updatedPermissions;
                }
            });
            setPermissions(temp)
            setAllCheck(e.target.checked)

        }

    };

    useEffect(() => {
        if (permissionList && permissions) {
            let allSelected = 0;
            let allData = {
                key: 1,
                [page]: (
                    <>
                        <span className="document_pdf">{page}</span>
                    </>
                ),
                checkbox: (
                    <>
                        <Checkbox
                            onChange={(e) => {
                                onChangeHandler(e);
                            }}
                            checked={allCheck}
                        ></Checkbox>
                    </>
                ),
                children: []
            };
            permissionList.forEach((value, index) => {
                if (permissions.indexOf(value) !== -1) {
                    allSelected = allSelected + 1
                }
                const element = {
                    key: `1${index}`,
                    checkbox: (
                        <>
                            <Checkbox
                                onChange={(e) => {
                                    if (permissions.indexOf(value) !== -1) {
                                        const updatedPermissions = permissions.filter(item => item !== value);
                                        setPermissions(updatedPermissions)
                                    } else {
                                        setPermissions((preVal) => [...preVal, value]);
                                    }

                                }}
                                checked={permissions.indexOf(value) !== -1}
                            ></Checkbox>
                        </>
                    ),
                }

                if(viewMode){
                   if( permissions.includes(value)){
                    element[page] = AllPermissions[value].title
                   }
                }else{
                    element[page] =AllPermissions[value].title
                }
                if(element[page]){
                allData.children.push(element)
                }
                if (allSelected === permissionList.length) {
                    setAllCheck(true);
                } else {
                    setAllCheck(false);
                }

            })
            setData([allData])
        }

    }, [permissions, permissionList, allCheck]);

    const columns = [
        {
            title: page,
            dataIndex: page,
            key: 1,
        },
    ];
    if(!viewMode){
        columns.push( {
            title: "Checkbox",
            dataIndex: "checkbox",
            key: 2,
        })
    }

    return (
        <div>
            <Table
                className="plus_change admin_table reservation_main-table arrowicon_change"
                defaultExpandAllRows={true}
                showHeader={false}
                columns={columns}
                dataSource={data}
                size="middle"
                pagination={false}
            />
        </div>
    );
};

export default PermissionsList;
