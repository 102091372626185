import { Button, Modal } from "antd";
import { useState } from "react";
const FourLocationModal = ({ locations_arr, dropdownName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (locations_arr?.length !== 0) {
      setIsModalOpen(true);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <span className="four_location" type="primary" onClick={showModal}>
        {locations_arr?.length} Locations
      </span>
      <Modal
        footer={false}
        title={dropdownName}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="button_wrap">
          {locations_arr?.map((loc) => {
            return (
              <Button
                className="primary_color"
                type=""
                size="small"
                style={{
                  fontSize: "12px",
                  background: "#E6F7FF",
                  lineHeight: "20px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  fontFamily: "Poppins",
                  color: "#0099E5",
                }}
              >
                {loc}
              </Button>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
export default FourLocationModal;
