import { Form, Select, Switch, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addonStepSetter,
  createOrEditAddon,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import { EventDetailsAddonsDetailsRoute } from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddonsSystemInfoForm = ({
  form2,
  createId,
  editId,
  eventId,
  next,
  addonFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addonCreation } = useSelector((state) => state.addons);
  const { addonCreationResponse, step2, addonStep } = addonCreation;
  const addonCreationRef = useRef(false);
  const statusOptions = [
    { value: "design", label: "Design" },
    { value: "lock", label: "Lock" },
    { value: "production", label: "Production" },
    { value: "complete", label: "Complete" },
    { value: "cancel", label: "Canceled" },
    { value: "archive", label: "Archived" },
    { value: "unarchive", label: "Unarchive" },
  ];

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(addonFormFields).length) {
      dispatch(addonStepSetter(addonFormFields?.field_step));
      form2.setFieldsValue({
        ...addonFormFields,
        field_status: addonFormFields?.field_status?.key || "design",
      });
    }
  }, [addonFormFields]);

  useEffect(() => {
    if (addonCreationRef.current) {
      if (addonCreationResponse.status) {
        dispatch(
          addonStepSetter(addonCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          step2
            ? navigate(
                `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${createId}`
              )
            : next({ type: "creation", id: eventId, addonId: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            step2
              ? navigate(
                  `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${editId}`
                )
              : next({ type: "edit", id: eventId, addonId: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateAddonStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    addonCreationRef.current = true;
  }, [addonCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_status: values?.field_status.key,
      field_web_enabled: values?.field_web_enabled,
      field_web_hide_item_code: values?.field_web_hide_item_code,
      field_web_brochure: values?.field_web_brochure,
      // field_allow_no_primary_item: values?.field_allow_no_primary_item,
      field_step: currentStep > addonStep ? +currentStep : addonStep,
    };
    const relationships = {
      field_event: {
        data: {
          type: "event--event",
          id: eventId,
        },
      },
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditAddon(payload));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form2}
          onFinish={onFinishHandler}
        >
          <Form.Item label="Status" name="field_status">
            <Select
              options={statusOptions.map((status) => ({
                key: status?.value,
                value: status?.value,
                label: status?.label,
              }))}
              disabled={true}
              showArrow={false}
            />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Web Enabled"
            name="field_web_enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Web Hide Item Code"
            name="field_web_hide_item_code"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Web Brochure"
            name="field_web_brochure"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {/* <Form.Item
            className="swith_form"
            label="Allow No Primary Item"
            name="field_allow_no_primary_item"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item> */}
        </Form>
      </div>
    </div>
  );
};

export default AddonsSystemInfoForm;
