import { Form, Grid, Layout } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import CreateOrganizationHeader from "../../components/Organizations/CreateOrganisation/CreateOrganizationHeader";
import CreateOrganizationSteps from "../../components/Organizations/CreateOrganisation/CreateOrganizationSteps";
import "./CreateOrganizationPage.less";
const { Header, Content, Sider } = Layout;

function OrganizationCreatePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const editId = searchParams.get("editId");
  const createId = searchParams.get("creationId");
  const copyId = searchParams.get("copyId");

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const breadCrumbs = [
    { title: "Organizations", path: "/organization/listing" },
    { title: editId ? "Edit Organization" : "Add Organization", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrganizationHeader
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
            />
          </div>
          <Content>
            <CreateOrganizationSteps
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
              copyId={copyId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default OrganizationCreatePage;
