import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { EventDetailsRoute } from "../../../../url-routes/urlRoutes";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function Reservationproduct_information(props) {
  const { data } = props;
  const [eventData, setEventData] = useState();

  useEffect(() => {
    if (data) {
      setEventData([
        {
          key: "1",
          label: "Event Name",
          input: (
            <>
              <Link to={`${EventDetailsRoute}?id=${data.uuid}`}>
                {data?.name}
              </Link>
            </>
          ),
        },
        {
          key: "2",
          label: "Start Date",
          input: data?.start_date ? (
            <>
              <span className="document_pdf">
                {data.start_date && moment(data?.start_date).format("L")}
              </span>
              <span className="span_text">
                {" "}
                {moment(data?.start_date).fromNow()}
              </span>
            </>
          ) : null,
        },
        {
          key: "3",
          label: "End Date",
          input: data?.end_date ? (
            <>
              <span className="document_pdf">
                {data.end_date && moment(data?.end_date).format("L")}{" "}
              </span>
              <span className="span_text">
                {" "}
                {moment(data?.end_date).fromNow()}
              </span>
            </>
          ) : null,
        },
      ]);
    }
  }, [data]);
  return (
    <div>
      <div className="partnership_inform">
        <h4>Event Information</h4>
        {/* <span><Link to=""> <Button 
          type="y"
          icon={<EditOutlined />}
        /></Link></span> */}
      </div>
      <Divider className="global_divider" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={eventData}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Reservationproduct_information;
