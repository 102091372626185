import { Form, Input, Select, Switch, TimePicker, message } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createOrEditEvent,
  eventStepSetter,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { EventDetailsRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function pad(num) {
  num = num.toString();
  while (num.length < 2) num = "0" + num;
  return num;
}
function convertSecondsToMinutesAndSeconds(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;
  return pad(minutes) + ":" + pad(remainingSeconds);
}

function toSec(time) {
  const referenceTime = moment().startOf("day");
  const date1 = referenceTime.unix();
  const date2 = time.unix();
  const sec = Math.abs(date2 - date1);
  return sec;
}
const SystemOptionsForm = ({
  form2,
  createId,
  editId,
  next,
  eventFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusOptions = [
    { value: "design", label: "Design" },
    { value: "lock", label: "Lock" },
    { value: "production", label: "Production" },
    { value: "complete", label: "Complete" },
    { value: "cancel", label: "Canceled" },
    { value: "archive", label: "Archived" },
    { value: "unarchive", label: "Unarchive" },
  ];

  const { eventCreation } = useSelector((state) => state.events);
  const { eventCreationResponse, step2, eventStep } = eventCreation;
  const eventCreationRef = useRef(false);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(eventFormFields).length) {
      dispatch(eventStepSetter(eventFormFields?.field_step));
      form2.setFieldsValue({
        ...eventFormFields,
        field_time_in_cart: !!eventFormFields?.field_time_in_cart
          ? moment(
              convertSecondsToMinutesAndSeconds(
                eventFormFields?.field_time_in_cart
              ),
              "mm:ss"
            )
          : null,
        field_status: eventFormFields?.field_status?.key || "design",
      });
    }
  }, [eventFormFields]);

  useEffect(() => {
    if (eventCreationRef.current) {
      if (eventCreationResponse.status) {
        dispatch(
          eventStepSetter(eventCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          step2
            ? navigate(`${EventDetailsRoute}?id=${createId}`)
            : next({ type: "creation", id: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            step2
              ? navigate(`${EventDetailsRoute}?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateEventStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    eventCreationRef.current = true;
  }, [eventCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_status: values?.field_status?.key,
      field_time_in_cart: Number(values.field_time_in_cart)
        ? toSec(values.field_time_in_cart)
        : 0,
      field_max_capacity: values.field_max_capacity,
      field_max_required_adult_age: values.field_max_required_adult_age,
      field_minimum_age: values.field_minimum_age,
      field_web_enabled: values.field_web_enabled,
      field_web_hide_item_code: values.field_web_hide_item_code,
      field_web_brochure: values.field_web_brochure,
      field_report_flag: values.field_report_flag,
      field_allow_no_primary_item: values.field_allow_no_primary_item,
      field_step: currentStep > eventStep ? +currentStep : eventStep,
      field_req_flight_info: values.field_req_flight_info,
    };
    const relationships = {
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditEvent(payload));
  };
  const validateNumberGreaterThanZero = (rule, value, callback) => {
    console.log({ rule, callback, value });
    let message =
      rule.field === "field_max_required_adult_age"
        ? "Min Required Adult Age Must Be Greater Than 0"
        : "Min Age Must Be Greater Than 0";
    if (value && value <= 0) {
      callback(message);
    } else {
      callback();
    }
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form2}
          onFinish={onFinishHandler}
        >
          <Form.Item label="Status" name="field_status">
            <Select
              options={statusOptions.map((status) => ({
                key: status?.value,
                value: status?.value,
                label: status?.label,
              }))}
              disabled={true}
              showArrow={false}
            />
          </Form.Item>

          <Form.Item label="Time In Cart" name="field_time_in_cart">
            <TimePicker
              placeholder="Select Time (mm:ss)"
              format={"mm:ss"}
              showNow={false}
            />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Traveler Goal"
            name="field_max_capacity"
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            className="swith_form asterick-align"
            label="Minimum Required Adult Age"
            name="field_max_required_adult_age"
            rules={[
              {
                validator: validateNumberGreaterThanZero,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className="swith_form asterick-align"
            label="Min Age"
            name="field_minimum_age"
            rules={[
              {
                validator: validateNumberGreaterThanZero,
              },
            ]}
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Web Enabled"
            name="field_web_enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Request Flight Information"
            name="field_req_flight_info"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Web Hide Item Code"
            name="field_web_hide_item_code"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Report Flag"
            name="field_report_flag"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Web Brochure"
            name="field_web_brochure"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Allow No Primary Item"
            name="field_allow_no_primary_item"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SystemOptionsForm;
