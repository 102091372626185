import { Table, Select, Tag, Steps } from "antd";
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import cruise_description from "./Intakestable_cruisedesc";
import { Link, useNavigate } from "react-router-dom";
import "./group_details_tour.less";
import React from "react";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  EventDetailsRoute,
  EventRoute,
  GroupDetailsCruise,
  GroupDetails_DetailsRoute,
} from "../../../url-routes/urlRoutes";
import GroupDetailDescription from "./GroupDetailDescription";
import { decodeContent, isJSONString, toSnakeCase } from "../../../utils/contentParser";

const GroupDetailsCruises = ({
  listingData,
  loadingAllGroupDetails,
  urlParamsObject,
  setUrlParamsObject,
  onDeleteHandler,
}) => {
  const navigate = useNavigate();
  const Option = Select.Option;
  const totalCount = listingData?.pager?.total_items;
  const permissions = JSON.parse(
    localStorage.getItem("authorization")
  )?.permissions;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleSorting = (value) => {
    if (value === "recently_read") {
      console.log("fffff", value, urlParamsObject);
      setUrlParamsObject({
        count: 10,
        order: "recently_read",
        page: 1,
        tab: urlParamsObject?.tab,
        // event_type: toSnakeCase(urlParamsObject?.tab),
      });
    } else {
      setUrlParamsObject({ ...urlParamsObject, order: value });
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "hash",
      key: "name",
    },
    {
      title: "Event Code",
      dataIndex: "event_code",
      key: "eventcode",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "age",

      render: (record, values) => {
        return (
          <>
            <div>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  position: "relative",
                  margin: "0",
                  opacity: "0.5",
                }}
              ></p>
              <p style={{ margin: "0" }}>{record}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Create Date",
      dataIndex: "date",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: <div className="action_start">Action</div>,
      dataIndex: "action",
    },
  ];

  const data = listingData?.rows?.map((item, i) => {
    const extensions =
      item?.extensions?.length &&
      isJSONString(item?.extensions?.length) &&
      JSON.parse(item?.extensions);
    const preExtId =
      extensions?.length &&
      extensions?.filter((item) => item?.type === "pre")?.[0];
    const postExtId =
      extensions?.length &&
      extensions?.filter((item) => item?.type === "post")?.[0];

    const isMainTour = preExtId || postExtId;
    const mainTour = isMainTour && {
      type: "Main Cruise",
      uuid: item?.uuid,
      created_at: item?.created,
    };
    const extensionsList = [
      preExtId,
      isMainTour && mainTour,
      postExtId,
    ]?.filter((item) => item);

    return {
      key: item?.id,
      uuid: item?.uuid,
      extensions,
      preExtId,
      postExtId,
      hash: (
        <>
          {permissions.includes("view group details") ? (
            <Link
              to={`${GroupDetails_DetailsRoute}?id=${item?.uuid}${
                preExtId?.uuid ? `&preExtId=${preExtId?.uuid}` : ""
              }${
                postExtId?.uuid ? `&postExtId=${postExtId?.uuid}` : ""
              }&type=cruise#Cruise`}
            >
              <a>{item?.id}</a>
            </Link>
          ) : (
            <span>{item?.id}</span>
          )}
        </>
      ),
      event_code: decodeContent(item?.field_event_code) || "",
      name: (
        <>
          {permissions.includes("view group details") ? (
            <Link
              to={`${GroupDetails_DetailsRoute}?id=${item?.uuid}${
                preExtId?.uuid ? `&preExtId=${preExtId?.uuid}` : ""
              }${
                postExtId?.uuid ? `&postExtId=${postExtId?.uuid}` : ""
              }&type=cruise#Cruise`}
            >
             <a dangerouslySetInnerHTML={{__html:item?.name|| ""}}/>
            </Link>
          ) : (
            <span dangerouslySetInnerHTML={{__html:item?.name|| ""}}/>
          )}
        </>
      ),
      date: item?.created || "",
      descri: (
        <>
          <div className="interior_table">
            <GroupDetailDescription
              extensionList={[...extensionsList]}
              preExtId={preExtId}
              postExtId={postExtId}
              navigateTo={GroupDetailsCruise}
              groupDetail={item}
              onDeleteHandler={onDeleteHandler}
            />
          </div>
        </>
      ),
      status: item?.field_group_detail_status,
      action: (
        <>
          <div className="action_flex action_end">
            {permissions.includes("add/edit group details") ? (
              <>
                <EditOutlined
                  className="action_edit"
                  onClick={() =>
                    navigate(
                      `${GroupDetailsCruise}?editId=${item?.uuid}${
                        preExtId?.uuid ? `&preExtId=${preExtId?.uuid}` : ""
                      }${
                        postExtId?.uuid ? `&postExtId=${postExtId?.uuid}` : ""
                      }&type=cruise#Cruise`
                    )
                  }
                />
              </>
            ) : null}

            {permissions.includes("delete group details") ? (
              <DeleteOutlined
                className="action_delete"
                onClick={(e) => {
                  console.log("deleted clicked");
                  e.preventDefault();
                  onDeleteHandler(item?.uuid, "groupDetails");
                }}
              />
            ) : null}

            {item?.field_group_detail_status?.includes("Converted") ? (
              <>
                {permissions.includes("view events") ? (
                  <a
                    href={`${EventDetailsRoute}?id=${item?.uuid || ""}`}
                    className="member_hover"
                  >
                    <CopyOutlined /> View Event
                  </a>
                ) : null}
              </>
            ) : (
              <>
                {permissions.includes("convert to events") ? (
                  <>
                    <a
                      href={`${EventRoute}?editId=${
                        item?.uuid || ""
                      }&convertToEvent=true`}
                      className="member_hover"
                    >
                      <CopyOutlined
                      // onClick={() =>
                      //   navigate(
                      //     `${GroupDetailsCruise}?editId=${
                      //       item?.uuid || ""
                      //     }&convertToEvent=true`
                      //   )
                      // }
                      />{" "}
                      Convert to Event
                    </a>
                  </>
                ) : null}
              </>
            )}
          </div>
        </>
      ),
      exp_row: extensions?.length ? true : false,
    };
  });

  return (
    <div className="cruise_table">
      <div className="table_filter">
        {/* <Checkbox onChange={onChange}></Checkbox> */}
        <span>Sort by :</span>
        <Select
          disabled={loadingAllGroupDetails}
          showSearch
          showArrow
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{
            width: 165,
            marginLeft: "10px",
          }}
          value={urlParamsObject?.order}
          onChange={handleSorting}
        >
          {/* <Option value="oldest">Oldest</Option> */}
          <Option value="recently_read" label="Recently Viewed">Recently Viewed</Option>
          <Option value="ASC" label="Ascending">Ascending</Option>
          <Option value="DESC" label="Descending">Descending</Option>
        </Select>
      </div>

      <Table
        className="table_listing table_tour intake_width"
        loading={{
          spinning: loadingAllGroupDetails,
          indicator: <DataLoader />,
        }}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <span
                style={{
                  margin: 0,
                  width: 0,
                }}
              >
                {record.descri}
              </span>
            );
          },
          rowExpandable: (record) => record.exp_row !== false,
        }}
        dataSource={data}
        pagination={
          urlParamsObject.order === "recently_read"
            ? false
            : {
                current: Number(urlParamsObject.page),
                pageSize: urlParamsObject.count,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                total: totalCount,
                position: ["topRight", "bottomRight"],
                onChange: (pageNo, pageSize) => {
                  setUrlParamsObject({
                    ...urlParamsObject,
                    tab: "Cruise",
                    page: pageNo,
                    count: pageSize,
                  });
                },
              }
        }
      />
    </div>
  );
};

export default GroupDetailsCruises;
