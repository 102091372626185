import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function InventoryEditPageHeader({ form, loading }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const eventId = searchParams.get("eventId");
  const inventoryId = searchParams.get("editId");

  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* <div className='addnote_content'>
                    <div className='breadcrumb_title'>
                        <p>Add Inventory</p>
                        
                    </div>

                </div> */}

        <h3 className="flex_row_column">
          <div className="header_res">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() => navigate(-1)}
            />
            <p>{`${inventoryId ? "Edit" : "Add"} Inventory`}</p>
          </div>
        </h3>

        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={loading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InventoryEditPageHeader;
