import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getGroupDetailsExtensionData } from "../../../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import { getExtensionsByGroupDetailIdApi } from "../../../../apis/groupDetailsApi";
import { message } from "antd";
import IsraelStaffing from "./IsraelStaffing/IsraelStaffing";
import ExtensionDetails from "./ExtensionsComponents/ExtensionDetails";
import ExtensionHotels from "./ExtensionsComponents/ExtensionHotels";
import ExtensionMeals from "./ExtensionsComponents/ExtensionMeals";
import ExtensionPricing from "./ExtensionsComponents/ExtensionPricing";
import ExtensionMisc from "./ExtensionsComponents/ExtensionMisc";
import ExtensionAir from "./ExtensionsComponents/EtensionAir";
import Notes from "./Notes/Notes";
import CruiseSystemInformation from "../CruiseDetails/CruiseSystemInformation";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

const ExtensionTab = ({ allDetails, allDetailsLoading }) => {
  const location = useLocation();
  const [extensionLoading, setExtensionLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [extensionData, setExtensionData] = useState({});
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  useEffect(() => {
    getExtensionData(id);
  }, []);

  const getExtensionData = async (GDId) => {
    setExtensionLoading(true);
    const res = await getExtensionsByGroupDetailIdApi(GDId);
    if (res?.status) {
      const activeTab = location?.hash.slice(1)?.split("-")[0].toLowerCase();
      const result = res?.data?.data?.length
        ? res?.data?.data?.find((item) => item?.field_type === activeTab)
        : {};
      setExtensionData(result);
    } else {
      setExtensionData({});
      message.error(SOMETHING_WENT_WRONG);
    }
    setExtensionLoading(false);
  };

  return (
    <>
      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionDetails
          extensionData={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>

      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionHotels
          extensionData={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>

      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionMeals
          extensionData={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionPricing
          extensionData={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>
      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <IsraelStaffing
          allDetails={extensionData}
          allDetailsLoading={extensionLoading}
        />
      </div>

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionAir
          allDetails={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>

      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ExtensionMisc
          extensionData={extensionData}
          extensionLoading={extensionLoading}
        />
      </div>
      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <Notes
          allDetails={extensionData}
          allDetailsLoading={extensionLoading}
        />
      </div>
      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <CruiseSystemInformation
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </div>
    </>
  );
};

export default ExtensionTab;
