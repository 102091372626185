import { Divider, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getContactListApi,
  getReservationDetailApi,
} from "../../../apis/reservationApi";
import { getMarketingSource } from "../../../apis/eventsApi";
import { getContactsBySearch } from "../../../others/commonApiCalls/commonApiCalls";
import { useDebounce } from "../../../others/Debounce/debounce";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

function ReservationMarketingForm({
  form,
  editId,
  onFinishHandler,
  reservationFormData,
  loadingReservationFormData,
}) {
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const [source, setSource] = useState([]);
  const [channel, setChannel] = useState([]);
  const [details, setDetails] = useState([]);
  const [travelAgentsList, setTravelAgentsList] = useState([]);
  const [loadingTravelAgent, setLoadingTravelAgent] = useState(false);
  const [referredContactsList, setReferredContactsList] = useState([]);
  const [loadingRefContacts, setLoadingRefContacts] = useState(false);
  const [loadingMarketingSource, setLoadingMarketingSource] = useState(false);
  const [agentSearchTerm, setAgentSearchTerm] = useState(
    reservationFormData?.referred_by_contact?.name || ""
  );
  const [contactSearchTerm, setContactSearchTerm] = useState(
    reservationFormData?.field_travel_agent?.name || ""
  );
  const agentDebounceSearch = useDebounce(agentSearchTerm, 500);
  const refContactDebountSearch = useDebounce(contactSearchTerm, 500);

  useEffect(() => {
    if (editId) {
      getReservationDetail(editId);
      // getContactList();
    }
  }, []);

  useEffect(() => {
    getContactsBySearch(
      agentDebounceSearch,
      setTravelAgentsList,
      setLoadingTravelAgent
    );
  }, [agentDebounceSearch]);
  useEffect(() => {
    getContactsBySearch(
      refContactDebountSearch,
      setReferredContactsList,
      setLoadingRefContacts
    );
  }, [refContactDebountSearch]);
  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      reservationFormData?.referred_by_contact?.name &&
        setContactSearchTerm(reservationFormData?.referred_by_contact?.name);
      reservationFormData?.field_travel_agent?.name &&
        setAgentSearchTerm(reservationFormData?.field_travel_agent?.name);
      form.setFieldsValue({
        field_source: reservationFormData?.field_source?.id,
        field_channel: reservationFormData?.field_channel?.id,
        field_detail: reservationFormData?.field_detail?.id,
        referred_by_contact: reservationFormData?.referred_by_contact?.id,
        field_travel_agent: reservationFormData?.field_travel_agent?.id,
      });
    }
  }, [reservationFormData]);

  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      let sourceChannel = source.find(
        (sourceItem) => reservationFormData?.field_source?.id == sourceItem.uuid
      );
      setChannel(sourceChannel?.channels);
    }
  }, [source]);

  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      let channelDetail = channel?.find(
        (channelItem) =>
          reservationFormData?.field_channel?.id == channelItem.uuid
      );
      setDetails(channelDetail?.details);
    }
  }, [channel]);

  useEffect(() => {
    if (Object.keys(reservationData).length) {
      getMarketingSourceData(reservationData?.select_event?.id || "");
    }
  }, [reservationData]);

  const getMarketingSourceData = async (eventId) => {
    setLoadingMarketingSource(true);
    const response = await getMarketingSource(eventId);
    if (response.status) {
      setSource(response.data);
      setLoadingMarketingSource(false);
    } else {
      setSource([]);
      message.error(SOMETHING_WENT_WRONG);
      setLoadingMarketingSource(false);
    }
  };
  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData([]);
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };

  // const getContactList = async () => {
  //   setLoadingTravelAgent(true);
  //   const res = await getContactListApi();
  //   if (res.status) {
  //     setTravelAgentList(res?.data?.data);
  //     setLoadingTravelAgent(false);
  //   } else {
  //     setTravelAgentList([]);
  //     message.error(SOMETHING_WENT_WRONG);
  //     setLoadingTravelAgent(false);
  //   }
  // };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Marketing</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 12,
        }}
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item
          name="field_source"
          label="Source"
          className="asterick-align"
          rules={[{ required: true, message: "Please Select Source" }]}
        >
          <Select
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={source?.map((sourceItem) => ({
              key: sourceItem.id,
              value: sourceItem.uuid,
              label: sourceItem.source_name,
            }))}
            onSelect={(e) => {
              let sourceChannel = source.find(
                (sourceItem) => e == sourceItem.uuid
              );
              form.setFieldsValue({ field_channel: null, field_detail: null });
              setChannel(sourceChannel.channels);
            }}
            allowClear
            onClear={() => {
              setChannel([]);
              form.setFieldsValue({ field_channel: null, field_detail: null });
            }}
          ></Select>
        </Form.Item>
        <Form.Item
          name="field_channel"
          className="asterick-align"
          label="Channel"
          rules={[{ required: true, message: "Please Select Channel" }]}
        >
          <Select
            options={channel?.map((channelItem) => ({
              key: channelItem.id,
              value: channelItem.uuid,
              label: channelItem.name,
            }))}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={!channel?.length}
            onSelect={(e) => {
              let channelDetail = channel.find(
                (channelItem) => e == channelItem.uuid
              );
              form.setFieldsValue({ field_detail: null });
              setDetails(channelDetail.details);
            }}
            allowClear
            onClear={() => {
              setDetails([]);
              form.setFieldsValue({ field_detail: null });
            }}
          />
        </Form.Item>
        <Form.Item
          name="field_detail"
          label="Detail"
          className="asterick-align"
          rules={[{ required: true, message: "Please Select Detail" }]}
        >
          <Select
            options={details?.map((detailItem) => ({
              key: detailItem.id,
              value: detailItem.uuid,
              label: detailItem.name,
            }))}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={!details?.length}
          />
        </Form.Item>
        <Form.Item label="Referred by Contact" className="asterick-align" name="referred_by_contact">
          <Select
            showSearch
            allowClear
            onClear={() => {
              setContactSearchTerm("");
            }}
            onSearch={(e) => {
              setContactSearchTerm(e);
            }}
            options={referredContactsList?.map((travelAgent) => ({
              key: travelAgent.drupal_internal__id,
              value: travelAgent.id,
              label: `${travelAgent.name} (${travelAgent.drupal_internal__id})`,
            }))}
            loading={loadingRefContacts}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item label="Travel Agent" className="asterick-align" name="field_travel_agent">
          <Select
            showSearch
            allowClear
            onClear={() => {
              setAgentSearchTerm("");
            }}
            onSearch={(e) => {
              setAgentSearchTerm(e);
            }}
            options={travelAgentsList?.map((travelAgent) => ({
              key: travelAgent.drupal_internal__id,
              value: travelAgent.id,
              label: `${travelAgent.name} (${travelAgent.drupal_internal__id})`,
            }))}
            loading={loadingTravelAgent}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default ReservationMarketingForm;
