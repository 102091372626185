import React from "react";

import { useEffect, useState } from "react";
import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
import Sidebar from "../Header_sidebar";
import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
const { Search } = Input;
const { useBreakpoint } = Grid;

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#000", fontSize: "14px", lineHeight: "28px" }}
              >
                {category}
              </a>
            </span>
          </div>
        ),
      };
    });

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            // href="#"
          >
            Change Password
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            Sign Out
          </a>
        ),
      },
    ]}
  />
);
const IntakesMainHeader = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Search toggle for mobile
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search Group Details"
                enterButton
              />
            </AutoComplete>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
};

export default IntakesMainHeader;
