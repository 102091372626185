import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";
import "./Res_guestprofile.less";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Reservation_editoperations() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Operations</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Dining Time Assigned" name="Dining Time Assigned">
              <Select>
                <option value="Main Dining">Main Dining</option>
                <option value="Early Dining">Early Dining</option>
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Table Size </div>
                  <div>Requested </div>
                </div>
              }
            >
              <Select>
                <option value="Table for 2">Table for 2</option>
                <option value="Table for 3">Table for 3</option>
                <option value="Table for 4">Table for 4</option>
                <option value="Table for 5">Table for 5</option>
                <option value="Table for 6">Table for 6</option>
                <option value="Table for 7">Table for 7</option>
                <option value="Table for 8">Table for 8</option>
                <option value="Table for 9">Table for 9</option>
                <option value="Table for 10">Table for 10</option>
                <option value="Table for 12">Table for 12</option>
              </Select>
            </Form.Item>
            <Form.Item label="Table No" name="Table No">
              <Input />
            </Form.Item>
            <Form.Item label="Table Status">
              <Select>
                <option value="Near window">Near window</option>
                <option value="Not a booth">Not a booth</option>
                <option value="Round table">Round table</option>
              </Select>
            </Form.Item>
            <Form.Item label="Insurance Declined" name="Insurance Declined">
              <Switch />
            </Form.Item>

            <Form.Item label="Charter Group" name="Charter Group">
              <Select>
                <option value="Red">Red</option>
                <option value="Blue">Blue</option>
              </Select>
            </Form.Item>
            <Form.Item label="Charter Priority" name="Charter Priority">
              <Select>
                <option value=" Green">Green</option>
                <option value=" Purple">Purple</option>
                <option value=" Yellow">Yellow</option>
              </Select>
            </Form.Item>
            <Form.Item label="Contract Rcvd Date" name="Contract Rcvd Date">
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Doc Request" name="Doc Request">
              <Switch />
            </Form.Item>
            <Form.Item label="Doc Request Type" name="Doc Request Type">
              <Select>
                <option value="Paper Docs">Paper Docs</option>
                <option value="Early Docs">Early Docs</option>
                <option value="E-Docs">E-Docs</option>
                <option value="Other">Other</option>
              </Select>
            </Form.Item>

            <div className="textarea_row">
              <Form.Item label="Doc Notes" name="Doc Notes">
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <Form.Item
              label="Web Terms Accept Method"
              //  label={
              //     <div className="text_break">
              //         <div>Web Terms  </div>
              //         <div>Accept Method </div>

              //     </div>
              // }
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break">
                  <div>Web Terms </div>
                  <div>Accept Date </div>
                </div>
              }
            >
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>

            <div className="textarea_row">
              <Form.Item label="Cancel Comments" name="Cancel Comments">
                <TextArea rows={4} />
              </Form.Item>
            </div>

          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Reservation_editoperations;
