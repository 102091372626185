import React, { useState } from "react";
import { Table, Button, Divider } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CreateUserModal from "../CreateUserModal";
import DataLoader from "../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const AssociateMembers = ({
  users,
  setGetRoleDependency,
  getRoleDependency,
  isLoading,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  // const [editUser, setEditUser] = useState(false);

  const data = users?.map((user) => {
    return {
      key: user.idusers,
      label: (
        <>
          <p className="span_text">
            {/* {user.idusers} */}
            {user?.drupal_internal__uid}
          </p>
          <p>
            {user.field_first_name
              ? `${user.field_first_name + " " + user.field_last_name}`
              : user.display_name}
          </p>
        </>
      ),
      actions: (
        <>
          <div className="action_flex">
            <EditOutlined
              className="action_edit"
              onClick={() => {
                handleModal(user);
              }}
            />

            {/* <DeleteOutlined className="action_delete" /> */}
          </div>
        </>
      ),
    };
  });

  const handleModal = (userData) => {
    // setEditUser(true);
    setModalData(userData);
    setOpenModal(true);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>Users</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
      />
      <CreateUserModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalData={modalData}
        editUser={true}
        setGetRoleDependency={setGetRoleDependency}
        getRoleDependency={getRoleDependency}
      />
    </div>
  );
};

export default AssociateMembers;
