import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";

function onChange(time, timeString) {
  console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function CruisePostform_two() {
  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };
  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0 ms-0">Hotel Options</h4>
      </div>
      <Divider className="global_divider mb-0" />



      <div style={{ marginTop: "24px" }} />
      <Form {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 5,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal" form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">

        <Form.Item
          label="Traveler Type"
          name="guest_type"

        >
          <Select>
            <Select.Option value=" 3 Star">
              Traveler
            </Select.Option>
            <Select.Option value=" 4 Star">
              Staff
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="City"
          name="city"

        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Hotel Name"
          name="hotel_name"

        >
          <Input />
        </Form.Item>


        <Form.Item
          label="Star Level"
          name="Star Level"

        >
          <Select>
            <Select.Option value=" 3 Star">
              3 Star
            </Select.Option>
            <Select.Option value=" 4 Star">
              4 Star
            </Select.Option>
            <Select.Option value=" 5 Star">
              5 Star
            </Select.Option>
            <Select.Option value=" 5 Star Deluxe">
              5 Star Deluxe
            </Select.Option>


          </Select>
        </Form.Item>



        <Form.Item
          label="Destination"
          name="Destination"

        >
          <Select>
            <Select.Option value=" Tiberias">
              Tiberias
            </Select.Option>
            <Select.Option value="Jerusalem">
              Jerusalem
            </Select.Option>

          </Select>
        </Form.Item>

        <Form.Item label="Check In">
          <DatePicker onChange={onChange} picker="Start Date" style={{

            padding: "4px"
          }}
            format={"MM/DD/YYYY"}


          />
        </Form.Item>
        <Form.Item label="Check Out">
          <DatePicker onChange={onChange} picker="Start Date" style={{

            padding: "4px"
          }}
            format={"MM/DD/YYYY"}


          />
        </Form.Item>



        <Form.Item label="Nights">
          <Form.Item name="Nights" noStyle>
            <InputNumber style={{

              width: "100%"
            }} />
          </Form.Item>

        </Form.Item>
        <Form.Item label="Rooms">
          <Form.Item name="Request" noStyle>
            <InputNumber style={{

              width: "100%"
            }} />
          </Form.Item>

        </Form.Item>



        <Form.List name="sights">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key} align="baseline"
                  style={{ borderTop: "1px solid #e8e8e8" }}
                >



                  <Form.Item

                  >
                    <div className="close_relative">
                      <Button className="close_absolute" type="dashed" onClick={() => remove(field.name)} icon={<CloseOutlined />}>

                      </Button>
                    </div>

                  </Form.Item>


                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (

                      <Form
                        {...layout}
                        labelCol={{
                          sm: 24,
                          md: 6,
                          lg: 6,
                          xl: 5,
                          xxl: 4,
                        }}
                        wrapperCol={{
                          sm: 24,
                          md: 14,
                          lg: 14,
                          xl: 14,
                          xxl: 12,
                        }}
                        layout="horizontal"
                      >

                        <Form.Item
                          label="Traveler Type"
                          name="guest_type"

                        >
                          <Select>
                            <Select.Option value=" 3 Star">
                              Traveler
                            </Select.Option>
                            <Select.Option value=" 4 Star">
                              Staff
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label="City"
                          name="city"

                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Hotel Name"
                          name="hotel_name"

                        >
                          <Input />
                        </Form.Item>


                        <Form.Item
                          label="Star Level"
                          name="Star Level"

                        >
                          <Select style={{ height: "40px" }}>
                            <Select.Option value=" 3 Star">
                              3 Star
                            </Select.Option>
                            <Select.Option value=" 4 Star">
                              4 Star
                            </Select.Option>
                            <Select.Option value=" 5 Star">
                              5 Star
                            </Select.Option>
                            <Select.Option value=" 5 Star Deluxe">
                              5 Star Deluxe
                            </Select.Option>


                          </Select>
                        </Form.Item>



                        <Form.Item
                          label="Destination"
                          name="Destination"

                        >
                          <Select>
                            <Select.Option value=" Tiberias">
                              Tiberias
                            </Select.Option>
                            <Select.Option value="Jerusalem">
                              Jerusalem
                            </Select.Option>

                          </Select>
                        </Form.Item>

                        <Form.Item label="Check In">
                          <DatePicker onChange={onChange} picker="Start Date" style={{

                            padding: "4px"
                          }}
                            format={"MM/DD/YYYY"}


                          />
                        </Form.Item>

                        <Form.Item label="Check Out">
                          <DatePicker onChange={onChange} picker="Start Date" style={{

                            padding: "4px"
                          }}
                            format={"MM/DD/YYYY"}


                          />
                        </Form.Item>



                        <Form.Item label="Nights">
                          <Form.Item name="Nights" noStyle>
                            <InputNumber style={{

                              width: "100%"
                            }} />
                          </Form.Item>

                        </Form.Item>
                        <Form.Item label="Request">
                          <Form.Item name="Request" noStyle>
                            <InputNumber style={{

                              width: "100%"
                            }} />
                          </Form.Item>

                        </Form.Item>




                      </Form>

                    )}
                  </Form.Item>
                </div>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Add Hotel

                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>


      </Form>





    </div>




  );
}

export default CruisePostform_two;
