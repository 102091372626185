import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PaymentStatus.css"
import { Card } from 'antd';

  const Message = ({ message, redirect, timeLeft, setTimeLeft }) => {

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => {
          if (timeLeft === 1) {
            clearInterval(intervalId);
            window.location.href = 'https://dev-ict.oslabs.app/Reservationsview_page';
            return 1;
          }
          return timeLeft - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }, []);

    return (
    <Card className="messageBody">
      <p className="message">{message}</p>
      {console.log(redirect,"redirect")}
      {redirect && (
        <p>
          <a >redirecting to the main website in {timeLeft}</a>
          <a href="https://dev-ict.oslabs.app/reservation-payment">redirect now {timeLeft}</a>
        </p>)}
    </Card>
    )
  };
  
  export default function PaymentStatus() {
    const [message, setMessage] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [timeLeft, setTimeLeft] = useState(5);
  
    useEffect(() => {
  
      const query = new URLSearchParams(window.location.search);
  
      async function updateSetupDB(){
        const reqBody = {
          session_id : query.get("session_id"),
          contact_email : query.get("c_e"),
        }
        const response = await axios.post("https://tykwr4ntgd.execute-api.us-west-2.amazonaws.com/dev/pay-with-stripe/update-future-payments", reqBody)
      }

      async function updatePaymentDB(){
        const reqBody = {
          session_id : query.get("session_id"),
          contact_email : query.get("c_e"),
        }
        
        const {data, response} = await axios.post("https://tykwr4ntgd.execute-api.us-west-2.amazonaws.com/dev/pay-with-stripe/update-card-payments", reqBody).catch((err)=> err)
        
        if(data?.success || response?.data?.success){
          setRedirect(true)
        }
        console.log("first")

      }

      // Check to see if this is a redirect back from Checkout
      if (query.get("payment-success") && query.get("session_id")) {
        setMessage("Order placed! You will receive an email confirmation.... updating records");
        updatePaymentDB()
      }
  
      if (query.get("setup-success") && query.get("session_id")) {
        setMessage("Payment Setup complete!.... updating records");
        updateSetupDB()
      }
  
      if (query.get("canceled")) {
        setMessage(
          "Order canceled -- continue to explore around and checkout when you're ready."
        );
      }  
  
    }, []);
  
    return (<div className="messageWrapper"><Message message={message} redirect={redirect} timeLeft={timeLeft} setTimeLeft={setTimeLeft}/> </div>);
  }