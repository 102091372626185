import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message, Modal } from "antd";
import Sidebar from "../../../components/Header/Header_sidebar";
import Searchevents_menu from "../../../components/Events/EventsSearchbar";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import Reservationtableview_header from "../../../components/Breadcrumb/Reservations_header/Reservationtableview_header";
import Reservationitem_details from "../../../components/View_components/Reservations/Reservationtable_components/Reservationitem_details";
import Reservationproduct_information from "../../../components/View_components/Reservations/Reservationtable_components/Reservationproduct_information";
import Reservationcategory_information from "../../../components/View_components/Reservations/Reservationtable_components/Reservationcategory_information";
import Reservationguest_details from "../../../components/View_components/Reservations/Reservationtable_components/Reservationguest_details";
import Reservationpayment_responsibility from "../../../components/View_components/Reservations/Reservationtable_components/Reservationpayment_responsibility";
import Reservationrevision_history from "../../../components/Reservation/ReservationItem/ReservationItemDetailSections/ReservationItemHistory";

import React, { useEffect, useState } from "react";
import { isoParse } from "d3";
import { getReservationItemDetailApi } from "../../../apis/reservationApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import ReservationItemHistory from "../../../components/Reservation/ReservationItem/ReservationItemDetailSections/ReservationItemHistory";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { permissionChecker } from "../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Reservationtableview_page() {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const paramsId = searchParams.get("id");
  const itemId = searchParams.get("item-id");

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [data, setData] = useState();
  const [triggerData, setTriggerData] = useState(false);

  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    {
      title: "Details",
      path: `/reservation/details?id=${paramsId}`,
    },
    { title: "Reservation Item", path: "" },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchReservationItem = async () => {
    const response = await getReservationItemDetailApi(itemId);
    if (response && response.status) {
      setData(response?.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };


  const handleCancel = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    const isAllowed = permissionChecker("add/edit reservation");
    if (!isAllowed) {
      navigate("/page-not-access");
    }
    fetchReservationItem();
  }, [triggerData, itemId]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>

          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          <div className="events-header">
            <Reservationtableview_header
              data={data}
              triggerData={triggerData}
              fetchReservationItem={fetchReservationItem}
              setTriggerData={setTriggerData}
              setDeleteModalOpen={setDeleteModalOpen}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Reservationitem_details data={data} />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Reservationproduct_information data={data?.event_datail} />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Reservationcategory_information
              completeData={data}
              data={data?.reservation_item_category}
              eventDetail={data?.event_datail}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Reservationguest_details
              data={data}
              setTriggerData={setTriggerData}
              triggerData={triggerData}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Reservationpayment_responsibility
              data={data?.payment_responsibility}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ReservationItemHistory itemId={itemId} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Reservationtableview_page;
