import React from "react";
import Notes from "./Notes/Notes";
import CruiseSystemInformation from "../CruiseDetails/CruiseSystemInformation";
import InspirationInitial from "./InspirationInitial/InspirationInitial";
import Air from "./Air/Air";
import Misc from "./Misc/Misc";
import IsraelStaffing from "./IsraelStaffing/IsraelStaffing";
import Meals from "./Meals/Meals";
import PreferredHotels from "./PreferredHotels/PreferredHotels";
import PassengersStaff from "./PassengersStaff/PassengersStaff";
import TourDetails from "./TourDetails/TourDetails";
import { useSearchParams } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import CruiseMeeting from "./CruiseMeeting/CruiseMeeting";
import ShoreExcursions from "../CruiseDetails/ShoreExcursions";
import CruiseMisc from "./CruiseMisc/CruiseMisc";
import CruiseDetails from "./CruiseDetails/CruiseDetails";
import CruisePassenger from "./CruisePassenger/CruisePassenger";

const TourMainTab = ({ allDetails, allDetailsLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  return (
    <>
      <div
        className="tabs_style pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        {type === "cruise" ? (
          <CruiseDetails
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        ) : (
          <TourDetails
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        )}
      </div>

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        {type === "cruise" ? (
          <CruisePassenger
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        ) : (
          <PassengersStaff
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        )}
      </div>

      {type === "cruise" && (
        <>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CruiseMeeting
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ShoreExcursions
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </Content>
          {/* <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ExtensionDetails
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </Content> */}
        </>
      )}

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <PreferredHotels
          type={type}
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </div>

      {type === "tour" && (
        <>
          <div
            className="tabs_style partners_informations pd-16 bg-white"
            style={{ marginBottom: "20px" }}
          >
            <Air
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </div>
          <div
            className="tabs_style partners_informations pd-16 bg-white"
            style={{ marginBottom: "20px" }}
          >
            <Meals
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </div>
          <div
            className="tabs_style partners_informations pd-16 bg-white"
            style={{ marginBottom: "20px" }}
          >
            <IsraelStaffing
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </div>
        </>
      )}

      <div
        className="tabs_style partners_informations   pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        {type === "tour" ? (
          <Misc allDetails={allDetails} allDetailsLoading={allDetailsLoading} />
        ) : (
          <CruiseMisc
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        )}
      </div>
      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <InspirationInitial
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </div>

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <Notes allDetails={allDetails} allDetailsLoading={allDetailsLoading} />
      </div>

      <div
        className="tabs_style partners_informations pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <CruiseSystemInformation
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </div>
    </>
  );
};

export default TourMainTab;
