import {
  Table,
  Divider,
  Checkbox,
  Switch,
  Modal,
  Tag,
  Button,
  message,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Link, useSearchParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { getReservationItemListing } from "../../../apis/reservationApi";
import moment from "moment";
import {
  EventDetailsAddonsDetailsRoute,
  EventCategoryDetailsRoute,
  ReservationItemDetailRoute,
} from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { addCommasInPricing } from "../../../utils/contentParser";
import { permissionChecker } from "../../../utils/permisssionChecker";

const columns = [
  {
    title: "Item Code/Name",
    dataIndex: "item_code",
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },
  {
    title: "Discounts",
    dataIndex: "discounts",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },
  {
    title: "Fees",
    dataIndex: "fees",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },

  {
    title: "Item Total",
    dataIndex: "item_total",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },

  {
    title: "Status",
    dataIndex: "status",
  },

  {
    title: "Category",
    dataIndex: "category",
  },
];

const Reservationsitems = ({ reservationData }) => {
  const [reservationItems, setReservationItems] = useState([]);
  const [ItemsCount, setItemsCount] = useState(0);
  const [showCancel, setShowCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isPayeeTrigger, highlightSection } = useSelector(
    (state) => state.reservation
  );

  let paramId = searchParams.get("id");

  const onChange = (checked) => {
    setShowCancel(checked.target.checked);
  };

  const getTravellerName = (list) => {
    let names = [];
    if (list) {
      list.forEach((val) => {
        names.push(val?.name);
      });
    }
    return names.join(", ");
  };

  useEffect(() => {
    if (showCancel) {
      getItemsList("cancelled");
    } else {
      getItemsList("reserved");
    }
    setLoading(true);
  }, [showCancel, isPayeeTrigger]);

  const getItemsList = async (payload) => {
    const response = await getReservationItemListing(payload, paramId);
    if (response && response.status) {
      let items = [];
      let travelersId = [];
      const { data } = response;
      data.forEach((value, index) => {
        value[0]?.reservationitem_traveler?.map((val) => {
          travelersId.push(val.uuid);
        });
        let item = {
          key: index,
          item_code: (
            <>
              {permissionChecker("add/edit reservation") ? (
                <Link
                  to={`${ReservationItemDetailRoute}?id=${paramId}&item-id=${value[0]?.reservationitem_uuid}`}
                >
                  <a style={{ fontSize: "14px", lineHeight: "20px" }}>
                    {value[0]?.reservationitem_name}
                  </a>
                </Link>
              ) : (
                value[0]?.reservationitem_name
              )}
              <br></br>
              <span className="span_text">
                {value[0]?.reservationitem_category?.category_event
                  ?.start_date &&
                  moment(
                    value[0]?.reservationitem_category?.category_event
                      ?.start_date
                  ).format("L")}{" "}
                -{" "}
                {value[0]?.reservationitem_category?.category_event?.end_date &&
                  moment(
                    value[0]?.reservationitem_category?.category_event?.end_date
                  ).format("L")}{" "}
                {getTravellerName(value[0]?.reservationitem_traveler)}
              </span>
            </>
          ),
          price: value[0]?.price || 0,
          discounts: value[0]?.discount || 0,
          fees: value[0]?.fees || 0,
          item_total: value[0]?.total || 0,
          status: value[0]?.reservationitem_status,
          category: (
            <>
              {permissionChecker("view events") ? (
                <Link
                  to={`${
                    value[0]?.is_primary_item
                      ? EventCategoryDetailsRoute
                      : EventDetailsAddonsDetailsRoute
                  }?id=${value[0]?.reservation_event?.uuid}&${
                    value[0]?.is_primary_item ? `categoryId` : "addonId"
                  }=${
                    value[0]?.is_primary_item
                      ? value[0]?.reservationitem_category?.uuid
                      : value[0]?.reservationitem_category?.category_event?.uuid
                  }`}
                >
                  <a style={{ fontSize: "14px", lineHeight: "20px" }}>
                    {value[0]?.reservationitem_category?.code} -{" "}
                    {value[0]?.reservationitem_category?.name}
                  </a>
                </Link>
              ) : (
                <span>
                  {value[0]?.reservationitem_category?.code} -{" "}
                  {value[0]?.reservationitem_category?.name}
                </span>
              )}
              <br></br>
              <span className="span_text">
                {value[0]?.reservationitem_category?.occupancy?.price_name}
              </span>
            </>
          ),
        };
        items.push(item);
      });
      travelersId = [...new Set(travelersId)];
      localStorage.setItem("travellersId", JSON.stringify({ travelersId }));
      setItemsCount(data.length);
      setReservationItems(items);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Items ({ItemsCount})</h4>

        <span>
          <Checkbox onChange={onChange} checked={showCancel}>
            Show Canceled
          </Checkbox>

          {permissionChecker("add/edit reservation") ? (
            reservationData?.reservation_status?.key === "discarded" ||
            reservationData?.reservation_status?.key === "cancel_pending" ||
            reservationData?.reservation_status?.key === "cancelled" ||
            reservationData?.reservation_status?.key === "completed" ? null : (
              <Link to={`/add_item?id=${paramId}`}>
                {" "}
                <Button type="primary" danger={!!highlightSection?.items}>
                  <PlusOutlined /> Add
                </Button>
              </Link>
            )
          ) : null}
        </span>
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={reservationItems}
        size="small"
        pagination={false}
        loading={loading}
      />
    </div>
  );
};
export default Reservationsitems;
