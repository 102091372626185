import { Menu, Select, Skeleton, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";

const columns = [
  {
    title: "Add-on Type",
    dataIndex: "add_on",
  },

  {
    title: "Ignore Category Dependencies",
    dataIndex: "ignore_category",
  },
];

const ReservationTable_header = (props) => {
  const {
    addOnTypes,
    handleTypeChange,
    handleCategoryChange,
    section,
    loading,
  } = props;
  const [addOnOptions, setAddOnOptions] = useState([]);

  useEffect(() => {
    if (addOnTypes) {
      let newList = addOnTypes;
      newList.unshift({ key: "all", value: "All" });
      setAddOnOptions(newList);
    }
  }, [addOnTypes]);

  const data = [
    {
      add_on: loading ? (
        <Skeleton.Input style={{ width: "300px" }} />
      ) : (
        <div>
          {addOnOptions.length ? (
            <>
              <p className="document_pdf">Filter by Add-On Type :</p>

              <Select
                defaultValue="All"
                style={{
                  width: 200,
                }}
                onChange={(e) => handleTypeChange(e)}
                options={addOnOptions?.map((val) => ({
                  key: val.key,
                  value: val.value,
                  label: val.value,
                }))}
              />
            </>
          ) : null}
        </div>
      ),
      ignore_category: (
        <div>
          <p className="document_pdf">Ignore Category Dependencies :</p>
          <Switch defaultChecked onChange={(e) => handleCategoryChange(e)} />
        </div>
      ),
    },
  ];

  return (
    <>
      {section === "category" && (
        <Table
          className="schedule_table addons-filters_wrapper"
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
        />
      )}
    </>
  );
};
export default ReservationTable_header;
