import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import ReservationDetailHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailHeader";
import ReservationDetailContainer from "../../../components/Reservation/ReservationDetails/ReservationDetailContainer";
import { useDispatch } from "react-redux";
import {
  getReservationDetail,
  setSectionHighlighter,
} from "../../../redux/features/reservationFeatures/reservationSlice";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getReservationDetailApi } from "../../../apis/reservationApi";
import { updateRecentlyViewed } from "../../../utils/recentlyViewedUpdater";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import ReservationSearchBar from "../../../components/Reservation/ReservationSearchBar";

const { Header, Content, Sider } = Layout;

function ReservationDetailPage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDiscarded, setIsDiscarded] = useState(false);
  const { useBreakpoint } = Grid;
  const reservationId = searchParams.get("id");
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    { title: "Detail", path: "" },
  ];
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const { isPayeeTrigger, isActivityTrigger, highlightSection } = useSelector(
    (state) => state.reservation
  );

  useEffect(() => {
    getReservationDetail(reservationId);
    return () => {
      dispatch(setSectionHighlighter({}));
    };
  }, [reservationId, isDiscarded, isPayeeTrigger, isActivityTrigger]);

  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      const { type, langcode, drupal_internal__id, id } = res?.data?.data;
      updateRecentlyViewed(type, id, langcode, drupal_internal__id);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ReservationSearchBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ReservationDetailHeader
              reservationData={reservationData}
              reservationId={reservationId}
              loadingReservationData={loadingReservationData}
              setIsDiscarded={setIsDiscarded}
              isDiscarded={isDiscarded}
            />
          </div>
          <ReservationDetailContainer
            reservationData={reservationData}
            reservationId={reservationId}
            loadingReservationData={loadingReservationData}
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ReservationDetailPage;
