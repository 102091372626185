import { DatePicker, Form, Input, Select, Switch, message } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addonStepSetter,
  createOrEditAddon,
  getAddonsPartnersList,
  getAddonsSuppliersList,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import { EventDetailsAddonsDetailsRoute } from "../../../../../url-routes/urlRoutes";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import { payTermsList } from "../../../../../others/util/commonFunctions";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const AddonsPaymentSetupForm = ({
  form3,
  createId,
  editId,
  eventId,
  next,
  addonFormFields,
  currentStep,
  isAddon = false
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addonCreation } = useSelector((state) => state.addons);
  const { addonCreationResponse, loadingAddonCreation, step3, addonStep } =
    addonCreation;
  const addonCreationRef = useRef(false);
  // const [paymentProcessorList, setPaymentProcessorList] = useState([]);
  // const [loadingPaymentProcessor, setLoadingPaymentProcessor] = useState(false);
  // const [multiCurrencyList, setMultiCurrencyList] = useState([]);
  // const [loadingMultiCurrency, setLoadingMultiCurrency] = useState(false);
  // const [defaultPayTermList, setDefaultPayTermList] = useState([]);
  // const [loadingPayTerm, setLoadingPayTerm] = useState(false);
  // const paymentProcessorRef = useRef(false);
  // const multiCurrencyRef = useRef(false);
  // const defaultPayTermRef = useRef(false);

  // useEffect(() => {
  //   getTaxonomyList(
  //     "payment_processor",
  //     setPaymentProcessorList,
  //     setLoadingPaymentProcessor
  //   );
  //   getTaxonomyList(
  //     "multi_currency",
  //     setMultiCurrencyList,
  //     setLoadingMultiCurrency
  //   );
  // }, []);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(addonFormFields).length) {
      dispatch(addonStepSetter(addonFormFields?.field_step));
      form3.setFieldsValue({
        ...addonFormFields,
        field_deposit_date: !!addonFormFields?.field_deposit_date
          ? moment(addonFormFields?.field_deposit_date, "YYYY-MM-DD")
          : null,
        field_second_deposit_date: !!addonFormFields?.field_second_deposit_date
          ? moment(addonFormFields?.field_second_deposit_date, "YYYY-MM-DD")
          : null,
        field_final_payment_date: !!addonFormFields?.field_final_payment_date
          ? moment(addonFormFields?.field_final_payment_date, "YYYY-MM-DD")
          : null,
        // field_payment_processor: addonFormFields?.field_payment_processor.id,
        // field_multi_currency: addonFormFields?.field_multi_currency.id,
        field_default_pay_terms:
          addonFormFields?.field_default_pay_terms?.key || null,
      });
    }
  }, [addonFormFields]);

  useEffect(() => {
    if (addonCreationRef.current) {
      if (addonCreationResponse.status) {
        dispatch(
          addonStepSetter(addonCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          step3
            ? navigate(
                `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${createId}`
              )
            : next({ type: "creation", id: eventId, addonId: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            step3
              ? navigate(
                  `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${editId}`
                )
              : next({ type: "edit", id: eventId, addonId: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateAddonStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    addonCreationRef.current = true;
  }, [addonCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_is_financeable: values.field_is_financeable,
      field_standard_deposit_amount: values.field_standard_deposit_amount,
      field_standard_deposit_percent: values.field_standard_deposit_percent,
      field_early_deposit_amount: values.field_early_deposit_amount,
      field_deposit_date: values?.["field_deposit_date"]?.format("YYYY-MM-DD"),
      field_second_deposit_amount: values.field_second_deposit_amount,
      field_second_deposit_percent: values.field_second_deposit_percent,
      field_second_deposit_date:
        values["field_second_deposit_date"]?.format("YYYY-MM-DD") || null,
      field_final_payment_date:
        values["field_final_payment_date"]?.format("YYYY-MM-DD") || null,
      field_default_pay_terms: values?.field_default_pay_terms || null,
      field_step: currentStep > addonStep ? +currentStep : addonStep,
    };
    const relationships = {
      field_event: {
        data: {
          type: "event--event",
          id: eventId,
        },
      },
      // field_payment_processor: values?.field_payment_processor
      //   ? {
      //       data: {
      //         type: "taxonomy_term--payment_processor",
      //         id: values.field_payment_processor,
      //       },
      //     }
      //   : null,
      // field_multi_currency: values?.field_multi_currency
      //   ? {
      //       data: {
      //         type: "taxonomy_term--multi_currency",
      //         id: values.field_multi_currency,
      //       },
      //     }
      //   : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditAddon(payload));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form3}
          onFinish={onFinishHandler}
        >
         {!isAddon ?  (
         <><Form.Item
            className="swith_form"
            label="Is Financeable"
            name="field_is_financeable"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Standard Deposit Amount"
            name="field_standard_deposit_amount"
          >
            <Input
              type="number"
              placeholder="Standard Deposit Amount"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Standard Deposit Percent"
            name="field_standard_deposit_percent"
          >
            <Input type="number" placeholder="Standard Deposit Percent" />
          </Form.Item>
          <Form.Item
            label="Early Deposit Amount"
            name="field_early_deposit_amount"
          >
            <Input
              placeholder="Early Deposit Amount"
              type="number"
              min={0}
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Deposit Date" name="field_deposit_date">
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Second Deposit Amount"
            name="field_second_deposit_amount"
          >
            <Input
              type="number"
              placeholder="Second Deposit Amount"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Second Deposit Percent"
            name="field_second_deposit_percent"
          >
            <Input type="number" placeholder="Second Deposit Percent" />
          </Form.Item>

          <Form.Item
            label="Second Deposit Date"
            name="field_second_deposit_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item label="Final Payment Date" name="field_final_payment_date">
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          {/* <Form.Item label="Payment Processor" name="field_payment_processor">
            <Select
              allowClear
              placeholder="Payment Processor"
              options={paymentProcessorList.map((paymentProcessor) => ({
                key: paymentProcessor?.id,
                value: paymentProcessor?.id,
                label: paymentProcessor?.name,
              }))}
            />
          </Form.Item>

          <Form.Item label="Multi-Currency" name="field_multi_currency">
            <Select
              allowClear
              placeholder="Multi-Currency"
              options={multiCurrencyList.map((multiCurrency) => ({
                key: multiCurrency?.id,
                value: multiCurrency?.id,
                label: multiCurrency?.name,
              }))}
            />
          </Form.Item> */}

          <Form.Item label="Default Pay Terms" name="field_default_pay_terms">
            <Select
              allowClear
              placeholder="Default Pay Terms"
              options={payTermsList.map((defaultPayTerm) => ({
                key: defaultPayTerm?.value,
                value: defaultPayTerm?.value,
                label: defaultPayTerm?.label,
              }))}
            />
          </Form.Item> 
          </>): (<>
            <Form.Item
            label="Standard Deposit Amount"
            name="field_standard_deposit_amount"
          >
            <Input
              type="number"
              placeholder="Standard Deposit Amount"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          </>)}
        </Form>
      </div>
    </div>
  );
};

export default AddonsPaymentSetupForm;
