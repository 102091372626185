import React from "react";
import { Table, Button, Divider } from "antd";

const columns = [
  {
    title: "Value",
    dataIndex: "value",
  },
];

function AssociatePermissions({ permissionsList }) {
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string.slice(1);
  }
  const data = permissionsList.map((val, index) => {
    return {
      key: index,
      value: capitalizeFirstLetter(val),
    };
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>Permissions</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default AssociatePermissions;
