import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, Card } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
import Horizontals from "../../components/Header/Horizontal_menu";
import TypographyGroup from "./TypographyGroup";
import ButtonGroup from "./ButtonGroup";
import FormDemo from "./FormDemo";
import StepForm from "./StepForm";
import DemoTable from "./DemoTable";
import React from "react";
import "./StyleGuide.less";
// import c3 from "c3";
// import "c3/c3.css";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function StyleGuide() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass =
    lg || lg === undefined
      ? "styleguide-section"
      : "mobileview styleguide-section";

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>

          <Content className="site-layout-background transparent-bg">
            <Row style={{ marginBottom: "20px" }} className="theme-color">
              <Col span={24}>
                <Card className="theme-color-wrap">
                  <h3 className="cardheading ">Color Scheme</h3>
                  <Row style={{ marginBottom: "20px" }}>
                    <Col span={6}>
                      <Card className="primary-color">
                        <p>#002233</p>
                      </Card>

                      <Card className="tertiary-color">
                        <p>#004466</p>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card>
                        <p>#ffffff</p>
                      </Card>
                      <Card className="secondary-color">
                        <p>#0099E5</p>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card className="background-shade">
                        <p>#f0f2f5</p>
                      </Card>
                      <Card className="font-shade">
                        <p>rgba(0, 0, 0, 0.85)</p>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Card>
                  <h3 className="cardheading ">Typography</h3>
                  <TypographyGroup />
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Card>
                  <h3 className="cardheading ">Button Group</h3>
                  <ButtonGroup />
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Card>
                  <h3 className="cardheading ">Form</h3>
                  <FormDemo />
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Card>
                  <h3 className="cardheading ">Steps</h3>
                  <StepForm />
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Card>
                  <h3 className="cardheading ">Table</h3>
                  <DemoTable />
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default StyleGuide;
