import {
  FilterOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
  AutoComplete,
  options,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "../../../others/Debounce/debounce";
import { getPrimaryContactsList } from "../../../redux/features/organizationFeatures/organizationSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import "./PrimaryContactSearch.less";

const PrimaryContactSearch = ({
  createId,
  editId,
  organizationNid,
  setPrimaryContactsList,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const [activeClass, setActiveClass] = useState(false);
  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [form] = Form.useForm();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchTerm = useDebounce(searchVal, 500);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const onFinishHandler = (values) => {
    let endpoint = "";
    let isObjectFiller = false;
    for (let prop in values) {
      if (!!values[prop]) {
        endpoint = endpoint + `&${prop}=${values[prop]}`;
        isObjectFiller = true;
      }
    }
    if (searchVal) {
      endpoint = endpoint + `&name=${searchVal}`;
    }
    if (isObjectFiller) {
      const reqHeaders = customRequestHeader({
        token: Token,
        defaultFormat: true,
        isHeaderAbsent: false,
        isUploadReq: false,
      });
      dispatch(
        getPrimaryContactsList({
          reqHeaders,
          url: `/api/v1/select/contact?org=${organizationNid}${endpoint}`,
        })
      );
      setIsLoading(true);
      setOpenDropdown(false);
      return;
    }
    setPrimaryContactsList([]);
    setOpenDropdown(false);
    return;
  };

  const clearFilter = () => {
    setSearchVal("");
    setPrimaryContactsList([]);
    setIsLoading(false);
    form.resetFields();
    setOpenDropdown(false);
    // const reqHeaders = customRequestHeader({
    //   token: Token,
    //   defaultFormat: true,
    //   isHeaderAbsent: false,
    //   isUploadReq: false,
    // });
    // dispatch(
    //   getPrimaryContactsList({
    //     reqHeaders,
    //     url: `/api/v1/select/contact?org=${organizationNid}`,
    //   })
    // );
  };
  const custumSearch = (
    <Form
      className="custom_search modalcustom_search"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        lg: 20,
        xs: 20,
      }}
      layout="horizontal"
      style={{ fontFamily: "'Poppins', sans-serif" }}
      form={form}
      onFinish={onFinishHandler}
    >
      <Form.Item
        label="Email"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="email"
      >
        <Input
          placeholder="Enter Email Address"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        />
      </Form.Item>

      <Form.Item
        label="Phone"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        name="phone"
      >
        <Input
          placeholder="Enter Phone Number"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        />
      </Form.Item>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          type="text"
          style={{ border: "none", opacity: "0.85" }}
          onClick={() => {
            clearFilter();
          }}
        >
          Clear Filter
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Search
        </Button>
      </div>
    </Form>
  );

  useEffect(() => {
    if (searchVal) {
      const reqHeaders = customRequestHeader({
        token: Token,
        defaultFormat: true,
        isHeaderAbsent: false,
        isUploadReq: false,
      });
      dispatch(
        getPrimaryContactsList({
          reqHeaders,
          url: `/api/v1/select/contact?org=${organizationNid}&name=${searchVal}`,
        })
      );
    } else {
      setPrimaryContactsList([]);
    }
  }, [debouncedSearchTerm]);

  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox modal_serchbox";

  return (
    <div className="horizontal_menu search_contact_modal">
      <Row className="logo-group">
        <Col sm={24} md={24} lg={24} className={mobileSearchToggle}>
          <div style={{ position: "relative" }}>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              // allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                className="search-contacts-modal"
                size="large"
                placeholder="Search Contacts"
                enterButton
                value={searchVal}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!value.trim()) {
                    setIsLoading(false);
                    setSearchVal("");
                    setPrimaryContactsList([]);
                  } else {
                    setIsLoading(true);
                    setSearchVal(value);
                  }
                }}
              />
            </AutoComplete>

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{
                maxWidth: "936px",
                width: "100%",
                paddingTop: "5px",
                paddingLeft: "15px",
                marginLeft: "90px",
              }}
              placement="bottomRight"
              trigger={["click"]}
              // className="advance-search-dropdown modal_advanced-searchbox"
              visible={openDropdown}
              className="advance-search-dropdown"
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                  setOpenDropdown(!openDropdown);
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  zIndex: "100s",
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{ opacity: "0.5", color: "#1A7f93" }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
            <Tooltip title="You can search for a contact by typing name. To search contact by using email and phone numbers use the advance filter.">
              <InfoCircleOutlined className="select_search_tooltip" />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PrimaryContactSearch;
