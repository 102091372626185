import { Divider, Switch, Table } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const DiscountInfo = ({ discountDetailsData, loadingDiscountDetailData }) => {
  const data = [
    {
      key: "discount_type",
      questions: "Discount Type",
      answers: discountDetailsData?.field_discount_type?.name || "",
    },
    {
      key: "discount_name",
      questions: "Discount Name",
      answers: discountDetailsData?.name?.name || "",
    },

    {
      key: "discount_amount",
      questions: "Discount Amount",
      answers: discountDetailsData?.field_discount_amount
        ? `$${addCommasInPricing(discountDetailsData?.field_discount_amount)}`
        : "",
    },

    {
      key: "limit_per_reservation",
      questions: "Limit Per Reservation",
      answers: discountDetailsData?.field_limit_per_reservation
        ? discountDetailsData?.field_limit_per_reservation
        : "",
    },
    {
      key: "limit_use_count",
      questions: "Limit Use Count",
      answers: discountDetailsData?.field_limit_use_count
        ? discountDetailsData?.field_limit_use_count
        : "",
    },
    {
      key: "promo_code",
      questions: "Promo Code",
      answers: discountDetailsData?.field_promo_code
        ? discountDetailsData?.field_promo_code
        : "",
    },
    {
      key: "description",
      questions: "Description",
      answers: discountDetailsData?.field_description
        ? discountDetailsData?.field_description
        : "",
    },
    {
      key: "agent_display",
      questions: "Agent Display",
      answers: (
        <Switch disabled checked={discountDetailsData?.field_agent_display} />
      ),
    },
    {
      key: "no_other_discount_allowed",
      questions: "No Other Discount Allowed",
      answers: (
        <Switch
          disabled
          checked={discountDetailsData?.field_discount_allowed}
        />
      ),
    },
    {
      key: "discount_percent",
      questions: "Discount Percent",
      answers: discountDetailsData?.field_discount_percent
        ? `${
            discountDetailsData?.field_discount_percent.includes(".00")
              ? Math.round(Number(discountDetailsData?.field_discount_percent))
              : discountDetailsData?.field_discount_percent
          }%`
        : "",
    },
    {
      key: "start_date",
      questions: "Start Date",
      answers: discountDetailsData?.field_start_date ? (
        <>
          <span className="">
            {moment(discountDetailsData?.field_start_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_small">
            {moment(`${discountDetailsData?.field_start_date} 00:00:00`).fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "end_date",
      questions: "End Date",
      answers: discountDetailsData?.field_end_date ? (
        <>
          <span className="">
            {moment(discountDetailsData?.field_end_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_small">
            {moment(`${discountDetailsData?.field_end_date} 23:59:00`).fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "minimum_reward_status",
      questions: "Minimum Reward Status",
      answers: discountDetailsData?.field_minimum_reward_status?.label,
    },
    {
      key: "maximum_reward_status",
      questions: "Maximum Reward Status",
      answers: discountDetailsData?.field_maximum_reward_status?.label,
    },

    {
      key: "web_enabled",
      questions: "Web Enabled",
      answers: (
        <Switch disabled checked={discountDetailsData?.field_web_enabled} />
      ),
    },

    {
      key: "minimum_age",
      questions: "Minimum Age",
      answers: discountDetailsData?.field_minimum_age
        ? `${discountDetailsData?.field_minimum_age} years`
        : "",
    },
    {
      key: "maximum_age",
      questions: "Maximum Age",
      answers: discountDetailsData?.field_maximum_age
        ? `${discountDetailsData?.field_maximum_age} years`
        : "",
    },
    {
      key: "min_occupancy",
      questions: "Min Occupancy",
      answers: discountDetailsData?.field_min_occupancy || "",
    },
    {
      key: "max_occupancy",
      questions: "Max Occupancy",
      answers: discountDetailsData?.field_max_occupancy || "",
    },
    // {
    //   key: "gender",
    //   questions: "Gender",
    //   answers: discountDetailsData?.field_gender?.label || "",
    // },
    {
      key: "military",
      questions: "Military",
      answers: discountDetailsData?.field_military?.length
        ? discountDetailsData?.field_military
            ?.map((military) => military?.label)
            .join(", ")
        : "",
    },
    {
      key: "web_only",
      questions: "Web Only",
      answers: (
        <Switch disabled checked={discountDetailsData?.field_web_only} />
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          Discount Information
        </h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
        loading={{
          spinning: loadingDiscountDetailData,
          indicator: <DataLoader />,
        }}
      />
    </div>
  );
};

export default DiscountInfo;
