import { Table, Select, Tag, Steps } from "antd";
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  CarOutlined,
} from "@ant-design/icons";
// import cruise_description from './Intakestable_cruisedesc';
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "./Report_elitelisting.less";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { decodeContent } from "../../../utils/contentParser";

function Report_elitelisting({ eliteTraveles, setEliteTraveles }) {
  const Option = Select.Option;
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const organizationType = searchParams.get("org_type");
  const organizationName = searchParams.get("name");
  const search = searchParams.get("search");

  const [listTotalCount, setListTotalCount] = useState(0);
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
    search,
    name: organizationName,
    org_type: organizationType,
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const columns = [
    {
      title: "Completed Events",
      dataIndex: "name",
      key: "1",
      // sorter: {
      //   compare: (a, b) => a.audience - b.audience,
      //   multiple: 1,
      // },
    },
    {
      title: "Code",
      dataIndex: "Code",
      key: "2",
    },
    {
      title: "Duration",
      dataIndex: "Duration",
      key: "3",
      // sorter: {
      //   compare: (a, b) => a.audience - b.audience,
      //   multiple: 1,
      // },
    },
    {
      title: "Destination",
      dataIndex: "Destination",
      key: "4",
    },
    {
      title: <div>Action</div>,
      dataIndex: "action",
      //   key: 'x',
      //   render: () => <a>Delete</a>,
    },
  ];
  const getCompletedEvents = async () => {
    const payload = {
      items_per_page: Number(urlParamsObject?.count),
      page: Number(urlParamsObject?.page) - 1,
      sort_by: "name",
      sort_order: urlParamsObject?.order,
    };
    const queryParam = `?items_per_page=${payload.items_per_page}&page=${payload.page}`;
    const data = await drupalAxiosRequest(
      "get",
      `/api/v1/list/completed_events/listing${queryParam}`,
      customRequestHeader({ defaultFormat: true })
    );
    const customData = data?.data?.rows?.map((val) => {
      return {
        name: decodeContent(val?.name),
        Code: val.field_event_code,
        Duration: val.duration,
        Destination: val?.field_destination?.title,
        action: (
          <>
            <div className="action_flex action_end">
              <Link to={`/Report_elitetravelerdetails?id=${val.uuid}`}>
                <a href="#" className="member_hover">
                  View Upgraded Travelers
                </a>
              </Link>
            </div>
          </>
        ),
        exp_row: false,
      };
    });
    setEliteTraveles(customData);
  };
  useEffect(() => {
    getCompletedEvents();
  }, [urlParamsObject]);

  return (
    <>
      <Table
        className="table_tour table_pagination"
        columns={columns}
        dataSource={eliteTraveles}
        pagination={{
          current: Number(urlParamsObject.page),
          pageSize: urlParamsObject.count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: true,
          total: listTotalCount,
          position: ["bottomRight"],
          onChange: (pageNo, pageSize) => {
            setUrlParamsObject({
              ...urlParamsObject,
              page: pageNo,
              count: pageSize,
            });
            // setPage(pageNo);
            // setItemsPerPage(pageSize);
            // setSearchQuery({
            //   count: pageSize,
            //   page: pageNo,
            // });
          },
        }}
      />
    </>
  );
}

export default Report_elitelisting;
