import {
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  Table as AntTable,
  message,
} from "antd";
// import { CKEditor } from "ckeditor4-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomUpload from "../../../../../others/CustomUpload/CustomUpload";
import { customRequestHeader } from "../../../../../utils/cutomRequestHeader";
import { sendEmailToTravellerApi } from "../../../../../apis/reservationApi";
import { ReservationDetailRoute } from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";

const TravellerEmailForm = ({
  form,
  setSendLoading,
  emailData,
  loadingEmailData,
}) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadDocumentId, setuploadDocuementId] = useState("");
  const [editor, setEditor] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [emailTypes, setEmailTypes] = useState([]);
  const [loadingEmailTypes, setLoadingEmailTypes] = useState(false);
  const navigate = useNavigate();
  const reservationUuid = searchParams.get("id");
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid } = authorization;
  const [attachmentData, setAttachmentData] = useState([]);
  const editorRef = useRef(null);
  const [editorInitData, setEditorInitData] = useState(
    emailData?.emailBody || ""
  );
  const [editorInstance, setEditorInstance] = useState(null);

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };
  // const [form] = Form.useForm();
  const columns = [
    {
      title: "Document",
      dataIndex: "filename",
    },
    {
      title: "Attach?",
      dataIndex: "confirmation_attachment",
      render: (value, recordObj) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              setAttachmentData(
                attachmentData?.map((attachment) =>
                  attachment?.fileid === recordObj.fileid
                    ? {
                        ...attachment,
                        confirmation_attachment:
                          !attachment.confirmation_attachment,
                      }
                    : attachment
                )
              );
            }}
          />
        );
      },
    },
  ];
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  let keysToInclude = [
    "terms_and_conditions_template",
    "booking_cofirmation_template",
    "payment_confirmation_template",
  ];

  useEffect(() => {
    if (emailData && Object.keys(emailData).length) {
      setEmailTypes(
        emailData?.allTypes
          ? Object.entries(emailData?.allTypes).reduce((acc, [key, value]) => {
              if (keysToInclude.includes(key)) {
                acc.push({ key, value });
              }
              return acc;
            }, [])
          : []
      );

      if (editorRef.current) {
        editorRef.current.editor.setData(emailData?.emailBody);
      }

      form.setFieldsValue({
        name: emailData?.partyName,
        email: emailData?.partyEmail,
        email_type: emailData?.emailType?.key,
        email_subject: emailData?.emailSubject,
      });

      if (emailData?.attachements?.length) {
        setAttachmentData(
          emailData?.attachements?.map((file) => {
            return {
              filename: file?.filename,
              confirmation_attachment: file?.confirmation_attachment,
              fileid: file?.fileid,
              fileuuid: file?.fileuuid,
              fileUrl: file?.fileUrl,
            };
          })
        );
      }
    }
  }, [emailData]);

  const createEmailActivity = async (data) => {
    setSendLoading(true);
    const res = await sendEmailToTravellerApi(data);
    if (res.status) {
      message.success("Email Sent Successfully");
      navigate(`${ReservationDetailRoute}?id=${reservationUuid}`);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setSendLoading(false);
  };

  const onFinishHandler = (values) => {
    const {
      name,
      email,
      email_subject,
      copy_to_others,
      email_type,
    } = values;
    const email_body = editorRef.current?.editor.getData();
    let attachments = [
      ...attachmentData
        ?.filter((attachment) => attachment.confirmation_attachment)
        ?.map((item) => ({
          filename: item?.filename,
          fileid: item?.fileid,
          fileuuid: item?.fileuuid,
          fileUrl: item?.fileUrl,
        })),
      ...fileList.map((item) => ({
        filename: item?.name,
        fileid: item?.fileid.toString(),
        fileuuid: item?.uid,
        fileUrl: item?.fileUrl,
      })),
    ];
    const payload = {
      data: {
        allTypes: emailData?.allTypes,
        emailType: emailData?.emailType,
        id: emailData?.id,
        rez_id: emailData?.rez_id,
        copy_to_others: !!copy_to_others,
        entity_type: email_type,
        entity_id: emailData?.entity_id,
        traveler_id: emailData?.traveler_id,
        partyName: name,
        partyEmail: email,
        emailSubject: email_subject,
        emailBody: email_body,
        attachements: attachments,
      },
      status: true,
    };
    createEmailActivity(payload);
  };

  const handleSelectionChange = (value) => {
    setSelectedOption(value);
    searchParams.set("emailType", value);
    setSearchParams(searchParams);
  };

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  return (
    <div className="addnote_form">
      <Spin spinning={loadingEmailData} size="large">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 3,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Name"
            name="name"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please fill name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            className="asterick-align"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
                validateTrigger: "onSubmit",
              },
              {
                required: true,
                message: "Please enter email address",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Copy Other Guest(s)"
            name="copy_to_others"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Email Type" name="email_type">
            <Select
              options={emailTypes?.map((item) => ({
                key: item?.key,
                value: item?.key,
                label: item?.value,
              }))}
              loading={loadingEmailTypes}
              onSelect={(value) => handleSelectionChange(value)}
            ></Select>
          </Form.Item>

          <Form.Item
            label="Email Subject"
            name="email_subject"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please enter email subject",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="textarea_row">
            <Form.Item
              className="ckeitor-field-wrapper"
              label="Email Body"
              name="email_body"
              rules={[
                {
                  required: true,
                  message: "Please enter email body",
                },
              ]}
            >
              <CKEditor
                editor={ClassicEditor}
                ref={editorRef}
                config={editorConfig}
                data={editorInitData}
                onReady={(editor) => {
                  setEditorInstance(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorInitData(data);
                  form.setFieldsValue({ email_body: data });
                }}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item className="asterick=align" label="Attachmentes">
              <AntTable
                columns={columns}
                dataSource={attachmentData}
                pagination={false}
              />
            </Form.Item>
          </div>
          <div className="textarea_row">
            <Form.Item className="asterick-align" label="Attach Files">
              <div>
                <CustomUpload
                  fileList={fileList}
                  isMultiple={true}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/media/task_documents/field_media_file"
                  emailDocument={true}
                  // setuploadDocuementId={setuploadDocuementId}
                  // linkMediaUrl="/api/v1/media/task_documents"
                  // linkMediaRelation="field_media_file"
                  // linkMediaType="media--task_documents"
                />
              </div>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default TravellerEmailForm;
