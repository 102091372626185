import { Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";

function CancellationReasons({
  form,
  cancelDetails,
  onFinishHandler,
  loadingCancelDetails,
}) {
  const { reasons_covered, reasons_not_covered } =
    cancelDetails?.cancellation_reasons || {};
  const [reasonsCoveredList, setReasonsCoveredList] = useState([]);
  const [reasonsNotCoveredList, setReasonsNotCoveredList] = useState([]);
  const [loadingCoveredList, setLoadingCoveredList] = useState(false);
  const [loadingNotCoveredList, setLoadingNotCoveredList] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "reservation_cancellation_reasons",
      setReasonsCoveredList,
      setLoadingCoveredList
    );
    getTaxonomyList(
      "res_cancellation_reasons_not",
      setReasonsNotCoveredList,
      setLoadingNotCoveredList
    );
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      reasons_covered: reasons_covered?.length ? reasons_covered?.map(listItem => listItem?.uuid) : [],
      reasons_not_covered: reasons_not_covered?.length ? reasons_not_covered?.map(listItem => listItem?.uuid) : [],
    });
  }, [cancelDetails]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="m-0">Cancellation Reasons</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingCancelDetails} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinishHandler}
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                className="asterick-align"
                label="Reasons Covered"
                name="reasons_covered"
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  options={reasonsCoveredList.map((reason) => ({
                    key: reason.id,
                    value: reason.id,
                    label: reason.name,
                  }))}
                  loading={loadingCoveredList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="textarea_row">
                <Form.Item
                  className="asterick-align"
                  label="Reasons Not Covered"
                  name="reasons_not_covered"
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    options={reasonsNotCoveredList.map((reason) => ({
                      key: reason.id,
                      value: reason.id,
                      label: reason.name,
                    }))}
                    loading={loadingNotCoveredList}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default CancellationReasons;
