import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getPaymentDetailApi } from "../../../apis/reservationApi";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import PaymentBasicDetail from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PaymentDetail/PaymentBasicDetails";
import PaymentDetailHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PaymentDetail/PaymentDetailHeader";
import PlannedPaymentsApplied from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PaymentDetail/PlannedPaymentsApplied";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import SystemInformation from "../../../others/CommonSystemInformation/SystemInformation";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content, Sider } = Layout;

function PaymentDetailPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("id");
  const paymentId = searchParams.get("paymentId");
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [paymentData, setPaymentData] = useState({});
  const [loadingPaymentData, setLoadingPaymentData] = useState(false);

  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Details", path: `${ReservationDetailRoute}?id=${reservationId}` },
    { title: "Payment Detail", path: "" },
  ];

  useEffect(() => {
    getPaymentDetails(paymentId);
  }, []);

  const getPaymentDetails = async (paymentId) => {
    setLoadingPaymentData(true);
    const res = await getPaymentDetailApi(paymentId);
    if (res.status) {
      setPaymentData(res?.data?.data);
      setLoadingPaymentData(false);
    } else {
      setPaymentData({});
      setLoadingPaymentData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <PaymentDetailHeader
              paymentData={paymentData}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <PaymentBasicDetail
              paymentData={paymentData}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <PlannedPaymentsApplied
              paymentData={paymentData}
              loadingPaymentData={loadingPaymentData}
              reservationId={reservationId}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            {console.log({ paymentData })}
            <SystemInformation
              user_id={paymentData?.user_id || {}}
              modified_by={paymentData?.field_last_modified_by || {}}
              created={paymentData?.created}
              changed={paymentData?.changed}
              loading={loadingPaymentData}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PaymentDetailPage;
