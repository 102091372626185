import { Input, Select, Table } from "antd";
import "antd/dist/antd.css";
import "./EventReservationsTravelersTable.less";
import { useEffect, useState } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";
import { useDebounce } from "../../../../others/Debounce/debounce";
const Option = Select.Option;
const { Search } = Input;

function EventReservationsTravelersTable({
  travelerList,
  loadingTravelerList,
  urlParamsObject,
  setUrlParamsObject,
  listCount,
}) {
  const [idSearchVal, setIdSearchVal] = useState(urlParamsObject?.res_id);
  const [nameSearchVal, setNameSearchVal] = useState(urlParamsObject?.name);
  const idDebounceSearch = useDebounce(idSearchVal, 500);
  const nameDebounceSearch = useDebounce(nameSearchVal, 500);

  useEffect(() => {
    if (idDebounceSearch !== undefined) {
      let searchObj = { ...urlParamsObject, res_id: idSearchVal, page: 1 };
      if (idSearchVal === "") {
        delete searchObj.res_id;
      }
      setUrlParamsObject(searchObj);
    }
  }, [idDebounceSearch]);

  useEffect(() => {
    if (nameDebounceSearch !== undefined) {
      let searchObj = { ...urlParamsObject, name: nameSearchVal, page: 1 };
      if (nameSearchVal === "") {
        delete searchObj.name;
      }
      setUrlParamsObject(searchObj);
    }
  }, [nameDebounceSearch]);

  const columns = [
    {
      title: (
        <>
          <div style={{ paddingBottom: "8px" }}>Reservation ID</div>
          <Input
            placeholder="Search"
            value={idSearchVal}
            onInput={(e) => {
              setIdSearchVal(e.target.value);
            }}
          />
        </>
      ),
      dataIndex: "res_id",
      key: "1",
    },
    {
      title: (
        <>
          <div style={{ paddingBottom: "8px" }}>Traveler Name</div>
          <Input
            placeholder="Search"
            value={nameSearchVal}
            onInput={(e) => {
              setNameSearchVal(e.target.value);
            }}
          />
        </>
      ),
      dataIndex: "name",
      key: "2",
      width: "30%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: "10%",
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "4",
      width: "10%",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "5",
      width: "10%",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      key: "6",
      width: "10%",
    },
  ];

  return (
    <>
      <Table
        className="table_listing icon_change reservation_main-table"
        columns={columns}
        pagination={{
          current: Number(urlParamsObject?.page),
          pageSize: Number(urlParamsObject?.count),
          showSizeChanger: true,
          total: Number(listCount),
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setUrlParamsObject({
              ...urlParamsObject,
              page: page,
              count: pageSize,
            });
          },
        }}
        loading={{ spinning: loadingTravelerList, size: "large" }}
        dataSource={travelerList}
      />
    </>
  );
}

export default EventReservationsTravelersTable;
