import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Divider,
} from "antd";

function onChange(time, timeString) {
    console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function CruisePostform_five() {
    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);

    useEffect(() => {
        form.validateFields(["nickname"]);
    }, [checkNick, form]);

    const onCheckboxChange = (e) => {
        setCheckNick(e.target.checked);
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log("Success:", values);
        } catch (errorInfo) {
            console.log("Failed:", errorInfo);
        }
    };

    const { Panel } = Collapse;

    const onChange = (key) => {
        console.log(key);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };


    // other 

    const onGenderChange = (value) => {
        switch (value) {
            case 'male':
                form.setFieldsValue({
                    note: 'Hi, man!',
                });
                return;
            case 'female':
                form.setFieldsValue({
                    note: 'Hi, lady!',
                });
                return;
            case 'other':
                console.log("first111")
                form.setFieldsValue({
                    note: 'Hi there!',
                });
                console.log("antd")
                break;
            default:
        }
    };
    const onFinish = (values) => {
        console.log(values);
    };
    const onReset = () => {
        form.resetFields();
    };
    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };





    return (
        <div className="">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <h4 className="mb-0 ms-0">Staffing & Vehicles</h4>

            </div>
            <Divider className="global_divider mb-0" />



            <div style={{ marginTop: "24px" }} />
            <Form
                {...layout}
                labelCol={{
                    sm: 24,
                    md: 6,
                    lg: 6,
                    xl: 5,
                    xxl: 4,
                }}
                wrapperCol={{
                    sm: 24,
                    md: 14,
                    lg: 14,
                    xl: 14,
                    xxl: 12,
                }}
                layout="horizontal" form={form} name="control-hooks" onFinish={onFinish}
            >

                <Form.Item
                    name="gender"
                    label="Guide Request"

                >
                    <Select
                        mode="multiple"
                        showArrow
                        onChange={(e) => {
                            e.includes('other') ? onGenderChange() : console.log("sec")
                        }}
                        allowClear
                    >
                        <Option value="Option 1">Option 1</Option>
                        <Option value="Option 2">Option 2</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="# of Travel Coordinators" name="Coordinator">
                    <Input type="number" />
                </Form.Item>

                <Form.Item label="Free VIP Vehicle & Driver" name="Free VIP Vehicle & Driver">
                    <Input type="number" />
                </Form.Item>
                <Form.Item label="Senior Sound Tech" name="Senior Sound Tech">
                    <Input type="number" />
                </Form.Item>
                <Form.Item label="Sound Techs" name="Sound Techs">
                    <Input type="number"/>
                </Form.Item>
                <Form.Item label="Sound Assistant" name="Sound Assistant">
                    <Input type="number" />
                </Form.Item>
                <Form.Item label="Sound Tech Van" name="Sound tech van">
                    <Radio.Group name="radiogroup">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Luggage Truck" name="Luggage Truck">
                    <Radio.Group name="radiogroup">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                >
                    {({ getFieldValue }) =>

                        getFieldValue('gender')?.includes('other') ? (
                            <Form.Item
                                name="Others"
                                label="Others"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <div className="textarea_row">
                    <Form.Item label="Notes" name="Notes">
                        <TextArea rows={4} />
                    </Form.Item>

                </div>
            </Form>
        </div>
    );
}

export default CruisePostform_five;
