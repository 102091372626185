import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";
import "./Res_guestprofile.less";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Reservation_operationsnotes() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Operations - Notes</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <div className="textarea_row">
              <Form.Item label="Traveler Comments" name="traveler_comments" >
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <div className="textarea_row">
              <Form.Item label="Event Coordinator Notes and Potential Challenges" name="event_coordinator" >
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <div className="textarea_row">
              <Form.Item label="Event Partner Notes" name="event_partner">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <div className="textarea_row">
              <Form.Item label="Supplier Notes" name="Supplier Notes">
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <div className="textarea_row">
              <Form.Item label="Signature Service Notes" name="signature_service">
                <TextArea rows={4} />
              </Form.Item>
            </div>


            <div className="textarea_row">
              <Form.Item label="Medical Notes" name="medical_service">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Reservation_operationsnotes;
