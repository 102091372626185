import {
  Table,
  Button,
  Divider,
  Form,
  Modal,
  Menu,
  Dropdown,
  Space,
  Select,
  message,
} from "antd";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import Reservationguests_table from "./Reservationguests_table";
import ReservationGuestsTable from "./ReservationGuestsTable";
import {
  addTraveler,
  getContactLists,
  getTravelersData,
} from "../../../../../apis/reservationApi";
import TravelerSelectContactModal from "./TravelerSelectContactModal";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

function TravelersListing() {
  const [showTable, setShowTable] = useState(false);
  const [travelersData, setTravelersData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [allTravelersData, setAllTravelersData] = useState([]);
  const [reservationId, setReservationID] = useState();
  const [loading, setLoading] = useState();
  const { highlightSection } = useSelector((state) => state.reservation);

  const getTravelers = async (id) => {
    const response = await getTravelersData(id);
    if (response && response.status) {
      // setTravelersData(response?.data?.data)
      const { data } = response?.data;
      setAllTravelersData(data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchParams) {
      let id = searchParams.get("id");
      setReservationID(id);
      getTravelers(id);
      setLoading(true);
    }
  }, [searchParams]);

  const refreshTravelerData = () => {
    getTravelers(reservationId);
    setAllTravelersData([]);
    setLoading(true);
  };

  useEffect(() => {
    let travelers = [];
    if (allTravelersData) {
      allTravelersData.forEach((element, index) => {
        travelers.push({
          key: index,
          label: (
            <>
              {element.is_payee ? (
                <button class="button">
                  <span>Payee</span>
                </button>
              ) : (
                <button class="mark_payee">
                  <span>Mark Payee</span>
                </button>
              )}
              <span className="document_pdf adult_class">{element.name}</span>
            </>
          ),
          input: (
            <TravelerSelectContactModal
              index={index}
              setShowTable={setShowTable}
              showTable={showTable}
              element={element}
              refreshTravelerData={refreshTravelerData}
            />
          ),
        });
      });
    }
    setTravelersData(travelers);
  }, [allTravelersData]);

  const addNewTraveler = async (type) => {
    const response = await addTraveler(reservationId, type);
    if (response && response.status) {
      message.success("New Traveler Added");
      refreshTravelerData();
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: <a onClick={() => addNewTraveler("adult")}>Adult</a>,
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: <a onClick={() => addNewTraveler("child")}>Child</a>,
          key: "1",
        },
      ]}
    />
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Travelers</h4>
        {permissionChecker("add/edit reservation") ? (
          <span>
            <Link to={`/reservation/details/traveler?id=${reservationId}`}>
              {" "}
              <Button
                danger={
                  !!highlightSection?.select_relation ||
                  !!highlightSection?.travelers
                }
                icon={<EditOutlined />}
              />
            </Link>
          </span>
        ) : null}
      </div>
      <Divider className="global_divider" />
      {showTable ? (
        <>
          {/* < Reservationguests_table /> */}
          <ReservationGuestsTable />
        </>
      ) : (
        <>
          <ReservationGuestsTable
            allTravelersData={allTravelersData}
            setShowTable={setShowTable}
            showTable={showTable}
            refreshTravelerData={refreshTravelerData}
            loading={loading}
          />

          <div className="button_bottom">
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayClassName="guest_dropdown"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    className="button_hover"
                    type="dashed"
                    icon={<PlusOutlined />}
                  >
                    Add Traveler
                  </Button>
                </Space>
              </a>
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );
}

export default TravelersListing;
