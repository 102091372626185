import { ImportOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Row, Space, Upload } from "antd";
import React, { useState } from "react";
import ImportDraggerUpload from "../../../others/CustomUpload/ImportDraggerUpload";
import EventImportDraggerUpload from "./EventImportDraggerUpload";
const { Dragger } = Upload;

const ImportEvent = ({ open,
  setOpen,
  setIsModalOpen,
  setErrorList,
  errorList,}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(true);
  };
  var stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  return (
    <>
      <Button icon={<ImportOutlined />} onClick={showDrawer} />
      <Drawer
        title="Import Event"
        maxWidth={425}
        closable={!isLoading}
        onClose={onClose}
        maskClosable={false}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
      >
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Upload File" name="Upload File">
                <EventImportDraggerUpload
                  fileList={fileList}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/media/document/field_media_document"
                  fileProcessingUrl="/api/rest/event-import"
                  entityType="contact"
                  onClose={onClose}
                  setOpen={setOpen}
                  setIsModalOpen={setIsModalOpen}
                  setErrorList={setErrorList}
                  type="event"
                />
              </Form.Item>
              {errorList.length ? (
                <>
                  <ul>
                    {errorList.map((val) => {
                      return (
                        <>
                            <span style={{ color: "red" }} dangerouslySetInnerHTML={{__html: val}}/>
                        </>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ImportEvent;
