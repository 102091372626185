import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changePasswordApi,
  getMemeberDataApi,
  memberLoginApi,
  resetPasswordApi,
  sendForgetPasswordOtpApi,
} from "../../../apis/authentication";

export const memberLogin = createAsyncThunk(
  "authentication/memberLogin",
  async (loginData) => {
    let response = await memberLoginApi(loginData);
    return response;
  }
);

export const getMemberData = createAsyncThunk(
  "authentication/getMemberData",
  async (payload) => {
    let response = await getMemeberDataApi(payload);
    return response;
  }
);
export const changeUserPassword = createAsyncThunk(
  "authentication/changePassword",
  async (payload) => {
    let response = await changePasswordApi(payload);
    return response;
  }
);
export const sendForgetPasswordOtp = createAsyncThunk(
  "authentication/sendForgetPasswordOtp",
  async (payload) => {
    let response = await sendForgetPasswordOtpApi(payload);
    return response;
  }
);

export const resetUserPassword = createAsyncThunk(
  "authentication/resetUserPassword",
  async (payload) => {
    let response = await resetPasswordApi(payload);
    return response;
  }
);
const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    login: {
      loginResponse: {},
      loadingLogin: false,
      isMemberLoggedIn: false,
      loggedInMemberData: {},
    },
    memberData: {
      memberDataResponse: {},
      loadingMemberData: false,
    },
    changePassword: {
      changePasswordResponse: {},
      loadingChangePassword: false,
    },
    forgetPasswordOtp: {
      forgetPasswordOtpResponse: {},
      loadingForgetPasswordOtp: false,
    },
    resetPassword: {
      resetPasswordResponse: {},
      loadingResetPassword: false,
    },
  },
  reducers: {
    loggedInUser: (state, action) => {
      state.login.isMemberLoggedIn = true;
      state.login.loggedInMemberData = action.payload;
    },
    loggedOutUser: (state, action) => {
      state.login.isMemberLoggedIn = false;
      state.login.loggedInMemberData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(memberLogin.pending, (state, action) => {
      state.login.loadingLogin = true;
    });
    builder.addCase(memberLogin.fulfilled, (state, action) => {
      state.login.loadingLogin = false;
      state.login.loginResponse = action.payload;
      // state.login.isLoginApiCalled = true;
    });
    builder.addCase(memberLogin.rejected, (state, action) => {
      console.log("login failed");
      state.login.loadingLogin = false;
      state.login.loginResponse = action.payload;
    });
    builder.addCase(getMemberData.pending, (state, action) => {
      state.memberData.loadingMemberData = true;
    });
    builder.addCase(getMemberData.fulfilled, (state, action) => {
      console.log("memberdata response", action.payload);
      state.memberData.memberDataResponse = {};
      state.memberData.loadingMemberData = false;
    });
    builder.addCase(changeUserPassword.pending, (state, action) => {
      state.changePassword.loadingChangePassword = true;
    });
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      state.changePassword.changePasswordResponse = action.payload;
      state.changePassword.loadingChangePassword = false;
    });
    builder.addCase(sendForgetPasswordOtp.pending, (state, action) => {
      state.forgetPasswordOtp.loadingForgetPasswordOtp = true;
    });
    builder.addCase(sendForgetPasswordOtp.fulfilled, (state, action) => {
      state.forgetPasswordOtp.forgetPasswordOtpResponse = action.payload;
      state.forgetPasswordOtp.loadingForgetPasswordOtp = false;
    });
    builder.addCase(resetUserPassword.pending, (state, action) => {
      state.resetPassword.loadingResetPassword = true;
    });
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      state.resetPassword.resetPasswordResponse = action.payload;
      state.resetPassword.loadingResetPassword = false;
    });
  },
});
export const { loggedInUser, loggedOutUser } = authenticationSlice.actions;
export default authenticationSlice.reducer;
