import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { EventRoute } from "../../../../url-routes/urlRoutes";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const AccountingInformation = ({ eventData, eventId, loading }) => {
  const data = [
    {
      key: "1",
      label: "COA Brand",
      input: eventData?.field_coa_brand?.name,
    },
    {
      key: "2",
      label: "COA Trip",
      input: eventData?.field_coa_trip,
    },
    {
      key: "3",
      label: "Commission Calculation",
      input: eventData?.field_commission_calculation?.name,
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Accounting Information</h4>
        <span>
          {permissionChecker("add/edit event") ? (
            <Link to={`${EventRoute}?editId=${eventId}&step=4`}>
              {" "}
              <Button type="y" icon={<EditOutlined />} />
            </Link>
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default AccountingInformation;
