import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../../../../url-routes/urlRoutes";

function CancelRservationHeader({
  reservationDetails,
  loadingResDetails,
  form,
  setCurrentStatus,
  loadingUpdateRes,
  isEdit,
}) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined className="back-icon-profile" />
          {loadingResDetails ? (
            <Skeleton.Input active={true} style={{ width: "400px" }} />
          ) : (
            <div className="header_res">
              <p>{isEdit ? "Edit " : ""}Cancel Reservation</p>
              <Link
                className="header_smalltext"
                to={`${ReservationDetailRoute}?id=${reservationDetails?.id}`}
              >
                {reservationDetails?.name || ""}
              </Link>
            </div>
          )}
        </h3>
        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          {reservationDetails?.reservation_status?.key === "cancelled" &&
          isEdit ? (
            <Button
              loading={loadingUpdateRes}
              disabled={loadingResDetails}
              type="primary"
              onClick={() => {
                setCurrentStatus("cancelled");
                form.submit();
              }}
            >
              Save
            </Button>
          ) : null}
          {reservationDetails?.reservation_status?.key === "active" ||
          reservationDetails?.reservation_status?.key === "revise" ? (
            <Button
              loading={loadingUpdateRes}
              disabled={loadingResDetails}
              type="primary"
              onClick={() => {
                setCurrentStatus("cancel_pending");
                form.submit();
              }}
            >
              Request Cancellation
            </Button>
          ) : null}
          {reservationDetails?.reservation_status?.key === "cancel_pending" ? (
            <Button
              loading={loadingUpdateRes}
              disabled={loadingResDetails}
              type="primary"
              onClick={() => {
                setCurrentStatus("cancelled");
                form.submit();
              }}
            >
              Confirm Cancellation
            </Button>
          ) : null}
          {reservationDetails?.reservation_status?.key === "cancel_pending" ? (
            <Button
              loading={loadingUpdateRes}
              disabled={loadingResDetails}
              type="primary"
              onClick={() => {
                setCurrentStatus("call_off_cancellation");
                form.submit();
              }}
            >
              Call off Cancellation
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CancelRservationHeader;
