import { Grid, Layout } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import FeeDiscountCategoriesEdit from "../../../components/Events/EventDetailsTabs/FeeDiscountCategory/FeeDiscountCategoriesEdit";
import FeeDiscountCategoriesHeader from "../../../components/Events/EventDetailsTabs/FeeDiscountCategory/FeeDiscountCategoriesHeader";
import EventsSearchbar from "../../../components/Events/EventsSearchbar";
import {
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";
const { Header, Content, Sider } = Layout;

const DiscountCategoriesEditPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoriesListData, setCategoriesListData] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState([]);
  const eventId = searchParams.get("id");
  const discountId = searchParams.get("discountId");
  const addonId = searchParams.get("addonId");
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    {
      title: "Discounts",
      path: `${EventDetailsRoute}?id=${eventId}#Discounts`,
    },
    { title: "Discounts Categories", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <FeeDiscountCategoriesHeader
              discountType={"event"}
              eventId={eventId}
              discountId={discountId}
              updatedPayload={updatedPayload}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <FeeDiscountCategoriesEdit
              discountType={"event"}
              eventId={eventId}
              discountId={discountId}
              loadingCategories={loadingCategories}
              setLoadingCategories={setLoadingCategories}
              categoriesListData={categoriesListData}
              setCategoriesListData={setCategoriesListData}
              setUpdatedPayload={setUpdatedPayload}
              updatedPayload={updatedPayload}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DiscountCategoriesEditPage;
