import { Button } from "antd";
import React from "react";
import "./CreateOrEditEventsHeader.less";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  pressEventNext,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";

const CreateOrEditEventsHeader = ({
  form1,
  form2,
  form3,
  form4,
  createId,
  editId,
}) => {
  const { eventCreation } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingEventCreation } = eventCreation;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get("step");
  const convertToEvent = searchParams.get("convertToEvent");

  const onSavehandler = () => {
    if (currentStep === "1" || currentStep === null) {
      dispatch(pressEventNext(""));
      dispatch(updateEventStep("1"));
      form1.submit();
    }
    if (currentStep === "2") {
      dispatch(pressEventNext(""));
      dispatch(updateEventStep("2"));
      form2.submit();
    }
    if (currentStep === "3") {
      dispatch(pressEventNext(""));
      dispatch(updateEventStep("3"));
      form3.submit();
    }
    if (currentStep === "4") {
      dispatch(pressEventNext(""));
      dispatch(updateEventStep("4"));
      form4.submit();
    }
  };
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() => {
                let route =
                  createId || editId
                    ? `${EventDetailsRoute}?id=${createId || editId} `
                    : -1;
                navigate(route);
              }}
              style={{ color: "#000" }}
            />
            <p>{editId ? "Edit" : "Add"} Event</p>
          </div>
        </h3>

        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              let route =
                (createId || editId) && !convertToEvent
                  ? `${EventDetailsRoute}?id=${createId || editId} `
                  : -1;
              navigate(route);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSavehandler();
            }}
            loading={loadingEventCreation}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrEditEventsHeader;
