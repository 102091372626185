const ENV = process.env.REACT_APP_PUBLIC_MODE || "development";

const CONFIG = {
  development: {
    BASE_URL: "https://dev-querybuilder.oslabs.app",
    QUERY_LISTING_ID: "a6cb993c-1df1-4e82-916c-04eff332ff37"
  },
  stage: {
    BASE_URL: "https://inspirationtravel-booking-querybuilder.mmgystage.com",
    QUERY_LISTING_ID: "a6cb993c-1df1-4e82-916c-04eff332ff37"
  },
  prod: {
    BASE_URL: "https://booking-querybuilder.inspirationtravel.com",
    QUERY_LISTING_ID: "9a61f970-51b8-4f9f-8157-fffcb6939319"
  },
  oldStage: {
    BASE_URL: "https://stage-querybuilder.oslabs.app",
    QUERY_LISTING_ID: "a6cb993c-1df1-4e82-916c-04eff332ff37"
  },
 
};

export default CONFIG[ENV];