import { Table, Switch } from "antd";
import React from "react";
import DataLoader from "../../../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Category Code",
    dataIndex: "category_code",
  },

  {
    title: "Category Name",
    dataIndex: "category_name",
  },

  {
    title: "Active?",
    dataIndex: "active",
  },
  {
    title: "Default?",
    dataIndex: "default",
  },

  {
    title: "Required?",
    dataIndex: "required",
  },
];

const EditDependentCategory = ({
  editableCategories,
  isLoading,
  payloadData,
}) => {
  function reduceNestedArray(data) {
    const result = [];
    let indexCounter = 0;

    function processItem(item, index) {
      result.push({
        key: item?.addon_uuid,
        category_code: (
          <>
            <p className="document_pdf">{item?.addon_name}</p>
          </>
        ),
        category_name: "",
        active: "",
        default: "",
        required: "",
      });

      item?.category?.forEach((category, i) => {
        const key = indexCounter++;
        const { dep_cat } = category || {};
        result.push({
          key,
          category_code: category?.cat_code,
          category_name: category?.cat_name,
          active: (
            <>
              <Switch
                onChange={(e) => {
                  onChange(e, "active", key);
                }}
                defaultChecked={dep_cat?.[0]?.dep_category_active || false}
              />
            </>
          ),
          default: (
            <>
              <Switch
                onChange={(e) => {
                  onChange(e, "default", key);
                }}
                defaultChecked={dep_cat?.[0]?.dep_category_default || false}
              />
            </>
          ),
          required: (
            <>
              <Switch
                onChange={(e) => {
                  onChange(e, "required", key);
                }}
                defaultChecked={dep_cat?.[0]?.dep_category_required || false}
              />
            </>
          ),
        });
        updatePayloadData(category);
        // indexCounter++;
      });
    }
    data.forEach((element, index) => {
      processItem(element, index);
    });

    return result;
  }

  const updatePayloadData = (category, index) => {
    const data = {
      dependent_category_name: category?.cat_name,
      dependent_category_code: category?.cat_code,
      active: category?.dep_cat?.[0]?.dep_category_active || false,
      default: category?.dep_cat?.[0]?.dep_category_default || false,
      required: category?.dep_cat?.[0]?.dep_category_required || false,
      add_on_category_uuid: category?.cat_uuid,
    };
    if (category?.dep_cat?.length) {
      data["dependent_category_uuid"] =
        category?.dep_cat?.[0]?.dep_category_uuid;
    }
    payloadData.push(data);
  };

  const onChange = (checked, state, index) => {
    payloadData[index][state] = checked;
  };
  // const reducedArray = useMemo(
  //   () => reduceNestedArray(editableCategories?.data || []),
  //   []
  // );
  const reducedArray = reduceNestedArray(editableCategories?.data || []);

  return (
    <div>
      <Table
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={reducedArray}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

export default EditDependentCategory;
