import { Collapse, Divider, Button, message } from "antd";
import CategoryDiscountListing from "./CategoryDiscountListing";
import {
  EditOutlined,
  FileOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { EventCategoryDetailsDiscountRoute } from "../../../../url-routes/urlRoutes";
import {
  CategoryDiscountListingApi,
  eventDiscountListingApi,
} from "../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { Panel } = Collapse;

const Discounts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const [DiscountListData, setDiscountListData] = useState([]);
  const [loadingDiscountList, setLoadingDiscountList] = useState(false);

  useEffect(() => {
    getEventDiscountList(categoryId);
  }, [categoryId]);

  const getEventDiscountList = async (cId) => {
    setLoadingDiscountList(true);
    const response = await CategoryDiscountListingApi(cId);
    if (response?.status) {
      setDiscountListData(response?.data?.listing?.data);
      setLoadingDiscountList(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setDiscountListData([]);
      setLoadingDiscountList(false);
    }
  };
  return (
    <Collapse bordered={false}>
      <Panel
        header={
          <>
            <div className="collapse_flex">
              <p className="pricing_paragraph">{`Discounts (${
                DiscountListData ? DiscountListData?.length : 0
              })`}</p>

              {/* <Link to={EventCategoryDetailsDiscountRoute}>
            <Button
              type="y"
              icon={<EditOutlined />}

            />
          </Link> */}
            </div>

            <Divider className="global_divider mb-0" />
          </>
        }
        key="1"
      >
        <CategoryDiscountListing
          loadingDiscountList={loadingDiscountList}
          DiscountListData={DiscountListData}
        />
      </Panel>
    </Collapse>
  );
};
export default Discounts;
