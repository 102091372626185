import { Table, Divider, Button, Modal, Form, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const data = [
  {
    key: "1",
    label: "Amount",
    value: (
      <>
        <p className="para-text">$1000.00</p>
      </>
    ),
  },
  {
    key: "2",
    label: "Method",
    value: (
      <>
        <p className="para-text">Chargeback</p>
      </>
    ),
  },
  {
    key: "3",
    label: "Date",
    value: (
      <>
        <p className="para-text">07/26/2023</p>
      </>
    ),
  },
];

const ViewPaymentDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVoidModalOpen, setIsVoidModalOpen] = useState(false);
  const [isUnVoidModalOpen, setIsUnVoidModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showVoidModal = () => {
    setIsVoidModalOpen(true);
  };

  const handleVoidOk = () => {
    setIsVoidModalOpen(false);
  };
  const handleVoidCancel = () => {
    setIsVoidModalOpen(false);
  };

  const showUnVoidModal = () => {
    setIsUnVoidModalOpen(true);
  };

  const handleUnVoidOk = () => {
    setIsUnVoidModalOpen(false);
  };
  const handleUnVoidCancel = () => {
    setIsUnVoidModalOpen(false);
  };

  const { TextArea } = Input;
  const onChange = (e) => {
    console.log("Change:", e.target.value);
  };

  return (
    <div>
      <div className="partnership_inform">
        <h4>Details</h4>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <span>
            <Link to="/edit-payment-form-page">
              {" "}
              <Button type="y" icon={<EditOutlined />} />
            </Link>
          </span>
          <Button onClick={showModal}>Refund</Button>
          <Modal
            footer={
              <div>
                <Button type="primary">Confirm</Button> <Button>cancel</Button>
              </div>
            }
            title="Refund Confirmation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="refund-modal">
              <p>
                Please confirm that you want to{" "}
                <span> refund payment 'Payment 07/26/2023'.</span>
              </p>
            </div>
          </Modal>

          <Button onClick={showVoidModal}>Void</Button>
          <Modal
            footer={
              <div>
                <Button type="primary">Confirm</Button> <Button>cancel</Button>
              </div>
            }
            title="Void Confirmation"
            open={isVoidModalOpen}
            onOk={handleVoidOk}
            onCancel={handleVoidCancel}
          >
            <div className="void-modal">
              <p>Please confirm that you want to mark this payment as Void.</p>
              <span>
                Please note, Void only marks payment as voided in Booking
                Engine. You need to undo payment in your merchant account
                separately, if needed.
              </span>
              <div className="void-modal_form">
                <Form>
                  <Form.Item
                    label="Comment"
                    name="comment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea
                      showCount
                      rows={4}
                      maxLength={100}
                      onChange={onChange}
                      placeholder="can resize"
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>

          <Button onClick={showUnVoidModal}>Email</Button>
          <Modal
            footer={
              <div>
                <Button type="primary">Confirm</Button> <Button>cancel</Button>
              </div>
            }
            title="UnVoid Confirmation"
            open={isUnVoidModalOpen}
            onOk={handleUnVoidOk}
            onCancel={handleUnVoidCancel}
          >
            <div className="void-modal">
              <p>Please confirm that you want to mark this payment as Void.</p>
              <div className="void-modal_form">
                <Form>
                  <Form.Item
                    label="Comment"
                    name="comment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea
                      showCount
                      rows={4}
                      maxLength={100}
                      onChange={onChange}
                      placeholder="can resize"
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default ViewPaymentDetails;
