import { Table } from "antd";
import React from "react";

function EventReservationListing({
  data,
  count,
  urlParamsObject,
  setUrlParamsObject,
  dataLoading,
}) {
  const columns = [
    {
      title: "Reservation ID",
      dataIndex: "hash",
      render: (text) => <a>{text}</a>,
    },

    {
      title: "Primary Traveler",
      dataIndex: "primaryguest",
    },

    {
      title: "Status",
      dataIndex: "status",
    },

    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Created On",
      dataIndex: "created",
    },
  ];
  return (
    <>
      <Table
        className="event_cruise_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: dataLoading, size: "large" }}
        pagination={{
          showSizeChanger: true,
          current: Number(urlParamsObject?.page),
          pageSize: Number(urlParamsObject?.count),
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: Number(count),
          position: ["topRight", "bottomRight"],
          onChange: (page, pageSize) => {
            setUrlParamsObject({
              ...urlParamsObject,
              page,
              count: pageSize,
            });
          },
        }}
      ></Table>
    </>
  );
}
export default EventReservationListing;
