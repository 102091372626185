import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
// import Fee_Cate
import { useSearchParams } from "react-router-dom";
import { getFeeDetailsApi } from "../../../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import AddonFeeDetailsContainer from "../../../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeDetailsContainer";
import AddonFeeDetailsHeader from "../../../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeDetailsHeader";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
const { Header, Content, Sider } = Layout;

const AddonFeeDetailsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const feeId = searchParams.get("feeId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const [feeDetailsData, setFeeDetailsData] = useState({});
  const [loadingFeeDetailData, setLoadingFeeDetailData] = useState(false);
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: "Fee Detail", path: "" },
  ];
  useEffect(() => {
    getFeeDetail();
  }, []);

  const getFeeDetail = async () => {
    setLoadingFeeDetailData(true);
    const response = await getFeeDetailsApi(feeId);
    if (response?.status) {
      setFeeDetailsData(response?.data?.data);
      setLoadingFeeDetailData(false);
    } else {
      setFeeDetailsData({});
      setLoadingFeeDetailData(false);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <AddonFeeDetailsHeader
              feeDetailsData={feeDetailsData}
              loadingFeeDetailData={loadingFeeDetailData}
            />
          </div>
          <AddonFeeDetailsContainer
            feeDetailsData={feeDetailsData}
            loadingFeeDetailData={loadingFeeDetailData}
            feeType={"addon"}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonFeeDetailsPage;
