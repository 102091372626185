import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createRoleApi,
  getAllRolesApi,
  getUsersCountWithRoleApi,
} from "../../../apis/manageRoles";

export const getAllRoles = createAsyncThunk(
  "manage-roles/getAllRoles",
  async () => {
    const response = await getAllRolesApi();
    console.log(response);
    console.log({ data: response.data, status: response.status });
    return { data: response.data, status: response.status };
  }
);

export const getUsersCountWithRole = createAsyncThunk(
  "manage-roles/getUsersCountWithRole",
  async (dummy, { rejectWithValue }) => {
    const response = await getUsersCountWithRoleApi();
    if (response.status) {
      return { data: response.data, status: response.status };
    } else {
      return rejectWithValue({ error: "unable to fetch user count" });
    }
  }
);

export const createRole = createAsyncThunk(
  "manage-roles/createRoleApi",
  async (payload) => {
    const response = await createRoleApi(payload);
    return { data: response.data, status: response.status };
  }
);
const manageRolesSlice = createSlice({
  name: "manage-roles",
  initialState: {
    allRoles: {
      allRolesResponse: {},
      loadingAllRoles: false,
    },
    usersCountWithRole: {},
    roleCreation: {
      roleCreationResponse: {},
      loadingRoleCreation: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.pending, (state, action) => {
      state.allRoles.loadingAllRoles = true;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      console.log(action, "actiomnnnnn");
      state.allRoles.allRolesResponse = action.payload;
      // state.allRoles.loadingAllRoles = false;
    });
    builder.addCase(getAllRoles.rejected, (state, action) => {
      state.allRoles.allRolesResponse = action.payload;
      state.allRoles.loadingAllRoles = false;
    });
    builder.addCase(getUsersCountWithRole.pending, (state, action) => {
      state.usersCountWithRole = true;
    });
    builder.addCase(getUsersCountWithRole.fulfilled, (state, action) => {
      console.log(action, "getUsersCountWithRole");
      state.usersCountWithRole = action.payload;
      state.allRoles.loadingAllRoles = false;
    });
    builder.addCase(getUsersCountWithRole.rejected, (state, action) => {
      state.usersCountWithRole = action.payload;
      state.allRoles.loadingAllRoles = false;
    });
    builder.addCase(createRole.pending, (state, action) => {
      state.roleCreation.loadingRoleCreation = true;
    });

    builder.addCase(createRole.fulfilled, (state, action) => {
      state.roleCreation.roleCreationResponse = action.payload;
      state.roleCreation.loadingRoleCreation = false;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      state.roleCreation.roleCreationResponse = action.payload;
      state.roleCreation.loadingRoleCreation = false;
    });
  },
});

export default manageRolesSlice.reducer;
