import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message } from "antd";
// import Searchintakes_menu from "../../components/Header/Searchintakes_menu/Searchintakes_menu";
// import Intakes_header from "../../components/Breadcrumb/Intakes_header/Intakes_header";
// import Intake_tabs from "../../components/Intakes_component/Intake_tabs/Intake_tabs";
import "../contact-details/ContactDetailsPage.less";
import { GroupDetailsListingRoute } from "../../url-routes/urlRoutes";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import GroupDetailSearchBar from "../../components/GroupDetails/SearchGroupDetail/SearchGroupDetail";
import ListingHeader from "../../components/GroupDetails/GroupDetailsListing/ListingHeader";
import GroupDetailsListingTabs from "../../components/GroupDetails/GroupDetailsListing/GroupDetailsListingTabs";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroupDetails } from "../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header } = Layout;

const GroupDetailsListingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const navigate = useNavigate()
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const page = searchParams.get("page") || 1;
  const count = searchParams.get("count") || 10;
  const order = searchParams.get("order") || "ASC";
  const name = searchParams.get("name");
  const tab = searchParams.get("tab") || "Cruise";
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
    name,
    tab,
  });
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Group Details", path: GroupDetailsListingRoute },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu group details");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  // useEffect(() => {
  //   console.log("urlParamsObject 3333333333333", urlParamsObject);
  //   let url = urlParamsObject;
  //   url.tab = tab;
  //   setUrlParamsObject(url);
  // }, [tab]);

  // useEffect(() => {
  //   const handlePopstate = () => {
  //     console.log(searchParams, urlParamsObject);
  //     setUrlParamsObject(urlParamsObject);
  //   };

  //   window.addEventListener("popstate", handlePopstate);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);

  return (
    <Layout className={mobileClass}>
      <Layout>
        {/* {console.log("XXXXX", tab, urlParamsObject)} */}
        <Layout>
          <Header className="contact_edit main-header">
            <GroupDetailSearchBar
              name={name}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ListingHeader
              name={name}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
            />
          </div>
          <GroupDetailsListingTabs
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default GroupDetailsListingPage;
