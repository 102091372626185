import { Table } from "antd";
import { Divider } from "antd";
import "./SystemSettings.less";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const SystemSettings = ({ contactDetailsData, contactId, isLoading }) => {
  const { user_id, created, modified_by, changed } = contactDetailsData;

  const data = user_id &&
    modified_by &&
    created &&
    changed && [
      {
        key: "1",
        questions: "Created By",
        answers: (
          <>
            <p className="paragraph_marg">
              {user_id?.field_first_name || user_id?.field_last_name
                ? user_id?.field_first_name + " " + user_id?.field_last_name
                : user_id?.display_name || " "}
            </p>
            <p className="paragraph_marg">
              {created ? moment(created).format(DATE_FORMAT) : ""}{" "}
              {created ? moment(created).format("hh:mm A") : ""}{" "}
              <span className="span_text">
                {moment(created).from(moment())}
              </span>
            </p>
          </>
        ),
      },
      {
        key: "2",
        questions: "Last Modified By",
        answers: (
          <>
            <p className="paragraph_marg">
              {modified_by?.field_first_name || modified_by?.field_last_name
                ? modified_by?.field_first_name +
                  " " +
                  modified_by?.field_last_name
                : modified_by?.display_name || " "}
            </p>
            <p className="paragraph_marg">
              {changed ? moment(changed).format(DATE_FORMAT) : ""}{" "}
              {changed ? moment(changed).format("hh:mm A") : ""}{" "}
              <span className="span_text">
                {changed ? moment(changed).from(moment()) : "No status"}
              </span>
            </p>
          </>
        ),
      },
    ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'" }}>
          System Information
        </h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        className="system_table admin_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default SystemSettings;
