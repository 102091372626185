import { Button } from "antd";
import axios from "axios";
import React from "react";

function EditpaymentHeader({ paymentData, setPaymentData }) {

  async function createCheckoutSession(e) {
    const { data } = await axios.post('https://tykwr4ntgd.execute-api.us-west-2.amazonaws.com/dev/pay-with-stripe/create-checkout-session', paymentData)
    window.location.href = data.url
  }
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 className="flex_row_column">
          <div className="header_res ">
            <p>Edit Planned Payment: Installment 08/03/2022</p>
            <p className='header_smalltext' >  In His Steps 2023: A Holy Land Tour with Jimmy Evans and Ed Young
              - 1082699</p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{ display: "flex", gap: "10px" }}
        >
          <Button>Cancel</Button>
          <Button type="primary">Save</Button>
        </div>
      </div>
    </div>
  );
}
export default EditpaymentHeader;
