import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Skeleton, Tooltip, message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  cloneCategoryApi,
  deleteSingleCategoryApi,
} from "../../../../../apis/eventsApi";
import {
  AddonCategoryDetailsRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
import { AddCategoryDrawer } from "../CategoriesListing/EventAddCategory";

function HeaderCategoryDetail({
  eventId,
  categoryId,
  loadingSingleCategory,
  categoryDetails,
  addonId,
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditCategory = authData?.permissions?.includes("edit category");
  const allowDeleteCategory =
    authData?.permissions?.includes("delete category");
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = (val) => {
    setOpen(true);
  };

  const cloneThisCategory = async (payload) => {
    setIsLoading(true);
    const cloneRes = await cloneCategoryApi(payload);
    if (cloneRes.status) {
      message.success("Category Cloned Successfully");
      form.resetFields();
      handleOk();
      navigate(
        addonId
          ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${cloneRes?.data?.clonedcategory_uuid}`
          : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${cloneRes?.data?.clonedcategory_uuid}`
      );
    } else {
      message.error(
        cloneRes?.data?.response?.data?.message ||
          cloneRes?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
    setIsLoading(false);
  };

  const onFinish = ({ category_code, category_name }) => {
    const payload = {
      uuid: categoryId,
      category_name,
      category_code,
    };

    cloneThisCategory(payload);
  };

  const { field_event } = categoryDetails;

  const deleteCategory = async (deleteId) => {
    const response = await deleteSingleCategoryApi(deleteId);
    if (response.status) {
      message.success("Category Deleted Successfully");
      navigate(
        addonId
          ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
          : `${EventDetailsRoute}?id=${eventId}#Accommodations`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message || response?.data?.response?.data?.errors?.[0]?.detail || 
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteCategory(id);
      },
    });
  };

  return (
    <>
      {open ? (
      <AddCategoryDrawer
        open={open}
        categoryId={categoryId}
        eventId={eventId}
        addonId={addonId}
        setOpen={setOpen}
        eventData={categoryDetails?.field_event}
      />
      ) : null} 
      {/* <CloneCategoryModal /> */}
      <Modal
        footer={[
          <Button
            type="primary"
            loading={isLoading}
            onClick={(e) => {
              e.preventDefault();
              form.submit();
            }}
          >
            Copy
          </Button>,
        ]}
        title="Copy Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="copy_inventory_modal">
          <Form name="basic" form={form} onFinish={onFinish} autoComplete="off">
            <Form.Item
              className="asterick-align"
              label="Category Code"
              name="category_code"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter category code",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="asterick-align"
              label="Category Name"
              name="category_name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <div className="breadcrumb_content">
        {/* {loadingSingleCategory ? (
          <>
            <Skeleton.Input active={true} style={{ width: "250px" }} />
          </>
        ) : ( */}
        <>
          <div className="breadcrumb_heading view_header">
            <h3 className="flex_row_column">
              <ArrowLeftOutlined
                className="back-icon-profile"
                onClick={() =>
                  navigate(
                    addonId
                      ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                      : `${EventDetailsRoute}?id=${eventId}`
                  )
                }
              />
              {loadingSingleCategory ? (
                <Skeleton.Input
                  active={true}
                  style={{ width: "250px", height: "65px" }}
                />
              ) : (
                <div className="">
                  {" "}
                  <p style={{ margin: "5px" }}>{`${
                    categoryDetails?.name || ""
                  } (${categoryDetails?.drupal_internal__id || ""})`}</p>
                  <Link
                    to={
                      addonId
                        ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                        : `${EventDetailsRoute}?id=${eventId}`
                    }
                  >
                    <p
                      className="header_smalltext cta_hover_color"
                      style={{ margin: "5px" }}
                    >
                      {field_event?.name || "event name pending"} (
                      {field_event?.drupal_internal__id || "event id pending"})
                    </p>
                  </Link>
                </div>
              )}
            </h3>

            <div className="breadcrumb_buttons">
              {permissionChecker("add category") ? (
                <Tooltip placement="top" title="Edit">
                  <Button
                    disabled={loadingSingleCategory}
                    type="y"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      showDrawer();
                    }}
                  />
                </Tooltip>
              ) : null}
              {
                permissionChecker("copy category") ? 
                <Tooltip placement="top" title="Copy">
                  <Button
                    disabled={loadingSingleCategory}
                    type="y"
                    icon={<FileOutlined />}
                    onClick={showModal}
                  />
                </Tooltip> : null
              }
              {permissionChecker("delete category") ? (
                <Tooltip placement="top" title="Delete">
                  <Button
                    className="delete_btn"
                    disabled={loadingSingleCategory}
                    type="y"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteHandler(categoryId);
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>{" "}
        </>
        {/* )} */}
      </div>
    </>
  );
}

export default HeaderCategoryDetail;
