import { Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import {
  addCommasInPricing,
  isJSONString,
} from "../../../../../../utils/contentParser";

const PricingTable = ({ categoryDetails, loadingSingleCategory }) => {
  const field_category_pricing =
    categoryDetails?.field_category_pricing &&
    isJSONString(categoryDetails?.field_category_pricing) &&
    JSON.parse(categoryDetails.field_category_pricing);

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Price Item",
      children: [
        {
          key: "occupancy",
          title: "Occupancy",
          dataIndex: "occupancy",
        },
        {
          key: "code",
          title: "Code",
          dataIndex: "priceCode",
        },
        {
          key: "name",
          title: "Name",
          dataIndex: "priceName",
        },
        {
          key: "share",
          title: "Share",
          dataIndex: "share",
        },
      ],
    },

    {
      title: "1st",
      children: [
        {
          key: "adult",
          title: "Adult",
          dataIndex: "adult_first",
        },
        {
          title: "Child",
          dataIndex: "child_first",
          key: "child1",
        },
      ],
    },

    {
      title: "2nd",
      children: [
        {
          key: "adult2",
          title: "Adult",
          dataIndex: "adult_second",
        },
        {
          title: "Child",
          dataIndex: "child_second",
          key: "child2",
        },
      ],
    },

    {
      title: "3rd",
      children: [
        {
          key: "adult3",
          title: "Adult",
          dataIndex: "adult_third",
        },
        {
          title: "Child",
          dataIndex: "child_third",
          key: "child3",
        },
      ],
    },

    {
      title: "4th etc.",
      children: [
        {
          key: "adult4",
          title: "Adult",
          dataIndex: "adult_fourth",
        },
        {
          title: "Child",
          dataIndex: "child_fourth",
          key: "child4",
        },
      ],
    },
  ]);

  const extactValues = (obj, name) => {
    let extractedData = {};
    if (obj && Object.keys(obj).length) {
      for (let key in obj) {
        extractedData[`${name}_${key}`] = obj[key]
          ? `$${addCommasInPricing(Number(obj[key]).toFixed(2))}`
          : "";
      }
    }
    return extractedData;
  };

  useEffect(() => {
    const data = field_category_pricing?.length
      ? field_category_pricing?.map((item, index) => {
          const { occupancy, priceCode, priceName, share } = item;
          const { adult, child, staff, rewardPoints, additionalDeposit } =
            item?.pricingData || {};
          let adultvalues = extactValues(adult, "adult");
          let childValues = extactValues(child, "child");
          let staffValues = extactValues(staff, "staff");
          let rewardValues = extactValues(rewardPoints, "reward");
          let additionalValues = extactValues(additionalDeposit, "additional");
          return {
            key: index,
            occupancy,
            priceCode,
            priceName,
            name: "Quad Occupancy",
            share: (
              <>
                <Switch checked={share} disabled />
              </>
            ),
            ...adultvalues,
            ...childValues,
            ...staffValues,
            ...rewardValues,
            ...additionalValues,
          };
        })
      : null;

    if (data?.length) {
      columnToShow(data);
      setDataSource(data);
    }
  }, [categoryDetails]);

  const columnToShow = (data) => {
    let newColumn = [...columns];
    let showRewardColumn = [];
    let showStaffColumn = [];
    let showAdditionalColumn = [];
    data.forEach((dataItem, index) => {
      if (dataItem?.staff_first) {
        showStaffColumn[0] = true;
      }
      if (dataItem?.staff_second) {
        showStaffColumn[1] = true;
      }
      if (dataItem?.staff_third) {
        showStaffColumn[2] = true;
      }
      if (dataItem?.staff_fourth) {
        showStaffColumn[3] = true;
      }

      if (dataItem?.reward_first) {
        showRewardColumn[0] = true;
      }
      if (dataItem?.reward_second) {
        showRewardColumn[1] = true;
      }
      if (dataItem?.reward_third) {
        showRewardColumn[2] = true;
      }
      if (dataItem?.reward_fourth) {
        showRewardColumn[3] = true;
      }

      if (dataItem?.additional_first) {
        showAdditionalColumn[0] = true;
      }
    });

    showStaffColumn.forEach((showColumn, index) => {
      if (!!showColumn) {
        newColumn[index + 1]?.children.push({
          title: "Staff",
          dataIndex: `staff_${returnIntegerVal(index + 1)}`,
          key: `staff${index + 1}`,
        });
      }
    });

    showRewardColumn.forEach((showColumn, index) => {
      if (!!showColumn) {
        newColumn[index + 1]?.children.push({
          title: "Reward Points",
          dataIndex: `reward_${returnIntegerVal(index + 1)}`,
          key: `reward${index + 1}`,
        });
      }
    });

    showAdditionalColumn.forEach((showColumn, index) => {
      if (!!showColumn) {
        newColumn[index + 1]?.children.push({
          title: "Additional Deposits",
          dataIndex: `additional_${returnIntegerVal(index + 1)}`,
          key: `additional${index + 1}`,
        });
      }
    });
    setColumns(newColumn);
  };

  const returnIntegerVal = (int) => {
    switch (int) {
      case 1:
        return "first";
      case 2:
        return "second";
      case 3:
        return "third";
      case 4:
        return "fourth";
      default:
        return null;
    }
  };
  return (
    <Table
      loading={{ spinning: loadingSingleCategory, indicator: <DataLoader /> }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      bordered
      size="middle"
    />
  );
};

export default PricingTable;
