import { updateRecentlyViewedApi } from "../apis/usersApi";
import { Token, customRequestHeader } from "./cutomRequestHeader";

export const updateRecentlyViewed = async (
  recently_read_type,
  id,
  langcode,
  drupal_internal__id,
  filter
) => {
  const payload = {
    recently_read_type: recently_read_type?.split("--")[0],
    viewed_entity: {
      id,
      langcode,
      drupal_internal__id,
    },
  };

  if (filter) {
    payload.viewed_entity["filter_types"] = filter;
  }
  const reqHeader = customRequestHeader({
    token: Token,
    defaultFormat: true,
  });
  const res = await updateRecentlyViewedApi(payload, reqHeader);
  return res;
};
