import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../url-routes/urlRoutes";

function DiscountCreateOrEditHeader({
  form,
  loadingDiscountCreation,
  discountType,
  addonId,
  eventId,
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const discountId = searchParams.get("editId");

  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3 className="flex_row_column">
          <div className="header_res">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() =>
                navigate(
                  discountType === "event"
                    ? `${EventDetailsRoute}?id=${eventId}#Discounts`
                    : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                )
              }
            />
            <p>{`${discountId ? "Edit" : "Add"} Discount`}</p>
          </div>
        </h3>

        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={loadingDiscountCreation}
            onClick={() => form.submit()}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DiscountCreateOrEditHeader;
