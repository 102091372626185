import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

function AddonCreateOrEditFlightHeader({ form, onSaveLoading, editId }) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>
              <ArrowLeftOutlined
                style={{ paddingRight: "6px" }}
                onClick={() => {
                  navigate(-1);
                }}
              />{" "}
              {editId ? "Edit Flights" : "Add Flights"}
            </p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={onSaveLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddonCreateOrEditFlightHeader;
