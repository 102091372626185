import { DownOutlined } from "@ant-design/icons";
import { Table, Divider, Button, Input, message, Dropdown } from "antd";
import React, { useState } from "react";
import {
  refundPlannedPaymentApi,
  transferPlannedPaymentApi,
} from "../../../../../../apis/reservationApi";
import { addCommasInPricing } from "../../../../../../utils/contentParser";
import PaymentMethod from "../../../../../../utils/paymentMethod.json";

const TransactionsDetail = ({
  transactionList,
  loadingTransaction,
  paymentData,
  loadingPaymentData,
  reservationId,
  setLoadingTransaction,
  setPaymentDataTrigger,
  paymentDataTrigger,
}) => {
  const [inputData, setInputData] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const columns = [
    {
      title: "Payment Name",
      dataIndex: "payment_name",
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      render: (value) => {
        return `$${value ? addCommasInPricing(value) : "0.00"
          }`;
      },
    },
    {
      title: "Refund Applied",
      dataIndex: "applied",
      render: (value) => {
        return `$${value ? addCommasInPricing(value) : "0.00"
          }`;
      },
    },
    // {
    //   title: "Pending",
    //   dataIndex: "pending",
    //   render: (value) => {
    //     return `$${
    //       value ? typeof value === "number" ? addCommasInPricing(value.toFixed(2).toString()) : value : "0.00"
    //     }`;
    //   },
    // },
    {
      title: "",
      dataIndex: "text_box",
      render: (text, record) => {
      const {amount, applied} = record
        const items = ["payment_card", "credit_card_to_vendor"].includes(
          record?.payment_method
        )
          ? PaymentMethod?.list?.filter(val=> val.value !== "transfer")?.map((val) => {
            return {
              label: (
                <span
                  onClick={() => {
                    setSelectedPaymentMethod({ ...val ,id:record?.payment_id});
                  }}
                >
                  {val?.name}
                </span>
              ),
              key: val?.value,
            };
          })
          : PaymentMethod?.list?.filter(val=> val.value !== "transfer")
            ?.map((val) => {
              return (
                !["payment_card", "credit_card_to_vendor"].includes(
                  val?.value
                ) && {
                  label: (
                    <span
                      onClick={() => {
                        setSelectedPaymentMethod({ ...val,id:record?.payment_id });
                      }}
                    >
                      {val?.name}
                    </span>
                  ),
                  key: val?.value,
                }
              );
            })
            .filter(Boolean);

        return (
          <>
          {Number(amount) !== Number(applied) ? 
            <div className="refund-via-wrapper">
              <div className="transactions-wrpper">
                <Input
                  type="number"
                  min={0}
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      // evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown"
                    ) {
                      evt.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value <= 0) {
                      message.error("Please enter amount greater than 0");
                    }
                    onChangeHandler(e.target.value, record);
                  }}
                  value={inputData?.[record?.payment_id] || null}
                />
                <Button
                  type="primary"
                  disabled={Number(paymentData?.pending_amount) <= 0}
                  onClick={() => {
                    onTransferHandler(record);
                  }}
                >
                  Transfer
                </Button>
              </div>
              <Dropdown.Button
                icon={<DownOutlined />}
                className="dropdown-refund"
                menu={{
                  items: items,
                }}
                type="primary"
                disabled={Number(paymentData?.pending_amount) <= 0}
                onClick={() => {
                  onRefundHandler(record);
                }}
              >
                Refund via &nbsp; <strong>{record?.payment_id === selectedPaymentMethod?.id ? selectedPaymentMethod?.name : null}</strong>
              </Dropdown.Button>
            </div> 
          : null } 
          </>
        );
      },
    },
  ];

  const onChangeHandler = (value, record) => {
    setInputData({ ...inputData, [record?.payment_id]: value });
  };
  const onRefundHandler = async (record) => {
    if (!selectedPaymentMethod?.value) {
      return message.error("Payment method not selected");
    }
    const amount = inputData?.[record?.payment_id];
    const payload = {
      traveler_uuid: paymentData?.field_traveler?.uuid,
      reservation_uuid: paymentData?.field_reservation?.uuid,
      payment_id: record?.payment_id,
      planned_payment_uuid: paymentData?.uuid,
      method: selectedPaymentMethod?.value,
      amount,
    };
    if (payload.amount <= 0)
      return message.error("Please enter amount greater than 0");
    if (!payload.amount) return message.error("Please enter the amount");
    setLoadingTransaction(true);
    const res = await refundPlannedPaymentApi(payload);
    if (res.status) {
      message.success(res?.data?.message);
      setLoadingTransaction(false);
      setPaymentDataTrigger(!paymentDataTrigger);
      setInputData({});
      setSelectedPaymentMethod({})
    } else {
      message.error(res?.data?.response?.data?.message);
      setLoadingTransaction(false);
    }
  };

  const onTransferHandler = async (record) => {
    const amount = inputData?.[record?.payment_id];
    const payload = {
      contact_uuid: paymentData?.field_contact?.uuid,
      traveler_uuid: paymentData?.field_traveler?.uuid,
      reservation_uuid: paymentData?.field_reservation?.uuid,
      payment_id: record?.payment_id,
      planned_payment_uuid: paymentData?.uuid,
      amount,
    };
    if (payload.amount <= 0)
      return message.error("Please enter amount greater than 0");
    if (!payload.amount) return message.error("Please enter the amount");
    setLoadingTransaction(true);
    const res = await transferPlannedPaymentApi(payload);
    if (res.status) {
      message.success(res?.data?.data?.message?.message);
      setLoadingTransaction(false);
      setPaymentDataTrigger(!paymentDataTrigger);
      setInputData({});
    } else {
      message.error(res?.data?.response?.data?.data?.message);
      setLoadingTransaction(false);
    }
  };

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Transactions</h4>
      </div>
      <Divider className="global_divider" />
      <Table
        className="transaction-table"
        columns={columns}
        dataSource={transactionList}
        pagination={false}
        loading={loadingPaymentData || loadingTransaction}
      />
    </div>
  );
};

export default TransactionsDetail;
