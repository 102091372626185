import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Switch, Table } from "antd";
import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { EventDetailsAddonsRoute } from "../../../../../url-routes/urlRoutes";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../../utils/contentParser";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },

  {
    title: "Input",
    dataIndex: "input",
  },
];

const AddonPaymentSetup = ({ addonData, eventId, addonId, isLoading , isAddon = false }) => {
  const navigate = useNavigate();
  const addOnData = [
    {
      key: "1",
      label: "Standard Deposit Amount",
      input: addonData?.field_standard_deposit_amount
        ? `$${addCommasInPricing(addonData?.field_standard_deposit_amount)}`
        : "",
    },
  ]
  const data = [
    {
      key: "1",
      label: "Is Financeable",
      input:
        addonData?.field_is_financeable !== null ? (
          <Switch disabled checked={addonData?.field_is_financeable} />
        ) : (
          ""
        ),
    },
    {
      key: "2",
      label: "Standard Deposit Amount",
      input: addonData?.field_standard_deposit_amount
        ? `$${addCommasInPricing(addonData?.field_standard_deposit_amount)}`
        : "",
    },
    {
      key: "3",
      label: "Standard Deposit Percent",
      input: addonData?.field_standard_deposit_percent
        ? `${addonData?.field_standard_deposit_percent}%`
        : "",
    },
    {
      key: "4",
      label: "Early Deposit Amount",
      input: addonData?.field_early_deposit_amount
        ? `$${addCommasInPricing(addonData?.field_early_deposit_amount)}`
        : "",
    },
    {
      key: "5",
      label: "Deposit Date",
      input: addonData?.field_deposit_date ? (
        <>
          <span>
            {moment(addonData?.field_deposit_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_text">
            {moment(addonData?.field_deposit_date, "YYYY-MM-DD").fromNow()}
          </span>
        </>
      ) : null,
    },
    {
      key: "6",
      label: "Second Deposit Amount",
      input: addonData?.field_second_deposit_amount
        ? `$${addCommasInPricing(addonData?.field_second_deposit_amount)}`
        : "",
    },
    {
      key: "7",
      label: "Second Deposit Percent",
      input: addonData?.field_second_deposit_percent
        ? `${addonData?.field_second_deposit_percent}%`
        : "",
    },
    {
      key: "8",
      label: "Second Deposit Date",
      input: addonData?.field_second_deposit_date ? (
        <>
          <span>
            {moment(addonData?.field_second_deposit_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_text">
            {moment(
              addonData?.field_second_deposit_date,
              "YYYY-MM-DD"
            ).fromNow()}
          </span>
        </>
      ) : null,
    },
    {
      key: "9",
      label: "Final Payment Date",
      input: addonData?.field_final_payment_date ? (
        <>
          <span>
            {moment(addonData?.field_final_payment_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_text">
            {moment(
              addonData?.field_final_payment_date,
              "YYYY-MM-DD"
            ).fromNow()}
          </span>
        </>
      ) : null,
    },
    // {
    //   key: "10",
    //   label: "Payment Processor",
    //   input: addonData?.field_payment_processor?.name || "",
    // },
    // {
    //   key: "11",
    //   label: "Multi Currency",
    //   input: addonData?.field_multi_currency?.name || "",
    // },
    {
      key: "12",
      label: "Default Pay Terms",
      input: addonData?.field_default_pay_terms?.label || "",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>Payment Setup</h4>
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(
                `${EventDetailsAddonsRoute}?id=${eventId}&editId=${addonId}&step=3`
              );
            }}
          />
        </span>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={isAddon ? addOnData : data}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default AddonPaymentSetup;
