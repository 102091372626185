import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Oval } from "react-loader-spinner";

const DataLoader = ({ width, height, stroke, outerStroke }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 60,
      }}
      spin
    />
  );
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default DataLoader;
