import React, { useEffect, useRef, useState } from "react";
import { Layout, Grid, message, Modal } from "antd";
import ContactsListHeader from "../../components/Contacts/ContactsListing/ContactsListHeader";
import ContactsListContainer from "../../components/Contacts/ContactsListing/ContactsListContainer";
import { useLocation, useSearchParams } from "react-router-dom";
import { getAllContacts } from "../../redux/features/contactFeatures/contactSlice";
import { useDispatch, useSelector } from "react-redux";
import "./ContactsListingPage.less";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { Cookie, customRequestHeader } from "../../utils/cutomRequestHeader";
import ContactSearchBar from "../../components/Contacts/ContactsListing/ContactSearchBar";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { searchUserApi } from "../../apis/usersApi";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const ContactsListingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const [searchParams, setSearchParams] = useSearchParams();
  const [isContactUpdated, setIsContactUpdated] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const { contactListing, importContact } = useSelector(
    (state) => state.contacts
  );
  const { contactListingResponse, loadingContactListing } = contactListing;
  const [contactsList, setContactsList] = useState([]);
  const [contactsListCount, setContactsListCount] = useState("");
  const contactsListRef = useRef(false);
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const page = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const organization = searchParams.get("organization");
  const contactName = searchParams.get("name");
  const contactEmail = searchParams.get("email");
  const contactPhone = searchParams.get("phone");
  const cid = searchParams.get("cid");
  const contactPhoneExt = searchParams.get("phone_ext");
  const org = searchParams.get("org");
  // const travelStatus = searchParams.get("travel_status");
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
    name: contactName,
    email: contactEmail,
    phone_ext: contactPhoneExt,
    phone: contactPhone,
    organization: organization,
    org,
    cid,
    // travel_status: travelStatus,
  });
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  // useEffect(() => {
  //   let pageNumber = searchParams.get("page") || 1;
  //   let sort = searchParams.get("order") || "asc";
  //   let count = searchParams.get("count") || 10;
  // }, [location.search]);

  useEffect(() => {
    if (contactsListRef.current) {
      if (contactListingResponse && contactListingResponse.status) {
        setContactsList(contactListingResponse?.data?.rows);
        setContactsListCount(
          Number(contactListingResponse?.data?.pager?.total_items)
        );
      } else {
        message.error(
          contactListingResponse?.data?.response?.data?.message ||
            contactListingResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        setContactsList([]);
        setContactsListCount(0);
      }
    }
    contactsListRef.current = true;
  }, [contactListingResponse]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setUrlParamsObject({
        // ...urlParamsObject,
        count,
        order,
        page,
      });
    }
  }, [location.search]);

  useEffect(() => {
    for (let prop in urlParamsObject) {
      if (!urlParamsObject[prop]) {
        delete urlParamsObject[prop];
      }
    }
    setSearchParams(urlParamsObject);
    const reqHeaders = customRequestHeader({ token: Token });
    dispatch(
      getAllContacts({
        ...urlParamsObject,
        items_per_page: Number(urlParamsObject?.count),
        sort_by: "name",
        page: Number(urlParamsObject?.page) - 1,
        sort_order: urlParamsObject?.order,
        reqHeaders,
      })
    );
  }, [isContactUpdated, urlParamsObject, importContact]);
  const handleOk = () => {
    setOpen(false);
    setIsModalOpen(false);
    setErrorList([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const breadCrumbs = [{ title: "Contacts", path: "/contact/listing" }];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ContactSearchBar
              contactName={contactName}
              contactEmail={contactEmail}
              cId={cid}
              contactPhoneExt={contactPhoneExt}
              contactPhone={contactPhone}
              // organizationName={name}
              organization={organization}
              organizationType={org}
              // travelStatus={travelStatus}
              page={page}
              order={order}
              count={count}
              setSearchParams={setSearchParams}
              setUrlParamsObject={setUrlParamsObject}
              urlParamsObject={urlParamsObject}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ContactsListHeader
              numberOfContact={contactsListCount}
              isLoading={loadingContactListing}
              setIsContactUpdated={setIsContactUpdated}
              isContactUpdated={isContactUpdated}
              open={open}
              setOpen={setOpen}
              setIsModalOpen={setIsModalOpen}
              setErrorList={setErrorList}
              errorList={errorList}
            />
          </div>
          <Content>
            <ContactsListContainer
              contactsList={contactsList}
              totalCount={contactsListCount}
              setIsContactUpdated={setIsContactUpdated}
              isContactUpdated={isContactUpdated}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              isLoading={loadingContactListing}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Content>
          <Modal
            title="Confirmation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to discard?</p>
          </Modal>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default ContactsListingPage;
