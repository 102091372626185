import { DatePicker, Form, Input, Select, Switch, message } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { payTermsList } from "../../../others/util/commonFunctions";
import {
  createOrEditEvent,
  eventStepSetter,
  getEventMultiCurrencyList,
  getEventPaymentProcessorList,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { EventDetailsRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const PaymentSetupForm = ({
  form3,
  createId,
  editId,
  next,
  eventFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    eventCreation,
    eventPaymentProcessor,
    eventMultiCurrency,
    eventDefaultPayTerm,
  } = useSelector((state) => state.events);
  const { eventCreationResponse, loadingEventCreation, step3, eventStep } =
    eventCreation;
  // const { eventPaymentProcessorResponse, loadingEventPaymentProcessor } =
  //   eventPaymentProcessor;
  // const { eventMultiCurrencyResponse, loadingEventMultiCurrency } =
  //   eventMultiCurrency;
  // const { eventDefaultPayTermResponse, loadingEventDefaultPayTerm } =
  //   eventDefaultPayTerm;
  const eventCreationRef = useRef(false);
  // const [paymentProcessorList, setPaymentProcessorList] = useState([]);
  // const [multiCurrencyList, setMultiCurrencyList] = useState([]);
  // const paymentProcessorRef = useRef(false);
  // const multiCurrencyRef = useRef(false);

  useEffect(() => {
    dispatch(getEventPaymentProcessorList());
    dispatch(getEventMultiCurrencyList());
  }, []);

  // useEffect(() => {
  //   if (paymentProcessorRef.current) {
  //     if (eventPaymentProcessorResponse.status) {
  //       setPaymentProcessorList(eventPaymentProcessorResponse.data.data);
  //     } else {
  //       setPaymentProcessorList([]);
  //     }
  //   }
  //   paymentProcessorRef.current = true;
  // }, [eventPaymentProcessorResponse]);

  // useEffect(() => {
  //   if (multiCurrencyRef.current) {
  //     if (eventMultiCurrencyResponse.status) {
  //       setMultiCurrencyList(eventMultiCurrencyResponse.data.data);
  //     } else {
  //       setMultiCurrencyList([]);
  //     }
  //   }
  //   multiCurrencyRef.current = true;
  // }, [eventMultiCurrencyResponse]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(eventFormFields).length) {
      dispatch(eventStepSetter(eventFormFields?.field_step));
      form3.setFieldsValue({
        ...eventFormFields,
        field_deposit_date: !!eventFormFields?.field_deposit_date
          ? moment(eventFormFields?.field_deposit_date, "YYYY-MM-DD")
          : null,
        field_second_deposit_date: !!eventFormFields?.field_second_deposit_date
          ? moment(eventFormFields?.field_second_deposit_date, "YYYY-MM-DD")
          : null,
        field_final_payment_date: !!eventFormFields?.field_final_payment_date
          ? moment(eventFormFields?.field_final_payment_date, "YYYY-MM-DD")
          : null,
        // field_payment_processor: eventFormFields?.field_payment_processor.id,
        // field_multi_currency: eventFormFields?.field_multi_currency.id,
        field_default_pay_terms:
          eventFormFields?.field_default_pay_terms?.key || null,
      });
    }
  }, [eventFormFields]);

  useEffect(() => {
    if (eventCreationRef.current) {
      if (eventCreationResponse.status) {
        dispatch(
          eventStepSetter(eventCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          step3
            ? navigate(`${EventDetailsRoute}?id=${createId}`)
            : next({ type: "creation", id: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            step3
              ? navigate(`${EventDetailsRoute}?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateEventStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    eventCreationRef.current = true;
  }, [eventCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_is_financeable: values.field_is_financeable,
      field_standard_deposit_amount:
        values?.field_standard_deposit_amount || null,
      field_standard_deposit_percent:
        values?.field_standard_deposit_percent || null,
      field_early_deposit_amount: values?.field_early_deposit_amount || null,
      field_deposit_date:
        values?.field_deposit_date?.format("YYYY-MM-DD") || null,
      field_second_deposit_amount: values?.field_second_deposit_amount || null,
      field_second_deposit_percent:
        values?.field_second_deposit_percent || null,
      field_second_deposit_date:
        values["field_second_deposit_date"]?.format("YYYY-MM-DD") || null,
      field_final_payment_date:
        values["field_final_payment_date"]?.format("YYYY-MM-DD") || null,
      field_default_pay_terms: values?.field_default_pay_terms || null,
      field_step: currentStep > eventStep ? +currentStep : eventStep,
    };

    const relationships = {
      // field_payment_processor: values?.field_payment_processor
      //   ? {
      //       data: {
      //         type: "taxonomy_term--payment_processor",
      //         id: values.field_payment_processor,
      //       },
      //     }
      //   : null,
      // field_multi_currency: values?.field_multi_currency
      //   ? {
      //       data: {
      //         type: "taxonomy_term--multi_currency",
      //         id: values.field_multi_currency,
      //       },
      //     }
      //   : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditEvent(payload));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form3}
          onFinish={onFinishHandler}
        >
          <Form.Item
            className="swith_form asterick-align"
            label="Is Financeable"
            name="field_is_financeable"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Standard Deposit Amount"
            className="asterick-align"
            name="field_standard_deposit_amount"
          >
            <Input
              type="number"
              placeholder="Standard Deposit Amount"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Standard Deposit Percent"
            className="asterick-align"
            name="field_standard_deposit_percent"
          >
            <Input type="number" placeholder="Standard Deposit Percent" />
          </Form.Item>
          <Form.Item
            label="Early Deposit Amount"
            name="field_early_deposit_amount"
            className="asterick-align"
          >
            <Input
              placeholder="Early Deposit Amount"
              type="number"
              min={0}
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Deposit Date"
            className="asterick-align"
            name="field_deposit_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Second Deposit Amount"
            name="field_second_deposit_amount"
            className="asterick-align"
          >
            <Input
              type="number"
              placeholder="Second Deposit Amount"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Second Deposit Percent"
            className="asterick-align"
            name="field_second_deposit_percent"
          >
            <Input type="number" placeholder="Second Deposit Percent" />
          </Form.Item>

          <Form.Item
            label="Second Deposit Date"
            className="asterick-align"
            name="field_second_deposit_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Final Payment Date"
            className="asterick-align"
            name="field_final_payment_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          {/* <Form.Item label="Payment Processor" className="asterick-align" name="field_payment_processor">
            <Select
              allowClear
              placeholder="Payment Processor"
              options={paymentProcessorList.map((paymentProcessor) => ({
                key: paymentProcessor?.id,
                value: paymentProcessor?.id,
                label: paymentProcessor?.name,
              }))}
            />
          </Form.Item> */}

          {/* <Form.Item label="Multi-Currency" className="asterick-align" name="field_multi_currency">
            <Select
              allowClear
              placeholder="Multi-Currency"
              options={multiCurrencyList.map((multiCurrency) => ({
                key: multiCurrency?.id,
                value: multiCurrency?.id,
                label: multiCurrency?.name,
              }))}
            />
          </Form.Item> */}

          <Form.Item
            label="Default Pay Terms"
            className="asterick-align"
            name="field_default_pay_terms"
          >
            <Select
              allowClear
              placeholder="Default Pay Terms"
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={payTermsList.map((defaultPayTerm) => ({
                key: defaultPayTerm?.value,
                value: defaultPayTerm?.value,
                label: defaultPayTerm?.label,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PaymentSetupForm;
