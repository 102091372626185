import { Button, Col, Grid, Row, Steps, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  getOrganizationAllDetails,
  organizationStepSetter,
  pressOrganizationNext,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import AddressInformationForm from "./AddressInformationForm";
import CompanyInformationForm from "./CompanyInformationForm";
import "./CreateOrganizationSteps.less";
import OrganizationDetailsForm from "./OrganizationDetailsForm";
import PrimaryContact from "./PrimaryContact";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const { Step } = Steps;

const CreateOrganizationSteps = ({
  editId,
  createId,
  copyId,
  form1,
  form2,
  form3,
  form4,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    organizationCreation,
    organizationAllDetails,
    primaryContactDetails,
  } = useSelector((state) => state.organizations);
  const {
    organizationCreationResponse,
    loadingOrganizationCreation,
    organizationStep,
  } = organizationCreation;

  const {
    organizationAllDetailsResponse,
    loadingOrganizationAllDetailsResponse,
  } = organizationAllDetails;
  const organizationDetailsRef = useRef(false);
  const [organisationFormFields, setOrganisationFormFields] = useState({});
  const [formStep, setFormStep] = useState(1);
  const [stepLimit, setStepLimit] = useState("1");
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const orientationStep = lg ? "vertical" : "horizontal";
  const currentStep = searchParams.get("step");
  const [loadingSingleOrg, setLoadingSingleOrg] = useState(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [primaryContactLoading, setPrimaryContactLoading] = useState(true);

  useEffect(() => {
    setFormStep(Number(currentStep) || 1);
  }, [location.search]);

  useEffect(() => {
    if (organizationDetailsRef.current) {
      if (organizationAllDetailsResponse.status) {
        setOrganisationFormFields(organizationAllDetailsResponse?.data?.data);
      } else {
        message.error(
          organizationAllDetailsResponse?.data?.message ||
            organizationAllDetailsResponse?.data?.response?.data?.error
              ?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }

    organizationDetailsRef.current = true;
  }, [organizationAllDetailsResponse]);

  useEffect(() => {
    if (editId || createId) {
      const reqHeaders = customRequestHeader({
        token: Token,
        defaultFormat: true,
        isHeaderAbsent: false,
        isUploadReq: false,
      });
      const orgId = editId || createId;
      dispatch(getOrganizationAllDetails({ reqHeaders, orgId }));
    } else {
      setOrganisationFormFields({});
      dispatch(organizationStepSetter(0));
    }
  }, [currentStep]);

  const next = (info) => {
    setFormStep((prev) => Number(prev + 1));
    if (info.type === "creation") {
      setSearchParams({ creationId: info.id, step: formStep + 1 });
    } else {
      if (info.type === "edit") {
        setSearchParams({ editId: info.id, step: formStep + 1 });
      } else {
        setSearchParams({ step: formStep + 1 });
      }
    }
  };

  const prev = () => {
    setFormStep((prev) => Number(prev - 1));
    if (editId) {
      setSearchParams({
        editId: editId,
        step: formStep - 1,
      });
    } else {
      if (createId) {
        setSearchParams({
          creationId: createId,
          step: formStep - 1,
        });
      } else {
        setSearchParams({ step: formStep - 1 });
      }
    }
  };

  const onStepChangeHandler = (stepValue) => {
    if (Number(stepValue + 1) > organizationStep + 1) {
      return;
    }
    if (editId) {
      setFormStep(Number(stepValue + 1));
      setSearchParams({ editId: editId, step: stepValue + 1 });
    } else {
      if (createId) {
        setSearchParams({
          creationId: editId || createId,
          step: stepValue + 1,
        });
      } else {
        setSearchParams({ step: stepValue + 1 });
      }
    }
  };

  const steps = [
    {
      title: "Organization Details",
      content: (
        <OrganizationDetailsForm
          organisationFormFields={organisationFormFields}
          form1={form1}
          createId={createId}
          editId={editId}
          next={next}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "Primary Contact",
      content: (
        <PrimaryContact
          organisationFormFields={organisationFormFields}
          form2={form2}
          editId={editId}
          createId={createId}
          next={next}
          prev={prev}
          currentStep={currentStep}
          setPrimaryContactLoading={setPrimaryContactLoading}
        />
      ),
    },
    {
      title: "Address Information",
      content: (
        <AddressInformationForm
          organisationFormFields={organisationFormFields}
          form3={form3}
          editId={editId}
          createId={createId}
          next={next}
          prev={prev}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "Company Information",
      content: (
        <CompanyInformationForm
          organisationFormFields={organisationFormFields}
          form4={form4}
          editId={editId}
          createId={createId}
          prev={prev}
          currentStep={currentStep}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={6}>
          <Steps
            direction={orientationStep}
            current={formStep - 1}
            onChange={(e) => onStepChangeHandler(e)}
            items={steps}
          />
        </Col>
        {loadingOrganizationAllDetailsResponse  ? (
          <Col xs={24} md={24} lg={14}>
            <DataLoader />
          </Col>
        ) : (
          <Col xs={24} md={24} lg={14}>
            <div className="steps-content">{steps?.[formStep - 1].content}</div>
            <div className="steps-action">
              {formStep === 1 && (
                <Button
                  type="primary"
                  loading={loadingOrganizationCreation}
                  onClick={() => {
                    dispatch(updateOrganizationStep());
                    dispatch(pressOrganizationNext("next-button"));
                    form1.submit();
                    // next();
                  }}
                >
                  <span>Next</span>
                </Button>
              )}

              {formStep === 2 && (
                <Button
                  type="primary"
                  loading={loadingOrganizationCreation}
                  onClick={() => {
                    dispatch(updateOrganizationStep());
                    dispatch(pressOrganizationNext("next-button"));
                    form2.submit();
                    // next();
                  }}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === 3 && (
                <Button
                  type="primary"
                  loading={loadingOrganizationCreation}
                  onClick={() => {
                    dispatch(updateOrganizationStep());
                    dispatch(pressOrganizationNext("next-button"));
                    form3.submit();
                  }}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === steps.length && (
                <Button
                  type="primary"
                  loading={loadingOrganizationCreation}
                  onClick={() => {
                    dispatch(updateOrganizationStep());
                    dispatch(pressOrganizationNext("next-button"));
                    form4.submit();
                  }}
                >
                  Done
                </Button>
              )}
              {formStep > 1 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </Col>
        )}{" "}
      </Row>
    </>
  );
};
export default CreateOrganizationSteps;
