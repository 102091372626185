import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EventRoute } from "../../../../url-routes/urlRoutes";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";
import { permissionChecker } from "../../../../utils/permisssionChecker";
import { drupalAxiosRequest } from "../../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "value",
    dataIndex: "value",
  },
];

const DiningOptions = ({ eventData, eventId, loading }) => {
  const [diningData, setDiningData] = useState([]);
  const [diningOptions, setDiningOptions] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const fetchDiningOptions = async () => {
    try {
      const [response, diningOptions] = await Promise.all([
        drupalAxiosRequest(
          "get",
          `/api/rest/ict_dining_time?event=${eventId}`,
          customRequestHeader({ token: Token })
        ),
        drupalAxiosRequest(
          "get",
          `/api/rest/ict_dining_time`,
          customRequestHeader({ token: Token })
        ),
      ]);
      if (response?.status) {
        const data = [
          {
            key: "1",
            label: "Dining Time Request",
            value:  response?.data?.dining_time_request?.length
            ? response?.data?.dining_time_request
                ?.map((val) =>  <Tag className="dining-tag">{val?.name}</Tag>)
                
                  // ?.join(", ")
              : "",
          },
          {
            key: "2",
            label: "Dining Time Assigned",
            value:response?.data?.dining_time_assigned?.length
            ? response?.data?.dining_time_assigned
                ?.map((val) => <Tag>{val?.name}</Tag>)
                  // ?.join(", ")
              : "",
          },
        ];
        form.setFieldsValue({
          dining_time_request: response?.data?.dining_time_request?.map((val) => {
            return {
              key: val?.tid,
              value: val?.uuid,
              label: val?.name,
            };
          }),
          dining_time_assigned: response?.data?.dining_time_assigned?.map((val) => {
            return {
              key: val?.tid,
              value: val?.uuid,
              label: val?.name,
            };
          })
        });
        setDiningOptions(diningOptions?.data);
        setDiningData(data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    fetchDiningOptions();
  }, []);

  const addAndRemoveDiningTime = async (operation, data) => {
    setLoader(true);
    try {
      const response = await drupalAxiosRequest(
        "patch",
        `/api/rest/ict_dining_time?event=${eventId}`,
        customRequestHeader({ defaultFormat: true }),
        {
          operation,
          event_uuid: eventId,
          data: [
            {
              dining_time_uuid: data,
            },
          ],
        }
      );
      if (response?.status) {
        message.success(response?.data?.message);
        fetchDiningOptions()
      } else {
        message.success(SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      console.log("error", err);
    }
    setLoader(false);
  };

  return (
    <div>
      <div className="partnership_inform">
        <h4>Dining Options</h4>
        <span>
          {permissionChecker("add/edit event") ? (
            <Button
              type="y"
              icon={<EditOutlined />}
              onClick={() => {
                setDrawerOpen(true);
              }}
            />
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={diningData}
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        showHeader={false}
        pagination={false}
        size="small"
      />
      <Drawer
        title="Add Dining Options"
        maxWidth={425}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        bodyStyle={{ paddingBottom: "32px" }}
      >
        <Spin spinning={loader}>
          <Form layout="vertical" form={form}>
            <Form.Item label="Dining Time Request" name="dining_time_request">
              <Select
                onDeselect={(value) => {
                  addAndRemoveDiningTime("remove", value);
                }}
                onSelect={(value) => {
                  addAndRemoveDiningTime("add", value);
                }}
                showArrow
                showSearch            
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                options={diningOptions?.dining_time_request?.map((val) => {
                  return {
                    key: val.tid,
                    value: val.uuid,
                    label: val.name,
                  };
                })}
              />
            </Form.Item>

            <Form.Item label="Dining Time Assigned" name="dining_time_assigned">
              <Select
                onDeselect={(value) => {
                  addAndRemoveDiningTime("remove", value);
                }}
                onSelect={(value) => {
                  addAndRemoveDiningTime("add", value);
                }}
                showArrow
                showSearch            
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                options={diningOptions?.dining_time_assigned?.map((val) => {
                  return {
                    key: val.tid,
                    value: val.uuid,
                    label: val.name,
                  };
                })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default DiningOptions;
