import { Form, Grid, Layout } from "antd";
import Horizontals from "../../../components/Header/Horizontal_menu";
import React, { useState } from "react";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CreateOrEditItineraryContainer from "../../../components/Events/EventDetailsTabs/ItinerariesTab/CreateOrEditItinerary/CreateOrEditItineraryContainer";
import CreateOrEditItineraryHeader from "../../../components/Events/EventDetailsTabs/ItinerariesTab/CreateOrEditItinerary/CreateOrEditItineraryHeader";
import {
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";
import { useSearchParams } from "react-router-dom";
const { Header, Content } = Layout;

const AddOrEditItineraryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const eventId = searchParams.get("id");
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    {
      title: "Itineraries",
      path: `${EventDetailsRoute}?id=${eventId}#Itineraries`,
    },
    { title: "Add Itineraries", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditItineraryHeader
              form={form}
              isLoading={isLoading}
              itineraryType={"event"}
            />
          </div>
          <Content>
            <CreateOrEditItineraryContainer
              eventId={eventId}
              form={form}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              itineraryType={"event"}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddOrEditItineraryPage;
