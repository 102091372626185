import { Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateSingleVocabularyWeight } from "../../../redux/features/customDropdownFeatures/customDropDownSlice";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const EditCustomDropdownHeader = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  let { orderedTerms, updateWeightLoading, updateWeightResponse } = useSelector(
    (state) => state.customDropDown.singleVocabularyLocations
  );
  const paramsId = searchParams.get("id");
  const headerRef = useRef();

  useEffect(() => {
    if (headerRef.current) {
      if (updateWeightResponse.status) {
        message.success(updateWeightResponse?.data?.message);
        navigate("/custom-dropdown");
      } else {
        message.error(
          updateWeightResponse?.data?.response?.data?.message ||
            updateWeightResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    headerRef.current = true;
  }, [updateWeightResponse]);

  const handleSave = () => {
    if (paramsId && orderedTerms.length) {
      dispatch(
        updateSingleVocabularyWeight({
          vocabulary: paramsId,
          terms: orderedTerms,
        })
      );
    } else {
      message.success("No Changes In Order");
      navigate("/custom-dropdown");
    }
  };

  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="addnote_content">
          <div className="breadcrumb_title">
            <h3 className="flex_row_column">
              <CloseOutlined
                className="back-icon-profile"
                onClick={() => navigate("/custom-dropdown")}
              />
              <div className="header_res">
                <span>{title}</span>
              </div>
            </h3>
          </div>
        </div>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            loading={updateWeightLoading}
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button onClick={() => navigate("/custom-dropdown")}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default EditCustomDropdownHeader;
