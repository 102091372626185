import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  createAccommodationApi,
  getSingleAccommodationApi,
  updateAccommodationApi,
} from "../../../../../apis/eventsApi";
import { useSearchParams } from "react-router-dom";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { AccommodationOrCategoryCreated } from "../../../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const EventAddAccommodation = ({ addonId, eventId }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = (val) => {
    setOpen(val);
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          showDrawer("Add");
        }}
      >
        Accommodation
      </div>

      <AddAccomodationDrawer
        open={open}
        eventId={eventId}
        addonId={addonId}
        setOpen={setOpen}
      />
    </>
  );
};

export default EventAddAccommodation;

export const AddAccomodationDrawer = ({
  open,
  accId,
  eventId,
  addonId,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const [isAccomodationLoading, setIsAccomodationLoading] = useState(false);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [form] = Form.useForm();

  const getSingleAccommodation = async () => {
    setIsAccomodationLoading(true);
    const response = await getSingleAccommodationApi(accId);
    if (response?.status) {
      const data = response?.data?.data;
      form.setFieldsValue({
        accomodationType: data?.name,
        description: data?.field_description,
        displayOrder: data?.field_display_order,
        subGroup: data?.field_add_as_sub_group,
        agentDisplay: data?.field_agent_display,
        webEnabled: data?.field_web_enabled,
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsAccomodationLoading(false);
  };

  useEffect(() => {
    if (accId) {
      getSingleAccommodation();
    }
  }, [open, accId]);

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onFinishHandler = (values) => {
    const {
      accomodationType,
      agentDisplay,
      description,
      displayOrder,
      subGroup,
      webEnabled,
    } = values;
    const payload = {
      data: {
        type: "accommodation_type--accommodation_type",
        attributes: {
          name: accomodationType,
          field_description: description,
          field_display_order: displayOrder,
          field_add_as_sub_group: Boolean(subGroup),
          field_agent_display: Boolean(agentDisplay),
          field_web_enabled: Boolean(webEnabled),
          status: 1,
        },
        relationships: {
          field_event: {
            data: {
              type: "event--event",
              id: addonId || eventId,
            },
          },
        },
      },
    };
    setOnSaveLoading(true);
    if (accId) {
      payload["data"]["id"] = accId;
      updateAccommodation(payload, accId);
    } else {
      createAccommodation(payload);
    }
  };

  const createAccommodation = async (payload) => {
    const response = await createAccommodationApi(payload);
    if (response?.status) {
      dispatch(AccommodationOrCategoryCreated());
      onClose();
      message.success("Accommodation Added Successfully");
      form.resetFields();
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setOnSaveLoading(false);
  };

  const updateAccommodation = async (id, payload) => {
    const response = await updateAccommodationApi(id, payload);
    if (response?.status) {
      dispatch(AccommodationOrCategoryCreated());
      onClose();
      message.success("Accomodation  Updated Successfully");
      form.resetFields();
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setOnSaveLoading(false);
  };

  return (
    <Drawer
      title="Accommodation Type"
      maxWidth={425}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: "32px" }}
      extra={
        <Space>
          <Button
            onClick={() => {
              form.submit();
            }}
            type="primary"
            loading={onSaveLoading}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Spin size="large" spinning={isAccomodationLoading}>
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Accommodation Type Name"
                name="accomodationType"
                rules={[
                  {
                    required: true,
                    message: "Please Enter accommodation type name",
                  },
                ]}
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Display Order"
                name="displayOrder"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown"
                    ) {
                      evt.preventDefault();
                    }
                    evt.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={20} md={20} lg={24}>
              <Form.Item
                className="swith_form"
                label="Add As Sub Group"
                name="subGroup"
                valuePropName="checked"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col xs={20} md={20} lg={24}>
              <Form.Item
                className="swith_form"
                label="Agent Display"
                name="agentDisplay"
                valuePropName="checked"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col xs={20} md={20} lg={24}>
              <Form.Item
                className="swith_form"
                label="Web Enabled"
                name="webEnabled"
                valuePropName="checked"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};
