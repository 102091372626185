import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Modal,
  Switch,
  Table,
  message,
  Tooltip,
  Form,
} from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteAccommodatiobnApi } from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { EventDetailsAddonsDetailsRoute } from "../../../../../url-routes/urlRoutes";
import { AddAccomodationDrawer } from "../CategoriesListing/EventAddAccommodation";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const GeneraInformation = ({ accommodationTypeData, loading, addonId }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const accId = searchParams.get("accId");
  const eventId = searchParams.get("id");
  // const onClose = () => {
  //   setOpen(false);
  // };
  const showDrawer = () => {
    setOpen(true);
  };

  const deleteAccomodation = async (deleteId) => {
    const response = await deleteAccommodatiobnApi(deleteId);
    if (response?.status) {
      message.success("Accommodation Deleted Successfully");
      navigate(
        addonId
          ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
          : `/event/details?id=${eventId}#Accommodations`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this accommodation?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteAccomodation(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const data = [
    {
      key: "1",
      rez: "Accommodation Type",
      name: accommodationTypeData?.name,
    },
    {
      key: "2",
      rez: "Description",
      name: accommodationTypeData?.field_description,
    },
    {
      key: "3",
      rez: "Display Order",
      name: accommodationTypeData?.field_display_order,
    },

    {
      key: "4",
      rez: "Add As Sub Group",
      name: (
        <>
          <Switch
            checked={accommodationTypeData?.field_add_as_sub_group}
            disabled
          />
        </>
      ),
    },

    {
      key: "4",
      rez: "Agent Display",
      name: (
        <>
          <Switch
            checked={accommodationTypeData?.field_agent_display}
            disabled
          />
        </>
      ),
    },

    {
      key: "5",
      rez: "Web Enabled",
      name: (
        <>
          <Switch checked={accommodationTypeData?.field_web_enabled} disabled />
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "Rez #",
      dataIndex: "rez",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];
  return (
    <div>
      {open ? (
        <AddAccomodationDrawer
          // loading={loading}
          // setLoading={setLoading}
          open={open}
          accId={accId}
          // onClose={onClose}
          eventId={eventId}
          addonId={addonId}
          setOpen={setOpen}
        />
      ) : null}
      <div
        className="reservarion_heading bg-white"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          General Information
        </h4>
        <div className="buttons_flex">
          {permissionChecker("add accommodation type entities") ? <Tooltip title="Edit" placement="top">
            <Button
              type="y"
              icon={<EditOutlined />}
              onClick={() => showDrawer()}
            />
          </Tooltip> : null}

          {permissionChecker("delete accommodation type entities") ? <Tooltip title="Delete" placement="top">
            <Button
              className="delete_btn"
              type="y"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.preventDefault();
                onDeleteHandler(accId);
              }}
            />
          </Tooltip> : null}
        </div>
      </div>

      <Divider className="global_divider" />

      <Table
        // className="reservat_table category_information"
        className="reservat_table"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={{ indicator: <DataLoader />, spinning: loading }}
        size="small"
      />
    </div>
  );
};
export default GeneraInformation;
