import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, Form } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
// import Org_horizontalmenu from "../../components/Header/Org_horizontalmenu";
import Searchintakes_menu from "../../components/Header/Searchintakes_menu/Searchintakes_menu";
import Breadcrumb_intaketour from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditTour/CreateOrEditTourHeader";
import Intaketour_viewtabs from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditTour/CreateOrEditTourTabsContainer";
import React from "react";
import { GroupDetailsListingRoute } from "../../url-routes/urlRoutes";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import CreateOrEditTourHeader from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditTour/CreateOrEditTourHeader";
import CreateOrEditTourTabsContainer from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditTour/CreateOrEditTourTabsContainer";
import { useDispatch } from "react-redux";
import { useSearchParams , useParams} from "react-router-dom";
const { Header, Content, Sider } = Layout;

const CreateOrEditTourPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const createId = searchParams.get("creationId");
  const editId = searchParams.get("editId");
  const preExtId = searchParams.get("preExtId");
  const postExtId = searchParams.get("postExtId");
  let { type } = useParams();
  type = capitalizeAndRemoveHyphens(type);
  const breadCrumbs = [
    { title: "Group Details", path: GroupDetailsListingRoute },
    { title: editId ? `${type}` : `Add ${type}`, path: "" },
  ];

  function capitalizeAndRemoveHyphens(input) {
    switch (input) {
      case 'charter-cruise':
        return 'Charter Cruise';
      case 'cruise-tour':
        return 'Cruise/Tour';
      case 'tour':
        return 'Tour';
      default:
        return input
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  }

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditTourHeader
              form1={form1}
              form2={form2}
              form3={form3}
              editId={editId}
              createId={createId}
              preExtId={preExtId}
              postExtId={postExtId}
              type={type}
            />
          </div>
          <CreateOrEditTourTabsContainer
            form1={form1}
            form2={form2}
            form3={form3}
            editId={editId}
            createId={createId}
            preExtId={preExtId}
            postExtId={postExtId}
            type={type}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CreateOrEditTourPage;
