import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import "./viewreport.css";
import { updateRecentlyViewed } from "../../utils/recentlyViewedUpdater";
import ReportDetailsHeader from "../../components/Reports/ReportDetailsHeader";
import { getReportDetailsApi } from "../../apis/reportApi";
const { Header, Content, Sider } = Layout;

function ViewReportPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const reportSource = searchParams.get("report_link");
  const reportId = searchParams.get("id");
  const reportUuid = searchParams.get("uuid");
  const [loadingReportName, setLoadingReportName] = useState(false);
  const [reportData, setReportData] = useState({});
  const breadCrumbs = [
    { title: "All Reports", path: "/reports" },
    { title: "View Report", path: "" },
  ];

  useEffect(() => {
    updateRecentlyViewed("reports", reportUuid, "en", Number(reportId));
    getReportDetails(reportUuid);
  }, []);

  const getReportDetails = async (reportUuid) => {
    setLoadingReportName(true);
    const res = await getReportDetailsApi(reportUuid);
    if (res.status) {
      console.log({ res });
      setReportData(res?.data?.data);
      setLoadingReportName(false);
    } else {
      message.error("Unable to fetch report name");
      setReportData({});
      setLoadingReportName(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>

          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ReportDetailsHeader
              reportData={reportData}
              loadingReportName={loadingReportName}
            />
          </div>
          <Content className="site-layout-background">
            <div className="scrollable-container">
              <object
                data={reportSource}
                width="100%"
                height="100%"
                //   type="text/html"
                style={{ overflow: "auto" }}
              >
                No Content
              </object>
            </div>
            {/* <iframe src={reportSource} width="100%" height="" /> */}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ViewReportPage;
