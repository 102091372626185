import { Button, Skeleton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function EditActivityHeader({ activityDetails, form }) {
  const navigate = useNavigate();
  const { isLoading } = useSelector(
    (state) => state.contacts.createAndUpdateActivity
  );
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => navigate(-1)}
          />
          <div className="header_res">
            <p>
              Edit Task:{" "}
              {!activityDetails?.field_task_type?.label ? (
                <Skeleton.Button
                  shape="round"
                  size="small"
                  style={{ width: "100px" }}
                />
              ) : (
                `${activityDetails?.field_task_type?.label} (${activityDetails?.drupal_internal__id})`
              )}{" "}
              {/* Email Confirmation (606737) */}
            </p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
export default EditActivityHeader;
