import { Divider, Menu, Table } from "antd";
import EventAddAccommodation from "../../../Events/EventDetailsTabs/CategoriesTab/CategoriesListing/EventAddAccommodation";
import Event_addcategory from "../../../Events/EventDetailsTabs/CategoriesTab/CategoriesListing/EventAddCategory";
import { useEffect, useState } from "react";
import { addCommasInPricing } from "../../../../utils/contentParser";

const menu = (
  <Menu
    items={[
      {
        label: <EventAddAccommodation />,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <Event_addcategory />,
        key: "1",
      },
    ]}
  />
);

const columns = [
  {
    title: "Traveler",
    dataIndex: "guest",
  },

  {
    title: "Percent",
    dataIndex: "percent",
  },

  {
    title: "Amount",
    dataIndex: "amount",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },
];

const Reservationpayment_responsibility = (props) => {
  const { data } = props;
  const [payData, setPayData] = useState();
  useEffect(() => {
    if (data) {
      let traveller = [];
      data.forEach((element, index) => {
        if (element?.field_payment_resp_amount) {
          traveller.push({
            key: index,
            guest: element.traveler_name,
            percent: element?.field_payment_resp_percent,
            amount: element?.field_payment_resp_amount || 0,
          });
        }
      });
      setPayData(traveller);
    }
  }, [data]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'" }}> Payment Responsibility</h4>
      </div>

      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={payData}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

export default Reservationpayment_responsibility;
