import axios from "axios";
import drupalConfig from "../config/serverless/drupal.config";

export const loginAxiosRequest = async (
  reqMethod,
  reqUrl,
  data
) => {
    let result;
    let config = {
        method: reqMethod,
        maxBodyLength: Infinity,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
        data : data
      };
  try {
      result = await axios.request(config);
    console.log("result=================", result);
    return { data: result.data, status: true };
  } catch (error) {
    console.log("error", error);
    return { data: error, status: false };
  }
};
