import { Checkbox, Divider, Empty, Input, Table } from "antd";
import React from "react";
import { addCommasInPricing } from "../../../../../../utils/contentParser";

const PlannedPaymentAppyToFormTable = ({ data, handleTableInputChange }) => {
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Payment Amount",
      dataIndex: "planned_amount",
      render: (item) => {
        return `$${addCommasInPricing(item?.toString() || "0")}`;
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      render: (item) => {
        return `$${addCommasInPricing(item?.toString() || "0")}`;
      },
    },
    {
      title: "Apply?",
      dataIndex: "apply",
      render: (text, record, index) => {
        return (
          <Checkbox
            disabled={
              Number(record.planned_amount) === Number(record.amount_paid)
            }
            checked={text}
            onChange={(e) => {
              handleTableInputChange(e.target.checked, index, "apply");
            }}
          ></Checkbox>
        );
      },
    },

    {
      title: "Apply from this payment",
      dataIndex: "apply_from_this_payment",
      render: (text, record, index) => {
        return (
          <Input
            disabled
            style={{ maxWidth: "150px" }}
            value={text}
            onChange={(e) =>
              handleTableInputChange(
                e.target.value,
                index,
                "apply_from_this_payment"
              )
            }
          />
        );
      },
    },
  ];

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Planned Payments - Apply To</h4>
      </div>
      <Divider className="global_divider" />
      {data && data.length ? (
        <Table
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={false}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
export default PlannedPaymentAppyToFormTable;
