import React from "react";
import "./App.less";
import AppContainer from "./components/AppContainer/AppContainer";

function App() {
  window.onerror = (e) => {
    if (e === "ResizeObserver loop limit exceeded") {
      console.error("Error can be ignored", e);
      document.getElementById(
        "webpack-dev-server-client-overlay"
      ).style.display = "none";
    }
  };
  return (
    <>
      <AppContainer />
    </>
  );
}
export default App;
