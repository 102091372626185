import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { Grid } from "antd";
import { Link, useNavigate } from "react-router-dom";
import logo_two from "../../assests/images/IT_logo_color.svg";
import "./ForgotPassword.less";
import React, { useEffect, useState } from "react";
import { generatePasswordOtp } from "../../apis/authentication";
import DataLoader from "../../../src/others/Loaders/DataLoader";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const { lg } = useBreakpoint(); // lg is one of t,he elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const validateElementFields = (errorField, errorMessage) => {
    form.setFields([
      {
        name: errorField,
        errors: errorMessage ? [errorMessage] : [],
      },
    ]);
  };

  useEffect(() => {
    if (Token) {
      message.error("You Are Already Logged In");
      navigate(-1);
    }
    setPageLoading(false);
  }, []);

  const sendOtp = async (data) => {
    const response = await generatePasswordOtp(data);

    if (response && response.status === true) {
      if (response.data.message === "Verification Token sent") {
        message.success(response.data.message);
        navigate("/set-password");
        localStorage.setItem("forgotPasswordEmail", data.value);
      } else {
        validateElementFields("email", response.data.message);
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const onFinishHandler = (values) => {
    const data = {
      type: "email",
      value: values.email,
    };
    setLoading(true);
    sendOtp(data);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg_main">
      <div className="bg-overlay"></div>
      {!pageLoading ? (
        <div className="main_login">
          <div className="main_login-container">
            <div className="main_login-container--logo">
              <div className="forgot_arrow">
                <Link to="/login">
                  <ArrowLeftOutlined />
                </Link>
              </div>
              <img
                src={logo_two}
                alt="This is a Logo"
                className="logo_two"
              ></img>
            </div>
            <Form
              layout="vertical"
              name="normal_login"
              className="login-form forgot_main"
              onFinish={onFinishHandler}
              form={form}
            >
              <Form.Item>
                <h2>Forgot Password?</h2>
                <p className="span_text">
                  Please enter the email address used to create your account and we will send you a one-time code to access your Inspiration Travel account and reset your password.
                </p>
              </Form.Item>

              <div className="forgot_emailaddress">
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Enter email address",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email address" />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Get One-Time Code
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className="login_bottom">
            <p>Copyright ©{currentYear} Inspiration Travel </p>
          </div>
        </div>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default ForgotPassword;
