import countryList from "../../../../../utils/countryList.json";
import { DatePicker, Form, Input, Select, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import {
  addonStepSetter,
  createOrEditAddon,
  getAddonPartnersList,
  getAddonSuppliersList,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import { EventDetailsAddonsDetailsRoute } from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Option, OptGroup } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddonsGeneralInfoForm = ({
  form1,
  createId,
  editId,
  eventId,
  next,
  addonFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addonCreation, addonPartners, addonSuppliers } = useSelector(
    (state) => state.addons
  );
  const { addonCreationResponse, loadingAddonCreation, step1, addonStep } =
    addonCreation;
  const { addonPartnersResponse, loadingAddonPartners } = addonPartners;
  const { addonSuppliersResponse, loadingAddonSuppliers } = addonSuppliers;
  const [addonPartnersList, setAddonPartnersList] = useState([]);
  const [addonSuppliersList, setAddonSuppliersList] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [loadingVenue, setLoadingVenue] = useState(false);
  const addonCreationRef = useRef(false);
  const addonPartnersRef = useRef(false);
  const addonSuppliersRef = useRef(false);
  const addonType = searchParams.get("addonType");
  const addonsType = [
    { value: "air", label: "Air" },
    { value: "pre_extension_air", label: "Pre Extension Air" },
    { value: "post_extension_air", label: "Post Extension Air" },
    { value: "pre_extension", label: "Pre Extension" },
    { value: "post_extension", label: "Post Extension" },
    { value: "pre_extension_hotel", label: "Pre Extension Hotel" },
    { value: "post_extension_hotel", label: "Post Extension Hotel" },
    { value: "resort", label: "Resort" },
    { value: "template", label: "Template" },
    { value: "pre_book", label: "Pre-Book" },
    { value: "tour", label: "Tour" },
    { value: "hotel", label: "Hotel" },
    { value: "staff_hotel", label: "Staff Hotel" },
    { value: "insurance", label: "Insurance" },
    { value: "dining", label: "Dining" },
    { value: "show_with_seating", label: "Show with Seating" },
    { value: "conference", label: "Conference" },
    { value: "tour_extension", label: "Tour Extension" },
    { value: "cruise_tour", label: "Cruise/Tour" },
    { value: "pre", label: "Pre" },
    { value: "post", label: "Post" },
    { value: "motorcoach", label: "Motorcoach" },
    { value: "pre_hotel", label: "Pre Hotel" },
    { value: "post_hotel", label: "Post Hotel" },
    { value: "activity", label: "Activity" },
    { value: "pre_extension_motorcoach", label: "Pre Extension Motorcoach" },
    { value: "post_extension_motorcoach", label: "Post Extension Motorcoach" },
    { value: "dining_time", label: "Dining Time" },
    { value: "box_office", label: "BoxOffice" },
    { value: "transfer", label: "Transfer" },
    { value: "accessories", label: "Accessories" },
    { value: "gift", label: "Gift" },
    { value: "miscellaneous", label: "Miscellaneous" },
  ];
  useEffect(() => {
    dispatch(getAddonPartnersList());
    dispatch(getAddonSuppliersList());
    getTaxonomyList("destination", setDestinationList, setLoadingDestination);
    getTaxonomyList("ship", setVenueList, setLoadingVenue);
  }, []);

  useEffect(() => {
    if (addonPartnersRef.current) {
      if (addonPartnersResponse.status) {
        setAddonPartnersList(addonPartnersResponse.data.data);
      } else {
        setAddonPartnersList([]);
      }
    }
    addonPartnersRef.current = true;
  }, [addonPartnersResponse]);

  useEffect(() => {
    if (addonSuppliersRef.current) {
      if (addonSuppliersResponse.status) {
        setAddonSuppliersList(addonSuppliersResponse.data.data);
      } else {
        setAddonSuppliersList([]);
      }
    }
    addonSuppliersRef.current = true;
  }, [addonSuppliersResponse]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(addonFormFields).length) {
      dispatch(addonStepSetter(addonFormFields?.field_step));

      form1.setFieldsValue({
        ...addonFormFields,
        field_start_date: !!addonFormFields?.field_start_date
          ? moment(addonFormFields?.field_start_date, "YYYY-MM-DD")
          : null,
        field_end_date: addonFormFields?.field_end_date
          ? moment(addonFormFields?.field_end_date, "YYYY-MM-DD")
          : null,
        field_add_ons_type: addonFormFields?.field_add_ons_type?.key,
        field_event_partner: addonFormFields?.field_event_partner?.length
          ? addonFormFields?.field_event_partner?.map((partner) => partner?.id)
          : [],
        field_event_supplier: addonFormFields?.field_event_supplier?.length
          ? addonFormFields?.field_event_supplier?.map(
              (supplier) => supplier?.id
            )
          : [],
        field_destination: addonFormFields?.field_destination?.id,
        field_ship: addonFormFields?.field_ship?.id,
      });
    }
    if (!editId && !createId && !currentStep) {
      form1.resetFields();
    }
  }, [addonFormFields]);

  useEffect(() => {
    if (addonCreationRef.current) {
      if (addonCreationResponse.status) {
        let identifyForm = createId || editId ? "editForm" : "creationForm";

        dispatch(
          addonStepSetter(addonCreationResponse?.data?.data?.field_step) || 1
        );
        if (identifyForm === "creationForm" || createId) {
          message.success("Form Submitted Successfully");
          step1
            ? navigate(
                `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${
                  addonCreationResponse?.data?.data?.id || createId
                }`
              )
            : next({
                type: "creation",
                addonId: addonCreationResponse?.data?.data?.id || createId,
                id: eventId,
              });
        } else {
          if (identifyForm === "editForm") {
            message.success("Form Submitted Successfully");
            step1
              ? navigate(
                  `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${editId}`
                )
              : next({ type: "edit", addonId: editId, id: eventId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateAddonStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    addonCreationRef.current = true;
  }, [addonCreationResponse]);

  const onFinishHandler = (values) => {
    const reqType = createId || editId ? "patch" : "post";
    const attributes = {
      name: values.name,
      field_is_add_on: true,
      field_status:
        reqType === "post" ? "design" : addonFormFields?.field_status?.key,
      field_add_ons_type: values?.field_add_ons_type || null,
      status: true,
      field_direct_event: true,
      field_event_code: values.field_event_code,
      field_start_date: values["field_start_date"]?.format("YYYY-MM-DD"),
      field_end_date: values["field_end_date"]?.format("YYYY-MM-DD"),
      field_step: currentStep > addonStep ? +currentStep : addonStep,
      field_destination_country: values.field_destination_country,
    };
    const relationships = {
      field_event: {
        data: {
          type: "event--event",
          id: eventId,
        },
      },
      field_event_partner: values?.field_event_partner?.length
        ? {
            data: values.field_event_partner?.map((partnerId) => ({
              type: "node--organization",
              id: partnerId,
            })),
          }
        : null,
      field_event_supplier: values?.field_event_supplier?.length
        ? {
            data: values?.field_event_supplier?.map((supplierId) => ({
              type: "node--organization",
              id: supplierId,
            })),
          }
        : null,
      field_destination: values?.field_destination
        ? {
            data: {
              type: "taxonomy_term--destination",
              id: values.field_destination,
            },
          }
        : null,
      field_ship: values?.field_ship
        ? {
            data: {
              type: "taxonomy_term--destination",
              id: values.field_ship,
            },
          }
        : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };
    const data = {
      data: {
        type: "event--event",
        attributes,
        relationships,
      },
    };
    if (reqType === "patch") {
      data.data.id = createId || editId;
    }
    const payload = { reqType, uuid: createId || editId, data };
    dispatch(createOrEditAddon(payload));
  };

  const validateEndDate = (e, value) => {
    if (e.field === "field_start_date") {
      const startDate = value;
      const endDate = form1.getFieldValue("field_end_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form1.setFields([
            {
              name: "field_end_date",
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form1.setFields([
              {
                name: "field_end_date",
                errors: ["End Date cannot be less than the Start Date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form1.getFieldValue("field_start_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End Date cannot be less than the Start Date");
        }
      }
    }
    return Promise.resolve();
  };
  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form1}
          onFinish={onFinishHandler}
          initialValues={{ field_add_ons_type: addonType }}
        >
          <Form.Item
            label="Type"
            name="field_add_ons_type"
            rules={[{ required: true, message: "Please Select Add-On Type" }]}
          >
            <Select
              showSearch
              options={addonsType.map((addons) => ({
                key: addons.value,
                value: addons.value,
                label: addons.label,
              }))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label="Add-On Name"
            className="asterick-align"
            name="name"
            rules={[{ required: true, message: "Please Enter Add-On Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Add-On Code"
            className="asterick-align"
            name="field_event_code"
            rules={[{ required: true, message: "Please Enter Add-On Code" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Add-On Partner"
            className="asterick-align"
            name="field_event_partner"
            rules={[
              { required: true, message: "Please Select Add-On Partner" },
            ]}
          >
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              showArrow
              loading={loadingAddonPartners}
              optionLabelProp="label"
              options={addonPartnersList?.map((addonPartners) => ({
                key: addonPartners?.id,
                label: addonPartners?.title,
                value: addonPartners?.id,
              }))}
            />
          </Form.Item>

          <Form.Item label="Suppliers" name="field_event_supplier">
            <Select
              mode="multiple"
              showArrow
              allowClear
              loading={loadingAddonSuppliers}
              optionLabelProp="label"
              options={
                addonSuppliersList?.length
                  ? addonSuppliersList?.map((addonSuppliers) => ({
                      key: addonSuppliers?.id,
                      label: addonSuppliers?.title,
                      value: addonSuppliers.id,
                    }))
                  : []
              }
            />
          </Form.Item>

          <Form.Item
            label="Start Date"
            className="asterick-align"
            name="field_start_date"
            rules={[
              { required: true, message: "Please Select Start Date" },
              {
                validator: validateEndDate,
                dependencies: ["field_end_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="End Date"
            name="field_end_date"
            rules={[
              { required: true, message: "Please Select End Date" },
              {
                validator: validateEndDate,
                dependencies: ["field_start_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item label="Destination" name="field_destination">
            <Select
              allowClear
              loading={loadingDestination}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Destination"
              options={destinationList?.map((destination) => ({
                key: destination.id,
                label: destination?.name,
                value: destination.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Destination Country"
            name="field_destination_country"
          >
            <Select
              allowClear
              loading={loadingDestination}
              placeholder="Destination Country"
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={Object.keys(countryList).map(
                (eventDestination, index) => ({
                  key: index,
                  label: countryList[eventDestination],
                  value: eventDestination,
                })
              )}
            />
          </Form.Item>

          <Form.Item label="Ship" name="field_ship">
            <Select
              allowClear
              showSearch
              placeholder="Ship"
              loading={loadingVenue}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={venueList?.map((venue) => ({
                key: venue.id,
                value: venue.id,
                label: venue?.name,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddonsGeneralInfoForm;
