import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  cancelReservationItemDetailApi,
  getReservationDetailApi,
  getReservationItemDetailApi,
} from "../../../../apis/reservationApi";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CancelReservationItemHeader from "../../../../components/Reservation/ReservationItem/CancelReservationItem/CancelReservationItemHeader";
import ResItemCancellationCalculation from "../../../../components/Reservation/ReservationItem/CancelReservationItem/ResItemCancellationCalculation";
import ReservationSearchBar from "../../../../components/Reservation/ReservationSearchBar";
import CancellationsDetails from "../../../../components/View_components/Reservations/Reservationtable_components/CancellationDetails";
import {
  ReservationDetailRoute,
  ReservationItemDetailRoute,
  ReservationListingRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import CalculationDetails from "../../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CalculationDetails";

const { Header, Content } = Layout;

function CancelReservationItemPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const reservationId = searchParams.get("id");
  const itemId = searchParams.get("item-id");
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const [cancelDetails, setCancelDetails] = useState({});
  const [loadingCancelDetails, setLoadingCancelDetails] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [resItemData, setResItemData] = useState({});
  const [loadingResItem, setLoadingResItem] = useState(false);
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Details", path: `${ReservationDetailRoute}?id=${reservationId}` },
    {
      title: "Reservation Item",
      path: `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${itemId}`,
    },
    { title: "Cancel Reservation Item", path: "" },
  ];

  useEffect(() => {
    getReservationDetail(reservationId);
    getReservationItemDetail(itemId);
    cancelReservationItemDetail({ resId: reservationId, itemId });
  }, [changeStatus]);

  const getReservationItemDetail = async (itemId) => {
    setLoadingResItem(true);
    const res = await getReservationItemDetailApi(itemId);
    if (res.status) {
      setResItemData(res?.data?.reservation_item);
      setLoadingResItem(false);
    } else {
      setResItemData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingResItem(false);
    }
  };
  const cancelReservationItemDetail = async ({ resId, itemId }) => {
    setLoadingCancelDetails(true);
    const res = await cancelReservationItemDetailApi({ resId, itemId });
    if (res.status) {
      setCancelDetails(res?.data?.data);
      setLoadingCancelDetails(false);
    } else {
      setCancelDetails({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingCancelDetails(false);
    }
  };
  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ReservationSearchBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CancelReservationItemHeader
              reservationId={reservationId}
              itemId={itemId}
              reservationData={reservationData}
              loadingReservationData={loadingReservationData}
              cancelDetails={cancelDetails}
              loadingCancelDetails={loadingCancelDetails}
              setChangeStatus={setChangeStatus}
              resItemData={resItemData}
              changeStatus={changeStatus}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <div className="mb-20">
              <ResItemCancellationCalculation
                cancelDetails={cancelDetails}
                loadingCancelDetails={loadingCancelDetails}
              />
            </div>
          </Content>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CalculationDetails
              cancelDetails={cancelDetails}
              loadingCancelDetails={loadingCancelDetails}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default CancelReservationItemPage;
