import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import {
  getAllVocabularies,
  searchVocabulary,
} from "../../../redux/features/customDropdownFeatures/customDropDownSlice";
import { useDispatch, useSelector } from "react-redux";
import DataLoader from "../../../others/Loaders/DataLoader";
import FourLocationModal from "../../View_components/Editdropdown_table/FourLocationModal";
import { useDebounce } from "../../../others/Debounce/debounce";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "1",
    width: "70%",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "2",
  },
  {
    title: <div className="Action">Action</div>,
    dataIndex: "action",
  },
];

function Customdropdown_table() {
  const [searchValue, setSearchValue] = useState("");
  const { Search } = Input;
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const allVocabularies = useSelector(
    (state) => state.customDropDown.allVocabularies
  );
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditCustom = authData?.permissions?.includes(
    "edit custom dropdown"
  );
  const loading = useSelector((state) => state.customDropDown.loading);

  let data = [];

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(searchVocabulary(debouncedSearchTerm));
    }else{
      dispatch(getAllVocabularies());
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    dispatch(getAllVocabularies());
  }, [dispatch]);

  function onSearch(name) {
    if (name !== "") {
      setSearchValue(name);
    } else {
      setSearchValue("");
    }
  }

  if (allVocabularies || allVocabularies.length > 0) {
    data = allVocabularies?.map((item, index) => {
      if(["multi_currency","payment_processor"].includes(item?.drupal_internal__vid)) return
      let locations_arr = item?.description?.length
        ? item?.description?.split(",")
        : [];

      return {
        name: <p>{item?.name}</p>,

        location: (
          <div>
            Used at{" "}
            <span style={{ color: "#327f97" }}>
              <FourLocationModal
                locations_arr={locations_arr}
                dropdownName={item?.name}
              />
            </span>
          </div>
        ),

        action: (
          <div className="action_flex action_end">
            {allowEditCustom ? (
              <Link
                to={`/custom-dropdown/edit?id=${item?.drupal_internal__vid}&name=${item?.name}`}
              >
                <EditOutlined className="action_edit" />
              </Link>
            ) : null}
          </div>
        ),
        exp_row: false,
      };
    }).filter(Boolean);
  }

  return (
    <>
      <div className="report_elitedetails" style={{ marginBottom: "20px" }}>
        <Search
          placeholder="Search"
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
      </div>

      <Table
        className="event_cruise_table table_tour"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        loading={{ spinning: loading, indicator: <DataLoader /> }}
      />
    </>
  );
}

export default Customdropdown_table;
