import { createSlice } from "@reduxjs/toolkit";

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    isReportFilterApplied: false,
    reportSearchFilters: {},
  },
  reducers: {
    reportSearchFilterApplied: (state, action) => {
      state.isReportFilterApplied = action.payload;
    },
    reportSearchFiltersSetter: (state, action) => {
      state.reportSearchFilters = action.payload;
    },
  },
});

export const { reportSearchFilterApplied, reportSearchFiltersSetter } =
  reportSlice.actions;
export default reportSlice.reducer;
