import { ImportOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Row, Space, Upload } from "antd";
import React, { useState } from "react";
import ImportDraggerUpload from "../../../others/CustomUpload/ImportDraggerUpload";
const { Dragger } = Upload;

const ImportContact = ({
  open,
  setOpen,
  setIsModalOpen,
  setErrorList,
  errorList,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button type="" onClick={showDrawer}>
        <ImportOutlined /> <span>Import Contacts</span>
      </Button>

      <Drawer
        title="Import Contact"
        maxWidth={425}
        closable={!isLoading}
        onClose={onClose}
        maskClosable={false}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        // extra={
        //   <Space>
        //     {/* <Button onClick={onClose} style={{ opacity: '0.8' }} >Cancel</Button> */}
        //     <Button onClick={onClose} type="primary">
        //       Save
        //     </Button>
        //   </Space>
        // }
      >
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Upload File"
                name="Upload File"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <ImportDraggerUpload
                  fileList={fileList}
                  isMultiple={true}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/media/document/field_media_document"
                  fileProcessingUrl="/api/rest/bulk_import_entity"
                  entityType="contact"
                  onClose={onClose}
                  setErrorList={setErrorList}
                  setOpen={setOpen}
                  setIsModalOpen={setIsModalOpen}
                />
              </Form.Item>
              {errorList.length ? (
                <>
                  <ul>
                    {errorList.map((val) => {
                      return (
                        <>
                            <span dangerouslySetInnerHTML={{__html:val}} style={{ color: "red" }}/>
                        </>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ImportContact;
