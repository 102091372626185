import { message } from "antd";
import { getTaxonomyListApi } from "../../apis/groupDetailsApi";
import { getTourOperatorApi } from "../../apis/eventsApi";
import {
  getContactsBySearchApi,
  getEliteTravelerStatusApi,
} from "../../apis/contactApi";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

export const getTaxonomyList = async (
  taxonomyName,
  setFieldState,
  setFieldLoading
) => {
  setFieldLoading(true);
  const response = await getTaxonomyListApi(taxonomyName);
  if (response.status) {
    setFieldState(response?.data?.data);
    setFieldLoading(false);
  } else {
    setFieldState([]);
    setFieldLoading(false);
    message.error(SOMETHING_WENT_WRONG);
  }
};

export const getTourOperatorList = async (setFieldState, setFieldLoading) => {
  setFieldLoading(true);
  const response = await getTourOperatorApi();
  if (response.status) {
    setFieldState(response?.data);
    setFieldLoading(false);
  } else {
    setFieldState([]);
    setFieldLoading(false);
    message.error(SOMETHING_WENT_WRONG);
  }
};

export const getEliteTravelerStatus = async (
  contactId,
  setFieldState,
  setFieldLoading
) => {
  setFieldLoading(true);
  const response = await getEliteTravelerStatusApi(contactId);
  if (response.status) {
    setFieldState(response?.data?.data);
    setFieldLoading(false);
  } else {
    setFieldState([]);
    setFieldLoading(false);
    message.error(SOMETHING_WENT_WRONG);
  }
};

export const getContactsBySearch = async (
  contactName,
  setFieldState,
  setFieldLoading
) => {
  setFieldLoading(true);
  const response = await getContactsBySearchApi(contactName);
  if (response.status) {
    setFieldState(response?.data?.data);
    setFieldLoading(false);
  } else {
    setFieldState([]);
    setFieldLoading(false);
    message.error(SOMETHING_WENT_WRONG);
  }
};
