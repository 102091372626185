import { Card, Button, Divider, Anchor, message, Modal, Empty } from "antd";
import {
  EditOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import "./Document_card.less";
import { AssetsDrawer } from "./AssetsForm";
import { deleteAssetsApi } from "../../../../../apis/eventsApi";
import { useDispatch } from "react-redux";
import { triggerIsAssetDeleted } from "../../../../../redux/features/eventFeatures/eventsSlice";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
const { Meta } = Card;

const authData = JSON.parse(localStorage.getItem("authorization"));
const allowAEditDoc = authData?.permissions?.includes(
  "add/edit event document"
);

const AssetsListingCard = ({
  assetType,
  eventData,
  eventId,
  addonId,
  documentTypes,
  docTypesLoading,
}) => {
  const dispatch = useDispatch();
  const { field_assets } = eventData;
  const [open, setOpen] = useState(false);
  const [editAsset, setEditAsset] = useState(null);
  const [assetId, setAssetId] = useState(null);

  function getIcon(format, style = { fontSize: "32px" }) {
    const ICONS_BY_DOCUMENT_TYPE = [
      {
        types: ["image/png", "image/jpeg", "image/svg+xml"],
        icon: <FileImageOutlined style={style} />,
      },
      {
        types: ["application/pdf"],
        icon: <FilePdfOutlined style={style} />,
      },
      {
        types: ["text/plain"],
        icon: <FileTextOutlined style={style} />,
      },
      {
        types: [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
        icon: <FileWordOutlined style={style} />,
      },
      {
        types: [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel.sheet.macroEnabled.12",
        ],
        icon: <FileExcelOutlined style={style} />,
      },
    ];
    const result = ICONS_BY_DOCUMENT_TYPE.find((ele) =>
      ele.types.includes(format)
    );
    if (result?.icon) return result?.icon;
    return <FileOutlined style={style} />;
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const deleteAssest = async (id) => {
    const response = await deleteAssetsApi(id);
    if (response.status) {
      dispatch(triggerIsAssetDeleted());
      message.success("Asset Deleted Successfully");
    } else {
      message.error(
        response?.data?.message ||
          response?.data?.response?.data?.errors[0]?.title ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const handleEdit = (asset, id) => {
    setEditAsset(asset);
    setAssetId(id);
    showDrawer();
  };

  const ondeleteHandler = (deleteId) => {
    if (deleteId) {
      Modal.confirm({
        title: "Are you sure you want to delete this asset?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteAssest(deleteId);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <>
      <div>
        <div className="document_card">
          <Divider className="global_divider mb-0" />
          <AssetsDrawer
            documentTypes={documentTypes}
            docTypesLoading={docTypesLoading}
            eventData={eventData}
            eventId={eventId}
            assetType={assetType}
            addonId={addonId}
            open={open}
            showDrawer={showDrawer}
            editAsset={editAsset}
            assetId={assetId}
            setAssetId={setAssetId}
            setOpen={setOpen}
          />

          {field_assets?.length ? (
            field_assets?.map((asset) => {
              const { media_data, document_data } = asset?.meta || {};
              return (
                <>
                  <Card key={asset?.id}>
                    <div className="card_meta">
                      <Meta
                        avatar={
                          // <FilePdfOutlined style={{ fontSize: "32px" }} />
                          getIcon(media_data?.filemime)
                        }
                        title={
                          <p style={{ margin: "0" }}>
                            {document_data?.document_type || ""}
                          </p>
                        }
                        description={
                          permissionChecker("view event assets") ?  <a
                            style={{
                              position: "relative",
                              top: "-8px",
                              fontWeight: "400",
                            }}
                            href={`${media_data?.uri}`}
                            target="_blank"
                            className="document_pdf"
                          >
                            {media_data?.filename || asset?.name || ""}
                          </a>
                           : <span> {media_data?.filename || asset?.name || ""} </span>
                        }
                      />
                      <div className="card_buttons">
                        {permissionChecker("add/edit event assets") ? (
                          <Button
                            type="y"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(asset, asset?.id)}
                          />
                        ) : null}
                        {permissionChecker("delete event assets") ? (
                        <Button
                          className="delete_btn"
                          icon={<DeleteOutlined />}
                          onClick={() => ondeleteHandler(asset?.id)}
                        />
                        ) : null}
                      </div>
                    </div>
                  </Card>
                  <Divider
                    className="global_divider"
                    style={{ marginBottom: "0" }}
                  />
                </>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </>
  );
};
export default AssetsListingCard;
