import { Breadcrumb, Grid, Layout, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import EventsSearchbar from "../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { EventsListingRoute } from "../../../../url-routes/urlRoutes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSingleAccommodationApi } from "../../../../apis/eventsApi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSingleAccomodation } from "../../../../redux/features/eventFeatures/eventsSlice";
import HeaderAccomodationTypeDetail from "../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/HeaderAccomodationTypeDetail";
import AccomodationCategoryTable from "../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/AccomodationCategoryTable";
import GeneraInformation from "../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/GeneraInformation";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

const AccommodationTypeDetails = () => {
  const dispatch = useDispatch();
  const [accommodationTypeData, setAccommodationTypeData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const accId = searchParams.get("accId");
  const eventId = searchParams.get("id");
  const type = searchParams.get("type");
  const { useBreakpoint } = Grid;
  const navigate = useNavigate()
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const getAccomodationRef = useRef(false);
  const { isAccommodationOrCategoryCreated, singleAccommodation } = useSelector(
    (state) => state.events
  );
  const { singleAccomodationResponse, loadingSingleAccomodation } =
    singleAccommodation;

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `/event/details?id=${eventId}#Accommodations` },
    {
      title:
        type === "sub_acc"
          ? "Accommodation Type Sub-Group Detail"
          : "Accommodation Type Detail",
      path: "",
    },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view published accommodation type entities");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    if (getAccomodationRef?.current) {
      if (singleAccomodationResponse?.status) {
        setAccommodationTypeData(singleAccomodationResponse?.data?.data || {});
      } else {
        message.error(
          singleAccomodationResponse?.error?.response?.data?.errors?.[0]
            ?.title ||
            singleAccomodationResponse?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    getAccomodationRef.current = true;
  }, [singleAccomodationResponse]);

  useEffect(() => {
    dispatch(getSingleAccomodation(accId));
  }, [isAccommodationOrCategoryCreated]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <HeaderAccomodationTypeDetail
              accommodationTypeData={accommodationTypeData}
              loading={loadingSingleAccomodation}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <GeneraInformation
              accommodationTypeData={accommodationTypeData}
              loading={loadingSingleAccomodation}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <AccomodationCategoryTable />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AccommodationTypeDetails;
