import {
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { eventDiscountCreateOrEditApi } from "../../../../apis/eventsApi";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { rewardStatusList } from "../../../../others/util/commonFunctions";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
function DiscountCreateOrEditForm({
  discountType,
  form,
  addonId,
  eventId,
  editId,
  copyId,
  setLoadingDiscountCreation,
  singleDiscountData,
}) {
  const navigate = useNavigate();
  const [discountTypeList, setDiscountTypeList] = useState([]);
  const [loadingDiscountType, setLoadingDiscountType] = useState(false);
  const [discountNameList, setDiscountNameList] = useState([]);
  const [loadingDiscountName, setLoadingDiscountName] = useState(false);
  const [showAdditonalDetails, setShowAdditionalDetails] = useState(false);
  // const genderList = [
  //   { value: "male", name: "Male" },
  //   { value: "female", name: "Female" },
  // ];
  const militaryList = [
    { value: "active_duty", name: "Active Duty" },
    { value: "reserve", name: "Reserve" },
    { value: "veteran", name: "Veteran" },
    { value: "retired", name: "Retired" },
    { value: "family", name: "Family" },
  ];
  useEffect(() => {
    getTaxonomyList(
      "discount_type",
      setDiscountTypeList,
      setLoadingDiscountType
    );
    getTaxonomyList(
      "discount_name",
      setDiscountNameList,
      setLoadingDiscountName
    );
  }, []);

  const { Panel } = Collapse;

  useEffect(() => {
    if ((copyId || editId) && !!Object.keys(singleDiscountData)?.length) {
      form.setFieldsValue({
        name: editId ? singleDiscountData?.name?.id : null,
        field_discount_type: singleDiscountData?.field_discount_type?.id,
        field_promo_code: editId ? singleDiscountData?.field_promo_code : null,
        field_description: singleDiscountData?.field_description,
        field_discount_amount: singleDiscountData?.field_discount_amount,
        field_discount_percent: singleDiscountData?.field_discount_percent,
        field_start_date: singleDiscountData?.field_start_date
          ? moment(singleDiscountData?.field_start_date)
          : null,
        field_end_date: singleDiscountData?.field_end_date
          ? moment(singleDiscountData?.field_end_date)
          : null,
        field_agent_display: singleDiscountData?.field_agent_display,
        field_web_enabled: singleDiscountData?.field_web_enabled,
        field_minimum_age: singleDiscountData?.field_minimum_age,
        field_maximum_age: singleDiscountData?.field_maximum_age,
        field_discount_allowed: singleDiscountData?.field_discount_allowed,
        field_limit_use_count: singleDiscountData?.field_limit_use_count,
        field_limit_per_reservation:
          singleDiscountData?.field_limit_per_reservation,
        field_min_occupancy: singleDiscountData?.field_min_occupancy,
        field_max_occupancy: singleDiscountData?.field_max_occupancy,
        field_web_only: singleDiscountData?.field_web_only,
        // field_gender: singleDiscountData?.field_gender?.key,
        field_military: singleDiscountData?.field_military?.length
          ? singleDiscountData?.field_military?.map((military) => military?.key)
          : [],
        field_minimum_reward_status:
          singleDiscountData?.field_minimum_reward_status?.key || null,
        field_maximum_reward_status:
          singleDiscountData?.field_maximum_reward_status?.key || null,
      });

      if (
        singleDiscountData?.field_minimum_age ||
        singleDiscountData?.field_maximum_age ||
        singleDiscountData?.field_discount_allowed !== null ||
        singleDiscountData?.field_limit_use_count ||
        singleDiscountData?.field_limit_per_reservation ||
        singleDiscountData?.field_min_occupancy ||
        singleDiscountData?.field_max_occupancy ||
        singleDiscountData?.field_web_only !== null ||
        // singleDiscountData?.field_gender?.key ||
        singleDiscountData?.field_military?.length
      ) {
        setShowAdditionalDetails(true);
      }
    }
  }, [singleDiscountData]);

  const createOrEditDiscount = async (data) => {
    setLoadingDiscountCreation(true);
    const response = await eventDiscountCreateOrEditApi(data);
    if (response.status) {
      setLoadingDiscountCreation(false);
      editId
        ? message.success("Discount Edited Successfully")
        : message.success("Discount Added Successfully");
      discountType === "event"
        ? navigate(`${EventDetailsRoute}?id=${eventId}#Discounts`)
        : navigate(
            `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
          );
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
      setLoadingDiscountCreation(false);
    }
  };
  const onFinishHandler = (values) => {
    const reqMethod = editId ? "patch" : "post";
    const payload = {
      data: {
        type: "discounts--discounts",
        attributes: {
          status: true,
          field_promo_code: values?.field_promo_code,
          field_description: values?.field_description,
          field_discount_amount: values?.field_discount_amount,
          field_discount_percent: values?.field_discount_percent,
          field_start_date: values?.field_start_date
            ? moment(values?.field_start_date).format("YYYY-MM-DD")
            : null,
          field_end_date: values?.field_end_date
            ? moment(values?.field_end_date).format("YYYY-MM-DD")
            : null,
          field_agent_display: values?.field_agent_display,
          field_web_enabled: values?.field_web_enabled,
          field_minimum_age: values?.field_minimum_age,
          field_maximum_age: values?.field_maximum_age,
          field_discount_allowed: values?.field_discount_allowed,
          field_limit_use_count: values?.field_limit_use_count,
          field_limit_per_reservation: values?.field_limit_per_reservation,
          field_min_occupancy: values?.field_min_occupancy,
          field_max_occupancy: values?.field_max_occupancy,
          // field_gender: values?.field_gender,
          field_military: values?.field_military?.length
            ? values?.field_military?.map((militaryVal) => ({
                value: militaryVal,
              }))
            : [],
          field_web_only: values?.field_web_only,
          field_minimum_reward_status:
            values?.field_minimum_reward_status || null,
          field_maximum_reward_status:
            values?.field_maximum_reward_status || null,
        },
        relationships: {
          field_discount_type: values?.field_discount_type
            ? {
                data: {
                  type: "taxonomy_term--discount_type",
                  id: values?.field_discount_type,
                },
              }
            : null,
          name: values?.name
            ? {
                data: {
                  type: "taxonomy_term--discount_name",
                  id: values?.name,
                },
              }
            : null,
          field_event: {
            data: {
              type: "event--event",
              id: discountType === "event" ? eventId : addonId,
            },
          },
        },
      },
    };
    if (copyId && reqMethod === "post") {
      payload.data.attributes.field_category = singleDiscountData
        ?.field_category?.length
        ? singleDiscountData?.field_category
        : [];
    }
    if (reqMethod === "patch") {
      payload.data.id = editId;
    }
    createOrEditDiscount({ reqMethod, editId, payload });
  };

  const validateEndDate = (e, value) => {
    if (e.field === "field_start_date") {
      const startDate = value;
      const endDate = form.getFieldValue("field_end_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: "field_end_date",
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: "field_end_date",
                errors: ["End Date cannot be less than the Start Date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue("field_start_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End Date cannot be less than the Start Date");
        }
      }
    }
    return Promise.resolve();
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={onFinishHandler}
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 4,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
        >
          <Form.Item
            className="asterick-align"
            label="Discount Type"
            name="field_discount_type"
            rules={[
              {
                required: true,
                message: "Please select discount type",
              },
            ]}
          >
            <Select
              loading={loadingDiscountType}
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={discountTypeList?.map((discountTypes) => ({
                key: discountTypes?.id,
                value: discountTypes?.id,
                label: discountTypes?.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="Discount Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please select discount name",
              },
            ]}
          >
            <Select
              loading={loadingDiscountName}
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={discountNameList?.map((discountName) => ({
                key: discountName?.id,
                value: discountName?.id,
                label: discountName?.name,
              }))}
            />
          </Form.Item>
          <Form.Item label="Promo Code" name="field_promo_code">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="field_description">
            <Input />
          </Form.Item>
          <Form.Item label="Discount Amount" name="field_discount_amount">
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Discount Percent" name="field_discount_percent">
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (evt.key === "e" || evt.key === "-" || evt.key === "+") {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="field_start_date"
            rules={[
              {
                validator: validateEndDate,
                dependencies: ["field_end_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker style={{ height: "32px" }} format={"MM/DD/YYYY"} />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="field_end_date"
            rules={[
              {
                validator: validateEndDate,
                dependencies: ["field_start_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker style={{ height: "32px" }} format={"MM/DD/YYYY"} />
          </Form.Item>
          <Form.Item
            label={
              <div div className="text_break">
                <div>Minimum Reward </div>
                <div>Status</div>
              </div>
            }
            name="field_minimum_reward_status"
          >
            <Select
              allowClear
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={rewardStatusList?.map((minRewards) => ({
                key: minRewards?.value,
                value: minRewards?.value,
                label: minRewards?.label,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={
              <div div className="text_break">
                <div>Maximum Reward </div>
                <div>Status</div>
              </div>
            }
            name="field_maximum_reward_status"
          >
            <Select
              allowClear
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={rewardStatusList?.map((maxRewards) => ({
                key: maxRewards?.value,
                value: maxRewards?.value,
                label: maxRewards?.label,
              }))}
            />
          </Form.Item>
          <Form.Item
            className="swith_form"
            label="Agent Display"
            name="field_agent_display"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className="swith_form"
            label="Web Enabled"
            name="field_web_enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Collapse
            className="addnotes_collapse"
            activeKey={showAdditonalDetails ? ["additional details"] : [""]}
            onChange={() => {
              setShowAdditionalDetails(!showAdditonalDetails);
            }}
          >
            <Panel
              header={
                <>
                  <span className="additional_panel">Additional Details</span>
                </>
              }
              key="additional details"
            >
              <Form.Item label="Minimum Age" name="field_minimum_age">
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Maximum Age" name="field_maximum_age">
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div div className="text_break">
                    <div>No Other Discount</div>
                    <div>Allowed</div>
                  </div>
                }
                name="field_discount_allowed"
                className="swith_form"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item label="Limit Use Count" name="field_limit_use_count">
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Limit Per Reservation"
                name="field_limit_per_reservation"
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item label="Min Occupancy" name="field_min_occupancy">
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                className="asterick-align"
                label="Max Occupancy"
                name="field_max_occupancy"
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              {/* <Form.Item label="Gender" name="field_gender">
                <Select
                  options={genderList?.map((gender) => ({
                    key: gender?.value,
                    value: gender?.value,
                    label: gender?.name,
                  }))}
                />
              </Form.Item> */}

              <Form.Item label="Military" name="field_military">
                <Select
                  mode="multiple"
                  options={militaryList?.map((military) => ({
                    key: military?.value,
                    value: military?.value,
                    label: military?.name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="Web Only"
                name="field_web_only"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </>
  );
}

export default DiscountCreateOrEditForm;
