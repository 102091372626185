import { Button, message } from "antd";
import React, { useEffect, useRef } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./CreateContactHeader.less";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  pressNext,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";

function CreateContactHeader({
  editId,
  createId,
  form1,
  form2,
  form3,
  form4,
  primaryContactOrgId,
  orgId,
}) {
  const { contactCreation } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = contactCreation;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get("step");

  const onSavehandler = () => {
    if (currentStep === "1" || currentStep === null) {
      dispatch(pressNext(""));
      dispatch(updateStep("1"));
      form1.submit();
    }
    if (currentStep === "2") {
      dispatch(pressNext(""));
      dispatch(updateStep("2"));
      form2.submit();
    }
    if (currentStep === "3") {
      dispatch(pressNext(""));
      dispatch(updateStep("3"));
      form3.submit();
    }
    if (currentStep === "4") {
      dispatch(pressNext(""));
      dispatch(updateStep("4"));
      form4.submit();
    }
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />{" "}
          <span style={{ fontFamily: "'Poppins', sans-serif" }}>
            {editId ? "Edit Contact" : "New Contact"}
          </span>
        </h3>
        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              let route =
                primaryContactOrgId && createId
                  ? `/organization?creationId=${primaryContactOrgId}&orgCotactId=${createId}&step=2`
                  : primaryContactOrgId
                  ? `/organization?creationId=${primaryContactOrgId}&step=2`
                  : !!orgId
                  ? `/organization/details?id=${orgId}#Contacts`
                  : createId || editId
                  ? `/contact/details?id=${createId || editId} `
                  : -1;
              navigate(route);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSavehandler();
            }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateContactHeader;
