import { Form, Grid, Layout } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import CreateOrEditCruiseHeader from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditCruise/CreateOrEditCruiseHeader";
import CreateOrEditCruiseTabsContainer from "../../components/GroupDetails/CreateOrEditGroupDetails/CreateOrEditCruise/CreateOrEditCruiseTabsContainer";
import { GroupDetailsListingRoute } from "../../url-routes/urlRoutes";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

const CreateOrEditCruisePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate  = useNavigate()
  const { useBreakpoint } = Grid;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const createId = searchParams.get("creationId");
  const editId = searchParams.get("editId");
  const preExtId = searchParams.get("preExtId");
  const postExtId = searchParams.get("postExtId");

  useEffect(()=>{
    const isAllowedPage = permissionChecker("add/edit group details");
    if(!isAllowedPage){
      navigate("/page-not-access")
    }
  },[])

  const breadCrumbs = [
    { title: "Group Details", path: GroupDetailsListingRoute },
    { title: editId ? "Edit Cruise" : "Add Cruise", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditCruiseHeader
              form1={form1}
              form2={form2}
              form3={form3}
              editId={editId}
              createId={createId}
              preExtId={preExtId}
              postExtId={postExtId}
            />
          </div>
          <CreateOrEditCruiseTabsContainer
            className="details-tabs"
            form1={form1}
            form2={form2}
            form3={form3}
            editId={editId}
            createId={createId}
            preExtId={preExtId}
            postExtId={postExtId}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};
export default CreateOrEditCruisePage;
