import React from "react";
import { Divider, Form, Input, Spin, Switch } from "antd";

function AutoPayAuthForm({ form, onFinishHandler, loadingPaymentData }) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>Auto Payment Authorization</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingPaymentData} size="large">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="auto_pay_auth_form"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.Item className="asterick-align" label="Document Reference ID" name="field_docref_id">
            <Input />
          </Form.Item>

          <Form.Item
            label="Authorization Signed"
            name="field_authsign"
            valuePropName="checked"
            className="asterick-align"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="DocuSign Link"
            name="field_docsignlink"
            className="asterick-align"
            rules={[
              {
                type: "url",
                message: "Invalid link",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default AutoPayAuthForm;
