import { Grid, Layout, Modal, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EventDetailsHeader from "../../components/Events/EventDetails/EventDetailsHeader";
import EventsSearchbar from "../../components/Events/EventsSearchbar";
import EventDetailsTabsContainer from "../../components/Events/EventDetails/EventDetailsTabsContainer";
import "../contact-details/ContactDetailsPage.less";
import {
  EventDetailsRoute,
  EventsListingRoute,
} from "../../url-routes/urlRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEvent } from "../../redux/features/eventFeatures/eventsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateRecentlyViewedApi } from "../../apis/usersApi";
import { updateRecentlyViewed } from "../../utils/recentlyViewedUpdater";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { permissionChecker } from "../../utils/permisssionChecker";

const { Header } = Layout;

const EventDetailsPage = () => {
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const {
    singleEvent,
    isEventStatusChanged,
    eventCreation,
    isAssetUpdated,
    isAssetDeleted,
    isImportEventTrigger,
  } = useSelector((state) => state.events);
  const { singleEventResponse, loadingSingleEvent } = singleEvent;
  const { eventCreationResponse, loadingEventCreation } = eventCreation;
  const [eventData, setEventData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const cloneId = searchParams.get("cloneId");
  const eventDetailsRef = useRef();
  const navigate = useNavigate()
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `` },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view events");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  useEffect(() => {
    if (eventDetailsRef.current) {
      if (singleEventResponse.status) {
        setEventData(singleEventResponse?.data?.data);
        const { type, langcode, drupal_internal__id, id } =
          singleEventResponse?.data?.data;
        updateRecentlyViewed(type, id, langcode, drupal_internal__id, "event");
        // if (cloneId && eventId === cloneId) {
        //   console.log({ singleEvent });
        //   Modal.confirm({
        //     title: "Would you like to sync this event?",
        //     okText: "Yes",
        //     okType: "danger",
        //     onOk: () => {
        //       // return deleteContactFunc(deleteId);
        //     },
        //   });
        // }
      } else {
        message.error(
          singleEventResponse?.data?.message || SOMETHING_WENT_WRONG
        );
        setEventData({});
      }
    }
    eventDetailsRef.current = true;
  }, [singleEventResponse]);

  useEffect(() => {
    const reqHeaders = { Authorization: `Bearer ${Token}` };
    dispatch(getSingleEvent(eventId));
  }, [
    isEventStatusChanged,
    eventCreationResponse,
    isAssetUpdated,
    isAssetDeleted,
    eventId,
    isImportEventTrigger,
  ]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} eventId={eventId} />
          <div className="events-header">
            <EventDetailsHeader
              eventId={eventId}
              eventData={eventData}
              loadingSingleEvent={loadingSingleEvent}
            />
          </div>
          <EventDetailsTabsContainer
            eventId={eventId}
            eventData={eventData}
            loading={loadingSingleEvent}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};
export default EventDetailsPage;
