import { Form, Grid, Layout, message } from "antd";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  EventCategoryDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSingleCategory } from "../../../../../redux/features/eventFeatures/eventsSlice";
import HeaderCategoryPricingEdit from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Pricing/AddOrEditPricing/HeaderCategoryPricingEdit";
import AddOrEditPricing from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Pricing/AddOrEditPricing/AddOrEditPricing";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header } = Layout;

const EventCategoryPricing = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [form] = Form.useForm();
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    {
      title: "Detail",
      path: `${EventDetailsRoute}?id=${eventId}#Accommodations`,
    },
    {
      title: "Category Detail",
      path: `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`,
    },
    { title: "Edit Pricing", path: "" },
  ];

  const getSingleCategoryRef = useRef(false);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const { singleCategoryResponse, loadingSingleCategory } = useSelector(
    (state) => state.events.singleCategory
  );

  useEffect(() => {
    if (getSingleCategoryRef?.current) {
      if (singleCategoryResponse?.status) {
        setCategoryDetails(singleCategoryResponse?.data?.data || {});
      } else {
        message.error(
          singleCategoryResponse?.error?.response?.data?.errors?.[0]?.title ||
            singleCategoryResponse?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    getSingleCategoryRef.current = true;
  }, [singleCategoryResponse]);

  useEffect(() => {
    dispatch(getSingleCategory(categoryId));
  }, []);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <HeaderCategoryPricingEdit
              categoryDetails={categoryDetails}
              loadingSingleCategory={loadingSingleCategory}
              form={form}
            />
          </div>
          <AddOrEditPricing
            categoryDetails={categoryDetails}
            loadingSingleCategory={loadingSingleCategory}
            form={form}
            categoryId={categoryId}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EventCategoryPricing;
