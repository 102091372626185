import React, { useState, useEffect, useRef } from "react";
import { Col, InputNumber, message, Row } from "antd";
import "./OrganizationDetailsForm.less";
import { Form, Input, Select, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import {
  createOrganization,
  organizationStepSetter,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { Country, State } from "country-state-city";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { customCountriesList } from "../../../others/util/customCountriesList";

const AddressInformation = ({
  organisationFormFields,
  form3,
  editId,
  createId,
  next,
  currentStep,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countriesList = customCountriesList();
  const createOrganizationRef = useRef(false);
  const [states, setStates] = useState([]);
  const { organizationCreation } = useSelector((state) => state.organizations);
  const {
    organizationCreationResponse,
    loadingOrganizationCreation,
    step3,
    pressedNextButton,
    organizationStep,
  } = organizationCreation;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  useEffect(() => {
    if ((editId || createId) && Object.keys(organisationFormFields).length) {
      dispatch(organizationStepSetter(organisationFormFields.field_step));
      let countryCode = countriesList.find(
        (country) => country.name === organisationFormFields.country
      );
      setStates(
        countryCode?.isoCode
          ? State.getStatesOfCountry(countryCode.isoCode)
          : null
      );
      form3.setFieldsValue({
        field_address_line_1:
          organisationFormFields?.field_address_information
            ?.field_address_line_1 || null,
        field_address_line_2:
          organisationFormFields?.field_address_information
            ?.field_address_line_2 || null,
        field_city:
          organisationFormFields?.field_address_information?.field_city || null,
        field_state:
          organisationFormFields?.field_address_information?.field_state ||
          null,
        field_country:
          organisationFormFields?.field_address_information?.field_country ||
          null,
        field_postal_code:
          organisationFormFields?.field_address_information
            ?.field_postal_code || null,
      });
    }
  }, [organisationFormFields]);

  useEffect(() => {
    if (createOrganizationRef.current) {
      if (organizationCreationResponse.status) {
        if (createId) {
          message.success(
            organizationCreationResponse?.data?.message ||
              "Form Submitted Successfully!"
          );
          step3
            ? navigate(`/organization/details?id=${createId}`)
            : next({ type: "creation", id: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            step3
              ? navigate(`/organization/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateOrganizationStep());
      } else {
        message.error(
          organizationCreationResponse?.data?.response?.data?.error?.message ||
            organizationCreationResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    createOrganizationRef.current = true;
  }, [organizationCreationResponse]);

  const onFinishHandler = (values) => {
    const reqMethod = "patch";
    const orgId = editId || createId;
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const payload = {
      data: {
        type: "node--organization",
        id: orgId,
        attributes: {
          field_address_information: {
            field_address_line_1: values.field_address_line_1 || null,
            field_address_line_2: values.field_address_line_2 || null,
            field_city: values.field_city || null,
            field_state: values.field_state || null,
            field_country: values.field_country || null,
            field_postal_code: values.field_postal_code || null,
          },
          field_step: currentStep
            ? currentStep > organizationStep
              ? +currentStep
              : organizationStep
            : 1,
        },
        relationships: {
          field_modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };

    dispatch(createOrganization({ reqMethod, orgId, reqHeaders, payload }));
  };
  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 6,
              }}
              wrapperCol={{
                sm: 24,
                md: 18,
                lg: 18,
              }}
              layout="horizontal"
              form={form3}
              onFinish={onFinishHandler}
            >
              <Form.Item label="Address 1" name="field_address_line_1">
                <Input placeholder="Address 1" />
              </Form.Item>
              <Form.Item label="Address 2" name="field_address_line_2">
                <Input placeholder="Address 2" />
              </Form.Item>
              <Form.Item label="Country" name="field_country">
                <Select
                  showSearch
                  allowClear
                  placeholder="Country"
                  onSelect={(val, selectObj) => {
                    setStates(State.getStatesOfCountry(selectObj.key));
                    form3.setFieldsValue({ state: null });
                  }}
                  onClear={() => {
                    form3.setFieldsValue({ state: null });
                  }}
                >
                  {countriesList.map((country) => (
                    <Select.Option value={country.name} key={country.isoCode}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="State" name="field_state">
                <Select placeholder="State" showSearch allowClear>
                  {states?.length
                    ? states.map((state, index) => (
                        <Select.Option value={state.name} key={state.isoCode}>
                          {state.name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
              <Form.Item label="City" name="field_city">
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item label="Postal Code" name="field_postal_code">
                <Input
                  placeholder="Postal Code"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddressInformation;
