import { Button, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createRoleApi, updateRoleApi } from "../../../apis/manageRoles";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const CreateOrEditRoleHeader = ({
  roleName,
  permissions,
  editRolePage,
  roleData,
  form,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const roles = JSON.parse(localStorage.getItem("authorization"))?.roles;
  const createOrEditRoleFunc = async (allPayload) => {
    setLoading(true);
    let response;
    if (editRolePage) {
      response = await updateRoleApi(allPayload);
    } else {
      response = await createRoleApi(allPayload);
    }

    if (response.status) {
      setLoading(false);
      if(roles.includes(allPayload?.id)){
      const authorization  = JSON.parse(localStorage.getItem("authorization"))
      localStorage.setItem("authorization",JSON.stringify({...authorization,permissions:allPayload.payload.add_permission}))
    }
    navigate("/manage-roles");
    message.success(response?.data?.message);
    } else {
      setLoading(false);
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
    }
  };
  const onSaveHandler = () => {
    form.submit();
    if (!roleName?.length) {
      return;
    }

    let allPayload = {
      id:
        roleData?.drupal_internal__id ||
        roleName?.replaceAll(" ", "_").toLowerCase(),

      payload: {
        role_id:
          roleData?.drupal_internal__id ||
          roleName?.replaceAll(" ", "_").toLowerCase(),
        add_permission: permissions,
      },
      token: Token,
    };
    if (editRolePage) {
      allPayload.payload.label = roleName;
    } else {
      allPayload.label = roleName;
    }
    createOrEditRoleFunc(allPayload);
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p> {editRolePage ? "Edit Role" : "Create Role"}</p>
          </div>
        </h3>
        <div className="breadcrum_buttons">
          <Button type="primary" onClick={onSaveHandler} loading={loading}>
            Save
          </Button>
          <Button
            onClick={() => {
              navigate("/manage-roles");
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CreateOrEditRoleHeader;
