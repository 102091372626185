import axios from "axios";
import serverlessConfig from "../config/serverless/serverless.config";
// const BASE_URL = "https://tykwr4ntgd.execute-api.us-west-2.amazonaws.com"; // STAGE
// const BASE_URL = "https://t6xulybi2k.execute-api.ap-south-1.amazonaws.com"; // DEV

export const axiosRequest = async (reqMethod, reqUrl, data) => {
  let result;
  try {
    if (reqMethod === "post") {
      result = await axios({
        headers: { Authorization: "oslabc123" },
        method: reqMethod,
        url: `${serverlessConfig.BASE_URL}${reqUrl}`,
        data,
      });
    } else {
      if (reqMethod === "delete") {
        let deleteConfig;
        if (data) {
          deleteConfig = {
            headers: { Authorization: "oslabc123" },
            method: reqMethod,
            url: `${serverlessConfig.BASE_URL}${reqUrl}`,
            data,
          };
        } else {
          deleteConfig = {
            headers: { Authorization: "oslabc123" },
            method: reqMethod,
            url: `${serverlessConfig.BASE_URL}${reqUrl}`,
          };
        }
        result = await axios(deleteConfig);
      } else {
        result = await axios({
          headers: { Authorization: "oslabc123" },
          method: reqMethod,
          url: `${serverlessConfig.BASE_URL}${reqUrl}`,
        });
      }
    }
    console.log("result=================", result);
    return { data: result.data, status: true };
  } catch (error) {
    console.log("errrorrrrrrrrrr", error);
    return { data: error, status: false };
  }
};
