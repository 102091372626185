import React from "react";
import { Divider, Table } from "antd";
// import './Org_system.less';
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { DATE_FORMAT } from "../../../../utils/constants";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const SystemInfo = ({ organizationDetailsData, loading }) => {
  const { uid, created, field_modified_by, changed } = organizationDetailsData;
  const data = [
    {
      key: "1",
      questions: "Created By",
      answers: (
        <>
          <p className="paragraph_marg">
            {uid?.field_first_name || uid?.field_last_name
              ? uid?.field_first_name + " " + uid?.field_last_name
              : uid?.display_name || " "}
          </p>
          <p className="paragraph_marg">
            {created ? moment(created).format(DATE_FORMAT) : ""}{" "}
            {created ? moment(created).format("hh:mm A") : ""}{" "}
            <span className="span_text">{moment(created).from(moment())}</span>
          </p>
        </>
      ),
    },
    {
      key: "2",
      questions: "Last Modified By",
      answers: (
        <>
          <p className="paragraph_marg">
            {field_modified_by?.field_first_name ||
            field_modified_by?.field_last_name
              ? field_modified_by?.field_first_name +
                " " +
                field_modified_by?.field_last_name
              : field_modified_by?.display_name || " "}
          </p>
          <p className="paragraph_marg">
            {changed ? moment(changed).format(DATE_FORMAT) : ""}{" "}
            {changed ? moment(changed).format("hh:mm A") : ""}{" "}
            <span className="span_text">
              {changed ? moment(changed).from(moment()) : "No status"}
            </span>
          </p>
        </>
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          System Information
        </h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default SystemInfo;
