import { Table, Tag, Select, Space, Input, Checkbox, message } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./Report_elitedetailstable.less";
import { Link, useSearchParams } from "react-router-dom";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
const Option = Select.Option;

function Report_elitedetailstable({
  urlParamsObject,
  setUrlParamsObject,
  setSearchText,
  listTotalCount,
  eliteTraveles,
  setUpgradedTravelers,
  getCompletedEvents,
  upgradedTravelers,
}) {
  const [loading, setLoading] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(urlParamsObject.count);
  
  const onChange = (value) => {
    setUpgradedTravelers(value.target.checked);
    setUrlParamsObject({
      ...urlParamsObject,
      page: 1,
      count: 10,
    });
    
    // getCompletedEvents({ upgradedTravelers: value.target.checked });
  };

  const columns = [
    {
      title: "Contact ID",
      dataIndex: "hash",
      render: (text, obj) => (
        <>
          <Link to={`/contact/details?id=${obj.contactId}`}>{text}</Link>
        </>
      ),
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Traveler Name",
      dataIndex: "name",

      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservationid",
      sorter: {
        compare: (a, b) => a.reservationid - b.reservationid,
        multiple: 3,
      },
    },

    {
      title: "Total Days Traveled",
      dataIndex: "totaldays",
      sorter: {
        compare: (a, b) => a.totaldays - b.totaldays,
        multiple: 2,
      },
    },

    {
      title: "Current Status",
      dataIndex: "Currentstatus",
      sorter: {
        compare: (a, b) => a.Currentstatus - b.Currentstatus,
        multiple: 1,
      },
    },
  ];

  const { Search } = Input;
  const onSearch = (value) => {
    setSearchText(value);
    getCompletedEvents({ search: value, upgradedTravelers });
  };

  return (
    <>
      <div className="report_elitedetails" style={{ marginBottom: "1rem", width: "100%" }}>
        <Search placeholder="Search" onSearch={onSearch} />
        <Checkbox value={upgradedTravelers} onChange={onChange}>
          Only Show Upgraded Travelers
        </Checkbox>
      </div>
      <Table
        className="table_listing table_tour"
        columns={columns}
        dataSource={eliteTraveles}
        pagination={{
          current: Number(urlParamsObject.page),
          pageSize: urlParamsObject.count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: true,
          total: listTotalCount,
          position: ["bottomRight"],
          onChange: (pageNo, pageSize) => {
            if (pageSize !== prevPageSize) {
              console.log(prevPageSize,pageSize,"pagesize")
              setUrlParamsObject({
                ...urlParamsObject,
                page: 1,
                count: pageSize,
              });
              setPrevPageSize(pageSize);
            } else {
              setUrlParamsObject({
                ...urlParamsObject,
                page: pageNo,
                count: pageSize,
              });
            }
          },
        }}
      ></Table>
    </>
  );
}
export default Report_elitedetailstable;
