import React, { useState } from "react";
import {
  CloseOutlined,
  LeftOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Col, Drawer, Grid, Input, Row } from "antd";
import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
import Sidebar from "../../Header/Header_sidebar";
import { searchUserApi } from "../../../apis/usersApi";
import {
  getAllUsers,
  searchUser,
} from "../../../redux/features/usersFeatures/usersSlice";
import { useDispatch, useSelector } from "react-redux";
const { useBreakpoint } = Grid;

const MarketingSouresSearchbar = () => {
  const [options, setOptions] = useState([]);
  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  async function searchTerm(value) {
    const response = await searchUserApi(value, 10, 0);
    dispatch(searchUser(response.data));
  }

  const handleSearch = (value) => {
    if (value.length > 0) {
      searchTerm(value);
    } else {
      dispatch(getAllUsers());
    }
  };

  const onSelect = (value) => {
    // console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group group_details_cross">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search Sources"
                enterButton
              />
            </AutoComplete>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
};
export default MarketingSouresSearchbar;
