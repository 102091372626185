import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Skeleton, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AddonFeeDetailsRoute,
  AddonFeeRoute,
  EventFeeDetailsRoute,
  EventFeesRoute,
} from "../../../../url-routes/urlRoutes";
import {
  deleteEventFeeApi,
  eventFeeListingApi,
} from "../../../../apis/eventsApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import {
  addCommasInPricing,
  decodeContent,
} from "../../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const AddonFeesListing = ({ addonId, eventId, feeType }) => {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddFee = authData?.permissions?.includes("add/edit event fee");
  const allowDeleteFee = authData?.permissions?.includes("delete event fee");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [isfeeDeleted, setIsFeeDeleted] = useState(false);
  const columns = [
    { title: "Name", dataIndex: "name" },
    { title: "Type", dataIndex: "type" },
    { title: "Usage Type", dataIndex: "usage_type" },
    { title: "Fee Amount", dataIndex: "fee_amount" },
    { title: "	Fee Percent", dataIndex: "fee_percent" },
    {
      title: "Optional?",
      dataIndex: "optional",
      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Switch checked={record} disabled />
            </div>
          </>
        );
      },
    },
    {
      key: "4",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex action_end">
              {permissionChecker("add/edit event fee") ? (
                <EditOutlined
                  className="action_edit"
                  onClick={() =>
                    navigate(
                      feeType === "event"
                        ? `${EventFeesRoute}?id=${eventId}&editId=${record?.key}`
                        : `${AddonFeeRoute}?id=${eventId}&addonId=${addonId}&editId=${record.key}`
                    )
                  }
                />
              ) : null}

              {permissionChecker("delete fees entities") ? (
                <DeleteOutlined
                  className="action_delete"
                  onClick={(e) => {
                    e.preventDefault();
                    ondeleteHandler(record?.key);
                  }}
                />
              ) : null}
            </div>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getAddonFeeList(feeType === "event" ? eventId : addonId);
  }, [isfeeDeleted, addonId, eventId]);

  const deleteEventFee = async (deleteId) => {
    const response = await deleteEventFeeApi(deleteId);
    if (response.status) {
      message.success("Fee Deleted Successfully");
      setIsFeeDeleted(!isfeeDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };
  const ondeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this fee?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteEventFee(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const getAddonFeeList = async (aId) => {
    setLoadingData(true);
    const response = await eventFeeListingApi(aId);
    if (response.status) {
      setData(
        !!response?.data?.length
          ? response?.data.map((feelist) => ({
              key: feelist?.uuid,
              name: (
               <>
               {permissionChecker("view published fees entities") ?  <Link
                  to={
                    feeType === "event"
                      ? `${EventFeeDetailsRoute}?id=${eventId}&feeId=${feelist?.uuid}`
                      : `${AddonFeeDetailsRoute}?id=${eventId}&addonId=${addonId}&feeId=${feelist?.uuid}`
                  }
                >
                  {decodeContent(feelist?.name || "")}
                </Link> : <span> {decodeContent(feelist?.name || "")}</span>}
               </>
              ),
              type: decodeContent(feelist?.field_fee_type) || "",
              usage_type: feelist?.field_usage_type || "",
              fee_amount: feelist?.field_fee_amount
                ? `$${addCommasInPricing(
                    feelist?.field_fee_amount?.toString()
                  )}`
                : "",
              fee_percent: feelist?.field_fee_percent
                ? `${
                    feelist?.field_fee_percent.includes(".00")
                      ? Math.round(Number(feelist?.field_fee_percent))
                      : feelist?.field_fee_percent
                  }%`
                : "",
              optional:
                feelist?.field_is_optional !== null
                  ? feelist?.field_is_optional
                  : "",
            }))
          : []
      );
      setLoadingData(false);
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
      setData([]);
      setLoadingData(false);
    }
  };
  return (
    <div className="requests">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          Fees{" "}
          {loadingData ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${data?.length || 0})`
          )}
        </h4>
       {permissionChecker("add/edit event fee") ?  <Link
          to={
            feeType === "event"
              ? `${EventFeesRoute}?id=${eventId}`
              : `${AddonFeeRoute}?id=${eventId}&addonId=${addonId}`
          }
        >
          <Button type="primary">
            <PlusOutlined /> Add
          </Button>
        </Link> : null}
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        loading={{ spinning: loadingData, indicator: <DataLoader /> }}
        pagination={false}
        size="medium"
      />
    </div>
  );
};
export default AddonFeesListing;
