import { PlusOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import React, { useState } from "react";
import "./Reservations_header.less";
import ReservationCreationModal from "../../Reservation/CreateOrEditReservation/ReservationCreationModal";
import { useSelector } from "react-redux";

function Reservationlisting_header(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const { loadingReservationList } = useSelector(
    (state) => state?.reservation?.reservationList
  );
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>Reservations</p>
            {loadingReservationList ? (
              <Skeleton.Button
                shape="round"
                size="small"
                style={{ width: "60px" }}
              />
            ) : (
              `(${props?.totalReservation || 0})`
            )}
          </div>
        </h3>
        <div className="breadcrum_buttons">
          <Button type="primary" onClick={showModal}>
            <PlusOutlined /> Create
          </Button>

          {isModalOpen ? (
            <ReservationCreationModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setTotalCount={setTotalCount}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Reservationlisting_header;
