import {
  getAllUsersApi,
  getSingleUserApi,
  createUserApi,
  updateUserApi,
  deleteUserApi,
  searchUserApi,
  getUserRolesApi,
} from "../../../apis/usersApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  const allUsers = await getAllUsersApi();
  if (allUsers.status) {
    return allUsers?.data;
  } else {
    return SOMETHING_WENT_WRONG;
  }
});

export const getSingleUser = createAsyncThunk(
  "users/getSingleUser",
  async (userId) => {
    const getSingleUser = await getSingleUserApi(userId);
    if (getSingleUser.status) {
      return getSingleUser.data.data;
    } else {
      return SOMETHING_WENT_WRONG;
    }
  }
);

export const updateUser = createAsyncThunk("users/updateUser", async (data) => {
  const userUpdated = await updateUserApi(data.data, data.userId);
  if (userUpdated.status) {
    return userUpdated.data;
  } else {
    return SOMETHING_WENT_WRONG;
  }
});

export const getUserRoles = createAsyncThunk("users/getUserRoles", async () => {
  const userRoles = await getUserRolesApi();
  if (userRoles.status) {
    return userRoles.data.data;
  } else {
    return SOMETHING_WENT_WRONG;
  }
});

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: {
      userList: [],
      loading: false,
    },
    singleUser: {},
    userCreated: {},
    userUpdated: {},
    userDeleted: {},
    userSearched: [],
    userRoles: [],
    loading: false,
    searchInput: "",
    error: [],
  },
  reducers: {
    clearError: (state) => {
      state.error = [];
    },
    clearUserCreated: (state) => {
      state.userCreated = "";
    },
    clearUserDeleted: (state) => {
      state.userDeleted = "";
    },
    userCreatedOption: (state, action) => {
      state.userCreated = action.payload;
    },
    userUpdatedOption: (state, action) => {
      state.userUpdated = action.payload;
    },
    userDeletedOption: (state, action) => {
      state.userDeleted = action.payload;
    },
    searchUser: (state, action) => {
      state.users.userList = action.payload;
    },
    searchInput: (state, action) => {
      state.searchInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.users.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users.loading = false;
      state.users.userList = action.payload;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.users.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleUser.fulfilled, (state, action) => {
      state.loading = false;
      state.singleUser = action.payload;
    });
    builder.addCase(getSingleUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.userUpdated = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getUserRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.userRoles = action.payload;
    });
    builder.addCase(getUserRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  clearError,
  clearUserCreated,
  clearUserDeleted,
  userCreatedOption,
  userDeletedOption,
  userUpdatedOption,
  searchUser,
  searchInput,
} = usersSlice.actions;
export default usersSlice.reducer;
