import React, { useState } from "react";
import "./Res_editguestformsection.less";
import Res_editguest_form from "../../Forms/Res_editguest_form/Res_editguest_form";
import Res_editguest_formtwo from "../../Forms/Res_editguest_form/Res_editguest_formtwo";
import Res_editguest_formbtn from "../../Forms/Res_editguest_form/Res_editguest_formbtn";
// import Res_table from "../Forms/Res_editguest_form/Res_table";
import Res_table from "../../../components/Forms/Res_editguest_form/Res_table";
import Res_tabletwo from "./Restable_two";
import {
  Collapse,
  Select,
  Form,
  Input,
  Button,
  Radio,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
  Table,
} from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Space, TimePicker } from 'antd';
import * as moment from 'moment';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const { TextArea } = Input;

const Res_editguestformsection = (props) => {
  const [showForm, setShowForm] = useState(true);

  return (
    <div className="details-tabs" style={{ margin: 0 }}>
      <div>
        <div className="Res_details bg-white">
          {/* <Res_editguest_form /> */}
          <div className="ant-collapse-header">
            <div className="ict-custom-accordion">
              <div className="ict-custom-accordion--header">
                <Button type="link">
                  {showForm ? <DownOutlined className="down_color" onClick={() => setShowForm(!showForm)} /> : <RightOutlined className="down_color" onClick={() => setShowForm(!showForm)} />}

                </Button>
                <Res_table />
              </div>

              {showForm ? <>
                <div className="ict-custom-accordion--content">
                  <div className="res_form_main">
                    <div className="res_form_text">
                      <h4>Traveler Details</h4>
                    </div>
                    <Form
                      {...layout}
                      labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 5,
                        xxl: 4,
                      }}
                      wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                      }}
                      layout="horizontal"
                    >
                      <Form.Item label="Gender" name="gender">
                        <Select>
                          <Select.Option value="male">Male</Select.Option>
                          <Select.Option value="female">Female</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item name="party_role" label="Role">
                        <Select>
                          <option value="Primary">Primary</option>
                          <option value="Sponsor">Sponsor</option>
                        </Select>
                      </Form.Item>

                    </Form>
                    <div className="res_form_text">
                      <h4>Personal Flight Details (Arrival/Departure)</h4>
                    </div>
                    <Form
                      {...layout}
                      labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 5,
                        xxl: 4,
                      }}
                      wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                      }}
                      layout="horizontal"
                    >
                      <Form.Item label="Flight Arrival" name="flight_arrival">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Arrival Airline" name="arrival_airline">
                        <Select>
                          <option value="arrival_airline_1">Arrival Airline 1</option>
                          <option value="arrival_airline_2">Arrival Airline 2</option>
                          <option value="arrival_airline_3">Arrival Airline 3</option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Arrival Date" name="arrival_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival Last Depart City" name="arrival_last">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Arrival Last Depart Date" name="arrival_last_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival Transfer Date" name="arrival_transfer_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival Record Locator" name="arrival_record">
                        <Input />
                      </Form.Item>


                      <Form.Item label="Arrival Transfer" name="arrival_transfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item className="asterick-align" label="Air Notes" name="air_notes">
                        <TextArea rows={4} />
                      </Form.Item>

                      <Form.Item label="Flight Departure" name="flight_departure">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Departure Airline" name="departure_airline">
                        <Select>
                          <Select.Option value="departure_airline_1">
                            Departure Airline 1
                          </Select.Option>
                          <Select.Option value="departure_airline_2">
                            Departure Airline 2
                          </Select.Option>
                          <Select.Option value="departure_airline_3">
                            Departure Airline 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Departure Date" name="departure_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival City" name="arrival_city">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Departure Transfer Date" name="departure_transfer_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Departure Record Locator" name="departure_record_locator">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Arrival Transfer" name="arrival_tranfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_3">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Departure Transfer" name="departure_transfer">
                        <Select>
                          <Select.Option value="departure_transfer_1">
                            Departure Transfer 1
                          </Select.Option>
                          <Select.Option value="departure_transfer_2">
                            Departure Transfer 2
                          </Select.Option>
                          <Select.Option value="departure_transfer_3">
                            Departure Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                    </Form>

                    <div className="res_form_text">
                      <h4>Personal Flight Details (Pre Ext Arrival / Departure)</h4>
                    </div>
                    <Form
                      {...layout}
                      labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 5,
                        xxl: 4,
                      }}
                      wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                      }}
                      layout="horizontal"
                    >
                      <Form.Item label="Pre Ext Arrival Flight" name="pre_ext_arrival_flight">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival Airline" name="pre_ext_arrival_airline">
                        <Select>
                          <option value="arrival_airline_1">Arrival Airline 1</option>
                          <option value="arrival_airline_2">Arrival Airline 2</option>
                          <option value="arrival_airline_3">Arrival Airline 3</option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival Date/Time" name="pre_ext_arrival_date">
                        <div className='end_time date_time'>
                          <DatePicker format="MM/DD/YYYY" style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival Last Depart City" name="pre_ext_arrival_last">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival Last Depart Date/Time" name="pre_ext_arrival_last_date">
                        <div className='end_time date_time'>
                          <DatePicker style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival Record Locator" name="pre_ext_arrival_record">
                        <Input />
                      </Form.Item>


                      <Form.Item label="Pre Ext Arrival Transfer" name="ext_arrival_transfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Pre Ext Departure Flight" name="pre_ext_air_notes">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Pre Ext Departure Airline" name="pre_ext_departure_airline">
                        <Select>
                          <Select.Option value="departure_airline_1">
                            Departure Airline 1
                          </Select.Option>
                          <Select.Option value="departure_airline_2">
                            Departure Airline 2
                          </Select.Option>
                          <Select.Option value="departure_airline_3">
                            Departure Airline 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Pre Ext Departure Date/Time" name="pre_ext_departure_date">
                        <div className='end_time date_time'>
                          <DatePicker format="MM/DD/YYYY" style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Pre Ext Arrival City" name="pre_ext_arrival_city">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Pre Ext Departure Record Locator" name="pre_ext_departure_transfer_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival Transfer" name="arrival_tranfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_3">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Pre Ext Departure Transfer" name="pre_ext_departure_transfer">
                        <Select>
                          <Select.Option value="departure_transfer_1">
                            Departure Transfer 1
                          </Select.Option>
                          <Select.Option value="departure_transfer_2">
                            Departure Transfer 2
                          </Select.Option>
                          <Select.Option value="departure_transfer_3">
                            Departure Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                    </Form>


                    <div className="res_form_text">
                      <h4>Personal Flight Details (Post Ext Arrival / Departure)</h4>
                    </div>
                    <Form
                      {...layout}
                      labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 5,
                        xxl: 4,
                      }}
                      wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                      }}
                      layout="horizontal"
                    >
                      <Form.Item label="Post Ext Arrival Flight" name="post_ext_arrival_flight">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival Airline" name="post_ext_arrival_airline">
                        <Select>
                          <option value="arrival_airline_1">Arrival Airline 1</option>
                          <option value="arrival_airline_2">Arrival Airline 2</option>
                          <option value="arrival_airline_3">Arrival Airline 3</option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival Date/Time" name="post_ext_arrival_date">
                        <div className='end_time date_time'>
                          <DatePicker format="MM/DD/YYYY" style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival Last Depart City" name="post_ext_arrival_last">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival Last Depart Date/Time" name="post_ext_arrival_last_date">
                        <div className='end_time date_time'>
                          <DatePicker format="MM/DD/YYYY" style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival Record Locator" name="post_ext_arrival_record">
                        <Input />
                      </Form.Item>


                      <Form.Item label="Post Ext Arrival Transfer" name="post_ext_arrival_transfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Post Ext Departure Flight" name="post_ext_air_notes">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Post Ext Departure Airline" name="post_ext_departure_airline">
                        <Select>
                          <Select.Option value="departure_airline_1">
                            Departure Airline 1
                          </Select.Option>
                          <Select.Option value="departure_airline_2">
                            Departure Airline 2
                          </Select.Option>
                          <Select.Option value="departure_airline_3">
                            Departure Airline 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Post Ext Departure Date/Time" name="post_ext_departure_date">
                        <div className='end_time date_time'>
                          <DatePicker format="MM/DD/YYYY" style={{ width: '48%', height: '40px' }} />
                          <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: '50%', marginLeft: '20px', height: '40px' }} />
                        </div>
                      </Form.Item>

                      <Form.Item label="Post Ext Arrival City" name="post_ext_arrival_city">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Post Ext Departure Record Locator" name="post_ext_departure_transfer_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Arrival Transfer" name="arrival_tranfer">
                        <Select>
                          <Select.Option value="arrival_transfer_1">
                            Arrival Transfer 1
                          </Select.Option>
                          <Select.Option value="arrival_transfer_2">
                            Arrival Transfer 2
                          </Select.Option>
                          <Select.Option value="arrival_transfer_3">
                            Arrival Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>


                      <Form.Item label="Post Ext Departure Transfer" name="post_ext_departure_transfer">
                        <Select>
                          <Select.Option value="departure_transfer_1">
                            Departure Transfer 1
                          </Select.Option>
                          <Select.Option value="departure_transfer_2">
                            Departure Transfer 2
                          </Select.Option>
                          <Select.Option value="departure_transfer_3">
                            Departure Transfer 3
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Form>

                    <div className="res_form_text">
                      <h4>Operations</h4>
                    </div>
                    <Form
                      {...layout}
                      labelCol={{
                        sm: 24,
                        md: 6,
                        lg: 6,
                        xl: 5,
                        xxl: 4,
                      }}
                      wrapperCol={{
                        sm: 24,
                        md: 14,
                        lg: 14,
                        xl: 14,
                        xxl: 12,
                      }}
                      layout="horizontal"
                    >
                      <Form.Item label="Doc Request" name="doc_requests">
                        <Switch defaultChecked onChange={onChange} />
                      </Form.Item>

                      <Form.Item label="Doc Request Type" name="Doc Request Type">
                        <Select>
                          <option value="Paper Docs">Paper Docs</option>
                          <option value="Early Docs">Early Docs</option>
                          <option value="E-Docs">E-Docs</option>
                          <option value="Other">Other</option>
                        </Select>
                      </Form.Item>

                      <div className="textarea_row">
                        <Form.Item label="Doc Notes" name="doc_notes">
                          <TextArea rows={4} />
                        </Form.Item>
                      </div>

                      <Form.Item label="Insurance Declined" name="insurance_declined">
                        <Switch defaultChecked onChange={onChange} />
                      </Form.Item>

                      <Form.Item label="Contract Received Date" name="contract_rcvd_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <Form.Item label="Web Terms Accept Method" name="web_terms_accept">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Web Terms Accept Date" name="web_terms_accept_date">
                        <DatePicker
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </> : null}
            </div>
          </div>
        </div>


      </div>
    </div>
  )
};

export default Res_editguestformsection;
