const ENV = process.env.REACT_APP_PUBLIC_MODE || "development";

const CONFIG = {
  development: {
    BASE_URL: "https://dev-ict-booking.oslabs.app",
  },
  stage: {
    BASE_URL: "https://inspirationtravel-booking-backend.mmgystage.com"
  },
  prod: {
    BASE_URL: "https://booking-backend.inspirationtravel.com",
  },
  oldStage: {
    BASE_URL: "https://stage-ict-booking.oslabs.app"
  },
};

export default CONFIG[ENV];
