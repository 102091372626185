import { Button } from "antd";
import "./OtherActivitiesActionsBar.less";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function OtherActivitiesActionsBar({ activity, form }) {
  const navigate = useNavigate();
  const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const contactId = searchParams.get("id");
  const { isLoading } = useSelector(
    (state) => state.contacts.createAndUpdateActivity
  );

  // const { contactActivities } = useSelector((state) => state.contactDetails);

  // const routeChange = () => {
  //   let path = `/contact/details?id=${contactId}#Activities`;
  //   navigate(path);
  // };

  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <div className="addnote_content">
          <h3 className="flex_row_column">
            <div className="header_res">
              <p>
                {activity
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </p>
              <p className="header_smalltext">({location?.state?.name})</p>
            </div>
          </h3>
        </div>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            type="primary"
            onClick={(e) => {
              // e.preventDefault();
              form.submit();
            }}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OtherActivitiesActionsBar;
