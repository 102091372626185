import { Table } from "antd";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
  },

  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Fee Amount",
    dataIndex: "fee_amount",
  },

  {
    title: "	Fee Percent",
    dataIndex: "fee_percent",
  },
];

const CategoryFeeListing = ({ feeListingData, loadingFeeList }) => {
  const data = feeListingData.length
    ? feeListingData.map((feeListing) => ({
        key: feeListing?.uuid,
        type: feeListing?.type || "",
        name: feeListing?.name || "",
        fee_amount: feeListing?.fee_amount
          ? `$${addCommasInPricing(feeListing?.fee_amount)}`
          : "",
        fee_percent: feeListing?.fee_percent
          ? `${feeListing?.fee_percent}%`
          : "",
      }))
    : [];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loadingFeeList, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default CategoryFeeListing;
