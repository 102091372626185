import { Breadcrumb } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const BreadCrumbGenerator = ({ breadCrumbs }) => {
  const navigate = useNavigate();

  const breadCrumbClickHandler = (navigationPath) => {
    if (navigationPath) {
      navigate(navigationPath);
    }
  };
  return (
    <Breadcrumb>
      <Breadcrumb.Item
        key="Home"
        onClick={() => {
          breadCrumbClickHandler("/");
        }}
        style={{ cursor: "pointer" }}
      >
        Home
      </Breadcrumb.Item>
      {breadCrumbs.map((breadCrumb) => (
        <Breadcrumb.Item
          key={breadCrumb?.title}
          onClick={() => {
            breadCrumbClickHandler(breadCrumb.path);
          }}
          style={{ cursor: "pointer" }}
        >
          {breadCrumb.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumbGenerator;
