import {
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  TimePicker,
  message,
} from "antd";

// import { CKEditor } from "ckeditor4-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import {
  createActivitiesApi,
  getAllAssigneeApi,
  getAllEventsApi,
  getOrganizationListApi,
} from "../../../../../apis/contactApi";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import { handleLoadingForActivity } from "../../../../../redux/features/contactFeatures/contactSlice";
import { convertDateTimeToISO } from "../../../../../utils/DateTimeFormater";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
  TIME_FORMAT,
} from "../../../../../utils/constants";
import {
  Cookie,
  customRequestHeader,
} from "../../../../../utils/cutomRequestHeader";
import "./OtherActivitiesCommonForm.less";
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const OtherActivitiesCommonForm = ({ activity, form, contactId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactActivitiesRef = useRef(false);
  const [editor, setEditor] = useState(null);
  const [orgsList, setOrgsList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [taskNames, setTaskNames] = useState([]);
  const [loadingTaskNames, setLoadingTaskNames] = useState(false);
  const [tagNames, setTagNames] = useState([]);
  const [loadingTagNames, setLoadingTagNames] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const [allActivitiesData, setAllActivitiesData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [editorInitData, setEditorInitData] = useState("");
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [getResponse, setGetResponse] = useState({});
  const authorization = JSON.parse(
    window.localStorage.getItem("authorization")
  );
  const { allActivitiesResponse, loadingallActivities } = useSelector(
    (state) => state.contacts.allActivities
  );
  const { uuid, user_id } = authorization;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const activityList = [
    { value: "letter", label: "Letter" },
    { value: "to_do", label: "To Do" },
    { value: "phone_call", label: "Phone Call" },
  ];
  useEffect(() => {
    getTaxonomyList("activity_task_name", setTaskNames, setLoadingTaskNames);
    getTaxonomyList("activity_tags", setTagNames, setLoadingTagNames);
    getOrganizationList();
    getEventList();
    getAssigneeList();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      due_date: moment(),
      due_time: moment(),
      start_date: moment(),
      start_time: moment(),
    });
  }, []);

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  const createActivities = async (data) => {
    const response = await createActivitiesApi(data);
    if (response.status) {
      message.success("Activity Created Successfully");
      dispatch(handleLoadingForActivity(false));
      navigate(
        `/contact/details/activities?activityId=${response?.data?.data?.id}&contactId=${contactId}`
      );
      // form.resetFields();
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      dispatch(handleLoadingForActivity(false));
    }
  };

  const onFinishHandler = (values) => {
    const isDueTimeValid = values["due_date"] && values["due_time"];
    const isStartTimeValid = values["start_date"] && values["start_time"];
    const isEndTimeValid = values["end_date"] && values["end_time"];
    const message = editorRef.current?.editor.getData();
    console.log(message,"message++++++");
    const payload = {
      data: {
        type: "activity--activity",
        attributes: {
          field_task_type: values["task_type"],
          field_task_status: values["status"],
          field_description: values["description"],
          field_due_time:
            isDueTimeValid &&
            convertDateTimeToISO(values["due_date"], values["due_time"]),
          field_start_time:
            isStartTimeValid &&
            convertDateTimeToISO(values["start_date"], values["start_time"]),
          field_end_time:
            isEndTimeValid &&
            convertDateTimeToISO(values["end_date"], values["end_time"]),
          // field_start_time_and_end_time: startAndEndTimeIsValid && [
          //   {
          //     value: `${values["start_date"].format("YYYY-MM-DD")}T${values[
          //       "start_time"
          //     ].format("hh:mm:ssZ")}`,
          //     end_value: `${values["end_date"].format("YYYY-MM-DD")}T${values[
          //       "end_time"
          //     ].format("hh:mm:ssZ")}`,
          //   },
          // ],
          field_percentage_done: values["percentage_done"],
          field_priority: values["priority"] || null,
          field_message: message,
          created_on: "staffweb",
          modified_on: "staffweb",
        },
        relationships: {
          field_contact: {
            data: {
              type: "contact--contact",
              id: contactId,
            },
          },
          field_activity_task_name: {
            data: {
              type: "taxonomy_term--activity_task_name",
              id: values["task_name"],
            },
          },
          field_organizations: values["organizations"] && {
            data: {
              type: "node--organization",
              id: values["organizations"],
            },
          },
          field_event: values["event"] && {
            data: {
              type: "event--event",
              id: values["event"],
            },
          },
          field_activity_tags: {
            data: values["tags"]?.length
              ? values["tags"]?.map((item) => {
                  return { type: "taxonomy_term--activity_tags", id: item };
                })
              : [],
          },
          field_assignee_staff: {
            data: {
              type: "user--user",
              id: values["assignee_staff_id"],
            },
          },
          field_last_modified_by: {
            data: uuid && {
              type: "user--user",
              id: uuid || "", // will getuser when login implemented
            },
          },
        },
      },
    };
    dispatch(handleLoadingForActivity(true));
    createActivities({ payload });
  };

  const getOrganizationList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getOrganizationListApi({ reqHeaders });
    if (response && response.status) {
      setOrgsList(response?.data?.data);
    } else {
      setOrgsList([]);
      form.setFields([
        {
          name: "organization",
          errors: ["Unable to fetch organization data"],
        },
      ]);
    }
  };

  const getEventList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getAllEventsApi({ reqHeaders });
    if (response.status) {
      setEventList(response?.data?.data);
    } else {
      setEventList([]);
    }
  };

  const getAssigneeList = async () => {
    const reqHeaders = customRequestHeader({
      token: Token,
      cookie: Cookie,
      defaultFormat: true,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const response = await getAllAssigneeApi({ reqHeaders });
    if (response.status) {
      setAssigneeList(response?.data?.data);
    } else {
      setAssigneeList([]);
    }
  };
  const validateEndDate = (e, value) => {
    if (e.field === "start_date") {
      const startDate = value;
      const endDate = form.getFieldValue("end_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: "end_date",
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: "end_date",
                errors: ["End Date cannot be less than the Start Date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue("start_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End Date cannot be less than the Start Date");
        }
      }
    }
    return Promise.resolve();
  };

  return (
    <div className="addnote_form">
      <div>
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 3,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          form={form}
          onFinish={onFinishHandler}
          layout="horizontal"
          initialValues={{ task_type: activity }}
        >
          <Form.Item label="Task Type" name="task_type">
            <Select
              disabled
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={activityList.map((activity) => ({
                key: activity.value,
                value: activity.value,
                label: activity.label,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Task Name"
            name="task_name"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Task Name required",
              },
            ]}
          >
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={taskNames?.map((task) => ({
                key: task?.id,
                value: task?.id,
                label: task?.name,
              }))}
              loading={loadingTaskNames}
            ></Select>
          </Form.Item>

          <Form.Item
            label="Assignee Staff"
            name="assignee_staff_id"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Assignee Staff required",
              },
            ]}
          >
            <Select
              options={assigneeList?.map((aName) => ({
                key: aName?.id,
                value: aName?.id,
                label: aName?.display_name,
              }))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            ></Select>
          </Form.Item>

          <Form.Item
            label="Task Status"
            name="status"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Task Status required",
              },
            ]}
          >
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="New" name="New">
                New
              </Select.Option>
              <Select.Option value="Closed" name="Closed">
                Closed
              </Select.Option>
              <Select.Option value="Rework" name="Rework">
                Rework
              </Select.Option>
              <Select.Option value="Returned" name="Returned">
                Returned
              </Select.Option>
              <Select.Option value="Started" name="Started">
                Started
              </Select.Option>
              <Select.Option value="Complete" name="Complete">
                Complete
              </Select.Option>
              <Select.Option value="Error" name="Error">
                Error
              </Select.Option>
            </Select>
          </Form.Item>

          <div className="form_align">
            <Form.Item label="Due Time" name="due_date">
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <div className="form_label">
              <Form.Item name="due_time">
                <TimePicker use12Hours format={TIME_FORMAT} />
              </Form.Item>
            </div>
          </div>

          <div className="form_align">
            <Form.Item
              label="Start Time"
              name="start_date"
              rules={[
                {
                  validator: validateEndDate,
                  dependencies: ["end_date"], // Trigger validation when startDate changes
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <div className="form_label">
              <Form.Item name="start_time">
                <TimePicker use12Hours format={TIME_FORMAT} />
              </Form.Item>
            </div>
          </div>

          <div className="form_align">
            <Form.Item
              label="End Time"
              name="end_date"
              rules={[
                {
                  validator: validateEndDate,
                  dependencies: ["start_date"], // Trigger validation when startDate changes
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <div className="form_label">
              <Form.Item name="end_time">
                <TimePicker use12Hours format={TIME_FORMAT} />
              </Form.Item>
            </div>
          </div>

          <Collapse className="addnotes_collapse">
            <Panel
              header={
                <>
                  <span className="additional_panel">Additional Details</span>
                </>
              }
              key="1"
            >
              <div className="textarea_row">
                <Form.Item label="Description" name="description">
                  <TextArea
                    showCount
                    // allowClear
                    maxLength={500}
                    rows={4}
                  />
                </Form.Item>
              </div>

              <Form.Item label="Organizations" name="organizations">
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={orgsList?.map((org) => ({
                    key: org?.drupal_internal__nid,
                    value: org?.id,
                    label: org?.title,
                    nid: org?.drupal_internal__nid,
                  }))}
                  allowClear
                ></Select>
              </Form.Item>

              <Form.Item label="Event" name="event">
                <Select
                  options={eventList?.map((event) => ({
                    key: event?.drupal_internal__id,
                    value: event?.id,
                    label: event?.name,
                  }))}
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                ></Select>
              </Form.Item>

              <Form.Item label="Percent Done" name="percentage_done">
                <Input
                  type="number"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      // evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown"
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item label="Priority" name="priority">
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="A" name="A">
                    A
                  </Select.Option>
                  <Select.Option value="B" name="B">
                    B
                  </Select.Option>
                  <Select.Option value="C" name="C">
                    C
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Tags" name="tags">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Any Tag(s)"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={tagNames?.map((tag) => ({
                    key: tag?.id,
                    value: tag?.id,
                    label: tag?.name,
                  }))}
                  loading={loadingTagNames}
                />
              </Form.Item>
            </Panel>
          </Collapse>

          <div className="textarea_row">
            <Form.Item
              className="ckeitor-field-wrapper"
              label="Message"
              name="message"
              valuePropName="data"
            >
              {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
              <CKEditor
                editor={ClassicEditor}
                ref={editorRef}
                config={editorConfig}
                data={editorInitData}
                onReady={(editor) => {
                  setEditorInstance(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorInitData(data);
                  form.setFieldsValue({ message: data });
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default OtherActivitiesCommonForm;
