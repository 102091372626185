import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Skeleton, Space, Table } from "antd";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import React, { useEffect, useState } from "react";
import { getContactReservationListingApi } from "../../../../apis/contactApi";
import { statusList } from "../../../../others/util/commonFunctions";
import { ReservationDetailRoute } from "../../../../url-routes/urlRoutes";
import { addCommasInPricing, decodeContent } from "../../../../utils/contentParser";
import ReservationCreationModal from "../../../Reservation/CreateOrEditReservation/ReservationCreationModal";
import "./ReservationsDesktop.less";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const authData = JSON.parse(localStorage.getItem("authorization"));
const allowEditreservation = authData?.permissions?.includes(
  "add/edit reservation"
);

/*---------------------------------------------------------------------------
                                NOTE

Add the below flag on view Reservation & view Events while you start working on this page.
---------------------------------------------------------------------------*/

const allowViewReservation =
  authData?.permissions?.includes("view reservations");
const allowViewEvents = authData?.permissions?.includes("view events");

const columns = [
  {
    title: "Reservation",
    dataIndex: "res",
    render: (value, recordObj) => (
     <>
      {permissionChecker("view reservations") ? (<Link to={`${ReservationDetailRoute}?id=${recordObj.uuid}`}>
        <span>{value}</span>
      </Link>):( <span>{value}</span>)}
     </>
    ),
  },
  {
    title: "Reservation Name",
    dataIndex: "name",
    render: (value, recordObj) => (
      <>
      {permissionChecker("view reservations") ? (
      <Link to={`${ReservationDetailRoute}?id=${recordObj.uuid}`}>
        <span>{decodeContent(value)}</span>
      </Link>):(  <span>{value}</span>)
     }
     </>
    ),
  },

  {
    title: "Event Code",
    dataIndex: "event_code",
  },

  {
    title: "Role",
    dataIndex: "role",
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (record) => {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <span>.</span> */}
            <p>{record}</p>
          </div>
        </>
      );
    },
  },

  {
    title: "Total",
    dataIndex: "total",
  },

  {
    title: "Balance",
    dataIndex: "balance",
  },
];

const ReservationsDesktop = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resStatus, setResStatus] = useState(searchParams.get("status"));
  const [resCount, setResCount] = useState(0);
  const contactId = searchParams.get("id");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [count, setCount] = useState(searchParams.get("count") || 10);

  useEffect(() => {
    getContactReservationsList({
      status: resStatus,
      uuid: contactId,
      page,
      count,
    });
  }, [resStatus, page, count]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const getContactReservationsList = async (data) => {
    setLoading(true);
    const res = await getContactReservationListingApi(data);
    if (res.status) {
      setResCount(res?.data?.pager?.total_items || 0);
      setData(
        res.data?.rows?.map((reservation) => ({
          uuid: reservation?.uuid || null,
          res: reservation?.id || null,
          name: reservation?.name || null,
          event_code: reservation?.field_event_code || null,
          role: reservation?.traveler_role
            ? JSON.parse(reservation?.traveler_role)?.field_role
            : null,
          status: reservation?.reservation_status || null,
          total: reservation?.total
            ? `$${addCommasInPricing(reservation?.total)}`
            : null,
          balance: reservation?.balance
            ? `$${addCommasInPricing(reservation?.balance)}`
            : null,
        }))
      );
      setLoading(false);
    } else {
      setResCount(0);
      setData([]);
      setLoading(false);
    }
  };
  return (
    <div>
      <div
        className="reservarion_heading bg-white"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'" }}>
          Reservations{" "}
          {loading ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${resCount})`
          )}
        </h4>
        <div className="breadcrum_buttons">
          <Select
            value={resStatus}
            placeholder="Status"
            style={{
              width: 135,
              marginRight: "14px",
            }}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={(e) => {
              setResStatus(e);
              setPage(1);
              setCount(10);
              navigate(
                `/contact/details?id=${contactId}&page=${1}&count=${10}&status=${e}#Reservations`
              );
            }}
            options={statusList.map((status) => ({
              key: status.value,
              value: status.value,
              label: status.label,
            }))}
            onClear={() => {
              setResStatus(null);
              setPage(1);
              setCount(10);
              navigate(
                `/contact/details?id=${contactId}&page=${1}&count=${10}&#Reservations`
              );
            }}
            allowClear
          />

          {permissionChecker("add/edit reservation") ? (
            <span className="reservation_dropdown" style={{ width: "114px" }}>
              <Space>
                <Button
                  onClick={showModal}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Add New
                </Button>
              </Space>
            </span>
          ) : null}
        </div>
      </div>

      <Divider className="global_divider" />

      <Table
        className="reservat_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loading, size: "large" }}
        pagination={{
          current: Number(page),
          pageSize: Number(count),
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: true,
          total: Number(resCount),
          position: ["bottomRight"],
          onChange: (pageNo, pageSize) => {
            setPage(pageNo);
            setCount(pageSize);
            navigate(
              `/contact/details?id=${contactId}&page=${pageNo}&count=${pageSize}${
                resStatus ? `&status=${resStatus}` : ""
              }#Reservations`
            );
          },
        }}
      />
      {isModalOpen ? (
        <ReservationCreationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};
export default ReservationsDesktop;
