import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import React, { useState } from "react";
import "./TravelHistoryForm.less";
import { PlusOutlined } from "@ant-design/icons";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const TravelHistoryForm = ({
  form,
  onFinishHandler,
  open,
  setOpen,
  loading,
  onClose,
  editIndex,
}) => {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <>
      {permissionChecker("add/edit travel history") ? (
        <Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
          Add
        </Button>
      ) : null}

      <Drawer
        title={`${editIndex !== null ? "Edit" : "Add"} Travel History Item`}
        width={420}
        onClose={onClose}
        maskClosable={false}
        open={open}
        bodyStyle={{ paddingBottom: 32 }}
        extra={
          <Space>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
              loading={loading}
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="field_event_code"
                label="Event Code"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="field_event_name"
                label="Event Name"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="field_days_traveled"
                label="Number of Days Traveled"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default TravelHistoryForm;
