import {
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  PageHeader,
  Table,
  Tag,
  Button,
  Menu,
  Dropdown,
  Space,
  Form,
  Select,
  Divider,
  message,
} from "antd";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Reservation_select from "./Reservation_select";
import Reservation_selecttwo from "./Reservation_selecttwo";
import TravelerSelectContactModal from "./TravelerSelectContactModal";
import {
  contactSuggestion,
  editTravelerData,
} from "../../../../../apis/reservationApi";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import { useDispatch } from "react-redux";
import { markPayeeTrigger } from "../../../../../redux/features/reservationFeatures/reservationSlice";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { addCommasInPricing } from "../../../../../utils/contentParser";
import { ReservationSendEmailRoute } from "../../../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Payee",
    dataIndex: "payee",
  },

  {
    title: "Name/ Email",
    dataIndex: "name",
    render: (record) => {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <p>{record}</p>
          </div>
        </>
      );
    },
  },

  {
    title: "Relationship",
    dataIndex: "relation",
    render:(record) => {
      return (
        <>
        <div className={`${!record?.isGreyTraveler?.length ? "disable_traveler" : ""}`}>
          {record?.data}
        </div>
        </>
      )
    }
  },
  {
    title: "Contact",
    dataIndex: "contact",
  },
  {
    title: "Total",
    dataIndex: "total",
    render: (value, recordObj) => (
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            margin: "0",
          }}
        >
          {/* Total */}
        </p>
        {recordObj?.traveler_type === "child" ? null : (
          <p
            style={{ color: recordObj?.is_discrepency ? "red" : "black" }}
          >{`$${addCommasInPricing(value?.toString())}`}</p>
        )}
      </div>
    ),
  },

  {
    title: "Paid",
    dataIndex: "paid",
    render: (value, recordObj) => (
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            margin: "0",
          }}
        ></p>
        {recordObj?.traveler_type === "child" ? null : (
          <p>{`$${addCommasInPricing(value?.toString())}`}</p>
        )}
      </div>
    ),
  },

  {
    title: "Balance",
    dataIndex: "balance",
    render: (value, recordObj) => (
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            margin: "0",
          }}
        ></p>
        {recordObj?.traveler_type === "child" ? null : (
          <p>{`$${addCommasInPricing(value?.toString())}`}</p>
        )}
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "action",
    render: (val, recordObj) => {
      return recordObj?.travellerDetails?.contacts?.length ? (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Link
                      to={`${ReservationSendEmailRoute}?id=${recordObj?.travellerDetails?.reservationId}&traveller_id=${recordObj?.travellerDetails?.uuid}&emailType=booking_cofirmation_template`}
                    >
                      Booking Confirmation
                    </Link>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Link
                      to={`${ReservationSendEmailRoute}?id=${recordObj?.travellerDetails?.reservationId}&traveller_id=${recordObj?.travellerDetails?.uuid}&emailType=terms_and_conditions_template`}
                    >
                      Terms & Conditions
                    </Link>
                  ),
                },
              ],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Send Email
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </>
      ) : null;
    },
  },

  // {
  //     title: <>
  //         <div className='action_flex'>
  //             <DeleteOutlined className='action_delete' />
  //         </div>
  //     </>,
  //     dataIndex: '',
  // },
];

const ReservationGuestsTable = (props) => {
  const {
    allTravelersData,
    setShowTable,
    showTable,
    refreshTravelerData,
    loading,
  } = props;
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [primaryTravelerData, setPrimaryTravelerData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [priorityTraveler, setPriorityTraveler] = useState([]);
  const [priorityLoader, setPriorityLoader] = useState(false);
  const [orgTypeList, setOrgTypeList] = useState([]);
  const [orgTypeLoading, setOrgTypeLoading] = useState(false);
  let reservationId = searchParams.get("id");
  const { isPayeeTrigger } = useSelector((state) => state.reservation);
  // useEffect(() => {
  //   getTaxonomyList("organization_type", setOrgTypeList, setOrgTypeLoading);
  // }, []);

  const updatePayee = async (payload) => {
    const response = await editTravelerData(payload);

    if (response && response.status) {
      refreshTravelerData();
      dispatch(markPayeeTrigger(!isPayeeTrigger));
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const fetchContactSuggestion = async (uuid) => {
    setPriorityLoader(true);
    const response = await contactSuggestion(uuid);
    if (response && response.status) {
      setPriorityTraveler(response?.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setPriorityLoader(false);
  };
  useEffect(() => {
    if (primaryTravelerData) {
      fetchContactSuggestion(primaryTravelerData.uuid);
    }
  }, [primaryTravelerData]);

  const markPayee = (val, boolean) => {
    let payload = {
      data: [
        {
          id: val?.id,
          field_is_payee: boolean,
        },
      ],
    };
    updatePayee(payload);
  };
  useEffect(() => {
    if (allTravelersData) {
      let travelers = [];
      allTravelersData.forEach((val, index) => {
        if (val.is_primary) {
          setPrimaryTravelerData(val);
        }
        const isGreyTraveler = val?.reservation_items?.map(val=> ["cancelled_pending","reserved"].includes(val.field_itemstatus) && val.field_is_active === "1").filter(Boolean)
        let travelerData = {
          is_discrepency: val.is_discrepency,
          key: index,
          payee: (
            <>
              {val.traveler_type !== "child" &&
                (val.is_payee ? (
                  <Tag
                    className={`primary_color cursor-pointer button ${!isGreyTraveler.length ? "disable_traveler" : ""}`}
                    color="blue"
                    onClick={() => markPayee(val, false)}
                  >
                    <span>Payee</span>
                  </Tag>
                ) : (
                  <Tag
                    className={`cursor-pointer ${!isGreyTraveler.length ? "disable_traveler" : ""}`}
                    onClick={() => markPayee(val, true)}
                  >
                    Mark payee
                  </Tag>
                ))}
            </>
          ),
          isGreyTraveler,
          name: (
            <>
              <div className={`${!isGreyTraveler?.length ? "disable_traveler" : ""}`}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    position: "relative",
                    margin: "0",
                    opacity: "0.5",
                  }}
                ></p>
                {val.contacts.length ? (
                 <>
                 {permissionChecker("view contact") ?  <Link to={`/contact/details?id=${val?.contacts[0]?.uuid}`}>
                    <p>{val?.contacts[0]?.name}</p>
                  </Link> :  <p>{val?.contacts[0]?.name}</p>}
                 </>
                ) : (
                  <p>{val?.name}</p>
                )}
              </div>
              <p>
                {val.contacts.length ? (
                  <>
                    <MailOutlined /> {val?.contacts[0]?.email}
                  </>
                ) : null}
              </p>
            </>
          ),
          relation: {data:val?.relationship,isGreyTraveler},
          contact: (
            <div className={`${!isGreyTraveler?.length ? "disable_traveler" : ""}`}>
            <TravelerSelectContactModal
              index={index}
              setShowTable={setShowTable}
              showTable={showTable}
              element={val}
              refreshTravelerData={refreshTravelerData}
              priorityTraveler={priorityTraveler}
              priorityLoader={priorityLoader}
              orgTypeList={orgTypeList}
              orgTypeLoading={orgTypeLoading}
            />
            </div>
          ),
          email: (
            <>
              {val?.contacts[0]?.email ? (
                <>
                  <span className="mail_padding">
                    <MailOutlined />{" "}
                  </span>
                  <span> {val?.contacts[0]?.email}</span>{" "}
                </>
              ) : null}
            </>
          ),
          total: val?.traveler_type !== "child" ? val?.total : 0,
          paid: val?.traveler_type !== "child" ? val?.paid : 0,
          balance: val?.traveler_type !== "child" ? val?.balance : 0,
          traveler_type: val?.traveler_type,
          action: (
            <>
              <div className="action_flex">
                <EditOutlined className="action_edit" />

                <DeleteOutlined className="action_delete" />
              </div>
            </>
          ),
          travellerDetails: { ...val, reservationId },
        };
        travelers.push(travelerData);
      });
      setTableData(travelers);
    }
  }, [allTravelersData, priorityTraveler]);
  return (
    <>
      <Table
        className="travellers-wrapper"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        loading={{ spinning: loading, size: "large" }}
      />
    </>
  );
};

export default ReservationGuestsTable;
