import { Table, Button, Divider, Image, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import "./CitizenshipInformation.less";
import React, { useEffect, useState } from "react";
import moment from "moment";
import CitizenshipFormDrawer from "../GeneralDetailsTab/SideDrawerForms/CitizenshipFormDrawer";
import DataLoader from "../../../../others/Loaders/DataLoader";
import drupalConfig from "../../../../config/serverless/drupal.config";
import { COMPLETE_PREVIOUS_STEP } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const CitizenshipInformation = ({
  contactDetailsData,
  contactId,
  isLoading,
}) => {
  const [sourceData, setSourceData] = useState([]);
  const navigate = useNavigate();
  const {
    field_place_of_birth,
    field_proof_of_citizenship,
    field_passport_issue_place,
    field_passport_issue_date,
    field_passport_expire_date,
    field_passport_number,
    field_drivers_license_number,
    field_drivers_license_issue_date,
    field_drivers_license_expire__date,
    field_certificate_issue_date,
    field_certificate_number,
    field_step,
    field_citizenship,
    field_proof_photo,
  } = contactDetailsData || {};
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const passportData = [
    {
      key: "1",
      label: "Citizenship",
      value: field_citizenship || "",
    },
    {
      key: "2",
      label: "Place Of Birth",
      value: field_place_of_birth || "",
    },
    {
      key: "3",
      label: "Proof Of Citizenship",
      value: field_proof_of_citizenship?.label || "",
    },
    {
      key: "4",
      label: "Passport Number",
      value: field_passport_number || "",
    },
    {
      key: "5",
      label: "Passport Issue Place",
      value: field_passport_issue_place || "",
    },
    {
      key: "6",
      label: "Passport Issue Date",
      value: field_passport_issue_date
        ? moment(field_passport_issue_date, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
    },
    {
      key: "7",
      label: "Passport Expiry Date",
      value: field_passport_expire_date ? (
        <>
          <span>
            {moment(field_passport_expire_date, "YYYY-MM-DD").format(
              "MM/DD/YYYY"
            )}
          </span>{" "}
          <span className="span_text">
            {moment().to(moment(field_passport_expire_date, "YYYY-MM-DD"))}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "8",
      label: "Proof Photo",
      value: field_proof_photo?.uri?.url ? (
        <Image
          src={`${drupalConfig.BASE_URL}${field_proof_photo.uri.url}`}
          width={100}
          height={50}
        />
      ) : (
        ""
      ),
    },
  ];
  const licenseData = [
    {
      key: "1",
      label: "Citizenship",
      value: field_citizenship || "",
    },
    {
      key: "2",
      label: "Place Of Birth",
      value: field_place_of_birth || "",
    },
    {
      key: "3",
      label: "Proof Of Citizenship",
      value: field_proof_of_citizenship?.label || "",
    },
    {
      key: "4",
      label: "Drivers License Number",
      value: field_drivers_license_number || "",
    },
    {
      key: "5",
      label: "Drivers License Issue Date",
      value: field_drivers_license_issue_date
        ? moment(field_drivers_license_issue_date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          )
        : "",
    },
    {
      key: "6",
      label: "Drivers License Expire Date",
      value: field_drivers_license_expire__date
        ? moment(field_drivers_license_expire__date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          )
        : "",
    },
    {
      key: "7",
      label: "Proof Photo",
      value: field_proof_photo?.uri?.url ? (
        <Image
          src={`${drupalConfig.BASE_URL}${field_proof_photo.uri.url}`}
          width={100}
          height={50}
        />
      ) : (
        ""
      ),
    },
  ];

  const birthCertificateData = [
    {
      key: "1",
      label: "Citizenship",
      value: field_citizenship || "",
    },
    {
      key: "2",
      label: "Place Of Birth",
      value: field_place_of_birth || "",
    },
    {
      key: "3",
      label: "Proof Of Citizenship",
      value: field_proof_of_citizenship?.label || "",
    },
    {
      key: "4",
      label: "Certificate Number",
      value: field_certificate_number || "",
    },
    {
      key: "5",
      label: "Certificate Issue Date",
      value: field_certificate_issue_date
        ? moment(field_certificate_issue_date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          )
        : "",
    },
    {
      key: "7",
      label: "Proof Photo",
      value: field_proof_photo?.uri?.url ? (
        <Image
          src={`${drupalConfig.BASE_URL}${field_proof_photo?.uri?.url}`}
          width={100}
          height={50}
        />
      ) : (
        ""
      ),
    },
  ];

  useEffect(() => {
    dataToShow(field_proof_of_citizenship?.key);
  }, [field_proof_of_citizenship]);

  const dataToShow = (citizenshipProof) => {
    if (citizenshipProof)
      if (citizenshipProof === "passport") {
        setSourceData(passportData);
      } else {
        if (citizenshipProof === "drivers_license") {
          setSourceData(licenseData);
        } else {
          if (citizenshipProof === "birth_certificate") {
            setSourceData(birthCertificateData);
          } else {
            setSourceData([]);
          }
        }
      }
  };

  const onEditHandler = (currentStep) => {
    if (Number(currentStep + 1) < 3) {
      message.error(COMPLETE_PREVIOUS_STEP);
    } else {
      navigate(`/contact?editId=${contactId}&step=3`);
    }
  };
  return (
    <div>
      <div className="partnership_inform">
        <h4>Citizenship Information</h4>
        {allowEditContact ? (
          <span>
            {permissionChecker("add/edit contact") ? (<Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditHandler(field_step || 0);
              }}
            />):null}
          </span>
        ) : null}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={sourceData}
        showHeader={false}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default CitizenshipInformation;
