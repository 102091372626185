import { Form, Input, Select, message } from "antd";
import { Country } from "country-state-city";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrganizationTypeApi } from "../../../apis/organisationApi";
import {
  createOrganization,
  organizationStepSetter,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import "./OrganizationDetailsForm.less";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { organizationTypeList } from "../../../others/util/commonFunctions";

const OrganizationDetailsForm = ({
  organisationFormFields,
  setOrganisationFormFields,
  form1,
  createId,
  editId,
  next,
  currentStep,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createOrganizationRef = useRef(false);
  const { organizationCreation } = useSelector((state) => state.organizations);
  const {
    organizationCreationResponse,
    pressedNextButton,
    step1,
    organizationStep,
  } = organizationCreation;
  
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if ((editId || createId) && Object.keys(organisationFormFields).length) {
      dispatch(organizationStepSetter(organisationFormFields.field_step));
      form1.setFieldsValue({
        ...organisationFormFields,
        field_organization_type:
          organisationFormFields?.field_organization_type?.key || null,
      });
    }
    if (!editId && !createId && !currentStep) {
      form1.resetFields();
    }
  }, [organisationFormFields]);

  useEffect(() => {
    if (createOrganizationRef.current) {
      if (organizationCreationResponse.status) {
        let identifyForm = editId || createId ? "editForm" : "creationForm";
        if (identifyForm === "creationForm" || createId) {
          message.success(
            organizationCreationResponse?.data?.message ||
              "Form Submitted Successfully!"
          );
          step1
            ? navigate(
                `/organization/details?id=${
                  organizationCreationResponse?.data?.data?.id || createId
                }`
              )
            : next({
                type: "creation",
                id: organizationCreationResponse?.data?.data?.id || createId,
              });
        } else {
          if (identifyForm === "editForm") {
            message.success("Form Submitted Successfully!");
            step1
              ? navigate(`/organization/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateOrganizationStep());
      } else {
        message.error(
          organizationCreationResponse?.data?.response?.data?.error?.message ||
            organizationCreationResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    createOrganizationRef.current = true;
  }, [organizationCreationResponse]);

  const onFinishHandler = (values) => {
    const reqMethod = editId || createId ? "patch" : "post";
    const orgId = editId || createId;
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const payload = {
      data: {
        type: "node--organization",
        attributes: {
          title: values?.field_organization_name || null,
          field_leader_name: values?.field_leader_name || null,
          field_organization_name: values?.field_organization_name || null,
          field_organization_type: values?.field_organization_type || null,
          field_organization_website:
            values?.field_organization_website || null,
          field_step: currentStep
            ? currentStep > organizationStep
              ? +currentStep
              : organizationStep
            : 1,
        },
        relationships: {
          field_modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (editId || createId) {
      payload.data.id = editId || createId;
    }
    dispatch(createOrganization({ reqMethod, orgId, reqHeaders, payload }));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form1}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Organization Name"
            name="field_organization_name"
            className="asterick-align"
            rules={[
              { required: true, message: "Please enter organization name" },
            ]}
          >
            <Input placeholder="Organization Name" />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="Organization Type"
            name="field_organization_type"
            rules={[
              { required: true, message: "Please select organization type" },
            ]}
          >
            <Select
              placeholder="Organization Type"
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={organizationTypeList.map((orgType) => ({
                key: orgType.key,
                value: orgType.key,
                label: orgType.label,
              }))}
            />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Leader Name"
            name="field_leader_name"
            rules={[{ required: true, message: "Please enter leader name" }]}
          >
            <Input placeholder="Leader Name" />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Website"
            name="field_organization_website"
            rules={[
              {
                type: "url",
                message: "Invalid url",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input placeholder="Website" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default OrganizationDetailsForm;
