import { Grid, Layout, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getReservedTravelerListApi } from "../../../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import AvailabilitySoldCountHeader from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Availalbility/AvailabilitySoldCountHeader";
import AvailabilitySoldCountTable from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Availalbility/AvailabilitySoldCountTable";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import {
  AddonCategoryDetailsRoute,
  ContactDetailRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
  ReservationDetailRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { urlParser } from "../../../../../utils/contentParser";
const { Header, Content, Sider } = Layout;

function SoldCountAvailabilityPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const categoryId = searchParams.get("categoryId");
  const [travelerList, setTravelerList] = useState([]);
  const [loadingTravelerList, setLoadingTravelerList] = useState(false);
  const [listCount, setListCount] = useState(0);
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [urlParamsObject, setUrlParamsObject] = useState({
    ...urlParser(window.location.search),
    page: searchParams.get("page") || 1,
    order: searchParams.get("order") || "ASC",
    count: searchParams.get("count") || 10,
  });

  const getBreadCrumbs = (addonId) => {
    const breadCrumbs = [
      { title: "Events", path: EventsListingRoute },
      { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    ];
    if (addonId) {
      breadCrumbs.push(
        {
          title: "Add-Ons",
          path: `${EventDetailsRoute}?id=${eventId}#Add-Ons`,
        },
        {
          title: "Add-Ons Detail",
          path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
        },
        {
          title: "Category Detail",
          path: `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`,
        },
        { title: "Availability", path: "" }
      );
    } else {
      breadCrumbs.push(
        {
          title: "Category Detail",
          path: `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`,
        },
        { title: "Availability", path: "" }
      );
    }
    return breadCrumbs;
  };

  useEffect(() => {
    setSearchParams(urlParamsObject);
    getReservedTravelerList({ ...urlParamsObject, categoryId });
  }, [urlParamsObject]);

  const getReservedTravelerList = async (data) => {
    setLoadingTravelerList(true);
    const res = await getReservedTravelerListApi(data);
    if (res.status) {
      setListCount(Number(res?.data?.pager?.total_items || 0));
      setTravelerList(
        res?.data?.rows?.map((listItem) => ({
          key: listItem?.reservation_id,
          hash: (
            <Link
              target={"_blank"}
              to={`${ReservationDetailRoute}?id=${listItem?.reservation_uuid}`}
            >
              {listItem?.reservation_id}
            </Link>
          ),
          status: listItem?.reservation_status || "",
          primary_guest: listItem.has_contact ? (
            <Link
              to={`${ContactDetailRoute}?id=${listItem?.primary_traveler?.uuid}`}
            >
              {listItem?.primary_traveler?.name}
            </Link>
          ) : null,
          total: listItem?.total ? `$${listItem?.total}` : `$0`,
          balance: listItem?.balance ? `$${listItem?.balance}` : `$0`,
          created: listItem?.created
            ? moment.unix(listItem?.created).fromNow()
            : "",
          modified: listItem?.changed
            ? moment.unix(listItem?.changed).fromNow()
            : "",
        }))
      );
      setLoadingTravelerList(false);
    } else {
      setListCount(0);
      setTravelerList([]);
      message.error(
        res?.data?.response?.data?.message ||
          res?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setLoadingTravelerList(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={getBreadCrumbs(addonId)} />
          <div className="events-header">
            <AvailabilitySoldCountHeader
              eventId={eventId}
              categoryId={categoryId}
            />
          </div>
          <Content className="site-layout-background">
            <AvailabilitySoldCountTable
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              listCount={listCount}
              travelerList={travelerList}
              loadingTravelerList={loadingTravelerList}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default SoldCountAvailabilityPage;
