import { Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import ActivitiesDesktop from "../ContactDetailsTabs/ActivitiesTab/ActivitiesDesktop";
import AddressInformation from "../ContactDetailsTabs/GeneralDetailsTab/AddressInformation";
import AdditionalInformation from "../ContactDetailsTabs/GeneralDetailsTab/AddtionalInformation";
import BasicDetails from "../ContactDetailsTabs/GeneralDetailsTab/BasicDetails";
import CitizenshipInformation from "../ContactDetailsTabs/GeneralDetailsTab/CitizenshipInformation";
import EmergencyContact from "../ContactDetailsTabs/GeneralDetailsTab/EmergencyContact";
import MedicalAndDietaryDetails from "../ContactDetailsTabs/GeneralDetailsTab/MedicalAndDietaryDetails";
import MembershipPrograms from "../ContactDetailsTabs/GeneralDetailsTab/MembershipPrograms";
import Preferences from "../ContactDetailsTabs/GeneralDetailsTab/Preferences";
import Requests from "../ContactDetailsTabs/RequestsTab/Requests";
import ReservationsMain from "../ContactDetailsTabs/ReservationsTab/ReservationsMain";
import SystemSettings from "../ContactDetailsTabs/SettingsTab/SystemSettings";
import TravelHistory from "../ContactDetailsTabs/TravelHistoryTab/TravelHistory";
import "./ContactDetailTabs.less";
import { urlParser } from "../../../utils/contentParser";
import { permissionChecker } from "../../../utils/permisssionChecker";
import Wallet from "../ContactDetailsTabs/GeneralDetailsTab/Wallet";
const tabArray = [
  "Contact Details",
  "Reservations",
  "Activities",
  "Travel History",
  "Requests",
  "Settings",
];

const ContactDetailTabs = ({
  contactId,
  contactDetailsData,
  isLoading,
  eliteStatus,
  loadingEliteStatus,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(
    location.hash ? decodeURI(location.hash).slice(1) : "Contact Details"
  );
  const [noteTrigger, setNoteTrigger] = useState(false);

  useEffect(()=>{
    const isAllowed = permissionChecker("view contact");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    let tab = location.hash
      ? decodeURI(location.hash).slice(1)
      : "Contact Details";
    setCurrentTab(tab);
  }, [location.hash]);

  const handleTabChange = (key) => {
    setCurrentTab(key);
    let searchQuery = urlParser(location.search);
    delete searchQuery.page;
    delete searchQuery.count;
    delete searchQuery.status;
    key === "Reservations"
      ? navigate(`${location.pathname}${location.search}#${key}`)
      : navigate(
          `${location.pathname}?${new URLSearchParams(
            searchQuery
          ).toString()}#${key}`
        );
  };

  if (!currentTab) {
    return <DataLoader />;
  }
  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(e, v) => {
        handleTabChange(e, v);
      }}
      destroyInactiveTabPane={true}
    >
      <Tabs.TabPane tab="Contact Details" key="Contact Details">
        <>
        <div className="basic_details partners_informations pd-16 bg-white">
          <Wallet contactId={contactId}/>
          </div>
          <div className="basic_details pd-16 bg-white">
            <BasicDetails
              contactDetailsData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
              eliteStatus={eliteStatus}
            />
          </div>

          <div className="basic_details partners_informations pd-16 bg-white">
            <AddressInformation
              contactDetailsData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>
          <div className="basic_details partners_informations pd-16 bg-white">
            <CitizenshipInformation
              contactDetailsData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>
          <div className="basic_details partners_informations pd-16 bg-white">
            <EmergencyContact
              contactDetailsData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>

          <div className="basic_details partners_informations pd-16">
            <MembershipPrograms
              contactDetailsData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>

          <div className="basic_details partners_informations pd-16 bg-white">
            <AdditionalInformation
              additionalInfoData={contactDetailsData}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>

          <div className="basic_details partners_informations pd-16 bg-white">
            <MedicalAndDietaryDetails
              medicalDetailsData={contactDetailsData || {}}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>
          <div className="basic_details partners_informations pd-16 bg-white">
            <Preferences
              contactPreferencesData={contactDetailsData || {}}
              contactId={contactId}
              isLoading={isLoading}
            />
          </div>

        </>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Reservations" key="Reservations">
        <div className="reservation_details pd-16 bg-white">
          <ReservationsMain contactId={contactId} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Activities" key="Activities">
        <div className="activity_details pd-16 bg-white">
          <ActivitiesDesktop
            contactId={contactId}
            setNoteTrigger={setNoteTrigger}
            noteTrigger={noteTrigger}
            contactDetailsData={contactDetailsData}
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane tab="Travel History" key="Travel History">
        <div className="product_history pd-16 bg-white">
          <TravelHistory
            contactId={contactId}
            contactDetailsData={contactDetailsData}
            isLoading={isLoading}
            eliteStatus={eliteStatus}
            loadingEliteStatus={loadingEliteStatus}
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane tab="Requests" key="Requests">
        <div className="product_details container_spacing pd-16 bg-white">
          <Requests
            contactId={contactId}
            contactDetailsData={contactDetailsData}
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane tab="Settings" key="Settings">
        <div className="settings_details settings_details--system pd-16 bg-white">
          <SystemSettings
            contactDetailsData={contactDetailsData}
            contactId={contactId}
            isLoading={isLoading}
          />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ContactDetailTabs;
