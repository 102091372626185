import React, { useEffect, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TourAirOptions = ({ form, onFinishHandler, isLoading }) => {
  const [airCityList, setAirCityList] = useState([]);
  const [loadingAirCity, setLoadingAirCity] = useState(false);
  useEffect(() => {
    getTaxonomyList("air_city", setAirCityList, setLoadingAirCity);
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0">Air Options</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.List
            name="field_air_options"
            initialValue={[
              {
                field_air_option_city: null,
                field_seats: null,
                field_carrier_options: null,
                field_notes: null,
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                  >
                    {key > 0 ? (
                      <Form.Item>
                        <div className="close_relative">
                          <Button
                            className="close_absolute"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </Form.Item>
                    ) : null}

                    <Form.Item
                      label="Air City"
                      {...restField}
                      name={[name, "field_air_option_city"]}
                    >
                      <Select
                        allowClear
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={airCityList?.map((airCity) => ({
                          key: airCity.id,
                          value: airCity.drupal_internal__tid,
                          label: airCity.name,
                        }))}
                        loading={loadingAirCity}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Carrier Options"
                      {...restField}
                      name={[name, "field_carrier_options"]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="# Seats"
                      {...restField}
                      name={[name, "field_seats"]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>

                    <div className="textarea_row">
                      <Form.Item
                        label="Notes"
                        {...restField}
                        name={[name, "field_notes"]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </div>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add More
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </div>
  );
};

export default TourAirOptions;
