import { Form, Grid, Layout } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../components/CommonTopBar/CommonTopBar";
import CreateOrEditEventsHeader from "../../components/Events/CreateOrEditEvent/CreateOrEditEventsHeader";
import CreateOrEditEventsSteps from "../../components/Events/CreateOrEditEvent/CreateOrEditEventsSteps";
import { EventsListingRoute } from "../../url-routes/urlRoutes";
import "./CreateOrEditEventsPage.less";
const { Header, Content, Sider } = Layout;

const CreateOrEditEventsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const createId = searchParams.get("creationId");
  const editId = searchParams.get("editId");
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: `${editId ? "Edit" : "Add"} Event`, path: "" },
  ];
  const eventId = searchParams.get("");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditEventsHeader
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
            />
          </div>
          <Content>
            <CreateOrEditEventsSteps
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CreateOrEditEventsPage;
