import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import ManageUserSearchbar from "../../../components/Admin/ManageUsers/ManageUserSearchbar";
import ManageUsersHeader from "../../../components/Admin/ManageUsers/ManageusersHeader";
import ManageusersTable from "../../../components/Admin/ManageUsers/ManageusersTable";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { getAllUsers } from "../../../redux/features/usersFeatures/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import DataLoader from "../../../others/Loaders/DataLoader";
import { permissionChecker } from "../../../utils/permisssionChecker";
import { useNavigate } from "react-router-dom";

const { Header, Content } = Layout;

const ManageUsersPage = () => {
  const { useBreakpoint } = Grid;
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState(10);
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Users", path: "" },
  ];

  const dispatch = useDispatch();

  const userCreated = useSelector((state) => state.users.userCreated);
  const userDelete = useSelector((state) => state.users.userDeleted);
  const userUpdate = useSelector((state) => state.users.userUpdated);
  const ANONYMOUS_USER_ID = "7c79528c-f1eb-4d84-9b14-6e6d5736d60e";
  const navigate = useNavigate();

  useEffect(() => {
    const isAllowed = permissionChecker("view navigation menu manage users");
    if (!isAllowed) {
      navigate("/page-not-access");
    }
  }, []);

  useEffect(() => {
    dispatch(getAllUsers());
    setPage(1);
  }, [userCreated, userDelete, userUpdate, dispatch]);

  // useEffect(() => {
  //   dispatch(getAllUsers());
  //   console.log("userDelete", userDelete);
  // }, [userDelete, userUpdate, dispatch]);

  let userList = useSelector((state) => state.users.users.userList) || {};
  const loadingAllUsers = useSelector((state) => state.users.users.loading);

  // ? written to hides users with status=false from ui
  // let allUsers = userList?.filter((user) => {
  //   return user.status === true || user.id === ANONYMOUS_USER_ID;
  // });

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ManageUserSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ManageUsersHeader />
          </div>
          <Content className="site-layout-background">
            {/* {loadingAllUsers ? (
              <DataLoader />
            ) : ( */}
            <ManageusersTable
              users={userList}
              loadingAllUsers={loadingAllUsers}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
            {/* )} */}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default ManageUsersPage;
