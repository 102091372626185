import { Table, Divider, Space, Menu, Dropdown, Tag, Button } from 'antd';
import { DeleteOutlined, PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Reservation_activityaddfile from '../../Forms/Reservation_activityaddfile/Reservation_activityaddfile';
import { Link } from "react-router-dom";
import TaskDocument_link from '../../Forms/Reservation_activityaddfile/TaskDocument_link';

import React, { useState } from 'react';


const menu = (
  <Menu
    items={[
      {
        label: <Reservation_activityaddfile />,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <TaskDocument_link />,
        key: '1',
      },

    ]}
  />
);


const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: 'Document Name',
    dataIndex: 'file_name',
  },
  {
    title: 'Document Type',
    dataIndex: 'document_type',
  },
  {
    key: '4',
    title: 'Actions',
    render: (record) => {
      return <>
        <div className='action_flex action_end'>


          <DeleteOutlined className='action_delete' />


        </div>
      </>
    }
  },
];

const Task_documents = () => {


  const data = [
    {
      key: '1',
      file_name: <>
        <a href='#'>Invoice.pdf</a>
      </>,
      document_type: 'DataFile',
    },

  ];
  return (
    <div>

      <div className='requests_title'>
        <h4 className='mb-0'>Task Documents</h4>

        <Dropdown overlay={menu} trigger={['click']}
          overlayClassName="add_cat"
          placement="bottomRight"
        >
          <a onClick={e => e.preventDefault()}>
            <Space>
              <Button type="primary" >
                <PlusOutlined /> Add
              </Button>
            </Space>
          </a>
        </Dropdown>
      </div>
      <Divider className='global_divider' />
      <Table columns={columns} dataSource={data} size="middle" pagination={false} />

    </div>
  )
};
export default Task_documents;