import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentContaner from "../ContentContainer/ContentContaner";
import { Row, Col, Layout, Grid } from "antd";
import Sidebar from "../Header/Header_sidebar";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

const AppContainer = () => {
  const location = useLocation();
  const [hideSidebar, setHideSidebar] = useState(true);

  useLayoutEffect(() => {
    if (
      location.pathname === "/create-report" ||
      location.pathname === "/event/details/assign-dining" ||
      location.pathname === "/Event_assignmotorcoach" ||
      location.pathname === "/login" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/password-updated" ||
      location.pathname === "/set-password" ||
      location.pathname === "/*"
    ) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [location.pathname]);

  const styleSidebarClass = (page) => {
    switch (page) {
      case "/create-report":
      case "/event/details/assign-dining":
      case "/Event_assignmotorcoach":
      case "/login":
      case "/forgot-password":
      case "/password-updated":
      case "/set-password":
      case "/*":
        return "hide_sidebar";
      default:
        return "";
    }
  };
  return (
    <>
      <Layout className={styleSidebarClass(location.pathname)}>
        <Layout>
          {!hideSidebar && (
            <Row className="main-sidebar aside-menu primary-bg">
              <Col xs={0} md={0} lg={24} className="site-layout-background">
                <Sider className="site-layout-background"></Sider>
                <Sidebar />
              </Col>
            </Row>
          )}
          <div className="layout-content">
            <ContentContaner />
          </div>
        </Layout>
      </Layout>
    </>
  );
};
export default AppContainer;
