import React from "react";
import ReservationCelebrationForm from "./ReservationCelebrationForm";
import ReservationMarketingForm from "./ReservationMarketingForm";
import ReservationNotesForm from "./ReservationNotesForm";
import ReservationPreferenceForm from "./ReservationPreferenceForm";
import { Spin } from "antd";

const ReservationEditForm = ({
  form,
  editId,
  onFinishHandler,
  reservationFormData,
  loadingReservationFormData,
}) => (
  <div className="details-tabs" style={{ margin: 0 }}>
    <div>
      <div className="basic_details pd-16 bg-white">
        <Spin spinning={loadingReservationFormData} size="large">
          <ReservationMarketingForm
            form={form}
            editId={editId}
            onFinishHandler={onFinishHandler}
            reservationFormData={reservationFormData}
            loadingReservationFormData={loadingReservationFormData}
          />
        </Spin>
      </div>
    </div>
    <div className="basic_details pd-16 bg-white">
      <Spin spinning={loadingReservationFormData} size="large">
        <ReservationPreferenceForm
          form={form}
          onFinishHandler={onFinishHandler}
          reservationFormData={reservationFormData}
          loadingReservationFormData={loadingReservationFormData}
          editId={editId}
        />
      </Spin>
    </div>
    <div className="basic_details pd-16 bg-white">
      <Spin spinning={loadingReservationFormData} size="large">
        <ReservationCelebrationForm
          form={form}
          onFinishHandler={onFinishHandler}
          reservationFormData={reservationFormData}
          loadingReservationFormData={loadingReservationFormData}
          editId={editId}
        />
      </Spin>
    </div>
    <div className="basic_details pd-16 bg-white">
      <Spin spinning={loadingReservationFormData} size="large">
        <ReservationNotesForm
          form={form}
          onFinishHandler={onFinishHandler}
          reservationFormData={reservationFormData}
          loadingReservationFormData={loadingReservationFormData}
          editId={editId}
        />
      </Spin>
    </div>
  </div>
);

export default ReservationEditForm;
