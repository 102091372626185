import { DownOutlined, UpOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loggedOutUser } from "../../redux/features/authFeatures/authSlice";
// import { userAuthData } from "../../const/const";
const items = [
  {
    label: <span>Change Password</span>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: <span>Logout</span>,
    key: "2",
  },
];
const BasicProfileControl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAuthData = JSON.parse(localStorage.getItem("authorization"));
  const [arrowUp, setarrowUp] = useState(false);

  const { first_name, last_name } = userAuthData;

  const clearMemberData = () => {
    window.localStorage.removeItem("authorization");
    window.localStorage.removeItem("accessTokenExpireAt");
    window.location.href = "/login"
    // navigate("/login", { replace: true });
  };

  const onItemClick = ({ key }) => {
    if (key === "1") {
      navigate("/change-password");
    }
    if (key === "2") {
      clearMemberData();
    }
  };


  return (
    <Dropdown
      overlayClassName="profile-dropdown"
      menu={{
        items,
        onClick: onItemClick,
      }}
      trigger={["click"]}
    >
      <Space
        style={{ cursor: "pointer" }}
        onClick={() => {
          setarrowUp((prevState) => !prevState);
        }}
      >
        <UserOutlined />
        <span className="user-name"> {`${first_name} ${last_name}`}</span>
        {arrowUp ? <UpOutlined /> : <DownOutlined />}
      </Space>
    </Dropdown>
  );
};

export default BasicProfileControl;
