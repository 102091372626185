import { Divider, Table } from "antd";
import moment from "moment";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const AddonFeeCriteria = ({ loadingFeeDetailData, feeDetailsData }) => {
  const data = [
    {
      key: "1",
      questions: "Start Date",
      answers: (
        <>
          <span>
            {feeDetailsData?.field_start_date
              ? moment(feeDetailsData?.field_start_date).format("MM/DD/YYYY")
              : ""}{" "}
            <span className="span_small">
              {feeDetailsData?.field_start_date
                ? moment(`${feeDetailsData?.field_start_date} 00:00:00`).from(moment())
                : ""}
            </span>
          </span>
        </>
      ),
    },
    {
      key: "2",
      questions: "End Date",
      answers: (
        <>
          <span>
            {feeDetailsData?.field_end_date
              ? moment(feeDetailsData?.field_end_date).format("MM/DD/YYYY")
              : ""}{" "}
            <span className="span_small">
              {feeDetailsData?.field_end_date
                ? moment(`${feeDetailsData?.field_end_date} 23:59:00`).from(moment())
                : ""}
            </span>
          </span>
        </>
      ),
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>Criteria</h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="fee_criteria"
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
        loading={{ spinning: loadingFeeDetailData, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default AddonFeeCriteria;
