import { Divider, Modal, Skeleton, Table, Tooltip, message } from "antd";
import { Link } from "react-router-dom";
import Reservation_promocode from "./AddPromoModal";

import React, { useEffect, useState } from "react";
import {
  addOrRemovePromoApi,
  getPromoCodeListApi,
} from "../../../../apis/reservationApi";
import { DeleteOutlined } from "@ant-design/icons";
import AddPromoModal from "./AddPromoModal";
import { EventDiscountDetailsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  markPayeeTrigger,
  setActivityTrigger,
} from "../../../../redux/features/reservationFeatures/reservationSlice";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const ReservationPromoCode = ({ reservationData, reservationId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [addRemovePromo, setAddRemovePromo] = useState(false);
  const { isPayeeTrigger, isActivityTrigger } = useSelector(
    (state) => state.reservation
  );
  const columns = [
    { key: "1", title: "Promo Code", dataIndex: "promo_code" },
    {
      key: "1",
      title: "Discount Name",
      dataIndex: "discount_name",
      render: (record, recordObj) => {
        return (
          <Link
            to={`${EventDiscountDetailsRoute}?id=${reservationData?.select_event?.id}&discountId=${recordObj.uuid}`}
          >
            {record}
          </Link>
        );
      },
    },
    {
      key: "1",
      title: "Action",
      dataIndex: "action",
      render: (record, recordObj) => {
        return (
          <>
            <div className="action_flex action_end">
              <Tooltip title="Remove" placement="rightTop">
                <DeleteOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteHandler({
                      operation: "remove",
                      reservation_id: reservationData?.drupal_internal__id,
                      discount_id: recordObj?.id,
                      applied_on: "none",
                    });
                  }}
                  className="action_delete delete_btn"
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getPromoCodeList(reservationId);
  }, [addRemovePromo]);

  const removePromoCode = async (payload) => {
    const res = await addOrRemovePromoApi(payload);
    if (res.status) {
      message.success("Promo Code Removed");
      setAddRemovePromo(!addRemovePromo);
      dispatch(markPayeeTrigger(!isPayeeTrigger));
      dispatch(setActivityTrigger(!isActivityTrigger));
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const onDeleteHandler = (data) => {
    if (data?.discount_id) {
      Modal.confirm({
        title: "Are you sure you want to remove this promo code?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          let payload = {
            data,
          };
          return removePromoCode(payload);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const getPromoCodeList = async (rId) => {
    setDataLoading(true);
    let res = await getPromoCodeListApi(rId);
    if (res.status) {
      setData(
        res?.data?.map((promo) => ({
          promo_code: promo.promo_code,
          discount_name: promo.discount_name,
          uuid: promo.discount_uuid,
          id: promo.discount_id,
        }))
      );
      setDataLoading(false);
    } else {
      setData([]);
      message.error(SOMETHING_WENT_WRONG);
      setDataLoading(false);
    }
  };

  return (
    <div>
      <div className="requests_title">
        <h4>
          Promo Codes{" "}
          {dataLoading ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${data.length})`
          )}{" "}
          - Related Discounts
        </h4>

        {permissionChecker("add/edit reservation") ? (
          <AddPromoModal
            reservationEventId={reservationData?.select_event?.id || null}
            reservationId={reservationData?.drupal_internal__id || null}
            reservationUuid={reservationId}
            reservationStatus={reservationData?.reservation_status}
            setAddRemovePromo={setAddRemovePromo}
            addRemovePromo={addRemovePromo}
          />
        ) : null}
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        loading={{ spinning: dataLoading, size: "large" }}
        size="middle"
        pagination={false}
      />
    </div>
  );
};
export default ReservationPromoCode;
