import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Select, Skeleton } from "antd";
import { statusList } from "../../../../others/util/commonFunctions";
import { getSingleEventApi } from "../../../../apis/eventsApi";
import { Link, useNavigate } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../url-routes/urlRoutes";
const { Option } = Select;

function EventReservationHeader({
  setUrlParamsObject,
  urlParamsObject,
  eventId,
  type,
  addonId,
  addonType,
}) {
  const [eventData, setEventData] = useState({});
  const navigate = useNavigate();
  const [loadingEventData, setLoadingEventData] = useState(false);
  useEffect(() => {
    getEventData(type === "event" ? eventId : addonId);
  }, []);

  const getEventData = async (id) => {
    setLoadingEventData(true);
    const res = await getSingleEventApi(id);
    if (res.status) {
      setEventData(res?.data?.data);
    } else {
      setEventData({});
    }
    setLoadingEventData(false);
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(
                type === "event"
                  ? `${EventDetailsRoute}?id=${eventId}`
                  : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}&addonType=${addonType}`
              );
            }}
          />
          {loadingEventData ? (
            <Skeleton.Input active={true} style={{ width: "500px" }} />
          ) : (
            <div className="header_res">
              <p>Reservation Status:</p>
              <Select
                style={{
                  width: 200,
                }}
                value={urlParamsObject?.status || "active"}
                onSelect={(e) => {
                  setUrlParamsObject({ ...urlParamsObject, status: e });
                }}
                showSearch            
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={statusList.map((status) => ({
                  key: status.value,
                  value: status.value,
                  label: status.label,
                }))}
              />

              <Link
                className="header_smalltext"
                to={
                  type === "event"
                    ? `${EventDetailsRoute}?id=${eventData?.id}`
                    : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}&addonType=${addonType}`
                }
              >
                {eventData?.name} ({eventData?.field_event_code})
              </Link>
            </div>
          )}
        </h3>
      </div>
    </div>
  );
}

export default EventReservationHeader;
