import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "./FormSteps.less";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getDuplicateContactApi,
  getOrganizationListApi,
} from "../../../apis/contactApi";
import {
  contactStepSetter,
  createContact,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { TextArea } = Input;
const ContactDetailsForm = ({
  form1,
  next,
  contactFormFields,
  editId,
  createId,
  copyId,
  currentStep,
  primaryContactOrgId,
  orgId,
  orgName,
  orgType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createContactStatusRef = useRef(false);
  const [orgsList, setOrgsList] = useState([]);
  const [loadingOrg, setLoadingOrg] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("redirectBackTo");
  const [alreadySavedEmail, setAlreadySavedEmail] = useState("");
  const { contactCreation, contactOrganization } = useSelector(
    (state) => state.contacts
  );
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const { createContactStatus, step1, pressedNextButton, contactStep } =
    contactCreation;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    getOrganizationList();
  }, []);

  useEffect(() => {
    if ((editId || createId) && Object.keys(contactFormFields).length) {
      dispatch(contactStepSetter(contactFormFields.field_step));
      setAlreadySavedEmail(contactFormFields?.field_email_address);
      let stepOneFields = {
        ...contactFormFields,
        field_organization: contactFormFields.field_organization.id,
        field_date_of_birth: !!contactFormFields?.field_date_of_birth
          ? moment(contactFormFields?.field_date_of_birth, "YYYY-MM-DD")
          : null,
        field_prefix: contactFormFields?.field_prefix?.key || null,
        field_suffix: contactFormFields?.field_suffix?.key || null,
        field_gender: contactFormFields?.field_gender?.key || null,
        field_marital_status:
          contactFormFields?.field_marital_status?.key || null,
      };
      form1.setFieldsValue(stepOneFields);
    }
    if (!editId && !createId && !currentStep) {
      form1.resetFields();
    }
  }, [contactFormFields]);

  useEffect(() => {
    if (createContactStatusRef.current) {
      if (createContactStatus?.status) {
        let identifyForm = editId || createId ? "editForm" : "creationForm";
        if (identifyForm === "creationForm" || createId) {
          message.success("Form Submitted Successfully!");
          let route = primaryContactOrgId
            ? `/organization?creationId=${primaryContactOrgId}&orgCotactId=${
                createContactStatus?.data?.data?.id || createId
              }&step=2`
            : !!orgId
            ? `/organization/details?id=${orgId}#Contacts`
            : `/contact/details?id=${
                createContactStatus?.data?.data?.id || createId
              }`;
          step1
            ? reservationId
              ? navigate(`/reservation/details?id=${reservationId}`)
              : navigate(route)
            : next({
                type: "creation",
                id: createContactStatus?.data?.data?.id || createId,
                primaryContactOrgId,
                orgId,
              });
        } else {
          if (identifyForm === "editForm") {
            message.success("Form Submitted Successfully!");
            step1
              ? navigate(`/contact/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateStep());
      }
      if (!createContactStatus?.status) {
        message.error(
          createContactStatus?.data?.message || SOMETHING_WENT_WRONG
        );
      }
    }
    createContactStatusRef.current = true;
  }, [createContactStatus]);

  const getOrganizationList = async () => {
    setLoadingOrg(true);
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getOrganizationListApi({ reqHeaders });
    if (response && response.status) {
      setOrgsList(response?.data?.data);
      setLoadingOrg(false);
      if (primaryContactOrgId || orgId) {
        form1.setFieldsValue({
          field_organization: primaryContactOrgId || orgId,
        });
      }
    } else {
      setOrgsList([]);
      form1.setFields([
        {
          name: "organization",
          errors: ["Unable to fetch organization data"],
        },
      ]);
      setLoadingOrg(false);
    }
  };

  const checkDuplicateEmail = async (email) => {
    const payload = {
      data: {
        validation_type: "email",
        value: email,
        contact_id: null,
      },
    };
    const response = await getDuplicateContactApi(payload);
    if (response.status) {
      return {
        isDuplicate: response.data.duplicates,
        message: response.data.message,
      };
    } else {
      message.error(SOMETHING_WENT_WRONG);
      return { error: true };
    }
  };

  const onFinishHandler = async (values) => {
    const reqMethod = editId || createId ? "patch" : "post";
    const contactId = editId || createId;
    const reqHeaders = customRequestHeader({ token: Token });
    const payload = {
      data: {
        type: "contact--contact",
        attributes: {
          name: `${
            values?.field_first_name ? values?.field_first_name?.trim() : ""
          }${
            values?.field_middle_name
              ? ` ${values?.field_middle_name?.trim()}`
              : ""
          }${
            values?.field_last_name ? ` ${values?.field_last_name?.trim()}` : ""
          }`,
          field_prefix: values.field_prefix || null,
          field_first_name: values.field_first_name || null,
          field_middle_name: values.field_middle_name || null,
          field_last_name: values.field_last_name || null,
          field_suffix: values.field_suffix || null,
          field_preferred_name: values.field_preferred_name || null,
          field_date_of_birth:
            values.field_date_of_birth.format("YYYY-MM-DD") || null,
          field_gender: values.field_gender || null,
          field_roles: values.field_roles || null,
          // field_primary_contact: values.field_primary_contact || null,
          field_marital_status: values.field_marital_status || null,
          field_spouse_name: values.field_spouse_name || null,
          field_email_address: values.field_email_address || null,
          field_email_address_2: values.field_email_address_2 || null,
          field_do_not_contact: values.field_do_not_contact,
          field_blessing_notes: values.field_blessing_notes || null,
          field_step: currentStep
            ? currentStep > contactStep
              ? +currentStep
              : contactStep
            : 1, 
        },
        relationships: {
          field_organization: {
            data: !!values.field_organization
              ? {
                  type: "node--organization",
                  id: values.field_organization,
                }
              : {},
          },
          field_proof_photo: null,
          field_vaccination_certificate: null,
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    
    if(reqMethod === "post" || createId){
      payload.data.attributes.field_source = "booking_engine"
    }
    if (
      (editId || createId) &&
      values?.field_email_address === alreadySavedEmail
    ) {
      payload.data.id = editId || createId;
      dispatch(createContact({ reqMethod, contactId, reqHeaders, payload }));
    } else {
      let duplicateContact = await checkDuplicateEmail(
        values?.field_email_address
      );
      if (duplicateContact?.isDuplicate) {
        // message.error(duplicateContact?.message);
        form1.setFields([
          {
            name: "field_email_address", // required
            errors: [duplicateContact?.message],
          },
        ]);
      } else {
        if (duplicateContact?.error) {
          message.error(SOMETHING_WENT_WRONG);
        } else {
          if (editId || createId) {
            payload.data.id = editId || createId;
          }
          dispatch(
            createContact({ reqMethod, contactId, reqHeaders, payload })
          );
        }
      }
    }
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 8,
                lg: 8,
              }}
              wrapperCol={{
                sm: 24,
                md: 14,
                lg: 14,
              }}
              layout="horizontal"
              form={form1}
              onFinish={onFinishHandler}
            >
              <Form.Item
                className="asterick-align"
                label="Organization"
                name="field_organization"
              >
                <Select
                  loading={loadingOrg}
                  showSearch
                  showArrow
                  allowClear
                  disabled={!!primaryContactOrgId || !!orgId}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={orgsList.map((org) => ({
                    key: org.id,
                    value: org.id,
                    label: org.title,
                  }))}
                />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Prefix"
                name="field_prefix"
              >
                <Select 
                allowClear
                showSearch
                showArrow
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  <Select.Option value="apostle" label="Apostle">Apostle</Select.Option>
                  <Select.Option value="bishop" label="Bishop">Bishop</Select.Option>
                  <Select.Option value="dr" label="Dr">Dr</Select.Option>
                  <Select.Option value="miss" label="Miss">Miss</Select.Option>
                  <Select.Option value="mr" label="Mr">Mr</Select.Option>
                  <Select.Option value="mrs" label="Mrs">Mrs</Select.Option>
                  <Select.Option value="ms" label="Ms">Ms</Select.Option>
                  <Select.Option value="pastor" label="Pastor">Pastor</Select.Option>
                  <Select.Option value="rev" label="Rev">Rev</Select.Option>
                  <Select.Option value="rabbi" label="Rabbi">Rabbi</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="First Name"
                name="field_first_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Middle Name"
                name="field_middle_name"
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Last Name"
                name="field_last_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Suffix" name="field_suffix">
                <Select 
                  allowClear 
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  <Select.Option value="jr" label="Jr">Jr</Select.Option>
                  <Select.Option value="sr" label="Sr">Sr</Select.Option>
                  <Select.Option value="i" label="I">I</Select.Option>
                  <Select.Option value="ii" label="II">II</Select.Option>
                  <Select.Option value="iii" label="III">III</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Preferred Name" name="field_preferred_name">
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Date Of Birth"
                name="field_date_of_birth"
                rules={[
                  {
                    required: true,
                    message: "Please enter date of birth",
                  },
                ]}
              >
                <DatePicker
                  format={"MM/DD/YYYY"}
                  disabledDate={(current) =>
                    current.isAfter(moment().subtract(1, "day"))
                  }
                />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Gender"
                name="field_gender"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="male">Male</Radio>
                  <Radio value="female">Female</Radio>
                </Radio.Group>
              </Form.Item>

              {/* <Form.Item label="Roles" name="field_roles">
                <Select>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="traveler">Traveler</Select.Option>
                  <Select.Option value="ministry">Ministry</Select.Option>
                  <Select.Option value="editor">Editor</Select.Option>
                </Select>
              </Form.Item> */}
              {/* <Form.Item label="Primary Contact" name="field_primary_contact">
                <Select>
                  <Select.Option value={true}>True</Select.Option>
                  <Select.Option value={false}>False</Select.Option>
                </Select>
              </Form.Item> */}
              <Form.Item label="Marital Status" name="field_marital_status">
                <Select 
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  <Select.Option value="single" label="Single">Single</Select.Option>
                  <Select.Option value="married" label="Married">Married</Select.Option>
                  <Select.Option value="widowed" label="Widowed">Widowed</Select.Option>
                  <Select.Option value="divorced " label="Divorced">Divorced</Select.Option>
                  <Select.Option value="separated" label="Separated">Separated</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Spouse Name" name="field_spouse_name">
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Email Address"
                name="field_email_address"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                    validateTrigger: "onSubmit",
                  },
                  {
                    message: "Please enter email address",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Alternative Email Address"
                name="field_email_address_2"
                validateTrigger="onSubmit"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Do Not Contact"
                name="field_do_not_contact"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                className="textarea_row"
                label="Blessing Notes"
                name="field_blessing_notes"
              >
                <TextArea />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactDetailsForm;
