import { EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, Tag } from "antd";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./Dependent_category.less";
import DependentCategoryContainer from "./DependentCategoryContainer";
import {
  AddonCategoryDetailsDependentRoute,
  EventCategoryDetailsDependentRoute,
} from "../../../../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../../../../utils/permisssionChecker";

const { Panel } = Collapse;

// table

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (record) => {
      return (
        <>
          <div>
            <a style={{ fontSize: "14px", lineHeight: "20px" }}>JFK1</a>
            <p>{record}</p>
          </div>
        </>
      );
    },
  },

  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
];

const DependentCategory = ({ addonId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");

  return (
    <Collapse bordered={false}>
      <Panel
        header={
          <>
            <div className="collapse_flex">
              <p className="pricing_paragraph">Dependent Categories</p>
              {permissionChecker("add category") ? <Link
                to={
                  addonId
                    ? `${AddonCategoryDetailsDependentRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
                    : `${EventCategoryDetailsDependentRoute}?id=${eventId}&categoryId=${categoryId}`
                }
              >
                <Button icon={<EditOutlined />} />
              </Link> : null}
            </div>
            <Divider className="global_divider mb-0" />
          </>
        }
        key="1"
      >
        <div className="dependent_main">
          <DependentCategoryContainer
          // dependentCategory={dependentCategory}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default DependentCategory;
