import React, { useEffect, useRef } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeUserPassword } from "../../redux/features/authFeatures/authSlice";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const ChangePasswordForm = () => {
  const { login, changePassword } = useSelector(
    (state) => state.authentication
  );
  const { changePasswordResponse, loadingChangePassword } = changePassword;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const changePasswordRef = useRef(false);

  useEffect(() => {
    if (changePasswordRef.current) {
      console.log({ changePasswordResponse });
      if (changePasswordResponse.status) {
        navigate("/");
        message.success(changePasswordResponse.data.message);
      } else {
        message.error(
          changePasswordResponse.data.response.data.error ||
          changePasswordResponse.data.message ||
          SOMETHING_WENT_WRONG
        );
      }
    }
    changePasswordRef.current = true;
  }, [changePasswordResponse]);

  const onFinishHandler = (values) => {
    const payload = {
      old_pass: values.oldPassword,
      new_pass: values.newPassword,
      confirm_pass: values.confirmPassword,
    };
    dispatch(changeUserPassword(payload));
  };
  return (
    <div>
      <div style={{ marginTop: "24px" }} />
      <Form
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 6,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item
          className="asterick-align"
          label="Old Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Please Input Old Password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please Input New Password!",
            },
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Please Confirm New Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The Passwords You Entered Do Not Match")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingChangePassword}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
