import { Collapse, Table, Tag, message, Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "./Dependent_category.less";
import { useDispatch, useSelector } from "react-redux";
import { getDependentCategories } from "../../../../../../redux/features/eventFeatures/eventsSlice";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import { AddonCategoryDetailsRoute } from "../../../../../../url-routes/urlRoutes";

const { Panel } = Collapse;

const columns = [
  {
    title: "Code",
    dataIndex: "code",
  },

  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          if (tag) {
            return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
          }
        })}
      </>
    ),
  },
];

const DependentCategoryContainer = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dependentCategory, setDependentCategory] = useState([]);
  const { loadingDependentCategory, dependentCategoryResponse } = useSelector(
    (state) => state.events.dependentCategory
  );
  const categoryId = searchParams.get("categoryId");
  const eventId = searchParams.get("id");

  const dependentCategoryRef = useRef();

  useEffect(() => {
    if (dependentCategoryRef?.current) {
      if (dependentCategoryResponse?.status) {
        setDependentCategory(dependentCategoryResponse?.data?.data);
      } else {
        message.error(
          dependentCategoryResponse?.data?.message || SOMETHING_WENT_WRONG
        );
      }
    }
    dependentCategoryRef.current = true;
  }, [dependentCategoryResponse]);

  useEffect(() => {
    dispatch(getDependentCategories(categoryId));
  }, []);

  return (
    <>
      <Spin spinning={loadingDependentCategory} size="large">
        {!!dependentCategory?.length &&
          dependentCategory?.map((item) => {
            if (!item?.dependent_category?.length) {
              return (
                <>
                  <div className="bg-grey">
                    <p>{`${item?.addon_name || ""} (0)`}</p>
                  </div>
                </>
              );
            }
            const dataSource = item?.dependent_category?.map((val, index) => {
              const {
                dep_category_active,
                dep_category_default,
                dep_category_required,
              } = val || {};
              return {
                key: index,
                id: <>{val?.dep_category_id || ""}</>,
                code: <>{val?.dep_category_code || ""}</>,
                name: (
                  <>
                    <Link
                      to={`${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${item?.addon_uuid}&categoryId=${val?.redirect_uuid}`}
                    >
                      {val?.dep_category_name || ""}
                    </Link>
                  </>
                ),
                tags: [
                  dep_category_active && "Active",
                  dep_category_default && "Default",
                  dep_category_required && "Required",
                ].filter((tag) => tag),
              };
            }, []);

            return (
              <>
                <Collapse bordered={false}>
                  <Panel
                    header={`${item?.addon_name || ""} (${
                      item?.dependent_category?.length
                    })`}
                    key="1"
                  >
                    <Table
                      columns={columns}
                      dataSource={dataSource}
                      size="middle"
                      pagination={false}
                      showHeader={false}
                    />
                  </Panel>
                </Collapse>
              </>
            );
          })}
      </Spin>
    </>
  );
};

export default DependentCategoryContainer;
