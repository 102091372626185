import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
import Horizontals from "../../components/Header/Horizontal_menu";
import ContactDetailsHeader from "../../components/Contacts/ContactDetails/ContactDetailsHeader";
import ContactDetailTabs from "../../components/Contacts/ContactDetails/ContactDetailTabs";
import "./ContactDetailsPage.less";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { contactAllDetails } from "../../redux/features/contactFeatures/contactSlice";
import DataLoader from "../../others/Loaders/DataLoader";
import ContactSearchBar from "../../components/Contacts/ContactsListing/ContactSearchBar";
import { updateRecentlyViewedApi } from "../../apis/usersApi";
import { updateRecentlyViewed } from "../../utils/recentlyViewedUpdater";
import { customRequestHeader } from "../../utils/cutomRequestHeader";
import { getEliteTravelerStatus } from "../../others/commonApiCalls/commonApiCalls";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
const { Header, Content, Sider } = Layout;

const ContactDetailsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const dispatch = useDispatch();
  const {
    contactDetails,
    contactCreation,
    updateAdditional,
    updateMedical,
    updatePreference,
    updateMembership,
    deleteMembership,
    loadingContactDetailsEdit,
    addEditTravelHistory,
  } = useSelector((state) => state.contacts);
  const contactDetailsRef = useRef(false);
  const page = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
  });
  const { deleteMembershipResponse } = deleteMembership;
  const { updateMembershipResponse } = updateMembership;
  const { updateAdditionalResponse } = updateAdditional;
  const { updateMedicalResponse } = updateMedical;
  const { updatePreferenceResponse } = updatePreference;
  const { contactAllDetailsResponse, contactDetailsLoading } = contactDetails;
  const { createContactStatus } = contactCreation;
  const [contactDetailsData, setContactDetailsData] = useState({});
  const contactId = searchParams.get("id");
  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    { title: "Details", path: "" },
  ];
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    if (contactDetailsRef.current) {
      if (contactAllDetailsResponse.status) {
        setContactDetailsData(contactAllDetailsResponse?.data?.data);
        const { type, langcode, drupal_internal__id, id } =
          contactAllDetailsResponse?.data?.data;
        updateRecentlyViewed(type, id, langcode, drupal_internal__id);
      } else {
        message.error(
          contactAllDetailsResponse.data.message || SOMETHING_WENT_WRONG
        );
        setContactDetailsData({});
      }
    }
    contactDetailsRef.current = true;
  }, [contactAllDetailsResponse]);

  useEffect(() => {
    const reqHeaders = customRequestHeader({ token: Token });
    dispatch(contactAllDetails({ contactId, reqHeaders }));
  }, [
    createContactStatus,
    deleteMembership,
    updateMembershipResponse,
    updateAdditionalResponse,
    updateMedicalResponse,
    updatePreferenceResponse,
    contactId,
    addEditTravelHistory,
  ]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            {/* <Horizontals /> */}
            <ContactSearchBar
              contactId={contactId}
              // filter props are required
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
              setUrlParamsObject={setUrlParamsObject}
              urlParamsObject={urlParamsObject}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ContactDetailsHeader
              contactId={contactId}
              contactDetailsData={contactDetailsData}
              contactDetailsLoading={contactDetailsLoading}
            />
          </div>
          <ContactDetailTabs
            contactId={contactId}
            contactDetailsData={contactDetailsData}
            isLoading={contactDetailsLoading && loadingContactDetailsEdit}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ContactDetailsPage;
