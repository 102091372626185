import { Button, Modal, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { editMarketingSource } from "../../../../../apis/eventsApi";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const ModalSource = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sourceValue, setSourceValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [drupalInternalId, setDrupalInternalId] = useState();
  const { singleEvent } = useSelector((state) => state.events);
  const [form] = Form.useForm();
  const url = window.location.pathname.split("/");
  const permission = url.includes("event") ? permissionChecker("add/edit event") : permissionChecker("view navigation menu marketing sources")  
  const { singleEventResponse } = singleEvent;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFinish = async () => {
    setLoading(true);
    const payload = {
      type: "event",
      event_id: drupalInternalId,
      marketing_data_type: "source",
      operation: "add",
      data: [
        {
          name: sourceValue,
          id: null,
        },
      ],
    };
    const response = await editMarketingSource(payload);

    if (response.status) {
      message.success(response?.data?.data?.message);
      setIsModalOpen(false);
      props.setRefreshData(true);
      form.resetFields();
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (singleEventResponse.status) {
      const { drupal_internal__id } = singleEventResponse?.data?.data;
      setDrupalInternalId(drupal_internal__id);
    }
  }, [singleEventResponse]);
  return (
    <>
      {permission? (
        <Button type="primary" onClick={showModal}>
          Add
        </Button>
      ) : null}

      <Modal
        footer={
          <Button
            type="primary"
            onClick={() => handleFinish()}
            loading={loading}
          >
            Add
          </Button>
        }
        title="Add Source"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item
            label="Source"
            name="sources"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please specify source.",
              },
            ]}
          >
            <Input onChange={(e) => setSourceValue(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ModalSource;
