import { Divider, Table } from 'antd';
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
  },
];
const data = [
  {
    key: '1',
    name: 'Cancel at Administrative Fee',
    age: <>
      <p className='text-bold' >200.0000</p>
    </>,
  },
  {
    key: '2',
    name: 'V - Verandan Stateroom',
    age: <>
      <p className='text-bold'>200.0000</p>
    </>,
  },
  {
    key: '3',
    name: 'Michael McDonald Jr',
    age: <>
      <p className='text-bold'>200.0000</p>
    </>,
  },
];
const CancellationsDetails = () => (
  <>
    <div className='form-title'>
      <p>Cancellation Details</p>
    </div>
    <Table className='cancel_table' columns={columns} dataSource={data} showHeader={false} pagination={false} size="middle" />
  </>
);
export default CancellationsDetails;