import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";

import Breadcrumb_motorcoach from "../../components/Breadcrumb/Breadcrumb_motorcoach/Breadcrumb_motorcoach";
import Event_assignmotertable from "../../components/View_components/Event_assignmotertable/Event_assignmotertable";
import EventAssignButton from "../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AssignDining/EventAssignButton/EventAssignButton";
import Event_motorcoachtable from "../../components/View_components/Event_motorcoachtable/Event_motorcoachtable";
import React from "react";
const { Header, Content, Sider } = Layout;

function Event_assignmotorcoach() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Breadcrumb className="Event_breadcrumb_main">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Events</Breadcrumb.Item>
            <Breadcrumb.Item>Events Details</Breadcrumb.Item>
            <span>
              <Breadcrumb.Item>Assign Motorcoach</Breadcrumb.Item>
            </span>

            <Breadcrumb_motorcoach />
          </Breadcrumb>
          <div>
            <div className="event_dining_layout">
              <Row>
                <Col
                  sm={24}
                  md={24}
                  lg={11}
                  xl={11}
                  xxl={11}
                  className="motor_coach-section"
                >
                  <Content>
                    <div>
                      <Event_assignmotertable />
                    </div>
                  </Content>
                </Col>
                <Col
                  sm={24}
                  md={24}
                  lg={1}
                  xl={1}
                  xxl={1}
                  className="centerassigned-btn"
                >
                  <EventAssignButton />
                </Col>

                <Col
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="motor_right-section"
                >
                  <Content>
                    <div>
                      <Event_motorcoachtable />
                    </div>
                  </Content>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default Event_assignmotorcoach;
