import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
import CruisePostform_five from "../../../Forms/Intakecruise_formone/CruisePostform_five";
import CruisePostform_four from "../../../Forms/Intakecruise_formone/CruisePostform_four";
import CruisePostform_one from "../../../Forms/Intakecruise_formone/CruisePostform_one";
import CruisePostform_seven from "../../../Forms/Intakecruise_formone/CruisePostform_seven";
import CruisePostform_six from "../../../Forms/Intakecruise_formone/CruisePostform_six";
import CruisePostform_two from "../../../Forms/Intakecruise_formone/CruisePostform_two";
import CruiseDetailsForm from "./CruiseTabs/MainTab/CruiseDetailsForm";
import HotelOptionsForm from "./CruiseTabs/MainTab/HotelOptionsForm";
import InitialContactPersonForm from "./CruiseTabs/MainTab/InitialContactPersonForm";
import MeetingSpaceNeedsForm from "./CruiseTabs/MainTab/MeetingSpaceNeedsForm";
import MiscellaneousForm from "./CruiseTabs/MainTab/MiscellaneousForm";
import NotesForm from "./CruiseTabs/MainTab/NotesFrom";
import PricingOptionsForm from "./CruiseTabs/MainTab/PricingOptionsForm";
import ShoreExcursionsForm from "./CruiseTabs/MainTab/ShoreExcursionsForm";
import AirOptionsForm from "./CruiseTabs/PrePostExtensionTab/AirOptionsForm";
import DatesAndItineryForm from "./CruiseTabs/PrePostExtensionTab/DatesAndItineryForm";
import MealsForm, {
  default as CruisePostform_three,
} from "./CruiseTabs/PrePostExtensionTab/MealsForm";
import PrePricingOptionsForm from "./CruiseTabs/PrePostExtensionTab/PrePricingOptionsForm";
import StaffAndVehicleForm from "./CruiseTabs/PrePostExtensionTab/StaffAndVehicleForm";
import {
  changeTabState,
  createGroupDetails,
  createGroupDetailsExtension,
  getGroupDetailsData,
  getGroupDetailsExtensionData,
} from "../../../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PrePostMiscellaneousForm from "./CruiseTabs/PrePostExtensionTab/PrePostMiscellaneousForm";
import moment from "moment";
import { GroupDetails_DetailsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
// import { userUuid } from "../../../../utils/cutomRequestHeader";

const CreateOrEditCruiseTabsContainer = ({
  createId,
  editId,
  form1,
  form2,
  form3,
  preExtId,
  postExtId,
}) => {
  const [currentTab, setCurrentTab] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    groupDetailsCreation,
    groupDetailsExtensionCreation,
    groupDetailsData,
    groupDetailsExtensionData,
  } = useSelector((state) => state.groupDetails);

  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const {
    groupDetailsCreationResponse,
    loadingGroupDetailsCreation,
    mainTab,
    preTab,
    postTab,
  } = groupDetailsCreation;
  const groupDetailsCreationRef = useRef(false);
  const {
    groupDetailsExtensionCreationResponse,
    loadingGroupDetailsExtensionCreation,
  } = groupDetailsExtensionCreation;
  const extensionCreationRef = useRef(false);
  const { groupDetailsDataResponse, loadingGroupDetailsData } =
    groupDetailsData;
  const groupDetailsDataRef = useRef(false);
  const {
    groupDetailsExtensionDataResponse,
    loadingGroupDetailsExtensionData,
  } = groupDetailsExtensionData;
  const extensionDataRef = useRef(false);
  const [cruiseFormField, setCruiseFormField] = useState({});
  const [extFormField, setExtFormField] = useState({});

  useEffect(() => {
    let tab = location.hash ? decodeURI(location.hash).slice(1) : "Cruise";
    setCurrentTab(tab);
  }, [location.hash]);

  useEffect(() => {
    if (currentTab === "Cruise" && (editId || createId)) {
      dispatch(getGroupDetailsData(createId || editId));
    }
    if (
      (currentTab === "Pre-Extension" && preExtId) ||
      (currentTab === "Post-Extension" && postExtId)
    ) {
      dispatch(getGroupDetailsExtensionData(createId || editId));
    }
  }, [currentTab]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(cruiseFormField).length) {
      form1.setFieldsValue({
        field_event_partner: !!cruiseFormField?.field_event_partner?.length
          ? cruiseFormField?.field_event_partner?.map(
              (eventPartner) => eventPartner.id
            )
          : [],
        field_event_name: cruiseFormField?.name || null,
        field_event_code: cruiseFormField?.field_event_code || null,
        field_tollfree_extension:
          cruiseFormField?.field_tollfree_extension || "+1",
        field_tollfree_phone: cruiseFormField?.field_tollfree_phone || null,
        field_event_details: !!cruiseFormField?.field_event_details?.length
          ? cruiseFormField?.field_event_details.map((eventDetails) => ({
              field_requested_dates: [
                !!eventDetails?.field_requested_start_dates
                  ? moment(
                      eventDetails?.field_requested_start_dates,
                      "YYYY-MM-DD"
                    )
                  : null,
                !!eventDetails?.field_requested_end_dates
                  ? moment(
                      eventDetails?.field_requested_end_dates,
                      "YYYY-MM-DD"
                    )
                  : null,
              ],
              field_alternative_dates: [
                !!eventDetails?.field_alternative_start_dates
                  ? moment(
                      eventDetails?.field_alternative_start_dates,
                      "YYYY-MM-DD"
                    )
                  : null,
                !!eventDetails?.field_alternative_end_dates
                  ? moment(
                      eventDetails?.field_alternative_end_dates,
                      "YYYY-MM-DD"
                    )
                  : null,
              ],
              field_are_dates_flexible: eventDetails?.field_are_dates_flexible,
              field_cruise_line: eventDetails?.field_cruise_line?.id || null,
              field_ship: eventDetails?.field_ship?.id || null,
              field_cruise_itinerary:
                eventDetails?.field_cruise_itinerary?.id || null,
              field_length_of_cruise:
                eventDetails?.field_length_of_cruise || null,
              field_url_address: eventDetails?.field_url_address || null,
              field_sailing: eventDetails?.field_sailing || null,
              field_event_exclusive: eventDetails?.field_event_exclusive,
            }))
          : [],
        field_pricing_option: !!cruiseFormField?.field_pricing_option?.length
          ? cruiseFormField?.field_pricing_option.map((pricingOption) => ({
              ...pricingOption,
              field_est_group_size:
                pricingOption?.field_est_group_size?.id || null,
              field_one_for_program:
                pricingOption?.field_one_for_program?.id || null,
            }))
          : [],
        field_hotel_options: !!cruiseFormField?.field_hotel_options?.length
          ? cruiseFormField?.field_hotel_options?.map((hotelOptions) => ({
              ...hotelOptions,
              field_check_in: hotelOptions?.field_check_in
                ? moment(hotelOptions?.field_check_in, "YYYY-MM-DD")
                : null,
              field_check_out: hotelOptions?.field_check_out
                ? moment(hotelOptions?.field_check_out, "YYYY-MM-DD")
                : null,
              field_destination: hotelOptions?.field_destination?.id || null,
              field_star_level: hotelOptions?.field_star_level?.id || null,
              field_traveler_type:
                hotelOptions?.field_traveler_type?.id || null,
            }))
          : [],

        field_meeting_needs: !!cruiseFormField?.field_meeting_needs?.length
          ? cruiseFormField?.field_meeting_needs?.map((meetingSpace) => ({
              ...meetingSpace,
              field_preliminary_event:
                meetingSpace?.field_preliminary_event?.id || null,
              field_dining_time: !!meetingSpace?.field_dining_time
                ? moment(meetingSpace?.field_dining_time, "hh:mm A")
                : null,
              field_large_theater:
                meetingSpace?.field_large_theater?.id || null,
              field_dining_type: meetingSpace?.field_dining_type?.id || null,
              field_break_out_rooms: meetingSpace?.field_break_out_rooms?.length
                ? meetingSpace?.field_break_out_rooms?.map(
                    (breakOutRoom) => breakOutRoom?.id
                  )
                : [],
            }))
          : [],

        field_shore_excursions: !!cruiseFormField?.field_shore_excursions
          ?.length
          ? cruiseFormField?.field_shore_excursions?.map((shoreExcursions) => ({
              ...shoreExcursions,
              field_port: shoreExcursions?.field_port?.id || null,
            }))
          : [],
        field_non_married_roommate_policy:
          cruiseFormField?.field_non_married_roommate_policy?.id || null,
        field_optional_post_departure_ins_included:
          cruiseFormField?.field_optional_post_departure_ins_included,
        field_event_partner_flight_allowance:
          cruiseFormField?.field_event_partner_flight_allowance || null,
        field_username: cruiseFormField?.field_username || null,
        field_note: cruiseFormField?.field_note || null,
      });
    }
  }, [cruiseFormField]);

  useEffect(() => {
    if (
      currentTab === "Pre-Extension" &&
      preExtId &&
      !!Object.keys(extFormField).length
    ) {
      // console.log({ extFormField });
      let preExtFormData = !!extFormField?.length
        ? extFormField.find((extform) => extform.field_type === "pre")
        : null;
      extFormValueSetter(form2, preExtFormData);
    }
    if (
      currentTab === "Post-Extension" &&
      postExtId &&
      !!Object.keys(extFormField).length
    ) {
      // console.log({ extFormField });
      let postExtFormData = !!extFormField?.length
        ? extFormField.find((extform) => extform.field_type === "post")
        : null;
      extFormValueSetter(form3, postExtFormData);
    }
  }, [extFormField]);

  useEffect(() => {
    if (groupDetailsDataRef.current) {
      if (groupDetailsDataResponse.status) {
        setCruiseFormField(groupDetailsDataResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setCruiseFormField({});
      }
    }
    groupDetailsDataRef.current = true;
  }, [groupDetailsDataResponse]);

  useEffect(() => {
    if (extensionDataRef.current) {
      if (groupDetailsExtensionDataResponse.status) {
        setExtFormField(groupDetailsExtensionDataResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setExtFormField({});
      }
    }
    extensionDataRef.current = true;
  }, [groupDetailsExtensionDataResponse]);

  useEffect(() => {
    if (groupDetailsCreationRef.current) {
      if (groupDetailsCreationResponse.status) {
        let identifyForm = editId || createId ? "editForm" : "creationForm";
        let route = createRouteToNavigate(
          editId,
          groupDetailsCreationResponse?.data?.data?.id || createId,
          preExtId,
          postExtId,
          currentTab
        );
        let detailsRoutes = routeToDetailsPage(
          groupDetailsCreationResponse?.data?.data?.id || createId || editId,
          preExtId,
          postExtId
        );
        if (identifyForm === "creationForm" || createId) {
          message.success("Cruise Created Successfully");
          mainTab
            ? navigate(detailsRoutes)
            : navigate(`${route}#Pre-Extension`);
        } else {
          if (identifyForm === "editForm") {
            message.success("Cruise Updated Successfully");
            mainTab
              ? navigate(detailsRoutes)
              : navigate(`${route}#Pre-Extension`);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(changeTabState());
      }
      if (!groupDetailsCreationResponse?.status) {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    groupDetailsCreationRef.current = true;
  }, [groupDetailsCreationResponse]);

  useEffect(() => {
    if (extensionCreationRef.current) {
      if (groupDetailsExtensionCreationResponse.status) {
        if (currentTab === "Pre-Extension") {
          const route = createRouteToNavigate(
            editId,
            createId,
            groupDetailsExtensionCreationResponse?.data?.data?.id || preExtId,
            postExtId,
            currentTab
          );
          const detailsRoutes = routeToDetailsPage(
            editId || createId,
            groupDetailsExtensionCreationResponse?.data?.data?.id || preExtId,
            postExtId
          );
          if (preExtId) {
            message.success("Pre-Extension Updated Successfully");
            preTab
              ? navigate(detailsRoutes)
              : navigate(`${route}#Post-Extension`);
          } else {
            message.success("Pre-Extension Created Successfully");
            preTab
              ? navigate(detailsRoutes)
              : navigate(`${route}#Post-Extension`);
          }
        } else {
          if (currentTab === "Post-Extension") {
            const detailsRoutes = routeToDetailsPage(
              editId || createId,
              preExtId,
              groupDetailsExtensionCreationResponse?.data?.data?.id || postExtId
            );
            postExtId
              ? message.success("Post-Extension Updated Successfully")
              : message.success("Post-Extension Created Successfully");
            navigate(detailsRoutes);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(changeTabState());
      }
      if (!groupDetailsExtensionCreationResponse.status) {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    extensionCreationRef.current = true;
  }, [groupDetailsExtensionCreationResponse]);

  const extFormValueSetter = (extform, extFormData) => {
    extform.setFieldsValue({
      field_requested_dates: [
        !!extFormData?.field_requested_start_date
          ? moment(extFormData?.field_requested_start_date, "YYYY-MM-DD")
          : null,
        !!extFormData?.field_requested_end_date
          ? moment(extFormData?.field_requested_end_date, "YYYY-MM-DD")
          : null,
      ],
      field_extension_destination:
        extFormData?.field_extension_destination?.id || null,
      field_tour_operator: extFormData?.field_tour_operator?.id || null,
      field_extension_itinerary:
        extFormData?.field_extension_itinerary?.id || null,
      field_are_dates_flexible: extFormData?.field_are_dates_flexible,
      field_length_of_extension: extFormData?.field_length_of_extension || null,
      field_hotel_options: !!extFormData?.field_hotel_options?.length
        ? extFormData?.field_hotel_options?.map((hotelOptions) => ({
            ...hotelOptions,
            field_check_in: hotelOptions?.field_check_in
              ? moment(hotelOptions?.field_check_in, "YYYY-MM-DD")
              : null,
            field_check_out: hotelOptions?.field_check_out
              ? moment(hotelOptions?.field_check_out, "YYYY-MM-DD")
              : null,
            field_destination: hotelOptions?.field_destination?.id || null,
            field_star_level: hotelOptions?.field_star_level?.id || null,
            field_traveler_type: hotelOptions?.field_traveler_type?.id || null,
          }))
        : [],
      field_meal_type: extFormData?.field_meal_type?.id || null,
      field_additional_meal: extFormData?.field_additional_meal || null,
      field_group_size_req:
        extFormData?.field_pricing_option?.field_group_size_req || null,
      field_group_size_pricing:
        extFormData?.field_pricing_option?.field_group_size_pricing || null,
      field_est_bus_count:
        extFormData?.field_pricing_option?.field_est_bus_count || null,
      field_est_staff_count:
        extFormData?.field_pricing_option?.field_est_staff_count || null,
      field_pricing_based_on:
        extFormData?.field_pricing_option?.field_pricing_based_on || null,
      field_purpose_for_income:
        extFormData?.field_pricing_option?.field_purpose_for_income || null,
      field_partner_income:
        extFormData?.field_pricing_option?.field_partner_income || null,
      field_notes: extFormData?.field_pricing_option?.field_notes || null,
      field_tip_budget:
        extFormData?.field_pricing_option?.field_tip_budget || null,
      field_misc_items:
        extFormData?.field_pricing_option?.field_misc_items || null,
      field_one_for_program:
        extFormData?.field_pricing_option?.field_one_for_program?.id || null,
      field_guide_request: extFormData?.field_guide_request?.length
        ? extFormData?.field_guide_request?.map(
            (guideRequest) => guideRequest?.id
          )
        : [],
      field_travel_coordinators: extFormData?.field_travel_coordinators || null,
      field_senior_sound_tech: extFormData?.field_senior_sound_tech || null,
      field_sound_techs: extFormData?.field_sound_techs || null,
      field_sound_assistant: extFormData?.field_sound_assistant || null,
      field_sound_tech_van: extFormData?.field_sound_tech_van,
      field_luggage_truck: extFormData?.field_luggage_truck,
      field_staffing_vehicle_notes:
        extFormData?.field_staffing_vehicle_notes || null,
      field_air_options: !!extFormData?.field_air_options?.length
        ? extFormData?.field_air_options?.map((airOption) => ({
            field_air_option_city: airOption?.field_air_option_city?.id || null,
            field_seats: airOption?.field_seats,
          }))
        : [],
      field_meeting_space: extFormData?.field_meeting_space || null,
      field_touring_the_day_they_arrive:
        extFormData?.field_touring_the_day_they_arrive || null,
      field_miscellaneous_notes: extFormData?.field_miscellaneous_notes || null,
      field_leader_attending_ext:
        extFormData?.field_leader_attending_ext || null,
      field_note: extFormData?.field_note || null,
    });
  };
  const createOrEditExtension = (allvalues, extension, eventId) => {
    // console.log("extensions", { allvalues });
    let reqMethod = "";
    if (extension === "Pre-Extension") {
      reqMethod = preExtId ? "patch" : "post";
    }
    if (extension === "Post-Extension") {
      reqMethod = postExtId ? "patch" : "post";
    }
    let payload = {
      data: {
        type: "extensions--extensions",
        attributes: {
          name: "ext",
          field_type: extension === "Pre-Extension" ? "pre" : "post",
          field_requested_start_date: !!allvalues?.field_requested_dates?.[0]
            ? allvalues?.field_requested_dates?.[0]?.format("YYYY-MM-DD")
            : null,
          field_requested_end_date: !!allvalues?.field_requested_dates?.[1]
            ? allvalues?.field_requested_dates?.[1]?.format("YYYY-MM-DD")
            : null,
          field_are_dates_flexible: allvalues?.field_are_dates_flexible,
          field_length_of_extension: allvalues?.field_length_of_extension,
          field_hotel_options: allvalues?.field_hotel_options.map(
            (hotelOptions) => ({
              ...hotelOptions,
              field_check_in: !!hotelOptions?.field_check_in
                ? hotelOptions?.field_check_in?.format("YYYY-MM-DD")
                : null,
              field_check_out: !!hotelOptions?.field_check_out
                ? hotelOptions?.field_check_out?.format("YYYY-MM-DD")
                : null,
            })
          ),
          field_pricing_option: {
            field_group_size_req: allvalues?.field_group_size_req || null,
            field_group_size_pricing:
              allvalues?.field_group_size_pricing || null,
            field_est_bus_count: allvalues?.field_est_bus_count || null,
            field_est_staff_count: allvalues?.field_est_staff_count || null,
            field_pricing_based_on: allvalues?.field_pricing_based_on || null,
            field_purpose_for_income:
              allvalues?.field_purpose_for_income || null,
            field_partner_income: allvalues?.field_partner_income || null,
            field_notes: allvalues?.field_notes || null,
            field_tip_budget: allvalues?.field_tip_budget || null,
            field_misc_items: allvalues?.field_misc_items || null,
            field_one_for_program: allvalues?.field_one_for_program || null,
          },
          field_additional_meal: allvalues?.field_additional_meal || null,
          field_travel_coordinators:
            allvalues?.field_travel_coordinators || null,
          field_senior_sound_tech: allvalues?.field_senior_sound_tech || null,
          field_sound_techs: allvalues?.field_sound_techs || null,
          field_sound_assistant: allvalues?.field_sound_assistant || null,
          field_sound_tech_van: allvalues?.field_sound_tech_van,
          field_luggage_truck: allvalues?.field_luggage_truck,
          field_staffing_vehicle_notes:
            allvalues?.field_staffing_vehicle_notes || null,
          field_air_options: allvalues?.field_air_options,
          field_meeting_space: allvalues?.field_meeting_space || null,
          field_touring_the_day_they_arrive:
            allvalues?.field_touring_the_day_they_arrive,
          field_leader_attending_ext:
            allvalues?.field_leader_attending_ext || null,
          field_free_vip_vehicle_driver:
            allvalues?.field_free_vip_vehicle_driver || null,
          field_additional_vip_vehicle_driver:
            allvalues?.field_additional_vip_vehicle_driver || null,
          field_miscellaneous_notes:
            allvalues?.field_miscellaneous_notes || null,
          field_note: allvalues?.field_note || null,
        },
        relationships: {
          field_extension_destination: allvalues?.field_extension_destination
            ? {
                data: {
                  type: "taxonomy_term--destination",
                  id: allvalues?.field_extension_destination,
                },
              }
            : null,
          field_tour_operator: allvalues?.field_tour_operator
            ? {
                data: {
                  type: "node--organization",
                  id: allvalues?.field_tour_operator,
                },
              }
            : null,
          field_extension_itinerary: allvalues?.field_extension_itinerary
            ? {
                data: {
                  type: "taxonomy_term--extension_itinerary",
                  id: allvalues?.field_extension_itinerary,
                },
              }
            : null,
          field_meal_type: !!allvalues?.field_meal_type
            ? {
                data: {
                  type: "taxonomy_term--meal_type",
                  id: allvalues?.field_meal_type,
                },
              }
            : null,
          field_guide_request: !!allvalues?.field_guide_request?.length
            ? {
                data: allvalues?.field_guide_request?.map((guideRequest) => ({
                  type: "taxonomy_term--guide_request",
                  id: guideRequest,
                })),
              }
            : null,
          field_event: {
            data: {
              type: "event--event",
              id: eventId,
            },
          },
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (reqMethod === "patch") {
      payload.data.id = extension === "Pre-Extension" ? preExtId : postExtId;
    }
    const extensionId = extension === "Pre-Extension" ? preExtId : postExtId;

    dispatch(createGroupDetailsExtension({ reqMethod, extensionId, payload }));
  };

  const onFinishHandler = (allvalues) => {
    // console.log("mainForm", { allvalues });
    if (currentTab === "Cruise") {
      const reqMethod = createId || editId ? "patch" : "post";
      let payload = {
        data: {
          type: "event--event",
          attributes: {
            name: allvalues?.field_event_name?.trim() || null,
            field_type: "Cruise",
            field_status: "lock",
            field_direct_event: false,
            field_group_detail_status:
              reqMethod === "post"
                ? "in_progress"
                : cruiseFormField?.field_group_detail_status?.key,
            field_time_in_cart: 0,
            field_event_code: allvalues?.field_event_code?.trim() || null,
            field_tollfree_extension: allvalues?.field_tollfree_phone
              ? allvalues?.field_tollfree_extension || null
              : null,
            field_tollfree_phone: allvalues?.field_tollfree_phone,
            field_event_details: allvalues?.field_event_details?.map(
              (eventDetails) => ({
                field_requested_start_dates: !!eventDetails
                  ?.field_requested_dates?.[0]
                  ? eventDetails?.field_requested_dates?.[0]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_requested_end_dates: !!eventDetails
                  ?.field_requested_dates?.[1]
                  ? eventDetails?.field_requested_dates?.[1]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_alternative_start_dates: !!eventDetails
                  ?.field_alternative_dates?.[0]
                  ? eventDetails?.field_alternative_dates?.[0]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_alternative_end_dates: !!eventDetails
                  ?.field_alternative_dates?.[1]
                  ? eventDetails?.field_alternative_dates?.[1]?.format(
                      "YYYY-MM-DD"
                    )
                  : null,
                field_are_dates_flexible:
                  eventDetails?.field_are_dates_flexible,
                field_cruise_line: eventDetails?.field_cruise_line,
                field_ship: eventDetails?.field_ship,
                field_cruise_itinerary: eventDetails?.field_cruise_itinerary,
                field_length_of_cruise: eventDetails?.field_length_of_cruise,
                field_url_address: eventDetails?.field_url_address,
                field_sailing: eventDetails?.field_sailing,
                field_event_exclusive: eventDetails?.field_event_exclusive,
              })
            ),
            field_pricing_option: allvalues?.field_pricing_option,
            field_hotel_options: allvalues?.field_hotel_options.map(
              (hotelOptions) => ({
                ...hotelOptions,
                field_check_in: !!hotelOptions?.field_check_in
                  ? hotelOptions?.field_check_in?.format("YYYY-MM-DD")
                  : null,
                field_check_out: !!hotelOptions?.field_check_out
                  ? hotelOptions?.field_check_out?.format("YYYY-MM-DD")
                  : null,
              })
            ),
            field_meeting_needs: allvalues?.field_meeting_needs?.map(
              (spaceNeeds) => ({
                ...spaceNeeds,
                field_break_out_rooms: !!spaceNeeds?.field_break_out_rooms
                  ?.length
                  ? spaceNeeds?.field_break_out_rooms?.join(",")
                  : null,
                field_dining_time: !!spaceNeeds?.field_dining_time
                  ? spaceNeeds?.field_dining_time?.format("hh:mm A")
                  : null,
              })
            ),
            field_shore_excursions: allvalues?.field_shore_excursions,
            field_note: allvalues?.field_note?.trim() || null,
            field_username: allvalues?.field_username || null,
            field_optional_post_departure_ins_included:
              allvalues?.field_optional_post_departure_ins_included,
            field_event_partner_flight_allowance:
              allvalues?.field_event_partner_flight_allowance || null,
            status: 1,
          },
          relationships: {
            field_event_partner: !!allvalues?.field_event_partner?.length
              ? {
                  data: allvalues?.field_event_partner?.map(
                    (eventPartnerId) => ({
                      type: "node--organization",
                      id: eventPartnerId,
                    })
                  ),
                }
              : null,
            field_non_married_roommate_policy:
              !!allvalues?.field_non_married_roommate_policy
                ? {
                    data: {
                      type: "taxonomy_term--non_married_roommate_policy",
                      id: allvalues?.field_non_married_roommate_policy,
                    },
                  }
                : null,
            modified_by: {
              data: {
                type: "user--user",
                id: userUuid,
              },
            },
          },
        },
      };
      const eventId = reqMethod === "patch" ? createId || editId : null;
      if (reqMethod === "patch") {
        payload.data.id = createId || editId;
      }
      dispatch(createGroupDetails({ reqMethod, eventId, payload }));
    }
    if (currentTab === "Pre-Extension" || currentTab === "Post-Extension") {
      createOrEditExtension(allvalues, currentTab, createId || editId);
    }
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
    let route = location.pathname;
    if ((editId || createId) && preExtId && postExtId) {
      route = `${route}?${editId ? "editId" : "creationId"}=${
        editId || createId
      }&preExtId=${preExtId}&postExtId=${postExtId}#${key}`;
    } else {
      if ((editId || createId) && (preExtId || postExtId)) {
        route = `${route}?${editId ? "editId" : "creationId"}=${
          editId || createId
        }&${preExtId ? "preExtId" : "postExtId"}=${
          preExtId || postExtId
        }#${key}`;
      } else {
        if (editId || createId) {
          route = `${route}?${editId ? "editId" : "creationId"}=${
            editId || createId
          }#${key}`;
        } else {
          route = `${route}#${key}`;
        }
      }
    }
    navigate(route);
  };

  const createRouteToNavigate = (eId, cId, preId, postId) => {
    let route = location.pathname;
    if ((eId || cId) && preId && postId) {
      return (route = `${route}?${eId ? "editId" : "creationId"}=${
        eId || cId
      }&preExtId=${preId}&postExtId=${postId}`);
    } else {
      if ((eId || cId) && (preId || postId)) {
        return (route = `${route}?${eId ? "editId" : "creationId"}=${
          eId || cId
        }&${preId ? "preExtId" : "postExtId"}=${preId || postId}`);
      } else {
        if (eId || cId) {
          return (route = `${route}?${eId ? "editId" : "creationId"}=${
            eId || cId
          }`);
        } else {
          return route;
        }
      }
    }
  };

  const routeToDetailsPage = (groupDetailId, preId, postId) => {
    return `${GroupDetails_DetailsRoute}?type=cruise&id=${groupDetailId}${
      preId ? `&preExtId=${preId}` : ""
    }${postId ? `&postExtId=${postId}` : ""}#Cruise`;
  };

  return (
    <>
      <Tabs
        activeKey={currentTab}
        className="details-tabs"
        onChange={(e, v) => {
          handleTabChange(e, v);
        }}
      >
        <Tabs.TabPane tab="Cruise" key="Cruise">
          <div className="basic_details pd-16 bg-white">
            <CruiseDetailsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <PricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <HotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <MeetingSpaceNeedsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <ShoreExcursionsForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <MiscellaneousForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <InitialContactPersonForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <NotesForm
              onFinishHandler={onFinishHandler}
              form={form1}
              isLoading={loadingGroupDetailsData}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <>
              <PlusOutlined /> Add Pre-Extension
            </>
          }
          key="Pre-Extension"
          disabled={createId || editId ? false : true}
        >
          <div className="basic_details pd-16 bg-white">
            <DatesAndItineryForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <HotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <MealsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <PrePricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <StaffAndVehicleForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <AirOptionsForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <PrePostMiscellaneousForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <NotesForm
              onFinishHandler={onFinishHandler}
              form={form2}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <PlusOutlined />
              Add Post-Extension
            </span>
          }
          key="Post-Extension"
          disabled={createId || editId ? false : true}
        >
          <div className="basic_details pd-16 bg-white">
            <DatesAndItineryForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <HotelOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <MealsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <PrePricingOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <StaffAndVehicleForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <AirOptionsForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>

          <div className="basic_details pd-16 bg-white">
            <PrePostMiscellaneousForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
          <div className="basic_details pd-16 bg-white">
            <NotesForm
              onFinishHandler={onFinishHandler}
              form={form3}
              isLoading={loadingGroupDetailsExtensionData}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Button
        type="primary"
        // className="position_button"
        style={{ position: "absolute", right: "60px", top: "255px" }}
        loading={
          loadingGroupDetailsCreation || loadingGroupDetailsExtensionCreation
        }
        onClick={() => {
          dispatch(changeTabState());
          if (currentTab === "Cruise") {
            form1.submit();
          }
          if (currentTab === "Pre-Extension") {
            form2.submit();
          }
          if (currentTab === "Post-Extension") {
            form3.submit();
          }
        }}
      >
        Save & Add Extension
      </Button>
    </>
  );
};

export default CreateOrEditCruiseTabsContainer;
