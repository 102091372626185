import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Row, Select, Space, message } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import React, { useEffect, useRef, useState } from "react";
import {
  addDescriptionApi,
  getDescriptionTypeApi,
} from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
const DescriptionsListingDrawer = ({
  eventId,
  editDescription,
  editDescriptionFn,
  open,
  setOpen,
  setEditDescription,
  getDescriptionListing,
  loading,
  setLoading,
}) => {
  const [descriptionTypes, setDescriptionTypes] = useState([]);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [editorInitData, setEditorInitData] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const [editor, setEditor] = useState(null);
  const editorRef = useRef(null);

  const paramId = searchParams.get("id");
  const showDrawer = () => {
    setOpen(true);
    setEditDescription();
    form.setFieldsValue({
      field_info_text: editor?.setData(""),
    });
    if (editorRef.current) {
      form.setFieldsValue({
        field_info_text: editorRef.current.editor.setData(""),
      });
    }
  };
  const onClose = () => {
    setOpen(false);
    setEditorInitData("");
    setEditDescription();
  };
  useEffect(() => {
    if (editDescription) {
      setOpen(true);
      setTimeout(() => {
        form.setFieldsValue({
          field_description_type: editDescription?.documentId,
          // field_info_text: editor?.setData(editDescription?.value || ""),
        });
        if (editorRef.current) {
          editorRef.current.editor.setData(editDescription?.value || "");
        }
      }, 200);
    }
  }, [editDescription, editorInstance]);

  const addDescription = async (payload) => {
    const response = await addDescriptionApi(payload);
    if (response && response.status) {
      message.success("Description Added Successfully");
      getDescriptionListing();
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    onClose();
    setLoading(false);
  };
  const validateElementFields = (errorField, errorMessage) => {
    form.setFields([
      {
        name: errorField,
        errors: errorMessage ? [errorMessage] : [],
      },
    ]);
  };
  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  const onFinishHandler = (values) => {
    if (!editorInitData) {
      validateElementFields("field_info_text", "Please Enter Info Text");
    } else {
      const selectedDescription = descriptionTypes.filter((e) => {
        if (e.value === values.field_description_type) {
          return e.value;
        }
      });
      const field_info_text = editorRef.current?.editor.getData();
      console.log("editing from here++++", field_info_text)
      const payload = {
        parentEntityUUID: paramId,
        documentTypeID: selectedDescription[0].value,
        description: {
          value: field_info_text,
          text_format: "basic_html",
        },
      };
      if (editDescription) {
        values.field_info_text = field_info_text;
        editDescriptionFn(values, selectedDescription);
        setLoading(true);
      } else {
        addDescription(payload);
        setLoading(true);
      }
    }
  };

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };
  const getDescriptionType = async () => {
    const response = await getDescriptionTypeApi();
    if (response && response?.status) {
      setDescriptionTypes(
        response?.data?.data.map((e) => {
          return { value: e.id, label: e.name };
        })
      );
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    getDescriptionType();
  }, []);
  return (
    <>
      {permissionChecker("add/edit event descriptions") ? <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          Add New
      </Button> : null}
      <Drawer
        title={editDescription ? "Edit Description" : "Add Description"}
        placement="right"
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => onFinishHandler(values)}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="field_description_type"
                label="Description Type"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Select Description Type",
                  },
                ]}
              >
                <Select
                  placeholder="Description Type"
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={descriptionTypes}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="textarea_row">
                <Form.Item
                  className="ckeitor-field-wrapper"
                  name="field_info_text"
                  label="Info Text"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Info Text",
                    },
                  ]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    ref={editorRef}
                    config={editorConfig}
                    data={editorInitData}
                    onReady={(editor) => {
                      setEditorInstance(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorInitData(data);
                      form.setFieldsValue({ field_info_text: data });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default DescriptionsListingDrawer;
