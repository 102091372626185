import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Table, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteItineraryApi,
  getItineraryListApi,
} from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import {
  AddonDetailsItineraryRoute,
  EventDetailsItineraryRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const authData = JSON.parse(localStorage.getItem("authorization"));

const columns = [
  {
    title: "Day",
    dataIndex: "timeliner",
  },

  {
    title: "Location",
    dataIndex: "location",
  },

  {
    title: "Start Date",
    dataIndex: "start_date",
  },
  {
    title: "End Date",
    dataIndex: "end_date",
  },
  {
    title: "Arrive",
    dataIndex: "arrive",
  },

  {
    title: "Depart",
    dataIndex: "depart",
  },
  { title: "Actions", dataIndex: "actions" },
];

const ItineraryListing = ({ eventId, itineraryType, addonId }) => {
  const navigate = useNavigate();
  const [searchParamas, setSearchParams] = useSearchParams();
  const [itineraryData, setItineraryData] = useState([]);
  const [loadingItinerary, setLoadingItinerary] = useState(false);
  const [itineraryDeleted, setItineraryDeleted] = useState(false);

  useEffect(() => {
    getItineraryListing(itineraryType === "event" ? eventId : addonId);
  }, [addonId, eventId, itineraryDeleted]);

  const deleteItinerary = async (itineraryIndex) => {
    const payload = {
      eventUUID: itineraryType === "event" ? eventId : addonId,
      itinerariesIndex: [itineraryIndex],
    };
    const res = await deleteItineraryApi(payload);
    if (res.status) {
      message.success("Itinerary Deleted Successfully");
      setItineraryDeleted(!itineraryDeleted);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const onDeleteHandler = (itineraryIndex) => {
    if (itineraryIndex || itineraryIndex === 0) {
      Modal.confirm({
        title: "Are you sure you want to delete?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteItinerary(itineraryIndex);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const data = itineraryData?.length
    ? itineraryData.map((itineary, index) => ({
        timeliner: (
          <div className="day_round">
            <p>{itineary?.day_number ? itineary?.day_number : ""}</p>
          </div>
        ),
        start_date: itineary?.start_date
          ? moment(itineary?.start_date).format("M/D/YYYY")
          : null,
        end_date: itineary?.end_date
          ? moment(itineary?.end_date).format("M/D/YYYY")
          : null,
        location: itineary?.location_name ? itineary?.location_name : "",
        arrive: itineary?.arrive ? itineary?.arrive : "",
        depart: itineary?.depart ? itineary?.depart : "",
        actions: (
          <div className="action_flex action_end">
            {" "}
            {permissionChecker("add/edit event itinerary") ? (
              <EditOutlined
                onClick={() => {
                  navigate(
                    itineraryType === "event"
                      ? `${EventDetailsItineraryRoute}?id=${eventId}`
                      : `${AddonDetailsItineraryRoute}?id=${eventId}&addonId=${addonId}`
                  );
                }}
                className="action_edit"
                style={{
                  fontSize: "18px",
                  paddingTop: "11px",
                  paddingBottom: "14px",
                }}
              />
            ) : null}
          {permissionChecker("delete event itineraries")  ?  <DeleteOutlined
              onClick={(e) => {
                e.preventDefault();
                onDeleteHandler(index);
              }}
              className="action_delete delete_btn"
            /> : null}
          </div>
        ),
      }))
    : [];

  const getItineraryListing = async (id) => {
    setLoadingItinerary(true);
    const response = await getItineraryListApi(id);
    if (response?.status) {
      setItineraryData(response?.data?.data);
      setLoadingItinerary(false);
    } else {
      setItineraryData([]);
      setLoadingItinerary(false);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>Itineraries</h4>
        <div className="button_flex">
          {permissionChecker("add/edit event itinerary") ? (
            <Link
              to={
                itineraryType === "event"
                  ? `${EventDetailsItineraryRoute}?id=${eventId}`
                  : `${AddonDetailsItineraryRoute}?id=${eventId}&addonId=${addonId}`
              }
            >
              {
                <Button type="primary">
                  <PlusOutlined /> Add
                </Button>
              }
            </Link>
          ) : null}
        </div>
      </div>

      <div className="interior_table">
        <Divider className="global_divider" />
        <Table
          className="itenary_table"
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          loading={{ spinning: loadingItinerary, indicator: <DataLoader /> }}
        />
      </div>
    </div>
  );
};

export default ItineraryListing;
