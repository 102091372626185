import { Table, Divider, Checkbox, Switch, Input } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: "Type",
    dataIndex: "type",
  },

  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Active?",
    dataIndex: "active",
  },
];
const data = [
  {
    key: "1",
    type: "Cancellation Fee	",
    name: "Cancel at 50% Fee",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },

  {
    key: "2",
    type: "Cancellation Fee	",
    name: "Cancel at Administrative Fee	",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },

  {
    key: "3",
    type: "Cancellation Fee	",
    name: "Cancel at Deposit Fee",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },

  {
    key: "4",
    type: "Government Taxes/Fees",
    name: "Government Taxes/Fees",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },

  {
    key: "5",
    type: "International Shipping Fee",
    name: "Cancel at 50% Fee",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch defaultChecked onChange={onChange} />
      </>
    ),
  },

  {
    key: "6",
    type: "Shipping-Canada/Mexico",
    name: "Book Online Discount	",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },

  {
    key: "7",
    type: "Item Change Fee",
    name: "Item Change Fee",
    master_discount: "",
    discount_percent: "",
    active: (
      <>
        <Switch onChange={onChange} />
      </>
    ),
  },
];
const Fees_edit = () => (
  <div>
    {/* <div className='requests_title'>
      <h4>Fees (15)</h4>
      </div>
      <Divider style={{ marginTop: '0' }} /> */}
    <Table
      columns={columns}
      dataSource={data}
      size="middle"
      pagination={false}
    />
  </div>
);

export default Fees_edit;
