import {
  Table,
  Divider,
  Select,
  Form,
  Modal,
  Input,
  Button,
  DatePicker,
  TimePicker,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";
import { editTaskActivityApi } from "../../../../apis/contactApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { useDispatch } from "react-redux";
import { taskActivityUpdater } from "../../../../redux/features/contactFeatures/contactSlice";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
  TIME_FORMAT,
} from "../../../../utils/constants";
import { convertDateTimeToISO } from "../../../../utils/DateTimeFormater";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Date/Time",
    dataIndex: "date_time",
  },

  {
    title: "Staff Member",
    dataIndex: "staff_member",
  },

  {
    title: "Description",
    dataIndex: "description",
  },
];

const TaskActivity = ({ activityDetails, loadingSingleActivity }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [addActivityLoading, setAddActivityLoading] = useState(false);
  let { field_task_activity } = activityDetails;
  const [form] = Form.useForm();
  const activityId = searchParams.get("activityId");
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const { uuid, user_id } = authorization;
  // const DATE_FORMAT = "MM/DD/YYYY";
  // const TIME_FORMAT = "hh:mm A";

  const data = field_task_activity?.length
    ? field_task_activity?.map((item, index) => ({
        key: index,
        type: item?.type,
        date: item?.date,
        date_time: (
          <>
            <div>
              <span className="">
                {item?.date_time
                  ? moment(item?.date_time).format(DATE_FORMAT)
                  : ""}{" "}
                {item?.date_time
                  ? moment(item?.date_time).format(TIME_FORMAT)
                  : ""}{" "}
              </span>
              <p>
                <span className="span_text">
                  {item?.date_time
                    ? moment(item?.date_time).from(moment())
                    : ""}
                </span>
              </p>
            </div>
          </>
        ),
        duration: "00:00:02",
        staff_member: item?.created_by_name,
        description: item?.comment || "",
      }))
    : [];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const { TextArea } = Input;

  const addTaskActivity = async (payload) => {
    const addTaskActivity = await editTaskActivityApi({
      payload,
      activityId,
    });
    if (addTaskActivity.status) {
      dispatch(taskActivityUpdater(addTaskActivity?.data));
      setAddActivityLoading(false);
      handleOk();
      form.resetFields();
      message.success("Task Activity Added");
    } else {
      setAddActivityLoading(false);
      message.error(addTaskActivity?.data?.message || SOMETHING_WENT_WRONG);
    }
  };

  const finishHandler = (values) => {
    setAddActivityLoading(true);
    const dateIsvalid = values["activity_date"] && values["activity_time"];
    const newTaskActivity = {
      type: values?.activity_type,
      date_time:
        dateIsvalid &&
        convertDateTimeToISO(values["activity_date"], values["activity_time"]),
      created_by: user_id, // current loggedIn user drupal_internal__uid will go here
      comment: values?.comments,
    };

    field_task_activity = [...field_task_activity, newTaskActivity];

    const payload = {
      data: {
        type: "activity--activity",
        id: activityId,
        attributes: {
          field_task_activity: [
            ...field_task_activity?.map(
              ({ type, date_time, created_by, comment }) => ({
                type,
                date_time,
                created_by,
                comment,
              })
            ),
          ],
        },
        relationships: {
          field_last_modified_by: uuid && {
            data: {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
        },
      },
    };
    addTaskActivity(payload);
  };

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Task Activity</h4>

        <span>
          {permissionChecker("add task activity") ? (
            <Button type="primary" onClick={showModal}>
              <PlusOutlined /> Add
            </Button>
          ) : null}

          <Modal
            footer={
              <>
                {" "}
                <Link to="">
                  <Button
                    type="primary"
                    loading={addActivityLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </Link>
              </>
            }
            title={
              <>
                <span className="span_bold">Add Activity</span>
              </>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 6,
                xl: 6,
                xxl: 6,
              }}
              wrapperCol={{
                sm: 24,
                md: 14,
                lg: 14,
                xl: 16,
                xxl: 16,
              }}
              layout="horizontal"
              form={form}
              onFinish={finishHandler}
            >
              <Form.Item
                className="asterick-align"
                label="Activity Type"
                name="activity_type"
                rules={[
                  {
                    required: true,
                    message: "Please select activity type",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Comment">Comment</Select.Option>
                  <Select.Option value="Update">Update</Select.Option>
                  <Select.Option value="Status Change">
                    Status Change
                  </Select.Option>
                  <Select.Option value="Execute">Execute</Select.Option>
                </Select>
              </Form.Item>

              <div className="form_modal_align">
                <div className="form_label_one">
                  <Form.Item
                    className="asterick-align"
                    label="Activity Time"
                    name="activity_date"
                    rules={[
                      {
                        required: true,
                        message: "Date is required",
                      },
                    ]}
                  >
                    <DatePicker format={DATE_FORMAT} />
                  </Form.Item>
                </div>
                <div className="form_label">
                  <Form.Item
                    className="asterick-align"
                    name="activity_time"
                    rules={[
                      {
                        required: true,
                        message: "Time is required",
                      },
                    ]}
                  >
                    <TimePicker use12Hours format={TIME_FORMAT} />
                  </Form.Item>
                </div>
              </div>

              <div className="textarea_row">
                <Form.Item
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: "Comments are required",
                    },
                  ]}
                >
                  <TextArea showCount maxLength={500} rows={4} />
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </span>
      </div>
      <Divider className="global_divider" />
      <Table
        className="task_activity_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={{ spinning: loadingSingleActivity, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default TaskActivity;
