import { Table } from "antd";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Discount Amount",
    dataIndex: "master_discount",
  },
  {
    title: "Discount Percent",
    dataIndex: "discount_percent",
  },

  {
    title: "Uses",
    dataIndex: "used",
  },
];

const CategoryDiscountListing = ({ loadingDiscountList, DiscountListData }) => {
  const data = DiscountListData.length
    ? DiscountListData.map((discount) => ({
        key: discount?.discount_uuid,
        type: discount?.type || "",
        name: discount?.name || "",
        master_discount: discount?.discount_amount
          ? `$${addCommasInPricing(discount?.discount_amount)}`
          : "",
        discount_percent: discount?.discount_percent
          ? `${discount?.discount_percent}%`
          : "",

        used: discount?.used || "",
      }))
    : [];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        loading={{ spinning: loadingDiscountList, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default CategoryDiscountListing;
