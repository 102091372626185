import { Select, Skeleton, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ContactDetailRoute,
  ReservationDetailRoute,
} from "../../../url-routes/urlRoutes";
import { createFilterTags } from "../../../others/util/filterTagsCreator";
import {
  addCommasInPricing,
  decodeContent,
  isJSONString,
} from "../../../utils/contentParser";
import { getPrimaryTravelerName } from "../../../others/util/commonFunctions";
import { permissionChecker } from "../../../utils/permisssionChecker";

const Option = Select.Option;

const columns = [
  {
    title: "Reservation",
    dataIndex: "reservation",
    render: (value, recordObj) => (
      <>
        {permissionChecker("view reservations") ? (
          <Link to={`${ReservationDetailRoute}?id=${recordObj.uuid}`}>
            <span>{value}</span>
          </Link>
        ) : (
          <span>{value}</span>
        )}
      </>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (value, recordObj) => (
      <>
        {permissionChecker("view reservations") ? (
          <Link to={`${ReservationDetailRoute}?id=${recordObj.uuid}`}>
            <span>{decodeContent(value)}</span>
          </Link>
        ) : (
          <span>{decodeContent(value)}</span>
        )}
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Primary Traveler",
    dataIndex: "primary_traveler",
  },
  {
    title: "Total	",
    dataIndex: "total",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },

  {
    title: "Balance",
    dataIndex: "balance",
    render: (value) => `$${addCommasInPricing(value?.toString())}`,
  },

  {
    title: "Created",
    dataIndex: "created",
  },

  {
    title: "Modified",
    dataIndex: "modified",
  },
];

const ReservationListing = ({
  allReservationData,
  isLoading,
  urlParamsObject,
  setUrlParamsObject,
  eventCount,
  totalItems,
}) => {
  const { isFilterApplied, searchFilters } = useSelector(
    (state) => state.reservation
  );
  const navigate = useNavigate()

  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu reservations");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  const handleSorting = (value) => {
    if (value === "recently_read") {
      setUrlParamsObject({
        count: 10,
        order: "recently_read",
        page: 1,
        tab: urlParamsObject?.tab,
      });
    } else {
      setUrlParamsObject({ ...urlParamsObject, order: value });
    }
  };
  const data = !!allReservationData?.length
    ? allReservationData?.map((reservationData, index) => {
        return {
          key: index + 1 || "",
          uuid: reservationData?.uuid || "",
          reservation: reservationData?.id || "",
          name: reservationData?.name || "",
          status: reservationData?.reservation_status || "",
          primary_traveler: isJSONString(
            reservationData?.contact_field || reservationData?.contact
          ) ? (
            <>
              {permissionChecker("view contact") ? (
                <Link
                  to={`${ContactDetailRoute}?id=${
                    JSON.parse(
                      reservationData?.contact_field || reservationData?.contact
                    )?.uuid
                  }`}
                >
                  {getPrimaryTravelerName(
                    JSON.parse(
                      reservationData?.contact_field || reservationData?.contact
                    )
                  )}
                </Link>
              ) : (
                <span>
                  {getPrimaryTravelerName(
                    JSON.parse(
                      reservationData?.contact_field || reservationData?.contact
                    )
                  )}
                </span>
              )}
            </>
          ) : (
            ""
          ),
          total: reservationData?.total || "0.00",
          balance: reservationData?.balance || "0.00",
          created: reservationData?.created || "",
          modified: reservationData?.changed || "",
        };
      })
    : [];

  return (
    <>
      <div className="table_filter">
        {isFilterApplied && (
          <div className="filter-applied--wrapper">
            <span className="filter-title">Filter(s) Applied:</span>
            {createFilterTags(searchFilters)}
          </div>
        )}
        {isLoading ? (
          <Skeleton.Input size="medium" style={{ width: "250px" }} />
        ) : (
          <>
            <span>Sort by :</span>
            <Select
              value={urlParamsObject.order}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: 165,
                marginLeft: "10px",
              }}
              onChange={(e) => {
                handleSorting(e);
              }}
            >
              <Option value="recently_read" label="Recently Viewed">Recently Viewed</Option>
              <Option value="ASC" label="Oldest">Oldest</Option>
              <Option value="DESC" label="Newest">Newest</Option>
            </Select>
          </>
        )}
      </div>
      <Table
        className="table_listing table_tour intake_width"
        columns={columns}
        dataSource={data}
        loading={{ spinning: isLoading, size: "large" }}
        pagination={
          urlParamsObject?.order === "recently_read"
            ? false
            : {
                current: Number(urlParamsObject?.page),
                pageSize: urlParamsObject?.count,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                total: Number(totalItems),
                position: ["topRight", "bottomRight"],
                onChange: (pageNo, pageSize) => {
                  setUrlParamsObject({
                    ...urlParamsObject,
                    // tab: "Tour",
                    page: pageNo,
                    count: pageSize,
                  });
                },
              }
        }
      ></Table>
    </>
  );
};

export default ReservationListing;
