import { Token, customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";

export const getEmailTemplatesListApi = (payload) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/node/email_template`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const getEmailTemplateDataApi = (id) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/node/email_template/${id}`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const editEmailTemplateApi = (data) => {
  return drupalAxiosRequest(
    "patch",
    `/api/v1/node/email_template/${data?.templateId}`,
    customRequestHeader({ token: Token }),
    data?.payload
  );
};
