import { Table, Button, Divider, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import {
  AddonCategoryDetailsInventoryDetailRoute,
  EventCategoryDetailsInventoryDetailRoute,
  ReservationItemDetailRoute,
} from "../../../../url-routes/urlRoutes";
import { rollbackReservationItemApi } from "../../../../apis/reservationApi";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function Reservationitem_details(props) {
  const { data } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemData, setItemData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const status = data?.reservation_item?.reservation_status;
  const itemStatus = data?.reservation_item?.field_itemstatus;
  const reservationId = searchParams.get("id");
  const itemId = searchParams.get("item-id");
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const newItemData = [
        {
          key: "1",
          label: "Item Code",
          input: (
            <>
              {data?.reservation_item_inventory?.uuid ? (
                <Link
                  to={data?.reservation_item_category?.category_event?.is_primary_item
                    ? `${EventCategoryDetailsInventoryDetailRoute}?inventoryId=${data?.reservation_item_inventory?.uuid}&id=${data?.event_datail?.uuid}&categoryId=${data?.reservation_item_category?.uuid}`
                    : `${AddonCategoryDetailsInventoryDetailRoute}?inventoryId=${data?.reservation_item_inventory?.uuid}&id=${data?.event_datail?.uuid}&addonId=${data?.reservation_item_category?.category_event?.uuid}&categoryId=${data?.reservation_item_category?.uuid}`}
                >
                  {data?.reservation_item_inventory?.field_item_number}
                </Link>
              ) : (
                <span>{data?.reservation_item_inventory?.field_item_number}</span>
              )}
            </>
          ),
        },
        {
          key: "2",
          label: "Item Name",
          input: data?.reservation_item_inventory?.field_item_name,
        },
        {
          key: "3",
          label: "Item Status",
          input: data?.reservation_item?.field_itemstatus_label,
        },
        {
          key: "5",
          label: "Cruise Booking Number",
          input: data?.reservation_item?.field_cruise_bookno,
        },
      ];

      if (data?.reservation_item?.field_itemstatus==='purchased') {
        newItemData.push({
          key: "6",
          label: "Confirmation Number",
          input: data?.reservation_item?.field_comments[0]?.value,
        });
      }
      if(!['purchased',"quote"].includes(data?.reservation_item?.field_itemstatus)){
          newItemData.push({
            key:"7",
            label:"Comment",
            input: data?.reservation_item?.field_comments?.[0]?.value,
          })
      }

      setItemData(newItemData);
    }
  }, [data]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const rollbackReservation = async () => {
    const payload = {
      data: {
        item_uuid: itemId,
      },
    };
    const response = await rollbackReservationItemApi(payload);

    if (response?.status) {
      if (response?.data?.status) {
        message.success("Reservation Rollback Successful");
        setIsModalOpen(false);
        navigate(
          `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${response?.data?.data?.uuid}`
        );
      } else {
        message.error(response?.data?.data);
      }
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleOk = () => {
    rollbackReservation();
    setLoading(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="partnership_inform">
        <h4>Item Details</h4>

        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <span>
            <Link
              to={`/edit-reservation-item?id=${reservationId}&item-id=${itemId}`}
            >
              {" "}
              <Button type="y" icon={<EditOutlined />} />
            </Link>
          </span>

          {status === "revise" &&
          itemStatus !== "cancelled" &&
          data?.reservation_item?.field_parent_reservation_item ? (
            <Button onClick={showModal}>Rollback</Button>
          ) : null}

          <Modal
            footer={[
              <Button
                type="primary"
                onClick={() => handleOk()}
                loading={loading}
              >
                Confirm
              </Button>,
              <Button onClick={() => handleCancel()}>Cancel</Button>,
            ]}
            title="Rollback Confirmation"
            open={isModalOpen}
            onCancel={handleCancel}
          >
            <div className="revise-btn_modal">
              <p>
                Please confirm that you want to rollback reservation item{" "}
                <span>{data?.reservation_item?.name}</span> to its previous
                revision.
              </p>
            </div>
          </Modal>
        </div>
      </div>
      <Divider className="global_divider" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={itemData}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Reservationitem_details;
