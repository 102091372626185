import {
  EditOutlined,
  FileOutlined,
  DeleteOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Radio, Skeleton } from "antd";
import { Dropdown, Menu, Space } from "antd";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../url-routes/urlRoutes";

import React, { useState } from "react";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

function HeaderAccomodationTypeDetail({
  accommodationTypeData,
  loading,
  addonId,
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        {/* <h3><ArrowLeftOutlined className="back-icon-profile" />   <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Category Group (PS PS Pinnacle Suite)  </span>   <span className='span_serial' style={{ fontSize: '14px', lineHeight: '22px', fontWeight: '400', fontWeight: '500', fontFamily: "'Poppins', sans-serif", color: '#00000073', opacity: '0.6' }} >Gaither Homecoming 2023 Caribbean Cruise (31554)</span>
        </h3> */}

        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => navigate(-1)}
          />
          <div className="">
            {loading ? (
              <Skeleton.Input active={true} style={{ width: "250px" }} />
            ) : (
              <>
                <p style={{ margin: "0" }}>
                  {accommodationTypeData?.name || ""}{" "}
                  {accommodationTypeData?.drupal_internal__id
                    ? `(${accommodationTypeData?.drupal_internal__id || ""})`
                    : null}
                </p>
                {permissionChecker("view events") ? <Link
                  to={
                    addonId
                      ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                      : `${EventDetailsRoute}?id=${eventId}`
                  }
                >
                  <p className="header_smalltext cta_hover_color">
                    {accommodationTypeData?.field_event?.name || ""}
                    {accommodationTypeData?.field_event?.drupal_internal__id
                      ? `(${accommodationTypeData?.field_event?.drupal_internal__id})`
                      : null}
                  </p>
                </Link> : null}
              </>
            )}
          </div>
        </h3>
      </div>
    </div>
  );
}

export default HeaderAccomodationTypeDetail;
