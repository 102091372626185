import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  createRequestsApi,
  getAllEventsListApi,
  marketingSourceApi,
  updateRequestApi,
} from "../../../../apis/contactApi";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import "./RequestForm.less";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";
import { reservationEventsListApi } from "../../../../apis/reservationApi";
import { decodeContent } from "../../../../utils/contentParser";

const RequestForm = ({
  setGetRequestResponse,
  onClose,
  open,
  showDrawer,
  form,
  requestId,
  setIsMarketingCodeSelected,
  isMarketingCodeSelected,
  setIsEventSelected,
  isEventSelected,
  handleChangeMarketing,
  isFormDataLoading,
  marketingCode,
  eventList,
  setEventList,
  loadingMarketingCode,
  marketingDetail,
  setMarketingDetail,
  setMarketingCode,
  getMarketingDropdownDataBasedOnEvent,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventListLoading, setEventListLoading] = useState(false);
  const [requestType, setRequestType] = useState([]);
  const [loadingRequestType, setLoadingRequestType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditRequest = authData?.permissions?.includes(
    "add/edit contact request"
  );
  const contactId = searchParams.get("id");
  const field_status = [
    { id: 1, status: "New", value: "new" },
    { id: 2, status: "Complete", value: "complete" },
  ];

  const getEventList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    setEventListLoading(true);
    const response = await reservationEventsListApi({ reqHeaders });
    if (response.status) {
      setEventList(response?.data);
    } else {
      setEventList([]);
    }
    setEventListLoading(false);
  };

  useEffect(() => {
    getEventList();
    getTaxonomyList(
      "request_type_vocab",
      setRequestType,
      setLoadingRequestType
    );
  }, []);

  const onFinishHandler = (values) => {
    const {
      request_date,
      request_type,
      event,
      quantity,
      marketing_code,
      marketing_detail,
      request_status,
      complete_date,
    } = values;
    const payload = {
      data: {
        type: "contact_request--contact_request",

        attributes: {
          name: "Request 146",
          field_request_date: request_date
            ? moment(request_date).format("YYYY-MM-DD")
            : null,
          field_quantity: quantity,
          field_Request_status: request_status,
          field_complete_date: complete_date
            ? moment(complete_date).format("YYYY-MM-DD")
            : null,
        },
        relationships: {
          field_request_type: {
            data: {
              type: "taxonomy_term--request_type_vocab",
              id: request_type,
            },
          },
          field_contact: {
            data: {
              type: "contact--contact",
              id: contactId,
            },
          },
        },
      },
    };
    if (event) {
      payload.data.relationships.field_event = {
        data: {
          type: "event--event",
          id: event,
        },
      };
    }
    if (marketing_code) {
      payload.data.relationships.field_marketing_channel = {
        data: {
          type: "channel--channel",
          id: marketing_code,
        },
      };
    }
    if (marketing_detail) {
      payload.data.relationships.field_marketing_detail = {
        data: {
          type: "detail--detail",
          id: marketing_detail,
        },
      };
    }
    setIsLoading(true);
    if (open === "Add") {
      createContactRequest(payload);
    } else if (open === "Edit") {
      payload.data.id = requestId;
      updateContactRequest(requestId, payload);
    }
  };

  const createContactRequest = async (payload) => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await createRequestsApi({ reqHeaders, payload });
    if (response.status) {
      setIsLoading(false);
      onClose();

      setGetRequestResponse(response);

      message.success("Request Created Successfully");
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setIsLoading(false);
      onClose();
    }
  };
  const updateContactRequest = async (payload, id) => {
    const response = await updateRequestApi(id, payload);
    if (response.status) {
      setIsLoading(false);
      onClose();

      setGetRequestResponse(response);
      message.success("Request Updated Successfully");
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setIsLoading(true);
    }
  };

  return (
    <>
      {permissionChecker("add/edit request") ? (
        <Button type="primary" onClick={() => showDrawer("Add")}>
          Add
        </Button>
      ) : null}
      <Drawer
        title="Contact Request"
        maxWdth={420}
        onClose={onClose}
        open={open && open.length ? true : false}
        bodyStyle={{ paddingBottom: 32 }}
        extra={
          <Space>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
              loading={isLoading}
            >
              Save
            </Button>
          </Space>
        }
      >
        {" "}
        <Spin spinning={isFormDataLoading} size="large">
          <Form layout="vertical" onFinish={onFinishHandler} form={form}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Request Date"
                  name="request_date"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select request date",
                    },
                  ]}
                >
                  <DatePicker format={"MM/DD/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Request Type"
                  name="request_type"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select request type",
                    },
                  ]}
                >
                  <Select
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={requestType?.map((req) => ({
                      key: req?.id,
                      value: req?.id,
                      label: req?.name,
                    }))}
                    loading={loadingRequestType}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Event"
                  name="event"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Select
                    showSearch
                    showArrow
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={eventList?.map((event) => ({
                      key: event?.uuid,
                      value: event?.uuid,
                      label: decodeContent(event?.name) || "",
                    }))}
                    onClear={() => {
                      setMarketingCode([]);
                      setMarketingDetail([]);
                      form.setFieldValue("marketing_code", null);
                      form.setFieldValue("marketing_detail", null);
                      setIsMarketingCodeSelected(false);
                      setIsEventSelected(false);
                    }}
                    onSelect={(e) => {
                      form.setFieldValue("marketing_code", null);
                      form.setFieldValue("marketing_detail", null);
                      setIsEventSelected(true);
                      getMarketingDropdownDataBasedOnEvent(e);
                    }}
                    loading={eventListLoading}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Input
                    type="number"
                    min={0}
                    autoComplete="nope"
                    pattern="/^[0-9\b]+$/"
                    onKeyDown={(evt) => {
                      if (
                        evt.key === "e" ||
                        evt.key === "-" ||
                        evt.key === "+" ||
                        evt.key === "." ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowDown"
                      ) {
                        evt.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Marketing Channel"
                  name="marketing_code"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Select
                    // onChange={handleChangeMarketing}
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    disabled={!isEventSelected}
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={marketingCode?.map((mCode) => ({
                      key: mCode?.id,
                      value: mCode?.uuid,
                      label: mCode?.name,
                    }))}
                    loading={loadingMarketingCode}
                    allowClear
                    onClear={() => {
                      setMarketingDetail([]);
                      setIsMarketingCodeSelected(false);
                      form.setFieldValue("marketing_detail", null);
                    }}
                    onSelect={(e) => {
                      handleChangeMarketing();
                      form.setFieldValue("marketing_detail", null);
                      setMarketingDetail(
                        marketingCode.find((details) => e === details.uuid)
                          ?.details
                      );
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Marketing Detail"
                  name="marketing_detail"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Select
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    disabled={!isMarketingCodeSelected}
                    allowClear
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={marketingDetail?.map((mDetail) => ({
                      key: mDetail?.id,
                      value: mDetail?.uuid,
                      label: mDetail?.name,
                    }))}
                    // loading={loadingMarketingDetail}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Request Status"
                  name="request_status"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Select
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={field_status?.map((stat) => ({
                      key: stat?.id,
                      value: stat?.value,
                      label: stat?.status,
                    }))}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Complete Date"
                  name="complete_date"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <DatePicker format={"MM/DD/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default RequestForm;
