import { EditOutlined, FileOutlined, DeleteOutlined, ArrowLeftOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { Dropdown, Menu, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const men = (
    <Menu
        items={[
            {
                label: 'Confirmation',
                key: '0',
            },
            {
                label: 'Query Test Email',
                key: '1',
            },
            {
                label: 'Terms and Conditions',
                key: '2',
            },

            {
                label: 'Reservation Communication',
                key: '2',
            },
        ]}
    />
);


const menu = (
    <Menu
        items={[
            {
                label: <a href="#">Edit</a>,
                key: '0',
            },
            {
                type: 'divider',
            },
            {
                label: <a href="#">File</a>,
                key: '1',
            },
            {
                type: 'divider',
            },
            {
                label: 'Delete',
                key: '2',
            },
            {
                type: 'divider',
            },
            {
                label: 'Find Duplicates',
                key: '3',
            },
        ]}
    />
);

function Breadcrumb_resguestviewdetail() {
    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };
    return (

        <div className="breadcrumb_content">
            <div className='breadcrumb_heading view_header' >
                {/* <h3><ArrowLeftOutlined className="back-icon-profile" />
                    <span  >
                        Traveler View Details</span>


                </h3> */}

                <h3 className='flex_row_column'>

                    <div className='header_res'>
                        <p>Traveler View Details</p>
                 
                    </div>
                </h3>


                <div className="breadcrumb_buttons">
                    <Link to=""> <Button type="" >Copy Air Details
                    </Button></Link>


                    <Link to=""> <Button type="" >Cancel
                    </Button></Link>

                    <Dropdown overlay={men} trigger={['click']}
                        overlayClassName="email_width"
                        Arrow={true}
                    >
                        <a onClick={e => e.preventDefault()}>
                            <Space>
                                <Link to=""> <Button type="" >Email <DownOutlined />
                                </Button></Link>
                            </Space>
                        </a>
                    </Dropdown>

                </div>


                <div className='breadcrumb_toggle'>
                    <Dropdown overlay={menu} trigger={['click']}
                        overlayClassName="add_new"
                    >

                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <Button
                                    type="y"
                                    icon={<MoreOutlined />}

                                />
                            </Space>
                        </a>
                    </Dropdown>
                </div>


            </div>
        </div>

    );
}

export default Breadcrumb_resguestviewdetail;
