import { Table, Divider } from "antd";
import React, { useEffect, useState } from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { getWallletAmount } from "../../../../apis/contactApi";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const Wallet = ({contactId
}) => {
    const [tableData,setTableData] = useState([]);
    const [isLoading,setIsLoading] = useState([]);

    useEffect(()=>{
        (async()=>{
            setIsLoading(true)
            const data = await getWallletAmount({contactId})
            if(data.status){
                setTableData([{
                    key: "balance",
                    label: "Balance",
                    value: data?.data?.data?.balance|| 0,
                }])
            }
            setIsLoading(false)
        })()
    },[])
  return (
    <div>
      <div className="partnership_inform">
        <h4>Wallet</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={tableData}
        showHeader={false}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default Wallet;
