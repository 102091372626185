import { Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import React from "react";

function EditplannedPayment({
  paymentData,
  setPaymentData,
  updatePaymentData,
}) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;

  const onFinish = (values) => {
    // console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Planned Payment Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Payment Type" name="payment_type">
              <Select
                showSearch
                optionFilterProp="children"
                options={[
                  {
                    value: "installment",
                    label: "Installment",
                  },
                  {
                    value: "deposit",
                    label: "Deposit",
                  },
                  {
                    value: "refund",
                    label: "Refund",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item label="Payment Name" className="asterick-align" name="payment_name"
              rules={[
                {
                  required: true,
                  message: 'Please Input Payment Name',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Payment Amount" className="asterick-align" name="payment_amount">
              <Input />
            </Form.Item>

            <Form.Item label="Payment Method" className="asterick-align" name="payment_method">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item name="payment_date" className="asterick-align" label="Payment Date">
              <DatePicker format={"MM/DD/YYYY"} placeholder={false} />
            </Form.Item>

            <Form.Item label="Payment Status" className="asterick-align" name="transfer_status">
              Pending
            </Form.Item>

            <Form.Item label="Invoice No" className="asterick-align" name="transfer_balance">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditplannedPayment;
