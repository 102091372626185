export const customRequestHeader = ({
  token,
  defaultFormat,
  isHeaderAbsent,
  isUploadReq,
  fileType,
}) => {
  if (isHeaderAbsent) {
    return {};
  }
  if (isUploadReq) {
    return {
      Accept: "application/vnd.api+json",
      "Content-Disposition": `file; filename="${fileType}"`,
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    Accept: !!defaultFormat ? "application/json" : "application/vnd.api+json",
    "Content-Type": !!defaultFormat
      ? "application/json"
      : "application/vnd.api+json",
    Authorization: `Bearer ${token}`,
  };
};

export const Token = JSON.parse(
  localStorage.getItem("authorization")
)?.access_token;
// export const Token =
//   "YWJoaXNoZWsuckBvcGVuc2Vuc2VsYWJzLmNvbTphYmhpc2hlay5yQG9wZW5zZW5zZWxhYnMuY29t";
export const Cookie =
  "SESSab9e5daa9f7d3a4dad9834669d53504a=zCph0Bc2WWMPLdLZvSRjG2cKTPG6PLDDMuhvZygPjzWLwHUS";
// export const userUuid = "35b5e7e3-cfb0-42f6-bc66-3dfa53a30b1b";
