import { Table, Button, Divider, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./BasicDetails.less";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import drupalConfig from "../../../../config/serverless/drupal.config";
import { getEliteTravelerStatus } from "../../../../others/commonApiCalls/commonApiCalls";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];
const capitalize = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1);

const BasicDetails = ({ contactDetailsData, contactId, isLoading }) => {
  const {
    field_prefix,
    field_first_name,
    field_middle_name,
    field_last_name,
    field_suffix,
    field_preferred_name,
    field_date_of_birth,
    field_gender,
    // field_roles,
    // field_primary_contact,
    field_marital_status,
    field_spouse_name,
    field_email_address,
    field_email_address_2,
    field_do_not_contact,
    field_blessing_notes,
    field_organization,
  } = contactDetailsData || {};
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const [eliteStatus, setEliteStatus] = useState({});
  const [loadingEliteStatus, setLoadingEliteStatus] = useState(false);

  useEffect(() => {
    getEliteTravelerStatus(contactId, setEliteStatus, setLoadingEliteStatus);
  }, []);

  const basicDetailsData = [
    {
      key: "organization",
      label: "Organization",
      value: field_organization?.title || "",
    },
    {
      key: "elite-traveler-status",
      label: "Elite Traveler Status",
      value: (
        <>
          <span className="span_color">{eliteStatus?.traveler_status}</span>
        </>
      ),
    },
    {
      key: "prefix",
      label: "Prefix",
      value: field_prefix?.label || "",
    },
    { key: "first-name", label: "First Name", value: field_first_name || "" },
    {
      key: "middle-name",
      label: "Middle Name",
      value: field_middle_name || "",
    },
    { key: "last-name", label: "Last Name", value: field_last_name || "" },
    {
      key: "suffix",
      label: "Suffix",
      value: field_suffix?.label || "",
    },
    {
      key: "full-name",
      label: "Full Name",
      value: `${field_first_name || ""} ${field_middle_name || ""} ${
        field_last_name || ""
      } ${field_suffix?.label || ""}`,
    },
    {
      key: "preferred-name",
      label: "Preferred Name",
      value: field_preferred_name || "",
    },
    {
      key: "date-of-birth",
      label: "Date Of Birth",
      value: !!field_date_of_birth ? (
        <>
          <span>
            {moment(field_date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY")}
          </span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {`Age ${moment(field_date_of_birth, "YYYY-MM-DD").fromNow(true)}`}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "gender",
      label: "Gender",
      value: field_gender?.label || "",
    },
    // {
    //   key: "roles",
    //   label: "Roles",
    //   value: field_roles?.label || "",
    // },
    // {
    //   key: "primary-contact",
    //   label: "Primary Contact",
    //   value: field_primary_contact && `${field_primary_contact}`,
    // },
    {
      key: "marital-status",
      label: "Marital Status",
      value: field_marital_status?.label || "",
    },

    { key: "spouse", label: "Spouse Name", value: field_spouse_name || "" },
    {
      key: "email-address",
      label: "Email Address",
      value: field_email_address ? (
        <a href={`mailto:${field_email_address}`}>{field_email_address}</a>
      ) : (
        ""
      ),
    },
    {
      key: "email-address2",
      label: "Email Address 2",
      value: field_email_address_2 ? (
        <a href={`mailto:${field_email_address}`}>{field_email_address_2}</a>
      ) : (
        ""
      ),
    },
    {
      key: "do-not-contact",
      label: "Do Not Contact",
      value: field_do_not_contact !== null && (
        <Switch checked={field_do_not_contact} disabled />
      ),
    },
    {
      key: "blessing-notes",
      label: "Blessing Notes",
      value: field_blessing_notes,
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Personal Information</h4>
        {permissionChecker("add/edit contact") ? (
          <span>
            <Link to={`/contact?editId=${contactId}&step=1`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </span>
        ) : null}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={basicDetailsData}
        showHeader={false}
        pagination={false}
        size="small"
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
      />
    </div>
  );
};

export default BasicDetails;
