import { Grid, Layout } from "antd";
import React from "react";
import ManageRolesContainer from "../../../../Admin/ManageRoles/ManageRolesContainer";
import ViewPaymentPageHeader from "./ViewPaymentPageHeader";
import Searchreservations_menu from "../../../ReservationSearchBar";
import BreadCrumbGenerator from "../../../../BreadCrumbGenerator/BreadCrumbGenerator";
import ViewPaymentDetails from "./ViewPaymentDetails";
import ViewPaymentDetailsTable from "./ViewPaymentDetailsTable";
const { Header, Content, Sider } = Layout;

const ViewPaymentPageSection = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Reservations", path: "" },
    { title: "Details", path: "" },
    { title: "Payment Details", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ViewPaymentPageHeader />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ViewPaymentDetails />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ViewPaymentDetailsTable />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ViewPaymentPageSection;
