import {
  Table,
  Divider,
  Input,
  Space,
  Switch,
  Button,
  Checkbox,
  Form,
  Row,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { updateItem } from "../../../../apis/reservationApi";

function Guestdetailsedit_table(props) {
  const { data, form, setPaymentRespTotalAmount, paymentRespTotalAmount } =
    props;
  const [travelersList, setTravelersList] = useState([]);
  const [prices, setPrices] = useState([]);
  const [totalFees, setTotalFees] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalRoomPrice, setTotalRoomPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(
    Number(data?.traveler_details_total_sum?.total_items_amount)
  );
  const [fieldChecked, setFieldChecked] = useState({});
  function isUpdatedField(field, isChecked) {
    let totalAmount = Number(
      data?.traveler_details_total_sum?.total_items_amount
    );
    
    let roomPrice = 0;
    let feePrice = 0;
    let discountPrice = 0;

    travelersList.map((val) => {
      if (val.fieldName && val.fieldName.includes("amount")) {
        const fieldValue = Number(form.getFieldValue(val.fieldName));
        roomPrice += fieldValue;
      }
      if (val?.children && val?.children?.length) {
        val.children.forEach((val) => {
          const index = val.fieldName.split("-")[1];
          const checkedValue = form.getFieldValue(`item_active-${index}`);
          if (val.fieldName.includes("fee")) {
            if (!checkedValue) {
            } else {
              const fieldValue = Number(form.getFieldValue(val.fieldName));
              feePrice += fieldValue;
            }
          }
          if (val.fieldName.includes("discount")) {
            if (!checkedValue) {
            } else {
              const fieldValue = Number(form.getFieldValue(val.fieldName));
              discountPrice += fieldValue;
            }
          }
        });
      }
    });
    if (roomPrice < totalRoomPrice) {
      let updatedRoomPrice = totalRoomPrice - roomPrice;
      totalAmount -= updatedRoomPrice;
    } else if (roomPrice > totalRoomPrice) {
      let updatedRoomPrice = roomPrice - totalRoomPrice;
      totalAmount += updatedRoomPrice;
    }

    if (feePrice < totalFees) {
      let updatedFeesPrice = totalFees - feePrice;
      totalAmount -= updatedFeesPrice;
    } else if (feePrice > totalFees) {
      let updatedFeesPrice = feePrice - totalFees;
      totalAmount += updatedFeesPrice;
    }

    if (discountPrice < totalDiscount) {
      let updatedDiscountPrice = totalDiscount - discountPrice;
      totalAmount += updatedDiscountPrice;
    } else if (discountPrice > totalDiscount) {
      let updatedDiscountPrice = discountPrice - totalDiscount;
      totalAmount -= updatedDiscountPrice;
    }
    setPaymentRespTotalAmount({ isUpdated: true, totalAmount });
  }
  const columns = [
    {
      title: "Item/Name",
      dataIndex: "item_name",
      key: "1",
      render: (text, record, index) => (
        <>
          <Row>
            <span>
              {record.hasOwnProperty("field_is_active") ? (
                <Form.Item
                  name={`item_active-${record.key}`}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={ data?.reservation_item?.field_itemstatus  === "quote"}
                    onChange={(event) => {
                      record.isChecked = event.target.checked;
                      isUpdatedField(record.fieldName, event.target.checked);
                      setFieldChecked((prev) => {
                        let fieldName;
                        if (record.type === "fees") {
                          fieldName =
                            record.hasOwnProperty("child_index") &&
                            record.type === "fees"
                              ? `fee_${record.child_index}-${record.key}`
                              : `fee-${record.key}`;
                        } else {
                          fieldName =
                            record.hasOwnProperty("child_index") &&
                            record.type === "discount"
                              ? `discount_${record.child_index}-${record.key}`
                              : `discount-${record.key}`;
                        }
                        return { ...prev, [fieldName]: !event.target.checked };
                      });
                    }}
                    className="checbox_space"
                  ></Checkbox>
                </Form.Item>
              ) : null}
            </span>
            <span> {text}</span>
          </Row>
          {record.key !== 0 && `${record.key}`.length === 1 ? (
            <Form.Item
              name={`item_id-${record.key}`}
              style={{ display: "none", width: "10px" }}
            >
              <Input value={record.id}  disabled={ data?.reservation_item?.field_itemstatus  === "quote"}/>
            </Form.Item>
          ) : null}
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "2",
      render: (text, record, index) => (
        <>
          {record.key === 0 || record.price === "" ? (
            <span>{record.price}</span>
          ) : (
            <Form.Item name={`amount-${record.key}`}>
              <Input
                onChange={(event) => {
                  isUpdatedField();
                }}
                disabled={data?.reservation_item?.field_itemstatus  === "quote"  || record.price === "" ? true : false}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "3",
      render: (text, record, index) => {
        let fieldName =
          record.hasOwnProperty("child_index") && record.type === "discount"
            ? `discount_${record.child_index}-${record.key}`
            : `discount-${record.key}`;
        return (
          <>
            {typeof record.key === "number" || (record.discount === "" &&  record?.type === "fees") ? (
              <span>{record.discount}</span>
            ) : (
              <Form.Item
                name={
                  record.hasOwnProperty("child_index") &&
                  record.type === "discount"
                    ? `discount_${record.child_index}-${record.key}`
                    : `discount-${record.key}`
                }
              >
                <Input
                  onChange={(event) => {
                    isUpdatedField();
                  }}
                  disabled={
                    data?.reservation_item?.field_itemstatus  === "quote" || 
                    record.discount === ""  && record?.type === "fees"
                      ? true
                      : fieldChecked.hasOwnProperty(fieldName)
                      ? fieldChecked?.[fieldName]
                      : false
                  }
                />
              </Form.Item>
            )}
          </>
        );
      },
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "4",
      render: (text, record, index) => {
        let fieldName =
          record.hasOwnProperty("child_index") && record.type === "fees"
            ? `fee_${record.child_index}-${record.key}`
            : `fee-${record.key}`;
        return (
          <>
            {typeof record.key === "number" || (record.fee === "" && record?.type === "discount") ? (
              <span>{record.fee}</span>
            ) : (
              <Form.Item
                name={
                  record.hasOwnProperty("child_index") && record.type === "fees"
                    ? `fee_${record.child_index}-${record.key}`
                    : `fee-${record.key}`
                }
              >
                <Input
                  disabled={
                    data?.reservation_item?.field_itemstatus  === "quote" ||
                    record.fee === "" && record?.type ==="discount"
                      ? true
                      : fieldChecked.hasOwnProperty(fieldName)
                      ? fieldChecked?.[fieldName]
                      : false
                  }
                  onChange={(event) => {
                    isUpdatedField();
                  }}
                  // disabled={record.fee === "" ? true : false}
                />
              </Form.Item>
            )}
          </>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "5",
    },
  ];

  useEffect(() => {
    let travelers = [];
    if (data) {
      if (data.reservation_item_category) {
        form.setFieldsValue({
          [`amount-${0}`]: Number(
            data?.traveler_details_total_sum?.total_items_price
          ),
          [`fee-${0}`]: Number(
            data?.traveler_details_total_sum?.total_items_fees
          ),
          [`discount-${0}`]: Number(
            data?.traveler_details_total_sum?.total_items_discount
          ),
        });
        travelers.push({
          key: 0,
          item_name: data?.reservation_item_category?.name,
          price: data?.traveler_details_total_sum?.total_items_price,
          discount: data?.traveler_details_total_sum?.total_items_discount,
          fee: data?.traveler_details_total_sum?.total_items_fees,
          total: data?.traveler_details_total_sum?.total_items_amount,
        });
        setTotalFees(
          Number(data?.traveler_details_total_sum?.total_items_fees)
        );
        setTotalDiscount(
          Number(data?.traveler_details_total_sum?.total_items_discount)
        );
        setTotalRoomPrice(
          Number(data?.traveler_details_total_sum?.total_items_price)
        );
      }
      if (data && data?.travelers_items?.length) {
        let priceList = [];
        data.travelers_items.forEach((value, index) => {
          form.setFieldsValue({
            [`amount-${index + 1}`]: Number(value?.field_price),
            [`fee-${index + 1}`]: Number(value.total_fees),
            [`discount-${index + 1}`]: Number(value?.total_discount),
            [`item_id-${index + 1}`]: value?.id,
          });

          priceList.push(value?.field_price);
          let values = {
            key: index + 1,
            item_name: value?.field_traveler?.contact?.name || value.name,
            price: value?.field_price,
            id: value?.id,
            discount: value?.total_discount,
            fee: value?.total_fees,
            total: value?.total,
            fieldName: `amount-${index + 1}`,
          };
          let disc_fee = value.discounts.concat(value.fees);
          if (disc_fee.length) {
            let child = [];
            disc_fee.forEach((element, childIndex) => {
              form.setFieldValue(
                [`fee_${element?.index}-${index + 1}${childIndex + 1}`],
                Number(element.field_amount) || 0
              );
              form.setFieldValue(
                [`discount_${element?.index}-${index + 1}${childIndex + 1}`],
                Number(element.field_amount) || 0
              );
              form.setFieldValue(
                [`item_active-${index + 1}${childIndex + 1}`],
                element.field_is_active
              );
              let discData = {
                key: `${index + 1}${childIndex + 1}`,
                item_name: element.name,
                price: "",
                child_index: element?.index,
                discount: Number(element?.field_discount) || "",
                type: element?.field_traveler_item_type || element?.type,
                fee: element?.field_traveler_item_type !== "discount" ? Number(element?.field_amount) : "",
                total: "",
                field_is_active: element?.field_is_active,
                fieldName:
                  element?.type === "fees"
                    ? `fee_${element?.index}-${index + 1}${childIndex + 1}`
                    : `discount_${element?.index}-${index + 1}${
                        childIndex + 1
                      }`,
              };
              child.push(discData);
            });
            values.children = child;
          }
          travelers.push(values);
        });
        setPrices(priceList);
      }
      setTravelersList(travelers);
    }
  }, [data]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'" }}>Traveler Details</h4>
      </div>

      <Divider className="global_divider" />
      <Form form={form}>
        <Table
          className="plus_change arrowicon_change"
          expandable={{
            defaultExpandAllRows: true,
          }}
          columns={columns}
          dataSource={travelersList}
          size="middle"
          pagination={false}
        />
      </Form>
    </div>
  );
}

export default Guestdetailsedit_table;
