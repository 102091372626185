import { Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAllReportListApi, getQueriesListApi } from "../../apis/reportApi";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Breadcrumb_allreport from "../../components/Reports/ReportsListHeader";
import ReportsSearchBar from "../../components/Reports/ReportsSearchBar";
import All_reporttable from "../../components/Reports/ReportsListContainer";
import { urlParser } from "../../utils/contentParser";
import ReportsListContainer from "../../components/Reports/ReportsListContainer";
import ReportsListHeader from "../../components/Reports/ReportsListHeader";
import ReportListingTab from "../../components/Reports/ReportListingTab";
import ReportQueryListing from "../../components/Reports/ReportQueryListing";
const { Header, Content, Sider } = Layout;

function ReportListingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [{ title: "All Reports", path: "" }];
  const [reportListData, setReportListData] = useState([]);
  const [queryListData, setQueryListData] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [reportCount, setReportCount] = useState(0);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [queryCount, setQueryCount] = useState(0);
  const sort_by = searchParams.get("sort_by") || "report_name";
  const tab = searchParams.get("tab") || "Reports";

  const [urlParamsObject, setUrlParamsObject] = useState();

  useEffect(() => {
    if (tab === "Reports") {
      setUrlParamsObject({
        ...urlParser(window.location.search),
        sort_by,
        tab,
      });
    } else {
      setUrlParamsObject({
        ...urlParser(window.location.search),
        tab,
      });
    }
  }, []);

  const getQueriesList = async () => {
    setLoadingQuery(true);
    const response = await getQueriesListApi();
    if (response.result === "ok") {
      setQueryListData(response?.queries);
      setQueryCount(response?.queries.length);
    } else {
      message.error("Something went wrong");
    }
    setLoadingQuery(false);
  };

  useEffect(() => {
    if (urlParamsObject && urlParamsObject?.tab === "Reports") {
      setSearchParams(urlParamsObject);

      for (let prop in urlParamsObject) {
        if (!urlParamsObject[prop]) {
          delete urlParamsObject[prop];
        }
      }
      getAllReportsList(urlParamsObject);
    } else {
      setSearchParams(urlParamsObject);

      getQueriesList();
    }
  }, [urlParamsObject]);

  const lastViewParser = (value) => {
    if (!value) return 0;
    let timeStamp = JSON.parse(value)?.timestamp || 0;
    return timeStamp;
  };

  const getAllReportsList = async (params) => {
    setLoadingList(true);
    const response = await getAllReportListApi(params);
    if (response.status) {
      setReportListData(
        params.sort_by === "view_count"
          ? response?.data?.rows?.toSorted(
              (a, b) =>
                parseInt(b?.viewCount || 0) - parseInt(a?.viewCount || 0)
            )
          : params.sort_by === "recent_read"
          ? response?.data?.rows
              ?.toSorted((a, b) => {
                return lastViewParser(b.lastView) - lastViewParser(a.lastView);
              })
              .slice(0, 10)
          : response?.data?.rows
      );
      setReportCount(response?.data?.pager?.total_items || 0);
      setLoadingList(false);
    } else {
      setReportListData([]);
      setLoadingList(false);
      setReportCount(0);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ReportsSearchBar
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
            />
          </Header>

          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ReportsListHeader
              reportCount={reportCount}
              loadingList={loadingList}
              queryCount={queryCount}
              loadingQuery={loadingQuery}
            />
          </div>
          <ReportListingTab
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            reportCount={reportCount}
            loadingList={loadingList}
            queryCount={queryCount}
            loadingQuery={loadingQuery}
          />
          {urlParamsObject && urlParamsObject?.tab === "Reports" ? (
            <Content className="site-layout-background">
              <ReportsListContainer
                urlParamsObject={urlParamsObject}
                setUrlParamsObject={setUrlParamsObject}
                reportListData={reportListData}
                loadingList={loadingList}
                reportCount={reportCount}
                setReportListData={setReportListData}
              />
            </Content>
          ) : (
            <Content className="site-layout-background">
              <ReportQueryListing
                urlParamsObject={urlParamsObject}
                setUrlParamsObject={setUrlParamsObject}
                reportListData={queryListData}
                loadingList={loadingList}
                reportCount={reportCount}
                setReportListData={setReportListData}
              />
            </Content>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ReportListingPage;
