import React from 'react';

import Res_guestdetails from '../../Forms/Reservation_editforms/Res_guestdetails';
import Res_guestprofile from '../../Forms/Reservation_editforms/Res_guestprofile';
import Reservation_editoperations from '../../Forms/Reservation_editforms/Reservation_editoperations';
import Reservation_arrivaldeparture from '../../Forms/Reservation_editforms/Reservation_arrivaldeparture';
import Reservation_predxtdrrivaldeparture from '../../Forms/Reservation_editforms/Reservation_predxtdrrivaldeparture';
import Reservation_postdxtdrrivaldeparture from '../../Forms/Reservation_editforms/Reservation_postdxtdrrivaldeparture';
import Reservation_operationsnotes from '../../Forms/Reservation_editforms/Reservation_operationsnotes';
import Reservation_marketing from '../../Forms/Reservation_editforms/Reservation_marketing';



const Reservationedit_formsection = () => (
  <div className='details-tabs' style={{margin:0}}>

    <div>
      <div className='basic_details pd-16 bg-white'>
        <Res_guestdetails />
      </div>

    </div>
    <div className='basic_details pd-16 bg-white'>
      <Res_guestprofile />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_editoperations />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_arrivaldeparture />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_predxtdrrivaldeparture />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_postdxtdrrivaldeparture />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_operationsnotes />
    </div>
    <div className='basic_details pd-16 bg-white'>
      <Reservation_marketing />
    </div>
  </div>


);

export default Reservationedit_formsection;