import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Country } from "country-state-city";

const MobileInput = ({ label, extension, phoneName, form, countryChange }) => {
    const [extValue, setExtValue] = useState();
    const [phoneValue, setPhoneValue] = useState();
    const countriesList = Country.getAllCountries();

    useEffect(() => {
        setTimeout(() => {
            const extInputValue = form.getFieldValue(extension)
            const phoneInputValue = form.getFieldValue(phoneName)
            setExtValue(extInputValue)
            setPhoneValue(phoneInputValue)
        }, 100);
    }, [form, countryChange])

    const handleKeyUp = (event) => {
        const key = event.key.toLowerCase();
        const isAlphaCharacter = /^[a-z]$/.test(key) || /^[^\w\s-]+$/.test(key);

        if (isAlphaCharacter) {
            return true;
        }
        return false;
    };

    const onChange = (e, phoneName) => {

        const value = e.target.value;
        if (extValue === "+1" && value.length === 3) {
            if (e.keyCode === 8) {
                form.setFieldValue(phoneName, value)
                setPhoneValue(value)
            } else {
                let firstPart = value.substring(0, 3);
                let secondPart = value.substring(3);
                let inputVal = `${firstPart}-${secondPart}`
                inputVal.slice(0, 12)
                form.setFieldValue(phoneName, inputVal)
                setPhoneValue(inputVal)
            }

        } else if (extValue === "+1" && value.length === 7) {
            if (e.keyCode === 8) {
                form.setFieldValue(phoneName, value)
                setPhoneValue(value)
            } else {
                let firstPart = value.substring(0, 7);
                let secondPart = value.substring(7);
                let inputVal = `${firstPart}-${secondPart}`
                inputVal.slice(0, 12)
                form.setFieldValue(phoneName, inputVal)
                setPhoneValue(inputVal)
            }

        } else {
            setPhoneValue(value)
        }
    }

    const onExtensionChange = (e) => {
        setExtValue(e)
        if (e === "+1" && phoneValue && phoneValue.length) {
            if (phoneValue.length > 6) {
                const modifiedPhone = phoneValue.slice(0, 3) + '-' + phoneValue.slice(3, 6) + '-' + phoneValue.slice(6);
                setPhoneValue(modifiedPhone)
                form.setFieldValue(phoneName, modifiedPhone)
            }
        }
    }

    const countryCodeSelector = (name) => (
        <Form.Item name={name} noStyle initialValue={"+1"}>
            <Select style={{ width: "90px" }} showSearch onChange={onExtensionChange}>
                {countriesList.map((country, index) => (
                    <Select.Option
                        value={`+${country.phonecode.replace("+", "")}`}
                        key={index}
                    >
                        +{country.phonecode.replace("+", "")}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (<>
        <Form.Item
            className="asterick-align number-arrows"
            label={label}
            name={phoneName}
        >
            <Input
                addonBefore={countryCodeSelector(extension)}
                type="text"
                autoComplete="nope"
                onKeyDown={(evt) => {
                    if (
                        evt.key === "e" ||
                        evt.key === "+" ||
                        evt.key === "." ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowDown" || handleKeyUp(evt)
                    ) {
                        evt.preventDefault();
                    } else {
                        onChange(evt, phoneName);
                    }
                }}
                onInput={(e) => (e.target.value = e.target?.value?.slice(0, 12))}
            />
        </Form.Item>
    </>)
}
export default MobileInput;