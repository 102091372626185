import React, { useRef } from "react";
// import "./OrganizationSearchBar.less";
import { useEffect, useState } from "react";
import {
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Country } from "country-state-city";
import { AutoComplete, Dropdown, Space, Col, Row, Grid, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Form, Select } from "antd";
import { Button } from "antd";
import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useDebounce } from "../../../others/Debounce/debounce";
// import { useSearchParams } from "react-router-dom";
import {
  getAllContactsApi,
  getOrganizationListApi,
} from "../../../apis/contactApi";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import { getOrganizationTypeApi } from "../../../apis/organisationApi";
import { decodeContent } from "../../../utils/contentParser";
import {
  contactSearchFilterApplied,
  contactSearchFilterSetter,
} from "../../../redux/features/contactFeatures/contactSlice";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import MobileInput from "../../Custom/MobileInput";
import { organizationTypeList } from "../../../others/util/commonFunctions";
const { useBreakpoint } = Grid;

const ContactSearchBar = ({
  contactName,
  contactEmail,
  cId,
  contactPhoneExt,
  contactPhone,
  organization,
  organizationType,
  travelStatus,
  contactId,
  setSearchParams,
  page,
  sorting,
  itemsPerPage,
  setUrlParamsObject,
  urlParamsObject,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [orgsList, setOrgsList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const countriesList = Country.getAllCountries();
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(contactName);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { isFilterApplied } = useSelector((state) => state.contacts);
  const customFilterRef = useRef();
  const filterInputRef = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  useEffect(() => {
    getOrganizationList();
    form.setFieldsValue({
      email: contactEmail !== "null" && contactEmail ? contactEmail : null,
      cid: cId !== "null" && cId ? cId : null,
      phone_ext:
        contactPhoneExt !== "null" && contactPhoneExt ? contactPhoneExt : "+1",
      phone: contactPhone !== "null" && contactPhone ? contactPhone : null,
      org:
        organizationType !== "null" && organizationType
          ? organizationType
          : null,
      organization:
        organization !== "null" && organization ? Number(organization) : null,
    });
  }, []);

  useEffect(() => {
    // const checker = {
    //   contactEmail,
    //   // contactName,
    //   contactPhoneExt,
    //   contactPhone,
    //   organizationType,
    //   organization,
    // };
    // for (let key in checker) {
    //   if (!checker[key]) {
    //     delete checker[key];
    //   }
    // }
    // if (Object.keys(checker).length) {
    //   setIsFilterApplied(true);
    // } else {
    //   setIsFilterApplied(false);
    // }
    if (contactEmail || contactPhone || organizationType || organization || cId) {
      dispatch(contactSearchFilterApplied(true));
      dispatch(
        contactSearchFilterSetter({
          Email: contactEmail !== "null" && contactEmail ? contactEmail : null,
          "Contact Id":cId !== "null" && cId ? cId : null,
          Phone:
            contactPhone !== "null" && contactPhone
              ? `${contactPhoneExt}-${contactPhone}`
              : null,
          "Organization Type":
            organizationType !== "null" && organizationType
              ? organizationTypeList.find(
                  (orgType) => orgType?.key === organizationType
                )?.label
              : null,
          Organization:
            organization !== "null" && organization
              ? orgsList.find(
                  (org) => org.drupal_internal__nid === Number(organization)
                )?.title
              : null,
        })
      );
    } else {
      dispatch(contactSearchFilterApplied(false));
      dispatch(contactSearchFilterSetter({}));
    }
  }, [orgsList]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     const listComponents = document.querySelectorAll(".rc-virtual-list");

  //     let isClickedOnList = false;
  //     listComponents?.forEach((ele) => {
  //       if (ele?.contains(event.target)) {
  //         isClickedOnList = true;
  //       }
  //     });
  //     if (
  //       // customFilterRef.current &&
  //       !filterInputRef?.current?.contains(event.target) &&
  //       !customFilterRef?.current?.contains(event.target) &&
  //       !isClickedOnList
  //     ) {
  //       setIsVisible(false);
  //     }
  //   }

  //   if (isVisible) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isVisible]);

  const getContactSearchResult = async (url, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }

    let response = await getAllContactsApi({
      // ...urlParamsObject,
      page: 0,
      items_per_page: 5,
      sort_order: "ASC",
      sort_by: "name",
      name: searchVal,
    });

    if (response?.status) {
      let searchOptions = response?.data?.rows?.slice(0, 5)?.map((option) => {
        return {
          key: option?.uuid,
          value: (
            <span
              style={{ width: "100%", display: "block" }}
              onClick={() => {
                navigate(`/contact/details?id=${option?.uuid}`);
                setAutoCompleteOpen(false);
              }}
            >
              {decodeContent(option?.name)}
            </span>
          ),
        };
      });
      if (searchOptions.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== "/contact/listing"
                    ? navigate(
                        `/contact/listing?count=10&order=ASC&page=1&name=${searchVal}`
                      )
                    : setUrlParamsObject({
                        count: 10,
                        order: "ASC",
                        page: 1,
                        // search: true,
                        name: searchVal || contactName,
                      });
                  setAutoCompleteOpen(false);
                }}
              >
                see all results for {searchVal || contactName}
              </span>
            ),
          },
        ]);
        // if (urlParamsObject.name !== searchVal) {
        setAutoCompleteOpen(true);
        // }
        // setIsVisible(false);
      } else {
        setOptions([]);
        setAutoCompleteOpen(true);
        // setIsVisible(false);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setIsVisible(false);
      setAutoCompleteOpen(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      let url = `api/contact/listing?count=5&name=${debouncedSearchTerm}`;
      getContactSearchResult(url, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
      location.pathname === "/contact/listing" &&
        setUrlParamsObject({
          ...urlParamsObject,
          name: null,
        });
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const getOrganizationList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getOrganizationListApi({ reqHeaders });
    if (response && response.status) {
      setOrgsList(response?.data?.data);
    } else {
      setOrgsList([]);
      form.setFields([
        {
          name: "organization",
          errors: ["Unable to fetch organization data"],
        },
      ]);
    }
  };

  const onFinishHandler = (values) => {
    if (!values.phone) {
      values.phone_ext = null;
    }
    console.log({ valuesaf: values });
    const result = new URLSearchParams(values);
    const newParams = new URLSearchParams();
    for (const [key, value] of result) {
      if (value !== null && value !== undefined && value !== "" && value !== "undefined" && value !== "null") {
        newParams.append(key, value);
      }
    }
    const searchQuery = newParams.toString();
    if (searchQuery.length) {
      if (location.pathname !== "/contact/listing") {
        navigate(`/contact/listing?count=10&order=ASC&page=1&${searchQuery}`);
      } else {
        setUrlParamsObject({
          ...urlParamsObject,
          page: 1,
          order:
            urlParamsObject?.order === "recently_read"
              ? "ASC"
              : urlParamsObject?.order,
          ...values,
        });
      }
      setAutoCompleteOpen(false);

      dispatch(contactSearchFilterApplied(true));
      dispatch(
        contactSearchFilterSetter({
          Email: values?.email !== "null" && values.email ? values.email : null,
          "Contact Id": values?.cid !== "null" && values.cid ? values.cid : null,
          Phone:
            values?.phone !== "null" && values.phone
              ? `${values?.phone_ext}-${values?.phone}`
              : null,
          "Organization Type":
            values?.org !== "null" && values?.org
              ? organizationTypeList.find(
                  (orgType) => orgType?.key === values?.org
                )?.label
              : null,
          Organization:
            values?.organization !== "null" && values?.organization
              ? orgsList.find(
                  (org) =>
                    org.drupal_internal__nid === Number(values?.organization)
                )?.title
              : null,
        })
      );
    }
    setIsVisible(false);
  };

  const custumSearch = (
    <div className="custom_search searchbox__dropdown" ref={customFilterRef}>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item
          label="Email"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="email"
        >
          <Input
            placeholder="Enter Email Address"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <MobileInput
          label={"Phone"}
          extension={"phone_ext"}
          phoneName={"phone"}
          form={form}
        />
        {/* <Form.Item
          label="Phone"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="phone"
        >
          <Input
            addonBefore={countryCodeSelector("phone_ext")}
            placeholder="Enter Phone Number"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            type="number"
            min={0}
            // placeholder="Enter Phone"
            autoComplete="nope"
            pattern="/^[0-9\b]+$/"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "-" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown"
              ) {
                evt.preventDefault();
              }
            }}
            onInput={(e) => (e.target.value = e.target?.value?.slice(0, 12))}
          />
        </Form.Item> */}

        <Form.Item
          label="Organization Type"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="org"
        >
          <Select
            placeholder="Select Organization Type"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={organizationTypeList.map((orgType) => ({
              key: orgType.key,
              value: orgType.key,
              label: orgType.label,
            }))}
            allowClear
          ></Select>
        </Form.Item>

        <Form.Item
          label="Organization"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="organization"
        >
          <Select
            placeholder="Select Organization"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={orgsList?.map((org) => ({
              key: org?.drupal_internal__nid,
              value: org?.drupal_internal__nid,
              label: org?.title,
              nid: org?.drupal_internal__nid,
            }))}
            allowClear
          ></Select>
        </Form.Item>
        <Form.Item
          label="Contact Id"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="cid"
        >
          <Input
            placeholder="Enter Contact Id"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        {/* <Form.Item
          label="Traveler Status"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="traveler_status"
        >
          <Select
            placeholder="Select Traveler Status"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          >
            <Select.Option value="elite">Elite</Select.Option>
            <Select.Option value="silver">Silver</Select.Option>
            <Select.Option value="sapphire">Sapphire</Select.Option>
            <Select.Option value="platinum">Platinum</Select.Option>
            <Select.Option value="diamond">Diamond</Select.Option>
          </Select>
        </Form.Item> */}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85" }}
            onClick={() => {
              form.resetFields();
              setUrlParamsObject({
                count: urlParamsObject?.count,
                order: urlParamsObject?.order,
                page: urlParamsObject?.page,
              });
              setSearchValue("");
              dispatch(contactSearchFilterApplied(false));
              dispatch(contactSearchFilterSetter({}));
            }}
          >
            Clear Filter
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align" ref={filterInputRef}>
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSearch={handleSearch}
              // defaultValue={contactName}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              onClear={() => {
                setAutoCompleteOpen(false);
                location.pathname !== "/contact/listing"
                  ? setSearchValue("")
                  : setUrlParamsObject({ ...urlParamsObject, name: null });
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (location.pathname !== "/contact/listing") {
                    navigate(
                      `/contact/listing?count=10&order=ASC&page=1&name=${e.target.value}`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      page: 1,
                      order:
                        urlParamsObject?.order === "recently_read"
                          ? "ASC"
                          : urlParamsObject?.order,
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                    // setIsFilterApplied(true);
                  }
                }
              }}
              open={autoCompleteOpen}
              placeholder="Search Contacts"
              size="large"
              value={
                urlParamsObject?.order === "recently_read" ? "" : searchValue
              }
            />

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              trigger={["click"]}
              open={isVisible}
              onOpenChange={(e) => {
                setIsVisible(e);
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setAutoCompleteOpen(false);
                }}
              >
                <Space>
                  <FilterOutlined
                    className="active-filter active-hover"
                    style={{
                      opacity: "0.5",
                      color: "#000",
                      backgroundColor: isFilterApplied
                        ? "rgba(26, 127, 147, 0.2)"
                        : "transparent",
                      //   backgroundColor: "#000",
                    }}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactSearchBar;
