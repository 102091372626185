import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Skeleton, message, Dropdown } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
} from "../../../../../../utils/constants";
import TextArea from "antd/lib/input/TextArea";
import { changePaymentStatusApi } from "../../../../../../apis/reservationApi";
import { ReservationDetailRoute, ReservationSendEmailRoute } from "../../../../../../url-routes/urlRoutes";
function PaymentDetailHeader({
  paymentData,
  loadingPaymentData,
  reservationId,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isUnvoidModalOpen, setIsUnvoidModalOpen] = useState(false);
  const [voidComment, setVoidComment] = useState(null);
  const [unvoidComment, setUnvoidComment] = useState(null);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [isVoidModalOpen, setIsVoidModalOpen] = useState(false);

  const showVoidModal = () => {
    setIsVoidModalOpen(true);
  };
  const closeVoidModal = () => {
    setVoidComment(null);
    setIsVoidModalOpen(false);
  };
  const showUnvoidModal = () => {
    setIsUnvoidModalOpen(true);
  };

  const closeUnvoidModal = () => {
    setUnvoidComment(null);
    setIsUnvoidModalOpen(false);
  };
  const changePaymentStatus = async (payload) => {
    setLoadingConfirmation(true);
    const res = await changePaymentStatusApi(payload);
    if (res.status) {
      message.success("Payment Status Changed Successfully");
      navigate(`${ReservationDetailRoute}?id=${reservationId}`);
      setLoadingConfirmation(false);
      closeVoidModal();
      closeUnvoidModal();
    } else {
      setLoadingConfirmation(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const onItemClick = (data) => {
    navigate(`${ReservationSendEmailRoute}?id=${paymentData?.field_reservation?.id}&traveller_id=${paymentData?.field_traveler?.id}&emailType=${data?.key}&payment_id=${paymentData?.id}`)
  }
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate(`${ReservationDetailRoute}?id=${reservationId}`);
            }}
          />{" "}
          {loadingPaymentData ? (
            <Skeleton.Input active={true} style={{ width: "350px" }} />
          ) : (
            <div className="header_res">
              <p>
                View Payment: {paymentData?.name || ""}{" "}
                {moment(paymentData?.field_paymentdate, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}
              </p>
              {/* reservation name isn't rendering as an extra API will be called only for the name rendering */}
            </div>
          )}
        </h3>

        {paymentData?.field_payment_status?.key === "complete" ? (
          <div
            className="breadcrum_buttons"
            style={{ display: "flex", gap: "10px" }}
          >
             {paymentData?.field_paymentsource?.key !== "refund" ? <Button
              onClick={() => {
                showVoidModal();
              }}
            >
              Void
            </Button> : null}
            <Dropdown
               overlayClassName="profile-dropdown"
               menu={{
                 items: [{label:"Payment Confirmation",key:"payment_confirmation_template"}],
                 onClick: onItemClick,
               }}
               trigger={["click"]}
               >
            <Button icon={<DownOutlined />}>Email</Button>
            </Dropdown>
          </div>
        ) : null}
        {paymentData?.field_payment_status?.key === "void" ? (
          <div
            className="breadcrum_buttons"
            style={{ display: "flex", gap: "10px" }}
          >
            <Button
              onClick={() => {
                showUnvoidModal();
              }}
            >
              Unvoid
            </Button>
          </div>
        ) : null}
      </div>
      <Modal
        footer={
          <div>
            <Button
              type="primary"
              onClick={() => {
                changePaymentStatus({
                  payment_uuid: paymentData?.id,
                  set_to_status: "void",
                  comment: voidComment,
                });
              }}
              loading={loadingConfirmation}
            >
              Confirm
            </Button>{" "}
            <Button
              onClick={() => {
                closeVoidModal();
              }}
            >
              Cancel
            </Button>
          </div>
        }
        title="Void Confirmation"
        open={isVoidModalOpen}
        onOk={closeVoidModal}
        onCancel={closeVoidModal}
      >
        <div className="void-modal">
          <p>Please confirm that you want to mark this payment as Void.</p>
          <span>
            Please note, Void only marks payment as voided in Booking Engine.
            You need to undo payment in your merchant account separately, if
            needed.
          </span>
          <div className="void-modal_form">
            <Form>
              <Form.Item
                label="Comment"
                // name="comment"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  showCount
                  rows={4}
                  maxLength={100}
                  value={voidComment}
                  onInput={(e) => {
                    setVoidComment(e.target.value);
                  }}
                  placeholder="Add your comment here..."
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal
        footer={
          <div>
            <Button
              type="primary"
              onClick={() => {
                changePaymentStatus({
                  payment_uuid: paymentData?.id,
                  set_to_status: "complete",
                  comment: unvoidComment,
                });
              }}
              loading={loadingConfirmation}
            >
              Confirm
            </Button>{" "}
            <Button
              onClick={() => {
                closeUnvoidModal();
              }}
            >
              Cancel
            </Button>
          </div>
        }
        title="UnVoid Confirmation"
        open={isUnvoidModalOpen}
        onOk={closeUnvoidModal}
        onCancel={closeUnvoidModal}
      >
        <div className="void-modal">
          <p>Please confirm that you want to mark this payment as Unvoid.</p>
          <div className="void-modal_form">
            <Form>
              <Form.Item
                label="Comment"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  showCount
                  rows={4}
                  value={unvoidComment}
                  onInput={(e) => {
                    setUnvoidComment(e.target.value);
                  }}
                  maxLength={100}
                  placeholder="can resize"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default PaymentDetailHeader;
