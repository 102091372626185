import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Switch, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { EventRoute } from "../../../../url-routes/urlRoutes";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../utils/contentParser";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const PaymentSetup = ({ eventData, eventId, loading }) => {
  const data = [
    {
      key: "1",
      label: "Is Financeable",
      input: <Switch checked={!!eventData?.field_is_financeable} disabled />,
    },
    {
      key: "2",
      label: "Standard Deposit Amount",
      input: eventData?.field_standard_deposit_amount
        ? `$${addCommasInPricing(eventData?.field_standard_deposit_amount)}`
        : "",
    },
    {
      key: "3",
      label: "Standard Deposit Percent",
      input: eventData?.field_standard_deposit_percent
        ? eventData?.field_standard_deposit_percent + "%"
        : "",
    },
    {
      key: "4",
      label: "Early Deposit Amount",
      input: eventData?.field_early_deposit_amount
        ? `$${addCommasInPricing(eventData?.field_early_deposit_amount)}`
        : "",
    },
    {
      key: "5",
      label: "Deposit Date",
      input: eventData?.field_deposit_date ? (
        <>
          <span>
            {moment(eventData?.field_deposit_date).format("MM/DD/YYYY")}
          </span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {moment(eventData?.field_deposit_date).fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "6",
      label: "Second Deposit Amount",
      input: eventData?.field_second_deposit_amount
        ? `$${addCommasInPricing(eventData?.field_second_deposit_amount)}`
        : "",
    },
    {
      key: "7",
      label: "Second Deposit Percent",
      input: eventData?.field_second_deposit_percent
        ? eventData?.field_second_deposit_percent + "%"
        : "",
    },
    {
      key: "8",
      label: "Second Deposit Date",
      input: eventData?.field_second_deposit_date ? (
        <>
          <span>
            {moment(eventData?.field_second_deposit_date).format("MM/DD/YYYY")}
          </span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {moment(eventData?.field_second_deposit_date).fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    {
      key: "10",
      label: "Final Payment Date",
      input: eventData?.field_final_payment_date ? (
        <>
          <span>
            {moment(eventData?.field_final_payment_date).format("MM/DD/YYYY")}
          </span>
          <span className="span_text" style={{ marginLeft: "10px" }}>
            {moment(eventData?.field_final_payment_date).fromNow()}
          </span>
        </>
      ) : (
        ""
      ),
    },
    // {
    //   key: "11",
    //   label: "Payment Processor",
    //   input: eventData?.field_payment_processor?.name,
    // },
    // {
    //   key: "15",
    //   label: "Multi Currency",
    //   input: eventData?.field_multi_currency?.name,
    // },
    {
      key: "16",
      label: "Default Pay Terms",
      input: eventData?.field_default_pay_terms?.label,
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Payment Setup</h4>
        <span>
          {permissionChecker("add/edit event") ? (
            <Link to={`${EventRoute}?editId=${eventId}&step=3`}>
              {" "}
              <Button type="y" icon={<EditOutlined />} />
            </Link>
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default PaymentSetup;
