import { DatePicker, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DATE_FORMAT } from "../../../../../../../utils/constants";
import { getTaxonomyList } from "../../../../../../../others/commonApiCalls/commonApiCalls";
import moment from "moment";
import paymentMethod from "../../../../../../../utils/paymentMethod.json"

const SecondDepositFinalPaymentForm = ({
  form,
  term,
  termFormData,
  onFinishHandler,
}) => {
  const [paymentMethodList, setPaymentMethodList] = useState(paymentMethod.list);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  // useEffect(() => {
  //   getTaxonomyList(
  //     "payment_method",
  //     setPaymentMethodList,
  //     setPaymentMethodLoading
  //   );
  // }, []);

  useEffect(() => {
    form.setFieldsValue({
      deposit_amount: termFormData?.deposit_data?.amount?.toString() || null,
      deposit_due_date: termFormData?.deposit_data?.due_date
        ? moment(termFormData?.deposit_data?.due_date, "YYYY-MM-DD")
        : null,
      second_deposit_amount:
        termFormData?.second_deposit?.amount?.toString() || null,
      second_deposit_due_date: termFormData?.second_deposit?.due_date
        ? moment(termFormData?.second_deposit?.due_date, "YYYY-MM-DD")
        : null,
      final_amount: termFormData?.final_amount?.amount?.toString() || null,
      final_due_date: termFormData?.final_amount?.due_date
        ? moment(termFormData?.final_amount?.due_date, "YYYY-MM-DD")
        : null,
    });
    setTotalAmount(termFormData?.traveler_data?.total_amount || 0);
  }, [termFormData]);

  const finalAmountHandler = (amount, field) => {
    let depositAmount = amount
      ? field == "deposit_amount"
        ? amount
        : parseFloat(form.getFieldValue("deposit_amount").replace(/,/g, ""))
      : 0;
    let secondAmount = amount
      ? field == "second_deposit_amount"
        ? amount
        : parseFloat(
          form.getFieldValue("second_deposit_amount").replace(/,/g, "")
        )
      : 0;
    let finalAmount =
      Number(totalAmount) - (Number(depositAmount) + Number(secondAmount));
    form.setFieldsValue({ final_amount: finalAmount });
  };
  return (
    <>
      <Form form={form} onFinish={onFinishHandler}>
        <div>
          <h3>Deposit</h3>
          <Divider className="global_divider" />
          <Form.Item
            label="Amount"
            className="asterick-align"
            name="deposit_amount"
            rules={[{ required: true, message: "Please enter Amount" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown"
                ) {
                  evt.preventDefault();
                }
              }}
              onInput={(e) => {
                finalAmountHandler(e.target.value, "deposit_amount");
              }}
            />
          </Form.Item>
          <Form.Item
            label="Payment Method"
            className="asterick-align"
            name="deposit_payment_method"
            rules={[
              { required: true, message: "Please select Payment Method" },
            ]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              options={paymentMethodList.map((paymentMethod,index) => ({
                key: index,
                value: paymentMethod.value,
                label: paymentMethod.name,
              }))}
              loading={paymentMethodLoading}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            className="asterick-align"
            name="deposit_due_date"
            rules={[{ required: true, message: "Please enter Due Date" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </div>
        <div class="background_div"></div>
        <div>
          <h3 style={{ paddingTop: "1rem" }}>Second Deposit</h3>
          <Divider className="global_divider" />

          <Form.Item
            label="Amount"
            className="asterick-align"
            name="second_deposit_amount"
            rules={[{ required: true, message: "Please enter Amount" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown"
                ) {
                  evt.preventDefault();
                }
              }}
              onInput={(e) => {
                finalAmountHandler(e.target.value, "second_deposit_amount");
              }}
            />
          </Form.Item>

          <Form.Item
            className="asterick-align"
            label="Due Date"
            name="second_deposit_due_date"
            rules={[{ required: true, message: "Please enter Due Date" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </div>
        <div class="background_div"></div>
        <div>
          <h3 style={{ paddingTop: "1rem" }}>Final Payment</h3>
          <Divider className="global_divider" />

          <Form.Item
            className="asterick-align"
            label="Amount"
            name="final_amount"
            rules={[{ required: true, message: "Please enter Amount" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Payment Method"
            className="asterick-align"
            name="final_payment_method"
            rules={[
              { required: true, message: "Please select Payment Method" },
            ]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              options={paymentMethodList.map((paymentMethod,index) => ({
                key: index,
                value: paymentMethod.value,
                label: paymentMethod.name,
              }))}
              loading={paymentMethodLoading}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            className="asterick-align"
            name="final_due_date"
            rules={[{ required: true, message: "Please enter Due Date" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default SecondDepositFinalPaymentForm;
