import { Breadcrumb, Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb_eventactive from "../../../components/Events/EventDetails/Reservations/EventReservationHeader";
import Searchevents_menu from "../../../components/Events/EventsSearchbar";
import Eventactive_table from "../../../components/Events/EventDetails/Reservations/EventReservationListing";
import {
  ContactDetailRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
  ReservationDetailRoute,
} from "../../../url-routes/urlRoutes";
import { Link, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EventsSearchbar from "../../../components/Events/EventsSearchbar";
import EventReservationHeader from "../../../components/Events/EventDetails/Reservations/EventReservationHeader";
import EventReservationListing from "../../../components/Events/EventDetails/Reservations/EventReservationListing";
import {
  getAddonReservationListingApi,
  getReservationListingAPI,
} from "../../../apis/reservationApi";
import { addCommasInPricing } from "../../../utils/contentParser";
import { getPrimaryTravelerName } from "../../../others/util/commonFunctions";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/constants";
import { setLoadig } from "../../../redux/features/customDropdownFeatures/customDropDownSlice";
const { Header, Content, Sider } = Layout;

function EventReservationsPage({ type }) {
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const addonId = searchParams.get("addonId");
  const addonType = searchParams.get("addonType");
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const [urlParamsObject, setUrlParamsObject] = useState(
    type === "event"
      ? {
          id: searchParams.get("id"),
          event: searchParams.get("event"),
          order: searchParams.get("order") || "ASC",
          count: searchParams.get("count") || "10",
          page: searchParams.get("page") || 1,
          status: searchParams.get("status") || "active",
        }
      : {
          id: searchParams.get("id"),
          order: searchParams.get("order") || "ASC",
          count: searchParams.get("count") || "10",
          page: searchParams.get("page") || 1,
          status: searchParams.get("status") || "active",
          addonType,
          addonId,
        }
  );
  const breadCrumbs =
    type === "event"
      ? [
          { title: "Events", path: EventsListingRoute },
          { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
          { title: "All Reservations", path: `` },
        ]
      : [
          { title: "Events", path: EventsListingRoute },
          { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
          {
            title: "Add-Ons",
            path: `${EventDetailsRoute}?id=${eventId}#Add-Ons`,
          },
          {
            title: "Add-Ons Detail",
            path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}&addonType=${addonType}`,
          },
          {
            title: "Reservations",
            path: "",
          },
        ];
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setSearchParams(urlParamsObject);
    let backendUrl = {
      id: urlParamsObject?.id,
      event: urlParamsObject?.event,
      sort_order: urlParamsObject?.order,
      items_per_page: urlParamsObject?.count,
      page: Number(urlParamsObject?.page) - 1,
      status: urlParamsObject?.status,
      addonId: urlParamsObject?.addonId,
      addonType: urlParamsObject?.addonType,
    };
    if (type === "event") {
      delete backendUrl.id;
      delete backendUrl.addonId;
      delete backendUrl.addonType;
      getEventReservationList(backendUrl);
    }
    if (type === "addon") {
      getAddonReservationList(backendUrl);
    }
  }, [urlParamsObject]);

  const getAddonReservationList = async (payload) => {
    setDataLoading(true);
    const res = await getAddonReservationListingApi(payload);
    if (res.status) {
      setData(
        res?.data?.data?.rows?.map((reservation) => ({
          hash: (
            <Link to={`${ReservationDetailRoute}?id=${reservation?.reservation_uuid}`}>
              {" "}
              {reservation?.reservation_id || null}
            </Link>
          ),
          primaryguest: reservation?.traveler_name
          ? (
            <Link to={`${ContactDetailRoute}?id=${reservation?.traveler_uuid}`}>
              {reservation?.traveler_name}
            </Link>
          ) : null,
          status: reservation?.reservation_status || null,
          total: reservation?.total
            ? `$${addCommasInPricing(reservation?.total?.toString())}`
            : "$0",
          balance: reservation?.balance
            ? `$${addCommasInPricing(reservation?.balance?.toString())}`
            : "$0",
          created: reservation?.reservation_created
            ? moment.unix(reservation?.reservation_created).format(DATE_FORMAT)
            : null,
        }))
      );
      setCount(res?.data?.data?.pager?.total_count || 0);
      setDataLoading(false);
    } else {
      setData([]);
      setDataLoading(false);
    }
  };

  const getEventReservationList = async (payload) => {
    setDataLoading(true);
    const res = await getReservationListingAPI(payload);
    if (res.status) {
      setDataLoading(false);
      setData(
        res?.data?.rows?.map((reservation) => ({
          hash: (
            <Link to={`${ReservationDetailRoute}?id=${reservation?.uuid}`}>
              {" "}
              {reservation?.id || null}
            </Link>
          ),
          primaryguest: reservation?.contact ? (
            <Link
              to={`${ContactDetailRoute}?id=${
                JSON.parse(reservation?.contact)?.uuid
              }`}
            >
              {getPrimaryTravelerName(JSON.parse(reservation?.contact))}
            </Link>
          ) : null,
          status: reservation?.reservation_status || null,
          total: reservation?.total
            ? `$${addCommasInPricing(reservation?.total?.toString())}`
            : "$0",
          balance: reservation?.balance
            ? `$${addCommasInPricing(reservation?.balance?.toString())}`
            : "$0",
          created: reservation?.reservation_created
            ? moment.unix(reservation?.reservation_created).format(DATE_FORMAT)
            : null,
        }))
      );
      setCount(res?.data?.pager?.total_items || 0);
    } else {
      setDataLoading(false);
      setData([]);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EventReservationHeader
              setUrlParamsObject={setUrlParamsObject}
              urlParamsObject={urlParamsObject}
              eventId={eventId}
              type={type}
              addonId={addonId}
              addonType={addonType}
            />
          </div>
          <Content>
            <EventReservationListing
              data={data}
              count={count}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              dataLoading={dataLoading}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default EventReservationsPage;
