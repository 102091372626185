import {
  CheckCircleOutlined,
  EditOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Switch } from "antd";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  EventsListingRoute,
  ReservationListingRoute,
} from "../../url-routes/urlRoutes";
import { permissionChecker } from "../../utils/permisssionChecker";

const DashboardTotal = ({ setIsToday, dashboardTotalCount, isToday }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Row
      gutter={16}
      style={{ marginBottom: "20px" }}
      className="dash-section dash-section-one"
    >
      <Col xs={12} sm={12} md={12} lg={6}>
        <Card className="card_one">
          <h5 style={{ paddingBottom: "12px", margin: "0" }}>
            <span>
              <EditOutlined />
            </span>
            Total Active Events
          </h5>
          <h2>{dashboardTotalCount?.total_active_events || 0}</h2>
          {permissionChecker(
            "view navigation menu dashboard view all button"
          ) ? (
            <Link to={EventsListingRoute}>View All</Link>
          ) : null}
        </Card>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6}>
        <Card className="card_one">
          <h5 style={{ paddingBottom: "12px", margin: "0" }}>
            <span>
              <TableOutlined />
            </span>
            Pending Events
          </h5>
          <h2>{dashboardTotalCount?.total_pending_events || 0}</h2>
          {permissionChecker(
            "view navigation menu dashboard view all button"
          ) ? (
            <Link
              to={`${EventsListingRoute}?count=10&order=ASC&page=1&tab=All+Events&event_status=design`}
            >
              View All
            </Link>
          ) : null}
        </Card>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6}>
        <Card className="card_one">
          <h5 style={{ paddingBottom: "12px", margin: "0" }}>
            <span>
              <UserOutlined />
            </span>
            Total Reservations
          </h5>
          <h2>{dashboardTotalCount?.total_reservations || 0}</h2>
          {permissionChecker(
            "view navigation menu dashboard view all button"
          ) ? (
            <Link to={ReservationListingRoute}>View All</Link>
          ) : null}
        </Card>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6}>
        <Card className="card_one">
          <h5>
            <span>
              <CheckCircleOutlined />
            </span>
            Total Booked Travelers
          </h5>
          <h2 style={{ position: "relative", paddingBottom: "1rem" }}>
            {dashboardTotalCount?.total_booked_travelers || 0}
          </h2>
          <div className="book__flex">
            {permissionChecker(
              "view navigation menu dashboard view all button"
            ) ? (
              <Link
                style={{ position: "relative", top: "-14px" }}
                to={`${ReservationListingRoute}?count=10&page=1&tab=reservation&order=ASC&status=active`}
              >
                View All{" "}
              </Link>
            ) : null}

            <div className="today_end">
              <Switch
                onChange={(e) => {
                  setIsToday(e);
                  searchParams.set("is_today", e);
                  setSearchParams(searchParams);
                }}
                checked={isToday}
              />
              <span style={{ marginTop: "0" }}>Today</span>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardTotal;
