import { Button } from 'antd';
import { EditOutlined, FileOutlined, DeleteOutlined, PaperClipOutlined, ArrowLeftOutlined, MoreOutlined, ImportOutlined, CloudDownloadOutlined, GlobalOutlined } from '@ant-design/icons';
import React from 'react';

function Breadcrumb_resactivityemail() {
    return (
        <div className="breadcrumb_content" style={{ width: '100%' }}>
            <div className='breadcrumb_heading' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
                {/* <div className='addnote_content'>
                    <div className='breadcrumb_title'>
                    <h3><ArrowLeftOutlined className="back-icon-profile details_icon" /> <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Edit Task: Email Confirmation (606737)</span> 

                        </h3>
                        
                    </div>

                </div> */}


                <h3 className='flex_row_column'>
                    <ArrowLeftOutlined className="back-icon-profile" />
                    <div className='header_res'>
                        <p>Edit Task: Email Confirmation (606737)</p>
                    </div>
                </h3>
                <div className="breadcrum_buttons" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'end' }}>
                    <Button>Cancel</Button>
                    <Button type="primary">Save</Button>
                </div>
            </div>
        </div>
    );
}
export default Breadcrumb_resactivityemail;


