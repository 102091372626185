import React from "react";
import { DatePicker, message } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  Description,
  UserOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
} from "antd";
import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
import Sidebar from "../Header/Header_sidebar";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
import { DATE_FORMAT, SOMETHING_WENT_WRONG } from "../../utils/constants";
import { useDebounce } from "../../others/Debounce/debounce";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../url-routes/urlRoutes";
import moment from "moment";
import { decodeContent } from "../../utils/contentParser";
import {
  getReservationEventListingApi,
  getReservationListingAPI,
  getReservationUsersListingApi,
} from "../../apis/reservationApi";
import { useDispatch } from "react-redux";
import {
  searchFilterApplied,
  searchFiltersSetter,
} from "../../redux/features/reservationFeatures/reservationSlice";
import { useSelector } from "react-redux";
import { statusList } from "../../others/util/commonFunctions";
const { useBreakpoint } = Grid;

const { RangePicker } = DatePicker;

function ReservationSearchBar({
  urlParamsObject,
  setUrlParamsObject,
  defaultSortOrder = "DESC",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeClass, setActiveClass] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(urlParamsObject?.name);
  const [reservationEventList, setReservationEventList] = useState([]);
  const [loadingResEvent, setLoadingResEvent] = useState(false);
  const [reservationUsers, setReservationsUsers] = useState([]);
  const [loadingResUsers, setLoadingResUsers] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { isFilterApplied } = useSelector((state) => state.reservation);
  const [form] = Form.useForm();

  useEffect(() => {
    getReservationUsers();
    getReservationEvents();
    form.setFieldsValue({
      res_id: urlParamsObject?.res_id || null,
      status: urlParamsObject?.status || null,
      contact_email: urlParamsObject?.contact_email || null,
      contact_name: urlParamsObject?.contact_name || null,
      created: [
        urlParamsObject?.createdMin
          ? moment(urlParamsObject?.createdMin, "YYYY-MM-DD")
          : null,
        urlParamsObject?.createdMax
          ? moment(urlParamsObject?.createdMax, "YYYY-MM-DD")
          : null,
      ],
      changed: [
        urlParamsObject?.changedMin
          ? moment(urlParamsObject?.changedMin, "YYYY-MM-DD")
          : null,
        urlParamsObject?.changedMax
          ? moment(urlParamsObject?.changedMax, "YYYY-MM-DD")
          : null,
      ],
      event: urlParamsObject?.event || null,
      cruise_book_no: urlParamsObject?.cruise_book_no || null,
      user_id: urlParamsObject?.user_id || null,
      travel_agent: urlParamsObject?.travel_agent || null,
    });
  }, []);

  useEffect(() => {
    if (
      urlParamsObject?.res_id ||
      urlParamsObject?.status ||
      urlParamsObject?.contact_email ||
      urlParamsObject?.contact_name ||
      urlParamsObject?.createdMin ||
      urlParamsObject?.createdMax ||
      urlParamsObject?.changedMin ||
      urlParamsObject?.changedMax ||
      urlParamsObject?.event ||
      urlParamsObject?.cruise_book_no ||
      urlParamsObject?.user_id ||
      urlParamsObject?.travel_agent
    ) {
      dispatch(searchFilterApplied(true));
      dispatch(
        searchFiltersSetter({
          Reservation: urlParamsObject?.res_id || null,
          Status: urlParamsObject?.status
            ? statusList.find(
                (status) => status.value === urlParamsObject?.status
              ).label
            : null,
          Email: urlParamsObject?.contact_email || null,
          "Traveler Name": urlParamsObject?.contact_name,
          "Created On":
            urlParamsObject?.createdMin && urlParamsObject?.createdMax
              ? `${moment(urlParamsObject?.createdMin, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )} - ${moment(urlParamsObject?.createdMax, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}`
              : null,
          "Modified On":
            urlParamsObject?.changedMin && urlParamsObject?.changedMax
              ? `${moment(urlParamsObject?.changedMin, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )} - ${moment(urlParamsObject?.changedMax, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}`
              : null,
          Event: urlParamsObject?.event
            ? reservationEventList?.find(
                (event) => event.id === urlParamsObject?.event
              )?.name
            : null,
          "Cruise Booking Number": urlParamsObject?.cruise_book_no || null,
          "Created By Staff": urlParamsObject?.user_id
            ? reservationUsers?.find(
                (user) => user.uid === urlParamsObject?.user_id
              )?.user_id
            : null,
          "Travel Agent": urlParamsObject?.travel_agent || null,
        })
      );
    } else {
      dispatch(searchFilterApplied(false));
      dispatch(searchFiltersSetter({}));
    }
  }, [reservationEventList, reservationUsers]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      let url = `items_per_page=10&sort_order=${defaultSortOrder}&name=${debouncedSearchTerm}`;
      getReservationSearchResult(url, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
    }
  }, [debouncedSearchTerm]);

  const getReservationEvents = async () => {
    setLoadingResEvent(true);
    const res = await getReservationEventListingApi();
    if (res.status) {
      setReservationEventList(res.data);
      setLoadingResEvent(false);
    } else {
      setReservationEventList([]);
      setLoadingResEvent(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const getReservationUsers = async () => {
    setLoadingResUsers(true);
    const res = await getReservationUsersListingApi();
    if (res.status) {
      setReservationsUsers(res.data);
      setLoadingResUsers(false);
    } else {
      setReservationsUsers([]);
      setLoadingResUsers(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const getReservationSearchResult = async (urlQuery, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }

    let response = await getReservationListingAPI({ urlQuery });
    if (response?.status) {
      let searchOptions = response?.data?.rows?.slice(0, 5)?.map((option) => {
        return {
          key: option?.uuid,
          value: (
            <span
              style={{ width: "100%", display: "block" }}
              onClick={() => {
                navigate(`${ReservationDetailRoute}?id=${option?.uuid}`);
                setAutoCompleteOpen(false);
              }}
            >
              {decodeContent(option?.name)}
            </span>
          ),
        };
      });
      if (searchOptions.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== ReservationListingRoute
                    ? navigate(
                        `${ReservationListingRoute}?count=10&order=${defaultSortOrder}&page=1&name=${searchVal}`
                      )
                    : setUrlParamsObject({
                        count: 10,
                        order: defaultSortOrder,
                        page: 1,
                        name: searchVal || "",
                        tab: "reservation",
                      });
                  setAutoCompleteOpen(false);
                }}
              >
                see all results for {searchVal || ""}
              </span>
            ),
          },
        ]);
        // if (urlParamsObject.name !== searchVal) {
        setAutoCompleteOpen(true);
        // }
        // setIsVisible(false);
      } else {
        setOptions([]);
        setAutoCompleteOpen(true);
        // setIsVisible(false);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      // setIsVisible(false);
      setAutoCompleteOpen(false);
    }
  };

  function isOnListingRoute() {
    return location.pathname !== ReservationListingRoute ? false : true;
  }
  const onFinish = (values) => {
    const urlObject = {
      ...values,
      changedMin: values?.changed?.[0]
        ? values?.changed?.[0]?.format("YYYY-MM-DD")
        : null,
      changedMax: values?.changed?.[1]
        ? values?.changed?.[1]?.format("YYYY-MM-DD")
        : null,
      createdMin: values?.created?.[0]
        ? values?.created?.[0]?.format("YYYY-MM-DD")
        : null,
      createdMax: values?.created?.[1]
        ? values?.created?.[1]?.format("YYYY-MM-DD")
        : null,
    };
    delete urlObject.changed;
    delete urlObject.created;
    for (let key in urlObject) {
      if (!urlObject[key]) {
        delete urlObject?.[key];
      }
    }
    const result = new URLSearchParams(urlObject).toString();
    if (!isOnListingRoute()) {
      navigate(
        `${ReservationListingRoute}?count=10&order=${defaultSortOrder}&page=1&${result}`
      );
    } else {
      setUrlParamsObject({
        count: 10,
        page: 1,
        tab: urlParamsObject?.tab || "reservation",
        order:
          urlParamsObject?.order === "recently_read"
            ? defaultSortOrder
            : urlParamsObject?.order,
        ...urlObject,
      });
    }
    setAutoCompleteOpen(false);
    dispatch(searchFilterApplied(true));
    dispatch(
      searchFiltersSetter({
        Reservation: urlObject?.res_id || null,
        Status: urlObject?.status
          ? statusList.find((status) => status.value === urlObject?.status)
              .label
          : null,
        Email: urlObject?.contact_email || null,
        "Traveler Name": urlObject?.contact_name,
        "Created On":
          urlObject?.createdMin && urlObject?.createdMax
            ? `${moment(urlObject?.createdMin, "YYYY-MM-DD").format(
                DATE_FORMAT
              )} - ${moment(urlObject?.createdMax, "YYYY-MM-DD").format(
                DATE_FORMAT
              )}`
            : null,
        "Modified On":
          urlObject?.changedMin && urlObject?.changedMax
            ? `${moment(urlObject?.changedMin, "YYYY-MM-DD").format(
                DATE_FORMAT
              )} - ${moment(urlObject?.changedMax, "YYYY-MM-DD").format(
                DATE_FORMAT
              )}`
            : null,
        Event: urlObject?.event
          ? reservationEventList?.find((event) => event.id === urlObject?.event)
              ?.name
          : null,
        "Cruise Booking Number": urlObject?.cruise_book_no || null,
        "Created By Staff": urlObject?.user_id
          ? reservationUsers?.find((user) => user.uid === urlObject?.user_id)
              ?.user_id
          : null,
        "Travel Agent": urlObject?.travel_agent || null,
      })
    );

    setIsVisible(false);
    setActiveClass(false);
  };

  const custumSearch = (
    <div className="custom_search">
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        <Form.Item label="Reservation" name="res_id" wrapperCol={{ span: 9 }}>
          <Input />
        </Form.Item>

        <Form.Item
          className="select-reservation_searchbox"
          name="status"
          wrapperCol={{ span: 8 }}
        >
          <Select
            placeholder="Status"
            options={statusList.map((status) => ({
              key: status.value,
              value: status.value,
              label: status.label,
            }))}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="contact_email"
          className="asterick-align"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          rules={[{ type: "email", message: "Please input a valid email" }]}
        >
          <Input
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Traveler Name"
          name="contact_name"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Created On"
          name="created"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <RangePicker
            style={{
              fontFamily: "'Poppins', sans-serif",
              maxWidth: "390px",
              height: "32px",
            }}
            format={DATE_FORMAT}
            disabledDate={(current) => current.isAfter(moment())}
          />
        </Form.Item>

        <Form.Item
          label="Modified On"
          name="changed"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <RangePicker
            style={{
              fontFamily: "'Poppins', sans-serif",
              maxWidth: "390px",
              height: "32px",
            }}
            format={DATE_FORMAT}
            disabledDate={(current) => current.isAfter(moment())}
          />
        </Form.Item>

        <Form.Item
          label="Event"
          name="event"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={reservationEventList.map((resEvent) => ({
              key: resEvent.uuid,
              value: resEvent.id,
              label: decodeContent(resEvent.name),
            }))}
            showSearch
            allowClear
            loading={loadingResEvent}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>

        <Form.Item
          label="Cruise Booking Number"
          name="cruise_book_no"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
            // placeholder="Enter Organization"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Created By Staff"
          name="user_id"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            // placeholder="Select Destination Type"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={reservationUsers.map((users) => ({
              key: users.uuid,
              value: users.uid,
              label: users.user_id,
            }))}
            showArrow
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Travel Agent"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="travel_agent"
        >
          <Input
            // placeholder="Enter Organization"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
            onClick={() => {
              form.resetFields();
              if (isOnListingRoute()) {
                setUrlParamsObject({
                  count: urlParamsObject?.count,
                  order: urlParamsObject?.order,
                  page: urlParamsObject?.page,
                  tab: urlParamsObject?.tab,
                });
              }
              setSearchValue("");
              // setIsVisible(false);
              dispatch(searchFilterApplied(false));
              dispatch(searchFiltersSetter({}));
            }}
          >
            Clear Filter
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );

  const handleSearch = (value) => {
    if (!value) {
      setAutoCompleteOpen(false);
      location.pathname !== ReservationListingRoute
        ? setSearchValue("")
        : setUrlParamsObject({ ...urlParamsObject, name: null });
      // return;
    }
    setSearchValue(value);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Search toggle for mobile
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align">
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              onClear={(e) => {
                setAutoCompleteOpen(false);
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!isOnListingRoute()) {
                    navigate(
                      `${ReservationListingRoute}?count=10&order=${defaultSortOrder}&page=1&name=${e.target.value}`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      page: 1,
                      order:
                        urlParamsObject?.order === "recently_read"
                          ? defaultSortOrder
                          : urlParamsObject?.order,
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                    // setIsFilterApplied(true);
                  }
                }
              }}
              open={autoCompleteOpen}
              placeholder="Search Reservations"
              size="large"
              value={
                urlParamsObject?.order === "recently_read" ? "" : searchValue
              }
            />

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              open={isVisible}
              trigger={["click"]}
              onOpenChange={(e) => {
                setActiveClass(e);
                setIsVisible(e);
              }}
              // className="advance-search-dropdown"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{
                      opacity: "0.5",
                      color: "#000",
                      backgroundColor: isFilterApplied
                        ? "rgba(26, 127, 147, 0.2)"
                        : "transparent",
                      //   backgroundColor: "#000",
                    }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default ReservationSearchBar;
