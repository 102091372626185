import { Col, Divider, Form, Row, Spin } from "antd";
import moment from "moment";
import React from "react";
import { addCommasInPricing } from "../../../../../../utils/contentParser";
import PaymentList from "../../../../../../utils/paymentListType.json";

function PlannedPaymentBasicDetail({ paymentData, loadingPaymentData }) {
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Planned Payment Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingPaymentData} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Payment Type" className="asterick-align" name="payment_type">
                <label>{PaymentList?.list?.find(val=> val.value === paymentData?.field_paymenttype?.name )?.name || null}</label>
              </Form.Item>
              <Form.Item label="Payment Name" className="asterick-align" name="payment_name"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Payment Name',
                  },
                ]}
              >
                <label>{paymentData?.field_payname || null}</label>
              </Form.Item>
              <Form.Item label="Payment Amount" className="asterick-align" name="payment_amount">
                <label>
                  {paymentData?.field_planamount
                    ? `$${addCommasInPricing(
                      paymentData?.field_planamount.toString()
                    )}`
                    : ""}
                </label>
              </Form.Item>
              <Form.Item label="Payment Method" className="asterick-align" name="payment_method">
                <label>{paymentData?.field_paymethod?.name || null}</label>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Payment Date"
                className="asterick-align"
                name="payment_date"
              >
                <label>
                  {paymentData?.field_paydate
                    ? moment(paymentData?.field_paydate, "YYYY-MM-DD").format(
                      "MM/DD/YYYY"
                    )
                    : ""}{" "}
                  {paymentData?.field_paydate
                    ? moment(paymentData?.field_paydate, "YYYY-MM-DD").fromNow()
                    : ""}
                </label>
              </Form.Item>
              <Form.Item label="Payment Status" className="asterick-align" name="payment_status">
                <label>
                  {paymentData?.field_planned_payment_status?.value
                    ? paymentData?.field_planned_payment_status?.value
                    : ""}
                </label>
              </Form.Item>
              <Form.Item label="Applied Amount" className="asterick-align" name="applied_amount">
                <label>
                  {`$${addCommasInPricing(
                    paymentData?.field_applied_amount?.toString() || 0
                  )}`}
                </label>
              </Form.Item>
              <Form.Item label="Pending Amount" className="asterick-align" name="pending_amount">
                <label>
                  {" "}
                  {`$${addCommasInPricing(
                    paymentData?.pending_amount?.toString() || "0"
                  )}`}
                </label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default PlannedPaymentBasicDetail;
