import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, TimePicker, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createEventApi,
  getSingleEventApi,
} from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import DynamicStartEndDate from "../../../../../others/StartEndDateValidator/DynamicStartEndDate";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../url-routes/urlRoutes";
import {
  ITINERARY_REQUIRED,
  SOMETHING_WENT_WRONG,
} from "../../../../../utils/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CreateOrEditItineraryContainer = ({
  eventId,
  form,
  isLoading,
  setIsLoading,
  itineraryType,
  addonId,
}) => {
  const navigate = useNavigate();
  const [itinerayData, setItineraryData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    getItineraryData(addonId || eventId);
  }, []);

  useEffect(() => {
    if (addonId || eventId) {
      form.setFieldsValue({
        field_itinerary: itinerayData?.length
          ? itinerayData?.map((itinerary) => ({
              field_day_number: itinerary?.field_day_number,
              field_start_date: itinerary?.field_start_date
                ? moment(itinerary?.field_start_date, "YYYY-MM-DD")
                : null,
              field_end_date: itinerary?.field_end_date
                ? moment(itinerary?.field_end_date, "YYYY-MM-DD")
                : null,
              field_location_name: itinerary?.field_location_name,
              field_arrive: itinerary?.field_arrive
                ? moment(itinerary?.field_arrive, "hh:mm a")
                : null,
              field_depart: itinerary?.field_depart
                ? moment(itinerary?.field_depart, "hh:mm a")
                : null,
            }))
          : [
              {
                field_day_number: null,
                field_location_name: null,
                field_arrive: null,
                field_depart: null,
                field_start_date: null,
                field_end_date: null,
              },
            ],
      });
    }
  }, [itinerayData]);

  let getItineraryData = async (eId) => {
    setIsDataLoading(true);
    const response = await getSingleEventApi(eId);
    if (response.status) {
      setItineraryData(
        response?.data?.data?.field_itinerary?.length
          ? response?.data?.data?.field_itinerary
          : []
      );
      setIsDataLoading(false);
    } else {
      setItineraryData([]);
      setIsDataLoading(false);
    }
  };

  let createOrEditItinerary = async (payload) => {
    setIsLoading(true);
    const response = await createEventApi(payload);
    if (response.status) {
      message.success("Itinerary Added Successfully");
      navigate(
        itineraryType === "event"
          ? `${EventDetailsRoute}?id=${eventId}#Itineraries`
          : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
      );
      setIsLoading(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setIsLoading(false);
    }
  };

  const onFinishHandler = (values) => {
    let data = {
      data: {
        type: "event--event",
        id: itineraryType === "event" ? eventId : addonId,
        attributes: {
          field_itinerary: values?.field_itinerary?.length
            ? values?.field_itinerary.map((itinerary) => ({
                ...itinerary,
                field_start_date: itinerary?.field_start_date
                  ? itinerary?.field_start_date.format("YYYY-MM-DD")
                  : null,
                field_end_date: itinerary?.field_end_date
                  ? itinerary?.field_end_date.format("YYYY-MM-DD")
                  : null,
                field_day_number: itinerary?.field_day_number
                  ? itinerary?.field_day_number
                  : null,
                field_location_name: itinerary?.field_location_name
                  ? itinerary?.field_location_name
                  : null,
                field_arrive: itinerary?.field_arrive
                  ? itinerary?.field_arrive.format("hh:mm A")
                  : null,
                field_depart: itinerary?.field_depart
                  ? itinerary?.field_depart.format("hh:mm A")
                  : null,
              }))
            : [],
        },
      },
    };

    if (
      isFormFieldFilled(values.field_itinerary).every(
        (element) => element === true
      )
    ) {
      createOrEditItinerary({
        data,
        uuid: itineraryType === "event" ? eventId : addonId,
        reqType: "patch",
      });
    } else {
      message.error(ITINERARY_REQUIRED);
    }
  };

  const isFormFieldFilled = (formArray) => {
    let flagArray = new Array(formArray.length).fill(false);
    formArray.forEach((form, index) => {
      Object.keys(form).forEach((formInput) => {
        if (
          form[formInput] !== null &&
          form[formInput] !== undefined &&
          form[formInput] !== ""
        ) {
          flagArray[index] = true;
        }
      });
    });
    return flagArray;
  };

  if (isDataLoading) {
    return <DataLoader />;
  }

  const validateEndDate = (e, value) => {
    if (e.field.includes("field_start_date")) {
      const startDate = value;
      const endDate = form.getFieldValue([
        "field_itinerary",
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: [
                "field_itinerary",
                e?.dependencies?.[0]?.[0],
                e?.dependencies?.[0]?.[1],
              ],
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: [
                  "field_itinerary",
                  e?.dependencies?.[0]?.[0],
                  e?.dependencies?.[0]?.[1],
                ],
                errors: ["End date cannot be less than the start date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue([
        "field_itinerary",
        e?.dependencies?.[0]?.[0],
        e?.dependencies?.[0]?.[1],
      ]);
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End date cannot be less than the start date");
        }
      }
    }
    return Promise.resolve();
  };
  return (
    <div className="">
      <div style={{ marginTop: "24px" }} />
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 5,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
        form={form}
        name="dynamic_form_complex"
        onFinish={onFinishHandler}
        autoComplete="off"
      >
        <Form.List
          name="field_itinerary"
          initialValue={[
            {
              field_day_number: null,
              field_location_name: null,
              field_arrive: null,
              field_depart: null,
              field_start_date: null,
              field_end_date: null,
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  align="baseline"
                  style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                >
                  {key > 0 ? (
                    <Form.Item>
                      <div className="close_relative">
                        <Button
                          className="close_absolute"
                          type="dashed"
                          onClick={() => remove(name)}
                          icon={<CloseOutlined />}
                        ></Button>
                      </div>
                    </Form.Item>
                  ) : null}

                  <Form.Item
                    label="Day Number"
                    {...restField}
                    name={[name, "field_day_number"]}
                    rules={[
                      {
                        required:true,
                        message:"Please add day number"
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <DynamicStartEndDate
                    form={form}
                    name={name}
                    restField={restField}
                    formListName={"field_itinerary"}
                    startDateLabel={"Start Date"}
                    endDateLabel={"End Date"}
                    startName={"field_start_date"}
                    endName={"field_end_date"}
                  />

                  <Form.Item
                    label="Location Name"
                    {...restField}
                    name={[name, "field_location_name"]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Arrive"
                    {...restField}
                    name={[name, "field_arrive"]}
                  >
                    <TimePicker use12Hours format="hh:mm A" />
                  </Form.Item>

                  <Form.Item
                    label="Depart"
                    {...restField}
                    name={[name, "field_depart"]}
                  >
                    <TimePicker use12Hours format="hh:mm A" />
                  </Form.Item>
                </div>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Itineraries
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};
export default CreateOrEditItineraryContainer;
