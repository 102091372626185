import { Table, Divider, Checkbox, Switch, Steps, Timeline } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GroupDetailsListingRoute,
  GroupDetails_DetailsRoute,
} from "../../../url-routes/urlRoutes";
import { DATE_FORMAT } from "../../../utils/constants";
import moment from "moment";

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: "Steps",
    dataIndex: "steps",
  },

  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Date",
    dataIndex: "date",
  },

  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const GroupDetailDescription = ({
  extensionList,
  preExtId,
  postExtId,
  navigateTo,
  groupDetail,
  onDeleteHandler,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const extensionOrder = ["Pre-Extension", "Main Tour", "Post-Extension"];
  const permissions = JSON.parse(
    localStorage.getItem("authorization")
  )?.permissions;

  const newData = extensionList?.map((item, index) => {
    const isLastItem = index === extensionList?.length - 1;
    const isMainTour = extensionList?.length > 1 && index === 2;
    const extensionName =
      item?.type === "pre"
        ? "Pre-Extension"
        : item?.type === "post"
        ? "Post-Extension"
        : item?.type.includes("Main")
        ? item?.type
        : "";
    if (item) {
      return {
        key: index + 1,
        name: (
          <>
            {isLastItem ? (
              <Timeline>
                <Timeline.Item className="timeline_class">
                  {extensionName}
                </Timeline.Item>
              </Timeline>
            ) : (
              <Timeline.Item className="timeline_class">
                {" "}
                {/* {`${item?.type}-Extension`} */}
                {/* {index !== 1 ? extensionName : item?.type} */}
                {extensionName}
              </Timeline.Item>
            )}
          </>
        ),
        date: item?.created_at
          ? moment(item?.created_at).format(DATE_FORMAT)
          : "",
        actions: (
          <>
            <div className="action_flex">
              {permissions.includes("add/edit group details") ? (
                <EditOutlined
                  onClick={() =>
                    navigate(
                      `${navigateTo}?editId=${groupDetail?.uuid}${
                        preExtId?.uuid ? `&preExtId=${preExtId?.uuid}` : ""
                      }${
                        postExtId?.uuid ? `&postExtId=${postExtId?.uuid}` : ""
                      }#${
                        extensionName?.includes("Main") ? tab : extensionName
                      }`
                    )
                  }
                  className="action_edit"
                  style={{
                    fontSize: "18px",
                    paddingTop: "11px",
                    paddingBottom: "14px",
                  }}
                />
              ) : null}

              {permissions.includes("delete group details") ? (
                <DeleteOutlined
                  className="action_delete"
                  onClick={(e) => {
                    console.log("deleted clicked");
                    e.preventDefault();
                    extensionName?.includes("Main")
                      ? onDeleteHandler(item?.uuid, "groupDetails")
                      : onDeleteHandler(item?.uuid, "extension");
                  }}
                  style={{
                    fontSize: "18px",
                    marginLeft: "11px",
                    paddingTop: "11px",
                    paddingBottom: "14px",
                  }}
                />
              ) : null}
              {permissions.includes("view group details") ? (
                <EyeOutlined
                  className="action_edit"
                  onClick={() => {
                    navigate(
                      `${GroupDetails_DetailsRoute}?id=${groupDetail?.uuid}${
                        preExtId?.uuid ? `&preExtId=${preExtId?.uuid}` : ""
                      }${
                        postExtId?.uuid ? `&postExtId=${postExtId?.uuid}` : ""
                      }&type=${tab?.toLowerCase()}#${
                        extensionName?.includes("Main") ? tab : extensionName
                      }`
                    );
                  }}
                  style={{
                    fontSize: "18px",
                    marginLeft: "11px",
                    paddingTop: "11px",
                    paddingBottom: "14px",
                  }}
                />
              ) : null}
            </div>
          </>
        ),
      };
    }
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={newData}
        size="middle"
        pagination={false}
        showHeader={false}
      />
    </div>
  );
};

export default GroupDetailDescription;
