import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import drupalConfig from "../../../../config/serverless/drupal.config";
import CustomDraggerUpload from "../../../../others/CustomUpload/CustomDraggerUpload";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import {
  contactDetailsEditLoader,
  updateMedicalAndDietary,
} from "../../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import "./MedicalInformation.less";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const EditMedicalAndDietaryInfo = ({ contactId, medicalDetailsData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { updateMedicalResponse, loadingUpdateMedical } = useSelector(
    (state) => state.contacts.updateMedical
  );
  const updateMedicalRef = useRef(false);
  const [vaccinationList, setVaccinationList] = useState([]);
  const [loadingVaccination, setLoadingVaccination] = useState(true);
  const [dietaryNeedList, setDietaryNeedList] = useState([]);
  const [loadingDietaryNeed, setLoadingDietaryNeed] = useState(true);
  const [medicalHistoryList, setMedicalHistoryList] = useState([]);
  const [loadingMedicalHistory, setLoadingMedicalHistory] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const {
    field_travel_insurance_company,
    field_policy_number,
    field_alcohol,
    field_smoking,
    field_medical_notes,
    field_additional_notes,
    field_vaccination,
    field_vaccination_certificate,
    field_dietary_need,
    // field_medical_history,
    field_step,
  } = medicalDetailsData || {};
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    if (updateMedicalRef.current) {
      if (updateMedicalResponse.status) {
        onClose();
        message.success("Medical And Dietary Information Updated");
      } else {
        message.error(
          updateMedicalResponse?.data?.response?.data?.message ||
            updateMedicalResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        onClose();
      }
    }
    updateMedicalRef.current = true;
  }, [updateMedicalResponse]);

  const showDrawer = () => {
    !!field_vaccination_certificate.length
      ? setFileList(
          field_vaccination_certificate.map((vaccinationCertificate) => ({
            uid: vaccinationCertificate.id,
            name: vaccinationCertificate.filename,
            url: `${drupalConfig.BASE_URL}${vaccinationCertificate.uri.url}`,
            thumbUrl: `${drupalConfig.BASE_URL}${vaccinationCertificate.uri.url}`,
          }))
        )
      : setFileList([]);
    form.setFieldsValue({
      field_travel_insurance_company,
      field_policy_number,
      field_alcohol,
      field_smoking,
      field_medical_notes,
      field_additional_notes,

      field_vaccination: !!field_vaccination.length
        ? field_vaccination?.map((vaccine) => vaccine.id)
        : [],
      field_dietary_need: !!field_dietary_need.length
        ? field_dietary_need?.map((dietary) => dietary.id)
        : [],
      // field_medical_history: !!field_medical_history.length
      //   ? field_medical_history?.map((medical) => medical.id)
      //   : [],
    });
    getTaxonomyList("vaccination", setVaccinationList, setLoadingVaccination);
    getTaxonomyList("dietary_need", setDietaryNeedList, setLoadingDietaryNeed);
    getTaxonomyList(
      "medical_history",
      setMedicalHistoryList,
      setLoadingMedicalHistory
    );
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinishHandler = (values) => {
    const payload = {
      data: {
        type: "contact--contact",
        id: contactId,
        attributes: {
          field_travel_insurance_company:
            values?.field_travel_insurance_company || null,
          field_policy_number: values.field_policy_number,
          field_alcohol: values?.field_alcohol,
          field_smoking: values?.field_smoking,
          field_medical_notes: values?.field_medical_notes || null,
          field_additional_notes: values?.field_additional_notes || null,
          field_step,
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_vaccination: {
        data: !!values.field_vaccination.length
          ? values.field_vaccination.map((vaccine) => ({
              type: "taxonomy_term--vaccination",
              id: vaccine,
            }))
          : [],
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_vaccination_certificate: {
        data: !!fileList.length
          ? fileList.map((file) => ({
              type: "file--file",
              id: file.uid,
            }))
          : [],
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_dietary_need: {
        data: !!values.field_dietary_need.length
          ? values.field_dietary_need.map((dietaryNeed) => ({
              type: "taxonomy_term--dietary_need",
              id: dietaryNeed,
            }))
          : [],
      },
    };

    payload.data.relationships = {
      ...payload.data.relationships,
      // commented this code as per requirement
      // field_medical_history: {
      //   data: !!values.field_medical_history.length
      //     ? values.field_medical_history.map((medicalHistory) => ({
      //         type: "taxonomy_term--medical_history",
      //         id: medicalHistory,
      //       }))
      //     : [],
      // },
    };

    const reqHeaders = customRequestHeader({ token: Token });

    dispatch(updateMedicalAndDietary({ payload, reqHeaders, contactId }));
  };

  return (
    <>
      {permissionChecker("add/edit contact") ? (
        <Button onClick={showDrawer} type="y" icon={<EditOutlined />} />
      ) : null}

      <Drawer
        title="Edit Medical & Dietary Information"
        maxWidth={425}
        onClose={onClose}
        maskClosable={false}
        closable={!isLoading}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button
              onClick={() => {
                dispatch(contactDetailsEditLoader(false));
                form.submit();
              }}
              type="primary"
              loading={loadingUpdateMedical}
            >
              Save
            </Button>
          </Space>
        }
        //  style={{ maxWidth: '425px' }}
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="field_travel_insurance_company"
                label="Travel Insurance Company"
              >
                <Input />
              </Form.Item>

              <Form.Item name="field_policy_number" label="Policy Number">
                <Input />
              </Form.Item>

              <Form.Item
                label="Vaccination"
                name="field_vaccination"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  loading={loadingVaccination}
                  optionLabelProp="label"
                  options={vaccinationList.map((vaccination) => ({
                    key: vaccination.id,
                    value: vaccination.id,
                    label: vaccination.name,
                  }))}
                  showArrow
                />
              </Form.Item>

              <Form.Item
                label="Vaccination Certificate"
                name="field_vaccination_certificate"
              >
                <CustomDraggerUpload
                  fileList={fileList}
                  setFileList={setFileList}
                  isMultiple={true}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/contact/contact/field_vaccination_certificate"
                  supportedFormat={["png", "jpg/jpeg", "svg"]}
                />
              </Form.Item>

              <Form.Item name="field_dietary_need" label="Dietary Need">
                <Select
                  mode="multiple"
                  showArrow
                  loading={loadingDietaryNeed}
                  options={dietaryNeedList.map((dietaryNeed) => ({
                    key: dietaryNeed.id,
                    value: dietaryNeed.id,
                    label: dietaryNeed.name,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Form.Item
                className="swith_form"
                label="Alcohol"
                name="field_alcohol"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={18}>
              <Form.Item
                className="swith_form"
                label="Smoking"
                name="field_smoking"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Medical History"
                name="field_medical_history"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  optionLabelProp="label"
                  showArrow
                  loading={loadingMedicalHistory}
                  options={medicalHistoryList.map((medicalHistory) => ({
                    key: medicalHistory.id,
                    value: medicalHistory.id,
                    label: medicalHistory.name,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Medical Notes" name="field_medical_notes">
                <Input.TextArea
                  rows={4}
                  // placeholder="Please enter Guest Notes"
                />
              </Form.Item>
              <Form.Item label="Additional Notes" name="field_additional_notes">
                <Input.TextArea
                  rows={4}
                  // placeholder="Please enter Guest Notes"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default EditMedicalAndDietaryInfo;
