import { Button } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

function Breadcrumb_elitetravelers() {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className='flex_row_column'>
          <div className='header_res'>
            <p>Manage Elite Travelers</p>
          </div>
        </h3>
        {/* <div className="breadcrum_buttons">
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Link to="/create-report"><PlusOutlined /> Create Report{" "}</Link>
          </Button>
        </div> */}
      </div>
    </div>
  );
}
export default Breadcrumb_elitetravelers;
