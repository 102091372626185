import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Switch, } from 'antd';
import { Card, Divider, Anchor } from "antd";
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import React, { useState } from 'react';
const { Option } = Select;

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};


const handleChange = (value) => {
  console.log(`selected ${value}`);
};


const { TextArea } = Input;
const { Dragger } = Upload;
const props = {
  action: '//jsonplaceholder.typicode.com/posts/',
  listType: 'picture',
  previewFile(file) {
    console.log('Your upload file:', file);
    // Your process logic. Here we just mock to the same file
    return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
      method: 'POST',
      body: file,
    })
      .then((res) => res.json())
      .then(({ thumbnail }) => thumbnail);
  },
};

function TaskDocument_link() {

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const { Search } = Input;

  const onSearch = (value) => console.log(value);
  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };






  return (

    <>
      <div onClick={showDrawer}> Link</div>

      <Drawer
        title="Attach Link"
        maxWidth={425}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: '32px' }}
        extra={
          <Space>
            <Button onClick={onClose} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Link Path"
                name="link_path"
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Date',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Link Name"
                name="link_name"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </Drawer>
    </>

  );
}
export default TaskDocument_link;



