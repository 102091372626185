import { Grid, Layout } from "antd";
import React from "react";
import ManagePermissionsContainer from "../../../components/Admin/ManagePermissions/ManagePermissionsContainer";
import ManagePermissionsHeader from "../../../components/Admin/ManagePermissions/ManagePermissionsHeader";
import ManagePermissionsSearchbar from "../../../components/Admin/ManagePermissions/ManagePermissionsSearchbar";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
const { Header, Content, Sider } = Layout;

const ManagePermissionsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Permissions", path: "" },
  ];
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ManagePermissionsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          <div className="events-header">
            <ManagePermissionsHeader />
          </div>
          <Content className="site-layout-background">
            <ManagePermissionsContainer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ManagePermissionsPage;
