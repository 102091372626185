import React, { useEffect, useRef, useState } from "react";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Switch,
} from "antd";
import { Country } from "country-state-city";
import { getOrganizationListApi } from "../../../../../../apis/contactApi";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import { customRequestHeader } from "../../../../../../utils/cutomRequestHeader";
import MobileInput from "../../../../../Custom/MobileInput";
import "./CruiseDetailsForm.less";
import { getEventPartnersList } from "../../../../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CruiseDetailsForm = ({ onFinishHandler, form, isLoading }) => {
  const dispatch = useDispatch();
  const { eventPartners } = useSelector((state) => state.events);
  const { eventPartnersResponse, loadingEventPartners } = eventPartners;
  const countriesList = Country.getAllCountries();
  const [shipList, setShipList] = useState([]);
  const [loadingShipList, setLoadingShipList] = useState(false);
  const [cruiseItineraryList, setCruiseItineraryList] = useState([]);
  const [loadingCruiseItinerary, setLoadingCruiseItinerary] = useState(false);
  const [cruiseLineList, setCruiseLineList] = useState([]);
  const [loadingCruiseLine, setLoadingCruiseLine] = useState(false);
  const [eventPartnersList, setEventPartnersList] = useState([]);
  const eventPartnersRef = useRef(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    dispatch(getEventPartnersList());
    // getOrganizationList();
    getTaxonomyList("ship", setShipList, setLoadingShipList);
    getTaxonomyList(
      "cruise_itinerary",
      setCruiseItineraryList,
      setLoadingCruiseItinerary
    );
    getTaxonomyList("cruise_line", setCruiseLineList, setLoadingCruiseLine);
  }, []);

  useEffect(() => {
    if (eventPartnersRef.current) {
      if (eventPartnersResponse.status) {
        setEventPartnersList(eventPartnersResponse.data.data);
      } else {
        setEventPartnersList([]);
      }
    }
    eventPartnersRef.current = true;
  }, [eventPartnersResponse]);

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Cruise Details</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <div style={{ marginTop: "24px" }} />

      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_one"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.Item label="Event Partner" name="field_event_partner">
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              mode="multiple"
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={eventPartnersList?.map((eventPartners) => ({
                key: eventPartners?.drupal_internal__nid,
                value: eventPartners?.id,
                label: eventPartners?.title,
              }))}
              loading={loadingEventPartners}
            />
          </Form.Item>
          <Form.Item
            label="Event Name"
            className="asterick-align"
            name="field_event_name"
            rules={[{ required: true, message: "Please fill Event Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Event Code"
            className="asterick-align"
            name="field_event_code"
            rules={[{ required: true, message: "Please fill Event Code" }]}
          >
            <Input />
          </Form.Item>
          <MobileInput
            label={"Toll Free Phone Number"}
            extension={"field_tollfree_extension"}
            phoneName={"field_tollfree_phone"}
            form={form}
          />
          <Form.List
            name="field_event_details"
            initialValue={[
              {
                field_requested_dates: null,
                field_alternative_dates: null,
                field_are_dates_flexible: null,
                field_length_of_cruise: null,
                field_event_exclusive: null,
                field_tollfree_phone_extension: "+1",
                field_toll_free_phone: null,
                field_url_address: null,
                field_sailing: null,
                field_cruise_line: null,
                field_ship: null,
                field_cruise_itinerary: null,
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                  >
                    {key > 0 ? (
                      <Form.Item>
                        <div className="close_relative">
                          <Button
                            className="close_absolute"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </Form.Item>
                    ) : null}

                    <Form.Item
                      label="Requested Dates"
                      {...restField}
                      name={[name, "field_requested_dates"]}
                    >
                      <RangePicker
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          height: "32px",
                        }}
                        format={"MM/DD/YYYY"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Alternative Dates"
                      {...restField}
                      name={[name, "field_alternative_dates"]}
                    >
                      <RangePicker
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          height: "32px",
                        }}
                        format={"MM/DD/YYYY"}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Are Dates Flexible?"
                      {...restField}
                      name={[name, "field_are_dates_flexible"]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Cruise Line"
                      className="asterick-align"
                      {...restField}
                      name={[name, "field_cruise_line"]}
                    >
                      <Select
                      showSearch
                      showArrow
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                        options={cruiseLineList?.map((cruiseLine) => ({
                          key: cruiseLine.id,
                          value: cruiseLine.drupal_internal__tid,
                          label: cruiseLine.name,
                        }))}
                        loading={loadingCruiseLine}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      className="asterick-align"
                      label="Ship"
                      {...restField}
                      name={[name, "field_ship"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={shipList?.map((ship) => ({
                          key: ship.id,
                          value: ship.drupal_internal__tid,
                          label: ship.name,
                        }))}
                        loading={loadingShipList}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      className="asterick-align"
                      label="Cruise Itinerary"
                      {...restField}
                      name={[name, "field_cruise_itinerary"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={cruiseItineraryList?.map(
                          (cruiseItinerary) => ({
                            key: cruiseItinerary.id,
                            value: cruiseItinerary.drupal_internal__tid,
                            label: cruiseItinerary.name,
                          })
                        )}
                        loading={loadingCruiseItinerary}
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item
                      className="asterick-align"
                      label="Length of Cruise"
                      {...restField}
                      name={[name, "field_length_of_cruise"]}
                    >
                      <Input
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="asterick-align"
                      label="Event Exclusive"
                      {...restField}
                      name={[name, "field_event_exclusive"]}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>

                    <Form.Item
                      label="URL Address"
                      className="asterick-align"
                      {...restField}
                      name={[name, "field_url_address"]}
                      rules={[
                        {
                          type: "url",
                          message: "Invalid url",
                          validateTrigger: "onSubmit",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Cruise Line Group # / Sailing #"
                      {...restField}
                      name={[name, "field_sailing"]}
                    >
                      <Input
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Cruise Details
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </div>
  );
};

export default CruiseDetailsForm;
