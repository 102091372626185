import { Table, Divider, Checkbox, message } from "antd";
import React, { useEffect, useState } from "react";
import { getCategoriesByAccIdApi } from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { useSearchParams } from "react-router-dom";
import {
  addCommasInPricing,
  decodeContent,
} from "../../../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const AccomodationCategoryTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryListData, setCategoryListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accId = searchParams.get("accId");
  const type = searchParams.get("type");

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    const response = await getCategoriesByAccIdApi(accId, type);
    if (response?.status) {
      setCategoryListData(response?.data);
    } else {
      setCategoryListData([]);
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "field_category_code",
    },

    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Display Price	",
      dataIndex: "field_display_price",
    },

    {
      title: "Display Order	",
      dataIndex: "field_display_order",
    },

    {
      title: "Agent Display?	",
      dataIndex: "field_agent_display",
      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Checkbox checked={record} disabled />
            </div>
          </>
        );
      },
    },

    {
      title: "Web Enabled?",
      dataIndex: "field_web_enabled",
      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Checkbox checked={record} disabled />
            </div>
          </>
        );
      },
    },
  ];

  const categoryData = categoryListData?.map((category) => {
    return {
      key: category?.id,
      field_category_code: category?.field_category_code || "",
      name: decodeContent(category?.name) || "",
      field_display_price: category?.field_display_price
        ? addCommasInPricing(category?.field_display_price)
        : "",
      field_display_order: category?.field_display_order || "",
      field_agent_display: category?.field_agent_display || false,
      field_web_enabled: category?.field_web_enabled || false,
    };
  });

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">{`Categories (${
          categoryData?.length || "0"
        })`}</h4>
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={categoryData}
        size="small"
        pagination={false}
        loading={{ indicator: <DataLoader />, spinning: loading }}
      />
    </div>
  );
};
export default AccomodationCategoryTable;
