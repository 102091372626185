import {
  CloseOutlined,
  FilterOutlined,
  LeftOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllEventsWrapperApi,
  getEventSearchDropdownApi,
} from "../../apis/eventsApi";
import { useDebounce } from "../../others/Debounce/debounce";
import {
  eventSearchFilterApplied,
  eventSearchFilterSetter,
} from "../../redux/features/eventFeatures/eventsSlice";
import { EventsListingRoute } from "../../url-routes/urlRoutes";
import {
  ALL_EVENT_STATUS_FOR_SEARCH,
  SOMETHING_WENT_WRONG,
} from "../../utils/constants";
import { decodeContent } from "../../utils/contentParser";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
import Sidebar from "../Header/Header_sidebar";
const { Option } = Select;
const { useBreakpoint } = Grid;

const EventsSearchbar = ({ urlParamsObject, setUrlParamsObject }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const DROPDOWN_LIST = [];
  const [activeClass, setActiveClass] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(urlParamsObject?.name);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { isFilterApplied } = useSelector((state) => state.events);
  const [ships, setShips] = useState([]);
  // const [isShipsLoading, setIsShipsLoading] = useState(false);
  const [destinations, setDestinations] = useState([]);
  // const [isDestinationsLoading, setIsDestinationsLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [form] = Form.useForm();

  // Search toggle for mobile
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";
  // lg is one of the elements returned if screenwidth exceeds 991
  const { lg } = useBreakpoint();
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  function isOnListingRoute() {
    return location.pathname !== EventsListingRoute ? false : true;
  }

  useEffect(() => {
    // if (activeClass && !ships?.length && !destinations?.length) {
    getListItems(
      ["ships", "destinations", "organizations"],
      [setShips, setDestinations, setOrganizations]
    );
    form.setFieldsValue({
      destination: urlParamsObject?.destination || null,
      ship: urlParamsObject?.ship || null,
      organization: urlParamsObject?.organization
        ? urlParamsObject?.organization
        : null,
      event_status: urlParamsObject?.event_status?.split(",") || [],
    });
    // }
  }, []);

  useEffect(() => {
    if (
      urlParamsObject?.destination ||
      urlParamsObject?.ship ||
      urlParamsObject?.organization ||
      urlParamsObject?.event_status
    ) {
      dispatch(eventSearchFilterApplied(true));
      dispatch(
        eventSearchFilterSetter({
          Destination: urlParamsObject?.destination
            ? destinations.find(
              (dest) => dest.id === urlParamsObject?.destination
            )?.name
            : null,
          Ship: urlParamsObject?.ship
            ? ships.find((ship) => ship.id === urlParamsObject?.ship)?.name
            : null,
          Organization: urlParamsObject?.organization
            ? organizations.find(
              (org) => org.id === urlParamsObject?.organization
            )?.name
            : null,
          "Event Status": urlParamsObject?.event_status
            ? ALL_EVENT_STATUS_FOR_SEARCH?.filter((item) =>
              urlParamsObject?.event_status.includes(item.value)
            )
              .map((item) => item.label)
              .join(", ")
            : null,
        })
      );
    } else {
      dispatch(eventSearchFilterApplied(false));
      dispatch(eventSearchFilterSetter({}));
    }
  }, [ships, destinations, organizations]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      let url = `items_per_page=10&sort_order=ASC&name=${debouncedSearchTerm}`;
      getEventSearchResult(url, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
      // location.pathname === EventsListingRoute &&
      //   setUrlParamsObject({
      //     ...urlParamsObject,
      //     name: null,
      //   });
    }
  }, [debouncedSearchTerm]);

  const getEventSearchResult = async (urlQuery, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }

    let response = await getAllEventsWrapperApi(urlQuery);
    if (response?.status) {
      let searchOptions = response?.data?.[0]?.all_events?.rows
        ?.slice(0, 5)
        ?.map((option) => {
          return {
            key: option?.uuid,
            value: (
              <span
                style={{ width: "100%", display: "block" }}
                onClick={() => {
                  navigate(`/event/details?id=${option?.uuid}`);
                  setAutoCompleteOpen(false);
                }}
              >
                {decodeContent(option?.name)}
              </span>
            ),
          };
        });
      if (searchOptions.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== EventsListingRoute
                    ? navigate(
                      `${EventsListingRoute}?count=10&order=ASC&page=1&tab=All Events&name=${searchVal}`
                    )
                    : setUrlParamsObject({
                      count: 10,
                      order: "ASC",
                      page: 1,
                      tab: "All Events",
                      // search: true,
                      name: searchVal || "",
                    });
                  setAutoCompleteOpen(false);
                }}
              >
                see all results for {searchVal || ""}
              </span>
            ),
          },
        ]);
        // if (urlParamsObject.name !== searchVal) {
        setAutoCompleteOpen(true);
        // }
        // setIsVisible(false);
      } else {
        setOptions([]);
        setAutoCompleteOpen(true);
        // setIsVisible(false);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      // setIsVisible(false);
      setAutoCompleteOpen(false);
    }
  };

  const onInputClear = () => {
    setAutoCompleteOpen(false);
    location.pathname !== EventsListingRoute
      ? setSearchValue("")
      : setUrlParamsObject({ ...urlParamsObject, name: null });
  };

  const onFinish = (values) => {
    for (let key in values) {
      if (!values[key]) {
        delete values?.[key];
      }
    }
    const result = new URLSearchParams(values).toString();

    if (result?.length) {
      if (!isOnListingRoute()) {
        navigate(
          `${EventsListingRoute}?count=10&order=ASC&page=1&tab=All Events&${result}`
        );
      } else {
        const event_status = values?.event_status?.toString();
        setUrlParamsObject({
          ...urlParamsObject,
          page: 1,
          order:
            urlParamsObject?.order === "recently_read"
              ? "ASC"
              : urlParamsObject?.order,
          ...values,
          event_status,
        });
      }
      setAutoCompleteOpen(false);
      // setIsFilterApplied(true);
      dispatch(eventSearchFilterApplied(true));
      dispatch(
        eventSearchFilterSetter({
          Destination: values?.destination
            ? destinations.find((dest) => dest.id === values?.destinations)
              ?.name
            : null,
          Ship: values?.ship
            ? ships.find((ship) => ship.id === values?.ship)?.name
            : null,
          Organization: values?.organization
            ? organizations.find((org) => org.id === values?.organization)?.name
            : null,
          "Event Status": values?.event_status
            ? ALL_EVENT_STATUS_FOR_SEARCH?.filter((item) =>
              values?.event_status.includes(item.value)
            )
              .map((item) => item.label)
              .join(", ")
            : null,
        })
      );
    }
    setIsVisible(false);
    setActiveClass(false);
  };

  const getListItems = async (types, states) => {
    const response = await Promise.all(
      types?.map((type) => getEventSearchDropdownApi(type))
    );
    response.forEach((item, index) => {
      states[index](item?.data);
    });
  };

  const custumSearch = (
    <div className="custom_search searchbox__dropdown">
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        <Form.Item
          label="Destination"
          name="destination"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={
              destinations?.length
                ? destinations.map((item) => {
                  return {
                    key: item?.id,
                    id: item?.uuid,
                    value: item?.id,
                    label: item?.name,
                  };
                })
                : []
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
          ></Select>
        </Form.Item>

        <Form.Item
          label="Ship"
          name="ship"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={
              ships?.length
                ? ships.map((item) => {
                  return {
                    key: item?.id,
                    id: item?.uuid,
                    value: item?.id,
                    label: item?.name,
                  };
                })
                : []
            }
            showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            allowClear
          />
        </Form.Item>

        <Form.Item
          label="Organization"
          name="organization"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            // placeholder="Select Destination Type"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={
              organizations?.length
                ? organizations.map((item) => {
                  return {
                    key: item?.id,
                    id: item?.uuid,
                    value: item?.id,
                    label: item?.name,
                  };
                })
                : []
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
          />
        </Form.Item>

        <Form.Item
          label="Event Status"
          name="event_status"
          style={{
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          <Select
            mode="multiple"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            showArrow
            // optionLabelProp="label"
            options={
              ALL_EVENT_STATUS_FOR_SEARCH?.length
                ? ALL_EVENT_STATUS_FOR_SEARCH?.map((item, index) => ({
                  key: index,
                  value: item?.value,
                  label: item?.label,
                }))
                : []
            }
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          >
            {/* <Option value="complete" label="Include Completed">
              <div className="demo-option-label-item">Include Completed</div>
            </Option>

            <Option value="lock" label="Include Locked">
              <div className="demo-option-label-item">Include Locked</div>
            </Option>

            <Option value="design" label="Include Design">
              <div className="demo-option-label-item">Include Design</div>
            </Option>

            <Option value="cancel" label="Include Canceled">
              <div className="demo-option-label-item">Include Canceled</div>
            </Option>

            <Option value="archive" label="Include Archived">
              <div className="demo-option-label-item">Include Archived</div>
            </Option> */}
          </Select>
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
            onClick={() => {
              form.resetFields();
              // setIsFilterApplied(false);
              if (isOnListingRoute()) {
                setUrlParamsObject({
                  count: urlParamsObject?.count,
                  order: urlParamsObject?.order,
                  page: urlParamsObject?.page,
                  tab: "All Events",
                });
              }
              setSearchValue("");
              dispatch(eventSearchFilterApplied(false));
              dispatch(eventSearchFilterSetter({}));
              // setIsVisible(false);
            }}
          >
            Clear Filter
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );

  const handleSearch = (value) => {
    if (!value) {
      setAutoCompleteOpen(false);
      location.pathname !== EventsListingRoute
        ? setSearchValue("")
        : setUrlParamsObject({ ...urlParamsObject, name: null });
      // return;
    }
    setSearchValue(value);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align">
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              // onSelect={onSelect}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              onClear={(e) => {
                setAutoCompleteOpen(false);
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!isOnListingRoute()) {
                    navigate(
                      `/events/listing?count=10&order=ASC&page=1&name=${e.target.value}&tab=All Events`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      page: 1,
                      order:
                        urlParamsObject?.order === "recently_read"
                          ? "ASC"
                          : urlParamsObject?.order,
                      tab: "All Events",
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                    // setIsFilterApplied(true);
                  }
                }
              }}
              open={autoCompleteOpen}
              placeholder="Search Events"
              size="large"
              value={searchValue}
            // >
            // {/* <Input.Search */}
            // value={
            //   urlParamsObject?.order === "recently_read"
            //     ? ""
            //     : urlParamsObject?.name
            // }
            // enterButton
            // size="large"
            // placeholder="Search Events"
            // onSearch={handleSearch}
            />
            {/* </AutoComplete> */}

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              open={isVisible}
              trigger={["click"]}
              onOpenChange={(e) => {
                setActiveClass(e);
                setIsVisible(e);
              }}
            // className="advance-search-dropdown"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                  // setIsVisible(!isVisible);
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{
                      opacity: "0.5",
                      color: "#000",
                      backgroundColor: isFilterApplied
                        ? "rgba(26, 127, 147, 0.2)"
                        : "transparent",
                      //   backgroundColor: "#000",
                    }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
};

export default EventsSearchbar;
