import { Table } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import DataLoader from "../../../others/Loaders/DataLoader";
import { decodeContent } from "../../../utils/contentParser";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (record, values) => {
      return (
        <>
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                position: "relative",
                margin: "0",
                opacity: "0.5",
              }}
            >
              {values.key}
            </p>
            <p>{decodeContent(record)}</p>
          </div>
        </>
      );
    },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: (record) => {
      return (
        <>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {record && (
              <PhoneOutlined
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            )}
            <p>{record}</p>
          </div>
        </>
      );
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (record) => {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            {record && <MailOutlined style={{ paddingTop: "4px" }} />}
            <p>{record}</p>
          </div>
        </>
      );
    },
  },
];

const OrganizationPrimaryContactsTable = ({
  primaryContactsList,
  primaryContactInfo,
  setPrimaryContactInfo,
  loading,
  setPrimaryContactId,
  isLoading
}) => {
  let [selectedPrimaryContact, setSelectedPrimaryContact] = useState(
    primaryContactInfo?.id ? [`${primaryContactInfo?.id}`] : []
  );
  let data = primaryContactsList?.map((primaryContact) => {
    const {
      name,
      field_email_address,
      id,
      uuid,
      field_cell_phone,
      field_cell_phone_extension,
      field_other_phone_extension,
      field_other_phone,
    } = primaryContact;
    return {
      id: uuid || "",
      key: id || "",
      name: name || "",
      phone: `${
        field_cell_phone
          ? `${field_cell_phone_extension}-${field_cell_phone}`
          : ""
      }${
        field_other_phone
          ? `, ${field_other_phone_extension}-${field_other_phone}`
          : ""
      }`,
      email: field_email_address || "",
    };
  });

  const onChangeHandler = (value, valuesObj) => {
    setSelectedPrimaryContact([valuesObj?.[0]?.key]);
    setPrimaryContactId(valuesObj?.[0]?.id);
  };

  if (isLoading) {
    return <DataLoader />;
  }
  return (
    <>
    {primaryContactsList?.length ? (
      <Table
      className="modal_table"
      rowSelection={{
        type: "radio",
        onChange: onChangeHandler,
        selectedRowKeys: selectedPrimaryContact,
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ y: 250 }}
    /> 
    ): null}</>
  );
};
export default OrganizationPrimaryContactsTable;
