import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomDraggerUpload from "../../../../../others/CustomUpload/CustomDraggerUpload";
import { useSearchParams } from "react-router-dom";
import {
  createAssetsApi,
  getAssetDetailApi,
} from "../../../../../apis/eventsApi";
import {
  createOrEditEvent,
  triggerIsAssetUpdated,
} from "../../../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch, useSelector } from "react-redux";
import drupalConfig from "../../../../../config/serverless/drupal.config";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

function AddAssetsForm({
  assetType,
  documentTypes,
  docTypesLoading,
  eventData,
  eventId,
  addonId,
  showMessageForAddOnly,
}) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <>
      {permissionChecker("add/edit event assets") ? <Button type="primary" onClick={showDrawer}>
        <PlusOutlined /> Add Asset
      </Button> : null}

      <AssetsDrawer
        documentTypes={documentTypes}
        docTypesLoading={docTypesLoading}
        eventData={eventData}
        eventId={eventId}
        open={open}
        showDrawer={showDrawer}
        showMessageForAddOnly={showMessageForAddOnly}
        assetType={assetType}
        addonId={addonId}
        setOpen={setOpen}
      />
    </>
  );
}

export default AddAssetsForm;

export const AssetsDrawer = ({
  assetType,
  documentTypes,
  showMessageForAddOnly,
  eventData,
  eventId,
  addonId,
  open,
  assetId,
  setAssetId,
  setOpen,
}) => {
  const { field_assets } = eventData || {};
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addOrEditLoading, setAddOrEditLoading] = useState(false);
  const [assestDetail, setAssestDetail] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const { eventCreationResponse } = useSelector(
    (state) => state.events.eventCreation
  );
  const assetsRef = useRef();

  useEffect(() => {
    if (assetsRef.current && showMessageForAddOnly) {
      if (eventCreationResponse.status) {
        message.success("Assest Added Successfully");
      } else {
        message.error(
          eventCreationResponse?.data?.message || SOMETHING_WENT_WRONG
        );
      }
    }
    assetsRef.current = true;
  }, [eventCreationResponse]);

  useEffect(() => {
    if (assetId) {
      getAssetDetail(assetId);
    }
  }, [assetId]);

  const getAssetDetail = async (assestId) => {
    setIsAssetLoading(true);
    const res = await getAssetDetailApi(assestId);
    if (res?.status) {
      const detail = res?.data?.data;
      const {
        field_document_type,
        field_confirmation_attachment,
        field_request,
        field_media_file,
      } = detail || {};
      form?.setFieldsValue({
        field_document_type: field_document_type?.id,
        field_confirmation_attachment,
        field_request,
      });
      const fileData = {
        uid: field_media_file.id,
        name: field_media_file.filename,
        url: `${drupalConfig.BASE_URL}${field_media_file.uri.url}`,
        thumbUrl: `${drupalConfig.BASE_URL}${field_media_file.uri.url}`,
      };
      setFileList([fileData]);

      setAssestDetail(res?.data?.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsAssetLoading(false);
  };

  useEffect(() => {
    if (fileList.length) {
      form?.setFields([
        {
          name: "upload_file",
          errors: [],
        },
      ]);
    }
  }, [fileList]);

  const updateAssetsInEvent = async (assets, isAddAsset) => {
    const existingAssets = field_assets?.length
      ? field_assets?.map(({ type, id }) => ({ type, id }))
      : [];

    let Asset = isAddAsset
      ? [
          ...existingAssets,
          {
            type: "media--assets",
            id: assets?.id,
          },
        ]
      : [
          {
            type: "media--assets",
            id: assets?.id,
          },
        ];
    const payload = {
      data: {
        type: "event--event",
        id: assetType === "event" ? eventId : addonId,
        relationships: {
          field_assets: {
            data: Asset,
          },
        },
      },
    };
    const params = {
      reqType: "patch",
      uuid: assetType === "event" ? eventId : addonId,
      data: payload,
    };
    dispatch(createOrEditEvent(params));
  };

  const createAssets = async (assetsPayload, isAddAsset) => {
    const res = await createAssetsApi({ payload: assetsPayload, id: assetId });
    if (res.status) {
      form.resetFields();
      setFileList([]);
      onClose();
      assetId && message.success("Assest Updated Successfully");
      const assets = res?.data?.data;
      updateAssetsInEvent(assets, isAddAsset);
      // } else {
      //   setAssetId(null);
      //   dispatch(triggerIsAssetUpdated());
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setAddOrEditLoading(false);
  };

  const onFinishHandler = (values) => {
    const {
      field_document_type,
      upload_file,
      field_confirmation_attachment,
      field_request,
    } = values;
    const isAddAsset = field_request === false ? false : true;

    const assetsPayload = {
      data: {
        type: "media--assets",
        attributes: {
          name: upload_file[0]?.name,
          status: true,
          field_confirmation_attachment,
          field_request,
        },
        relationships: {
          field_document_type: field_document_type && {
            data: {
              type: "taxonomy_term--document_type",
              id: field_document_type,
            },
          },
          field_media_file: upload_file?.length && {
            data: upload_file?.map(({ uid }) => ({
              type: "file--file",
              id: uid,
            })),
          },
        },
      },
    };
    if (assetId && assestDetail) {
      assetsPayload["data"]["id"] = assetId;
    }
    setAddOrEditLoading(true);
    createAssets(assetsPayload, isAddAsset);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setFileList([]);
  };
  return (
    <>
      <Drawer
        title="Attach Document"
        maxWidth={420}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            <Button
              loading={addOrEditLoading}
              onClick={() => {
                if (fileList.length) {
                  form.setFieldsValue({ upload_file: fileList });
                }
                form.submit();
              }}
              type="primary"
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Spin spinning={addOrEditLoading || isAssetLoading}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="field_document_type"
                  label="Document Type"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter document type",
                    },
                  ]}
                >
                  <Select
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    showSearch            
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={
                      documentTypes?.length
                        ? documentTypes.map(({ id, name }) => ({
                            key: id,
                            label: name,
                            value: id,
                          }))
                        : []
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Upload File"
                  name="upload_file"
                  rules={[
                    {
                      required: true,
                      message: "Please upload file",
                    },
                  ]}
                >
                  <CustomDraggerUpload
                    fileList={fileList}
                    setFileList={setFileList}
                    // isMultiple={true}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    uploadUrl="/api/v1/media/assets/field_media_file"
                    supportedFormat={[
                      "txt",
                      "doc",
                      "docx",
                      "pdf",
                      "xlsx",
                      "xlsm",
                      "jpeg",
                      "svg",
                      "png",
                    ]}
                    uploadLimit={"20 MB"}
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={24}>
                <Form.Item
                  className="swith_form"
                  label="Confirmation Attachment"
                  name="field_confirmation_attachment"
                  valuePropName="checked"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col xs={20} md={20} lg={24}>
                <Form.Item
                  className="swith_form"
                  label="Required"
                  name="field_request"
                  valuePropName="checked"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Drawer>
    </>
  );
};
