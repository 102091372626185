export const statusList = [
  { label: "Active", value: "active" },
  { label: "Canceled", value: "cancelled" },
  { label: "Cancel Pending", value: "cancel_pending" },
  { label: "Completed", value: "completed" },
  { label: "Discarded", value: "discarded" },
  { label: "Pending", value: "pending" },
  { label: "Quote", value: "quote" },
  { label: "Revise", value: "revise" },
  { label: "Temporary Hold", value: "temporary_hold" },
  { label: "Waitlist", value: "waitlist" },
];
export const financialStatusList = [
  { label: "Design", value: "design" },
  { label: "Deposit Pending", value: "deposit_pending" },
  { label: "Paid Off", value: "paid_off" },
  { label: "Payment Pending", value: "payment_pending" },
  { label: "Revise", value: "revise" },
];
export const payTermsList = [
  { value: "pay_in_full_immediately", label: "Pay In Full Immediately" },
  { value: "pay_in_full_in_60_days", label: "Pay In Full In 60 Days" },
  { value: "payment_plan", label: "Payment Plan" },
  { value: "monthly_payment_plan", label: "Monthly Payment Plan" },
  {
    value: "deposit_2nd_deposit_and_final_payment",
    label: "Deposit 2nd Deposit And Final Payment",
  },
  { value: "deposit_and_final_payment", label: "Deposit And Final Payment" },
  { value: "quartely_payment_plan", label: "Quartely Payment Plan" },
];

export const getPrimaryTravelerName = (travelerObj) => {
  return travelerObj?.name || "";
};

export const rewardStatusList = [
  { value: "1", label: "ELITE" },
  { value: "2", label: "SILVER" },
  { value: "3", label: "SAPPHIRE" },
  { value: "4", label: "PLATINUM" },
  { value: "5", label: "DIAMOND" },
];

export const barGraphDuration = [
  { value: "today", label: "Today" },
  { value: "7_days", label: "Last 7 Days" },
  { value: "30_days", label: "Last 30 Days" },
  { value: "90_days", label: "Last 90 Days" },
  { value: "180_days", label: "Last 180 Days" },
  { value: "last_year", label: "Last Year" },
];

export const organizationTypeList = [
  { key: "event_partner", label: "Event Partner" },
  { key: "affiliate", label: "Affiliate" },
  { key: "air_consolidator", label: "Airline Consolidator" },
  { key: "airline", label: "Airline" },
  { key: "cruise_line", label: "Cruise Line" },
  { key: "internal", label: "Internal" },
  { key: "ground_transportation", label: "Ground Transportation" },
  { key: "hotel", label: "Hotel" },
  { key: "tour_operator", label: "Tour Operator" },
];

export const jacketShirtSize = [
  { key: "wxs", label: "Womens XS" },
  { key: "ws", label: "Womens S" },
  { key: "wm", label: "Womens M" },
  { key: "wl", label: "Womens L" },
  { key: "wxl", label: "Womens XL" },
  { key: "wxxl", label: "Womens XXL" },
  { key: "wxxxl", label: "Womens XXXL" },
  { key: "wxxxxl", label: "Womens XXXXL" },
  { key: "mm", label: "Mens M" },
  { key: "ms", label: "Mens S" },
  { key: "ml", label: "Mens L" },
  { key: "mxl", label: "Mens XL" },
  { key: "mxxl", label: "Mens XXL" },
  { key: "mxxxl", label: "Mens XXXL" },
];
