import { LockOutlined, UserOutlined, CheckOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { Grid } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import logo_two from "../../assests/images/IT_logo_color.svg";
import "./PasswordUpdated.less";

function PasswordUpdated() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg_main">
      <div className="bg-overlay"></div>
      <div className="main_login">
        <div className="main_login-container">
          <div className="updated_icon">
            <img src={logo_two} alt="This is a Logo" className="logo_two"></img>
          </div>

          <Form
            layout="horizontal"
            name="normal_login"
            className="login-form updateform_main"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <div className="update_form">
              <Form.Item>
                <div className="updated_check">
                  <CheckCircleFilled style={{ color: "#52C41A", fontSize: '350%' }} size={100} />
                </div>

                <h2>Password Updated</h2>
                <p>Your account password has been updated,</p>
                <p>to continue login into your account.</p>
              </Form.Item>
            </div>

            <Form.Item>
              <Link to="/login">
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Login
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>

        <div className="login_bottom">
          <p>Copyright ©{currentYear} Inspiration Travel </p>
        </div>
      </div>
    </div>
  );
}

export default PasswordUpdated;
