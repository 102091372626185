import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
function GroupListingsHeader() {
  const [searchParams, setSearchParams] = useSearchParams();
  const groupName = searchParams.get("name");
  const paramsId = searchParams.get("id");

  const navigate = useNavigate()
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
          onClick={()=>{
            navigate(`/reservation/details?id=${paramsId}`)
          }}
          />
          <div className="header_res">
            <p>View Reservation Group With: {groupName}</p>
          </div>
        </h3>
      </div>
    </div>
  );
}
export default GroupListingsHeader;