import { Form, Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Searchreservations_menu from "../../components/Reservation/ReservationSearchBar";
import Reservation_editgeneralpage from "../../components/Reservation/CreateOrEditReservation/ReservationEditForm";
import ReservationEditHeader from "../../components/Reservation/CreateOrEditReservation/ReservationEditHeader";
import ReservationEditForm from "../../components/Reservation/CreateOrEditReservation/ReservationEditForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createOrEditReservationApi,
  getReservationDetailApi,
} from "../../apis/reservationApi";
import { ReservationDetailRoute } from "../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { setSectionHighlighter } from "../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

function ReservationEditPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const editId = searchParams.get("editId");
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid } = authorization;
  const [reservationFormData, setReservationFormData] = useState({});
  const [loadingReservationFormData, setLoadingReservationFormData] =
    useState(false);
  const [editReservationLoading, setEditReservationLoading] = useState(false);
  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    { title: "Detail", path: `${ReservationDetailRoute}?id=${editId}` },
    { title: "Edit Reservation", path: "" },
  ];
  const [form] = Form.useForm();

  useEffect(() => {
    const isAllowed = permissionChecker("add/edit reservation");
    if(!isAllowed){
      navigate("/page-not-access")
    }
    getReservationDetail(editId);
  }, []);

  const getReservationDetail = async (reservationId) => {
    setLoadingReservationFormData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationFormData(res?.data?.data);
      setLoadingReservationFormData(false);
    } else {
      setReservationFormData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationFormData(false);
    }
  };

  const onFinishHandler = (values) => {
    const payload = {
      data: {
        type: "reservation--reservation",
        id: editId,
        attributes: {
          table_no: values.table_no,
          traveler_comments: values.traveler_comments,
          event_coordinator_notes_potential_challenges:
            values.event_coordinator_notes_potential_challenges,
          event_partner_notes: values.event_partner_notes,
          supplier_notes: values.supplier_notes,
          signature_services_notes: values.signature_services_notes,
          cancellation_comments: values.cancellation_comments,
          group_with: values.group_with,
          celebration_date:
            values?.celebration_date?.format("YYYY-MM-DD") || null,
          traveling_with: values.traveling_with,
          field_surprise: values.field_surprise,
          modified_on:"staffweb"
        },
        relationships: {
          field_bed_request: values.field_bed_request
            ? {
                data: {
                  type: "taxonomy_term--bed_request",
                  id: values.field_bed_request,
                },
              }
            : null,
          field_dining_time_request: values?.field_dining_time_request
            ? {
                data: {
                  type: "taxonomy_term--dining_time_request",
                  id: values?.field_dining_time_request,
                },
              }
            : null,
          field_dining_time_assigned: values?.field_dining_time_assigned
            ? {
                data: {
                  type: "taxonomy_term--dining_time_assigned",
                  id: values?.field_dining_time_assigned,
                },
              }
            : null,
          field_table_size_request: values?.field_table_size_request
            ? {
                data: {
                  type: "taxonomy_term--table_size_request",
                  id: values?.field_table_size_request,
                },
              }
            : null,
          field_table_status: values?.field_table_status
            ? {
                data: {
                  type: "taxonomy_term--table_status",
                  id: values?.field_table_status,
                },
              }
            : null,
          field_charter_group: values?.field_charter_group
            ? {
                data: {
                  type: "taxonomy_term--charter_group",
                  id: values?.field_charter_group,
                },
              }
            : null,
          field_charter_priority: values?.field_charter_priority
            ? {
                data: {
                  type: "taxonomy_term--charter_priority",
                  id: values?.field_charter_priority,
                },
              }
            : null,
          field_celebration: values?.field_celebration
            ? {
                data: {
                  type: "taxonomy_term--celebration",
                  id: values?.field_celebration,
                },
              }
            : null,
          field_source: values?.field_source
            ? {
                data: {
                  type: "source--source",
                  id: values?.field_source,
                },
              }
            : null,
          field_channel: values?.field_channel
            ? {
                data: {
                  type: "channel--channel",
                  id: values?.field_channel,
                },
              }
            : null,
          field_detail: values?.field_detail
            ? {
                data: {
                  type: "detail--detail",
                  id: values?.field_detail,
                },
              }
            : null,
          field_last_modified_by: {
            data: {
              type: "user--user",
              id: uuid,
            },
          },
          referred_by_contact: values?.referred_by_contact
            ? {
                data: {
                  type: "contact--contact",
                  id: values?.referred_by_contact,
                },
              }
            : null,
          field_travel_agent: values?.field_travel_agent
            ? {
                data: {
                  type: "contact--contact",
                  id: values?.field_travel_agent,
                },
              }
            : null,
        },
      },
    };

    editReservation({ reqMethod: "patch", payload, reservationId: editId });
  };

  const editReservation = async (data) => {
    setEditReservationLoading(true);
    const res = await createOrEditReservationApi(data);
    if (res.status) {
      dispatch(setSectionHighlighter({}));
      message.success("Reservation Edited Successfully");
      setEditReservationLoading(false);
      navigate(`${ReservationDetailRoute}?id=${editId}`);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setEditReservationLoading(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ReservationEditHeader
              form={form}
              editReservationLoading={editReservationLoading}
              reservationFormData={reservationFormData}
            />
          </div>
          <ReservationEditForm
            form={form}
            editId={editId}
            onFinishHandler={onFinishHandler}
            reservationFormData={reservationFormData}
            loadingReservationFormData={loadingReservationFormData}
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ReservationEditPage;
