import { Divider, Form, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";

const { TextArea } = Input;

function ReservationNotesForm({
  form,
  onFinishHandler,
  reservationFormData,
  editId,
}) {
  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      form.setFieldsValue({
        traveler_comments: reservationFormData?.traveler_comments,
        event_coordinator_notes_potential_challenges:
          reservationFormData?.event_coordinator_notes_potential_challenges,
        event_partner_notes: reservationFormData?.event_partner_notes,
        supplier_notes: reservationFormData?.supplier_notes,
        signature_services_notes: reservationFormData?.signature_services_notes,
        cancellation_comments: reservationFormData?.cancellation_comments,
      });
    }
  }, [reservationFormData]);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Reservation Notes</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 12,
        }}
        onFinish={onFinishHandler}
        form={form}
      >
        <div className="textarea_row">
          <Form.Item label="Traveler Comments" name="traveler_comments">
            <TextArea rows={4} />
          </Form.Item>
        </div>

        <div className="textarea_row">
          <Form.Item
            label="Event Coordinator Notes and Potential Challenges"
            name="event_coordinator_notes_potential_challenges"
          >
            <TextArea rows={4} />
          </Form.Item>
        </div>

        <div className="textarea_row">
          <Form.Item label="Event Partner Notes" name="event_partner_notes">
            <TextArea rows={4} />
          </Form.Item>
        </div>

        <div className="textarea_row">
          <Form.Item label="Supplier Notes" name="supplier_notes">
            <TextArea rows={4} />
          </Form.Item>
        </div>

        <div className="textarea_row">
          <Form.Item
            label="Signature Services Notes"
            name="signature_services_notes"
          >
            <TextArea rows={4} />
          </Form.Item>
        </div>

        <div className="textarea_row">
          <Form.Item label="Cancellation Comments" name="cancellation_comments">
            <TextArea rows={4} />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default ReservationNotesForm;
