import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const data = [
  {
    key: "1",
    label: "Name",
    input: "David Astemborski",
  },
  {
    key: "2",
    label: "Date Of Birth",
    input: "01/15/1960 Age 62",
  },
  {
    key: "3",
    label: "Age",
    input: "63",
  },
  {
    key: "4",
    label: "Email",
    input: "astemdw@gmail.com",
  },
  {
    key: "5",
    label: "Gender",
    input: "Male",
  },
  {
    key: "6",
    label: "Elite Traveler Status",
    input: " Diamond",
  },
  {
    key: "7",
    label: "Role",
    input: "Primary",
  },
  {
    key: "8",
    label: "Pay Percent",
    input: "100.00",
  },
  {
    key: "9",
    label: "Relation",
    input: "Husband",
  },
  {
    key: "10",
    label: "Contact",
    input: "David W Astemborski (79642)",
  },


];

function Guest_details() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4>Traveler Details</h4>
        <span>
          <Link to="/Reservation_editform">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Guest_details;
