import { Select } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import { barGraphDuration } from "../../others/util/commonFunctions";
import { DATE_FORMAT, uniqueColors } from "../../utils/constants";
import "./DashboardBarGraph.less";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DashboardBarGraph({ setBarDuration, dashboardBarData, barDuration }) {
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [searchParams, setSearchParams] = useSearchParams();
  const bar_duration = searchParams.get("bar_duration");
  function generateRandomHexColor(index) {
    const colorIndex = index % uniqueColors.length;
    return uniqueColors[colorIndex];
  }
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          color: (context) => {
            let lineColor = context?.tick?.value === 0 ? "black" : "";
            return lineColor;
          },
          z: 12,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // position: "right",
        // onClick: (e) => {
        //   e.preventDefault();
        // },
        // labels: {
        //   generateLabels: (chart) => {
        //     let newLabels = chart?.data?.datasets?.map((dataItem) => {
        //       return {
        //         text: `${dataItem?.label} (${
        //           Array.isArray(dataItem?.data[0])
        //             ? dataItem?.data
        //                 ?.flat()
        //                 .reduce((accum, item) => accum + item, 0)
        //             : dataItem?.data?.reduce((accum, item) => accum + item, 0)
        //         })`,
        //         fillStyle: dataItem.backgroundColor,
        //         lineWidth: 0,
        //       };
        //     });
        //     return newLabels;
        //   },
        // },
      },
      title: {
        display: true,
      },
    },
  };
  useEffect(() => {
    if (Object.keys(dashboardBarData).length) {
      setData({
        labels: ["7_days", "30_days", "90_days"].includes(barDuration)
          ? dashboardBarData?.labels?.map((label) =>
              moment(label, "YYYY-MM-DD").format(DATE_FORMAT)
            )
          : dashboardBarData?.labels,
        datasets: dashboardBarData?.datasets?.length
          ? dashboardBarData?.datasets?.map((dataItem, index) => ({
              label: dataItem?.event_name,
              data: dataItem?.reservation_count,
              backgroundColor: generateRandomHexColor(index),
            }))
          : [],
      });
    }
  }, [dashboardBarData]);
  return (
    <>
      <Select
        className="reservation_abs"
        style={{ width: 120 }}
        onChange={(e) => {
          setBarDuration(e);
          searchParams.set("bar_duration", e);
          setSearchParams(searchParams);
        }}
        value={barDuration}
        showSearch
        showArrow
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={barGraphDuration.map((duration) => ({
          key: duration.value,
          value: duration.value,
          label: duration.label,
        }))}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70%", height: "100%" }}>
          <Bar
            options={options}
            data={{
              datasets: data?.datasets?.length ? data?.datasets : [],
              labels: data.labels.length ? data.labels : [],
            }}
          />
        </div>
        <div
          style={{ maxHeight: "300px", width: "30%", overflow: "auto" }}
          className="events_reservation"
        >
          {data?.datasets?.map((dataItem, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  backgroundColor: dataItem?.backgroundColor,
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <span>{`${dataItem?.label} (${
                Array.isArray(dataItem?.data[0])
                  ? dataItem?.data
                      ?.flat()
                      .reduce((accum, item) => accum + item, 0)
                  : dataItem?.data?.reduce((accum, item) => accum + item, 0)
              })`}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default DashboardBarGraph;
