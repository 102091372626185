import { Grid, Layout, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import TravellerEmailForm from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/SendTravellerEmail/TravellerEmailForm";
import TravellerEmailHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/SendTravellerEmail/TravellerEmailHeader";
import ReservationSearchBar from "../../../components/Reservation/ReservationSearchBar";
import { ReservationDetailRoute } from "../../../url-routes/urlRoutes";
import { getTravellerEmailInfoApi } from "../../../apis/reservationApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content } = Layout;

const SendTravellerEmailPage = () => {
  const { useBreakpoint } = Grid;

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = useForm();
  const reservationId = searchParams.get("id");
  const travellerId = searchParams.get("traveller_id");
  const emailType = searchParams.get("emailType");
  const paymentId = searchParams.get("payment_id");
  const [sendLoading, setSendLoading] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [loadingEmailData, setLoadingEmailData] = useState(false);

  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    { title: "Detail", path: `${ReservationDetailRoute}?id=${reservationId}` },
    { title: "Send Email", path: "" },
  ];

  useEffect(() => {
    getEmailTemplateInfo({ emailType, reservationId, travellerId,paymentId });
  }, [emailType]);

  const getEmailTemplateInfo = async (data) => {
    setLoadingEmailData(true);
    const res = await getTravellerEmailInfoApi(data);
    if (res.status) {
      setEmailData(res?.data?.data);
      setLoadingEmailData(false);
    } else {
      setEmailData({});
      setLoadingEmailData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            {" "}
            <ReservationSearchBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <TravellerEmailHeader
              form={form}
              sendLoading={sendLoading}
              emailData={emailData}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              backgroundColor: "#fff",
            }}
          >
            <TravellerEmailForm
              form={form}
              setSendLoading={setSendLoading}
              emailData={emailData}
              loadingEmailData={loadingEmailData}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SendTravellerEmailPage;
