import { Layout, Grid } from "antd";
import React, { useState } from "react";
import EditActivityContainer from "../../../components/Contacts/ContactDetails/EditContactActivity/EditActivityContainer";
import ContactSearchBar from "../../../components/Contacts/ContactsListing/ContactSearchBar";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
const { Header, Content, Sider } = Layout;

function ActivityEditPage() {
  const [isFilterApplied, setIsFilterApplied] = useState(false); // isFilterApplied, setIsFilterApplied are mandatory for ContactSearchBar
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const [searchParams, setSearchParams] = useSearchParams();
  const activityId = searchParams.get("activityId");
  const contactId = searchParams.get("contactId");
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    { title: "Details", path: `/contact/details?id=${contactId}#Activities` },
    {
      title: "Activity",
      path: `/contact/details/activities?activityId=${activityId}&contactId=${contactId}`,
    },
    { title: "Edit Task", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ContactSearchBar
              // these two below props isFilterApplied, setIsFilterApplied are mandatory
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>

          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          {/* <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reservations</Breadcrumb.Item>
            <Breadcrumb.Item>Activity</Breadcrumb.Item>
            <Breadcrumb.Item>Edit Task</Breadcrumb.Item>
          </Breadcrumb> */}

          <EditActivityContainer />
          {/* <div className="events-header">
            <Breadcrumb_resactivityemail />
          </div>

          <Res_activityemailviewpage /> */}
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ActivityEditPage;
