import { Button, Col, Grid, Row, Steps, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  eventStepSetter,
  getSingleEvent,
  pressEventNext,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { permissionChecker } from "../../../utils/permisssionChecker";
import AccountingInformationForm from "./AccountingInformationForm";
import GeneralInformationForm from "./GeneralInformationForm";
import PaymentSetupForm from "./PaymentSetupForm";
import SystemOptionsForm from "./SystemOptionsForm";

const CreateOrEditEventsSteps = ({
  form1,
  form2,
  form3,
  form4,
  editId,
  createId,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const singleEventDataRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventFormFields, setEventFormFields] = useState({});
  const { eventCreation, singleEvent } = useSelector((state) => state.events);
  const { loadingEventCreation, eventStep } = eventCreation;
  const { singleEventResponse, loadingSingleEvent } = singleEvent;
  const [formStep, setFormStep] = useState(1);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const orientationStep = lg ? "vertical" : "horizontal";
  const currentStep = searchParams.get("step");
  const navigate = useNavigate()
  

  useEffect(()=>{
    const isAllowed = permissionChecker("add/edit event");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    setFormStep(Number(currentStep) || 1);
  }, [location.search]);

  useEffect(() => {
    if (singleEventDataRef.current) {
      if (singleEventResponse.status) {
        setEventFormFields(singleEventResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    singleEventDataRef.current = true;
  }, [singleEventResponse]);

  useEffect(() => {
    if (editId || createId) {
      dispatch(getSingleEvent(createId || editId));
    } else {
      setEventFormFields({});
      dispatch(eventStepSetter(0));
    }
  }, [currentStep]);

  const next = (info) => {
    setFormStep((prev) => Number(prev + 1));
    if (info.type === "creation") {
      setSearchParams({ creationId: info.id, step: formStep + 1 });
    } else {
      if (info.type === "edit") {
        setSearchParams({ editId: info.id, step: formStep + 1 });
      } else {
        setSearchParams({ step: formStep + 1 });
      }
    }
  };

  const prev = () => {
    setFormStep((prev) => Number(prev - 1));
    if (editId) {
      setSearchParams({
        editId: editId,
        step: formStep - 1,
      });
    } else {
      if (createId) {
        setSearchParams({
          creationId: createId,
          step: formStep - 1,
        });
      } else {
        setSearchParams({ step: formStep - 1 });
      }
    }
  };

  const onStepChangeHandler = (stepValue) => {
    if (Number(stepValue + 1) > eventStep + 1) {
      return;
    }
    if (editId) {
      setFormStep(Number(stepValue + 1));
      setSearchParams({ editId: editId, step: stepValue + 1 });
    } else {
      if (createId) {
        setSearchParams({
          creationId: createId,
          step: stepValue + 1,
        });
      } else {
        setSearchParams({ step: stepValue + 1 });
      }
    }
  };

  const steps = [
    {
      title: "General Information",
      content: (
        <GeneralInformationForm
          singleEventResponse={singleEventResponse}
          form1={form1}
          next={next}
          createId={createId}
          editId={editId}
          eventFormFields={eventFormFields}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "System Options / Settings",
      content: (
        <SystemOptionsForm
          form2={form2}
          next={next}
          createId={createId}
          editId={editId}
          eventFormFields={eventFormFields}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "Payment Setup",
      content: (
        <PaymentSetupForm
          form3={form3}
          next={next}
          createId={createId}
          editId={editId}
          eventFormFields={eventFormFields}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "Accounting Information",
      content: (
        <AccountingInformationForm
          form4={form4}
          next={next}
          createId={createId}
          editId={editId}
          eventFormFields={eventFormFields}
          currentStep={currentStep}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={6}>
          <Steps
            direction={orientationStep}
            current={formStep - 1}
            items={steps}
            onChange={onStepChangeHandler}
          />
        </Col>
        {loadingSingleEvent ? (
          <DataLoader />
        ) : (
          <Col xs={24} md={24} lg={16}>
            <div className="steps-content">{steps?.[formStep - 1].content}</div>
            <div className="steps-action">
              {formStep === 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateEventStep());
                    dispatch(pressEventNext("next-button"));
                    form1.submit();
                  }}
                  loading={loadingEventCreation}
                >
                  <span>Next</span>
                </Button>
              )}

              {formStep === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateEventStep());
                    dispatch(pressEventNext("next-button"));
                    form2.submit();
                  }}
                  loading={loadingEventCreation}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateEventStep());
                    dispatch(pressEventNext("next-button"));
                    form3.submit();
                  }}
                  loading={loadingEventCreation}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === steps.length && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateEventStep());
                    dispatch(pressEventNext("next-button"));
                    form4.submit();
                  }}
                  loading={loadingEventCreation}
                >
                  Done
                </Button>
              )}
              {formStep > 1 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
export default CreateOrEditEventsSteps;
