import { Form, Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../BreadCrumbGenerator/BreadCrumbGenerator";
import Horizontals from "../../../../Header/Horizontal_menu";
import OtherActivitiesCommonForm from "../AddActivitiesForms/OtherActivitiesCommonForm";
import "./OtherActivities.less";
import OtherActivitiesActionsBar from "./OtherActivitiesActionsBar";
const { Header, Content } = Layout;

const OtherActivities = () => {
  const { useBreakpoint } = Grid;
  const location = useLocation();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const contactId = searchParams.get("id");
  const [form] = Form.useForm();
  const [activityType, setActivityType] = useState("");

  useEffect(() => {
    let type = location.pathname.split("/")[4];
    if (type === "todo") {
      setActivityType("to_do");
    } else {
      if (type === "phonecall") {
        setActivityType("phone_call");
      } else {
        if (type === "letter") {
          setActivityType("letter");
        } else {
          setActivityType("");
        }
      }
    }
  }, [location.pathname]);

  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    {
      title: "Contacts Details",
      path: `/contact/details?id=${contactId}#Activities`,
    },
    {
      title: activityType
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      path: "",
    },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            {activityType && (
              <OtherActivitiesActionsBar form={form} activity={activityType} />
            )}
          </div>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              backgroundColor: "#fff",
            }}
          >
            {activityType && (
              <OtherActivitiesCommonForm
                form={form}
                activity={activityType}
                contactId={contactId}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default OtherActivities;
