import { Table, Button, Divider } from "antd";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const ExtensionMeals = ({ extensionData, extensionLoading }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const { field_additional_meal, field_meal_type } = extensionData || {};

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  if (!currentTab) {
    return <DataLoader />;
  }

  const data = [
    {
      key: "1",
      label: "Meal Type",
      input: `${field_meal_type?.id ? field_meal_type?.name : ""}`,
    },
    {
      key: "2",
      label: "Additional Meals",
      input: `${field_additional_meal || ""}`,
    },
  ];
  console.log("🚀 ~ file: PreMeals.jsx:53 ~ PreMeals ~ data:", data);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Meals</h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: extensionLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
      {/* )} */}
    </div>
  );
};
export default ExtensionMeals;
