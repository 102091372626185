import { Layout, Grid, message, Form } from "antd";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import RevisePageHeader from "../../../components/Breadcrumb/Reservations_header/RevisePageHeader";
import ReviseForm from "../../../components/View_components/Reservations/Reservationtable_components/ReviseForm";
import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { useSearchParams } from "react-router-dom";
import {
  ReservationDetailRoute,
  ReservationItemDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import { getRevisePageDetailApi } from "../../../apis/reservationApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const { Header, Content } = Layout;

function RevisePage() {
  const [reservationName, setReservationName] = useState();
  const [eventsList, setEventsList] = useState();
  const [isAddon, setIsAddon] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [travelerList, setTravelerList] = useState();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  let reservationId = searchParams.get("id");
  let itemId = searchParams.get("item-id");

  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Detail", path: `${ReservationDetailRoute}?id=${reservationId}` },
    {
      title: "Reservation Item",
      path: `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${itemId}`,
    },
    { title: "Replace Item", path: "" },
  ];

  const getRevisePageDetails = async () => {
    const response = await getRevisePageDetailApi(itemId);
    if (response && response.status) {
      const { data } = response?.data;
      setReservationName({
        name: data?.res_details?.name,
        res_item_uuid: data?.res_item_details?.res_item_uuid,
        res_uuid: data?.res_details?.uuid,
      });
      setEventsList(data?.events_list);
      setSelectedEvent(data?.res_details?.event_uuid);
      setTravelerList(data?.traveler_details);
      if (Number(data?.res_details?.is_add_on)) {
        setIsAddon(Number(data?.res_details?.is_add_on));
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRevisePageDetails();
    setLoading(true);
  }, []);
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          <div className="events-header">
            <RevisePageHeader
              reservationName={reservationName}
              form={form}
              reservationId={reservationId}
              itemId={itemId}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <ReviseForm
              eventsList={eventsList}
              travelerList={travelerList}
              form={form}
              reservationId={reservationId}
              loading={loading}
              isAddon={isAddon}
              reservationName={reservationName}
              selectedEvent={selectedEvent}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default RevisePage;
