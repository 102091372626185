import {
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteOrganisationApi,
  deleteOrganizationApi,
} from "../../../apis/organisationApi";
import { useSelector } from "react-redux";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const OrganizationDetailsHeader = ({ orgId }) => {
  const navigate = useNavigate();
  const [organizationDetailsData, setOrganizationDetailsData] = useState({});
  const { organizationAllDetails } = useSelector(
    (state) => state.organizations
  );
  const {
    organizationAllDetailsResponse,
    loadingOrganizationAllDetailsResponse,
  } = organizationAllDetails;
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );
  const allowDeleteOrganizations = authData?.permissions?.includes(
    "delete organization"
  );

  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  useEffect(() => {
    setOrganizationDetailsData(
      organizationAllDetailsResponse?.data?.data || {}
    );
  }, [organizationAllDetailsResponse]);

  const deleteOrganisation = async (deleteId) => {
    const payload = {
      data: {
        type: "node--organization",
        id: deleteId,
      },
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
    });

    const response = await deleteOrganizationApi({
      reqHeaders,
      orgId: deleteId,
      payload,
    });
    if (response.status) {
      message.success("Organization Deleted Successfully");
      navigate("/organization/listing");
    } else {
      message.error(
        response?.data?.response?.data?.message || response?.data?.response?.data?.errors?.[0]?.detail ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (deleteId) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteOrganisation(deleteId);
      },
    });
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          {loadingOrganizationAllDetailsResponse ? (
            <>
              <Skeleton.Input active={true} style={{ width: "250px" }} />
            </>
          ) : (
            <div className="header_res">
              <p>{organizationDetailsData.field_organization_name || ""}</p>
              <p className="header_smalltext">
                {!!organizationDetailsData?.drupal_internal__nid &&
                  `(${organizationDetailsData?.drupal_internal__nid})`}
              </p>
            </div>
          )}
        </h3>

        <div className="breadcrumb_buttons">
          {allowEditOrganizations ? (
            <Tooltip placement="top" title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(`/organization?editId=${orgId}&step=1`);
                }}
              />
            </Tooltip>
          ) : null}
          {allowDeleteOrganizations ? (
            <Tooltip placement="top" title="Delete">
              <Button
                className="delete_btn"
                icon={<DeleteOutlined />}
                onClick={() => {
                  onDeleteHandler(orgId);
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetailsHeader;
