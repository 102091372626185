import { Table, Divider, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const Air = ({ allDetails, allDetailsLoading }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const navigate = useNavigate();
  const { field_air_options } = allDetails || {};

  const handleTabChange = (key) => {
    setCurrentTab(Number(key));
  };

  const data = [
    {
      key: "1",
      label: "Airport City",
      input: (
        <>
          {field_air_options?.[currentTab - 1]?.field_air_option_city?.label ||
            ""}
        </>
      ),
    },
    {
      key: "2",
      label: "Carrier Options",
      input: (
        <>{field_air_options?.[currentTab - 1]?.field_carrier_options || ""}</>
      ),
    },
    {
      key: "3",
      label: "# Seats",
      input: <>{field_air_options?.[currentTab - 1]?.field_seats || ""}</>,
    },
    {
      key: "4",
      label: "Notes",
      input: <>{field_air_options?.[currentTab - 1]?.field_notes || ""}</>,
    },
  ];

  const tabItems = allDetails?.field_air_options?.map((item, index) => {
    return {
      key: index + 1,
      label: `Air Option ${index + 1}`,
      children: (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      ),
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <h4 className="mb-0">Air Options</h4>
        {/* <span>
          <Link to="/contact">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      {allDetails?.field_hotel_options?.length > 1 ? (
        <Tabs
          activeKey={currentTab}
          className="details-tabs"
          items={tabItems}
          onChange={(key) => {
            handleTabChange(key);
          }}
        ></Tabs>
      ) : (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
};

export default Air;
