import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Radio, Select, Switch } from "antd";
import "./TourDetailsForm.less";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";

const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TourMiscellaneous = ({ form, onFinishHandler, isLoading }) => {
  const [roommatePolicyList, setRoommatePolicyList] = useState([]);
  const [loadingRoommatePolicy, setLoadingRoommatePolicy] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "non_married_roommate_policy",
      setRoommatePolicyList,
      setLoadingRoommatePolicy
    );
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 classname="mb-0">Miscellaneous</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <div className="textarea_row">
            <Form.Item label="Meeting Space" name="field_meeting_space">
              <TextArea rows={4} />
            </Form.Item>
          </div>
          <div className="textarea_row">
            <Form.Item
              label="Event Partner Flight Allowance"
              name="field_event_partner_flight_allowance"
            >
              <TextArea rows={4} />
            </Form.Item>
          </div>

          <Form.Item
            label="Touring The Day They Arrive"
            name="field_touring_the_day_they_arrive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Extended Sea of Galilee Boat Ride"
            name="field_extended_sea_of_galilee_boat_ride"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Use Gai Beach Stage"
            name="field_use_gai_beach_stage"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Southern Steps Event"
            name="field_southern_steps_event"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Southern Steps Stage"
            name="field_southern_steps_stage"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={
              <div div className="text_break">
                <div>Electrical Engineer </div>
                <div>at Southern Steps</div>
              </div>
            }
            name="field_elec_eng_southern_steps"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Private Garden Tomb Event"
            name="field_private_garden_tomb_event"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <div className="textarea_row">
            <Form.Item label="Chairs for Events" name="field_chairs_for_events">
              <TextArea rows={4} />
            </Form.Item>
          </div>

          <div className="textarea_row">
            <Form.Item label="Portable Toilets " name="field_portable_toilets">
              <TextArea rows={4} />
            </Form.Item>
          </div>
          <Form.Item
            label="Christian Travel Guide"
            name="field_christian_travel_guide"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item label="Price in Visa" name="field_price_in_visa">
            <Input />
          </Form.Item>

          <Form.Item label="Baptism Location" name="field_baptism_location">
            <Input />
          </Form.Item>

          <Form.Item
            label="Leader performing Baptism"
            name="field_leader_performing_baptism"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Non-Married Roommate Policy"
            name="field_non_married_roommate_policy"
          >
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={roommatePolicyList.map((roommatePolicy) => ({
                key: roommatePolicy.id,
                value: roommatePolicy.id,
                label: roommatePolicy.name,
              }))}
              loading={loadingRoommatePolicy}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Optional Post Departure Ins Included"
            name="field_optional_post_departure_ins_included"
          >
            <Radio.Group name="radiogroup">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default TourMiscellaneous;
