import { Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import { Country } from "country-state-city";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import drupalConfig from "../../../config/serverless/drupal.config";
import CustomUpload from "../../../others/CustomUpload/CustomUpload";
import {
  contactStepSetter,
  createContact,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import "./FormSteps.less";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const CitizenshipForm = ({
  form3,
  next,
  contactFormFields,
  editId,
  createId,
  currentStep,
  primaryContactOrgId,
  orgId,
}) => {
  const createContactRef = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contactCreation, documentUpload } = useSelector(
    (state) => state.contacts
  );
  const { createContactStatus, step3, pressedNextButton, contactStep } =
    contactCreation;
  // const docUploadRef = useRef(false);
  // const { loadingDocUpload, documentUploadResponse } = documentUpload;
  let countriesList = Country.getAllCountries();
  countriesList = countriesList.map(val=>{
    if(val.name.toLowerCase() === "united states"){
      val.name = "USA"
    }
    return val
  })
  const [fileList, setFileList] = useState([]);
  const [proof, setProof] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("redirectBackTo");

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if ((editId || createId) && Object.keys(contactFormFields).length) {
      dispatch(contactStepSetter(contactFormFields.field_step));
      let stepThreeFields = {
        ...contactFormFields,
        field_citizenship: contactFormFields?.field_citizenship || null,
        field_proof_of_citizenship:
          contactFormFields?.field_proof_of_citizenship?.key || null,
        field_certificate_issue_date:
          contactFormFields?.field_certificate_issue_date
            ? moment(
                contactFormFields.field_certificate_issue_date,
                "YYYY-MM-DD"
              )
            : null,
        field_drivers_license_issue_date:
          contactFormFields?.field_drivers_license_issue_date
            ? moment(
                contactFormFields.field_drivers_license_issue_date,
                "YYYY-MM-DD"
              )
            : null,
        field_drivers_license_expire__date:
          contactFormFields?.field_drivers_license_expire__date
            ? moment(
                contactFormFields?.field_drivers_license_expire__date,
                "YYYY-MM-DD"
              )
            : null,
        field_passport_issue_date: contactFormFields?.field_passport_issue_date
          ? moment(contactFormFields.field_passport_issue_date, "YYYY-MM-DD")
          : null,
        field_passport_expire_date:
          contactFormFields?.field_passport_expire_date
            ? moment(
                contactFormFields?.field_passport_expire_date,
                "YYYY-MM-DD"
              )
            : null,
      };
      setProof(contactFormFields?.field_proof_of_citizenship?.key || null);
      contactFormFields?.field_proof_photo?.data === null
        ? setFileList([])
        : setFileList([
            {
              uid: contactFormFields?.field_proof_photo?.id,
              name: contactFormFields?.field_proof_photo?.filename,
              url: `${drupalConfig.BASE_URL}${contactFormFields?.field_proof_photo?.uri?.url}`,
              thumbUrl: `${drupalConfig.BASE_URL}${contactFormFields?.field_proof_photo?.uri?.url}`,
            },
          ]);
      form3.setFieldsValue(stepThreeFields);
    }
  }, [contactFormFields]);

  useEffect(() => {
    if (createContactRef.current) {
      if (createContactStatus?.status) {
        if (createId) {
          message.success("Form Submitted Successfully!");
          // let route = `/contact/details?id=${createId}`;
          let route = primaryContactOrgId
            ? `/organization?creationId=${primaryContactOrgId}&orgCotactId=${createId}&step=2`
            : !!orgId
            ? `/organization/details?id=${orgId}#Contacts`
            : `/contact/details?id=${createId}`;
          step3
            ? reservationId
              ? navigate(`/reservation/details?id=${reservationId}`)
              : navigate(route)
            : next({
                type: "creation",
                id: createId,
                primaryContactOrgId,
                orgId,
              });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            step3
              ? navigate(`/contact/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateStep());
      }
      if (!createContactStatus?.status) {
        message.error(createContactStatus.data.message || SOMETHING_WENT_WRONG);
      }
    }
    createContactRef.current = true;
  }, [createContactStatus]);

  const onFinishHandler = (values) => {
    const formValues = {
      ...values,
      field_passport_issue_date:
        values["field_passport_issue_date"]?.format("YYYY-MM-DD"),
      field_passport_expire_date:
        values["field_passport_expire_date"]?.format("YYYY-MM-DD"),
    };
    if (values["field_proof_of_citizenship"] === "passport") {
      formValues.field_passport_issue_date =
        values["field_passport_issue_date"]?.format("YYYY-MM-DD") || null;
      formValues.field_passport_expire_date =
        values["field_passport_expire_date"]?.format("YYYY-MM-DD") || null;
      formValues.field_drivers_license_number = null;
      formValues.field_drivers_license_issue_date = null;
      formValues.field_drivers_license_expire__date = null;
      formValues.field_certificate_number = null;
      formValues.field_certificate_issue_date = null;
    }
    if (values["field_proof_of_citizenship"] === "drivers_license") {
      formValues.field_drivers_license_issue_date =
        values["field_drivers_license_issue_date"]?.format("YYYY-MM-DD") ||
        null;
      formValues.field_drivers_license_expire__date =
        values["field_drivers_license_expire__date"]?.format("YYYY-MM-DD") ||
        null;
      formValues.field_passport_number = null;
      formValues.field_passport_issue_place = null;
      formValues.field_passport_issue_date = null;
      formValues.field_passport_expire_date = null;
      formValues.field_certificate_number = null;
      formValues.field_certificate_issue_date = null;
    }
    if (values["field_proof_of_citizenship"] === "birth_certificate") {
      formValues.field_certificate_issue_date =
        values["field_certificate_issue_date"]?.format("YYYY-MM-DD") || null;
      formValues.field_drivers_license_number = null;
      formValues.field_drivers_license_issue_date = null;
      formValues.field_drivers_license_expire__date = null;
      formValues.field_passport_number = null;
      formValues.field_passport_issue_place = null;
      formValues.field_passport_issue_date = null;
      formValues.field_passport_expire_date = null;
    }
    const reqMethod = "patch";
    const contactId = editId || createId;
    const reqHeaders = customRequestHeader({ token: Token });
    const payload = {
      data: {
        type: "contact--contact",
        attributes: {
          field_citizenship: formValues?.field_citizenship,
          field_place_of_birth: formValues?.field_place_of_birth,
          field_proof_of_citizenship: formValues?.field_proof_of_citizenship,
          field_passport_number: formValues?.field_passport_number,
          field_passport_issue_place: formValues?.field_passport_issue_place,
          field_passport_issue_date: formValues?.field_passport_issue_date,
          field_passport_expire_date: formValues?.field_passport_expire_date,
          field_drivers_license_number:
            formValues?.field_drivers_license_number,
          field_drivers_license_issue_date:
            formValues?.field_drivers_license_issue_date,
          field_drivers_license_expire__date:
            formValues?.field_drivers_license_expire__date,
          field_certificate_number: formValues?.field_certificate_number,
          field_certificate_issue_date:
            formValues?.field_certificate_issue_date,
          field_step: currentStep > contactStep ? +currentStep : contactStep,
        },
        relationships: {
          field_proof_photo: {
            data: !!fileList.length
              ? {
                  type: "file--file",
                  id: fileList[0].uid,
                }
              : {},
          },
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };

    if (editId || createId) {
      payload.data.id = editId || createId;
    }
    dispatch(createContact({ reqMethod, contactId, reqHeaders, payload }));
  };

  const selectCitizenshipProofHandler = (proof) => {
    if (proof === "passport") {
      return (
        <>
          <Form.Item label="Passport Number" name="field_passport_number">
            <Input />
          </Form.Item>
          <Form.Item
            label="Passport Issue Place"
            name="field_passport_issue_place"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Passport Issue Date"
            name="field_passport_issue_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Passport Expire Date"
            name="field_passport_expire_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
        </>
      );
    }
    if (proof === "drivers_license") {
      return (
        <>
          <Form.Item
            label="Drivers License Number"
            name="field_drivers_license_number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Drivers License Issue Date"
            name="field_drivers_license_issue_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Drivers License Expire Date"
            name="field_drivers_license_expire__date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
        </>
      );
    }
    if (proof === "birth_certificate") {
      return (
        <>
          <Form.Item label="Certificate Number" name="field_certificate_number">
            <Input />
          </Form.Item>
          <Form.Item
            label="Certificate Issue Date"
            name="field_certificate_issue_date"
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
        </>
      );
    }
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 8,
              }}
              wrapperCol={{
                sm: 24,
                md: 18,
                lg: 16,
              }}
              layout="horizontal"
              form={form3}
              onFinish={onFinishHandler}
            >
              <Form.Item label="Citizenship" name="field_citizenship">
                <Select
                  showSearch
                  allowClear
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={countriesList.map((country) => ({
                    key: country.isoCode,
                    value: country.name,
                    label: country.name,
                  }))}
                />
              </Form.Item>
              <Form.Item label="Place Of Birth" name="field_place_of_birth">
                <Input />
              </Form.Item>

              <Form.Item
                label="Citizenship Proof"
                name="field_proof_of_citizenship"
              >
                <Select
                  onSelect={(e) => {
                    setProof(e);
                  }}
                  allowClear
                  onClear={() => {
                    setProof(null);
                  }}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="passport" label="Passport">Passport</Select.Option>
                  <Select.Option value="drivers_license" label="Drivers License">
                    Drivers License
                  </Select.Option>
                  <Select.Option value="birth_certificate" label="Birth Certificate">
                    Birth Certificate
                  </Select.Option>
                </Select>
              </Form.Item>
              {!!proof ? selectCitizenshipProofHandler(proof) : null}
              <div className="textarea_row">
                <Form.Item
                  label="Proof Photo"
                  name="field_proof_photo"
                  className="asterick-align"
                >
                  <div>
                    {/* <Upload
                      multiple={false}
                      className="upload-list-inline"
                      customRequest={""}
                      listType="picture"
                      fileList={fileList}
                      maxCount={1}
                      onRemove={() => {
                        return false;
                      }}
                    >
                      <Button icon={<UploadOutlined />} loading={imageLoading}>
                        Upload
                      </Button>
                    </Upload> */}

                    <CustomUpload
                      fileList={fileList}
                      isMultiple={false}
                      setFileList={setFileList}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      uploadUrl="/api/v1/contact/contact/field_proof_photo"
                      availableFormat={"png, jpg, jpeg, svg, pdf"}
                      availableSize={"100 MB"}
                    />
                  </div>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CitizenshipForm;
