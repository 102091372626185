import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";
import Searchreservations_menu from "../Reservation/ReservationSearchBar";
import EditpaymentHeader from "./EditpaymentHeader";
import PlannedPayment from "./EditplannedPayment";
import React, { useState } from "react";
import axios from "axios";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function EditpaymentPage() {
  const [paymentData, setPaymentData] = useState({
    email: "harshphartyal45313302818@gmail.com",
    name: "OSL test user",
    contact_id: 64,
  });
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  function updatePaymentData(value, field) {
    setPaymentData((old) => {
      return { ...old, [field]: value };
    });
  }

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reservations</Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
            <Breadcrumb.Item>Payments</Breadcrumb.Item>
            <Breadcrumb.Item>Edit Payment</Breadcrumb.Item>
          </Breadcrumb>
          <div className="events-header">
            <EditpaymentHeader />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <PlannedPayment />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default EditpaymentPage;
