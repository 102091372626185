import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { Grid } from "antd";
import { Link, useNavigate } from "react-router-dom";
import logo_two from "../../assests/images/IT_logo_color.svg";
import React, { useEffect, useState } from "react";
import "./set-password.less";
import {
  generatePasswordOtp,
  updatePasswordApi,
  verifyOtp,
} from "../../apis/authentication";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

function SetPassword() {
  const [form] = Form.useForm();
  const [sendOtpScreen, setSendOtpScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);

  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const forgotPasswordEmail = localStorage.getItem("forgotPasswordEmail") || "";
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    if (Token) {
      message.error("You Are Already Logged In");
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const validateElementFields = (errorField, errorMessage) => {
    form.setFields([
      {
        name: errorField,
        errors: errorMessage ? [errorMessage] : [],
      },
    ]);
  };

  const onResendOtpHandler = async () => {
    const data = {
      type: "email",
      value: forgotPasswordEmail,
    };
    const response = await generatePasswordOtp(data);

    if (response && response.status === true) {
      message.info({
        content: response.data.message,
        icon: <InfoCircleOutlined style={{ color: "orange" }} />,
      });
      localStorage.setItem("forgotPasswordEmail", data.value);
      setMinutes(15);
      setSeconds(0);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    form.resetFields();
    setResendLoader(false);
  };

  const verifyOTP = async (data) => {
    const response = await verifyOtp(data);
    if (response && response.status === true) {
      message.success(response.data.message);
      setSendOtpScreen(false);
    } else {
      if (response.data.response.status === 400) {
        const msg = response?.data?.response?.data?.message;
        validateElementFields("code", msg);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    setLoading(false);
  };

  const updatePassword = async (data) => {
    const response = await updatePasswordApi(data);

    if (response && response.status === true) {
      message.success(response.data.message);
      setSendOtpScreen(false);
      navigate(`/password-updated`);
    } else {
      if (response.data.response.status === 400) {
        const msg = response?.data?.response?.data?.message;
        validateElementFields("password", msg);
        validateElementFields("confirmPassword", "  ");
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    setLoading(false);
  };

  const onFinishHandler = (values) => {
    setLoading(true);
    if (sendOtpScreen) {
      const data = {
        email: forgotPasswordEmail,
        code: values.code,
      };
      verifyOTP(data);
    } else {
      const data = {
        email: forgotPasswordEmail,
        new_pass: values.confirmPassword,
      };
      updatePassword(data);
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg_main Set_password-main">
      <div className="bg-overlay"></div>
      <div className="main_login set_password">
        <div className="main_login-container">
          <div className="main_login-container--logo">
            <div className="forgot_arrow">
              <Link to="/forgot-password">
                <ArrowLeftOutlined />
              </Link>
            </div>
            {/* <img src="./login_logo.png"></img> */}
            <img src={logo_two} alt="This is a Logo" className="logo_two"></img>
          </div>

          <Form
            layout="vertical"
            name="normal_login"
            className="login-form forgot_main"
            onFinish={onFinishHandler}
            form={form}
          >
            <Form.Item>
              <h2>
                {sendOtpScreen ? "Enter One-Time Code" : "Set New Password"}
              </h2>
              {sendOtpScreen ? (
                <>
                  <span className="span_text">
                    A one-time code has been sent to the email address
                  </span>
                  <span className="span_text">
                    {` "${forgotPasswordEmail}"` || ""}
                  </span>
                </>
              ) : (
                ""
              )}
            </Form.Item>
            {sendOtpScreen ? (
              <div className="forgot_emailaddress">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    <span>Resend OTP</span> in{" "}
                    {minutes < 10 ? `0${minutes}` : `${minutes}`}:
                    {seconds < 10 ? `0${seconds}` : `${seconds}`}
                  </p>
                ) : (
                  <a
                    onClick={() => {
                      onResendOtpHandler();
                      setResendLoader(true);
                    }}
                    loading={resendLoader}
                  >
                    Resend
                  </a>
                )}
                <Form.Item
                  label="Enter One-Time Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Enter One-Time Code",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter 6 digit one-time code" />
                </Form.Item>
              </div>
            ) : (
              <>
                {" "}
                <div className="forgot_emailaddress">
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter new password",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="forgot_emailaddress">
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter confirm password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The passwords you entered do not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
              </>
            )}

            <Form.Item>
              <Button
                style={{ width: "100%", height: "40px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
                className="login-form-button"
              >
                {sendOtpScreen ? "Verify" : "Update Password"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="login_bottom">
        <p>Copyright ©{currentYear} Inspiration Travel </p>
      </div>
    </div>
  );
}

export default SetPassword;
