import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  pressAddonNext,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import { EventDetailsAddonsDetailsRoute } from "../../../../../url-routes/urlRoutes";

const AddonsHeader = ({
  form1,
  form2,
  form3,
  form4,
  editId,
  createId,
  eventId,
}) => {
  const { addonCreation } = useSelector((state) => state.addons);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingAddonCreation } = addonCreation;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get("step");

  const onSaveHandler = () => {
    if (currentStep === "1" || currentStep === null) {
      dispatch(pressAddonNext(""));
      dispatch(updateAddonStep("1"));
      form1.submit();
    }
    if (currentStep === "2") {
      dispatch(pressAddonNext(""));
      dispatch(updateAddonStep("2"));
      form2.submit();
    }
    if (currentStep === "3") {
      dispatch(pressAddonNext(""));
      dispatch(updateAddonStep("3"));
      form3.submit();
    }
    if (currentStep === "4") {
      dispatch(pressAddonNext(""));
      dispatch(updateAddonStep("4"));
      form4.submit();
    }
  };
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3>
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              let route =
                createId || editId
                  ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${
                      createId || editId
                    }`
                  : -1;
              navigate(route);
            }}
          />
          <span style={{ fontFamily: "'Poppins', sans-serif" }}>
            {editId ? "Edit Add-Ons" : "Add Add-Ons"}
          </span>
        </h3>

        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              let route =
                createId || editId
                  ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${
                      createId || editId
                    }`
                  : -1;
              navigate(route);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={loadingAddonCreation}
            onClick={onSaveHandler}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddonsHeader;
