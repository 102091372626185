import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import {
  ReservationDetailRoute,
  ReservationItemDetailRoute,
} from "../../../url-routes/urlRoutes";
import { Link, useNavigate } from "react-router-dom";

function RevisePageHeader({ reservationName, form, reservationId, itemId }) {
  const navigate = useNavigate();

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile cross-icon"
            onClick={() => {
              navigate(
                `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${itemId}`
              );
            }}
          />
          <div className="header_res">
            <p>Replace Item </p>
            <Link
              to={`${ReservationDetailRoute}?id=${reservationName?.res_uuid}`}
              className="header_smalltext"
            >
              {reservationName?.name}
            </Link>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              navigate(
                `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${itemId}`
              );
            }}
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RevisePageHeader;
