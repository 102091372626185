import { Col, DatePicker, Divider, Form, Input, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect } from "react";

const { TextArea } = Input;
function Recommendations({
  form,
  cancelDetails,
  onFinishHandler,
  loadingCancelDetails,
}) {
  const { recommendations, recommendation_date, notes } =
    cancelDetails?.recommendations || {};
  useEffect(() => {
    form.setFieldsValue({
      field_recommendations: recommendations,
      recommendation_date: recommendation_date
        ? moment(recommendation_date, "YYYY-MM-DD")
        : null,
      notes,
    });
  }, [cancelDetails]);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="m-0">Recommendations</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingCancelDetails} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinishHandler}
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <div className="textarea_row">
                <Form.Item label="Recommendations" name="field_recommendations">
                  <TextArea rows={4} />
                </Form.Item>
              </div>
              <Form.Item label="Recommendation Date" name="recommendation_date">
                <DatePicker format={"MM/DD/YYYY"} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="textarea_row">
                <Form.Item label="Notes" name="notes">
                  <TextArea rows={4} />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default Recommendations;
