import axios from "axios";
// import  from "../config/serverless/serverless.config";
import drupalConfig from "../config/serverless/drupal.config";
import { Children } from "react";

// const BASE_URL = `https://dev-ict-booking.oslabs.app`;

export const drupalAxiosRequest = async (
  reqMethod,
  reqUrl,
  reqHeaders,
  data = {}
) => {
  let accessToken = JSON.parse(
    localStorage.getItem("authorization")
  )?.access_token;
  let result;
  try {
    if (accessToken) {
      axios.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${
          JSON.parse(localStorage.getItem("authorization"))?.access_token
        }`;
        return config;
      });
    }

    if (
      reqMethod === "post" ||
      reqMethod === "patch" ||
      (reqMethod === "delete" && !!Object.keys(data).length)
    ) {
      result = await axios({
        headers: reqHeaders,
        method: reqMethod,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
        data,
      });
    } else if (reqMethod === "delete") {
      let payload = Object.keys(data).length ? data : null;
      result = await axios({
        headers: reqHeaders,
        method: reqMethod,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
        payload,
      });
    } else {
      result = await axios({
        headers: reqHeaders,
        method: reqMethod,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
      });
    }
    // console.log("request result", result);
    return { data: result.data, status: true };
  } catch (error) {
    // console.log("request error", error);
    return { data: error, status: false };
  }
};
