import { Col, Form, Input, Row, Spin } from "antd";
import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
	Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
  ImageResize,
	ImageToolbar,
	ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import "./Ckeditor.css";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditEmailTemplateForm = ({
  form,
  onFinishHandler,
  templateData,
  loadingTemplate,
}) => {
  const [editorInitData, setEditorInitData] = useState(
    templateData?.body?.value || ""
  );
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      field_email_subject: templateData?.field_email_subject || null,
      body: editorInstance?.setData(templateData?.body?.value || ""),
    });

    if (editorInstance && templateData?.body?.value) {
      editorInstance.setData(templateData.body.value);
    }
  }, [templateData, editorInstance]);

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'sourceEditing',
        'findAndReplace',
        'selectAll',
        '|',
        'heading',
        'style',
        '|',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'link',
        'insertImage',
        'insertTable',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'indent',
        'outdent',
        '|',
        'accessibilityHelp',
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
			Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
      ImageResize,
		  ImageStyle,
			ImageToolbar,
			ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: ['imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage']
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  return (
    <div className="addnote_form">
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 3,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
        form={form}
        onFinish={(values) => onFinishHandler(values, editorInstance)}
      >
        <Spin spinning={loadingTemplate} size="large">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Subject"
                name="field_email_subject"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="textarea_row">
                <Form.Item
                  label="Body"
                  name="body"
                  className="ckeitor-field-wrapper"
                >
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={editorInitData}
                    onReady={(editor) => {
                      setEditorInstance(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorInitData(data);
                      form.setFieldsValue({ body: data });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </div>
  );
};

export default EditEmailTemplateForm;
