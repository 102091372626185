import { InboxOutlined } from "@ant-design/icons";
import { message } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import {
  fileUploadProcessingApi,
  uploadDocumentApi,
} from "../../../apis/contactApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import DataLoader from "../../../others/Loaders/DataLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { importEventTrigger } from "../../../redux/features/eventFeatures/eventsSlice";

const EventImportDraggerUpload = ({
  fileList,
  setFileList,
  isLoading,
  setIsLoading,
  uploadUrl,
  fileProcessingUrl,
  entityType,
  onClose,
  setErrorList,
  setIsModalOpen,
  setOpen
}) => {
  const { isImportEventTrigger } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const uploadFunction = async (payload, fileType) => {
    setIsLoading(true);
    const url = uploadUrl;
    const reqHeaders = customRequestHeader({
      token: Token,
      isUploadReq: true,
      fileType,
    });
    const response = await uploadDocumentApi({ url, reqHeaders, payload });
    if (response.status) {
      uploadFileDataProcessing(response?.data?.data);
    } else {
      setIsLoading(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const uploadFileDataProcessing = async (uploadResponse) => {
    const payload = {
      file_uri: uploadResponse?.uri?.value,
      //   entity_type: entityType,
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: true,
    });
    const response = await fileUploadProcessingApi({
      url: fileProcessingUrl,
      reqHeaders,
      payload,
    });
    if (response.status) {
      message.success(`${uploadResponse.filename} Imported Successfully`);
      setIsLoading(false);
      onClose();
      dispatch(importEventTrigger(!isImportEventTrigger));
      setIsModalOpen(false)
      setOpen(false)
    } else {
      const errorMessage = [response?.data?.response?.data?.message]
      if (response?.data?.response?.data?.error?.length) {
        const errors = response?.data?.response?.data?.error;
        errors.forEach((error) => {
          errorMessage.push(error)
        });
      }
      setErrorList(errorMessage)
      setFileList([]);
      setIsLoading(false);
    }
  };

  const onChangeHandler = (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = function () {
        const base64String = reader.result.split(",")[1]; // extract base64 string from data URL
        const binaryArray = getBinaryArrayFromBase64String(base64String);
        console.log({ binaryArray });
        uploadFunction(binaryArray, info.file.name);
      };
      reader.onerror = function (error) {
        message.error("Failed To Upload File");
      };

      //   const reader = new FileReader();
      //   reader.readAsArrayBuffer(info.file.originFileObj);
      //   reader.onload = () => {
      //     const binaryData = new Uint8Array(reader.result);
      //     const binaryString = new TextDecoder().decode(binaryData);
      //     uploadFunction(binaryString);
      //   };
      //   reader.onerror = () => {
      //     message.error("Failed to upload image");
      //   };
    }
  };

  const getBinaryArrayFromBase64String = (base64String) => {
    const binaryString = atob(base64String);
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    return binaryArray;
  };

  const beforeUpload = (file) => {
    const isXLSX =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXLSX) {
      message.error("You Can Only Upload .xlsx File");
    }
    return isXLSX;
  };
  return (
    <Dragger
      multiple={false}
      customRequest={() => {}}
      listType="picture"
      fileList={fileList}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={onChangeHandler}
      accept=".xlsx"
    >
      <>
        <p className="ant-upload-drag-icon">
          {isLoading ? (
            <>
              <DataLoader />
            </>
          ) : (
            <InboxOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Supported file types: .xlsx</p>
        <p className="ant-upload-hint"> Max file size: 100 MB.</p>
      </>
    </Dragger>
  );
};
export default EventImportDraggerUpload;
