import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPriceCodeTaxonomies } from "../../../../../../../apis/eventsApi";
import { updateSingleCategory } from "../../../../../../../redux/features/eventFeatures/eventsSlice";
import {
  AddonCategoryDetailsRoute,
  EventCategoryDetailsRoute,
} from "../../../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../../../utils/constants";
import { isJSONString } from "../../../../../../../utils/contentParser";

function CategoryPricingForm({ categoryDetails, loadingSingleCategory, form }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const addonId = searchParams.get("addonId");
  const [priceCodes, setPriceCodes] = useState([]);
  const { updateCategoryResponse } = useSelector(
    (state) => state.events.updateCategory
  );
  const updateCategoryRef = useRef();

  function onSuccessRedirection() {
    let url = addonId
      ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`;
    navigate(url);
  }

  const fetchPriceCodes = async () => {
    const res = await getPriceCodeTaxonomies();
    if (res.status) {
      setPriceCodes(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchPriceCodes();
  }, []);

  useEffect(() => {
    if (updateCategoryRef?.current) {
      if (updateCategoryResponse?.status) {
        message.success("Category Pricing Updated Successfully");
        onSuccessRedirection();
      } else {
        message.error(
          updateCategoryResponse?.error?.response?.data?.errors?.[0]?.title ||
            updateCategoryResponse?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    updateCategoryRef.current = true;
  }, [updateCategoryResponse]);

  useEffect(() => {
    const field_category_pricing =
      categoryDetails?.field_category_pricing &&
      isJSONString(categoryDetails?.field_category_pricing) &&
      JSON.parse(categoryDetails?.field_category_pricing);

    const data = field_category_pricing;
    form.setFieldsValue({
      field_category_pricing: data?.length
        ? data?.map((pricing) => {
            return {
              ...pricing,
            };
          })
        : [{ occupancy: undefined }],
    });
  }, [categoryDetails]);

  const data = [
    {
      key: "1",
      name: "Adult",
      code: "adult",
      first: "",
      second: "",
      third: "",
      fourth: "",
    },
    {
      key: "2",
      name: "Child",
      code: "child",
      first: "",
      second: "",
      third: "",
      fourth: "",
    },
    {
      key: "3",
      name: "Staff",
      code: "staff",
      first: "",
      second: "",
      third: "",
      fourth: "",
    },
    {
      key: "4",
      name: "Reward Points",
      code: "rewardPoints",
      first: "",
      second: "",
      third: "",
      fourth: "",
    },
    {
      key: "5",
      name: "Additional Deposit",
      code: "additionalDeposit",
      first: "",
      second: "",
      third: "",
      fourth: "",
    },
  ];

  const onDeleteHandler = (i) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        message.success("Deleted");
      },
    });
  };

  function convertAllPricingtoDecimal(data) {
    data?.forEach((ele, index) => {
      const { pricingData } = ele;
      for (let key in pricingData) {
        for (let type in pricingData[key]) {
          if (pricingData[key][type]) {
            pricingData[key][type] = Number(pricingData[key][type]).toFixed(2);
          }
        }
      }
    });
    return data;
  }

  const onFinishHandler = (values) => {
    // let { field_category_pricing } = values;
    let field_category_pricing = convertAllPricingtoDecimal(
      values?.field_category_pricing
    );
    const payload = {
      data: {
        type: "category--category",
        id: categoryId,
        attributes: {
          field_category_pricing:
            field_category_pricing && JSON.stringify(field_category_pricing),
        },
      },
    };
    dispatch(updateSingleCategory({ id: categoryId, payload }));
  };

  const COLUMNS = [
    {
      title: "Traveler Type",
      key: "travelerType",
      dataIndex: "name",
      width: "200",
      editable: false,
    },
    {
      title: "1st",
      dataIndex: "first",
      key: "first",
      editable: true,
      render: (text) => <input name="1st" />,
    },
    {
      title: "2nd",
      dataIndex: "second",
      key: "second",
      render: (text) => <input name="2ndrawInput" />,
      editable: true,
    },
    {
      title: "3rd",
      dataIndex: "third",
      key: "third",
      editable: true,
      render: (text) => <input name="3rdrawInput" />,
    },
    {
      title: "4th +",
      dataIndex: "fourth",
      key: "fourth",
      editable: true,
      render: (text) => <input />,
    },
  ];

  // if (loadingSingleCategory) return <DataLoader />;

  function isNotInputable(rowIndex, columnIndex) {
    const AdditionalDepositRowIndex = 4;
    const validColumnUpperLimit = 1;
    if (
      rowIndex === AdditionalDepositRowIndex &&
      columnIndex > validColumnUpperLimit
    ) {
      return true;
    } else {
      return false;
    }
  }

  const onFinishFailed = (value) => {
    value.errorFields.forEach((element) => {
      message.error(
        `${element.errors[0]} for Category Pricing ${element.name[1] + 1}`
      );
    });
  };

  return (
    <>
      <Spin spinning={loadingSingleCategory} size="large">
        <Form
          className="pricing_border"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          onFinishFailed={onFinishFailed}
        >
          <Form.List
            name="field_category_pricing"
            initialValue={[
              {
                occupancy: "1",
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  const newColumns = COLUMNS.map((col, index) => {
                    return {
                      ...col,
                      render:
                        index !== 0
                          ? (text, record, rowIndex) => {
                              if (isNotInputable(rowIndex, index)) {
                                return null;
                              }
                              return (
                                <Form.Item
                                  name={[
                                    name,
                                    "pricingData",
                                    record?.code,
                                    col?.key,
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    min={0}
                                    pattern="/^[0-9\b]+$/"
                                    onKeyDown={(evt) => {
                                      if (
                                        evt.key === "e" ||
                                        evt.key === "-" ||
                                        evt.key === "+" ||
                                        // evt.key === "." ||
                                        evt.key === "ArrowUp" ||
                                        evt.key === "ArrowDown" ||
                                        (evt.target.value.split(".")?.[0]
                                          ?.length >= 7 &&
                                          evt.key != "Backspace" &&
                                          record.code !== "rewardPoints")
                                      ) {
                                        evt.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              );
                            }
                          : null,
                    };
                  });
                  return (
                    <>
                      <div className="" key={key}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "2rem",
                          }}
                        >
                          <h4 className="mb-0">Category Pricing {key + 1}</h4>
                          <span>
                            {" "}
                            {key > 0 ? (
                              <Form.Item>
                                <div className="close_relative">
                                  <Button
                                    className="close_absolute"
                                    type="dashed"
                                    onClick={() => {
                                      remove(name);
                                    }}
                                    icon={<CloseOutlined />}
                                  ></Button>
                                </div>
                              </Form.Item>
                            ) : null}
                          </span>
                        </div>
                        <Divider className="global_divider mb-0" />

                        <div style={{ marginTop: "24px" }} />
                        <div key={key}>
                          <Row gutter={16}>
                            <Col sm={24} md={24} lg={7} xl={7} xxl={7}>
                              <Form.Item
                                className="label-align"
                                label="Occupancy"
                                name={[name, "occupancy"]}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={[
                                    {
                                      value: "1",
                                      label: "1",
                                    },
                                    {
                                      value: "2",
                                      label: "2",
                                    },
                                    {
                                      value: "3",
                                      label: "3",
                                    },
                                    {
                                      value: "4",
                                      label: "4",
                                    },
                                    {
                                      value: "5",
                                      label: "5",
                                    },
                                    {
                                      value: "6",
                                      label: "6",
                                    },
                                    {
                                      value: "7",
                                      label: "7",
                                    },
                                    {
                                      value: "8",
                                      label: "8",
                                    },
                                  ]}
                                />
                              </Form.Item>
                              <Form.Item
                                className="asterick-align label-align"
                                {...restField}
                                name={[name, "priceCode"]}
                                label="Price Code"
                                rules={[
                                  {
                                    required: true,
                                    message: "Price Code Is Required",
                                  },
                                ]}
                              >
                                <Select
                                showSearch            
                                filterOption={(input, option) =>
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={
                                    priceCodes?.length
                                      ? priceCodes?.map((code) => ({
                                          key: code.id,
                                          label: code.name,
                                          value: code.name,
                                        }))
                                      : []
                                  }
                                ></Select>
                              </Form.Item>
                              <Form.Item
                                className="label-align"
                                label="Price Name"
                                name={[name, "priceName"]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                className="swith_form label-align"
                                label="Share"
                                name={[name, "share"]}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            </Col>

                            <Col sm={24} md={24} lg={17} xl={17} xxl={17}>
                              <Form.Item
                                {...restField}
                                name={[name, "pricingData"]}
                              >
                                <Table
                                  key={key}
                                  className="pricing_table"
                                  columns={newColumns}
                                  dataSource={data}
                                  pagination={false}
                                  size="middle"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div>
                        <Divider className="global_divider" />
                      </div>
                    </>
                  );
                })}
                {/* " grey bar will go here will the help of themeing" */}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Category Pricing
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Spin>
    </>
  );
}

export default CategoryPricingForm;
