import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Res_guestdetails() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4>Traveler Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="First Name" name="First Name">
              <label>David</label>
            </Form.Item>
            <Form.Item label="Middle Name" name="Middle Name">
              <label>W</label>
            </Form.Item>
            <Form.Item label="Last Name" name="Last Name">
              <label>Astemborski</label>
            </Form.Item>
            <Form.Item label="Preferred Name" name="name">
              <label>David Astemborski</label>
            </Form.Item>
            <Form.Item label="Date Of Birth" name="Date Of Birth">
              <label>01/15/1960 Age 62</label>
            </Form.Item>
            <Form.Item label="Email" name="Email">
              <Input />
            </Form.Item>
            <Form.Item label="Gender" name="Gender">
              <label>Male</label>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Organization Number" name="organization_Number">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item name=" Non Revenue" label=" Non Revenue">
              <Switch />
            </Form.Item>
            <Form.Item name=" Rez Transfer" label=" Rez Transfer">
              <Switch />
            </Form.Item>

            <Form.Item name="party_role" label="Role">
              <Select>
                <option value="Primary">Primary</option>
                <option value="Sponsor">Sponsor</option>
              </Select>
            </Form.Item>
            <Form.Item name="relation" label="Relation">
              <Select>
                <option value="Brother">Brother</option>
                <option value="Daughter">Daughter</option>
                <option value="Father">Father</option>
                <option value="Friend">Friend</option>
                <option value="Grandchild">Grandchild</option>
                <option value="Grandparent">Grandparent</option>
                <option value="Husband">Husband</option>
                <option value="Mother">Mother</option>
                <option value="Other Relation">Other Relation</option>
                <option value="Room Share">Room Share</option>
                <option value="Self">Self</option>
                <option value="Sister">Sister</option>
                <option value="Son">Son</option>
                <option value="Wife">Wife</option>
              </Select>
            </Form.Item>
            <Form.Item name="group" label="Group">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Res_guestdetails;
