import { Form, Select, Checkbox } from "antd";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
function ReviseForm({
  travelerList,
  eventsList,
  form,
  reservationId,
  loading,
  reservationName,
  isAddon,
  selectedEvent,
}) {
  const [defaultTravelers, setDefaultTravelers] = useState([]);
  const [isTravelerVisible, setIsTravelerVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (travelerList && eventsList) {
      const selectedTraveler = travelerList.filter((e) => e.selected === true);
      const list = selectedTraveler.map((e) => e.traveler_uuid);
      setDefaultTravelers(list);
      form.setFieldsValue({
        primary_product: eventsList.filter((e) => e.current_event === true)[0]
          ?.uuid,
        travelers: list,
      });
      setIsTravelerVisible(true);
    }
  }, [travelerList, eventsList]);

  const handleFinish = (values) => {
    if (isAddon) {
      navigate(
        `/add_item?id=${reservationId}&&replace-item=true&name=${
          reservationName?.name
        }&res-item-uuid=${
          reservationName?.res_item_uuid
        }&selected-travelers=${values.travelers.join(",")}&is-addon=${isAddon}`
      );
    } else {
      navigate(
        `/add_item?step=category&id=${reservationId}&event_id=${
          values?.primary_product || selectedEvent
        }&replace-item=true&name=${reservationName?.name}&res-item-uuid=${
          reservationName?.res_item_uuid
        }&selected-travelers=${values.travelers.join(",")}`
      );
    }
  };

  if (loading) {
    return <DataLoader />;
  }

  return (
    <div>
      <div className="form-title mb-3">
        <p>Item Replace Options</p>
      </div>

      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={handleFinish}
      >
        {eventsList && eventsList.length ? (
          <Form.Item
            label="Event"
            name="primary_product"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please Select a Date",
              },
            ]}
          >
            <Select
              defaultValue={
                eventsList.filter((e) => e.current_event === true)[0]?.uuid
              }
              options={eventsList?.map((event) => ({
                key: event?.id,
                value: event?.uuid,
                label: event?.name,
              }))}
            ></Select>
          </Form.Item>
        ) : null}

        <div className="textarea_row item_replace_traveler">
          {isTravelerVisible ? (
            <Form.Item
              label="Travelers"
              name="travelers"
              valuePropName="checked"
            >
              <Checkbox.Group
                options={travelerList?.map((event) => ({
                  key: event?.traveler_id,
                  value: event?.traveler_uuid,
                  label: event?.name,
                }))}
                defaultValue={defaultTravelers}
              />
            </Form.Item>
          ) : null}
        </div>
      </Form>
    </div>
  );
}

export default ReviseForm;
