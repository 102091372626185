import React, { useEffect, useState } from "react";
import { Grid, Layout, message } from "antd";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../BreadCrumbGenerator/BreadCrumbGenerator";
import Horizontals from "../../../../Header/Horizontal_menu";
import ActivitiesEmailForm from "../AddActivitiesForms/ActivitiesEmailForm";
import "./ActivitiesEmail.less";
import { useForm } from "antd/es/form/Form";
import EmailActivityActionBar from "../../../../Breadcrumb/Breadcrumb_email/EmailActivityActionBar";
import { getContactAllDetailsApi } from "../../../../../apis/contactApi";
import {
  Token,
  customRequestHeader,
} from "../../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header, Content } = Layout;

const ActivitiesEmail = () => {
  const { useBreakpoint } = Grid;

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = useForm();
  const contactId = searchParams.get("id");
  const [createLoading, setCreateLoading] = useState(false);
  const [contactData, setContactData] = useState({});
  const [loadingContactData, setLoadingContactData] = useState(false);
  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    {
      title: "Contacts Details",
      path: `/contact/details?id=${contactId}#Activities`,
    },
    { title: "Send Email", path: "" },
  ];
  useEffect(() => {
    getContactDetails(contactId);
  }, []);

  const getContactDetails = async (contactId) => {
    setLoadingContactData(true);
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getContactAllDetailsApi({ contactId, reqHeaders });
    if (response.status) {
      setContactData(response?.data?.data);
      setLoadingContactData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setContactData({});
      setLoadingContactData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} form={form} />
          <div className="events-header">
            <EmailActivityActionBar form={form} createLoading={createLoading} />
          </div>

          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              backgroundColor: "#fff",
            }}
          >
            <ActivitiesEmailForm
              form={form}
              setCreateLoading={setCreateLoading}
              contactData={contactData}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ActivitiesEmail;
