import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOrganizationApi,
  editOrganizationApi,
  getAllOrganisationsApi,
  getOrganizationAllDetailsApi,
  getPrimaryContactDetailsApi,
  getPrimaryContactsListApi,
} from "../../../apis/organisationApi";

export const getAllOrganisations = createAsyncThunk(
  "organizations/getAllOrganisations",
  async (data) => {
    const response = await getAllOrganisationsApi(data);
    return response;
  }
);

export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (formData) => {
    const response = await createOrganizationApi(formData);
    return response;
  }
);

export const getPrimaryContactsList = createAsyncThunk(
  "organizations/getPrimaryContactsList",
  async (data) => {
    const response = await getPrimaryContactsListApi(data);
    return response;
  }
);

export const getPrimaryContactDetails = createAsyncThunk(
  "organizations/getPrimaryContactDetails",
  async (data) => {
    const response = await getPrimaryContactDetailsApi(data);
    return response;
  }
);
export const getOrganizationAllDetails = createAsyncThunk(
  "organizations/getOrganizationAllDetails",
  async (data) => {
    const response = await getOrganizationAllDetailsApi(data);
    return response;
  }
);

export const editOrganizationInfo = createAsyncThunk(
  "organizations/editOrganizationInfo",
  async (data) => {
    const response = await editOrganizationApi(data);
    return response;
  }
);

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState: {
    loadingOrganizationDetailsEdit: true,
    organizationsListing: {
      organizationsListingResponse: {},
      loadingOrganizationsList: false,
    },
    organizationCreation: {
      organizationCreationResponse: {},
      loadingOrganizationCreation: false,
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      pressedNextButton: false,
      organizationStep: 0,
    },
    primaryContactsListing: {
      primaryContactsListingResponse: {},
      loadingPrimaryContactsListing: false,
    },
    primaryContactDetails: {
      primaryContactDetailsResponse: {},
      loadingPrimaryContactDetails: false,
    },
    organizationAllDetails: {
      organizationAllDetailsResponse: {},
      loadingOrganizationAllDetailsResponse: false,
    },
    editOrganization: {
      editOrganizationResponse: {},
      loadingEditOrganization: false,
    },
    isFilterApplied: false,
    searchFilters: {},
  },
  reducers: {
    updateOrganizationStep: (state, action) => {
      switch (action.payload) {
        case "1":
          state.organizationCreation.step1 = "done";
          break;
        case "2":
          state.organizationCreation.step2 = "done";
          break;
        case "3":
          state.organizationCreation.step3 = "done";
          break;
        case "4":
          state.organizationCreation.step4 = "done";
          break;
        default:
          state.organizationCreation.step1 = "";
          state.organizationCreation.step2 = "";
          state.organizationCreation.step3 = "";
          state.organizationCreation.step4 = "";
      }
    },
    pressOrganizationNext: (state, action) => {
      action.payload === "next-button"
        ? (state.organizationCreation.pressedNextButton = true)
        : (state.organizationCreation.pressedNextButton = false);
    },
    organizationStepSetter: (state, action) => {
      state.organizationCreation.organizationStep = action.payload;
    },
    organizationDetailsEditLoader: (state, action) => {
      state.loadingOrganizationDetailsEdit = action.payload;
    },
    orgSearchFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    orgSearchFilterSetter: (state, action) => {
      state.searchFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrganisations.pending, (state, action) => {
      state.organizationsListing.loadingOrganizationsList = true;
    });
    builder.addCase(getAllOrganisations.fulfilled, (state, action) => {
      state.organizationsListing.organizationsListingResponse = action.payload;
      state.organizationsListing.loadingOrganizationsList = false;
    });
    builder.addCase(createOrganization.pending, (state, action) => {
      state.organizationCreation.loadingOrganizationCreation = true;
    });
    builder.addCase(createOrganization.fulfilled, (state, action) => {
      state.organizationCreation.organizationCreationResponse = action.payload;
      state.organizationCreation.loadingOrganizationCreation = false;
    });
    builder.addCase(createOrganization.rejected, (state, action) => {
      console.log("rejected", action.payload);
      state.organizationCreation.loadingOrganizationCreation = false;
    });
    builder.addCase(getPrimaryContactsList.pending, (state, action) => {
      state.primaryContactsListing.loadingPrimaryContactsListing = true;
    });
    builder.addCase(getPrimaryContactsList.fulfilled, (state, action) => {
      state.primaryContactsListing.primaryContactsListingResponse =
        action.payload;
      state.primaryContactsListing.loadingPrimaryContactsListing = false;
    });
    builder.addCase(getPrimaryContactDetails.pending, (state, action) => {
      state.primaryContactDetails.loadingPrimaryContactDetails = true;
    });
    builder.addCase(getPrimaryContactDetails.fulfilled, (state, action) => {
      state.primaryContactDetails.loadingPrimaryContactDetails = false;
      state.primaryContactDetails.primaryContactDetailsResponse =
        action.payload;
    });
    builder.addCase(getOrganizationAllDetails.pending, (state, action) => {
      state.organizationAllDetails.loadingOrganizationAllDetailsResponse = true;
    });
    builder.addCase(getOrganizationAllDetails.fulfilled, (state, action) => {
      state.organizationAllDetails.organizationAllDetailsResponse =
        action.payload;
      state.organizationAllDetails.loadingOrganizationAllDetailsResponse = false;
    });
    builder.addCase(editOrganizationInfo.pending, (state, action) => {
      state.editOrganization.loadingEditOrganization = true;
    });
    builder.addCase(editOrganizationInfo.fulfilled, (state, action) => {
      state.editOrganization.editOrganizationResponse = action.payload;
      state.editOrganization.loadingEditOrganization = false;
    });
  },
});
export const {
  updateOrganizationStep,
  pressOrganizationNext,
  organizationStepSetter,
  organizationDetailsEditLoader,
  orgSearchFilterApplied,
  orgSearchFilterSetter,
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
