import React, { useState } from "react";
import "antd/dist/antd.css";
import { Button, Table } from "antd";
import { MailOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
import "./Orgcontact_table.less";

const columns = [
  {
    key: "1",
    title: "Name",
    dataIndex: "name",
    render: (record, valuesObj) => {
      return (
        <>
          <Link to={`/contact/details?id=${valuesObj.uuid}`}>
            <div>
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  position: "relative",
                  margin: "0",
                }}
              >
                {valuesObj.key}
              </span>
              <div>
                <span>{record}</span>
              </div>
            </div>
          </Link>
        </>
      );
    },
    width: "20%",
  },
  {
    key: "2",
    title: "Phone",
    dataIndex: "phone",
    render: (record) => {
      console.log("record", record.length);
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          {!!record ? (
            <>
              <PhoneOutlined
                style={{
                  paddingTop: "5px",
                  marginTop: "0px",
                  transform: "rotate(-270deg)",
                }}
              />
              <p style={{ margin: "0" }}>{record}</p>
            </>
          ) : null}
        </div>
      );
    },
    width: "20%",
  },

  {
    key: "3",
    title: "Address",
    dataIndex: "address",
    width: "35%",
  },

  {
    key: "4",
    title: "Email",
    dataIndex: "email",
    render: (record) => {
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          {record ? (
            <>
              {" "}
              <MailOutlined style={{ paddingTop: "4px" }} />
              <p style={{ margin: "0" }}>{record}</p>
            </>
          ) : null}
        </div>
      );
    },
    width: "25%",
  },
];

const OrganizationContactTable = ({
  organizationContactsData,
  isLoading,
  orgId,
}) => {
  console.log({ organizationContactsData });
  const data = organizationContactsData?.map((contact) => ({
    uuid: contact.uuid,
    key: contact.id || "",
    name: contact.name || "",
    phone: contact.field_phone || "",
    address: `${
      contact.field_address_line_1 ? `${contact.field_address_line_1}, ` : ""
    }${
      contact.field_address_line_2 ? `${contact.field_address_line_2}, ` : ""
    }${contact.field_field_city ? `${contact.field_field_city}, ` : ""}${
      contact.field_field_state ? `${contact.field_field_state}, ` : ""
    }${contact.field_field_country ? `${contact.field_field_country}, ` : ""}${
      contact.field_postal_code ? `${contact.field_postal_code} ` : ""
    }`,
    email: contact.field_email_address || "",
  }));
  return (
    <>
      <div style={{ float: "right", paddingBottom: "20px" }}>
        <Link to={`/contact?orgId=${orgId}`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add
          </Button>
        </Link>
      </div>
      <Table
        className="table_listing table_top"
        columns={columns}
        dataSource={data}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        style={{ marginTop: "50px" }}
        pagination={false}
      />
    </>
  );
};
export default OrganizationContactTable;
