import { Button, Skeleton, Modal, message, Form, Input } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
} from "../../../../../../utils/constants";
import {
  ReservationDetailRoute,
  ReservationPaymentRoute,
  ReservationPlannedPaymentRoute,
} from "../../../../../../url-routes/urlRoutes";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  changeReservationsChildStatusApi,
  payPlannedPaymentWithStripeApi,
  validateTransactionApi,
} from "../../../../../../apis/reservationApi";

function PannedPaymentDetailHeader({
  paymentData,
  loadingPaymentData,
  reservationId,
  transactionList,
  unpaidPayment,
  setDeleteModalOpen,
}) {
  const status = paymentData?.field_reservation?.reservation_status;
  let paymentName = paymentData?.field_paymenttype?.name;
  paymentName =
    paymentName?.charAt?.(0)?.toUpperCase() + paymentName?.slice?.(1);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cancelPlannedPayment = async (paymentUuid) => {
    let payload = {
      data: {
        entity: "add_planned_payment",
        type: "add_planned_payment",
        entity_uuid: [
          {
            uuid: paymentUuid,
          },
        ],
      },
    };
    setLoadingStatus(true);
    const res = await changeReservationsChildStatusApi(payload);
    if (res.status) {
      setLoadingStatus(false);
      navigate(`${ReservationDetailRoute}?id=${reservationId}`);
      message.success("Status Changed Successfully");
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setLoadingStatus(false);
    }
  };

  const onPayHandler = (values) => {
    const { field_traveler, field_reservation, uuid } = paymentData;
    try {
      navigate(
        `${ReservationPaymentRoute}?id=${
          field_reservation.uuid
        }&reservation-id=${field_reservation.id}&traveler-uuid=${
          field_traveler.uuid
        }&event-name=${field_reservation.name || ""}&paymentId=${uuid}`
      );
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };

  const payWithStripe = async (payload) => {
    setIsPaymentLoading(true);
    const res = await payPlannedPaymentWithStripeApi(payload);
    if (res.status) {
      // if (res?.data?.status) {
      setIsPaymentLoading(false);
      window.location.href = res?.data?.session_url;
    } else {
      setIsPaymentLoading(false);
      message.error(
        res?.data?.response?.data?.message ||
          res?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };
  const modalHandler = () => {
    setDeleteModalOpen(true);
  };

  const handleCancelRefund = () => {
    setIsRefundModalOpen(false);
  };

  const onFinishRefund = (values) => {
    console.log("Received values:", values);
    // Add your logic here for handling form submission
    if (values?.transaction_id) {
      validateTransactionId(values?.transaction_id);
    }

    // setIsRefundModalOpen(false);
  };

  const validateTransactionId = async (transactionId) => {
    const res = await validateTransactionApi(transactionId);
    console.log({ res });
  };
  console.log({ paymentData });
  return (
    <>
      <Modal
        title={"Refund"}
        open={isRefundModalOpen}
        onCancel={handleCancelRefund}
        footer={null}
      >
        <Form onFinish={onFinishRefund}>
          <Form.Item
            label="Transaction Id"
            name="transaction_id"
            rules={[
              { required: true, message: "Please input your transaction id" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        footer={[
          <Button
            type="primary"
            onClick={() => {
              cancelPlannedPayment(paymentData?.uuid);
            }}
            loading={loadingStatus}
          >
            Confirm
          </Button>,
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>,
        ]}
        title="Confirmation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="revise-btn_modal">
          <p>Please confirm that you want to cancel this planned payment.</p>
        </div>
      </Modal>
      <div className="breadcrumb_content" style={{ width: "100%" }}>
        <div
          className="breadcrumb_heading"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3 className="flex_row_column">
            <ArrowLeftOutlined
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate(`${ReservationDetailRoute}?id=${reservationId}`);
              }}
            />{" "}
            {loadingPaymentData ? (
              <Skeleton.Input active={true} style={{ width: "350px" }} />
            ) : (
              <div className="header_res">
                <p>View Planned Payment: {`Plan - ${paymentName || ""}`}</p>
                <Link
                  to={`${ReservationDetailRoute}?id=${reservationId}`}
                  className="header_smalltext"
                >
                  {" "}
                  {paymentData?.field_reservation?.name || ""}-{" "}
                  {paymentData?.field_reservation?.id || ""}
                </Link>
              </div>
            )}
          </h3>
          <div
            className="breadcrum_buttons"
            style={{ display: "flex", gap: "10px" }}
          >
            {(["active", "revise", "cancel_pending"].includes(
              paymentData?.field_reservation?.reservation_status
            ) &&
              paymentData?.field_planned_payment_status?.key === "pending" &&
              parseFloat(paymentData?.pending_amount) > 0 &&
              paymentData?.field_planned_payment_status?.key !== "canceled" &&
              paymentData?.field_paymenttype?.name !== "refund") ||
            (transactionList.length &&
              paymentData?.field_paymenttype?.name !== "refund") ? (
              <Button
                onClick={() => {
                  onPayHandler(paymentData);
                }}
                loading={isPaymentLoading}
              >
                Pay
              </Button>
            ) : null}
            {paymentData?.field_paymenttype?.name === "Refund" ? (
              <Button
                onClick={() => {
                  // onPayHandler(paymentData);
                  setIsRefundModalOpen(true);
                }}
                loading={isPaymentLoading}
              >
                Complete Refund
              </Button>
            ) : null}
            {(paymentData?.field_reservation?.reservation_status === "active" ||
              paymentData?.field_reservation?.reservation_status === "revise" ||
              paymentData?.field_reservation?.reservation_status ===
                "cancel_pending" || 
                paymentData?.field_reservation?.reservation_status === "cancelled") &&
            unpaidPayment &&
            paymentData?.field_planned_payment_status?.key !== "canceled" ? (
              <Button
                onClick={() => {
                  showModal();
                }}
              >
                Cancel
              </Button>
            ) : null}

            {(!["discard", "canceled", "complete"].includes(
              paymentData?.field_reservation?.reservation_status
            ) &&
              paymentData?.field_planned_payment_status?.key === "pending" &&
              paymentData?.field_planned_payment_status?.key !== "canceled" &&
              unpaidPayment &&
              paymentData?.field_paymenttype?.name !== "refund") ||
            (transactionList.length &&
              paymentData?.field_paymenttype?.name !== "refund") ? (
              <Button
                type="primary"
                onClick={() => {
                  navigate(
                    `${ReservationPlannedPaymentRoute}?id=${reservationId}&travellerId=${paymentData?.field_traveler?.uuid}&paymentId=${paymentData?.uuid}`
                  );
                }}
              >
                Edit
              </Button>
            ) : null}
            {["quote", "waitlist", "pending", "temporary_hold"].includes(
              status
            ) ? (
              <Button type="danger" onClick={modalHandler}>
                Delete
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default PannedPaymentDetailHeader;
