import { Table, Button, Divider, Tabs } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },

  {
    title: "Input",
    dataIndex: "input",
  },
];

function CruiseMeeting({ allDetails, allDetailsLoading }) {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { field_meeting_needs } = allDetails || {};

  const handleTabChange = (key) => {
    setCurrentTab(Number(key));
  };

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }

  const data = [
    {
      key: "1",
      label: "Large Theater",
      input: (
        <>
          {field_meeting_needs?.[currentTab - 1]?.field_large_theater?.label ||
            ""}
        </>
      ),
    },
    {
      key: "2",
      label: "Break Out Rooms",
      input: (
        <>
          {field_meeting_needs?.[currentTab - 1]?.field_break_out_rooms?.length
            ? field_meeting_needs?.[currentTab - 1]?.field_break_out_rooms
                ?.map(({ label }) => label)
                ?.join(", ")
            : ""}
        </>
      ),
    },
    {
      key: "3",
      label: "Preliminary Event Schedule Needed?",
      input: (
        <>
          {field_meeting_needs?.[currentTab - 1]?.field_preliminary_event
            ?.label || ""}
        </>
      ),
    },
    {
      key: "4",
      label: "Dining Time",
      input: (
        <>
          {(field_meeting_needs?.[currentTab - 1]?.field_dining_type?.label ||
            "") +
            " " +
            (field_meeting_needs?.[currentTab - 1]?.field_dining_time || "")}
        </>
      ),
    },
  ];

  const tabItems = allDetails?.field_meeting_needs?.map((item, index) => {
    return {
      key: index + 1,
      label: `Meeting Space Option ${index + 1}`,
      children: (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      ),
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <h4 className="mb-0">Meeting Space Needs</h4>
      </div>
      <Divider className="global_divider mb-0" />
      {allDetails?.field_meeting_needs?.length > 1 ? (
        <Tabs
          activeKey={currentTab}
          className="details-tabs"
          items={tabItems}
          onChange={(key) => {
            handleTabChange(key);
          }}
        ></Tabs>
      ) : (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
}

export default CruiseMeeting;
