import { Button, Col, Grid, Row, Steps, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import {
  addonStepSetter,
  getSingleAddon,
  pressAddonNext,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import AddonsAccountingInfoForm from "./AddonsAccountingInfoForm";
import AddonsGeneralInfoForm from "./AddonsGeneralInfoForm";
import AddonsPaymentSetupForm from "./AddonsPaymentSetupForm";
import AddonsSystemInfoForm from "./AddonsSystemInfoForm";

const AddonsSteps = ({ form1, form2, form3, form4, editId, createId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const singleAddonDataRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [addonFormFields, setAddonFormFields] = useState({});
  const { addonCreation, singleAddon } = useSelector((state) => state.addons);
  const { loadingAddonCreation, addonStep } = addonCreation;
  const { singleAddonResponse, loadingSingleAddon } = singleAddon;
  const [formStep, setFormStep] = useState(1);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const orientationStep = lg ? "vertical" : "horizontal";
  const currentStep = searchParams.get("step");
  const eventId = searchParams.get("id");

  useEffect(() => {
    setFormStep(Number(currentStep) || 1);
  }, [location.search]);

  useEffect(() => {
    if (singleAddonDataRef.current) {
      if (singleAddonResponse.status) {
        setAddonFormFields(singleAddonResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    singleAddonDataRef.current = true;
  }, [singleAddonResponse]);

  useEffect(() => {
    if (editId || createId) {
      dispatch(getSingleAddon(createId || editId));
    } else {
      setAddonFormFields({});
      dispatch(addonStepSetter(0));
    }
  }, [currentStep]);

  const next = (info) => {
    setFormStep((prev) => Number(prev + 1));
    if (info.type === "creation") {
      setSearchParams({
        id: info.id,
        creationId: info.addonId,
        step: formStep + 1,
      });
    } else {
      if (info.type === "edit") {
        setSearchParams({
          id: info.id,
          editId: info.addonId,
          step: formStep + 1,
        });
      } else {
        setSearchParams({ id: eventId, step: formStep + 1 });
      }
    }
  };

  const prev = () => {
    setFormStep((prev) => Number(prev - 1));
    if (editId) {
      setSearchParams({
        id: eventId,
        editId: editId,
        step: formStep - 1,
      });
    } else {
      if (createId) {
        setSearchParams({
          id: eventId,
          creationId: createId,
          step: formStep - 1,
        });
      } else {
        setSearchParams({ id: eventId, step: formStep - 1 });
      }
    }
  };

  const onStepChangeHandler = (stepValue) => {
    if (Number(stepValue + 1) > addonStep + 1) {
      return;
    }
    if (editId) {
      setFormStep(Number(stepValue + 1));
      setSearchParams({ id: eventId, editId: editId, step: stepValue + 1 });
    } else {
      if (createId) {
        setSearchParams({
          id: eventId,
          creationId: createId,
          step: stepValue + 1,
        });
      } else {
        setSearchParams({ id: eventId, step: stepValue + 1 });
      }
    }
  };

  const steps = [
    {
      title: "General Information",
      content: (
        <AddonsGeneralInfoForm
          form1={form1}
          next={next}
          createId={createId}
          editId={editId}
          eventId={eventId}
          addonFormFields={addonFormFields}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "System Options / Settings",
      content: (
        <AddonsSystemInfoForm
          form2={form2}
          next={next}
          createId={createId}
          editId={editId}
          eventId={eventId}
          addonFormFields={addonFormFields}
          currentStep={currentStep}
        />
      ),
    },
    // Skip the payment setup

    {
      title: "Payment Setup",
      content: (
        <AddonsPaymentSetupForm
           isAddon = {true}
          form3={form3}
          next={next}
          createId={createId}
          editId={editId}
          eventId={eventId}
          addonFormFields={addonFormFields}
          currentStep={currentStep}
        />
      ),
    },
    {
      title: "Accounting Information",
      content: (
        <AddonsAccountingInfoForm
          form4={form4}
          next={next}
          createId={createId}
          editId={editId}
          eventId={eventId}
          addonFormFields={addonFormFields}
          currentStep={currentStep}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={6}>
          <Steps
            direction={orientationStep}
            current={formStep - 1}
            items={steps}
            onChange={onStepChangeHandler}
          />
        </Col>
        {loadingSingleAddon ? (
          <DataLoader />
        ) : (
          <Col xs={24} md={24} lg={16}>
            <div className="steps-content">{steps?.[formStep - 1].content}</div>
            <div className="steps-action">
              {formStep === 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateAddonStep());
                    dispatch(pressAddonNext("next-button"));
                    form1.submit();
                  }}
                  loading={loadingAddonCreation}
                >
                  <span>Next</span>
                </Button>
              )}

              {formStep === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateAddonStep());
                    dispatch(pressAddonNext("next-button"));
                    form2.submit();
                  }}
                  loading={loadingAddonCreation}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateAddonStep());
                    dispatch(pressAddonNext("next-button"));
                    form3.submit();
                  }}
                  loading={loadingAddonCreation}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === steps.length && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(updateAddonStep());
                    form4.submit();
                  }}
                  loading={loadingAddonCreation}
                >
                  Done
                </Button>
              )}
              {formStep > 1 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
export default AddonsSteps;
