import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import React, { useState } from "react";
import {
  EventDetailAssignDining,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../../url-routes/urlRoutes";

function EventAssignDiningHeader() {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventName = searchParams.get("event");
  const addOnUuid = searchParams.get("addonId");
  const eventId = searchParams.get("id");
  const addOnType = searchParams.get("addonType");
  const label = searchParams.get("label");
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header pt-3 pb-0">
        <h3 className="flex_row_column">
          <Link
          // to="/Addonsview_page"
          >
            {" "}
            <CloseOutlined
              className="icon_color pe-2"
              onClick={() => {
                window.location.href = `${EventDetailsAddonsDetailsRoute}?id=${EventDetailsRoute}&addonId=${addOnUuid}&addonType=${addOnType}`;
              }}
            />
          </Link>
          <div className="header_res">
            <p>Assign {label}</p>
            <a
              className="header_smalltext"
              href={`${EventDetailsRoute}?id=${eventId}`}
            >
              {eventName}
            </a>
          </div>
        </h3>
      </div>
    </div>
  );
}

export default EventAssignDiningHeader;
