import { Col, Divider, Form, Row, Spin } from "antd";
import moment from "moment";
import React from "react";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { addCommasInPricing } from "../../../../../utils/contentParser";

function CancellationCalculation({ cancelDetails, loadingCancelDetails }) {
  const {
    cancellation_date,
    cancellation_penalty,
    payby_transfer,
    payto_date,
    refund_amount,
    rez_status,
    transfer_amount,
    // transfer_penalty,
  } = cancelDetails?.cancellation_calculation || {};

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Cancellation Calculation</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingCancelDetails} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Rez Status" name="rez_status">
                <label>{rez_status || ""}</label>
              </Form.Item>
              <Form.Item label="Cancellation Date" name="cancellation_date">
                <label>
                  {cancellation_date
                    ? `${moment(cancellation_date, "YYYY-MM-DD").format(
                        DATE_FORMAT
                      )}`
                    : moment().format(
                      DATE_FORMAT
                    )}
                </label>
              </Form.Item>
              <Form.Item label="Paid To Date" name="paid_date">
                <label>
                  {payto_date
                    ? `$${addCommasInPricing(payto_date?.toString())}`
                    : "$0"}
                </label>
              </Form.Item>
              <Form.Item label="Paid By Transfer" name="paid_transfer">
                <label>
                  {payby_transfer
                    ? `$${addCommasInPricing(payby_transfer)}`
                    : "$0"}
                </label>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Cancellation Penalty"
                name="cancellation_penalty"
              >
                <label>
                  {cancellation_penalty
                    ? `$${addCommasInPricing(cancellation_penalty?.toString())}`
                    : "$0"}
                </label>
              </Form.Item>
              {/* <Form.Item label="Transfer Penalty" name="transfer_penalty">
                <label>
                  {transfer_penalty
                    ? `$${addCommasInPricing(transfer_penalty)}`
                    : ""}
                </label>
              </Form.Item> */}
              <Form.Item label="Refund Amount" name="refund_amount">
                <label>
                  {refund_amount ? `$${addCommasInPricing(refund_amount)}` : ""}
                </label>
              </Form.Item>
              <Form.Item label="Transfer Amount" name="transfer_amount">
                <label>
                  {transfer_amount
                    ? `$${addCommasInPricing(transfer_amount)}`
                    : ""}
                </label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default CancellationCalculation;
