import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu } from "antd";
import "./Demo_page.less";
import Sidebar from "../../components/Header/Header_sidebar";
import Horizontals from "../../components/Header/Horizontal_menu";
// import Demographic_information from '../../components/Forms/demographic_information/demographic_information';
// import Dietary_information from '../../components/Forms/dietary_information/dietary_information';
// import Medical_information from '../../components/Contacts/ContactDetailsTabs/GeneralDetailsTab/MedicalInformation';
// import Marketing_information from '../../components/Forms/Marketing_information/Marketing_information';
// import Contact_details from '../../components/Forms/Contact_details/Contact_details';
import "./Demo_page.less";
import Breadcrumb_record from "../../components/Contacts/ContactsListing/ContactsListHeader";
import React from "react";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

const Demo_page = () => (
  <Layout className="demo_layout">
    <Header className="contact_edit">
      <Horizontals />
    </Header>
    <Layout>
      <Sider width={208} className="site-layout-background">
        <Sidebar />
      </Sider>
      <Layout
        style={{
          padding: "0 24px 24px",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            backgroundColor: "#fff",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "20px",
            display: "block",
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item style={{ color: "#000", fontWeight: "500" }}>
            Contacts
          </Breadcrumb.Item>
          <Breadcrumb_record />
        </Breadcrumb>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            backgroundColor: "#fff",
          }}
        >
          <div
            className="demo_edit"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* <Contact_details /> */}
            {/* <Dietary_information /> */}
            {/* <Demographic_information />
        <Medical_information />
        <Marketing_information /> */}
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default Demo_page;
