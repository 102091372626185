import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./ManageRolesHeader.less";

const ManageRolesHeader = () => {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddRole = authData?.permissions?.includes("add/edit role");

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          <span style={{ fontFamily: "'Poppins', sans-serif" }}>
            Manage Roles
          </span>
        </h3>
        <div className="breadcrum_buttons">
          {allowAddRole ? (
            <Link to="/manage-roles/create">
              <Button type="primary">
                <PlusOutlined /> Create New Role
              </Button>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ManageRolesHeader;
