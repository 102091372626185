import { Skeleton, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllDocumentsTypesApi } from "../../../../apis/eventsApi";
import AddAssetsForm from "./AddOrEditAsset/AssetsForm";
import AssetsListingCard from "./AddOrEditAsset/AssetsListingCard";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";

const AssetTabContainer = ({ eventData, eventId, assetType, addonId }) => {
  const { singleEvent } = useSelector((state) => state.events);
  const { singleEventResponse, loadingSingleEvent } = singleEvent;
  const [documentTypes, setDocumentTypes] = useState([]);
  const [docTypesLoading, setDocTypesLoading] = useState(true);

  useEffect(() => {
    getTaxonomyList("document_type", setDocumentTypes, setDocTypesLoading);
  }, []);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4
            style={{ fontFamily: "'Poppins'", minWidth: "95px", margin: "0" }}
          >
            Assets{" "}
            {loadingSingleEvent ? (
              <Skeleton.Button
                shape="round"
                size="small"
                style={{ width: "60px" }}
              />
            ) : (
              `(${eventData?.field_assets?.length || 0})`
            )}
          </h4>
          <div
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "end",
              flexWrap: "wrap",
            }}
          >
            <AddAssetsForm
              eventData={eventData}
              showMessageForAddOnly={true}
              eventId={eventId}
              addonId={addonId}
              docTypesLoading={docTypesLoading}
              documentTypes={documentTypes}
              assetType={assetType}
            />
          </div>
        </div>

        <Spin spinning={loadingSingleEvent} size="large">
          <AssetsListingCard
            eventData={eventData}
            eventId={eventId}
            addonId={addonId}
            docTypesLoading={docTypesLoading}
            documentTypes={documentTypes}
            assetType={assetType}
          />
        </Spin>
      </div>
    </>
  );
};

export default AssetTabContainer;
