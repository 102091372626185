import { Form, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { useSearchParams } from "react-router-dom";
import {
  getDiscountCategoryListingApi,
  getFeeCategoryListingApi,
} from "../../../../apis/eventsApi";

const columns = [
  {
    title: "Category Code",
    dataIndex: "category_code",
  },

  {
    title: "Category Name",
    dataIndex: "category_name",
  },

  {
    title: "Active?",
    dataIndex: "active",
  },
];

const FeeDiscountCategoriesEdit = ({
  feeType,
  discountType,
  eventId,
  addonId,
  discountId,
  feeId,
  loadingCategories,
  setLoadingCategories,
  categoriesListData,
  setCategoriesListData,
  updatedPayload,
  setUpdatedPayload,
  p,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getCategoriesList(eventId, feeId, discountId, addonId);
  }, []);

  const getCategoriesList = async (eventId, feeId, discountId, addonId) => {
    setLoadingCategories(true);
    const feeData = !!feeType
      ? feeType === "event"
        ? { eventId, feeId }
        : { addonId, feeId }
      : null;
    const discountData = !!discountType
      ? discountType === "event"
        ? { eventId, discountId }
        : { addonId, discountId }
      : null;
    const response = feeId
      ? await getFeeCategoryListingApi(feeData)
      : await getDiscountCategoryListingApi(discountData);
    if (response?.status) {
      setCategoriesListData(response?.data?.listing?.data);
      setLoadingCategories(false);
    } else {
      setCategoriesListData([]);
      message.error("No Categories Are Linked With This Fees");
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    setData(
      categoriesListData?.length
        ? categoriesListData.map((category, i) => ({
            key: category?.category_uuid,
            category_code: category?.category_code,
            category_name: category?.category_name,
            active: (
              <Switch
                checked={category?.active}
                onChange={(value) => {
                  onChangeHandler(value, category, i);
                }}
              />
            ),
          }))
        : []
    );

    setUpdatedPayload(
      categoriesListData.map((category) => ({
        field_category: category?.category_id,
        field_active: category?.active,
      }))
    );
  }, [categoriesListData]);

  const onChangeHandler = (e, category, categoryIndex) => {
    let updatedData = [...categoriesListData];
    updatedData[categoryIndex] = { ...category, active: e };
    setCategoriesListData(updatedData);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loadingCategories, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default FeeDiscountCategoriesEdit;
