import { Button, Card, Divider, Empty, Form, Modal, Tag, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoader from "../../../../others/Loaders/DataLoader";
import {
  contactDetailsEditLoader,
  deleteMembershipProgram,
} from "../../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import AddmembershipDrawer from "./AddmembershipDrawer";
import "./MembershipPrograms.less";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { Meta } = Card;

const MembershipPrograms = ({ contactDetailsData, contactId, isLoading }) => {
  const [programData, setProgramData] = useState({});
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [editIndex, setEditIndex] = useState(null);
  const dispatch = useDispatch();
  const { field_membership_program, field_step } = contactDetailsData;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const { deleteMembershipResponse, loadingDeleteMembership } = useSelector(
    (state) => state.contacts.deleteMembership
  );
  const deleteMembershipRef = useRef(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    if (deleteMembershipRef.current) {
      if (deleteMembershipResponse.status) {
        const msg = "Membership Programs Deleted";
        message.success(msg);
      } else {
        message.error(
          deleteMembershipResponse?.data?.response?.data?.message ||
            deleteMembershipResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    deleteMembershipRef.current = true;
  }, [deleteMembershipResponse]);

  const onEditHandler = (memberData, index) => {
    setProgramData(memberData);
    setEditIndex(index);
    form.setFieldsValue({
      field_program_name: memberData?.field_program_name?.id || null,
      field_program_type: memberData?.field_program_type?.id || null,
      field_membership_number: memberData?.field_membership_number || null,
      field_status_level: memberData?.field_status_level?.id || null,
    });
    setOpen(true);
  };

  const deleteModal = (memberData, index) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(contactDetailsEditLoader(false));
        const remainingMembershipProgram = field_membership_program.filter(
          (program, programIndex) => programIndex !== index
        );

        const reqHeaders = customRequestHeader({ token: Token });
        const payload = {
          data: {
            type: "contact--contact",
            id: contactId,
            attributes: {
              field_membership_program: remainingMembershipProgram.map(
                (membership) => ({
                  field_program_type:
                    membership?.field_program_type?.id || null,
                  field_program_name:
                    membership?.field_program_name?.id || null,
                  field_membership_number:
                    membership?.field_membership_number || null,
                  field_status_level:
                    membership?.field_status_level?.id || null,
                })
              ),
              field_step,
            },
            relationships: {
              modified_by: {
                data: {
                  type: "user--user",
                  id: userUuid,
                },
              },
            },
          },
        };
        return dispatch(
          deleteMembershipProgram({ payload, reqHeaders, contactId })
        );
      },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Membership Programs</h4>
        <AddmembershipDrawer
          programData={programData}
          membershipProgram={field_membership_program}
          contactId={contactId}
          open={open}
          setOpen={setOpen}
          form={form}
          isLoading={isLoading}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
        />
      </div>
      <Divider className="global_divider mb-0" />

      {isLoading ? (
        <DataLoader />
      ) : (
        <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
          <div>
            <div
            className="overflow-scroll"
              style={{
                display: "flex",
                gap: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {!!field_membership_program?.length ? (
                field_membership_program.map(
                  (singleMembershipProgram, index) => {
                    return (
                      <Card type="inner" style={{ width: "50%" }}>
                        <div className="membership_buttons">
                          {singleMembershipProgram?.field_program_type
                            ?.label ? (
                            <Tag className="primary_color" color="blue">
                              {
                                singleMembershipProgram?.field_program_type
                                  ?.label
                              }
                            </Tag>
                          ) : null}
                          {singleMembershipProgram?.field_status_level
                            ?.label ? (
                            <Tag className="primary_color" color="blue">
                              {singleMembershipProgram?.field_status_level
                                ?.label || ""}
                            </Tag>
                          ) : null}
                        </div>
                        <p
                          style={{
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: "600",
                            fontFamily: "'Poppins'",
                          }}
                        >
                          {singleMembershipProgram?.field_program_name?.label ||
                            ""}
                        </p>
                        <p>
                          <span>
                            {singleMembershipProgram?.field_membership_number ||
                              ""}
                          </span>
                        </p>
                        <Divider className="mb-0" />
                        <div className="card_anchor">
                          <Button
                            onClick={() => {
                              onEditHandler(singleMembershipProgram, index);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              deleteModal(singleMembershipProgram, index);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </Card>
                    );
                  }
                )
              ) : (
                <div className="empty_data">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MembershipPrograms;
