import React, { useEffect, useState } from "react";
import { EditOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Form, Select, message } from "antd";
import {
  editMarketingSource,
  getMarketingSourceList,
} from "../../../../../apis/eventsApi";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "../../../../../others/Debounce/debounce";

const EditDeleteModal = (props) => {
  const {
    data,
    sourceModal,
    section,
    sourceParaId,
    channelParaId,
    setRefreshData,
    tab,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [drupalInternalId, setDrupalInternalId] = useState();
  const [editValue, setEditValue] = useState("");
  const [addOrEdit, setAddOrEdit] = useState("edit");
  const [loading, setLoading] = useState(false);
  const [nameEdit, setNameEdit] = useState(false);
  const [searchParams,setSearchParams] = useSearchParams()
  const isGlobal = searchParams.get("global")
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { singleEvent } = useSelector((state) => state.events);
  const [form] = Form.useForm();
  const { singleEventResponse } = singleEvent;
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchMarketingSource(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (singleEventResponse.status) {
      const { drupal_internal__id } = singleEventResponse?.data?.data;
      setDrupalInternalId(drupal_internal__id);
    }
  }, [singleEventResponse]);

  const searchMarketingSource = async (e) => {
    const response = await getMarketingSourceList(e, section);

    if (response?.status) {
      if (!e) {
        setOptions([]);
      } else {
        const { data } = response?.data;
        if(!data.length){
          setOptions([{
            value: e,
            label: e,
            key:""
          }]);
          setAddOrEdit("add");
          return
        }
        let list = [];
        data.forEach((element, index) => {
          list.push({
            value: `${element.name}-${element.drupal_internal__id}`,
            label: element.name,
            key: element.drupal_internal__id,
          });
        });
        const dataArray = []  
        list.map(val=>{
          if(!dataArray.length)return dataArray.push(val)
         const data =  dataArray.map((value,index,self)=>{
            if((val?.label)?.toLowerCase() === value?.label?.toLowerCase()){
              return val
            }
          }).filter(Boolean)
          if(!data.length) dataArray.push(val)
        })
        const labelExists = dataArray.some((item) => item.label === e);
        if (labelExists) {
          setAddOrEdit("edit");
        } else {
          setAddOrEdit("add");
        }
        setOptions(dataArray);
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalFirst, setIsModalOpenFirst] = useState(false);

  useEffect(() => {
    if (nameEdit) {
      setIsModalOpenFirst(true);
    }
  }, [nameEdit]);
  const handleOkFirst = () => {
    setIsModalOpenFirst(false);
    setNameEdit(false);
  };
  const handleCancelFirst = () => {
    setIsModalOpenFirst(false);
    setNameEdit(false);
  };

  const deleteFromParentSection = (name, sec,id) => {
    let filteredChannelParaID = data?.channels?.filter((e) => e.name === name);
    const deleteItem = data?.details?.find(val=> val?.id == id)
    let payload = {
      type: tab || "event",
      event_id: drupalInternalId || "global",
      operation: "remove",
      marketing_data_type: sec,
      source_paragraph: sourceParaId,
      channel_paragraph:
        sec === "channel" ? filteredChannelParaID[0].para_id : data.para_id,
      data: [
        {
          name: name,
          id: deleteItem?.id,
          paragraph_id:
            sec === "channel" ? filteredChannelParaID[0].para_id : undefined,
        },
      ],
    };
    editMS(payload);
  };

  const deleteSection = (name) => {
    let payload = {
      type: tab || "event",
      event_id: drupalInternalId || "global",
      operation: "remove",
      marketing_data_type: section,
      source_paragraph: sourceParaId,
      channel_paragraph:
        section === "channel" && channelParaId ? channelParaId : null,
      data: [
        {
          name: name,
          id: data?.id,
          paragraph_id: section === "channel" ? data.para_id : undefined,
        },
      ],
    };
    editMS(payload);
  };

  const editMS = async (payload) => {
    const response = await editMarketingSource(payload);
    if (response?.status) {
      if (response.data.data.message.includes("duplicate")) {
        message.error(response?.data?.data?.message || SOMETHING_WENT_WRONG);
      } else {
        message.success(response.data.data.message);
        setIsModalOpen(false);
        setRefreshData(true);
      }
    } else {
      message.error(response?.data?.response?.data?.data?.message || SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const handleFinish = () => {
    setLoading(true);
    if (selectedValue.length) {
      let payload = {
        type: tab || "event",
        event_id: drupalInternalId || "global",
        operation:"add",
        marketing_data_type:
          section === "source"
            ? "channel"
            : section === "channel"
            ? "detail"
            : "detail",
        source_paragraph: sourceParaId,
        channel_paragraph:
          section === "channel" && channelParaId ? channelParaId : null,
        data: [],
      };
      selectedValue.forEach((val) => {
        const selected = options.filter((e) => e.value === val?.value);
        // if (!selected.length) {
        //   payload.operation = "add";
        // }
        payload.data.push({
          name: selected.length ? selected[0].label : val?.label,
          id: selected.length ? selected[0].key : val?.key,
          paragraph_id:
            addOrEdit === "add"
              ? null
              : section === "channel"
              ? data.para_id
              : undefined,
        });
      });
      editMS(payload);
    }else{
      message.error("Channel is not selected")
      setLoading(false);
    }
  };
  const saveEdit = () => {
    setLoading(true);
    let payload = {
      type: tab || "event",
      event_id: drupalInternalId || "global",
      operation: "edit",
      marketing_data_type: section,
      source_paragraph: sourceParaId,
      channel_paragraph:
        (section === "channel" || section === "detail") && channelParaId
          ? channelParaId
          : null,
      data: [{ name: editValue, id: data.id }],
    };
    editMS(payload);
  };

  const deleteDetail = () => {};

  return (
    <>
      {section === "channel" ? (
        <p onClick={() => setNameEdit(true)}>{data.name}</p>
      ) : null}
      {section === "source" ? (
        <p onClick={() => setNameEdit(true)} id={data.id}>
          {data.source_name}
        </p>
      ) : null}
      {section === "detail" ? (
        <p onClick={() => setNameEdit(true)}>{data.name}</p>
      ) : null}
      <Modal
        footer={
          <>
            <Button type="primary" onClick={() => saveEdit()} loading={loading}>
              Save
            </Button>
          </>
        }
        title="Edit"
        open={isModalFirst}
        onOk={handleOkFirst}
        onCancel={handleCancelFirst}
      >
        <div className="textarea_group">
          <textarea
            rows={4}
            onChange={(e) => setEditValue(e.target.value)}
            defaultValue={data.source_name || data.name}
          ></textarea>
        </div>
      </Modal>

      {section !== "detail" ? (
        <>
          {" "}
          {permissionChecker("edit marketing sources") ?  <span onClick={showModal}>
            <span>
              <EditOutlined />
            </span>
          </span> : null}
          {section === "source" && isGlobal === "true" ?  null : permissionChecker("delete marketing sources") ? <span onClick={() => deleteSection(data.source_name || data.name)}>
            <DeleteOutlined />
          </span> : null}
          <Modal
            title={
              <>
                <span className="document_pdf">
                  {" "}
                  {`Manage ${section === "source" ? "Channels" : "Details"}`}
                </span>
                <span className="span_text">
                  {" "}
                  ({data.source_name || data.name})
                </span>
              </>
            }
            footer={
              <>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => form.submit()}
                >
                  Save Changes
                </Button>
              </>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div>
              <Form
                layout="vertical"
                form={form}
                onFinish={() => handleFinish()}
              >
                <div className="textarea_row">
                  <Form.Item
                    label={`Select / Create ${
                      section === "source" ? "Channels" : "Details"
                    }`}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      style={{
                        width: "100%",
                      }}
                      onSelect={(_,value) => {
                        setSelectedValue(prev=>{
                          return [...prev,value]
                        })
                      }}
                      onDeselect={(_,value) => {
                        setSelectedValue(prev=>{
                        const updatedList = []
                        prev.map(val=>{
                            if(val.key !== value.key){
                              updatedList.push(val)
                            }
                          })
                          return updatedList
                        })
                      }}
                      onSearch={handleSearch}
                      options={options}
                    />

                    <span className="span_text">
                      {`Press enter to add ${
                        section === "source" ? "Channels" : "Details"
                      }`}
                    </span>
                  </Form.Item>
                </div>

                <div className="main-collapse">
                  <p>
                    {" "}
                    {`Selected ${
                      section === "source" ? "Channels" : "Details"
                    }`}
                  </p>

                  <div className="main-collapse--button">
                    {data?.channels?.length
                      ? data?.channels.map((channel) => {
                          return (
                            <Button>
                              {channel.name}{" "}
                              <CloseOutlined
                                className="span_text"
                                onClick={() =>
                                  deleteFromParentSection(
                                    channel.name,
                                    "channel",
                                    channel.id,
                                  )
                                }
                              />
                            </Button>
                          );
                        })
                      : null}
                    {data?.details?.length
                      ? data?.details.map((channel) => {
                          return (
                            <Button>
                              {channel.name}{" "}
                              <CloseOutlined
                                className="span_text"
                                onClick={() =>
                                  deleteFromParentSection(
                                    channel.name,
                                    "detail",
                                    channel.id
                                  )
                                }
                              />
                            </Button>
                          );
                        })
                      : null}
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      ) : null}
    </>
  );
};
export default EditDeleteModal;
