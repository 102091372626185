import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import {
  HolderOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dragula from "dragula";
import "dragula/dist/dragula.css";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: '',
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
function Adddropdown_table() {
  const defaultColumns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      width: "150px",
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "",
      width: "100px",
      dataIndex: "operation",
      render: (_, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Are you sure you want to delete this?"
            placement="bottomRight"
            onConfirm={() => handleDelete(record.key)}
          >
            <div className="action_flex action_end">
              <DeleteOutlined className="action_delete" />
            </div>
          </Popconfirm>
        ) : null,
    },
  ];
  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);
  const [dataSource, setDataSource] = useState([
    {
      title: <HolderOutlined className="draggable" type="swap" />,
      key: "1",
      name: "Click here to add option",
      // placeholder:"Click here to add options",
    },
  ]);
  const handleReorder = (dragIndex, draggedIndex) => {
    setDataSource((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };
  React.useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReorder(start, end);
    });
  }, []);
  const [count, setCount] = useState(3);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData = {
      title: <HolderOutlined className="draggable" type="swap" />,
      key: count,
      name: `Click here to add option`,
      age: "32",
      address: `London, Park Lane no. ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const { Search } = Input;
  const onSearch = (value: string) => console.log(value);
  return (
    <div>
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 8,
          xxl: 7,
        }}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item label="Dropdown Name" name="Dropdown Name">
          <Input />
        </Form.Item>
      </Form>
      <div className="partnership_inform">
        <p className="dropdown_heading">Dropdown Options</p>
      </div>
      <Table className="custom_table_drag"
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} className="button_style" type="dashed">
        <PlusCircleOutlined /> Add Option
      </Button>
    </div>
  );
}
export default Adddropdown_table;
