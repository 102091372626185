import { Form, Grid, Layout, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import AddonFeeCreateOrEditForm from "../../../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeCreateOrEditForm";
import CreateOrEditAddonFeeHeader from "../../../../../components/Events/EventDetailsTabs/FeesTab/CreateOrEditAddonFeeHeader";

import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Horizontals from "../../../../../components/Header/Horizontal_menu";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { useSearchParams } from "react-router-dom";
import { getFeeDetailsApi } from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header, Content, Sider } = Layout;

const AddonFeeCreateOrEditPage = () => {
  const [form] = Form.useForm();
  const [loadingFeeCreation, setLoadingFeeCreation] = useState(false);
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const editId = searchParams.get("editId");
  const copyId = searchParams.get("copyId");
  const [singleFeeData, setSingleFeeData] = useState({});
  const [singleFeeLoading, setSingleFeeLoading] = useState(false);
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Details",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: editId ? "Edit Fee" : "Add Fee", path: "" },
  ];

  useEffect(() => {
    if (editId || copyId) {
      getFeeDetails(editId || copyId);
    }
  }, []);

  const getFeeDetails = async (feeId) => {
    setSingleFeeLoading(true);
    const response = await getFeeDetailsApi(feeId);
    if (response?.status) {
      setSingleFeeData(response?.data?.data);
      setSingleFeeLoading(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setSingleFeeLoading(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditAddonFeeHeader
              form={form}
              loadingFeeCreation={loadingFeeCreation}
              discountType={"addon"}
              addonId={addonId}
              eventId={eventId}
              editId={editId}
            />
          </div>
          <div className="details-tabs" style={{ margin: 0 }}>
            <div>
              <div className="basic_details pd-16 bg-white">
                <Spin
                  spinning={singleFeeLoading}
                  size="large"
                  children={
                    <AddonFeeCreateOrEditForm
                      feeType={"addon"}
                      form={form}
                      addonId={addonId}
                      eventId={eventId}
                      editId={editId}
                      copyId={copyId}
                      setLoadingFeeCreation={setLoadingFeeCreation}
                      singleFeeData={singleFeeData}
                      setSingleFeeData={setSingleFeeData}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonFeeCreateOrEditPage;
