import { axiosRequest } from "../utils/axios";
import axios from "axios";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
import { customRequestHeader } from "../utils/cutomRequestHeader";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

const getAllVocabulariesApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_vocabulary/taxonomy_vocabulary?page%5Blimit%5D=200&sort=name`,
    customRequestHeader({ token: Token })
  );
};
const getSingleVocabularyApi = async (vocabularyName) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/${vocabularyName}?sort=weight&page%5Blimit%5D=1000`,
    customRequestHeader({ token: Token })
  );
};

const createTermApi = async (data, vocabularyName) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/taxonomy_term/${vocabularyName}?sort=weight`,
    customRequestHeader({ token: Token }),
    { data }
  );
};

const updateTermApi = async (data, vocabularyName, termId) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/taxonomy_term/${vocabularyName}/${termId}?sort=weight`,
    customRequestHeader({ token: Token }),
    { data }
  );
};

const deleteTermApi = async (data) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/taxonomy_term/${data.type}/${data.id}?sort=weight`,
    customRequestHeader({ token: Token }),
    data.data
  );
};

const searchVocabularyApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_vocabulary/taxonomy_vocabulary?filter[name][value]=${data}&filter[name][operator]=CONTAINS`,
    customRequestHeader({ token: Token })
  );
};

const getSingleVocabularyLocationsApi = async (vocabularyName) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/${vocabularyName}?sort=weight`,
    customRequestHeader({ token: Token })
  );
};

const updateSingleVocabularyLocationsApi = async (data, vocabularyName) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/update/weight`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    data
  );
};

export {
  getAllVocabulariesApi,
  getSingleVocabularyApi,
  createTermApi,
  updateTermApi,
  deleteTermApi,
  searchVocabularyApi,
  getSingleVocabularyLocationsApi,
  updateSingleVocabularyLocationsApi,
};
