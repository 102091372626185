import { Button, Skeleton } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const EditEmailTemplateHeader = ({
  templateData,
  form,
  editLoader,
  loadingTemplate,
}) => {
  const navigate = useNavigate();
  const authData = JSON.parse(localStorage.getItem("authorization"));

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          {loadingTemplate ? (
            <Skeleton.Input style={{ width: "300px" }} />
          ) : (
            <span style={{ fontFamily: "'Poppins', sans-serif" }}>
              {`Edit Email Templates : ${templateData?.title}`}
            </span>
          )}
        </h3>

        {loadingTemplate ? null : (
          <div className="breadcrum_buttons">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={editLoader}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default EditEmailTemplateHeader;
