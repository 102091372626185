import { Table } from "antd";
import React from "react";

function AvailabilitySoldCountTable({
  urlParamsObject,
  setUrlParamsObject,
  listCount,
  travelerList,
  loadingTravelerList,
}) {
  const columns = [
    {
      title: "Reservation Id",
      dataIndex: "hash",
      key: "hash",
    },
    {
      title: "Primary Traveler",
      dataIndex: "primary_guest",
      key: "primary_guest",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Modified",
      dataIndex: "modified",
      key: "modified",
    },
  ];
  return (
    <>
      <Table
        className="table_tour"
        columns={columns}
        dataSource={travelerList}
        pagination={{
          current: Number(urlParamsObject?.page),
          pageSize: Number(urlParamsObject?.count),
          showSizeChanger: true,
          total: Number(listCount),
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          position: ["topRight", "bottomRight"],
          onChange: (page, pageSize) => {
            setUrlParamsObject({
              ...urlParamsObject,
              page: page,
              count: pageSize,
            });
          },
        }}
        loading={{ spinning: loadingTravelerList, size: "large" }}
      />
    </>
  );
}

export default AvailabilitySoldCountTable;
