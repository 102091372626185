import { Button, Form, Modal, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { reservationEventsListApi } from "../../../apis/reservationApi";
import { useDispatch } from "react-redux";
import { createOrEditReservation } from "../../../redux/features/reservationFeatures/reservationSlice";
import { useSelector } from "react-redux";
import { ReservationDetailRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { decodeContent } from "../../../utils/contentParser";
var showMessage = false;
function ReservationCreationModal({ isModalOpen, setIsModalOpen }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid, user_id } = authorization;
  const [resEventList, setResEventList] = useState([]);
  const [loadingResEvent, setLoadingResEvent] = useState(false);
  const dispatch = useDispatch();
  const { createReservation } = useSelector((state) => state.reservation);
  const { createReservationResponse, loadingCreateReservation } =
    createReservation;
  const createReservationRef = useRef(false);

  useEffect(() => {
    reservationList();
  }, []);

  useEffect(() => {
    if (createReservationRef.current) {
      if (createReservationResponse.status) {
        if (!showMessage) {
          message.success("Reservation Created Successfully", [1], onClose);
        }
        window.location.href = `${ReservationDetailRoute}?id=${createReservationResponse?.data?.data?.id}`;
        handleCancel();
      } else {
        if (!showMessage) {
          message.error(SOMETHING_WENT_WRONG, [1], onClose);
        }
        handleCancel();
      }
    }

    createReservationRef.current = true;
  }, [createReservationResponse]);

  const onClose = () => {
    showMessage = true;
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const getStatus = (type) => {
    if (type === "Waitlist") {
      return "waitlist";
    } else if (type === "Quotes") {
      return "quote";
    } else if (type === "Reservation") {
      return "temporary_hold";
    }
  };

  const onFinishHandler = (value) => {
    const payload = {
      data: {
        type: "reservation--reservation",
        attributes: {
          status: true,
          name: "reservation",
          reservation_type: value.type,
          reservation_status: getStatus(value.type),
          created_on: "staffweb",
          "modified_on": "staffweb"
        },
        relationships: {
          select_event: {
            data: [
              {
                type: "event--event",
                id: value.select_event,
              },
            ],
          },
          field_last_modified_by: {
            data: {
              type: "user--user",
              id: uuid,
            },
          },
        },
      },
    };
    dispatch(createOrEditReservation({ reqMethod: "post", payload }));
  };

  const reservationList = async () => {
    setLoadingResEvent(true);
    const res = await reservationEventsListApi();
    if (res.status) {
      setResEventList(res?.data);
      setLoadingResEvent(false);
    } else {
      setResEventList([]);
      setLoadingResEvent(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <>
      <Modal
        title={
          <>
            <span className="span_bold">New</span>
          </>
        }
        footer={
          <>
            <Button
              type="primary"
              loading={loadingCreateReservation}
              onClick={() => {
                form.submit();
              }}
            >
              Create
            </Button>
          </>
        }
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 14,
          }}
          wrapperCol={{
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Type"
            name="type"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
            labelCol={{
              sm: 24,
              md: 3,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
          >
            <Select 
             showSearch
             showArrow
             filterOption={(input, option) =>
               option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
               option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
             }>
              <Select.Option value="Reservation" label="Reservation">Reservation</Select.Option>
              <Select.Option value="Quotes" label="Quotes">Quotes</Select.Option>
              <Select.Option value="Waitlist" label="Waitlist">Waitlist</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Event"
            name="select_event"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please Select Event",
              },
            ]}
            labelCol={{
              sm: 24,
              md: 3,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
          >
            <Select
              options={resEventList.map((resEvent) => ({
                key: resEvent.id,
                value: resEvent.uuid,
                label: decodeContent(resEvent.name),
              }))}
              loading={loadingResEvent}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ReservationCreationModal;
