import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useState } from "react";
import PermissionModal from "../PermissionModal";

const columns = [
  {
    title: "Permission Name",
    dataIndex: "roles",
    key: "1",
    sorter: {
      compare: (a, b) => a.chinese - b.chinese,
      multiple: 3,
    },
  },
  {
    title: "Assigned Module",
    dataIndex: "number_members",
    key: "2",
    sorter: {
      compare: (a, b) => a.chinese - b.chinese,
      multiple: 3,
    },
  },
  {
    title: (
      <>
        <div className="action_start">Action</div>
      </>
    ),
    dataIndex: "action",
    key: "3",
  },
];

const ManagePermissionsContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const data = [
    {
      key: 1,
      roles: "Batch Delete",
      number_members: (
        <>
          <span>Organization</span>
        </>
      ),
      action: (
        <>
          <div className="action_flex action_end">
            <EditOutlined onClick={showModal} className="action_edit" />

            <DeleteOutlined className="action_delete" />
          </div>
        </>
      ),
      exp_row: false,
    },

    {
      key: 2,
      roles: "Duplicate",
      number_members: (
        <>
          <span>Intakes</span>
        </>
      ),
      action: (
        <>
          <div className="action_flex action_end">
            <EditOutlined onClick={showModal} className="action_edit" />

            <DeleteOutlined className="action_delete" />
          </div>
        </>
      ),
      exp_row: false,
    },
  ];

  return (
    <>
      <PermissionModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <Table
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

export default ManagePermissionsContainer;
