import { Checkbox, Input, Row, Select, message, Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ReservationTable_header from "./ReservationTable_header";
import ReservationAddOn from "./ReservationAddOn";
import {
  getAddOnList,
  getItemInventory,
} from "../../../../apis/reservationApi";
import moment from "moment";
import AddOnExpandable from "../AddOnExpandable";
import { useNavigate } from "react-router-dom";
import { objectToQueryString } from "../../../../others/util/convertObjectToQueryString";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const AddOnTab = (props) => {
  const {
    travelerFlag,
    getName,
    selectedTravelers,
    travelers,
    paramId,
    setSelectedTravelers,
    searchParams,
    setSearchParams,
  } = props;
  const [addOnTypes, setAddOnTypes] = useState();
  const [selectedType, setSelectedType] = useState();
  const [addOnData, setAddOnData] = useState();
  const [section, setSection] = useState("category");
  const [catDependency, setCatDependency] = useState(true);
  const [location, setLocation] = useState([]);
  const [deck, setDeck] = useState([]);
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(false);
  let reservationId = searchParams.get("id");
  let catId = searchParams.get("catId");
  let replaceItem = searchParams.get("replace-item") || "";

  const fetchInventories = async (e, queryString) => {
    setLoading(true);
    const response = await getItemInventory(
      e,
      selectedTravelers.length,
      queryString
    );
    if (response && response.status) {
      const { data } = response;
      if (data) {
        setSection("inventory");
        let tableVal = [];
        setLocation(data?.location);
        setDeck(data?.deck);
        data?.inventory?.forEach((element, index) => {
          let value = {
            key: index,
            code: element?.inventory_id,
            status: element?.inventory_status,
            name: element?.inventory_name,
            price: element?.price,
            fees: element?.fees,
            discount: element?.discount,
            selling_price: element?.total,
            uuid: element?.inventory_uuid,
            field_machinename: element?.field_machinename,
          };
          tableVal.push(value);
        });
        setAddOnData(tableVal);
        setLoading(false);
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (catId) {
      fetchInventories(catId);
    } else if (reservationId) {
      if (selectedTravelers.length) {
        fetchAddOnList(selectedTravelers.length, "All", true);
        setSelectedType("All");
        setSection("category");
      }
    }
  }, [searchParams, selectedTravelers]);

  const handleClick = async (e) => {
    setSearchParams({ id: reservationId, catId: e.cat_uuid });
  };

  const fetchAddOnList = async (guest, type, cat_dep) => {
    setLoading(true);
    const response = await getAddOnList(props.paramId, guest, type, cat_dep,replaceItem.includes("true") ? true: false);

    if (response && response.status) {
      setAddOnTypes(response?.data?.data?.addon_types);
      const { rows } = response?.data?.data;
      if (rows) {
        let categories = [];
        rows.forEach((element, index) => {
          let values = {
            key: index,
            hash: (
              <>
                <a href="#">{element.id}</a>
              </>
            ),
            type: element?.type,
            name: element?.name,
            start_date:
              element.start_date && moment(element?.start_date).format("L"),
            end_date: element.end_date && moment(element?.end_date).format("L"),
            description: (
              <>
                {element.categories.length && (
                  <AddOnExpandable
                    cat={element?.categories}
                    setSection={setSection}
                    handleClick={handleClick}
                  />
                )}
              </>
            ),
          };
          categories.push(values);
        });
        setAddOnData(categories);
        setLoading(false);
      }
    }
  };

  const onChangeTraveler = (val) => {
    setSelectedTravelers(val);
    fetchAddOnList(val.length, selectedType, catDependency);
  };

  const handleTypeChange = (val) => {
    setSelectedType(val);
    fetchAddOnList(selectedTravelers.length, val, catDependency);
  };
  const handleCategoryChange = (val) => {
    setCatDependency(val);
    fetchAddOnList(selectedTravelers.length, selectedType, val);
  };
  const addQuery = (val, keys) => {
    let existingKeys = Object.keys(query);
    if (existingKeys.indexOf(keys) === -1) {
      let obj = val ? { [keys]: val } : null;
      let queryObj = { ...query, ...obj };
      const queryString = objectToQueryString(queryObj);
      if (section === "inventory") {
        fetchInventories(catId, queryString);
      }
      setQuery(queryObj);
    } else {
      let obj = query;
      if (val) {
        obj[keys] = val;
      } else {
        delete obj[keys];
      }
      const queryString = objectToQueryString(obj);
      fetchInventories(catId, queryString);
      setQuery(obj);
    }
  };

  return (
    <>
      <div
        className="activity_details pd-16 bg-white travelers--form"
        style={{ marginBottom: "20px" }}
      >
        <div>
          <div className="travelers-checkbox--list">
            {travelerFlag && (
              <div>
                {" "}
                <div className="textarea_row">
                  {
                    <Form.Item
                      label={
                        <>
                          <p style={{ margin: "0" }}>Travelers</p>{" "}
                        </>
                      }
                      name="travelers"
                      valuePropName="checked"
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        defaultValue={selectedTravelers}
                        onChange={(e) => onChangeTraveler(e)}
                      >
                        {travelers.map((val, index) => (
                          <Checkbox value={val?.id}>
                            {" "}
                            {getName(
                              val.contact.field_first_name,
                              val?.contact?.field_middle_name,
                              val?.contact?.field_last_name,
                              val?.name
                            )}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  }
                </div>
              </div>
            )}
          </div>
          {section === "category" ? (
            <ReservationTable_header
              addOnTypes={addOnTypes}
              handleTypeChange={handleTypeChange}
              handleCategoryChange={handleCategoryChange}
              section={section}
              loading={loading}
            />
          ) : (
            <>
              <div>
                <Row>
                  {" "}
                  <p className="travelers--form_label">Location: </p>{" "}
                  <Select
                    placeholder="Select Location"
                    onChange={(val) => addQuery(val, "location")}
                    options={location?.map((task) => ({
                      key: task?.id,
                      value: task?.id,
                      label: task?.name,
                    }))}
                  />
                </Row>
                <Row>
                  {" "}
                  <p className="travelers--form_label">Location 2: </p>{" "}
                  <Input
                    placeholder="Enter location"
                    onChange={(val) => addQuery(val.target.value, "location2")}
                  />
                </Row>
                <Row>
                  {" "}
                  <p className="travelers--form_label">Deck: </p>{" "}
                  <Select
                    placeholder="Select Deck"
                    onChange={(val) => addQuery(val, "deck")}
                    options={deck?.map((task) => ({
                      key: task?.id,
                      value: task?.id,
                      label: task?.name,
                    }))}
                  />
                </Row>
                <Row>
                  {" "}
                  <p className="travelers--form_label">Type: </p>{" "}
                  <Input
                    placeholder="Enter Type"
                    onChange={(val) => addQuery(val.target.value, "type")}
                  />
                </Row>
                <Row>
                  {" "}
                  <p className="travelers--form_label">
                    Accessible only:{" "}
                  </p>{" "}
                  <Switch onChange={(val) => addQuery(val, "accessible")} />
                </Row>
                <Row>
                  {" "}
                  {selectedTravelers.length === 1 ? (
                    <>
                      {" "}
                      <p className="travelers--form_label">Share: </p>{" "}
                      <Switch
                        onChange={(val) => {
                          addQuery(val ? 1 : 0, "share");
                        }}
                      />
                    </>
                  ) : null}
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="activity_details pd-16 bg-white"
        style={{ marginBottom: "20px" }}
      >
        <ReservationAddOn
          paramId={paramId}
          setAddOnTypes={setAddOnTypes}
          addOnTypes={addOnTypes}
          addOnData={addOnData}
          section={section}
          reservationId={reservationId}
          catId={catId}
          selectedTravelers={selectedTravelers}
          travelers={travelers}
          loading={loading}
          setLoading = {setLoading}
        />
      </div>
    </>
  );
};

export default AddOnTab;
