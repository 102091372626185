import { customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

export const getAllRolesApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/user_role/user_role",
    customRequestHeader({ token: Token })
  );
};

export const createRoleApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/api/rest/role",
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};
export const updateRoleA = async (payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/rest/role/${payload.id}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};
export const updateRoleApi = async (allPayload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/permission`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    allPayload.payload
  );
};

export const deleteRoleApi = async (id) => {
  return drupalAxiosRequest(
    "delete",
    `/api/rest/role/${id}`,
    customRequestHeader({ token: Token })
  );
};
export const searchRoleApi = async (value) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user_role/user_role?filter[label][value]=${value}&filter[label][operator]=CONTAINS`,
    customRequestHeader({ token: Token })
  );
};

export const getSingleRoleApi = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user_role/user_role/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getUsersCountWithRoleApi = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/role/list`,
    customRequestHeader({ token: Token })
  );
};
