import { Table, Button, Divider, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import parse from "html-react-parser";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const TaskDetails = ({ activityDetails, loadingSingleActivity }) => {
  const {
    field_task_type,
    field_activity_email_type,
    field_activity_task_name,
    field_contact,
    field_reservations,
    user_id,
    field_assignee_staff,
    field_last_modified_by,
    field_due_time,
    field_start_time,
    field_end_time,
    field_task_status,
    field_email_subject,
    field_description,
  } = activityDetails;
  const [searchParams, setSearchParams] = useSearchParams();
  // const [emailBody, setEmailBody] = useState(null);
  const activityId = searchParams.get("activityId");
  const contactId = searchParams.get("contactId");
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddEditTask = authData?.permissions?.includes("add/edit task");
  const allowViewContact = authData?.permissions?.includes("view contact");

  // const DATE_FORMAT = "MM/DD/YYYY";
  // const TIME_FORMAT = "hh:mm A";

  // useEffect(() => {
  //   if (
  //     activityDetails?.field_email_body?.processed &&
  //     activityDetails?.field_task_type === "Email"
  //   ) {
  //     var parsers = new DOMParser();
  //     var xmlDoc = parsers.parseFromString(
  //       activityDetails?.field_email_body?.value,
  //       "text/html"
  //     );
  //     var decodedHtml = xmlDoc.documentElement.textContent;
  //     setEmailBody(decodedHtml);
  //   }
  // }, [activityDetails]);

  const data = [
    {
      key: "1",
      label: "Task Type",
      input: (
        <>
          <span className="document_pdf">{field_task_type?.label}</span>
        </>
      ),
    },
    {
      key: "2",
      label: "Task Name",
      input: (
        <>
          <span className="document_pdf">
            {field_activity_task_name?.id && field_activity_task_name?.name}
          </span>
        </>
      ),
    },

    {
      key: "3",
      label: "Description",
      input: (
        <>
          <div className="document_pdf">
            {field_task_type?.label === "Email"
              ? `Email Subject: ${field_email_subject || ""}`
              : `${field_description || ""}`}
          </div>
          {field_task_type?.label === "Email" && (
            <>
              {/* <div className="document_pdf">
                Celebration 2023 Hawaii Cruise - R980105
              </div> */}
              <div className="document_pdf">
                Email To : {activityDetails?.field_email || ""}
              </div>
              {/* <div className="document_pdf">
                Email CC: 'Kelly Astemborski' (alohamekelly@gmail.com){" "}
              </div> */}
              {activityDetails?.field_description && (
                <div className="document_pdf">
                  {activityDetails?.field_description}
                </div>
              )}
            </>
          )}
        </>
      ),
    },

    {
      key: "4",
      label: "Vendor",
      input: (
        // <>
        //   <span className="document_pdf">{activityDetails?.field_organizations?.field_organization_name || ""}</span>
        // </>
        <>
          <a
            href={`/organization/details?id=${activityDetails?.field_organizations?.id}`}
          >
            {activityDetails?.field_organizations?.id &&
              `${
                activityDetails?.field_organizations?.field_organization_name ||
                ""
              }`}
          </a>
        </>
      ),
    },

    // {
    //   key: "4",
    //   label: "Email Type",
    //   input: <>
    //   <span className="document_pdf">Confirmation</span>
    //   </>,
    // },

    {
      key: "5",
      label: "Contact",
      input: (
        <>
          {allowViewContact ? (
            <a href={`/contact/details?id=${field_contact?.id}`}>
              {field_contact?.id &&
                `${field_contact?.name} (${field_contact?.drupal_internal__id})`}
            </a>
          ) : (
            field_contact?.id &&
            `${field_contact?.name} (${field_contact?.drupal_internal__id})`
          )}
        </>
      ),
    },

    {
      key: "6",
      label: "Event",
      input: (
        <>
          <a href={`/event/details?id=${activityDetails?.field_event?.id}`}>
            {activityDetails?.field_event?.name || ""}
          </a>
        </>
      ),
    },
    {
      key: "7",
      label: "Reservation",
      input: (
        <>
          <a href="#">
            {field_reservations?.name
              ? `${field_reservations?.name} (${field_reservations?.drupal_internal__id})`
              : ""}
          </a>
        </>
      ),
    },

    {
      key: "8",
      label: "Creator",
      input: (
        <>
          <span className="document_pdf">
            {user_id?.field_first_name || user_id?.field_last_name
              ? user_id?.field_first_name + " " + user_id?.field_last_name
              : user_id?.display_name || " "}
          </span>
        </>
      ),
    },

    {
      key: "9",
      label: "Assignee",
      input: (
        <>
          <span className="document_pdf">
            {field_assignee_staff?.field_first_name ||
            field_assignee_staff?.field_last_name
              ? field_assignee_staff?.field_first_name +
                " " +
                field_assignee_staff?.field_last_name
              : field_assignee_staff?.display_name || " "}
          </span>
        </>
      ),
    },

    {
      key: "10",
      label: "Due Time",
      input: (
        <>
          <span className="document_pdf">
            {field_due_time ? moment(field_due_time).format(DATE_FORMAT) : ""}{" "}
            {field_due_time ? moment(field_due_time).format(TIME_FORMAT) : ""}{" "}
          </span>
          <span className="span_text">
            {field_due_time ? moment(field_due_time).from(moment()) : ""}
          </span>
        </>
      ),
    },

    {
      key: "11",
      label: "Start Time",
      input: (
        <>
          <span className="document_pdf">
            {field_start_time
              ? moment(field_start_time).format(DATE_FORMAT)
              : ""}{" "}
            {field_start_time
              ? moment(field_start_time).format(TIME_FORMAT)
              : ""}{" "}
          </span>
          <span className="span_text">
            {field_start_time ? moment(field_start_time).from(moment()) : ""}
          </span>
        </>
      ),
    },

    {
      key: "12",
      label: "End Time",
      input: (
        <>
          <span className="document_pdf">
            {field_end_time ? moment(field_end_time).format(DATE_FORMAT) : ""}{" "}
            {field_end_time ? moment(field_end_time).format(TIME_FORMAT) : ""}{" "}
          </span>
          <span className="span_text">
            {field_end_time ? moment(field_end_time).from(moment()) : ""}
          </span>
        </>
      ),
    },

    {
      key: "13",
      label: "Task Status",
      input: (
        <>
          <span className="document_pdf">{field_task_status || ""}</span>
        </>
      ),
    },

    {
      key: "14",
      label: "Percent Done",
      input: (
        <span className="document_pdf">
          {activityDetails?.field_percentage_done || ""}
        </span>
      ),
    },

    {
      key: "15",
      label: "Priority",
      input: (
        <span className="document_pdf">
          {activityDetails?.field_priority || ""}
        </span>
      ),
    },

    {
      key: "16",
      label: "Tags",
      input: (
        <span className="document_pdf">
          {activityDetails?.field_activity_tags?.length
            ? activityDetails?.field_activity_tags?.map(({ name }) => (
                <Tag
                // className="primary_color"
                // color="geekblue"
                >
                  {name}
                </Tag>
              ))
            : ""}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Task Details</h4>
        <span>
          {/* <Link to="/Reservation_activityemailview"> */}
          {permissionChecker("edit task details and message") ? (
            <Link
              to={`/contact/details/activities/edit?activityId=${activityId}&contactId=${contactId}`}
            >
              <Button type="y" icon={<EditOutlined />} />
            </Link>
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        loading={{ spinning: loadingSingleActivity, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default TaskDetails;
