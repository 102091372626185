import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";

import Sidebar from "../../components/Header/Header_sidebar";
// import Horizontals from '../../components/Header/Horizontal_menu';
import Searchreservations_menu from "../../components/Reservation/ReservationSearchBar";
import Reservationitem_tabs from "../../components/Reservation/ReservationItem/Reservationitem_tabs";
import Reservationitem_header from "../../components/Breadcrumb/Reservations_header/Reservationitem_header";
import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
  ReservationRoute,
} from "../../url-routes/urlRoutes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Reservationadditemsview_page() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [nameAndId, setNameAndId] = useState();
  const [isReplaceItem, SetIsReplaceItem] = useState(false);
  let reservationId = searchParams.get("id");
  let replaceItem = searchParams.get("replace-item");
  let name = searchParams.get("name");
  const navigate  = useNavigate()
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    {
      title: "Details",
      path: `${ReservationDetailRoute}?id=${reservationId}`,
    },
    { title: isReplaceItem ? `Replace Item` : `Add Item`, path: "" },
  ];
  useEffect(()=>{
    const isAllowed = permissionChecker("add/edit reservation");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  useEffect(() => {
    if (name) {
      setNameAndId({ name: name });
    }
    if (replaceItem) {
      SetIsReplaceItem(true);
    }
  }, [searchParams]);
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <Reservationitem_header
              nameAndId={nameAndId}
              isReplaceItem={isReplaceItem}
              reservationId={reservationId}
            />
          </div>
          <Reservationitem_tabs
            setNameAndId={setNameAndId}
            isReplaceItem={isReplaceItem}
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Reservationadditemsview_page;
