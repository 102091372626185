import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Res_activityemailform() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Task Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Task Type" name="Task Type">
              <label>Email</label>
            </Form.Item>
            <Form.Item
              name=" Task Name"
              label=" Task Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select defaultValue="Email Confirmation">
                <option value="Passengers Phone Call">
                Traveler Phone Call
                </option>
                <option value="Waitlist Information">
                  Waitlist Information
                </option>
                <option value="Reservation Information">
                  Reservation Information
                </option>
                <option value="Passenger Email">Traveler Email</option>
                <option value="Web Reservation">Web Reservation</option>
                <option value="Email Confirmation">Email Confirmation</option>
              </Select>
            </Form.Item>

            <div className="textarea_row">
              <Form.Item label="Description" name="Description">
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <Form.Item name=" Vendor" label=" Vendor">
              <Select placeholder="">
                <option value="Akorn">Akorn</option>
                <option value="AmaWaterways">AmaWaterways</option>
                <option value="American Airlines">American Airlines</option>
                <option value="Andy's Tours">Andy's Tours</option>
                <option value="ARS Incoming">ARS Incoming</option>
                <option value="Avalon Waterways">Avalon Waterways</option>
                <option value="Be Unique (formerly Inter Trav)">
                  Be Unique (formerly Inter Trav)
                </option>
              </Select>
            </Form.Item>
            <Form.Item name=" Product" label=" Event">
              <Select placeholder="">
                <option value="28485">HAL - Eurodam - Charter (28485)</option>
                <option value="28437">HAL - Eurodam - Half Ship (28437)</option>
                <option value="28772">
                  HAL - Nieuw Statendam - Half Ship (28772)
                </option>
                <option value="28844">HAL - Oosterdam - Charter (28844)</option>
                <option value="28812">ICT USA Tour (28812)</option>
                <option value="31791">WV24IS FOR SETUP (31791)</option>
              </Select>
            </Form.Item>
            <Form.Item label="Contact" name="Contact">
              <label>
                <a>David W Astemborski (79642)</a>
              </label>
            </Form.Item>
            <Form.Item label="Reservation" name="Reservation">
              <label>
                <a>IMC Gospel Music Celebration 2023 Hawaii Cruise - R980105</a>
              </label>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form
              name="basic"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item label="Creator" name="Creator">
                <label>System</label>
              </Form.Item>
              <Form.Item
                name=" Assignee Staff"
                label=" Assignee Staff"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select defaultValue="System">
                  <option value="Aaron Phanco">Aaron Phanco</option>
                  <option value="Alexis Kalugin">Alexis Kalugin</option>
                  <option value="Alicia Green">Alicia Green</option>
                  <option value="Andrea Torres">Andrea Torres</option>
                  <option value="Arpee Caron">Arpee Caron</option>
                </Select>
              </Form.Item>

              <Form.Item label="Due Time" name="Due Time">
                <div className="end_time">
                  <DatePicker
                    style={{ width: "47%", height: "40px" }}
                    format={"MM/DD/YYYY"}
                  />
                  <TimePicker
                    onChange={onChange}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{ width: "47%", marginLeft: "20px", height: "40px" }}
                  />
                </div>
              </Form.Item>
              <Form.Item label="Start Time" name="Start Time">
                <div className="end_time">
                  <DatePicker
                    style={{ width: "47%", height: "40px" }}
                    format={"MM/DD/YYYY"}
                  />
                  <TimePicker
                    onChange={onChange}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{ width: "47%", marginLeft: "20px", height: "40px" }}
                  />
                </div>
              </Form.Item>

              <Form.Item label="End Time" name="End Time">
                <div className="end_time">
                  <DatePicker
                    style={{ width: "47%", height: "40px" }}
                    format={"MM/DD/YYYY"}
                  />
                  <TimePicker
                    onChange={onChange}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{ width: "47%", marginLeft: "20px", height: "40px" }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name=" Task Status"
                label=" Task Status"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select defaultValue="Complete">
                  <option value="0">New</option>
                  <option value="1">Closed</option>
                  <option value="2">Rework</option>
                  <option value="3">Returned</option>
                  <option value="4">Started</option>
                </Select>
              </Form.Item>
              <Form.Item label="Percent Done" name="Percent Done">
                <Input />
              </Form.Item>
              <Form.Item name=" Priority" label=" Priority">
                <Select placeholder="">
                  <option value="A">New</option>
                  <option value="B">Closed</option>
                  <option value="C">Rework</option>
                </Select>
              </Form.Item>
              <Form.Item label="Tag">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                  optionLabelProp="label"
                  placeholder=""
                >
                  <Option value="Business Contacts " label="Business Contacts ">
                    <div className="demo-option-label-item">
                      Business Contacts
                    </div>
                  </Option>

                  <Option value="Document Notes  " label="Document Notes  ">
                    <div className="demo-option-label-item">
                      Document Notes{" "}
                    </div>
                  </Option>
                  <Option value="Guest Services  " label="Guest Services  ">
                    <div className="demo-option-label-item">
                      Guest Services{" "}
                    </div>
                  </Option>
                  <Option value="Waitlist   " label="Waitlist   ">
                    <div className="demo-option-label-item">Waitlist</div>
                  </Option>
                  <Option value="Web Reservation" label="Web Reservation">
                    <div className="demo-option-label-item">
                      Web Reservation{" "}
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default Res_activityemailform;
