import { Table, Button, Divider, Select, Space, Switch, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useState } from "react";

const columns = [
  {
    title: "Item",
    dataIndex: "item",
    key: "1",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "2",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "3",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "4",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "5",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "6",
  },
  {
    title: "Staff",
    dataIndex: "staff",
    key: "7",
  },
  {
    title: "Primary Item",
    dataIndex: "primaryitem",
    key: "8",
  },
];

const data = [
  {
    key: 1,
    item: "Available (50)",

    children: [
      {
        key: 1,
        item: "00",
        count: "50",
        category: "Motorcoach",
        name: "",
        gender: "",
        age: "",
        staff: "",
        primaryitem: "",
      },
    
    ],
  },
  {
    key: 2,
    item: "Reservation Hold (2)",

    children: [
      {
        key: 3,
        item: "00",
        count: "1",
        category: "Motorcoach",
        name: "Mark Douglas Graham",
        gender: "M",
        age: "51",
        staff: "",
        primaryitem: "TourBNA",
      },
      {
        key: 4,
        item: "001",
        count: "1",
        category: "Motorcoach",
        name: "Patricia Lee Devine",
        gender: "F",
        age: "49",
        staff: "",
        primaryitem: "TourBNA",
      },
     
    ],
  },

];

// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

function Event_motorcoachtable() {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [editingStudent, setEditingStudent] = useState(null);
  const [checkStrictly, setCheckStrictly] = useState(false);
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  return (
    <div>
      <div className="partnership_inform">
        <h4>Items</h4>
        <span>
        Group By:
          <Select
          style={{ width: '150px', marginLeft: '5px' }}
            defaultValue="Status"
            onChange={handleChange}
            options={[
              {
                value: "Reservation ",
                label: "Reservation",
              },
              {
                value: "Category",
                label: "Category",
              },
              {
                value: "Item",
                label: "Item",
              },
              {
                value: "Group",
                label: "Group",
              },
            ]}
          />
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      {/* table */}
      <div className="event_unassign_search">
        <Search placeholder="Search" onSearch={onSearch} />
      </div>

      <Table
        className="reservation_main-tablee icon_change_transfer"
        defaultExpandAllRows
        columns={columns}
        rowSelection={{
          ...rowSelection,
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: 85,
          position: ["topRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
        dataSource={data}
      />
    </div>
  );
}

export default Event_motorcoachtable;
