import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const PermissionModal = ({ isModalOpen, handleOk, handleCancel }) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <>
      <Modal
        title={
          <>
            <span className="document_pdf">Create Permission</span>
          </>
        }
        footer={
          <>
            {" "}
            <Link to="">
              <Button type="primary" onClick={handleOk}>
                Create Permission
              </Button>
            </Link>
          </>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 7,
            xxl: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 16,
            xxl: 16,
          }}
          layout="horizontal"
        >
          <Form.Item label="Select Module">
            <Select
              options={[
                {
                  value: "Contacts",
                  label: "Contacts",
                },
                {
                  value: "Organization",
                  label: "Organization",
                },

                {
                  value: "Event",
                  label: "Event",
                },
                {
                  value: "Reservation",
                  label: "Reservation",
                },
                {
                  value: "Intake",
                  label: "Intake",
                },
                {
                  value: "Reports",
                  label: "Reports",
                },
                {
                  value: "Admin",
                  label: "Admin",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Permission Name" name="Permission Name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PermissionModal;
