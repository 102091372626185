import { Grid, Layout } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomDropdownContainer from "../../../components/Admin/CustomDropdown/CustomDropdownContainer";
import CustomDropdownHeader from "../../../components/Admin/CustomDropdown/CustomDropdownHeader";
import CustomDropdownSearchbar from "../../../components/Admin/CustomDropdown/CustomDropdownSearchbar";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { permissionChecker } from "../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

const CustomDropdownPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Custom Dropdowns", path: "" },
  ];
  const navigate = useNavigate();
  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu custom dropdown");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CustomDropdownSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CustomDropdownHeader />
          </div>
          <Content>
            <CustomDropdownContainer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CustomDropdownPage;
