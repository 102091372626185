import { contactAxiosRequest } from "../utils/contactDrupalAxios";
import { customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
export const createEventApi = async (params) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const { reqType, uuid, data } = params;
  return await contactAxiosRequest(
    reqType,
    `/api/v1/event/event/${uuid || ""}`,
    customRequestHeader({ token: Token }),
    data
  );
};
// export const createEventApi = async (payload) => {
//   return await drupalAxiosRequest("post", "/api/v1/event/event", payload);
// };

export const getSingleEventApi = async (uuid) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/event/event/${uuid}?include=field_destination,field_event_partner,field_ship,field_payment_processor,field_multi_currency,field_commission_calculation,field_event_supplier,field_coa_brand,modified_by,field_event,user_id
    `,
    customRequestHeader({ token: Token })
  );
};

export const getAllEventsApi = async (data) => {
  return await drupalAxiosRequest("get", data.url, data.reqHeaders);
};

export const getAllEventsWrapperApi = async (urlQuery) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await drupalAxiosRequest(
    "get",
    `/api/rest/events/wrapper?${urlQuery}`,
    customRequestHeader({ token: Token })
  );
};

export const deleteEventApi = async (deleteId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/event/event/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

export const getEventSearchDropdownApi = async (param) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/event/${param}`,
    customRequestHeader({ token: Token })
  );
};

export const getEventTypeApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/event_type?sort=weight`,
    customRequestHeader({ token: Token })
  );
};
export const getEventPartnersApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/node/organization?filter%5B2%5D%5Bcondition%5D%5Bpath%5D=field_organization_type&filter%5B2%5D%5Bcondition%5D%5Bvalue%5D=headliner&filter%5B2%5D%5Bcondition%5D%5BmemberOf%5D=or-group&filter%5Bor-group%5D%5Bgroup%5D%5Bconjunction%5D=OR&filter%5Bfield_organization_type%5D%5Bcondition%5D%5Bpath%5D=field_organization_type&filter%5Bfield_organization_type%5D%5Bcondition%5D%5Bvalue%5D=event_partner&filter%5Bfield_organization_type%5D%5Bcondition%5D%5BmemberOf%5D=or-group&page%5Blimit%5D=1000`,
    customRequestHeader({ token: Token })
  );
};
export const getEventSuppliersApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/node/organization?filter[field_organization_type][operator]=NOT%20IN&filter[field_organization_type][value][0]=event_partner`,
    customRequestHeader({ token: Token })
  );
};
export const getEventDestinationsApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/destination?sort=weight&page[limit]=150`,
    customRequestHeader({ token: Token })
  );
};
export const getEventShipApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/ship?sort=weight",
    customRequestHeader({ token: Token })
  );
};
// export const getEventStatusApi = async () => {
//   return await drupalAxiosRequest("get", "/api/v1/taxonomy_term/event_status");
// };
export const getEventPaymentProcessorApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/payment_processor?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getEventMultiCurrencyApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/multi_currency?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getEventDefaultPayTermApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/default_pay_terms?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getEventCoaBrandApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/coa_brand?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getEventCommissionCalculationApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/commission_calculation?sort=weight",
    customRequestHeader({ token: Token })
  );
};

//EVENT DETAILS
export const cloneEventApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/event/clone`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getEventReservationApi = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/event/reservation/statics/${id}`,
    customRequestHeader({ token: Token })
  );
};
export const getEventStatusApi = async (eventId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/event_status/${eventId}`,
    customRequestHeader({ token: Token })
  );
};
export const updateEventStatusApi = async (eventId, payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/event/event/${eventId}`,
    customRequestHeader({ token: Token }),

    payload
  );
};
export const getAllAccommodationListApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/accommodation_type/accommodation_type",
    customRequestHeader({ token: Token })
  );
};
export const getAllCategoryListApi = async (eventId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/category/${eventId}`,
    customRequestHeader({ token: Token })
  );
};
export const createAccommodationApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/accommodation_type/accommodation_type",
    customRequestHeader({ token: Token }),
    payload
  );
};
export const createOrUpdateCategoryApi = async ({ id, payload }) => {
  return await drupalAxiosRequest(
    `${id ? "patch" : "post"}`,
    `/api/v1/category/category/${id || ""}`,
    customRequestHeader({ token: Token }),
    payload
  );
};

export const updateAccommodationApi = async (payload, accId) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/accommodation_type/accommodation_type/${accId}`,
    customRequestHeader({ token: Token }),
    payload
  );
};
export const getAccomodationTypesByEventIdApi = async (eventId, subGroup) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/accommodation/type/${eventId}?sub_group=${subGroup}`,
    customRequestHeader({ token: Token })
  );
};

export const getSingleAccommodationApi = async (accId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/accommodation_type/accommodation_type/${accId}?include=field_event`,
    customRequestHeader({ token: Token })
  );
};
export const getCategoriesByAccIdApi = async (accId, type) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/list/category/listing?${
      type === "sub_acc" ? "sub_uuid" : "acc_uuid"
    }=${accId}`,
    customRequestHeader({ token: Token })
  );
};

export const getAllAccommodationRelatedCategoryListApi = async (accId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  return await contactAxiosRequest(
    "get",
    `/api/v1/accommodation_type/accommodation_type?filter[field_event.id]=${accId}`,
    customRequestHeader({ token: Token })
  );
};
export const deleteAccommodatiobnApi = async (deleteId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/accommodation_type/accommodation_type/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

// Event Details --> Assets Tab
export const getAllDocumentsTypesApi = async (id) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/document_type?sort=weight`,
    customRequestHeader({ token: Token })
  );
};

export const createAssetsApi = async ({ id, payload }) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  return await contactAxiosRequest(
    `${id ? "patch" : "post"}`,
    `/api/v1/media/assets${id ? `/${id}` : ""}`,
    customRequestHeader({ token: Token }),
    payload
  );
};

export const getAssetDetailApi = async (id) => {
  if (!id) return;
  return await contactAxiosRequest(
    "get",
    `/api/v1/media/assets/${id}?include=field_media_file`,
    customRequestHeader({ token: Token })
  );
};

export const deleteAssetsApi = async (id) => {
  return await contactAxiosRequest(
    "delete",
    `/api/v1/media/assets/${id}`,
    customRequestHeader({ token: Token })
  );
};

// Event Detail --> Category Detail
export const getPriceCodeTaxonomies = async (id) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/price_code?sort=weight`,
    customRequestHeader({ token: Token })
  );
};

export const getSingleCategoryApi = async (id) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/category/category/${id}?include=field_event,field_accommodation_type_sub_group,field_accommodation_type,field_flight`,
    customRequestHeader({ token: Token })
  );
};

export const updateSingleCategoryApi = async ({ id, payload }) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  return await contactAxiosRequest(
    "patch",
    `/api/v1/category/category/${id}`,
    customRequestHeader({ token: Token }),
    payload
  );
};

export const deleteSingleCategoryApi = async (id) => {
  return await contactAxiosRequest(
    "delete",
    `/api/v1/category/category/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const inventoryAvailabilityApi = async (categoryId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/rest/inventory/availability/${categoryId}`,
    customRequestHeader({ token: Token })
  );
};

export const cloneCategoryApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/rest/category/clone`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

// Event Detail --> Category Detail : Dependent Category
export const getDependentCategoriesApi = async (id) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/dependentcategory/listing/${id}/list`,
    customRequestHeader({ token: Token })
  );
};

export const getDependentCategoriesEditListApi = async (categoryId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/dependentcategory/listing/${categoryId}/edit`,
    customRequestHeader({ token: Token })
  );
};

export const updateDependentCategoriesApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/category/dependent_category/create`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    data
  );
};

//Event Deatil--> Categories detail: Inventory
export const getAllInventoryListApi = async (categoryId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/inventory/${categoryId}`,
    customRequestHeader({ token: Token })
  );
};
export const getDeckNumberApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/deck_number?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getLocationApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/location?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getViewApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/view?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getBedApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/bed?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getExtraBedApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/extra_bed?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getSingleInventoryApi = async (Id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/category_inventory/category_inventory/${Id}?include=field_category,field_deck_number,field_location,field_extra_bed,field_view,field_bed,user_id,modified_by`,
    customRequestHeader({ token: Token })
  );
};
export const createInventoryApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/category_inventory/category_inventory",
    customRequestHeader({ token: Token }),
    payload
  );
};
export const updateInventoryApi = async (id, payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/category_inventory/category_inventory/${id}`,
    customRequestHeader({ token: Token }),
    payload
  );
};
export const deleteInventoryApi = async (deleteId) => {
  return await contactAxiosRequest(
    "delete",
    `/api/v1/category_inventory/category_inventory/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};
export const cloneInventoryApi = async (payload) => {
  return await contactAxiosRequest(
    "post",
    "/api/v1/inventory/clone",
    customRequestHeader({ defaultFormat: true, token: Token }),
    payload
  );
};

//Event Accommodation--> CategoryDetail -->Fees and Discount
export const CategoryFeeListingApi = async (categoryId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/detail/fees/${categoryId}`,
    customRequestHeader({ token: Token })
  );
};
export const CategoryDiscountListingApi = async (categoryId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/detail/discount/${categoryId}`,
    customRequestHeader({ token: Token })
  );
};
//FEES APIS

export const eventFeeListingApi = async (eventId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/fees/listing/${eventId}`,
    customRequestHeader({ token: Token })
  );
};

export const eventFeeCreateOrEditApi = async (data) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    data.reqMethod,
    `/api/v1/fees/fees/${data?.editId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};
export const createEventFeeApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/fees/fees",
    customRequestHeader({ token: Token }),
    payload
  );
};
export const updateEventFeeApi = async (id, payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/fees/fees/${id}`,
    customRequestHeader({ token: Token }),
    payload
  );
};
export const getSingleFeeDetailApi = async (feeId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/fees/fees/${feeId}?include=field_fee_type,name,field_payment_method,field_event`,
    customRequestHeader({ token: Token })
  );
};
export const deleteEventFeeApi = async (deleteId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/fees/fees/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

export const getFeeCategoriesListApi = async (eventId, feeId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/fees/${eventId}/${feeId}`,
    customRequestHeader({ token: Token })
  );
};
export const updateFeeCategoriesListApi = async (feeId, payload) => {
  return await contactAxiosRequest(
    "patch",
    `/api/v1/fees/fees/${feeId}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

//EVENT FEE TAXONOMY
export const getFeeTypeApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/fee_type?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getFeeNameApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/fee_name?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getFeeUsageTypeApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/usage_type?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getFeePaymentMethodApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/payment_method?sort=weight",
    customRequestHeader({ token: Token })
  );
};

//DISCOUNT APIS

export const eventDiscountListingApi = async (eventId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/discounts/listing/${eventId}`,
    customRequestHeader({ token: Token })
  );
};
export const getSingleDiscountlApi = async (discId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/discounts/discounts/${discId}?include=field_discount_type,name,field_military,field_gender,field_maximum_reward_status,field_minimum_reward_status`,
    customRequestHeader({ token: Token })
  );
};
export const createEventDiscountApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/discounts/discounts",
    customRequestHeader({ token: Token }),
    payload
  );
};
export const updateEventDiscountApi = async (id, payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/discounts/discounts/${id}`,
    customRequestHeader({ token: Token }),
    payload
  );
};
export const deleteEventDiscountApi = async (deleteId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/discounts/discounts/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

export const eventDiscountCreateOrEditApi = async (data) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    data.reqMethod,
    `/api/v1/discounts/discounts/${data?.editId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const getDiscountCategoriesListApi = async (eId, discId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/discount/${eId}/${discId}`,
    customRequestHeader({ token: Token })
  );
};
export const updateDiscountCategoriesListApi = async (discId, payload) => {
  return await contactAxiosRequest(
    "post",
    `/api/rest/category/discount/update/${discId}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

//EVENT DISCOUNT TAXONOMY
export const getDiscountTypeApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/discount_type?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getDiscountNameApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/discount_name?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getGenderApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/gender?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getDiscountMilitaryApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/military?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getDiscountMinRewardApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/minimum_reward_status?sort=weight",
    customRequestHeader({ token: Token })
  );
};
export const getDiscountMaxRewardApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/taxonomy_term/maximum_reward_status?sort=weight",
    customRequestHeader({ token: Token })
  );
};

export const getAccommodationList = async (uuid) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/accomodation/listing/${uuid}`,
    customRequestHeader({ token: Token })
  );
};
// Event Addon
export const getAddonListingApi = async (eventId) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/list/addons/${eventId}`,
    customRequestHeader({ token: Token })
  );
};

export const deleteAddonApi = async (deleteId) => {
  return await contactAxiosRequest(
    "delete",
    `/api/v1/event/event/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

export const getFeeDetailsApi = async (feeId) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/fees/fees/${feeId}?include=field_fee_type,name,field_payment_method,field_event`,
    customRequestHeader({ token: Token })
  );
};

export const getDiscountDetailsApi = async (discountId) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/discounts/discounts/${discountId}?include=field_discount_type,name,field_event`,
    customRequestHeader({ token: Token })
  );
};

export const getFeeCategoryListingApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/fees/${data?.eventId || data?.addonId}/${data?.feeId}`,
    customRequestHeader({ token: Token })
  );
};

export const getDiscountCategoryListingApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/category/discount/${data?.eventId || data?.addonId}/${
      data?.discountId
    }`,
    customRequestHeader({ token: Token })
  );
};

export const updateFeeCategoryListingApi = async (data) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/fees/fees/${data?.updateId}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const updateDiscountCategoryListingApi = async (data) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/discounts/discounts/${data?.updateId}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};
// Event Itineraries
export const getItineraryListApi = async (eID) => {
  return await contactAxiosRequest(
    "get",
    `/api/v1/list/event/itinerary/${eID}`,
    customRequestHeader({ token: Token })
  );
};
export const deleteItineraryApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    "/rest/api/delete-itineraries",
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};
export const getMarketingSource = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/marketing/sources/${id}`,
    customRequestHeader({ token: Token })
  );
};
//Addon Flight Listing
export const getFlightListingApi = async (eId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/flights/listing/${eId}`,
    customRequestHeader({ token: Token })
  );
};

export const getMarketingSourceList = async (payload, section) => {
  const query =
    section === "channel" ? "detail" : section === "source" ? "channel" : null;
  return await drupalAxiosRequest(
    "get",
    `/api/v1/${query}/${query}?filter[name][value]=${payload}&filter[name][operator]=CONTAINS`,
    customRequestHeader({ token: Token })
  );
};

export const createOrEditFlightApi = async (data) => {
  return await drupalAxiosRequest(
    data.reqMethod,
    `/api/v1/flights/flights/${data.flightId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const getFlightDetailsApi = async (flightId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/flights/flights/${flightId}`,
    customRequestHeader({ token: Token })
  );
};

export const editMarketingSource = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/marketing/sources`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const changeWeight = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/marketing/sources/weight`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const deleteAddonFlightApi = async (data) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/flights/flights/${data.flightId}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const getTourOperatorApi = async () => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/list/tour/operator",
    customRequestHeader({ token: Token })
  );
};

export const getEventTravelersListApi = async (data) => {
  const { eventUuid, urlParamsObject,type,addonId } = data;
  const { page, count, order, res_id, name } = urlParamsObject || {};
  let apiUrl  = type === "event" ? `/api/v1/list/traveler/${eventUuid}?${
    page ? `page=${Number(page) - 1}&` : ""
  }${count ? `items_per_page=${count}&` : ""}${
    order ? `sort_order=${order}&` : ""
  }${name ? `name=${name}&` : ""}${res_id ? `id=${res_id}&` : ""}
  `
  : 
  `/api/rest/addon/travelers/list/${addonId}?${
    page ? `page=${Number(page)}&` : ""
  }${count ? `items_per_page=${count}&` : ""}${
    order ? `sort_order=${order}&` : ""
  }${name ? `name=${name}&` : ""}${res_id ? `id=${res_id}&` : ""}
  `

  return await drupalAxiosRequest(
    "get",
    apiUrl,
    customRequestHeader({ token: Token })
  );
};

export const getReservedTravelerListApi = async (data) => {
  const { categoryId, page, order, count } = data || {};
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/category/reservation/${data.categoryId}?${
      page ? `page=${Number(page) - 1}&` : ""
    }${order ? `sort_order=${order}&` : ""}${
      count ? `items_per_page=${count}&` : ""
    }`
  );
};

export const getAssignDining = async (addonId, query) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/rest/res/dinning-motorcoach/invetory/list/${addonId}?${query}`,
    customRequestHeader({ token: Token })
  );
};

export const getInventoryReservationsListApi = async (data) => {
  const { inventoryId, page, count, order, res_status, fin_status } = data;
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/inventory/reservation/${inventoryId}?page=${page}&items_per_page=${count}&sort_order=${order}&${
      res_status ? `reservation_status=${res_status}&` : ""
    }${fin_status ? `financial_status=${fin_status}&` : ""}`,
    customRequestHeader({ token: Token })
  );
};

export const getUnassignedGuest = async (query) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/assign/dining-motorcoach/travelers/list?${query}`,
    customRequestHeader({ token: Token })
  );
};

export const postUnassignGuest = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/assign/dining-motorcoach/travelers`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getFlightsListApi = async (addonUuid) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/flights/listing/${addonUuid}`,
    customRequestHeader({ token: Token })
  );
};
export const getDescriptionListingApi = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/view/additional-document/list?event=${id}`,
    customRequestHeader({ token: Token })
  );
};

export const deleteDescriptionApi = async (id) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/rest/additional_documents/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getDescriptionTypeApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/taxonomy_term/event_documents_type?sort=weight`,
    customRequestHeader({ token: Token })
  );
};

export const addDescriptionApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/additional_documents`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const editDescriptionApi = async (payload) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/rest/additional_documents/${payload?.entityUUID}`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const eventSyncApi = async (eventId) => {
  return await drupalAxiosRequest(
    "post",
    `/rest/api/manual_sync`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    {eventUUID:eventId}
  );
};

export const addonReservationsCountApi = async (addonId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/addon/reservations/list/${addonId}`,
    customRequestHeader({ token: Token })
  );
};

export const getEventListForBulkInvoiceDropDown = async () => {
  return await drupalAxiosRequest(
    "get",
    '/api/v1/events_list',
    customRequestHeader({ token: Token })
  );
};
