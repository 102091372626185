import { Table, Divider, Tabs } from "antd";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function PassengersStaff({ allDetails, allDetailsLoading }) {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  if (!currentTab) {
    return <DataLoader />;
  }
  console.log(currentTab, "data", allDetails?.field_pricing_option);
  const { field_pricing_option } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Est Group Size For Space Request",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_group_size_req || ""
      }`,
    },
    {
      key: "2",
      label: "Est Group Size For Pricing",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_group_size_pricing || ""
      }`,
    },
    {
      key: "3",
      label: "Est Bus Count (Based On Group Size)",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_est_bus_count || ""
      }`,
    },
    {
      key: "4",
      label: "Est Staff Count",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_est_staff_count || ""
      }`,
    },
    {
      key: "5",
      label: "Pricing Based On",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_pricing_based_on || ""
      }`,
    },
    {
      key: "6",
      label: "1 for Program",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_one_for_program?.label ||
        ""
      }`,
    },
    {
      key: "7",
      label: "Event Partner Income",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_partner_income || ""
      }`,
    },

    {
      key: "8",
      label: "Purpose For Income",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_purpose_for_income || ""
      }`,
    },
    {
      key: "9",
      label: "Tip Budget",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_tip_budget || ""
      }`,
    },

    {
      key: "10",
      label: "Miscellaneous Items",
      input: `${
        field_pricing_option?.[currentTab - 1]?.field_misc_items || ""
      }`,
    },

    {
      key: "11",
      label: "Notes",
      input: `${field_pricing_option?.[currentTab - 1]?.field_notes || ""}`,
    },
  ];

  // const tabItems = allDetails?.field_pricing_option?.map((item, index) => {
  //   return {
  //     key: index + 1,
  //     label: `Pricing ${index + 1}`,
  //     children: (
  //       <Table
  //         loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
  //         className="basic_table"
  //         columns={columns}
  //         dataSource={data}
  //         showHeader={false}
  //         pagination={false}
  //         size="small"
  //       />
  //     ),
  //   };
  // });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Pricing Options</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}
export default PassengersStaff;
