import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";
import "./Res_guestprofile.less";

function onChange(time, timeString) {
  console.log(time, timeString);
}

function Reservation_arrivaldeparture() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Individual Flight Information </h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Flight Arrival" name="Flight Arrival">
              <Input />
            </Form.Item>
            <Form.Item label="Arrival Airline" name="Arrival Airline">
              <Select>
                <option value="bus">Bus</option>
                <option value="car">Car</option>
                <option value="Domestic Carriers Only">
                  Domestic Carriers Only
                </option>
                <option value="3L (InterSky)"> 3L (InterSky)</option>
                <option value="3M (Silver Airways)">3M (Silver Airways)</option>
                <option value="4D (Air Sanai)">4D (Air Sanai)</option>
                <option value="6H (Israir)">6H (Israir)</option>
                <option value="9U (Air Moldova)">9U (Air Moldova)</option>
              </Select>
            </Form.Item>

            <Form.Item label="Arrival Date" name="Arrival Date">
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Arrival Last </div>
                  <div>Depart City</div>
                </div>
              }
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Arrival Last </div>
                  <div>Depart Date</div>
                </div>
              }
            >
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Arrival Transfer </div>
                  <div>Date</div>
                </div>
              }
            >
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Arrival Record </div>
                  <div>Locator</div>
                </div>
              }
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Arrival Transfer </div>
                </div>
              }
            >
              <Select>
                <option value="No Transfer">No Transfers</option>
                <option value="Group Transfer">Group Transfer</option>
                <option value="Signature Service">Signature Service</option>
              </Select>
            </Form.Item>

            <div className="textarea_row">
              <Form.Item className="asterick-align" label="Air Notes" name="Air Notes">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Form.Item label="Flight Departure" name="Flight Departure">
              <Input />
            </Form.Item>

            <Form.Item label="Departure Airline" name="Departure Airline">
              <Select>
                <option value="bus">Bus</option>
                <option value="car">Car</option>
                <option value="Domestic Carriers Only">
                  Domestic Carriers Only
                </option>
                <option value="3L (InterSky)">3L (InterSky)</option>
                <option value="3M (Silver Airways)">3M (Silver Airways)</option>
                <option value="4D (Air Sanai)">4D (Air Sanai)</option>
              </Select>
            </Form.Item>

            <Form.Item label="Departure Date" name="Departure Date">
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>
            <Form.Item label="Arrival City" name="Arrival City">
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Departure </div>
                  <div>Transfer Date </div>
                </div>
              }
            >
              <DatePicker style={{ padding: "5px 10px" }} />
            </Form.Item>

            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Departure </div>
                  <div>Record Locator</div>
                </div>
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <div className="text_break_first">
                  <div>Departure </div>
                  <div>Transfer </div>
                </div>
              }
            >
              <Select>
                <option value="No Transfers">No Transfers</option>
                <option value="Group Transfer">Group Transfer</option>
                <option value="Signature Service">Signature Service</option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Reservation_arrivaldeparture;
