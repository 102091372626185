import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from 'antd';
import Sidebar from '../../components/Header/Header_sidebar';
// import Horizontals from '../../components/Header/Horizontal_menu';
import SearchDropdown_menu from '../../components/Admin/CustomDropdown/CustomDropdownSearchbar';
import Breadcrumb_adddropdown from '../../components/Breadcrumb/Breadcrumb_editdropdown/Breadcrumb_adddropdown';
import Adddropdown_table from '../../components/View_components/Editdropdown_table/Adddropdown_table';
import React from 'react';
const { Header, Content, Sider } = Layout;
const items1 = ['1', '2', '3'].map((key) => ({
    key,
    label: `nav ${key}`,
}));

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
        key: `sub${key}`,
        icon: React.createElement(icon),
        label: `subnav ${key}`,
        children: new Array(4).fill(null).map((_, j) => {
            const subKey = index * 4 + j + 1;
            return {
                key: subKey,
                label: `option${subKey}`,
            };
        }),
    };
});

function Custom_adddropdown() {
    const { useBreakpoint } = Grid;
    const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
    const mobileClass = lg || lg === undefined ? "" : "mobileview";
    return (
        <Layout className={mobileClass}>
            <Layout>

                <Layout>
                    <Header className="contact_edit main-header">
                        <SearchDropdown_menu />
                    </Header>
                    <Breadcrumb
                        className=""

                    >

                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage Custom Dropdown</Breadcrumb.Item>
                        <Breadcrumb.Item>Add Dropdown</Breadcrumb.Item>
                    </Breadcrumb>
                    <Breadcrumb_adddropdown />
                    <Content>
                        <Adddropdown_table />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default Custom_adddropdown;