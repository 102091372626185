import { Table, Divider, Select, Input, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import "./EventUnassignGuestTable.less";
import { getUnassignedGuest } from "../../../../../../../apis/eventsApi";
import { objectToQueryString } from "../../../../../../../others/util/convertObjectToQueryString";
import { SOMETHING_WENT_WRONG } from "../../../../../../../utils/constants";
import { useDebounce } from "../../../../../../../others/Debounce/debounce";
import { useSearchParams } from "react-router-dom";

function EventUnassignGuestTable({
  setAssignUnassignButton,
  refreshData,
  setRefreshData,
  setUnassignTravellers,
  setSelectedRowKeys,
  setRadioRowKeys,
  selectedRowKeys,
  setSelectedTraveler,
  isReservationHold,
  setReservationHoldInventory,
  buttonClicked,
  loading,
  setLoading
}) {
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const debouncedSearchTerm = useDebounce(query, 500);

  const eventId = searchParams.get("id");
  const addOnId = searchParams.get("addonId");

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("reservation");

  const onInputSearch = (column, val) => {
    setQuery({ ...query, [column]: val });
  };

  const handleChange = (value) => {
    setSelectedStatus(value);
    setExpandedRowKeys([]);
    setTableData([])
    const newQuery = { ...query, group_by: value };

      if (value === "reservation") {
        delete newQuery.r_id;
      }
      setQuery(newQuery);
  };

  useEffect(() => {
    if (page) {
      setQuery({ ...query, page: page });
    }
  }, [page]);

  useEffect(() => {
    if (pageSize) {
      setQuery({ ...query, items_per_page: pageSize });
    }
  }, [pageSize]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const acceptedRows = selectedRows
        .filter((val) => val && val.reservation_uuid)
        .map((val) => ({
          id: val?.traveler_uuid,
          reservation_uuid: val?.reservation_uuid,
          traveler_uuid: val?.traveler_uuid,
        }));
      if (acceptedRows.length) {
        setAssignUnassignButton({ left: true, right: false });
      } else {
        setAssignUnassignButton({ left: true, right: true });
      }
      setReservationHoldInventory({})
      if(isReservationHold){
        setRadioRowKeys([])
      }
      setUnassignTravellers(acceptedRows);
    },
    onSelect: (record, selected, selectedRows) => {},
  };

  const columns = [
    {
      title: (
        <p>
          Res Id <br />
          <Input onChange={(e) => onInputSearch("r_id", e.target.value)} />
        </p>
      ),
      dataIndex: "resid",
      key: "0",
    },
    {
      title: (
        <p>
          Name <br />
          <Input onChange={(e) => onInputSearch("t_name", e.target.value)} />
        </p>
      ),
      dataIndex: "name",
      key: "1",
    },
    {
      title: (
        <p>
          Category <br />
          <Input onChange={(e) => onInputSearch("category", e.target.value)} />
        </p>
      ),
      dataIndex: "category",
      key: "2",
    },
    {
      title: (
        <p>
          Gender <br />
          <Select
            style={{ width: 100 }}
            allowClear
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
            onChange={(e) => onInputSearch("gender", e)}
          />
        </p>
      ),
      dataIndex: "gender",
      key: "3",
    },
    {
      title: (
        <p>
          Age <br />
          <Input onChange={(e) => onInputSearch("age", e.target.value)} />
        </p>
      ),
      dataIndex: "age",
      key: "4",
    },
    {
      title: (
        <p>
          Staff <br />
          <Input
            onChange={(e) => onInputSearch("reporting_role", e.target.value)}
          />
        </p>
      ),
      dataIndex: "staff",
      key: "5",
    },
    {
      title: (
        <p>
          Primary Item <br />
          <Input
            onChange={(e) => onInputSearch("primary_item", e.target.value)}
          />
        </p>
      ),
      dataIndex: "primaryitem",
      key: "6",
    },
    {
      title: (
        <p>
          Request
          <br />
          <Input onChange={(e) => onInputSearch("request", e.target.value)} />
        </p>
      ),
      dataIndex: "request",
      key: "6",
    },
  ];

  const fetchAssignDining = async (query) => {
    setLoading(true);
    const response = await getUnassignedGuest(query);
    if (response && response.status) {
      const { rows } = response?.data?.data;
      setTotalDataCount(response?.data?.data?.pager?.total_items);
      if (rows?.length) {
        let table = [];
        rows.forEach((item, index) => {
          let childData = [];
          item?.values.forEach((child, i) => {
            childData.push({
              key: `${index}${i}${index}`,
              resid: child?.reservation,
              name: child?.contact_name,
              category: child?.category,
              gender: child?.gender,
              reservation_uuid: child?.reservation_uuid,
              traveler_uuid: child?.traveler_uuid,
              age: child?.age,
              staff: child?.reporting_role,
              primaryitem: child?.primary_item,
              request: child?.request,
            });
          });
          table.push({
            key: index,
            name: `${item?.name} ${item?.count ? `(${item?.count})` : ""}`,
            children: childData,
          });
        });
        setTableData(table);
      } else {
        setTableData([]);
      }
      setUnassignTravellers([]);
      setSelectedTraveler([]);
      setSelectedRowKeys([]);
      setAssignUnassignButton({ left: true, right: true });
      setRefreshData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    setQuery({
      add_on_id: addOnId,
      group_by: "reservation",
      items_per_page: "10",
    });
  }, []);

  useEffect(() => {
    if (query) {
      let url_query = objectToQueryString(query);
      fetchAssignDining(url_query);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (refreshData && query && !buttonClicked) {
      let url_query = objectToQueryString(query);
      fetchAssignDining(url_query);
    }
  }, [refreshData, buttonClicked]);

  useEffect(() => {
     setExpandedRowKeys([]);
    if (selectedStatus === "reservation") {
      const allKeys = tableData.map((item) => item.key);
      setExpandedRowKeys(allKeys);
    } else {
      setExpandedRowKeys([]);
    }
  }, [selectedStatus, tableData]);

  const filterColumns = (status) => {
    return columns.filter((column) => {
      if (
        (status === "reservation" && column.dataIndex === "resid") ||
        (status === "Category" && column.dataIndex === "category")
      ) {
        return false;
      }
      return true;
    });
  };

  return (
    <div>
      <div className="partnership_inform">
        <h4>Unassigned Guests</h4>
        <span>
          Group By:
          <Select
            style={{ width: "150px", marginLeft: "5px" }}
            defaultValue="reservation"
            onChange={handleChange}
            options={[
              {
                value: "reservation",
                label: "Reservation",
              },
              {
                value: "Category",
                label: "Category",
              },
              {
                value: "Group",
                label: "Group",
              },
            ]}
          />
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      {/* table */}

      <Table
        className="reservation_main-tablee icon_change_transfer assign-table"
        columns={filterColumns(selectedStatus)}
        defaultExpandAllRows
        loading={loading}
        rowSelection={{ ...rowSelection }}
        dataSource={tableData}
        pagination={{
          showSizeChanger:true,
          current: page,
          pageSize: pageSize,
          defaultPageSize:10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: totalDataCount,
          position: ["topRight"],
          onChange: (newPage, newPageSize) => {
            if (newPageSize !== pageSize) {
              setPage(1);
              setQuery({ ...query, page: 1 });
              setPageSize(newPageSize);
            } else {
              setPage(newPage);
            }
          },
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={(expanded, record) => {
          if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record.key]);
          } else {
            setExpandedRowKeys(
              expandedRowKeys.filter((key) => key !== record.key)
            );
          }
        }}
      />
    </div>
  );
}

export default EventUnassignGuestTable;
