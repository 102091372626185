import { Button, Skeleton } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import "./ContactsListHeader.less";
import { PlusOutlined, ImportOutlined } from "@ant-design/icons";
import ImportContact from "./ImportContact";
import {permissionChecker} from "../../../utils/permisssionChecker"

function ContactsListHeader({
  numberOfContact,
  isLoading,
  setIsContactUpdated,
  isContactUpdated,
  open,
  setOpen,
  setIsModalOpen,
  setErrorList,
  errorList,
}) {
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div className="breadcrumb_heading">
        <h3 className="flex_column">
          <span style={{ marginRight: "5px" }}>Contacts</span>
          <span className="small_text">
            {isLoading ? (
              <Skeleton.Button
                shape="round"
                size="small"
                style={{ width: "60px" }}
              />
            ) : (
              `(${numberOfContact || 0})`
            )}
          </span>
        </h3>
        <div
          className="breadcrum_buttons mobile_hidden"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          {permissionChecker("import contact") ? (
            <ImportContact
              open={open}
              setOpen={setOpen}
              setIsModalOpen={setIsModalOpen}
              setIsContactUpdated={setIsContactUpdated}
              isContactUpdated={isContactUpdated}
              setErrorList={setErrorList}
              errorList={errorList}
            />
          ) : null}

          {permissionChecker("add/edit contact") ? (
            <Link to="/contact">
              {" "}
              <Button type="primary">
                <PlusOutlined /> <span>Add Contact</span>
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="btradcrumbmob_button">
          <Button>
            <ImportOutlined /> <span> Import</span>
          </Button>
          <Link to="/contact">
            {" "}
            <Button type="primary">
              <PlusOutlined /> <span> Add</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default ContactsListHeader;
