import { Table, Divider, Button, message, Skeleton, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import DescriptionsListingDrawer from "../DescriptionsListingDrawer/DescriptionsListingDrawer";
import React, { useEffect, useState } from "react";
import {
  deleteDescriptionApi,
  editDescriptionApi,
  getDescriptionListingApi,
} from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const DescriptionsListing = ({ eventId }) => {
  const [descriptionList, setDescriptionList] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [searchParamas, setSearchParams] = useSearchParams();
  const [editDescription, setEditDescription] = useState();
  const paramId = searchParamas.get("id");

  const deleteDescription = async (uuid) => {
    setLoading(true);
    const response = await deleteDescriptionApi(uuid);
    if (response && response.status) {
      message.success("Description Deleted Successfully");
      getDescriptionListing();
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const editDescriptionFn = async (data, data2) => {
    setLoading(true);
    const payload = {
      entityUUID: editDescription?.uuid,
      documentTypeID: data2[0]?.value,
      description: {
        value: data?.field_info_text,
        text_format: "basic_html",
      },
    };

    const response = await editDescriptionApi(payload);

    if (response && response.status) {
      message.success("Description Updated Successfully");
      getDescriptionListing();
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
    setOpen(false);
  };

  const editClick = (click, record) => {
    if (click === "delete") {
      Modal.confirm({
        title: "Are you sure you want to delete this description?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteDescription(record?.uuid);
        },
      });
    } else {
      setEditDescription(record);
    }
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
    },

    {
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex action_end">
             {permissionChecker("add/edit event descriptions") ?  <EditOutlined
                className="action_edit"
                onClick={() => editClick("edit", record)}
              /> : null}

              {permissionChecker("delete event descriptions") ? <DeleteOutlined
                className="action_delete"
                onClick={() => editClick("delete", record)}
              /> : null}
            </div>
          </>
        );
      },
    },
  ];

  const getDescriptionListing = async () => {
    setLoading(true);
    const response = await getDescriptionListingApi(eventId);
    if (response && response.status) {
      const data = [];
      response?.data?.rows?.forEach((element) => {
        data.push({
          type: element?.documentType?.title,
          uuid: element?.UUID,
          documentId: element?.documentType?.uuid,
          value: element?.documentDiscription,
        });
      });
      setDescriptionList(data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (eventId) {
      getDescriptionListing();
    }
  }, [eventId]);

  return (
    <div>
      <div className="requests_title">
        <h4 className="heading_four">
          Descriptions{" "}
          {loading ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${descriptionList?.length || 0})`
          )}
        </h4>
        <div
          style={{
            position: "relative",
          }}
        >
          <DescriptionsListingDrawer
            eventId={eventId}
            editDescription={editDescription}
            editDescriptionFn={editDescriptionFn}
            open={open}
            setOpen={setOpen}
            setEditDescription={setEditDescription}
            getDescriptionListing={getDescriptionListing}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
      <Divider className="global_divider" />
      <Table
        className="description-table"
        columns={columns}
        dataSource={descriptionList}
        size="small"
        pagination={false}
      />
    </div>
  );
};
export default DescriptionsListing;
