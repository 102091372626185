export const EventRoute = `/event`;
export const EventDetailsRoute = `/event/details`;
export const EventsListingRoute = `/events/listing`;
export const EventCategoryDetailsRoute = "/event/details/category-details";
export const CategoryAvailabilityRoute =
  "/event/details/category-details/availability";
export const AddonCategoryAvailabilityRoute =
  "/event/details/add-ons/details/category-details/availability";
export const EventReservationsRoute = "/event/details/reservations";
export const EventCategoryDetailsPricingRoute =
  "/event/details/category-details/pricing";
export const EventCategoryDetailsInventoryRoute =
  "/event/details/category-details/add-inventory";
export const EventCategoryDetailsInventoryDetailRoute =
  "/event/details/category-details/inventory";
export const EventCategoryDetailsDiscountRoute =
  "/event/details/category-details/discount";
export const EventCategoryDetailsFeesRoute =
  "/event/details/category-details/fees";
export const EventCategoryDetailsDependentRoute =
  "/event/details/category-details/dependent";
export const AccomodationTypeDetailsRoute =
  "/event/details/accommodation-type-details";
export const EventFeesRoute = "/event/details/fees";
export const EventFeeDetailsRoute = "/events/details/fee-details";
export const EventFeeDetailsCategoriesRoute =
  "/events/details/fee-details/category";
export const EventDiscountRoute = "/events/details/discount";
export const EventDiscountDetailsRoute = "/events/details/discount-details";
export const EventDiscountDetailsCategoriesRoute =
  "/events/details/discount-details/category";
export const EventDetailsAddonsRoute = "/event/details/add-ons";
export const EventDetailsAddonsDetailsRoute = "/event/details/add-ons/details";
export const EventDetailAssignDining = "/event/details/assign-dining";
export const AddonCategoryDetailsRoute =
  "/event/details/add-ons/details/category-details";
export const AddonCategoryDetailsDependentRoute = `/event/details/add-ons/details/category-details/dependent`;
export const AddonCategoryDetailsInventoryRoute =
  "/event/details/add-ons/details/category-details/add-inventory";
export const AddonCategoryDetailsPricingRoute =
  "/event/details/add-ons/details/category-details/pricing";
export const AddonAccomodationTypeDetailsRoute =
  "/event/details/add-ons/details/accommodation-type-details";
export const AddonFeeRoute = "/event/details/add-ons/details/fee";
export const AddonFeeDetailsRoute = "/event/details/add-ons/details/fee-detail";
export const AddonFeeDetailsCategoryRoute =
  "/event/details/add-ons/details/fee-detail/category";
export const AddonCategoryDetailsInventoryDetailRoute =
  "/event/details/add-ons/details/category-details/inventory";
export const AddonDiscountRoute = "/event/details/add-ons/discount";
export const AddonDiscountDetailsRoute =
  "/event/details/add-ons/discount-detail";
export const AddonDiscountDetailsCategoryRoute =
  "/event/details/add-ons/discount-detail/category";
export const AddonDetailsItineraryRoute = "/events/details/add-ons/itinerary";
export const AddonDetailsFlightRoute = "/events/details/add-ons/flight";
export const EventDetailsItineraryRoute = "/events/details/itineraries";
export const GroupDetailsListingRoute = "/group-details/listing";
export const GroupDetails_DetailsRoute = "/group-details/details";
export const GroupDetailsCruise = "/group-details/cruise";
export const GroupDetailsTour = "/group-details/tour";
export const EventInventoryReservationsListRoute =
  "/event/details/category-details/reservations";
export const AddonInventoryReservationsListRoute =
  "/event/details/add-ons/details/category-details/reservations";
export const AddonReservationsListingRoute =
  "/event/details/add-ons/details/reservations";
export const AddonTravelersListingRoute =
  "/event/details/add-ons/details/travelers";
// reservations routes
export const ReservationDetailRoute = `/reservation/details`;
export const ReservationSendEmailRoute = `/reservation/details/send_email`;
export const ReservationListingRoute = `/reservations`;
export const ReservationRoute = `/reservation`;
export const ReservationEditRoute = `/reservation`;
export const ReservationPlannedPaymentRoute = `/reservation/details/planned-payment`;
export const ReservationPlannedPaymentDetailRoute = `/reservation/details/planned-payment/details`;
export const ReservationSchedulePaymentRoute = `/reservation/details/schedule-payment`;
export const ReservationItemDetailRoute = `/reservation/details/reservation-item-details`;
export const ReplaceItemRoute = `/reservation/details/reservation-item-details/replace-item`;
export const ReservationPaymentRoute = `/reservation/details/payment`;
export const ReservationPaymentDetailRoute = `/reservation/details/payment/details`;
export const CancelReservationRoute = `/reservation/details/cancel-reservation`;
export const ConfirmPaymentStatusRoute = `/payment-confirmation`;
export const ReviseRoute = `/revise-page`;
export const CancelReservationItemRoute = `/reservation/details/reservation-item-details/cancel-item`;

//contact route
export const ContactDetailRoute = `/contact/details`;
export const DuplicateContactRoute = `/contact/details/duplicate_contact`;

//admin route

export const AdminEmailTemplatesRoute = `/email_templates`;
export const AdminEmailTemplateEditRoute = `/email_template`;
export const AdminEmailMonitorRoute = `/email_monitor`;

export const ViewReportRoute = `/reports/view_report`;
export const ReportListingRoute = `/reports`;
export const CreateReport = `/create-report`;
export const InsurancePayment = `/reservation/details/reservation-item-details/insurance/payment`;
