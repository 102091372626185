import { Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  financialStatusList,
  getPrimaryTravelerName,
  statusList,
} from "../../../../../../../others/util/commonFunctions";
import { Link, useSearchParams } from "react-router-dom";
import {
  ContactDetailRoute,
  ReservationDetailRoute,
} from "../../../../../../../url-routes/urlRoutes";
import { addCommasInPricing } from "../../../../../../../utils/contentParser";
import moment from "moment";

function InventoryReservations({
  reservationList,
  count,
  urlParamsObject,
  setUrlParamsObject,
  dataLoading,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const res_status = searchParams.get("res_status");
  const fin_status = searchParams.get("fin_status");
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(
      reservationList?.map((reservation) => ({
        hash: (
          <Link target={"_blank"} to={`${ReservationDetailRoute}?id=${reservation?.uuid}`}>
            {" "}
            {reservation?.id || null}
          </Link>
        ),
        name: (
          <Link to={`${ReservationDetailRoute}?id=${reservation?.uuid}`}>
            {reservation?.name || null}
          </Link>
        ),
        primaryguest: reservation?.has_contact ? (
          reservation?.primary_traveler ? (
            <Link
              to={`${ContactDetailRoute}?id=${reservation?.primary_traveler?.uuid}`}
            >
              {getPrimaryTravelerName(reservation?.primary_traveler)}
            </Link>
          ) : null
        ) : null,
        res_status: reservation?.reservation_status || null,
        financial_status: reservation?.financial_status || null,
        total: reservation?.total
          ? `$${addCommasInPricing(reservation?.total?.toString())}`
          : "$0",
        balance: reservation?.balance
          ? `$${addCommasInPricing(reservation?.balance?.toString())}`
          : "$0",
        created: (
          <span>
            {reservation?.created_on || ""}{" "}
            <span className="span_text">
              {reservation?.created
                ? moment.unix(reservation?.created).fromNow()
                : null}
            </span>
          </span>
        ),
        modified: (
          <span className="span_text">
            {reservation?.changed
              ? moment.unix(reservation?.changed).fromNow()
              : null}
          </span>
        ),
      }))
    );
  }, [reservationList]);

  const columns = [
    {
      title: "Reservation ID",
      dataIndex: "hash",
    },
    // { title: "Name", dataIndex: "name" },
    {
      title: (
        <>
          <div style={{ paddingBottom: "8px" }}>Reservation Status</div>
          {data.length || fin_status || res_status ? (
            <Select
              allowClear
              placeholder="Search..."
              style={{
                width: 200,
              }}
              value={urlParamsObject?.res_status || null}
              onSelect={(e) => {
                setUrlParamsObject({ ...urlParamsObject, res_status: e });
              }}
              onClear={() => {
                let newUrlParams = { ...urlParamsObject };
                delete newUrlParams.res_status;
                setUrlParamsObject(newUrlParams);
              }}
              options={statusList.map((status) => ({
                key: status.value,
                value: status.value,
                label: status.label,
              }))}
            />
          ) : null}
        </>
      ),
      dataIndex: "res_status",
    },
    {
      title: (
        <>
          <div style={{ paddingBottom: "8px" }}>Financial Status</div>
          {data?.length || fin_status || res_status ? (
            <Select
              allowClear
              placeholder="Search..."
              style={{
                width: 200,
              }}
              value={urlParamsObject?.fin_status || null}
              onSelect={(e) => {
                setUrlParamsObject({ ...urlParamsObject, fin_status: e });
              }}
              onClear={() => {
                let newUrlParams = { ...urlParamsObject };
                delete newUrlParams.fin_status;
                setUrlParamsObject(newUrlParams);
              }}
              options={financialStatusList.map((status) => ({
                key: status.value,
                value: status.value,
                label: status.label,
              }))}
            />
          ) : null}
        </>
      ),
      dataIndex: "financial_status",
    },
    {
      title: "Primary Traveler",
      dataIndex: "primaryguest",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Created By",
      dataIndex: "created",
    },
    { title: "Modified By", dataIndex: "changed" },
  ];
  return (
    <>
      <div></div>
      <Table
        // className="event_cruise_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: dataLoading, size: "large" }}
        pagination={{
          showSizeChanger: true,
          current: Number(urlParamsObject?.page),
          pageSize: Number(urlParamsObject?.count),
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: Number(count),
          position: ["topRight", "bottomRight"],
          onChange: (page, pageSize) => {
            setUrlParamsObject({
              ...urlParamsObject,
              page,
              count: pageSize,
            });
          },
        }}
      ></Table>
    </>
  );
}
export default InventoryReservations;
