import { Divider, Image, Switch, Table } from "antd";
import React from "react";
import EditMedicalAndDietaryInfo from "./EditMedicalAndDietaryInfo";
import DataLoader from "../../../../others/Loaders/DataLoader";
import drupalConfig from "../../../../config/serverless/drupal.config";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const MedicalAndDietaryDetails = ({
  medicalDetailsData,
  contactId,
  isLoading,
}) => {
  const {
    field_travel_insurance_company,
    field_policy_number,
    field_alcohol,
    field_smoking,
    field_medical_notes,
    field_additional_notes,
    field_vaccination,
    field_vaccination_certificate,
    field_dietary_need,
    field_medical_history,
  } = medicalDetailsData || {};

  const medicalDataSource = [
    {
      key: "1",
      label: "Travel Insurance Company",
      value: field_travel_insurance_company || "",
    },
    {
      key: "2",
      label: "Policy Number",
      value: field_policy_number || "",
    },

    {
      key: "3",
      label: "Vaccination",
      value: !!field_vaccination?.length
        ? field_vaccination.map((vaccine) => vaccine.name).join(", ")
        : "",
    },

    {
      key: "4",
      label: "Vaccination Certificate",
      value: !!field_vaccination_certificate?.length ? (
        <>
          {field_vaccination_certificate.map((vaccinationCertificate) => (
            <Image
              src={`${drupalConfig.BASE_URL}${vaccinationCertificate?.uri?.url}`}
              height={50}
              width={100}
            />
          ))}
        </>
      ) : (
        []
      ),
    },
    {
      key: "5",
      label: "Dietary Need",
      value: !!field_dietary_need?.length
        ? field_dietary_need?.map((dietaryNeed) => dietaryNeed.name).join(", ")
        : "",
    },
    {
      key: "6",
      label: "Alcohol",
      value: field_alcohol !== null && (
        <Switch checked={field_alcohol} disabled />
      ),
    },
    {
      key: "7",
      label: "Smoking",
      value: field_smoking !== null && (
        <Switch checked={field_smoking} disabled />
      ),
    },
    // {
    //   key: "8",
    //   label: "Medical History",
    //   value: !!field_medical_history?.length
    //     ? field_medical_history
    //         ?.map((medicalHistory) => medicalHistory.name)
    //         .join(", ")
    //     : "",
    // },
    {
      key: "9",
      label: "Medical Notes",
      value: field_medical_notes || "",
    },
    {
      key: "10",
      label: "Additional Notes",
      value: field_additional_notes || "",
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Medical & Dietary Information</h4>
        <EditMedicalAndDietaryInfo
          medicalDetailsData={medicalDetailsData}
          contactId={contactId}
        />
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={medicalDataSource}
        showHeader={false}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default MedicalAndDietaryDetails;
