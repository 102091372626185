import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
// const weekFormat = "MM/DD";
// const customFormat = (value) => `custom format: ${value.format(DATE_FORMAT)}`;

// const DATE_FORMAT = 'YYYY/MM/DD';

function onChange(time, timeString) {
  console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function CruisePostform_one() {
  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Dates & Itinerary</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 5,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
      >
        <Form.Item label="Extension Destination" name="extension_destination">
          <Select placeholder="Extension Destination">
            <option value="1">Alaska Explorer</option>
            <option value="2">Southeast US</option>
            <option value="3">Alaska Glacier</option>
            <option value="4">Alaska Inside Passage</option>
            <option value="5">Bahamas</option>
            <option value="6">Mediterranean </option>
            <option value="7">Baltic</option>
            <option value="8">Cincinnati</option>
            <option value="9">Bermuda</option>
            <option value="10">British Isles</option>
            <option value="11">Canada</option>
            <option value="12">Canada/New England (Boston)</option>
            <option value="13">Canada/New England (Montreal)</option>
            <option value="14">Canada/New England (New York)</option>
            <option value="15">Caribbean</option>
            <option value="16">Conferences</option>
            <option value="18">Cuba</option>
            <option value="19">Eastern Caribbean</option>
            <option value="20">Eastern Mediterranean</option>
            <option value="21">Eastern US</option>
            <option value="22">Egypt</option>
            <option value="23">Europe</option>
            <option value="24">FIT</option>
            <option value="25">Footsteps of Moses</option>
            <option value="57">Footsteps of Paul</option>
            <option value="26">France</option>
            <option value="27">Greek Isles</option>
            <option value="28">Hawaii</option>
            <option value="29">Israel</option>
            <option value="30">Italy</option>
            <option value="31">Jamaica</option>
            <option value="32">Jordan</option>
            <option value="33">Mexican Riviera</option>
            <option value="34">Mexico</option>
            <option value="35">Mississippi River</option>
            <option value="36">Nashville TN</option>
            <option value="37">Pacific Northwest</option>
            <option value="38">Panama Canal</option>
            <option value="39">Petra</option>
            <option value="40">Puerto Rico</option>
            <option value="41">Radio Rally</option>
            <option value="42">Reformation Britain</option>
            <option value="43">Reformation Europe</option>
            <option value="44">Reformation Oberammergau</option>
            <option value="45">Rome</option>
            <option value="46">Russia</option>
            <option value="47">South Africa</option>
            <option value="48">Southern Caribbean</option>
            <option value="49">Tahiti</option>
            <option value="50">Tennessee</option>
            <option value="51">Tropical Caribbean</option>
            <option value="52">Turkey</option>
            <option value="53">Vienna</option>
            <option value="54">Western Caribbean</option>
            <option value="55">Western Mediterranean</option>
            <option value="56">Fresno</option>
          </Select>
        </Form.Item>

        <Form.Item label="Itinerary" name="Tour Itinerary">
          <Select>
            <Select.Option value="1">Itinerary 1</Select.Option>
            <Select.Option value="3">Itinerary 2</Select.Option>
            <Select.Option value="4">Itinerary 3</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Requested Dates">
          <RangePicker
            style={{
              fontFamily: "'Poppins', sans-serif",
              maxWidth: "100%",
              height: "32px",
            }}
            format={"MM/DD/YYYY"}
          />
        </Form.Item>

        <Form.Item label="Are Dates Flexible?" name="Are Dates Flexible?">
          <Radio.Group onChange={onChange}>
            <Radio value={1}>Yes</Radio>
            <Radio value={2}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Length of Extension">
          <Form.Item name="Length of Extension" noStyle>
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Form.Item>

        {/* <Form.Item label="Est Group Size">
                    <Form.Item name="Est Group Size" noStyle>
                        <InputNumber style={{

                            width: "100%"
                        }} />
                    </Form.Item>

                </Form.Item>
                <Form.Item label="Pricing Based On">
                    <Form.Item name="Pricing Based On" noStyle>
                        <InputNumber style={{

                            width: "100%"
                        }} />
                    </Form.Item>

                </Form.Item>


                <Form.Item label="1 for Program" name="1 for Program">
                    <Select >
                        <Select.Option value="10">
                            10
                        </Select.Option>
                        <Select.Option value="15">
                            15
                        </Select.Option>


                    </Select>
                </Form.Item>
                <Form.Item label="Ministry Income" name="Event Partner Income">
                    <Input />
                </Form.Item>
                <Form.Item label="Tip Budget" name="Tip Budget">
                    <Input />
                </Form.Item> */}
      </Form>
    </div>
  );
}

export default CruisePostform_one;
