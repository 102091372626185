import { Button, Col, Grid, message, Row, Steps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  contactAllDetails,
  contactStepSetter,
  pressNext,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";
import AddressForm from "./AddressForm";
import CitizenshipForm from "./CitizenshipForm";
import ContactDetailsForm from "./ContactDetailsForm";
import "./CreateContactSteps.less";
import EmergencyForm from "./EmergencyForm";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const { useBreakpoint } = Grid;

const CreateContactSteps = ({
  editId,
  createId,
  primaryContactOrgId,
  copyId,
  form1,
  form2,
  form3,
  form4,
  orgId,
  orgName,
  orgType,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const singleContactDataRef = useRef(false);
  const [secondAddress, setSecondAddress] = useState(false);
  const [contactFormFields, setContactFormFields] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [formStep, setFormStep] = useState(1);
  const [stepLimit, setStepLimit] = useState("1");
  const { contactDetails, contactCreation } = useSelector(
    (state) => state.contacts
  );
  const { loading, contactStep } = contactCreation;
  const { contactAllDetailsResponse, contactDetailsLoading } = contactDetails;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const orientationStep = lg || lg === undefined ? "vertical" : "horizontal";
  const currentStep = searchParams.get("step");
  const reservationId = searchParams.get("redirectBackTo");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(() => {
    setFormStep(Number(currentStep) || 1);
  }, [location.search]);

  useEffect(() => {
    if (singleContactDataRef.current) {
      if (contactAllDetailsResponse.status) {
        setContactFormFields(contactAllDetailsResponse.data.data);
        // setStepLimit(contactAllDetailsResponse.data.field_step || "1");
      } else {
        message.error(
          contactAllDetailsResponse?.data?.message ||
            contactAllDetailsResponse?.data?.response?.data?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    singleContactDataRef.current = true;
  }, [contactAllDetailsResponse]);

  const getContactIdForContactDetail = (
    editId,
    createId,
    copyId,
    currentStep
  ) => {
    if (copyId && currentStep == "2") {
      return copyId;
    } else {
      return editId || createId;
    }
  };
  useEffect(() => {
    if (editId || createId) {
      const reqHeaders = customRequestHeader({ token: Token });
      const contactId = getContactIdForContactDetail(
        editId,
        createId,
        copyId,
        currentStep
      );
      dispatch(contactAllDetails({ reqHeaders, contactId }));
    } else {
      setContactFormFields({});
      dispatch(contactStepSetter(0)); //to handle situation, if admin accidently click on add contact while in edit or create contact state
    }
  }, [currentStep]);

  const next = (idInfo) => {
    const { primaryContactOrgId, id, orgId, orgName, orgType, type } = idInfo;
    setFormStep((prev) => Number(prev + 1));
    if (type === "creation") {
      let query = !!primaryContactOrgId
        ? {
            primaryContactOrgId,
            creationId: id,
            step: formStep + 1,
          }
        : !!orgId
        ? { orgId, creationId: id, step: formStep + 1 }
        : copyId && currentStep === "1"
        ? { copyId, creationId: id, step: formStep + 1 }
        :    reservationId ? {
          creationId: id, step: formStep + 1 ,
          "redirectBackTo":reservationId
        } :   { creationId: id, step: formStep + 1 };
      setSearchParams(query);
    } else {
      if (type === "edit") {
        setSearchParams({ editId: id, step: formStep + 1 });
      } else {
        setSearchParams({ step: formStep + 1 });
      }
    }
  };

  const prev = () => {
    setFormStep((prev) => Number(prev - 1));
    if (editId) {
      setSearchParams({
        editId: editId,
        step: formStep - 1,
      });
    } else {
      if (createId) {
        let query = primaryContactOrgId
          ? {
              primaryContactOrgId,
              creationId: createId,
              step: formStep - 1,
            }
          : !!orgId
          ? {
              orgId,
              creationId: createId,
              step: formStep - 1,
            }
          : copyId && currentStep === "2"
          ? { copyId, creationId: createId, step: formStep - 1 }
          :
          reservationId ? {
            creationId: createId,
            step: formStep - 1,
            "redirectBackTo":reservationId
          } :  {
              creationId: createId,
              step: formStep - 1,
            };
        setSearchParams(query);
      } else {
        setSearchParams({ step: formStep - 1 });
      }
    }
  };

  const onStepChangeHandler = (stepValue) => {
    if (Number(stepValue + 1) > contactStep + 1) {
      return;
    }
    if (editId) {
      setFormStep(Number(stepValue + 1));
      setSearchParams({ editId: editId, step: stepValue + 1 });
    } else {
      if (createId) {
        let query = primaryContactOrgId
          ? {
              primaryContactOrgId,
              creationId: createId || editId,
              step: stepValue + 1,
            }
          : !!orgId
          ? {
              orgId,
              creationId: createId || editId,
              step: stepValue + 1,
            }
          : copyId && ["1", "2"].includes(currentStep)
          ? { copyId, creationId: createId || editId, step: stepValue + 1 }
          : reservationId ? {
            creationId: createId || editId,
            step: stepValue + 1,
            "redirectBackTo":reservationId
          } :  {
              creationId: createId || editId,
              step: stepValue + 1,
            };

        setSearchParams(query);
      } else {
        setSearchParams({ step: stepValue + 1 });
      }
    }
  };

  // if (contactDetailsLoading) {
  //   return <DataLoader />;
  // }

  const steps = [
    {
      title: "Personal Information",
      content: (
        <ContactDetailsForm
          contactFormFields={contactFormFields}
          form1={form1}
          next={next}
          editId={editId}
          copyId={copyId}
          primaryContactOrgId={primaryContactOrgId}
          createId={createId}
          currentStep={currentStep}
          orgId={orgId}
        />
      ),
    },
    {
      title: "Address Details",
      content: (
        <AddressForm
          contactFormFields={contactFormFields}
          secondAddress={secondAddress}
          setSecondAddress={setSecondAddress}
          form2={form2}
          next={next}
          prev={prev}
          editId={editId}
          copyId={copyId}
          createId={createId}
          currentStep={currentStep}
          primaryContactOrgId={primaryContactOrgId}
          orgId={orgId}
        />
      ),
      // status: contactStep < 2 ? "wait" : "finished",
    },
    {
      title: "Citizenship Information",
      content: (
        <CitizenshipForm
          contactFormFields={contactFormFields}
          form3={form3}
          next={next}
          prev={prev}
          editId={editId}
          createId={createId}
          copyId={copyId}
          currentStep={currentStep}
          primaryContactOrgId={primaryContactOrgId}
          orgId={orgId}
        />
      ),
      // status: contactStep < 3 ? "wait" : "finished",
    },
    {
      title: "Emergency Contact",
      content: (
        <EmergencyForm
          contactFormFields={contactFormFields}
          form4={form4}
          next={next}
          prev={prev}
          editId={editId}
          createId={createId}
          copyId={copyId}
          currentStep={currentStep}
          primaryContactOrgId={primaryContactOrgId}
          orgId={orgId}
        />
      ),
      // status: contactStep < 4 ? "wait" : "finished",
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={6}>
          <Steps
            direction={orientationStep}
            current={formStep - 1}
            onChange={(e) => onStepChangeHandler(e)}
            items={steps}
          />
        </Col>

        {contactDetailsLoading ? (
          <Col xs={24} md={24} lg={14}>
            <DataLoader />
          </Col>
        ) : (
          <Col xs={24} md={24} lg={14}>
            <div className="steps-content">{steps?.[formStep - 1].content}</div>
            <div className="steps-action form-button">
              {formStep === 1 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => {
                    dispatch(updateStep());
                    dispatch(pressNext("next-button"));
                    form1.submit();
                  }}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === 2 && (
                <div>
                  {!secondAddress ? (
                    <Button
                      type=""
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setSecondAddress(true);
                      }}
                    >
                      <i className="fa-solid fa-circle-plus"></i>{" "}
                      <span style={{ paddingLeft: "10px" }}>Add Address 2</span>
                    </Button>
                  ) : null}
                  {secondAddress ? (
                    <Button
                      type=""
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setSecondAddress(false);
                      }}
                      danger
                    >
                      <i className="fa-solid fa-circle-minus"></i>{" "}
                      <span style={{ paddingLeft: "10px" }}>
                        Remove Address 2
                      </span>
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      dispatch(updateStep());
                      dispatch(pressNext("next-button"));
                      form2.submit();
                    }}
                    loading={loading}
                  >
                    <span>Next</span>
                  </Button>
                </div>
              )}
              {formStep === 3 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(updateStep());
                    dispatch(pressNext("next-button"));
                    form3.submit();
                  }}
                  loading={loading}
                >
                  <span>Next</span>
                </Button>
              )}
              {formStep === steps.length && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(updateStep());
                    dispatch(pressNext("next-button"));
                    form4.submit();
                  }}
                  loading={loading}
                >
                  <span>Done</span>
                </Button>
              )}
              {formStep > 1 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
export default CreateContactSteps;
