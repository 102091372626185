import { Table, Button, Checkbox } from "antd";
import { Dropdown, Menu, Space } from "antd";
import { Divider, Modal, message } from "antd";
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import {
  EventDetailsAddonsRoute,
  EventRoute,
} from "../../../../../url-routes/urlRoutes";
import moment from "moment";
import "./AddonDetailSections.less";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const AddonGeneralInfo = ({ addonData, eventId, addonId, isLoading }) => {
  const navigate = useNavigate();

  const data = [
    {
      key: "1",
      label: "Type",
      input: addonData?.field_add_ons_type?.label || "",
    },
    {
      key: "2",
      label: "Add-On Name",
      input: addonData?.name,
    },
    {
      key: "3",
      label: "Add-On Code",
      input: addonData?.field_event_code,
    },
    {
      key: "4",
      label: "Add-On Partner",
      input: addonData?.field_event_partner?.length
        ? addonData?.field_event_partner?.map((eventPartner, index) => (
            <Link to={`/organization/details?id=${eventPartner?.id}`}>
              {`${eventPartner?.title} (${eventPartner?.drupal_internal__nid})${
                index < addonData?.field_event_partner?.length - 1 ? ", " : ""
              }`}
            </Link>
          ))
        : "",
    },

    {
      key: "5",
      label: "Suppliers",
      input: addonData?.field_event_supplier?.length
        ? addonData?.field_event_supplier?.map((eventPartner, index) => (
            <Link to={`/organization/details?id=${eventPartner?.id}`}>
              {`${eventPartner?.title} (${eventPartner?.drupal_internal__nid})${
                index < addonData?.field_event_supplier?.length - 1 ? ", " : ""
              }`}
            </Link>
          ))
        : "",
    },
    {
      key: "6",
      label: "Start Date",
      input: addonData?.field_start_date ? (
        <>
          <span>
            {moment(addonData?.field_start_date).format("MM/DD/YYYY")}
          </span>{" "}
          <span className="span_text">
            {moment(addonData?.field_start_date, "YYYY-MM-DD").fromNow()}
          </span>
        </>
      ) : null,
    },
    {
      key: "7",
      label: "End Date",
      input: addonData?.field_end_date ? (
        <>
          <span>{moment(addonData?.field_end_date).format("MM/DD/YYYY")}</span>{" "}
          <span className="span_text">
            {moment(addonData?.field_end_date, "YYYY-MM-DD").fromNow()}
          </span>
        </>
      ) : null,
    },
    {
      key: "8",
      label: "Destination",
      input: addonData?.field_destination?.name,
    },
    {
      key: "8",
      label: "Destination Country",
      input:  addonData?.field_destination_country || "",
    },
    {
      key: "9",
      label: "Ship",
      input: addonData?.field_ship?.name,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">
          General Information
        </h4>
        <div className="buttons_flex">
         {permissionChecker("add/edit addon") ?  <Button
            onClick={() =>
              navigate(
                `${EventDetailsAddonsRoute}?id=${eventId}&editId=${addonId}&step=1`
              )
            }
            icon={<EditOutlined />}
          /> : null}
        </div>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        showHeader={false}
        size="small"
      />
    </div>
  );
};
export default AddonGeneralInfo;
