import React, { useState } from "react";
import { DatePicker, Divider, Form, Input, Select, Spin } from "antd";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import paymentMethod from "../../../../../utils/paymentMethod.json"
import paymentList from "../../../../../utils/paymentListType.json"


function PlannedPaymentForm({ form, onFinishHandler, loadingPaymentData }) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [paymentTypeList, setPaymentTypeList] = useState(paymentList.list);
  const reservationStatus = new URLSearchParams(window.location.search)?.get("status");
  const [paymentTypeLoading, setPaymentTypeLoading] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState(paymentMethod.list);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const status = [
    { key: "design", value: "design", label: "Design" },
    { key: "complete", value: "complete", label: "Complete" },
    { key: "error", value: "error", label: "Error" },
    { key: "canceled", value: "canceled", label: "Canceled" },
    { key: "pending", value: "pending", label: "Pending" },
  ];
  useState(() => {
    // getTaxonomyList("payment_type", setPaymentTypeList, setPaymentTypeLoading);
    // getTaxonomyList(
    //   "payment_method",
    //   setPaymentMethodList,
    //   setPaymentMethodLoading
    // );
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Planned Payment Details</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingPaymentData} size="large">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
          initialValues={{field_planned_payment_status: ["active","revise","cancel_pending","complete","cancelled"].includes(reservationStatus) ? 'pending' :"design"}}
        >
          <Form.Item
            label="Payment Type"
            name="field_paymenttype"
            className="asterick-align"
            rules={[
              {
                required: true,
                message: "Please Select Payment Type",
              },
            ]}
          >
            <Select
              options={paymentTypeList.map((paymentType,index) => ({
                key: index,
                value: paymentType.value,
                label: paymentType.name,
              }))}
              loading={paymentTypeLoading}
            />
          </Form.Item>
          <Form.Item label="Payment Name" className="asterick-align" name="field_payname"
            rules={[
              {
                required: true,
                message: 'Please Input Payment Name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Payment Amount"
            className="asterick-align"
            name="field_planamount"
            rules={[
              {
                required: true,
                message: "Please Input Payment Amount",
              },
            ]}
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                console.log("shdhss", evt.key);
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Payment Method"
            className="asterick-align"
            name="field_paymethod"
            rules={[
              {
                required: true,
                message: "Please Select Payment Method",
              },
            ]}
          >
            <Select
              options={paymentMethodList.map((paymentMethod,index) => ({
                key: index,
                value: paymentMethod.value,
                label: paymentMethod.name,
              }))}
              loading={paymentMethodLoading}
            />
          </Form.Item>

          <Form.Item
            label="Payment Date"
            className="asterick-align"
            name="field_paydate"
            rules={[
              {
                required: true,
                message: "Please Select a Date",
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
          <Form.Item label="Payment Status" className="asterick-align" name="field_planned_payment_status">
            <Select
              options={status.map((statusItem) => ({
                key: statusItem.key,
                value: statusItem.value,
                label: statusItem.label,
              }))}
              disabled={true}
              showArrow={false}
            />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default PlannedPaymentForm;
