import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ViewPaymentPageHeader = () => {
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddRole = authData?.permissions?.includes("add/edit role");

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          <span style={{ fontFamily: "'Poppins', sans-serif" }}>
            Payment Details 07/26/2023
            <span className="header_smalltext" style={{ marginTop: "-4px", marginLeft: "8px" }}>(IMC Gospel Music Celebration 2023 Hawaii Cruise - R980105)</span>
          </span>
        </h3>
      </div>
    </div>
  );
};
export default ViewPaymentPageHeader;
