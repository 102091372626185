import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AddonCategoryDetailsInventoryDetailRoute, AddonCategoryDetailsRoute, EventCategoryDetailsInventoryDetailRoute } from "../../../../url-routes/urlRoutes";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function Reservationcategory_information({ data, eventDetail,completeData }) {
  const [catData, setCatData] = useState();
  useEffect(() => {
    if (data) {
      setCatData([
        {
          key: "1",
          label: "Category Name",
          input: (
            <>
              <Link
               to={ completeData?.reservation_item_category?.category_event?.is_primary_item ? `${EventCategoryDetailsInventoryDetailRoute}?inventoryId=${completeData?.reservation_item_inventory?.uuid}&id=${completeData?.event_datail?.uuid}&categoryId=${completeData?.reservation_item_category?.uuid}`
               :`${AddonCategoryDetailsInventoryDetailRoute}?inventoryId=${completeData?.reservation_item_inventory?.uuid}&id=${completeData?.event_datail?.uuid}&addonId=${completeData?.reservation_item_category?.category_event?.uuid}&categoryId=${completeData?.reservation_item_category?.uuid}`}
              >
                {data?.name}
              </Link>
            </>
          ),
        },
        {
          key: "2",
          label: "Category Code",
          input: (
            <>
              <Link
                to={`${AddonCategoryDetailsRoute}?id=${eventDetail?.uuid}&categoryId=${data?.uuid}&addonId=${data?.category_event?.uuid}`}
              >
                {data?.code}
              </Link>
            </>
          ),
        },
      ]);
    }
  }, [data]);
  return (
    <div>
      <div className="partnership_inform">
        <h4>Category Information</h4>
      </div>
      <Divider className="global_divider" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={catData}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Reservationcategory_information;
