import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Grid, Layout } from "antd";
import Reservationsemail_confirmationheader from "../../../components/Breadcrumb/Reservations_header/Reservationsemail_confirmationheader";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import Resersystem_information from "../../../components/Reservations_components/Reservationsemail_confirmationfolder/Resersystem_information";
import Task_activity from "../../../components/Reservations_components/Reservationsemail_confirmationfolder/Task_activity";
import Task_details from "../../../components/Reservations_components/Reservationsemail_confirmationfolder/Task_details";
import Task_documents from "../../../components/Reservations_components/Reservationsemail_confirmationfolder/Task_documents";

import React from "react";

const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Reservationsemailconfirmation_page() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reservations</Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
            <Breadcrumb.Item>Activity</Breadcrumb.Item>
          </Breadcrumb>

          <div className="events-header">
            <Reservationsemail_confirmationheader />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Task_details />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Task_activity />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Task_documents />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Resersystem_information />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Reservationsemailconfirmation_page;
