import { Col, DatePicker, Divider, Form, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import moment from "moment";

function CancellationDetails({
  form,
  cancelDetails,
  onFinishHandler,
  loadingCancelDetails,
}) {
  const { check_list, confirmation_recieved_by, confirmation_recieved_date } =
    cancelDetails?.cancellation_details || {};

  const [confirmRecievedList, setConfirmRecievedList] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [loadingConfirmReceived, setLoadingConfirmRecieved] = useState(false);
  const [loadingChecklist, setLoadingChecklist] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "reservation_cancellation_checkli",
      setChecklist,
      setLoadingChecklist
    );
    getTaxonomyList(
      "reservation_cancellation_confirm",
      setConfirmRecievedList,
      setLoadingConfirmRecieved
    );
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      check_list: check_list?.length
        ? check_list?.map((listItem) => listItem?.uuid)
        : [],
      confirmation_recieved_by: confirmation_recieved_by?.uuid || null,
      confirmation_recieved_date: confirmation_recieved_date
        ? moment(confirmation_recieved_date, "YYYY-MM-DD")
        : null,
    });
  }, [cancelDetails]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="m-0">Cancellation Details</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Spin spinning={loadingCancelDetails} size="large">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinishHandler}
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Confirmation Received By"
                name="confirmation_recieved_by"
              >
                <Select
                  allowClear
                  options={confirmRecievedList.map((listItem) => ({
                    key: listItem.id,
                    value: listItem.id,
                    label: listItem.name,
                  }))}
                  loading={loadingConfirmReceived}
                />
              </Form.Item>
              <Form.Item
                label="Confirmation Received Date"
                name="confirmation_recieved_date"
              >
                <DatePicker format={"MM/DD/YYYY"} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="textarea_row">
                <Form.Item
                  className="asterick-align"
                  label="Check List"
                  name="check_list"
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    options={checklist.map((listItem) => ({
                      key: listItem.id,
                      value: listItem.id,
                      label: listItem.name,
                    }))}
                    loading={loadingChecklist}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default CancellationDetails;
