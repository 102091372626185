import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Grid, Table, Popconfirm } from "antd";
import { Collapse, TimePicker } from "antd";
import ReactQuill from "react-quill";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Divider,
} from "antd";
import moment from "moment";

import { CKEditor } from "ckeditor4-react";

function EditActivityMessageForm() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Message Content</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 23,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={24} lg={24}>
            <div className="textarea_row">
              <Form.Item id="editor" className="ckeitor-field-wrapper">
                <CKEditor
                // editorUrl="https://cdn.ckeditor.com/4.20.1/full/ckeditor.js"
                // config={{
                //   toolbar: [
                //     { name: "document", items: ["Source"] },
                //     {
                //       name: "styles",
                //       items: [
                //         "Styles",
                //         "-",
                //         "Format",
                //         "-",
                //         "Font",
                //         "-",
                //         "FontSize",
                //       ],
                //     },
                //     { name: "colors", items: ["TextColor", "BGColor"] },
                //     { name: "editing", items: ["Scayt"] },
                //     "/",
                //     {
                //       name: "basicstyles",
                //       items: ["Bold", "Italic", "Underline"],
                //     },
                //     {
                //       name: "clipboard",
                //       items: [
                //         "Undo",
                //         "Redo",
                //         "-",
                //         "Cut",
                //         "Copy",
                //         "Paste",
                //         "Find",
                //         "Replace",
                //       ],
                //     },

                //     {
                //       name: "paragraph",
                //       items: [
                //         "Outdent",
                //         "Indent",
                //         "-",
                //         "NumberedList",
                //         "BulletedList",
                //         "-",
                //         "JustifyLeft",
                //         "JustifyCenter",
                //         "JustifyRight",
                //         "JustifyBlock",
                //       ],
                //     },
                //     {
                //       name: "insert",
                //       items: ["Image", "Table", "-", "Link", "Unlink"],
                //     },
                //   ],
                // }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditActivityMessageForm;
