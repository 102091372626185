import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { message, Table, Modal } from "antd";
import CreateUserModal from "../CreateUserModal";
import PermissionModal from "../PermissionModal";
import {
  searchUser,
  userDeletedOption,
} from "../../../redux/features/usersFeatures/usersSlice";
import { useDispatch } from "react-redux";
import { deleteUserApi, searchUserApi } from "../../../apis/usersApi";
import DataLoader from "../../../others/Loaders/DataLoader";
import { DRUPAL_INTERNAL_ROLES_ID } from "../../../utils/constants";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Username",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
  },
  {
    title: (
      <>
        <div className="action_start">Action</div>
      </>
    ),
    dataIndex: "action",
    key: "action",
  },
];

const ManageusersTable = ({
  users,
  loadingAllUsers,
  page,
  setPage,
  pageSize,
  setPageSize,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [editindex, setEditIndex] = useState(null);
  const [totalCount, setTotalCount] = useState();
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddUser = authData?.permissions?.includes("add/edit user");
  const dispatch = useDispatch();
  const searchInputValue = useSelector((state) => state.users.searchInput);
  const [load, setLoad] = useState(false);
  async function searchTerm(value, limit, offset) {
    setLoad(true);
    const response = await searchUserApi(value, limit, offset);
    dispatch(searchUser(response.data));
    setLoad(false);
  }
  const showModal = (index, id) => {
    setOpenModal(true);
    setEditIndex(index);
  };

  useEffect(() => {
    setPage(1);
  }, [searchInputValue]);

  useEffect(() => {
    if (page) {
      let offset = (page - 1) * pageSize;
      searchTerm(searchInputValue, pageSize, offset);
    }
  }, [page, pageSize]);

  const handleOk = () => {
    setOpenModal(false);
    setEditIndex(null);
  };
  const handleCancel = () => {
    setOpenModal(false);
    setEditIndex();
  };

  useEffect(() => {
    if (users) {
      setTotalCount(users?.meta?.count);
    }
  }, [users]);

  // async function handleDelete(id) {
  //   const data = {
  //     type: "user--user",
  //     id,
  //   };
  //   let response = await deleteUserApi(data, id);
  //   if (response.status) {
  //     dispatch(userDeletedOption({ id: id }));
  //     message.success("User Deleted Successfully");
  //   } else {
  //     message.error("Something went wrong");
  //   }
  // }

  // const deleteHandler = (id) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete?",
  //     okText: "Yes",
  //     okType: "danger",
  //     onOk: () => {
  //       return handleDelete(id);
  //     },
  //   });
  // };
  // const DRUPAL_INTERNAL_ROLES_ID = [
  //   "51a88203-94c1-4aa7-aeab-e21dc70a55cf",
  //   "df32f8b4-2f4e-4529-be07-6aa9543b745b",
  //   "4cc6b764-9c37-46c2-a291-f2523bd7a832",
  // ];

  const data = users?.data?.map((item, index) => {
    let role = [];
    if (item?.roles?.length) {
      item.roles.forEach((element) => {
        // role = element.meta.drupal_internal__target_id + ',' + role;
        !DRUPAL_INTERNAL_ROLES_ID.includes(element?.id) &&
          role.push(element?.label);
      });
      role = role?.join(", ");
    }
    return {
      key: item.id,
      first_name: item.field_first_name,
      last_name: item.field_last_name,
      name: (
        <>
          <p>{item.display_name}</p>
        </>
      ),
      roles: (
        <>
          <p className="span_text">{role}</p>
        </>
      ),
      action: (
        <>
          <div className="action_flex action_end">
            {allowAddUser ? (
              <EditOutlined
                onClick={() => showModal(index, item.id)}
                className="action_edit"
              />
            ) : null}

            {index === editindex && (
              <CreateUserModal
                key={index}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setEditIndex={setEditIndex}
                modalData={item}
                editUser={true}
              />
            )}
            {/* <DeleteOutlined
              className="action_delete"
              // onClick={() => handleDelete(item.drupal_internal__uid)}
              onClick={() => {
                deleteHandler(item.id);
              }}
            /> */}
          </div>
        </>
      ),
      exp_row: false,
    };
  });

  return (
    <>
      <PermissionModal
        isModalOpen={openModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Table
        loading={{
          spinning: loadingAllUsers || load,
          indicator: <DataLoader />,
        }}
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: page,
          position: ["bottomRight"],
          total: Number(totalCount),
          onChange: (pageNo, itemPerPage) => {
            setPage(pageNo);
            setPageSize(itemPerPage);
          },
        }}
      />
    </>
  );
};

export default ManageusersTable;
