import {
  Checkbox,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Tabs,
  message,
  Modal,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Reservationprimary_table from "./ReservationPrimary_table";
import {
  getAllTravelerDetails,
  getItemCategories,
  getItemEvent,
  getItemInventory,
} from "../../../apis/reservationApi";
import AddOnTab from "./AddOnTab";
import moment from "moment";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const Reservationitem_tabs = ({ setNameAndId, isReplaceItem }) => {
  const [eventData, setEventData] = useState();
  const [page, setPage] = useState("event");
  const [tab, setTab] = useState("primary");
  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState();
  const [travelers, setAllTravelers] = useState([]);
  const [travelerFlag, setTravelerFlag] = useState(false);
  const [selectedTravelers, setSelectedTravelers] = useState([]);
  const [selectedAddOnTravelers, setSelectedAddOnTravelers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accommodation, setAccommodation] = useState();
  const [category, setCategory] = useState();
  const [deck, setDeck] = useState();
  const [location, setLocation] = useState();
  const [subAccommodation, setSubAccommodation] = useState();
  const [query, setQuery] = useState({});
  const [resItemUuid, setResItemUuid] = useState();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({ status: false });
  const modalRef = useRef();
  const [tabKey, settabKey] = useState("1");
  const travellersId =
    JSON.parse(localStorage.getItem("travellersId"))?.travelersId || [];

  // const query = Object.fromEntries([...searchParams])
  let paramId = searchParams.get("id");
  let eventId = searchParams.get("event_id");
  let categoryId = searchParams.get("category_id");
  let step = searchParams.get("step");
  let replaceItemSelectedTraveler = searchParams.get("selected-travelers");
  let resItemUuidUrl = searchParams.get("res-item-uuid");
  let isAddOn = searchParams.get("is-addon");
  let replaceItem = searchParams.get("replace-item") || "";

  const getTravelersData = async (id) => {
    const response = await getAllTravelerDetails(id);
    if (response && response.status) {
      const filteredData = response?.data?.data.map((e) => {
        return { name: e.name, id: e.id, contact: e.contact };
      });
      const filteredId = response?.data?.data.map((e) => e.id);

      if (replaceItemSelectedTraveler) {
        setSelectedTravelers(replaceItemSelectedTraveler?.split(","));
      } else {
        const unUsedTravellers = filteredId.filter(
          (val) => val && !travellersId.includes(val)
        );
        if (!unUsedTravellers.length) {
          setTab("addon");
          navigate(`/add_item?id=${paramId}`);
          settabKey("2");
          setSelectedAddOnTravelers(filteredId);
        } else {
          setSelectedTravelers(unUsedTravellers);
        }
      }
      setAllTravelers(filteredData);
      setTravelerFlag(true);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (isAddOn) {
      setTab("addon");
    } else if (tab === "primary") {
      if (step === "category") {
        if (selectedTravelers.length === 1) {
          const queryString = objectToQueryString(query);
          getCategories(queryString);
        } else {
          delete query.share;
          const queryString = objectToQueryString(query);
          getCategories(queryString);
        }
        setLoading(true);
      } else if (step === "inventory") {
        if (selectedTravelers.length === 1) {
          const queryString = objectToQueryString(query);
          getInventories(queryString);
        } else {
          delete query.share;
          const queryString = objectToQueryString(query);
          getInventories(queryString);
        }

        setLoading(true);
      } else {
        getEvent(paramId);
        setLoading(true);
      }
      if (resItemUuidUrl) {
        setResItemUuid(resItemUuidUrl);
      }
    }
  }, [searchParams, selectedTravelers]);

  const getEvent = async (id) => {
    const response = await getItemEvent(id);
    if (response && response.status) {
      const { select_event, name, drupal_internal__id } = response?.data?.data;
      setPage("event");
      setNameAndId({ name, drupal_internal__id, id });
      if (select_event?.data === null) {
      } else {
        let tableVal = [
          {
            key: 1,
            hash: (
              <>
                <a href="#">{select_event?.drupal_internal__id}</a>
              </>
            ),
            type: select_event?.field_type,
            name: select_event?.name,
            start_date: moment(select_event?.field_start_date).format("L"),
            end_date: moment(select_event?.field_end_date).format("L"),
            uuid: select_event?.id,
          },
        ];
        setEventData(tableVal);
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const getCategories = async (query) => {
    setLoading(true);

    let combinedQuery;
    if (isReplaceItem) {
      let replaceQuery = {
        replace: true,
        res_item_uuid: resItemUuid,
      };
      combinedQuery = query
        ? `${query}&${objectToQueryString(replaceQuery)}`
        : objectToQueryString(replaceQuery);
    }
    const response = await getItemCategories(
      eventId,
      selectedTravelers.length,
      combinedQuery || query
    );
    if (response && response.status) {
      const { data } = response;
      setPage("category");
      let tableVal = [];
      setAccommodation(data?.accomodation);
      setDeck(data?.deck);
      setLocation(data?.location);
      setSubAccommodation(data?.sub_accomodation);
      if (data && data.category) {
        data?.category?.forEach((element, index) => {
          let value = {
            key: index,
            code: element?.category_code,
            description: element?.category_description,
            name: element?.category_name,
            price: element?.price,
            fees: element?.fees,
            discount: element?.discount,
            selling_price: element?.total,
            uuid: element?.category_uuid,
          };
          tableVal.push(value);
        });
      }

      setEventData(tableVal);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const getInventories = async (query) => {
    const response = await getItemInventory(
      categoryId,
      selectedTravelers.length,
      query
    );
    if (response && response.status) {
      const { data } = response;
      setPage("inventory");
      let tableVal = [];
      setAccommodation(data?.accomodation);
      setCategory(data?.category);
      setDeck(data?.deck);
      setLocation(data?.location);
      setSubAccommodation(data?.sub_accomodation);
      if (data && data.inventory && data.inventory.length) {
        data?.inventory?.forEach((element, index) => {
          let value = {
            key: index,
            code: element?.inventory_id,
            status: element?.inventory_status,
            name: element?.inventory_name,
            price: element?.price,
            fees: element?.fees,
            discount: element?.discount,
            selling_price: element?.total,
            uuid: element?.inventory_uuid,
            field_machinename: element?.field_machinename,
            isOnHold: element?.hold_or_avail === "hold" || false,
          };
          tableVal.push(value);
        });
      }

      setEventData(tableVal);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchParams.size < 2) {
      setSearchParams({ id: paramId, step: page });
    }
    getTravelersData(paramId);
  }, []);

  const onChange = (e, index) => {
    setSelectedTravelers(e);
  };
  function objectToQueryString(obj) {
    const queryString = Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");

    return queryString;
  }

  const addQuery = (val, keys) => {
    let existingKeys = Object.keys(query);

    if (existingKeys.indexOf(keys) === -1) {
      let obj = val && val !== undefined ? { [keys]: val || "" } : null;
      let queryObj = { ...query, ...obj };
      const queryString = objectToQueryString(queryObj);
      if (page === "inventory") {
        getInventories(queryString);
      } else {
        getCategories(queryString);
      }
      setQuery(queryObj);
    } else {
      let obj = query;

      if (val) {
        obj[keys] = val;
      } else {
        delete obj[keys];
      }
      const queryString = objectToQueryString(obj);
      if (page === "inventory") {
        getInventories(queryString);
      } else {
        getCategories(queryString);
      }
      setQuery(obj);
    }
  };

  const getName = (firstName, middleName, lastName, name) => {
    if (firstName && middleName && lastName) {
      return `${firstName} ${middleName} ${lastName}`;
    } else if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return name;
    }
  };

  const changeTab = (tab) => {
    if (tab === "primary") {
      setTab("primary");
      navigate(`/add_item?id=${paramId}&step=event`);
    } else {
      setTab("addon");
      navigate(`/add_item?id=${paramId}`);
    }
  };

  const handleOk = () => {
    modalRef.current.click();
    setIsModalOpen({ status: false });
  };

  const handleCancel = () => {
    setIsModalOpen({ status: false });
  };

  const PrimaryTab = ()=>{
    return(
      (
        <Tabs.TabPane tab="Primary" key="primary">
          <div
            className="activity_details pd-16 bg-white travelers--form"
            style={{ marginBottom: "20px" }}
          >
            <div className="primary_tabs_wrapper">
              <div className="travelers-checkbox--list">
                {travelerFlag && (
                  <div>
                    <div className="textarea_row">
                      {
                        <Form.Item
                          label="Travelers"
                          name="travelers"
                          valuePropName="checked"
                        >
                          <Checkbox.Group
                            style={{ width: "100%" }}
                            onChange={onChange}
                            defaultValue={selectedTravelers}
                          >
                            {travelers.map((val, index) => (
                              <Checkbox value={val?.id}>
                                {" "}
                                {getName(
                                  val.contact.field_first_name,
                                  val?.contact?.field_middle_name,
                                  val?.contact?.field_last_name,
                                  val?.name
                                )}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                      }
                    </div>
                  </div>
                )}
              </div>
              {step === "category" || step === "inventory" ? (
                <>
                  <div className="traveler_form_second">
                    <div>
                      {step === "category" ? (
                        <>
                          {" "}
                          <Row>
                            {" "}
                            <p className="travelers--form_label">
                              Accommodation:{" "}
                            </p>{" "}
                            <Select
                              placeholder="Select Accommodation"
                              allowClear={true}
                              showSearch
                              showArrow
                              filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                  0 ||
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(val) => addQuery(val, "category_group")}
                              options={accommodation?.map((task) => ({
                                key: task?.id,
                                value: task?.id,
                                label: task?.name,
                              }))}
                            />
                          </Row>
                          <Row>
                            {" "}
                            <p className="travelers--form_label">
                              Sub Accommodation:
                            </p>{" "}
                            <Select
                              placeholder="Select Sub Accommodation"
                              allowClear={true}
                              onChange={(val) => addQuery(val, "category_subgroup")}
                              showSearch
                              showArrow
                              filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                  0 ||
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              options={subAccommodation?.map((task) => ({
                                key: task?.id,
                                value: task?.id,
                                label: task?.name,
                              }))}
                            />
                          </Row>
                        </>
                      ) : null}
                      <Row>
                        {" "}
                        <p className="travelers--form_label">Location: </p>{" "}
                        <Select
                          placeholder="Select Location"
                          allowClear={true}
                          showSearch
                          showArrow
                          filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                              0 ||
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(val) => addQuery(val, "location")}
                          options={location?.map((task) => ({
                            key: task?.id,
                            value: task?.id,
                            label: task?.name,
                          }))}
                        />
                      </Row>
                      <Row>
                        {" "}
                        <p className="travelers--form_label">Location 2: </p>{" "}
                        <Input
                          placeholder="Enter Location"
                          onChange={(val) =>
                            addQuery(val.target.value, "location2")
                          }
                        />
                      </Row>
                      <Row>
                        {" "}
                        <p className="travelers--form_label">Deck: </p>{" "}
                        <Select
                          placeholder="Select Deck"
                          allowClear={true}
                          showSearch
                          showArrow
                          filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                              0 ||
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(val) => addQuery(val, "deck")}
                          options={deck?.map((task) => ({
                            key: task?.id,
                            value: task?.id,
                            label: task?.name,
                          }))}
                        />
                      </Row>
                      <Row>
                        {" "}
                        <p className="travelers--form_label">Type: </p>{" "}
                        <Input
                          placeholder="Enter Type"
                          onChange={(val) => addQuery(val.target.value, "type")}
                        />
                      </Row>
                      <Row>
                        {" "}
                        <p className="travelers--form_label">
                          Accessible only:{" "}
                        </p>{" "}
                        <Switch onChange={(val) => addQuery(val, "accessible")} />
                      </Row>
                      <Row>
                        {" "}
                        {(step === "inventory" || step === "category") &&
                        selectedTravelers.length === 1 ? (
                          <>
                            {" "}
                            <p className="travelers--form_label">Share: </p>{" "}
                            <Switch
                              onChange={(val) => {
                                addQuery(val ? 1 : 0, "share");
                              }}
                            />
                          </>
                        ) : null}
                      </Row>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div
            className="activity_details pd-16 bg-white"
            style={{ marginBottom: "20px" }}
          >
            <Reservationprimary_table
              eventData={eventData}
              page={page}
              setUuid={setUuid}
              setSearchParams={setSearchParams}
              paramId={paramId}
              searchParams={searchParams}
              loading={loading}
              travelers={travelers}
              selectedTravelers={selectedTravelers}
              setIsModalOpen={setIsModalOpen}
              modalRef={modalRef}
              isModalOpen={isModalOpen}
            />
          </div>
        </Tabs.TabPane>
      )
    )
  }
  const AddonTabPane = ()=>{
    {console.log("working")}
    return (
    <Tabs.TabPane tab="Add-Ons" key="addon">
      <AddOnTab
        travelerFlag={travelerFlag}
        getName={getName}
        selectedTravelers={
          selectedAddOnTravelers.length
            ? selectedAddOnTravelers
            : selectedTravelers
        }
        travelers={travelers}
        paramId={paramId}
        setSelectedTravelers={setSelectedAddOnTravelers}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </Tabs.TabPane>
  )}
  return (
    <>
      <Tabs
        activeKey={tab}
        className="details-tabs"
        onChange={(e) => changeTab(e)}
      >
        {replaceItem.includes("true") && !isAddOn ? (
          <>
          {PrimaryTab()}
          </>
        ) : replaceItem.includes("true") && isAddOn === '1' ? (
          <>
          {AddonTabPane()}
          </>
        ) : (
          <>
            {PrimaryTab()}
            {AddonTabPane()}
          </>
        )}
      </Tabs>
      <Modal
        title="Confirmation"
        open={isModalOpen?.status}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          This item is under <strong>{isModalOpen?.data?.status}</strong>. Are
          you sure you want to add this item?
        </p>
      </Modal>
    </>
  );
};

export default Reservationitem_tabs;
