import React from "react";
import "./Horizontal_menu.less";
import { useEffect, useState } from "react";
import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  FilterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
  Button,
  Input,
  Form,
  Select,
} from "antd";
import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Sidebar from "./Header_sidebar";
import Pro_icon from "../../assests/images/pro-icon.png";
import { interpolateRgbBasis } from "d3";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
const { Search } = Input;
const { useBreakpoint } = Grid;

const onSearch = (value) => console.log(value);

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#000", fontSize: "14px", lineHeight: "28px" }}
              >
                {category}
              </a>
            </span>
          </div>
        ),
      };
    });

function Horizontals() {
  const [searchText, setSearchText] = useState("");
  const [activeClass, setActiveClass] = useState(false);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link to="/change-password">Change Password</Link>,
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.antgroup.com"
            >
              Sign Out
            </a>
          ),
        },
      ]}
    />
  );
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const custumSearch = (
    <div className="custom_search">
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        <Form.Item
          label="Email"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
            placeholder="Enter Email Address"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Phone"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
            placeholder="Enter Phone Number"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Organization Type"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            placeholder="Select Organization Type"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          >
            <Select.Option value="min">Ministry</Select.Option>
            <Select.Option value="air">Airline</Select.Option>
            <Select.Option value="airline"> Airline Consolidator</Select.Option>
            <Select.Option value="cruise">Cruise Line</Select.Option>
            <Select.Option value="internal">Internal</Select.Option>
            <Select.Option value="tour">Tour Operator</Select.Option>
            <Select.Option value="horizontal">Headliner/Artist</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Organization"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            placeholder="Select Organization"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          >
            <Select.Option value="american">American Airlines</Select.Option>
            <Select.Option value="holand">Holland America</Select.Option>
            <Select.Option value="cruise">Norwegian Cruise Line</Select.Option>
            <Select.Option value="cruise">
              Royal Caribbean Cruise Line
            </Select.Option>
            <Select.Option value="marama">Marama Tours</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Traveler Status"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Select
            placeholder="Select Traveler Status"
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          >
            <Select.Option value="elite">Elite</Select.Option>
            <Select.Option value="silver">Silver</Select.Option>
            <Select.Option value="sapphire">Sapphire</Select.Option>
            <Select.Option value="platinum">Platinum</Select.Option>
            <Select.Option value="diamond">Diamond</Select.Option>
          </Select>
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button type="text" style={{ border: "none", opacity: "0.85" }}>
            Clear Filter
          </Button>
          <Button type="primary">Search</Button>
        </div>
      </Form>
    </div>
  );

  // const options = [
  //   { value: 'light', label: 'Light' },
  //   { value: 'bamboo', label: 'Bamboo' },
  // ];

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>

            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              // allowClear={{clearIcon: <CloseOutlined />}}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search Contacts"
                enterButton
              />
            </AutoComplete>

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              trigger={["click"]}
              //  onOpenChange={(e) => setActiveClass(e)}
              className="advance-search-dropdown"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{ opacity: "0.5", color: "#1A7f93" }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>
          {/* */}
          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default Horizontals;
