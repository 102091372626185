import { Row, Col, Form, Input, Select, Button, Divider, message } from "antd";
import React, { useState } from "react";
import {
  HOLD_STATUS,
  SOMETHING_WENT_WRONG,
} from "../../../../../../../utils/constants";
import { updateInventoryApi } from "../../../../../../../apis/eventsApi";
import { decodeContent } from "../../../../../../../utils/contentParser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { inventoryUpdated } from "../../../../../../../redux/features/eventFeatures/eventsSlice";
import {
  AddonInventoryReservationsListRoute,
  EventInventoryReservationsListRoute,
} from "../../../../../../../url-routes/urlRoutes";

function InventoryStatusHoldForm({
  editInventory,
  setLoadInventoryAgain,
  handleCancel,
  handleOk,
  releasingHold,
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const addonId = searchParams.get("addonId");
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const { TextArea } = Input;

  const updateHoldStatusInInventory = async (payload) => {
    setLoading(true);
    const response = await updateInventoryApi(editInventory?.uuid, payload);
    if (response.status) {
      message.success("Inventory Updated Successfully");
      form.resetFields();
      handleOk();
      dispatch(inventoryUpdated());
      setLoadInventoryAgain(response?.data);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const setHoldFinishHandler = (values) => {
    const payload = {
      data: {
        type: "category_inventory--category_inventory",
        id: editInventory?.uuid,
        attributes: {},
      },
    };
    if (releasingHold && releasingHold !== "availability") {
      payload.data.attributes[values?.new_status] =
        Number(editInventory?.[values?.new_status]) -
          Number(values?.quantity ?? 0) ?? 0;
    } else {
      payload.data.attributes[values?.new_status] =
        Number(editInventory?.[values?.new_status] ?? 0) +
        Number(values?.quantity ?? 0);
      payload.data.attributes["field_inventory_comment"] =
        values?.field_inventory_comment?.trim();
    }
    updateHoldStatusInInventory(payload);
  };
  return (
    <div className="App">
      <Row>
        <Col xs={24} md={24} lg={24}>
          {releasingHold && releasingHold === "field_reservation_hold_count" ? (
            <div>
              <div className="button_primary">
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      addonId
                        ? `${AddonInventoryReservationsListRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}&inventoryId=${editInventory?.uuid}`
                        : `${EventInventoryReservationsListRoute}?id=${eventId}&categoryId=${categoryId}&inventoryId=${editInventory?.uuid}`
                    )
                  }
                  loading={loading}
                >
                  View Reservation
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="set_hold">
                <p className="set_heading staffhold_anchor">
                  {" "}
                  {releasingHold && releasingHold !== "availability"
                    ? "Release Hold"
                    : "Set Hold"}{" "}
                </p>

                {/* <DeleteOutlined
                        className="staffhold_delete"
                        onClick={close}
                      /> */}
              </div>
              <div className="inventory_form">
                <Form
                  labelCol={{
                    sm: 24,
                    md: 7,
                    lg: 7,
                    xl: 7,
                    xxl: 7,
                  }}
                  wrapperCol={{
                    sm: 24,
                    md: 18,
                    lg: 18,
                    xl: 18,
                    xxl: 18,
                  }}
                  layout="horizontal"
                  form={form}
                  onFinish={setHoldFinishHandler}
                  initialValues={{
                    field_inventory_comment: decodeContent(
                      editInventory?.field_inventory_comment || ""
                    ),
                    new_status:
                      releasingHold && releasingHold !== "availability"
                        ? releasingHold
                        : null,
                  }}
                >
                  <Form.Item
                    className="asterick-align"
                    label="New Status"
                    name="new_status"
                    rules={[
                      {
                        required: true,
                        message: "Please select a status",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        releasingHold && releasingHold !== "availability"
                      }
                      showSearch
                      showArrow
                      filterOption={(input, option) =>
                        option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                          0 ||
                        option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={HOLD_STATUS?.filter(
                        ({ key }) => key !== "field_reservation_hold_count"
                      )?.map(({ name, key }) => ({
                        key,
                        label: name,
                        value: key,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    className="asterick-align"
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            releasingHold &&
                            releasingHold !== "availability"
                          ) {
                            if (
                              Number(value) >
                              Number(editInventory?.[releasingHold])
                            )
                              return Promise.reject(
                                "Value can't be more than hold"
                              );
                            return Promise.resolve();
                          }
                          if (
                            Number(value) >
                            Number(editInventory?.availability || 0)
                          )
                            return Promise.reject(
                              "Value can't be more than available count"
                            );
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="number"
                      style={{ maxWidth: "90px" }}
                      onKeyDown={(evt) => {
                        if (
                          evt.key === "e" ||
                          evt.key === "-" ||
                          evt.key === "+" ||
                          evt.key === "." ||
                          evt.key === "ArrowUp" ||
                          evt.key === "ArrowDown"
                        ) {
                          evt.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>

                  {releasingHold === "availability" && (
                    <div className="textarea_row">
                      <Form.Item
                        initialValue={decodeContent(
                          editInventory?.field_inventory_comment || ""
                        )}
                        className="asterick-align"
                        label="Item Comment"
                        name="field_inventory_comment"
                      >
                        <TextArea showCount maxLength={100} />
                      </Form.Item>
                    </div>
                  )}
                </Form>
                <Divider style={{ marginBottom: "0", marginTop: "36px" }} />
                <div className="button_primary">
                  <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={loading}
                  >
                    {releasingHold && releasingHold !== "availability"
                      ? "Release Hold"
                      : "Save Changes"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default InventoryStatusHoldForm;
