import { Table, Divider, Select, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { getAssignDining } from "../../../../../../apis/eventsApi";
import { useSearchParams } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import { objectToQueryString } from "../../../../../../others/util/convertObjectToQueryString";
import { useDebounce } from "../../../../../../others/Debounce/debounce";

function EventAssignDiningTable({
  setSelectedInventory,
  refreshData,
  setRefreshData,
  setAssignUnassignButton,
  setSelectedTraveler,
  selectedRowKeys,
  setSelectedRowKeys,
  setUnassignTravellers,
  setSelectedCheckBoxKeys,
  setIsReservationHold,
  setReservationHoldInventory,
  buttonClicked,
  loading,
  setLoading
}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState();
  const [totalInventory, setTotalInventory] = useState();
  const debouncedSearchTerm = useDebounce(query, 500);

  const [searchParams, setSearchParams] = useSearchParams();
  const addOnId = searchParams.get("addonId");
  const addonType = searchParams.get("addonType");

  const onInputSearch = (column, val) => {
    setQuery({ ...query, [column]: val });
  };

  useEffect(() => {
    if (page) {
      setQuery({ ...query, page: page });
    }
  }, [page]);

  useEffect(() => {
    if (pageSize) {
      setQuery({ ...query, items_per_page: pageSize });
    }
  }, [pageSize]);
  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      if (query?.group_by !== "status") {
        if (selectedRows[0]?.traveler_id) {
          setAssignUnassignButton({ left: false, right: true });
          setReservationHoldInventory({
            inventory_uuid: selectedRows[0]?.inventory_uuid,
            hold_type: "field_reservation_hold_count",
          });
          setSelectedTraveler([
            {
              id: selectedRows[0]?.traveler_uuid,
              reservation_uuid: selectedRows[0]?.reservation_uuid,
              traveler_uuid: selectedRows[0]?.traveler_uuid,
            },
          ]);
          setUnassignTravellers([]);
          setSelectedCheckBoxKeys([]);
          setIsReservationHold(true);
          setSelectedInventory();
        } else {
          setAssignUnassignButton({ left: true, right: false });
          setIsReservationHold(false);
          setSelectedTraveler([{}]);
          const {
            count = 0,
            staff_hold = 0,
            executive_hold = 0,
            promotion_hold = 0,
            artist_hold = 0,
            revenue_hold = 0,
            vendor_hold = 0,
          } = selectedRows[0] || {};

          const totalHold = [
            staff_hold,
            executive_hold,
            promotion_hold,
            artist_hold,
            revenue_hold,
            vendor_hold,
          ].reduce((acc, hold) => acc + (hold || 0), 0);

          const isGreaterThanZero = count - totalHold > 0;
          if (isGreaterThanZero) {
            setSelectedInventory({
              inventory_uuid: selectedRows[0]?.inventory_uuid,
              hold_type: "field_reservation_hold_count",
            });
          } else {
            let stopLoop = false;
            const holdTypes = [
              "field_staff_hold_count",
              "field_executive_hold_count",
              "field_promotion_hold_count",
              "field_artist_hold_count",
              "field_revenue_hold_count",
              "field_vendor_hold_count",
            ];
            const holdKeys = [
              "staff_hold",
              "executive_hold",
              "promotion_hold",
              "artist_hold",
              "revenue_hold",
              "vendor_hold",
            ];

            holdKeys.forEach((val, index) => {
              if (!stopLoop) {
                if (selectedRows[0][val] > 0) {
                  setSelectedInventory({
                    inventory_uuid: selectedRows[0]?.inventory_uuid,
                    hold_type: holdTypes[index],
                  });
                  stopLoop = true;
                }
              }
            });
          }
        }
      } else {
        setSelectedInventory({
          inventory_uuid: selectedRows[0]?.inventory_uuid,
          hold_type: selectedRows[0]?.hold_type,
        });
        setReservationHoldInventory({
          inventory_uuid: selectedRows[0]?.inventory_uuid,
          hold_type: selectedRows[0]?.hold_type,
        });
        if (selectedRows[0]?.assignOrUnassign) {
          setAssignUnassignButton({ left: false, right: true });
          setSelectedTraveler([
            {
              id: selectedRows[0]?.traveler_uuid,
              reservation_uuid: selectedRows[0]?.reservation_uuid,
              traveler_uuid: selectedRows[0]?.traveler_uuid,
            },
          ]);
          setUnassignTravellers([]);
          setSelectedCheckBoxKeys([]);
          setIsReservationHold(true);
          setSelectedInventory();
        } else {
          setIsReservationHold(false);
          setAssignUnassignButton({ left: true, right: false });
          setSelectedTraveler([{}]);
        }
      }
    },
    onSelect: (record, selected, selectedRows) => {},
  };

  const columns = [
    {
      title: (
        <p>
          Item <br />
          <Input onChange={(e) => onInputSearch("item", e.target.value)} />
        </p>
      ),
      dataIndex: "item",
      key: "1",
    },
    {
      title: (
        <p>
          Count <br />
          <Input onChange={(e) => onInputSearch("count", e.target.value)} />
        </p>
      ),
      dataIndex: "count",
      key: "2",
    },
    {
      title: (
        <p>
          Category <br />
          <Input onChange={(e) => onInputSearch("category", e.target.value)} />
        </p>
      ),
      dataIndex: "category",
      key: "3",
    },
    {
      title: (
        <p>
          Name <br />
          <Input onChange={(e) => onInputSearch("t_name", e.target.value)} />
        </p>
      ),
      dataIndex: "name",
      key: "4",
    },
    {
      title: (
        <p>
          Gender <br />
          <Select
            allowClear
            style={{ width: 100 }}
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
            onChange={(e) => onInputSearch("gender", e)}
          />
        </p>
      ),
      dataIndex: "gender",
      key: "5",
    },
    {
      title: (
        <p>
          Age <br />
          <Input
            type="number"
            onChange={(e) => onInputSearch("age", e.target.value)}
          />
        </p>
      ),
      dataIndex: "age",
      key: "6",
    },
    {
      title: (
        <p style={{ alignItems: "center" }}>
          Staff <br />
          <Input
            onChange={(e) => onInputSearch("reporting_role", e.target.value)}
          />
        </p>
      ),
      dataIndex: "staff",
      key: "7",
    },
    {
      title: (
        <p style={{ alignItems: "center" }}>
          Primary Item <br />
          <Input
            onChange={(e) => onInputSearch("primary_item", e.target.value)}
          />
        </p>
      ),
      dataIndex: "primaryitem",
      key: "8",
    },
  ];

  const fetchAssignDining = async (query) => {
    setLoading(true);
    const response = await getAssignDining(addOnId, query);
    if (response && response.status) {
      const { rows } = response?.data?.data;
      setTotalInventory(response?.data?.data?.pager?.total_items);
      if (rows.length) {
        let table = [];
        rows.forEach((item, index) => {
          let childData = [];
          item?.values.forEach((child, i) => {
            childData.push({
              key: `${index}${i}`,
              item: child?.item,
              count: child?.count,
              category: child?.category,
              name: child?.traveler_name,
              gender: child?.gender,
              inventory_uuid: child?.inventory_uuid,
              age: child?.age,
              staff: child?.reporting_role,
              primaryitem: child?.primary_item,
              hold_type: item?.hold_type,
              assignOrUnassign: item?.unassign,
              traveler_uuid: child?.traveler_uuid,
              ...child,
            });
          });

          if (childData.length) {
            table.push({
              key: index,
              item: `${item?.name} ${item?.count ? `(${item?.count})` : ""}`,
              children: childData,
            });
          }
        });
        setTableData(table);
      } else {
        setTableData([]);
      }
      setSelectedInventory({});
      setSelectedRowKeys([]);
      setAssignUnassignButton({ left: true, right: true });
      setRefreshData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  const handleChange = (value) => {
    setQuery({ ...query, group_by: value });
  };

  useEffect(() => {
    if (query) {
      let url_query = objectToQueryString(query);
      fetchAssignDining(url_query);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setQuery({
      group_by: "status",
    });
  }, []);

  useEffect(() => {
    if (query && refreshData && !buttonClicked) {
      let url_query = objectToQueryString(query);
      fetchAssignDining(url_query);
    }
  }, [refreshData,buttonClicked]);


  return (
    <div>
      <div className="partnership_inform">
        <h4>{addonType === "dining" ? "Tables" : "Buses"}</h4>
        <span>
          Group By:
          <Select
            style={{ width: "150px", marginLeft: "5px" }}
            defaultValue="status"
            onChange={handleChange}
            options={[
              {
                value: "reservation",
                label: "Reservation",
              },
              {
                value: "category",
                label: "Category",
              },
              {
                value: "status",
                label: "Status",
              },
              {
                value: "item",
                label: "Item",
              },
              {
                value: "group",
                label: "Group",
              },
            ]}
          />
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      {/* table */}

      <Table
      className="reservation_main-tablee icon_change_transfer assign-table"
      columns={columns}
      defaultExpandAllRows
      loading={loading}
      rowSelection={{
        ...rowSelection,
      }}
      pagination={{
        showSizeChanger:true,
        current: page,
        pageSize: pageSize,
        defaultPageSize:10,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        total: totalInventory,
        position: ["topRight"],
        onChange: (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        },
      }}
      dataSource={tableData}
    />
    </div>
  );
}

export default EventAssignDiningTable;
