import { axiosRequest } from "../utils/axios";
import { Token, customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
import { drupalNoAuthAxiosRequest } from "../utils/drupalNoAuthAxiosRequest";
import { loginAxiosRequest } from "../utils/loginDrupalAxios";

export const memberLoginApi = async (payload) => {
  return await loginAxiosRequest("post", "/oauth/token", payload);
};

export const getMemeberDataApi = async (payload) => {
  return await axiosRequest("post", "user/getUserData", payload);
};

export const changePasswordApi = async (payload) => {
  return await drupalAxiosRequest(
    "patch",
    "/api/user/password-change",
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const sendForgetPasswordOtpApi = async (payload) => {
  return await axiosRequest("post", "user/forgotPassword", payload);
};

export const resetPasswordApi = async (payload) => {
  return await axiosRequest("post", "user/resetPassword", payload);
};

export const generatePasswordOtp = async (payload) => {
  const header = {
    contentType: "application/json",
  };
  return await drupalNoAuthAxiosRequest(
    "post",
    "/api/user/forget-password",
    payload,
    header
  );
};

export const verifyOtp = async (payload) => {
  const header = {
    contentType: "application/json",
  };
  return await drupalNoAuthAxiosRequest(
    "post",
    "/api/user/verify-code",
    payload,
    header
  );
};
export const updatePasswordApi = async (payload) => {
  const header = {
    contentType: "application/json",
  };
  return await drupalNoAuthAxiosRequest(
    "post",
    "/api/user/password-reset",
    payload,
    header
  );
};
