import { EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import CategoryFeeListing from "./CategoryFeeListing";
import { EventCategoryDetailsFeesRoute } from "../../../../url-routes/urlRoutes";
import {
  CategoryFeeListingApi,
  eventFeeListingApi,
} from "../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { Panel } = Collapse;

const Fees = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const [feeListingData, setFeeListingData] = useState([]);
  const [loadingFeeList, setLoadingFeeList] = useState(false);

  useEffect(() => {
    getEventList(categoryId);
  }, [categoryId]);

  const getEventList = async (cId) => {
    setLoadingFeeList(true);
    const response = await CategoryFeeListingApi(cId);
    if (response.status) {
      setFeeListingData(response?.data?.listing?.data);
      setLoadingFeeList(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setFeeListingData([]);
      setLoadingFeeList(false);
    }
  };
  return (
    <Collapse bordered={false}>
      <Panel
        header={
          <>
            <div className="collapse_flex">
              <p className="pricing_paragraph">
                {`Fees (${feeListingData ? feeListingData?.length : 0})`}
              </p>
              <Link to={EventCategoryDetailsFeesRoute}>
                {/* <Button type="y" icon={<EditOutlined />} /> */}
              </Link>
            </div>
            <Divider className="global_divider mb-0" />
          </>
        }
        key="1"
      >
        <CategoryFeeListing
          loadingFeeList={loadingFeeList}
          feeListingData={feeListingData}
        />
      </Panel>
    </Collapse>
  );
};
export default Fees;
