import { Divider, Form, Input, Switch } from "antd";
import React from "react";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TourPrePostMiscellaneousForm = ({ form, onFinishHandler, isLoading }) => {
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 classname="mb-0">Miscellaneous</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item label="Meeting Space" name="field_meeting_space">
            <Input />
          </Form.Item>

          <Form.Item
            label="Touring The Day They Arrive"
            name="field_touring_the_day_they_arrive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <div className="textarea_row">
            <Form.Item label="Notes" name="field_miscellaneous_notes">
              <TextArea rows={4} />
            </Form.Item>
          </div>

          <Form.Item
            label="Leader attending Ext"
            name="field_leader_attending_ext"
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default TourPrePostMiscellaneousForm;
