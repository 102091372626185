import React, { useState } from "react";
import {
  Space,
  DatePicker,
  Form,
  Input,
  InputNumber,
  DatePickerProps,
  Select,
  Switch,
  TreeSelect,
  Divider,
  message,
} from "antd";
import { customCountriesList } from "../../../../others/util/customCountriesList";
import creditCardType from "credit-card-type";
import { drupalAxiosRequest } from "../../../../utils/drupalAxiosRequest";
import moment from "moment";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { ReservationItemDetailRoute } from "../../../../url-routes/urlRoutes";

const InsurancePayCredit = ({ form, insurancePaymentInfo,setLoader }) => {
  const [componentSize, setComponentSize] = useState("default");
  const [searchParams, setSearchParams] = useSearchParams();
  const countriesList = customCountriesList();
  const paramsId = searchParams.get("id");
  const itemId = searchParams.get("item-id");
  const navigate = useNavigate()

  const finishHandler = async (values) => {
    const cardType = creditCardType(values?.cardNumber);
    const year = moment(values?.expiration_year).year().toString();
    const month = (moment(values?.expiration_month).month() + 1).toString();
    const cardExpirationDate = `${
      month.length === 1 ? `0${month}` : month
    }/${year.slice(year.length - 2)}`;

    if(values?.cardNumber.length !== 16){
      return message.error("Invalid card number length")
    }

    if (!cardType[0]?.niceType) {
      return message.error("Card number is not valid");
    }
  
    const countryCode = Object.keys(countriesList).map(val=>{
      if((countriesList?.[val]?.name)?.toLowerCase() === values?.country?.toLowerCase()){
        return values?.country
      }
    }).filter(Boolean)
    const payload = {
      card_details: {
        cardNumber: values?.cardNumber,
        country: countryCode?.[0],
        paymentType: cardType?.[0]?.niceType==='Mastercard'? 'MasterCard': cardType?.[0]?.niceType || "",
        cardHolderName: values?.cardholderName,
        cardExpirationDate,
      },
      amount: insurancePaymentInfo?.payment_details?.payment_amount,
      res_uuid: paramsId,
      res_item_uuid: itemId,
    };
    setLoader(true)
    try {
      const response = await drupalAxiosRequest(
        "post",
        "/api/rest/travelex/payment",
        customRequestHeader({ defaultFormat: true }),
        payload
      );
      if(response?.status){
        return navigate(`${ReservationItemDetailRoute}?id=${paramsId}&item-id=${itemId}`)
      }else{
        message.error(response?.data?.response?.data?.data || SOMETHING_WENT_WRONG)
      }
    } catch (err) {
      console.log("🚀 ~ finishHandler ~ err:", err);
    }
    setLoader(false)
  };

  const handleKeyUp = (event) => {
    const key = event.key.toLowerCase();
    const isAlphaCharacter = /^[a-z]$/.test(key) || /^[^\w\s-]+$/.test(key);

    if (isAlphaCharacter) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Credit Card</h4>
      </div>
      <Divider className="global_divider" />
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        initialValues={{
          size: componentSize,
        }}
        form={form}
        className="payment-credit-form"
        onFinish={finishHandler}
      >
        <Form.Item
          className="asterick-align"
          label="Cardholder Name"
          name="cardholderName"
          rules={[
            {
              required: true,
              message: "Please Enter Cardholder Name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="Card Number"
          name="cardNumber"
          rules={[
            {
              required: true,
              message: "Please Enter Card Number",
            },
          ]}
        >
          <Input
            type="text"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown" ||
                handleKeyUp(evt)
              ) {
                evt.preventDefault();
              }
            }}
            onInput={(e) => {
              return (e.target.value = e?.target.value.slice(0, 20));
            }}
          />
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="Expiration Month"
          name="expiration_month"
          rules={[
            {
              required: true,
              message: "Please Enter Expiration Month",
            },
          ]}
        >
          <DatePicker picker="month" format="MM" />
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="Expiration Year"
          name="expiration_year"
          rules={[
            {
              required: true,
              message: "Please Enter Expiration Year",
            },
          ]}
        >
          <DatePicker picker="year" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Select Country",
            },
          ]}
          className="asterick-align"
          label="Country"
          name="country"
        >
          <Select
            showSearch
            placeholder="Select Country"
            optionFilterProp="country"
            options={countriesList.map((country) => ({
              key: country.isoCode,
              value: country.name,
              label: country.name,
            }))}
          />
        </Form.Item>
      </Form>
    </>
  );
};
export default InsurancePayCredit;
