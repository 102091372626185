import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  loggedOutUser,
} from "../../redux/features/authFeatures/authSlice";
import { useSelector } from "react-redux";
const ProtectedRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userAuthData = JSON.parse(localStorage.getItem("authorization"));
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    let accessTokenExpireAt = JSON.parse(
      localStorage.getItem("accessTokenExpireAt")
    );
    if (!!accessTokenExpireAt && accessTokenExpireAt < Date.now()) {
      setIsAuthenticated(true);
      dispatch(loggedOutUser());
      localStorage.removeItem("authorization");
      localStorage.removeItem("accessTokenExpireAt");
    }
  }, [location]);

  return userAuthData && userAuthData.access_token && !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
export default ProtectedRoute;
