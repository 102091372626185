import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createGroupDetailsApi,
  createGroupDetailsExtensionApi,
  getAllGroupDetailsApi,
  getGroupDetailsDataApi,
  getGroupDetailsExtensionDataApi,
  getSingleGroupDetailWrapperApi,
} from "../../../apis/groupDetailsApi";
import { toSnakeCase } from "../../../utils/contentParser";

export const getAllGroupDetails = createAsyncThunk(
  "groupDetails/getAllEvents",
  async (data) => {
    let urlQuery = new URLSearchParams(data).toString();
    data?.sort_order === "recently_read" &&
      (urlQuery = `items_per_page=10&sort_order=recently_read&page=0&event_type=${toSnakeCase(
        data?.tab
      )}`);
    const response = await getSingleGroupDetailWrapperApi(urlQuery);
    return response;
  }
);

export const createGroupDetails = createAsyncThunk(
  "groupDetails/createGroupDetails",
  async (data) => {
    return await createGroupDetailsApi(data);
  }
);
export const createGroupDetailsExtension = createAsyncThunk(
  "groupDetails/createGroupDetailsExtension",
  async (data) => {
    return await createGroupDetailsExtensionApi(data);
  }
);
export const getGroupDetailsData = createAsyncThunk(
  "groupDetails/getGroupDetailsData",
  async (eventId) => {
    return await getGroupDetailsDataApi(eventId);
  }
);
export const getGroupDetailsExtensionData = createAsyncThunk(
  "groupDetails/getGroupDetailsExtensionData",
  async (eventId) => {
    return await getGroupDetailsExtensionDataApi(eventId);
  }
);
export const groupDetailsSlice = createSlice({
  name: "groupDetails",
  initialState: {
    allGroupDetails: {
      allGroupDetailsResponse: {},
      loadingAllGroupDetails: false,
    },
    groupDetailsCreation: {
      groupDetailsCreationResponse: {},
      loadingGroupDetailsCreation: false,
      mainTab: "",
      preTab: "",
      postTab: "",
    },
    groupDetailsExtensionCreation: {
      groupDetailsExtensionCreationResponse: {},
      loadingGroupDetailsExtensionCreation: false,
    },
    groupDetailsData: {
      groupDetailsDataResponse: {},
      loadingGroupDetailsData: false,
    },
    groupDetailsExtensionData: {
      groupDetailsExtensionDataResponse: {},
      loadingGroupDetailsExtensionData: false,
    },
  },
  reducers: {
    changeTabState: (state, action) => {
      switch (action.payload) {
        case "main-tab":
          state.groupDetailsCreation.mainTab = "done";
          break;
        case "pre-tab":
          state.groupDetailsCreation.preTab = "done";
          break;
        case "post-tab":
          state.groupDetailsCreation.postTab = "done";
          break;
        default:
          state.groupDetailsCreation.mainTab = "";
          state.groupDetailsCreation.postTab = "";
          state.groupDetailsCreation.preTab = "";
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGroupDetails.pending, (state) => {
      state.allGroupDetails.loadingAllGroupDetails = true;
    });
    builder.addCase(getAllGroupDetails.fulfilled, (state, action) => {
      state.allGroupDetails.allGroupDetailsResponse = action.payload;
      state.allGroupDetails.loadingAllGroupDetails = false;
    });
    builder.addCase(createGroupDetails.pending, (state) => {
      state.groupDetailsCreation.loadingGroupDetailsCreation = true;
    });
    builder.addCase(createGroupDetails.fulfilled, (state, action) => {
      state.groupDetailsCreation.groupDetailsCreationResponse = action.payload;
      state.groupDetailsCreation.loadingGroupDetailsCreation = false;
    });
    builder.addCase(createGroupDetailsExtension.pending, (state) => {
      state.groupDetailsExtensionCreation.loadingGroupDetailsExtensionCreation = true;
    });
    builder.addCase(createGroupDetailsExtension.fulfilled, (state, action) => {
      state.groupDetailsExtensionCreation.groupDetailsExtensionCreationResponse =
        action.payload;
      state.groupDetailsExtensionCreation.loadingGroupDetailsExtensionCreation = false;
    });
    builder.addCase(getGroupDetailsData.pending, (state) => {
      state.groupDetailsData.loadingGroupDetailsData = true;
    });
    builder.addCase(getGroupDetailsData.fulfilled, (state, action) => {
      state.groupDetailsData.groupDetailsDataResponse = action.payload;
      state.groupDetailsData.loadingGroupDetailsData = false;
    });
    builder.addCase(getGroupDetailsExtensionData.pending, (state) => {
      state.groupDetailsExtensionData.loadingGroupDetailsExtensionData = true;
    });
    builder.addCase(getGroupDetailsExtensionData.fulfilled, (state, action) => {
      state.groupDetailsExtensionData.groupDetailsExtensionDataResponse =
        action.payload;
      state.groupDetailsExtensionData.loadingGroupDetailsExtensionData = false;
    });
  },
});
export const { changeTabState } = groupDetailsSlice.actions;
export default groupDetailsSlice.reducer;
