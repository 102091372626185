import axios from "axios";
import drupalConfig from "../config/serverless/drupal.config";
// const BASE_URL = `https://dev-ict-booking.oslabs.app`;

export const organizationDrupalAxios = async (
  reqMethod,
  reqUrl,
  reqHeaders,
  data
) => {
  let result;
  try {
    if (
      reqMethod === "post" ||
      reqMethod === "patch" ||
      (reqMethod === "delete" && !!Object.keys(data).length)
    ) {
      result = await axios({
        headers: reqHeaders,
        method: reqMethod,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
        data,
      });
    } else {
      result = await axios({
        headers: reqHeaders,
        method: reqMethod,
        url: `${drupalConfig.BASE_URL}${reqUrl}`,
      });
    }
    console.log("result=================", result);
    return { data: result.data, status: true };
  } catch (error) {
    console.log("error", error);
    return { data: error, status: false };
  }
};
