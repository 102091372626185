import { Form, Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdownSearchbar from "../../../components/Admin/CustomDropdown/CustomDropdownSearchbar";
import EditEmailTemplateHeader from "../../../components/Admin/EmailTemplates/EditEmailTemplate/EditEmailTemplateHeader";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EditEmailTemplateForm from "../../../components/Admin/EmailTemplates/EditEmailTemplate/EditEmailTemplateForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  editEmailTemplateApi,
  getEmailTemplateDataApi,
} from "../../../apis/emailTemplates";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { AdminEmailTemplatesRoute } from "../../../url-routes/urlRoutes";
const { Header, Content, Sider } = Layout;

const breadCrumbs = [
  { title: "Admin", path: "" },
  { title: "Email Templates", path: "/email_templates" },
  { title: "Edit", path: "" },
];
const EditEmailTemplatePage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [form] = Form.useForm();
  const [templateData, setTemplateData] = useState({});
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const templateId = searchParams.get("id");

  useEffect(() => {
    getTemplateData(templateId);
  }, []);

  const getTemplateData = async (id) => {
    setLoadingTemplate(true);
    const response = await getEmailTemplateDataApi(id);
    if (response.status) {
      setTemplateData(response?.data?.data);
      setLoadingTemplate(false);
    } else {
      setTemplateData({});
      setLoadingTemplate(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const onFinishHandler = (values, editorInstance) => {
    let payload = {
      data: {
        type: "node--email_template",
        id: templateId,
        attributes: {
          body: editorInstance ? editorInstance.getData() : null,
          field_email_subject: values?.field_email_subject || null,
        },
      },
    };
    if (templateId) {
      editTemplate({ payload, templateId });
    }
  };

  const editTemplate = async (data) => {
    setEditLoader(true);
    const res = await editEmailTemplateApi(data);
    if (res.status) {
      setEditLoader(false);
      message.success("Template Updated Successfully");
      navigate(AdminEmailTemplatesRoute);
    } else {
      setEditLoader(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CustomDropdownSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EditEmailTemplateHeader
              form={form}
              onFinishHandler={onFinishHandler}
              editLoader={editLoader}
              loadingTemplate={loadingTemplate}
              templateData={templateData}
            />
          </div>
          <Content className="site-layout-background">
            <EditEmailTemplateForm
              form={form}
              onFinishHandler={onFinishHandler}
              templateData={templateData}
              loadingTemplate={loadingTemplate}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EditEmailTemplatePage;
