import { Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Email Type",
    dataIndex: "email_type",
    key: "email_type",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
];

const PendingEmailMonitorContainer = ({pendingEmail}) => {
  const [data, setData] = useState([
    { email_type: "Terms & Conditions", count: 0,type:"terms_and_conditions_count" },
    { email_type: "Booking Confirmation", count: 0 ,type:"booking_confirmation_count"},
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updateData = []
    if(pendingEmail && pendingEmail.data && pendingEmail.data.data){
      Object.keys(pendingEmail?.data?.data).forEach((val,index)=>{
        if(val === "booking_confirmation_count"){
          updateData.push({count:pendingEmail?.data?.data?.[val],email_type:"Booking Confirmation"})
        }else{
          updateData.push({count:pendingEmail?.data?.data?.[val],email_type:"Terms & Conditions"})
        }
      })
      setData(updateData)
    }
  }, [pendingEmail]);

  // const getEmailTemplates = async () => {
  //   setLoading(true);
  //   const res = await getEmailTemplatesListApi();
  //   if (res.status) {
  //     setData(
  //       res?.data?.data?.map((listItem) => ({
  //         key: listItem.id,
  //         email_templates: listItem.title,
  //         action: (
  //           <EditOutlined
  //             className="action_edit"
  //             onClick={() => {
  //               navigate(`${AdminEmailTemplateEditRoute}?id=${listItem?.id}`);
  //             }}
  //           />
  //         ),
  //       }))
  //     );
  //     setLoading(false);
  //   } else {
  //     setData([]);
  //     message.error(SOMETHING_WENT_WRONG);
  //     setLoading(false);
  //   }
  // };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>Pending Emails</h3>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default PendingEmailMonitorContainer;
