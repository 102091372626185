import { Table, Divider, Tabs } from "antd";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },

  {
    title: "Input",
    dataIndex: "input",
  },
];

function CruisePassenger({ allDetails, allDetailsLoading }) {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);

  const { field_pricing_option } = allDetails || {};

  const handleTabChange = (key) => {
    setCurrentTab(Number(key));
  };

  const data = [
    {
      key: "1",
      label: "Est Group Size",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_est_group_count || ""}
        </>
      ),
    },
    {
      key: "2",
      label: "Est Group Count",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_est_group_size
            ?.label || ""}
        </>
      ),
    },
    {
      key: "3",
      label: "# of Travel Coordinators",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_travel_coordinator ||
            ""}
        </>
      ),
    },
    {
      key: "4",
      label: "1 for Program",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_one_for_program
            ?.label || ""}
        </>
      ),
    },
    {
      key: "5",
      label: "Event Partner Income (Minus Conf Fee)",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_event_partner_income ||
            ""}
        </>
      ),
    },
    {
      key: "6",
      label: "Conference Fee",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_conference_fee || ""}
        </>
      ),
    },
    {
      key: "7",
      label: "Purpose of Income",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_purpose_of_income ||
            ""}
        </>
      ),
    },
    {
      key: "8",
      label: "3rd & 4th Pax Event Partner Income",
      input: (
        <>
          {field_pricing_option?.[currentTab - 1]?.field_partner_income || ""}
        </>
      ),
    },
    {
      key: "9",
      label: "Notes",
      input: <>{field_pricing_option?.[currentTab - 1]?.field_notes || ""}</>,
    },
  ];

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }

  const tabItems = allDetails?.field_pricing_option?.map((item, index) => {
    return {
      key: index + 1,
      label: `Pricing ${index + 1}`,
      children: (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      ),
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <h4 className="mb-0 ms-0">Pricing Options</h4>
      </div>
      <Divider className="global_divider mb-0" />
      {allDetails?.field_pricing_option?.length > 1 ? (
        <Tabs
          activeKey={currentTab}
          className="details-tabs"
          items={tabItems}
          onChange={(key) => {
            handleTabChange(key);
          }}
        ></Tabs>
      ) : (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
}

export default CruisePassenger;
