import {
  Table,
  Divider,
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  Select,
  Switch,
  message,
} from "antd";
// import { Link } from "react-router-dom";
// import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import "./Reservationguest_details.less";
import {
  addRemoveTraveler,
  getApplicationDiscountApi,
  getApplicationFeeApi,
  getTravelersData,
  postApplicationApi,
} from "../../../../apis/reservationApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { addCommasInPricing } from "../../../../utils/contentParser";
import { ReservationItemDetailRoute } from "../../../../url-routes/urlRoutes";

// interface DataType {
//   key: React.ReactNode;
//   name: string;
//   age: number;
//   address: string;
//   children?: DataType[];
// }

function Reservationguest_details(props) {
  const { data, setTriggerData,triggerData } = props;
  const [traveler, setTraveler] = useState();
  const [feeList, setFeeList] = useState([]);
  const [travelerList, setTravelerList] = useState([]);
  const [modalData, setModalData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTraveler, setCurrentTraveler] = useState([]);
  const [addTraveler, setAddTraveler] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [changeGuestForm] = Form.useForm();
  const paramsId = searchParams.get("id");
  const itemId = searchParams.get("item-id");
  const status = data?.reservation_item?.reservation_status;
  const itemStatus = data?.reservation_item?.field_itemstatus;
  const navigate = useNavigate();

  const columns = [
    {
      title: "Item/Name",
      dataIndex: "item_name",
      key: "1",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "2",
      render: (value) =>
        value === "" || value === undefined
          ? ""
          : `$${addCommasInPricing(value?.toString())}`,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "3",
      render: (value) =>
        value === "" || value === undefined
          ? ""
          : `$${addCommasInPricing(value?.toString())}`,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "4",
      render: (value) =>
        value === "" || value === undefined
          ? ""
          : `$${addCommasInPricing(value?.toString())}`,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "5",
      render: (value) =>
        value === "" || value === undefined
          ? ""
          : `$${addCommasInPricing(value?.toString())}`,
    },
  ];
  const getApplicationFee = async () => {
    const response = await getApplicationFeeApi(itemId);
    if (response && response.status) {
      const { data } = response;
      const feeIds = data.map((val) => {
        return { id: val.id, name: val?.name };
      });
      setFeeList(feeIds);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const getApplicationDiscount = async () => {
    const response = await getApplicationDiscountApi(itemId);
    if (response && response.status) {
      const { data } = response;
      const discounts = data.map((val) => {
        return { id: val.id, name: val?.name };
      });
      setDiscountList(discounts);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  // const getTravelerList = async (id) => {
  //   const response = await getTravelersData(id);
  //   if (response && response.status) {
  //     const { data } = response?.data;
  //     const travelerIds = data.map((val) => {
  //       if (val?.contacts.length) {
  //         return { id: val.id, name: val?.contacts[0]?.name };
  //       } else {
  //         return { id: val.id, name: val?.name };
  //       }
  //     });
  //     setTravelerList(travelerIds);
  //   } else {
  //     message.error(SOMETHING_WENT_WRONG);
  //   }
  // };

  useEffect(() => {
    if (data) {
      setTravelerList(data?.remove_travelers);
      setModalData({
        eventName: data?.event_datail?.name,
        reservationId: data?.reservation_item?.id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      let travellers = [];
      if (data?.traveler_details_total_sum) {
        travellers.push({
          key: 0,
          item_name: data?.reservation_item_category?.name,
          price:
            data?.traveler_details_total_sum?.total_items_price?.toLocaleString(
              "en-US"
            ) || 0,
          discount:
            data?.traveler_details_total_sum?.total_items_discount?.toLocaleString(
              "en-US"
            ) || 0,
          fee:
            data?.traveler_details_total_sum?.total_items_fees?.toLocaleString(
              "en-US"
            ) || 0,
          total:
            data?.traveler_details_total_sum?.total_items_amount?.toLocaleString(
              "en-US"
            ) || 0,
        });
      }
      const current_traveler = data?.travelers_items?.map((val) => {
        return {
          contact_name: val?.field_traveler?.contact?.name,
          name: val?.field_traveler?.name,
          id: val?.field_traveler?.id,
        };
      });
      setAddTraveler(data?.add_travelers);
      setCurrentTraveler({
        list: current_traveler,
        reservation_id: data?.reservation_item?.id,
        event_name: data?.event_datail?.name,
        addTraveler: data?.add_travelers,
        remove_travelers: data?.remove_travelers,
      });
      data?.travelers_items?.forEach((val, index) => {
        let travelerChild = [];
        let travelerData = {
          key: index + 1,
          item_name: (
            <>
              <span className="document_pdf">
                {val?.field_traveler?.contact?.name ||
                  val?.field_traveler?.name}
              </span>
            </>
          ),
          price: val.field_price?.toLocaleString("en-US") || 0,
          discount: val.total_discount?.toLocaleString("en-US") || 0,
          fee: val?.total_fees?.toLocaleString("en-US") || 0,
          total: val?.total?.toLocaleString("en-US") || 0,
        };
        if (val.fees.length) {
          val.fees.forEach((elem, idx) => {
            let child = {
              // key: `${index + 1}${idx}`,
              item_name: (
                <>
                  <span>
                    <Checkbox
                      className="checbox_space"
                      checked={elem.field_is_active}
                      disabled
                    ></Checkbox>
                  </span>
                  <span>{elem?.name}</span>
                </>
              ),
              fee:
                elem?.type === "fees" &&
                (elem?.field_amount?.toLocaleString("en-US") || 0),
              total: ``,
            };
            travelerChild.push(child);
          });
        }
        if (val.discounts.length) {
          val.discounts.forEach((elem, idx) => {
            let child = {
              // key: `${index + 1}${idx}`,
              item_name: (
                <>
                  <span>
                    <Checkbox
                      className="checbox_space"
                      checked={elem?.field_is_active}
                      disabled
                    ></Checkbox>
                  </span>
                  <span>{elem?.name}</span>
                </>
              ),
              // price: elem?.field_amount,
              discount: elem?.field_amount?.toLocaleString("en-US") || 0,
              // fee: elem?.type === "fees" && elem?.field_amount,
              total: ``,
            };
            travelerChild.push(child);
          });
        }
        travelerData.children = travelerChild;
        travellers.push(travelerData);
      });
      setTraveler(travellers);
    }
  }, [data]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    getApplicationDiscount()
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form2.setFieldsValue({
      select_discount:"",
      apply_travelers:""
    })
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form2.setFieldsValue({
      select_discount:"",
      apply_travelers:""
    })
    setIsModalOpen(false);
  };

  const addApplicationFee = async (payload, urlType) => {
    const response = await postApplicationApi(payload, urlType, itemId);
    if (response && response.status) {
      message.success(response?.data?.message);
      feeCancel();
      handleCancel();
      setTriggerData(!triggerData);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setButtonLoading(false);
  };

  const onFinish = (values) => {
    setButtonLoading(true);
    const payload = {
      data: {
        traveler_item_id: values?.apply_fee_travelers,
        fees_id: values?.select_fee,
        applied_on: "booking_engine",
        reservation_id: paramsId,
      },
    };
    addApplicationFee(payload, "applicable_fees");
  };

  const onDiscountFinish = (values) => {
    setButtonLoading(true);
    const payload = {
      data: {
        traveler_item_id: values?.apply_travelers,
        discount_id: values?.select_discount,
        applied_on: "booking_engine",
        reservation_id: paramsId,
      },
    };
    addApplicationFee(payload, "applicable_discounts");
  };

  const [feeModalOpen, setFeeModalOpen] = useState(false);
  const feeModal = () => {
    getApplicationFee();
    setFeeModalOpen(true);
  };
  const feeOk = () => {
    form.setFieldsValue({
      select_fee:"",
      apply_fee_travelers:""
    })

    setFeeModalOpen(false);
  };
  const feeCancel = () => {
      form.setFieldsValue({
      select_fee:"",
      apply_fee_travelers:""
    })
    setFeeModalOpen(false);
  };

  const [travelModalOpen, setTravelModalOpen] = useState(false);
  const travelModal = () => {
    changeGuestForm.resetFields();
    setTravelModalOpen(true);
  };
  const travelOk = () => {
    setTravelModalOpen(false);
  };
  const travelCancel = () => {
    setTravelModalOpen(false);
  };

  const updateTraveler = async (payload) => {
    const response = await addRemoveTraveler(payload);

    if (response && response.status) {
      message.success(response?.data?.message);
      travelOk();
      if (
        response?.data?.reservation_uuid &&
        response?.data?.reservation_item_uuid
      ) {
        navigate(
          `${ReservationItemDetailRoute}?id=${response?.data?.reservation_uuid}&item-id=${response?.data?.reservation_item_uuid}`
        );
      }
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
    }
    setLoading(false);
  };
  const onFinishUpdateTraveler = (value) => {
    setLoading(true);
    const payload = {
      res_item_id: currentTraveler?.reservation_id,
      assign_responsibility: value.add_responsibility ? true : false,
      reservation_id: paramsId,
      remove: {
        traveler_id: value?.remove || null,
      },
      add: {
        traveler_id: value?.add || null,
      },
    };
    updateTraveler(payload);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 style={{ fontFamily: "'Poppins'" }}> Travelers Details</h4>

        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          {!["quote","cancelled","purchased"].includes(itemStatus) &&
            (status === "active" ||
              status === "revise" ||
              status === "cancel_pending") ? (
            <Button type="primary" onClick={travelModal}>
              Change Traveler(s)
            </Button>
          ) : null}

          <Modal
            footer={[
              <Form.Item>
                <Button
                  type="submit"
                  loading={loading}
                  onClick={() => changeGuestForm.submit()}
                >
                  Update Traveler(s)
                </Button>
              </Form.Item>,
            ]}
            title="Change Traveler(s)"
            open={travelModalOpen}
            onOk={travelOk}
            onCancel={travelCancel}
          >
            <div className="add-discount-form_modal">
              <p>{currentTraveler?.reservation_id}</p>
              <p>{currentTraveler?.event_name}</p>

              <div className="current-travelers mb-3">
                <div className="current_title">
                  <p>Current Traveler(s)</p>
                </div>

                <div className="currnet_traveler_name">
                  {currentTraveler?.list?.map((val) => (
                    <p className="pb-2">
                      {val?.contact_name || val?.name}{" "}
                      <span>{val?.id && `(${val?.id})`}</span>
                    </p>
                  ))}
                </div>
              </div>

              <div className="form-title mb-3">
                <p>Change Traveler(s)</p>
              </div>
              <Form
                name="basic"
                form={changeGuestForm}
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishUpdateTraveler}
                autoComplete="off"
              >
                <Form.Item
                  label="Traveler to remove"
                  name="remove"
                  className="asterick-align"
                >
                  <Select
                    allowClear
                    options={currentTraveler?.remove_travelers?.map(
                      (v, index) => ({
                        key: index,
                        value: v?.id,
                        label: v?.contact?.name || v?.name,
                      })
                    )}
                  ></Select>
                </Form.Item>
                <Form.Item
                  label="Traveler to add"
                  name="add"
                  className="asterick-align"
                >
                  <Select
                    allowClear
                    options={currentTraveler?.addTraveler?.map((v, index) => ({
                      key: index,
                      value: v?.id,
                      label: v?.contact?.name || v?.name,
                    }))}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name={"add_responsibility"}
                  label="Assign responsibility for traveler change fee (if any) to new traveler"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Form>
            </div>
          </Modal>

          {!["quote","purchased"].includes(itemStatus) && 
            (status === "active" ||
              status === "revise" ||
              status === "cancel_pending" ||
              status === "completed" ||
              status === "cancelled") ? (
            <Button onClick={feeModal}>Add Fee</Button>
          ) : null}

          <Modal
            footer={[
              <Button
                type="primary"
                loading={buttonLoading}
                onClick={() => form.submit()}
              >
                Save
              </Button>,
            ]}
            title="Add Fee"
            open={feeModalOpen}
            onOk={feeOk}
            onCancel={feeCancel}
          >
            <div className="add-discount-form_modal">
              <p>{modalData?.reservationId}</p>
              <p>{modalData?.eventName}</p>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  label="Select Fee"
                  name="select_fee"
                  className="asterick-align"
                  rules={[
                    {
                      required: true,
                      message: "Please select fee",
                    },
                  ]}
                >
                  <Select
                    options={feeList.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  ></Select>
                </Form.Item>

                <Form.Item
                  label="Select Traveler"
                  name="apply_fee_travelers"
                  className="asterick-align"
                  rules={[
                    {
                      required: true,
                      message: "Please select traveler",
                    },
                  ]}
                >
                  <Select
                    options={travelerList?.map((item) => {
                      return {
                        label: item?.contact?.name || item?.name,
                        value: item.traveler_item_id,
                      };
                    })}
                  />
                </Form.Item>
              </Form>
            </div>
          </Modal>

          {!["quote","purchased"].includes(itemStatus) && 
            (status === "active" ||
              status === "revise" ||
              status === "cancel_pending" ||
              status === "completed" ||
              status === "cancelled") ? (
            <Button onClick={showModal}>Add Discount</Button>
          ) : null}

          <Modal
            footer={[
              <Button
                type="primary"
                onClick={() => form2.submit()}
                loading={buttonLoading}
              >
                Save
              </Button>,
            ]}
            title="Add Discount"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="add-discount-form_modal">
              <p>{modalData?.reservationId}</p>
              <p>{modalData?.eventName}</p>
              <Form
                name="basic"
                form={form2}
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                onFinish={onDiscountFinish}
              >
                <Form.Item
                  label="Select Discount"
                  name="select_discount"
                  className="asterick-align"
                  rules={[
                    {
                      required: true,
                      message: "Please select discount",
                    },
                  ]}
                >
                  <Select
                    options={discountList.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  ></Select>
                </Form.Item>

                <Form.Item
                  className="asterick-align"
                  label="Select Traveler"
                  name="apply_travelers"
                  rules={[
                    {
                      required: true,
                      message: "Please select traveler",
                    },
                  ]}
                >
                  <Select
                    options={travelerList?.map((item) => {
                      return {
                        label: item?.contact?.name || item?.name,
                        value: item.traveler_item_id,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>
      </div>

      <Divider className="global_divider" />

      <Table
        className="plus_change arrowicon_change"
        defaultExpandAllRows={true}
        columns={columns}
        dataSource={traveler}
        size="middle"
        pagination={false}
      />
    </div>
  );
}

export default Reservationguest_details;
