import { Table, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import React from "react";

// const columns = [
//   {
//     title: "Label",
//     dataIndex: "label",
//   },
//   {
//     title: "Input",
//     dataIndex: "input",
//   },
// ];

function Notes({ allDetails, allDetailsLoading }) {
  const { field_note } = allDetails || {};
  // const data = [
  //   {
  //     key: "1",
  //     label: "Date",
  //     input: "10/10/2023",
  //   },
  //   {
  //     key: "2",
  //     label: "Date & Initials",
  //     input: <>{field_note || ""}</>,
  //   },
  // ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Notes</h4>
        {/* <span>
          <Link to="/contact">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      {/* <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      /> */}
      <p className="full_table">{field_note || ""}</p>
    </div>
  );
}

export default Notes;
