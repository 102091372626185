import { DatePicker, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DATE_FORMAT } from "../../../../../../../utils/constants";
import paymentMethod from "../../../../../../../utils/paymentMethod.json"

import { getTaxonomyList } from "../../../../../../../others/commonApiCalls/commonApiCalls";
import moment from "moment";
import { addCommasInPricing } from "../../../../../../../utils/contentParser";

const PlanPaymentForm = ({ form, term, termFormData, onFinishHandler }) => {
  const [paymentMethodList, setPaymentMethodList] = useState(paymentMethod.list);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  // useEffect(() => {
  //   getTaxonomyList(
  //     "payment_method",
  //     setPaymentMethodList,
  //     setPaymentMethodLoading
  //   );
  // }, []);

  useEffect(() => {
    form.setFieldsValue({
      deposit_amount: termFormData?.deposit_data?.amount?.toString() || null,
      deposit_due_date: termFormData?.deposit_data?.due_date
        ? moment(termFormData?.deposit_data?.due_date, "YYYY-MM-DD")
        : null,
      installment_amount:
        termFormData?.installments?.amount?.toString() || null,
      no_of_installement:
        termFormData?.installments?.no_of_installement || null,
      installment_due_date: termFormData?.installments?.due_date
        ? moment(termFormData?.installments?.due_date, "YYYY-MM-DD")
        : null,
      installment_service_fee:
        termFormData?.installments?.service_fee?.toString() || null,
    });
    setTotalAmount(termFormData?.traveler_data?.total_amount || 0);
  }, [termFormData]);

  const intallmentAmountHandler = (value, type) => {
    const numberOfInstallment =
      type == "installment"
        ? Number(value) <= 0
          ? 1
          : Number(value)
        : Number(form.getFieldValue("no_of_installement") || 0);

    const installmentAmount =
      type == "installment"
        ? (Number(totalAmount) -
          parseFloat(
            form.getFieldValue("deposit_amount").replace(/,/g, "")
          )) /
        numberOfInstallment
        : (Number(totalAmount) - value) / numberOfInstallment;
    form.setFieldsValue({
      installment_amount: installmentAmount.toFixed(2),
    });
  };
  return (
    <>
      <Form form={form} onFinish={onFinishHandler}>
        <div>
          <h3>Deposit</h3>
          <Divider className="global_divider" />
          <Form.Item
            label="Amount"
            name="deposit_amount"
            className="asterick-align"
            rules={[{ required: true, message: "Please enter Amount" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  // evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown"
                ) {
                  evt.preventDefault();
                }
              }}
              onInput={(e) => {
                intallmentAmountHandler(e.target.value, "amount");
              }}
            />
          </Form.Item>
          <Form.Item
            label="Payment Method"
            name="deposit_payment_method"
            className="asterick-align"
            rules={[
              { required: true, message: "Please select Payment Method" },
            ]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              options={paymentMethodList.map((paymentMethod,index) => ({
                key: index,
                value: paymentMethod.value,
                label: paymentMethod.name,
              }))}
              loading={paymentMethodLoading}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name="deposit_due_date"
            className="asterick-align"
            rules={[{ required: true, message: "Please enter Due Date" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </div>
        <div class="background_div"></div>
        <div>
          <h3 style={{ paddingTop: "1rem" }}>Installments</h3>
          <Divider className="global_divider" />
          <Form.Item
            label="# of Installments"
            name="no_of_installement"
            className="asterick-align"
            rules={[
              { required: true, message: "Please enter # of Installments" },
            ]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input
              disabled={term === "quartely_payment_plan"}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown"
                ) {
                  evt.preventDefault();
                }
              }}
              onInput={(e) => {
                intallmentAmountHandler(e.target.value, "installment");
              }}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="installment_amount"
            className="asterick-align"
            rules={[{ required: true, message: "Please enter Amount" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Service Fee"
            name="installment_service_fee"
            className="asterick-align"
            rules={[{ required: true, message: "Please enter Service Fee" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Payment Method"
            name="installment_payment_method"
            className="asterick-align"
            rules={[
              { required: true, message: "Please select Payment Method" },
            ]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              options={paymentMethodList.map((paymentMethod,index) => ({
                key:index,
                value: paymentMethod.value,
                label: paymentMethod.name,
              }))}
              loading={paymentMethodLoading}
            />
          </Form.Item>
          <Form.Item
            label="First Due Date"
            name="installment_due_date"
            className="asterick-align"
            rules={[{ required: true, message: "Please enter First Due Date" }]}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <DatePicker
              format={DATE_FORMAT}
              disabled={term === "quartely_payment_plan"}
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default PlanPaymentForm;
