import { Layout, Grid } from "antd";
import React, { useState } from "react";
import ContactSearchBar from "../../../components/Contacts/ContactsListing/ContactSearchBar";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import ContactActivityDetailContainer from "../../../components/Contacts/ContactDetails/ContactActivityDetails/ContactActivityDetailContainer";
import { useSearchParams } from "react-router-dom";

const { Header } = Layout;

function ActivityDetailPage() {
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [isFilterApplied, setIsFilterApplied] = useState(false); // necessary props for Contact search Bar
  const contactId = searchParams.get("contactId");

  const breadCrumbs = [
    { title: "Contacts", path: "/contact/listing" },
    { title: "Details", path: `/contact/details?id=${contactId}#Activities` },
    { title: "Activity", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            {/* <Searchreservations_menu /> */}
            <ContactSearchBar
              // filter props are required
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          {/* <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reservations</Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
            <Breadcrumb.Item>Activity</Breadcrumb.Item>
          </Breadcrumb> */}
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          <ContactActivityDetailContainer />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ActivityDetailPage;
