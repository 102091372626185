import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, Select, Tree } from "antd";
import React, { useState } from "react";
const options = [];

const ManageMarketingInspiration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpe, setIsModalOpe] = useState(false);
  const showModa = () => {
    setIsModalOpe(true);
  };
  const handleO = () => {
    setIsModalOpe(false);
  };
  const handleCance = () => {
    setIsModalOpe(false);
  };

  const treeData = [
    {
      title: (
        <>
          <div className="drag_icon marketing_sources_inspiration">
            <div className="inspiration_edit">
              <p>Inspiration</p>

              <div className="marketing_action_btn">
                <div onClick={showModal}>
                  <EditOutlined />
                </div>

                <Modal
                  title={
                    <>
                      <span className="document_pdf">Manage Channels</span>
                      <span className="span_text"> (Inspiration)</span>
                    </>
                  }
                  footer={
                    <>
                      <Button type="primary">Save Changes</Button>
                    </>
                  }
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <div>
                    <Form layout="vertical">
                      <div className="textarea_row">
                        <Form.Item label="Select / Create Channels">
                          <Select
                            mode="tags"
                            placeholder="Please select"
                            style={{
                              width: "100%",
                            }}
                            options={options}
                          />

                          <span className="span_text">
                            Press enter to add Channels
                          </span>
                        </Form.Item>
                      </div>

                      <div className="main-collapse">
                        <p>Selected Channels</p>

                        <div className="main-collapse--button">
                          <Button>
                            Email <CloseOutlined className="span_text" />
                          </Button>

                          <Button>
                            Event <CloseOutlined className="span_text" />
                          </Button>

                          <Button>
                            Inspiration Referral{" "}
                            <CloseOutlined className="span_text" />
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </>
      ),
      key: "0-0",
      children: [
        {
          title: (
            <>
              <div className="inspiration_edit">
                <p>Email</p>
                <div onClick={showModa}>
                  <EditOutlined />
                </div>
                <Modal
                  title={
                    <>
                      <span className="document_pdf">Manage Details </span>
                      <span className="span_text"> (Email)</span>
                    </>
                  }
                  footer={
                    <>
                      <Button type="primary">Save Changes</Button>
                    </>
                  }
                  open={isModalOpe}
                  onOk={handleO}
                  onCancel={handleCance}
                >
                  <div>
                    <Form layout="vertical">
                      <div className="textarea_row">
                        <Form.Item label="Select / Create Details">
                          <Select
                            mode="tags"
                            placeholder="Please select"
                            style={{
                              width: "100%",
                            }}
                            options={options}
                          />

                          <span className="span_text">
                            Press enter to add Details
                          </span>
                        </Form.Item>
                      </div>

                      <div className="main-collapse">
                        <p>Selected Details</p>

                        <div className="main-collapse--button">
                          <Button>
                            From Inspiration{" "}
                            <CloseOutlined className="span_text" />
                          </Button>

                          <Button>
                            From iTickets{" "}
                            <CloseOutlined className="span_text" />
                          </Button>

                          <Button>
                            Best Christian Travels" September 2022{" "}
                            <CloseOutlined className="span_text" />
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal>
                <div>
                  <DeleteOutlined />
                </div>
              </div>
            </>
          ),
          key: "0-0-0",
          children: [
            {
              title: "From Inspiration",
              key: "0-0-0-0",
            },
            {
              title: "From iTickets",
              key: "0-0-0-1",
            },
            {
              title: '"Best Christian Travels" September 2022',
              key: "0-0-0-2",
            },
          ],
        },
        {
          title: (
            <>
              <div className="inspiration_edit">
                <p>Event</p>
                <div>
                  <EditOutlined />
                </div>
                <div>
                  <DeleteOutlined />
                </div>
              </div>
            </>
          ),
          key: "0-0-1",
          children: [
            {
              title: "Ark Encounter Event",
              key: "0-0-1-0",
            },
          ],
        },
        {
          title: (
            <>
              <div className="inspiration_edit">
                <p>Inspiration Referral</p>
                <div>
                  <EditOutlined />
                </div>
                <div>
                  <DeleteOutlined />
                </div>
              </div>
            </>
          ),
          key: "0-0-2",
          children: [
            {
              title: "Inspiration Referral",
              key: "0-0-2-0",
            },
          ],
        },
      ],
    },
  ];
  return (
    <Tree
      defaultExpandAll
      className="marketing_tree"
      showLine
      switcherIcon={<DownOutlined />}
      defaultExpandedKeys={["0-0-0"]}
      treeData={treeData}
      defaultSelectedKeys={false}
      defaultExpandParent={true}
      defaultCheckedKeys={true}
    />
  );
};
export default ManageMarketingInspiration;
