import { Token, customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";

export const getTotalDashboardCountApi = (today) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/dashboard/event-and-reservation-count?today=${today}`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const getDashboardBarDataApi = ({ status, time }) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/dashboard/reservation-event/graph/${status}/${time}`,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};

export const getAvailabilityPieDataApi = ({ eventUuid, availabilityType }) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/event/availability/${eventUuid}/${availabilityType}`,
    customRequestHeader({ token: Token })
  );
};

export const getDemographicPieDataApi = ({ eventUuid, demographicType }) => {
  return drupalAxiosRequest(
    "get",
    `/api/rest/event/demographic/${eventUuid}/${demographicType}`,
    customRequestHeader({ token: Token })
  );
};
export const getProdEventsListApi = () => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/list/event/dashboard`,
    customRequestHeader({ token: Token })
  );
};
