
import { SettingOutlined, MailOutlined, CopyOutlined } from '@ant-design/icons';
import {
    Collapse, Select, Form,
    Input,
    Button,
    Radio,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Divider,
    Table,
} from 'antd';
import React, { useState } from 'react';
import Res_table_two from './Res_table_two';
import "./Res_editguest_form.less";
const { Panel } = Collapse;
const { Option } = Select;
const handleChange = (value) => {
    console.log(`selected ${value}`);
};
const Res_editguest_form = () => {
    const [expandIconPosition, setExpandIconPosition] = useState('start');
    const onPositionChange = (newExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
    };
    const onChange = (key) => {
        console.log(key);
    };



    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };



    return (
        <>
             <div className='Res_collapse_maindiv'>
                <Collapse
                    defaultActiveKey={['2']}
                    onChange={onChange}
                    expandIconPosition={expandIconPosition}
                >
                    <Panel header={<>
                        <Res_table_two/>
                    </>} >
                        <div className='res_form_main'>
                            <div className='res_form_text'
                            >
                                <h4>Operations</h4>

                            </div>
                            <Form
                                {...layout}
                                labelCol={{
                                    sm: 24,
                                    md: 6,
                                    lg: 6,
                                    xl: 5,
                                    xxl: 4,
                                }}
                                wrapperCol={{
                                    sm: 24,
                                    md: 14,
                                    lg: 14,
                                    xl: 14,
                                    xxl: 12,
                                }}
                                layout="horizontal"
                            >


                                <Form.Item
                                    label="Dining Time Assigned"
                                    name="Dining Time Assigned"

                                >
                                    <Select style={{ height: "40px" }}>
                                        <Select.Option value="Main Dining">
                                            Main Dining
                                        </Select.Option>
                                        <Select.Option value=" Early Dining">
                                       Early Dining
                                        </Select.Option>
                                        

                                    </Select>
                                </Form.Item>
                                <Form.Item label="Dining Room" name="Dining Room">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Charter Group"
                                    name="Charter Group"

                                >
                                    <Select style={{ height: "40px" }}>
                                        <Select.Option value=" Red">
                                           Red
                                        </Select.Option>
                                        <Select.Option value="Blue">
                                            Blue
                                        </Select.Option>
                                       

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Table Status"
                                    name="Table Status"

                                >
                                    <Select style={{ height: "40px" }}>
                                        <Select.Option value="Near window">
                                        Near window
                                        </Select.Option>
                                        <Select.Option value="Not a booth">
                                        Not a booth
                                        </Select.Option>
                                        <Select.Option value="Round table">
                                        Round table
                                        </Select.Option>
                                        <Select.Option value="Near the rail">
                                        Near the rail
                                        </Select.Option>
                                        <Select.Option value="Bus request">
                                        Bus request
                                        </Select.Option>
                                        <Select.Option value="Near Staff">
                                        Near Staff
                                        </Select.Option>
                                        <Select.Option value=" Near/With Families-children">
                                        Near/With Families-children
                                        </Select.Option>
                                        <Select.Option value="With Singles">
                                        With Singles
                                        </Select.Option>
                                        <Select.Option value="See Others Res Notes">
                                       See Others Res Notes
                                        </Select.Option>

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Bed Request"
                                    name="Bed Request"

                                >
                                    <Select style={{ height: "40px" }}>
                                        <Select.Option value="passenger">
                                          One Bed
                                        </Select.Option>
                                        <Select.Option value="waitlist">
                                           Two Beds
                                        </Select.Option>
                                        <Select.Option value="reservation">
                                            Three Beds
                                        </Select.Option>

                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                label={
                                    <div div className="text_break">
                                        <div>Indicate voice part</div>
                                        <div>
(for Choir Track selection)</div>

                                    </div>
                                }>
                                    <Select>
                                        <Select.Option value="Alto">
                                        Alto
                                        </Select.Option>
                                        <Select.Option value="Bass">
                                            Bass
                                        </Select.Option>
                                        <Select.Option value="Soprano">
                                        Soprano
                                        </Select.Option>
                                        <Select.Option value="Tenor">
                                        Tenor
                                        </Select.Option>

                                    </Select>
                                 
                                </Form.Item>
                                <Form.Item 
                                label={
                                    <div div className="text_break_second">
                                        <div>Provide Church Name,</div>
                                        <div>City & State</div>
                                        
                                        <div>
                                       (for Choir Track selection)</div>

                                    </div>
                                }>
                                      <Input />
                                    
                                </Form.Item>

                            </Form>
                            <div className='res_form_text'
                            >
                                <h4>Marketing</h4>

                            </div>
                            <Form
                                {...layout}
                                labelCol={{
                                    sm: 24,
                                    md: 6,
                                    lg: 6,
                                    xl: 5,
                                    xxl: 4,
                                }}
                                wrapperCol={{
                                    sm: 24,
                                    md: 14,
                                    lg: 14,
                                    xl: 14,
                                    xxl: 12,
                                }}
                                layout="horizontal"
                            >


                                <Form.Item label={
                                    <div div className="text_break">
                                        <div>Who did you hear about </div>
                                        <div>this
                                            event from?</div>

                                    </div>
                                }>
                                    <Select style={{ height: "40px" }}>
                                        <option value="Inspiration">Inspiration</option>
                                        <option value="IMC Concerts">IMC Concerts</option>
                                        <option value="Legacy Five">Legacy Five</option>
                                        <option value="Booth Brothers">Booth Brothers</option>
                                        <option value="Triumphant Quartet">Triumphant Quartet</option>
                                        <option value="Joseph Habedank">Joseph Habedank</option>
                                        <option value="TaRanda Greene">TaRanda Greene</option>
                                        <option value="Greater Vision">Greater Vision</option>
                                        <option value="The Hoppers">The Hoppers</option>
                                        <option value="Jim &amp; Melissa Brady">Jim &amp; Melissa Brady</option>
                                        <option value="Geron Davis &amp; Kindred Souls">Geron Davis &amp; Kindred Souls</option>

                                    </Select>
                                </Form.Item>


                                <Form.Item
                                    label="Code"
                                    name="Code"

                                >
                                    <Select style={{ height: "40px" }}>
                                        <option value="Concert">Concert</option>
                                        <option value="Event">Event</option>
                                        <option value="Email">Email</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Magazine/Catalog">Magazine/Catalog</option>
                                        <option value="Mailing">Mailing</option>
                                        <option value="Onboard Mention" >Onboard Mention</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Website">Website</option>
                                        <option value="Other">Other</option>
                                        <option value="Not sure">Not sure</option>

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Detail"
                                    name="Detail"

                                >
                                    <Select style={{ height: "40px" }}>
                                    <Select.Option value="Onboard Mention">
                                            Onboard Mention
                                        </Select.Option>

                                        

                                    </Select>

                                </Form.Item>
                            </Form>
                        </div>
                    </Panel>

                </Collapse>
            </div>



        </>
    );
};
export default Res_editguest_form;