import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import "./OrganizationBasicDetails.less";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const OrganizationBasicDetails = ({
  organizationDetailsData,
  orgId,
  isLoading,
}) => {
  const navigate = useNavigate();
  const {
    field_organization_name,
    field_organization_type,
    field_leader_name,
    field_organization_website,
  } = organizationDetailsData || {};

  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );

  const data = [
    {
      key: "1",
      label: "Organization Name",
      input: field_organization_name || "",
    },
    {
      key: "2",
      label: "Organization Type",
      input: field_organization_type?.label || "",
    },

    {
      key: "3",
      label: "Leader Name",
      input: field_leader_name || "",
    },
    {
      key: "10",
      label: "Website",
      input: field_organization_website || "",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Basic Details</h4>
        <span>
          {allowEditOrganizations ? (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/organization?editId=${orgId}&step=1`);
              }}
            />
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
      />
    </div>
  );
};

export default OrganizationBasicDetails;
