import { configureStore } from "@reduxjs/toolkit";
import authenticationReducers from "./features/authFeatures/authSlice";
import contactsReducers from "./features/contactFeatures/contactSlice";
import organizationsReducers from "./features/organizationFeatures/organizationSlice";
import manageRolesReducers from "./features/adminFeatures/manageRolesSlice";
import eventsSlice from "./features/eventFeatures/eventsSlice";
import addonsSlice from "./features/eventFeatures/addonsSlice";
import { customDropDownSlice } from "./features/customDropdownFeatures/customDropDownSlice";
import { usersSlice } from "./features/usersFeatures/usersSlice";
import groupDetailsSlice from "./features/groupDetailsFeatures/groupDetailsSlice";
import reservationSlice from "./features/reservationFeatures/reservationSlice";
import reportSlice from "./features/reportFeatures/reportSlice";

const store = configureStore({
  reducer: {
    authentication: authenticationReducers,
    contacts: contactsReducers,
    organizations: organizationsReducers,
    manageRoles: manageRolesReducers,
    events: eventsSlice,
    addons: addonsSlice,
    groupDetails: groupDetailsSlice,
    customDropDown: customDropDownSlice.reducer,
    users: usersSlice.reducer,
    reservation: reservationSlice,
    report: reportSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
