import { Table, Select, Form } from "antd";
import React, { useEffect } from "react";
import { addCommasInPricing } from "../../../../../../utils/contentParser";

function SchedulePlannedPaymetInfo({
  term,
  setTerm,
  form,
  reservationId,
  termFormData,
  formDataLoading,
}) {
  const columns = [
    {
      title: "Terms",
      dataIndex: "terms",
    },
    {
      title: "Party Name",
      dataIndex: "party_name",
    },
    {
      title: "Transfer Balance",
      dataIndex: "transfer_balance",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
    },
    {
      title: "Paid",
      dataIndex: "paid",
    },
  ];
  const data = [
    {
      key: "1",
      terms: (
        <>
          <div className="textarea_row">
            <Form layout="vertical" form={form}>
              <Form.Item
                layout="vertical"
                label="Terms"
                name="terms"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={Number(termFormData?.traveler_data?.total_amount) < 0}
                  onSelect={(e) => {
                    setTerm(e);
                    // form.resetFields();
                  }}
                  style={{
                    width: 230,
                  }}
                  options={[
                    {
                      value: "pay_in_full_immediately",
                      label: "Pay In Full immediately",
                    },
                    {
                      value: "pay_in_full_in_60_days",
                      label: "Pay In Full in 60 days",
                    },
                    {
                      value: "payment_plan",
                      label: "Payment Plan",
                    },
                    {
                      value: "monthly_payment_plan",
                      label: "Monthly Payment Plan",
                    },
                    {
                      value: "deposit_2nd_deposit_and_final_payment",
                      label: "Deposit, 2nd Deposit and Final Payment",
                    },
                    {
                      value: "deposit_and_final_payment",
                      label: "Deposit and Final Payment",
                    },
                    {
                      value: "quartely_payment_plan",
                      label: "Quarterly Payment Plan",
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
        </>
      ),
      party_name: (
        <>
          <p className="span_text">Party Name</p>
          <p>{termFormData?.traveler_data?.traveler_name || ""}</p>
        </>
      ),
      transfer_balance: (
        <>
          <p className="span_text">Transfer Balance</p>
          <p>
            {termFormData?.traveler_data?.transfer_balance
              ? `$${addCommasInPricing(
                  termFormData?.traveler_data?.transfer_balance?.toString()
                )}`
              : "$0"}
          </p>
        </>
      ),
      total_amount: (
        <>
          <p className="span_text">Total Amount</p>
          <p>
            {" "}
            {termFormData?.traveler_data?.total_amount
              ? `$${addCommasInPricing(
                  termFormData?.traveler_data?.total_amount?.toString()
                )}`
              : "$0"}
          </p>
        </>
      ),
      paid: (
        <>
          <p className="span_text">Paid</p>
          <p>
            {`$${addCommasInPricing(
              termFormData?.traveler_data?.paid?.toString() || "0"
            )}`}
          </p>
        </>
      ),
    },
  ];
  useEffect(() => {
    form.setFieldsValue({ terms: Number(termFormData?.traveler_data?.total_amount) < 0 ?  "pay_in_full_immediately":  term });
    setTerm(Number(termFormData?.traveler_data?.total_amount) < 0 ?  "pay_in_full_immediately":  term )
  }, [termFormData]);

  return (
    <>
      <Table
        className="schedule_table"
        columns={columns}
        dataSource={data}
        size="small"
        showHeader={false}
        pagination={false}
        loading={{ spinning: formDataLoading, size: "large" }}
      />
    </>
  );
}

export default SchedulePlannedPaymetInfo;
