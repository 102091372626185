import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  TimePicker,
  message,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  editActivityApi,
  getAllAssigneeApi,
  getAllEventsApi,
  getOrganizationListApi,
} from "../../../../apis/contactApi";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import { handleLoadingForActivity } from "../../../../redux/features/contactFeatures/contactSlice";
import { convertDateTimeToISO } from "../../../../utils/DateTimeFormater";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
  TIME_FORMAT,
} from "../../../../utils/constants";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import "../../ContactDetailsTabs/ActivitiesTab/AddActivitiesForms/OtherActivitiesCommonForm.less";

const EditActivityDetailForm = ({ activityDetails, form }) => {
  const { user_id } = activityDetails || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [editorInitData, setEditorInitData] = useState(
    activityDetails?.field_message?.value || ""
  );
  const [editor, setEditor] = useState(null);
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [orgsList, setOrgsList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [taskNames, setTaskNames] = useState([]);
  const [loadingTaskNames, setLoadingTaskNames] = useState(false);
  const [tagNames, setTagNames] = useState([]);
  const [loadingTagNames, setLoadingTagNames] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid } = authorization;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowViewContact = authData?.permissions?.includes("view contact");

  const { isLoading } = useSelector(
    (state) => state.contacts.createAndUpdateActivity
  );
  const activityId = searchParams.get("activityId");
  const contactId = searchParams.get("contactId");
  // const DATE_FORMAT = "MM/DD/YYYY";
  // const TIME_FORMAT = "hh:mm A";

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  useEffect(() => {
    getOrganizationList();
    getEventList();
    getTaxonomyList("activity_task_name", setTaskNames, setLoadingTaskNames);
    getTaxonomyList("activity_tags", setTagNames, setLoadingTagNames);
    getAssigneeList();
  }, []);

  function setDateTime(name, value) {
    form.setFieldValue(
      name,
      moment(value, name.includes("time") ? TIME_FORMAT : DATE_FORMAT)
    );
  }

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  const getOrganizationList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getOrganizationListApi({ reqHeaders });
    if (response && response.status) {
      setOrgsList(response?.data?.data);
    } else {
      setOrgsList([]);
      form.setFields([
        {
          name: "organization",
          errors: ["Unable to fetch organization data"],
        },
      ]);
    }
  };

  const getEventList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getAllEventsApi({ reqHeaders });
    if (response.status) {
      setEventList(response?.data?.data);
    } else {
      setEventList([]);
    }
  };

  const getAssigneeList = async () => {
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await getAllAssigneeApi({ reqHeaders });
    if (response.status) {
      setAssigneeList(response?.data?.data);
    } else {
      setAssigneeList([]);
    }
  };

  useEffect(() => {
    if (activityDetails?.id) {
      const {
        field_task_type,
        field_activity_task_name,
        field_description,
        field_organizations,
        field_event,
        field_contact,
        field_reservations,
        user_id,
        field_assignee_staff,
        field_due_time,
        field_start_time,
        field_end_time,
        field_activity_tags,
        field_email_body,
        field_message,
      } = activityDetails;
      form.setFieldsValue({
        task_name: field_activity_task_name?.id && field_activity_task_name.id,
        description: field_description,
        vendor: field_organizations?.id && field_organizations.id,
        product: field_event?.id && field_event.id,
        contact: field_contact?.id ? field_contact?.name : "",
        reservation: field_reservations?.id && field_reservations?.name,
        creator:
          user_id?.id && user_id?.field_first_name
            ? `${user_id?.field_first_name} ${user_id?.field_last_name}`
            : `${user_id?.name}`,
        assignee_staff: field_assignee_staff?.id && field_assignee_staff.id,
        due_date: field_due_time && moment(field_due_time),
        due_time: field_due_time && moment(field_due_time),
        start_date: field_start_time && moment(field_start_time),
        start_time: field_start_time && moment(field_start_time),
        end_date: field_end_time && moment(field_end_time),
        end_time: field_end_time && moment(field_end_time),
        task_status: activityDetails?.field_task_status,
        percent_done: activityDetails?.field_percentage_done,
        priority: activityDetails?.field_priority,
        tags: field_activity_tags?.length
          ? field_activity_tags?.map(({ id }) => id)
          : [],
        // editor_message: editor?.setData(
        //   field_message?.value || ""
        //   // field_task_type === "Email" && !field_message
        //   //   ? field_email_body?.value
        //   //   : field_message?.value || ""
        // ),
      });

      if (editorRef.current) {
        editorRef.current.editor.setData(activityDetails?.field_message?.value);
      }
    }
  }, [activityDetails]);

  const updateTaskActivity = async (payload) => {
    const updateRes = await editActivityApi({ payload, activityId });
    if (updateRes.status) {
      dispatch(handleLoadingForActivity(false));
      message.success("Edited Successfully");
      navigate(
        `/contact/details/activities?activityId=${activityId}&contactId=${contactId}`
      );
    } else {
      message.error(updateRes?.data?.message || SOMETHING_WENT_WRONG);
      dispatch(handleLoadingForActivity(false));
    }
  };

  const onFinish = (values) => {
    dispatch(handleLoadingForActivity(true));
    const isDueTimeValid = values["due_date"] && values["due_time"];
    const isStartTimeValid = values["start_date"] && values["start_time"];
    const isEndTimeValid = values["end_date"] && values["end_time"];
    const field_message = editorRef.current?.editor.getData();
    const payload = {
      data: {
        type: "activity--activity",
        id: activityId,
        attributes: {
          field_task_status: values["task_status"],
          field_description: values["description"],
          field_percentage_done: values["percent_done"],
          field_priority: values["priority"],
          field_message: field_message,
          // field_email_body:
          //   activityDetails?.field_task_type === "Email" &&
          //   values["editor_message"]?.editor.getData(),
          field_due_time:
            isDueTimeValid &&
            convertDateTimeToISO(values["due_date"], values["due_time"]),
          field_start_time:
            isStartTimeValid &&
            convertDateTimeToISO(values["start_date"], values["start_time"]),
          field_end_time:
            isEndTimeValid &&
            convertDateTimeToISO(values["end_date"], values["end_time"]),
        },
        relationships: {
          field_assignee_staff: {
            data: {
              type: "user--user",
              id: values["assignee_staff"],
            },
          },
          field_last_modified_by: {
            data: uuid && {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
          field_activity_task_name: {
            data: {
              type: "taxonomy_term--activity_task_name",
              id: values["task_name"],
            },
          },
          field_organizations: values["vendor"] && {
            data: {
              type: "node--organization",
              id: values["vendor"],
            },
          },
          field_event: values["product"] && {
            data: {
              type: "event--event",
              id: values["product"],
            },
          },
          field_activity_tags: {
            data: values["tags"]?.length
              ? values["tags"]?.map((item) => {
                  return { type: "taxonomy_term--activity_tags", id: item };
                })
              : [],
          },
        },
      },
    };
    updateTaskActivity(payload);
  };

  const { TextArea } = Input;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 className="mb-0">Task Details</h4>
        </div>
        <Divider className="global_divider mb-0" />

        <div style={{ marginTop: "24px" }} />
        <Form
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Task Type" name="Task Type">
                <label>
                  {!activityDetails?.id ? (
                    <Skeleton.Button
                      shape="round"
                      size="small"
                      style={{ width: "100px" }}
                    />
                  ) : (
                    `${activityDetails?.field_task_type?.label || ""}`
                  )}
                </label>
              </Form.Item>
              <Form.Item
                className="asterick-align"
                name="task_name"
                label=" Task Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={taskNames?.map((task) => ({
                    key: task?.id,
                    value: task?.id,
                    label: task?.name,
                  }))}
                  loading={loadingTaskNames}
                ></Select>
              </Form.Item>

              <div className="textarea_row">
                <Form.Item label="Description" name="description">
                  <TextArea
                    showCount
                    // allowClear
                    maxLength={500}
                    rows={4}
                  />
                </Form.Item>
              </div>

              <Form.Item name="vendor" label="Vendor">
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={orgsList?.map((org) => ({
                    key: org?.drupal_internal__nid,
                    value: org?.id,
                    label: org?.title,
                    nid: org?.drupal_internal__nid,
                  }))}
                ></Select>
              </Form.Item>
              <Form.Item name="product" label="Event">
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={eventList?.map((event) => ({
                    key: event?.drupal_internal__id,
                    value: event?.id,
                    label: event?.name,
                  }))}
                ></Select>
              </Form.Item>
              <Form.Item label="Contact" name="contact">
                <label>
                  {allowViewContact ? (
                    <a
                      href={`/contact/details?id=${activityDetails?.field_contact?.id}`}
                    >
                      {activityDetails?.field_contact?.name}
                    </a>
                  ) : (
                    <a>{activityDetails?.field_contact?.name}</a>
                  )}
                </label>
              </Form.Item>
              <Form.Item label="Reservation" name="reservation">
                <label>
                  <a>{form.getFieldValue("reservation") || ""}</a>
                </label>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Creator" name="creator">
                <label>
                  {!activityDetails?.id ? (
                    <Skeleton.Button
                      shape="round"
                      size="small"
                      style={{ width: "100px" }}
                    />
                  ) : user_id?.id && user_id?.field_first_name ? (
                    `${user_id?.field_first_name} ${user_id?.field_last_name}`
                  ) : (
                    `${user_id?.name}`
                  )}
                </label>
              </Form.Item>
              <Form.Item
                className="asterick-align assignee-staff"
                name="assignee_staff"
                label=" Assignee Staff"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={assigneeList?.map((aName) => ({
                    key: aName?.id,
                    value: aName?.id,
                    label: aName?.display_name,
                  }))}
                ></Select>
              </Form.Item>

              <div className="form_col_align">
                <Form.Item label="Due Time" name="due_date">
                  <DatePicker
                    value={form.getFieldValue("d")}
                    format={DATE_FORMAT}
                    onChange={(value, newValue) => {
                      setDateTime("due_date", newValue);
                    }}
                  />
                </Form.Item>

                <div className="form_label">
                  <Form.Item name="due_time">
                    <TimePicker
                      use12Hours
                      format={TIME_FORMAT}
                      onChange={(value, newValue) => {
                        setDateTime("due_time", newValue);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form_col_align">
                <Form.Item label="Start Time" name="start_date">
                  <DatePicker
                    format={DATE_FORMAT}
                    onChange={(value, newValue) => {
                      setDateTime("start_date", newValue);
                    }}
                  />
                </Form.Item>
                <div className="form_label">
                  <Form.Item name="start_time">
                    <TimePicker
                      use12Hours
                      format={TIME_FORMAT}
                      onChange={(value, newValue) => {
                        setDateTime("start_time", newValue);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form_col_align">
                <Form.Item label="End Time" name="end_date">
                  <DatePicker
                    format={DATE_FORMAT}
                    onChange={(value, newValue) => {
                      setDateTime("end_date", newValue);
                    }}
                  />
                </Form.Item>
                <div className="form_label">
                  <Form.Item name="end_time">
                    <TimePicker
                      use12Hours
                      format={TIME_FORMAT}
                      onChange={(value, newValue) => {
                        setDateTime("end_time", newValue);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item
                className="asterick-align"
                name="task_status"
                label=" Task Status"
                rules={[
                  {
                    required: true,
                    message: "Please select task status",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  <option value="New">New</option>
                  <option value="Closed">Closed</option>
                  <option value="Rework">Rework</option>
                  <option value="Returned">Returned</option>
                  <option value="Started">Started</option>
                </Select>
              </Form.Item>
              <Form.Item label="Percent Done" name="percent_done">
                <Input
                  type="number"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      // evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown"
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="priority" label=" Priority">
                <Select placeholder=""
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </Select>
              </Form.Item>
              <Form.Item label="Tags" name="tags">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Any Tag(s)"
                  // optionLabelProp="label"
                  options={tagNames?.map((tag) => ({
                    key: tag?.id,
                    value: tag?.id,
                    label: tag?.name,
                  }))}
                  loading={loadingTagNames}
                />
              </Form.Item>
              {/* </Form> */}
            </Col>
          </Row>
          <div className="background_div"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "24px",
            }}
          >
            <h4>Message Content</h4>
          </div>
          <Divider className="global_divider mb-0" />

          <div style={{ marginTop: "24px" }}></div>

          <div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="textarea_row">
                  <Form.Item
                    className="ckeitor-field-wrapper"
                    id="editor"
                    name="editor_message"
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      ref={editorRef}
                      config={editorConfig}
                      data={editorInitData}
                      onReady={(editor) => {
                        setEditorInstance(editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorInitData(data);
                        form.setFieldsValue({ editor_message: data });
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};
export default EditActivityDetailForm;
