import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
import { useRef, useState } from "react";
import {
  fileUploadProcessingApi,
  processQueueApi,
  uploadDocumentApi,
} from "../../apis/contactApi";
import { Cookie, customRequestHeader } from "../../utils/cutomRequestHeader";
import drupalConfig from "../../config/serverless/drupal.config";
import Dragger from "antd/lib/upload/Dragger";
import DataLoader from "../Loaders/DataLoader";
import { linkMediaToLibraryApi } from "../../apis/organisationApi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { importContactTrigger } from "../../redux/features/contactFeatures/contactSlice";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const ImportDraggerUpload = ({
  fileList,
  setFileList,
  isLoading,
  setIsLoading,
  uploadUrl,
  fileProcessingUrl,
  entityType,
  onClose,
  setErrorList,
  setIsModalOpen,
  setOpen,
}) => {
  const { importContact } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState(0);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const uploadFunction = async (payload, fileType) => {
    setIsLoading(true);
    const url = uploadUrl;
    const reqHeaders = customRequestHeader({
      token: Token,
      isUploadReq: true,
      fileType,
    });
    const response = await uploadDocumentApi({ url, reqHeaders, payload });
    // console.log("res", response);
    if (response.status) {
      uploadFileDataProcessing(response?.data?.data);
      //   setIsLoading(false);
      //   message.success(`${response.data.data.filename} Uploaded Successfully`);
    } else {
      setIsLoading(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const uploadFileDataProcessing = async (uploadResponse) => {
    const payload = {
      file_path: uploadResponse?.uri?.value,
      entity_type: entityType,
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: true,
    });
    const response = await fileUploadProcessingApi({
      url: fileProcessingUrl,
      reqHeaders,
      payload,
    });
    if (response?.data?.success) {
      console.log({ response });
      processUploadedEntries(response?.data);
      setIsModalOpen(false);
      setOpen(false);
    } else {
      const errorMessage = [response?.data?.message || response?.data?.response?.data?.message];
      if (response?.data?.error?.length) {
        const errors = response?.data?.error;
        errors.map((error) => {
          errorMessage.push(error);
        });
      }
      setErrorList(errorMessage);
      setFileList([]);
      setIsLoading(false);
    }
  };

  //   const processUploadedEntries= async(entries)=>{
  //     if(entries?.success){
  //      if(entries?.item_ids?.length){
  //         entries?.item_ids?.forEach(id => {
  //             const response = await processQueueApi();
  //         });

  //      }
  //     }
  //   };

  const processUploadedEntries = async (data) => {
    let payload = data?.item_ids || [];
    const fetchData = async (chunk) => {
      try {
        const response = await processQueueApi({
          type: "bulk_import_contact",
          item_id: chunk[0],
        });
        return response;
      } catch (error) {
        console.error("Error While Importing Contact:", error);
        throw error; // Rethrow the error to be caught at the higher level
      }
    };

    const chunkSize = 1;
    const totalItems = payload.length;
    let startIndex = 0;

    const hitApiRecursively = async () => {
      const endIndex = Math.min(startIndex + chunkSize, totalItems);
      const currentChunk = payload.slice(startIndex, endIndex);
      if (startIndex >= totalItems) {
        setIsLoading(false);
        onClose();
        setPercentage(0);
        message.success("Contacts Imported SuccessFully");
        dispatch(importContactTrigger(!importContact));
        // setSearchListResult([]);
        // setCurrentSearchTerm({ technology: "", size: "", locations: "" });
        // form.resetFields();
        // setNameOfResultList("");
        return;
      }

      try {
        const response = await fetchData(currentChunk);
        if (response?.status) {
          startIndex = endIndex;
          setPercentage((startIndex / totalItems) * 100);
          await new Promise((resolve) => setTimeout(resolve, 500));
          await hitApiRecursively();
        } else {
          setIsLoading(false);
          message.error("An Error Occurred While Processing The Data");
        }
      } catch (error) {
        setIsLoading(false);
        message.error("An Error Occurred While Processing The Data");
      }
    };

    await hitApiRecursively();
  };

  const onChangeHandler = (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = function () {
        const base64String = reader.result.split(",")[1]; // extract base64 string from data URL
        const binaryArray = getBinaryArrayFromBase64String(base64String);
        console.log({ binaryArray });
        uploadFunction(binaryArray, info.file.name);
      };
      reader.onerror = function (error) {
        message.error("Failed To Upload File");
      };

      //   const reader = new FileReader();
      //   reader.readAsArrayBuffer(info.file.originFileObj);
      //   reader.onload = () => {
      //     const binaryData = new Uint8Array(reader.result);
      //     const binaryString = new TextDecoder().decode(binaryData);
      //     uploadFunction(binaryString);
      //   };
      //   reader.onerror = () => {
      //     message.error("Failed to upload image");
      //   };
    }
  };

  const getBinaryArrayFromBase64String = (base64String) => {
    const binaryString = atob(base64String);
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    return binaryArray;
  };

  console.log({ percentage });
  const beforeUpload = (file) => {
    const isXLSX =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXLSX) {
      message.error("You Can Only Upload .xlsx File");
    }
    return isXLSX;
  };
  return (
    <Dragger
      multiple={false}
      customRequest={() => {}}
      listType="picture"
      fileList={fileList}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={onChangeHandler}
      accept=".xlsx"
    >
      <>
        <p className="ant-upload-drag-icon">
          {isLoading ? (
            <>
              <DataLoader />
              <h3>{`${percentage}%`}</h3>
            </>
          ) : (
            <InboxOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Supported file types: .xlsx</p>
        <p className="ant-upload-hint"> Max file size: 100 MB.</p>
      </>
    </Dragger>
  );
};
export default ImportDraggerUpload;
