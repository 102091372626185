import { Divider, Table } from "antd";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const DiscountUsage = ({ loadingDiscountDetailData, usedCount }) => {
  const data = [
    {
      key: "1",
      questions: "Uses",
      answers: usedCount, //used count pending from backend
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>Discount Usage</h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
        loading={{
          spinning: loadingDiscountDetailData,
          indicator: <DataLoader />,
        }}
      />
    </div>
  );
};
export default DiscountUsage;
