import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Grid, message } from "antd";
import React, { useEffect, useState } from "react";
import GroupDetailSearchBar from "../../components/GroupDetails/SearchGroupDetail/SearchGroupDetail";
import ToursDetailsTabs from "../../components/GroupDetails/GroupDetailDetails/CruiseOrToursDetail/TourDetailsTabs/CruiseorTourDetailsTabs";
import { useNavigate, useSearchParams } from "react-router-dom";
import CruiseOrTourDetailHeader from "../../components/GroupDetails/GroupDetailDetails/GroupDetailHeader/CruiseOrTourDetailHeader";
import {
  deleteGroupDetailApi,
  getSingleGroupDetailApi,
} from "../../apis/groupDetailsApi";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { GroupDetailsListingRoute } from "../../url-routes/urlRoutes";
import CruiseorTourDetailsTabs from "../../components/GroupDetails/GroupDetailDetails/CruiseOrToursDetail/TourDetailsTabs/CruiseorTourDetailsTabs";
import { updateRecentlyViewed } from "../../utils/recentlyViewedUpdater";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header } = Layout;

const CruiseOrTourDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [allDetails, setAllDetails] = useState({});
  const [allDetailsLoading, setAllDetailsLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  let type = searchParams.get("type");
  const id = searchParams.get("id");
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const breadCrumbs = [
    { title: "Group Details", path: GroupDetailsListingRoute },
    { title: "Details", path: "" },
  ];

  useEffect(() => {
    const isAllowedPage = permissionChecker("view group details");
    if (!isAllowedPage) {
      navigate("/page-not-access");
    }
    fetchGroupDetail(id);
  }, []);

  const fetchGroupDetail = async (id) => {
    const res = await getSingleGroupDetailApi(id);
    if (res.status) {
      setAllDetails(res?.data?.data);
      const { type, langcode, drupal_internal__id, id } = res?.data?.data;
      updateRecentlyViewed(
        type,
        id,
        langcode,
        drupal_internal__id,
        "group_detail"
      );
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setAllDetailsLoading(false);
  };

  const deleteGroupDetail = async (deleteId) => {
    const response = await deleteGroupDetailApi(deleteId);
    if (response.status) {
      message.success("Deleted Successfully");
      setIsDeleted(!isDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  type = capitalizeAndRemoveHyphens(type);
  function capitalizeAndRemoveHyphens(input) {
    switch (input) {
      case "charter-cruise":
        return "Charter Cruise";
      case "cruise-tour":
        return "Cruise/Tour";
      case "tour":
        return "Tour";
      case "cruise":
        return "Cruise";
      default:
        return input
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
    }
  }

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <GroupDetailSearchBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          {/* <Breadcrumb className="edit_breadcrumb"></Breadcrumb> */}
          <div className="events-header">
            <CruiseOrTourDetailHeader
              deleteGroupDetail={deleteGroupDetail}
              allDetails={allDetails}
              allDetailsLoading={allDetailsLoading}
            />
          </div>
          <CruiseorTourDetailsTabs
            type={type}
            id={id}
            allDetails={allDetails}
            allDetailsLoading={allDetailsLoading}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CruiseOrTourDetailPage;
