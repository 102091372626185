export const decodeContent = (value) => {
  if (!value) return value;
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, "text/html");
  return doc.documentElement.textContent;
};

export function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export function addCommasInPricing(input) {
  if (!input || typeof input !== "string") {
    return "";
  }
  const parts = String(input).split(".");
  let wholeNumber = parts[0];
  const decimalPart = parts[1] || "";

  // Add commas to whole number
  wholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const result = decimalPart ? wholeNumber + "." + decimalPart : wholeNumber;
  return result;
}

export function urlParser(searchQuery) {
  const params = {};
  const urlParams = new URLSearchParams(searchQuery);
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

export function toSnakeCase(str) {
  if (typeof str === "string" && !str?.length) return "";
  return str?.toLowerCase()?.replace(/ /g, "_");
}

export function capitalizeFirstLetter(str) {
  if (typeof str === "string" && !str?.length) return "";
  let firstLetter = str?.charAt(0).toUpperCase();
  let restString = str?.slice(1);
  return firstLetter + restString;
  // return str?.toLowerCase()?.replace(/ /g, "_");
}
