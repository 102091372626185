import { Table, Button } from "antd";
import ExpandSelect_drawer from "../../Reservations_components/ReservationItem_components/ExpandSelect_drawer";
import React, { useEffect, useState } from "react";
import { getItemInventory } from "../../../apis/reservationApi";

function AddOnExpandable(props) {
  const { cat, setSection, handleClick } = props;
  const [categoryData, setCategoryData] = useState();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
  ];

  useEffect(() => {
    if (cat && cat.length) {
      let catData = [];
      cat.forEach((val, index) => {
        let values = {
          key: index,
          name: (
            <>
              {/* <p className='paragraph_margin'>LUAU</p> */}
              <a href="#">{val?.code}</a>
              <p className="document_pdf">{val?.cat_name}</p>
              <p className="span_text">{val?.description}</p>
            </>
          ),
          price: (
            <>
              <span className="document_pdf">
                {val?.price && `$${val.price}`}
              </span>
            </>
          ),
          address: (
            <>
              <Button onClick={() => handleClick(val)}>Select</Button>
            </>
          ),
        };
        catData.push(values);
      });
      setCategoryData(catData);
    }
  }, [cat]);
  return (
    <>
      <Table
        columns={columns}
        dataSource={categoryData}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </>
  );
}

export default AddOnExpandable;
