import { Table, Divider, Select, Form, Modal, Input, Button, DatePicker, TimePicker } from 'antd';
import { MailOutlined, PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Reservation_activityaddfile from '../../Forms/Reservation_activityaddfile/Reservation_taskactivity';
import { Link } from "react-router-dom";

import React, { useState } from 'react';

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Date/Time',
    dataIndex: 'date_time',
    render: (record) => {
      return <>
        <div>
          {/* <Link to="/Inventoryview_page"> */}
          <span className=''>11/15/2022 12:15 PM</span>
          {/* </Link> */}
          <p>{record}</p>
        </div>
      </>
    }
  },
  // {
  //   title: 'Duration',
  //   dataIndex: 'duration',
  // },
  {
    title: 'Staff Member',
    dataIndex: 'staff_member',
  },

  {
    title: 'Description',
    dataIndex: 'description',
  },

];

const Task_activity = () => {


  const data = [
    {
      key: '1',
      type: 'Execute',
      date_time: <>
        <span className='span_text'>17 days ago</span>
      </>,
      duration: '00:00:02',
      staff_member: 'System',
      description: '',
    },

  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const { TextArea } = Input;

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div>
      <div className='requests_title'>
        <h4 className='mb-0'>Task Activity</h4>

        <span>
          <Button type="primary" onClick={showModal}><PlusOutlined /> Add</Button>

          <Modal
            footer={
              <>
                {" "}
                <Link to="">
                  <Button type="primary" >Save</Button>
                </Link>
              </>
            }
          
          title={<><span className='span_bold'>Add Activity</span></>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

            <Form{...layout}
              labelCol={{
                sm: 24, md: 6, lg: 6, xl: 6, xxl: 6,
              }}
              wrapperCol={{
                sm: 24, md: 14, lg: 14, xl: 16, xxl: 16,
              }}
              layout="horizontal"
            >Task Activity


              <Form.Item
                label="Activity Type"
                name="activity_type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >

                <Select>
                  <Select.Option value="apostle">Comment</Select.Option>
                  <Select.Option value="bishop">Update</Select.Option>
                  <Select.Option value="dr">Status Change</Select.Option>
                  <Select.Option value="miss">Execute</Select.Option>
                </Select>

              </Form.Item>

              <Form.Item
                label="Activity Time"
                name="activity_time"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div className='end_time task_name'>
                  <DatePicker onChange={onChange} style={{ width: '46%' }} />

                  <TimePicker use12Hours format="h:mm a" onChange={onChange}
                    style={{ width: '47%' }}
                  />
                </div>
              </Form.Item>

              <div className="textarea_row">
                <Form.Item
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >

                  <TextArea rows={4} />

                </Form.Item>
              </div>
            </Form>


          </Modal>
        </span>
      </div>
      <Divider style={{ marginTop: '0' }} />
      <Table columns={columns} dataSource={data} size="middle" pagination={false} />

    </div>
  )
};
export default Task_activity;