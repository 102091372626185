import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Grid, Layout, Skeleton, message } from "antd";
import Reservationtableviewedit_header from "../../../components/Breadcrumb/Reservations_header/Reservationtableviewedit_header";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import Guestdetailsedit_table from "../../../components/View_components/Reservations/Reservationtable_components/Guestdetailsedit_table";
import Itemdetailsedit_form from "../../../components/View_components/Reservations/Reservationtable_components/Itemdetailsedit_form";
import Paymentresponsibility_edit from "../../../components/View_components/Reservations/Reservationtable_components/Paymentresponsibility_edit";

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getReservationItemDetailApi } from "../../../apis/reservationApi";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  ReservationDetailRoute,
  ReservationItemDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Reservationtableedit_page() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get("id");
  const [data, setData] = useState();
  const itemId = searchParams.get("item-id");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [paymentRespTotalAmount, setPaymentRespTotalAmount] = useState({isUpdated: false,totalAmount:0});

  const fetchReservationItem = async () => {
    const response = await getReservationItemDetailApi(itemId);
    if (response && response.status) {
      setData(response?.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReservationItem();
    setLoading(true);
  }, []);

  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    {
      title: "Details",
      path: `${ReservationDetailRoute}?id=${paramsId}`,
    },
    {
      title: "Reservation Item",
      path: `${ReservationItemDetailRoute}?id=${paramsId}&item-id=${itemId}`,
    },

    { title: "Edit Reservation Item", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="events-header">
                <Reservationtableviewedit_header form={form} data={data} />
              </div>
              <Content
                className="site-layout-background"
                style={{ marginBottom: "20px" }}
              >
                <Itemdetailsedit_form data={data} form={form} />
              </Content>

              <Content
                className="site-layout-background"
                style={{ marginBottom: "20px" }}
              >
                <Guestdetailsedit_table
                  setPaymentRespTotalAmount={setPaymentRespTotalAmount}
                  paymentRespTotalAmount={paymentRespTotalAmount}
                  data={data}
                  form={form}
                  itemId={itemId}
                />
              </Content>

              <Content
                className="site-layout-background"
                style={{ marginBottom: "20px" }}
              >
                <Paymentresponsibility_edit
                  data={data?.payment_responsibility}
                  traveler_details_total_sum={data?.traveler_details_total_sum}
                  paymentRespTotalAmount={paymentRespTotalAmount}
                  form={form}
                  itemId={itemId}
                  paramsId={paramsId}
                  reservationItem={data?.reservation_item}
                  inventroy={data?.reservation_item_inventory}
                />
              </Content>
            </>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Reservationtableedit_page;
