import { Form, Grid, Layout } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CommonTopBar from "../../../../components/CommonTopBar/CommonTopBar";
import AddonsHeader from "../../../../components/Events/EventDetailsTabs/AddonsTab/CreateOrEditAddons/AddonsHeader";
import AddonsSteps from "../../../../components/Events/EventDetailsTabs/AddonsTab/CreateOrEditAddons/AddonsSteps";
const { Header, Content, Sider } = Layout;

function CreateOrEditAddonsPage({}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const breadCrumbs = [
    { title: "Events", path: "/events/listing" },
    { title: "Details", path: `/event/details?id=${eventId}#Add-Ons` },
    { title: "Add-Ons", path: `/event/details?id=${eventId}#Add-Ons` },
    { title: "Add Add-Ons", path: "" },
  ];
  const createId = searchParams.get("creationId");
  const editId = searchParams.get("editId");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <AddonsHeader
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
              eventId={eventId}
            />
          </div>
          <Content>
            <AddonsSteps
              form1={form1}
              form2={form2}
              form3={form3}
              form4={form4}
              createId={createId}
              editId={editId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default CreateOrEditAddonsPage;
