import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Select, Switch, Divider } from "antd";
import { decodeContent } from "../../../../../../../utils/contentParser";

const { TextArea } = Input;

function InventoryItemInfoForm({
  onFinishHandler,
  form,
  deckNumber,
  loadingDeckNumber,
  category,
  loadingCategory,
}) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [searchParamas, setSearchParams] = useSearchParams();

  const categoryId = searchParamas.get("categoryId");
  const [disableShare, setDisableShare] = useState(true);
  const [disableMaxShare, setDisableMaxShare] = useState(true);

  useEffect(() => {
    if (
      form?.getFieldValue("Occupancy") &&
      form?.getFieldValue("Occupancy") >= 2
    ) {
      setDisableShare(false);
    }
    if (form?.getFieldValue("Share")) {
      setDisableMaxShare(false);
    }
  }, [form.getFieldValue("Occupancy"), form.getFieldValue("Share")]);

  const validateOccupancy = (e, value) => {
    if (e.field === "Occupancy") {
      const maxOcp = value;
      const minOcp = form.getFieldValue("MinOccupancy");
      if (maxOcp && minOcp) {
        if (maxOcp >= minOcp) {
          form.setFields([
            {
              name: "MinOccupancy",
              errors: [],
            },
          ]);
        } else {
          if (maxOcp < minOcp) {
            form.setFields([
              {
                name: "MinOccupancy",
                errors: ["Max Occupancy cannot be less than Min Occupancy"],
              },
            ]);
          }
        }
      }
      //start = maxOct, end = minOct
    } else {
      const minOcp = value;
      const maxOcp = form.getFieldValue("Occupancy");
      if (maxOcp && minOcp) {
        if (maxOcp < minOcp) {
          return Promise.reject(
            "Max Occupancy cannot be less than Min Occupancy"
          );
        }
      }
    }
    return Promise.resolve();
  };

  const validateNumberGreaterThanZero = (rule, value, callback) => {
    if (value && value <= 0) {
      callback("Min Occupancy must be greater than 0");
    } else {
      callback();
    }
  };

  const validateShare = (rule, value) => {
    if (Number(value) < 2) {
      setDisableShare(true);
      setDisableMaxShare(true);
      form.setFieldsValue({ Share: false, MaxShares: null });
    } else {
      setDisableShare(false);
    }
    return Promise.resolve();
  };

  const validateMaxShare = (rule, value) => {
    if (value) {
      setDisableMaxShare(false);
    } else {
      setDisableMaxShare(true);
      form.setFieldsValue({ MaxShares: null });
    }
    return Promise.resolve();
  };

  const validateMaxShareWithOccupancy = (rule, value, callback) => {
    if (value && value < 2) {
      callback("Max Shares Value Cannot Be Less Than 2");
    } else {
      if (value && value > (form.getFieldValue("Occupancy") || 2)) {
        callback("Max Shares Cannot Be Greater Than Maximum Occupancy");
      } else {
        callback();
      }
    }
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>Inventory Item Information</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 3,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
        onFinish={onFinishHandler}
        form={form}
      >
        <Form.Item
          label="Category"
          initialValue={categoryId}
          name="Category"
          rules={[
            {
              required: true,
              message: "Please Select Category",
            },
          ]}
        >
          <Select
            disabled
            value={categoryId}
            loading={loadingCategory}
            options={
              category?.length
                ? category?.map((cat) => ({
                    key: cat?.uuid,
                    value: cat?.uuid,
                    label: decodeContent(cat?.name),
                  }))
                : []
            }
          ></Select>
        </Form.Item>
        <Form.Item label="Deck Number" name="deckNumber">
          <Select
            allowClear
            loading={loadingDeckNumber}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={
              deckNumber?.length
                ? deckNumber?.map((deckN) => ({
                    key: deckN?.id,
                    value: deckN?.id,
                    label: deckN?.name,
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Item Code"
          name="ItemCode"
          rules={[
            {
              required: true,
              message: "Please Enter Number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Item Name"
          name="ItemName"
          rules={[
            {
              required: true,
              message: "Please Enter Name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Quantity"
          name="Quantity"
          rules={[
            {
              required: true,
              message: "Please Enter Quantity",
            },
          ]}
        >
          <Input
            type="number"
            min={0}
            autoComplete="nope"
            pattern="/^[0-9\b]+$/"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "-" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown"
              ) {
                evt.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Max Occupancy"
          name="Occupancy"
          rules={[
            { required: true, message: "Max Occupancy Is required" },
            {
              validator: validateOccupancy,
              dependencies: ["MinOccupancy"], // Trigger validation when startDate changes
            },
            { validator: validateShare },
          ]}
        >
          <Input
            type="number"
            min={0}
            autoComplete="nope"
            pattern="/^[0-9\b]+$/"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "-" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown"
              ) {
                evt.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="asterick-align"
          label="Min Occupancy"
          name="MinOccupancy"
          initialValue={"1"}
          rules={[
            { required: true, message: "Min Occupancy Is required" },
            {
              validator: validateNumberGreaterThanZero,
            },
            {
              validator: validateOccupancy,
              dependencies: ["Occupancy"], // Trigger validation when startDate changes
            },
          ]}
        >
          <Input
            type="number"
            min={0}
            autoComplete="nope"
            pattern="/^[0-9\b]+$/"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "-" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown"
              ) {
                evt.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="swith_form"
          label="Share"
          name="Share"
          valuePropName="checked"
          rules={[{ validator: validateMaxShare }]}
        >
          <Switch disabled={disableShare} />
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="Max Shares"
          name="MaxShares"
          rules={[
            {
              required: !!form.getFieldValue("Share"),
              message: "Max Share Is Required If Share Toggle Is On",
            },
            { validator: validateMaxShareWithOccupancy },
          ]}
        >
          <Input
            disabled={disableMaxShare}
            type="number"
            min={2}
            autoComplete="nope"
            pattern="/^[0-9\b]+$/"
            onKeyDown={(evt) => {
              if (
                evt.key === "e" ||
                evt.key === "-" ||
                evt.key === "+" ||
                evt.key === "." ||
                evt.key === "ArrowUp" ||
                evt.key === "ArrowDown"
              ) {
                evt.preventDefault();
              }
            }}
          />
        </Form.Item>
        <div className="textarea_row">
          <Form.Item label="Item Description" name="field_item_description">
            <TextArea rows={6} />
          </Form.Item>
        </div>
      </Form>
    </div>
    //   )}
    // </>
  );
}

export default InventoryItemInfoForm;
