import { DatePicker, message } from "antd";
import React, { useEffect, useState } from "react";

import {
  CloseOutlined,
  FilterOutlined,
  LeftOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Row,
  Select,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "../../others/Debounce/debounce";
import { statusList } from "../../others/util/commonFunctions";
import {
  reportSearchFilterApplied,
  reportSearchFiltersSetter,
} from "../../redux/features/reportFeatures/reportSlice";
import {
  ReportListingRoute,
  ViewReportRoute,
} from "../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { decodeContent } from "../../utils/contentParser";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
import Sidebar from "../Header/Header_sidebar";
import {
  getAllReportListApi,
  getReportTagsListApi,
} from "../../apis/reportApi";
import reportConfig from "../../config/report.config";
const { useBreakpoint } = Grid;

function ReportsSearchBar({ urlParamsObject, setUrlParamsObject }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeClass, setActiveClass] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(urlParamsObject?.name);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { isReportFilterApplied } = useSelector((state) => state.report);
  const [reportTagsList, setReportTagsList] = useState([]);
  const [loadingTagsList, setLoadingTagsList] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getReportTagsList();
    form.setFieldsValue({
      report_tag: urlParamsObject?.report_tag
        ? Number(urlParamsObject?.report_tag)
        : null,
    });
  }, []);

  useEffect(() => {
    if (urlParamsObject?.report_tag) {
      dispatch(reportSearchFilterApplied(true));
      dispatch(
        reportSearchFiltersSetter({
          Tag: urlParamsObject?.report_tag
            ? reportTagsList?.find(
                (tag) => tag.id == urlParamsObject?.report_tag
              )?.name
            : null,
        })
      );
    } else {
      dispatch(reportSearchFilterApplied(false));
      dispatch(reportSearchFiltersSetter({}));
    }
  }, [reportTagsList]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      let url = `items_per_page=10&sort_by=report_name&report_name=${debouncedSearchTerm}`;
      getReservationSearchResult(url, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
    }
  }, [debouncedSearchTerm]);

  const getReportTagsList = async () => {
    setLoadingTagsList(true);
    const res = await getReportTagsListApi();
    if (res.status) {
      setReportTagsList(res?.data?.rows);
      setLoadingTagsList(false);
    } else {
      setReportTagsList([]);
      setLoadingTagsList(false);
    }
  };
  const getReservationSearchResult = async (urlQuery, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }

    let response = await getAllReportListApi({ urlQuery });
    if (response?.status) {
      let searchOptions = response?.data?.rows?.slice(0, 5)?.map((option) => {
        return {
          key: option?.uuid,
          value: (
            <span
              style={{ width: "100%", display: "block" }}
              onClick={() => {
                navigate(
                  `${ViewReportRoute}?view_link=${reportConfig?.BASE_URL}${option?.birtLink}`
                );
                setAutoCompleteOpen(false);
              }}
            >
              {decodeContent(option?.reportName)}
            </span>
          ),
        };
      });
      if (searchOptions.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== ReportListingRoute
                    ? navigate(
                        `${ReportListingRoute}?sort_by=report_name&name=${searchVal}`
                      )
                    : setUrlParamsObject({
                        sort_by: "report_name",
                        name: searchVal || "",
                      });
                  setAutoCompleteOpen(false);
                }}
              >
                see all results for {searchVal || ""}
              </span>
            ),
          },
        ]);
        setAutoCompleteOpen(true);
      } else {
        setOptions([]);
        setAutoCompleteOpen(true);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setAutoCompleteOpen(false);
    }
  };

  function isOnListingRoute() {
    return location.pathname !== ReportListingRoute ? false : true;
  }
  const onFinish = (values) => {
    const urlObject = { ...values };

    for (let key in urlObject) {
      if (!urlObject[key]) {
        delete urlObject?.[key];
      }
    }
    const result = new URLSearchParams(urlObject).toString();
    if (!isOnListingRoute()) {
      navigate(`${ReportListingRoute}?sort_by=report_name&${result}`);
    } else {
      setUrlParamsObject({
        sort_by:
          urlParamsObject?.sort_by === "recent_read"
            ? "report_name"
            : urlParamsObject?.sort_by,
        ...urlObject,
      });
    }
    setAutoCompleteOpen(false);
    dispatch(reportSearchFilterApplied(urlObject?.report_tag ? true : false));
    dispatch(
      reportSearchFiltersSetter({
        Tag: urlObject?.report_tag
          ? reportTagsList?.find((tag) => tag.id == urlObject?.report_tag)?.name
          : null,
      })
    );
    setIsVisible(false);
    setActiveClass(false);
  };

  const custumSearch = (
    <div className="custom_search">
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        <Form.Item
          // className="select-reservation_searchbox"
          name="report_tag"
          wrapperCol={{ span: 8 }}
        >
          <Select
            placeholder="Report Tag"
            options={reportTagsList.map((listItem) => ({
              key: listItem.uuid,
              value: listItem.id,
              label: listItem.name,
            }))}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
            onClick={() => {
              form.resetFields();
              if (isOnListingRoute()) {
                setUrlParamsObject({
                  sort_by: urlParamsObject?.sort_by,
                });
              }
              setSearchValue("");
              // setIsVisible(false);
              dispatch(reportSearchFilterApplied(false));
              dispatch(reportSearchFiltersSetter({}));
            }}
          >
            Clear Filter
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );

  const handleSearch = (value) => {
    if (!value) {
      setAutoCompleteOpen(false);
      location.pathname !== ReportListingRoute
        ? setSearchValue("")
        : setUrlParamsObject({ ...urlParamsObject, name: null });
      // return;
    }
    setSearchValue(value);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Search toggle for mobile
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align">
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              onClear={(e) => {
                setAutoCompleteOpen(false);
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!isOnListingRoute()) {
                    navigate(
                      `${ReportListingRoute}?sort_by=report_name&name=${e.target.value}`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      sort_by:
                        urlParamsObject?.sort_by === "recent_read"
                          ? "report_name"
                          : urlParamsObject?.sort_by,
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                    // setIsFilterApplied(true);
                  }
                }
              }}
              open={autoCompleteOpen}
              placeholder="Search Reports"
              size="large"
              value={
                urlParamsObject?.sort_by === "recent_read" ? "" : searchValue
              }
            />

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              open={isVisible}
              trigger={["click"]}
              onOpenChange={(e) => {
                setActiveClass(e);
                setIsVisible(e);
              }}
              // className="advance-search-dropdown"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{
                      opacity: "0.5",
                      color: "#000",
                      backgroundColor: isReportFilterApplied
                        ? "rgba(26, 127, 147, 0.2)"
                        : "transparent",
                      //   backgroundColor: "#000",
                    }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default ReportsSearchBar;
