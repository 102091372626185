import { Form, Grid, Layout } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import CommonTopBar from "../../../../../components/CommonTopBar/CommonTopBar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import CreateOrEditItineraryHeader from "../../../../../components/Events/EventDetailsTabs/ItinerariesTab/CreateOrEditItinerary/CreateOrEditItineraryHeader";
import CreateOrEditItineraryContainer from "../../../../../components/Events/EventDetailsTabs/ItinerariesTab/CreateOrEditItinerary/CreateOrEditItineraryContainer";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
const { Header, Content } = Layout;

const AddonAddOrEditItineraryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Details",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: "Add/Edit Itineraries", path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CommonTopBar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CreateOrEditItineraryHeader
              form={form}
              isLoading={isLoading}
              itineraryType={"addon"}
              addonId={addonId}
            />
          </div>
          <Content>
            <CreateOrEditItineraryContainer
              eventId={eventId}
              form={form}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              itineraryType={"addon"}
              addonId={addonId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonAddOrEditItineraryPage;
