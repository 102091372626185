import { Table, Button, Divider, Image, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const OrganizationCompanyInfo = ({ organizationDetailsData, orgId }) => {
  const navigate = useNavigate();
  const {
    field_commission_rate,
    field_deduct_commission,
    field_ein,
    field_iata_arc_clia,
    field_tax_form,
  } = organizationDetailsData;
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditOrganizations = authData?.permissions?.includes(
    "add/edit organization"
  );
  const data = [
    {
      key: "1",
      label: "EIN",
      input: field_ein || "",
    },
    {
      key: "2",
      label: "Tax Form",
      input: field_tax_form?.meta?.media_data?.uri ? (
        <a href={field_tax_form?.meta?.media_data?.uri} target="_blank">
          {field_tax_form?.meta?.media_data?.filename}
        </a>
      ) : (
        ""
      ),
    },
    {
      key: "3",
      label: "IATA/ARC/CLIA",
      input: field_iata_arc_clia || "",
    },
    {
      key: "4",
      label: "Commission Rate",
      input: field_commission_rate ? `${field_commission_rate}%` : "",
    },

    {
      key: "5",
      label: "Deduct Commission",
      input: field_deduct_commission !== null && (
        <Switch checked={field_deduct_commission} disabled />
      ),
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Company Information</h4>
        <span>
          {allowEditOrganizations ? (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/organization?editId=${orgId}&step=4`);
              }}
            />
          ) : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default OrganizationCompanyInfo;
