import { Divider, Table } from "antd";
import moment from "moment";
import React from "react";
import { DATE_FORMAT, TIME_FORMAT } from "../../utils/constants";
import DataLoader from "../Loaders/DataLoader";
import { useLocation } from "react-router-dom";
const columns = [
  {
    title: "Questions",
    dataIndex: "questions",
  },

  {
    title: "Answers",
    dataIndex: "answers",
  },
];

const SystemInformation = ({
  user_id,
  modified_by,
  created,
  changed,
  loading,
  reservationData,
}) => {
  const location = useLocation();
  const shouldRender = location.pathname.startsWith("/reservation");
  const data = [
    {
      key: "1",
      questions: "Created By",
      answers: (
        <>
          {shouldRender ? (
            <>
              <p className="paragraph_marg">
                {reservationData?.created_on?.key === "staffweb"
                  ? user_id?.field_first_name + " " + user_id?.field_last_name
                  : reservationData?.contact_created_by?.name ||
                    "Primary Traveler"}
              </p>
            </>
          ) : (
            <>
              <p className="paragraph_marg">
                {user_id?.field_first_name || user_id?.field_last_name
                  ? user_id?.field_first_name + " " + user_id?.field_last_name
                  : user_id?.display_name || " "}
              </p>
            </>
          )}
          <p className="paragraph_marg">
            {created ? moment(created).format(DATE_FORMAT) : ""}{" "}
            {created ? moment(created).format(TIME_FORMAT) : ""}{" "}
            <span className="span_text">
              {created ? moment(created).from(moment()) : ""}
            </span>
          </p>
        </>
      ),
    },

    {
      key: "2",
      questions: "Last Modified By",
      answers: (
        <>
          {shouldRender ? (
            <>
              {" "}
              <p className="paragraph_marg">
                {reservationData?.modified_on?.key === "staffweb"
                  ? reservationData?.field_last_modified_by?.display_name
                  : reservationData?.contact_modify_by?.name ||
                    "Primary Traveler"}
              </p>
            </>
          ) : (
            <>
              <p className="paragraph_marg">
                {modified_by?.field_first_name || modified_by?.field_last_name
                  ? modified_by?.field_first_name +
                    " " +
                    modified_by?.field_last_name
                  : modified_by?.display_name || " "}
              </p>
            </>
          )}
          <p className="paragraph_marg">
            {changed ? moment(changed).format(DATE_FORMAT) : ""}{" "}
            {changed ? moment(changed).format(TIME_FORMAT) : ""}{" "}
            <span className="span_text">
              {changed ? moment(changed).from(moment()) : ""}
            </span>
          </p>
        </>
      ),
    },
  ];

  if (reservationData?.type === "reservation--reservation") {
    data.push({
      key: "3",
      questions: "Created On",
      answers: reservationData?.created_on?.label || "",
    });
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'", margin: "0" }}>
          System Information
        </h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default SystemInformation;
