import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Radio } from "antd";
import { Dropdown, Menu, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

const AssociateHeader = ({ roleNames }) => {
  const navigate = useNavigate();
  const navigateToManageRoles = () => {
    navigate("/manage-roles");
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            onClick={navigateToManageRoles}
            className="back-icon-profile"
          />
          <div className="header_res">
            <span>{roleNames?.name}</span>
          </div>
        </h3>

        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Link to={`/manage-roles/edit?role=${roleNames.machineName}`}>
            {" "}
            <Button>
              <EditOutlined /> Edit Role
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AssociateHeader;
