import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CreateOrEditItineraryHeader = ({ form, isLoading }) => {
  const navigate = useNavigate();

  const onSaveHandler = () => {
    form.submit();
  };
  const onCancelHandler = () => {
    navigate(-1);
  };
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>
              <ArrowLeftOutlined
                style={{ paddingRight: "6px" }}
                onClick={() => {
                  navigate(-1);
                }}
              />{" "}
              Add Itineraries
            </p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={onCancelHandler}>Cancel</Button>
          <Button type="primary" onClick={onSaveHandler} loading={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CreateOrEditItineraryHeader;
