import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEventApi,
  getEventPartnersApi,
  getEventSuppliersApi,
  getSingleEventApi,
} from "../../../apis/eventsApi";

export const createOrEditAddon = createAsyncThunk(
  "events/createOrEditAddon",
  async (data) => {
    const response = await createEventApi(data);
    return response;
  }
);

export const getSingleAddon = createAsyncThunk(
  "events/getSingleAddon",
  async (uuid, { rejectWithValue }) => {
    const response = await getSingleEventApi(uuid);
    return response;
  }
);
export const getAddonPartnersList = createAsyncThunk(
  "events/getAddonPartnersList",
  async () => {
    const response = await getEventPartnersApi();
    return response;
  }
);
export const getAddonSuppliersList = createAsyncThunk(
  "events/getAddonSuppliersList",
  async () => {
    const response = await getEventSuppliersApi();
    return response;
  }
);

export const addonsSlice = createSlice({
  name: "addons",
  initialState: {
    addonCreation: {
      addonCreationResponse: {},
      loadingAddonCreation: false,
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      pressedNextButton: false,
      addonStep: 0,
    },
    singleAddon: {
      singleAddonResponse: {},
      loadingSingleAddon: false,
    },
    allAddons: {
      allAddonsResponse: {},
      loadingAllAddons: false,
    },
    addonPartners: {
      addonPartnersResponse: {},
      loadingAddonPartners: false,
    },
    addonSuppliers: {
      addonSuppliersResponse: {},
      loadingAddonSuppliers: false,
    },
  },
  reducers: {
    updateAddonStep: (state, action) => {
      switch (action.payload) {
        case "1":
          state.addonCreation.step1 = "done";
          break;
        case "2":
          state.addonCreation.step2 = "done";
          break;
        case "3":
          state.addonCreation.step3 = "done";
          break;
        case "4":
          state.addonCreation.step4 = "done";
          break;
        default:
          state.addonCreation.step1 = "";
          state.addonCreation.step2 = "";
          state.addonCreation.step3 = "";
          state.addonCreation.step4 = "";
      }
    },
    pressAddonNext: (state, action) => {
      action.payload === "next-button"
        ? (state.addonCreation.pressedNextButton = true)
        : (state.addonCreation.pressedNextButton = false);
    },
    addonStepSetter: (state, action) => {
      state.addonCreation.addonStep = action.payload;
    },
    changedAddonsStatus: (state, action) => {
      state.isEventStatusChanged = !state.isEventStatusChanged;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createOrEditAddon.pending, (state) => {
      state.addonCreation.loadingAddonCreation = true;
    });
    builder.addCase(createOrEditAddon.fulfilled, (state, action) => {
      state.addonCreation.addonCreationResponse = action.payload;
      state.addonCreation.loadingAddonCreation = false;
    });
    builder.addCase(createOrEditAddon.rejected, (state, action) => {
      state.addonCreation.loadingAddonCreation = false;
    });

    builder.addCase(getSingleAddon.pending, (state) => {
      state.singleAddon.loadingSingleAddon = true;
    });
    builder.addCase(getSingleAddon.fulfilled, (state, action) => {
      state.singleAddon.singleAddonResponse = action.payload;
      state.singleAddon.loadingSingleAddon = false;
    });
    builder.addCase(getSingleAddon.rejected, (state, action) => {
      state.singleAddon.singleAddonResponse = action.payload;
      state.singleAddon.loadingSingleAddon = false;
    });
    //event partners list
    builder.addCase(getAddonPartnersList.pending, (state) => {
      state.addonPartners.loadingAddonPartners = true;
    });
    builder.addCase(getAddonPartnersList.fulfilled, (state, action) => {
      state.addonPartners.addonPartnersResponse = action.payload;
      state.addonPartners.loadingAddonPartners = false;
    });
    //event partners list
    builder.addCase(getAddonSuppliersList.pending, (state) => {
      state.addonSuppliers.loadingAddonSuppliers = true;
    });
    builder.addCase(getAddonSuppliersList.fulfilled, (state, action) => {
      state.addonSuppliers.loadingAddonSuppliers = false;
      state.addonSuppliers.addonSuppliersResponse = action.payload;
    });
  },
});
export const { updateAddonStep, pressAddonNext, addonStepSetter } =
  addonsSlice.actions;
export default addonsSlice.reducer;
