import { Col, Row, Select, Spin, message } from "antd";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import {
  getDemographicPieDataApi,
  getProdEventsListApi,
} from "../../apis/dashboardApi";
import { SOMETHING_WENT_WRONG, uniqueColors } from "../../utils/constants";
import { decodeContent } from "../../utils/contentParser";

const { Option } = Select;
ChartJS.register(ArcElement, Tooltip, Legend);
function generateRandomHexColor(index) {
  const colorIndex = index % uniqueColors.length;
  return uniqueColors[colorIndex];
}
const DemographicsPie = ({ setLoadingDashboard }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [prodEventList, setProdEventList] = useState([]);
  const [prodEventLoading, setProdEventLoading] = useState(false);
  const demographEvent = searchParams.get("demographic_event");
  const demographType = searchParams.get("demographic_type");
  const [demographicEvent, setDemographicEvent] = useState(
    demographEvent || null
  );
  const [demographicType, setDemographicType] = useState(
    demographType || "age"
  );
  const [pieData, setPieData] = useState({ labels: [], datasets: [] });
  const [loadingPieData, setLoadingPieData] = useState(false);

  useEffect(() => {
    getProdEventList();
  }, []);

  useEffect(() => {
    setDemographicEvent(demographEvent || prodEventList[0]?.uuid);
  }, [prodEventList]);

  useEffect(() => {
    if (demographicEvent) {
      getDemographicPieData({
        eventUuid: demographicEvent,
        demographicType,
      });
    }
  }, [demographicEvent, demographicType]);

  const getProdEventList = async () => {
    setProdEventLoading(true);
    const response = await getProdEventsListApi();
    if (response.status) {
       const updateRes = response?.data.map((val)=>{
        val.name = decodeContent(val.name)
        return val
      })
      setProdEventList(updateRes);
      setProdEventLoading(false);
    } else {
      setProdEventList([]);
      setProdEventLoading(false);
    }
  };

  const getDemographicPieData = async ({ eventUuid, demographicType }) => {
    setLoadingPieData(true);
    const response = await getDemographicPieDataApi({
      eventUuid,
      demographicType,
    });
    if (response.status) {
      setPieData({
        labels:
          response?.data?.data?.length &&
          response?.data?.data?.some((item) => item.value > 0)
            ? response?.data?.data?.map((item) => item.type)
            : ["No Data"],
        datasets: [
          {
            label: "#",
            data:
              response?.data?.data?.length &&
              response?.data?.data?.some((item) => item.value > 0)
                ? response?.data?.data?.map((item) => item.value)
                : ["1"],
            backgroundColor:
              response?.data?.data?.length &&
              response?.data?.data?.some((item) => item.value > 0)
                ? response?.data?.data?.map((item, index) =>
                    generateRandomHexColor(index)
                  )
                : ["rgba(128,128,128,0.5)"],
            borderColor:
              response?.data?.data?.length &&
              response?.data?.data?.some((item) => item.value > 0)
                ? response?.data?.data?.map((item, index) =>
                    generateRandomHexColor(index)
                  )
                : ["rgba(128,128,128)"],
            borderWidth: 1,
          },
        ],
      });
      setLoadingPieData(false);
    } else {
      setPieData([]);
      setLoadingPieData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    hoverOffset: 20,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce((sum, value) => sum + value, 0);
          const value = dataset.data[tooltipItem.index];
          const percentage = ((value / total) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
        },
      },
    },
    plugins: {
      tooltip: {
        enabled:
          pieData?.datasets?.length === 0 || pieData?.labels[0] === "No Data"
            ? false
            : true,
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          label: (context, hv) => {
            if (context.label === "No Data") {
              return;
            }
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              let totalDataCount = context.dataset.data.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );
              label += `${context.parsed} (${(
                (context.parsed / totalDataCount) *
                100
              ).toFixed(2)}%)`;
            }
            return label;
          },
        },
      },

      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 40,
        right: 40,
      },
    },
  };

  return (
    <Spin spinning={loadingPieData} size="large">
      <Row style={{ marginBottom: "20px" }} gutter={16}>
        <Col span={16}>
          <Select
            style={{ width: "100%" }}
            value={demographicEvent || null}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              setDemographicEvent(e);
              searchParams.set("demographic_event", e);
              setSearchParams(searchParams);
            }}
            options={prodEventList?.map((listItem) => ({
              key: listItem.uuid,
              value: listItem.uuid,
              label: listItem.name,
            }))}
            loading={prodEventLoading}
          />
        </Col>
        <Col span={8}>
          <Select
            style={{ width: "100%" }}
            value={demographicType}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              setDemographicType(e);
              searchParams.set("demographic_type", e);
              setSearchParams(searchParams);
            }}
          >
            <Option value="age" label="Age">Age</Option>
            <Option value="gender" label="Gender">Gender</Option>
            <Option value="state" label="State">State</Option>
            <Option value="country" label="Country">Country</Option>
          </Select>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2em",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70%", height: "80%" }}>
          <Doughnut data={pieData} options={options} />
        </div>
        <div
          style={{ maxHeight: "300px", width: "25%", overflow: "auto" }}
          className="events_reservation"
        >
          {pieData?.datasets?.[0]?.backgroundColor?.map((dataset, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  backgroundColor:
                    pieData?.datasets?.[0]?.backgroundColor?.[index],
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <span>{pieData?.labels[index]}</span>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default DemographicsPie;
