import axios from "axios";
// import  from "../config/serverless/serverless.config";
import drupalConfig from "../config/serverless/drupal.config";

// const BASE_URL = `https://dev-ict-booking.oslabs.app`;

export const drupalNoAuthAxiosRequest = async (
    reqMethod,
    reqUrl,
    data,
    customHeaders
) => {
    let result;
    try {
        if (reqMethod === "post" || reqMethod === "patch") {
            result = await axios({
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": customHeaders?.contentType
                        ? customHeaders.contentType
                        : "application/vnd.api+json",
                },
                method: reqMethod,
                url: `${drupalConfig.BASE_URL}${reqUrl}`,
                data,
            });
        } else if (reqMethod === "delete") {
            result = await axios({
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: reqMethod,
                url: `${drupalConfig.BASE_URL}${reqUrl}`,
                data,
            });
        } else {
            result = await axios({
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": "application/vnd.api+json",
                },
                method: reqMethod,
                url: `${drupalConfig.BASE_URL}${reqUrl}`,
            });
        }
        console.log("result=================", result);
        return { data: result.data, status: true };
    } catch (error) {
        console.log("error", error);
        return { data: error, status: false };
    }
};
