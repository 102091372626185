import React from "react";

import { Collapse, Switch } from "antd";
import { Form, Input, Select, Divider } from "antd";

function InventoryRoomInfoForm({
  onFinishHandler,
  form,
  view,
  loadingView,
  bed,
  loadingBed,
  extraBed,
  loadingExtraBed,
  location,
  loadingLocation,
  loadingSingleInventory,
}) {
  const { Panel } = Collapse;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>Room Information</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        {...layout}
        labelCol={{
          sm: 24,
          md: 6,
          lg: 6,
          xl: 3,
          xxl: 4,
        }}
        wrapperCol={{
          sm: 24,
          md: 14,
          lg: 14,
          xl: 14,
          xxl: 12,
        }}
        layout="horizontal"
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item label="Location" name="Location">
          <Select
            allowClear
            loading={loadingLocation}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={
              location?.length
                ? location?.map((loc) => ({
                    key: loc?.id,
                    value: loc?.id,
                    label: loc?.name,
                  }))
                : []
            }
          />
        </Form.Item>

        <Form.Item label="Location 2" name="Location2">
          <Input />
        </Form.Item>

        <Form.Item label="View" name="View">
          <Select
            allowClear
            loading={loadingView}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={
              view?.length
                ? view?.map((view) => ({
                    key: view?.id,
                    value: view?.id,
                    label: view?.name,
                  }))
                : []
            }
          />
        </Form.Item>

        <Form.Item label="Balcony" name="Balcony" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Bed" name="Bed">
          <Select
            allowClear
            loading={loadingBed}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={
              bed?.length
                ? bed?.map((bed) => ({
                    key: bed?.id,
                    value: bed?.id,
                    label: bed?.name,
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item label="Extra Bed" name="field_extra_bed">
          <Select
            allowClear
            loading={loadingExtraBed}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={
              extraBed?.length
                ? extraBed?.map((extraB) => ({
                    key: extraB?.id,
                    value: extraB?.id,
                    label: extraB?.name,
                  }))
                : []
            }
          />
        </Form.Item>

        <Collapse className="addnotes_collapse">
          <Panel
            header={
              <>
                <span className="additional_panel">Additional Details</span>
              </>
            }
            key="1"
          >
            <Form.Item label="Connects" name="Connects">
              <Input />
            </Form.Item>
            <Form.Item label="Deck" name="Deck">
              <Input />
            </Form.Item>

            <Form.Item
              label="Accessible"
              name="Accessible"
              // initialValue={""}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Organization Category Code"
              name="VendorCategoryCode"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Smoking"
              name="Smoking"
              // initialValue={""}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item label="Age" name="Age">
              <Input
                type="number"
                min={0}
                autoComplete="nope"
                pattern="/^[0-9\b]+$/"
                onKeyDown={(evt) => {
                  if (
                    evt.key === "e" ||
                    evt.key === "-" ||
                    evt.key === "+" ||
                    evt.key === "." ||
                    evt.key === "ArrowUp" ||
                    evt.key === "ArrowDown"
                  ) {
                    evt.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item label="Type" name="Type">
              <Input />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </div>
    //   )}
    // </>
  );
}
// </>

//   )
// }

export default InventoryRoomInfoForm;
