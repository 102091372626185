import React from "react";

const CustomDropdownHeader = () => {
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <span>Manage Custom Dropdown</span>
          </div>
        </h3>
      </div>
    </div>
  );
};

export default CustomDropdownHeader;
