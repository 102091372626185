import { contactAxiosRequest } from "../utils/contactDrupalAxios";
import { customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

const getAllUsersApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user/user?filter[name][value]=&filter[name][operator]=CONTAINS&include=roles&page%5Blimit%5D=10&page%5Boffset%5D=0&sort=name`,
    customRequestHeader({ token: Token })
  );
};

const getSingleUserApi = async (userId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user/user/${userId}`,
    customRequestHeader({ token: Token })
  );
};

const createUserApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/user/user`,
    customRequestHeader({ token: Token }),
    data
  );
};

const updateUserApi = async (data, userId) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/user/user/${userId}`,
    customRequestHeader({ token: Token }),
    {
      data,
    }
  );
};

const deleteUserApi = async (data, userId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/user/user/${userId}`,
    customRequestHeader({ token: Token }),
    {
      data,
    }
  );
};

const searchUserApi = async (data, limit, offset) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user/user?filter[name][value]=${data}&filter[name][operator]=CONTAINS&include=roles&page%5Blimit%5D=${limit}&page%5Boffset%5D=${offset}&sort=name`,
    customRequestHeader({ token: Token })
  );
};
const getUserRolesApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user_role/user_role`,
    customRequestHeader({ token: Token })
  );
};

const userAssociatedWithRolesApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/user/user?filter[roles.meta.drupal_internal__target_id]=${data}`,
    customRequestHeader({ token: Token })
  );
};

const updateRecentlyViewedApi = async (data, reqHeader) => {
  return await contactAxiosRequest(
    "post",
    `/api/rest/recently_read`,
    reqHeader,
    data
  );
};

export {
  getAllUsersApi,
  getSingleUserApi,
  createUserApi,
  updateUserApi,
  deleteUserApi,
  searchUserApi,
  getUserRolesApi,
  userAssociatedWithRolesApi,
  updateRecentlyViewedApi,
};
