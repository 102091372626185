import React, { useEffect, useState } from "react";
import {
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import {
  getTaxonomyList,
  getTourOperatorList,
} from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";

const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const TourPrePostDatesAndItineryForm = ({
  form,
  onFinishHandler,
  isLoading,
}) => {
  const [destinationList, setDestinationList] = useState([]);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [itineraryList, setItineraryList] = useState([]);
  const [loadingItinerary, setLoadingItinerary] = useState(false);
  const [tourOperatorList, setTourOperatorList] = useState([]);
  const [loadingTourOperator, setLoadingTourOperator] = useState(false);
  useEffect(() => {
    getTaxonomyList("destination", setDestinationList, setLoadingDestination);
    getTaxonomyList(
      "extension_itinerary",
      setItineraryList,
      setLoadingItinerary
    );
    getTourOperatorList(setTourOperatorList, setLoadingTourOperator);
  }, []);

  console.log({ tourOperatorList });
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Dates & Itinerary</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Extension Destination"
            name="field_extension_destination"
          >
            <Select
              placeholder="Extension Destination"
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={destinationList?.map((destination) => ({
                key: destination.id,
                value: destination.id,
                label: destination.name,
              }))}
              loading={loadingDestination}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Itinerary" name="field_extension_itinerary">
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={itineraryList?.map((itinerary) => ({
                key: itinerary.id,
                value: itinerary.id,
                label: itinerary.name,
              }))}
              loading={loadingItinerary}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Requested Dates" name="field_requested_dates">
            <RangePicker
              style={{
                fontFamily: "'Poppins', sans-serif",
                height: "32px",
              }}
              format={"MM/DD/YYYY"}
            />
          </Form.Item>

          <Form.Item
            label="Are Dates Flexible?"
            name="field_are_dates_flexible"
          >
            <Radio.Group
            //   onChange={onChange}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Length of Extension"
            name="field_length_of_extension"
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Tour Operator" name="field_tour_operator">
            <Select
              showSearch
              placeholder="Tour Operator"
              options={tourOperatorList?.map((tourOperator) => ({
                key: tourOperator?.nid,
                value: tourOperator?.uuid,
                label: tourOperator?.title,
              }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingTourOperator}
              allowClear
            />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
export default TourPrePostDatesAndItineryForm;
