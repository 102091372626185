import { Button } from "antd";
// import "./Breadcrumb_email.less";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function TravellerEmailHeader({ form, sendLoading, emailData }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <h3 className="flex_row_column">
          <div className="header_res">
            {emailData?.partyName && emailData?.emailType?.value
              ? `Send ${emailData?.emailType?.value} Email To ${emailData?.partyName}`
              : null}
            <Link className="header_smalltext"></Link>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{ display: "flex", gap: "10px" }}
        >
          <Button onClick={(e) => navigate(-1)}>Cancel</Button>
          <Button
            type="primary"
            onClick={(e) => form.submit()}
            loading={sendLoading}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TravellerEmailHeader;
