import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getEventStatusApi,
  updateEventStatusApi,
} from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { changedEventStatus } from "../../../../../redux/features/eventFeatures/eventsSlice";
import { EventDetailsAddonsRoute } from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },

  {
    title: "Input",
    dataIndex: "input",
  },
];

const AddonSystemOptionInfo = ({ addonData, eventId, addonId, isLoading }) => {
  const [fieldStatus, setFieldStatus] = useState([]);
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("Change Status");
  const [loading, setLoading] = useState(false);
  const [changedFieldStatus, setChangedFieldStatus] = useState([]);
  const [isUnarchive, setIsUnarchive] = useState(false);
  // const authData = JSON.parse(localStorage.getItem("authorization"));
  const [iswebEnabled, setIsWebEnabled] = useState(null);
  const navigate = useNavigate();

  const handeleWebEnabledChange = () => {
    if (!addonData?.field_status?.label === "Cancel") {
      setIsWebEnabled(addonData?.field_web_enabled);
    } else {
      setIsWebEnabled(null);
    }
  };

  const getEventStatus = async () => {
    const response = await getEventStatusApi(addonId);

    if (response?.status) {
      if (
        Object.keys(response?.data?.data?.field_is_archive || {}).includes(
          "none"
        )
      ) {
        setFieldStatus([response?.data?.data?.event_status]);
      } else {
        setFieldStatus([response?.data?.data?.field_is_archive]);
        setIsUnarchive(true);
      }
    } else {
      setFieldStatus([]);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const handelSelectChange = (value) => {
    const payload = {
      data: {
        type: "event--event",
        id: addonId,
        attributes: {},
      },
    };
    if (isUnarchive) {
      payload.data.attributes.field_is_archive = value;
    } else {
      payload.data.attributes.field_status = value;
    }

    setLoading(true);
    setSelectedValue("Change Status");
    updateStatus(addonId, payload);
  };
  const updateStatus = async (id, payload) => {
    const response = await updateEventStatusApi(id, payload);
    if (response?.status) {
      setLoading(false);
      dispatch(changedEventStatus(!loading));
      message.success("Status Updated Successfully");
      setChangedFieldStatus(response);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventStatus();
  }, [changedFieldStatus, addonId, eventId]);

  const generateFieldStatus = () => {
    if (
      addonData?.field_is_archive !== "none" &&
      addonData?.field_is_archive === "archive"
    ) {
      return "Archived";
    }
    if (addonData?.field_status) {
      return addonData?.field_status?.label === "Lock"
        ? "Locked"
        : addonData?.field_status?.label === "Cancel"
        ? "Canceled"
        : addonData?.field_status?.label === "Complete"
        ? "Completed"
        : addonData?.field_status?.label || "";
    }
  };
  const data = [
    {
      key: "status",
      label: "Status",
      input: generateFieldStatus(),
    },

    {
      key: "web-enabled",
      label: "Web Enabled",
      input: (
        <Switch
          disabled
          checked={addonData?.field_web_enabled || false}
          // checked={iswebEnabled}
          // onChange={handeleWebEnabledChange}
        />
      ),
    },
    {
      key: "web-hide-item-code",
      label: "Web Hide Item Code",
      input: <Switch disabled checked={addonData?.field_web_hide_item_code} />,
    },
    {
      key: "web-brochure",
      label: "Web Brochure",
      input: <Switch disabled checked={addonData?.field_web_brochure} />,
    },

    // {
    //   key: "allow-no-primary-item",
    //   label: "Allow No Primary Item",
    //   input: (
    //     <Switch disabled checked={addonData?.field_allow_no_primary_item} />
    //   ),
    // },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          System Options/Settings
        </h4>

        <div className="breadcrumb_buttons">
          <Select
            value={selectedValue}
            style={{
              width: 160,
            }}
            onChange={handelSelectChange}
            loading={loading}
            options={
              fieldStatus?.length
                ? fieldStatus
                    ?.map((val) => {
                      const arr = [];
                      for (let i in val) {
                        arr.push({
                          value: i,
                          label: i
                            ? `${i.charAt(0)?.toUpperCase()}${i.slice(1)}`
                            : "",
                        });
                      }
                      return arr;
                    })
                    .flat(2)
                : []
            }
          />
          <span>
            {permissionChecker("add/edit addon") ? <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(
                  `${EventDetailsAddonsRoute}?id=${eventId}&editId=${addonId}&step=2`
                );
              }}
            /> : null}
          </span>
        </div>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="system_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </div>
  );
};

export default AddonSystemOptionInfo;
