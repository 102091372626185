import React from "react";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  Description,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
} from "antd";
import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
import Sidebar from "./Header_sidebar";
import BasicProfileControl from "../BasicProfileControl/BasicProfileControl";
const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const weekFormat = "MM/DD";
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
// const customWeekStartEndFormat = (value) =>
//   `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value)
//     .endOf('week')
//     .format(weekFormat)}`;

const dateFormat = "YYYY/MM/DD";

const { RangePicker } = DatePicker;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};

const onSearch = (value) => console.log(value);

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#000", fontSize: "14px", lineHeight: "28px" }}
              >
                {category}
              </a>
            </span>
          </div>
        ),
      };
    });

function SearchDashboard_menu() {
  const [searchText, setSearchText] = useState("");
  const [activeClass, setActiveClass] = useState(false);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              // href="#"
            >
              Change Password
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.antgroup.com"
            >
              Sign Out
            </a>
          ),
        },
        // {
        //   key: '3',
        //   label: (
        //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        //       Send Feedback
        //     </a>
        //   ),
        // },
      ]}
    />
  );

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const custumSearch = (
  //   <div className="custom_search">
  //     <Form
  //       labelCol={{
  //         span: 6,
  //       }}
  //       wrapperCol={{
  //         lg: 24,
  //         xs: 14,
  //       }}
  //       layout="horizontal"
  //       style={{ fontFamily: "'Poppins', sans-serif" }}
  //     >

  //       <Form.Item label="Reservation">
  //         <div className='end_time'>
  //           <Input type="number"
  //             style={{ width: '46%' }}
  //           />

  //           <Select
  //             style={{ width: '47%', marginLeft: '20px', }}
  //             placeholder="Status"
  //           >
  //             <Select.Option value="0">Temporary</Select.Option>
  //             <Select.Option value="1">Pending</Select.Option>
  //             <Select.Option value="2">Expired</Select.Option>
  //             <Select.Option value="3">Discarded</Select.Option>
  //             <Select.Option value="4">TemporaryHold</Select.Option>
  //             <Select.Option value="5">Active</Select.Option>
  //             <Select.Option value="6">Revise</Select.Option>
  //             <Select.Option value="7">Complete</Select.Option>
  //             <Select.Option value="8">ReviseComplete</Select.Option>
  //             <Select.Option value="9">CancelPending</Select.Option>
  //             <Select.Option value="10">CancelApproved</Select.Option>
  //             <Select.Option value="11">Canceled</Select.Option>

  //           </Select>
  //         </div>
  //       </Form.Item>

  //       <Form.Item
  //         label="Email"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Input
  //           // placeholder="Enter Organization"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         />
  //       </Form.Item>

  //       <Form.Item
  //         label="Guest Name"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Input
  //           // placeholder="Enter Organization"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         />
  //       </Form.Item>

  //       <Form.Item
  //         label="Created On"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >

  //         <RangePicker
  //           // defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px", height: '32px' }}
  //           format={dateFormat}
  //         />

  //       </Form.Item>

  //       <Form.Item
  //         label="Modified On"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >

  //         <RangePicker
  //           // defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px", height: '32px' }}
  //           format={dateFormat}
  //         />

  //       </Form.Item>

  //       <Form.Item
  //         label="Product"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Select
  //           // placeholder="Select Destination Type"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         >
  //           <Select.Option value="31716">In His Steps 2023: A Holy Land Tour with Jimmy Evans and Ed Young (31716)</Select.Option>
  //           <Select.Option value="31554">Gaither Homecoming 2023 Caribbean Cruise (31554)</Select.Option>
  //           <Select.Option value="31317">Experience Israel 2023 with Tony Evans (31317)</Select.Option>
  //           <Select.Option value="31426">Stand with Israel 2023 Israel Tour with Dennis Prager &amp; Mike Gallagher (31426)</Select.Option>
  //           <Select.Option value="31523">The Museum of the Bible 2023 Israel Tour (31523)</Select.Option>
  //           <Select.Option value="31382">Reston Bible 2023 Israel Tour (31382)</Select.Option>
  //           <Select.Option value="31542">3trees Church 2023 Israel Tour  (31542)</Select.Option>
  //           <Select.Option value="31300">Salem Media's Deeper Faith 2023 Mediterranean Cruise  (31300)</Select.Option>
  //         </Select>
  //       </Form.Item>

  //       <Form.Item
  //         label="Vendor Booking #"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Input
  //           // placeholder="Enter Organization"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         />
  //       </Form.Item>

  //       <Form.Item
  //         label="Created By Staff"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Select
  //           // placeholder="Select Destination Type"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         >
  //           <Select.Option value="1443">Aaron Phanco</Select.Option>
  //           <Select.Option value="1432">Alexis Kalugin</Select.Option>
  //           <Select.Option value="1351">Alicia Green</Select.Option>
  //           <Select.Option value="1358">Andrea Torres</Select.Option>
  //           <Select.Option value="1398">Arpee Caron</Select.Option>
  //           <Select.Option value="1131">Charlie Spencer</Select.Option>
  //           <Select.Option value="1405">Charlotte Russ</Select.Option>
  //           <Select.Option value="1422">Ciara Haywood</Select.Option>
  //           <Select.Option value="1356">Darlene Jones</Select.Option>
  //         </Select>
  //       </Form.Item>

  //       <Form.Item
  //         label="Travel Agent"
  //         style={{ fontFamily: "'Poppins', sans-serif" }}
  //       >
  //         <Input
  //           // placeholder="Enter Organization"
  //           style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
  //         />
  //       </Form.Item>

  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "end",
  //           // paddingRight: "25px",
  //         }}
  //       >
  //         <Button
  //           type="text"
  //           style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
  //         >
  //           Clear Filter
  //         </Button>
  //         <Button type="primary">Search</Button>
  //       </div>
  //     </Form>
  //   </div>
  // );

  // const options = [
  //   { value: 'light', label: 'Light' },
  //   { value: 'bamboo', label: 'Bamboo' },
  // ];

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            {/* <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              // allowClear={{clearIcon: <CloseOutlined />}}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search reservations, qutoes, etc"
                enterButton
              />
            </AutoComplete> */}

            {/* <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: '560px', width: '100%' }}
              placement="bottomRight"
              trigger={["click"]}
              onOpenChange={(e) => setActiveClass(e)}
              className="advance-search-dropdown"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                }}
              >
                <Space>
                  <SlidersOutlined
                    style={{ opacity: "0.5", color: "#000" }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown> */}
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default SearchDashboard_menu;
