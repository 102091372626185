import { Table, Divider, Checkbox, Switch, Modal, Tag, Button } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import React, { useState } from "react";
import moment from "moment";
import { CancelReservationRoute } from "../../../../url-routes/urlRoutes";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../utils/constants";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const ReservationCancellationInfo = ({
  reservationData,
  reservationId,
  loadingReservationData,
}) => {
  const data = [
    {
      key: "1",
      label: "Reasons Not Covered",
      input:
        Array.isArray(reservationData?.field_reasons_not_covered) &&
        reservationData?.field_reasons_not_covered
          ? reservationData?.field_reasons_not_covered
              ?.map((reason) => reason.name)
              .join(", ")
          : "",
    },
    {
      key: "2",
      label: "Check List",
      input:
        Array.isArray(reservationData?.field_check_list) &&
        reservationData?.field_check_list
          ? reservationData?.field_check_list
              ?.map((reason) => reason.name)
              .join(", ")
          : "",
    },
    {
      key: "3",
      label: "Confirmation Received By",
      input:
        reservationData?.field_cancellation_confirmation_received_by?.name ||
        "",
    },
    {
      key: "4",
      label: "Cancellation Request Processed By",
      input: `${reservationData?.field_cancelrequest_processed_by?.name || ""}${
        reservationData?.field_cancellationprocessed_time
          ? ` on ${moment(
              reservationData?.field_cancellationprocessed_time
            ).format(DATE_FORMAT)} ${moment(
              reservationData?.field_cancellationprocessed_time
            ).format(TIME_FORMAT)}`
          : ""
      }`,
    },
    {
      key: "5",
      label: "Confirmation Received Date",
      input: reservationData?.field_cancellation_confirmation_received_date
        ? moment(
            reservationData?.field_cancellation_confirmation_received_date,
            "YYYY-MM-DD"
          ).format(DATE_FORMAT)
        : "",
    },
  ];

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Cancellation Information</h4>

        <span>
          <Link
            to={`${CancelReservationRoute}?id=${reservationId}&isEdit=true`}
          >
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        columns={columns}
        showHeader={false}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loadingReservationData, size: "large" }}
      />
    </div>
  );
};
export default ReservationCancellationInfo;
