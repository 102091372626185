import { DatePicker, Divider, Form, Input, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { getTaxonomyList } from "../../../others/commonApiCalls/commonApiCalls";
import moment from "moment";

function ReservationCelebrationForm({
  form,
  onFinishHandler,
  reservationFormData,
  editId,
}) {
  const [celebration, setCelebration] = useState([]);
  const [loadingCelebration, setLoadingCelebration] = useState(false);
  useEffect(() => {
    getTaxonomyList("celebration", setCelebration, setLoadingCelebration);
  }, []);

  useEffect(() => {
    if (editId && Object.keys(reservationFormData).length) {
      form.setFieldsValue({
        field_celebration: reservationFormData?.field_celebration?.id,
        celebration_date: reservationFormData?.celebration_date
          ? moment(reservationFormData?.celebration_date, "YYYY-MM-DD")
          : null,
        field_surprise: reservationFormData?.field_surprise,
      });
    }
  }, [reservationFormData]);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Celebrations</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 12,
        }}
        onFinish={onFinishHandler}
        form={form}
      >
        <Form.Item name="field_celebration" label="Celebration">
          <Select
            allowClear
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={celebration?.map((celebrationItem) => ({
              key: celebrationItem.drupal_internal__tid,
              value: celebrationItem.id,
              label: celebrationItem.name,
            }))}
            loading={loadingCelebration}
          />
        </Form.Item>
        <Form.Item label="Celebration Date" name="celebration_date">
          <DatePicker format="MM/DD/YYYY" />
        </Form.Item>

        <Form.Item
          label="Surprise"
          name="field_surprise"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </div>
  );
}

export default ReservationCelebrationForm;
