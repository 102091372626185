import { Grid, Layout, message } from "antd";
// import Horizontals from '../../../components/Header/Horizontal_menu';
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AddonCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { getSingleInventoryApi } from "../../../../../apis/eventsApi";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import InventoryDetailHeader from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/InventoryDetail/InventoryDetailHeader";
import InventoryInformation from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/InventoryDetail/InventoryInformation";
import RoomViewInformation from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/InventoryDetail/RoomViewInformation";
import SystemInformation from "../../../../../others/CommonSystemInformation/SystemInformation";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const { Header, Content, Sider } = Layout;

const AddonInventoryDetailPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [inventoryData, setInventoryData] = useState({});
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const categoryId = searchParams.get("categoryId");

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    {
      title: "Detail",
      path: `${EventDetailsRoute}?id=${eventId}`,
    },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    {
      title: "Category Detail",
      path: `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`,
    },
    { title: "Inventory Detail", path: "" },
  ];

  useEffect(() => {
    getInventoryDetail();
  }, []);

  const getInventoryDetail = async () => {
    setInventoryLoading(true);
    const response = await getSingleInventoryApi(inventoryId);
    if (response.status) {
      setInventoryData(response?.data?.data);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setInventoryLoading(false);
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <InventoryDetailHeader
              inventoryData={inventoryData}
              inventoryLoading={inventoryLoading}
              inventoryId={inventoryId}
              eventId={eventId}
              categoryId={categoryId}
              addonId={addonId}
            />
          </div>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <InventoryInformation
              inventoryData={inventoryData}
              inventoryLoading={inventoryLoading}
              inventoryId={inventoryId}
              eventId={eventId}
              categoryId={categoryId}
              addonId={addonId}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <RoomViewInformation
              inventoryData={inventoryData}
              inventoryLoading={inventoryLoading}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <SystemInformation
              user_id={inventoryData?.user_id || {}}
              modified_by={inventoryData?.modified_by || {}}
              created={inventoryData?.created}
              changed={inventoryData?.changed}
              loading={inventoryLoading}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonInventoryDetailPage;
