import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllActivities } from "../../../../redux/features/contactFeatures/contactSlice";
import "./ActivitiesDesktop.less";
// import parse from "html-react-parser";

import moment from "moment";
import { createActivitiesApi } from "../../../../apis/contactApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { decodeContent } from "../../../../utils/contentParser";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const { TextArea } = Input;

const ActivitiesDesktop = ({
  contactId,
  tabLoading,
  noteTrigger,
  setNoteTrigger,
  contactDetailsData,
}) => {
  const [form] = Form.useForm();
  const [deleteList, setDeleteList] = useState([]);
  const [getResponse, setGetResponse] = useState({});

  const onSelectChange = (selectList) => {
    setDeleteList(selectList);
  };

  const dispatch = useDispatch();
  const contactActivitiesRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activitiesNotes, setActivitiesNotes] = useState("");
  const [allActivitiesData, setAllActivitiesData] = useState([]);
  const [listTotalCount, setListTotalCount] = useState(0);
  const [loadingNote, setLoadingNote] = useState(false);
  // const { login } = useSelector((state) => state.authentication);
  // const { idusers } = login.loggedInMemberData.userData;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const { allActivitiesResponse, loadingallActivities } = useSelector(
    (state) => state.contacts.allActivities
  );

  const dateFormat = "MM/DD/YYYY";

  useEffect(() => {
    if (contactActivitiesRef.current) {
      if (allActivitiesResponse?.status) {
        // setAllActivitiesData(allActivitiesResponse?.data?.rows || []);
        setAllActivitiesData(allActivitiesResponse?.data || []);
      } else {
        message.error(allActivitiesResponse?.message || SOMETHING_WENT_WRONG);
        setAllActivitiesData([]);
      }
    }
    contactActivitiesRef.current = true;
  }, [allActivitiesResponse]);

  useEffect(() => {
    const reqHeaders = customRequestHeader({ token: Token });
    dispatch(getAllActivities({ contactId, reqHeaders }));
  }, [contactId, getResponse]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Task Name",
      dataIndex: "description",
      render: (record, values) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <p style={{ width: "30rem", overflowWrap: "break-word" }}>
                {record}
              </p>
            </div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <p>{record}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
    },

    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "User",
      dataIndex: "user",
    },
  ];

  const items = [
    {
      label: (
        <div
          onClick={() => {
            showModal();
          }}
        >
          Note
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          to={`/contact/details/activities/todo?id=${contactId}`}
          state={{ name: contactDetailsData?.name }}
        >
          <span>To Do</span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link
          to={`/contact/details/activities/email?id=${contactId}`}
          state={{ name: contactDetailsData?.name }}
        >
          <span>Email</span>
        </Link>
      ),
      key: "3",
    },

    {
      label: (
        <Link
          to={`/contact/details/activities/phonecall?id=${contactId}`}
          state={{ name: contactDetailsData?.name }}
        >
          <span>Phone Call</span>
        </Link>
      ),
      key: "4",
    },

    {
      label: (
        <Link
          to={`/contact/details/activities/letter?id=${contactId}`}
          state={{ name: contactDetailsData.name }}
        >
          <span>Letter</span>
        </Link>
      ),
      key: "5",
    },
  ];

  const data = allActivitiesData?.length
    ? allActivitiesData?.map(
        ({
          activity_uuid,
          task_type,
          task_status,
          task_name,
          start_date_time,
          authored_by,
        }) => ({
          key: activity_uuid,
          type: task_type,

          description:
            task_type === "Note" ? (
              decodeContent(task_name)
            ) : permissionChecker("view published activity entities") ? (
              <Link
                to={`/contact/details/activities?activityId=${activity_uuid}&contactId=${contactId}`}
              >
                {decodeContent(task_name)}
              </Link>
            ) : (
              decodeContent(task_name)
            ),
          task_name: task_name,
          status: task_status,
          date: (
            <>
              <span>
                {start_date_time
                  ? moment(start_date_time).format(dateFormat)
                  : ""}
              </span>
            </>
          ),
          time: (
            <>
              <span>
                {start_date_time
                  ? moment(start_date_time).format("hh:mm A")
                  : ""}
              </span>
              <span className="span_text">
                {" "}
                {start_date_time ? moment(start_date_time).from(moment()) : ""}
              </span>
            </>
          ),
          user: `${authored_by}`,
        })
      )
    : [];

  const createNote = async () => {
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload = {
      data: {
        type: "activity--activity",
        attributes: {
          field_note_text: activitiesNotes?.trim(),
          field_task_type: "note",
          field_task_status: null,
          field_priority: null,
          field_start_time: moment(
            moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "YYYY-MM-DDTHH:mm:ssZ"
          ).format("YYYY-MM-DDTHH:mm:ssZ"),
          field_end_time: moment(
            moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "YYYY-MM-DDTHH:mm:ssZ"
          ).format("YYYY-MM-DDTHH:mm:ssZ"),
          created_on: "staffweb",
          modified_on: "staffweb",
        },
        relationships: {
          field_contact: {
            data: {
              type: "contact--contact",
              id: contactId,
            },
          },
        },
      },
    };
    const response = await createActivitiesApi({ payload });
    if (response.status) {
      setLoadingNote(false);
      setActivitiesNotes("");
      setNoteTrigger(!noteTrigger);
      message.success("Note Created Successfully");
      setIsModalOpen(false);
      form.resetFields();
      setGetResponse(response);
    } else {
      setLoadingNote(false);
      setActivitiesNotes("");
      setIsModalOpen(false);
      form.resetFields();

      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
  };

  return (
    <div className="reservation_data">
      <div
        className="reservarion_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'" }}>
          Activities{" "}
          {loadingallActivities ? (
            <Skeleton.Button shape="round" style={{ width: "50px" }} />
          ) : (
            `(${data.length})`
          )}
        </h4>
        <div>
          {permissionChecker("add activity entities") ? (
            <span className="reservation_dropdown" style={{ width: "114px" }}>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Space>
                  <Button type="primary" icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Space>
              </Dropdown>
            </span>
          ) : null}
        </div>
      </div>

      <Divider className="global_divider" />
      <Table
        className="activity_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={{ indicator: <DataLoader />, spinning: loadingallActivities }}
      />
      <div className="modal_popup">
        <Modal
          footer={
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={loadingNote}
            >
              Save
            </Button>
          }
          className="pop_modal"
          title={<span className="span_bold">Adding Note</span>}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <div className="modal_heading">
            <div className="modal_title">
              {/* name of contact and id of contact whose detail page is open */}
              <p>{contactDetailsData?.name} </p>
              <p>({contactDetailsData?.drupal_internal__id})</p>
            </div>
          </div>

          <div className="modal_textarea">
            <Form
              layout="vertical"
              form={form}
              autoComplete="off"
              onFinish={() => {
                createNote(contactId);
                setLoadingNote(true);
              }}
            >
              <Form.Item
                className="textarea_row"
                label="Note Text"
                name="noteText"
                rules={[
                  {
                    required: true,
                    message: "Please enter note text",
                  },
                ]}
              >
                <TextArea
                  showCount
                  // allowClear
                  maxLength={500}
                  onChange={(e) => setActivitiesNotes(e.target.value)}
                  value={activitiesNotes}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ActivitiesDesktop;
