import { Divider, Form, Input, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import "./TourDetailsForm.less";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import TextArea from "antd/lib/input/TextArea";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const TourMealsForm = ({ form, onFinishHandler, isLoading }) => {
  const [mealTypeList, setMealTypeList] = useState([]);
  const [loadingMealType, setLoadingMealType] = useState(false);
  useEffect(() => {
    getTaxonomyList("meal_type", setMealTypeList, setLoadingMealType);
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Meals</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.Item label="Hotel Meal Type" name="field_meal_type">
            <Select
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={mealTypeList?.map((mealType) => ({
                key: mealType.id,
                value: mealType.id,
                label: mealType.name,
              }))}
              loading={loadingMealType}
              allowClear
            />
          </Form.Item>
          <div className="textarea_row">
            <Form.Item label="Additional Meals" name="field_additional_meal">
              <TextArea rows={4} />
            </Form.Item>
          </div>
          {/* <Form.Item label="Additional Meals" name="field_additional_meal">
            <Input />
          </Form.Item> */}
          <Form.Item
            label="Private Dining"
            name="field_private_dining"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <div className="textarea_row">
            <Form.Item label="Dinner Venue" name="field_dinner_venue">
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default TourMealsForm;
