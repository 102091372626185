import React from "react";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";
import Searchevents_menu from "../../components/Events/EventsSearchbar";
import ChangePasswordHeader from "../../components/ChangePassword/ChangePasswordHeader";
import ChangePasswordForm from "../../components/ChangePassword/ChangePasswordForm";
const { Header, Content, Sider } = Layout;

function ChangePasswordPage() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchevents_menu />
          </Header>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Change Password</Breadcrumb.Item>
          </Breadcrumb>
          <div className="events-header">
            <ChangePasswordHeader />
          </div>
          <Content className="site-layout-background">
            <ChangePasswordForm />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default ChangePasswordPage;
