import { Form, Input, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createOrEditEvent,
  eventStepSetter,
  getEventCoaBrand,
  getEventCommissionCalculation,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { EventDetailsRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AccountingInformationForm = ({
  form4,
  createId,
  editId,
  eventFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventCreation, eventCoaBrand, eventCommissionCalculation } =
    useSelector((state) => state.events);
  const { eventCreationResponse, loadingEventCreation, eventStep } =
    eventCreation;
  const { eventCoaBrandResponse, loadingEventCoaBrand } = eventCoaBrand;
  const {
    eventCommissionCalculationResponse,
    loadingEventCommissionCalculation,
  } = eventCommissionCalculation;
  const eventCreationRef = useRef(false);
  const [coaBrandList, setCoaBrandList] = useState([]);
  const [commissionCalculationList, setCommissionCalculationList] = useState(
    []
  );
  const coaBrandRef = useRef(false);
  const commissionCalculationRef = useRef(false);

  useEffect(() => {
    dispatch(getEventCoaBrand());
    dispatch(getEventCommissionCalculation());
  }, []);

  useEffect(() => {
    if (coaBrandRef.current) {
      if (eventCoaBrandResponse.status) {
        setCoaBrandList(eventCoaBrandResponse.data.data);
      } else {
        setCoaBrandList([]);
      }
    }
    coaBrandRef.current = true;
  }, [eventCoaBrandResponse]);

  useEffect(() => {
    if (commissionCalculationRef.current) {
      if (eventCommissionCalculationResponse.status) {
        setCommissionCalculationList(
          eventCommissionCalculationResponse.data.data
        );
      } else {
        setCommissionCalculationList([]);
      }
    }
    commissionCalculationRef.current = true;
  }, [eventCommissionCalculationResponse]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(eventFormFields).length) {
      dispatch(eventStepSetter(eventFormFields.field_step));
      form4.setFieldsValue({
        ...eventFormFields,
        field_coa_brand: eventFormFields?.field_coa_brand?.id,
        field_commission_calculation:
          eventFormFields?.field_commission_calculation?.id,
      });
    }
  }, [eventFormFields]);

  useEffect(() => {
    if (eventCreationRef.current) {
      if (eventCreationResponse.status) {
        dispatch(
          eventStepSetter(eventCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          navigate(`${EventDetailsRoute}?id=${createId}`);
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            navigate(`${EventDetailsRoute}?id=${editId}`);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateEventStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    eventCreationRef.current = true;
  }, [eventCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_coa_trip: values.field_coa_trip,
      field_step: currentStep > eventStep ? +currentStep : eventStep,
    };
    const relationships = {
      field_coa_brand: values?.field_coa_brand
        ? {
            data: {
              type: "taxonomy_term--coa_brand",
              id: values.field_coa_brand,
            },
          }
        : null,
      field_commission_calculation: values.field_commission_calculation
        ? {
            data: {
              type: "taxonomy_term--commission_calculation",
              id: values.field_commission_calculation,
            },
          }
        : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };
    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditEvent(payload));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form4}
          onFinish={onFinishHandler}
        >
          <Form.Item label="COA Brand" name="field_coa_brand">
            <Select
              allowClear
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="COA Brand"
              options={coaBrandList.map((coaBrand) => ({
                key: coaBrand.id,
                value: coaBrand.id,
                label: coaBrand.name,
              }))}
            />
          </Form.Item>

          <Form.Item label="COA Trip" name="field_coa_trip">
            <Input />
          </Form.Item>

          <Form.Item
            label="Commission Calculation"
            name="field_commission_calculation"
          >
            <Select
              allowClear
              placeholder="Commission Calculation"
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={commissionCalculationList.map((coaBrand) => ({
                key: coaBrand.id,
                value: coaBrand.id,
                label: coaBrand.name,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AccountingInformationForm;
