import { ArrowLeftOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function ReportDetailsHeader({ reportData, loadingReportName }) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="header_res">
            {loadingReportName ? (
              <>
                <Skeleton.Input active={true} style={{ width: "250px" }} />
              </>
            ) : (
              <p>{reportData?.field_report_name || ""}</p>
            )}
          </div>
        </h3>
      </div>
    </div>
  );
}
export default ReportDetailsHeader;
