import { Breadcrumb, Form, Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import CancelReservation_header from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CancelRservationHeader";
import CalculationDetails from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CalculationDetails";
import CancellationCalculation from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CancellationCalculation";
import CancellationDetails from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CancellationDetails";
import CancellationReasons from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CancellationReasons";
import Recommendations from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/Recommendations";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { updateRecentlyViewed } from "../../../utils/recentlyViewedUpdater";
import {
  cancelReservationDetailApi,
  createOrEditReservationApi,
  getReservationDetailApi,
} from "../../../apis/reservationApi";
import CancelRservationHeader from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/CancelReservation/CancelRservationHeader";
import moment from "moment";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content, Sider } = Layout;

function CancelReservationPage() {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const reservationId = searchParams.get("id");
  const isEdit = Boolean(searchParams.get("isEdit"));
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [reservationDetails, setReservationDetails] = useState({});
  const [cancelDetails, setCancelDetails] = useState({});
  const [loadingCancelDetails, setLoadingCancelDetails] = useState(false);
  const [loadingResDetails, setLoadingResDetails] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loadingUpdateRes, setLoadingUpdateRes] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [form] = Form.useForm();
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    { title: "Details", path: `${ReservationDetailRoute}?id=${reservationId}` },
    { title: "Cancel Reservation", path: "" },
  ];
  useEffect(() => {
    getReservationDetail(reservationId);
    getCancellationDetail(reservationId);
  }, [triggerRender]);

  const getCancellationDetail = async (id) => {
    setLoadingCancelDetails(true);
    const res = await cancelReservationDetailApi(id);
    if (res.status) {
      console.log("hello", res);
      setCancelDetails(res?.data?.data);
      setLoadingCancelDetails(false);
    } else {
      setCancelDetails({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingCancelDetails(false);
    }
  };

  const getReservationDetail = async (id) => {
    setLoadingResDetails(true);
    const res = await getReservationDetailApi(id);
    if (res.status) {
      setReservationDetails(res?.data?.data);
      const { type, langcode, drupal_internal__id, id } = res?.data?.data;
      updateRecentlyViewed(type, id, langcode, drupal_internal__id);
      setLoadingResDetails(false);
    } else {
      setReservationDetails({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingResDetails(false);
    }
  };

  const updateReservationStatus = async (data) => {
    console.log({ data });
    setLoadingUpdateRes(true);
    const res = await createOrEditReservationApi(data);
    if (res.status) {
      message.success("Reservation Status Updated Successfully");
      setTriggerRender(!triggerRender);
      if (
        isEdit ||
        data.payload.data.attributes.reservation_status === "cancelled"
      ) {
        navigate(`${ReservationDetailRoute}?id=${reservationId}`);
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoadingUpdateRes(false);
  };

  const onFinishHandler = (values) => {
    console.log({ values }, { reservationDetails });
    const payload = {
      data: {
        type: "reservation--reservation",
        id: reservationId,
        attributes: {
          name: reservationDetails?.name || "",
          field_hash_id: reservationDetails?.field_hash_id,
          reservation_type: reservationDetails?.reservation_type || "",
          table_no: reservationDetails?.table_no || "",
          traveler_comments: reservationDetails?.traveler_comments || "",
          event_coordinator_notes_potential_challenges:
            reservationDetails?.event_coordinator_notes_potential_challenges ||
            "",
          event_partner_notes: reservationDetails?.event_partner_notes || "",
          supplier_notes: reservationDetails?.supplier_notes || "",
          signature_services_notes:
            reservationDetails?.signature_services_notes || "",
          cancellation_comments:
            reservationDetails?.cancellation_comments || "",
          group_with: reservationDetails?.group_with || "",
          celebration_date: reservationDetails?.celebration_date,
          traveling_with: reservationDetails?.traveling_with,
          field_surprise: reservationDetails?.field_surprise,
          reservation_status: currentStatus,
          current_state: reservationDetails?.current_state || "",
          field_step_count: reservationDetails?.field_step_count || "",
          field_room_json: reservationDetails?.field_room_json || "",
          field_payment_res: reservationDetails?.field_payment_res || "",
          field_ext_json: reservationDetails?.field_ext_json || "",
          field_discount_json: reservationDetails?.field_discount_json || "",
          field_confirmtime: reservationDetails?.field_confirmtime || null,
          field_recommendations: values?.field_recommendations || "",
          field_notes: values?.notes || "",
          field_recommendations_date: values?.recommendation_date
            ? values?.recommendation_date?.format("YYYY-MM-DD")
            : null,
          field_cancellation_confirmation_received_date:
            values?.confirmation_recieved_date
              ? values?.confirmation_recieved_date?.format("YYYY-MM-DD")
              : null,
          field_cancellation_request_time:
            currentStatus === "cancel_pending"
              ? moment().format()
              : reservationDetails?.field_cancellation_request_time,
          created: reservationDetails?.created,
          start_timer: reservationDetails?.start_timer,
          financial_status: reservationDetails?.financial_status?.key || "",
        },
        relationships: {
          select_event: reservationDetails?.select_event?.id
            ? {
              data: {
                type: "event--event",
                id: reservationDetails?.select_event?.id,
              },
            }
            : null,
          field_bed_request: reservationDetails?.field_bed_request?.id
            ? {
              data: {
                type: "taxonomy_term--bed_request",
                id: reservationDetails?.field_bed_request?.id,
              },
            }
            : null,
          field_dining_time_request: reservationDetails
            ?.field_dining_time_request?.id
            ? {
              data: {
                type: "taxonomy_term--dining_time_request",
                id: reservationDetails?.field_dining_time_request?.id,
              },
            }
            : null,
          field_dining_time_assigned: reservationDetails
            ?.field_dining_time_assigned?.id
            ? {
              data: {
                type: "taxonomy_term--dining_time_assigned",
                id: reservationDetails?.field_dining_time_assigned?.id,
              },
            }
            : null,
          field_table_size_request: reservationDetails?.field_table_size_request
            ?.id
            ? {
              data: {
                type: "taxonomy_term--table_size_request",
                id: reservationDetails?.field_table_size_request?.id,
              },
            }
            : null,
          field_table_status: reservationDetails?.field_table_status?.id
            ? {
              data: {
                type: "taxonomy_term--table_status",
                id: reservationDetails?.field_table_status?.id,
              },
            }
            : null,
          field_charter_group: reservationDetails?.field_charter_group?.id
            ? {
              data: {
                type: "taxonomy_term--charter_group",
                id: reservationDetails?.field_charter_group?.id,
              },
            }
            : null,
          field_charter_priority: reservationDetails?.field_charter_priority?.id
            ? {
              data: {
                type: "taxonomy_term--charter_priority",
                id: reservationDetails?.field_charter_priority?.id,
              },
            }
            : null,
          field_celebration: reservationDetails?.field_celebration?.id
            ? {
              data: {
                type: "taxonomy_term--celebration",
                id: reservationDetails?.field_celebration?.id,
              },
            }
            : null,
          field_reasons_covered: values?.reasons_covered?.length
            ? {
              data: values?.reasons_covered?.map((reasonsId) => ({
                type: "taxonomy_term--reservation_cancellation_reasons",
                id: reasonsId,
              })),
            }
            : null,
          field_reasons_not_covered: values?.reasons_not_covered?.length
            ? {
              data: values?.reasons_not_covered?.map((reasonsId) => ({
                type: "taxonomy_term--res_cancellation_reasons_not",
                id: reasonsId,
              })),
            }
            : null,
          field_cancellation_confirmation_received_by:
            values?.confirmation_recieved_by
              ? {
                data: {
                  type: "taxonomy_term--reservation_cancellation_confirm",
                  id: values?.confirmation_recieved_by,
                },
              }
              : null,
          field_check_list: values?.check_list?.length
            ? {
              data: values?.check_list?.map((listItemId) => ({
                type: "taxonomy_term--reservation_cancellation_checkli",
                id: listItemId,
              })),
            }
            : null,
          field_source: reservationDetails?.field_source?.id
            ? {
              data: {
                type: "source--source",
                id: reservationDetails?.field_source?.id,
              },
            }
            : null,
          field_channel: reservationDetails?.field_channel?.id
            ? {
              data: {
                type: "channel--channel",
                id: reservationDetails?.field_channel?.id,
              },
            }
            : null,
          field_detail: reservationDetails?.field_detail?.id
            ? {
              data: {
                type: "detail--detail",
                id: reservationDetails?.field_detail?.id,
              },
            }
            : null,
          field_last_modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
          field_cancellation_requested_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
          field_travel_agent: reservationDetails?.field_travel_agent?.id
            ? {
              data: {
                type: "contact--contact",
                id: reservationDetails?.field_travel_agent?.id,
              },
            }
            : null,
        },
      },
    };
    updateReservationStatus({
      reqMethod: "patch",
      payload,
      reservationId,
    });
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <CancelRservationHeader
              reservationDetails={reservationDetails}
              loadingResDetails={loadingResDetails}
              reservationId={reservationId}
              form={form}
              setCurrentStatus={setCurrentStatus}
              loadingUpdateRes={loadingUpdateRes}
              isEdit={isEdit}
            />
          </div>
          {!isEdit && (
            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <CancellationCalculation
                cancelDetails={cancelDetails}
                loadingCancelDetails={loadingCancelDetails}
              />
            </Content>
          )}

          {!isEdit && (
            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <CalculationDetails
                cancelDetails={cancelDetails}
                loadingCancelDetails={loadingCancelDetails}
              />
            </Content>
          )}

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <Recommendations
              cancelDetails={cancelDetails}
              form={form}
              onFinishHandler={onFinishHandler}
              loadingCancelDetails={loadingCancelDetails}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CancellationReasons
              cancelDetails={cancelDetails}
              form={form}
              onFinishHandler={onFinishHandler}
              loadingCancelDetails={loadingCancelDetails}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <CancellationDetails
              cancelDetails={cancelDetails}
              form={form}
              onFinishHandler={onFinishHandler}
              loadingCancelDetails={loadingCancelDetails}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default CancelReservationPage;
