import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Select, Skeleton, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateReport, ViewReportRoute } from "../../url-routes/urlRoutes";
import reportConfig from "../../config/report.config";
import { useSelector } from "react-redux";
import { createFilterTags } from "../../others/util/filterTagsCreator";
const { Option } = Select;

function ReportQueryListing({
  urlParamsObject,
  setUrlParamsObject,
  reportListData,
  loadingList,
  reportCount,
  setReportListData,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const { isReportFilterApplied, reportSearchFilters } = useSelector(
    (state) => state.report
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (record, recordObj) => {
        return (
          <Button
            onClick={() => {
              navigate(`${CreateReport}?query=${recordObj?.id}`);
            }}
          >
            View Query
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (reportListData.length) {
      console.log("AAAAA", reportListData);
      setData(
        reportListData?.map((reportListItem) => ({
          name: reportListItem?.name,
          id: reportListItem?.id,
        }))
      );
    }
  }, [reportListData]);

  const handleSorting = (value) => {
    if (value === "recent_read") {
      setUrlParamsObject({
        sort_by: "recent_read",
      });
    } else {
      setUrlParamsObject({ ...urlParamsObject, sort_by: value });
    }
  };
  return (
    <>
      <div className="table_filter">
        {isReportFilterApplied && (
          <div className="filter-applied--wrapper">
            <span className="filter-title">Filter(s) Applied:</span>
            {createFilterTags(reportSearchFilters)}
          </div>
        )}
        {loadingList ? (
          <Skeleton.Input size="medium" style={{ width: "250px" }} />
        ) : (
          <>
            {urlParamsObject && urlParamsObject?.sort_by && (
              <>
                {" "}
                <span>Sort by :</span>
                <Select
                  value={urlParamsObject.sort_by}
                  style={{
                    width: 165,
                    marginLeft: "10px",
                  }}
                  onChange={(e) => {
                    handleSorting(e);
                  }}
                >
                  <Option value="recent_read">Recently Viewed</Option>
                  <Option value="report_name">Name</Option>
                  <Option value="view_count">Most Used</Option>
                </Select>
              </>
            )}
          </>
        )}
      </div>
      <Table
        className="table_tour"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loadingList, size: "large" }}
        pagination={false}
      />
    </>
  );
}

export default ReportQueryListing;
