import { Table, Divider } from "antd";

import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  AddonReservationsListingRoute,
  AddonTravelersListingRoute,
  EventDetailAssignDining,
} from "../../../../../url-routes/urlRoutes";
import { addonReservationsCountApi } from "../../../../../apis/eventsApi";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../../utils/constants";
const columns = [
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Count",
    dataIndex: "count",
  },
  {
    title: "First Created On",
    dataIndex: "first_created",
  },
  {
    title: "Last Created On",
    dataIndex: "last_created",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const AddonReservations = ({ addonType, singleAddonData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const addOnUuid = searchParams.get("addonId");
  const addOnType = addonType || searchParams.get("addonType");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await addonReservationsCountApi(addOnUuid);
      let tableData = []
      if (res.status) {
        tableData = res.data.data.status.length
          ? res.data.data.status.map((stat) => ({
              key: stat.status_key,
              status: stat.status,
              count: stat.count,
              first_created: stat.first_created_on
                ? `${moment(stat.first_created_on).format(
                    DATE_FORMAT
                  )} ${moment(stat.first_created_on).format(
                    TIME_FORMAT
                  )} ${moment(stat.first_created_on).fromNow()}`
                : null,
              last_created: stat.last_created_on
                ? `${moment(stat.last_created_on).format(DATE_FORMAT)} ${moment(
                    stat.last_created_on
                  ).format(TIME_FORMAT)} ${moment(
                    stat.last_created_on
                  ).fromNow()}`
                : null,
              action: (
                <>
                  <div className="buttons_flex">
                    <Link
                      to={`${AddonReservationsListingRoute}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&count=10&page=1&status=${stat?.status_key}`}
                    >
                      View
                    </Link>

                    {/* {addonType === "motorcoach" || addonType === "dining" ? (
                      <Link
                        to={`${EventDetailAssignDining}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&event=${singleAddonData?.field_event?.name} (${singleAddonData?.field_event?.meta?.drupal_internal__target_id})`}
                      >
                        <a href="#">Assign</a>
                      </Link>
                    ) : null} */}
                  </div>
                </>
              ),
            }))
          : [];
        if (res.data.data.travelers) {
          const { count, first_created_on, last_created_on, status } =
            res?.data?.data?.travelers;
          tableData.push({
            key: status,
            status: status,
            count: count || 0,
            first_created: first_created_on
              ? `${moment(first_created_on).format(DATE_FORMAT)} ${moment(
                  first_created_on
                ).format(TIME_FORMAT)} ${moment(first_created_on).fromNow()}`
              : null,
            last_created: last_created_on
              ? `${moment(last_created_on).format(DATE_FORMAT)} ${moment(
                  last_created_on
                ).format(TIME_FORMAT)} ${moment(last_created_on).fromNow()}`
              : null,
            action: (
              <>
                <div className="buttons_flex">
                 <Link
                 to={`${AddonTravelersListingRoute}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&count=10&page=1&status=${status}`}
               >
                 View
               </Link>
                  
                  { ["motorcoach", "dining","post_extension_motorcoach","pre_extension_motorcoach"]?.includes(addOnType?.toLowerCase()) ? (
                    <Link
                      to={`${EventDetailAssignDining}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&label=${singleAddonData?.field_add_ons_type?.label}&event=${singleAddonData?.field_event?.name} (${singleAddonData?.field_event?.meta?.drupal_internal__target_id})`}
                    >
                      <a href="#">Assign</a>
                    </Link>
                  ) : null}
                </div>
              </>
            ),
          });
        }else{
          tableData.push({
            key: "Travelers",
            status: "Travelers",
            count: 0,
            first_created: null,
            last_created: null,
            action: (
              <>
                <div className="buttons_flex">
                  { ["motorcoach","dining","post_extension_motorcoach","pre_extension_motorcoach"].includes(addOnType?.toLowerCase()) ? (
                    <Link
                      to={`${EventDetailAssignDining}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&label=${singleAddonData?.field_add_ons_type?.label}&event=${singleAddonData?.field_event?.name}(${singleAddonData?.field_event?.meta?.drupal_internal__target_id})`}
                    >
                      <a href="#">Assign</a>
                    </Link>
                  ) : null}
                </div>
              </>
            ),
          });
        }
        setData(tableData);
      }
    })();
  }, [singleAddonData]);

  // const data = [
  //   {
  //     key: "1",
  //     status: (
  //       <>
  //         <a href="#">Guest</a>
  //       </>
  //     ),
  //     count: "0",
  //     first_created: "",
  //     last_created: "",
  //     action: (
  //       <>
  //         <div className="buttons_flex">
  //           <a href="#">View</a>

  //           {addonType === "motorcoach" || addonType === "dining" ? (
  //             <Link
  //               to={`${EventDetailAssignDining}?id=${eventId}&addonId=${addOnUuid}&addonType=${addOnType}&event=${singleAddonData?.field_event?.name} (${singleAddonData?.field_event?.meta?.drupal_internal__target_id})`}
  //             >
  //               <a href="#">Assign</a>
  //             </Link>
  //           ) : null}
  //         </div>
  //       </>
  //     ),
  //   },
  // ];
  return (
    <div className="requests">
      <div className="requests_title">
        <h4 className="content_heading mb-0">Reservations</h4>
      </div>
      <Divider className="global_divider" />
      <Table
        className="request_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </div>
  );
};
export default AddonReservations;
