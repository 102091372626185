// import React from "react";
// import "./Horizontal_menu.less";
// import { useEffect, useState } from "react";
// import {
//   DownOutlined,
//   SlidersOutlined,
//   MenuOutlined,
//   SearchOutlined,
//   LeftOutlined,
// } from "@ant-design/icons";
// import {
//   AutoComplete,
//   Dropdown,
//   Menu,
//   Space,
//   Col,
//   Row,
//   Drawer,
//   Grid,
//   Button,
//   Input,
//   Form,
//   Select,
//   Modal,
// } from "antd";
// import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
// import Sidebar from "./Header_sidebar";
// import { interpolateRgbBasis } from "d3";
// const { Search } = Input;

// const onSearch = (value) => console.log(value);

// const getRandomInt = (max, min = 0) =>
//   Math.floor(Math.random() * (max - min + 1)) + min;

// const searchResult = (query) =>
//   new Array(getRandomInt(5))
//     .join(".")
//     .split(".")
//     .map((_, idx) => {
//       const category = `${query}${idx}`;
//       return {
//         value: category,
//         label: (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//             }}
//           >
//             <span>
//               <a
//                 href={`https://s.taobao.com/search?q=${query}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ color: "#000", fontSize: "14px", lineHeight: "28px" }}
//               >
//                 {category}
//               </a>
//             </span>
//           </div>
//         ),
//       };
//     });

// function FindDuplicate_searchbox() {
//   const [searchText, setSearchText] = useState("");
//   const [activeClass, setActiveClass] = useState(false);
//   const menu = (
//     <Menu
//       items={[
//         {
//           key: "1",
//           label: (
//             <a
//               target="_blank"
//               rel="noopener noreferrer"
//               // href="https://www.antgroup.com"
//             >
//               Change Password
//             </a>
//           ),
//         },
//         {
//           key: "2",
//           label: (
//             <a
//               target="_blank"
//               rel="noopener noreferrer"
//               href="https://www.antgroup.com"
//             >
//               Sign Out
//             </a>
//           ),
//         },
//         // {
//         //   key: '3',
//         //   label: (
//         //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
//         //       Send Feedback
//         //     </a>
//         //   ),
//         // },
//       ]}
//     />
//   );
//   const onFinish = (values) => {
//     console.log("Success:", values);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };

//   const custumSearch = (
//     <div className="custom_search modalcustom_search">
//       <Form
//         labelCol={{
//           span: 4,
//         }}
//         wrapperCol={{
//           lg: 20,
//           xs: 20,
//         }}
//         layout="horizontal"
//         style={{ fontFamily: "'Poppins', sans-serif" }}
//       >
//         <Form.Item
//           label="Email"
//           style={{ fontFamily: "'Poppins', sans-serif" }}
//         >
//           <Input
//             placeholder="Enter Email Address"
//             style={{ fontFamily: "'Poppins', sans-serif", 
//             // maxWidth: "390px" 
//           }}
//           />
//         </Form.Item>

//         <Form.Item
//           label="Phone"
//           style={{ fontFamily: "'Poppins', sans-serif" }}
//         >
//           <Input
//             placeholder="Enter Phone Number"
//             style={{ fontFamily: "'Poppins', sans-serif", 
//             // maxWidth: "390px" 
//           }}
//           />
//         </Form.Item>

//         <Form.Item
//           label="Organization Type"
//           style={{ fontFamily: "'Poppins', sans-serif" }}
//         >
//           <Select
//             placeholder="Select Organization Type"
//             style={{ fontFamily: "'Poppins', sans-serif",
//             //  maxWidth: "390px" 
//             }}
//           >
//             <Select.Option value="min">Ministry</Select.Option>
//             <Select.Option value="air">Airline</Select.Option>
//             <Select.Option value="airline"> Airline Consolidator</Select.Option>
//             <Select.Option value="cruise">Cruise Line</Select.Option>
//             <Select.Option value="internal">Internal</Select.Option>
//             <Select.Option value="tour">Tour Operator</Select.Option>
//             <Select.Option value="horizontal">Headliner/Artist</Select.Option>
//           </Select>
//         </Form.Item>

//         <Form.Item
//           label="Organization"
//           style={{ fontFamily: "'Poppins', sans-serif" }}
//         >
//           {/* <Select defaultValue="lucy" style={{ fontFamily: "'Poppins', sans-serif", width: '390px' }} >
//             <Select.Option></Select.Option>
//             <Select.Option value="lucy" >Lucy</Select.Option>
//             <Select.Option>James</Select.Option>
//           </Select> */}

//           <Select
//             placeholder="Select Organization"
//             style={{ fontFamily: "'Poppins', sans-serif",
//             //  maxWidth: "390px" 
//             }}
//           >
//             <Select.Option value="american">American Airlines</Select.Option>
//             <Select.Option value="holand">Holland America</Select.Option>
//             <Select.Option value="cruise">Norwegian Cruise Line</Select.Option>
//             <Select.Option value="cruise">
//               Royal Caribbean Cruise Line
//             </Select.Option>
//             <Select.Option value="marama">Marama Tours</Select.Option>
//           </Select>
//         </Form.Item>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "end",
//             // paddingRight: "25px",
//           }}
//         >
//           <Button
//             type="text"
//             style={{ border: "none", opacity: "0.85" }}
//           >
//             Clear Filter
//           </Button>
//           <Button type="primary">Search</Button>
//         </div>
//       </Form>
//     </div>
//   );

//   // const options = [
//   //   { value: 'light', label: 'Light' },
//   //   { value: 'bamboo', label: 'Bamboo' },
//   // ];

//   const [options, setOptions] = useState([]);

//   const handleSearch = (value) => {
//     setOptions(value ? searchResult(value) : []);
//   };

//   const onSelect = (value) => {
//     console.log("onSelect", value);
//   };

//   const [open, setOpen] = useState(false);
//   const showDrawer = () => {
//     setOpen(true);
//   };

//   const onClose = () => {
//     setOpen(false);
//   };

//   // Search toggle for mobile
//   const [showSearch, setShowSearch] = useState(false);
//   const mobileSearchToggle = showSearch
//     ? "active-search searchbox mobile-searchbox"
//     : "searchbox modal_serchbox";

//   const { useBreakpoint } = Grid;
//   const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
//   const mediaHideSmall = lg ? "" : "hide";
//   const mediaHideLarge = lg ? " hide  " : "";

//   return (
//     <div className="horizontal_menu">
//       <Row className="logo-group">
//         {/* <Col className={mediaHideLarge}>
//           <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
//         </Col>
//         <Col className={mediaHideLarge}>
//           <div className="mainnav-logo">
//             <a key="logo">
//               <img src="./logo-mobile.png" alt="This is a Logo"></img>
//             </a>
//           </div>
//         </Col> */}
//         <Col sm={24} md={24} lg={24} className={mobileSearchToggle}>
//           <div className="search-group duplicate-search-group">
//             {/* <Button
//               type="link"
//               onClick={() => {
//                 setShowSearch(false);
//               }}
//               className="back-btn"
//             >
//               <LeftOutlined />
//             </Button> */}

//             <AutoComplete
//               dropdownMatchSelectWidth={252}
//               options={options}
//               onSelect={onSelect}
//               onSearch={handleSearch}
//               // allowClear={{clearIcon: <CloseOutlined />}}
//               allowClear={{ clearIcon: <CloseOutlined /> }}
//             >
//               <Input.Search
//                 size="large"
//                 placeholder="Search Contacts"
//                 enterButton
//               />
//             </AutoComplete>

// <Dropdown
//   overlay={custumSearch}
//    overlayStyle={{maxWidth: '1170px',  width: '100%'}}
//    placement="bottomRight"
//   trigger={["click"]}
//   //  onOpenChange={(e) => setActiveClass(e)}
//    className="advance-search-dropdown modal_advanced-searchbox"
// >
//   <a
//     onClick={(e) => {
//       e.preventDefault();
//       setActiveClass(!activeClass);
//     }}
//   >
//     <Space>
//                   <SlidersOutlined
//                     style={{ opacity: "0.5", color: "#000" }}
//                     className={`${activeClass ? "active" : "inactive"}`}
//                   />
//     </Space>
//   </a>
// </Dropdown>
//           </div>
//         </Col>
//         <Col sm={12} className="profile-group modal_group">
//           <div
//             className="search-mobile"
//             onClick={() => {
//               setShowSearch(true);
//             }}
//           >
//             <SearchOutlined />
//           </div>

//           <div className="profile">
//             <Dropdown overlay={menu}>
//               <a onClick={(e) => e.preventDefault()}>
//                 <Space>
//                   <img
//                     src="./pro-icon.png"
//                     alt="This is a Logo"
//                     style={{ width: "24px", objectFit: "cover" }}
//                   ></img>
//                   <span className="user-name"> MMGY Delivery</span>
//                   <DownOutlined />
//                 </Space>
//               </a>
//             </Dropdown>
//           </div>
//         </Col>
//       </Row>
//       <Drawer
//         placement="left"
//         onClose={onClose}
//         open={open}
//         className="aside-menu primary-bg"
//       >
//         <Sidebar />
//       </Drawer>
//     </div>
//   );
// }

// export default FindDuplicate_searchbox;



// import { SearchOutlined, SlidersOutlined, CloseOutlined } from '@ant-design/icons';
// import React, { useState } from 'react';
// import { AutoComplete, Dropdown, Space, Input, Form, Button } from 'antd';
// import './FindDuplicate.less';

// const mockVal = (str, repeat = 1) => ({
//     value: str.repeat(repeat),
// });

// function FindDuplicate_searchbox() {
//     const [value, setValue] = useState('');
//     const [options, setOptions] = useState([]);



//     const onSearch = (searchText) => {
//         setOptions(
//             !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
//         );
//     };
//     const onSelect = (data) => {
//         console.log('onSelect', data);
//     };

  
//     const onChange = (data) => {
//         setValue(data);
//     };

//     const custumSearch = (
//         <div className='duplicate_search' >
//             <Form
//                 // labelCol={{
//                 //     span: 4,
//                 // }}
//                 // wrapperCol={{
//                 //     lg: 20,
//                 //     xs: 20,
//                 // }}
//                 layout="horizontal"
//                 style={{ fontFamily: "'Poppins', sans-serif" }}
//             >
//                 <Form.Item
//                     label="Email"
//                     style={{ fontFamily: "'Poppins', sans-serif" }}
//                 >
//                     <Input
//                         placeholder="Enter Email Address"
//                         onSelect="email"
//                         style={{
//                             fontFamily: "'Poppins', sans-serif",
//                             // maxWidth: "390px" 
//                         }}
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     label="Phone"
//                     style={{ fontFamily: "'Poppins', sans-serif" }}
//                 >
//                     <Input
//                         placeholder="Enter Phone Number"
//                         style={{
//                             fontFamily: "'Poppins', sans-serif",
//                             // maxWidth: "390px" 
//                         }}
//                     />
//                 </Form.Item>


//                 <div
//                     style={{
//                         display: "flex",
//                         justifyContent: "end",
//                         // paddingRight: "25px",
//                     }}
//                 >
//                     <Button
//                         type="text"
//                         style={{ border: "none", opacity: "0.85" }}
//                     >
//                         Clear Filter
//                     </Button>
//                     <Button type="primary">Search</Button>
//                 </div>
//             </Form>
//         </div>
//     );


//     return (
//         <>
//             <div className='contact_duplicate'>
//                 <AutoComplete className='auto_complete'
                    
//                     options={options}
//                     onSelect={onSelect}
//                     onSearch={onSearch}
//                     allowClear={{ clearIcon: <CloseOutlined className='close' /> }}

//                 >
//                     <Input prefix={<SearchOutlined />} suffix={
//                         <>
//                             <Dropdown
//                                 overlay={custumSearch}
//                                 trigger={["click"]}
//                                 overlayClassName="duplicate_dropdown"

//                             >
//                                <a onClick={(e) => e.preventDefault()}>
//                                     <Space>

//                                         <SlidersOutlined />

//                                     </Space>
//                                 </a>
//                             </Dropdown>
//                         </>

//                     } placeholder="Search Contacts" />
//                 </AutoComplete>


//             </div>

//         </>
//     );
// }

// export default FindDuplicate_searchbox;



import React from "react";
import { DatePicker } from 'antd';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';

import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined, Description, FilterOutlined
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
} from "antd";
import { AudioOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
import Sidebar from "./Header_sidebar";
const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const weekFormat = 'MM/DD';
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
// const customWeekStartEndFormat = (value) =>
//   `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value)
//     .endOf('week')
//     .format(weekFormat)}`;

const dateFormat = 'YYYY/MM/DD';

const { RangePicker } = DatePicker;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const onChange = (value, dateString) => {
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
};
const onOk = (value) => {
  console.log('onOk: ', value);
};

const onSearch = (value) => console.log(value);

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#000", fontSize: "14px", lineHeight: "28px" }}
              >
                {category}
              </a>
            </span>
          </div>
        ),
      };
    });

function FindDuplicate_searchbox() {
  const [searchText, setSearchText] = useState("");
  const [activeClass, setActiveClass] = useState(false);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
            // href="#"
            >
              Change Password
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.antgroup.com"
            >
              Sign Out
            </a>
          ),
        },
        // {
        //   key: '3',
        //   label: (
        //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        //       Send Feedback
        //     </a>
        //   ),
        // },
      ]}
    />
  );


  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const custumSearch = (
    <div className="custom_search"
>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >

       


        <Form.Item
          label="Name"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
        
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>


        <Form.Item
          label="Contact ID"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input

          type="number"
    
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>


        <Form.Item
          label="Email"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
        
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Input
        
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
          />
        </Form.Item>





     

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingRight: "25px",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
          >
            Clear Filter
          </Button>
          <Button type="primary">Search</Button>
        </div>
      </Form>
    </div>
  );

  // const options = [
  //   { value: 'light', label: 'Light' },
  //   { value: 'bamboo', label: 'Bamboo' },
  // ];

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Search toggle for mobile
  const [showSearch, setShowSearch] = useState(false);
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              // allowClear={{clearIcon: <CloseOutlined />}}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search Contacts"
                enterButton
              />
            </AutoComplete>

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: '560px', width: '100%' }}
              placement="bottomRight"
              trigger={["click"]}
              onOpenChange={(e) => setActiveClass(e)}
              className="advance-search-dropdown modal_advanced-searchbox"
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveClass(!activeClass);
                }}
              >
                <Space>
                  <FilterOutlined
                    style={{ opacity: "0.5", color: "#1A7f93" }}
                    className={`${activeClass ? "active" : "inactive"}`}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group modal_group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

         
          <div className="profile">
            <Dropdown overlay={menu}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <img
                    src="./pro-icon.png"
                    alt="This is a Logo"
                    style={{ width: "24px", objectFit: "cover" }}
                  ></img>
                  <span className="user-name"> MMGY Delivery</span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
}

export default FindDuplicate_searchbox;
