import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { updateDependentCategoriesApi } from "../../../../../../../apis/eventsApi";
import {
  AddonCategoryDetailsRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../../../url-routes/urlRoutes";
import { Button, Skeleton, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SOMETHING_WENT_WRONG } from "../../../../../../../utils/constants";

function EditDependentCategoryHeader({
  editableCategories,
  eventId,
  categoryId,
  isLoading,
  setIsLoading,
  payloadData,
  addonId,
}) {
  const navigate = useNavigate();

  const updateDependentCategories = async (payload) => {
    setIsLoading(true);
    const response = await updateDependentCategoriesApi(payload);
    if (response.status) {
      message.success("Dependent Categories Updated Successfully");
      navigate(
        addonId
          ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
          : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
    setIsLoading(false);
  };

  const onSaveHandler = (e) => {
    const payload = {
      data: {
        event_uuid: editableCategories?.category_details?.event?.cat_event_uuid,
        category_uuid: editableCategories?.category_details?.event_cat_uuid,
        add_on_categories: payloadData,
      },
    };

    updateDependentCategories(payload);
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => navigate(-1)}
          />
          <div className="">
            <p style={{ margin: "5px" }}>
              Edit Dependent Categories{" "}
              {isLoading ? (
                <Skeleton.Button shape="round" style={{ width: "170px" }} />
              ) : (
                `(${
                  editableCategories?.category_details?.event_cat_name || ""
                })`
              )}{" "}
            </p>
            {isLoading ? (
              <Skeleton.Input style={{ width: "450px" }} />
            ) : (
              <Link
                to={
                  addonId
                    ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                    : `${EventDetailsRoute}?id=${eventId}`
                }
              >
                <p
                  className="header_smalltext cta_hover_color"
                  style={{ margin: "5px" }}
                >
                  {editableCategories?.category_details?.event
                    ?.cat_event_name || "event name pending"}{" "}
                  (
                  {editableCategories?.category_details?.event?.cat_event_id ||
                    ""}
                  )
                </p>
              </Link>
            )}
          </div>
        </h3>

        <div className="breadcrum_buttons">
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={isLoading}
            // disabled={isLoading}
            type="primary"
            onClick={(e) => {
              onSaveHandler(e);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditDependentCategoryHeader;
