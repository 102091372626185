import "antd/dist/antd.css";
import { Button, Table, Modal, Input, Checkbox, message } from "antd";
import { DeleteOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import "./ContactsListContainer.less";
import { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { multipleContactsDelete } from "../../../redux/features/contactFeatures/contactSlice";
import DataLoader from "../../../others/Loaders/DataLoader";
import { customRequestHeader, Cookie } from "../../../utils/cutomRequestHeader";
import { deleteContactApi } from "../../../apis/contactApi";
import { decodeContent } from "../../../utils/contentParser";
import { createFilterTags } from "../../../others/util/filterTagsCreator";
import { permissionChecker } from "../../../utils/permisssionChecker";
const Option = Select.Option;

const ContactsListContainer = ({
  contactsList,
  totalCount,
  isLoading,
  urlParamsObject,
  setUrlParamsObject,
  setIsContactUpdated,
  isContactUpdated,
}) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [deleteList, setDeleteList] = useState([]);
  const { isFilterApplied, searchFilters } = useSelector(
    (state) => state.contacts
  );
  const navigate = useNavigate()
  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu contacts");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  const columns = [
    {
      key: "1",
      title: "Name",
      dataIndex: "name",
      width: 190,
      render: (record, values) => {
        return (
          <>
            {permissionChecker("view contact") ? (
              <Link to={`/contact/details?id=${values.id}`}>
                <div>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      position: "relative",
                      margin: "0",
                    }}
                  >
                    {values.key}
                  </span>
                  <div>
                    <span>{record}</span>
                  </div>
                </div>
              </Link>
            ) : (
              <div>
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    position: "relative",
                    margin: "0",
                  }}
                >
                  {values.key}
                </span>
                <div>
                  <span>{record}</span>
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "2",
      title: "Phone",
      dataIndex: "phone",
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "180px",
              }}
            >
              {record && (
                <>
                  <p>
                    <PhoneOutlined
                      style={{ paddingRight: "6px", transform: "scaleX(-1)" }}
                    />{" "}
                  </p>
                  <div>
                    <p>{record?.split(",")?.[0] || ""}</p>
                    <p>{record?.split(",")?.[1] || ""}</p>
                  </div>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "3",
      title: "Email",
      dataIndex: "email",
      render: (record) => {
        return (
          <>
            <div>
              {record && (
                <>
                  <p className="email_wrap">
                    <MailOutlined style={{ paddingRight: "6px" }} /> {record}
                  </p>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "4",
      title: "Organization Type",
      dataIndex: "organization_type",
    },
    {
      key: "5",
      title: "Organization",
      dataIndex: "organization",
    },
    {
      key: "6",
      title: "Elite Traveler Status",
      dataIndex: "elite_traveler",
    },
  ];
  const onSelectChange = (selectList, selectObj) => {
    setDeleteList(selectObj);
  };
  const rowSelection = {
    deleteList,
    onChange: onSelectChange,
  };
  const handleSorting = (value) => {
    if (value === "recently_read") {
      setUrlParamsObject({ count: 10, order: "recently_read", page: 1 });
    } else {
      setUrlParamsObject({ ...urlParamsObject, order: value });
    }
  };
  const contactsListData = contactsList?.map((contact, index) => {
    return {
      key: contact?.id || "",
      id: contact?.uuid || "",
      name: decodeContent(contact?.name || ""),
      phone: `${
        contact?.field_cell_phone
          ? `${contact?.field_cell_phone_extension}-${contact?.field_cell_phone}`
          : ""
      }${
        contact?.field_other_phone
          ? `, ${contact?.field_other_phone_extension}-${contact?.field_other_phone}`
          : ""
      }`,
      email: contact?.field_email_address || "",
      organization: decodeContent(contact?.field_organization || ""),
      organization_type: contact?.field_organization_type || "",
    };
  });

  const multipleContactDelete = async (ids) => {
    const results = [];
    try {
      for (let i = 0; i < ids.length; i++) {
        let contactId = ids[i].id;
        let reqHeaders = customRequestHeader({ token: Token });
        let payload = {
          data: {
            type: "contact--contact",
            id: ids[i].id,
          },
        };
        const response = await deleteContactApi({
          reqHeaders,
          contactId,
          payload,
        });
        results.push({ id: ids[i].name, success: response.status });
        if (response.status) {
          message.success(`${ids[i].name} Deleted Successfully.`);
          setDeleteList([]);
        } else {
          message.error(`Failed To Delete ${ids[i].name}.`);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsContactUpdated(!isContactUpdated);
  };
  const onMultipleDeleteContacts = () => {
    Modal.confirm({
      title: "Are you sure you want to delete selected contacts?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        if (!!deleteList?.length) {
          return multipleContactDelete(deleteList);
        }
      },
    });
  };

  return (
    <>
      <div className="table_filter table_filter--data">
        {isFilterApplied && (
          <div className="filter-applied--wrapper">
            <div className="filter-title">Filter(s) Applied:</div>{" "}
            {createFilterTags(searchFilters)}
          </div>
        )}
        <>
          <span>Sort by :</span>
          <Select
            disabled={isLoading}
            style={{
              width: 165,
              marginLeft: "10px",
              marginRight: "12px",
            }}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={urlParamsObject.order}
            onChange={(e) => {
              handleSorting(e);
            }}
          >
            <Option value="recently_read" label="Recently Viewed">Recently Viewed</Option>
            <Option value="ASC" label="Ascending">Ascending</Option>
            <Option value="DESC" label="Descending">Descending</Option>
          </Select>
          {deleteList?.length !== 0 && permissionChecker("delete contact") && (
            <Button
              className="delete_btn"
              onClick={() => {
                onMultipleDeleteContacts();
              }}
            >
              <DeleteOutlined /> Delete Selected
            </Button>
          )}
        </>
      </div>
      <Table
        className="table_listing contact-table"
        columns={columns}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        dataSource={contactsListData}
        rowSelection={rowSelection}
        pagination={
          urlParamsObject?.order === "recently_read"
            ? false
            : {
                current: Number(urlParamsObject.page),
                pageSize: urlParamsObject.count,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                total: totalCount,
                position: ["topRight", "bottomRight"],
                onChange: (pageNo, pageSize) => {
                  setUrlParamsObject({
                    ...urlParamsObject,
                    page: pageNo,
                    count: pageSize,
                  });
                },
              }
        }
      />
    </>
  );
};
export default ContactsListContainer;
