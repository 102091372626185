import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EventsListingHeader from "../../components/Events/EventsListing/EventsListingHeader";
import EventsListingTabs from "../../components/Events/EventsListing/EventsListingTabs";
import EventsSearchbar from "../../components/Events/EventsSearchbar";
import { EventsListingRoute } from "../../url-routes/urlRoutes";
import { useLocation, useSearchParams } from "react-router-dom";
import { urlParser } from "../../utils/contentParser";
const { Header } = Layout;

const EventsListingPage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [{ title: "Events", path: EventsListingRoute }];
  const page = searchParams.get("page") || 1;
  const count = searchParams.get("count") || 10;
  const order = searchParams.get("order") || "ASC";
  const tab = searchParams.get("tab") || "All Events";
  const [urlParamsObject, setUrlParamsObject] = useState({
    ...urlParser(window.location.search),
    count,
    order,
    page,
    tab,
  });

  // useEffect(() => {
  //   console.log("listing page 2");
  //   console.log("urlParamsObject ,,,,,", urlParamsObject);
  //   setSearchParams(urlParamsObject);
  // }, [urlParamsObject]);

  // const params = urlParser(window.location.search);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              // below two props are mandatory
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          <BreadCrumbGenerator
            breadCrumbs={breadCrumbs}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
          />
          <div className="events-header">
            <EventsListingHeader
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
            />
          </div>
          <EventsListingTabs
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EventsListingPage;
