import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import SystemInformation from "../../../../../others/CommonSystemInformation/SystemInformation";
import AssetTabContainer from "../../AssetsTab/AssetTabContainer";
import EventCategoriesListing from "../../CategoriesTab/CategoriesListing/EventCategoriesListing";
import DiscountsListing from "../../DiscountTab/DiscountsListing";
import AddonFeesListing from "../../FeesTab/AddonFeesListing";
import ItineraryListing from "../../ItinerariesTab/ItineraryListing/ItineraryListing";
import AddonAccountingInfo from "../AddonDetailsSections/AddonAccountingInfo";
import AddonFlightListing from "../AddonDetailsSections/AddonFlightListing";
import AddonGeneralInfo from "../AddonDetailsSections/AddonGeneralInfo";
import AddonPaymentSetup from "../AddonDetailsSections/AddonPaymentSetup";
import AddonReservations from "../AddonDetailsSections/AddonReservations";
import AddonSystemOptionInfo from "../AddonDetailsSections/AddonSystemOptionInfo";

const AddonDetailsContainer = ({
  eventId,
  addonId,
  singleAddonData,
  loadingData,
  addonType,
}) => {
  const [accommodationTotalBar, setAccommodationTotalBar] = useState({});
  return (
    <div>
      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonGeneralInfo
          addonData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          isLoading={loadingData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonSystemOptionInfo
          addonData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          isLoading={loadingData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonReservations
          addonType={addonType}
          singleAddonData={singleAddonData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonPaymentSetup
          isAddon={true}
          addonData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          isLoading={loadingData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonAccountingInfo
          addonData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          isLoading={loadingData}
        />
      </Content>

      <Content
        className="site-layout-background optional_accommodation_table"
        style={{ marginBottom: "20px" }}
      >
        <EventCategoriesListing
          eventData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          setAccommodationTotalBar={setAccommodationTotalBar}
          accommodationTotalBar={accommodationTotalBar}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AddonFeesListing
          addonId={addonId}
          eventId={eventId}
          feeType={"addon"}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <DiscountsListing
          addonId={addonId}
          eventId={eventId}
          discountType={"addon"}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <AssetTabContainer
          eventData={singleAddonData}
          eventId={eventId}
          addonId={addonId}
          assetType={"addon"}
        />
      </Content>
      {Object.keys(singleAddonData).length ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          {addonType === "air" ||
          addonType === "pre_extension_air" ||
          addonType === "post_extension_air" ? (
            <AddonFlightListing eventId={eventId} addonId={addonId} />
          ) : (
            <ItineraryListing
              eventId={eventId}
              addonId={addonId}
              itineraryType={"addon"}
            />
          )}
        </Content>
      ) : null}

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <SystemInformation
          user_id={singleAddonData?.user_id || {}}
          modified_by={singleAddonData?.modified_by || {}}
          created={singleAddonData?.created}
          changed={singleAddonData?.changed}
          loading={loadingData}
        />
      </Content>
    </div>
  );
};

export default AddonDetailsContainer;
