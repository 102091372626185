import React, { useEffect, useRef, useState } from "react";
import EditActivityHeader from "./EditActivityHeader";
import EditActivityViewForm from "./EditActivityViewForm";
import { Form, message } from "antd";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleActivity } from "../../../../redux/features/contactFeatures/contactSlice";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

const EditActivityContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activityDetails, setActivityDetails] = useState({});
  const dispatch = useDispatch();
  const { singleActivity, taskActivityUpdated, documentFileUpdated } =
    useSelector((state) => state.contacts);
  const { loadingSingleActivity, singleActivityResponse } = singleActivity;
  const activityId = searchParams.get("activityId");
  const activityRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    if (activityRef.current) {
      if (singleActivityResponse.status) {
        setActivityDetails(singleActivityResponse?.data?.data);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    activityRef.current = true;
  }, [singleActivityResponse]);

  useEffect(() => {
    // if (singleActivityResponse.status) {
    //   setActivityDetails(singleActivityResponse?.data?.data);
    // }
    dispatch(getSingleActivity({ activityId }));
  }, []);

  return (
    <>
      <div className="events-header">
        <EditActivityHeader activityDetails={activityDetails} form={form} />
      </div>

      <EditActivityViewForm form={form} activityDetails={activityDetails} />
    </>
  );
};

export default EditActivityContainer;
