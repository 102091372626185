import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function InsuranceHeader({
  insurancePaymentInfo,
  form,
  loader,
  backPath
}) {
  const navigate = useNavigate()
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={()=>{
              navigate(backPath)
            }}
          />
          <div className="header_res">
            <p>Pay for Item: {insurancePaymentInfo?.name}</p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button type="primary" loading={loader} onClick={()=>{
            form.submit()
          }}>
            Submit
          </Button>

          <Button
            type="primary"
            onClick={()=>{
              navigate(backPath)
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InsuranceHeader;
