import {
  Button,
  Checkbox,
  Divider,
  Table
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import stripeLogo from "../../../../assests/images/image7.svg";
import {
  ReservationPaymentDetailRoute,
  ReservationPaymentRoute,
  ReservationPlannedPaymentDetailRoute,
  ReservationPlannedPaymentRoute,
  ReservationSchedulePaymentRoute,
} from "../../../../url-routes/urlRoutes";
import { addCommasInPricing } from "../../../../utils/contentParser";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const columns = [
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Date",
    dataIndex: "date",
  },

  {
    title: "Payment Method",
    dataIndex: "payment_method",
  },

  {
    title: "Status",
    dataIndex: "status",
  },

  {
    title: "Amount",
    dataIndex: "amount",
    render: (value, recordObj) =>
      recordObj?.isTotal ? (
        <b>{`$${addCommasInPricing(value?.toString())}`}</b>
      ) : (
        `$${addCommasInPricing(value?.toString())}`
      ),
  },

  {
    title: "Applied",
    dataIndex: "applied",
    render: (value, recordObj) =>
      recordObj?.isTotal ? (
        <b>{`$${addCommasInPricing(value?.toString())}`}</b>
      ) : (
        `$${addCommasInPricing(value?.toString())}`
      ),
  },

  {
    title: "Pending",
    dataIndex: "pending",
    render: (value, recordObj) =>
      recordObj?.isTotal ? (
        <b>{`$${addCommasInPricing(value?.toString())}`}</b>
      ) : (
        `$${addCommasInPricing(value?.toString())}`
      ),
  },
];

function ReservationPayments({
  reservationData,
  reservationId,
  paymentList,
  setCancelledTravellerStatus,
  cancelledTravellerStatus,
  loadingPaymentListing,
}) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const paramsId = searchParams.get("id");
  const { highlightSection } = useSelector((state) => state.reservation);

  useEffect(() => {
    setLocalLoading(false);
  }, [paymentList]);

  useEffect(() => {
    setDataTotal([
      {
        description: <b>Total</b>,
        date: "",
        payment_method: "",
        status: "",
        isTotal: true,
        amount: paymentList?.total || 0,
        applied: paymentList?.applied || 0,
        pending: paymentList?.pending || 0,
      },
    ]);
    setData(
      paymentList?.planned_payments?.length
        ? paymentList?.planned_payments?.map((plannedPayment) => ({
            description: (
              <>
             {permissionChecker("modify payments")? (<Link
              to={`${ReservationPlannedPaymentDetailRoute}?id=${reservationId}&paymentId=${plannedPayment?.planned_payment_uuid}`}
            >
              {plannedPayment?.planned_payment_desc}
            </Link>): plannedPayment?.planned_payment_desc}
              </>
            ),
            date: plannedPayment?.planned_payment_date
              ? moment(plannedPayment?.planned_payment_date).format(
                  "MM/DD/YYYY"
                )
              : "",
            payment_method: plannedPayment?.planned_payment_method,
            status: plannedPayment?.planned_payment_status,
            amount: plannedPayment?.planned_payment_amount || 0,
            applied: plannedPayment?.planned_payment_applied || 0,
            pending: plannedPayment?.planned_payment_pending || 0,
            children: plannedPayment?.payments?.map((pay) => ({
              description: (
                <>
                {permissionChecker("modify payments") ? (
                   <Link
                   to={`${ReservationPaymentDetailRoute}?id=${reservationId}&paymentId=${pay?.payment_uuid}`}
                 >
                   {pay?.payment_desc}
                 </Link>
                ) : pay?.payment_desc}
                </>
               
              ),
              date: pay?.payment_date
                ? moment(pay?.payment_date).format("MM/DD/YYYY")
                : "",
              payment_method: pay?.payment_method,
              status: pay?.payment_status,
              amount: pay?.payment_amount || 0,
              applied: pay?.payment_applied || 0,
              pending: pay?.payment_pending || 0,
            })),
          }))
        : []
    );
  }, [paymentList]);

  useEffect(() => {
    if (data.length) {
      expandAllRows(data);
    }
  }, [data]);

  const expandAllRows = (datasource) => {
    const allKeys = datasource.reduce((keys, item) => {
      if (item.children) {
        return [...keys, item.key, ...item.children.map((child) => child.key)];
      }
      return keys;
    }, []);
    setExpandedRowKeys(allKeys);
  };

  useEffect(() => {
    if (data) {
      let tData = data.concat(dataTotal);
      setTableData(tData);
    }
  }, [data]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Payments - {paymentList?.traveler}</h4>
        <span>
          <div className="buttons_flex" style={{ alignItems: "center" }}>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setLocalLoading(true);
                  setCancelledTravellerStatus([
                    ...cancelledTravellerStatus,
                    paymentList?.traveler_id,
                  ]);
                } else {
                  setLocalLoading(true);
                  let updatedTravellerId = [...cancelledTravellerStatus].filter(
                    (travellerId) => travellerId !== paymentList?.traveler_id
                  );
                  setCancelledTravellerStatus(updatedTravellerId);
                }
              }}
            >
              Show Canceled
            </Checkbox>
           {permissionChecker("modify payments")? (<>
            {reservationData?.reservation_status?.key === "active" ||
            reservationData?.reservation_status?.key === "revise" ||
            reservationData?.reservation_status?.key === "cancel_pending" ||
            reservationData?.reservation_status?.key === "cancelled" ? (
              <Link
                to={`${ReservationPaymentRoute}?id=${paramsId}&reservation-id=${
                  reservationData.drupal_internal__id
                }&traveler-uuid=${paymentList?.traveler_uuid}&event-name=${
                  reservationData?.select_event?.name || ""
                }`}
              >
                <Button type="">
                  Pay via{" "}
                  <img src={stripeLogo} style={{ paddingLeft: "7px" }}></img>{" "}
                </Button>
              </Link>
            ) : null}

            <Button
              onClick={() => {
                navigate(
                  `${ReservationPlannedPaymentRoute}?id=${reservationId}&travellerId=${paymentList?.traveler_uuid}&status=${reservationData?.reservation_status?.key}`
                );
              }}
              type=""
            >
              Add Planned Payment
            </Button>
            {/* <Link to="/Reservationpayment_page"> */}
            <Button
              type="primary"
              danger={highlightSection?.payments?.includes(
                paymentList?.traveler
              )}
              onClick={() => {
                navigate(
                  `${ReservationSchedulePaymentRoute}?id=${reservationId}&reservationId=${
                    reservationData?.drupal_internal__id
                  }&travellerUuid=${
                    paymentList?.traveler_uuid || ""
                  }&travellerId=${paymentList?.traveler_id || ""}${
                    reservationData?.select_event?.field_default_pay_terms
                      ? `&defaultTerm=${reservationData?.select_event?.field_default_pay_terms?.key}`
                      : ""
                  }`
                );
              }}
            >
              Schedule
            </Button>
           </>):null}
            {/* </Link> */}
          </div>
        </span>
      </div>
      <Divider className="global_divider" />
      <Table
        className="payment_table pay_table"
        columns={columns}
        dataSource={tableData}
        expandable={{
          expandedRowKeys,
          expandIcon: () => null,
        }}
        pagination={false}
        loading={{
          spinning: loadingPaymentListing && localLoading,
          size: "large",
        }}
      />
    </>
  );
}

export default ReservationPayments;
