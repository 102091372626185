import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const data = [
  {
    key: "1",
    label: "Smoking",
    input: "No",
  },
  {
    key: "2",
    label: "Cross Ref 1 Name",
    input: "",
  },
  {
    key: "3",
    label: "Cross Ref 2",
    input: "",
  },
  {
    key: "4",
    label: "Cross Ref 3",
    input: "",
  },
  {
    key: "5",
    label: "Cross Ref 4",
    input: "",
  },
  {
    key: "6",
    label: "Surprise",
    input: "No",
  },
  {
    key: "7",
    label: "Bed Request",
    input: "One",
  },


];

function Guest_Profile() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4>Traveler Profile</h4>
        <span>
          <Link to="/Reservation_editform">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Guest_Profile;
