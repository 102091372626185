import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Modal, Skeleton, Tooltip, message } from "antd";

import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { deleteEventFeeApi } from "../../../../apis/eventsApi";
import {
  AddonFeeRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventFeesRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

function AddonFeeDetailsHeader({
  feeDetailsData,
  loadingFeeDetailData,
  feeType,
}) {
  const navigate = useNavigate();
  const [searchParamas, setSearchParams] = useSearchParams();
  const eventId = searchParamas.get("id");
  const addonId = searchParamas.get("addonId");
  const feeId = searchParamas.get("feeId");

  const deleteAddonFee = async (deleteId) => {
    const response = await deleteEventFeeApi(deleteId);
    if (response.status) {
      message.success("Fee Deleted Successfully");
      navigate(
        feeType === "event"
          ? `${EventDetailsRoute}?id=${eventId}#Fees`
          : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
      );
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const ondeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this Fee?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteAddonFee(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const onCopyHanlder = (eId, aId, fId) => {
    navigate(
      feeType === "event"
        ? `${EventFeesRoute}?id=${eId}&copyId=${fId}`
        : `${AddonFeeRoute}?id=${eId}&addonId=${aId}&copyId=${fId}`
    );
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(
                feeType === "event"
                  ? `${EventDetailsRoute}?id=${eventId}#Fees`
                  : `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
              )
            }
          />
          {loadingFeeDetailData ? (
            <Skeleton.Input active={true} style={{ width: "400px" }} />
          ) : (
            <div className="header_res">
              <p>
                {feeDetailsData?.name?.name || ""} (
                {feeDetailsData?.field_fee_type?.name || ""})
              </p>
             {permissionChecker("view events") ?  <Link
                to={
                  addonId
                    ? `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
                    : `${EventDetailsRoute}?id=${eventId}`
                }
                className="header_smalltext"
              >
                {feeDetailsData?.field_event?.name || ""}{" "}
                {feeDetailsData?.field_event?.drupal_internal__id
                  ? `(${feeDetailsData?.field_event?.drupal_internal__id})`
                  : ""}
              </Link> : null}
            </div>
          )}
        </h3>
        <div className="buttons_flex">
        {permissionChecker("add/edit event fee") ?  <Link
            to={
              feeType === "event"
                ? `${EventFeesRoute}?id=${eventId}&editId=${feeId}`
                : `${AddonFeeRoute}?id=${eventId}&addonId=${addonId}&editId=${feeId}`
            }
          >
            <Tooltip title="Edit">
              <Button icon={<EditOutlined />} />
            </Tooltip>
          </Link>:null}
          {permissionChecker("copy event fees") ? <Tooltip title="Copy">
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                onCopyHanlder(eventId, addonId, feeId);
              }}
            />
          </Tooltip> : null}
         {permissionChecker("delete fees entities") ?  <Tooltip title="Delete">
            <Button
              className="delete_btn"
              onClick={(e) => {
                e.preventDefault();
                ondeleteHandler(feeId);
              }}
              icon={<DeleteOutlined />}
            />
          </Tooltip> : null}
        </div>
      </div>
    </div>
  );
}

export default AddonFeeDetailsHeader;
