import { Tabs, message, Skeleton, Modal } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DataLoader from "../../../others/Loaders/DataLoader";
import GroupDetailsCruises from "./GroupDetailsCruises";
import GroupDetailsTours from "./GroupDetailsTours";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllGroupDetails } from "../../../redux/features/groupDetailsFeatures/groupDetailsSlice";
import {
  deleteExtensionApi,
  deleteGroupDetailApi,
} from "../../../apis/groupDetailsApi";
import { toSnakeCase } from "../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const GroupDetailsListingTabs = ({ urlParamsObject, setUrlParamsObject }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabArray = ["Cruise", "Tour", "Charter Cruise", "Cruise/Tour"];
  const [searchParams, setSearchParams] = useSearchParams();
  const { allGroupDetails } = useSelector((state) => state.groupDetails);
  const { allGroupDetailsResponse, loadingAllGroupDetails } = allGroupDetails;
  const [currentTab, setCurrentTab] = useState(
    tabArray.indexOf(urlParamsObject?.tab) + 1
  );
  const [notFound, setNotFound] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const groupDetailListingRef = useRef();
  const permisssions = JSON.parse(localStorage.getItem("authorization"))?.permissions
  console.log("🚀 ~ GroupDetailsListingPage ~ permisssions:", permisssions)

  useEffect(() => {
    if (groupDetailListingRef?.current) {
      // console.log("lllllllllllll", allGroupDetailsResponse);
      if (allGroupDetailsResponse?.status) {
        setListingData(allGroupDetailsResponse?.data?.[0]);
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    groupDetailListingRef.current = true;
  }, [allGroupDetailsResponse]);

  useEffect(() => {
    let tab = urlParamsObject?.tab || "1";
    if (tab == "1") {
      setCurrentTab(tab);
    } else {
      let num = tabArray.indexOf(tab);
      if (num === -1) {
        setNotFound(true);
      } else {
        setNotFound(false);
        setCurrentTab(`${num + 1}`);
      }
    }
  }, [urlParamsObject.tab]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setUrlParamsObject({
        count: 10,
        order: "ASC",
        page: 1,
        tab: "Cruise",
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (currentTab) {
      for (let prop in urlParamsObject) {
        if (!urlParamsObject[prop]) {
          delete urlParamsObject[prop];
        }
      }
      const { order, count, tab, ...rest } = urlParamsObject;

      const urlContructor = {
        ...rest,
        // type: tabArray[currentTab ? currentTab - 1 : 0],
        items_per_page: Number(urlParamsObject?.count),
        page: Number(urlParamsObject?.page) - 1,
        sort_order: urlParamsObject?.order,
        sort_by: "name",
        tab: toSnakeCase(tab),
      };
      dispatch(getAllGroupDetails(urlContructor));
    }
  }, [currentTab, urlParamsObject, isDeleted]);

  useEffect(() => {
    setSearchParams(urlParamsObject);
  }, [urlParamsObject]);

  const deleteGroupDetail = async (deleteId) => {
    const response = await deleteGroupDetailApi(deleteId);
    if (response.status) {
      message.success("Deleted successfully");
      setIsDeleted(!isDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const deleteExtension = async (deleteId) => {
    const response = await deleteExtensionApi(deleteId);
    if (response.status) {
      message.success("Deleted successfully");
      setIsDeleted(!isDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id, entity) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete ?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return entity === "groupDetails"
            ? deleteGroupDetail(id)
            : deleteExtension(id);
        },
      });
    } else {
      message.error("id is missing");
    }
  };

  const handleTabChange = (key, value) => {
    setCurrentTab(key);
    setUrlParamsObject({
      count: 10,
      order: "ASC",
      page: 1,
      tab: tabArray[Number(key) - 1],
    });
  };

  function getTotalItems(index, item) {
    return urlParamsObject?.order === "recently_read"
      ? listingData?.["recently_read"]?.pager?.total_items ?? 0
      : listingData?.[toSnakeCase(item)]?.pager?.total_items ?? 0;
  }

  function getTabData(index, item) {
    return urlParamsObject?.order === "recently_read"
      ? listingData?.["recently_read"] || []
      : listingData?.[toSnakeCase(item)] || [];
  }

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  if (!currentTab) {
    return <DataLoader />;
  }
  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key, value) => {
        handleTabChange(key, value);
      }}
    >
      <Tabs.TabPane
        tab={
          <>
            <span>Cruise </span>
            {urlParamsObject?.order !== "recently_read" && (
              <span className="small_text">
                {/* {loadingAllGroupDetails ? (
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    style={{ width: "20px" }}
                  />
                ) : ( */}
                ({getTotalItems(1, "cruise") ?? 0}){/* )} */}
              </span>
            )}
          </>
        }
        key="1"
      >
        <div className="activity_details pd-16 bg-white">
          <GroupDetailsCruises
            listingData={getTabData(1, "cruise")}
            loadingAllGroupDetails={loadingAllGroupDetails}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            onDeleteHandler={onDeleteHandler}
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane
        tab={
          <>
            <span>Tour </span>
            {urlParamsObject?.order !== "recently_read" && (
              <span className="small_text">
                {/* {loadingAllGroupDetails ? (
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    style={{ width: "20px" }}
                  />
                ) : ( */}
                ({getTotalItems(1, "tour") ?? 0}){/* )} */}
              </span>
            )}
          </>
        }
        key="2"
      >
        <div className="activity_details pd-16 bg-white">
          <GroupDetailsTours
            listingData={getTabData(1, "tour")}
            loadingAllGroupDetails={loadingAllGroupDetails}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            onDeleteHandler={onDeleteHandler}
            type="tour"
            tab="Tour"
            editRoute="/group-details/tour"
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane
        tab={
          <>
            <span>Charter Cruise </span>
            {urlParamsObject?.order !== "recently_read" && (
              <span className="small_text">
                {/* {loadingAllGroupDetails ? (
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    style={{ width: "20px" }}
                  />
                ) : ( */}
                ({getTotalItems(1, "charter_cruise") ?? 0}){/* )} */}
              </span>
            )}
          </>
        }
        key="3"
      >
        <div className="activity_details pd-16 bg-white">
          <GroupDetailsTours
            listingData={getTabData(1, "charter_cruise")}
            loadingAllGroupDetails={loadingAllGroupDetails}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            onDeleteHandler={onDeleteHandler}
            type="charter-cruise"
            tab="Charter Cruise"
            editRoute="/group-details/charter-cruise"
          />
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane
        tab={
          <>
            <span>Cruise/Tour </span>
            {urlParamsObject?.order !== "recently_read" && (
              <span className="small_text">
                {/* {loadingAllGroupDetails ? (
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    style={{ width: "20px" }}
                  />
                ) : ( */}
                ({getTotalItems(1, "cruise_tour") ?? 0}){/* )} */}
              </span>
            )}
          </>
        }
        key="4"
      >
        <div className="activity_details pd-16 bg-white">
          <GroupDetailsTours
            listingData={getTabData(1, "cruise_tour")}
            loadingAllGroupDetails={loadingAllGroupDetails}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            onDeleteHandler={onDeleteHandler}
            type="cruise-tour"
            tab="Cruise/Tour"
            editRoute="/group-details/cruise-tour"

          />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default GroupDetailsListingTabs;
