import { CopyOutlined, DeleteOutlined, MailOutlined } from "@ant-design/icons";
import { Form, Modal, Select, Table, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTravellerApi } from "../../../apis/reservationApi";
import { triggerTravellerRemoved } from "../../../redux/features/reservationFeatures/reservationSlice";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import "./Res_table.less";

function Res_table(props) {
  const dispatch = useDispatch();
  const { isTravellerRemoved } = useSelector((state) => state.reservation);
  const { tableData, index, setCopiedIndex, relationLoading, relations } =
    props;

  const columns = [
    {
      title: tableData?.name,
      dataIndex: tableData?.name,
    },
    {
      title: (
        <div>
          {tableData.dob && (
            <p style={{ margin: "0px" }}>
              {tableData?.dob}
              <span
                className="span_text"
                style={{ marginLeft: "6px" }}
              >{`Age ${tableData?.age}`}</span>
            </p>
          )}
        </div>
      ),
      dataIndex: tableData?.dob,
    },
    {
      title: (
        <div>
          {tableData.email && (
            <p style={{ margin: "0px" }}>
              <MailOutlined style={{ marginRight: "6px" }} />
              {tableData?.email}
            </p>
          )}
        </div>
      ),
      dataIndex: tableData?.email,
    },
    {
      title: (
        <div className="select_header">
          <Form.Item
            label="Relationship"
            className="asterick-align"
            name={`field_relation_traveler-${index}`}
            rules={[
              {
                required: true,
                message: "Please Select Relation",
              },
            ]}
          >
            <Select
              defaultValue={tableData?.relationship_id}
              style={{
                width: 145,
              }}
              options={relations?.map((tag) => ({
                value: tag?.drupal_internal__revision_id,
                label: tag?.name,
              }))}
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={relationLoading}
              allowClear
            />
          </Form.Item>
        </div>
      ),
      dataIndex: "address",
    },
    {
      title: (
        <>
          <div>
            <div className="action_flex">
              <a
                href="#"
                className="member_hover"
                onClick={() => {
                  setCopiedIndex(index);
                  message.success("Copied successfully");
                }}
              >
                <CopyOutlined /> Copy To Others
              </a>
            </div>
          </div>
        </>
      ),
      dataIndex: "address",
    },
  ];

  if (
    ["quote", "waitlist", "temporary_hold", "pending"].includes(
      tableData?.reservationStatus
    )
  ) {
    columns.push({
      title: (
        <>
          <div>
            <DeleteOutlined
              onClick={(e) => {
                e.preventDefault();
                onDeleteHandler(tableData?.travellerUUid);
              }}
              className="action_delete delete_btn"
            />
          </div>
        </>
      ),
      dataIndex: "address",
    });
  }

  const deleteTraveller = async (travellerUuid) => {
    const res = await deleteTravellerApi(travellerUuid);
    if (res.status) {
      message.success("Traveler Deleted Successfully");
      dispatch(triggerTravellerRemoved(!isTravellerRemoved));
    } else {
      message.error(
        res?.data?.response?.data?.errors[0]?.detail || SOMETHING_WENT_WRONG
      );
    }
  };
  const onDeleteHandler = (travellerUuid) => {
    if (travellerUuid) {
      Modal.confirm({
        title: "Are you sure you want to delete this traveler?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteTraveller(travellerUuid);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <>
      {tableData ? (
        <div className="Res_tables">
          <Table className="plus_change" columns={columns} />
        </div>
      ) : null}
    </>
  );
}
export default Res_table;
