import {
  Table,
  Divider,
  Space,
  Menu,
  Dropdown,
  Button,
  Modal,
  message,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import React from "react";
import DataLoader from "../../../../others/Loaders/DataLoader";
import TaskDocumentFile from "./TaskDocumentFile";
import TaskDocumentLink from "./TaskDocumentLink";
import { editTaskActivityApi } from "../../../../apis/contactApi";
import { useDispatch } from "react-redux";
import { documentFileUpdater } from "../../../../redux/features/contactFeatures/contactSlice";
import {
  SOMETHING_WENT_WRONG,
  UNABLE_TO_DELETE,
} from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const TaskDocuments = ({ activityDetails, loadingSingleActivity }) => {
  const { field_task_documents } = activityDetails;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const activityId = searchParams.get("activityId");

  const deleteTaskDocument = async (record, id) => {
    const payload = {
      data: {
        type: "activity--activity",
        id: activityId,
        relationships: {
          field_task_documents: {
            data: field_task_documents
              ?.filter((doc, index) => index !== id)
              .map(({ type, id }) => ({ type, id })),
          },
        },
      },
    };

    const deleteRes = await editTaskActivityApi({
      payload,
      activityId,
    });
    if (deleteRes.status) {
      dispatch(documentFileUpdater(deleteRes.data));
      message.success("Document Deleted");
    } else {
      message.error(UNABLE_TO_DELETE || SOMETHING_WENT_WRONG);
    }
  };

  const onDeleteHandler = (record, index) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteTaskDocument(record, index);
      },
    });
  };

  const data =
    field_task_documents?.data?.length === 0
      ? []
      : field_task_documents?.map((item, index) => {
          return {
            key: index,
            file_name: (
              <>
               {permissionChecker("view task document") ? ( <a href={`${item?.meta?.media_data?.uri}`} target="_blank">
                  {item?.meta?.media_data?.filename || item?.name || ""}
                </a>):(
                  <span>
                    {item?.meta?.media_data?.filename || item?.name || ""}
                    </span>
                )}
              </>
            ),
            document_type:
              item?.type === "media--task_documents" ? "Document" : "Link",
          };
        });
  const columns = [
    {
      title: "Document Name",
      dataIndex: "file_name",
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      key: "4",
      title: "Actions",
      render: (record, row, index) => {
        return (
          <>{
            permissionChecker("delete task document") ? <div className="action_flex action_end">
            <DeleteOutlined
              className="action_delete"
              onClick={(e) => {
                e.preventDefault();
                onDeleteHandler(record, index);
              }}
            />
          </div> : null
          }
            
          </>
        );
      },
    },
  ];

  const menu = (
    <Menu
      items={[
        {
          label: (
            <TaskDocumentFile
              activityDetails={activityDetails}
              field_task_documents={field_task_documents}
            />
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <TaskDocumentLink
              activityDetails={activityDetails}
              field_task_documents={field_task_documents}
            />
          ),
          key: "1",
        },
      ]}
    />
  );

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Task Documents</h4>
        {permissionChecker("add task document") ? (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayClassName="add_cat"
            placement="bottomRight"
          >
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space>
                <Button type="primary">
                  <PlusOutlined /> Add
                </Button>
              </Space>
            </a>
          </Dropdown>
        ) : null}
      </div>
      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
        loading={{ spinning: loadingSingleActivity, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default TaskDocuments;
