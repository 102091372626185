import { Table } from "antd";
import "./Inventory_popup.less";
import React, { useState } from "react";
import { decodeContent } from "../../../../../../../utils/contentParser";
import { HOLD_STATUS } from "../../../../../../../utils/constants";

const columns = [
  {
    title: "Rez #",
    dataIndex: "rez",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];

const InventoryHoldStatusTable = ({ editInventory, releasingHold }) => {
  const data = [
    {
      key: "1",
      rez: (
        <>
          <span className="document_pdf">Quantity</span>
        </>
      ),
      name: <>{editInventory?.[releasingHold] ?? 0}</>,
    },
    {
      key: "2",
      rez: (
        <>
          <span className="document_pdf">Reservation Status</span>
        </>
      ),
      name: (
        <>
          {releasingHold === "field_reservation_hold_count"
            ? editInventory?.reservation_status
            : ""}
        </>
      ),
    },
    {
      key: "3",
      rez: (
        <>
          <span className="document_pdf">Comments</span>
        </>
      ),
      name: <>{decodeContent(editInventory?.field_inventory_comment) || ""}</>,
    },

    {
      key: "6",
      rez: (
        <>
          <span className="document_pdf">Status</span>
        </>
      ),
      name: (
        <>
          <div>
            {releasingHold
              ? HOLD_STATUS?.find(({ key }) => key === releasingHold)?.name
              : editInventory?.availability &&
                Number(editInventory?.availability)
              ? "Available"
              : ""}
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="inventory_popup stafhold_table"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </div>
  );
};
export default InventoryHoldStatusTable;
