import { Form, Grid, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Searchreservations_menu from "../../../components/Reservation/ReservationSearchBar";
import SchedulePlannedPaymetInfo from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/SchedulePayment/SchedulePlannedPaymetInfo";
import ReservationPayment_header from "../../../components/Reservations_components/ReservationPayment_header";
import ReservationPayment_form from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/SchedulePayment/SchedulePaymentForm";
import {
  ReservationDetailRoute,
  ReservationListingRoute,
} from "../../../url-routes/urlRoutes";
import SchedulePaymentForm from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/SchedulePayment/SchedulePaymentForm";
import {
  createSchedulePaymentApi,
  getReservationDetailApi,
  getTermFormDataApi,
} from "../../../apis/reservationApi";
import AutoPayAuthForm from "../../../components/Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/AutoPayAuthForm";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Header, Content, Sider } = Layout;

function AddEditSchedulePaymentPage() {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const travellerUuid = searchParams.get("travellerUuid");
  const travellerId = searchParams.get("travellerId");
  const reservationUuid = searchParams.get("id");
  const reservationId = searchParams.get("reservationId");
  const paymentId = searchParams.get("paymentId");
  const [term, setTerm] = useState(
    searchParams.get("defaultTerm") || "pay_in_full_immediately"
  );
  const [termFormData, setTermFormData] = useState({});
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [creationLoading, setCreationLoading] = useState(false);
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const [form] = Form.useForm();
  const breadCrumbs = [
    { title: "Reservations", path: ReservationListingRoute },
    {
      title: "Details",
      path: `${ReservationDetailRoute}?id=${reservationUuid}`,
    },
    { title: `${paymentId ? "Edit" : "Add"} Schedule Payment`, path: "" },
  ];

  useEffect(() => {
    getReservationDetail(reservationUuid);
    getFormDataByTerm({ term, travellerId, reservationId });
  }, [term]);

  const getFormDataByTerm = async (data) => {
    setFormDataLoading(true);
    const res = await getTermFormDataApi(data);
    if (res.status) {
      setFormDataLoading(false);
      setTermFormData(res?.data?.data);
    } else {
      setFormDataLoading(false);
      setTermFormData({});
    }
  };

  const onFinishHandler = (values) => {
    const payload = {
      traveler_id: termFormData?.traveler_data?.traveler_id || null,
      traveler_uuid: termFormData?.traveler_data?.traveler_uuid || null,
      total_amount: termFormData?.traveler_data?.total_amount || null,
      traveler_name: termFormData?.traveler_data?.traveler_name || null,
      transfer_balance: termFormData?.traveler_data?.transfer_balance || null,
      reservation: reservationId || null,
      paid: termFormData?.traveler_data?.paid || null,
      docref_id: values?.field_docref_id,
      authsign: values?.field_authsign,
      docsignlink: values?.field_docsignlink,
      plan: values?.terms || null,
      deposit_data: {
        amount: values?.deposit_amount ? Number(values?.deposit_amount) : null,
        due_date: values?.deposit_due_date?.format("YYYY-MM-DD") || null,
        payment_method: values?.deposit_payment_method,
      },
    };
    if (values?.terms === "pay_in_full_immediately") {
      payload.final_amount = {
        amount: values?.payment_amount ? Number(values?.payment_amount) : null,
        payment_method: values?.payment_payment_method || null,
      };
    }
    if (values?.terms === "pay_in_full_in_60_days") {
      payload.final_amount = {
        amount: values?.payment_amount ? Number(values?.payment_amount) : null,
        payment_method: values?.payment_payment_method || null,
        due_date: values?.payment_due_date
          ? values?.payment_due_date?.format("YYYY-MM-DD")
          : null,
      };
    }
    if (
      values?.terms === "payment_plan" ||
      values?.terms === "monthly_payment_plan" ||
      values?.terms === "quartely_payment_plan"
    ) {
      payload.installments = {
        amount: values?.installment_amount
          ? Number(values?.installment_amount)
          : null,
        due_date: values?.installment_due_date?.format("YYYY-MM-DD") || null,
        no_of_installement: values?.no_of_installement
          ? Number(values?.no_of_installement)
          : null,
        payment_method: values?.installment_payment_method,
        service_fee: values?.installment_service_fee
          ? Number(values?.installment_service_fee)
          : null,
      };
    }
    if (values?.terms === "deposit_2nd_deposit_and_final_payment") {
      payload.second_deposit = {
        amount: values?.second_deposit_amount
          ? Number(values?.second_deposit_amount)
          : null,
        due_date: values?.second_deposit_due_date?.format("YYYY-MM-DD") || null,
      };
      payload.final_amount = {
        amount: values?.final_amount ? Number(values?.final_amount) : null,
        due_date: values?.final_due_date?.format("YYYY-MM-DD") || null,
        payment_method: values?.final_payment_method || null,
      };
    }
    if (values?.terms === "deposit_and_final_payment") {
      payload.final_amount = {
        amount: values?.final_amount ? Number(values?.final_amount) : null,
        due_date: values?.final_due_date?.format("YYYY-MM-DD") || null,
        payment_method: values?.final_payment_method || null,
      };
    }
    createSchedulePayment(payload);
  };

  const createSchedulePayment = async (payload) => {
    setCreationLoading(true);
    let res = await createSchedulePaymentApi(payload);
    if (res.status) {
      setCreationLoading(false);
      message.success("Payment Scheduled Successfully");
      navigate(`${ReservationDetailRoute}?id=${reservationUuid}`);
    } else {
      setCreationLoading(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <ReservationPayment_header
              form={form}
              creationLoading={creationLoading}
              loadingReservationData={loadingReservationData}
              reservationData={reservationData}
            />
          </div>

          <Content style={{ marginBottom: "20px" }}>
            <SchedulePlannedPaymetInfo
              term={term}
              setTerm={setTerm}
              form={form}
              onFinishHandler={onFinishHandler}
              reservationId={reservationId}
              termFormData={termFormData}
              formDataLoading={formDataLoading}
            />
          </Content>

          <Content style={{ marginBottom: "20px" }}>
            <SchedulePaymentForm
              term={term}
              termFormData={termFormData}
              form={form}
              onFinishHandler={onFinishHandler}
            />
          </Content>
          <Content style={{ marginBottom: "20px" }}>
            <AutoPayAuthForm
              form={form}
              onFinishHandler={onFinishHandler}
              loadingPaymentData={formDataLoading}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AddEditSchedulePaymentPage;
