import { Table, Button, Divider, Tabs } from "antd";
import React, { useState } from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../utils/constants";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const ExtensionHotels = ({ extensionData, extensionLoading }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);

  const handleTabChange = (key) => {
    setCurrentTab(Number(key));
  };

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  // if (!currentTab) {
  //   return <DataLoader />;
  // }
  const { field_hotel_options } = extensionData || {};

  const data = [
    {
      key: "1",
      label: "Traveler Type",
      input: `${
        field_hotel_options?.[currentTab - 1]?.field_traveler_type?.label || ""
      }`,
    },
    {
      key: "2",
      label: "City",
      input: `${field_hotel_options?.[currentTab - 1]?.field_city || ""}`,
    },
    {
      key: "3",
      label: "Hotel Name",
      input: `${field_hotel_options?.[currentTab - 1]?.field_hotel_name || ""}`,
    },
    {
      key: "4",
      label: "Star Level",
      input: `${
        field_hotel_options?.[currentTab - 1]?.field_star_level?.label || ""
      }`,
    },
    {
      key: "5",
      label: "Check In",
      input: (
        <>
          {field_hotel_options?.[currentTab - 1]?.field_check_in
            ? moment(
                field_hotel_options?.[currentTab - 1]?.field_check_in
              ).format(DATE_FORMAT)
            : ""}
        </>
      ),
    },
    {
      key: "6",
      label: "Check Out",
      input: (
        <>
          {field_hotel_options?.[currentTab - 1]?.field_check_out
            ? moment(
                field_hotel_options?.[currentTab - 1]?.field_check_out
              ).format(DATE_FORMAT)
            : ""}
        </>
      ),
    },
    {
      key: "7",
      label: "Destination",
      input: `${
        field_hotel_options?.[currentTab - 1]?.field_destination?.label || ""
      }`,
    },
    {
      key: "8",
      label: "Nights",
      input: `${field_hotel_options?.[currentTab - 1]?.field_nights || ""}`,
    },
    {
      key: "9",
      label: "Rooms",
      input: `${field_hotel_options?.[currentTab - 1]?.field_rooms || ""}`,
    },
  ];

  const tabItems = extensionData?.field_hotel_options?.map((item, index) => {
    return {
      key: index + 1,
      label: `Hotel Option ${index + 1}`,
      children: (
        <Table
          loading={{ spinning: extensionLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      ),
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <h4 className="mb-0 ms-0">Hotel Options</h4>
      </div>
      <Divider className="global_divider mb-0" />
      {extensionData?.field_hotel_options?.length > 1 ? (
        <Tabs
          activeKey={currentTab}
          className="details-tabs"
          items={tabItems}
          onChange={(key) => {
            handleTabChange(key);
          }}
        ></Tabs>
      ) : (
        <Table
          loading={{ spinning: extensionLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
};

export default ExtensionHotels;
