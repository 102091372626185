import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFeeDetailsApi } from "../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import AddonFeeDetailsContainer from "../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeDetailsContainer";
import AddonFeeDetailsHeader from "../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeDetailsHeader";
import EventsSearchbar from "../../../components/Events/EventsSearchbar";
import {
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

const FeeDetailsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const feeId = searchParams.get("feeId");
  const eventId = searchParams.get("id");
  const navigate = useNavigate()
  const [feeDetailsData, setFeeDetailsData] = useState({});
  const [loadingFeeDetailData, setLoadingFeeDetailData] = useState(false);
  const breadCrumbs = [
    {
      title: "Events",
      path: EventsListingRoute,
    },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Fees", path: `${EventDetailsRoute}?id=${eventId}#Fees` },
    { title: "Fee Detail", path: "" },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view published fees entities");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    getFeeDetail();
  }, []);

  const getFeeDetail = async () => {
    setLoadingFeeDetailData(true);
    const response = await getFeeDetailsApi(feeId);
    if (response?.status) {
      setFeeDetailsData(response?.data?.data);
      setLoadingFeeDetailData(false);
    } else {
      setFeeDetailsData({});
      setLoadingFeeDetailData(false);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <AddonFeeDetailsHeader
              feeDetailsData={feeDetailsData}
              loadingFeeDetailData={loadingFeeDetailData}
              feeType={"event"}
            />
          </div>
          <AddonFeeDetailsContainer
            feeDetailsData={feeDetailsData}
            loadingFeeDetailData={loadingFeeDetailData}
            feeType={"event"}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default FeeDetailsPage;
