import React from "react";
import {
  Admin,
  Contacts,
  Event,
  Group,
  Navigation,
  Organization,
  Reservations,
} from "../../../utils/totalPermissions";
import PermissionsList from "../../../components/Admin/ManageRoles/Permissions/PermissionsList";
import { Content } from "antd/lib/layout/layout";

const showOrNot = (permission, currentList) => {
  const lists = currentList
    .map((val) => permission.includes(val) && val)
    .filter(Boolean);
  return lists.length ? true : false;
};
const PermissionsDropDown = ({ viewMode, setPermissions, permissions }) => {
  return (
    <>
      <div className="partnership_inform">
        <p className="dropdown_heading assign-permission-title">{viewMode ? "Permissions" : "Assign Permissions"}</p>
      </div>
      {!viewMode || (viewMode && showOrNot(permissions, Navigation)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Navigation}
            page="Navigation Menu"
          />
        </Content>
      ) : null}

      {!viewMode || (viewMode && showOrNot(permissions, Contacts)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Contacts}
            page="Contacts"
          />
        </Content>
      ) : null}
      {!viewMode || (viewMode && showOrNot(permissions, Organization)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Organization}
            page="Organization"
          />
        </Content>
      ) : null}
      {!viewMode || (viewMode && showOrNot(permissions, Event)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Event}
            page="Event"
          />
        </Content>
      ) : null}

      {!viewMode || (viewMode && showOrNot(permissions, Admin)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Admin}
            page="Admin"
          />
        </Content>
      ) : null}
      {!viewMode || (viewMode && showOrNot(permissions, Reservations)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Reservations}
            page="Reservations"
          />
        </Content>
      ) : null}
      {!viewMode || (viewMode && showOrNot(permissions, Group)) ? (
        <Content
          className="site-layout-background"
          style={{ marginBottom: "20px" }}
        >
          <PermissionsList
            viewMode={viewMode}
            permissions={permissions}
            setPermissions={setPermissions}
            permissionList={Group}
            page="Group Details"
          />
        </Content>
      ) : null}
    </>
  );
};

export default PermissionsDropDown;
