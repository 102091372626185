import { ArrowLeftOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";

// const menu = (
//   <Menu
//     items={[
//       {
//         label: <a href="#">Edit</a>,
//         key: "0",
//       },
//       {
//         type: "divider",
//       },
//       {
//         label: <a href="#">Delete</a>,
//         key: "1",
//       },
//       {
//         type: "divider",
//       },
//       {
//         label: "More Options",
//         key: "3",
//       },
//     ]}
//   />
// );

function ActivityConfirmationHeader({
  activityDetails,
  loadingSingleActivity,
}) {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState([]);
  const { field_task_type, drupal_internal__id } = activityDetails;

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  return (
    <>
      <div className="breadcrumb_content">
        <div className="breadcrumb_heading view_header">
          {/* <h3 className='heading_three' ><ArrowLeftOutlined className="back-icon-profile" />   
        
                      <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >View Task: Email Confirmation </span>
                      <span className='span_text'> (603514)</span>
                  </h3> */}

          <h3 className="flex_row_column">
            <ArrowLeftOutlined
              className="back-icon-profile"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="header_res">
              {!activityDetails?.id ? (
                <Skeleton.Button
                  shape="round"
                  size="small"
                  style={{ width: "100px" }}
                />
              ) : (
                <>
                  <p>View Task: {field_task_type?.label || ""}</p>
                  <p className="header_smalltext"> ({drupal_internal__id})</p>
                </>
              )}
            </div>
          </h3>
        </div>
      </div>
    </>
  );
}

export default ActivityConfirmationHeader;
