import {
  Breadcrumb,
  Layout,
  Menu,
  Col,
  Row,
  Grid,
  message,
  Modal,
  Spin,
  Form,
} from "antd";
import Searchreservations_menu from "../../../../components/Reservation/ReservationSearchBar";
import InsuranceHeader from "./InsurancePayHeader";
import InsurancePayment from "./InsurancePaymentDetails";
import InsurancePayCredit from "./InsurancePayCreditCard";

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { ReservationItemDetailRoute } from "../../../../url-routes/urlRoutes";
import { getInsurancePaymentDetails } from "../../../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { Header, Content, Sider } = Layout;

function InsurancePage() {
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader,setLoader] = useState(false)
  const paramsId = searchParams.get("id");
  const itemId = searchParams.get("item-id");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loadingInsurancePaymentDetail = useSelector(
    (state) =>
      state.reservation.insurancePaymentDetails?.loadingInsurancePaymentDetail
  );
  const [insurancePaymentInfo, setInsurancePaymentInfo] = useState({});

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const breadCrumbs = [
    { title: "Reservations", path: "/reservations" },
    {
      title: "Details",
      path: `/reservation/details?id=${paramsId}`,
    },
    {
      title: "Reservation Item",
      path: `${ReservationItemDetailRoute}?id=${paramsId}&item-id=${itemId}`,
    },
    { title: "Pay For Insurance", path: "" },
  ];

  useEffect(() => {
    (async () => {
      const response = await dispatch(
        getInsurancePaymentDetails(itemId)
      ).unwrap();
      if (response?.status) {
        setInsurancePaymentInfo(response?.data?.data);
      } else {
        message.error(
          response?.data?.response?.data?.message ||
            response?.data?.response?.data?.data ||
            SOMETHING_WENT_WRONG
        );
        console.log(response);
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Spin spinning={loadingInsurancePaymentDetail}>
            <Header className="contact_edit main-header">
              <Searchreservations_menu />
            </Header>

            <BreadCrumbGenerator breadCrumbs={breadCrumbs} />

            <div className="events-header">
              <InsuranceHeader
                backPath={`${ReservationItemDetailRoute}?id=${paramsId}&item-id=${itemId}`}
                insurancePaymentInfo={insurancePaymentInfo}
                form={form}
                loader={loader}
              />
            </div>

            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <InsurancePayment
                insurancePaymentInfo={insurancePaymentInfo}
                form={form}
              />
            </Content>

            <Content
              className="site-layout-background"
              style={{ marginBottom: "20px" }}
            >
              <InsurancePayCredit
                insurancePaymentInfo={insurancePaymentInfo}
                form={form}
                setLoader={setLoader}
              />
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default InsurancePage;
