import { Table, Select, Input } from 'antd';
import { EditOutlined, CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import React from 'react';
import { useState } from "react";
const handleChange = (value) => {
    console.log(`selected ${value}`);
};




const Option = Select.Option;
function Event_detailcategorytablesix() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const start = () => {
        setLoading(true); // ajax request after empty completing

        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onChange = (value) => {
        console.log(`onChange ${value}`);
    };

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isEditing, setIsEditing] = useState(false);
    const [editingStudent, setEditingStudent] = useState(null);
    //  data column



    const columns = [
        {
            title: 'ORD - LX 009 / OS 860',
            dataIndex: 'ORD - LX 009 / OS 860',
        },

        {
            title: <>
                <div className='action_flex'>
                    <Link to="/Edit_dropdown">
                        <EditOutlined className='action_edit' />
                    </Link>

                    <DeleteOutlined className='action_delete' />


                </div>
            </>,
            dataIndex: 'address',
        },
    ];






    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };



    const onEditStudent = (record) => {
        setIsEditing(true);
        setEditingStudent({ ...record });
    };

    const resetEditing = () => {
        setIsEditing(false);
        setEditingStudent(null);
    };
    const { Search } = Input;
    const onSearch = (value) => console.log(value);

    return (
        <>
            <div className='Res_tables' style={{ width: "100%" }}>
                <Table className='plus_change' columns={columns} />

            </div>

        </>
    );


}
export default Event_detailcategorytablesix;