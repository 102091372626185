import { Table, Divider, Switch, Tabs } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../utils/constants";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

function CruiseDetails({ allDetails, allDetailsLoading }) {
  const [currentTab, setCurrentTab] = useState(1);

  const { field_event_details, name, field_event_code, field_event_partner } =
    allDetails || {};

  const handleTabChange = (key) => {
    setCurrentTab(Number(key));
  };

  const eventData = [
    {
      key: "1",
      label: "Event Partners",
      input: (
        <>
          {field_event_partner?.length
            ? field_event_partner
                ?.map(({ field_organization_name }) => field_organization_name)
                ?.join(", ")
            : ""}{" "}
        </>
      ),
    },
    {
      key: "2",
      label: "Event Name",
      input: <>{name || ""}</>,
    },
    {
      key: "3",
      label: "Event Code",
      input: <>{field_event_code || ""}</>,
    },
    {
      key: "4",
      label: "Toll Free Phone Number",
      input: (
        <>
          {allDetails?.field_tollfree_extension &&
          allDetails?.field_tollfree_phone
            ? `${allDetails?.field_tollfree_extension}-${allDetails?.field_tollfree_phone}`
            : ""}
        </>
      ),
    },
  ];

  const data = [
    {
      key: "4",
      label: "Requested Dates",
      input: (
        <>
          {(field_event_details?.[currentTab - 1]?.field_requested_start_dates
            ? moment(
                field_event_details?.[currentTab - 1]
                  ?.field_requested_start_dates
              ).format(DATE_FORMAT)
            : "") +
            `${
              field_event_details?.[currentTab - 1]?.field_requested_start_dates
                ? " - "
                : ""
            }` +
            (field_event_details?.[currentTab - 1]?.field_requested_end_dates
              ? moment(
                  field_event_details?.[currentTab - 1]
                    ?.field_requested_end_dates
                ).format(DATE_FORMAT)
              : "")}
        </>
      ),
    },
    {
      key: "5",
      label: "Alternative Dates",
      input: (
        <>
          {(field_event_details?.[currentTab - 1]?.field_alternative_start_dates
            ? moment(
                field_event_details?.[currentTab - 1]
                  ?.field_alternative_start_dates
              ).format(DATE_FORMAT)
            : "") +
            `${
              field_event_details?.[currentTab - 1]
                ?.field_alternative_start_dates
                ? " - "
                : ""
            }` +
            (field_event_details?.[currentTab - 1]?.field_alternative_end_dates
              ? moment(
                  field_event_details?.[currentTab - 1]
                    ?.field_alternative_end_dates
                ).format(DATE_FORMAT)
              : "")}
        </>
      ),
    },
    {
      key: "6",
      label: "Are Dates Flexible?",
      input: (
        <>
          {field_event_details?.[currentTab - 1]?.field_are_dates_flexible ===
          true
            ? "Yes"
            : field_event_details?.[currentTab - 1]
                ?.field_are_dates_flexible === false
            ? "No"
            : ""}
        </>
      ),
    },
    {
      key: "7",
      label: "Cruise Line",
      input: (
        <>
          {field_event_details?.[currentTab - 1]?.field_cruise_line?.label ||
            ""}
        </>
      ),
    },
    {
      key: "8",
      label: "Ship",
      input: (
        <>{field_event_details?.[currentTab - 1]?.field_ship?.label || ""}</>
      ),
    },
    {
      key: "9",
      label: "Cruise Itinerary",
      input: (
        <>
          {field_event_details?.[currentTab - 1]?.field_cruise_itinerary
            ?.label || ""}
        </>
      ),
    },

    {
      key: "10",
      label: "Length of Cruise",
      input: (
        <>
          {field_event_details?.[currentTab - 1]?.field_length_of_cruise || ""}
        </>
      ),
    },

    {
      key: "11",
      label: "Event Exclusive",
      input: (
        <>
          <Switch
            checked={
              field_event_details?.[currentTab - 1]?.field_event_exclusive
            }
            disabled
          />
        </>
      ),
    },
    {
      key: "12",
      label: "URL Address",
      input: (
        <>{field_event_details?.[currentTab - 1]?.field_url_address || ""}</>
      ),
    },
    {
      key: "13",
      label: "Cruise Line Group # / Sailing #",
      input: <>{field_event_details?.[currentTab - 1]?.field_sailing || ""}</>,
    },
  ];

  const tabItems = allDetails?.field_event_details?.map((item, index) => {
    return {
      key: index + 1,
      label: `Detail ${index + 1}`,
      children: (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      ),
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Cruise Details</h4>
        {/* <span>
          <Link to="/contact">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={eventData}
        showHeader={false}
        pagination={false}
        size="small"
      />

      {allDetails?.field_event_details?.length > 1 ? (
        <Tabs
          activeKey={currentTab}
          // className="details-tabs"
          items={tabItems}
          onChange={(key) => {
            handleTabChange(key);
          }}
        ></Tabs>
      ) : (
        <Table
          loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
          className="basic_table"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      )}

      {/* <Tabs
        activeKey={currentTab}
        // className="details-tabs"
        items={tabItems}
        onChange={(key) => {
          handleTabChange(key);
        }}
      ></Tabs> */}
    </div>
  );
}
export default CruiseDetails;
