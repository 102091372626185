import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Menu, Modal, Skeleton, message, Tooltip } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsAddonsRoute,
  EventDetailsRoute,
} from "../../../../../url-routes/urlRoutes";
import { cloneEventApi, deleteAddonApi } from "../../../../../apis/eventsApi";
import { urlParser } from "../../../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

function AddonDetailsHeader({
  eventId,
  addonId,
  singleAddonData,
  loadingData,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const deleteAddon = async (aId) => {
    const response = await deleteAddonApi(aId);
    if (response.status) {
      message.success("Addon Deleted Successfully");
      navigate(`${EventDetailsRoute}?id=${eventId}#Add-Ons`);
    } else {
      message.error(
        response?.data?.response?.data?.message|| response?.data?.response?.data?.errors?.[0]?.detail || SOMETHING_WENT_WRONG
      );
    }
  };
  const onDeleteHandler = (addonId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this add-on?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteAddon(addonId);
      },
    });
  };

  const cloneThisAddon = async (payload) => {
    setIsCloneLoading(true);
    const cloneRes = await cloneEventApi(payload);
    if (cloneRes?.status) {
      message.success("Addon Cloned Successfully");
      form.resetFields();
      handleOk();
      const addonType = urlParser(location.search)?.addonType;
      navigate(
        `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${cloneRes?.data?.data?.cloned_event_uuid || addonId
        }${addonType ? `&addonType=${addonType}` : ""}`
      );
    } else {
      message.error(
        cloneRes?.data?.response?.data?.message ||
        cloneRes?.data?.message ||
        SOMETHING_WENT_WRONG
      );
    }
    setIsCloneLoading(false);
  };

  const cloneEventFinishHandler = ({ event_name, event_code }) => {
    const payload = {
      data: {
        event_uuid: addonId,
        event_name,
        event_code,
      },
    };
    cloneThisAddon(payload);
  };

  return (
    <div className="breadcrumb_content">
      <Modal
        footer={[
          <Button
            type="primary"
            loading={isCloneLoading}
            onClick={(e) => {
              e.preventDefault();
              form.submit();
            }}
          >
            Copy
          </Button>,
        ]}
        title="Copy Add-On"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="copy_inventory_modal">
          <Form
            name="basic"
            form={form}
            onFinish={cloneEventFinishHandler}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="asterick-align"
              label="Add-On Name"
              name="event_name"
              rules={[
                {
                  required: true,
                  message: "Please enter event name",
                },
              ]}
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="asterick-align"
              label="Add-On Code"
              name="event_code"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter event code",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className=""
            onClick={() =>
              navigate(`${EventDetailsRoute}?id=${eventId}#Add-Ons`)
            }
            style={{ marginRight: "10px" }}
          />
          <div className="header_res">
            {loadingData ? (
              <>
                <Skeleton.Input active={true} style={{ width: "400px" }} />
              </>
            ) : (
              <>
                <p>
                  {singleAddonData?.name} (
                  {singleAddonData?.field_add_ons_type?.label || ""}{" "}
                  {singleAddonData?.drupal_internal__id || ""})
                </p>
                <Link
                  to={`${EventDetailsRoute}?id=${eventId}`}
                  className="header_smalltext"
                >
                  {!!singleAddonData?.field_event?.name &&
                    singleAddonData?.field_event?.name}
                  {!!singleAddonData?.field_event?.drupal_internal__id &&
                    ` (${singleAddonData?.field_event?.drupal_internal__id})`}
                </Link>
              </>
            )}
          </div>
        </h3>
        <div className="buttons_flex">
          {permissionChecker("add/edit addon") ? <Tooltip placement="top" title="Edit" >
            <Button
              type="y"
              icon={
                <EditOutlined
                  onClick={() =>
                    navigate(
                      `${EventDetailsAddonsRoute}?id=${eventId}&editId=${addonId}&step=1`
                    )
                  }
                />
              }
            />
          </Tooltip> : null}

          {permissionChecker("copy addon") ? <Tooltip placement="top" title="Copy">
            <Button
              type="y"
              icon={<CopyOutlined />}
              onClick={() => {
                showModal();
              }}
            />
          </Tooltip> : null}

         {permissionChecker("delete addon") ?  <Tooltip placement="top" title="Delete">
            <Button
              className="delete_btn"
              type="y"
              icon={<DeleteOutlined onClick={() => onDeleteHandler(addonId)} />}
            />
          </Tooltip> : null}
        </div>
      </div>
    </div>
  );
}

export default AddonDetailsHeader;
