import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Card } from 'antd';
import { Checkbox } from 'antd';
import './ExpandSelect_drawer.less';
import React, { useState } from 'react';
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);

};

const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};




function ExpandSelect_drawer() {

    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);


    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };


    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const { Search } = Input;
    const onSearch = (value) => console.log(value);

    return (

        <>
            <Button onClick={showDrawer} type="primary">Select</Button>

            <Drawer
                title={<>
                    <a href='#' className='span_text'>LUAU</a>
                    <p className='document_pdf' >Optional Kauai Luau - Jun 15</p>
                </>}
                maxWidth={425}
                onClose={onClose}
                open={open}
                bodyStyle={{ paddingBottom: '32px' }}
                extra={
                    <Space>
                        {/* <Button onClick={onClose} style={{ opacity: '0.8' }} >Cancel</Button> */}
                        {/* <Button onClick={onClose} type="primary">
                            Save
                        </Button> */}
                    </Space>
                }
            >

                <div className='search_filter'>
                    <Search
                        placeholder="Search"
                        onSearch={onSearch}
                        style={{
                            maxWidth: 250,
                        }}
                    />

                    <Button>Filters</Button>
                </div>

                <Card
                    style={{
                        maxWidth: 350,
                        marginBottom: '20px',
                    }}
                >

                    <div className='carddrawer'>
                        <div className='carddrawer_heading'>
                            <a href='#'>LUAU</a>
                            <p>Available</p>
                        </div>
                        <div className='carddrawer_paragraph'>
                            <p className='document_pdf'>Kauai Luau Excursion - Jun 15</p>
                        </div>
                        <div className='carddrawer_content'>
                            <div className='carddrawer_content--price'>
                                <p className='span_text'>Price</p>
                                <p className='document_pdf'>$20.00</p>
                            </div>

                            <div className='carddrawer_content--quantity'>
                                <p className='span_text'>Quanity</p>
                                <p className='document_pdf'>100</p>
                            </div>


                        </div>
                        <Button type="primary">Select</Button>
                    </div>



                </Card>


                <Card
                    style={{
                        maxWidth: 350,
                        marginBottom: '20px',
                    }}
                >

                    <div className='carddrawer'>
                        <div className='carddrawer_heading'>
                            <a href='#'>LUAU</a>
                            <p>Available</p>
                        </div>
                        <div className='carddrawer_paragraph'>
                            <p className='document_pdf'>Kauai Luau Excursion - Jun 15</p>
                        </div>
                        <div className='carddrawer_content'>
                            <div className='carddrawer_content--price'>
                                <p className='span_text'>Price</p>
                                <p className='document_pdf'>$20.00</p>
                            </div>

                            <div className='carddrawer_content--quantity'>
                                <p className='span_text'>Quanity</p>
                                <p className='document_pdf'>100</p>
                            </div>


                        </div>
                        <Button type="primary">Select</Button>
                    </div>



                </Card>



                <Card
                    style={{
                        maxWidth: 350,
                        marginBottom: '20px',
                    }}
                >

                    <div className='carddrawer'>
                        <div className='carddrawer_heading'>
                            <a href='#'>LUAU</a>
                            <p>Available</p>
                        </div>
                        <div className='carddrawer_paragraph'>
                            <p className='document_pdf'>Kauai Luau Excursion - Jun 15</p>
                        </div>
                        <div className='carddrawer_content'>
                            <div className='carddrawer_content--price'>
                                <p className='span_text'>Price</p>
                                <p className='document_pdf'>$20.00</p>
                            </div>

                            <div className='carddrawer_content--quantity'>
                                <p className='span_text'>Quanity</p>
                                <p className='document_pdf'>100</p>
                            </div>


                        </div>
                        <Button type="primary">Select</Button>
                    </div>



                </Card>










            </Drawer>
        </>

    );
}

export default ExpandSelect_drawer;

