import { EditOutlined } from "@ant-design/icons";
import { Divider, Form, Skeleton, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAndEditContactApi } from "../../../../apis/contactApi";
import { getEliteTravelerStatus } from "../../../../others/commonApiCalls/commonApiCalls";
import { isTravelHistoryAdded } from "../../../../redux/features/contactFeatures/contactSlice";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { customRequestHeader } from "../../../../utils/cutomRequestHeader";
import { permissionChecker } from "../../../../utils/permisssionChecker";
import TravelHistoryForm from "./TravelHistoryForm";
import "./TravelHistoryForm.less";
const authData = JSON.parse(localStorage.getItem("authorization"));

const TravelHistory = ({ contactDetailsData, contactId, isLoading }) => {
  const { addEditTravelHistory } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [eliteStatus, setEliteStatus] = useState({});
  const [totalDaysTraveled, setTotalDaysTraveled] = useState(0);
  const [loadingEliteStatus, setLoadingEliteStatus] = useState(false);
  const columns = [
    {
      title: "Event Traveled",
      dataIndex: "field_event_code",
    },

    {
      title: "Event Name",
      dataIndex: "field_event_name",
    },

    {
      title: "Days Traveled",
      dataIndex: "field_days_traveled",
    },

    {
      key: "4",
      title: "Actions",
      render: (record, recordObj) => {
        return (
          <>
            {permissionChecker("add/edit travel history") ? (
              <div className="action_flex action_end">
                <EditOutlined
                  className="action_edit"
                  onClick={() => {
                    onEditHandler(recordObj, recordObj?.index);
                  }}
                />
              </div>
            ) : null}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (contactDetailsData?.field_travel_history) {
      let totalDays = 0;
      getEliteTravelerStatus(contactId, setEliteStatus, setLoadingEliteStatus);
      const transformedData = contactDetailsData.field_travel_history.map(
        (travelHistory, index) => {
          const daysTraveled = travelHistory?.field_days_traveled || 0;
          totalDays += parseInt(daysTraveled);

          return {
            index,
            field_event_code: travelHistory?.field_event_code || null,
            field_event_name: travelHistory?.field_event_name || null,
            field_days_traveled: daysTraveled,
          };
        }
      );

      setData(transformedData);
      setTotalDaysTraveled(totalDays);
    }
  }, [contactDetailsData]);
  const onClose = () => {
    setOpen(false);
    setEditIndex(null);
    form.resetFields();
  };

  const onEditHandler = (travelHistory, index) => {
    setOpen(true);
    setEditIndex(index);
    form.setFieldsValue({
      field_event_code: travelHistory?.field_event_code || null,
      field_event_name: travelHistory?.field_event_name || null,
      field_days_traveled: travelHistory?.field_days_traveled || null,
    });
  };
  const onFinishHandler = (values) => {
    const isEveryValueMissing = Object.values(values).every((value) => {
      if (value === null || value === undefined || value === "") {
        return true;
      }
      return false;
    });

    const existingTravelHistory = contactDetailsData?.field_travel_history?.map(
      (travelHistory) => ({
        field_event_code: travelHistory?.field_event_code || null,
        field_event_name: travelHistory?.field_event_name || null,
        field_days_traveled: travelHistory?.field_days_traveled || null,
      })
    );

    const editTravelHistory = [...existingTravelHistory];
    editTravelHistory[editIndex] = {
      field_event_code: values?.field_event_code || null,
      field_event_name: values?.field_event_name || null,
      field_days_traveled: values?.field_days_traveled || null,
    };
    const reqHeaders = customRequestHeader({ token: Token });
    let payload = {
      data: {
        type: "contact--contact",
        id: contactId,
        attributes: {
          field_travel_history:
            editIndex !== null
              ? editTravelHistory
              : [
                  ...existingTravelHistory,
                  {
                    field_event_code: values?.field_event_code || null,
                    field_event_name: values?.field_event_name || null,
                    field_days_traveled: values?.field_days_traveled || null,
                  },
                ],
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (!isEveryValueMissing) {
      createOrEditTravelHistory({
        reqMethod: "patch",
        contactId,
        reqHeaders,
        payload,
      });
    }
  };

  const createOrEditTravelHistory = async (data) => {
    setLoading(true);
    const response = await createAndEditContactApi(data);
    if (response.status) {
      dispatch(isTravelHistoryAdded(!addEditTravelHistory));
      message.success("Travel History Added Successfully");
      setLoading(false);
      onClose();
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setLoading(false);
      onClose();
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'" }}>
          Travel History{" "}
          {loadingEliteStatus ? (
            <Skeleton.Input style={{ width: "300px" }} />
          ) : eliteStatus?.traveler_status ? (
            <>
              <span className="span_text mb-0"> (Elite Traveler Status: </span>{" "}
              {eliteStatus?.traveler_status}{" "}
              <span className="span_text mb-0">)</span>
              <span className="span_text mb-0"> (Total Days Traveled: </span>{" "}
              {totalDaysTraveled}{" "}
              <span className="span_text mb-0">)</span>
            </>
          ) : null}
        </h4>
        <div style={{ display: "flex", gap: "16px" }}>
          <TravelHistoryForm
            form={form}
            onFinishHandler={onFinishHandler}
            open={open}
            setOpen={setOpen}
            setEditIndex={setEditIndex}
            loading={loading}
            onClose={onClose}
            editIndex={editIndex}
          />
        </div>
      </div>

      <Divider className="global_divider" />
      <Table
        className="product_table"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={{ spinning: isLoading, size: "large" }}
        size="small"
      />
    </div>
  );
};

export default TravelHistory;
