import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ReservationItemDetailRoute } from "../../../url-routes/urlRoutes";

const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <a href="#">File</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "Delete",
        key: "2",
      },
      {
        type: "divider",
      },
      {
        label: "Find Duplicates",
        key: "3",
      },
    ]}
  />
);

function Reservationtableviewedit_header(props) {
  const [loading, setLoading] = useState(false);
  const { form, data } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let reservationId = searchParams.get("id");
  let reservationItemId = searchParams.get("item-id");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [loading]);
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3>
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(
                `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${reservationItemId}`
              )
            }
          />{" "}
          <div className="header_res">
            <p>Edit Reservation Item</p>
            <p className="header_smalltext">
              {data?.reservation?.id && `(${data?.reservation?.id})`}
            </p>
            <Link
              className="header_smalltext"
              to={`${ReservationItemDetailRoute}?id=${reservationId}&item-id=${data?.reservation_item?.uuid}`}
            >
              {`(${data?.reservation_item?.id} - ${data?.reservation_item?.name})`}{" "}
            </Link>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              form.submit();
              setLoading(true);
            }}
            type="primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Reservationtableviewedit_header;
