import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
import { useState } from "react";
import { uploadDocumentApi } from "../../apis/contactApi";
import { Cookie, customRequestHeader } from "../../utils/cutomRequestHeader";
import drupalConfig from "../../config/serverless/drupal.config";
import Dragger from "antd/lib/upload/Dragger";
import DataLoader from "../Loaders/DataLoader";
import { linkMediaToLibraryApi } from "../../apis/organisationApi";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const CustomDraggerUpload = ({
  fileList,
  setFileList,
  isMultiple,
  isLoading,
  setIsLoading,
  uploadUrl,
  mediaDocument,
  setuploadDocuementId,
  linkMediaUrl,
  linkMediaType,
  linkMediaRelation,
  supportedFormat,
  allFormatsSupported,
  uploadLimit,
}) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const linkMediaToLibrary = async (mediaResponse) => {
    const payload = {
      data: {
        type: linkMediaType,
        attributes: {
          name: "pdf-file",
        },
        relationships: {
          [linkMediaRelation]: {
            data: !!mediaResponse?.id
              ? {
                  type: "file--file",
                  id: mediaResponse?.id,
                }
              : {},
          },
        },
      },
    };
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const response = await linkMediaToLibraryApi({
      url: linkMediaUrl,
      reqHeaders,
      payload,
    });
    if (response.status) {
      setuploadDocuementId(response?.data?.data?.id, "lllllll");
      isMultiple
        ? setFileList([
            ...fileList,
            {
              uid: mediaResponse.id,
              name: mediaResponse.filename,
              url: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              thumbUrl: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              libraryId: response?.data?.data?.id,
            },
          ])
        : setFileList([
            {
              uid: mediaResponse.id,
              name: mediaResponse.filename,
              url: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
              thumbUrl: `${drupalConfig.BASE_URL}${mediaResponse.uri.url}`,
            },
          ]);
      setIsLoading(false);
      message.success(`${mediaResponse.filename} Uploaded Successfully`);
    } else {
      message.error("Unable To Upload Document");
      setuploadDocuementId("");
      setFileList([]);
      setIsLoading(false);
    }
  };

  const uploadFunction = async (payload, fileType) => {
    setIsLoading(true);
    const url = uploadUrl;
    const reqHeaders = customRequestHeader({
      token: Token,
      isUploadReq: true,
      fileType,
    });
    const response = await uploadDocumentApi({ url, reqHeaders, payload });
    // console.log("res", response);
    if (response.status) {
      if (mediaDocument) {
        linkMediaToLibrary(response?.data?.data);
      } else {
        isMultiple
          ? setFileList([
              ...fileList,
              {
                uid: response.data.data.id,
                name: response.data.data.filename,
                url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
              },
            ])
          : setFileList([
              {
                uid: response.data.data.id,
                name: response.data.data.filename,
                url: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
                thumbUrl: `${drupalConfig.BASE_URL}${response.data.data.uri.url}`,
              },
            ]);
        setIsLoading(false);
        message.success(`${response.data.data.filename} Uploaded Successfully`);
      }
    } else {
      setIsLoading(false);
      message.error(
        response?.data?.response?.data?.errors?.[0]?.detail
          ?.split("\n")
          .slice(1)
          .join("\n") || SOMETHING_WENT_WRONG
      );
    }
  };

  const onChangeHandler = (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = function () {
        const base64String = reader.result.split(",")[1]; // extract base64 string from data URL
        const binaryArray = getBinaryArrayFromBase64String(base64String);
        // console.log({ binaryArray });
        uploadFunction(binaryArray, info.file.name);
      };
      reader.onerror = function (error) {
        message.error("Failed To Upload Image");
      };

      //   const reader = new FileReader();
      //   reader.readAsArrayBuffer(info.file.originFileObj);
      //   reader.onload = () => {
      //     const binaryData = new Uint8Array(reader.result);
      //     const binaryString = new TextDecoder().decode(binaryData);
      //     uploadFunction(binaryString);
      //   };
      //   reader.onerror = () => {
      //     message.error("Failed to upload image");
      //   };
    }
  };

  const getBinaryArrayFromBase64String = (base64String) => {
    const binaryString = atob(base64String);
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    return binaryArray;
  };

  const onRemoveHandler = (removeFile) => {
    if (!isMultiple) {
      setFileList([]);
      // console.log("if", isMultiple);
    } else {
      // console.log("else", isMultiple, removeFile);
      setFileList(fileList.filter((file) => file.uid !== removeFile.uid));
    }
  };
  return (
    <Dragger
      multiple={isMultiple}
      customRequest={() => {}}
      listType="picture"
      fileList={fileList}
      onRemove={(e) => {
        onRemoveHandler(e);
      }}
      onChange={onChangeHandler}
    >
      <>
        <p className="ant-upload-drag-icon">
          {isLoading ? <DataLoader /> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>

        {!allFormatsSupported && (
          <>
            <p className="ant-upload-hint">
              Supported file types:{" "}
              {supportedFormat?.length
                ? supportedFormat?.join(", ")
                : "jpeg/jpf, png, pdf"}
            </p>
            <p className="ant-upload-hint">
              {" "}
              Max file size: {uploadLimit || "100 MB"}.
            </p>
          </>
        )}
      </>
    </Dragger>
  );
};
export default CustomDraggerUpload;
