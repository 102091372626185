import { Table, Select, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { deleteRoleApi } from "../../../apis/manageRoles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoles,
  getUsersCountWithRole,
} from "../../../redux/features/adminFeatures/manageRolesSlice";
import DataLoader from "../../../others/Loaders/DataLoader";
import {
  DRUPAL_INTERNAL_ROLES_ID,
  SOMETHING_WENT_WRONG,
} from "../../../utils/constants";
import { permissionChecker } from "../../../utils/permisssionChecker";

const ManageRolesContainer = () => {
  const [isRoleDeleted, setIsRoleDeleted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const allRolesRef = useRef(false);
  const [roles, setRoles] = useState([]);
  const { allRoles, usersCountWithRole } = useSelector(
    (state) => state.manageRoles
  );
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddRole = authData?.permissions?.includes("add/edit role");
  const allowDeleteRole = authData?.permissions?.includes("delete role");
  const allowViewRole = authData?.permissions?.includes("view role");

  const { allRolesResponse, loadingAllRoles } = allRoles;
  const columns = [
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Number of Users",
      dataIndex: "number_members",
      key: "number",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu manage roles");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])
  useEffect(() => {
    if (allRolesRef.current) {
      if (allRolesResponse.status) {
        setRoles(allRolesResponse.data);
      } else {
        message.error(allRolesResponse.data.message || SOMETHING_WENT_WRONG);
        setRoles([]);
      }
      if (usersCountWithRole.error) {
        message.error(usersCountWithRole?.error || SOMETHING_WENT_WRONG);
      }
    }
    allRolesRef.current = true;
  }, [allRolesResponse]);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getUsersCountWithRole());
  }, [dispatch, isRoleDeleted]);

  const deleteRole = async (deleteId) => {
    const response = await deleteRoleApi(deleteId);
    if (response.status) {
      message.success(response?.data?.message);
      setIsRoleDeleted(!isRoleDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
    }
  };

  const onDeleteHandler = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteRole(id);
      },
    });
  };
  const data = roles?.data
    ?.filter((item) => !DRUPAL_INTERNAL_ROLES_ID.includes(item?.id))
    .map((role) => {
      return {
        key: role.id,
        roles: role?.label,
        number_members: (
          <>
            {allowViewRole ? (
              <Link
                to={`/manage-roles/details?role=${role?.id}&name=${role?.drupal_internal__id}`}
              >
                <span className="text_colors">
                  {usersCountWithRole?.data?.[role?.label] || 0} Users
                </span>
              </Link>
            ) : (
              <span className="text_colors">
                {usersCountWithRole?.data?.[role?.label] || 0} Users
              </span>
            )}
          </>
        ),
        action: (
          <>
            <div className="action_flex action_end">
              {allowAddRole && !["administrator","system_admin"].includes(role?.drupal_internal__id) ? (
                <Link to={`/manage-roles/edit?role=${role.id}`}>
                  <EditOutlined className="action_edit" />
                </Link>
              ) : null}

              {allowDeleteRole && !["administrator","system_admin"].includes(role?.drupal_internal__id) ? (
                <DeleteOutlined
                  className="action_delete"
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteHandler(role.drupal_internal__id);
                  }}
                />
              ) : null}
            </div>
          </>
        ),
      };
    });

  return (
    <>
      <Table
        loading={{ spinning: loadingAllRoles, indicator: <DataLoader /> }}
        className="table_tour"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

export default ManageRolesContainer;
