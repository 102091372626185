import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import DiscountCategories from "./DiscountCategories";
import DiscountInfo from "./DiscountInfo";
import DiscountUsage from "./DiscountUsage";
import FeeDiscountCategoriesListing from "../FeeDiscountCategory/FeeDiscountCategoriesListing";

const DiscountDetailsContainer = ({
  discountDetailsData,
  loadingDiscountDetailData,
  discountType,
}) => {
  const [usedCount, setUsedCount] = useState(0);
  return (
    <div>
      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <DiscountInfo
          discountDetailsData={discountDetailsData}
          loadingDiscountDetailData={loadingDiscountDetailData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <DiscountUsage
          usedCount={usedCount}
          loadingDiscountDetailData={loadingDiscountDetailData}
        />
      </Content>

      <Content
        className="site-layout-background"
        style={{ marginBottom: "20px" }}
      >
        <FeeDiscountCategoriesListing
          discountType={discountType}
          setUsedCount={setUsedCount}
        />
      </Content>
    </div>
  );
};

export default DiscountDetailsContainer;
