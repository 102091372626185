import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Collapse, TimePicker } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Table,
  Select,
  Switch,
  DatePicker,
} from "antd";

const columns = [
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Date /Time',
    dataIndex: 'date_time',
  },
  {
    title: 'Payment Method',
    dataIndex: 'payment_method',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'planned_amount',
  },
  {
    title: 'Total Applied',
    dataIndex: 'total_applied',
  },
  {
    title: 'Apply?',
    dataIndex: 'apply',
  },
  {
    title: 'Apply from this payment',
    dataIndex: 'apply_payment',
  },
];
const data = [
  {
    key: '1',
    description: <><a href="#">Deposit</a></>,
    date_time: <><a href="#">07/26/2023</a></>,
    payment_method: 'TBD',
    planned_amount: '$1,000.00',
    total_applied: '$0.00',
    apply: <><Switch defaultChecked onChange={onChange} /></>,
    apply_payment: <><Input /></>,
  },

  {
    key: '2',
    description: <><a href="#">Installment</a></>,
    date_time: <><a href="#">07/15/2023</a></>,
    payment_method: 'TBD',
    planned_amount: '$11,000.00',
    total_applied: '$0.00',
    apply: <><Switch defaultChecked onChange={onChange} /></>,
    apply_payment: <><Input /></>,
  },
];
function onChange(time, timeString) {
  console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function EditPaymentForm() {
  const [value, setValue] = useState("");

  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [staff, setStaff] = useState(false);
  const [min, setMin] = useState(false);

  useEffect(() => {
    form.validateFields(["nickname"]);
  }, [checkNick, form]);

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const fileList = [];
  return (
    <>

      {/* Form One Start */}
      <div style={{ marginBottom: "4rem" }}>
        <div className='partnership_inform form-heading-two' >
          <h4 className="mb-0">Payment Details</h4>
        </div>
        <div>
          <Form
            {...layout}
            labelCol={{
              sm: 24,
              md: 6,
              lg: 6,
              xl: 4,
              xxl: 4,
            }}
            wrapperCol={{
              sm: 24,
              md: 14,
              lg: 14,
              xl: 14,
              xxl: 12,
            }}
            layout="horizontal"
          >

            <Form.Item className="asterick-align" label="Amount" name="amount" rules={[
              {
                required: true,
              },
            ]}
            >
              <Input placeholder="Enter payment amount" />
            </Form.Item>

            <Form.Item className="asterick-align" label="Method" name="method" rules={[
              {
                required: true,
              },
            ]}
            >
              <Input placeholder="Select payment method" />
            </Form.Item>

            <Form.Item className="asterick-align" label="Date" name="date" rules={[
              {
                required: true,
              },
            ]}
            >
              <DatePicker onChange={onChange} />
            </Form.Item>

            <div className="textarea_row">
              <Form.Item label="Comments" name="comments">
                <TextArea rows={3} placeholder="Add comments here" />
              </Form.Item>
            </div>


            <Form.Item className="asterick-align" label="Transfer Balance" name="transfer_balance">
              0.00
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* Form One End  */}

      {/* Form two Start */}
      <div style={{ marginBottom: "4rem" }}>
        <div className='partnership_inform form-heading-two' >
          <h4>Authorization</h4>
        </div>
        <div>
          <Form
            {...layout}
            labelCol={{
              sm: 24,
              md: 6,
              lg: 6,
              xl: 4,
              xxl: 4,
            }}
            wrapperCol={{
              sm: 24,
              md: 14,
              lg: 14,
              xl: 14,
              xxl: 12,
            }}
            layout="horizontal"
          >

            <Form.Item className="asterick-align" label="Authorization #" name="authorization_hash">
              <Input placeholder="#" />
            </Form.Item>

            <Form.Item className="asterick-align" label="Auth Info" name="auth_info">
              Error=Unidentified payment method
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* Form two Start */}

      {/* Table Start */}
      <div style={{ marginBottom: "4rem" }}>
        <div className='partnership_inform form-heading-two' >
          <h4>Planned Payment - Apply to</h4>
        </div>
        <div>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
      {/* Table End */}
    </>
  );
}

export default EditPaymentForm;


