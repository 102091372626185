import { Button, Skeleton } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

function ReportsListHeader({
  reportCount,
  loadingList,
  queryCount,
  loadingQuery,
}) {
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>
              All Reports
              <span className="small_text">
                {loadingList || loadingQuery ? (
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    style={{ width: "50px" }}
                  />
                ) : (
                  `(${reportCount + queryCount || 0})`
                )}
              </span>
            </p>
          </div>
        </h3>
        <div className="breadcrum_buttons">
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Link to="/create-report">
              <PlusOutlined /> Create Report{" "}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ReportsListHeader;
