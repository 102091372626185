import { PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Modal, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./AddressInformation.less";
import {
  Cookie,
  customRequestHeader,
} from "../../../../utils/cutomRequestHeader";
import {
  contactDetailsEditLoader,
  createContact,
} from "../../../../redux/features/contactFeatures/contactSlice";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { Country } from "country-state-city";
import { permissionChecker } from "../../../../utils/permisssionChecker";
import { customCountriesList } from "../../../../others/util/customCountriesList";
const { Meta } = Card;

const AddressInformation = ({ contactDetailsData, contactId, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { field_address_field, field_step } = contactDetailsData;
 const countriesList =  customCountriesList()
  const [primaryAddress, secondaryAddress] = field_address_field || [];
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const deleteHandler = () => {};

  const deleteModal = () => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(contactDetailsEditLoader(false));
        const reqMethod = "patch";
        const reqHeaders = customRequestHeader({ token: Token });
        const payload = {
          data: {
            type: "contact--contact",
            attributes: {
              field_address_field: [
                {
                  field_address_type: primaryAddress.field_address_type.id,
                  field_address_line_1: primaryAddress.field_address_line_1,
                  field_address_line_2: primaryAddress.field_address_line_2,
                  field_city: primaryAddress.field_city,
                  field_state: primaryAddress.field_state,
                  field_country: primaryAddress.field_country,
                  field_postal_code: primaryAddress.field_postal_code,
                  field_cell_phone_extension:
                    primaryAddress.field_cell_phone_extension,
                  field_cell_phone: primaryAddress.field_cell_phone,
                  field_other_phone_extension:
                    primaryAddress.field_other_phone_extension,
                  field_other_phone: primaryAddress.field_other_phone,
                },
                {
                  field_address_type: null,
                  field_address_line_1: null,
                  field_address_line_2: null,
                  field_city: null,
                  field_state: null,
                  field_country: null,
                  field_postal_code: null,
                  field_cell_phone_extension: null,
                  field_cell_phone: null,
                  field_other_phone_extension: null,
                  field_other_phone: null,
                },
              ],
              field_step,
            },
            relationships: {
              modified_by: {
                data: {
                  type: "user--user",
                  id: userUuid,
                },
              },
            },
          },
        };
        if (contactId) {
          payload.data.id = contactId;
        }
        return dispatch(
          createContact({ reqMethod, contactId, reqHeaders, payload })
        );
      },
    });
  };

  const addressCard = (address, type) => {
    if (!address?.field_address_type?.label) {
      return;
    }
    return (
      <Card type="inner">
        <Button
          className={type === "primary" ? "primary_color" : ""}
          type=""
          size="small"
          style={{
            fontSize: "12px",
            background: type === "primary" ? "#E6F7FF" : "",
            lineHeight: "20px",
            fontWeight: "400",
            marginBottom: "10px",
            fontFamily: "'Poppins'",
            color: type === "primary" ? "#0099E5" : "#000",
            cursor: "auto",
          }}
        >
          {address?.field_address_type?.label}
        </Button>
        <p className="document_pdf">
          {address?.field_address_line_1 &&
            `${address?.field_address_line_1}, `}
          {address?.field_address_line_2 &&
            `${address?.field_address_line_2}, `}
          {address?.field_city && `${address?.field_city}, `}
          {address?.field_state && `${address?.field_state}, `}
          {address?.field_country &&
            `${
              countriesList.find(
                (country) => country.isoCode === address.field_country
              )?.name
            }, `}
          {address?.field_postal_code && `${address.field_postal_code}`}
        </p>
        <p>
          {!!address?.field_cell_phone ? (
            <span>
              <PhoneOutlined style={{ transform: "rotate(-270deg)" }} />{" "}
              {`${address.field_cell_phone_extension}-${address.field_cell_phone}`}
            </span>
          ) : (
            ""
          )}
          {!!address.field_other_phone ? (
            <span style={{ marginLeft: "16px" }}>
              <PhoneOutlined style={{ transform: "rotate(-270deg)" }} />{" "}
              {`${address.field_other_phone_extension}-${address.field_other_phone}`}
            </span>
          ) : (
            ""
          )}
        </p>
        <Divider className="mb-0" />

        <div className="card_anchor">
          <Button
            onClick={() => {
              navigate(`/contact?editId=${contactId}&step=2`);
            }}
            disabled={allowEditContact ? false : true}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              deleteModal(type);
            }}
            disabled={type === "primary"}
          >
            Delete
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <div>
      {allowEditContact ? (
        <div
          className="address_information"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Address Information</h4>
          {!secondaryAddress?.field_address_type ? (
            <span>
             {permissionChecker("add/edit contact") ? ( <Link to={`/contact?editId=${contactId}&step=2`}>
                <Button type="primary">
                  <PlusOutlined /> Add Address
                </Button>
              </Link>):null}
            </span>
          ) : null}
        </div>
      ) : null}

      <Divider className="global_divider" />
      {isLoading ? (
        <DataLoader />
      ) : (
        <div
          className="white_space"
          style={{
            display: "flex",
            gap: "16px",
            marginTop: "20px",
          }}
        >
          {addressCard(primaryAddress, "primary")}
          {addressCard(secondaryAddress, "secondary")}
        </div>
      )}
    </div>
  );
};
export default AddressInformation;
