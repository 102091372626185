import { Tag } from "antd";
import { decodeContent } from "../../utils/contentParser";

export const createFilterTags = (filterObj) => {
  let newFilterObj = { ...filterObj };
  for (let key in newFilterObj) {
    if (!newFilterObj[key]) {
      delete newFilterObj[key];
    }
  }
  return Object.keys(newFilterObj).length ? (
    <div>
      {Object.keys(newFilterObj).map((filterItem) => {
        return (
          <div className="contact-filters">
            <Tag>
              <b>{filterItem}</b> : {decodeContent(newFilterObj[filterItem])}
            </Tag>
          </div>
        );
      })}
    </div>
  ) : null;
};
