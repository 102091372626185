import queryConfig from "../config/serverless/query.config";
export const DRUPAL_INTERNAL_ROLES_ID = [
  //   "51a88203-94c1-4aa7-aeab-e21dc70a55cf", // administrator id
  "df32f8b4-2f4e-4529-be07-6aa9543b745b",
  "4cc6b764-9c37-46c2-a291-f2523bd7a832",
  "e33046c6-e253-4bf9-bde8-df2060b55008",
];

export const DRUPAL_INTERNAL_ROLES = DRUPAL_INTERNAL_ROLES_ID?.map((id) => ({
  type: "user_role--user_role",
  id,
}));

export const AUTHENTICATED_USER_ROLE = {
  type: "user_role--user_role",
  id: "4cc6b764-9c37-46c2-a291-f2523bd7a832",
};

export const DATE_FORMAT = "MM/DD/YYYY";

export const TIME_FORMAT = "hh:mm A";
export const SOMETHING_WENT_WRONG = "Something Went Wrong";
export const UNABLE_TO_DELETE = "Unable To Delete";
export const COMPLETE_PREVIOUS_STEP =
  "Please Complete Previous Step First And Press Next Button";
export const INVENTORY_ID_REQUIRED = "Inventory Id Is Not Specified";
export const ITINERARY_REQUIRED =
  "Please Fill Atleast One Field For Each Itinerary";
export const ALL_EVENT_STATUS_FOR_SEARCH = [
  {
    label: "Include Complete",
    value: "complete",
  },
  {
    label: "Include Locked",
    value: "lock",
  },
  {
    label: "Include Design",
    value: "design",
  },
  {
    label: "Include Canceled",
    value: "cancel",
  },
  {
    label: "Include Archived",
    value: "archive",
  },
];

export const HOLD_STATUS = [
  { name: "Staff Hold", key: "field_staff_hold_count" },
  { name: "Executive Hold", key: "field_executive_hold_count" },
  { name: "Promotional Hold", key: "field_promotion_hold_count" },
  { name: "Artist Hold", key: "field_artist_hold_count" },
  { name: "Organization Hold", key: "field_vendor_hold_count" },
  { name: "Revenue Hold", key: "field_revenue_hold_count" },
  { name: "Reservation Hold", key: "field_reservation_hold_count" },
];

export const INVENTORY_FACILITIES = [
  {
    name: "Location",
    key: "field_location",
  },
  {
    name: "Balcony",
    key: "field_balcony",
  },
  {
    name: "View",
    key: "field_view",
  },
  {
    name: "Bed",
    key: "field_bed",
  },
  {
    name: "Extra Bed",
    key: "field_extra_bed",
  },
];

// export const QUERY_BUILDER_URL = "http://localhost:4000";
export const QUERY_BUILDER_URL = queryConfig.BASE_URL

export const uniqueColors = [
  "#2F4858",
  "#A84F97",
  "#91E385",
  "#FF4D4F",
  "#6B5695",
  "#0072CA",
  "#00A0AB",
  "#DF437E",
  "#3B537C",
  "#20C4A0",
  "#7E7188",
  "#FAAD14",
  "#876C83",
  "#98AC26",
  "#3E9C54",
  "#006673",
  "#594935",
  "#008371",
  "#0A99C9",
  "#8F667A",
  "#00B953",
  "#00AA80",
  "#FC8F3A",
  "#5675BC",
  "#0099A5",
  "#8C7964",
  "#0087C0",
  "#9A92B5",
  "#638D3F",
  "#41493B",
  "#A4AE9D",
  "#56423E",
  "#0084A4",
  "#A4B5D3",
  "#DD751F",
  "#4EA672",
  "#926F8F",
  "#A6665B",
  "#00858B",
  "#854E62",
  "#007FB7",
  "#8B68B2",
  "#7EB4C0",
  "#812A00",
  "#737010",
  "#7D5000",
  "#38BEA9",
  "#D0A097",
];

export const TRAVELER_REPORTING_ROLES = ["Event Partner Staff", "ICT Staff", "Staff Family Member", "VIP"]
