import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import { Card, Divider, Anchor } from "antd";

import React, { useState } from "react";
import { eventDiscountListingApi } from "../../../../apis/eventsApi";
import {
  addOrRemovePromoApi,
  getApplyDiscountListApi,
} from "../../../../apis/reservationApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  markPayeeTrigger,
  setActivityTrigger,
} from "../../../../redux/features/reservationFeatures/reservationSlice";
const { Option } = Select;

function AddPromoModal({
  reservationEventId,
  reservationId,
  addRemovePromo,
  setAddRemovePromo,
  reservationStatus,
  reservationUuid,
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [discountList, setDiscountList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const { isPayeeTrigger, isActivityTrigger } = useSelector(
    (state) => state.reservation
  );
  const showDrawer = () => {
    setOpen(true);
    getDiscountList(reservationUuid);
  };
  const getDiscountList = async (reId) => {
    setListLoading(true);
    const res = await getApplyDiscountListApi(reId);
    if (res.status) {
      setDiscountList(res?.data);
      setListLoading(false);
    } else {
      setDiscountList([]);
      message.error(SOMETHING_WENT_WRONG);
      setListLoading(false);
    }
  };
  const onClose = () => {
    setOpen(false);
    setListLoading(false);
  };
  const addPromoCode = async (payload) => {
    setListLoading(true);
    const res = await addOrRemovePromoApi(payload);
    if (res.status) {
      message.success("Promo Code Added");
      setAddRemovePromo(!addRemovePromo);
      setListLoading(false);
      dispatch(markPayeeTrigger(!isPayeeTrigger));
      dispatch(setActivityTrigger(!isActivityTrigger));
      onClose();
    } else {
      message.error(SOMETHING_WENT_WRONG);
      onClose();
      setListLoading(false);
    }
  };
  const onApplyHandler = async (data) => {
    let payload = {
      data,
    };
    addPromoCode(payload);
  };

  return (
    <>
      {reservationStatus?.key === "discarded" ||
      reservationStatus?.key === "cancelled" ||
      reservationStatus?.key === "completed" ? null : (
        <Button
          type="primary"
          onClick={showDrawer}
          disabled={!reservationEventId}
        >
          <PlusOutlined />
          <span> Add</span>
        </Button>
      )}

      <Drawer
        title="Apply Promocode"
        maxWidth={425}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        maskClosable={false}
      >
        <Spin spinning={listLoading} size="large">
          <Row gutter={24}>
            {discountList.map((discount) => {
              return (
                <Col span={24}>
                  <div className="pro_card">
                    <Card type="inner" style={{ width: 325 }}>
                      <div>
                        {discount?.field_promo_code && (
                          <h2
                            style={{
                              color: "#612500",
                              fontWeight: "700",
                              border: "1px dashed #FA8C16",
                              display: "inline-block",
                              padding: "4px 15px",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            {discount?.field_promo_code || ""}
                          </h2>
                        )}
                        <p style={{ color: "#8C8C8C", margin: "0px" }}>
                          {discount?.name?.name}
                        </p>
                        <h5
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                        >
                          {discount?.field_description || ""}
                        </h5>
                      </div>
                      <div className="card_button">
                        <Button
                          style={{ float: "right" }}
                          type="primary"
                          onClick={() => {
                            onApplyHandler({
                              operation: "add",
                              reservation_id: reservationId,
                              discount_id: discount?.id,
                              applied_on: "booking_engine",
                            });
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Spin>
        {/* </Form> */}
      </Drawer>
    </>
  );
}

export default AddPromoModal;
