import React, { useEffect, useState } from "react";
import {
  DownOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { Tree } from "antd";
import EditDeleteModal from "./EditDeleteModal";


const Inspiration = (props) => {
  const [data, setData] = useState(props.sourceList)
  const onSelect = (selectedKeys, info) => {
  };


  const getSecondChild = (details, firstChildIndex, sourceParaId, channelParaId) => {
    if (details.length) {
      let detailsArray = []
      details.map((val, index) => {
        detailsArray.push({
          title: <div className="inspiration_edit">
            <EditDeleteModal data={val} sourceModal={false} section="detail" sourceParaId={sourceParaId} channelParaId={channelParaId} setRefreshData={props.setRefreshData} tab={props.tab} />

          </div>,
          key: `${props.parentIndex}-${firstChildIndex}-0-${index}`,
        })
      })

      return detailsArray;
    }
    return [];
  }

  const getFirstChild = () => {
    let firstChildList = [];
    data?.channels?.map((channel, firstChildIndex) => {
      let firstChildData = {
        title: (
          <>
            <div className="inspiration_edit">

              <EditDeleteModal data={channel} sourceModal={false} section="channel" sourceParaId={data.para_id} channelParaId={channel.para_id} setRefreshData={props.setRefreshData} tab={props.tab} />
            </div>
          </>
        ),
        key: `${props.parentIndex}-${firstChildIndex}`,
        children: getSecondChild(channel.details, firstChildIndex, data.para_id, channel.para_id),
      }
      firstChildList.push(firstChildData)

    })

    return firstChildList
  }

  const treeData = [
    {
      title: (
        <>
          <div className="drag_icon">
            <HolderOutlined />
            <div className="inspiration_edit">
              <EditDeleteModal data={data} sourceModal={true} section="source" sourceParaId={data.para_id} setRefreshData={props.setRefreshData} tab={props.tab} />
            </div>
          </div>
        </>
      ),
      key: `${props.parentIndex}`,
      children: getFirstChild()
    },
  ];

  useEffect(() => {
    if (data.length) {
      data.forEach((children) => {

      })
    }
  }, [data])

  return (
    <Tree
      className="marketing_tree"
      showLine
      switcherIcon={<DownOutlined />}
      defaultExpandedKeys={["0-0-0"]}
      onSelect={onSelect}
      treeData={treeData}
      defaultSelectedKeys={false}
      defaultExpandParent={false}
      defaultCheckedKeys={false}
    />
  );
};
export default Inspiration;
