import React from 'react';


import Res_activityemailform from '../../Forms/Res_activityemailform/Res_activityemailform';
import Res_activityemailformtwo from '../../Forms/Res_activityemailformtwo/Res_activityemailformtwo';

const Res_activityemailviewpage = () => (
    <div className='details-tabs' style={{ margin: 0 }}>

        <div>
            <div className='basic_details pd-16 bg-white'>
                <Res_activityemailform />
            </div>
            <div className='basic_details pd-16 bg-white'>
                <Res_activityemailformtwo />
            </div>
        </div>
    </div>

);

export default Res_activityemailviewpage;