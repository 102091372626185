import React, { useState, useEffect, useRef } from "react";
import { Col, message, Row } from "antd";
import "./OrganizationDetailsForm.less";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { AutoComplete, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import {
  createOrganization,
  organizationStepSetter,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import CustomUpload from "../../../others/CustomUpload/CustomUpload";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function CompanyInformation({
  organisationFormFields,
  setOrganisationFormFields,
  createId,
  editId,
  form4,
  currentStep,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countriesList = Country.getAllCountries();
  const createOrganizationRef = useRef(false);
  const { organizationCreation } = useSelector((state) => state.organizations);
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const {
    organizationCreationResponse,
    loadingOrganizationCreation,
    pressedNextButton,
    step4,
    organizationStep,
  } = organizationCreation;
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDocumentId, setuploadDocuementId] = useState("");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if ((editId || createId) && Object.keys(organisationFormFields).length) {
      dispatch(organizationStepSetter(organisationFormFields.field_step));
      if (!organisationFormFields?.field_tax_form?.data) {
        setFileList([]);
      }
      if (
        Object.keys(
          organisationFormFields?.field_tax_form?.meta?.media_data || {}
        ).length
      ) {
        setFileList([
          {
            uid: organisationFormFields?.field_tax_form?.meta?.media_data?.uuid,
            name: organisationFormFields?.field_tax_form?.meta?.media_data
              ?.filename,
            url: organisationFormFields?.field_tax_form?.meta?.media_data?.uri,
            thumbUrl:
              organisationFormFields?.field_tax_form?.meta?.media_data?.uri,
          },
        ]);
        setuploadDocuementId(organisationFormFields?.field_tax_form?.id);
      }

      form4.setFieldsValue(organisationFormFields);
    }
  }, [organisationFormFields]);
  useEffect(() => {
    if (createOrganizationRef.current) {
      if (organizationCreationResponse?.status) {
        if (createId) {
          message.success("Form Submitted Successfully!");
          navigate(`/organization/details?id=${createId}`);
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            navigate(`/organization/details?id=${editId}`);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateOrganizationStep());
      } else {
        message.error(
          organizationCreationResponse?.data?.message || SOMETHING_WENT_WRONG
        );
      }
    }
    createOrganizationRef.current = true;
  }, [organizationCreationResponse]);

  const onFinishHandler = (values) => {
    const reqMethod = "patch";
    const orgId = editId || createId;
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const payload = {
      data: {
        type: "node--organization",
        id: orgId,
        attributes: {
          field_commission_rate: values?.field_commission_rate,
          field_deduct_commission: values?.field_deduct_commission,
          field_ein: values?.field_ein,
          field_iata_arc_clia: values?.field_iata_arc_clia,
          field_step: currentStep
            ? currentStep > organizationStep
              ? +currentStep
              : organizationStep
            : 1,
        },
        relationships: {
          field_tax_form: {
            data: !!fileList.length
              ? {
                  type: "media--document",
                  id: uploadDocumentId,
                }
              : {},
          },
          field_modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    dispatch(createOrganization({ reqMethod, orgId, reqHeaders, payload }));
  };
  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 6,
              }}
              wrapperCol={{
                sm: 24,
                md: 18,
                lg: 18,
              }}
              layout="horizontal"
              form={form4}
              onFinish={onFinishHandler}
            >
              <Form.Item label="EIN" name="field_ein">
                <Input placeholder="EIN" />
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="Tax Form"
                name="taxform"
              >
                <div>
                  <CustomUpload
                    fileList={fileList}
                    isMultiple={false}
                    setFileList={setFileList}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    uploadUrl="/api/v1/media/document/field_media_document"
                    mediaDocument={true}
                    setuploadDocuementId={setuploadDocuementId}
                    linkMediaUrl="/api/v1/media/document"
                    linkMediaRelation="field_media_document"
                    linkMediaType="media--document"
                    availableFormat={
                      "csv, txt, rtf, doc, docx, ppt, pptx, xls, xlsx, pdf, odf, odg, odp, ods, odt, fodt, fods, fodp, fodg, key, numbers, pages"
                    }
                    availableSize={"100 MB"}
                  />
                </div>
              </Form.Item>

              <Form.Item
                className="asterick-align"
                label="IATA/ARC/CLIA"
                name="field_iata_arc_clia"
              >
                <Input placeholder="IATA/ARC/CLIA" />
              </Form.Item>
              <Form.Item
                className="asterick-align"
                label="Commission Rate"
                name="field_commission_rate"
              >
                <Input placeholder="Commission Rate" />
              </Form.Item>
              <Form.Item
                className="asterick-align"
                label="Deduct Commission"
                valuePropName="checked"
                name="field_deduct_commission"
              >
                <Switch />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CompanyInformation;
