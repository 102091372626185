import React from "react";
import "./OrganizationSearchBar.less";
import { useEffect, useState, useRef } from "react";
import {
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { AutoComplete, Dropdown, Space, Col, Row, Grid, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import { Button } from "antd";

import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
import {
  getAllOrganisationsApi,
  // organizationSearchApi,
} from "../../../apis/organisationApi";
import { getOrganizationTypeApi } from "../../../apis/organisationApi";
import { useLocation, useNavigate } from "react-router";
import { useDebounce } from "../../../others/Debounce/debounce";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import { decodeContent } from "../../../utils/contentParser";
import { useDispatch } from "react-redux";
import {
  orgSearchFilterApplied,
  orgSearchFilterSetter,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { organizationTypeList } from "../../../others/util/commonFunctions";
const { useBreakpoint } = Grid;

const OrganizationSearchBar = ({
  organizationName,
  organizationType,
  setUrlParamsObject,
  urlParamsObject,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const customFilterRef = useRef();
  const filterInputRef = useRef();
  const [orgsType, setOrgsType] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(organizationName);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { isFilterApplied } = useSelector((state) => state.organizations);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  useEffect(() => {
    // getOrganizationType();
    form.setFieldsValue({
      org_type: organizationType,
    });
  }, []);

  useEffect(() => {
    if (organizationType) {
      dispatch(orgSearchFilterApplied(true));
      dispatch(
        orgSearchFilterSetter({
          "Organization Type": organizationType
            ? organizationTypeList.find(
                (orgType) => orgType?.key === organizationType
              )?.label
            : null,
        })
      );
    } else {
      dispatch(orgSearchFilterApplied(false));
      dispatch(orgSearchFilterSetter({}));
    }
  }, [orgsType]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     const listComponents = document.querySelectorAll(".rc-virtual-list");

  //     let isClickedOnList = false;
  //     listComponents?.forEach((ele) => {
  //       if (ele?.contains(event.target)) {
  //         isClickedOnList = true;
  //       }
  //     });
  //     if (
  //       // customFilterRef.current &&
  //       !filterInputRef?.current?.contains(event.target) &&
  //       !customFilterRef?.current?.contains(event.target) &&
  //       !isClickedOnList
  //     )
  //       setIsVisible(false);
  //   }
  //   if (isVisible) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isVisible]);

  // useEffect(() => {
  //   organizationType &&
  //     form.setFieldsValue({ organization_type: organizationType });
  // }, []);

  const getOrganizationSearchResult = async (url, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }
    let response = await getAllOrganisationsApi({
      name: searchVal,
      // organization_type: searchVal,
    });
    if (response?.status) {
      let searchOptions = response?.data?.rows?.map((option) => {
        return {
          key: option?.nid,
          value: (
            <span
              style={{ width: "100%", display: "block" }}
              onClick={() => {
                navigate(
                  `/organization/details?id=${option?.field_organization_uuid}`
                );
                setAutoCompleteOpen(false);
              }}
            >
              {decodeContent(option?.title)}
            </span>
          ),
        };
      });

      if (searchOptions.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== "/organization/listing"
                    ? navigate(
                        `/organization/listing?count=10&order=ASC&page=1&name=${searchVal}`
                      )
                    : setUrlParamsObject({
                        count: 10,
                        order: "ASC",
                        page: 1,
                        name: searchVal || organizationName,
                      });
                  setAutoCompleteOpen(false);
                }}
              >
                see all results for {searchVal || organizationName}
              </span>
            ),
          },
        ]);
        setAutoCompleteOpen(true);
        setIsVisible(false);
      } else {
        setOptions([]);
        setAutoCompleteOpen(true);
        setIsVisible(false);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setIsVisible(false);
      setAutoCompleteOpen(false);
      setOptions([]);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      let url = `api/organization/list?count=5&search=true&name=${debouncedSearchTerm}`;
      getOrganizationSearchResult(url, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
      location.pathname === "/organization/listing" &&
        setUrlParamsObject({
          ...urlParamsObject,
          name: null,
        });
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const getOrganizationType = async () => {
    const reqHeaders = customRequestHeader({
      token: Token,
      cookie: Cookie,
      defaultFormat: true,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const response = await getOrganizationTypeApi({ reqHeaders });
    if (response.status) {
      console.log("resssss", response?.data?.data);
      if (response?.data?.data.length) {
        setOrgsType(response?.data?.data);
      } else {
        setOrgsType([]);
        message.error(SOMETHING_WENT_WRONG);
      }
    } else {
      setOrgsType([]);
      form.setFields([
        {
          name: "organization",
          errors: ["Unable to fetch organization data"],
        },
      ]);
    }
  };
  const onFinishHandler = (values) => {
    for (let prop in values) {
      if (!values[prop]) {
        delete values[prop];
      }
    }
    const result = new URLSearchParams(values).toString();
    if (result.length) {
      console.log("first if");
      if (location.pathname !== "/organization/listing") {
        console.log(" === iiff");
        navigate(`/organization/listing?count=10&order=ASC&page=1&${result}}`);
      } else {
        setUrlParamsObject({
          ...urlParamsObject,
          page: 1,
          order:
            urlParamsObject.order === "recently_read"
              ? "ASC"
              : urlParamsObject.order,
          ...values,
        });
      }
      setAutoCompleteOpen(false);
      setIsVisible(false);
      dispatch(orgSearchFilterApplied(true));
      dispatch(
        orgSearchFilterSetter({
          "Organization Type": values?.org_type
            ? organizationTypeList.find(
                (orgType) => orgType?.key === values?.org_type
              )?.label
            : null,
        })
      );
    }
  };

  const custumSearch = (
    <div className="custom_search searchbox__dropdown" ref={customFilterRef}>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          lg: 24,
          xs: 14,
        }}
        layout="horizontal"
        style={{ fontFamily: "'Poppins', sans-serif" }}
        form={form}
        onFinish={onFinishHandler}
      >
        <Form.Item
          label="Organization Type"
          style={{ fontFamily: "'Poppins', sans-serif" }}
          name="org_type"
        >
          <Select
            placeholder="Select Organization Type"
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ fontFamily: "'Poppins', sans-serif", maxWidth: "390px" }}
            options={organizationTypeList.map((orgType) => ({
              key: orgType.key,
              value: orgType.key,
              label: orgType.label,
            }))}
            allowClear
          ></Select>
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            type="text"
            style={{ border: "none", opacity: "0.85", marginRight: "10px" }}
            onClick={() => {
              form.resetFields();
              setUrlParamsObject({
                count: urlParamsObject.count,
                order: urlParamsObject.order,
                page: urlParamsObject.page,
              });
              setSearchValue("");
              setIsVisible(false);
              dispatch(orgSearchFilterApplied(false));
              dispatch(orgSearchFilterSetter({}));
            }}
          >
            Clear Filter
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align" ref={filterInputRef}>
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSearch={handleSearch}
              defaultValue={organizationName}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              onClear={() => {
                setAutoCompleteOpen(false);
                location.pathname !== "/organization/listing"
                  ? setSearchValue("")
                  : setUrlParamsObject({
                      ...urlParamsObject,
                      name: null,
                    });
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (location.pathname !== "/organization/listing") {
                    navigate(
                      `/organization/listing?count=10&order=ASC&page=1&search=true&name=${e.target.value}`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      page: 1,
                      order:
                        urlParamsObject.order === "recently_read"
                          ? "ASC"
                          : urlParamsObject.order,
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                  }
                }
              }}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              open={autoCompleteOpen}
              placeholder="Search Organizations"
              size="large"
              value={searchValue}
            />

            <Dropdown
              overlay={custumSearch}
              overlayStyle={{ maxWidth: "560px", width: "100%" }}
              placement="bottomRight"
              trigger={["click"]}
              open={isVisible}
              onOpenChange={(e) => {
                setIsVisible(e);
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setAutoCompleteOpen(false);
                }}
              >
                <Space>
                  <FilterOutlined
                    className="active-filter active-hover"
                    style={{
                      opacity: "0.5",
                      color: "#1A7F93",
                      backgroundColor: isFilterApplied
                        ? "rgba(26, 127, 147, 0.2)"
                        : "transparent",
                      //   backgroundColor: "#000",
                    }}
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationSearchBar;
