import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Radio, Select } from "antd";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";

const { TextArea } = Input;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TourPrePostStaffAndVehicleForm = ({
  form,
  onFinishHandler,
  isLoading,
}) => {
  const [guideRequestList, setGuideRequestList] = useState([]);
  const [loadingGuideRequest, setLoadingGuideRequest] = useState(false);
  useEffect(() => {
    getTaxonomyList(
      "guide_request",
      setGuideRequestList,
      setLoadingGuideRequest
    );
  }, []);

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0 ms-0">Staffing & Vehicles</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="control-hooks"
          onFinish={onFinishHandler}
        >
          <Form.Item name="field_guide_request" label="Guide Request">
            <Select
              mode="multiple"
              showArrow
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={guideRequestList?.map((guideRequest) => ({
                key: guideRequest.id,
                value: guideRequest.id,
                label: guideRequest.name,
              }))}
              loading={loadingGuideRequest}
            />
          </Form.Item>

          <Form.Item
            label="# of Travel Coordinators"
            name="field_travel_coordinators"
          >
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Free VIP Vehicle & Driver"
            name="field_free_vip_vehicle_driver"
          >
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Additional VIP Vehicle & Driver"
            name="field_additional_vip_vehicle_driver"
          >
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Senior Sound Tech" name="field_senior_sound_tech">
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Sound Techs" name="field_sound_techs">
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Sound Assistant" name="field_sound_assistant">
            <Input
              style={{ width: "100%" }}
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "."
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Sound Tech Van" name="field_sound_tech_van">
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Luggage Truck" name="field_luggage_truck">
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="textarea_row">
            <Form.Item label="Notes" name="field_staffing_vehicle_notes">
              <TextArea rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};
export default TourPrePostStaffAndVehicleForm;
