import React from "react";
import EditActivityDetailForm from "./EditActivityDetailForm";
import EditActivityMessageForm from "./EditActivityMessageForm";

const EditActivityViewForm = ({ form, activityDetails }) => (
  <div className="details-tabs" style={{ margin: 0 }}>
    <div>
      <div className="basic_details pd-16 bg-white">
        <EditActivityDetailForm form={form} activityDetails={activityDetails} />
      </div>
      {/* <div className="basic_details pd-16 bg-white">
        <EditActivityMessageForm form={form} />
      </div> */}
    </div>
  </div>
);

export default EditActivityViewForm;
