import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EventCategoryDetailsRoute } from "../../../../../../url-routes/urlRoutes";

function AvailabilitySoldCountHeader({ eventId, categoryId }) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() =>
              navigate(
                `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`
              )
            }
          />
          <div className="header_res">
            <p>Sold Count</p>
          </div>
        </h3>
      </div>
    </div>
  );
}
export default AvailabilitySoldCountHeader;
