import {
  CloseOutlined,
  LeftOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Col, Drawer, Grid, Input, Row } from "antd";
import React, { useState } from "react";
import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
import Sidebar from "../../Header/Header_sidebar";
import { useNavigate } from "react-router-dom";
import { searchRoleApi } from "../../../apis/manageRoles";

const { Search } = Input;
const { useBreakpoint } = Grid;
const RolesSearchbar = () => {
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
 
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSearch = async (value) => {
    if(value){
      const response = await searchRoleApi(value);
      const results = response?.data?.data
        .filter((option) =>
          option.label.toLowerCase().includes(value.toLowerCase())
        )
        .map((option) => ({ value: option.label ,id:option.id}));
        
      setOptions(results);
    }else{
      setOptions([])
    }
  };

  const onSelect = (name,value) => {

    navigate(`/manage-roles/details?role=${value.id}`);
  };

  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  return (
    <div className="horizontal_menu complete_events_filter">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group">
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              allowClear={{ clearIcon: <CloseOutlined /> }}
            >
              <Input.Search
                size="large"
                placeholder="Search Roles"
                enterButton
              />
            </AutoComplete>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
};

export default RolesSearchbar;
