import { Form, Input, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import {
  addonStepSetter,
  createOrEditAddon,
  updateAddonStep,
} from "../../../../../redux/features/eventFeatures/addonsSlice";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddonsAccountingInfoForm = ({
  form4,
  createId,
  editId,
  eventId,
  addonFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addonCreation } = useSelector((state) => state.addons);
  const { addonCreationResponse, loadingAddonCreation, step4, addonStep } =
    addonCreation;
  const [coaBrandList, setCoaBrandList] = useState([]);
  const [loadingCoaBrandList, setLoadingCoaBrandList] = useState(false);
  const [commissionCalculationList, setCommissionCalculationList] = useState(
    []
  );
  const [loadingCommissionList, setLoadingCommissionList] = useState(false);
  const addonCreationRef = useRef(false);

  useEffect(() => {
    getTaxonomyList("coa_brand", setCoaBrandList, setLoadingCoaBrandList);
    getTaxonomyList(
      "commission_calculation",
      setCommissionCalculationList,
      setLoadingCommissionList
    );
  }, []);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(addonFormFields).length) {
      dispatch(addonStepSetter(addonFormFields.field_step));
      form4.setFieldsValue({
        ...addonFormFields,
        field_coa_brand: addonFormFields?.field_coa_brand?.id,
        field_commission_calculation:
          addonFormFields?.field_commission_calculation?.id,
      });
    }
  }, [addonFormFields]);

  useEffect(() => {
    if (addonCreationRef.current) {
      if (addonCreationResponse.status) {
        dispatch(
          addonStepSetter(addonCreationResponse?.data?.data?.field_step || 1)
        );
        if (createId) {
          message.success("Form Submitted Successfully");
          navigate(
            `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${createId}`
          );
        } else {
          if (editId) {
            message.success("Form Submitted Successfully");
            navigate(
              `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${editId}`
            );
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateAddonStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    addonCreationRef.current = true;
  }, [addonCreationResponse]);

  const onFinishHandler = (values) => {
    const attributes = {
      field_coa_trip: values.field_coa_trip,
      field_step: currentStep > addonStep ? +currentStep : addonStep,
    };
    const relationships = {
      field_event: {
        data: {
          type: "event--event",
          id: eventId,
        },
      },
      field_coa_brand: values?.field_coa_brand
        ? {
            data: {
              type: "taxonomy_term--coa_brand",
              id: values.field_coa_brand,
            },
          }
        : null,
      field_commission_calculation: values?.field_commission_calculation
        ? {
            data: {
              type: "taxonomy_term--commission_calculation",
              id: values.field_commission_calculation,
            },
          }
        : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        id: createId || editId,
        attributes,
        relationships,
      },
    };
    const payload = { reqType: "patch", uuid: createId || editId, data };
    dispatch(createOrEditAddon(payload));
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form4}
          onFinish={onFinishHandler}
        >
          <Form.Item label="COA Brand" name="field_coa_brand">
            <Select
              allowClear
              placeholder="COA Brand"
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={coaBrandList.map((coaBrand) => ({
                key: coaBrand.id,
                value: coaBrand.id,
                label: coaBrand.name,
              }))}
            />
          </Form.Item>

          <Form.Item label="COA Trip" name="field_coa_trip">
            <Input />
          </Form.Item>

          <Form.Item
            label="Commission Calculation"
            name="field_commission_calculation"
          >
            <Select
              allowClear
              placeholder="Commission Calculation"
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={commissionCalculationList.map((coaBrand) => ({
                key: coaBrand.id,
                value: coaBrand.id,
                label: coaBrand.name,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddonsAccountingInfoForm;
