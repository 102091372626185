import React from "react";
import ReservationsDesktop from "./ReservationsDesktop";
import "./ReservationsMain.less";

const ReservationsMain = () => {
  return (
    <div className="responsive">
      <ReservationsDesktop />
    </div>
  );
};

export default ReservationsMain;
