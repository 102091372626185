import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid, message, Form } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
// import Org_horizontalmenu from "../../components/Header/Org_horizontalmenu";
import Searchreservations_menu from "../../components/Reservation/ReservationSearchBar";
import Breadcrumb_editguestdetails from "../../components/Breadcrumb/Breadcrumb_editguestdetails/Breadcrumb_editguestdetails";
import Res_editguestformsection from "../../components/Reservation/ReservationDetails/ReservationDetailSections/Travelers/EidtTravelers/Res_editguestformsection";
import EditTravelers_tabs from "../../components/Reservations_components/Reservationsview_folders/EditTravelers_tabs";
import React, { useEffect, useState } from "react";
import {
  getAllTravelerDetails,
  getReservationDetailApi,
  getTravelersData,
} from "../../apis/reservationApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../others/Loaders/DataLoader";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  ReservationDetailRoute,
  ReservationRoute,
} from "../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { useSelector } from "react-redux";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

function Res_editguestdetails() {
  const { useBreakpoint } = Grid;
  const [allTravelersData, setAllTravelersData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [form] = Form.useForm();
  const id = searchParams.get("id");
  const [reservationData, setReservationData] = useState({});
  const [loadingReservationData, setLoadingReservationData] = useState(false);
  const { isTravellerRemoved } = useSelector((state) => state.reservation);
  const navigate = useNavigate();

  const breadCrumbs = [
    { title: "Reservations", path: ReservationRoute },
    { title: "Detail", path: `${ReservationDetailRoute}?id=${id}` },
    { title: "Edit Traveler Detail" },
  ];

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  const getTravelersData = async (id) => {
    const response = await getAllTravelerDetails(id);
    if (response && response.status) {
      setAllTravelersData(response?.data?.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setDataLoading(false);
  };

  const getReservationDetail = async (reservationId) => {
    setLoadingReservationData(true);
    const res = await getReservationDetailApi(reservationId);
    if (res.status) {
      setReservationData(res?.data?.data);
      setLoadingReservationData(false);
    } else {
      setReservationData({});
      message.error(SOMETHING_WENT_WRONG);
      setLoadingReservationData(false);
    }
  };
  useEffect(() => {
    const isAllowed = permissionChecker("add/edit reservation");
    if (!isAllowed) {
      navigate("/page-not-access");
    }
  }, []);
  useEffect(() => {
    if (searchParams) {
      // getTravelers(id)
      getReservationDetail(id);
      getTravelersData(id);
      setDataLoading(true);
    }
  }, [searchParams, isTravellerRemoved]);
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Searchreservations_menu />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <Breadcrumb_editguestdetails
              form={form}
              loading={loading}
              name={allTravelersData?.name}
              reservationData={reservationData}
            />
          </div>
          <Content>
            <div className="basic_details pd-16 bg-white">
              {dataLoading || loadingReservationData ? (
                <DataLoader />
              ) : (
                <Res_editguestformsection
                  allTravelersData={allTravelersData}
                  form={form}
                  setLoading={setLoading}
                  reservationStatus={reservationData?.reservation_status?.key}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Res_editguestdetails;
