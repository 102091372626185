import { Table, Select, Input } from 'antd';
import { SettingOutlined, MailOutlined, CopyOutlined } from '@ant-design/icons';
import React from 'react';
import { useState } from "react";
import "./Res_table.less";
const handleChange = (value) => {
  console.log(`selected ${value}`);
};




const Option = Select.Option;
function Res_table_two() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const start = () => {
    setLoading(true); // ajax request after empty completing

    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onChange = (value) => {
    console.log(`onChange ${value}`);
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [editingStudent, setEditingStudent] = useState(null);
  //  data column



  const columns = [
    {
      title: 'Kelly Astemborski',
      dataIndex: 'Kelly Astemborski',
    },
    {
      title: <div>
        <p style={{ margin: '0px' }}>01/15/1960 <span className='span_text'>Age 59</span></p>
      </div>,
      dataIndex: '01/15/1960 Age 59',
    },
    {
      title: <div>
        <p style={{ margin: "0px" }}><MailOutlined />  &nbsp;alohakelly@gmail.com</p>
      </div>,
      dataIndex: 'astemdw@gmail.com',
    },
    {
      title: <div className='select_header'>
        <Select
          defaultValue="Brother"
          style={{
            width: 145,
          }}

          options={[
            {
              value: 'Brother',
              label: 'Brother',
            },
            {
              value: 'Daughter',
              label: 'Daughter',
            },
            {
              value: 'Father',
              label: 'Father',
            },
            {
              value: 'Friend',
              label: 'Friend',
            },
            {
              value: 'Grandchild',
              label: 'Grandchild',
            },
            {
              value: 'Grandparent',
              label: 'Grandparent',
            },

          ]}
        /></div>,
      dataIndex: 'address',
    },
    {
      title: <>
        <div >
          <a href='#' className='convert_intake'><CopyOutlined /> Copy To Others</a>
        </div>
      </>,
      dataIndex: 'address',
    },
  ];






  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };



  const onEditStudent = (record) => {
    setIsEditing(true);
    setEditingStudent({ ...record });
  };

  const resetEditing = () => {
    setIsEditing(false);
    setEditingStudent(null);
  };
  const { Search } = Input;
  const onSearch = (value) => console.log(value);

  return (
    <>
      <div className='Res_tables'>
        <Table columns={columns} />
      </div>

    </>
  );


}
export default Res_table_two;