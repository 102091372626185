import { Button, Image, Modal, Result, message } from "antd";
import React, { useEffect, useState } from "react";
import PaymentLoading from "../../assests/images/payment.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  payPlannedPaymentWithStripeApi,
  paymentWithStripeApi,
} from "../../apis/reservationApi";
import {
  ReservationListingRoute,
  ReservationPaymentDetailRoute,
  ReservationPlannedPaymentDetailRoute,
} from "../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const PaymentConfirmStatusPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get("session_id");
  const checkoutSessionSuccess = searchParams.get("success");
  const paymentType = searchParams.get("type");
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let payload = {
      label: "check_status",
      session_id: checkoutSessionId,
      success: JSON.parse(checkoutSessionSuccess),
    };
    if (paymentType === "payment") {
      checkPaymentStatus(payload);
    }
    if (paymentType === "planned_payment") {
      checkPlannedPaymentStatus(payload);
    }
  }, []);

  const checkPaymentStatus = async (payload) => {
    const res = await paymentWithStripeApi(payload);
    if (res.status) {
      if (
        res?.data?.payment_status === "complete" ||
        res?.data?.payment_status === "unpaid"
      ) {
        res?.data?.payment_status === "complete"
          ? message.success("Transaction Successful")
          : message.error("Transaction Failed");
        navigate(
          `${ReservationPaymentDetailRoute}?id=${res?.data?.reservation_id}&paymentId=${res?.data?.payment_uuid}`
        );
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setShowButton(true);
    }
  };

  const checkPlannedPaymentStatus = async (payload) => {
    const res = await payPlannedPaymentWithStripeApi(payload);
    if (res.status) {
      if (
        res?.data?.payment_status === "complete" ||
        res?.data?.payment_status === "unpaid"
      ) {
        res?.data?.payment_status === "complete"
          ? message.success("Transaction Successful")
          : message.error("Transaction Failed");
        navigate(
          `${ReservationPlannedPaymentDetailRoute}?id=${res?.data?.reservation_id}&paymentId=${res?.data?.planned_payment_uuid}`
        );
      }
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setShowButton(true);
    }
  };
  return (
    <Modal open={true} closable={false} footer={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "500px",
        }}
      >
        {showButton ? (
          <>
            <Result
              status="error"
              title="Error In Transaction"
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(ReservationListingRoute);
                  }}
                >
                  Go to Reservation
                </Button>
              }
            />
          </>
        ) : (
          <div>
            <img src={PaymentLoading} style={{ width: "250px" }} />
            <h1>Verifying Transaction...</h1>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentConfirmStatusPage;
