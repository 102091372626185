import { Table, Button, Divider } from "antd";
import Contact_details from "./SideDrawerForms/ContactPreferencesDrawer";
import React from "react";
import ContactPreferencesDrawer from "./SideDrawerForms/ContactPreferencesDrawer";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const Preferences = ({ contactPreferencesData, contactId, isLoading }) => {
  const {
    field_destination_interests,
    field_airline,
    field_airplane_class,
    field_airplane_seat,
    field_hotel_room,
  } = contactPreferencesData || {};
  const preferencesDataSource = [
    {
      key: "destination-interests",
      label: "Destination Interests",
      value: !!field_destination_interests?.length
        ? field_destination_interests
            ?.map((destination) => destination.name)
            .join(", ")
        : "",
    },
    {
      key: "airline",
      label: "Airline",
      value: field_airline?.name || "",
    },
    {
      key: "airplane-class",
      label: "Airplane Class",
      value: field_airplane_class?.name || "",
    },
    {
      key: "airplane-seat",
      label: "Airplane Seat",
      value: field_airplane_seat?.name || "",
    },

    {
      key: "hotel-room",
      label: "Hotel Room",
      value: field_hotel_room?.name || "",
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Travel Preferences</h4>
        <ContactPreferencesDrawer
          contactPreferencesData={contactPreferencesData}
          contactId={contactId}
        />
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={preferencesDataSource}
        showHeader={false}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default Preferences;
