import { Breadcrumb, Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  AddonCategoryDetailsRoute,
  ContactDetailRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
  ReservationDetailRoute,
} from "../../../../../url-routes/urlRoutes";
import { getInventoryReservationsListApi } from "../../../../../apis/eventsApi";
import { getPrimaryTravelerName } from "../../../../../others/util/commonFunctions";
import { addCommasInPricing } from "../../../../../utils/contentParser";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EventReservationHeader from "../../../../../components/Events/EventDetails/Reservations/EventReservationHeader";
import EventReservationListing from "../../../../../components/Events/EventDetails/Reservations/EventReservationListing";
import InventoryReservations from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/InventoryReservations/InventoryReservations";
import InventoryReservationsHeader from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/Inventory/InventoryReservations/InventoryReservationsHeader";
import { urlParser } from "../../../../../utils/contentParser";
import moment from "moment";
const { Header, Content, Sider } = Layout;

function InventoryReservationsListPage() {
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const categoryId = searchParams.get("categoryId");
  const [urlParamsObject, setUrlParamsObject] = useState({
    ...urlParser(window.location.search),
    order: searchParams.get("order") || "ASC",
    count: searchParams.get("count") || "10",
    page: searchParams.get("page") || 1,
  });
  const getBreadCrumbs = (addonId) => {
    const breadCrumbs = [
      { title: "Events", path: EventsListingRoute },
      { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    ];
    if (addonId) {
      breadCrumbs.push(
        {
          title: "Add-Ons",
          path: `${EventDetailsRoute}?id=${eventId}#Add-Ons`,
        },
        {
          title: "Add-Ons Detail",
          path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
        },
        {
          title: "Category Detail",
          path: `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`,
        },
        { title: "Reservations", path: "" }
      );
    } else {
      breadCrumbs.push(
        {
          title: "Category Detail",
          path: `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${categoryId}`,
        },
        { title: "Reservations", path: "" }
      );
    }
    return breadCrumbs;
  };

  const [reservationList, setReservationList] = useState([]);
  const [count, setCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setSearchParams(urlParamsObject);
    let backendUrlObj = {
      order: urlParamsObject?.order,
      count: urlParamsObject?.count,
      page: Number(urlParamsObject?.page) - 1,
      res_status: urlParamsObject?.res_status || null,
      fin_status: urlParamsObject?.fin_status || null,
    };
    getInventoryReservationsList(backendUrlObj);
  }, [urlParamsObject]);

  const getInventoryReservationsList = async (data) => {
    data.inventoryId = searchParams.get("inventoryId");
    setDataLoading(true);
    const res = await getInventoryReservationsListApi(data);
    if (res.status) {
      setDataLoading(false);
      setReservationList(res?.data?.rows);
      setCount(res?.data?.pager?.total_items || 0);
    } else {
      setDataLoading(false);
      setReservationList([]);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={getBreadCrumbs(addonId)} />
          <div className="events-header">
            <InventoryReservationsHeader
              setUrlParamsObject={setUrlParamsObject}
              urlParamsObject={urlParamsObject}
              eventId={eventId}
              categoryId={categoryId}
            />
          </div>
          <Content>
            <InventoryReservations
              reservationList={reservationList}
              count={count}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              dataLoading={dataLoading}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default InventoryReservationsListPage;
