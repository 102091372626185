import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdownSearchbar from "../../../components/Admin/CustomDropdown/CustomDropdownSearchbar";
import EmailMonitorHeader from "../../../components/Admin/EmailMonitor/EmailMonitorHeader";
import PendingEmailMonitorContainer from "../../../components/Admin/EmailMonitor/PendingEmailMonitorContainer";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import LastDaysEmailMonitorContainer from "../../../components/Admin/EmailMonitor/LastDaysEmailMonitorContainer";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import { permissionChecker } from "../../../utils/permisssionChecker";
import { useNavigate } from "react-router-dom";
const { Header, Content, Sider } = Layout;

const EmailMonitorPage = () => {
  const [monitoringData,setMonitoringData] = useState({pending:[],completed:[]})
  const { useBreakpoint } = Grid;
  const navigate = useNavigate()
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Email Monitor", path: "/email_monitor" },
  ];
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu email monitor");
    if(!isAllowed){
      return navigate("/page-not-access")
    }
    (async()=>{
      const [pending,completed] = await Promise.all([
        await drupalAxiosRequest("get","/api/rest/email-monitor/pending",customRequestHeader({token:Token,defaultFormat:true})),
        await drupalAxiosRequest("get","/api/rest/email-monitor/listing",customRequestHeader({token:Token,defaultFormat:true}))
      ])
      setMonitoringData({pending,completed})
    })()
  },[])

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <CustomDropdownSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EmailMonitorHeader />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            {monitoringData?.pending && <PendingEmailMonitorContainer pendingEmail = {monitoringData?.pending} />}
          </Content>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
             {monitoringData?.completed && <LastDaysEmailMonitorContainer completed = {monitoringData?.completed}  />}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EmailMonitorPage;
