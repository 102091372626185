import { Table, Divider, message } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { inventoryAvailabilityApi } from "../../../../../../apis/eventsApi";
import { useSelector } from "react-redux";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import {
  AddonCategoryAvailabilityRoute,
  CategoryAvailabilityRoute,
} from "../../../../../../url-routes/urlRoutes";

const columns = [
  {
    title: "Reservation #",
    dataIndex: "rez",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];

const CategoryAvailablity = () => {
  const [availability, setavailability] = useState({});
  const [searchParamas, setSearchParams] = useSearchParams();
  const eventId = searchParamas.get("id");
  const categoryId = searchParamas.get("categoryId");
  const addonId = searchParamas.get("addonId");
  const { isInventoryUpdated } = useSelector((state) => state.events);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInventoryAvailability();
  }, [isInventoryUpdated, categoryId]);

  const getInventoryAvailability = async () => {
    setLoading(true);
    const response = await inventoryAvailabilityApi(categoryId);
    if (response.status) {
      setavailability(response?.data?.data);
      setLoading(false);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
      setLoading(false);
    }
  };

  const data = [
    {
      key: "1",
      rez: "Total Count",
      name: <>{availability?.total_count ?? ""}</>,
    },
    {
      key: "2",
      rez: "Hold Count",
      name: <>{availability?.hold_count ?? ""}</>,
    },
    {
      key: "3",
      rez: "Sold Count",
      name: (
        <>
          <>{availability?.sold_count ?? ""}</>
          {availability?.sold_count >= 0 ? (
            <Link
              target={"_blank"}
              to={
                addonId
                  ? `${AddonCategoryAvailabilityRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
                  : `${CategoryAvailabilityRoute}?id=${eventId}&categoryId=${categoryId}`
              }
              style={{ paddingLeft: "4px" }}
            >
              View
            </Link>
          ) : null}
        </>
      ),
    },

    {
      key: "5",
      rez: "Avail Count",
      name: <>{availability?.available_count ?? ""}</>,
    },

    {
      key: "6",
      rez: "Avail Share Count",
      name: <>{availability?.availshare_count ?? ""}</>,
    },

    {
      key: "7",
      rez: "Avail Percent",
      name: <>{availability?.available_percentage ?? 0}%</>,
    },
  ];

  return (
    <div>
      <div
        className="reservarion_heading bg-white"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Availability</h4>
      </div>

      <Divider className="global_divider mb-0" />

      <Table
        className="reservat_table"
        loading={{ spinning: loading, size: "large" }}
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </div>
  );
};
export default CategoryAvailablity;
