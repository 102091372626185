import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllContactsApi,
  getContactAllDetailsApi,
  updateContactDetailsApi,
  createAndEditContactApi,
  getAllActivitiesApi,
  getOneActivityDetailsApi,
  editTaskActivityApi,
  getAllRequestListApi,
  updateRequestApi,
} from "../../../apis/contactApi";

export const getAllContacts = createAsyncThunk(
  "contacts/getAllContacts",
  async (data) => {
    const response = await getAllContactsApi(data);
    return response;
  }
);

export const createContact = createAsyncThunk(
  "contacts/createContact",
  async (data) => {
    const response = await createAndEditContactApi(data);
    return response;
  }
);
export const contactAllDetails = createAsyncThunk(
  "contacts/contactAllDetails",
  async (data) => {
    const response = await getContactAllDetailsApi(data);
    return response;
  }
);

export const updateMembershipInfo = createAsyncThunk(
  "contacts/updateMembershipInfo",
  async (data) => {
    const response = await updateContactDetailsApi(data);
    return response;
  }
);
export const deleteMembershipProgram = createAsyncThunk(
  "contacts/deleteMembershipProgram",
  async (data) => {
    const response = await updateContactDetailsApi(data);
    return response;
  }
);
export const updateAdditionalInfo = createAsyncThunk(
  "contacts/updateAdditionalInfo",
  async (data) => {
    const response = await updateContactDetailsApi(data);
    return response;
  }
);

export const updateMedicalAndDietary = createAsyncThunk(
  "contacts/updateMedicalAndDietary",
  async (data) => {
    const response = await updateContactDetailsApi(data);
    return response;
  }
);

export const updatePreferenceInfo = createAsyncThunk(
  "contacts/updatePreferenceInfo",
  async (data) => {
    const response = await updateContactDetailsApi(data);
    return response;
  }
);

export const getAllActivities = createAsyncThunk(
  "contacts/getAllActivities",
  async (data) => {
    const response = await getAllActivitiesApi(data);
    return response;
  }
);

export const getSingleActivity = createAsyncThunk(
  "contacts/getSingleActivity",
  async ({ activityId, contactId, reqHeaders }) => {
    const response = await getOneActivityDetailsApi({ activityId });
    return response;
  }
);

export const updateTaskActivity = createAsyncThunk(
  "contacts/getSingleActivity",
  async (data) => {
    const response = await editTaskActivityApi(data);
    return response;
  }
);

export const getAllRequestsList = createAsyncThunk(
  "contacts/getAllRequestsList",
  async ({ contactId }) => {
    const response = await getAllRequestListApi(contactId);
    return response;
  }
);
export const updateContactRequest = createAsyncThunk(
  "contacts/updateContactRequest",
  async (data) => {
    const response = await updateRequestApi(data);
    return response;
  }
);

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    loadingContactDetailsEdit: true,
    contactListing: {
      contactListingResponse: {},
      loadingContactListing: false,
    },
    //contact creation initial state
    contactCreation: {
      loading: false,
      createContactStatus: {},
      createContactInsertId: "",
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      pressedNextButton: false,
      contactStep: 0,
    },
    //contact details
    contactDetails: {
      contactAllDetailsResponse: {},
      contactDetailsLoading: false,
    },
    //update contact details
    updateMembership: {
      updateMembershipResponse: {},
      loadingUpdateMembership: false,
    },
    deleteMembership: {
      deleteMembershipResponse: {},
      loadingDeleteMembership: false,
    },
    updateAdditional: {
      updateAdditionalResponse: {},
      loadingUpdateAdditional: false,
    },
    updateMedical: {
      updateMedicalResponse: {},
      loadingUpdateMedical: false,
    },
    updatePreference: {
      updatePreferenceResponse: {},
      loadingUpdatePreference: false,
    },
    allActivities: {
      allActivitiesResponse: {},
      loadingallActivities: false,
    },
    singleActivity: {
      singleActivityResponse: {},
      loadingSingleActivity: false,
    },
    createAndUpdateActivity: {
      isLoading: false,
    },
    taskActivityUpdated: {},
    documentFileUpdated: {},
    taskActivity: {
      addTaskActivityResponse: {},
      loadingAddTaskActivity: false,
    },
    taskDocuments: {
      addTaskDocuments: {},
      deleteTaskDocument: {},
      loadingTaskDocuments: false,
    },
    allRequests: {
      allRequestsResponse: {},
      loadingAllRequests: false,
    },
    updateRequest: {
      updateRequestResponse: {},
      loadingUpdateRequest: false,
      singleActivity: {
        singleActivityResponse: {},
        loadingSingleActivity: false,
      },
    },
    addEditTravelHistory: false,
    isFilterApplied: false,
    searchFilters: {},
    importContact: false,
  },
  reducers: {
    updateStep: (state, action) => {
      switch (action.payload) {
        case "1":
          state.contactCreation.step1 = "done";
          break;
        case "2":
          state.contactCreation.step2 = "done";
          break;
        case "3":
          state.contactCreation.step3 = "done";
          break;
        case "4":
          state.contactCreation.step4 = "done";
          break;
        default:
          state.contactCreation.step1 = "";
          state.contactCreation.step2 = "";
          state.contactCreation.step3 = "";
          state.contactCreation.step4 = "";
      }
    },
    pressNext: (state, action) => {
      action.payload === "next-button"
        ? (state.contactCreation.pressedNextButton = true)
        : (state.contactCreation.pressedNextButton = false);
    },
    emptyDocUploadResponse: (state) => {
      state.documentUpload = {};
    },
    contactDetailsEditLoader: (state, action) => {
      state.loadingContactDetailsEdit = action.payload;
    },
    contactStepSetter: (state, action) => {
      state.contactCreation.contactStep = action.payload;
    },
    taskActivityUpdater: (state, action) => {
      state.taskActivityUpdated = action.payload;
    },
    documentFileUpdater: (state, action) => {
      state.documentFileUpdated = action.payload;
    },
    handleLoadingForActivity: (state, action) => {
      state.createAndUpdateActivity.isLoading = action.payload;
    },
    isTravelHistoryAdded: (state, action) => {
      state.addEditTravelHistory = action.payload;
    },
    contactSearchFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    contactSearchFilterSetter: (state, action) => {
      state.searchFilters = action.payload;
    },
    importContactTrigger: (state, action) => {
      state.importContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    //all contacts
    builder.addCase(getAllContacts.pending, (state, action) => {
      state.contactListing.loadingContactListing = true;
    });
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.contactListing.contactListingResponse = action.payload;
      state.contactListing.loadingContactListing = false;
    });

    //contact creation
    builder.addCase(createContact.pending, (state, action) => {
      state.contactCreation.loading = true;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.contactCreation.createContactStatus = action.payload;
      state.contactCreation.loading = false;
    });
    builder.addCase(createContact.rejected, (state, action) => {
      console.log("rejected", action.payload);
      state.contactCreation.loading = false;
    });

    builder.addCase(contactAllDetails.pending, (state, action) => {
      state.contactDetails.contactDetailsLoading = true;
    });
    builder.addCase(contactAllDetails.fulfilled, (state, action) => {
      state.contactDetails.contactAllDetailsResponse = action.payload;
      state.contactDetails.contactDetailsLoading = false;
    });
    //update membership program
    builder.addCase(updateMembershipInfo.pending, (state, action) => {
      state.updateMembership.loadingUpdateMembership = true;
    });
    builder.addCase(updateMembershipInfo.fulfilled, (state, action) => {
      state.updateMembership.updateMembershipResponse = action.payload;
      state.updateMembership.loadingUpdateMembership = false;
    });
    //delete membership program
    builder.addCase(deleteMembershipProgram.pending, (state, action) => {
      state.deleteMembership.loadingDeleteMembership = true;
    });
    builder.addCase(deleteMembershipProgram.fulfilled, (state, action) => {
      state.deleteMembership.deleteMembershipResponse = action.payload;
      state.deleteMembership.loadingDeleteMembership = false;
    });
    //update additional info
    builder.addCase(updateAdditionalInfo.pending, (state, action) => {
      state.updateAdditional.loadingUpdateAdditional = true;
    });
    builder.addCase(updateAdditionalInfo.fulfilled, (state, action) => {
      state.updateAdditional.updateAdditionalResponse = action.payload;
      state.updateAdditional.loadingUpdateAdditional = false;
    });
    //update medical and dietary
    builder.addCase(updateMedicalAndDietary.pending, (state, action) => {
      state.updateMedical.loadingUpdateMedical = true;
    });
    builder.addCase(updateMedicalAndDietary.fulfilled, (state, action) => {
      state.updateMedical.updateMedicalResponse = action.payload;
      state.updateMedical.loadingUpdateMedical = false;
    });
    //update preference
    builder.addCase(updatePreferenceInfo.pending, (state, action) => {
      state.updatePreference.loadingUpdatePreference = true;
    });
    builder.addCase(updatePreferenceInfo.fulfilled, (state, action) => {
      state.updatePreference.updatePreferenceResponse = action.payload;
      state.updatePreference.loadingUpdatePreference = false;
    });
    builder.addCase(getAllActivities.pending, (state, action) => {
      state.allActivities.loadingallActivities = true;
    });
    builder.addCase(getAllActivities.fulfilled, (state, action) => {
      state.allActivities.allActivitiesResponse = action.payload;
      state.allActivities.loadingallActivities = false;
    });
    // get single contact activity
    builder.addCase(getSingleActivity.pending, (state, action) => {
      state.singleActivity.loadingSingleActivity = true;
    });
    builder.addCase(getSingleActivity.fulfilled, (state, action) => {
      state.singleActivity.singleActivityResponse = action.payload;
      state.singleActivity.loadingSingleActivity = false;
    });
    builder.addCase(getSingleActivity.rejected, (state, action) => {
      state.singleActivity.singleActivityResponse = action.payload;
      state.singleActivity.loadingSingleActivity = false;
    });
    builder.addCase(getAllRequestsList.pending, (state, action) => {
      state.allRequests.loadingAllRequests = true;
    });
    builder.addCase(getAllRequestsList.fulfilled, (state, action) => {
      state.allRequests.allRequestsResponse = action.payload;
      state.allRequests.loadingAllRequests = false;
    });
    builder.addCase(updateContactRequest.pending, (state, action) => {
      state.updateRequest.loadingUpdateRequest = true;
    });
    builder.addCase(updateContactRequest.fulfilled, (state, action) => {
      state.updateRequest.updateRequestResponse = action.payload;
      state.updateRequest.loadingUpdateRequest = false;
    });
    // update task activity in contact activity
    // builder.addCase(updateTaskActivity.pending, (state, action) => {
    //   state.taskActivity.loadingAddTaskActivity = true;
    // });
    // builder.addCase(updateTaskActivity.fulfilled, (state, action) => {
    //   state.taskActivity.addTaskActivityResponse = action.payload;
    //   state.taskActivity.loadingAddTaskActivity = false;
    // });
    // builder.addCase(updateTaskActivity.rejected, (state, action) => {
    //   state.taskActivity.addTaskActivityResponse = action.payload;
    //   state.taskActivity.loadingAddTaskActivity = false;
    // });
  },
});
export const {
  updateStep,
  pressNext,
  emptyDocUploadResponse,
  contactDetailsEditLoader,
  contactStepSetter,
  taskActivityUpdater,
  documentFileUpdater,
  handleLoadingForActivity,
  isTravelHistoryAdded,
  contactSearchFilterApplied,
  contactSearchFilterSetter,
  importContactTrigger,
} = contactsSlice.actions;
export default contactsSlice.reducer;
