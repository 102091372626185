import { Grid, Layout } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import OrganizationDetailsHeader from "../../components/Organizations/OrganizationDetails/OrganizationDetailsHeader";
import OrganizationDetailsTabs from "../../components/Organizations/OrganizationDetails/OrganizationDetailsTabs";
import OrganizationSearchBar from "../../components/Organizations/OrganizationsListing/OrganizationSearchBar";
import "./OrganizationDetailsPage.less";
const { Header, Content, Sider } = Layout;

const breadCrumbs = [
  { title: "Organizations", path: "/organization/listing" },
  { title: "Details", path: "" },
];

const OrganizationDetailsPage = () => {
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const orgId = searchParams.get("id");
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <OrganizationSearchBar
              // filter props are required
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <OrganizationDetailsHeader orgId={orgId} />
          </div>

          {<OrganizationDetailsTabs orgId={orgId} />}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default OrganizationDetailsPage;
