import {
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { eventFeeCreateOrEditApi } from "../../../../apis/eventsApi";
import { getTaxonomyList } from "../../../../others/commonApiCalls/commonApiCalls";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
} from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
const { TextArea } = Input;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddonFeeCreateOrEditForm = ({
  feeType,
  form,
  editId,
  eventId,
  addonId,
  copyId,
  setLoadingFeeCreation,
  singleFeeData,
}) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [feeTypeList, setFeeTypeList] = useState([]);
  const [loadingFeeType, setLoadingFeeType] = useState(false);
  const [feeNameList, setFeeNameList] = useState([]);
  const [loadingFeeName, setLoadingFeeName] = useState(false);
  // const [paymentMethodList, setPaymentMethodList] = useState([]);
  // const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [showAdditonalDetails, setShowAdditionalDetails] = useState(false);

  const usageType = [
    { value: "cancellation", label: "Cancellation" },
    { value: "item_change", label: "Item Change" },
    // { value: "party_add", label: "Party Add" },
    // {
    //   value: "payment_installment",
    //   label: "Payment Installment",
    // },
    // {
    //   value: "payment_installment2",
    //   label: "Payment Installment 2",
    // },
    // { value: "payment_plan", label: "Payment Plan" },
    // { value: "nsf", label: "NSF" },
    { value: "penalty", label: "Penalty" },
    // { value: "processing", label: "Processing" },
    // { value: "referral", label: "Referral" },
  ];

  useEffect(() => {
    getTaxonomyList("fee_type", setFeeTypeList, setLoadingFeeType);
    getTaxonomyList("fee_name", setFeeNameList, setLoadingFeeName);
    // getTaxonomyList(
    //   "payment_method",
    //   setPaymentMethodList,
    //   setLoadingPaymentMethod
    // );
  }, []);

  useEffect(() => {
    if ((copyId || editId) && !!Object.keys(singleFeeData)?.length) {
      form.setFieldsValue({
        field_fee_amount: singleFeeData?.field_fee_amount,
        field_fee_percent: singleFeeData?.field_fee_percent,
        field_is_optional: singleFeeData?.field_is_optional,
        field_start_date: singleFeeData?.field_start_date
          ? moment(singleFeeData?.field_start_date, "YYYY-MM-DD")
          : null,
        field_end_date: singleFeeData?.field_end_date
          ? moment(singleFeeData?.field_end_date, "YYYY-MM-DD")
          : null,
        field_description: singleFeeData?.field_description,
        field_fee_name: editId ? singleFeeData?.name?.id : null,
        field_fee_type: singleFeeData?.field_fee_type?.id,
        field_commissionable: singleFeeData?.field_commissionable,
        field_maximum_age: singleFeeData?.field_maximum_age,
        field_minimum_age: singleFeeData?.field_minimum_age,
        // field_payment_method: singleFeeData?.field_payment_method?.id,
        field_usage_type: singleFeeData?.field_usage_type?.key,
        field_organization_service: singleFeeData?.field_organization_service,
      });
      if (
        singleFeeData?.field_description ||
        singleFeeData?.field_commissionable ||
        singleFeeData?.field_maximum_age ||
        singleFeeData?.field_minimum_age ||
        // singleFeeData?.field_payment_method?.id ||
        singleFeeData?.field_organization_service
      ) {
        setShowAdditionalDetails(true);
      }
    }
  }, [singleFeeData]);

  const createOrEditFee = async (data) => {
    setLoadingFeeCreation(true);
    const response = await eventFeeCreateOrEditApi(data);
    if (response.status) {
      setLoadingFeeCreation(false);
      editId
        ? message.success("Fee Edited Successfully")
        : message.success("Fee Added Successfully");
      feeType === "event"
        ? navigate(`${EventDetailsRoute}?id=${eventId}#Fees`)
        : navigate(
            `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`
          );
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
      setLoadingFeeCreation(false);
    }
  };

  const onFinishHandler = (values) => {
    const reqMethod = editId ? "patch" : "post";
    const payload = {
      data: {
        type: "fees--fees",
        attributes: {
          status: true,
          field_usage_type: values?.field_usage_type
            ? values?.field_usage_type
            : null,
          field_fee_amount: values?.field_fee_amount || null,
          field_fee_percent: values?.field_fee_percent || null,
          field_is_optional: values?.field_is_optional || null,
          field_start_date: values?.field_start_date
            ? moment(values?.field_start_date).format("YYYY-MM-DD")
            : null,
          field_end_date: values?.field_end_date
            ? moment(values?.field_end_date).format("YYYY-MM-DD")
            : null,
          field_description: values?.field_description || null,
          field_commissionable: values?.field_commissionable || null,
          field_organization_service:
            values?.field_organization_service || null,
          field_minimum_age: values?.field_minimum_age || null,
          field_maximum_age: values?.field_maximum_age || null,
        },
        relationships: {
          field_fee_type: values?.field_fee_type
            ? {
                data: {
                  type: "taxonomy_term--fee_type",
                  id: values?.field_fee_type,
                },
              }
            : null,
          name: values?.field_fee_name
            ? {
                data: {
                  type: "taxonomy_term--fee_name",
                  id: values?.field_fee_name,
                },
              }
            : null,
          // field_payment_method: values?.field_payment_method
          //   ? {
          //       data: {
          //         type: "taxonomy_term--payment_method",
          //         id: values?.field_payment_method,
          //       },
          //     }
          //   : null,
          field_event: {
            data: {
              type: "event--event",
              id: feeType === "event" ? eventId : addonId,
            },
          },
        },
      },
    };
    if (copyId && reqMethod === "post") {
      payload.data.attributes.field_category = singleFeeData?.field_category
        ?.length
        ? singleFeeData?.field_category
        : [];
    }
    if (reqMethod === "patch") {
      payload.data.id = editId;
    }
    createOrEditFee({ reqMethod, editId, payload });
  };

  const validateEndDate = (e, value) => {
    if (e.field === "field_start_date") {
      const startDate = value;
      const endDate = form.getFieldValue("field_end_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form.setFields([
            {
              name: "field_end_date",
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form.setFields([
              {
                name: "field_end_date",
                errors: ["End Date cannot be less than the Start Date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form.getFieldValue("field_start_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End Date cannot be less than the Start Date");
        }
      }
    }
    return Promise.resolve();
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={onFinishHandler}
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 4,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
        >
          <Form.Item
            className="asterick-align"
            label="Fee Type"
            name="field_fee_type"
            rules={[
              {
                required: true,
                message: "Please select fee type",
              },
            ]}
          >
            <Select
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={feeTypeList?.map((feeTypes) => ({
                key: feeTypes?.id,
                value: feeTypes?.id,
                label: feeTypes?.name,
              }))}
              loading={loadingFeeType}
            />
          </Form.Item>
          <Form.Item
            className="asterick-align"
            label="Fee Name"
            name="field_fee_name"
            rules={[
              {
                required: true,
                message: "Please select fee name",
              },
            ]}
          >
            <Select
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={feeNameList?.map((feeName) => ({
                key: feeName?.id,
                value: feeName?.id,
                label: feeName?.name,
              }))}
              loading={loadingFeeName}
            />
          </Form.Item>
          <Form.Item
            label="Fee Amount"
            className="asterick-align"
            name="field_fee_amount"
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown" ||
                  (evt.target.value.split(".")?.[0]?.length >= 7 &&
                    evt.key != "Backspace")
                ) {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Fee Percent"
            className="asterick-align"
            name="field_fee_percent"
          >
            <Input
              type="number"
              min={0}
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (evt.key === "e" || evt.key === "-" || evt.key === "+") {
                  evt.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Usage Type"
            className="asterick-align"
            name="field_usage_type"
          >
            <Select
              allowClear
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={usageType?.map((usage) => ({
                value: usage?.value,
                label: usage?.label,
              }))}
            />
          </Form.Item>
          <Form.Item
            className="swith_form asterick-align"
            label="Is Optional"
            name="field_is_optional"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="field_start_date"
            className="asterick-align"
            rules={[
              {
                validator: validateEndDate,
                dependencies: ["field_end_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="field_end_date"
            className="asterick-align"
            rules={[
              {
                validator: validateEndDate,
                dependencies: ["field_start_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>
          <Collapse
            className="addnotes_collapse"
            activeKey={showAdditonalDetails ? ["additional details"] : [""]}
            onChange={() => {
              setShowAdditionalDetails(!showAdditonalDetails);
            }}
          >
            <Panel header="Additional Details" key="additional details">
              <Form.Item
                label="Description"
                className="asterick-align"
                name="field_description"
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="swith_form asterick-align"
                label="Commissionable"
                name="field_commissionable"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <div className="textarea_row">
                <Form.Item
                  label="Organization Service"
                  name="field_organization_service"
                >
                  <TextArea rows={4} />
                </Form.Item>
              </div>
              {/* <Form.Item
                label="Payment Method"
                className="asterick-align"
                name="field_payment_method"
              >
                <Select
                  loading={loadingPaymentMethod}
                  options={paymentMethodList?.map((paymentMethod) => ({
                    key: paymentMethod?.id,
                    value: paymentMethod?.id,
                    label: paymentMethod?.name,
                  }))}
                />
              </Form.Item> */}

              <Form.Item
                label="Minimum Age"
                className="asterick-align"
                name="field_minimum_age"
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Maximum Age"
                className="asterick-align"
                name="field_maximum_age"
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "."
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </>
  );
};

export default AddonFeeCreateOrEditForm;
