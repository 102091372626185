import React, { useState, useEffect } from "react";
import { Select, Row, Col, message, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  getAvailabilityPieDataApi,
  getProdEventsListApi,
} from "../../apis/dashboardApi";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import {decodeContent} from "../../utils/contentParser"

const { Option } = Select;
ChartJS.register(ArcElement, Tooltip, Legend);
const AvailabilityPie = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const availEvent = searchParams.get("avail_event");
  const availType = searchParams.get("avail_type");
  const [prodEventList, setProdEventList] = useState([]);
  const [prodEventLoading, setProdEventLoading] = useState(false);
  const [availabilityEvent, setAvailabilityEvent] = useState(
    availEvent || null
  );
  const [availabilityType, setAvailabilityType] = useState(
    availType || "travelers"
  );
  const [pieData, setPieData] = useState({ labels: [], datasets: [] });
  const [loadingPieData, setLoadingPieData] = useState(false);

  useEffect(() => {
    getProdEventList();
  }, []);

  useEffect(() => {
    setAvailabilityEvent(availEvent || prodEventList[0]?.uuid);
  }, [prodEventList]);

  useEffect(() => {
    if (availabilityEvent) {
      getAvailabilityPieData({
        eventUuid: availabilityEvent,
        availabilityType,
      });
    }
  }, [availabilityEvent, availabilityType]);

  const getAvailabilityPieData = async ({ eventUuid, availabilityType }) => {
    setLoadingPieData(true);
    const response = await getAvailabilityPieDataApi({
      eventUuid,
      availabilityType,
    });
    if (response.status) {
      setPieData({
        labels: response?.data?.data?.length
          ? response?.data?.data?.map((item) => item.type)
          : ["No Data"],
        datasets: [
          {
            label: "#",
            data: response?.data?.data?.length
              ? response?.data?.data?.map((item) => item.value)
              : ["1"],
            backgroundColor: response?.data?.data?.length
              ? ["rgb(139,0,0)", "rgb(0,163,158)", "rgb(255,160,122)"]
              : ["rgba(128,128,128,0.5)"],
            borderColor: response?.data?.data?.length
              ? ["rgb(139,0,0)", "rgb(0,163,158)", "rgb(255,160,122)"]
              : ["rgb(128, 128, 128)"],
            borderWidth: 1,
          },
        ],
      });
      setLoadingPieData(false);
    } else {
      setPieData([]);
      setLoadingPieData(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const getProdEventList = async () => {
    setProdEventLoading(true);
    const response = await getProdEventsListApi();
    if (response.status) {
      const updateRes = response?.data.map((val)=>{
        val.name = decodeContent(val.name)
        return val
      })
      setProdEventList(updateRes);
      setProdEventLoading(false);
    } else {
      setProdEventList([]);
      setProdEventLoading(false);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    hoverOffset: 20,
    plugins: {
      tooltip: {
        enabled:
          pieData?.datasets?.length === 0 || pieData?.labels[0] === "No Data"
            ? false
            : true,
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          label: (context, hv) => {
            if (context.label === "No Data") {
              return;
            }
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              let totalDataCount = context.dataset.data.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );
              label += `${context.parsed} (${(
                (context.parsed / totalDataCount) *
                100
              ).toFixed(2)}%)`;
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 40,
        right: 40,
      },
    },
  };
  return (
    <Spin spinning={loadingPieData} size="large">
      <Row style={{ marginBottom: "20px" }} gutter={16}>
        <Col span={16}>
          <Select
            style={{ width: "100%" }}
            value={availabilityEvent || null}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              setAvailabilityEvent(e);
              searchParams.set("avail_event", e);
              setSearchParams(searchParams);
            }}
            options={prodEventList?.map((listItem) => ({
              key: listItem.uuid,
              value: listItem.uuid,
              label: listItem.name,
            }))}
            loading={prodEventLoading}
          />
        </Col>
        <Col span={8}>
          <Select
            style={{ width: "100%" }}
            value={availabilityType}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              setAvailabilityType(e);
              searchParams.set("avail_type", e);
              setSearchParams(searchParams);
            }}
          >
            <Option value="travelers" label="Travelers">Travelers</Option>
            <Option value="room_inventory" label="Room Inventory">Room Inventory</Option>
          </Select>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2em",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70%", height: "80%" }}>
          <Doughnut data={pieData} options={options} />
        </div>
        <div style={{ maxHeight: "300px", width: "20%", overflow: "auto" }}>
          {pieData?.datasets?.[0]?.backgroundColor?.map((dataset, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  backgroundColor:
                    pieData?.datasets?.[0]?.backgroundColor?.[index],
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <span>{pieData?.labels[index]}</span>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default AvailabilityPie;
