import { Tabs, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import { getReservationsList } from "../../../redux/features/reservationFeatures/reservationSlice";
import ReservationListing from "./ReservationListing";
import { urlParser } from "../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

export const convertToUrlParams = (type) => {
  const typeMapping = {
    reservation: "Reservations",
    quotes: "Quotes",
    waitlist: "Waitlist Entry",
  };

  return typeMapping[type] || null;
};
const ReservationListingTabContainer = ({
  setTotalReservation,
  urlParamsObject,
  setUrlParamsObject,
  defaultSortOrder
}) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState("reservation");
  const dispatch = useDispatch();
  const TAB_NAMES = ["reservation", "quotes", "waitlist"];
  const [searchParams, setSearchParams] = useSearchParams();
  const [notFound, setNotFound] = useState(false);
  const [reservationData, setReservationData] = useState();
  const { reservationListResponse, loadingReservationList } = useSelector(
    (state) => state.reservation.reservationList
  );
  const reservationListRef = useRef(false);

  useEffect(() => {
    setCurrentTab(urlParamsObject?.tab || "reservation");
  }, [urlParamsObject?.tab]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setSearchParams({
        count: searchParams.get("count") || 10,
        order: searchParams.get("order") || "ASC",
        page: searchParams.get("page") || 1,
        tab: searchParams.get("tab") || "reservation",
      });
    }
  }, []);

  useEffect(() => {
    for (let prop in urlParamsObject) {
      if (!urlParamsObject[prop]) {
        delete urlParamsObject[prop];
      }
    }
    setSearchParams(urlParamsObject);
  }, [urlParamsObject]);

  useEffect(() => {
    if (currentTab && urlParamsObject?.tab) {
      for (let prop in urlParamsObject) {
        if (!urlParamsObject[prop]) {
          delete urlParamsObject[prop];
        }
      }

      const { order, count, ...rest } = urlParamsObject;
      const urlConstructor = {
        ...rest,
        items_per_page: Number(urlParamsObject?.count),
        page: Number(urlParamsObject?.page) - 1,
        sort_order: urlParamsObject?.order,
      };
      dispatch(getReservationsList(urlConstructor));
    }
  }, [urlParamsObject]);

  useEffect(() => {
    if (reservationListRef?.current) {
      if (reservationListResponse?.status) {
        setReservationData(reservationListResponse?.data || {});
        setTotalReservation(
          urlParamsObject?.order === "recently_read"
            ? Number(reservationListResponse?.data?.pager?.total_items) <= 10
              ? reservationListResponse?.data?.pager?.total_items
              : "10"
            : Object.values(
              reservationListResponse?.data?.pager?.count_res || {}
            ).reduce((acc, count) => acc + count, 0)
        );
      } else {
        setReservationData([]);
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    reservationListRef.current = true;
  }, [reservationListResponse]);

  function getTotalItems(index, item) {
    return urlParamsObject?.order === "recently_read"
      ? reservationData?.pager?.total_items ?? 0
      : reservationData?.pager?.count_res?.[item] ?? 0;
  }

  // function getTabData(index, item) {
  //   return reservationData?.rows || [];
  // }

  const tabItems = TAB_NAMES.map((item, index) => {
    return {
      key: item,
      label: (
        <>
          <span>{convertToUrlParams(item)}</span>{" "}
          {urlParamsObject?.order !== "recently_read" && (
            <span>
              {/* {loadingAllEvents ? (
                <span style={{ width: "5px" }}>
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    width="5px"
                    style={{ width: "5px" }}
                  />
                </span>
              ) : (
                ` (${getTotalItems(index, item) ?? 0})`
              )} */}
              ({getTotalItems(index, item) ?? 0})
            </span>
          )}
        </>
      ),
      children: (
        <div className="activity_details pd-16 bg-white">
          <ReservationListing
            allReservationData={reservationData?.rows || []}
            isLoading={loadingReservationList}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            // defaultSortOrder={defaultSortOrder}
            totalItems={getTotalItems(index, item)}
          />
        </div>
      ),
    };
  });

  const handleTabChange = (key) => {
    setUrlParamsObject({
      ...urlParamsObject,
      count: 10,
      order: defaultSortOrder,
      page: 1,
      tab: key,
    });
  };

  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key) => {
        handleTabChange(key);
      }}
      items={tabItems}
    />
  );
};

export default ReservationListingTabContainer;
