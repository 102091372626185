import React from "react";
import { Button } from "antd";
import "./CreateOrganizationHeader.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  pressOrganizationNext,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";

const CreateOrganizationHeader = ({
  editId,
  createId,
  form1,
  form2,
  form3,
  form4,
}) => {
  const { organizationCreation } = useSelector((state) => state.organizations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingOrganizationCreation } = organizationCreation;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get("step");

  const onSavehandler = () => {
    if (currentStep === "1" || currentStep === null) {
      dispatch(pressOrganizationNext(""));
      dispatch(updateOrganizationStep("1"));
      form1.submit();
    }
    if (currentStep === "2") {
      dispatch(pressOrganizationNext(""));
      dispatch(updateOrganizationStep("2"));
      form2.submit();
    }
    if (currentStep === "3") {
      dispatch(pressOrganizationNext(""));
      dispatch(updateOrganizationStep("3"));
      form3.submit();
    }
    if (currentStep === "4") {
      dispatch(pressOrganizationNext(""));
      dispatch(updateOrganizationStep("4"));
      form4.submit();
    }
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate("/organization/listing");
            }}
            style={{ color: "#000" }}
          />
          <div className="header_res">
            <p>{editId ? "Edit Organization" : "New Organization"}</p>
          </div>
        </h3>

        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              let route =
                createId || editId
                  ? `/organization/details?id=${createId || editId} `
                  : -1;
              navigate(route);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSavehandler();
            }}
            loading={loadingOrganizationCreation}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrganizationHeader;
