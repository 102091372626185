import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Space,
  Switch,
  Table,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getAccommodationList } from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import {
  AccomodationTypeDetailsRoute,
  AddonAccomodationTypeDetailsRoute,
  AddonCategoryDetailsRoute,
  EventCategoryDetailsRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { addCommasInPricing } from "../../../../../utils/contentParser";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
import EventAddAccommodation from "./EventAddAccommodation";
import EventAddCategory from "./EventAddCategory";
import EventCategoriesListingTotal from "./EventscategoryListingTotal";
const authData = JSON.parse(localStorage.getItem("authorization"));
const allowAddCategory = authData?.permissions?.includes("add category");

const columns = [
  {
    title: "Code",
    dataIndex: "code",
  },

  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Retail Price",
    dataIndex: "retail_price",
    render: (value) =>
      value == "" || value === undefined
        ? ""
        : `$${addCommasInPricing(value?.toString())}`,
  },
  {
    title: "Staff Price",
    dataIndex: "staff_price",
    render: (value) =>
      value == "" || value === undefined
        ? ""
        : `$${addCommasInPricing(value?.toString())}`,
  },
  {
    title: "Web Enabled	",
    dataIndex: "web_enabled",
  },

  {
    title: "Total #",
    dataIndex: "total",
  },

  {
    title: "Holds",
    dataIndex: "holds",
  },

  {
    title: "Sold",
    dataIndex: "sold",
  },

  {
    title: "Avail",
    dataIndex: "avail",
  },

  {
    title: "Avail Shares",
    dataIndex: "avail_shares",
  },

  {
    title: "Avail %",
    dataIndex: "avail_percent",
  },
];

const EventCategoriesListing = ({
  eventData,
  addonId,
  eventId,
  setAccommodationTotalBar,
  accommodationTotalBar,
}) => {
  const [AccommodationData, setAccommodationData] = useState([]);
  const [loadingAccommodation, setLoadingAccommodation] = useState(false);
  const [categoryListingCount, setCategoryListingCount] = useState(0);
  const [totalData, setTotalData] = useState();
  const { isAccommodationOrCategoryCreated } = useSelector(
    (state) => state?.events
  );
  const getListValues = (value, index) => {
    let listValues = {
      key: index,
      code: (
        <>
         {permissionChecker("view published category entities") ?  <Link
            to={
              addonId
                ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${value?.uuid}`
                : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${value?.uuid}`
            }
          >
            <a>{value?.field_category_code}</a>
          </Link> :value?.field_category_code}
        </>
      ),
      name: (
        <>
         {permissionChecker("view published category entities") ?
          <Link
            to={
              addonId
                ? `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${value?.uuid}`
                : `${EventCategoryDetailsRoute}?id=${eventId}&categoryId=${value?.uuid}`
            }
          >
            <a> {value?.name}</a>
          </Link> :  value?.name}
        </>
      ),
      retail_price: value?.field_retail_price || 0,
      staff_price: value?.field_staff_price || 0,
      web_enabled: (
        <>
          <Switch checked={value?.field_web_enabled} disabled />
        </>
      ),
      total: value?.total,
      holds: value?.holds,
      sold: value?.sold,
      avail: value?.avail,
      avail_shares: value?.avail_share,
      avail_percent: value?.avail_percent ? `${value?.avail_percent}%` : "",
    };
    return listValues;
  };
  const getAllAccommodation = async () => {
    if (eventId || addonId) {
      setLoadingAccommodation(true);
      const response = await getAccommodationList(addonId || eventId);
      if (response?.status) {
        const { total_count } = response?.data;
        setTotalData({
          code: "Total",
          name: "",
          retail_price: "",
          staff_price: "",
          web_enabled: "",
          total: total_count?.total_count || 0,
          holds: total_count?.hold_count || 0,
          sold: total_count?.sold_count || 0,
          avail: total_count?.available_count || 0,
          avail_shares: total_count?.availshare_count,
          avail_percent: total_count?.available_percentage
            ? `${total_count?.available_percentage}%`
            : "0%",
        });
        setAccommodationTotalBar(response?.data?.total_count || {});
        setCategoryListingCount(response?.data?.categories_count || 0);
        let object = [];
        if (response.data.isolated_categories.length) {
          const isolated_categories = response.data.isolated_categories;

          isolated_categories?.forEach((element, index) => {
            const list = getListValues(element, object.length);
            object.push(list);
          });
        }
        if (response.data.isolated_sub_acc.length) {
          const isolated_sub_acc = response.data.isolated_sub_acc;
          isolated_sub_acc?.forEach((element, index) => {
            let listValues = {
              key: object.length,
              name: (
                <>
                  <div className="accomodation_main_sub">
                   {permissionChecker("view published accommodation type entities") ?  <Link
                      className="black_event_class"
                      to={
                        addonId
                          ? `${AddonAccomodationTypeDetailsRoute}?id=${eventId}&addonId=${addonId}&accId=${element?.uuid}`
                          : `${AccomodationTypeDetailsRoute}?id=${eventId}&accId=${element?.uuid}&type=sub_acc`
                      }
                    >
                      <a className="anchor_weight">
                        {" "}
                        {element.name} ({element.categories.length})
                      </a>
                    </Link> : <span> {element.name} ({element.categories.length})</span>}
                  </div>
                </>
              ),
            };
            object.push(listValues);
            if (element.categories.length) {
              element.categories.forEach((category) => {
                const list = getListValues(category, object.length);
                object.push(list);
              });
            }
          });
        }
        if (response.data.accommodations.length) {
          const accommodation = response.data.accommodations;
          accommodation.forEach((element) => {
            let listValues = {
              key: object.length,
              name: (
                <>
                  <div className="accomodation_main_heading">
                  {permissionChecker("view published accommodation type entities") ? 
                    <Link
                      className="black_event_class"
                      to={
                        addonId
                          ? `${AddonAccomodationTypeDetailsRoute}?id=${eventId}&addonId=${addonId}&accId=${element?.uuid}`
                          : `${AccomodationTypeDetailsRoute}?id=${eventId}&accId=${element?.uuid}&type=acc`
                      }
                    >
                      <a className="anchor_weight_black">
                        {" "}
                        {element.name} ({element.category_count || "0"})
                      </a>
                    </Link> : <span>{element.name} ({element.category_count || "0"})</span>}
                  </div>
                </>
              ),
            };
            object.push(listValues);
            if (element.categories.length) {
              element.categories.forEach((category) => {
                const list = getListValues(category, object.length);
                object.push(list);
              });
            }
            if (element.sub_accommodations.length) {
              element.sub_accommodations.forEach((category) => {
                let listValues = {
                  key: object.length,
                  name: (
                    <>
                      <div className="accomodation_main_sub">
                        <Link
                          to={
                            addonId
                              ? `${AddonAccomodationTypeDetailsRoute}?id=${eventId}&addonId=${addonId}&accId=${element?.uuid}`
                              : `${AccomodationTypeDetailsRoute}?id=${eventId}&accId=${category?.uuid}&type=sub_acc`
                          }
                        >
                          <a className="anchor_weight">
                            {" "}
                            {category.name} ({category.category_count || "0"})
                          </a>
                        </Link>
                      </div>
                    </>
                  ),
                };
                object.push(listValues);
                if (category.categories.length) {
                  category.categories.forEach((innerValue) => {
                    const list = getListValues(innerValue, object.length);
                    object.push(list);
                  });
                }
              });
            }
          });
        }
        setAccommodationData(object);
      } else {
        setAccommodationData([]);
        message.error(response?.data?.data?.message || SOMETHING_WENT_WRONG);
      }
      setLoadingAccommodation(false);
    }
  };

  useEffect(() => {
    getAllAccommodation();
  }, [isAccommodationOrCategoryCreated, addonId, eventId]);

  const menu = (
    <Menu
      items={[
        {
          label: <EventAddAccommodation addonId={addonId} eventId={eventId} />,
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <EventAddCategory
              addonId={addonId}
              eventId={eventId}
              eventData={eventData}
            />
          ),
          key: "1",
        },
      ]}
    />
  );

  const combinedData = AccommodationData.concat(totalData);

  return (
    <div>
      <div className="requests_title">
        <h4 className="mb-0">Accommodations ({categoryListingCount})</h4>
    {
      permissionChecker("add accommodation type entities") ? 
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayClassName="add_cat"
        placement="bottomRight"
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <Button type="primary">
              <PlusOutlined /> Add New
            </Button>
          </Space>
        </a>
      </Dropdown> : null
    }
      </div>
      <Divider className="global_divider" />
      <Table
        className="table_automatic"
        columns={columns}
        dataSource={combinedData}
        size="middle"
        pagination={false}
        rowClassName={(record, index) =>
          index === combinedData.length - 1 ? "footer-row" : ""
        }
        scroll={{ y: 300 }} // Adjust the height as needed
        loading={{ spinning: loadingAccommodation, indicator: <DataLoader /> }}

        // footer={
        //   addonId
        //     ? () => (
        //       <EventCategoriesListingTotal
        //         accommodationTotalBar={accommodationTotalBar}
        //       />
        //     )
        //     : false
        // }
      />
    </div>
  );
};
export default EventCategoriesListing;

// import React from 'react';
// import { Table } from 'antd';

// const columns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     render: (text) => <a>{text}</a>,
//   },
//   {
//     title: 'Cash Assets',
//     className: 'column-money',
//     dataIndex: 'money',
//     align: 'right',
//   },
//   {
//     title: 'Address',
//     dataIndex: 'address',
//   },
// ];

// const data = [
//   {
//     key: '1',
//     name: 'John Brown',
//     money: '￥300,000.00',
//     address: 'New York No. 1 Lake Park',
//   },
//   {
//     key: '2',
//     name: 'Jim Green',
//     money: '￥1,256,000.00',
//     address: 'London No. 1 Lake Park',
//   },
//   {
//     key: '3',
//     name: 'Joe Black',
//     money: '￥120,000.00',
//     address: 'Sydney No. 1 Lake Park',
//   },
// ];

// const footerData = {
//   name: 'Footer Name',
//   money: '￥123,456.78', // Adjust this data as needed
//   address: 'Footer Address', // Adjust this data as needed
// };

// const combinedData = data.concat(footerData);

// const EventCategoriesListing = () => (
//   <Table
//     columns={columns}
//     dataSource={combinedData}
//     bordered
//     title={() => 'Header'}
//   />
// );

// export default EventCategoriesListing;
