import { Grid, Layout, message } from "antd";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import {
  AddonCategoryDetailsRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import EditDependentCategory from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/DependentCategory/EditDependentCategory/EditDependentCategory";
import EditDependentCategoryHeader from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/CategoryDetail/DependentCategory/EditDependentCategory/EditDependentCategoryHeader";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDependentCategoriesEditListApi } from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header, Content } = Layout;

const AddonDependentCategoryEdit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const addonId = searchParams.get("addonId");
  const [editableCategories, setEditableCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    {
      title: "Details",
      path: `${EventDetailsRoute}?id=${eventId}#Accommodations`,
    },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    {
      title: "Category Detail",
      path: `${AddonCategoryDetailsRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`,
    },
    { title: "Edit Dependent Categories", path: "" },
  ];
  const payloadData = [];

  useEffect(() => {
    getDependentCategoriesEditList();
  }, []);

  const getDependentCategoriesEditList = async () => {
    // setIsLoading(true);
    const response = await getDependentCategoriesEditListApi(categoryId);
    if (response.status) {
      setEditableCategories(response?.data || {});
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsLoading(false);
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EditDependentCategoryHeader
              editableCategories={editableCategories}
              setEditableCategories={setEditableCategories}
              eventId={eventId}
              categoryId={categoryId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              payloadData={payloadData}
              addonId={addonId}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <EditDependentCategory
              editableCategories={editableCategories}
              setEditableCategories={setEditableCategories}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              payloadData={payloadData}
              addonId={addonId}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonDependentCategoryEdit;
