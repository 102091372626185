import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Divider,
} from "antd";

function onChange(time, timeString) {
    console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function CruisePostform_six() {
    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);

    useEffect(() => {
        form.validateFields(["nickname"]);
    }, [checkNick, form]);

    const onCheckboxChange = (e) => {
        setCheckNick(e.target.checked);
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log("Success:", values);
        } catch (errorInfo) {
            console.log("Failed:", errorInfo);
        }
    };

    const { Panel } = Collapse;

    const onChange = (key) => {
        console.log(key);
    };
    const onFinish = (values) => {
        console.log('Received values of form:', values);
    };
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <div className="">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <h4 className="mb-0">Air Options</h4>

            </div>
            <Divider className="global_divider mb-0" />



            <div style={{ marginTop: "24px" }} />
            <Form {...layout}
                labelCol={{
                    sm: 24,
                    md: 6,
                    lg: 6,
                    xl: 5,
                    xxl: 4,
                }}
                wrapperCol={{
                    sm: 24,
                    md: 14,
                    lg: 14,
                    xl: 14,
                    xxl: 12,
                }}
                layout="horizontal" form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">


                <Form.Item
                    label="City"
                    name="City"

                >
                    <Select>
                        <Select.Option value="JFK">

                            JFK
                        </Select.Option>
                        <Select.Option value="EWR">
                            EWR
                        </Select.Option>
                        <Select.Option value="IAD">
                            IAD
                        </Select.Option>
                        <Select.Option value="IAD">
                            MIA
                        </Select.Option>
                        <Select.Option value="ATL">
                            ATL
                        </Select.Option>
                        <Select.Option value="DFW">
                            DFW
                        </Select.Option>
                        <Select.Option value="DEN">
                            DEN
                        </Select.Option>
                        <Select.Option value="IAD">
                            MIA
                        </Select.Option>
                        <Select.Option value="LAX">
                            LAX
                        </Select.Option>

                    </Select>
                </Form.Item>

                <Form.Item label="# Seats" name="# Seats">
                    <Input />
                </Form.Item>


                <Form.List name="sights">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <div key={field.key} align="baseline"
                                    style={{ borderTop: "1px solid #e8e8e8" }}
                                >
                                    <Form.Item

                                    >
                                        <div className="close_relative">
                                            <Button className="close_absolute" type="dashed" onClick={() => remove(field.name)} icon={<CloseOutlined />}>

                                            </Button>
                                        </div>

                                    </Form.Item>


                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                        }
                                    >
                                        {() => (

                                            <Form {...layout}
                                                labelCol={{
                                                    sm: 24,
                                                    md: 6,
                                                    lg: 6,
                                                    xl: 5,
                                                    xxl: 4,
                                                }}
                                                wrapperCol={{
                                                    sm: 24,
                                                    md: 14,
                                                    lg: 14,
                                                    xl: 14,
                                                    xxl: 12,
                                                }}
                                                layout="horizontal" >
                                                <Form.Item
                                                    label="City"
                                                    name="City"

                                                >
                                                    <Select>
                                                        <Select.Option value="JFK">

                                                            JFK
                                                        </Select.Option>
                                                        <Select.Option value="EWR">
                                                            EWR
                                                        </Select.Option>
                                                        <Select.Option value="IAD">
                                                            IAD
                                                        </Select.Option>
                                                        <Select.Option value="IAD">
                                                            MIA
                                                        </Select.Option>
                                                        <Select.Option value="ATL">
                                                            ATL
                                                        </Select.Option>
                                                        <Select.Option value="DFW">
                                                            DFW
                                                        </Select.Option>
                                                        <Select.Option value="DEN">
                                                            DEN
                                                        </Select.Option>
                                                        <Select.Option value="IAD">
                                                            MIA
                                                        </Select.Option>
                                                        <Select.Option value="LAX">
                                                            LAX
                                                        </Select.Option>

                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label="# Seats" name="# Seats">
                                                    <Input />
                                                </Form.Item>


                                            </Form>

                                        )}
                                    </Form.Item>
                                </div>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                    Add Air
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>


            </Form>
        </div>




    );
}

export default CruisePostform_six;
