import { Table, Button, Divider } from "antd";
import { Switch } from "antd";
import React from "react";
import DataLoader from "../../../../../others/Loaders/DataLoader";

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const Misc = ({ allDetails, allDetailsLoading }) => {
  const {
    field_meeting_space,
    field_event_partner_flight_allowance,
    field_touring_the_day_they_arrive,
    field_extended_sea_of_galilee_boat_ride,
    field_use_gai_beach_stage,
    field_southern_steps_event,
    field_southern_steps_stage,
    field_elec_eng_southern_steps,
    field_private_garden_tomb_event,
    field_private_dining,
    field_dinner_venue,
    field_chairs_for_events,
    field_portable_toilets,
    field_christian_travel_guide,
    field_price_in_visa,
    field_baptism_location,
    field_leader_performing_baptism,
    field_non_married_roommate_policy,
    field_optional_post_departure_ins_included,
  } = allDetails || {};

  const data = [
    {
      key: "1",
      label: "Meeting Space",
      input: `${field_meeting_space || ""}`,
    },
    {
      key: "2",
      label: "Event Partner Flight Allowance",
      input: `${field_event_partner_flight_allowance || ""}`,
    },
    {
      key: "3",
      label: "Touring The Day They Arrive",
      input: (
        <>
          <Switch
            checked={field_touring_the_day_they_arrive}
            // defaultChecked={field_touring_the_day_they_arrive && true}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },
    {
      key: "4",
      label: "Extended Sea of Galilee Boat Ride",
      input: (
        <>
          <Switch
            checked={field_extended_sea_of_galilee_boat_ride}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "5",
      label: "Use Gai Beach Stage",
      input: (
        <>
          <Switch
            checked={field_use_gai_beach_stage}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "6",
      label: "Southern Steps Event",
      input: (
        <>
          <Switch
            checked={field_southern_steps_event}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "7",
      label: "Southern Steps Stage",
      input: (
        <>
          <Switch
            checked={field_southern_steps_stage}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "8",
      label: "Electrical Engineer at Southern Steps",
      input: (
        <>
          <Switch
            checked={field_elec_eng_southern_steps}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "9",
      label: "Private Garden Tomb Event",
      input: (
        <>
          <Switch
            checked={field_private_garden_tomb_event}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "10",
      label: "Private Dining",
      input: (
        <>
          <Switch checked={field_private_dining} onChange={onChange} disabled />
        </>
      ),
    },

    {
      key: "11",
      label: "Dinner Venue",
      input: <>{field_dinner_venue || ""}</>,
    },

    {
      key: "12",
      label: "Chairs for Events",
      input: <>{field_chairs_for_events || ""}</>,
    },

    {
      key: "13",
      label: "Portable Toilets",
      input: (
        <>
          <span className="">{field_portable_toilets || ""}</span>
        </>
      ),
    },

    {
      key: "14",
      label: "Christian Travel Guide",
      input: (
        <>
          <Switch
            checked={field_christian_travel_guide}
            onChange={onChange}
            disabled
          />
        </>
      ),
    },

    {
      key: "15",
      label: "Price in Visa",
      input: (
        <>
          <span className="">{field_price_in_visa || ""}</span>
        </>
      ),
    },
    {
      key: "15",
      label: "Baptism Location",
      input: (
        <>
          <span className="">{field_baptism_location || ""}</span>
        </>
      ),
    },
    {
      key: "15",
      label: "Leader performing Baptism",
      input: (
        <>
          <span className="">{field_leader_performing_baptism || ""}</span>
        </>
      ),
    },
    {
      key: "15",
      label: "Non-Married Roommate Policy",
      input: (
        <>
          <span className="">
            {field_non_married_roommate_policy?.name || ""}
          </span>
        </>
      ),
    },
    {
      key: "15",
      label: "Optional Post Departure Ins Included",
      input: (
        <>
          <span className="">
            {field_optional_post_departure_ins_included === true
              ? "Yes"
              : field_optional_post_departure_ins_included === false
              ? "No"
              : ""}
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0">Miscellaneous</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        loading={{ spinning: allDetailsLoading, indicator: <DataLoader /> }}
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default Misc;
