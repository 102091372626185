import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
const NotAccessPage = ({ locationUrl }) => {
  const navigate = useNavigate();
  return (
    <Result
      style={{ display: "grid", placeContent: "center", height: "100vh" }}
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Back to Home
        </Button>
      }
    />
  );
};
export default NotAccessPage;
