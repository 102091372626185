const ENV = process.env.REACT_APP_PUBLIC_MODE || "development";
console.log("this is working serverless config")
const CONFIG = {
  development: {
    BASE_URL: "https://t6xulybi2k.execute-api.ap-south-1.amazonaws.com/dev/",
  },
  stage: {
    BASE_URL: "https://t6xulybi2k.execute-api.ap-south-1.amazonaws.com/dev/",
  },
  oldStage: {
    BASE_URL: "https://t6xulybi2k.execute-api.ap-south-1.amazonaws.com/dev/",
  },
};

export default CONFIG[ENV];
