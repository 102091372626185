import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

function ReservationEditHeader({
  form,
  editReservationLoading,
  reservationFormData,
}) {
  const { contactCreation } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = contactCreation;
  const [searchParams, setSearchParams] = useSearchParams();

  const onSavehandler = () => {
    form.submit();
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3>
          <ArrowLeftOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />{" "}
          <div className="header_res">
            <p style={{ paddingLeft: "12px" }}> Edit Reservation</p>
            <p className="header_smalltext">
              {reservationFormData?.drupal_internal__id &&
                `(${reservationFormData?.drupal_internal__id})`}
            </p>
          </div>
        </h3>
        <div className="breadcrum_buttons">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSavehandler();
            }}
            loading={editReservationLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReservationEditHeader;
