import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createOrEditReservationApi,
  getReservationDetailApi,
  getReservationInsurancePaymentApi,
  getReservationListingAPI,
} from "../../../apis/reservationApi";

export const getReservationsList = createAsyncThunk(
  "reservation/getReservationsList",
  async (query) => {
    const response = await getReservationListingAPI(query);
    return response;
  }
);

export const createOrEditReservation = createAsyncThunk(
  "reservation/createOrEditReservation",
  async (payload) => {
    const response = await createOrEditReservationApi(payload);
    return response;
  }
);

export const getReservationDetail = createAsyncThunk(
  "reservation/getReservationDetail",
  async (reservationId) => {
    const response = await getReservationDetailApi(reservationId);
    return response;
  }
);

export const getInsurancePaymentDetails = createAsyncThunk(
  "reservation/getInsurancePaymentDetails",
  async (reservationItemId) => {
    const response = await getReservationInsurancePaymentApi(reservationItemId);
    return response;
  }
);
export const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    reservationList: {
      reservationListResponse: {},
      loadingReservationList: false,
    },
    createReservation: {
      createReservationResponse: {},
      loadingCreateReservation: false,
    },
    reservationDetail: {
      reservationDetailResponse: {},
      loadingReservationDetail: false,
    },
    insurancePaymentDetails: {
      insurancePaymentDetailsResponse: {},
      loadingInsurancePaymentDetail: false,
    },
    isFilterApplied: false,
    searchFilters: {},
    isPayeeTrigger: false,
    highlightSection: {},
    isActivityTrigger: false,
    isTravellerRemoved: false,
  },
  reducers: {
    searchFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    searchFiltersSetter: (state, action) => {
      state.searchFilters = action.payload;
    },
    markPayeeTrigger: (state, action) => {
      state.isPayeeTrigger = action.payload;
    },
    setSectionHighlighter: (state, action) => {
      state.highlightSection = action.payload;
    },
    setActivityTrigger: (state, action) => {
      state.isActivityTrigger = action.payload;
    },
    triggerTravellerRemoved: (state, action) => {
      state.isTravellerRemoved = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReservationsList.pending, (state) => {
      state.reservationList.loadingReservationList = true;
    });
    builder.addCase(getReservationsList.fulfilled, (state, action) => {
      state.reservationList.reservationListResponse = action.payload;
      state.reservationList.loadingReservationList = false;
    });
    builder.addCase(getReservationsList.rejected, (state, action) => {
      state.reservationList.loadingReservationList = false;
    });

    builder.addCase(createOrEditReservation.pending, (state) => {
      state.createReservation.loadingCreateReservation = true;
    });
    builder.addCase(createOrEditReservation.fulfilled, (state, action) => {
      state.createReservation.createReservationResponse = action.payload;
      state.createReservation.loadingCreateReservation = false;
    });
    builder.addCase(createOrEditReservation.rejected, (state, action) => {
      state.createReservation.loadingCreateReservation = false;
    });

    builder.addCase(getReservationDetail.pending, (state) => {
      state.reservationDetail.loadingReservationDetail = true;
    });
    builder.addCase(getReservationDetail.fulfilled, (state, action) => {
      state.reservationDetail.reservationDetailResponse = action.payload;
      state.reservationDetail.loadingReservationDetail = false;
    });
    builder.addCase(getReservationDetail.rejected, (state, action) => {
      state.reservationDetail.loadingReservationDetail = false;
    });

    builder.addCase(getInsurancePaymentDetails.pending, (state) => {
      state.insurancePaymentDetails.loadingInsurancePaymentDetail = true;
    });
    builder.addCase(getInsurancePaymentDetails.fulfilled, (state, action) => {
      state.insurancePaymentDetails.insurancePaymentDetailsResponse =
        action.payload;
      state.insurancePaymentDetails.loadingInsurancePaymentDetail = false;
    });
    builder.addCase(getInsurancePaymentDetails.rejected, (state, action) => {
      state.insurancePaymentDetails.loadingInsurancePaymentDetail = false;
    });
  },
});
export const {
  searchFilterApplied,
  searchFiltersSetter,
  markPayeeTrigger,
  setSectionHighlighter,
  setActivityTrigger,
  triggerTravellerRemoved,
} = reservationSlice.actions;
export default reservationSlice.reducer;
