import React, { useEffect, useRef } from "react";
import { Col, message, Row } from "antd";
import "./FormSteps.less";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  contactStepSetter,
  createContact,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import MobileInput from "../../Custom/MobileInput";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const EmergencyForm = ({
  contactFormFields,
  form4,
  currentStep,
  editId,
  createId,
  primaryContactOrgId,
  orgId,
}) => {
  const createContactRef = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createContactStatus, contactStep } = useSelector(
    (state) => state.contacts.contactCreation
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("redirectBackTo");
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  useEffect(() => {
    if ((editId || createId) && Object.keys(contactFormFields).length) {
      dispatch(contactStepSetter(contactFormFields.field_step));
      form4.setFieldsValue(contactFormFields);
      form4.setFieldsValue({
        field_ecp_extension:
          contactFormFields?.field_ecp_extension ||
          contactFormFields?.field_address_field?.[0]
            ?.field_cell_phone_extension ||
          "+1",
        field_emergency_contact_phone:
          contactFormFields?.field_emergency_contact_phone || "",
        field_ecp_2_extension:
          contactFormFields?.field_ecp_2_extension ||
          contactFormFields?.field_address_field?.[0]
            ?.field_cell_phone_extension ||
          "+1",
        field_emergency_contact_phone_2:
          contactFormFields?.field_emergency_contact_phone_2 || "",
      });
    }
  }, [contactFormFields]);

  useEffect(() => {
    if (createContactRef.current) {
      if (createContactStatus?.status) {
        if (createId) {
          // let route = `/contact/details?id=${createId}`;
          let route = primaryContactOrgId
            ? `/organization?creationId=${primaryContactOrgId}&orgCotactId=${createId}&step=2`
            : !!orgId
            ? `/organization/details?id=${orgId}#Contacts`
            : `/contact/details?id=${createId}`;
          message.success("Form Submitted Successfully!");
          reservationId
            ? navigate(`/reservation/details?id=${reservationId}`)
            : navigate(route);
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            navigate(`/contact/details?id=${editId}`);
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateStep());
      }
      if (!createContactStatus?.status) {
        message.error(
          createContactStatus?.data?.message || SOMETHING_WENT_WRONG
        );
      }
    }
    createContactRef.current = true;
  }, [createContactStatus]);

  const onFinishHandler = (values) => {
    const reqMethod = "patch";
    const contactId = editId || createId;
    const reqHeaders = customRequestHeader({ token: Token });
    const payload = {
      data: {
        type: "contact--contact",
        attributes: {
          field_emergency_contact_name: values.field_emergency_contact_name,
          field_emergency_contact_relationship:
            values.field_emergency_contact_relationship,
          field_ecp_extension: !!values.field_emergency_contact_phone
            ? values.field_ecp_extension
            : null,
          field_emergency_contact_phone: values.field_emergency_contact_phone,
          field_ecp_2_extension: !!values.field_emergency_contact_phone_2
            ? values.field_ecp_2_extension
            : null,
          field_emergency_contact_phone_2:
            values.field_emergency_contact_phone_2,
          field_emergency_contact_email: values.field_emergency_contact_email,
          field_step: currentStep > contactStep ? +currentStep : contactStep,
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };

    if (editId || createId) {
      payload.data.id = editId || createId;
    }
    dispatch(createContact({ reqMethod, contactId, reqHeaders, payload }));
  };

  const formDefaultValues = {
    field_ecp_extension: "+1",
    field_ecp_2_extension: "+1",
  };
  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 10,
              }}
              wrapperCol={{
                sm: 24,
                md: 18,
                lg: 14,
              }}
              layout="horizontal"
              form={form4}
              onFinish={onFinishHandler}
              initialValues={formDefaultValues}
            >
              <Form.Item
                label="Emergency Contact Name"
                name="field_emergency_contact_name"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Emergency Contact Relationship"
                name="field_emergency_contact_relationship"
              >
                <Input />
              </Form.Item>
              <MobileInput
                label={"Emergency Contact Phone"}
                extension={"field_ecp_extension"}
                phoneName={"field_emergency_contact_phone"}
                form={form4}
              />
              <MobileInput
                label={"Emergency Contact Alternative Phone"}
                extension={"field_ecp_2_extension"}
                phoneName={"field_emergency_contact_phone_2"}
                form={form4}
              />

              <Form.Item
                className="asterick-align"
                label="Emergency Contact Email"
                name="field_emergency_contact_email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmergencyForm;
