import { Form, Grid, Layout, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getDiscountDetailsApi } from "../../../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import DiscountCreateOrEditForm from "../../../../../components/Events/EventDetailsTabs/DiscountTab/DiscountCreateOrEditForm";
import DiscountCreateOrEditHeader from "../../../../../components/Events/EventDetailsTabs/DiscountTab/DiscountCreateOrEditHeader";
import Horizontals from "../../../../../components/Header/Horizontal_menu";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header, Content, Sider } = Layout;

const AddonDiscountCreateOrEditPage = () => {
  const [form] = Form.useForm();
  const [loadingDiscountCreation, setLoadingDiscountCreation] = useState(false);
  const { useBreakpoint } = Grid;
  const [searchParams, setSearchParams] = useSearchParams();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const editId = searchParams.get("editId");
  const copyId = searchParams.get("copyId");
  const [singleDiscountData, setSingleDiscountData] = useState({});
  const [singleDiscountLoading, setSingleDiscountLoading] = useState(false);
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: `${editId ? "Edit" : "Add"} Discount`, path: "" },
  ];

  useEffect(() => {
    if (editId || copyId) {
      getDiscountDetails(editId || copyId);
    }
  }, []);

  const getDiscountDetails = async (feeId) => {
    setSingleDiscountLoading(true);
    const response = await getDiscountDetailsApi(feeId);
    if (response?.status) {
      setSingleDiscountData(response?.data?.data);
      setSingleDiscountLoading(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setSingleDiscountLoading(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <DiscountCreateOrEditHeader
              form={form}
              loadingDiscountCreation={loadingDiscountCreation}
              discountType={"addon"}
              addonId={addonId}
              eventId={eventId}
              editId={editId}
            />
          </div>
          <div className="details-tabs" style={{ margin: 0 }}>
            <div>
              <div className="basic_details pd-16 bg-white">
                <Spin
                  spinning={singleDiscountLoading}
                  size="large"
                  children={
                    <DiscountCreateOrEditForm
                      discountType={"addon"}
                      form={form}
                      addonId={addonId}
                      eventId={eventId}
                      editId={editId}
                      copyId={copyId}
                      setLoadingDiscountCreation={setLoadingDiscountCreation}
                      singleDiscountData={singleDiscountData}
                      setSingleDiscountData={setSingleDiscountData}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonDiscountCreateOrEditPage;
