import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateContactPage from "../../Pages/contact-create/CreateContactPage";
import Demo_page from "../../Pages/Demo_page/Demo_page";
import AccommodationTypeDetailsPage from "../../Pages/event-details/accommodations/accommodation-type-details/AccommodationTypeDetailsPage";
import Eventsdescription_page from "../../Pages/event-details/Eventsdescription_page";
import Login from "../../Pages/login/Login";
import CreateOrganizationPage from "../../Pages/organization-create/CreateOrganizationPage";
import OrganizationDetailsPage from "../../Pages/organization-details/OrganizationDetailsPage";
import StyleGuide from "../../Pages/StyleGuide/StyleGuide";
// import Intakes_forms from "../../Pages/Intakes_form/Intakes_forms";
import Event_assigndining from "../../Pages/event-assign-dining/EventAssignDining";
import Event_assignmotorcoach from "../../Pages/Event_assignmotorcoach/Event_assignmotorcoach";
import Report_elitetravelerdetails from "../../Pages/Report_elitetravelerdetails/Report_elitetravelerdetails";
import Report_elitetravelers from "../../Pages/Report_elitetravelers/Report_elitetravelers";
import Res_editguestdetails from "../../Pages/Res_editguestdetails/Res_editguestdetails";
import Reservation_activityemailview from "../../Pages/Reservation_activityemailview /Reservation_activityemailview";
import Reservation_guestviewdetail from "../../Pages/Reservation_guestviewdetail/Reservation_guestviewdetail";
// import Rolespermission_page from "../../Pages/admin/ManageRolesPage";
import CustomDropdownEditPage from "../../Pages/admin/custom-dropdown/CustomDropdownEditPage";
import CustomDropdownPage from "../../Pages/admin/custom-dropdown/CustomDropdownPage";
import ManagePermissionsPage from "../../Pages/admin/manage-permissions/ManagePermissionsPage";
import CreateOrEditRolePage from "../../Pages/admin/manage-roles/CreateOrEditRolePage";
import ManageRoleDetailsPage from "../../Pages/admin/manage-roles/ManageRoleDetailsPage";
import ManageRolesPage from "../../Pages/admin/manage-roles/ManageRolesPage";
import ManageUsersPage from "../../Pages/admin/manage-users/ManageUsersPage";
import ChangePasswordPage from "../../Pages/change-password/ChangePasswordPage";
import ActivityDetailPage from "../../Pages/contact-details/activity-details/ActivityDetailsPage";
import ActivityEditPage from "../../Pages/contact-details/activity-details/ActivityEditPage";
import ContactDetailsPage from "../../Pages/contact-details/ContactDetailsPage";
import ContactsListingPage from "../../Pages/contacts-listing/ContactsListingPage";
import Custom_adddropdown from "../../Pages/Custom_adddropdown/Custom_adddropdown";
import EventCategoryPricing from "../../Pages/event-details/accommodations/category-details/add-pricing/EventCategoryPricing";
import CategoryDetailsPage from "../../Pages/event-details/accommodations/category-details/CategoryDetailsPage";
import EventCategoryDependent from "../../Pages/event-details/accommodations/category-details/dependent/EventCategoryDependent";
import EventCategoryDiscount from "../../Pages/event-details/accommodations/category-details/discount/EventCategoryDiscount";
import EventCategoryFees from "../../Pages/event-details/accommodations/category-details/fees/EventCategoryFees";
import DiscountDetailsPage from "../../Pages/event-details/discounts/DiscountDetailsPage";
import EventCreateOrEditDiscountPage from "../../Pages/event-details/discounts/EventCreateOrEditDiscountPage";
import EventDetailsPage from "../../Pages/event-details/EventDetailsPage";
import CreateOrEditFees from "../../Pages/event-details/fees/CreateOrEditFeesPage";
import FeeDetailsPage from "../../Pages/event-details/fees/FeeDetailsPage";
import CreateOrEditEventsPage from "../../Pages/events-create-edit/CreateOrEditEventsPage";
import EventsListingPage from "../../Pages/events-listing/EventsListingPage";
import ForgotPassword from "../../Pages/forgot-password/ForgotPassword";
import CreateOrEditCruisePage from "../../Pages/group-details-create-edit/CreateOrEditCruisePage";
import CreateOrEditTourPage from "../../Pages/group-details-create-edit/CreateOrEditTourPage";
import GroupDetailsListingPage from "../../Pages/group-details-listing/GroupDetailsListingPage";
import NotFoundPage from "../../Pages/not-found/NotFoundPage";
import OrganizationsListingPage from "../../Pages/organizations-listing/OrganizationsListingPage";
import PasswordUpdated from "../../Pages/password-updated/PasswordUpdated";
import Reservationadditemsview_page from "../../Pages/reservation-details/Reservationadditemsview_page";
import Reservationtableedit_form from "../../Pages/reservation-details/Reservationtableview_page/Reservationtableedit_form";
import Reservationtableview_page from "../../Pages/reservation-details/Reservationtableview_page/Reservationtableview_page";
import Reservation_editform from "../../Pages/Reservation_editform/Reservation_editform";
import SetPassword from "../../Pages/set-password/SetPassword";
import {
  AccomodationTypeDetailsRoute,
  AddonAccomodationTypeDetailsRoute,
  AddonCategoryAvailabilityRoute,
  AddonCategoryDetailsDependentRoute,
  AddonCategoryDetailsInventoryDetailRoute,
  AddonCategoryDetailsInventoryRoute,
  AddonCategoryDetailsPricingRoute,
  AddonCategoryDetailsRoute,
  AddonDetailsFlightRoute,
  AddonDetailsItineraryRoute,
  AddonDiscountDetailsCategoryRoute,
  AddonDiscountDetailsRoute,
  AddonDiscountRoute,
  AddonFeeDetailsCategoryRoute,
  AddonFeeDetailsRoute,
  AddonFeeRoute,
  AddonInventoryReservationsListRoute,
  AddonReservationsListingRoute,
  AddonTravelersListingRoute,
  AdminEmailMonitorRoute,
  AdminEmailTemplateEditRoute,
  AdminEmailTemplatesRoute,
  CancelReservationItemRoute,
  CancelReservationRoute,
  CategoryAvailabilityRoute,
  ConfirmPaymentStatusRoute,
  DuplicateContactRoute,
  EventCategoryDetailsDependentRoute,
  EventCategoryDetailsDiscountRoute,
  EventCategoryDetailsFeesRoute,
  EventCategoryDetailsInventoryDetailRoute,
  EventCategoryDetailsInventoryRoute,
  EventCategoryDetailsPricingRoute,
  EventCategoryDetailsRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsAddonsRoute,
  EventDetailsItineraryRoute,
  EventDetailsRoute,
  EventDiscountDetailsCategoriesRoute,
  EventDiscountDetailsRoute,
  EventDiscountRoute,
  EventFeeDetailsCategoriesRoute,
  EventFeeDetailsRoute,
  EventFeesRoute,
  EventInventoryReservationsListRoute,
  EventReservationsRoute,
  EventRoute,
  EventsListingRoute,
  GroupDetails_DetailsRoute,
  GroupDetailsCruise,
  GroupDetailsListingRoute,
  GroupDetailsTour,
  ReplaceItemRoute,
  ReportListingRoute,
  ReservationDetailRoute,
  ReservationEditRoute,
  ReservationItemDetailRoute,
  ReservationListingRoute,
  ReservationPaymentDetailRoute,
  ReservationPaymentRoute,
  ReservationPlannedPaymentDetailRoute,
  ReservationPlannedPaymentRoute,
  ReservationSchedulePaymentRoute,
  ReservationSendEmailRoute,
  ViewReportRoute,
  InsurancePayment
} from "../../url-routes/urlRoutes";
import ActivitiesEmail from "../Contacts/ContactDetailsTabs/ActivitiesTab/AddActivities/ActivitiesEmail";
import OtherActivities from "../Contacts/ContactDetailsTabs/ActivitiesTab/AddActivities/OtherActivities";
import EditpaymentPage from "../EditPayment/EditpaymentPage";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import CreateReports from "../View_components/Create_Reports/CreateReports";
// import CruiseDetailsPage from "../../Pages/group-detail-details/CruiseDetailsPage";
// import TourDetailsPage from "../../Pages/group-detail-details/TourDetailsPage";
import EditEmailTemplatePage from "../../Pages/admin/email-templates/EditEmailTemplatePage";
import EmailTemplatesPage from "../../Pages/admin/email-templates/EmailTemplatesPage";
import MarketingSourcesPage from "../../Pages/admin/marketing-sources/MarketingSourcesPage";
import DuplicateContactPage from "../../Pages/contact-details/duplicate-contact/DuplicateContactPage";
import Dashboard from "../../Pages/dashboard/Dashboard";
import SoldCountAvailabilityPage from "../../Pages/event-details/accommodations/category-details/availability/SoldCountAvailabilityPage";
import EventCategoryInventory from "../../Pages/event-details/accommodations/category-details/Inventory-pages/EventCategoryInventory";
import InventoryDetailPage from "../../Pages/event-details/accommodations/category-details/Inventory-pages/InventoryDetailPage";
import InventoryReservationsListPage from "../../Pages/event-details/accommodations/category-details/inventory-reservation/InventoryReservationsListPage";
import CreateOrEditAddonsPage from "../../Pages/event-details/addons/addons-create-edit/CreateOrEditAddonsPage";
import AddonAccommodationTypeDetailsPage from "../../Pages/event-details/addons/addons-details/addon-accommodation/AddonAccommodationTypeDetailsPage";
import AddonCategoryDetailsPage from "../../Pages/event-details/addons/addons-details/addon-accommodation/AddonCategoryDetailsPage";
import AddonCategoryPricing from "../../Pages/event-details/addons/addons-details/addon-accommodation/AddonCategoryPricing";
import AddonEditInventoryPage from "../../Pages/event-details/addons/addons-details/addon-accommodation/AddonEditInventoryPage";
import AddonInventoryDetailPage from "../../Pages/event-details/addons/addons-details/addon-accommodation/AddonInventoryDetailPage";
import AddonDependentCategoryEdit from "../../Pages/event-details/addons/addons-details/addon-dependent-category/AddonDependentCategoryEdit";
import AddonDiscountCategoriesEditPage from "../../Pages/event-details/addons/addons-details/addon-discount/AddonDiscountCategoriesEditPage";
import AddonDiscountCreateOrEditPage from "../../Pages/event-details/addons/addons-details/addon-discount/AddonDiscountCreateOrEditPage";
import AddonDiscountDetailsPage from "../../Pages/event-details/addons/addons-details/addon-discount/AddonDiscountDetailsPage";
import AddonFeeCategoriesEditPage from "../../Pages/event-details/addons/addons-details/addon-fee/AddonFeeCategoriesEditPage";
import AddonFeeCreateOrEditPage from "../../Pages/event-details/addons/addons-details/addon-fee/AddonFeeCreateOrEditPage";
import AddonFeeDetailsPage from "../../Pages/event-details/addons/addons-details/addon-fee/AddonFeeDetailsPage";
import AddonFlightCreateOrEditPage from "../../Pages/event-details/addons/addons-details/addon-flight/AddonFlightCreateOrEditPage";
import AddonAddOrEditItineraryPage from "../../Pages/event-details/addons/addons-details/addon-itinerary/AddonAddOrEditItineraryPage";
import AddonDetailsPage from "../../Pages/event-details/addons/addons-details/AddonDetailsPage";
import DiscountCategoriesEditPage from "../../Pages/event-details/discounts/DiscountCategoriesEditPage";
import EventReservationsPage from "../../Pages/event-details/event-reservations/EventReservationsPage";
import EventReservationsTravelerListingPage from "../../Pages/event-details/event-reservations/EventReservationsTravelerListingPage";
import FeeCategoriesEditPage from "../../Pages/event-details/fees/FeeCategoriesEditPage";
import AddOrEditItineraryPage from "../../Pages/event-details/itinerary/AddOrEditItineraryPage";
import CruiseOrTourDetailPage from "../../Pages/group-detail-details/CruiseOrTourDetailPage";
import PaymentConfirmStatusPage from "../../Pages/payment-confirm/PaymentConfirmStatusPage";
import ReportListingPage from "../../Pages/reports/ReportListingPage";
import ViewReportPage from "../../Pages/reports/ViewReportPage";
import AddEditSchedulePaymentPage from "../../Pages/reservation-details/reservation-detail/AddEditSchedulePaymentPage";
import AddPlannedPaymentPage from "../../Pages/reservation-details/reservation-detail/AddPlannedPaymentPage";
import CancelReservationPage from "../../Pages/reservation-details/reservation-detail/CancelReservationPage";
import PaymentDetailPage from "../../Pages/reservation-details/reservation-detail/PaymentDetailPage";
import PlannedPaymentDetailPage from "../../Pages/reservation-details/reservation-detail/PlannedPaymentDetailPage.jsx";
import CancelReservationItemPage from "../../Pages/reservation-details/reservation-detail/reservation-item/CancelReservationItemPage.jsx";
import ReservationDetailPage from "../../Pages/reservation-details/reservation-detail/ReservationDetailPage";
import Reservationsemailconfirmation_page from "../../Pages/reservation-details/reservation-detail/Reservationsemailconfirmation_page";
import RevisePage from "../../Pages/reservation-details/Reservationtableview_page/RevisePage";
import ReservationEditPage from "../../Pages/reservation-edit/ReservationEditPage";
import ReservationListingPage from "../../Pages/reservation-listing/ReservationListingPage";
import PayViaStripePage from "../Reservation/ReservationDetails/ReservationDetailSections/ReservationPayment/PayViaStripe/PayViaStripePage";
import EditPaymentFormPage from "../Reservation/ReservationDetails/ReservationDetailSections/ViewPaymentPageSections/EditPaymentFormPage";
import ViewPaymentPageSection from "../Reservation/ReservationDetails/ReservationDetailSections/ViewPaymentPageSections/ViewPaymentPageSection";
import SendTravellerEmailPage from "../../Pages/reservation-details/reservation-detail/SendTravellerEmailPage";
import EmailMonitorPage from "../../Pages/admin/email-monitor/EmailMonitorPage.jsx";
import CascaderComp from "../Events/EventDetailsTabs/InsurancePay/InsurancePayPage.jsx";
import NotAccessPage from "../../Pages/not-access/NotAccessPage.jsx";
import GroupListingsPage from "../Reservation/ReservationDetails/ReservationGroupListings/GroupListingsPage.jsx";

const ContentContaner = () => {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/password-updated" element={<PasswordUpdated />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/pageNotFound" element={<NotFoundPage />} />
        <Route path="/page-not-access" element={<NotAccessPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          {/* contact page */}
          <Route path="/contact" element={<CreateContactPage />} />
          <Route path="/contact/listing" element={<ContactsListingPage />} />
          <Route path="/contact/details" element={<ContactDetailsPage />} />
          {/*Add activities */}
          <Route
            path="/contact/details/activities/:activity"
            element={<OtherActivities />}
          />
          <Route
            path="/contact/details/activities/email"
            element={<ActivitiesEmail />}
          />
          <Route
            path="/contact/details/activities"
            element={<ActivityDetailPage />}
          />
          <Route
            path="/contact/details/activities/edit"
            element={<ActivityEditPage />}
          />
          {/* change password */}
          <Route path="/change-password" element={<ChangePasswordPage />} />
          {/* manage roles */}
          <Route path="/manage-roles" element={<ManageRolesPage />} />
          <Route
            path="/manage-roles/create"
            element={<CreateOrEditRolePage />}
          />
          <Route path="/manage-roles/edit" element={<CreateOrEditRolePage />} />
          <Route
            path="/manage-roles/details"
            element={<ManageRoleDetailsPage />}
          />
          {/* organization page */}
          <Route
            path="organization/listing"
            element={<OrganizationsListingPage />}
          />
          <Route path="/organization" element={<CreateOrganizationPage />} />
          <Route
            path="/organization/details"
            element={<OrganizationDetailsPage />}
          />

          {/* custom dropdown */}
          <Route path="/custom-dropdown" element={<CustomDropdownPage />} />
          <Route
            path="/custom-dropdown/edit"
            element={<CustomDropdownEditPage />}
          />

          {/* manage permission */}
          <Route
            path="/manage-permissions"
            element={<ManagePermissionsPage />}
          />

          {/* manage users */}
          <Route path="/manage-users" element={<ManageUsersPage />} />

          {/* event */}
          <Route path={EventsListingRoute} element={<EventsListingPage />} />
          <Route path={EventRoute} element={<CreateOrEditEventsPage />} />
          <Route path={EventDetailsRoute} element={<EventDetailsPage />} />
          <Route
            path={EventCategoryDetailsRoute}
            element={<CategoryDetailsPage />}
          />
          <Route
            path={EventCategoryDetailsPricingRoute}
            element={<EventCategoryPricing />}
          />
          <Route
            path={EventCategoryDetailsInventoryRoute}
            element={<EventCategoryInventory />}
          />
          <Route
            path={EventInventoryReservationsListRoute}
            element={<InventoryReservationsListPage />}
          />
          <Route
            path={AddonInventoryReservationsListRoute}
            element={<InventoryReservationsListPage />}
          />
          <Route
            path={EventCategoryDetailsDiscountRoute}
            element={<EventCategoryDiscount />}
          />
          <Route
            path={EventCategoryDetailsFeesRoute}
            element={<EventCategoryFees />}
          />
          <Route
            path={EventCategoryDetailsDependentRoute}
            element={<EventCategoryDependent />}
          />
          <Route
            path={AccomodationTypeDetailsRoute}
            element={<AccommodationTypeDetailsPage />}
          />
          <Route path={EventFeesRoute} element={<CreateOrEditFees />} />
          <Route path={EventFeeDetailsRoute} element={<FeeDetailsPage />} />
          <Route
            path={EventFeeDetailsCategoriesRoute}
            element={<FeeCategoriesEditPage />}
          />
          <Route
            path={EventDiscountRoute}
            element={<EventCreateOrEditDiscountPage />}
          />
          <Route
            path={EventDiscountDetailsRoute}
            element={<DiscountDetailsPage />}
          />
          <Route
            path={EventDiscountDetailsCategoriesRoute}
            element={<DiscountCategoriesEditPage />}
          />
          <Route
            path={EventDetailsItineraryRoute}
            element={<AddOrEditItineraryPage />}
          />
          <Route
            path={EventDetailsAddonsRoute}
            element={<CreateOrEditAddonsPage />}
          />
         <Route
            path={EventDetailsAddonsDetailsRoute}
            element={<AddonDetailsPage />}
          />
          {/* addon fees routes */}
          <Route path={AddonFeeRoute} element={<AddonFeeCreateOrEditPage />} />
          <Route
            path={AddonFeeDetailsRoute}
            element={<AddonFeeDetailsPage />}
          />
          <Route
            path={AddonFeeDetailsCategoryRoute}
            element={<AddonFeeCategoriesEditPage />}
          />
          {/* addon discounts routes */}
          <Route
            path={AddonDiscountRoute}
            element={<AddonDiscountCreateOrEditPage />}
          />
          <Route
            path={AddonDiscountDetailsRoute}
            element={<AddonDiscountDetailsPage />}
          />
          <Route
            path={AddonDiscountDetailsCategoryRoute}
            element={<AddonDiscountCategoriesEditPage />}
          />
          {/* addon itinerary routes */}
          <Route
            path={AddonDetailsItineraryRoute}
            element={<AddonAddOrEditItineraryPage />}
          />
          {/* addon category routes */}
          <Route
            path={AddonAccomodationTypeDetailsRoute}
            element={<AddonAccommodationTypeDetailsPage />}
          />
          <Route
            path={AddonCategoryDetailsRoute}
            element={<AddonCategoryDetailsPage />}
          />
          <Route
            path={AddonCategoryDetailsPricingRoute}
            element={<AddonCategoryPricing />}
          />
          <Route
            path={AddonCategoryDetailsInventoryRoute}
            element={<AddonEditInventoryPage />}
          />

          <Route
            path={AddonCategoryDetailsInventoryDetailRoute}
            element={<AddonInventoryDetailPage />}
          />
          <Route
            path={AddonCategoryDetailsDependentRoute}
            element={<AddonDependentCategoryEdit />}
          />

          {/* Group Details */}
          <Route
            path={GroupDetailsListingRoute}
            element={<GroupDetailsListingPage />}
          />
          <Route
            path={GroupDetailsCruise}
            element={<CreateOrEditCruisePage />}
          />
          <Route path="/group-details/:type" element={<CreateOrEditTourPage />} />
          <Route
            path={GroupDetails_DetailsRoute}
            element={<CruiseOrTourDetailPage />}
          />

          <Route path="/demo_page" element={<Demo_page />} />
          <Route path="StyleGuide" element={<StyleGuide />} />
          {/* <Route path="Event_addinventory" element={<Event_addinventory />} /> */}
          {/* <Route path="Image_form" element={<Image_form />} /> */}
          {/* <Route path="Description_page" element={<Description_page />} /> */}
          {/* <Route path="Images_page" element={<Images_page />} /> */}

          <Route
            path={EventCategoryDetailsInventoryDetailRoute}
            element={<InventoryDetailPage />}
          />
          <Route
            path="/event/details/reservation-details"
            element={<EventReservationsTravelerListingPage type={"event"} />}
          />

          <Route
            path={AddonTravelersListingRoute}
            element={<EventReservationsTravelerListingPage type={"addon"} />}
          />

          <Route
            path={EventReservationsRoute}
            element={<EventReservationsPage type={"event"} />}
          />
          <Route
            path={AddonReservationsListingRoute}
            element={<EventReservationsPage type={"addon"} />}
          />
          <Route
            path={AddonDetailsFlightRoute}
            element={<AddonFlightCreateOrEditPage />}
          />
          <Route
            path="Eventsdescription_page"
            element={<Eventsdescription_page />}
          />
          <Route
            path={ReservationListingRoute}
            element={<ReservationListingPage />}
          />
          <Route
            path={ReservationEditRoute}
            element={<ReservationEditPage />}
          />
          <Route
            path="Reservation_activityemailview"
            element={<Reservation_activityemailview />}
          />
          <Route
            path={ReservationDetailRoute}
            element={<ReservationDetailPage />}
          />
          <Route
            path="Eventsdescription_page"
            element={<Eventsdescription_page />}
          />
          <Route
            path="/event/details/assign-dining"
            element={<Event_assigndining />}
          />
          <Route
            path="Reservationsemailconfirmation_page"
            element={<Reservationsemailconfirmation_page />}
          />
          <Route
            path="reservation/details/traveler"
            element={<Res_editguestdetails />}
          />
          <Route
            path="Reservation_guestviewdetail"
            element={<Reservation_guestviewdetail />}
          />
          <Route path="elite-travelers" element={<Report_elitetravelers />} />
          <Route
            path="Report_elitetravelerdetails"
            element={<Report_elitetravelerdetails />}
          />
          <Route
            path="Event_assignmotorcoach"
            element={<Event_assignmotorcoach />}
          />
          <Route
            path={ReservationItemDetailRoute}
            element={<Reservationtableview_page />}
          />
          <Route
            path="edit-reservation-item"
            element={<Reservationtableedit_form />}
          />
          <Route
            path="Reservation_editform"
            element={<Reservation_editform />}
          />
          <Route path="add_item" element={<Reservationadditemsview_page />} />

          <Route path="create-report" element={<CreateReports />} />
          <Route
            path={ReservationSchedulePaymentRoute}
            element={<AddEditSchedulePaymentPage />}
          />
          <Route path={ReportListingRoute} element={<ReportListingPage />} />
          <Route
            path={ReservationPlannedPaymentRoute}
            element={<AddPlannedPaymentPage />}
          />
          <Route
            path={ReservationPlannedPaymentDetailRoute}
            element={<PlannedPaymentDetailPage />}
          />
          <Route
            path={ReservationPaymentDetailRoute}
            element={<PaymentDetailPage />}
          />
          <Route
            path={ConfirmPaymentStatusRoute}
            element={<PaymentConfirmStatusPage />}
          />
          <Route path="/custom-dropdown/add" element={<Custom_adddropdown />} />
          <Route
            path={DuplicateContactRoute}
            element={<DuplicateContactPage />}
          />
          <Route
            path={ReservationPaymentRoute}
            element={<PayViaStripePage />}
          />
          <Route path="payment-status" element={<PaymentStatus />} />
          <Route
            path={CategoryAvailabilityRoute}
            element={<SoldCountAvailabilityPage />}
          />
          <Route
            path={AddonCategoryAvailabilityRoute}
            element={<SoldCountAvailabilityPage />}
          />

          <Route
            path={CancelReservationRoute}
            element={<CancelReservationPage />}
          />
          <Route path="edit-payment" element={<EditpaymentPage />} />
          <Route path="marketing-sources" element={<MarketingSourcesPage />} />
          <Route path={ReplaceItemRoute} element={<RevisePage />} />
          <Route
            path={CancelReservationItemRoute}
            element={<CancelReservationItemPage />}
          />
          <Route
            path="view-payment-section"
            element={<ViewPaymentPageSection />}
          />
          <Route
            path="edit-payment-form-page"
            element={<EditPaymentFormPage />}
          />
          <Route
            path={AdminEmailTemplatesRoute}
            element={<EmailTemplatesPage />}
          />
          <Route
            path={AdminEmailTemplateEditRoute}
            element={<EditEmailTemplatePage />}
          />
          <Route path={ViewReportRoute} element={<ViewReportPage />} />
          <Route
            path={ReservationSendEmailRoute}
            element={<SendTravellerEmailPage />}
          />
          <Route path={AdminEmailMonitorRoute} element={<EmailMonitorPage />} />
          <Route path="/reservation/details/group-with/details" element={<GroupListingsPage />} />             
          <Route path={InsurancePayment} element={<CascaderComp />} />             
        </Route>
      </Routes>
    </div>
  );
};
export default ContentContaner;
