import { Button, Form, Input, Select, DatePicker } from "antd";
import React from "react";
import PayInFullImmediatlyForm from "./SchedulePaymentForms/PayInFullIForm";
import PayInFullForm from "./SchedulePaymentForms/PayInFullIForm";
import PlanPaymentForm from "./SchedulePaymentForms/PlanPaymentForm";
import SecondDepositFinalPaymentForm from "./SchedulePaymentForms/SecondDepositFinalPaymentForm";
import DepositFinalPaymentForm from "./SchedulePaymentForms/DepositFinalPayment";

function SchedulePaymentForm({ term, termFormData, form, onFinishHandler }) {
  if (term === "pay_in_full_immediately") {
    return (
      <PayInFullForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "pay_in_full_in_60_days") {
    return (
      <PayInFullForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "payment_plan") {
    return (
      <PlanPaymentForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "monthly_payment_plan") {
    return (
      <PlanPaymentForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "deposit_2nd_deposit_and_final_payment") {
    return (
      <SecondDepositFinalPaymentForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "deposit_and_final_payment") {
    return (
      <DepositFinalPaymentForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
  if (term === "quartely_payment_plan") {
    return (
      <PlanPaymentForm
        term={term}
        termFormData={termFormData}
        form={form}
        onFinishHandler={onFinishHandler}
      />
    );
  }
}

export default SchedulePaymentForm;
