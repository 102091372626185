import React, { useState } from "react";
import { Modal, Button, Row } from "antd";
const ReviseConfirmationCommon = (props) => {
  const {
    setIsModalOpen,
    isModalOpen,
    modalData,
    setModalResponse,
    loading,
    setLoading,
  } = props;

  const handleOk = () => {
    setLoading(true);
    setModalResponse("confirm");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setModalResponse("cancel");
  };
  return (
    <>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" loading={loading} onClick={handleOk}>
            Confirm
          </Button>,
          <Button onClick={handleCancel}>Cancel</Button>,
        ]}
      >
        <div className="revise-btn_modal">{modalData} </div>
      </Modal>
    </>
  );
};
export default ReviseConfirmationCommon;
