import { Table, Select } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import FindDuplicate_searchbox from '../../Header/FindDuplicate_searchbox';
import React, { useState } from 'react';

const Option = Select.Option;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const columns = [
    {
        title: 'Reservation',
        dataIndex: 'rez',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Primary Traveler',
        dataIndex: 'primary_guest',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Total	',
        dataIndex: 'total',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },

    {
        title: 'Balance',
        dataIndex: 'balance',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },

    {
        title: 'Created',
        dataIndex: 'created',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },

    {
        title: 'Modified',
        dataIndex: 'modified',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },


];
const data = [
    {
        key: '1',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },



    {
        key: '2',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087342</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>	Stand with Israel 2023 Israel Tour with Dennis Prager & Mike Gallagher - R1087342</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Marie Hebron-Jay</a>
        </>,
        total: '$14,496.00',
        balance: '$13,496.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' >  an hour ago</span>
        </>,
        modified: <>
            <span className='span_text'> an hour ago</span>
        </>,
    },



    {
        key: '3',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087249</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>The Isaacs, Tim Menzies, Jimmy Yeary & the Isaacs Foundation 2023 Holy Land Tour - R1087249</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Marlisa Allman</a>
        </>,
        total: '$5,713.00',
        balance: '$5,213.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 12 hours ago</span>
        </>,
        modified: <>
            <span className='span_text'>12 seconds ago</span>
        </>,
    },


    {
        key: '4',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087239</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087239</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Laura DiTurno</a>
        </>,
        total: '$12,696.00',
        balance: '$11,296.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 13 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>13 seconds ago</span>
        </>,
    },


    {
        key: '5',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087116</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>	Reston Bible 2023 Israel Tour - R1087116</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 15 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>15 seconds ago</span>
        </>,
    },


    {
        key: '6',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1086677</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>Experience Israel 2023 with Tony Evans - R1086677</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 7 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>7 seconds ago</span>
        </>,
    },



    {
        key: '7',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },



    {
        key: '8',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },



    {
        key: '9',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },



    {
        key: '10',
        rez: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>1086623</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1086623</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>21 seconds ago</span>
        </>,
    },

    {
        key: '11',
        rez: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },


    {
        key: '12',
        rez: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },


    {
        key: '13',
        rez: <>
            <Link to="/Reservationsview_page#">
                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>

            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },


    {
        key: '14',
        rez: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },


    {
        key: '15',
        rez: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>1087368</a>
            </Link>
        </>,
        name: <>
            <Link to="/Reservationsview_page#">

                <a href='#'>Salem Media's Deeper Faith 2023 Mediterranean Cruise - R1087368</a>
            </Link>
        </>,
        status: 'Active',
        primary_guest: <>
            <a href='#'>Deborah Shaw</a>
        </>,
        total: '$11,056.00',
        balance: '$9,656.00',
        created: <>
            <span>ContactWeb</span><span className='span_text' > 21 minutes ago</span>
        </>,
        modified: <>
            <span className='span_text'>44 seconds ago</span>
        </>,
    },

];
const FindDuplicateTable = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    return <>
        <div className='duplicate_searchbox'>
            <FindDuplicate_searchbox />
        </div>

        {/* <Table
            className="table_listing table_tour intake_width"
            columns={columns}
            // dataSource={data}
        ></Table> */}

    </>
}

export default FindDuplicateTable;

