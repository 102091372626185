import {
  Table,
  Divider,
  Checkbox,
  Switch,
  Button,
  message,
  Modal,
  Skeleton,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  AddonDiscountDetailsRoute,
  AddonDiscountRoute,
  EventDiscountDetailsRoute,
  EventDiscountRoute,
  EventFeeDetailsRoute,
} from "../../../../url-routes/urlRoutes";
import { useDispatch } from "react-redux";
import {
  deleteEventDiscountApi,
  eventDiscountListingApi,
} from "../../../../apis/eventsApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import {
  addCommasInPricing,
  decodeContent,
} from "../../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const DiscountsListing = ({ addonId, eventId, discountType }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [isDiscountDeleted, setIsDiscountDeleted] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Type",
      dataIndex: "type",
    },

    {
      title: "Code",
      dataIndex: "code",
    },

    {
      title: "Amount",
      dataIndex: "amount",
    },

    {
      title: "Percent",
      dataIndex: "percent",
    },

    {
      title: "Uses",
      dataIndex: "used",
    },

    {
      key: "4",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="action_flex action_end">
             {permissionChecker("add/edit event discount") ?  <EditOutlined
                className="action_edit"
                onClick={() =>
                  navigate(
                    discountType === "event"
                      ? `${EventDiscountRoute}?id=${eventId}&editId=${record?.key}`
                      : `${AddonDiscountRoute}?id=${eventId}&addonId=${addonId}&editId=${record.key}`
                  )
                }
              /> : null}
             {permissionChecker("delete discounts entities") ?  <DeleteOutlined
                className="action_delete"
                onClick={(e) => {
                  e.preventDefault();
                  ondeleteHandler(record?.key);
                }}
              />  : null}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getDiscountList(discountType === "event" ? eventId : addonId);
  }, [isDiscountDeleted, addonId, eventId]);

  const deleteEventDiscount = async (deleteId) => {
    const response = await deleteEventDiscountApi(deleteId);
    if (response.status) {
      message.success("Discount Deleted Successfully");
      setIsDiscountDeleted(!isDiscountDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const ondeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this discount?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteEventDiscount(id);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const getDiscountList = async (eId) => {
    setLoadingData(true);
    const response = await eventDiscountListingApi(eId);
    if (response.status) {
      setData(
        !!response?.data?.length
          ? response?.data.map((discount) => ({
              key: discount?.uuid,
              name: (
                <>
                  {permissionChecker("view published discounts entities") ?  <Link
                    to={
                      discountType === "event"
                        ? `${EventDiscountDetailsRoute}?id=${eventId}&discountId=${discount?.uuid}`
                        : `${AddonDiscountDetailsRoute}?id=${eventId}&addonId=${addonId}&discountId=${discount?.uuid}`
                    }
                  >
                    {decodeContent(discount?.name || "")}
                  </Link> : <span>{decodeContent(discount?.name || "")}</span>}
                </>
              ),
              type: decodeContent(discount?.field_discount_type) || "",
              code: discount?.field_promo_code || "",
              amount: discount?.field_discount_amount
                ? `$${addCommasInPricing(discount?.field_discount_amount)}`
                : "",
              percent: discount?.field_discount_percent
                ? `${
                    discount?.field_discount_percent.includes(".00")
                      ? Math.round(Number(discount.field_discount_percent))
                      : discount?.field_discount_percent
                  }%`
                : "",
              used: discount?.used_count || 0, //used count pending from backend
            }))
          : []
      );
      setLoadingData(false);
    } else {
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
      setData([]);
      setLoadingData(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          Discounts{" "}
          {loadingData ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${data?.length || 0})`
          )}
        </h4>
       {permissionChecker("add/edit event discount") ?  <Link
          to={
            discountType === "event"
              ? `${EventDiscountRoute}?id=${eventId}`
              : `${AddonDiscountRoute}?id=${eventId}&addonId=${addonId}`
          }
        >
          <Button type="primary">
            <PlusOutlined /> Add
          </Button>
        </Link>  :null}
      </div>

      <Divider className="global_divider" />

      <Table
        columns={columns}
        dataSource={data}
        size="small"
        loading={{ spinning: loadingData, indicator: <DataLoader /> }}
        pagination={false}
      />
    </div>
  );
};
export default DiscountsListing;
