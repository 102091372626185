import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import CreateUserModal from "../CreateUserModal";

const MarketingSourcesHeader = () => {
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowAddUser = authData?.permissions?.includes("add/edit user");

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>Manage Marketing Sources</p>
          </div>
        </h3>
        <div className="breadcrum_buttons">
          {/* {allowAddUser ? (
            <Button type="primary" onClick={showModal}>
              <PlusOutlined /> Add User
            </Button>
          ) : null} */}

          {/* <CreateUserModal openModal={openModal} setOpenModal={setOpenModal} /> */}
        </div>
      </div>
    </div>
  );
};
export default MarketingSourcesHeader;
