import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import {
  EventDetailsAddonsRoute,
  EventRoute,
} from "../../../../../url-routes/urlRoutes";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const AddonAccountingInfo = ({ addonData, eventId, addonId, isLoading }) => {
  const navigate = useNavigate();
  const data = [
    {
      key: "1",
      label: "COA Brand",
      input: addonData?.field_coa_brand?.name || "",
    },
    {
      key: "2",
      label: "COA Trip",
      input: addonData?.field_coa_trip || "",
    },
    {
      key: "3",
      label: "Commission Calculation",
      input: addonData?.field_commission_calculation?.name || "",
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Accounting Information</h4>
        <span>
         {permissionChecker("add/edit addon") ?  <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(
                `${EventDetailsAddonsRoute}?id=${eventId}&editId=${addonId}&step=4`
              );
            }}
          /> : null}
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default AddonAccountingInfo;
