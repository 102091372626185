import axios from "axios";
import queryConfig from "../config/serverless/query.config";
import { Token, customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";

export const getAllReportListApi = (paramsObj) => {
  const { urlQuery, name, report_tag } = paramsObj;
  console.log(`/api/v1/list/reports?sort_by=report_name
  &${name ? `report_name=${name}&` : ""}${
    report_tag ? `tag=${report_tag}&` : ""
  }`);
  return drupalAxiosRequest(
    "get",
    urlQuery
      ? `/api/v1/list/reports?${urlQuery}`
      : `/api/v1/list/reports?sort_by=report_name&${
          name ? `report_name=${name}&` : ""
        }${report_tag ? `tag=${report_tag}&` : ""}`,
    customRequestHeader({ token: Token })
  );
};

export const getReportTagsListApi = () => {
  return drupalAxiosRequest(
    "get",
    "/api/v1/reports/tags?sort_by=name",
    customRequestHeader({ token: Token })
  );
};

export const getQueriesListApi = async () => {
  try {
    const apiUrl = `${queryConfig.BASE_URL}/models/${queryConfig.QUERY_LISTING_ID}/queries`;
    const response = await axios.get(apiUrl);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getReportDetailsApi = async (reportUuid) => {
  return drupalAxiosRequest(
    "get",
    `/api/v1/site_setting_entity/reports/${reportUuid}`,
    customRequestHeader({ token: Token })
  );
};
