import {
  Table,
  Divider,
  Checkbox,
  Switch,
  Tag,
  Button,
  Dropdown,
  Space,
  Menu,
  Input,
  Modal,
  message,
  Select,
  Skeleton,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsAddonsRoute,
} from "../../../../../url-routes/urlRoutes";
import {
  deleteAddonApi,
  getAddonListingApi,
} from "../../../../../apis/eventsApi";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import moment from "moment";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const AddonsListing = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [autofocus, setAutofocus] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const addonsType = [
    { value: "air", label: "Air" },
    { value: "pre_extension_air", label: "Pre Extension Air" },
    { value: "post_extension_air", label: "Post Extension Air" },
    { value: "pre_extension", label: "Pre Extension" },
    { value: "post_extension", label: "Post Extension" },
    { value: "pre_extension_hotel", label: "Pre Extension Hotel" },
    { value: "post_extension_hotel", label: "Post Extension Hotel" },
    { value: "resort", label: "Resort" },
    { value: "template", label: "Template" },
    { value: "pre_book", label: "Pre-Book" },
    { value: "tour", label: "Tour" },
    { value: "hotel", label: "Hotel" },
    { value: "staff_hotel", label: "Staff Hotel" },
    { value: "insurance", label: "Insurance" },
    { value: "dining", label: "Dining" },
    { value: "show_with_seating", label: "Show with Seating" },
    { value: "conference", label: "Conference" },
    { value: "tour_extension", label: "Tour Extension" },
    { value: "cruise_tour", label: "Cruise/Tour" },
    { value: "pre", label: "Pre" },
    { value: "post", label: "Post" },
    { value: "motorcoach", label: "Motorcoach" },
    { value: "pre_hotel", label: "Pre Hotel" },
    { value: "post_hotel", label: "Post Hotel" },
    { value: "activity", label: "Activity" },
    { value: "pre_extension_motorcoach", label: "Pre Extension Motorcoach" },
    { value: "post_extension_motorcoach", label: "Post Extension Motorcoach" },
    { value: "dining_time", label: "Dining Time" },
    { value: "box_office", label: "BoxOffice" },
    { value: "transfer", label: "Transfer" },
    { value: "accessories", label: "Accessories" },
    { value: "gift", label: "Gift" },
    { value: "miscellaneous", label: "Miscellaneous" },
  ];
  const [addonsListing, setAddonsListing] = useState([]);
  const [data, setData] = useState([]);
  const [loadingAddonsList, setLoadingAddonsList] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [addonDeleteTrigger, setAddonDeleteTrigger] = useState(false);

  useEffect(() => {
    getAddonListing(eventId);
  }, [addonDeleteTrigger]);

  useEffect(() => {
    setData(
      addonsListing?.addon_list?.length
        ? addonsListing?.addon_list?.map((addonType, index) => ({
            key: addonType?.type,
            hash: (
              <>
                <p className="document_pdf">
                  {addonType?.type}{" "}
                  {addonType?.count ? `(${addonType?.count})` : 0}
                </p>
              </>
            ),
            name: "",
            status: "",
            web_enabled: "",
            start_date: "",
            end_date: "",
            actions: "none",
            children: addonType?.add_ons?.length
              ? addonType?.add_ons?.map((addon) => ({
                  type: addonType?.type,
                  key: addon.uuid,
                  hash: (
                    <>
                      {permissionChecker("view addon") ? <Link
                        to={`${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${
                          addon?.uuid || ""
                        }&addonType=${addonType?.type}`}
                      >
                        {addon?.id || "#"}
                      </Link> : addon?.id}
                    </>
                  ),
                  name: (
                    <>
                     {permissionChecker("view addon") ?  <Link
                        to={`${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${
                          addon?.uuid || ""
                        }&addonType=${addonType?.type}`}
                      >
                        {addon?.addon_name}
                      </Link> : addon?.addon_name}
                    </>
                  ),
                  status: (
                    <>
                      <Tag color="default">{addon?.status}</Tag>
                    </>
                  ),
                  web_enabled: (
                    <>
                      <Switch disabled checked={addon?.web_enabled} />
                    </>
                  ),
                  start_date: addon?.start_date
                    ? moment(addon?.start_date).format("MM/DD/YYYY")
                    : null,
                  end_date: addon?.end_date
                    ? moment(addon?.end_date).format("MM/DD/YYYY")
                    : null,
                }))
              : [],
          }))
        : []
    );
  }, [addonsListing]);

  useEffect(() => {
    if (data.length) {
      expandAllRows(data);
    }
  }, [data]);

  const getAddonListing = async (eId) => {
    setLoadingAddonsList(true);
    const response = await getAddonListingApi(eId);
    if (response.status) {
      setAddonsListing(response?.data?.data);
      setLoadingAddonsList(false);
    } else {
      setAddonsListing([]);
      setLoadingAddonsList(false);
      message.error(
        response?.data?.response?.data?.message || SOMETHING_WENT_WRONG
      );
    }
  };

  const deleteAddon = async (aId) => {
    const response = await deleteAddonApi(aId);
    if (response.status) {
      message.success("Addon Deleted Successfully");
      setAddonDeleteTrigger(!addonDeleteTrigger);
    } else {
      message.error(
        response?.data?.response?.data?.message || response?.data?.response?.data?.errors?.[0]?.detail || SOMETHING_WENT_WRONG
      );
    }
  };
  const onDeleteHandler = (aId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this add-on?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        return deleteAddon(aId);
      },
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "hash",
    },

    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Web Enabled",
      dataIndex: "web_enabled",
    },

    {
      title: "Start Date",
      dataIndex: "start_date",
    },

    {
      title: "End Date",
      dataIndex: "end_date",
    },

    {
      title: "Actions",

      render: (record) => {
        if (record.actions === "none") {
          return;
        }
        return (
          <>
            <div className="action_flex action_end">
              {permissionChecker("add/edit addon") ? (
                <EditOutlined
                  className="action_edit"
                  onClick={() =>
                    navigate(
                      `${EventDetailsAddonsRoute}?id=${eventId}&editId=${record?.key}&step=1`
                    )
                  }
                />
              ) : null}
              {permissionChecker("delete addon") ? (
                <DeleteOutlined
                  className="action_delete"
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteHandler(record?.key);
                  }}
                />
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const expandAllRows = (datasource) => {
    const allKeys = datasource.reduce((keys, item) => {
      if (item.children) {
        return [...keys, item.key, ...item.children.map((child) => child.key)];
      }
      return keys;
    }, []);
    setExpandedRowKeys(allKeys);
  };

  const onBlur = () => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 200);
    }
    setOpen(false);
    setOpen2(false);
    setAutofocus(false);
  };

  return (
    <div>
      <div className="requests_title">
        <h4 className="heading_four">
          Add-Ons{" "}
          {loadingAddonsList ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "60px" }}
            />
          ) : (
            `(${addonsListing?.count || 0})`
          )}
        </h4>
        <div
          style={{
            position: "relative",
          }}
        >
          {permissionChecker("add/edit addon") ? (
            <Button
              onClick={(e) => {
                if (!clicked) {
                  e.preventDefault();
                  setOpen(!open);
                  setOpen2(!open2);
                  setAutofocus(!autofocus);
                  setClicked(!clicked);
                }
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          ) : null}
          {open && (
            <Select
              onChange={(e) => {
                setOpen(false);
                setAutofocus(false);
                setOpen2(false);
                navigate(`${EventDetailsAddonsRoute}?id=${id}&addonType=${e}`);
              }}
              style={{
                width: "250px",
                position: "absolute",
                right: "0",
                top: "35px",
                zIndex: "999",
                background: "white",
                border: "unset",
                outline: "unset",
                boxShadow: "unset",
              }}
              placeholder="Select Add-Ons"
              showSearch
              open={open2}
              autoFocus={autofocus}
              onBlur={onBlur}
              options={addonsType.map((addons) => ({
                key: addons.value,
                value: addons.value,
                label: addons.label,
              }))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          )}
        </div>
      </div>

      <Divider className="global_divider" />
      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys((keys) => [...keys, record.key]);
            } else {
              setExpandedRowKeys((keys) =>
                keys.filter((key) => key !== record.key)
              );
            }
          },
          expandIconColumnIndex: -1,
        }}
        defaultExpandAllRows
        size="small"
        pagination={false}
        loading={{ spinning: loadingAddonsList, indicator: <DataLoader /> }}
      />
    </div>
  );
};
export default AddonsListing;
