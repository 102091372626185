import { PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Empty, Modal, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./EmergencyContact.less";
import DataLoader from "../../../../others/Loaders/DataLoader";
import {
  Cookie,
  customRequestHeader,
} from "../../../../utils/cutomRequestHeader";
import {
  contactDetailsEditLoader,
  createContact,
} from "../../../../redux/features/contactFeatures/contactSlice";
import { COMPLETE_PREVIOUS_STEP } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";
const { Meta } = Card;

const EmergencyContact = ({ contactDetailsData, contactId, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    field_emergency_contact_name,
    field_emergency_contact_relationship,
    field_emergency_contact_phone,
    field_emergency_contact_phone_2,
    field_emergency_contact_email,
    field_ecp_extension,
    field_ecp_2_extension,
    field_step,
  } = contactDetailsData || {};
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const onEditHandler = (currentStep) => {
    if (Number(currentStep + 1) < 4) {
      message.error(COMPLETE_PREVIOUS_STEP);
    } else {
      navigate(`/contact?editId=${contactId}&step=4`);
    }
  };

  const deleteModal = () => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(contactDetailsEditLoader(false));
        const reqMethod = "patch";
        const reqHeaders = customRequestHeader({ token: Token });
        const payload = {
          data: {
            type: "contact--contact",
            attributes: {
              field_emergency_contact_name: null,
              field_emergency_contact_relationship: null,
              field_emergency_contact_phone: null,
              field_emergency_contact_phone_2: null,
              field_emergency_contact_email: null,
              field_ecp_extension: null,
              field_ecp_2_extension: null,
              field_step,
            },
            relationships: {
              modified_by: {
                data: {
                  type: "user--user",
                  id: userUuid,
                },
              },
            },
          },
        };
        if (contactId) {
          payload.data.id = contactId;
        }
        return dispatch(
          createContact({ reqMethod, contactId, reqHeaders, payload })
        );
      },
    });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Emergency Contact</h4>
        {!field_emergency_contact_name && allowEditContact && (
          <span>
           {permissionChecker("add/edit contact") ? ( <Button
              icon={<PlusOutlined />}
              onClick={() => {
                onEditHandler(field_step + 1 || 0);
              }}
              type="primary"
            >
              Add Emergency Contact
            </Button>) : null}
          </span>
        )}
      </div>
      <Divider className="global_divider mb-0" />
      {isLoading ? (
        <DataLoader />
      ) : (
        <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
          <div>
            {field_emergency_contact_name ? (
              <Card
                className="emergency-contact"
                type="inner"
                style={{ width: "50%" }}
              >
                <Meta
                  title={
                    <div>
                      <p className="document_pdf">
                        {field_emergency_contact_name}
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            opacity: "0.7",
                            fontFamily: "Poppins",
                            marginLeft: "5px",
                          }}
                        >
                          {field_emergency_contact_relationship &&
                            `(${field_emergency_contact_relationship})`}
                        </span>
                      </p>
                      <p className="document_pdf">
                        {field_emergency_contact_phone && (
                          <span>
                            <PhoneOutlined
                              style={{ transform: "rotate(-270deg)" }}
                            />
                            {`${field_ecp_extension} ${field_emergency_contact_phone}`}
                          </span>
                        )}
                        {field_emergency_contact_phone_2 && (
                          <span style={{ marginLeft: "16px" }}>
                            <PhoneOutlined
                              style={{ transform: "rotate(-270deg)" }}
                            />
                            {`${field_ecp_2_extension} ${field_emergency_contact_phone_2}`}
                          </span>
                        )}
                      </p>
                    </div>
                  }
                  description={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "0",
                        fontFamily: "'Poppins'",
                        marginBottom: "16px",
                        color: "rgba(0, 0, 0, 0.85)",
                      }}
                    >
                      <span>{field_emergency_contact_email}</span>
                    </p>
                  }
                />

                <Divider className="mb-0" />
                <div className="card_anchor">
                  <Button
                    onClick={() => {
                      navigate(`/contact?editId=${contactId}&step=4`);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      deleteModal();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </Card>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default EmergencyContact;
