import { Divider, Table, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { getReservationItemHistoryApi } from "../../../../apis/reservationApi";
import { DATE_FORMAT, SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { decodeContent } from "../../../../utils/contentParser";

const columns = [
  {
    title: "Revision #",
    dataIndex: "hash",
  },

  {
    title: "Revision Description",
    dataIndex: "description",
  },

  {
    title: "User",
    dataIndex: "user",
  },

  {
    title: "Date/Time",
    dataIndex: "date_time",
  },
];

const ReservationItemHistory = ({ itemId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReservationItemHistory(itemId);
  }, []);

  const getReservationItemHistory = async (itemId) => {
    setLoading(true);
    const res = await getReservationItemHistoryApi(itemId);
    if (res.status) {
      setData(
        res?.data?.map((listItem) => ({
          hash: listItem?.id || "",
          description: decodeContent(listItem?.field_description || ""),
          user: listItem?.authored_by || "",
          date_time: listItem?.created ? (
            <span>
              {moment.unix(listItem?.created).format(DATE_FORMAT)}{" "}
              {moment.unix(listItem?.created).format("hh:mm A")}{" "}
              <span className="span_text">
                {moment.unix(listItem?.created).fromNow()}
              </span>
            </span>
          ) : null,
        }))
      );
      setLoading(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setLoading(false);
      setData([]);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          {" "}
          Revision History
        </h4>
      </div>

      <Divider className="global_divider mb-0" />
      <Table
        className="revision_history_table"
        columns={columns}
        dataSource={data}
        size="middle"
        loading={{ spinning: loading, size: "large" }}
        pagination={false}
      />
    </div>
  );
};

export default ReservationItemHistory;
