import { CloseOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../../../../url-routes/urlRoutes";

function PlannedPaymentHeader({
  form,
  loading,
  reservationData,
  reservationId,
  paymentId,
  loadingPaymentData,
}) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile"
            onClick={() => {
              navigate(-1);
            }}
          />
          {loadingPaymentData ? (
            <Skeleton.Input active={true} style={{ width: "250px" }} />
          ) : (
            <div className="header_res">
              <p>{paymentId ? "Edit" : "Add"} Planned Payment </p>
              <Link
                to={`${ReservationDetailRoute}?id=${reservationId}`}
                className="header_smalltext"
              >
                {`${reservationData?.name} - ${reservationData?.drupal_internal__id}`}
              </Link>
            </div>
          )}
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={loading}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PlannedPaymentHeader;
