export function objectToQueryString(obj) {
    const queryParams = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
    }

    return queryParams.join('&');
}