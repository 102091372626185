import countryList from "../../../utils/countryList.json";
import { DatePicker, Form, Input, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createOrEditEvent,
  eventStepSetter,
  getEventDestinationsList,
  getEventPartnersList,
  getEventShipList,
  getEventSuppliersList,
  getEventTypeList,
  updateEventStep,
} from "../../../redux/features/eventFeatures/eventsSlice";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EventDetailsRoute } from "../../../url-routes/urlRoutes";
import moment from "moment";
import MobileInput from "../../Custom/MobileInput";
import { editMarketingSource } from "../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Option, OptGroup } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const GeneralInformationForm = ({
  singleEventResponse,
  form1,
  createId,
  editId,
  next,
  eventFormFields,
  currentStep,
}) => {
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    eventCreation,
    eventPartners,
    eventSuppliers,
    eventDestinations,
    eventShip,
  } = useSelector((state) => state.events);
  const { eventCreationResponse, loadingEventCreation, step1, eventStep } =
    eventCreation;
  const { eventPartnersResponse, loadingEventPartners } = eventPartners;
  const { eventSuppliersResponse, loadingEventSuppliers } = eventSuppliers;
  const { eventDestinationsResponse, loadingEventDestinations } =
    eventDestinations;
  const { eventShipResponse, loadingEventShip } = eventShip;
  const eventCreationRef = useRef(false);
  const [eventPartnersList, setEventPartnersList] = useState([]);
  const [eventSuppliersList, setEventSuppliersList] = useState([]);
  const [eventDestinationList, setEventDestinationList] = useState([]);
  const [eventShipList, setEventShipList] = useState([]);
  const eventTypeRef = useRef(false);
  const eventPartnersRef = useRef(false);
  const eventSuppliersRef = useRef(false);
  const eventShipRef = useRef(false);
  const eventDestinationsRef = useRef(false);
  const convertToEvent = searchParams.get("convertToEvent");
  const [selectedPartner, setSelectedPartner] = useState([]);

  useEffect(() => {
    dispatch(getEventPartnersList());
    dispatch(getEventSuppliersList());
    dispatch(getEventDestinationsList());
    dispatch(getEventShipList());
  }, []);

  useEffect(() => {
    if (eventPartnersRef.current) {
      if (eventPartnersResponse.status) {
        setEventPartnersList(eventPartnersResponse.data.data);
      } else {
        setEventPartnersList([]);
      }
    }
    eventPartnersRef.current = true;
  }, [eventPartnersResponse]);

  useEffect(() => {
    if (eventSuppliersRef.current) {
      if (eventSuppliersResponse.status) {
        setEventSuppliersList(eventSuppliersResponse.data.data);
      } else {
        setEventSuppliersList([]);
      }
    }
    eventSuppliersRef.current = true;
  }, [eventSuppliersResponse]);

  useEffect(() => {
    if (eventDestinationsRef.current) {
      if (eventDestinationsResponse.status) {
        setEventDestinationList(eventDestinationsResponse.data.data);
      } else {
        setEventDestinationList([]);
      }
    }
    eventDestinationsRef.current = true;
  }, [eventDestinationsResponse]);

  useEffect(() => {
    if (eventShipRef.current) {
      if (eventShipResponse.status) {
        setEventShipList(eventShipResponse.data.data);
      } else {
        setEventShipList([]);
      }
    }
    eventShipRef.current = true;
  }, [eventShipResponse]);

  useEffect(() => {
    if ((createId || editId) && !!Object.keys(eventFormFields).length) {
      dispatch(eventStepSetter(eventFormFields?.field_step));
      form1.setFieldsValue({
        ...eventFormFields,
        field_start_date: !!eventFormFields?.field_start_date
          ? moment(eventFormFields?.field_start_date, "YYYY-MM-DD")
          : null,
        field_end_date: eventFormFields?.field_end_date
          ? moment(eventFormFields?.field_end_date, "YYYY-MM-DD")
          : null,
        field_type: eventFormFields?.field_type,
        field_event_partner: eventFormFields?.field_event_partner?.length
          ? eventFormFields?.field_event_partner?.map((partner) => partner?.id)
          : [],
        field_event_supplier:
          convertToEvent &&
          eventFormFields?.field_type === "Tour" &&
          eventFormFields?.field_tour_operator?.id
            ? [eventFormFields?.field_tour_operator?.id]
            : eventFormFields?.field_event_supplier?.length
            ? eventFormFields?.field_event_supplier?.map(
                (supplier) => supplier?.id
              )
            : [],
        field_destination: eventFormFields?.field_destination?.id,
        field_ship: eventFormFields?.field_ship?.id,
        field_tollfree_extension:
          eventFormFields?.field_tollfree_extension || "+1",
      });
    }
    if (!editId && !createId && !currentStep) {
      form1.resetFields();
    }
  }, [eventFormFields]);
  useEffect(() => {
    if (eventCreationRef.current) {
      if (eventCreationResponse.status) {
        let identifyForm = createId || editId ? "editForm" : "creationForm";
        dispatch(
          eventStepSetter(eventCreationResponse?.data?.data?.field_step) || 1
        );
        if (identifyForm === "creationForm" || createId) {
          if (selectedPartner.length) {
            const payload = {
              type: "event",
              event_id: eventCreationResponse?.data?.data?.drupal_internal__id,
              marketing_data_type: "source",
              operation: "event_partner_edit",
              data: [],
            };

            selectedPartner.forEach((selected) => {
              const eventpartner = eventPartnersList.filter(
                (e) => e.id === selected
              );
              payload.data.push({
                name: eventpartner[0].title,
                id: null,
              });
            });

            editMarketingSource(payload).then((res) => {
              if (res.data.status) {
                message.success("Form Submitted Successfully");
                step1
                  ? navigate(
                      `${EventDetailsRoute}?id=${
                        eventCreationResponse?.data?.data?.id || createId
                      }`
                    )
                  : next({
                      type: "creation",
                      id: eventCreationResponse?.data?.data?.id || createId,
                    });
              }
            });
          }
        } else {
          if (identifyForm === "editForm") {
            if (selectedPartner.length) {
              const payload = {
                type: "event",
                event_id:
                  eventCreationResponse?.data?.data?.drupal_internal__id,
                marketing_data_type: "source",
                operation: "event_partner_edit",
                data: [],
              };
              selectedPartner.forEach((selected) => {
                const eventpartner = eventPartnersList.filter(
                  (e) => e.id === selected
                );
                payload.data.push({
                  name: eventpartner[0].title,
                  id: null,
                });
              });

              editMarketingSource(payload).then((res) => {
                if (res.data.status) {
                  message.success("Form Submitted Successfully");
                  step1
                    ? navigate(`${EventDetailsRoute}?id=${editId}`)
                    : next({ type: "edit", id: editId });
                }
              });
            }
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateEventStep());
      } else {
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    eventCreationRef.current = true;
  }, [eventCreationResponse]);

  const onFinishHandler = (values) => {
    const reqType = createId || editId ? "patch" : "post";
    setSelectedPartner(values?.field_event_partner);
    const attributes = {
      name: values.name,
      field_status:
        reqType === "post" ? "design" : eventFormFields?.field_status?.key,
      status: true,
      field_direct_event: true,
      field_type: values.field_type,
      field_event_code: values.field_event_code,
      field_start_date: values?.field_start_date
        ? moment(values?.field_start_date)?.format("YYYY-MM-DD")
        : null,
      field_end_date: values?.field_end_date
        ? moment(values?.field_end_date)?.format("YYYY-MM-DD")
        : null,

      field_tollfree_extension: values?.field_tollfree_phone
        ? values?.field_tollfree_extension || null
        : null,
      field_tollfree_phone: values?.field_tollfree_phone,
      field_step: currentStep > eventStep ? +currentStep : eventStep,
      field_destination_country: values.field_destination_country || null,
    };
    if (convertToEvent) {
      attributes["field_group_detail_status"] = "converted_to_event";
      attributes["field_status"] = "design";
      attributes["field_direct_event"] = false;
    }

    const relationships = {
      field_event_partner: {
        data: values.field_event_partner?.map((partnerId) => ({
          type: "node--organization",
          id: partnerId,
        })),
      },
      field_event_supplier: values?.field_event_supplier?.length
        ? {
            data: values?.field_event_supplier?.map((supplierId) => ({
              type: "node--organization",
              id: supplierId,
            })),
          }
        : null,
      field_destination: values?.field_destination
        ? {
            data: {
              type: "taxonomy_term--destination",
              id: values.field_destination,
            },
          }
        : null,
      field_ship: values?.field_ship
        ? {
            data: {
              type: "taxonomy_term--ship",
              id: values.field_ship,
            },
          }
        : null,
      modified_by: {
        data: {
          type: "user--user",
          id: userUuid,
        },
      },
    };

    const data = {
      data: {
        type: "event--event",
        attributes,
        relationships,
      },
    };
    if (reqType === "patch") {
      data.data.id = createId || editId;
    }
    const payload = { reqType, uuid: createId || editId, data };
    dispatch(createOrEditEvent(payload));
  };

  const validateEndDate = (e, value) => {
    if (e.field === "field_start_date") {
      const startDate = value;
      const endDate = form1.getFieldValue("field_end_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (start.isBefore(end) || start.isSame(end)) {
          form1.setFields([
            {
              name: "field_end_date",
              errors: [],
            },
          ]);
        } else {
          if (end.isBefore(start)) {
            form1.setFields([
              {
                name: "field_end_date",
                errors: ["End Date cannot be less than the Start Date"],
              },
            ]);
          }
        }
      }
    } else {
      const endDate = value;
      const startDate = form1.getFieldValue("field_start_date");
      if (startDate && endDate) {
        const start = startDate.startOf("day");
        const end = endDate.startOf("day");

        if (end.isBefore(start)) {
          return Promise.reject("End Date cannot be less than the Start Date");
        }
      }
    }
    return Promise.resolve();
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
            lg: 18,
          }}
          layout="horizontal"
          form={form1}
          onFinish={onFinishHandler}
        >
          <Form.Item
            label="Type"
            className="asterick-align"
            name="field_type"
            rules={[{ required: true, message: "Please Select Event Type" }]}
          >
            <Select showSearch>
              <Select.Option value="Cruise" name="Cruise">
                Cruise
              </Select.Option>
              <Select.Option value="Tour" name="Tour">
                Tour
              </Select.Option>
              <Select.Option value="Charter Cruise" name="Charter Cruise">
                Charter Cruise{" "}
              </Select.Option>
              <Select.Option value="Cruise/Tour" name="Cruise/Tour">
                Cruise/Tour
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Event Name"
            className="asterick-align"
            name="name"
            rules={[{ required: true, message: "Please Enter Event Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Event Code"
            className="asterick-align"
            name="field_event_code"
            rules={[{ required: true, message: "Please Enter Event Code" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Event Partner"
            className="asterick-align"
            name="field_event_partner"
            rules={[{ required: true, message: "Please Select Event Partner" }]}
          >
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              showArrow
              loading={loadingEventPartners}
              optionLabelProp="label"
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={eventPartnersList?.map((eventPartners) => ({
                key: eventPartners?.id,
                value: eventPartners?.id,
                label: eventPartners?.title,
              }))}
            />
          </Form.Item>

          <Form.Item label="Suppliers" name="field_event_supplier">
            <Select
              allowClear
              mode="multiple"
              showArrow
              loading={loadingEventSuppliers}
              optionLabelProp="label"
              showSearch            
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                eventSuppliersList?.length
                  ? eventSuppliersList?.map((eventSuppliers) => ({
                      key: eventSuppliers?.id,
                      label: eventSuppliers?.title,
                      value: eventSuppliers.id,
                    }))
                  : []
              }
            />
          </Form.Item>

          <Form.Item
            label="Start Date"
            className="asterick-align"
            name="field_start_date"
            rules={[
              { required: true, message: "Please Select Start Date" },
              {
                validator: validateEndDate,
                dependencies: ["field_end_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item
            label="End Date"
            className="asterick-align"
            name="field_end_date"
            rules={[
              { required: true, message: "Please Select End Date" },
              {
                validator: validateEndDate,
                dependencies: ["field_start_date"], // Trigger validation when startDate changes
              },
            ]}
          >
            <DatePicker format={"MM/DD/YYYY"} />
          </Form.Item>

          <Form.Item label="Destination" name="field_destination">
            <Select
              allowClear
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingEventDestinations}
              placeholder="Destination"
              options={eventDestinationList?.map((eventDestination) => ({
                key: eventDestination.id,
                label: eventDestination?.name,
                value: eventDestination.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Destination Country"
            name="field_destination_country"
          >
            <Select
              allowClear
              showSearch
              showArrow
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingEventDestinations}
              placeholder="Destination Country"
              options={Object.keys(countryList).map(
                (eventDestination, index) => ({
                  key: index,
                  label: countryList[eventDestination],
                  value: eventDestination,
                })
              )}
            />
          </Form.Item>
          <Form.Item label="Ship" name="field_ship">
            <Select
              allowClear
              showSearch
              placeholder="Ship"
              loading={loadingEventShip}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={eventShipList?.map((eventShip) => ({
                key: eventShip.id,
                value: eventShip.id,
                label: eventShip?.name,
              }))}
            />
          </Form.Item>
          <MobileInput
            label={"Toll Free Phone Number"}
            extension={"field_tollfree_extension"}
            phoneName={"field_tollfree_phone"}
            form={form1}
          />
        </Form>
      </div>
    </div>
  );
};

export default GeneralInformationForm;
