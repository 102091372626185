import { Table, Button, Divider, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Additional_informationdrawer from "./SideDrawerForms/AdditionalInformationDrawer";
import { Link } from "react-router-dom";
import React from "react";
import AdditionalInformationDrawer from "./SideDrawerForms/AdditionalInformationDrawer";
import DataLoader from "../../../../others/Loaders/DataLoader";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const AdditionalInformation = ({
  additionalInfoData,
  contactId,
  isLoading,
}) => {
  const {
    field_shirt_size,
    field_church_member,
    field_church_name,
    field_military,
    field_company,
    field_website,
    field_department,
    field_occupation,
    field_job_title,
    field_vocal_part,
  } = additionalInfoData || {};

  const additionalInfoDataSource = [
    {
      key: "jacket/shirt-size",
      label: "Jacket/Shirt Size",
      value: field_shirt_size?.label || "",
    },
    {
      key: "church-member",
      label: "Church Member",
      value: field_church_member !== null && (
        <Switch checked={field_church_member} disabled />
      ),
    },
    {
      key: "church-name",
      label: "Church Name, City & State",
      value: field_church_name || "",
    },
    {
      key: "vocal-part",
      label: "Vocal Part",
      value: !!field_vocal_part?.length
        ? field_vocal_part?.map((vocalPart) => vocalPart.name)
        : "",
    },
    {
      key: "military",
      label: "Military",
      value: field_military?.label || "",
    },
    {
      key: "company",
      label: "Company",
      value: field_company || "",
    },
    {
      key: "website",
      label: "Website",
      value: field_website || "",
    },
    {
      key: "department",
      label: "Department",
      value: field_department || "",
    },

    {
      key: "occupation",
      label: "Occupation",
      value: field_occupation || "",
    },
    {
      key: "job-title",
      label: "Job Title",
      value: field_job_title || "",
    },
  ];
  return (
    <div>
      <div className="partnership_inform">
        <h4>Additional Information</h4>
        <AdditionalInformationDrawer
          additionalInfoData={additionalInfoData}
          contactId={contactId}
        />
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={additionalInfoDataSource}
        showHeader={false}
        pagination={false}
        loading={{ spinning: isLoading, indicator: <DataLoader /> }}
        size="small"
      />
    </div>
  );
};

export default AdditionalInformation;
