import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Radio } from "antd";
import { Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";

function Breadcrumb_editguestdetails(props) {
  const { form, loading, reservationData } = props;
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <CloseOutlined
            onClick={() => {
              window.history.go(-1);
            }}
          />
          <div className="header_res">
            <p style={{ paddingLeft: "12px" }}> Edit Traveler Detail</p>
            <p className="header_smalltext">
              {reservationData?.drupal_internal__id &&
                `(${reservationData?.drupal_internal__id})`}
            </p>
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Save
          </Button>

          <Button
            onClick={() => {
              window.history.go(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Breadcrumb_editguestdetails;
