import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];
const data = [

  {
    key: "2",
    label: "Created By",
    input: <>
    <span className="">Mike McDonald (Staff)
02/15/2022 10:30 AM</span>
    <span className="span_text" >  8 months ago</span>
    </>,
  },

  {
    key: "2",
    label: "Last Modified By",
    input: <>
    <span className="">Mike McDonald
10/24/2022 12:35 PM</span>
    <span className="span_text">  17 months ago</span>
    </>,
  },
];

function PaymentsystemInformation() {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>System Information</h4>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default PaymentsystemInformation;
