import { contactAxiosRequest } from "../utils/contactDrupalAxios";
import { customRequestHeader } from "../utils/cutomRequestHeader";

// export const getAllGroupDetailsApi = async (urlQuery) => {
//   return await contactAxiosRequest(
//     "get",
//     `/api/v1/list/group-details/listing?${urlQuery}`,
//     customRequestHeader({ token: Token })
//   );
// };

export const getSingleGroupDetailWrapperApi = async (urlQuery) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/rest/wrapper?${urlQuery}`,
    customRequestHeader({ token: Token })
  );
};

export const getSingleGroupDetailApi = async (uuid) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/event/event/${uuid}?include=field_meal_type,user_id,modified_by,field_event_partner,field_non_married_roommate_policy,field_tour_operator,field_tour_itinerary,field_guide_request,field_tour_operator,field_tour_itinerary`,
    customRequestHeader({ token: Token })
  );
};

// export const getSingleGroupDetailWrapperApi = async (uuid) => {
//   return await contactAxiosRequest(
//     "get",
//     `/api/v1/event/event/${uuid}?include=field_meal_type,user_id,field_event_partner,field_non_married_roommate_policy`,
//     customRequestHeader({ token: Token })
//   );
// };

export const getExtensionsByGroupDetailIdApi = async (uuid) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/extensions/extensions?filter[field_event.id]=${uuid}&include=field_meal_type,user_id,field_guide_request,modified_by,field_extension_itinerary,field_extension_destination,field_tour_operator`,
    customRequestHeader({ token: Token })
  );
};

export const deleteGroupDetailApi = async (deleteId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "delete",
    `/api/v1/event/event/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};

export const deleteExtensionApi = async (deleteId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const data = {
    type: "extensions--extesnions",
    id: deleteId,
  };
  return await contactAxiosRequest(
    "delete",
    `/api/v1/extensions/extensions/${deleteId}`,
    customRequestHeader({ token: Token }),
    { data }
  );
};

export const getTaxonomyListApi = async (taxonomyName) => {
  const limitList = ["departure_airline","arrival_airline","post_ext_arrival_airline","post_ext_departure_airline","pre_ext_arrival_airline","pre_ext_departure_airline","fee_name","discount_name"]
  const Url =
    taxonomyName === "airline"
      ? `/api/v1/taxonomy_term/${taxonomyName}?sort=weight&page[limit]=150`
      : `/api/v1/taxonomy_term/${taxonomyName}${
          taxonomyName.includes("?") ? `&sort=weight` : "?sort=weight"
        }${limitList.includes(taxonomyName) ? `&page%5Blimit%5D=400` : ""}`;
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    Url,
    customRequestHeader({ token: Token })
  );
};

export const createGroupDetailsApi = async (data) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    data.reqMethod,
    `/api/v1/event/event/${data?.eventId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const createGroupDetailsExtensionApi = async (data) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    data.reqMethod,
    `/api/v1/extensions/extensions/${data?.extensionId || ""}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const getGroupDetailsDataApi = async (eventId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/event/event/${eventId}?include=field_meal_type,user_id,field_event_partner,field_non_married_roommate_policy,field_tour_operator,field_tour_itinerary,field_guide_request`,
    customRequestHeader({ token: Token })
  );
};

export const getGroupDetailsExtensionDataApi = async (eventId) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  return await contactAxiosRequest(
    "get",
    `/api/v1/extensions/extensions?filter[field_event.id]=${eventId}`,
    customRequestHeader({ token: Token })
  );
};
