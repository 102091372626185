import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Col, Row, Grid } from "antd";
import Sidebar from "../../components/Header/Header_sidebar";
// import Horizontals from '../../components/Header/Horizontal_menu';
import SearchElite_menu from "../../components/Header/SearchElite_menu";
import Breadcrumb_elitetravelers from "../../components/Breadcrumb/Breadcrumb_elitetravelers/Breadcrumb_elitetravelers";
import Report_elitelisting from "../../components/View_components/Report_elitelisting/Report_elitelisting";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../utils/permisssionChecker";
const { Header, Content, Sider } = Layout;

function Report_elitetravelers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const order = searchParams.get("order") || "ASC";
  const count = searchParams.get("count") || 10;
  const organizationType = searchParams.get("org_type");
  const organizationName = searchParams.get("name");
  const [urlParamsObject, setUrlParamsObject] = useState({
    count,
    order,
    page,
    name: organizationName,
    org_type: organizationType,
  });
  const [eliteTraveles, setEliteTraveles] = useState([]);
  const navigate = useNavigate()
  useEffect(()=>{
    const isAllowed = permissionChecker("view navigation menu elite travelers");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <SearchElite_menu
              showSearchBar={true}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              eliteTraveles={eliteTraveles}
              setEliteTraveles={setEliteTraveles}
            />
          </Header>
          <Breadcrumb className="edit_breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
            <Breadcrumb.Item>Elite Travelers</Breadcrumb.Item>
          </Breadcrumb>
          <div className="events-header">
            <Breadcrumb_elitetravelers />
          </div>
          <Content>
            <Report_elitelisting
              eliteTraveles={eliteTraveles}
              setEliteTraveles={setEliteTraveles}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Report_elitetravelers;
