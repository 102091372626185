import { Button, Skeleton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

function HeaderCategoryPricingEdit({
  categoryDetails,
  loadingSingleCategory,
  form,
}) {
  const navigate = useNavigate();
  const [searchParamas, setSearchParams] = useSearchParams();
  const id = searchParamas.get("id");
  const { updateCategoryResponse, loadingUpdateCategory } = useSelector(
    (state) => state.events.updateCategory
  );
  return (
    <div className="breadcrumb_content" style={{ width: "100%" }}>
      <div
        className="breadcrumb_heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "16px",
        }}
      >
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile"
            onClick={() => navigate(-1)}
          />
          <div className="header_res">
            <p>Edit Pricing </p>
            <p className="header_smalltext">
              {loadingSingleCategory ? (
                <Skeleton.Input active={true} style={{ width: "250px" }} />
              ) : (
                `Category (${categoryDetails?.name || ""})`
              )}
            </p>
            {/* <p className="header_smalltext">Category (PS PS Pinnacle Suite)</p> */}
          </div>
        </h3>
        <div
          className="breadcrum_buttons"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button
            loading={loadingUpdateCategory}
            onClick={() => form.submit()}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeaderCategoryPricingEdit;
