import React, { useState, useEffect } from "react";

import { Col, Row, Collapse, TimePicker } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Divider,
} from "antd";

function onChange(time, timeString) {
    console.log(time, timeString);
}
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function CruisePostform_four() {
    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const [staff, setStaff] = useState(false);
    const [min, setMin] = useState(false);

    useEffect(() => {
        form.validateFields(["nickname"]);
    }, [checkNick, form]);

    const onCheckboxChange = (e) => {
        setCheckNick(e.target.checked);
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log("Success:", values);
        } catch (errorInfo) {
            console.log("Failed:", errorInfo);
        }
    };

    const { Panel } = Collapse;

    const onChange = (key) => {
        console.log(key);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <div className="">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <h4 className="mb-0 ms-0">Pricing Options</h4>

            </div>
            <Divider className="global_divider mb-0" />



            <div style={{ marginTop: "24px" }} />
            <Form
                {...layout}
                labelCol={{
                    sm: 24,
                    md: 6,
                    lg: 6,
                    xl: 5,
                    xxl: 4,
                }}
                wrapperCol={{
                    sm: 24,
                    md: 14,
                    lg: 14,
                    xl: 14,
                    xxl: 12,
                }}
                layout="horizontal"
            >




                <Form.Item label={
                    <div div className="text_break">
                        <div>Est Group Size For</div>
                        <div>Space Request</div>

                    </div>
                }
                >
                    <Input />
                </Form.Item>

                <Form.Item label="z For Pricing" name="Est Group Size For Pricing">
                    <Input />
                </Form.Item>


                <Form.Item label={
                    <div div className="text_break">
                        <div>Est Bus Count </div>
                        <div>(Based On Group Size)</div>

                    </div>
                }
                >
                    <Form.Item name="Length Of Tour" noStyle>
                        <InputNumber 
                        min={1} max={10} 
                        style={{

                            width: "100%"
                        }} />
                    </Form.Item>

                </Form.Item>


                <Form.Item label="Est Staff Count" name="Est Staff Count">
                    <Input />
                </Form.Item>

                <Form.Item label="Pricing Based On" name="Pricing Based On">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="1 for Program"
                    name="1 for Program"

                >
                    <Select style={{ height: "40px" }}>
                        <Select.Option value="10">
                            10
                        </Select.Option>
                        <Select.Option value="15">
                            15
                        </Select.Option>


                    </Select>
                </Form.Item>


                <Form.Item label="Event Partner Income" name="Event Partner Income">
                    <Input />
                </Form.Item>

                <Form.Item label="Purpose for Income" name="purpose_income">
                    <Input />
                </Form.Item>

                <Form.Item label="Tip Budget" name="Tip Budget">
                    <Input />
                </Form.Item>

                <div className="textarea_row">
                    <Form.Item label="Misc Items" name="Misc Items">
                        <TextArea rows={4} />
                    </Form.Item>

                </div>

                <div className="textarea_row">
                    <Form.Item label="Notes" name="Notes">
                        <TextArea rows={4} />
                    </Form.Item>
                </div>

            </Form>
        </div>




    );
}

export default CruisePostform_four;