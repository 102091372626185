import { Grid, Layout } from "antd";
import React, { useEffect } from "react";
import MarketingSouresSearchbar from "../../../components/Admin/ManageUsers/MarketingSourcesSearchbar";
import MarketingSourcesHeader from "../../../components/Admin/ManageUsers/MarketingSourcesHeader";
import ManageMarketingSources from "../../../components/Events/EventDetailsTabs/GeneralDetailsTab/ManageMarketingSources";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import { getAllUsers } from "../../../redux/features/usersFeatures/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import DataLoader from "../../../others/Loaders/DataLoader";

const { Header, Content } = Layout;

const MarketingSourcesPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const breadCrumbs = [
    { title: "Admin", path: "" },
    { title: "Manage Marketing Sources", path: "" },
  ];

  const dispatch = useDispatch();

  const userCreated = useSelector((state) => state.users.userCreated);
  const userDelete = useSelector((state) => state.users.userDeleted);
  const userUpdate = useSelector((state) => state.users.userUpdated);
  const ANONYMOUS_USER_ID = "7c79528c-f1eb-4d84-9b14-6e6d5736d60e";

  useEffect(() => {
    dispatch(getAllUsers());
  }, [userCreated, userDelete, userUpdate, dispatch]);

  let userList =
    useSelector((state) => state?.users?.users?.userList?.data) || [];
  const loadingAllUsers = useSelector((state) => state.users.users.loading);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <MarketingSouresSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <MarketingSourcesHeader />
          </div>
          <Content className="site-layout-background">
            <ManageMarketingSources
              users={userList}
              loadingAllUsers={loadingAllUsers}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default MarketingSourcesPage;
