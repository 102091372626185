import { CloseOutlined } from "@ant-design/icons";
import { Button, Skeleton, message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ReservationDetailRoute,
  ReservationItemDetailRoute,
} from "../../../../url-routes/urlRoutes";
import { changeResItemStatusApi } from "../../../../apis/reservationApi";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

function CancelReservationItemHeader({
  itemId,
  reservationId,
  reservationData,
  loadingReservationData,
  cancelDetails,
  loadingCancelDetails,
  setChangeStatus,
  resItemData,
  changeStatus,
}) {
  const navigate = useNavigate();
  const { rezitem_status } = cancelDetails?.cancellation_calculation || {};
  const [loadingStatus, setLoadingStatus] = useState(false);

  const changeResItemStatus = async (status, redirect) => {
    setLoadingStatus(true);
    const payload = {
      data: {
        entity: "reservation_item",
        type: "primary",
        operation: status,
        entity_uuid: [
          {
            uuid: itemId,
          },
        ],
      },
    };
    const res = await changeResItemStatusApi({ payload });
    if (res.status) {
      setLoadingStatus(false);
      message.success("Item Status Updated Successfully");
      setChangeStatus(!changeStatus);
      redirect &&
        navigate(
          `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${res?.data?.data?.uuid}`
        );
    } else {
      setLoadingStatus(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <CloseOutlined
            className="back-icon-profile cross-icon"
            onClick={() => {
              navigate(
                `${ReservationItemDetailRoute}?id=${reservationId}&item-id=${itemId}`
              );
            }}
          />
          <div className="header_res">
            <p>Cancel Reservation Item </p>
            {loadingReservationData ? (
              <Skeleton.Input active={true} style={{ width: "350px" }} />
            ) : (
              <Link
                to={`${ReservationDetailRoute}?id=${reservationId}`}
                className="header_smalltext"
              >
                {reservationData?.name}
              </Link>
            )}
          </div>
        </h3>
        {loadingCancelDetails ? (
          <Skeleton.Input active={true} style={{ width: "200px" }} />
        ) : (
          <div
            className="breadcrum_buttons"
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {" "}
              Back
            </Button>
            {rezitem_status === "Reserved" && (
              <Button
                type="primary"
                onClick={() => {
                  changeResItemStatus("request_cancellation");
                }}
                loading={loadingStatus}
              >
                Request Cancellation
              </Button>
            )}
            {rezitem_status === "Cancel Pending" ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    changeResItemStatus("backoff_cancellation");
                  }}
                  loading={loadingStatus}
                >
                  Call Off Cancellation
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    changeResItemStatus("confirm_cancellation", true);
                  }}
                  loading={loadingStatus}
                >
                  Confirm Cancellation
                </Button>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default CancelReservationItemHeader;
