import axios from "axios";
import { axiosRequest } from "../utils/axios";
import { organizationDrupalAxios } from "../utils/organizationDrupalAxios";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
import { customRequestHeader } from "../utils/cutomRequestHeader";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

export const getAllOrganisationsApi = async (data) => {
  const { reqHeaders } = data;
  delete data["reqHeaders"];
  delete data["count"];
  delete data["order"];

  let url = "/api/v1/listing/organization";
  let result = new URLSearchParams(data).toString();
  if (data.sort_order && data.sort_order === "recently_read") {
    result = "/recently_read";
    url += result;
  } else {
    url += `?${result}`;
  }

  return await organizationDrupalAxios("get", url, reqHeaders);
};

export const getOrganizationTypeApi = async (data) => {
  return await organizationDrupalAxios(
    "get",
    "/api/v1/taxonomy_term/organization_type?sort=weight",
    data.reqHeaders
  );
};

export const organizationSearchApi = async (data) => {
  return await organizationDrupalAxios(
    "get",
    `/api/v1/listing/organization?name=${data.name}&org_type=${data.org_type}`,
    data.reqHeaders
  );
};

export const getOrganizationAllDetailsApi = async (data) => {
  return await organizationDrupalAxios(
    "get",
    `/api/v1/node/organization/${data.orgId || ""}?include=field_modified_by`,
    data.reqHeaders
  );
};

export const getPrimaryContactsListApi = async (data) => {
  return await organizationDrupalAxios("get", data.url, data.reqHeaders);
};

export const getPrimaryContactDetailsApi = async (data) => {
  return await organizationDrupalAxios(
    "get",
    `/api/v1/contact/contact/${data.contactId}?include=field_organization,field_vocal_part,field_vaccination,field_dietary_need,field_medical_history,field_destination_interests,field_airline,field_airplane_class,field_airplane_seat,field_hotel_room,field_vaccination_certificate,field_proof_photo`,
    data.reqHeaders
  );
};
export const getOrganizationLinkedContactsApi = async (data) => {
  return await organizationDrupalAxios(
    "get",
    `/api/v1/list/contact/org?uuid=${data.orgId}`,
    data.reqHeaders
  );
};
export const createOrganizationApi = async (data) => {
  return await organizationDrupalAxios(
    data.reqMethod,
    `/api/v1/node/organization/${data?.orgId || ""}`,
    data.reqHeaders,
    data.payload
  );
};
export const deleteOrganizationApi = async (data) => {
  return await organizationDrupalAxios(
    "delete",
    `/api/v1/node/organization/${data.orgId}`,
    data.reqHeaders,
    data.payload
  );
};
export const linkMediaToLibraryApi = async (data) => {
  return await organizationDrupalAxios(
    "post",
    data.url,
    data.reqHeaders,
    data.payload
  );
};

//
export const getAllSearchOrganisationApi = async (url) => {
  return await axiosRequest("get", url);
};

export const createSearchOrganizationApi = async (searchFormData) => {
  const { step, insertId, data, action } = searchFormData;
  let url;
  if (step && insertId && action) {
    url = `api/organization/search/add?step=${step}&id=${insertId}&action=create-next`;
  } else {
    if (step && action) {
      url = `api/contact/search/add?step=${step}&action=create-next`;
    } else {
      if (step && insertId) {
        url = `api/contact/search/add?step=${step}&id=${insertId}`;
      } else {
        url = `api/contact/search/add?step=${step}`;
      }
    }
  }
  return await axiosRequest("post", url, data);
};
export const deleteOrganisationApi = async (data) => {
  return await organizationDrupalAxios(
    "delete",
    `/api/v1/node/organization/${data.deleteId}`,
    data.reqHeaders,
    data.payload
  );
};

export const editOrganizationApi = async (data) => {
  return await axiosRequest("post", data.url, data.payload);
};

export const getOrganizationDetailsApi = async (url) => {
  return await drupalAxiosRequest(
    "get",
    url,
    customRequestHeader({ token: Token, defaultFormat: true })
  );
};
