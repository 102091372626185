import { Tabs } from "antd";
import "./Intakeviewtabs.less";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import TourMainTab from "../TourMainTab";
import ExtensionTab from "../ExtensionTab";
import CruiseSystemInformation from "../../CruiseDetails/CruiseSystemInformation";

const CruiseorTourDetailsTabs = ({
  type,
  id,
  allDetails,
  allDetailsLoading,
}) => {
  const [currentTab, setCurrentTab] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const tabArray = [
    type,
    "Pre-Extension",
    "Post-Extension",
  ];
  useEffect(() => {
    let tab = location.hash ? decodeURI(location.hash.slice(1)) : "1";
    if (tab == "1") {
      setCurrentTab(tab);
    } else {
      let num = tabArray.indexOf(tab);
      if (num === -1) {
        setNotFound(true);
      } else {
        setNotFound(false);
        setCurrentTab(`${num + 1}`);
      }
    }
  }, [location.hash]);

  const navigateWithHash = (hash) => {
    navigate(`${location.pathname}${location.search}#${hash}`);
  };

  const handleTabChange = (key) => {
    navigateWithHash(tabArray[Number(key) - 1]);
    // setCurrentTab(key);
  };

  if (notFound) {
    return <h1>Page Not Found</h1>;
  }
  if (!currentTab) {
    return <DataLoader />;
  }
  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key) => {
        handleTabChange(key);
      }}
    >
      <Tabs.TabPane tab={type} key="1">
        <TourMainTab
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Pre-Extension" key="2">
        <ExtensionTab
          key={2}
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Post-Extension" key="3">
        <ExtensionTab
          key={3}
          allDetails={allDetails}
          allDetailsLoading={allDetailsLoading}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CruiseorTourDetailsTabs;
