import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import {
  contactDetailsEditLoader,
  updatePreferenceInfo,
} from "../../../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../../../utils/cutomRequestHeader";
import "./ContactPreferencesDrawer.less";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";

const ContactPreferencesDrawer = ({ contactPreferencesData, contactId }) => {
  const {
    field_destination_interests,
    field_airline,
    field_airplane_class,
    field_airplane_seat,
    field_hotel_room,
  } = contactPreferencesData || {};

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { updatePreferenceResponse, loadingUpdatePreference } = useSelector(
    (state) => state.contacts.updatePreference
  );
  const updateContactRef = useRef(false);
  const [destinationList, setDestinationList] = useState([]);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [airlineList, setAirlineList] = useState([]);
  const [loadingAirline, setLoadingAirline] = useState(false);
  const [airplaneClassList, setAirplaneClassList] = useState([]);
  const [loadingAirplaneClass, setLoadingAirplaneClass] = useState(false);
  const [airplaneSeatList, setAirplaneSeatList] = useState([]);
  const [loadingAirplaneSeat, setLoadingAirplaneSeat] = useState(false);
  const [hotelRoomList, setHotelRoomList] = useState([]);
  const [loadingHotelRoom, setLoadingHotelRoom] = useState(false);
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  useEffect(() => {
    if (updateContactRef.current) {
      if (updatePreferenceResponse.status) {
        onClose();
        message.success("Travel Preferences Updated");
      } else {
        message.error(
          updatePreferenceResponse?.data?.response?.data?.message ||
            updatePreferenceResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        onClose();
      }
    }
    updateContactRef.current = true;
  }, [updatePreferenceResponse]);

  const showDrawer = () => {
    form.setFieldsValue({
      field_destination_interests: !!field_destination_interests.length
        ? field_destination_interests?.map((destination) => destination.id)
        : [],
      field_airline: field_airline?.id || null,
      field_airplane_class: field_airplane_class?.id || null,
      field_airplane_seat: field_airplane_seat?.id || null,
      field_hotel_room: field_hotel_room?.id || null,
    });
    getTaxonomyList(
      "destination_interests",
      setDestinationList,
      setLoadingDestination
    );
    getTaxonomyList("airline", setAirlineList, setLoadingAirline);
    getTaxonomyList(
      "airplane_class",
      setAirplaneClassList,
      setLoadingAirplaneClass
    );
    getTaxonomyList(
      "airplane_seat",
      setAirplaneSeatList,
      setLoadingAirplaneSeat
    );
    getTaxonomyList("hotel_room", setHotelRoomList, setLoadingHotelRoom);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinishHandler = (values) => {
    const payload = {
      data: {
        type: "contact--contact",
        id: contactId,
      },
    };

    payload.data.relationships = {
      ...payload.data.relationships,
      field_destination_interests: {
        data: !!values?.field_destination_interests.length
          ? values.field_destination_interests?.map((destinationInterest) => ({
              type: "taxonomy_term--destination_interests",
              id: destinationInterest,
            }))
          : [],
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_airline: {
        data: !!values?.field_airline
          ? {
              type: "taxonomy_term--airline",
              id: values.field_airline,
            }
          : {},
      },
    };

    payload.data.relationships = {
      ...payload.data.relationships,
      field_airplane_class: {
        data: !!values?.field_airplane_class
          ? {
              type: "taxonomy_term--airplane_class",
              id: values.field_airplane_class,
            }
          : {},
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_airplane_seat: {
        data: !!values.field_airplane_seat
          ? {
              type: "taxonomy_term--airplane_seat",
              id: values.field_airplane_seat,
            }
          : {},
      },
    };
    payload.data.relationships = {
      ...payload.data.relationships,
      field_hotel_room: {
        data: !!values?.field_hotel_room
          ? {
              type: "taxonomy_term--hotel_room",
              id: values.field_hotel_room,
            }
          : {},
      },
    };

    const reqHeaders = customRequestHeader({ token: Token });

    dispatch(updatePreferenceInfo({ payload, reqHeaders, contactId }));
  };

  return (
    <>
      {permissionChecker("add/edit contact") ? (
        <Button onClick={showDrawer} icon={<EditOutlined />} />
      ) : null}

      <Drawer
        title="Edit Travel Preferences"
        maxWidth={425}
        onClose={onClose}
        maskClosable={false}
        open={open}
        bodyStyle={{ paddingBottom: 32 }}
        extra={
          <Space>
            <Button
              onClick={() => {
                dispatch(contactDetailsEditLoader(false));
                form.submit();
              }}
              type="primary"
              loading={loadingUpdatePreference}
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Destination Interests"
                name="field_destination_interests"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  showArrow
                  mode="multiple"
                  loading={loadingDestination}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  options={destinationList.map((destination) => ({
                    key: destination.id,
                    value: destination.id,
                    label: destination.name,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Airline"
                name="field_airline"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingAirline}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={airlineList.map((airline) => ({
                    key: airline.id,
                    value: airline.id,
                    label: airline.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Airplane Class"
                name="field_airplane_class"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    display: "flex",
                  }}
                  loading={loadingAirplaneClass}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={airplaneClassList.map((airplaneClass) => ({
                    key: airplaneClass.id,
                    value: airplaneClass.id,
                    label: airplaneClass.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Airplane Seat"
                name="field_airplane_seat"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingAirplaneSeat}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={airplaneSeatList.map((airplaneSeat) => ({
                    key: airplaneSeat.id,
                    value: airplaneSeat.id,
                    label: airplaneSeat.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Hotel Room"
                name="field_hotel_room"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={loadingHotelRoom}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={hotelRoomList.map((hotelRoom) => ({
                    key: hotelRoom.id,
                    value: hotelRoom.id,
                    label: hotelRoom.name,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ContactPreferencesDrawer;
