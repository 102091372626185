import { Table, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const data = [
  {
    key: "1",
    label: "Task Type",
    input: <>
      <span className="document_pdf">Email</span>
    </>,
  },
  {
    key: "2",
    label: "Task Name",
    input: <>
      <span className="document_pdf">Email Confirmation</span>
    </>,
  },


  {
    key: "3",
    label: "Description",
    input: <>

      <div className="document_pdf">Email Subject: Booking Confirmation: IMC Gospel Music</div>
      <div className="document_pdf">Celebration 2023 Hawaii Cruise - R980105</div>
      <div className="document_pdf">Email To: David Astemborski (astemdw@gmail.com)</div>
      <div className="document_pdf">Email CC: 'Kelly Astemborski' (alohamekelly@gmail.com) </div>
    </>,
  },


  // {
  //   key: "4",
  //   label: "Email Type",
  //   input: <>
  //   <span className="document_pdf">Confirmation</span>
  //   </>,
  // },


  {
    key: "5",
    label: "Contact",
    input: <>
      <a href="#">David W Astemborski (79642)</a>
    </>,
  },

  {
    key: "6",
    label: "Reservation",
    input: <>
      <a href="#">IMC Gospel Music Celebration 2023 Hawaii Cruise - R980105</a>
    </>,
  },

  {
    key: "7",
    label: "Creator",
    input: <>
      <span className="document_pdf">System</span>
    </>,
  },

  {
    key: "8",
    label: "Assignee",
    input: <>
      <span className="document_pdf">System</span>
    </>,
  },


  {
    key: "9",
    label: "Due Time",
    input: <>
      <span className="document_pdf">10/24/2022 12:35 PM</span>
      <span className="span_text"> 17 days ago</span>
    </>,
  },


  {
    key: "10",
    label: "Start Time",
    input: <>
      <span className="document_pdf">10/24/2022 12:35 PM</span>
      <span className="span_text"> 17 days ago</span>
    </>,
  },


  {
    key: "11",
    label: "End Time",
    input: <>
      <span className="document_pdf">10/24/2022 12:36 PM</span>
      <span className="span_text"> 17 days ago</span>
    </>,
  },

  {
    key: "12",
    label: "Task Status",
    input: <>
      <span className="document_pdf">Complete</span>
    </>,
  },

];

function Task_details() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0">Task Details</h4>
        <span>
          <Link to="/Reservation_activityemailview">
            {" "}
            <Button type="y" icon={<EditOutlined />} />
          </Link>
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default Task_details;
