import React from "react";
import CategoryPricingForm from "./CategoryPricingForm";

const AddOrEditPricing = ({
  addonId,
  categoryDetails,
  loadingSingleCategory,
  form,
  categoryId,
}) => (
  <div className="details-tabs" style={{ margin: 0 }}>
    <div className="basic_details pd-16 bg-white">
      <CategoryPricingForm
        addonId={addonId}
        categoryDetails={categoryDetails}
        loadingSingleCategory={loadingSingleCategory}
        form={form}
        categoryId={categoryId}
      />
    </div>
  </div>
);

export default AddOrEditPricing;
