import React from "react";
import { Skeleton, Tabs } from "antd";
import type { TabsProps } from "antd";

const ReportListingTab: React.FC = ({
  setUrlParamsObject,
  urlParamsObject,
  reportCount,
  loadingList,
  loadingQuery,
  queryCount,
}) => {
  const items: TabsProps["items"] = [
    {
      key: "Reports",
      label: (
        <span className="small_text">
          Reports{" "}
          {loadingList ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "50px" }}
            />
          ) : (
            `(${reportCount || 0})`
          )}
        </span>
      ),
    },
    {
      key: "Queries",
      label: (
        <span className="small_text">
          Queries{" "}
          {loadingQuery ? (
            <Skeleton.Button
              shape="round"
              size="small"
              style={{ width: "50px" }}
            />
          ) : (
            `(${queryCount || 0})`
          )}
        </span>
      ),
    },
  ];
  const onChange = (key) => {
    console.log(key);
    if (key === "Reports") {
      setUrlParamsObject({ tab: key, sort_by: "recent_read" });
    } else {
      setUrlParamsObject({ tab: key });
    }
  };
  if (urlParamsObject) {
    return (
      <Tabs
        className="details-tabs"
        defaultActiveKey={urlParamsObject && urlParamsObject.tab}
        items={items}
        onChange={onChange}
      />
    );
  }
};
export default ReportListingTab;
