import { Card, Col, Row } from "antd";
import React from "react";
import AvailabilityPie from "./AvailabilityPie";
import DemographicsPie from "./DemographicsPie";

const DashboardPieChartContainer = ({ setLoadingDashboard }) => {
    console.log("parent")
  return (
    <Row
      gutter={16}
      style={{ marginBottom: "20px" }}
      className="dash-section dash-section-three"
    >
      <Col xs={24} sm={24} md={12} lg={12}>
        <Card style={{ marginBottom: "20px" }}>
          <h3>Availability</h3>
          <AvailabilityPie setLoadingDashboard={setLoadingDashboard} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Card>
          <h3>Demographics</h3>
          <DemographicsPie setLoadingDashboard={setLoadingDashboard} />
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardPieChartContainer;
