import { Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Inspiration from "./MarketingSourcesItems/Inspiration";
import ModalSource from "./MarketingSourcesItems/ModalSource";
import { changeWeight, getMarketingSource } from "../../../../apis/eventsApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const MarketingSources = (props) => {
  const { tab } = props;
  const [sourceList, setSourceList] = useState([]);

  const [itemList, setItemList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState();
  let paramId = searchParams.get("id");
  let isGlobal = searchParams.get("global");

  const navigate =useNavigate()
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
    let payload = {
      event_id: props?.eventData?.drupal_internal__id,
      weights: {},
    };
    updatedList.forEach((val, index) => {
      payload.weights[index] = val.dragId;
    });
    changeWeight(payload);
  };
  const fetchMarketingSource = async () => {
    const response = await getMarketingSource(
      tab && tab === "global" ? "global" : paramId
    );
    if (response?.status) {
      setSourceList(response.data);
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setRefreshData(false);
    setLoading(false);
  };

  useEffect(()=>{
    const url = window.location.pathname.split("/");
    const isAllowed = url.includes("event") ? permissionChecker("view events") :  permissionChecker("view navigation menu marketing sources");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    if (sourceList && sourceList.length) {
      let components = [];
      sourceList.forEach((source, index) => {
        components.push({
          component: (
            <Inspiration
              sourceList={source}
              parentIndex={index}
              setRefreshData={setRefreshData}
              tab={tab}
            />
          ),
          dragId: `${source.para_id}`,
        });
      });
      setItemList(components);
    } else {
      setItemList([]);
    }
  }, [sourceList]);
  useEffect(() => {
    fetchMarketingSource();
  }, []);

  useEffect(() => {
    if (refreshData) {
      fetchMarketingSource();
      setSourceList([]);
      setLoading(true);
    }
  }, [refreshData]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: "12px 0",
    ...draggableStyle,
  });
  return (
    <div>
      <div className="partnership_inform">
        <h4>Marketing Sources</h4>
        {isGlobal !== "true" ? <ModalSource setRefreshData={setRefreshData} tab={tab} /> : null}
      </div>
      <Divider className="global_divider mb-0" />
      {loading ? (
        <DataLoader />
      ) : (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="lists-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sourceList.length
                  ? itemList.map((component, index) => (
                      <Draggable
                        key={component.dragId + 1}
                        draggableId={component.dragId + 1}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="imc_concert"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {component.component}
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default MarketingSources;
