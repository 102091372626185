import { Table, Button, Divider, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
// import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import moment from "moment";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../utils/constants";

import React from "react";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const TaskMessage = ({ activityDetails, loadingSingleActivity }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [emailBody, setEmailBody] = useState(null);
  const activityId = searchParams.get("activityId");
  const contactId = searchParams.get("contactId");

  const messageBody = activityDetails?.field_message?.value || "";
  //   activityDetails?.field_task_type === "Email"
  //   ? activityDetails?.field_email_body?.value
  //   : activityDetails?.field_message?.value;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h4 className="mb-0">Task Message</h4>
        <span>
          {/* <Link to="/Reservation_activityemailview"> */}
          {
            permissionChecker("edit task details and message") ? <Link
            to={`/contact/details/activities/edit?activityId=${activityId}&contactId=${contactId}`}
          >
            <Button type="y" icon={<EditOutlined />} />
          </Link> : null
          }
        </span>
      </div>
      <Divider className="global_divider" />
      <div style={{ marginLeft: "1rem" }}>{parse(messageBody)}</div>
    </div>
  );
};

export default TaskMessage;
