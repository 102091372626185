import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Select } from "antd";
import { useDispatch } from "react-redux";
import {
  getEventStatusApi,
  updateEventStatusApi,
} from "../../../../apis/eventsApi";
import DataLoader from "../../../../others/Loaders/DataLoader";
import { changedEventStatus } from "../../../../redux/features/eventFeatures/eventsSlice";
import { EventRoute } from "../../../../url-routes/urlRoutes";
import moment from "moment";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { permissionChecker } from "../../../../utils/permisssionChecker";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Input",
    dataIndex: "input",
  },
];

const SystemOptionsSettings = ({ eventData, eventId, loading }) => {
  const dispatch = useDispatch();

  const [fieldStatus, setFieldStatus] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Change Status");
  const [isLoading, setIsLoading] = useState(false);
  const [changedFieldStatus, setChangedFieldStatus] = useState([]);
  const [isUnarchive, setIsUnarchive] = useState(false);
  const [iswebEnabled, setIsWebEnabled] = useState(null);

  const handeleWebEnabledChange = () => {
    if (!eventData?.field_status?.label === "Cancel") {
      setIsWebEnabled(eventData?.field_web_enabled);
    } else {
      setIsWebEnabled(null);
    }
  };

  const getEventStatus = async () => {
    const response = await getEventStatusApi(eventId);
    if (response?.status) {
      if (
        Object.keys(response?.data?.data?.field_is_archive || {}).includes(
          "none"
        )
      ) {
        setFieldStatus([response?.data?.data?.event_status]);
      } else {
        setFieldStatus([response?.data?.data?.field_is_archive]);
        setIsUnarchive(true);
      }
    } else {
      setFieldStatus([]);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const handelSelectChange = (value) => {
    const payload = {
      data: {
        type: "event--event",
        id: eventId,
        attributes: {},
      },
    };
    let disableWebEnabledStatus = ["lock", "complete", "cancel"];
    let enableWebEnabledStatus = ["production"];

    if (isUnarchive) {
      payload.data.attributes.field_is_archive = value;
    } else {
      payload.data.attributes.field_status = value;
    }

    // condition to update web enable upon ebent status
    if (disableWebEnabledStatus.includes(value)) {
      payload.data.attributes.field_web_enabled = false;
    } else if (enableWebEnabledStatus.includes(value)) {
      payload.data.attributes.field_web_enabled = true;
    }

    setIsLoading(true);
    setSelectedValue("Change Status");
    updateStatus(eventId, payload);
  };

  const updateStatus = async (id, payload) => {
    const response = await updateEventStatusApi(id, payload);
    if (response?.status) {
      setIsLoading(false);
      dispatch(changedEventStatus(!isLoading));
      message.success("Status Updated Successfully");
      setChangedFieldStatus(response);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEventStatus();
  }, [changedFieldStatus]);

  const generateFieldStatus = () => {
    if (
      eventData?.field_is_archive !== "none" &&
      eventData.field_is_archive === "archive"
    ) {
      return "Archived";
    }
    if (eventData?.field_status) {
      return eventData?.field_status?.label === "Lock"
        ? "Locked"
        : eventData?.field_status?.label === "Cancel"
        ? "Canceled"
        : eventData?.field_status?.label === "Complete"
        ? "Completed"
        : eventData?.field_status?.label || "";
    }
  };
  function pad(num) {
    num = num.toString();
    while (num.length < 2) num = "0" + num;
    return num;
  }
  function convertSecondsToMinutesAndSeconds(seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    return pad(minutes) + ":" + pad(remainingSeconds);
  }
  console.log({ eventData });
  const data = [
    {
      key: "status",
      label: "Status",
      input: generateFieldStatus(),
    },
    {
      key: "time_in_cart",
      label: "Time In Cart",
      input: eventData?.field_time_in_cart
        ? convertSecondsToMinutesAndSeconds(eventData?.field_time_in_cart)
        : null,
    },
    {
      key: "max_traveler_capacity",
      label: "Traveler Goal",
      input: eventData?.field_max_capacity || null,
    },
    {
      key: "min_required_adult_age",
      label: "Minimum Required Adult Age",
      input: eventData?.field_max_required_adult_age
        ? `${eventData?.field_max_required_adult_age} years`
        : null,
    },
    {
      key: "min_age",
      label: "Min Age",
      input: eventData?.field_minimum_age
        ? `${eventData?.field_minimum_age} years`
        : null,
    },
    {
      key: "web_enabled",
      label: "Web Enabled",
      input: (
        <>
          <Switch
            disabled
            checked={eventData?.field_web_enabled || false}
            // checked={iswebEnabled}
            // onChange={handeleWebEnabledChange}
          />
        </>
      ),
    },
    {
      key: "req_flight_info",
      label: "Request Flight Information",
      input: (
        <>
          <Switch
            disabled
            checked={eventData?.field_req_flight_info || false}
          />
        </>
      ),
    },
    {
      key: "web_hide_item_code",
      label: "Web Hide Item Code",
      input: (
        <>
          <Switch disabled checked={eventData?.field_web_hide_item_code} />
        </>
      ),
    },
    {
      key: "web_brochure",
      label: "Web Brochure",
      input: (
        <>
          <Switch disabled checked={eventData?.field_web_brochure} />
        </>
      ),
    },

    {
      key: "report_flag",
      label: "Report Flag",
      input: (
        <>
          <Switch disabled checked={eventData?.field_report_flag} />
        </>
      ),
    },
    {
      key: "allow_no_primary_item",
      label: "Allow No Primary Item",
      input: (
        <>
          <Switch disabled checked={eventData?.field_allow_no_primary_item} />
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="partnership_inform">
        <h4>System Options/Settings</h4>

        <div className="breadcrumb_buttons">
          <Select
            value={selectedValue}
            style={{
              width: 160,
            }}
            showSearch            
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handelSelectChange}
            loading={isLoading}
            options={
              fieldStatus?.length
                ? fieldStatus
                    ?.map((val) => {
                      const arr = [];
                      for (let i in val) {
                        arr.push({
                          value: i,
                          label: i
                            ? `${i.charAt(0)?.toUpperCase()}${i.slice(1)}`
                            : "",
                        });
                      }
                      return arr;
                    })
                    .flat(2)
                : []
            }
          />

          <span>
            {permissionChecker("add/edit event") ? (
              <Link to={`${EventRoute}?editId=${eventId}&step=2`}>
                {" "}
                <Button type="y" icon={<EditOutlined />} />
              </Link>
            ) : null}
          </span>
        </div>
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={data}
        loading={{ spinning: loading, indicator: <DataLoader /> }}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default SystemOptionsSettings;
