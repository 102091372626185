import { EditOutlined, FileOutlined, DeleteOutlined, PaperClipOutlined, ArrowLeftOutlined, MoreOutlined, MailOutlined, UploadOutlined, ImportOutlined, CloseOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { Button, Radio } from 'antd';

import React, { useState } from 'react';


const menu = (
  <Menu
    items={[
      {
        label: <a href="#">Edit</a>,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="#">File</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: 'Download',
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: 'Import',
        key: '3',
      },
      {
        type: 'divider',
      },
      {
        label: 'Delete',
        key: '4',
      },
      {
        type: 'divider',
      },

      {
        label: 'Reports',
        key: '5',
      },
    ]}
  />
);


function Breadcrumb_motorcoach() {
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  return (

    <div className="breadcrumb_content">
      <div className='breadcrumb_heading view_header' >
        {/* <h3><CloseOutlined  className="back-icon-profile details_icon" /> <span style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '700', fontFamily: "'Poppins', sans-serif" }} >Assign Motorcoach </span>   <span className='span_text'>(Gaither Homecoming 2023 Caribbean Tour)</span>

        </h3> */}

        <h3 className='flex_row_column'>
          <CloseOutlined className="" />
          <div className='header_res'>
            <p>Assign Motorcoach</p>
            <p className='header_smalltext' >(Gaither Homecoming 2023 Caribbean Tour)</p>
          </div>
        </h3>


        <div className="breadcrum_buttons" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'end' }}>
          <Button type="primary">Save</Button>
          <Button>Cancel</Button>

        </div>
      </div>
    </div>

  );
}

export default Breadcrumb_motorcoach;
