import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import "./CruiseDetailsForm.less";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";
import DynamicStartEndDate from "../../../../../../others/StartEndDateValidator/DynamicStartEndDate";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const HotelOptionsForm = ({ form, onFinishHandler, isLoading }) => {
  const [travellerTypeList, setTravellerTypeList] = useState([]);
  const [loadingTravellerType, setLoadingTravellerType] = useState(false);
  const [starLevelList, setStarLevelList] = useState([]);
  const [loadingStarLevel, setLoadingStarLevel] = useState(false);
  const [destinationList, setDestinationList] = useState([]);
  const [loadingDestination, setLoadingDestination] = useState(false);

  useEffect(() => {
    getTaxonomyList(
      "traveler_type",
      setTravellerTypeList,
      setLoadingTravellerType
    );
    getTaxonomyList("star_level", setStarLevelList, setLoadingStarLevel);
    getTaxonomyList("destination", setDestinationList, setLoadingDestination);
  }, []);
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <h4 className="mb-0 ms-0">Hotel Options</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.List
            name="field_hotel_options"
            initialValue={[
              {
                field_city: null,
                field_hotel_name: null,
                field_check_in: null,
                field_check_out: null,
                field_nights: null,
                field_rooms: null,
                field_traveler_type: null,
                field_star_level: null,
                field_destination: null,
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                  >
                    {key > 0 ? (
                      <Form.Item>
                        <div className="close_relative">
                          <Button
                            className="close_absolute"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </Form.Item>
                    ) : null}

                    <Form.Item
                      label="Traveler Type"
                      {...restField}
                      name={[name, "field_traveler_type"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={travellerTypeList?.map((travellerType) => ({
                          key: travellerType.id,
                          value: travellerType.drupal_internal__tid,
                          label: travellerType.name,
                        }))}
                        loading={loadingTravellerType}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label="City"
                      {...restField}
                      name={[name, "field_city"]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Hotel Name"
                      {...restField}
                      name={[name, "field_hotel_name"]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Star Level"
                      {...restField}
                      name={[name, "field_star_level"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={starLevelList?.map((starLevel) => ({
                          key: starLevel.id,
                          value: starLevel.drupal_internal__tid,
                          label: starLevel.name,
                        }))}
                        loading={loadingStarLevel}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label="Destination"
                      {...restField}
                      name={[name, "field_destination"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={destinationList?.map((destination) => ({
                          key: destination.id,
                          value: destination.drupal_internal__tid,
                          label: destination.name,
                        }))}
                        loading={loadingDestination}
                        allowClear
                      />
                    </Form.Item>

                    <DynamicStartEndDate
                      form={form}
                      name={name}
                      restField={restField}
                      formListName={"field_hotel_options"}
                      startDateLabel={"Check In"}
                      endDateLabel={"Check Out"}
                      startName={"field_check_in"}
                      endName={"field_check_out"}
                    />

                    <Form.Item
                      label="Nights"
                      {...restField}
                      name={[name, "field_nights"]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Rooms"
                      {...restField}
                      name={[name, "field_rooms"]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        type="string"
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Hotel
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </div>
  );
};
export default HotelOptionsForm;
