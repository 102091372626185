import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Modal,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteContactApi } from "../../../apis/contactApi";
import { copyContact } from "../../../redux/features/contactFeatures/contactSlice";
import { Cookie, customRequestHeader } from "../../../utils/cutomRequestHeader";
import "./ContactDetailsHeader.less";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { DuplicateContactRoute } from "../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../utils/permisssionChecker";

const items = [
  {
    label: "Edit",
    key: "0",
  },
  {
    type: "divider",
  },
  {
    label: "File",
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "Delete",
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: "Find Duplicates",
    key: "3",
  },
];
const ContactDetailsHeader = ({
  contactId,
  contactDetailsData,
  contactDetailsLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, drupal_internal__id } = contactDetailsData || {};
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const authData = JSON.parse(localStorage.getItem("authorization"));
  const allowEditContact = authData?.permissions?.includes("add/edit contact");
  const allowDeleteContact = authData?.permissions?.includes("delete contact");

  const deleteContactFunc = async (contactId) => {
    const payload = {
      data: {
        type: "contact--contact",
        id: contactId,
      },
    };
    const reqHeaders = customRequestHeader({ token: Token });
    const response = await deleteContactApi({ payload, reqHeaders, contactId });
    if (response.status) {
      message.success("Contact Deleted Successfully");
      navigate("/contact/listing");
    } else {
      message.error(
        response?.data?.response?.data?.message || response?.data?.response?.data?.errors?.[0]?.detail ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const navigateToContact = (id, action, msg) => {
    if (id) {
      navigate(`/contact?${action}=${id}&step=1`);
    } else {
      message.error(msg || SOMETHING_WENT_WRONG);
    }
  };

  const ondeleteHandler = (deleteId) => {
    if (deleteId) {
      Modal.confirm({
        title: "Are you sure you want to delete this contact?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          return deleteContactFunc(deleteId);
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  const handleDropdownClick = (e) => {
    switch (e.key) {
      case "0":
        navigateToContact(contactId, "editId", "Unable To Edit");
        break;
      case "2":
        navigateToContact(contactId, "copyId", "Invalid Copy Id");
        break;
      default:
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleDropdownClick,
  };

  return (
    <div className="breadcrumb_content">
      <div className="breadcrumb_heading view_header">
        <h3 className="flex_row_column">
          <ArrowLeftOutlined
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          {contactDetailsLoading ? (
            <>
              <Skeleton.Input active={true} style={{ width: "250px" }} />
            </>
          ) : (
            <div className="header_res">
              <span style={{ marginRight: "5px" }}>{name || ""}</span>
              <span className="header_smalltext">
                {drupal_internal__id && `(${drupal_internal__id})`}
              </span>
            </div>
          )}
        </h3>

        <div className="breadcrumb_buttons">
          {permissionChecker("add/edit contact") ? (
            <Tooltip placement="top" title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigateToContact(contactId, "editId", "Unable To Edit");
                }}
              />
            </Tooltip>
          ) : null}
          {permissionChecker("copy contact") ? (<Tooltip placement="top" title="Copy Contact">
            <Button
              icon={<FileOutlined />}
              onClick={() => {
                // console.log("copy contact");
                navigateToContact(contactId, "copyId", "Invalid Copy Id");
              }}
            />
          </Tooltip>) : null}
          {permissionChecker("delete contact") ? (
            <Tooltip placement="top" title="Delete">
              <Button
                className="delete_btn"
                icon={<DeleteOutlined />}
                onClick={() => {
                  ondeleteHandler(contactId);
                }}
              />
            </Tooltip>
          ) : null}

          {permissionChecker("find duplicates") ? (<Link to={`${DuplicateContactRoute}?id=${contactId}`}>
            {" "}
            <Button type="">
              <SearchOutlined /> Find Duplicates
            </Button>
          </Link>): null}
        </div>

        <div className="breadcrumb_toggle">
          <Dropdown
            menu={menuProps}
            trigger={["click"]}
            overlayClassName="add_new"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button type="y" icon={<MoreOutlined />} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsHeader;
