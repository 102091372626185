import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDiscountDetailsApi } from "../../../apis/eventsApi";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import DiscountDetailsContainer from "../../../components/Events/EventDetailsTabs/DiscountTab/DiscountDetailsContainer";
import DiscountDetailsHeader from "../../../components/Events/EventDetailsTabs/DiscountTab/DiscountDetailsHeader";
import EventsSearchbar from "../../../components/Events/EventsSearchbar";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../url-routes/urlRoutes";
import { permissionChecker } from "../../../utils/permisssionChecker";
const { Header, Content } = Layout;

const DiscountDetailsPage = () => {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const discountId = searchParams.get("discountId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const [discountDetailsData, setDisountDetailsData] = useState({});
  const [loadingDiscountDetailData, setLoadingDiscountDetailData] =
    useState(false);
    
  const navigate = useNavigate()

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    {
      title: "Discounts",
      path: `${EventDetailsRoute}?id=${eventId}#Discounts`,
    },
    { title: "Discount Detail", path: "" },
  ];


  useEffect(()=>{
    const isAllowed = permissionChecker("view published discounts entities");
    if(!isAllowed){
      navigate("/page-not-access")
    }
  },[])

  useEffect(() => {
    getDiscountDetails();
  }, []);

  const getDiscountDetails = async () => {
    setLoadingDiscountDetailData(true);
    const response = await getDiscountDetailsApi(discountId);
    if (response?.status) {
      setDisountDetailsData(response?.data?.data);
      setLoadingDiscountDetailData(false);
    } else {
      setDisountDetailsData({});
      setLoadingDiscountDetailData(false);
    }
  };

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <DiscountDetailsHeader
              discountDetailsData={discountDetailsData}
              loadingDiscountDetailData={loadingDiscountDetailData}
              discountType={"event"}
            />
          </div>
          <DiscountDetailsContainer
            discountDetailsData={discountDetailsData}
            loadingDiscountDetailData={loadingDiscountDetailData}
            discountType={"event"}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DiscountDetailsPage;
