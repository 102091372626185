import { Button, Col, Drawer, Form, Row, Space } from "antd";
import { message } from "antd";

import React, { useEffect, useState } from "react";
import CustomDraggerUpload from "../../../../others/CustomUpload/CustomDraggerUpload";
import { useSearchParams } from "react-router-dom";
import { editTaskActivityApi } from "../../../../apis/contactApi";
import { useDispatch } from "react-redux";
import { documentFileUpdater } from "../../../../redux/features/contactFeatures/contactSlice";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

function TaskDocumentFile({ activityDetails, field_task_documents }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDocumentId, setuploadDocuementId] = useState("");
  const [addDocumentLoading, setAddDocumentLoading] = useState(false);
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid, user_id } = authorization;
  const [searchParams, setSearchParams] = useSearchParams();
  const activityId = searchParams.get("activityId");
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setFileList([]);
    setOpen(false);
  };

  const addTaskDocuments = async (payload) => {
    const res = await editTaskActivityApi({ payload, activityId });
    if (res.status) {
      form.resetFields();
      setFileList([]);
      onClose();
      dispatch(documentFileUpdater(res.data));
      message.success("Document Added");
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setAddDocumentLoading(false);
  };

  const onFinish = (values) => {
    setAddDocumentLoading(true);
    const { Upload_File } = values;
    const existingDocuments = field_task_documents?.length
      ? field_task_documents?.map(({ type, id }) => ({ type, id }))
      : [];

    const newDocuments = [
      ...existingDocuments,
      ...Upload_File?.map(({ uid, libraryId }) => ({
        type: "media--task_documents",
        id: libraryId,
      })),
    ];

    const payload = {
      data: {
        type: "activity--activity",
        id: activityId,
        relationships: {
          field_task_documents: {
            data: [...newDocuments],
          },
          field_last_modified_by: {
            data: {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
        },
      },
    };
    addTaskDocuments(payload);
  };

  useEffect(() => {
    if (fileList.length) {
      form.setFields([
        {
          name: "Upload_File",
          errors: [],
        },
      ]);
    }
  }, [fileList]);

  return (
    <>
      <div onClick={showDrawer}> Document</div>

      <Drawer
        title="Attach Document"
        maxWidth={425}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            {/* <Button onClick={onClose} style={{ opacity: '0.8' }} >Cancel</Button> */}
            <Button
              loading={addDocumentLoading}
              onClick={(e) => {
                // handleSave();
                // e.preventDefault();
                if (fileList.length) {
                  form.setFieldsValue({ Upload_File: fileList });
                }
                form.submit();
              }}
              type="primary"
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Upload File"
                name="Upload_File"
                rules={[
                  {
                    required: true,
                    message: "Please upload documents",
                  },
                ]}
              >
                <CustomDraggerUpload
                  fileList={fileList}
                  isMultiple={true}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  uploadUrl="/api/v1/media/task_documents/field_media_file"
                  mediaDocument={true}
                  setuploadDocuementId={setuploadDocuementId}
                  linkMediaUrl="/api/v1/media/task_documents"
                  linkMediaRelation="field_media_file"
                  linkMediaType="media--task_documents"
                  // supportedFormat={[".pdf", ".csv", ".doc"]}
                  allFormatsSupported={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default TaskDocumentFile;
