import { Form, Grid, Layout, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import AddonCreateOrEditFlightHeader from "../../../../../components/Events/EventDetailsTabs/AddonsTab/AddonDetailsSections/AddonFlight/AddonCreateOrEditFlightHeader";
import AddonFeeCreateOrEditForm from "../../../../../components/Events/EventDetailsTabs/FeesTab/AddonFeeCreateOrEditForm";
import Horizontals from "../../../../../components/Header/Horizontal_menu";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import AddonCreateOrEditFlightForm from "../../../../../components/Events/EventDetailsTabs/AddonsTab/AddonDetailsSections/AddonFlight/AddonCreateOrEditFlightForm";
import { getFlightDetailsApi } from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
const { Header, Content, Sider } = Layout;

function AddonFlightCreateOrEditPage() {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const editId = searchParams.get("editId");
  const [flightFormData, setFlightFormData] = useState({});
  const [loadingFlightFormData, setLoadingFlightFormData] = useState(false);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Details",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    { title: editId ? "Edit Flight" : "Add Flight", path: "" },
  ];

  useEffect(() => {
    if (editId) {
      getFlightDetails(editId);
    }
  }, []);

  const getFlightDetails = async (eId) => {
    setLoadingFlightFormData(true);
    const response = await getFlightDetailsApi(eId);
    if (response.status) {
      setFlightFormData(response?.data?.data);
      setLoadingFlightFormData(false);
    } else {
      message.error(SOMETHING_WENT_WRONG);
      setFlightFormData({});
      setLoadingFlightFormData(false);
    }
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <Horizontals />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <AddonCreateOrEditFlightHeader
              form={form}
              onSaveLoading={onSaveLoading}
              editId={editId}
            />
          </div>
          <Spin spinning={loadingFlightFormData} size="large">
            <div className="details-tabs" style={{ margin: 0 }}>
              <div>
                <div className="basic_details pd-16 bg-white">
                  <AddonCreateOrEditFlightForm
                    form={form}
                    eventId={eventId}
                    addonId={addonId}
                    editId={editId}
                    flightFormData={flightFormData}
                    setOnSaveLoading={setOnSaveLoading}
                  />
                </div>
              </div>
            </div>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AddonFlightCreateOrEditPage;
