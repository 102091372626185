import { Breadcrumb, Grid, Layout, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../../../../url-routes/urlRoutes";
import { getSingleAccomodation } from "../../../../../redux/features/eventFeatures/eventsSlice";
import EventsSearchbar from "../../../../../components/Events/EventsSearchbar";
import BreadCrumbGenerator from "../../../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import HeaderAccomodationTypeDetail from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/HeaderAccomodationTypeDetail";
import GeneraInformation from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/GeneraInformation";
import AccomodationCategoryTable from "../../../../../components/Events/EventDetailsTabs/CategoriesTab/AccomodationTypeDetail/AccomodationCategoryTable";
import { getSingleAccommodationApi } from "../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";

const { Header, Content, Sider } = Layout;

const AddonAccommodationTypeDetailsPage = () => {
  const dispatch = useDispatch();
  const [accommodationTypeData, setAccommodationTypeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const accId = searchParams.get("accId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const getAccomodationRef = useRef(false);
  const { isAccommodationOrCategoryCreated, singleAccommodation } = useSelector(
    (state) => state.events
  );
  const { singleAccomodationResponse, loadingSingleAccomodation } =
    singleAccommodation;

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Detail", path: `/event/details?id=${eventId}#Accommodations` },
    { title: "Add-Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add-Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}`,
    },
    {
      title: "Accommodation Type Detail",
      path: "",
    },
  ];

  useEffect(() => {
    getSingleAccommodation();
  }, []);

  const getSingleAccommodation = async () => {
    setLoading(true);
    const response = await getSingleAccommodationApi(accId);
    if (response?.status) {
      setAccommodationTypeData(response?.data?.data);
      setLoading(false);
    } else {
      setAccommodationTypeData([]);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (getAccomodationRef?.current) {
      if (singleAccomodationResponse?.status) {
        setAccommodationTypeData(singleAccomodationResponse?.data?.data || {});
      } else {
        message.error(
          singleAccomodationResponse?.error?.response?.data?.errors?.[0]
            ?.title ||
            singleAccomodationResponse?.error?.message ||
            SOMETHING_WENT_WRONG
        );
      }
      setLoading(false);
    }
    getAccomodationRef.current = true;
  }, [singleAccomodationResponse]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSingleAccomodation(accId));
  }, [isAccommodationOrCategoryCreated]);

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <HeaderAccomodationTypeDetail
              accommodationTypeData={accommodationTypeData}
              loading={loading}
              addonId={addonId}
            />
          </div>
          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
          >
            <GeneraInformation
              accommodationTypeData={accommodationTypeData}
              loading={loading}
              addonId={addonId}
            />
          </Content>

          <Content
            className="site-layout-background"
            style={{ marginBottom: "20px" }}
            addonId={addonId}
          >
            <AccomodationCategoryTable />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AddonAccommodationTypeDetailsPage;
