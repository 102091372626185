import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Modal, message, Select, Descriptions, Table } from "antd";
import "./OrganizationDetailsForm.less";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import OrganizationModal_table from "./OrganizationPrimaryContactsTable";
import Modal_searchbox from "./PrimaryContactSearch";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Button } from "antd";
import OrganizationPrimaryContactsTable from "./OrganizationPrimaryContactsTable";
import { getPrimaryContactsListApi } from "../../../apis/organisationApi";
import { useDispatch } from "react-redux";
import {
  createOrganization,
  getPrimaryContactDetails,
  getPrimaryContactsList,
  organizationStepSetter,
  updateOrganizationStep,
} from "../../../redux/features/organizationFeatures/organizationSlice";
import { useSelector } from "react-redux";
import PrimaryContactSearch from "./PrimaryContactSearch";
import { Country } from "country-state-city";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import DataLoader from "../../../others/Loaders/DataLoader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const PrimaryContact = ({
  organisationFormFields,
  form2,
  editId,
  createId,
  next,
  currentStep,
  setPrimaryContactLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const createOrganizationRef = useRef(false);
  const primaryContactListingRef = useRef(false);
  const primaryContactDetailsRef = useRef(false);
  const countriesList = Country.getAllCountries();
  const {
    organizationCreation,
    primaryContactsListing,
    primaryContactDetails,
  } = useSelector((state) => state.organizations);
  const {
    organizationCreationResponse,
    loadingOrganizationCreation,
    step2,
    pressedNextButton,
    organizationStep,
  } = organizationCreation;
  const { primaryContactsListingResponse, loadingPrimaryContactsListing } =
    primaryContactsListing;
  const { primaryContactDetailsResponse, loadingPrimaryContactDetails } =
    primaryContactDetails;
  const [primaryContactsList, setPrimaryContactsList] = useState([]);
  const [primaryContactInfo, setPrimaryContactInfo] = useState({});
  const [primaryContactId, setPrimaryContactId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orgContactId = searchParams.get("orgCotactId");
  // const [pcLoading, setPcLoading] = useState(true);
  const [organizationNid, setOrganizationNid] = useState(null);
  const [deleteOrgContactId, setDeleteOrgContactId] = useState(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if ((editId || createId) && Object.keys(organisationFormFields).length) {
      dispatch(organizationStepSetter(organisationFormFields.field_step));
      setOrganizationNid(organisationFormFields?.drupal_internal__nid || null);
      const contactId =
        orgContactId ||
        organisationFormFields?.field_primary_contact?.id ||
        null;
      const reqHeaders = customRequestHeader({
        token: Token,
        defaultFormat: true,
        isHeaderAbsent: false,
        isUploadReq: false,
      });
      if (!!contactId) {
        dispatch(getPrimaryContactDetails({ contactId, reqHeaders }));
        setPrimaryContactId(contactId);
      }
      form2.setFieldsValue({
        field_fax_phone_number:
          organisationFormFields?.field_fax_phone_number?.toString() || null,
        field_fax_extension:
          organisationFormFields?.field_fax_extension || "+1",
      });
    }
  }, [organisationFormFields]);

  useEffect(() => {
    if (primaryContactListingRef.current) {
      if (primaryContactsListingResponse.status) {
        setPrimaryContactsList(primaryContactsListingResponse?.data);
        setIsLoading(false)
      } else {
        message.error(
          primaryContactsListingResponse?.data?.message || SOMETHING_WENT_WRONG
        );
        setPrimaryContactsList([]);
        setIsLoading(false)
      }
    }
    primaryContactListingRef.current = true;
  }, [primaryContactsListingResponse]);

  useEffect(() => {
    if (primaryContactDetailsRef.current) {
      const {
        drupal_internal__id,
        field_address_field,
        field_job_title,
        field_email_address,
        field_email_address_2,
        id,
        name,
      } = primaryContactDetailsResponse?.data?.data || {};

      if (primaryContactDetailsResponse.status) {
        setPrimaryContactInfo({
          uuid: id,
          id: drupal_internal__id,
          name,
          title: field_job_title,
          email: field_email_address,
          email2: field_email_address_2,
          phone: field_address_field?.[0]?.field_cell_phone
            ? `${field_address_field?.[0]?.field_cell_phone_extension}-${field_address_field?.[0]?.field_cell_phone}`
            : null,
          phone2: field_address_field?.[0]?.field_other_phone
            ? `${field_address_field?.[0]?.field_other_phone_extension}-${field_address_field?.[0]?.field_other_phone}`
            : null,
        });
        form2.setFieldsValue({
          field_primary_contact: id,
        });
        if (deleteOrgContactId) {
          setIsModalOpen(false);
          if (deleteOrgContactId && orgContactId) {
            searchParams.delete("orgCotactId");
            setSearchParams(searchParams);
          }
        }
        setPrimaryContactLoading(false);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setPrimaryContactInfo({});
        deleteOrgContactId && setIsModalOpen(false);
        setPrimaryContactLoading(false);
      }
    }
    primaryContactDetailsRef.current = true;
  }, [primaryContactDetailsResponse]);

  useEffect(() => {
    if (createOrganizationRef.current) {
      if (organizationCreationResponse.status) {
        if (createId) {
          message.success(
            organizationCreationResponse?.data?.message ||
              "Form Submitted Successfully!"
          );
          step2
            ? navigate(`/organization/details?id=${createId}`)
            : next({ type: "creation", id: createId });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            step2
              ? navigate(`/organization/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateOrganizationStep());
      } else {
        message.error(
          organizationCreationResponse?.data?.response?.data?.error?.message ||
            organizationCreationResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    createOrganizationRef.current = true;
  }, [organizationCreationResponse]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const primaryContactHandler = (orgId) => {
    showModal();
    // const reqHeaders = customRequestHeader({
    //   token: Token,
    //   defaultFormat: true,
    //   isHeaderAbsent: false,
    //   isUploadReq: false,
    // });
    // dispatch(
    //   getPrimaryContactsList({
    //     reqHeaders,
    //     url: `/api/v1/select/contact?org=${orgId}`,
    //   })
    // );
  };

  const addSelectHandler = (pcId) => {
    const contactId = pcId;
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: true,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    setDeleteOrgContactId(true);
    if (!!orgContactId) {
      setPrimaryContactId(orgContactId);
    }
    if (pcId) {
      dispatch(getPrimaryContactDetails({ contactId, reqHeaders }));
    } else {
      message.error("Please Select Contact");
    }
  };

  const onFinishHandler = (values) => {
    const reqMethod = "patch";
    const orgId = editId || createId;
    const reqHeaders = customRequestHeader({
      token: Token,
      defaultFormat: false,
      isHeaderAbsent: false,
      isUploadReq: false,
    });
    const payload = {
      data: {
        type: "node--organization",
        id: orgId,
        attributes: {
          field_fax_extension: values.field_fax_extension || null,
          field_fax_phone_number: values.field_fax_phone_number || null,
          field_step: currentStep
            ? currentStep > organizationStep
              ? +currentStep
              : organizationStep
            : 1,
        },
        relationships: {
          field_primary_contact: {
            data: {
              type: "contact--contact",
              id: values.field_primary_contact,
            },
          },
          field_modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };

    dispatch(createOrganization({ reqMethod, orgId, reqHeaders, payload }));
  };

  const countryCodeSelector = (name) => (
    <Form.Item name={name} noStyle>
      <Select style={{ width: "90px" }} showSearch>
        {countriesList.map((country, index) => (
          <Select.Option
            value={`+${country.phonecode.replace("+", "")}`}
            key={index}
          >
            +{country.phonecode.replace("+", "")}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const formDefaultValues = {
    field_fax_extension: "+1",
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 24,
            lg: 8,
          }}
          wrapperCol={{
            sm: 24,
            md: 24,
            lg: 16,
          }}
          layout="horizontal"
          form={form2}
          onFinish={onFinishHandler}
          initialValues={formDefaultValues}
        >
          <Form.Item
            className="asterick-align"
            label="Primary Contact Name"
            name="field_primary_contact"
            rules={[
              { required: true, message: "Please select primary contact" },
            ]}
          >
            <>
              <Input style={{ display: "none" }} />
              {loadingPrimaryContactDetails ? null : (
                <Button
                  onClick={() => {
                    primaryContactHandler(organizationNid);
                  }}
                >
                  {!!Object.keys(primaryContactInfo).length
                    ? "Replace Contact"
                    : "Select Contact"}
                </Button>
              )}
            </>
          </Form.Item>

          {!!Object.keys(primaryContactInfo).length && (
            <div>
              <Form.Item label="Name" style={{ marginBottom: "0px" }}>
                {primaryContactInfo?.name || ""}
              </Form.Item>
              <Form.Item label="Job Title" style={{ marginBottom: "0px" }}>
                {primaryContactInfo?.title || ""}
              </Form.Item>
              <Form.Item label="Email Address" style={{ marginBottom: "0px" }}>
                {primaryContactInfo?.email || ""}
              </Form.Item>
              <Form.Item
                label="Alternative Email Address"
                style={{ marginBottom: "0px" }}
              >
                {primaryContactInfo?.email2 || ""}
              </Form.Item>
              <Form.Item label="Cell Phone" style={{ marginBottom: "0px" }}>
                {primaryContactInfo?.phone || ""}
              </Form.Item>
              <Form.Item label="Other Phone">
                {primaryContactInfo?.phone2 || ""}
              </Form.Item>
            </div>
          )}

          <Form.Item
            className="asterick-align"
            label="Fax"
            name="field_fax_phone_number"
            rules={[
              {
                min: 7,
                message: "Fax number must have minimum 7 digits",
                validateTrigger: "click",
              },
              {
                max: 15,
                message: "Fax number cannot exceed 15 digits",
                validateTrigger: "click",
              },
            ]}
          >
            <Input
              addonBefore={countryCodeSelector("field_fax_extension")}
              type="number"
              min={0}
              placeholder="Fax"
              autoComplete="nope"
              pattern="/^[0-9\b]+$/"
              onKeyDown={(evt) => {
                if (
                  evt.key === "e" ||
                  evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "." ||
                  evt.key === "ArrowUp" ||
                  evt.key === "ArrowDown"
                ) {
                  evt.preventDefault();
                }
              }}
              onInput={(e) => (e.target.value = e.target?.value?.slice(0, 12))}
            />
          </Form.Item>
        </Form>

        <Modal
          title="Select Contact"
          footer={
            <div className="end-between">
              <Link to={`/contact?primaryContactOrgId=${createId || editId}`}>
                <Button type="">
                  <PlusOutlined /> Add Contact
                </Button>
              </Link>
              <Button
                type="primary"
                loading={loadingPrimaryContactDetails}
                onClick={() => {
                  addSelectHandler(primaryContactId || orgContactId);
                }}
              >
                Add Selected Contact
              </Button>
            </div>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          // maskClosable={false}
          destroyOnClose={true}
          width={1000}
        >
          <div className="modal_searchbox">
            <PrimaryContactSearch
            setPrimaryContactsList={setPrimaryContactsList}
              createId={createId}
              editId={editId}
              organizationNid={organizationNid}
              setIsLoading={setIsLoading}
            />
          </div>

          <div className="modal_tablebox">
           {<OrganizationPrimaryContactsTable
              primaryContactsList={primaryContactsList}
              primaryContactInfo={primaryContactInfo}
              setPrimaryContactInfo={setPrimaryContactInfo}
              loading={loadingPrimaryContactsListing}
              createId={createId}
              isLoading={isLoading}
              editId={editId}
              setPrimaryContactId={setPrimaryContactId}
            />}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PrimaryContact;
