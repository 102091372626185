import { contactAxiosRequest } from "../utils/contactDrupalAxios";
import { customRequestHeader } from "../utils/cutomRequestHeader";
import { drupalAxiosRequest } from "../utils/drupalAxiosRequest";
const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

//contact listing
export const getAllContactsApi = async (data) => {
  const { reqHeaders } = data;
  delete data["reqHeaders"];
  delete data["count"];
  delete data["order"];

  let url = "/api/v1/list/contact";
  let queryString = new URLSearchParams(data).toString();
  let result = queryString.replace(/([&?])cid=/, '$1id=');
  if (data.sort_order && data.sort_order === "recently_read") {
    result = "/recently_read";
    url += result;
  } else {
    url += `?${result}`;
  }

  return await contactAxiosRequest("get", url, reqHeaders);
  // return await contactAxiosRequest(
  //   "get",
  //   `/api/v1/list/contact?items_per_page=${data.count}&page=${
  //     data.page - 1
  //   }&sort_by=name&sort_order=${data.order}`,
  //   data.reqHeaders
  // );
};
//create contact
export const createAndEditContactApi = async (data) => {
  return await drupalAxiosRequest(
    data.reqMethod,
    `/api/v1/contact/contact/${data?.contactId || ""}`,
    data.reqHeaders,
    data.payload
  );
};
//Contact Details
export const getContactAllDetailsApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/contact/contact/${data.contactId}?include=field_organization,field_vocal_part,field_vaccination,field_dietary_need,field_medical_history,field_destination_interests,field_airline,field_airplane_class,field_airplane_seat,field_hotel_room,field_vaccination_certificate,field_proof_photo,user_id,modified_by`,
    data.reqHeaders
  );
};
//contact delete
export const deleteContactApi = async (data) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/contact/contact/${data.contactId}`,
    data.reqHeaders,
    data.payload
  );
};
export const getOrganizationListApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/node/organization?page[limit]=500",
    data.reqHeaders
  );
};
//edit or update contact details
export const updateContactDetailsApi = async (data) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/contact/contact/${data.contactId}`,
    data.reqHeaders,
    data.payload
  );
};
//upload document
export const uploadDocumentApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    data.url,
    data.reqHeaders,
    data.payload
  );
};

//Activities
export const getAllActivitiesApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/activity/${data?.contactId}`,
    data.reqHeaders
  );
};

export const createActivitiesApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/activity/activity",
    customRequestHeader({ token: Token }),
    data.payload
  );
};
export const getAllEventsApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/event/event",
    data.reqHeaders
  );
};
export const getAllEventsListApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    "/api/v1/event/event?filter[field_status][condition][path]=field_status&filter[field_status][condition][value][0]=lock&filter[field_status][condition][value][1]=production&filter[field_status][condition][operator]=IN&filter[field_is_add_on]=0 for only events&page%5Blimit%5D=200",
    data.reqHeaders
  );
};

export const getAllAssigneeApi = async (data) => {
  return await drupalAxiosRequest("get", "/api/v1/user/user", data.reqHeaders);
};

export const createEmailActivityApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/activity/activity",
    data.reqHeaders,
    data.payload
  );
};

export const editTaskActivityApi = async (data) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/activity/activity/${data.activityId}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const createActivityDocumentLinkApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/media/link`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

export const getOneActivityDetailsApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/activity/activity/${data?.activityId}?include=user_id,field_activity_task_name,field_contact,field_event,field_organizations,field_reservation,field_assignee_staff,field_activity_tags,field_activity_email_type,field_attachments,field_last_modified_by`,
    customRequestHeader({ defaultFormat: true, token: Token })
  );
};

export const editActivityApi = async (data) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/activity/activity/${data.activityId}`,
    customRequestHeader({ token: Token }),
    data.payload
  );
};

//Request

export const getAllRequestListApi = async (data) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/list/request/listing?contact_uuid=${data}`,
    customRequestHeader({ token: Token })
  );
};

export const createRequestsApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    "/api/v1/contact_request/contact_request",
    data.reqHeaders,
    data.payload
  );
};

export const deleteRequestApi = async (deleteId) => {
  return await drupalAxiosRequest(
    "delete",
    `/api/v1/contact_request/contact_request/${deleteId}`,
    customRequestHeader({ token: Token })
  );
};
export const updateRequestApi = async (payload, reqId) => {
  return await drupalAxiosRequest(
    "patch",
    `/api/v1/contact_request/contact_request/${reqId}`,
    customRequestHeader({ token: Token }),
    payload
  );
};
export const getSingleRequestApi = async (id) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/contact_request/contact_request/${id}`,
    customRequestHeader({ token: Token })
  );
};

export const getContactReservationListingApi = async (data) => {
  const { page, order, count, uuid, status } = data || {};
  return await drupalAxiosRequest(
    "get",
    `/api/v1/reservations/listing?${page ? `&page=${Number(page) - 1}&` : ""}${
      order ? `&sort_order=${order}&` : ""
    }${count ? `&items_per_page=${count}&` : ""}contact_id=${uuid}${
      status ? `&status=${status}` : ""
    }`,
    customRequestHeader({ token: Token })
  );
};

export const marketingSourceApi = async () => {
  return await drupalAxiosRequest(
    "get",
    `/api/rest/marketing/sources/global`,
    customRequestHeader({ token: Token })
  );
};

export const fileUploadProcessingApi = async (data) => {
  return await drupalAxiosRequest(
    "post",
    data.url,
    data.reqHeaders,
    data.payload
  );
};

export const processQueueApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/rest/process-queue-item`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const getEliteTravelerStatusApi = async (contactId) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/contact/${contactId}/elite_status`,
    customRequestHeader({ token: Token })
  );
};

export const getContactsBySearchApi = async (name) => {
  return await drupalAxiosRequest(
    "get",
    `/api/v1/contact/contact?filter[name][value]=${name}&filter[name][operator]=CONTAINS`,
    customRequestHeader({ token: Token })
  );
};

export const getDuplicateContactApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/rest/contact/duplicates`,
    customRequestHeader({ token: Token, defaultFormat: true }),
    payload
  );
};

export const mergeDuplicateContactApi = async (payload) => {
  return await drupalAxiosRequest(
    "post",
    `/api/v1/rest/merge/duplicate/contacts`,
    customRequestHeader({ token: Token, defaultFormat:true }),
    payload
  );
};



export const getWallletAmount = async (options) => {
  const {contactId} = options
  return await drupalAxiosRequest(
    "get",
    `/rest/wallet-details/${contactId}`,
    customRequestHeader({ token: Token, defaultFormat:true }),
  );
};
