import React, { useState } from "react";

function FindDuplicateHeader() {
  return (
    <div className="breadcrumb_Organisation">
      <div className="breadcrumb_heading">
        <h3 className="flex_row_column">
          <div className="header_res">
            <p>Find Duplicates</p>
          </div>
        </h3>
      </div>
    </div>
  );
}

export default FindDuplicateHeader;
