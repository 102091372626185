import React from "react";

import { useEffect, useState } from "react";
import {
  DownOutlined,
  SlidersOutlined,
  MenuOutlined,
  SearchOutlined,
  LeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Drawer,
  Grid,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Form, Select } from "antd";
import { Button, Table, Modal } from "antd";
// import Sidebar from "../Header_sidebar";
import BasicProfileControl from "../../BasicProfileControl/BasicProfileControl";
import Sidebar from "../../Header/Header_sidebar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "../../../others/Debounce/debounce";
import {
  getAllGroupDetailsApi,
  getSingleGroupDetailWrapperApi,
} from "../../../apis/groupDetailsApi";
import {
  GroupDetailsListingRoute,
  GroupDetails_DetailsRoute,
} from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
const { Search } = Input;
const { useBreakpoint } = Grid;

const GroupDetailSearchBar = ({
  name,
  urlParamsObject,
  setUrlParamsObject,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(name);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const type = location.hash?.slice(1) || "Cruise";
      let urlQuery = `name=${debouncedSearchTerm}&items_per_page=10&page=0&sort_order=ASC`;
      getGroupDetailsSearchResult(urlQuery, debouncedSearchTerm);
    } else {
      setOptions([]);
      setAutoCompleteOpen(false);
      location.pathname === GroupDetailsListingRoute &&
        setUrlParamsObject({
          ...urlParamsObject,
          name: null,
        });
    }
  }, [debouncedSearchTerm]);

  const generateLink = (uuid, preExtId, postExtId, type) => {
    return `${GroupDetails_DetailsRoute}?id=${uuid}${
      preExtId ? `&preExtId=${preExtId}` : ""
    }${
      postExtId ? `&postExtId=${postExtId}` : ""
    }&type=${type.toLowerCase()}#${type}`;
  };

  const getGroupDetailsSearchResult = async (urlQuery, searchVal) => {
    if (urlParamsObject?.name === searchVal) {
      return;
    }

    let response = await getSingleGroupDetailWrapperApi(urlQuery);
    if (response?.status) {
      let searchOptions = response?.data?.[0]?.[
        urlParamsObject?.tab.toLowerCase()
      ]?.rows
        ?.slice(0, 5)
        ?.map((option) => {
          const extensions =
            option?.extensions?.length && JSON.parse(option?.extensions);
          const uuid = option?.uuid;
          const preExtId =
            extensions?.length &&
            extensions?.find((item) => item?.type === "pre")?.uuid;
          const postExtId =
            extensions?.length &&
            extensions?.find((item) => item?.type === "post")?.uuid;

          return {
            key: option?.uuid,
            value: (
              <span
                style={{ width: "100%", display: "block" }}
                onClick={() => {
                  navigate(
                    generateLink(uuid, preExtId, postExtId, option?.field_type)
                  );
                  // if (
                  //   option.uuid &&
                  //   option.extensions &&
                  //   option.extensions.length
                  // ) {
                  // let extensions = JSON.parse(option.extensions);

                  // navigate(
                  //   `${GroupDetails_DetailsRoute}?id=${option?.uuid}&${
                  //     extensions[0].type
                  //   }ExtId=${
                  //     extensions[0].uuid
                  //   }&type=${urlParamsObject?.tab.toLowerCase()}#${
                  //     urlParamsObject?.tab
                  //   }`
                  // );
                  // }
                  setAutoCompleteOpen(false);
                }}
              >
                {option?.name}
              </span>
            ),
          };
        });
      if (searchOptions?.length) {
        setOptions([
          ...searchOptions,
          {
            key: "key",
            value: (
              <span
                style={{ display: "block", width: "100%" }}
                onClick={() => {
                  location.pathname !== GroupDetailsListingRoute
                    ? navigate(
                        `${GroupDetailsListingRoute}?count=10&order=ASC&page=1&name=${searchVal}&tab="Cruise"`
                      )
                    : setUrlParamsObject({
                        count: 10,
                        order: "ASC",
                        page: 1,
                        tab: urlParamsObject?.tab,
                        // search: true,
                        name: searchVal || name,
                      });
                  setAutoCompleteOpen(false);
                  //   setOptions();
                }}
              >
                see all results for {searchVal || name}
              </span>
            ),
          },
        ]);
        // if (urlParamsObject.name !== searchVal) {
        setAutoCompleteOpen(true);
        // }
      } else {
        setAutoCompleteOpen(true);
      }
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setAutoCompleteOpen(false);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    // setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Search toggle for mobile
  const mobileSearchToggle = showSearch
    ? "active-search searchbox mobile-searchbox"
    : "searchbox";

  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mediaHideLarge = lg || lg === undefined ? "hide" : "";

  return (
    <div className="horizontal_menu">
      <Row className="logo-group">
        <Col className={mediaHideLarge}>
          <MenuOutlined className="hamurger-menu-icon" onClick={showDrawer} />
        </Col>
        <Col className={mediaHideLarge}>
          <div className="mainnav-logo">
            <a key="logo">
              <img src="./logo-mobile.png" alt="This is a Logo"></img>
            </a>
          </div>
        </Col>
        <Col sm={24} md={18} lg={12} className={mobileSearchToggle}>
          <div className="search-group searchbox__align group_details_cross">
            <div className="search_icon">
              <SearchOutlined />
            </div>
            <Button
              type="link"
              onClick={() => {
                setShowSearch(false);
              }}
              className="back-btn"
            >
              <LeftOutlined />
            </Button>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              placeholder="Search Group Details"
              size="large"
              open={autoCompleteOpen}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              onBlur={() => {
                setAutoCompleteOpen(false);
              }}
              allowClear={{
                clearIcon: (
                  <CloseOutlined
                    className="closeIcon"
                    style={{ right: "20px" }}
                  />
                ),
              }}
              onClear={(e) => {
                setAutoCompleteOpen(false);
                // location.pathname !== GroupDetailsListingRoute
                //   ? setSearchValue("")
                //   : setUrlParamsObject(({ name, ...urlParamsObject }) => ({
                //       ...urlParamsObject,
                //     }));
                // setUrlParamsObject(({ name, ...urlParamsObject }) => ({
                //   ...urlParamsObject,
                // }));
                // setUrlParamsObject({ count: 10, sort: "ASC", page: 1 });
              }}
              onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (location.pathname !== GroupDetailsListingRoute) {
                    navigate(
                      `${GroupDetailsListingRoute}?count=10&order=ASC&page=1&name=${e.target.value}`
                    );
                  } else {
                    setUrlParamsObject({
                      ...urlParamsObject,
                      page: 1,
                      order:
                        urlParamsObject?.order === "recently_read"
                          ? "ASC"
                          : urlParamsObject?.order,
                      name: e.target.value,
                    });
                    setAutoCompleteOpen(false);
                    // setIsFilterApplied(true);
                  }
                }
              }}
              value={
                urlParamsObject?.order === "recently_read" ? "" : searchValue
              }
            >
              {/* <Input.Search
                size="large"
                placeholder="Search Group Details"
                enterButton
              /> */}
            </AutoComplete>
          </div>
        </Col>
        <Col sm={12} className="profile-group">
          <div
            className="search-mobile"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <SearchOutlined />
          </div>

          <div className="profile">
            <BasicProfileControl />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="aside-menu primary-bg"
      >
        <Sidebar />
      </Drawer>
    </div>
  );
};

export default GroupDetailSearchBar;
