import { Grid, Layout } from "antd";
// import Horizontals from '../../components/Header/Horizontal_menu';
import React, { useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import Reservationlisting_header from "../../components/Breadcrumb/Reservations_header/Reservations_header";
import Searchreservations_menu from "../../components/Reservation/ReservationSearchBar";
import ReservationListingTabContainer from "../../components/Reservation/ReservationListing/ReservationListingTabContainer";
import { urlParser } from "../../utils/contentParser";
import { useSearchParams } from "react-router-dom";
import ReservationSearchBar from "../../components/Reservation/ReservationSearchBar";
const { Header, Content, Sider } = Layout;

function ReservationListingPage() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [totalReservation, setTotalReservation] = useState();
  const breadCrumbs = [{ title: "Reservations", path: "" }];
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSortOrder = "DESC"
  const page = searchParams.get("page") || 1;
  const count = searchParams.get("count") || 10;
  const order = searchParams.get("order") || defaultSortOrder;
  const tab = searchParams.get("tab") || "reservation";
  const [urlParamsObject, setUrlParamsObject] = useState({
    ...urlParser(window.location.search),
    count,
    order,
    page,
    tab,
  });
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <ReservationSearchBar
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              defaultSortOrder={defaultSortOrder}
            />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <Reservationlisting_header totalReservation={totalReservation} />
          </div>
          <ReservationListingTabContainer
            setTotalReservation={setTotalReservation}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            defaultSortOrder={defaultSortOrder}
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ReservationListingPage;
