import React, { useEffect, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import "./CruiseDetailsForm.less";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import DataLoader from "../../../../../../others/Loaders/DataLoader";

const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const PricingOptionsForm = ({ onFinishHandler, form, isLoading }) => {
  const [groupSizeList, setGroupSizeList] = useState([]);
  const [loadingGroupSize, setLoadingGroupSize] = useState(false);
  const [oneForProgramList, setOneForProgramList] = useState([]);
  const [loadingOneForProgram, setLoadingOneForProgram] = useState(false);

  useEffect(() => {
    getTaxonomyList("est_group_size", setGroupSizeList, setLoadingGroupSize);
    getTaxonomyList(
      "one_for_program",
      setOneForProgramList,
      setLoadingOneForProgram
    );
  }, []);

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="mb-0 ms-0">Pricing Options</h4>
      </div>
      <Divider className="global_divider mb-0" />

      <div style={{ marginTop: "24px" }} />
      {isLoading ? (
        <DataLoader />
      ) : (
        <Form
          {...layout}
          labelCol={{
            sm: 24,
            md: 6,
            lg: 6,
            xl: 5,
            xxl: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 14,
            lg: 14,
            xl: 14,
            xxl: 12,
          }}
          layout="horizontal"
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinishHandler}
          autoComplete="off"
        >
          <Form.List
            name="field_pricing_option"
            initialValue={[
              {
                field_est_group_size: null,
                field_travel_coordinator: null,
                field_event_partner_income: null,
                field_conference_fee: null,
                field_purpose_of_income: null,
                field_partner_income: null,
                field_notes: null,
                field_one_for_program: null,
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    style={key > 0 ? { borderTop: "1px solid #e8e8e8" } : {}}
                  >
                    {key > 0 ? (
                      <Form.Item>
                        <div className="close_relative">
                          <Button
                            className="close_absolute"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </Form.Item>
                    ) : null}


                    <Form.Item
                      label="Est Group Size"
                      {...restField}
                      name={[name,"field_est_group_count" ]}
                      wrapperCol={{
                        span: 6,
                      }}
                    >
                      <Input
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>


                    <Form.Item
                      {...restField}
                      name={[name, "field_est_group_size"]}
                      wrapperCol={{
                        span: 6,
                      }}
                      className="select_grid_field"
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={groupSizeList?.map((groupSize) => ({
                          key: groupSize.id,
                          value: groupSize.drupal_internal__tid,
                          label: groupSize.name,
                        }))}
                        loading={loadingGroupSize}
                        allowClear
                      />
                    </Form.Item>


                    <Form.Item
                      label="# of Travel Coordinators"
                      {...restField}
                      name={[name, "field_travel_coordinator"]}
                    >
                      <Input
                        type="number"
                        min={0}
                        autoComplete="nope"
                        pattern="/^[0-9\b]+$/"
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "."
                          ) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="1 for Program"
                      {...restField}
                      name={[name, "field_one_for_program"]}
                    >
                      <Select
                        showSearch
                        showArrow
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={oneForProgramList?.map((oneForProgram) => ({
                          key: oneForProgram.id,
                          value: oneForProgram.drupal_internal__tid,
                          label: oneForProgram.name,
                        }))}
                        loading={loadingOneForProgram}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div div className="text_break">
                          <div>Event Partner Income </div>
                          <div>(Minus Conf Fee)</div>
                        </div>
                      }
                      {...restField}
                      name={[name, "field_event_partner_income"]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Conference Fee"
                      {...restField}
                      name={[name, "field_conference_fee"]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Purpose of Income"
                      {...restField}
                      name={[name, "field_purpose_of_income"]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div div className="text_break">
                          <div>3rd & 4th Pax </div>
                          <div>Event Partner Income</div>
                        </div>
                      }
                      {...restField}
                      name={[name, "field_partner_income"]}
                    >
                      <Input />
                    </Form.Item>
                    <div className="textarea_row">
                      <Form.Item
                        label="Notes"
                        {...restField}
                        name={[name, "field_notes"]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </div>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Pricing Options
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </div>
  );
};
export default PricingOptionsForm;
