import { Col, Divider, Form, Input, Row, Select, message } from "antd";
import { Country, State } from "country-state-city";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTaxonomyList } from "../../../others/commonApiCalls/commonApiCalls";
import {
  contactStepSetter,
  createContact,
  updateStep,
} from "../../../redux/features/contactFeatures/contactSlice";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
import MobileInput from "../../Custom/MobileInput";
import "./FormSteps.less";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { customCountriesList } from "../../../others/util/customCountriesList";
const AddressForm = ({
  contactFormFields,
  secondAddress,
  setSecondAddress,
  form2,
  next,
  editId,
  createId,
  copyId,
  currentStep,
  primaryContactOrgId,
  orgId,
}) => {
  const countriesList = customCountriesList();
  const createContactRef = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [alternateStates, setAlternateStates] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [loadingAddressType, setLoadingAddressType] = useState(false);
  const [countryChange, setCountryChange] = useState(false);
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const userUuid = JSON.parse(localStorage.getItem("authorization"))?.uuid;
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationId = searchParams.get("redirectBackTo");

  const { createContactStatus, step2, contactStep } = useSelector(
    (state) => state.contacts.contactCreation
  );
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    getTaxonomyList("address_type", setAddressTypeList, setLoadingAddressType);
  }, []);

  useEffect(() => {
    if ((editId || createId) && Object.keys(contactFormFields).length) {
      dispatch(contactStepSetter(contactFormFields.field_step));
      if (!!contactFormFields?.field_address_field[1]?.field_address_type) {
        setSecondAddress(true);
      }
      let countryCode = getCountryCode(
        contactFormFields?.field_address_field[0]?.field_country || ""
      );
      let alternateCountryCode = getCountryCode(
        contactFormFields?.field_address_field[1]?.field_country || ""
      );
      setStates(
        countryCode?.isoCode
          ? State.getStatesOfCountry(countryCode.isoCode)
          : null
      );
      setAlternateStates(
        alternateCountryCode?.isoCode
          ? State.getStatesOfCountry(alternateCountryCode.isoCode)
          : null
      );
      form2.setFieldsValue({
        field_address_type:
          contactFormFields?.field_address_field?.[0]?.field_address_type?.id,
        field_address_line_1:
          contactFormFields?.field_address_field?.[0]?.field_address_line_1,
        field_address_line_2:
          contactFormFields?.field_address_field?.[0]?.field_address_line_2,
        field_city: contactFormFields?.field_address_field?.[0]?.field_city,
        field_state: contactFormFields?.field_address_field?.[0]?.field_state,
        field_country:
          contactFormFields?.field_address_field?.[0]?.field_country,
        field_postal_code:
          contactFormFields?.field_address_field?.[0]?.field_postal_code,
        field_cell_phone_extension:
          contactFormFields?.field_address_field?.[0]
            ?.field_cell_phone_extension || "+1",
        field_cell_phone:
          contactFormFields?.field_address_field?.[0]?.field_cell_phone,
        field_other_phone_extension:
          contactFormFields?.field_address_field?.[0]
            ?.field_other_phone_extension || "+1",
        field_other_phone:
          contactFormFields?.field_address_field?.[0]?.field_other_phone,
        //second address
        field2_address_type:
          contactFormFields?.field_address_field?.[1]?.field_address_type?.id,
        field2_address_line_1:
          contactFormFields?.field_address_field?.[1]?.field_address_line_1,
        field2_address_line_2:
          contactFormFields?.field_address_field?.[1]?.field_address_line_2,
        field2_city: contactFormFields?.field_address_field?.[1]?.field_city,
        field2_state: contactFormFields?.field_address_field?.[1]?.field_state,
        field2_country:
          contactFormFields?.field_address_field?.[1]?.field_country,
        field2_postal_code:
          contactFormFields?.field_address_field?.[1]?.field_postal_code,
        field2_cell_phone_extension:
          contactFormFields?.field_address_field?.[1]
            ?.field_cell_phone_extension || "+1",
        field2_cell_phone:
          contactFormFields?.field_address_field?.[1]?.field_cell_phone,
        field2_other_phone_extension:
          contactFormFields?.field_address_field?.[1]
            ?.field_other_phone_extension || "+1",
        field2_other_phone:
          contactFormFields?.field_address_field?.[1]?.field_other_phone,
      });
    }
  }, [contactFormFields]);

  useEffect(() => {
    if (createContactRef.current) {
      if (createContactStatus?.status) {
        if (createId) {
          message.success("Form Submitted Successfully!");
          let route = primaryContactOrgId
            ? `/organization?creationId=${primaryContactOrgId}&orgCotactId=${createId}&step=2`
            : !!orgId
            ? `/organization/details?id=${orgId}#Contacts`
            : `/contact/details?id=${createId}`;

          step2
            ? reservationId
              ? navigate(`/reservation/details?id=${reservationId}`)
              : navigate(route)
            : next({
                type: "creation",
                id: createId,
                primaryContactOrgId,
                orgId,
              });
        } else {
          if (editId) {
            message.success("Form Submitted Successfully!");
            step2
              ? navigate(`/contact/details?id=${editId}`)
              : next({ type: "edit", id: editId });
          } else {
            message.error(SOMETHING_WENT_WRONG);
          }
        }
        dispatch(updateStep());
      }

      if (!createContactStatus?.status) {
        message.error(
          createContactStatus?.data?.response?.data?.message ||
            createContactStatus?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    createContactRef.current = true;
  }, [createContactStatus]);

  const getCountryCode = (countryName) => {
    return countriesList.find((country) => country.isoCode === countryName);
  };

  const onFinishHandler = (values) => {
    const reqMethod = "patch";
    const contactId = editId || createId;
    const reqHeaders = customRequestHeader({ token: Token });
    const payload = {
      data: {
        type: "contact--contact",
        attributes: {
          field_address_field: [
            {
              field_address_type: values.field_address_type,
              field_address_line_1: values.field_address_line_1,
              field_address_line_2: values.field_address_line_2,
              field_city: values.field_city,
              field_state: values.field_state,
              field_country: values.field_country,
              field_postal_code: values.field_postal_code,
              field_cell_phone_extension: !!values.field_cell_phone
                ? values.field_cell_phone_extension
                : null,
              field_cell_phone: values.field_cell_phone,
              field_other_phone_extension: !!values.field_other_phone
                ? values.field_other_phone_extension
                : null,
              field_other_phone: values.field_other_phone,
            },
            {
              field_address_type: values?.field2_address_type || null,
              field_address_line_1: values?.field2_address_line_1 || null,
              field_address_line_2: values?.field2_address_line_2 || null,
              field_city: values?.field2_city || null,
              field_state: values?.field2_state || null,
              field_country: values?.field2_country || null,
              field_postal_code: values?.field2_postal_code || null,
              field_cell_phone_extension: !!values.field2_cell_phone
                ? values.field2_cell_phone_extension
                : null,
              field_cell_phone: values.field2_cell_phone,
              field_other_phone_extension: values.field2_other_phone
                ? values.field2_other_phone_extension
                : null,
              field_other_phone: values.field2_other_phone,
            },
          ],
          field_step: currentStep > contactStep ? +currentStep : contactStep,
        },
        relationships: {
          modified_by: {
            data: {
              type: "user--user",
              id: userUuid,
            },
          },
        },
      },
    };
    if (editId || createId) {
      payload.data.id = editId || createId;
    }

    dispatch(createContact({ reqMethod, contactId, reqHeaders, payload }));
  };
  const formDefaultValues = {
    field_cell_phone_extension: "+1",
    field_other_phone_extension: "+1",
    field2_cell_phone_extension: "+1",
    field2_other_phone_extension: "+1",
  };
  const addressForm = (typeOfAddress, fields, statesList) => {
    const {
      addressType,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      postalCode,
      otherPhone,
      otherPhoneExt,
      cellPhone,
      cellPhoneExt,
    } = fields;
    return (
      <>
        <Form.Item
          className="asterick-align"
          label="Address Type"
          name={addressType}
          rules={[{ required: true, message: "Please select address type" }]}
        >
          <Select
            loading={loadingAddressType}
            showSearch
            showArrow
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={addressTypeList.map((addressType) => ({
              key: addressType.drupal_internal__tid || "99",
              value: addressType.drupal_internal__tid || "99",
              label: addressType.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="Address Line 1"
          name={addressLine1}
          rules={[{ required: true, message: "Please enter address line 1" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Address Line 2" name={addressLine2}>
          <Input />
        </Form.Item>
        <div className="asterick-align">
          <Form.Item
            label="Country"
            name={country}
            rules={[{ required: true, message: "Please select country" }]}
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(val, selectObj) => {
                typeOfAddress === "primary"
                  ? setStates(State.getStatesOfCountry(selectObj.key))
                  : setAlternateStates(State.getStatesOfCountry(selectObj.key));
                let selectCountryObj = countriesList.find(
                  (country) => country.isoCode === val
                );
                setCountryChange(true);
                form2.setFieldsValue({
                  [state]: null,
                  [cellPhoneExt]: `+${selectCountryObj.phonecode.replace(
                    "+",
                    ""
                  )}`,
                  [otherPhoneExt]: `+${selectCountryObj.phonecode.replace(
                    "+",
                    ""
                  )}`,
                });
              }}
              onClear={() => {
                form2.setFieldsValue({
                  [state]: null,
                  [cellPhoneExt]: "+1",
                  [otherPhoneExt]: "+1",
                });
              }}
              options={countriesList.map((country) => ({
                key: country.isoCode,
                value: country.isoCode,
                label: country.name,
              }))}
            />
          </Form.Item>
        </div>

        <Form.Item label="State" name={state}>
          <Select showSearch allowClear>
            {statesList?.length
              ? statesList.map((state, index) => (
                  <Select.Option value={state.isoCode} key={state.isoCode}>
                    {state.name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item
          className="asterick-align"
          label="City"
          name={city}
          rules={[{ required: true, message: "Please enter city" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="asterick-align number-arrows"
          label="Postal Code"
          name={postalCode}
          rules={[{ required: true, message: "Please enter postal code" }]}
        >
          <Input placeholder="Postal Code"/>
        </Form.Item>
        <MobileInput
          label={"Cell Phone"}
          extension={cellPhoneExt}
          phoneName={cellPhone}
          form={form2}
          countryChange={countryChange}
        />
        <MobileInput
          label={"Other Phone"}
          extension={otherPhoneExt}
          phoneName={otherPhone}
          form={form2}
          countryChange={countryChange}
        />
      </>
    );
  };

  return (
    <div className="address_details">
      <div className="container border_container">
        <Row>
          <Col span={24}>
            <Form
              {...layout}
              labelCol={{
                sm: 24,
                md: 6,
                lg: 8,
              }}
              wrapperCol={{
                sm: 24,
                md: 18,
                lg: 16,
              }}
              layout="horizontal"
              form={form2}
              onFinish={onFinishHandler}
              initialValues={formDefaultValues}
            >
              <Divider orientation="left" plain>
                Primary Address
              </Divider>
              {addressForm(
                "primary",
                {
                  addressType: "field_address_type",
                  addressLine1: "field_address_line_1",
                  addressLine2: "field_address_line_2",
                  city: "field_city",
                  state: "field_state",
                  country: "field_country",
                  postalCode: "field_postal_code",
                  otherPhone: "field_other_phone",
                  otherPhoneExt: "field_other_phone_extension",
                  cellPhone: "field_cell_phone",
                  cellPhoneExt: "field_cell_phone_extension",
                },
                states
              )}
              {secondAddress ? (
                <>
                  <Divider orientation="left" plain>
                    Alternative Address
                  </Divider>
                  {addressForm(
                    "secondary",
                    {
                      addressType: "field2_address_type",
                      addressLine1: "field2_address_line_1",
                      addressLine2: "field2_address_line_2",
                      city: "field2_city",
                      state: "field2_state",
                      country: "field2_country",
                      postalCode: "field2_postal_code",
                      otherPhone: "field2_other_phone",
                      otherPhoneExt: "field2_other_phone_extension",
                      cellPhone: "field2_cell_phone",
                      cellPhoneExt: "field2_cell_phone_extension",
                    },
                    alternateStates
                  )}
                </>
              ) : null}
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddressForm;
