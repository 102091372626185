import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import PermissionModal from "../PermissionModal";

const ManagePermissionsHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="breadcrumb_content">
        <div className="breadcrumb_heading">
          <h3>
            <span style={{ fontFamily: "'Poppins', sans-serif" }}>
              Manage Permissions
            </span>
          </h3>
          <div className="breadcrum_buttons">
            <Button type="primary" onClick={showModal}>
              <PlusOutlined /> Create New Permission
            </Button>
          </div>
        </div>
      </div>
      <PermissionModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default ManagePermissionsHeader;
