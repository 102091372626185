import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { Card, Divider, Anchor } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

import React, { useState } from "react";
const { Option } = Select;

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { TextArea } = Input;
const { Dragger } = Upload;
const props = {
  action: "//jsonplaceholder.typicode.com/posts/",
  listType: "picture",
  previewFile(file) {
    console.log("Your upload file:", file);
    // Your process logic. Here we just mock to the same file
    return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
      method: "POST",
      body: file,
    })
      .then((res) => res.json())
      .then(({ thumbnail }) => thumbnail);
  },
};

function Reservation_activityaddfile() {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const { Search } = Input;

  const onSearch = (value) => console.log(value);
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} File Uploaded Successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} File Upload Failed.`);
      }
    },
  };

  return (
    <>
      <div onClick={showDrawer}> Document</div>

      <Drawer
        title="Attach Document"
        maxWidth={425}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: "32px" }}
        extra={
          <Space>
            {/* <Button onClick={onClose} style={{ opacity: '0.8' }} >Cancel</Button> */}
            <Button onClick={onClose} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Upload File"
                name="Upload File"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>

                  <p className="ant-upload-hint">
                    Supported file types: pdf, doc, xlsx, xlsm, jpeg, svg, png.
                    Max file size 20 MB.
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default Reservation_activityaddfile;
