import { Table, Button, Divider, Switch } from "antd";
import React, { useEffect, useState } from "react";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

const InsurancePayment = ({ insurancePaymentInfo }) => {

  const basicDetailsData = [
    {
      key: '1',
      label: 'Payment Amount',
      value: <>{insurancePaymentInfo?.payment_details?.payment_amount}</>,
    },

    {
      key: '2',
      label: 'Payment Method',
      value: <>{insurancePaymentInfo?.payment_details?.payment_method}</>,
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Payment Details</h4>
        {/* <span>
                        <Link to={`/contact?editId=${contactId}&step=1`}>
                            <Button icon={<EditOutlined />} />
                        </Link>
                    </span> */}
      </div>
      <Divider className="global_divider mb-0" />
      <Table
        className="basic_table"
        columns={columns}
        dataSource={basicDetailsData}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default InsurancePayment;
