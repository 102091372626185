import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getAllInventroy } from "../../../../../../redux/features/eventFeatures/eventsSlice";
import {
  AddonCategoryDetailsInventoryRoute,
  EventCategoryDetailsInventoryRoute,
} from "../../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import { permissionChecker } from "../../../../../../utils/permisssionChecker";
import InventoryListing from "./InventoryListing";
const { Panel } = Collapse;

const Inventory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const addonId = searchParams.get("addonId");
  const dispatch = useDispatch();
  const getAllInventoryRef = useRef(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [loadInventoryAgain, setLoadInventoryAgain] = useState(false);
  const { allInventoryListResponse, loadingAllInventoryList } = useSelector(
    (state) => state.events.allInventoryList
  );

  useEffect(() => {
    if (getAllInventoryRef?.current) {
      if (allInventoryListResponse?.status) {
        setInventoryData(allInventoryListResponse?.data);
      } else {
        message.error(
          allInventoryListResponse?.data?.response?.data?.message ||
            allInventoryListResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
        setInventoryData([]);
      }
    }
    getAllInventoryRef.current = true;
  }, [allInventoryListResponse]);

  useEffect(() => {
    dispatch(getAllInventroy(categoryId));
  }, [loadInventoryAgain, categoryId]);

  function redirectTo(inventoryId) {
    return addonId
      ? `${AddonCategoryDetailsInventoryRoute}?id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsInventoryRoute}?id=${eventId}&categoryId=${categoryId}`;
  }

  return (
    <Collapse
      bordered={false}
      //    defaultActiveKey={['1']}
    >
      <Panel
        header={
          <>
            <div className="collapse_flex">
              <p className="pricing_paragraph">{`Inventory (${inventoryData?.length})`}</p>
              {permissionChecker("add category") ? (
                <Link to={redirectTo()}>
                  <Button type="primary" icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Link>
              ) : null}
            </div>

            <Divider className="global_divider mb-0" />
          </>
        }
        key="1"
      >
        <InventoryListing
          inventoryData={inventoryData}
          loadingAllInventoryList={loadingAllInventoryList}
          setLoadInventoryAgain={setLoadInventoryAgain}
        />
      </Panel>
    </Collapse>
  );
};
export default Inventory;
