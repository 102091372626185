import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Grid } from "antd";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import logo_two from "../../assests/images/IT_logo_color.svg";
import "./Login.less";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  loggedInUser,
  memberLogin,
} from "../../redux/features/authFeatures/authSlice";
import { loggedInSuccessMsg } from "../../const/const";
import { getUserRoles } from "../../redux/features/usersFeatures/usersSlice";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useSelector((state) => state.authentication);
  const { loginResponse, loadingLogin } = login;
  const memberLoginRef = useRef(false);
  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const { lg } = useBreakpoint(); // lg is one of the elements returned if screenwidth exceeds 991
  const mobileClass = lg || lg === undefined ? "" : "mobileview";

  useEffect(() => {
    if (memberLoginRef.current) {
      if (loginResponse.status) {
        let userData = loginResponse?.data;
        console.log("🚀 ~ useEffect ~ userData:", JSON.stringify(userData,null,2))
        let accessTokenExpireAt =
          Date.now() + Number(userData.expires_in) * 1000;
        localStorage.setItem("authorization", JSON.stringify(userData));
        localStorage.setItem("accessTokenExpireAt", accessTokenExpireAt);

        message.success(loggedInSuccessMsg);
        dispatch(getUserRoles());
        setTimeout(() => {
          if (location?.state?.from && location.state.from.pathname !== "/") {
            window.location.href =
              location.state.from.pathname + location.state.from.search;
            // navigate(location.state.from, { replace: true });
          } else {
            window.location.href = "/";
            // navigate("/", { replace: true });
          }
        }, [100]);
      } else {
        message.error(
          loginResponse?.data?.response?.data?.error?.message ||
            loginResponse?.data?.response?.data?.message ||
            loginResponse?.data?.message ||
            SOMETHING_WENT_WRONG
        );
      }
    }
    memberLoginRef.current = true;
  }, [loginResponse]);

  const onFinishHandler = (values) => {
    let payload = new FormData();
    payload.append("grant_type", "password");
    payload.append("client_id", "0OAcTT-RvD5vecrSDKxqep_R6AIn0sK1F9m27pwYACE");
    payload.append("client_secret", "kjimpnyxzr42cggogwerbfbzhtfp2t");
    payload.append("username", values.username);
    payload.append("password", values.password);
    // const memberLoginPayload = {
    //   email: values.username,
    //   password: values.password,
    // };

    dispatch(memberLogin(payload));
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg_main">
      <div className="bg-overlay"></div>
      <div className="main_login">
        <div className="main_login-container">
          <div className="main_login-container--logo">
            <img src={logo_two} alt="Admin Logo" className="logo_two"></img>
          </div>

          <Form
            layout="horizontal"
            name="normal_login"
            className="login-form"
            form={form}
            onFinish={onFinishHandler}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Enter Username",
                },
              ]}
            >
              <Input
                prefix={
                  <UserOutlined
                    className="site-form-item-icon"
                    style={{ color: "#1A7F93" }}
                  />
                }
                placeholder="Enter Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Enter Password",
                },
              ]}
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    className="site-form-item-icon"
                    style={{ color: "#1A7F93" }}
                  />
                }
                placeholder="Enter Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ width: "100%", height: "40px" }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loadingLogin}
              >
                Login
              </Button>
            </Form.Item>

            <Form.Item
              // name="remember"
              //  valuePropName="checked"
              noStyle
            >
              <div className="login_remember">
                {/* <Checkbox>Remember me</Checkbox> */}
                <Link to="/forgot-password" className="login-form-forgot">
                  Forgot password?
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>

        <div className="login_bottom">
          <p>Copyright ©{currentYear} Inspiration Travel</p>
        </div>
      </div>
    </div>
  );
};
export default Login;
