import { Table, Divider, Select, Input, Switch } from "antd";
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { useState } from "react";
import "./Event_assignmotertable.less";

interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "1",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "2",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "3",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "4",
  },
  {
    title: "Staff",
    dataIndex: "staff",
    key: "5",
  },
  {
    title: "Primary Item",
    dataIndex: "primaryitem",
    key: "6",
  },
 
];

const data: DataType[] = [
  {
    key: 1,
    name: "1001650 (2)",

    children: [
      {
        key: 11,
        name: "Debra Ann Cowden",
        category: "4StarORD",
        gender: "F",
        age: 68,
        Staff: "",
        primaryitem: "4StarORD",
     
      },
      {
        key: 12,
        name: "Jeffrey Matthew Cowden",
        category: "4StarORD",
        gender: "F",
        age: 65,
        Staff: "",
        primaryitem: "4StarORD",
     
      },
    ],
  },
  {
    key: 2,
    name: "1009005 (2)",

    children: [
      {
        key: 13,
        name: "William Andrew Trapp",
        category: "5StarDTO",
        gender: "M",
        age: 46,
        Staff: "",
        primaryitem: "5StarDTO",
     
      },
      {
        key: 14,
        name: "Julie Ferguson Trapp",
        category: "5StarDTO",
        gender: "F",
        age: 50,
        Staff: "",
        primaryitem: "5StarDTO",
     
      },
    ],
  },
  {
    key: 3,
    name: "1009006 (2)",

    children: [
      {
        key: 15,
        name: "Amy Ferguson Pipes",
        category: "5StarDTO",
        gender: "F",
        age: 52,
        Staff: "",
        primaryitem: "5StarDTO",
     
      },
      {
        key: 16,
        name: "Samuel Wesley Pipes",
        category: "5StarDTO",
        gender: "M",
        age: 53,
        Staff: "",
        primaryitem: "	5StarDTO",
     
      },
    ],
  },
  {
    key: 4,
    name: "1014986 (2)",

    children: [
      {
        key: 17,
        name: "	Alford Foster Jr",
        category: "4StarDTO",
        gender: "M",
        age: 69,
        Staff: "",
        primaryitem: "4StarDTO",
     
      },
      {
        key: 18,
        name: "Barbara Parsons Foster",
        category: "4StarDTO",
        gender: "F",
        age: 67,
        Staff: "",
        primaryitem: "4StarDTO",
     
      },
    ],
  },
];




// rowSelection objects indicates the need for row selection
const rowSelection: TableRowSelection<DataType> = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

function Event_assignmotertable() {
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  return (
    <div>
      <div className="partnership_inform">
        <h4>Unassigned Guests</h4>
        <span>
        Group By:  
          <Select
            style={{ width: '150px', marginLeft: '5px' }}
            defaultValue="Reservation"
            onChange={handleChange}
            options={[
              {
                value: "Reservation",
                label: "Reservation",
              },
              {
                value: "Category",
                label: "Category",
              },
              {
                value: "Group",
                label: "Group",
              },
            ]}
          />
        </span>
      </div>
      <Divider className="global_divider mb-0" />
      {/* table */}
      <div className="event_unassign_search">
        <Search placeholder="Search" onSearch={onSearch} />
      </div>
      
       <Table
       className="reservation_main-tablee icon_change_transfer motor_coach"
       defaultExpandAllRows
        columns={columns}
        rowSelection={{ ...rowSelection, checkStrictly }}
        dataSource={data}
        pagination={{
          current: page,
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: 85,
          position: ["topRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      />
    </div>
  );
}

export default Event_assignmotertable;
